import React from 'react'
import { Button } from 'antd'

const ColorButton = (props) => {
  try {
    const { color, onClick } = props
    return (
      <Button
        onClick={onClick}
        style={{ backgroundColor: color }}
      >
        {!color ? 'X' : null}
      </Button>
    )
  } catch (e) {
    console.log('atoms/ColorButton error:', e)
    return null
  }
}

export default ColorButton

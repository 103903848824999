/* eslint-disable no-nested-ternary */
import React from 'react'
import {
  Select,
  Divider,
  InputNumber,
  Row,
  Col,
  Icon,
  Button,
  Input,
  Radio,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'

const { Search } = Input
const { Option } = Select

const CatalogBPFilters = (props) => {
  try {
    const {
      baseProducts,
      getFilteredBPs,
      allProviders,
      history,
      filters,
      hasFilters,
      setURLFilters,
      resetFilters,
      // getAllOptions,
      getAllCategories,
      getAllSubCategories,
      getAllTags,
      getAllMaterials,
      getAllOrigins,
      getAllColors,
      getAllProviders,
      getAllStatus,
    } = props

    const {
      keyword,
      category,
      subCategory,
      tags,
      material,
      origin,
      family,
      colors,
      provider,
      numberOfColors,
      status,
      deliveryTime,
      moq,
      buyingPrice,
      sellingPrice,
      oos = 'all',
    } = filters

    const { search } = history.location
    const intl = useIntl()

    const getOption = (value, filter, text) => {
      try {
        let number = 0
        let newSearch
        if (filter) newSearch = search.concat(`&${filter}=${escape(value)}`)
        if (newSearch) {
          number = getFilteredBPs(baseProducts, allProviders, newSearch).length
        }
        if (number > 0) {
          return (
            <Option value={value} key={value}>
              <span style={{ marginLeft: number < 100 ? number < 10 ? '17px' : '9px' : '0px' }}>
                {number}
              </span>
              <Divider type='vertical' />
              {filter === 'deliveryTime' ? (
                <Icon
                  type='clock-circle'
                  style={{ margin: '0 4px' }}
                />
              ) : null }
              {text || value}
            </Option>
          )
        }
        return null
      } catch (e) {
        console.log('CatalogBPFilters / getOption error:', e)
        return null
      }
    }

    return (
      <div style={{
        position: 'sticky',
        top: '20px',
        left: '20px',
      }}
      >
        <Divider style={{
          fontWeight: 'bold',
          marginBottom: 8,
        }}
        >
          <FormattedMessage id='filters' defaultMessage='Filtres' />
        </Divider>
        {hasFilters()
          ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                style={{ marginBottom: '8px' }}
                onClick={resetFilters}
              >
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </div>
          )
          : null}
        <Search
        // Note : waiting for AntD V4 to transform the keywordSearch in a Select, refering code has been silenced in form of comments
          id='keywordSearch'
          allowClear
          // showSearch
          style={{ width: '100%', margin: '5px 0' }}
          // onChange={(value) => {
          //   const trimmedValue = value && value.trim() !== '' ? value : undefined
          //   const newFilters = { ...filters, keyword: trimmedValue }
          //   setURLFilters(newFilters)
          // }}
          onSearch={(value) => {
            const trimmedValue = value && value.trim() !== '' ? value : undefined
            const newFilters = { ...filters, keyword: trimmedValue }
            setURLFilters(newFilters)
          }}
          // value={keyword}
          placeholder={intl.formatMessage({
            id: 'catalog.placeholder.keyword',
            defaultMessage: 'Mot-clé',
          })}
          disabled={
            // if there's only one available base product
            // (
            baseProducts && baseProducts.length < 2
            // or if there only one available option for this filter
            // || getAllOptions(DBBaseProducts, search).length < 2)
            // and if nothing has been selected in this filter
            // (otherwise the filter should still be cleanable)
            && !keyword
          }
        />
        <Select
          id='selectCategory'
          allowClear
          showArrow
          mode='multiple'
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, category: value })}
          // onSearch={(value) => setURLFilters({ ...filters, category: value })}
          value={category}
          placeholder={<FormattedMessage id='category' defaultMessage='Catégorie' />}
          disabled={
            // if there's only one available base product
            ((baseProducts && baseProducts.length < 2)
              // or if there only one available option for this filter
              || getAllCategories(baseProducts, allProviders, search).length < 2)
              // and if nothing has been selected in this filter
              // (otherwise the filter should still be cleanable)
              && !category
          }
        >
          {baseProducts && getAllCategories(baseProducts, allProviders, search).map(
            (cat) => getOption(cat, 'category'),
          )}
        </Select>
        <Select
          id='selectSubCategory'
          allowClear
          showArrow
          mode='multiple'
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, subCategory: value })}
          // onSearch={(value) => setURLFilters({ ...filters, subCategory: value })}
          value={subCategory}
          placeholder={<FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />}
          disabled={
            ((baseProducts && baseProducts.length < 2)
              || getAllSubCategories(baseProducts, allProviders, search).length < 2)
              && !subCategory
          }
        >
          {baseProducts && getAllSubCategories(baseProducts, allProviders, search).map(
            (subCat) => getOption(subCat, 'subCategory'),
          )}
        </Select>
        <Select
          id='selectTags'
          allowClear
          showArrow
          mode='multiple'
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, tags: value })}
          value={tags}
          placeholder={<FormattedMessage id='tags' defaultMessage='Tags' />}
          disabled={
            ((baseProducts && baseProducts.length < 2)
            || getAllTags(baseProducts, allProviders, search).length < 2)
              && !tags
          }
        >
          {baseProducts && getAllTags(baseProducts, allProviders, search).map(
            (tag) => getOption(tag, 'tags'),
          )}
        </Select>
        <Select
          id='selectMaterial'
          allowClear
          showArrow
          mode='multiple'
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, material: value })}
          // onSearch={(value) => setURLFilters({ ...filters, material: value })}
          value={material}
          placeholder={<FormattedMessage id='material' defaultMessage='Matière' />}
          disabled={
            ((baseProducts && baseProducts.length < 2)
              || getAllMaterials(baseProducts, allProviders, search).length < 2)
              && !material
          }
        >
          {baseProducts && getAllMaterials(baseProducts, allProviders, search).map(
            (mat) => getOption(mat, 'material'),
          )}
        </Select>
        <Select
          id='selectOrigin'
          allowClear
          showArrow
          mode='multiple'
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, origin: value })}
          // onSearch={(value) => setURLFilters({ ...filters, origin: value })}
          value={origin}
          placeholder={<FormattedMessage id='origin' defaultMessage='Origine' />}
          disabled={
            ((baseProducts && baseProducts.length < 2)
              || getAllOrigins(baseProducts, allProviders, search).length < 2)
              && !origin
          }
        >
          {baseProducts && getAllOrigins(baseProducts, allProviders, search).map(
            (ori) => getOption(ori, 'origin'),
          )}
        </Select>
        <Select
          id='selectFamilies'
          allowClear
          showArrow
          mode='multiple'
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, family: value })}
          value={family}
          placeholder={<FormattedMessage id='techniquesFamily' defaultMessage='Famille de techniques' />}
          disabled={
            (baseProducts && baseProducts.length < 2)
              && !family
          }
        >
          {getOption('Sans marquage', 'family', <FormattedMessage id='noMarking' defaultMessage='Sans marquage' />)}
          {getOption('Sur-mesure', 'family', <FormattedMessage id='tailored' defaultMessage='Sur mesure' />)}
          {getOption('Sur-devis', 'family', <FormattedMessage id='baseProductTechnicsForm.quotation' defaultMessage='Sur devis' />)}
          {getOption('Sérigraphie', 'family', <FormattedMessage id='silkscreen' defaultMessage='Sérigraphie' />)}
          {getOption('Broderie', 'family', <FormattedMessage id='embroidery' defaultMessage='Broderie' />)}
          {getOption('Numérique/Quadri', 'family', <FormattedMessage id='digitalQuadri' defaultMessage='Numérique/Quadri' />)}
          {getOption('Transfert', 'family', <FormattedMessage id='transfer' defaultMessage='Transfert' />)}
          {getOption('Gravure', 'family', <FormattedMessage id='engraving' defaultMessage='Gravure' />)}
          {getOption('Tampographie', 'family', <FormattedMessage id='padPrinting' defaultMessage='Tampographie' />)}
          {getOption('Sublimation', 'family', <FormattedMessage id='sublimation' defaultMessage='Sublimation' />)}
          {getOption('Embossage/Débossage', 'family', <FormattedMessage id='embossingDebossing' defaultMessage='Embossage/Débossage' />)}
        </Select>
        <Select
          id='selectColors'
          allowClear
          showArrow
          mode='multiple'
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, colors: value })}
          value={colors}
          placeholder={<FormattedMessage id='color' defaultMessage='Couleur' />}
          disabled={
            ((baseProducts && baseProducts.length < 2)
              || getAllColors(baseProducts, allProviders, search).length < 2)
              && !colors
          }
        >
          {baseProducts && getAllColors(baseProducts, allProviders, search).map(
            (color) => getOption(color, 'colors'),
          )}
        </Select>
        <Select
          allowClear
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, numberOfColors: value })}
          value={numberOfColors}
          placeholder={<FormattedMessage id='supportedColors' defaultMessage='Couleurs supportées' />}
          disabled={
            (baseProducts && baseProducts.length < 2)
              && !numberOfColors
          }
        >
          {getOption('1', 'numberOfColors')}
          {getOption('2', 'numberOfColors')}
          {getOption('3', 'numberOfColors')}
          {getOption('+4', 'numberOfColors')}
        </Select>
        <Select
          id='selectProvider'
          allowClear
          showArrow
          mode='multiple'
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, provider: value })}
          // onSearch={(value) => setURLFilters({ ...filters, provider: value })}
          value={provider}
          placeholder={<FormattedMessage id='supplier' defaultMessage='Fournisseur' />}
          disabled={
            ((baseProducts && baseProducts.length < 2)
              || getAllProviders(baseProducts, allProviders, search).length < 2)
              && !provider
          }
        >
          {baseProducts && getAllProviders(baseProducts, allProviders, search).map(
            (pro) => getOption(pro, 'provider'),
          )}
        </Select>
        <Select
          id='selectStatus'
          allowClear
          showArrow
          mode='multiple'
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, status: value })}
          value={status}
          placeholder={<FormattedMessage id='status' defaultMessage='Statut' />}
          disabled={
            ((baseProducts && baseProducts.length < 2)
              || getAllStatus(baseProducts, allProviders, search).length < 2)
              && !status
          }
        >
          {baseProducts && getAllStatus(baseProducts, allProviders, search).map(
            (sta) => getOption(sta, 'status'),
          )}
        </Select>
        <Select
          allowClear
          style={{ width: '100%', margin: '5px 0' }}
          onChange={(value) => setURLFilters({ ...filters, deliveryTime: value })}
          value={deliveryTime}
          placeholder={<FormattedMessage id='deliveryDelay' defaultMessage='Délais de livraison' />}
          disabled={
            (baseProducts && baseProducts.length < 2)
              && !deliveryTime
          }
        >
          {getOption('1', 'deliveryTime', <FormattedMessage id='within24hours' defaultMessage='Sous 24 heures' />)}
          {getOption('2', 'deliveryTime', <FormattedMessage id='within2days' defaultMessage='Sous 2 jours' />)}
          {getOption('5', 'deliveryTime', <FormattedMessage id='within5days' defaultMessage='Sous 5 jours' />)}
          {getOption('7', 'deliveryTime', <FormattedMessage id='within7days' defaultMessage='Sous 7 jours' />)}
          {getOption('15', 'deliveryTime', <FormattedMessage id='within15days' defaultMessage='Sous 15 jours' />)}
          {getOption('30', 'deliveryTime', <FormattedMessage id='within30days' defaultMessage='Sous 30 jours' />)}
        </Select>
        <div style={{ width: '100%', marginLeft: '12px', paddingRight: '12px' }}>
          <Row style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
            <Col span={16}>
              <FormattedMessage id='desiredQuantity' defaultMessage='Quantité désirée' />
              {' '}
              <Icon
                type='unlock'
                style={{ margin: '0 4px' }}
              />
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <InputNumber
                onChange={(value) => setURLFilters({ ...filters, moq: value })}
                value={moq}
                min={1}
                size='small'
                disabled={
                  (baseProducts && baseProducts.length < 2)
                    && !moq
                }
              />
            </Col>
          </Row>
          <Row style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
            <Col span={16}>
              <FormattedMessage id='catalogBPFilters.buyingPriceExcl' defaultMessage="Prix d'Achat HT (€)" />
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <InputNumber
                onChange={(value) => setURLFilters({ ...filters, buyingPrice: value })}
                value={buyingPrice}
                min={1}
                size='small'
                disabled={
                  (baseProducts && baseProducts.length < 2)
                    && !buyingPrice
                }
              />
            </Col>
          </Row>
          <Row style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
            <Col span={16}>
              <FormattedMessage id='catalogBPFilters.salesPriceExcl' defaultMessage='Prix de Vente HT (€)' />
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <InputNumber
                onChange={(value) => setURLFilters({ ...filters, sellingPrice: value })}
                value={sellingPrice}
                min={1}
                size='small'
                disabled={
                  (baseProducts && baseProducts.length < 2)
                    && !sellingPrice
                }
              />
            </Col>
          </Row>
          <Row style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
            <Col span={16}>
              <FormattedMessage id='catalogBPFilters.OOS.title' defaultMessage='Rupture de stock' />
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Radio.Group onChange={(e) => setURLFilters({ ...filters, oos: e.target.value })} value={oos}>
                <Radio value='only'>
                  <FormattedMessage id='catalogBPFilters.OOS.only' defaultMessage='Seulement' />
                </Radio>
                <Radio value='hide'>
                  <FormattedMessage id='catalogBPFilters.OOS.hide' defaultMessage='Masquer' />
                </Radio>
                <Radio value='all'>
                  <FormattedMessage id='catalogBPFilters.OOS.seeAll' defaultMessage='Tout afficher' />
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
        </div>
      </div>
    )
  } catch (e) {
    console.log('CatalogBPFilters', e)
    return null
  }
}

export default injectIntl(CatalogBPFilters)

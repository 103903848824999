// https://github.com/diegohaz/arc/wiki/Redux-modules
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
// import middlewares from './middlewares'
import reducer from './reducer'
import sagas from './sagas'
import config from '../config'

const { isDev } = config

const configureStore = (initialState, services = {}) => {
  const sagaMiddleware = createSagaMiddleware()
  // NOTE sanitizing to allow devtools to function
  // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Troubleshooting.md#excessive-use-of-memory-and-cpu
  const actionSanitizer = (action) => {
    return action
  }
  const composeEnhancers = (typeof window !== 'undefined' && isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionSanitizer,
    stateSanitizer: (state) => (state.data && state.data.length && state.data.length > 100 ?
      { ...state, data: state.data.slice(0, 100), hasBeenSanitized: true } : state),
  })) || compose
  const enhancers = composeEnhancers(applyMiddleware(
    // ...middlewares,
    sagaMiddleware,
  ))
  const store = createStore(reducer, initialState, enhancers)
  let sagaTask = sagaMiddleware.run(sagas, services)


  if (module.hot) {
    console.log('module is hot')
    module.hot.accept('./reducer', () => {
      const nextReducer = reducer.default
      store.replaceReducer(nextReducer)
    })
    module.hot.accept('./sagas', () => {
      const nextSagas = sagas.default
      sagaTask.cancel()
      sagaTask.done.then(() => {
        sagaTask = sagaMiddleware.run(nextSagas, services)
      })
    })
  }

  return store
}

export default configureStore

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { StepTooltipLabel } from '../..'
import theme, { character } from '../../../config/theme'

const StepInputLabel = (props) => {
  const {
    title,
    titleStyle,
    subtitle,
    subtitleStyle,
    tooltipText,
    isOptional = false,
    optionalStyle,
    iconStyle,
  } = props

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 500,
        fontSize: 14,
        color: character.title,
        gap: '4px',
        ...titleStyle,
      }}
      >
        {title}
        {isOptional && (
          <span style={{
            color: theme.character.secondary,
            ...optionalStyle,
          }}
          >
            <FormattedMessage id='field.optional' defaultMessage='(facultatif)' />
          </span>
        )}
        {tooltipText && <StepTooltipLabel title={tooltipText} style={iconStyle} />}
      </div>
      {subtitle && (
        <span style={{
          fontWeight: 500,
          fontSize: 10,
          color: character.disabledPlaceholder,
          ...subtitleStyle,
        }}
        >
          {subtitle}
        </span>
      )}
    </div>
  )
}

export default StepInputLabel

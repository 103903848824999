import React, { useState, useEffect } from 'react'
import {
  Button,
  Icon,
  Divider,
} from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter, Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { InnerLayout } from '../../layouts'
import { extractParams, writeUrl } from '../../helpers/urlHelpers'
import { INIT_FETCH_DATA, SAVE_DRAFT } from '../../store/products/actions'

import {
  Products as ProductsContainer,
  PreOrderCreationDrawer as PreOrderCreation,
  CartContainer as Cart,
  RefreshProducts,
  PreOrderContainer as PreOrder,
  ModifyProducts,
  ProductsStatusModificationDrawer,
} from '../../containers'
import { Refresher } from '../../components/molecules'

const initialState = {
  filters: {},
  selected: [],
  pagination: {
    current: 1,
    total: 50,
  },
  modification: false,
}

const Products = (props) => {
  const {
    location: { search }, shopId, fetchData, hasContent, history, isAdmin, saveDraft,
  } = props
  const query = extractParams(search)
  const composedFilters = { ...initialState.filters, ...query }
  const [productsPageState, setProductsPageState] = useState({ ...initialState, filters: composedFilters })
  const [selectedProducts, setSelectedProducts] = useState([])
  const {
    filters, selected, cartVisible, preOrder, modification,
  } = productsPageState

  const hasFilters = filters && Object.values(filters).filter(Boolean).length

  // on rajoute les filtres dans l'url quand ils sont modifiés
  useEffect(() => {
    history.push(writeUrl(filters))
  }, [filters])

  useEffect(() => {
    fetchData()
  }, [shopId])

  const setFiltering = (object) => {
    setProductsPageState({
      ...productsPageState,
      filters: {
        ...filters, ...object,
      },
    })
  }

  const resetFiltering = () => {
    setProductsPageState({ ...productsPageState, filters: initialState.filters })
  }

  const changeSelection = (newSelected) => {
    setProductsPageState({
      ...productsPageState,
      selected: newSelected,
    })
  }

  const closeDrawer = () => setProductsPageState({
    ...productsPageState,
    modification: false,
  })

  const actions = () => {
    const buttonTypes = {
      create: 'default',
      reset: selected.length || hasContent ? 'default' : 'primary',
      modify: hasContent ? 'default' : 'primary',
    }
    let defaultActions = [
      <Button key='multiple'>
        <Link
          to={{
            pathname: '/multiple-orders',
            state: { selected },
          }}
          state={selected}
        >
          {' '}
          <FormattedMessage
            id='productsPage.header.multiple'
            defaultMessage='Envoi Multiple'
          />
        </Link>
      </Button>,
      <RefreshProducts fetchData={fetchData} />,
      hasContent ? (
        <Button key='cart' type='primary' onClick={() => setProductsPageState({ ...productsPageState, cartVisible: true })}>
          <Icon type='shopping-cart' />
          <Divider type='vertical' />
          <FormattedMessage id='cart' defaultMessage='Panier' />
        </Button>
      ) : null,
    ].filter(Boolean)
    if (hasFilters) {
      defaultActions = [
        <Button key='reset' type={buttonTypes.reset} onClick={resetFiltering}>
          <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
        </Button>,
      ].concat(defaultActions)
    }
    if (selected.length > 0) {
      defaultActions = [
        <ModifyProducts key='modify' type={hasContent ? 'default' : 'primary'} />,
        <Button
          key='unselect'
          type={buttonTypes.modify}
          onClick={() => {
            setProductsPageState({ ...productsPageState, selected: [] })
          }}
        >
          <FormattedMessage
            id='productsPage.header.deselect'
            defaultMessage='Désélectionner'
          />
        </Button>,
        <PreOrder />,
      ].concat(defaultActions)
    } if (isAdmin) {
      defaultActions = [
        <Button
          type={buttonTypes.modify}
          onClick={() => {
            saveDraft({ draftProducts: selectedProducts })
            history.push('/workshop')
          }}
        >
          <FormattedMessage
            id='productsPage.header.modifyWorkshop'
            defaultMessage='Modifier avec l&apos;atelier'
          />
        </Button>,
      ].concat(defaultActions)
    }
    // TODO cart.content -> Commander
    return defaultActions
  }

  const toggleCart = () => {
    setProductsPageState({
      ...productsPageState,
      cartVisible: !cartVisible,
    })
  }
  return (
    <InnerLayout
      title={(
        <div style={{
          fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
        }}
        >
          <FormattedMessage
            id='products.pageTitle'
            defaultMessage='Produits'
          />
        </div>
      )}
      extra={actions()}
    >
      <div className='Products page'>
        <Refresher fetchData={fetchData} />
        <ProductsStatusModificationDrawer
          visible={!!modification}
          selected={selected}
          onOk={closeDrawer}
          onCancel={closeDrawer}
          isAdmin={isAdmin}
        />
        <PreOrderCreation
          visible={!!preOrder}
          selected={selected}
        />
        <Cart
          visible={cartVisible}
          onClose={toggleCart}
        />
        <ProductsContainer
          filtering={setFiltering}
          filters={filters}
          resetFiltering={resetFiltering}
          changeSelection={changeSelection}
          setSelectedProducts={setSelectedProducts}
          selected={selected}
          cartVisible={cartVisible}
          toggleCart={toggleCart}
          history={history}
        />
      </div>
    </InnerLayout>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
  saveDraft: (payload) => ({
    type: SAVE_DRAFT,
    payload,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(Products))

import React, { useEffect, useState } from 'react'
import {
  Modal, Button, notification,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { fromBaseProductsCart } from '../../../../store/selectors'
import { sendToHubspot, emptyCart } from '../../../../store/baseProductsCart/actions'
import CartRecap from '../../molecules/CartRecap'
import ImageSection from '../../molecules/ImageSection'
import SearchBar from '../../molecules/SearchBar'
import DealRecap from '../../molecules/DealRecap'
import config from '../../../../config'

const { catalog = {} } = config || {}
const { catalogApiUrl } = catalog

const ProductsMini = (props) => {
  const { cartContent } = props
  const slicedContent = cartContent.slice(0, 6)
  const difference = (cartContent.length - slicedContent.length)
  return (
    <div style={{
      textAlign: 'center', marginBottom: '8px', display: 'flex', justifyContent: 'center',
    }}
    >
      {slicedContent.map((product) => <ImageSection src={product.image} />)}
      { difference > 0 ? (
        <div style={{ margin: 'auto' }}>
          <span>
            {` + ${difference} `}
          </span>
        </div>
      ) : null}
    </div>
  )
}

const Footer = (props) => {
  const dispatch = useDispatch()
  const {
    isValid, dealId, closeModal, providersDeliveryRecap,
  } = props
  const totalDeliveryPrice = providersDeliveryRecap?.reduce((acc, current) => acc + current.deliveryPrice, 0)
  const onSuccess = () => {
    notification.success({
      message: 'Success',
      duration: 4,
    })
    closeModal()
    dispatch(emptyCart())
    window.open(`https://app-eu1.hubspot.com/contacts/26913618/deal/${dealId}`, '_blank')
  }

  const onError = () => {
    notification.error({
      message: 'Error',
      duration: 4,
    })
  }
  return (
    <div>
      <Button
        style={{ width: '100%' }}
        type='primary'
        disabled={!isValid}
        onClick={() => dispatch(sendToHubspot(dealId, totalDeliveryPrice, onSuccess, onError))}
      >
        <FormattedMessage id='bp-cart.send-hubpost' defaultMessage='Envoyer mes produits' />
      </Button>
    </div>
  )
}

const getDealList = async (query) => {
  try {
    const search = await fetch(`${catalogApiUrl}/api/deals`, {
      method: 'POST',
      body: JSON.stringify({ query }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const parsed = await search.json()
    const { result } = parsed
    return result
  } catch (e) {
    return []
  }
}

const HubspotDealValidator = (props) => {
  const cartContent = useSelector(fromBaseProductsCart.getBpCartContent)
  const {
    isValidatorVisible, totalQuantity, totalPrice, closeModal, providersDeliveryRecap,
  } = props
  const [isValid, setIsValid] = useState(false)
  const [deal, setDeal] = useState(null)
  const [lastDeals, setLastDeals] = useState([])
  useEffect(() => {
    fetch(`${catalogApiUrl}/api/deals`)
      .then((response) => response.json())
      .then((returnedDeals) => {
        const { result } = returnedDeals
        setLastDeals(result)
      })
      .catch(() => -1)
  }, [])

  const onDealSelectFromDropDown = (selectedDeal) => {
    try {
      const [id, name] = selectedDeal.split('-')
      setDeal({ id, name })
      setIsValid(true)
      return 1
    } catch (e) {
      return -1
    }
  }
  const { id: dealId } = deal || {}
  return (
    <Modal
      title={(
        <span style={{ fontSize: 'large', fontWeight: 'bold', left: '25%' }}>
          <FormattedMessage id='bp-cart.hubspot' defaultMessage='Ajouter mes produits sur hubspot' />
        </span>
      )}
      visible={isValidatorVisible}
      // L'action d'envoi du formulaire est dans le footer
      footer={(
        <Footer
          dealId={dealId}
          isValid={isValid}
          closeModal={closeModal}
          providersDeliveryRecap={providersDeliveryRecap}
        />
      )}
      onOk={() => console.log('clicked')}
      onCancel={() => closeModal()}
    >
      <ProductsMini cartContent={cartContent} />
      <div style={{ maginBottom: '32px' }}>
        <CartRecap
          nbOfProducts={cartContent.length}
          totalQuantity={totalQuantity}
          totalPrice={totalPrice}
          providersDeliveryRecap={providersDeliveryRecap}
        />
      </div>

      <span>
        <FormattedMessage id='bp-cart.deal-id' defaultMessage='Identifiant Deal' />
      </span>
      <SearchBar
        onSearch={getDealList}
        onSelect={onDealSelectFromDropDown}
        style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
        initialData={lastDeals}
      />
      { isValid && (
        <DealRecap
          deal={deal}
          onClear={() => {
            setIsValid(false)
            setDeal(null)
          }}
        />
      ) }
    </Modal>
  )
}

export default HubspotDealValidator

import React, { useState } from 'react'
import {
  Steps,
  Col,
  Button,
  Icon,
  Popconfirm,
  Result,
  Select,
  Input,
  Rate,
} from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { FormattedMessage } from 'react-intl'

import {
  BaseProductInfoForm,
  BaseProductImpactCarbon,
  BaseProductVariationsForm,
  BaseProductTechnicsForm,
  BaseProductImageForm,
  BaseProductRecap,
  BaseProductProviderForm,
} from '../..'

import { checkCreationBaseProduct } from '../../../helpers/baseProducts/creation'

const { Option } = Select
const { Step } = Steps
const { TextArea } = Input

const BaseProductForm = (props) => {
  try {
    const {
      product,
      updateProduct,
      createBaseProduct,
      modifyBaseProduct,
      removeProducts,
      createdIdBp,
      modifiedIdBp,
      history,
      loadingCreation,
      baseProducts,
      allProviders,
      allTechnics,
      allFamilies,
      products,
      colorChips,
    } = props

    const [currentStep, setCurrentStep] = useState(0)
    const [errorCreation, setErrorCreation] = useState([])
    const [displayButtonValidate, setDisplayButtonValidate] = useState(false)
    const [status, setStatus] = useState(product.status && product.status.length ? product.status : [])
    const [comment, setComment] = useState(product.comment || '')
    const [rate, setRate] = useState(product.rate || 0)

    const handleDisplayButton = () => {
      const arrayError = checkCreationBaseProduct(product, setCurrentStep)
      setErrorCreation(arrayError)
      if (arrayError.length === 0) {
        setDisplayButtonValidate(true)
      } if (arrayError.length) {
        setDisplayButtonValidate(false)
      }
    }

    const previousStep = () => {
      const previous = currentStep - 1
      setCurrentStep(previous)
      handleDisplayButton()
    }

    const nextStep = () => {
      const next = currentStep + 1
      setCurrentStep(next)
      handleDisplayButton()
    }

    if (createdIdBp === product.provisionalId) {
      return (
        <Result
          icon={<Icon type='check-circle' style={{ color: '#012169' }} />}
          title={<FormattedMessage id='productCreated' defaultMessage='Parfait, le produit est créé !' />}
          extra={[
            <Button
              type='primary'
              onClick={() => {
                updateProduct(product.provisionalId, {
                  provisionalId: Math.random().toString(8),
                })
              }}
            >
              <FormattedMessage id='continueProductCreation' defaultMessage='Reprendre la création du produit' />
            </Button>,
            <Button onClick={() => {
              history.push('/products')
            }}
            >
              <FormattedMessage id='closeWorkshop' defaultMessage="Fermer l'atelier" />
            </Button>,
          ]}
        />
      )
    } if (modifiedIdBp === product.provisionalId) {
      return (
        <Result
          icon={<Icon type='check-circle' style={{ color: '#012169' }} />}
          title={<FormattedMessage id='baseProductForm.modified' defaultMessage='Parfait, le produit est modifié !' />}
          extra={[
            <Button
              type='primary'
              onClick={() => {
                history.push('/catalog')
              }}
            >
              <FormattedMessage id='baseProductForm.catalog' defaultMessage='Voir le catalogue' />
            </Button>,
            <Button onClick={() => {
              history.push('/products')
            }}
            >
              <FormattedMessage id='closeWorkshop' defaultMessage="Fermer l'atelier" />
            </Button>,
          ]}
        />
      )
    }

    return (
      <div>
        <Col
          span={5}
          style={{
            padding: '24px',
          }}
        >
          <Steps
            direction='vertical'
            current={currentStep}
            onChange={(current) => {
              setCurrentStep(current)
              handleDisplayButton()
            }}
          >
            <Step title={<FormattedMessage id='steps.settings' defaultMessage='Paramètres généraux' />} />
            <Step title={<FormattedMessage id='steps.impactcarbone' defaultMessage='Impact carbone' />} />
            <Step title={<FormattedMessage id='steps.supplier' defaultMessage='Gestion du fournisseur' />} />
            <Step title={<FormattedMessage id='steps.variations' defaultMessage='Création des variations' />} />
            <Step title={<FormattedMessage id='steps.techniques' defaultMessage='Création des techniques' />} />
            <Step title={<FormattedMessage id='steps.images' defaultMessage='Gestion des images' />} />
            <Step title={<FormattedMessage id='steps.summary' defaultMessage='Récapitulatif' />} />
          </Steps>
          <ButtonGroup
            style={{ margin: '10px 0px 10px 0px', width: '100%' }}
          >
            <Button
              type='primary'
              style={{ width: '50%' }}
              disabled={currentStep < 1}
              onClick={() => previousStep()}
            >
              <Icon type='left' />
              <FormattedMessage id='previous' defaultMessage='Précédent' />
            </Button>
            <Button
              type='primary'
              style={{ width: '50%' }}
              disabled={currentStep > 5}
              onClick={() => nextStep()}
            >
              <FormattedMessage id='next' defaultMessage='Suivant' />
              <Icon type='right' />
            </Button>
          </ButtonGroup>
          {displayButtonValidate && (
            <div>
              <Popconfirm
                icon={<Icon style={{ fontSize: '16px', color: '#012169' }} type='info-circle' />}
                title={<FormattedMessage id='lastStep' defaultMessage='Dernière étape !' />}
                onConfirm={() => {
                  if (product.existInDB) {
                    modifyBaseProduct(product)
                  } else {
                    createBaseProduct({
                      ...product,
                      status,
                    })
                  }
                  removeProducts(product.provisionalId)
                  return -1
                }}
                onCancel={() => {
                  if (product.existInDB) {
                    modifyBaseProduct(product)
                  } else {
                    createBaseProduct({
                      ...product,
                      status,
                    })
                  }
                }}
                okText={product.existInDB
                  ? <FormattedMessage id='baseProductForm.modifyAndClose' defaultMessage="Modifier ce produit et fermer l'onglet" />
                  : <FormattedMessage id='createProductAndClose' defaultMessage="Créer ce produit et fermer l'onglet" />}
                cancelText={product.existInDB
                  ? <FormattedMessage id='baseProductForm.modifyProduct' defaultMessage='Modifier ce produit' />
                  : <FormattedMessage id='createProduct' defaultMessage='Créer ce produit' />}
              >
                <Button
                  type='primary'
                  loading={loadingCreation}
                  block
                >
                  {product.existInDB
                    ? <FormattedMessage id='baseProductForm.modifyProduct' defaultMessage='Modifier ce produit' />
                    : <FormattedMessage id='baseProductForm.createProduct' defaultMessage='Créer ce produit' />}
                  <span role='img' aria-label='rocket' style={{ marginLeft: '10px' }}>🚀</span>
                </Button>
              </Popconfirm>
            </div>
          )}
          <div style={{ fontWeight: 'bold', margin: '20px 0px 10px 0px' }}>
            <FormattedMessage id='baseProductForm.whichStatus' defaultMessage='Quel statut ?' />
          </div>
          <div style={{ margin: '0 0 10px 0' }}>
            <Select
              mode='tags'
              style={{ width: '100%' }}
              placeholder={<FormattedMessage id='baseProductForm.addAllStatus' defaultMessage='Ajoutez tous vos statuts...' />}
              value={status}
              onChange={(value) => {
                setStatus(value)
                updateProduct(product.provisionalId, {
                  status: value,
                })
              }}
            >
              <Option key='Brouillon'>
                <FormattedMessage id='draft' defaultMessage='Brouillon' />
              </Option>
              <Option key='Mockup'>
                <FormattedMessage id='mockup' defaultMessage='Mockup' />
              </Option>
              <Option key='A vérifier'>
                <FormattedMessage id='baseProductForm.toCheck' defaultMessage='À vérifier' />
              </Option>
              <Option key='Manque le gabarit'>
                <FormattedMessage id='baseProductForm.template' defaultMessage='Manque le gabarit' />
              </Option>
              <Option key='Shooting'>
                <FormattedMessage id='baseProductForm.shooting' defaultMessage='Shooting' />
              </Option>
              <Option key='Photos fournisseur'>
                <FormattedMessage id='baseProductForm.supplierPicture' defaultMessage='Photos fournisseur' />
              </Option>
              <Option key='Wording'>
                <FormattedMessage id='baseProductForm.wording' defaultMessage='Wording' />
              </Option>
              <Option key='Catalogue interne'>
                <FormattedMessage id='baseProductForm.internalCatalog' defaultMessage='Catalogue interne' />
              </Option>
              <Option key='Shopify'>
                <FormattedMessage id='baseProductForm.shopify' defaultMessage='Shopify' />
              </Option>
              <Option key='à intégrer - Shopify'>
                <FormattedMessage id='baseProductForm.shopifyToInclude' defaultMessage='À intégrer sur Shopify' />
              </Option>
              <Option key='A actualiser sur Shopify'>
                <FormattedMessage id='baseProductForm.shopifyToRefresh' defaultMessage='À actualiser sur Shopify' />
              </Option>
              <Option key='Pastille'>
                <FormattedMessage id='pastille' defaultMessage='Pastille' />
              </Option>
            </Select>
          </div>
          <div style={{ fontWeight: 'bold', marginBottom: '0px' }}>
            <FormattedMessage id='notes' defaultMessage='Notes' />
          </div>
          <div style={{ margin: '0 0 10px 0' }}>
            <Rate
              value={rate}
              onChange={(value) => {
                setRate(value)
                updateProduct(product.provisionalId, {
                  rate: value,
                })
              }}
              character={<Icon type='heart' theme='filled' />}
              allowHalf
            />
          </div>
          <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            <FormattedMessage id='comment' defaultMessage='Commentaire' />
          </div>
          <div style={{ margin: '0 0 10px 0' }}>
            <TextArea
              value={comment}
              onChange={(e) => {
                setComment(e.target.value)
                updateProduct(product.provisionalId, {
                  comment: e.target.value,
                })
              }}
            />
          </div>
        </Col>
        <Col
          span={19}
          style={{
            padding: '24px',
          }}
        >
          {currentStep === 0 && (
            <BaseProductInfoForm
              product={product}
              updateProduct={updateProduct}
              baseProducts={baseProducts}
              products={products}
            />
          )}
          {currentStep === 1 && (
            <BaseProductImpactCarbon
              product={product}
              updateProduct={updateProduct}
            />
          )}
          {currentStep === 2 && (
            <BaseProductProviderForm
              product={product}
              updateProduct={updateProduct}
              allProviders={allProviders}
            />
          )}
          {currentStep === 3 && (
            <BaseProductVariationsForm
              product={product}
              updateProduct={updateProduct}
              colorsFromAWS={colorChips}
              allFamilies={allFamilies}
            />
          )}
          {currentStep === 4 && (
            <BaseProductTechnicsForm
              product={product}
              updateProduct={updateProduct}
              baseProducts={baseProducts}
              allTechnics={allTechnics}
              products={products}
            />
          )}
          {currentStep === 5 && (
            <BaseProductImageForm
              product={product}
              updateProduct={updateProduct}
            />
          )}
          {currentStep === 6 && (
            <BaseProductRecap
              product={product}
              errorCreation={errorCreation}
            />
          )}
        </Col>
      </div>
    )
  } catch (e) {
    console.log('error organisms/BaseProductForm', e)
    return null
  }
}

export default BaseProductForm

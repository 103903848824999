import React from 'react'
import { Layout, PageHeader } from 'antd'
import theme from '../../config/theme'

const { Content } = Layout

export default ({
  title = '',
  subTitle = '',
  extra = [],
  children,
  background = theme.neutralColor[0],
  height = 'calc(100vh - 65px)', // Hauteur du viewport - hauteur du footer
  padding = '0',
  style = {
    padding: '24px', overflow: 'auto', position: 'relative',
  },
}) => (
  <Layout style={{ padding, background, height }}>
    <Content id='pageContent' style={style}>
      <PageHeader
        title={title}
        // breadcrumb={{routes}}
        subTitle={subTitle}
        extra={extra}
        style={{ marginBottom: '48px' }}
      />
      {children}
    </Content>
  </Layout>
)

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import {
  Drawer, Button, message,
} from 'antd'

import {
  fromShops, fromBaseProducts, fromUsers, fromProducts, fromProviders, fromTechnics, fromFamilies, fromCodes, fromEntities, fromLocale, fromPastilles,
} from '../../store/selectors'
import { UPLOAD } from '../../store/uploads/actions'
import { CREATE, SAVE_DRAFT } from '../../store/products/actions'
import { CREATE_STOCK } from '../../store/stocks/actions'
import { CREATE_BASEPRODUCT, MODIFY_BASEPRODUCT } from '../../store/baseProducts/actions'
import useAddProducts from '../../hooks/Products'
import { BpList, ProductsTab } from '../../components/organisms'
import { basicBaseProduct } from '../../helpers/baseProducts/creation'
import { InnerLayout } from '../../layouts'

const ButtonGroup = Button.Group

const Workshop = (props) => {
  try {
    const {
      baseProducts,
      upload,
      createProduct,
      createStock,
      allShops,
      shopId,
      history,
      userInfos,
      createdId,
      createdIdBp,
      modifiedIdBp,
      loadingCreation,
      saveDraft,
      draftProducts,
      createBaseProduct,
      modifyBaseProduct,
      allProviders,
      allTechnics,
      allFamilies,
      allCodes,
      allEntities,
      locale = 'fr',
      allColorChips,
    } = props

    const [visibleBpDrawer, setVisibleBpDrawer] = useState(false)
    const [_draftProducts, setDraftProducts] = useState(draftProducts && draftProducts.length ? draftProducts : [])
    const {
      products,
      pushProducts,
      removeProducts,
      updateProduct,
    } = useAddProducts()

    const [currentProduct, setCurrentProduct] = useState(false)

    if (!currentProduct && products.length) {
      setCurrentProduct(products[0])
    }
    if (_draftProducts && _draftProducts.length) {
      pushProducts(_draftProducts)
      setDraftProducts([])
    }

    return (
      <InnerLayout
        title={userInfos.firstName
          ? <FormattedMessage id='workshop.userName' defaultMessage="L'atelier de {firstName}" values={{ firstName: userInfos.firstName }} />
          : <FormattedMessage id='workshop' defaultMessage='Atelier' />}
        extra={[
          <Button
            key='0'
            type='primary'
            onClick={() => {
              setVisibleBpDrawer(true)
            }}
            style={{ marginLeft: '10px' }}
          >
            <FormattedMessage id='workshop.custom' defaultMessage='Créer un produit custom' />
          </Button>,
          <Button
            key='1'
            type='primary'
            onClick={() => {
              const baseProduct = basicBaseProduct()
              pushProducts([baseProduct])
              return setCurrentProduct({ provisionalId: baseProduct.provisionalId })
            }}
            style={{ marginLeft: '10px' }}
          >
            <FormattedMessage id='workshop.newReference' defaultMessage='Créer une nouvelle référence produit' />
          </Button>,
          <Button
            key='2'
            onClick={() => {
              saveDraft({ draftProducts: products })
              history.push('/catalog')
            }}
            style={{ marginLeft: '10px' }}
          >
            <FormattedMessage id='workshop.catalog' defaultMessage='Voir le catalogue' />
          </Button>,
          <ButtonGroup style={{ marginLeft: '10px' }}>
            <Button
              key='3'
              onClick={() => {
                saveDraft({ draftProducts: products })
                message.success(
                  <FormattedMessage id='saved' defaultMessage='Sauvegardé !' />,
                )
              }}
            >
              <FormattedMessage id='save' defaultMessage='Enregistrer' />
            </Button>
            <Button
              key='3'
              onClick={() => {
                saveDraft({
                  draftProducts: products,
                })
                history.push('/products')
              }}
              icon='close'
            />
          </ButtonGroup>,
        ]}
      >
        <div>
          <Drawer
            title={<FormattedMessage id='workshop.title.productsList' defaultMessage='Liste des produits Panopli' />}
            placement='bottom'
            onClose={() => setVisibleBpDrawer(false)}
            visible={visibleBpDrawer}
            height='100%'
          >
            <BpList
              products={products}
              pushProducts={pushProducts}
              baseProducts={baseProducts}
              shopId={shopId}
              allShops={allShops}
              onClose={() => setVisibleBpDrawer(false)}
              setCurrentProduct={setCurrentProduct}
            />
          </Drawer>
          <ProductsTab
            products={products}
            baseProducts={baseProducts}
            pushProducts={pushProducts}
            removeProducts={removeProducts}
            updateProduct={updateProduct}
            upload={upload}
            createProduct={createProduct}
            createStock={createStock}
            allShops={allShops}
            setVisibleBpDrawer={setVisibleBpDrawer}
            shopId={shopId}
            createdId={createdId}
            createdIdBp={createdIdBp}
            modifiedIdBp={modifiedIdBp}
            history={history}
            loadingCreation={loadingCreation}
            createBaseProduct={createBaseProduct}
            modifyBaseProduct={modifyBaseProduct}
            allProviders={allProviders}
            currentProduct={currentProduct}
            setCurrentProduct={setCurrentProduct}
            allTechnics={allTechnics}
            allFamilies={allFamilies}
            allCodes={allCodes}
            allEntities={allEntities}
            locale={locale}
            colorChips={allColorChips}
          />
        </div>
      </InnerLayout>
    )
  } catch (e) {
    console.log(e, 'containers/Workshop error')
    return null
  }
}

export default connect(
  (state) => ({
    shop: fromShops.getShop(state),
    allShops: fromShops.allShops(state),
    shopId: fromShops.getId(state),
    baseProducts: fromBaseProducts.getCanonBps(state),
    userInfos: fromUsers.getUserInformations(state),
    createdId: fromProducts.getCreatedId(state),
    createdIdBp: fromBaseProducts.getCreatedId(state),
    modifiedIdBp: fromBaseProducts.getModifiedId(state),
    allTechnics: fromTechnics.getAllTechnics(state),
    loadingCreation: fromProducts.loadingCreation(state),
    draftProducts: fromProducts.getDraftProducts(state),
    allProviders: fromProviders.getProviders(state),
    allFamilies: fromFamilies.getAllColorFamilies(state),
    allCodes: fromCodes.getAllCodes(state),
    allEntities: fromEntities.getAllEntities(state),
    allColorChips: fromPastilles.getPastilles(state),
    locale: fromLocale.getLocale(state),
  }), (dispatch) => bindActionCreators({
    upload: (payload) => ({
      type: UPLOAD,
      payload,
    }),
    createProduct: (payload) => ({
      type: CREATE,
      payload,
    }),
    createStock: (payload, callback) => ({
      type: CREATE_STOCK,
      payload,
      callback,
    }),
    modifyBaseProduct: (baseProduct) => ({
      type: MODIFY_BASEPRODUCT,
      payload: { baseProduct },
    }),
    createBaseProduct: (payload) => ({
      type: CREATE_BASEPRODUCT,
      payload,
    }),
    saveDraft: (payload) => ({
      type: SAVE_DRAFT,
      payload,
    }),
  }, dispatch),
)(Workshop)

/* eslint-disable max-len */
import {
  Alert,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  Slider,
} from 'antd'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

const { TextArea } = Input
const { Option } = Select

const ProductInfoForm = (props) => {
  try {
    const {
      product,
      updateProduct,
      allShops,
      shopId,
      allCodes,
    } = props

    const [name, setName] = useState(product.name ? product.name : '')
    const [descriptionFR, setDescriptionFR] = useState(product.displayDescription && product.displayDescription.fr ? product.displayDescription.fr : '')
    const [descriptionEN, setDescriptionEN] = useState(product.displayDescription && product.displayDescription.en ? product.displayDescription.en : '')
    const [price, setPrice] = useState(product?.baseProduct?.newBaseProduct ? 0 : product?.baseProduct?.unitPrice)
    const [margin, setMargin] = useState(product.pricing && product.pricing.margin ? product.pricing.margin : 0)
    const [customizationPrice, setCustomizationPrice] = useState(product.pricing && product.pricing.customizationPrice ? product.pricing.customizationPrice : 0)
    const [favColorReference, setFavColorReference] = useState(product.favColor && product.favColor.reference ? product.favColor.reference : '')
    // eslint-disable-next-line
    const [owner, setOwner] = useState(product.owner ? product.owner : allShops.find((shop) => shop._id === shopId).settings.name)
    const [shops, setShops] = useState(product.shops && product.shops.length ? product.shops : [shopId])
    const [technic, setTechnic] = useState(product.technic || false)
    const [codeLength, setCodeLength] = useState(product.codeLength || 0)
    const [marketValue, setMarketValue] = useState(product.pricing?.marketValue || false)
    const [isShared, setIsShared] = useState(product?.isShared || false)

    const onChangeCode = (value) => {
      setCodeLength(value)
      updateProduct(product.provisionalId, {
        codeLength: value,
      })
    }
    const displayFavColor = () => {
      if (!product.variations || !product.variations.filter((v) => v.type === 'color').length) {
        return (
          <Alert
            message={<FormattedMessage id='productInfoForm.alertMessage.noColorVariation' defaultMessage="Vous n'avez pas encore sélectionné de variations de couleur" />}
            type='warning'
            showIcon
            style={{ marginTop: '10px' }}
          />
        )
      }
      return (
        <div>
          <Select
            defaultValue={favColorReference}
            style={{ width: '100%' }}
            onChange={(value) => {
              setFavColorReference(value)
              updateProduct(product.provisionalId, {
                favColor: product.variations && product.variations.find((v) => v.reference === value),
              })
            }}
          >
            {product.variations.filter((v) => v.type === 'color').map((v) => (
              <Option value={v.reference} key={v.reference}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{
                    width: 10, height: 10, backgroundColor: v.value, marginRight: 10,
                  }}
                  />
                  {`${v.name} (ref: ${v.reference})`}
                </div>
              </Option>
            ))}
          </Select>
        </div>
      )
    }

    const basePrice = () => (price || '-')
    const salePrice = () => (margin || customizationPrice
      ? basePrice() + margin + customizationPrice : basePrice())

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
        <div>
          <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            <FormattedMessage id='lastName' defaultMessage='Nom' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Input
              defaultValue={name}
              onChange={(e) => {
                setName(e.target.value)
                updateProduct(product.provisionalId, {
                  name: e.target.value,
                })
              }}
              style={{ marginBottom: '10px' }}
            />
          </div>
          <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            <FormattedMessage id='productInfoForm.create' defaultMessage='Créer sur quel shop ?' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Select
              showSearch
              style={{ marginBottom: '10px' }}
              defaultValue={shops.length ? allShops.find((shop) => shop._id === shops[0]).settings.name : allShops.find((shop) => shop._id === shopId).settings.name}
              optionFilterProp='settings.name'
              onChange={(value) => {
                setOwner(allShops.find((shop) => shop.settings.name === value).owner)
                setShops([allShops.find((shop) => shop.settings.name === value)._id])
                updateProduct(product.provisionalId, {
                  owner: allShops.find((shop) => shop.settings.name === value).owner,
                  shops: [allShops.find((shop) => shop.settings.name === value)._id],
                  stock: [],
                })
              }}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {allShops.map((shop) => <Option value={shop.settings.name}>{shop.settings.name}</Option>)}
            </Select>
          </div>
          {product.baseProduct?.newBaseProduct && product.baseProduct?.dematerialized ? (
            <>
              <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                <FormattedMessage id='productInfoForm.technique' defaultMessage='Choisir la technique à utiliser' />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Select
                  showSearch
                  style={{ marginBottom: '10px' }}
                  onChange={(value) => {
                    const selectedTechnic = product?.baseProduct?.technics.find((_technic) => _technic.idPanopli === value)
                    setTechnic(selectedTechnic)
                    updateProduct(product.provisionalId, {
                      technic: selectedTechnic,
                    })
                  }}
                  defaultValue={technic.idPanopli}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {product?.baseProduct?.technics.map((_technic) => <Option value={_technic.idPanopli}>{_technic.name}</Option>)}
                </Select>
              </div>
              {product.technic?.idCodes && allCodes.find((code) => code._id === product.technic.idCodes)?.length && (
                <>
                  <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    <FormattedMessage id='productInfoForm.codeQuantity' defaultMessage='Choisir la quantité de code à attribuer' />
                  </div>
                  <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap', marginBottom: '10px',
                  }}
                  >
                    <Slider
                      style={{ width: '100%' }}
                      min={1}
                      max={allCodes.find((code) => code._id === product.technic.idCodes)?.length}
                      onChange={(value) => onChangeCode(value)}
                      value={codeLength}
                      defaultValue={codeLength}
                    />
                    <InputNumber
                      min={1}
                      max={allCodes.find((code) => code._id === product.technic.idCodes)?.length}
                      style={{ marginLeft: 16 }}
                      value={codeLength}
                      defaultValue={codeLength}
                      onChange={(value) => onChangeCode(value)}
                    />
                  </div>
                </>
              )}
            </>
          ) : null}
          <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            <FormattedMessage id='description' defaultMessage='Description' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span role='img' aria-label='FR-flag'>🇫🇷</span>
            <TextArea
              defaultValue={descriptionFR}
              onChange={(e) => {
                setDescriptionFR(e.target.value)
                updateProduct(product.provisionalId, {
                  displayDescription: {
                    ...product.displayDescription,
                    fr: e.target.value,
                  },
                })
              }}
              style={{ marginBottom: '10px' }}
            />
            <span role='img' aria-label='EN-flag'>🇬🇧</span>
            <TextArea
              defaultValue={descriptionEN}
              onChange={(e) => {
                setDescriptionEN(e.target.value)
                updateProduct(product.provisionalId, {
                  displayDescription: {
                    ...product.displayDescription,
                    en: e.target.value,
                  },
                })
              }}
              style={{ marginBottom: '10px' }}
            />
          </div>
          <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            <FormattedMessage id='price' defaultMessage='Prix' />
          </div>
          <Row gutter={16}>
            <Col span={24}>
              <FormattedMessage id='unitPrice' defaultMessage='Prix unitaire' />
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <InputNumber
                  style={{ width: '100%' }}
                  defaultValue={price}
                  min={0}
                  onChange={(value) => {
                    setPrice(value)
                    updateProduct(product.provisionalId, {
                      baseProduct: {
                        ...(product.baseProduct || {}),
                        unitPrice: value,
                      },
                    })
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={20}>
              <FormattedMessage id='margin' defaultMessage='Marge' />
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <InputNumber
                  style={{ width: '100%' }}
                  defaultValue={margin}
                  min={0}
                  onChange={(value) => {
                    setMargin(value)
                    updateProduct(product.provisionalId, {
                      pricing: {
                        ...product.pricing,
                        margin: value,
                      },
                    })
                  }}
                />
              </div>
            </Col>
            <Col span={4}>
              <FormattedMessage id='startingPrice' defaultMessage='Prix de base' />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ marginTop: '6px', marginLeft: '6px' }}>
                  {basePrice()}
                  {' '}
                  €
                </p>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={20}>
              <FormattedMessage id='customizationPrice' defaultMessage='Prix de customisation' />
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <InputNumber
                  style={{ width: '100%' }}
                  defaultValue={customizationPrice}
                  min={0}
                  onChange={(value) => {
                    setCustomizationPrice(value)
                    updateProduct(product.provisionalId, {
                      pricing: {
                        ...product.pricing,
                        customizationPrice: value,
                      },
                    })
                  }}
                />
              </div>
            </Col>
            <Col span={4}>
              <FormattedMessage id='sellingPrice' defaultMessage='Prix de vente' />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ marginTop: '6px', marginLeft: '6px' }}>
                  {salePrice()}
                  {' '}
                  €
                  {' '}
                </p>
              </div>
            </Col>
          </Row>
          <FormattedMessage id='marketValue' defaultMessage='Valeur marchande*' />
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
            <InputNumber
              style={{ width: '100%' }}
              defaultValue={marketValue}
              min={0}
              onChange={(value) => {
                setMarketValue(value)
                updateProduct(product.provisionalId, {
                  pricing: {
                    ...product.pricing,
                    marketValue: value,
                  },
                })
              }}
            />
          </div>
          <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            <FormattedMessage id='productInfoForm.presentationColor' defaultMessage='Choix de la couleur de présentation' />
          </div>
          {displayFavColor()}
          <div style={{ marginBottom: '10px', marginTop: '30px' }}>
            <Checkbox
              onChange={(e) => {
                setIsShared(e.target.checked)
                updateProduct(product.provisionalId, {
                  isShared: e.target.checked,
                })
              }}
              defaultValue={isShared}
            >
              <span style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                <FormattedMessage id='productInfoForm.isShared' defaultMessage='rendre disponible à toutes les boutiques' />
              </span>
            </Checkbox>
          </div>
        </div>
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/ProductInfoForm')
    return null
  }
}

export default ProductInfoForm

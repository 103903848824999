import React from 'react'
import { ContinueStepButton } from '../..'

const CreateCampaignStepButton = (props) => {
  const {
    onClickFunction,
    style, // Required
    type, // Required
    disabled = false,
  } = props

  return (
    <ContinueStepButton
      messageId='campaigns.createCampaign'
      defaultMessage='Créer la campagne'
      onClickFunction={onClickFunction}
      style={style}
      type={type}
      disabled={disabled}
    />
  )
}

export default CreateCampaignStepButton

import React from 'react'
import {
  Form,
  message,
  Button,
  Row,
  InputNumber,
} from 'antd'
import { FormattedMessage } from 'react-intl'

class TVAForm extends React.Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.shopSettings && (prevProps.shopSettings && prevProps.shopSettings.vat) !== (this.props.shopSettings && this.props.shopSettings.vat)) {
  //     this.props.form.resetFields()
  //   }
  // }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
        resetFields,
      },
      modifyShop,
      shop,
    } = this.props
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          vat,
        } = values

        const modifications = {
          settings: {
            ...shop.settings,
            vat,
          },
        }

        modifyShop({
          shop_id: shop._id,
          modifications,
          callback: () => {
            resetFields()

            message.info(
              <FormattedMessage
                id='updateSuccess.shop'
                defaultMessage='Boutique mise à jour'
              />,
            )
            return true
          },
        })
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        isFieldsTouched,
        resetFields,
      },
      shopSettings,
    } = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}

      >
        <Form.Item label={<FormattedMessage id='vat' defaultMessage='TVA' />}>
          {getFieldDecorator('vat', {
            rules: [
            ],
            initialValue: shopSettings.vat || 20,
          })(
            <InputNumber
              placeholder='20%'
              min={0}
              max={50}
              step={0.5}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
            />,
          )}
        </Form.Item>
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  marginRight: '12px',
                }}
              >
                <FormattedMessage id='save' defaultMessage='Enregistrer' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    )
  }
}

export default Form.create({ name: 'shopBasicSettings' })(TVAForm)

/* eslint-disable max-len */
import React, { useState } from 'react'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import {
  message,
  Button,
  DatePicker,
  Checkbox,
  Switch,
  Icon,
  Divider,
  List,
  Card,
  Input,
  Select,
} from 'antd'
import moment from 'moment'
import { displayDate } from '../../../helpers/trim'

const { RangePicker } = DatePicker
const { TextArea } = Input
const { Option } = Select

const ProductCustomizationForm = (props) => {
  try {
    const {
      product,
      updateProduct,
      locale,
    } = props
    let { clientCustomization = [] } = product
    const [preOrder, setPreOrder] = useState(product.preOrder || false)
    // CAREFULL DatePicker need a moment object not a string
    const [startDate, setStartDate] = useState(product?.preOrderInfos?.startDate ? moment(product?.preOrderInfos?.startDate) : moment())
    const [endDate, setEndDate] = useState(moment(product?.preOrderInfos?.endDate || moment().add(30, 'days')))
    const [shippingEstimate, setShippingEstimate] = useState(moment(product?.preOrderInfos?.shippingEstimate || moment().add(30, 'days')))
    const [displayBandOnCustomImage, setDisplayBandOnCustomImage] = useState((product.preOrderInfos && product.preOrderInfos.displayBandOnCustomImage) || false)
    const [displayClientCustomization, setDisplayClientCustomization] = useState((product.clientCustomization && product.clientCustomization.length) || false)
    const [placeholder, setPlaceholder] = useState('')
    const [side, setSide] = useState('')
    const [fieldDescription, setFieldDescription] = useState('')
    const [fieldName, setFieldName] = useState('')
    const [type, setType] = useState('')

    const intl = useIntl()

    const displayButtonPreOrder = () => {
      if (preOrder && startDate && endDate && shippingEstimate) return false
      return true
    }
    const displayButtonClientCustomization = () => {
      if (displayClientCustomization && placeholder && side && fieldDescription && fieldName && type) return false
      return true
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            checkedChildren={<Icon type='check' />}
            unCheckedChildren={<Icon type='close' />}
            onChange={(bool) => {
              setPreOrder(bool)
              if (!bool) {
                setStartDate(displayDate('01/01/2021', locale))
                setEndDate(displayDate('01/01/2021', locale))
                setShippingEstimate(displayDate('01/01/2021', locale))
                setDisplayBandOnCustomImage(false)
                updateProduct(product.provisionalId, {
                  preOrder: false,
                  preOrderInfos: {},
                }, message.success(<FormattedMessage id='productCustomizationForm.preorderDeleted' defaultMessage='Précommande supprimée !' />))
              }
            }}
            defaultChecked={preOrder}
          />
          <div style={{ fontWeight: 'bold', marginLeft: '10px' }}>
            <FormattedMessage id='productCustomizationForm.addPreorder' defaultMessage='Ajouter une précommande ?' />
          </div>
        </div>
        {preOrder && (
          <div style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            <div>
              <FormattedMessage id='productCustomizationForm.preorderDates' defaultMessage='Début / Fin de précommande' />
            </div>
            <RangePicker
              defaultValue={[startDate, endDate]}
              format={locale === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
              onChange={(dates, datesString) => {
                setStartDate(displayDate(datesString[0], locale))
                setEndDate(displayDate(datesString[1], locale))
              }}
              style={{ width: '300px' }}
            />
            <div style={{
              marginTop: '20px',
            }}
            >
              <FormattedMessage id='productCustomizationForm.sendDate' defaultMessage="Date d'envoi" />
            </div>
            <DatePicker
              defaultValue={shippingEstimate}
              format={locale === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
              onChange={(date, dateString) => setShippingEstimate(displayDate(dateString, locale))}
              style={{ width: '300px' }}
            />
            <Checkbox
              style={{ marginTop: '20px' }}
              onChange={() => setDisplayBandOnCustomImage(!displayBandOnCustomImage)}
            >
              <FormattedMessage id='productCustomizationForm.preorderBanner' defaultMessage="Afficher un bandeau précommande sur l'image" />
            </Checkbox>
            <Button
              disabled={displayButtonPreOrder()}
              onClick={() => updateProduct(product.provisionalId, {
                preOrder,
                preOrderInfos: {
                  startDate,
                  endDate,
                  shippingEstimate,
                  displayBandOnCustomImage,
                },
              }, () => message.success(<FormattedMessage id='productCustomizationForm.preorderCreated' defaultMessage='Précommande créée !' />))}
              style={{ width: '300px', marginTop: '10px' }}
            >
              <FormattedMessage id='validate' defaultMessage='Valider' />
            </Button>
          </div>
        )}
        <Divider />
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Switch
            defaultChecked={displayClientCustomization}
            checkedChildren={<Icon type='check' />}
            unCheckedChildren={<Icon type='close' />}
            onChange={(bool) => {
              setDisplayClientCustomization(bool)
              if (!bool) {
                setFieldName('')
                setFieldDescription('')
                setPlaceholder('')
                setSide('')
                setType('')
                updateProduct(product.provisionalId, {
                  clientCustomization: [],
                }, message.success(<FormattedMessage id='productCustomizationForm.customizationDeleted' defaultMessage='Customisation client supprimée !' />))
              }
            }}
          />
          <div style={{ fontWeight: 'bold', marginLeft: '10px' }}>
            <FormattedMessage id='productCustomizationForm.addCustomization' defaultMessage='Ajouter des clients customizations ?' />
          </div>
        </div>
        {displayClientCustomization && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <div>
                <FormattedMessage id='productCustomizationForm.customizationTitle' defaultMessage='Le titre de la section customization' />
              </div>
              <Input
                placeholder={intl.formatMessage({
                  id: 'productCustomizationForm.placeholder.addName',
                  defaultMessage: 'exemple : Rajoutez votre nom',
                })}
                onChange={(e) => setFieldName(e.target.value)}
              />
              <div style={{ marginTop: '20px' }}>
                <FormattedMessage id='productCustomizationForm.description' defaultMessage='La description' />
              </div>
              <TextArea
                autoSize
                placeholder={intl.formatMessage({
                  id: 'productCustomizationForm.placeholder.printingType',
                  defaultMessage: 'exemple : Une impression en fil d\'or',
                })}
                onChange={(e) => setFieldDescription(e.target.value)}
              />
              <div style={{ marginTop: '20px' }}>
                <FormattedMessage id='productCustomizationForm.inputPlaceholder' defaultMessage="Le placeholder de l'input" />
              </div>
              <Input
                placeholder={intl.formatMessage({
                  id: 'productCustomizationForm.placeholder.placeholder',
                  defaultMessage: 'Ceci est un placeholder <-',
                })}
                onChange={(e) => setPlaceholder(e.target.value)}
              />
              <div style={{ marginTop: '20px' }}>
                <FormattedMessage id='productCustomizationForm.customizationLocation' defaultMessage='Où se trouvera la customization ?' />
              </div>
              <Select style={{ width: '100%' }} onChange={(value) => setSide(value)}>
                {Object.keys(product.baseProduct.printingZone).map((pz) => <Option value={pz}>{pz}</Option>)}
              </Select>
              <div style={{ marginTop: '20px' }}>
                <FormattedMessage id='productCustomizationForm.printingType' defaultMessage="Le type d'impression" />
              </div>
              <Select style={{ width: '100%' }} onChange={(value) => setType(value)}>
                <Option value='embrodery'>
                  <FormattedMessage id='embroidery' defaultMessage='Broderie' />
                </Option>
                <Option value='typo'>
                  <FormattedMessage id='typography' defaultMessage='Typographie' />
                </Option>
                <Option value='image'>
                  <FormattedMessage id='image' defaultMessage='Image' />
                </Option>
              </Select>
              <Button
                disabled={displayButtonClientCustomization()}
                onClick={() => {
                  clientCustomization.push({
                    id: Math.random().toString(36).substring(7),
                    placeholder,
                    side,
                    fieldDescription,
                    fieldName,
                    type,
                  })
                  return updateProduct(product.provisionalId, { clientCustomization }, message.success(<FormattedMessage id='productCustomizationForm.customizationCreated' defaultMessage='Customization client créée !' />))
                }}
                style={{ width: '300px', marginTop: '10px' }}
              >
                <FormattedMessage id='add' defaultMessage='Ajouter' />
              </Button>
            </div>
            <div style={{ marginLeft: '20px', width: '100%' }}>
              <List
                grid={{ gutter: 16, column: 2 }}
                dataSource={clientCustomization}
                renderItem={(item) => (
                  <List.Item>
                    <Card
                      key={item.id}
                      extra={(
                        <Button
                          type='link'
                          onClick={() => {
                            clientCustomization = clientCustomization.filter((cc) => cc.id !== item.id)
                            updateProduct(product.provisionalId, {
                              clientCustomization: clientCustomization.filter((cc) => cc.id !== item.id),
                            }, message.success(<FormattedMessage id='productCustomizationForm.customizationDeleted' defaultMessage='Customisation client supprimée !' />))
                          }}
                        >
                          <FormattedMessage id='delete' defaultMessage='Supprimer' />
                        </Button>
                      )}
                    >
                      <Input
                        addonBefore={intl.formatMessage({
                          id: 'productCustomizationForm.addonBefore.title',
                          defaultMessage: 'Titre',
                        })}
                        style={{ marginTop: '5px' }}
                        defaultValue={item.fieldName}
                        disabled
                      />
                      <TextArea
                        autoSize
                        style={{ marginTop: '5px' }}
                        defaultValue={item.fieldDescription}
                        disabled
                      />
                      <Input
                        addonBefore='Placeholder'
                        style={{ marginTop: '5px' }}
                        defaultValue={item.placeholder}
                        disabled
                      />
                      <Input
                        addonBefore={intl.formatMessage({
                          id: 'productCustomizationForm.addonBefore.side',
                          defaultMessage: 'Coté',
                        })}
                        style={{ marginTop: '5px' }}
                        defaultValue={item.side}
                        disabled
                      />
                      <Input
                        addonBefore={intl.formatMessage({
                          id: 'productCustomizationForm.addonBefore.type',
                          defaultMessage: 'Type',
                        })}
                        style={{ marginTop: '5px' }}
                        value={item.type}
                        disabled
                      />
                    </Card>
                  </List.Item>
                )}
              />
            </div>
          </div>
        )}
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/ProductStocksSheet')
    return null
  }
}

export default injectIntl(ProductCustomizationForm)

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { faCubes } from '@fortawesome/free-solid-svg-icons'

import {
  DataBlockNPS, DataBlockSentMagicLinks, DataBlockSpentMagicLinks, DataBlock,
} from '../..'
import { isAShopOwner, isPanopliAdmin } from '../../../helpers/users'

const MagicLinksDashboardStats = (props) => {
  try {
    const {
      campaigns, magicLinks, shop, user,
    } = props

    return (
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '16px', width: '100%',
      }}
      >
        <div style={{
          display: 'flex', gap: '16px', width: '100%',
        }}
        >
          <DataBlock
            title={(
              <FormattedMessage
                id='magicLinks.dashboard.createdCampaigns'
                defaultMessage='Campagnes créées'
              />
            )}
            value={campaigns?.length || 'N/C'}
            icon={faCubes}
          />
          {(isAShopOwner(user, shop) || isPanopliAdmin(user))
            ? <DataBlockNPS npsSource='SHOP' shopId={shop._id} />
            : <DataBlockNPS npsSource='ENTITY' entityId={user.entityId} />}
        </div>
        <div style={{
          display: 'flex', gap: '16px', width: '100%',
        }}
        >
          <DataBlockSpentMagicLinks magicLinks={magicLinks} />
          <DataBlockSentMagicLinks magicLinks={magicLinks} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecules/MagicLinksDashboardStats error:', e)
    return null
  }
}

export default MagicLinksDashboardStats

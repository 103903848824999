// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import {
  put, call,
  select,
  takeLatest,
} from 'redux-saga/effects'
import {
  fromUsers,
} from '../selectors'

import * as actions from './actions'

const { getAuth } = fromUsers

export function* getFamilies(api) {
  try {
    const auth = yield select(getAuth)
    const shopifyFamilies = yield call([api, api.get],
      '/shopify', {
        headers: {
          authorization: auth, colors: true,
        },
      })
    const { colors } = shopifyFamilies
    yield put({ type: actions.RECEIVED_FAMILIES, payload: { data: colors } })
    return 1
  } catch (e) {
    console.log('getFamilies error', e)
    return -1
  }
}

export default function* ({ api }) {
  yield takeLatest(actions.GET_FAMILIES, getFamilies, api)
}

import { Button, Select } from 'antd'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import theme from '../../../config/theme'

const { Option } = Select

const MagicLinkSelectFilter = (props) => {
  const {
    options, // Required
    title, // Required
    onChange, // Required
    placeholder, // Required
    onResetFilter, // Required
    selectedValues, // Required
  } = props

  const [isOptionSelected, setIsOptionSelected] = useState(false)

  return (
    <div
      style={{
        display: 'flex', flex: 1, flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <h4 style={{ marginBottom: '8px' }}>
          {title}
        </h4>
        {isOptionSelected && (
          <Button
            type='link'
            style={{
              color: theme.primaryColor[6],
              display: 'block',
              padding: 0,
              height: 'auto',
            }}
            onClick={(event) => {
              onResetFilter(event)
              setIsOptionSelected(false)
            }}
          >
            <FormattedMessage
              id='magiclink.filters.reset'
              defaultMessage='Réinitialiser'
            />
          </Button>
        )}
      </div>
      <Select
        mode='multiple'
        showArrow
        allowClear
        style={{ minWidth: '220px', flex: '1 8' }}
        onChange={(value) => {
          onChange(value)
          setIsOptionSelected(value?.length > 0)
        }}
        placeholder={(
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center',
          }}
          >
            {placeholder}
          </div>
        )}
        defaultValue={[]}
        value={selectedValues}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default MagicLinkSelectFilter

import React from 'react'
import {
  Button,
  Icon,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import getPrice from '../../../helpers/getPrice'

const filters = [
  {
    type: 'creation',
    direction: 'asc',
    func: (a, b) => {
      const d1 = new Date(a.createdAt)
      const d2 = new Date(b.createdAt)

      if (d1 > d2) {
        return 1
      }
      return -1
    },
  },
  {
    type: 'creation',
    direction: 'desc',
    func: (a, b) => {
      const d1 = new Date(a.createdAt)
      const d2 = new Date(b.createdAt)

      if (d1 < d2) {
        return 1
      }
      return -1
    },
  },
  {
    type: 'update',
    direction: 'asc',
    func: (a, b) => {
      const d1 = new Date(a.updatedAt)
      const d2 = new Date(b.updatedAt)

      if (d1 > d2) {
        return 1
      }
      return -1
    },
  },
  {
    type: 'update',
    direction: 'desc',
    func: (a, b) => {
      const d1 = new Date(a.updatedAt)
      const d2 = new Date(b.updatedAt)

      if (d1 < d2) {
        return 1
      }
      return -1
    },
  },
  {
    type: 'price',
    direction: 'asc',
    func: (a, b) => {
      const p1 = getPrice({}, a).value
      const p2 = getPrice({}, b).value
      if (p1 < p2) {
        return 1
      }
      return -1
    },
  },
  {
    type: 'price',
    direction: 'desc',
    func: (a, b) => {
      const p1 = getPrice({}, a).value
      const p2 = getPrice({}, b).value
      if (p1 < p2) {
        return 1
      }
      return -1
    },
  },
  {
    type: 'popularity',
    direction: 'asc',
    func: (a, b) => {
      const p1 = a.timesOrdered
      const p2 = b.timesOrdered
      if (p1 < p2) {
        return 1
      }
      return -1
    },
  },
  {
    type: 'popularity',
    direction: 'desc',
    func: (a, b) => {
      const p1 = a.timesOrdered
      const p2 = b.timesOrdered
      if (p1 > p2) {
        return 1
      }
      return -1
    },
  },
]

const ButtonLabel = ({ type }) => {
  switch (type) {
  case 'price':
    return (
      <FormattedMessage
        id='filters.price'
        defaultMessage='Prix'
      />
    )
  case 'creation':
    return (
      <FormattedMessage
        id='creationDate'
        defaultMessage='Date de création'
      />
    )
  case 'update':
    return (
      <FormattedMessage
        id='filters.update'
        defaultMessage='Date de modification'
      />
    )
  case 'popularity':
    return (
      <FormattedMessage
        id='filters.popularity'
        defaultMessage='Popularité'
      />
    )
  default:
    return (
      <FormattedMessage
        id='filters.default'
        defaultMessage='Filtre'
      />
    )
  }
}

const FilterButton = ({
  type,
  directionRef,
  currentType,
  onClick,
}) => {
  try {
    return (
      <Button
        block
        onClick={() => onClick(type)}
        style={{
          marginBottom: '5px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <ButtonLabel type={type} />
        <Icon
          type={
            directionRef === 'desc' && currentType === type ? 'down' : 'up'
          }
          style={{ marginLeft: '5px', marginTop: '4px' }}
        />
      </Button>
    )
  } catch (error) {
    console.log('OrganizationFilters - FilterButton', { error })
    return null
  }
}

class OrganizationFilters extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: null,
      direction: 'asc',
    }
  }

  onChange = (newType) => {
    try {
      const {
        setListingOrder,
        items,
      } = this.props

      const {
        type,
        direction,
      } = this.state

      let newDirection = 'asc'
      if (newType === type) {
        newDirection = direction === 'asc' ? 'desc' : 'asc'
      }
      const filterObject = filters.find((f) => f.type === newType && f.direction === newDirection).func

      const newListingOrder = items.sort(filterObject).map((item) => item._id || item.id)
      this.setState({
        type: newType,
        direction: newDirection,
      })
      setListingOrder(newListingOrder)
      return true
    } catch (error) {
      console.log('OrganizationGrid - Cannot sort items', { error })
      return []
    }
  }

  render() {
    try {
      const {
        type,
        direction,
      } = this.state

      return (
        <>
          <FilterButton
            type='price'
            currentType={type}
            directionRef={direction}
            onClick={this.onChange}
          />
          <FilterButton
            type='creation'
            currentType={type}
            directionRef={direction}
            onClick={this.onChange}
          />
          <FilterButton
            type='update'
            currentType={type}
            directionRef={direction}
            onClick={this.onChange}
          />
          <FilterButton
            type='popularity'
            currentType={type}
            directionRef={direction}
            onClick={this.onChange}
          />
        </>

      )
    } catch (error) {
      console.log('OrganizationGrid - render', { error })
      return null
    }
  }
}

export default OrganizationFilters

import React from 'react'
import { Icon } from 'antd'
import { FormattedMessage } from 'react-intl'

export default ({
  content = '',
}) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EAEAEA',
    padding: '10px',
    margin: '0 0 10px 0',
    borderRadius: '5px',
  }}
  >
    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
      <Icon type='info-circle' style={{ marginRight: '5px' }} />
      <FormattedMessage id='subscriptionWarning.helper' defaultMessage='Ce service comprend un abonnement' />
    </span>
    <span>
      {content}
    </span>
  </div>
)

import React from 'react'
import { faPalette, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import {
  StepCardForm,
  StepInputLabel,
  PopoverMainColorPicker,
  PopoverFontColorPicker,
  CampaignFontSelect,
  MovingStepButton,
  CampaignLogoInput,
  PreviewContainer,
  ThemePreview,
} from '../..'

const CampaignCreationTheme = (props) => {
  const {
    campaign, // Required
    setCampaign, // Required
    moveNextStep,
    movePreviousStep,
    setNeedUpdateCampaign, // Required
    isDisabled = false,
    uploadImage, // Required
    createdCampaignId, // Required
  } = props

  const computeColor = (color) => `rgba(${color.r},${color.g},${color.b},${color.a})`
  const setMainColor = (newMainColor) => {
    setCampaign({ ...campaign, theme: { ...campaign?.theme, mainColor: computeColor(newMainColor.rgb) } })
  }
  const setFontColor = (newFontColor) => {
    setCampaign({ ...campaign, theme: { ...campaign?.theme, fontColor: computeColor(newFontColor.rgb) } })
  }
  const setFont = (newFont) => {
    setCampaign({ ...campaign, theme: { ...campaign?.theme, font: newFont } })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
      <StepCardForm
        avatarIcon={faPalette}
        titleMessageId='campaigns.create.step.theme.header'
        titleDefaultMessage='Thème de la campagne'
        descriptionMessageId='campaigns.create.step.theme.header.description'
        descriptionDefaultMessage='Personalisez le thème de votre campagne'
        isDisabled={isDisabled}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CampaignLogoInput
              campaign={campaign}
              setCampaign={setCampaign}
              setNeedUpdateCampaign={setNeedUpdateCampaign}
              isDisabled={isDisabled}
              uploadImage={uploadImage}
              createdCampaignId={createdCampaignId}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '32px' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '8px',
            }}
            >
              <StepInputLabel
                title={<FormattedMessage id='colors' defaultMessage='Couleurs' />}
                tooltipText={(
                  <FormattedMessage
                    id='campaigns.create.step.theme.colors.tooltip'
                    defaultMessage='Créez votre palette en choisissant une couleur principale et une secondaire'
                  />
                )}
                icon={faCircleInfo}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <PopoverMainColorPicker
                setMainColor={setMainColor}
                mainColor={campaign?.theme?.mainColor}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                isDisabled={isDisabled}
              />
              <PopoverFontColorPicker
                fontColor={campaign?.theme?.fontColor}
                setFontColor={setFontColor}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                isDisabled={isDisabled}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '32px' }}>
            <CampaignFontSelect
              selectedFontName={campaign?.theme?.font}
              setFont={setFont}
              setNeedUpdateCampaign={setNeedUpdateCampaign}
              isDisabled={isDisabled}
            />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', marginTop: '96px', justifyContent: 'space-between',
          }}
          >
            {moveNextStep && movePreviousStep && (
              <MovingStepButton
                moveNextStep={moveNextStep}
                isContinueDisabled={
                  !campaign?.theme?.font
                || !campaign?.theme?.logo
                || !campaign?.theme?.mainColor
                || !campaign?.theme?.fontColor
                }
                movePreviousStep={movePreviousStep}
              />
            )}
          </div>
        </div>
      </StepCardForm>
      <PreviewContainer>
        <ThemePreview campaign={campaign} />
      </PreviewContainer>
    </div>
  )
}

export default CampaignCreationTheme

import React, { useState, useEffect } from 'react'
import { Select } from 'antd'

const { Option } = Select

const SearchBar = (props) => {
  const {
    onSearch, onSelect, style, initialData,
  } = props
  const [inputValue, setInputValue] = useState(undefined)
  const [options, setOptions] = useState([])

  const handleSearch = async (value) => {
    if (value) {
      const result = await onSearch(value)
      setOptions(result)
    } else {
      setOptions([])
    }
  }

  const displayedOptions = (inputValue ? options : initialData).map((deal) => {
    const { properties: { dealname }, id } = deal
    return (
      <Option value={`${id}-${dealname}`} key={id}>
        <span style={{ fontWeight: 'bold' }}>
          {`${id}`}
        </span>
        <span>
          {' - '}
        </span>
        <span style={{ fontStyle: 'italic ' }}>
          {`${dealname}`}
        </span>

      </Option>
    )
  })
  // On met du delay sur le traitement de l'input pour limiter le nombre de requête, on enverra la requête 800 ms sans nouvel input
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(inputValue)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [inputValue])

  // on Map sur le state options pour générer les choix du sélecteur , le state sera hydraté au fur et à mesure de la recherche

  return (
    <Select
      showSearch
      value={inputValue}
      style={style}
      placeholder='Cherchez votre deal par Id ou par nom'
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSelect={(deal) => {
        onSelect(deal)
        setInputValue(undefined)
      }}
      onSearch={(value) => {
        setInputValue(value)
      }}
      notFoundContent='Aucun deal trouvé'
    >
      {displayedOptions}
    </Select>
  )
}

export default SearchBar

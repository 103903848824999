// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
import { initialState } from './selectors'
import {
  UPLOADING,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  UPLOAD,
} from './actions'

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case UPLOAD:
    return {
      ...state,
      uploading: true,
    }
  case UPLOADING:
    return {
      ...state,
      uploading: true,
    }
  case UPLOAD_SUCCESS:
    return {
      ...state,
      uploading: false,
    }
  case UPLOAD_ERROR:
    return {
      ...state,
      error: payload.error,
      uploading: false,
    }
  default:
    return state
  }
}

import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import {
  Button,
} from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { InnerLayout } from '../../layouts'
import { extractParams, writeUrl } from '../../helpers/urlHelpers'
import { INIT_FETCH_DATA } from '../../store/members/actions'
import {
  Members,
  DetailedMember,
  MemberCreation,
  MemberEdition,
  RatioDrawer,
  AddCreditDrawer,
  DefineEntityDrawer,
  GroupsDrawer,
  RulesDrawer,
} from '../../containers'
import {
  fromMembers, fromEntities,
} from '../../store/selectors'
import { Refresher, Loader } from '../../components'

const initialState = {
  visibleDrawerMember: false,
  visibleDrawerOrder: false,
  visibleDrawerCreation: false,
  visibleDrawerRatio: false,
  visibleDrawerAddCredit: false,
  visibleDrawerDefineEntity: false,
  visibleDrawerGroups: false,
  visibleDrawerRules: false,
  visibleDrawerEdit: false,
  editMember: false,
  detailedOrder: null,
  selectedRowKeys: [],
  filters: {},
}

const MembersPage = (props) => {
  const {
    location: { search }, shopId, fetchData, history, isReady, entities, membersLength, allMembers,
  } = props
  const query = extractParams(search)
  const composedFilters = { ...initialState.filters, ...query }
  const [membersPageState, setMembersPageState] = useState({
    ...initialState,
    filters: composedFilters,
  })

  useEffect(() => {
    history.push(writeUrl(membersPageState.filters))
  }, [membersPageState.filters])

  useEffect(() => {
    fetchData()
  }, [shopId])

  const resetFiltering = () => {
    setMembersPageState({
      ...membersPageState,
      filters: {},
    })
  }

  const hasFilters = () => {
    const { filters } = membersPageState
    return !!Object.values(filters).filter(Boolean).length
  }

  const setFiltering = (object) => {
    setMembersPageState({
      ...membersPageState,
      filters: {
        ...membersPageState.filters,
        ...object,
      },
    })
  }

  const onSelectChange = (selectedData) => {
    setMembersPageState({
      ...membersPageState,
      selectedRowKeys: selectedData.map((member) => member._id),
    })
  }
  const onAddCreditIconClick = (member) => {
    const selected = [String(member._id)]
    setMembersPageState({
      ...membersPageState,
      selectedRowKeys: selected,
      visibleDrawerAddCredit: true,
    })
  }

  const actions = () => {
    const { selectedRowKeys } = membersPageState
    const defaultActions = [
      hasFilters() ? (
        <Button
          key='reset'
          onClick={resetFiltering}
          style={{ marginBottom: '8px' }}
        >
          <FormattedMessage id='resetFilters' defaultMessage='Réinitialiser les filtres' />
        </Button>
      ) : null,
      <Button
        key='1'
        type='primary'
        onClick={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerCreation: true,
          })
        }}
      >
        <FormattedMessage
          id='members.addMembers'
          defaultMessage='Ajouter des membres'
        />
      </Button>,
      <Button
        key='2'
        type='default'
        onClick={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerRatio: true,
          })
        }}
      >
        <FormattedMessage
          id='members.ratio'
          defaultMessage='Définir ratio'
        />
      </Button>,
      <Button
        key='3'
        type='default'
        onClick={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerGroups: true,
          })
        }}
      >
        <FormattedMessage
          id='members.groupsManagment'
          defaultMessage='Gérer mes groupes'
        />
      </Button>,
      <Button
        key='4'
        type='default'
        onClick={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerRules: true,
          })
        }}
      >
        <FormattedMessage
          id='members.rulesManagment'
          defaultMessage='Gérer mes règles'
        />
      </Button>,
    ]
    if (selectedRowKeys.length) {
      defaultActions.push(
        <Button
          key='5'
          type='default'
          style={{ marginBottom: '8px' }}
          disabled={!selectedRowKeys.length}
          onClick={() => {
            setMembersPageState({
              ...membersPageState,
              visibleDrawerAddCredit: true,
            })
          }}
        >
          <FormattedMessage
            id='members.addCredits'
            defaultMessage='Ajouter des crédits'
          />
        </Button>,
      )
      if (entities) {
        defaultActions.push(
          <Button
            key='6'
            type='default'
            style={{ marginBottom: '8px' }}
            disabled={!selectedRowKeys.length}
            onClick={() => {
              setMembersPageState({
                ...membersPageState,
                visibleDrawerDefineEntity: true,
              })
            }}
          >
            <FormattedMessage
              id='members.entityAssignment'
              defaultMessage="Assigner à l'entité"
            />
          </Button>,
        )
      }
    }
    return defaultActions.filter(Boolean)
  }

  const {
    detailedMember,
    editMember,
    visibleDrawerMember,
    visibleDrawerOrder,
    visibleDrawerCreation,
    detailedOrder,
    selectedRowKeys,
    visibleDrawerRatio,
    visibleDrawerAddCredit,
    visibleDrawerDefineEntity,
    visibleDrawerGroups,
    visibleDrawerRules,
    visibleDrawerEdit,
    filters,
  } = membersPageState

  if (!isReady) {
    return (
      <Loader />
    )
  }
  return (
    <InnerLayout
      title={(
        <div style={{
          fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
        }}
        >
          <FormattedMessage
            id='orders.pageTitle'
            defaultMessage='Membres : {number}'
            values={{ number: membersLength }}
          />
        </div>
      )}
      extra={actions()}
    >
      <Refresher fetchData={fetchData} />
      <Members
        onSelectChange={onSelectChange}
        selectedRowKeys={selectedRowKeys}
        filtering={setFiltering}
        filters={filters}
        displayDrawer={(member) => {
          setMembersPageState({
            ...membersPageState,
            detailedMember: member,
            visibleDrawerMember: true,
          })
        }}
        displayDrawerEdit={(member) => {
          setMembersPageState({
            ...membersPageState,
            editMember: member,
            visibleDrawerEdit: true,
          })
        }}
        onAddCreditIconClick={onAddCreditIconClick}
      />
      <DetailedMember
        displayDrawerOrder={(order) => {
          setMembersPageState({
            ...membersPageState,
            detailedOrder: order,
            visibleDrawerOrder: true,
          })
        }}
        detailedMember={detailedMember}
        visibleDrawer={visibleDrawerMember}
        detailedOrder={detailedOrder}
        visibleDrawerOrder={visibleDrawerOrder}
        hideDrawer={() => {
          setMembersPageState({
            ...membersPageState,
            detailedMember: false,
            visibleDrawerMember: false,
          })
        }}
        hideDrawerOrder={() => {
          setMembersPageState({
            ...membersPageState,
            detailedOrder: null,
            visibleDrawerOrder: false,
          })
        }}
      />
      <MemberCreation
        visibleDrawer={visibleDrawerCreation}
        hideDrawer={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerCreation: false,
          })
        }}
      />
      <MemberEdition
        member={editMember}
        visibleDrawer={visibleDrawerEdit}
        hideDrawer={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerEdit: false,
            editMember: false,
          })
        }}
      />
      <RatioDrawer
        visibleDrawer={visibleDrawerRatio}
        hideDrawer={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerRatio: false,
          })
        }}
      />
      <AddCreditDrawer
        visibleDrawer={visibleDrawerAddCredit}
        hideDrawer={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerAddCredit: false,
          })
        }}
        selectedRowKeys={selectedRowKeys}
        onAddCreditIconClick={onAddCreditIconClick}
        allMembers={allMembers}
      />
      <DefineEntityDrawer
        visibleDrawer={visibleDrawerDefineEntity}
        hideDrawer={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerDefineEntity: false,
          })
        }}
        selectedRowKeys={selectedRowKeys}
      />
      <GroupsDrawer
        visibleDrawer={visibleDrawerGroups}
        hideDrawer={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerGroups: false,
          })
        }}
      />
      <RulesDrawer
        visibleDrawer={visibleDrawerRules}
        hideDrawer={() => {
          setMembersPageState({
            ...membersPageState,
            visibleDrawerRules: false,
          })
        }}
      />
    </InnerLayout>
  )
}

const mapStateToProps = (state) => ({
  isReady: fromMembers.isMembersPageReady(state),
  entities: fromEntities.getEntities(state),
  membersLength: fromMembers.getMembersLength(state),
  allMembers: fromMembers.getMembers(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MembersPage))

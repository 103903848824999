import React from 'react'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'

const dateFormatList = ['DD/MM/YYYY', 'MM/DD/YYYY']

const MagicLinksParamsDatePicker = (props) => {
  const {
    date, // Required
    onChange, // Required
    locale, // Required
    style,
    allowToday = false,
  } = props

  const format = locale === 'en' ? dateFormatList[1] : dateFormatList[0]
  const disabled = (current) => (allowToday ? current < dayjs().subtract(1, 'day') : current < dayjs())

  return (
    <DatePicker
      defaultValue={date}
      onChange={onChange}
      style={style}
      format={format}
      allowClear={false}
      showToday={allowToday}
      disabledDate={disabled}
    />
  )
}

export default MagicLinksParamsDatePicker

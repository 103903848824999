import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  InputNumber,
  message,
  Form,
  Button,
  Typography,
  Switch,
} from 'antd'

const { Item } = Form
const { Title } = Typography

class RatioMembership extends React.Component {
  handleSubmit = (e) => {
    const {
      modifyShop,
      shop,
      form: {
        resetFields,
        validateFieldsAndScroll,
      },
      modifyMembersRatio,
      allMembers,
    } = this.props
    e.preventDefault()
    validateFieldsAndScroll((err, _values) => {
      let newRules = shop?.settings?.rules
      let data
      const values = _values
      if (!err) {
        const {
          ratio,
          modifyCredit,
        } = values
        const creditConversion = shop?.settings?.membership?.currency?.creditConversion
        if (creditConversion && modifyCredit) {
          if (shop?.settings?.rules?.length) {
            newRules = shop.settings.rules.map((rule) => ({
              ...rule,
              credit: (parseFloat(rule.credit) / parseFloat(creditConversion)) * parseFloat(ratio),
            }))
          }
          data = {
            membersIds: allMembers.map((member) => member._id),
            newRatio: ratio,
            shopId: shop._id,
          }
        }
        const modifications = {
          settings: {
            ...shop?.settings,
            rules: newRules,
            membership: {
              ...shop?.settings?.membership,
              currency: {
                ...shop?.settings?.membership?.currency,
                creditConversion: ratio,
              },
            },
          },
        }
        modifyShop({
          shop_id: shop._id,
          modifications,
          callback: () => {
            resetFields()
            message.info(<FormattedMessage id='updateSuccess.ratio' defaultMessage='Ratio mis à jour' />)
            return true
          },
        })
        modifyMembersRatio({ data })
      }
    })
  }

  render() {
    const {
      shop,
      form: {
        getFieldDecorator,
        isFieldTouched,
      },
      display = false,
      page,
    } = this.props
    if (display) return false
    if (page === 'listing') {
      return (
        <Form
          id='ratioForm'
          onSubmit={this.handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            backgroundColor: '#99A6C3',
            margin: '10px',
            padding: '20px',
            borderRadius: '5px',
            position: 'relative',
          }}
        >
          <Title level={4} style={{ textAlign: 'center' }}>
            <FormattedMessage
              id='ratioMemberShip.helper1'
              defaultMessage='Veuillez définir le ratio appliqué sur les prix de la boutique pour les membres'
            />
          </Title>
          <div style={{ fontWeight: 'bold' }}>
            <FormattedMessage
              id='ratioMemberShip.helper2'
              defaultMessage='Ratio x Prix Produit = Prix en crédit'
            />
          </div>
          <Item label={<FormattedMessage id='ratio' defaultMessage='Ratio' />} style={{ marginBottom: 0 }}>
            {getFieldDecorator('ratio', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='ratioMemberShip.required.ratio' defaultMessage='Choisissez un ratio' />,
                },
              ],
              initialValue: shop.settings.membership && shop.settings.membership.currency
              && shop.settings.membership.currency.creditConversion,
            })(<InputNumber size='default' />)}
          </Item>
          {isFieldTouched('ratio') ? (
            <Button
              type='primary'
              htmlType='submit'
              style={{ marginTop: 10 }}
            >
              <FormattedMessage id='save' defaultMessage='Enregistrer' />
            </Button>
          ) : null}
        </Form>
      )
    }
    return (
      <Form
        id='ratioForm'
        onSubmit={this.handleSubmit}
        style={{ position: 'relative' }}
      >
        <Title level={4} style={{ textAlign: 'center' }}>
          <FormattedMessage
            id='ratioMemberShip.helper3'
            defaultMessage='Ratio x Prix Produit = Prix en crédit'
          />
        </Title>
        <Item label={<FormattedMessage id='ratio' defaultMessage='Ratio' />}>
          {getFieldDecorator('ratio', {
            rules: [
              {
                required: true,
                message: <FormattedMessage id='ratioMemberShip.required.ratio' defaultMessage='Choisissez un ratio' />,
              },
            ],
            initialValue: shop.settings.membership && shop.settings.membership.currency
            && shop.settings.membership.currency.creditConversion,
          })(<InputNumber size='default' />)}
        </Item>
        {
          shop.settings.membership && shop.settings.membership.currency
          && shop.settings.membership.currency.creditConversion
            ? (
              <Item label={(
                <FormattedMessage
                  id='ratioMemberShip.helper4'
                  defaultMessage='Modifier les crédits des règles et des membres'
                />
              )}
              >
                {getFieldDecorator('modifyCredit', { valuePropName: 'checked' })(<Switch />)}
              </Item>
            ) : false
        }
        {isFieldTouched('ratio') ? (
          <Button
            type='primary'
            htmlType='submit'
          >
            <FormattedMessage id='save' defaultMessage='Enregistrer' />
          </Button>
        ) : null}
      </Form>
    )
  }
}

export default Form.create({ name: 'ratioForm' })(RatioMembership)

import React, { useState } from 'react'
import { Drawer } from 'antd'
import { FormattedMessage } from 'react-intl'
import dayjs from 'dayjs'

import {
  MagicLinksSendingParameters,
  MagicLinksSendingMails,
  MagicLinksSendingMessage,
  FadingTransition,
  DrawerTitle,
  MailsRecap,
} from '../..'

import {
  isParametersNextStepDisabled,
  isSendingMailsNextDisabled,
  STEP_DRAWER_PARAMS,
  STEP_DRAWER_MAILS,
  STEP_DRAWER_MESSAGE,
  STEP_DRAWER_MANUALLY,
} from '../../../helpers/magicLinks'

const MagicLinksSendingDrawer = (props) => {
  try {
    const {
      visible,
      setVisible,
      email,
      testMail,
      campaigns,
      currentCampaignId,
      setCurrentCampaignId,
      campaignSelection,
      magicLinks,
      sendTestEmail,
      locale,
      // budget,
      createMagicLinks,
    } = props

    const [mails, setMails] = useState([])
    const [selection, setSelection] = useState([])
    const [step, setStep] = useState(STEP_DRAWER_PARAMS)
    const [subpart, setSubpart] = useState(STEP_DRAWER_MANUALLY)
    const [campaignParams, setCampaignParams] = useState({
      testMail,
      expirationDate: dayjs().add(1, 'month'),
      sendDate: dayjs(),
    })

    const { expirationDate, sendDate } = campaignParams

    // Récupération de la campagne courrante
    const currentCampaign = campaigns?.find((campaign) => campaign?._id === currentCampaignId) || {}
    // Récupération de l'objet du mail de campagne
    const { template = {} } = currentCampaign
    const { title } = template

    // Logiques de blocage des étapes
    const parametersNextDisabled = isParametersNextStepDisabled(currentCampaignId, sendDate, expirationDate)
    const sendingMailsNextDisabled = isSendingMailsNextDisabled(mails, subpart)

    const onClose = () => {
      setMails([])
      setStep(STEP_DRAWER_PARAMS)
      setSubpart(STEP_DRAWER_MANUALLY)
      setSelection([])
      setVisible(false)
    }

    const handleSending = async () => {
      await createMagicLinks({
        campaignId: currentCampaignId,
        newMagicLinks: mails,
        lifetime: expirationDate.diff(sendDate, 'day'),
        sentAt: new Date(),
      })
      onClose()
    }

    const displayTitle = () => {
      switch (step) {
      case STEP_DRAWER_PARAMS:
      case STEP_DRAWER_MAILS:
        return (
          <DrawerTitle
            title={<FormattedMessage id='magicLink.send' defaultMessage='Envoyer des Magic Links' />}
            subtitle={(
              <FormattedMessage
                id='magicLink.send.subTitle'
                defaultMessage='Les destinataires recevront l’emailing et pourront découvrir l’expérience Magic Link'
              />
            )}
            onClose={onClose}
            style={{ padding: '0 2px', margin: 0 }}
          />
        )
      case STEP_DRAWER_MESSAGE:
        return (
          <DrawerTitle
            title={<FormattedMessage id='customizeMessage' defaultMessage='Personnaliser le message' />}
            subtitle={(
              <FormattedMessage
                id='magicLink.customizeMessage.subTitle'
                defaultMessage='Personnalisez le message pour le/les destinataires sélectionnés'
              />
            )}
            onClose={onClose}
            style={{ padding: '0 2px', margin: 0 }}
            noDivider
            noCloseButton
          >
            <MailsRecap
              selection={selection}
              style={{ margin: '16px 0 32px 0' }}
            />
          </DrawerTitle>
        )
      default:
        return null
      }
    }

    const displayStep = () => {
      switch (step) {
      case STEP_DRAWER_PARAMS:
        return (
          <MagicLinksSendingParameters
            campaigns={campaigns}
            currentCampaignId={currentCampaignId}
            setCurrentCampaignId={setCurrentCampaignId}
            campaignSelection={campaignSelection}
            campaignParams={campaignParams}
            setCampaignParams={setCampaignParams}
            email={email}
            mailObject={title}
            sendTestEmail={sendTestEmail}
            locale={locale}
            onClose={onClose}
            setStep={setStep}
            nextDisabled={parametersNextDisabled}
          />
        )
      case STEP_DRAWER_MAILS:
        return (
          <MagicLinksSendingMails
            magicLinks={magicLinks}
            currentCampaignId={currentCampaignId}
            subpart={subpart}
            setSubpart={setSubpart}
            mails={mails}
            setMails={setMails}
            selection={selection}
            setSelection={setSelection}
            setStep={setStep}
            handleSending={handleSending}
            nextDisabled={sendingMailsNextDisabled}
            customMessageAllowed={currentCampaign.message?.isEnabled}
          />
        )
      case STEP_DRAWER_MESSAGE:
        return (
          <MagicLinksSendingMessage
            currentCampaign={currentCampaign}
            mails={mails}
            setMails={setMails}
            selection={selection}
            setSelection={setSelection}
            setStep={setStep}
          />
        )
      default:
        return null
      }
    }

    return (
      <Drawer
        visible={visible}
        onClose={onClose}
        destroyOnClose
        maskClosable={false}
        closable={false}
        width='60%'
        bodyStyle={{ padding: '48px 22px 0px 22px' }} // Padding avec une marge négative de 2px pour assurer le bon affichage de la partie en overflow
      >
        <div style={{ minHeight: '119px' }}>
          <FadingTransition id={step}>
            {displayTitle()}
          </FadingTransition>
        </div>
        <div
          id='magicLinksSendingScroll'
          className='hiddenScrollbar'
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - (80px + 167px))', // Pleine hauteur d'écran - (80px pour les boutons d'étape en bas du drawer + 167px pour le titre)
            overflowY: 'auto',
            padding: '0 2px 2px 2px', // Padding avec une marge positive de 2px permettant de ne pas rogner les shadows des inputs à la sélection (up)
          }}
        >
          <FadingTransition id={step}>
            {displayStep()}
          </FadingTransition>
        </div>
      </Drawer>
    )
  } catch (e) {
    console.log('organism/MagicLinksSendingDrawer error:', e)
    return null
  }
}

export default MagicLinksSendingDrawer

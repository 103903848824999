import React from 'react'
import { SketchPicker } from 'react-color'

const CustomizableColorPicker = (props) => {
  const {
    width = 'auto',
    color,
    onChangeAction,
    style = {
      padding: '10px 0',
      border: 'none',
    },
  } = props

  return (
    <SketchPicker
      width={width}
      color={color}
      onChange={onChangeAction}
      style={style}
    />
  )
}

export default CustomizableColorPicker

import React from 'react'
import { FormattedButton, CustomizablePopover, CustomizableColorPicker } from '../..'

const PopoverColorPicker = (props) => {
  const {
    popoverTitle,
    popoverTrigger = 'click',
    isPopoverVisible = false,
    colorPickerColor,
    onChangeColorPickerAction,
    buttonStyle = { width: '100%', height: '40px' },
    popoverOnVisibleChange,
    isDisabled = false,
  } = props

  return (
    <>
      <CustomizablePopover
        content={(
          <CustomizableColorPicker
            width='200px'
            color={colorPickerColor}
            onChangeAction={onChangeColorPickerAction}
          />
        )}
        title={popoverTitle}
        trigger={popoverTrigger}
        visible={isPopoverVisible}
        onVisibleChange={popoverOnVisibleChange}
      >
        <FormattedButton style={buttonStyle} disabled={isDisabled} />
      </CustomizablePopover>
    </>
  )
}

export default PopoverColorPicker

import React from 'react'
import { Icon, Input } from 'antd'
import { uniq, uniqBy, flatten } from 'lodash'
import getBPVariations from '../getBPVariations'

export default (products, shop, filteredProducts, appliedFilters) => {
  const lang = 'fr'
  const categories = []
  const collect = []
  products.map((item) => {
    if (item.baseProduct) {
      const allCats = flatten(item.baseProduct.category[lang]).filter(Boolean)
      categories.push(...allCats)
    }
    if (item.collections) {
      const { collections } = item
      collect.push(...collections.filter(Boolean))
    }
    return false
  })
  const hasOnlyColorFilter = !!(appliedFilters.color && Object.keys(appliedFilters).length === 1)
  const arbitratedArray = hasOnlyColorFilter ? products : filteredProducts
  const filteredProductsColors = arbitratedArray
    ? flatten(arbitratedArray.map((p) => flatten(getBPVariations(p, 'color'))))
    : []
  const uniqFilteredProductsColors = uniqBy(filteredProductsColors, 'reference')

  const uniqCats = uniq(categories)
  const uniqCollections = uniq(collect)
  const filters = uniqCats.map((c) => {
    const subCats = products.map((p) => {
      if (p.baseProduct && p.baseProduct.category[lang].includes(c)) {
        return p.baseProduct.subCategory[lang]
      }
      return false
    })
    return { type: 'category', title: c, options: uniq(subCats.filter(Boolean)) }
  })
  if (shop.campaign && shop.campaign.status === 'Active') {
    filters.push({ type: 'discount', title: 'Promotions', options: ['Promotions'] })
  }
  if (uniqCollections.length > 0) {
    filters.push({ type: 'collection', title: 'Collections', options: uniqCollections })
  }
  filters.push({ type: 'colors', title: 'Couleurs', options: uniqFilteredProductsColors })
  return filters
}

const createFiltersFromUrl = (query) => {
  try {
    const search = query || {}
    const obj = {}
    const keys = Object.keys(search).filter((k) => k === 'color' || k === 'subCategory' || k === 'category')
    // eslint-disable-next-line
    keys.map(k => (obj[k] = query[k]))
    return obj
  } catch (e) {
    console.log('error', e)
    return { error: true }
  }
}

const getSimilarColors = (color, colors) => colors.filter((compared) => (
  compared.name === color.name
         || compared.value === color.value
))

const decodeQuery = (query) => {
  try {
    const queryObject = {}
    const stripped = query.substring(1)
    const parts = stripped.split('&')
    parts.map((part) => {
      const [key, rawValue] = part.split('=')
      let value = unescape(rawValue)
      if (/%\d\d\d\d/.test(rawValue)) {
        value = unescape(value)
      }
      if (value.length && value !== 'undefined') queryObject[key] = value
      // queryObject[key] = unescape(rawValue)
      return null
    })
    return queryObject
  } catch (e) {
    console.log('decodeQuery', e)
    return {}
  }
}

const encodeQuery = (queryObj) => {
  try {
    let query = ''
    if (typeof queryObj === 'object') {
      Object.keys(queryObj).map((key) => {
        // if the value isn't null or undefined (otherwise we don't want it in the URL)
        if (queryObj[key]
          // and if the value as a length (as an array or a string)
          && (queryObj[key].length
            // or if the value is a number
            || !Number.isNaN(queryObj[key]))) {
          // we add this property to the URL
          query += `${key}=${escape(queryObj[key])}&`
        }
        return null
      })
      query = query.substring(0, query.length - 1)
    }
    return query
  } catch (error) {
    return ''
  }
}

const redirectToListing = (history, value) => {
  try {
    const query = encodeQuery(value)
    history.push(`/?${query}`)
    return true
  } catch (e) {
    console.log('redirectToListing', e)
    return false
  }
}

const getType = (data) => {
  let type = typeof data
  if (type === 'object') {
    type = Array.isArray(data) ? 'array' : 'object'
  }
  if (type === 'object') {
    throw Error('Filers Error - wrong type of data - do not support objet')
  }
  return type
}

const getCheckFromType = (data, value) => {
  const type = getType(data)
  switch (type) {
  case 'array':
    return data.some((v) => v.toLowerCase().includes(value.toLowerCase()))
  case 'string':
  default:
    return data.toLowerCase().includes(value.toLowerCase())
  }
}

const filterData = (data, filters, options = {}) => {
  try {
    const filtersKeys = (filt) => {
      const keys = Object.keys(filt)
      return keys.filter((k) => filt[k])
    }
    const keys = filtersKeys(filters)
    if (!filtersKeys.length) {
      return data
    }
    const result = data.filter((d) => {
      const isOk = keys.every((k) => (options[k] ? options[k](filters[k], d) : (d.formatted[k] && getCheckFromType(d.formatted[k], filters[k]))))
      return isOk
    })
    return result
  } catch (e) {
    console.log('helpers/filterData', e)
    return false
  }
}

const getColumnSearchProps = (dataIndex, filtering, options = {}) => {
  const { render, filterDropdown, onFilter } = options
  const id = `${dataIndex}-${Math.random().toString(36).substr(2, 10)}`
  let input
  return ({
    filterDropdown: filterDropdown || (() => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          id={id}
          ref={(node) => {
            input = node
            return null
          }}
          onChange={(e) => {
            // HACK TO PREVENT LAG
            const data = e.target.value
            setTimeout(() => {
              const obj = {}
              obj[dataIndex] = data
              filtering(obj)
            }, 700)
          }}
          style={{ width: 188, display: 'block' }}
        />
      </div>
    )),
    filterIcon: (filtered) => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: onFilter || ((value, record) => (record.formatted[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase())
    )),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => input && input.select())
      }
    },
    render(text) {
      return render ? render(text) : text
    },
  })
}

/**
 * Provide an associative array (object in which each key contains a filter) of filters (filters are objects) in order to apply it on data.
 * Filters are formatted as following:
 * {
 *     callback: a function to apply on data to filter it
 *     parameters: an array containing extra parameters to pass to the callback
 * }
 * The callback function must return an array.
 * The returned value is an array on which all filters has been applied.
 */
const applyFilters = (filters, data) => {
  if (!(filters instanceof Object)) {
    console.log('variable filters must be an associative array (object), given: ', filters)
    return []
  }
  let copiedData = data.slice()
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  Object.values(filters).forEach((filter) => {
    const { callback, parameters } = filter
    if (!!callback || !!parameters) {
      copiedData = callback(copiedData, ...parameters)
    }
  })

  return copiedData
}

export {
  createFiltersFromUrl, decodeQuery, encodeQuery, redirectToListing, getSimilarColors, getColumnSearchProps, filterData, applyFilters,
}

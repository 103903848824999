export const initialState = {
  loading: true,
  error: false,
  all_paymentMethods: [],
}

export const getPaymentMethods = (state = initialState) => state.all_paymentMethods

export const loading = (state = initialState) => state.loading

export const error = (state = initialState) => state.error

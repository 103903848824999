import React from 'react'
import { List } from 'antd'
import { ProductCard, ProductListHeader } from '../..'

class ProductList extends React.Component {
  constructor() {
    super()
    this.state = {
      pageSize: '48',
    }
  }

  render() {
    const {
      products,
      selected,
      changeSelection,
      setSelectedProducts,
      setProductOrdering,
      allProducts,
      filtering,
      filters,
      allStocks,
      entities,
      entityId,
      history,
    } = this.props
    const {
      pageSize,
    } = this.state

    return (
      <List
        grid={{
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 4,
          xxl: 4,
        }}
        header={(
          <ProductListHeader
            filters={filters}
            filtering={filtering}
            products={allProducts}
            entities={entities}
          />
        )}
        dataSource={products}
        pagination={{
          showQuickJumper: true,
          style: { textAlign: 'left', margin: '0 0 0 10px' },
          pageSize,
          showSizeChanger: true,
          onShowSizeChange: (current, _pageSize) => this.setState({ pageSize: _pageSize }),
          pageSizeOptions: ['12', '24', '48'],
        }}
        renderItem={(product) => (
          <ProductCard
            product={product}
            ordered={product.ordered}
            unitPrice={product?.baseProduct?.unitPrice || product.unitPrice}
            impact={product.hasImpact}
            selected={selected}
            select={() => {
              const isSelected = selected.includes(product._id)
              if (isSelected) {
                selected.splice(selected.indexOf(product._id), 1)
              } else {
                selected.push(product._id)
              }
              changeSelection(selected)

              // On selectionne les produits et on leur assign un provisonalId (needed pour l'atelier) et leur stock en DB.
              // Cette fonction est lié à la modification dans l'atelier
              setSelectedProducts(products.filter((_product) => selected.includes(_product._id))
                .map((_product) => ({
                  ..._product,
                  provisionalId: Math.random().toString(8),
                  stock: allStocks.filter((s) => s.productId === String(product._id || product.id)),
                })))
            }}
            setProductOrdering={setProductOrdering}
            allStocks={allStocks}
            entityId={entityId}
            history={history}
          />
        )}
      />
    )
  }
}

export default ProductList

import React from 'react'
import {
  Select,
} from 'antd'
import { uniqBy } from 'lodash'
import { FormattedMessage } from 'react-intl'
import '../../../App.css'

import {
  neutralColor,
  primaryColor,
} from '../../../config/theme'

import { getAdminName } from '../../../helpers/magicLinks'

const { Option } = Select

const DisplayResetFilter = (props) => {
  const { filterValue, onReset } = props
  if (filterValue && filterValue !== 'Tous les statuts' && filterValue?.length) {
    return (
      <span
        style={{ color: primaryColor[6], float: 'right', cursor: 'pointer' }}
        onClick={onReset}
      >
        <FormattedMessage
          id='campaignTrackingFilters.resetStatus'
          defaultMessage='Réinitialiser'
        />
      </span>
    )
  }
  return null
}

const ButtonCollapseFilter = (props) => {
  const {
    magicLinks, collaborators, openFilter, setFiltersState, filters, user, shopOwner,
  } = props

  const { adminsIds, sort, type } = filters
  if (!openFilter) {
    return null
  }
  return (
    <div style={{ padding: '20px 0px' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: 16,
        borderRadius: 8,
        background: neutralColor[2],
        gap: 16,
      }}
      >
        <div style={{
          display: 'flex', flex: 1, flexDirection: 'column',
        }}
        >
          <div style={{
            display: 'block', flexDirection: 'row',
          }}
          >
            <FormattedMessage
              id='display'
              defaultMessage='Affichage'
            />
            <DisplayResetFilter filterValue={sort} onReset={() => setFiltersState({ ...filters, sort: undefined })} />
          </div>
          <Select
            showArrow
            style={{ minWidth: '200px' }}
            value={sort}
            placeholder={(
              <FormattedMessage
                id='campaignTracking.sort.younger'
                defaultMessage='Du plus récent au plus ancien'
              />
            )}
            onChange={(value) => setFiltersState({ ...filters, sort: value })}
          >
            <Option value='recent'>
              <FormattedMessage id='campaignTracking.sort.younger' defaultMessage='Du plus récent au plus ancien' />
            </Option>
            <Option value='oldest'>
              <FormattedMessage id='campaignTracking.sort.older' defaultMessage='Du plus ancien au plus récent' />
            </Option>
          </Select>
        </div>
        <div style={{
          display: 'flex', flex: 1, flexDirection: 'column',
        }}
        >
          <div style={{
            display: 'block', flexDirection: 'row',
          }}
          >
            <FormattedMessage id='status' defaultMessage='Statut' />
            <DisplayResetFilter filterValue={type} onReset={() => setFiltersState({ ...filters, type: undefined })} />
          </div>
          <Select
            value={type}
            showArrow
            style={{ minWidth: '220px', flex: '1 8' }}
            placeholder={(
              <FormattedMessage
                id='campaignTracking.placeholders.status'
                defaultMessage='Tous les statuts'
              />
            )}
            onChange={(value) => setFiltersState({ ...filters, type: value })}
          >
            <Option value='private'>
              <FormattedMessage id='private.female' defaultMessage='Privée' />
            </Option>
            <Option value='public'>
              <FormattedMessage id='public.female' defaultMessage='Publique' />
            </Option>
          </Select>
        </div>
        {user.admin || (user?._id === shopOwner) || (user?.collaborator && user?.adminCollaborator) ? (
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'column',
          }}
          >
            <div style={{
              display: 'block', flexDirection: 'row',
            }}
            >
              <FormattedMessage
                id='administrator'
                defaultMessage='Administrateur'
              />
              <DisplayResetFilter filterValue={adminsIds} onReset={() => setFiltersState({ ...filters, adminsIds: [] })} />
            </div>
            <Select
              mode='multiple'
              showArrow
              style={{ minWidth: '220px' }}
              value={adminsIds}
              placeholder={(
                <FormattedMessage
                  id='campaignTracking.placeholders.admins'
                  defaultMessage='Nom de l&apos;administrateur'
                />
              )}
              onChange={(value) => setFiltersState({ ...filters, adminsIds: value })}
            >
              {uniqBy(magicLinks, 'userId')
                .map((magicLink) => <Option key={magicLink?.userId}>{getAdminName(magicLink, collaborators)}</Option>)}
            </Select>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ButtonCollapseFilter

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { InnerLayout } from '../../layouts'

import { EntitiesContainer } from '../../containers'
import { Refresher } from '../../components/molecules'
import { INIT_FETCH_DATA } from '../../store/entities/actions'

const EntitiesPage = (props) => {
  const {
    history, entities, adminCollaborator, collaborator, fetchData, shopId,
  } = props
  const [actions, setActions] = useState([])
  useEffect(() => {
    fetchData()
  }, [shopId])

  return (
    <InnerLayout
      title={(
        <div style={{
          fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
        }}
        >
          {entities.length === 1 ? `Mon Entité - ${entities[0].name}` : 'Mes Entités'}
        </div>
      )}
      extra={actions}
    >
      <Refresher fetchData={fetchData} />
      <EntitiesContainer
        history={history}
        setActions={setActions}
        adminCollaborator={adminCollaborator}
        collaborator={collaborator}
      />
    </InnerLayout>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(EntitiesPage))

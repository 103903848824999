import React, { useState, useRef } from 'react'

import {
  Input,
  Button,
  Icon,
  Modal,
  message,
} from 'antd'

import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { EmailEditor, TemplateVariables } from '../../atoms'
import { EmailPreview } from '../../molecules'
import theme from '../../../config/theme'

const NotificationUpdate = (props) => {
  const { currentCampaign, shop } = props
  const { title, body, cta } = currentCampaign

  const [visible, setVisible] = useState(false)
  if (currentCampaign?.template?.design) return null
  return (
    <>
      <Modal
        visible={visible}
        width='50%'
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        destroyOnClose
        footer={null}
      >
        <EmailPreview
          subject={title}
          body={body}
          cta={cta}
          shop={shop}
        />
      </Modal>
      <div style={{
        border: '1px solid #ffe58f',
        borderRadius: 3,
        padding: '3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fffbe6',
        marginBottom: '10px',
      }}
      >
        <div style={{ flex: 'center', alignItems: 'center' }}>
          <Icon type='info-circle' style={{ fontSize: 16, margin: 8 }} />
          <span>
            <b>
              <FormattedMessage
                id='campaigns.template.new.title'
                defaultMessage='Notre éditeur de mail a évolué !'
              />
            </b>
            <span>
              <FormattedMessage
                id='campaigns.template.new.description'
                defaultMessage='Votre template de mail précédent est
                toujours actif tant que vous n&apos;enregistrez pas un nouveau template avec notre nouvel éditeur.'
              />
            </span>
          </span>
        </div>
        <Button onClick={() => setVisible(true)}>
          <FormattedMessage
            id='campaigns.view.old.template'
            defaultMessage='Voir mon template actuel'
          />
        </Button>
      </div>
    </>
  )
}
/**
 * Retourne la section d'édition d'un template
 * -> Sujet et body
 *
 * @param {*} isDisplayOnly - indicateur de mode de consultation (lecture seule / modification)
 * @param {*} currentCampaign - campagne en cours (lecture seule)
 * @param {*} editedCampaign - campagne en cours d'édition
 * @param {*} updateCampaign - fonction dispatchée pour l'update call saga
 * @param {*} setEditedCampaignTemplate - hook fonction pour mettre à jour la campagne en cours
 * @returns JSX.element
 */
const SectionEditionTemplate = (props) => {
  const {
    shop,
    isDisplayOnly,
    currentCampaign,
    editedCampaign,
    updateCampaign,
    setEditedCampaignTemplate,
  } = props

  const intl = useIntl()

  const [isButtonEditTemplateDisabled, setDisabledEditTemplate] = useState(true)
  const [emailEditorState, setEmailEditorState] = useState({ body: editedCampaign.template.body, design: editedCampaign.template.design })

  const emailEditorRef = useRef(null)

  /**
     * Fonction appelée à chaque changement dans l'input "Sujet"
     * @param {event} e - informations sujet template mail
     */
  const onChangeTemplateTitle = (e) => {
    // on met à jour le titre du template
    setEditedCampaignTemplate({
      ...editedCampaign,
      template: {
        ...editedCampaign.template,
        title: e.target.value,
      },
    })

    setDisabledEditTemplate(e.target.value === currentCampaign?.template?.title
        && editedCampaign?.template?.body === currentCampaign?.template?.body)
  }

  return (
    <div style={{ flex: '1', marginRight: '20px', marginBottom: '70px' }}>
      <NotificationUpdate currentCampaign={currentCampaign} shop={shop} />
      <TemplateVariables />
      <h4 style={{
        color: theme.neutralColor[6][12],
        fontWeight: 'bold',
        marginBottom: '2px',
      }}
      >
        <FormattedMessage
          id='campaigns.modal.step.template.subject'
          defaultMessage='Sujet'
        />
      </h4>
      <Input
        disabled={isDisplayOnly}
        defaultValue={currentCampaign?.template?.title}
        onChange={onChangeTemplateTitle}
        style={{
          fontWeight: 'bold',
          margin: '5px 0 10px 0',
        }}
      />
      <EmailEditor
        design={currentCampaign?.template?.design}
        currentCampaign={currentCampaign}
        setEmailEditorState={setEmailEditorState}
        setDisabledEditTemplate={setDisabledEditTemplate}
        emailEditorRef={emailEditorRef}
      />
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <Button
          disabled={isButtonEditTemplateDisabled}
          type='primary'
          style={{ margin: '0 10px' }}
          onClick={() => {
            if (!editedCampaign.template.body.includes('magic_link')) {
              message.warning(intl.formatMessage({
                id: 'campaign.template.link.warning',
                defaultMessage: 'Le mail a besoin d\'une redirection au cadeau',
              }))
            } else {
              updateCampaign({ _id: editedCampaign._id, template: { ...editedCampaign.template, ...emailEditorState } })
              setDisabledEditTemplate(true)
            }
          }}
        >
          <FormattedMessage
            id='campaigns.modal.button.create'
            defaultMessage='Enregistrer'
          />
        </Button>
      </div>
    </div>
  )
}

/**
 * Composant fonctionnel qui gère le contenu "Template" de la page d'édition d'une campagne (paramètres campagne)
 */
const CampaignEditTemplateOrganism = (props) => {
  try {
    const {
      isDisplayOnly, // si true, toutes les modifications possibles sont désactivées
      currentCampaign,
      updateCampaign, // méthode dispatchée depuis le container
      shop,
    } = props

    const [editedCampaign, setEditedCampaignTemplate] = useState(currentCampaign)

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <SectionEditionTemplate
          shop={shop}
          isDisplayOnly={isDisplayOnly}
          currentCampaign={currentCampaign}
          editedCampaign={editedCampaign}
          updateCampaign={updateCampaign}
          setEditedCampaignTemplate={setEditedCampaignTemplate}
        />
      </div>

    )
  } catch (e) {
    console.log('error organism/CampaignEditTemplateOrganism', e)
    return null
  }
}

export default injectIntl(CampaignEditTemplateOrganism)

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Popover } from 'antd'

import { character, neutralColor, primaryColor } from '../../../config/theme'

const MailsRecap = (props) => {
  try {
    const { selection, style } = props

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
        background: neutralColor[1],
        borderRadius: '16px',
        fontWeight: 500,
        gap: '20px',
        ...style,
      }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          gap: '8px',
          color: character.primary,
          minWidth: 0,
        }}
        >
          {selection.length > 1 ? (
            <FormattedMessage id='mails.twoPoints' defaultMessage='Mails :' />
          ) : (
            <FormattedMessage id='mail.twoPoints' defaultMessage='Mail :' />
          )}
          <span style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          >
            {selection.join(' • ')}
          </span>
        </div>
        {selection.length > 1 && (
          <Popover
            placement='bottomRight'
            content={(
              <span style={{ fontWeight: 400, color: character.title }}>
                {selection.join(', ')}
              </span>
            )}
          >
            <span style={{ color: primaryColor[6], whiteSpace: 'nowrap' }}>
              <FormattedMessage id='seeDetails' defaultMessage='Voir le détail' />
            </span>
          </Popover>
        )}
      </div>
    )
  } catch (e) {
    console.log('atoms/MailsRecap error:', e)
    return null
  }
}

export default MailsRecap

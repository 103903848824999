/* eslint-disable no-param-reassign */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { INIT_FETCH_DATA } from '../../store/campaigns/actions'
import { Refresher } from '../../components'
import { CampaignCreateContainer } from '../../containers'
import theme from '../../config/theme'

const CampaignCreatePage = (props) => {
  const {
    fetchData, history, shop, user,
  } = props

  return (
    <div style={{
      transition: '0.5s',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 100,
      padding: '24px',
      backgroundColor: theme.neutralColor[0],
      overflow: 'auto',
    }}
    >
      <Refresher fetchData={fetchData} />
      <CampaignCreateContainer
        shop={shop}
        history={history}
        user={user}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(CampaignCreatePage))

// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import {
  put, call,
  takeEvery,
  select,
} from 'redux-saga/effects'
import { fromUsers } from '../selectors'
import * as actions from './actions'
import { GET as GET_SUPPLIERS } from '../baseProducts/actions'

const { getAuth } = fromUsers

/**
 * `*fetchSuppliers` fetches the info we need from the API
 * ---
 * Generator function doc: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/function*'
 * ---
 * @param {Function} api the api service: '/src/services/api'
 * @return {Boolean} true if everything went ok
 */
export function* fetchSuppliers(api) {
  try {
    //  TODO arbiter cache
    const auth = yield select(getAuth)
    yield put({ type: actions.GET })
    const { data } = yield call([api, api.get], '/supplier', { headers: { authorization: auth } })
    yield put({ type: actions.RECEIVED, payload: { data } })
    return true
  } catch (e) {
    console.log('fetchSuppliers error', e, e.message)
    yield put({
      type: actions.ERROR,
      payload: {
        error_details: (e.message || e),
      },
    })
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(GET_SUPPLIERS, fetchSuppliers, api)
}

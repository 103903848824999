import { uniqBy } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const getProductStocks = (allStocks, productId) => allStocks?.filter((stock) => stock.productId === productId)

const getEntityStocks = (allStocks, entityId) => allStocks?.filter((stock) => stock.entityId === entityId)

const getProductStocksByEntity = (allStocks, productId, entityId) => getEntityStocks(getProductStocks(allStocks, productId), entityId)

const getProductGlobalStocks = (allStocks, productId) => {
  const stocks = getProductStocks(allStocks, productId)
    .map((s) => ({ ...s, combo: `${s.variationCombo[0].name} | ${s.variationCombo[1].name}` }))
  const result = uniqBy(stocks.map((s) => ({
    ...s,
    qty: stocks.filter((st) => s.combo === st.combo).map((sto) => sto.qty).reduce((a, b) => a + b),
  })), 'combo')
  return result
}

const getMaxFromStock = (product, size, color, stocks, entityId) => {
  try {
    let quantity = null
    if (stocks?.length) {
      stocks.map((s) => {
        if (
          s.productId === String(product._id)
          && s.variationCombo.find((v) => v.type === 'size' && v.reference === size.reference)
          && s.variationCombo.find((v) => v.type === 'color' && v.reference === color.reference)
          && entityId === s.entityId
        ) {
          quantity = s.qty
        }
        return true
      })
    }
    return quantity
  } catch (e) {
    console.log('getMaxFromStock error:', e)
    return null
  }
}

const renderPlaceHolder = (unavailable, isOOS, value) => {
  if (unavailable) {
    return (
      <p>
        <span className='value'>
          <FormattedMessage id='productOrderingDrawer.unavailable' defaultMessage='(Indisponible)' />
        </span>
      </p>
    )
  }
  if (isOOS) {
    return (
      <p>
        (
        <FormattedMessage id='productOrderingDrawer.stockIs' defaultMessage='Le stock est' />
        {' '}
        <span className='value'>
          <FormattedMessage id='productOrderingDrawer.outOfStock' defaultMessage='épuisé' />
        </span>
        )
      </p>
    )
  }
  return (
    <p>
      (
      <FormattedMessage id='productOrderingDrawer.actualStock' defaultMessage='Stock actuel :' />
      {' '}
      <span className='value'>{value}</span>
      )
    </p>
  )
}

export {
  getProductStocks, getEntityStocks, getProductGlobalStocks, getProductStocksByEntity, getMaxFromStock, renderPlaceHolder,
}

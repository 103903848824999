import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import { BasicsForm } from '../../components'
import { MODIFY } from '../../store/shops/actions'
import { fromShops } from '../../store/selectors'

const { Title } = Typography

export default connect(
  (state) => ({
    shopSettings: fromShops.getShopSettings(state),
    shopId: fromShops.getId(state),
    shop: fromShops.getShop(state),
    canActivate: fromShops.canActivate(state),
  }),
  (dispatch) => bindActionCreators({
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)(({
  shopSettings,
  modifyShop,
  canActivate,
  shop,
}) => (
  <div id='basics'>
    <Title level={2}>
      <FormattedMessage
        id='settings.basics.title'
        defaultMessage='Général'
      />
    </Title>
    <BasicsForm
      shopSettings={shopSettings}
      shop={shop}
      modifyShop={modifyShop}
      canActivate={canActivate}
    />
  </div>
))

import React from 'react'
import { Button } from 'antd'

const MagicLinksSendingStepsButtons = (props) => {
  const {
    previousTitle,
    nextTitle,
    handleNextStep,
    handlePreviousStep,
    previousDisabled = false,
    nextDisabled = false,
    style,
  } = props

  return (
    <div style={{
      position: 'absolute',
      left: 0,
      bottom: 0,
      padding: '24px',
      width: '100%',
      display: 'flex',
      gap: '16px',
      ...style,
    }}
    >
      <Button onClick={handlePreviousStep} disabled={previousDisabled} block>
        {previousTitle}
      </Button>
      <Button onClick={handleNextStep} disabled={nextDisabled} type='primary' block>
        {nextTitle}
      </Button>
    </div>
  )
}

export default MagicLinksSendingStepsButtons

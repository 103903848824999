import {
  Alert,
  Table,
  Tag,
  Transfer,
  message,
} from 'antd'
import difference from 'lodash/difference'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

const tableColumns = [
  {
    dataIndex: 'name',
    title: <FormattedMessage id='lastName' defaultMessage='Nom' />,
  },
  {
    dataIndex: 'reference',
    title: <FormattedMessage id='reference' defaultMessage='Référence' />,
  },
  {
    dataIndex: 'type',
    title: <FormattedMessage id='type' defaultMessage='Type' />,
    filters: [{ text: 'Couleur', value: 'color' }, { text: 'Taille', value: 'size' }],
    onFilter: (value, v) => v.type === value,
    render: (type) => (
      <Tag>
        {type === 'size'
          ? <FormattedMessage id='size' defaultMessage='Taille' />
          : <FormattedMessage id='color' defaultMessage='Couleur' />}
      </Tag>
    ),
  },
  {
    dataIndex: 'value',
    title: <FormattedMessage id='value' defaultMessage='Valeur' />,
    render: (value) => <Tag color={value}>{value}</Tag>,
  },
]

const TableTransfer = ({ columns, ...restProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Transfer {...restProps} showSelectAll={false}>
    {({
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const rowSelection = {
        getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .map(({ key }) => key)
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys)
          onItemSelectAll(diffKeys, selected)
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected)
        },
        selectedRowKeys: listSelectedKeys,
      }

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size='small'
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return
              onItemSelect(key, !listSelectedKeys.includes(key))
            },
          })}
        />
      )
    }}
  </Transfer>
)

const ProductVariationsTransfer = (props) => {
  const {
    product,
    variations,
    updateProduct,
  } = props

  const [newVariations, setNewVariations] = useState([])
  const [targetKeys, setTargetKeys] = useState(() => (
    product.variations
    && product.variations?.length
    && product.variations?.map((v) => (`${v.reference}-${v.type}`))) || [])
    
  return (
    <div>
      <Alert
        message={<FormattedMessage id='productVariationsTransfer.alertMessage' defaultMessage='Une couleur et une taille obligatoire' />}
        type='warning'
        closable
        showIcon
        style={{ marginBottom: '10px' }}
      />
      <TableTransfer
        dataSource={variations?.map((v) => ({
          ...v,
          key: `${v.reference}-${v.type}`,
        }))}
        titles={[
          <FormattedMessage id='baseProduct' defaultMessage='Produit de base' />,
          <FormattedMessage id='newProduct' defaultMessage='Nouveau produit' />,
        ]}
        operations={[
          <FormattedMessage id='add' defaultMessage='Ajouter' />,
          <FormattedMessage id='remove' defaultMessage='Enlever' />,
        ]}
        targetKeys={targetKeys}
        onChange={(nextTargetKeys, direction, moveKeys) => {
          // Si on enlève une variation, on vérifie si c'est pas une variation qui vient juste d'être ajouté où si elle existe déjà dans le produit
          // on ne supprime pas les variations existantes du produit
          if (direction === 'left' && product._id && !moveKeys.every((variation) => newVariations.includes(variation))) {
            message.warning('Il n\'est pas possible d\'enlever des variations existantes via l\'atelier')
          } else {
            setTargetKeys(nextTargetKeys)
            updateProduct(product.provisionalId, {
              variations: variations?.filter((v) => nextTargetKeys.includes(`${v.reference}-${v.type}`)),
            }, () => message.success(<FormattedMessage id='newProductUpdated' defaultMessage='Nouveau produit mis à jour' />))
            setNewVariations(direction === 'left'
              ? newVariations.filter((variation) => !moveKeys.includes(variation)) : [...newVariations, ...moveKeys])
          }
        }}
        columns={tableColumns}
        showSearch
        filterOption={(inputValue, item) => item?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()) || item?.reference?.toLowerCase()?.includes(inputValue?.toLowerCase())}
      />
    </div>
  )
}

export default ProductVariationsTransfer

import React from 'react'
import {
  Input,
  Switch,
  InputNumber,
  Tooltip,
  Icon,
} from 'antd'
import { FormattedMessage } from 'react-intl'

import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

const HiddenCustomConversion = (props) => {
  try {
    const { newCustom, setNewCustom } = props
    const { conversion } = newCustom
    const { symbol, icon, rateEurosToCredit } = conversion
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage
            id='custom.conversion'
            defaultMessage='Conversion'
          />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.conversionSymbol'
                defaultMessage='Symbole ou icône à afficher'
              />
            </div>
            <Tooltip
              title={(
                <div>
                  <FormattedMessage
                    id='custom.helper2'
                    // eslint-disable-next-line max-len
                    defaultMessage="Remplace le symbole € par un autre mot/texte/icône. Pour une icône, la valeur doit être un nom d'icône issu de la liste Notion suivante :"
                  />
                  <a
                    href='https://www.notion.so/panopli/Liste-des-icones-ca4c0465a7854e33b3491221d0081a7c'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: 'white' }}
                  >
                    {' '}
                    <Icon type='link' />
                  </a>
                </div>
              )}
              placement='bottom'
            >
              <Input
                placeholder='$, icon...'
                value={symbol}
                onChange={(e) => setNewCustom({ ...newCustom, conversion: { ...conversion, symbol: e.target.value } })}
                style={{ width: '100px' }}
              />
            </Tooltip>
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.conversionIcon'
                defaultMessage="Afficher sous forme d'icône"
              />
            </div>
            <Switch
              checked={icon}
              onChange={(value) => setNewCustom({ ...newCustom, conversion: { ...conversion, icon: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.conversionRateEurosToCredit'
                defaultMessage='Taux euros/crédits'
              />
            </div>
            <Tooltip
              title={(
                <FormattedMessage
                  id='custom.conversionRateEurosToCreditInfos'
                  defaultMessage='Multiplie le prix en euros par le taux ci-contre pour une conversion en crédits.'
                />
              )}
              placement='bottom'
            >
              <InputNumber
                placeholder='Rate...'
                value={rateEurosToCredit}
                style={{ width: '100px' }}
                onChange={(value) => setNewCustom({ ...newCustom, conversion: { ...conversion, rateEurosToCredit: value } })}
              />
            </Tooltip>
          </div>
          <div style={columnStyle} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecule/HiddenCustomConversion error:', e)
    return null
  }
}

export default HiddenCustomConversion

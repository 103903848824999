import { compact, without, uniq } from 'lodash'

export const initialState = {
  data: [],
  loading: false,
  error: false,
  createdId: false,
  modifiedId: false,
  requireAction: false,
  statusModificationModalOpened: false,
}

export const shopifyRequireAction = (state = initialState) => state.requireAction

export const getCreatedId = (state = initialState) => state.createdId

export const getModifiedId = (state = initialState) => state.modifiedId

export const loading = (state = initialState) => state.loading || false

export const error = (state = initialState) => state.error || false

export const modalOpened = (state = initialState) => state.statusModificationModalOpened

export const getBPs = (state = initialState) => {
  if (state.data && state.data.length) {
    let data = compact(state.data)
    data = data.reverse()
    return data
  }
  return initialState.data
}

export const getShopifyBps = (state = initialState) => {
  if (state.data && state.data.length) {
    let data = compact(state.data.filter(
      (bp) => bp && bp._id !== '000000000000000000000000' && bp.newBaseProduct,
    ))
    data = data.reverse()
    return data
  }
  return initialState.data
}

export const getAllCategories = (state = initialState) => {
  try {
    const result = getShopifyBps(state).map(
      (bp) => bp.category && bp.category.fr.find(Boolean),
    )
    return without(uniq(result), null, undefined)
  } catch (e) {
    console.log('getAllCategories', e)
    return initialState.data
  }
}

export const getAllSubCategories = (state = initialState) => {
  try {
    const result = getShopifyBps(state).map(
      (bp) => bp.subCategory && bp.subCategory.fr,
    )
    return without(uniq(result), null, undefined)
  } catch (e) {
    console.log('getAllSubCategories', e)
    return initialState.data
  }
}

export const getCanonBps = (state = initialState) => {
  if (state.data && state.data.length) {
    let data = compact(state.data.filter(
      (bp) => bp && bp._id !== '000000000000000000000000',
    ))
    data = data.reverse()
    return data
  }
  return initialState.data
}

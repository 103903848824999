import React from 'react'
import {
  Form, Button,
  Col,
  Row,
  Input,
  message,
} from 'antd'
import { withRouter } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { requiresEmail, validateEmailWithCallback, noSpaceInEmail } from '../../../helpers/emailValidation'

class LoginForm extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  handleErrorMessage = (error) => {
    switch (error.status) {
    case 429:
      return (
        <FormattedMessage
          id='auth.lockedAccount'
          defaultMessage='Le compte a été temporairement bloqué par sécurité'
        />
      )
    case 403:
    case 404:
      return (
        <FormattedMessage
          id='auth.error'
          defaultMessage="L'identifiant ou le mot de passe est incorrect"
        />
      )
    case 500:
    default:
      return (
        <span>
          <FormattedMessage
            id='auth.technicalError'
            defaultMessage='Une erreur technique est survenue.'
          />
          <br />
          <FormattedMessage
            id='auth.technicalError.support'
            defaultMessage="Si le problème persiste, contactez le support Panopli à l'adresse suivante :"
          />
          <br />
          <Button
            type='link'
            style={{ fontWeight: 'bold' }}
            onClick={() => {
              window.location.href = 'mailto:help@panopli.co'
            }}
          >
            help@panopli.co
          </Button>
        </span>
      )
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    const {
      login,
      form: {
        validateFields,
      },
      history,
    } = this.props
    validateFields((err, values) => {
      if (!err && values) {
        this.setState({
          loading: true,
        })
        login(values, (error, user) => {
          this.setState({
            loading: false,
          })
          if (error) {
            message.config({ duration: 10, top: window.innerHeight / 2 - 230, maxCount: 1 })
            message.error((<span style={{ fontWeight: 'bold' }}>{this.handleErrorMessage(error)}</span>), 4)
            this.setState({
              loading: false,
            })
            history.push('/')
            return 1
          }
          // en cas de premiere connexion du user, pristine sera true, le user est redirigé vers la page de changement de password
          if (user?.pristine) {
            const passwordReset = user.password_reset
            const { token } = passwordReset || {}
            history.push(`/resetPassword/${token}`)
            return 1
          }
          history.push('/')
          return 1
        })
      }
    })
  }

  requiresPassword = (rule, value, callback) => (
    value.length ? callback() : callback(
      <FormattedMessage
        id='auth.emptyPassword'
        defaultMessage='Veuillez entrer un mot de passe'
      />,
    )
  )

  render() {
    const {
      form: {
        getFieldDecorator,
      },
    } = this.props
    const {
      loading,
    } = this.state

    return (
      <div style={{
        width: '80%',
      }}
      >
        <Form layout='vertical' hideRequiredMark onSubmit={this.onSubmit}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={<FormattedMessage id='email' defaultMessage='Email' />}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator('email', {
                  rules: [
                    { validator: requiresEmail },
                    { validator: validateEmailWithCallback },
                    { validator: noSpaceInEmail },
                  ],
                })(<Input placeholder='name@company.com' autoFocus />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={(
                  <FormattedMessage
                    id='password'
                    defaultMessage='Mot de passe'
                  />
                )}
                style={{ marginBottom: 0 }}

              >
                {getFieldDecorator('password', {
                  rules: [
                    { validator: this.requiresPassword },
                  ],
                })(
                  <Input.Password placeholder='******' />,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              position: 'relative',
              right: 0,
              bottom: 0,
              width: '100%',
              padding: '10px 0',
            }}
          >
            <Col span={24}>
              <Form.Item>
                <Button
                  type='primary'
                  loading={loading}
                  size='large'
                  htmlType='submit'
                >
                  <span style={{ marginLeft: loading ? 10 : 0 }}>
                    <FormattedMessage
                      id='auth.signIn'
                      defaultMessage='Connexion'
                    />
                  </span>
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default Form.create()(withRouter(LoginForm))

const formatScopes = (scopes) => {
  const formattedScopes = []
  scopes.map((scope) => {
    const name = scope.split('.')[1]
    if (formattedScopes.find((arr) => arr.name === name)) {
      formattedScopes.find((arr) => arr.name === name).scopes.push(scope.split('.')[0])
    } else {
      formattedScopes.push({
        name,
        scopes: [scope.split('.')[0]],
      })
    }
    return true
  })
  return formattedScopes
}

export default formatScopes

import React, {
// Profiler,
} from 'react'

import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/configure'
import stateLoader from './store/stateLoader'
import api from './services/api'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

const loadedLocalStorage = stateLoader.loadState()

// const profilerCallback = (
//   id,
//   // NOTE the "id" prop of the Profiler tree that has just committed
//   phase,
//   // NOTE either "mount" (if the tree just mounted) or "update" (if it re-rendered)
//   actualDuration,
//   // NOTE time spent rendering the committed update
//   baseDuration,
//   // NOTE estimated time to render the entire subtree without memoization
//   startTime,
//   // NOTE when React began rendering this update
//   commitTime,
//   // NOTE when React committed this update
//   interactions,
//   // NOTE the Set of interactions belonging to this update
// ) =>
//   // console.log('profiling', {
//   //   id,
//   //   phase,
//   //   actualDuration,
//   //   baseDuration,
//   //   startTime,
//   //   commitTime,
//   //   interactions,
//   // })
//   true

const createdStore = store(loadedLocalStorage, {
  api: api.create(),
})

createdStore.subscribe(() => {
  stateLoader.saveState(createdStore.getState())
})

ReactDOM.render(
  <Provider store={createdStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

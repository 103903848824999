import React from 'react'
import { notification } from 'antd'
import {
  put, call, takeEvery, select, all, takeLatest,
} from 'redux-saga/effects'
import { FormattedMessage } from 'react-intl'
import * as actions from './actions'
import { fromShops, fromUsers, fromEntities } from '../selectors'
import { fetchCollaborators } from '../collaborators/sagas'
import { fetchMagicLinks } from '../magicLinks/sagas'
import { fetchBudgets } from '../budgets/sagas'
import { getProducts } from '../products/sagas'
import { fetchEntities } from '../entities/sagas'
import { getOrders } from '../orders/sagas'

const { getId } = fromShops
const { getAuth, getUserId } = fromUsers
const { getEntities } = fromEntities

const endPointAPI = '/campaigns'

export function* fetchCampaigns(api) {
  try {
    yield put({ type: actions.GET })
    yield put({ type: actions.LOADING, payload: { loading: true } })
    const shop = yield select(getId)
    const auth = yield select(getAuth)
    const { data } = yield call([api, api.get], endPointAPI, { headers: { authorization: auth, shop } })
    yield put({ type: actions.RECEIVED, payload: { data } })
    return true
  } catch (e) {
    console.log('fetchCampaigns error', e, e.message)
    yield put({
      type: actions.ERROR,
      error_details: (e.message || e),
    })
    return false
  }
}

export function* createCampaign(api, { payload, callback }) {
  try {
    yield put({ type: actions.LOADING, payload: { loading: true } })
    const shopId = yield select(getId)
    const userId = yield select(getUserId)
    const auth = yield select(getAuth)
    const entities = yield select(getEntities)
    const entityId = entities.find((_entity) => _entity)._id
    const campaignCreated = yield call([api, api.post], endPointAPI, {
      ...payload,
      userId,
      shopId,
      entityId,
    }, {
      headers: {
        mode: 'cors',
        authorization: auth,
      },
    })
    if (campaignCreated) {
      if (callback) {
        callback(campaignCreated)
      }
      yield put({ type: actions.FETCH_FORCE })
    }
    return true
  } catch (e) {
    console.log('createCampaign error', e, e.message)
    yield put({
      type: actions.ERROR,
      error_details: (e.message || e),
    })
    notification.error({
      message: (<FormattedMessage
        id='store.editCampaign.create.error'
        defaultMessage='Oups, une erreur est survenue...'
      />),
      placement: 'bottomRight',
      duration: 4,
    })
    return false
  }
}

export function* sendTestEmail(api, { payload, callback }) {
  try {
    yield put({ type: actions.LOADING, payload: { loading: true } })
    const auth = yield select(getAuth)

    // Call API pour envoi email de test
    const testEmailSend = yield call([api, api.post],
      '/campaignSendTestEmail',
      payload, {
        headers: {
          mode: 'cors',
          authorization: auth,
        },
      })

    if (testEmailSend) {
      if (callback) {
        callback()
      }
      notification.success({
        message: (<FormattedMessage id='store.editCampaign.sendTestEmail.success' defaultMessage='Mail de test envoyé !' />),
        placement: 'bottomRight',
        duration: 4,
      })
    }

    return true
  } catch (e) {
    console.log('error sendTestEmail : ', e, e.message)
    yield put({
      type: actions.ERROR,
      error_details: (e.message || e),
    })
    notification.error({
      message: (<FormattedMessage
        id='store.editCampaign.create.error'
        defaultMessage='Oups, une erreur est survenue...'
      />),
      placement: 'bottomRight',
      duration: 4,
    })
    return false
  }
}

/**
 * Fonction qui met à jour la campagne présente dans le payload, refetch les campagnes et refresh les données du state rédux.
 *
 * @param {*} api
 * @param {*} payload : campagne à mettre à jour et call back (facultatif)
 * @returns
 */
export function* updateCampaign(api, { payload, callback }) {
  try {
    yield put({ type: actions.LOADING, payload: { loading: true } })
    const auth = yield select(getAuth)

    // Call API pour update la campagne
    const campaignUpdated = yield call([api, api.put], endPointAPI, payload, {
      headers: {
        mode: 'cors',
        authorization: auth,
      },
    })

    // Si campagne mise à jour, on refresh les données via callback ou actions sagas
    if (campaignUpdated) {
      if (callback) {
        callback(campaignUpdated)
      }
      console.log(`updateCampaign : campagne id=${payload._id} mise à jour`)

      yield put({ type: actions.FETCH_FORCE })
      console.log('updateCampaign : campagnes actualisées')
    }

    return true
  } catch (e) {
    console.log('updateCampaign error', e, e.message)
    notification.error({
      message: (<FormattedMessage
        id='store.editCampaign.update.error'
        defaultMessage='Oups, une erreur est survenue...'
      />),
      placement: 'bottomRight',
      duration: 4,
    })
    return false
  }
}

export function* removeDraftCampaign(api, { payload }) {
  try {
    console.log('start removeDraftCampaign, trying to delete :', payload)
    yield put({ type: actions.LOADING, payload: { loading: true } })
    const auth = yield select(getAuth)
    console.log(`send request to remove campaign with id [${payload._id}]`)
    yield call([api, api.delete], `${endPointAPI}/${payload._id}`, {
      headers: {
        mode: 'cors',
        authorization: auth,
      },
    })
    console.log(`campaign with id [${payload._id}] successfully deleted`)
    yield put({ type: actions.FETCH_FORCE })
    return true
  } catch (error) {
    console.log(`an error occurred while deleting campaign with id [${payload._id} : ${error}`)
    return false
  }
}

export function* getDataForCampaignPage(api) {
  const page = 0
  const pageSize = 200
  yield all([
    call(fetchCampaigns, api),
    call(fetchCollaborators, api),
    call(fetchMagicLinks, api),
    call(fetchBudgets, api),
    call(getProducts, api),
    call(fetchEntities, api),
    call(getOrders, api, { payload: { page, pageSize } }),
  ])
  yield put({ type: actions.END_FETCH_DATA })
}

export default function* ({ api }) {
  yield takeLatest(actions.INIT_FETCH_DATA, getDataForCampaignPage, api)
  yield takeLatest(actions.FETCH_FORCE, fetchCampaigns, api)
  yield takeEvery(actions.CREATE, createCampaign, api)
  yield takeEvery(actions.UPDATE, updateCampaign, api)
  yield takeEvery(actions.SEND_TEST, sendTestEmail, api)
  yield takeEvery(actions.REMOVE_DRAFT_CAMPAIGN, removeDraftCampaign, api)
}

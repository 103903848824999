import React from 'react'

import { Badge } from 'antd'
import { primaryColor, otherBackgroundColor, thirdColor } from '../../../config/theme'

const BadgeBeta = () => (
  <Badge
    style={{
      marginLeft: '10px',
      backgroundColor: thirdColor,
      color: primaryColor,
      boxShadow: `0 0 0 1px ${otherBackgroundColor} inset`,
    }}
    count='beta 🚧'
  />
)

export default BadgeBeta

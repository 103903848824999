import React from 'react'
import { Alert } from 'antd'

const notEqualToGlobal = (impactCarbon) => {
  const {
    global = 0,
    manufacturing = 0,
    marking = 0,
    transport = 0,
    packaging = 0,
    endOfLife = 0,
  } = impactCarbon
  // on multiplie par 1000 pour permettre des grands chiffres à virgule
  const total1000 = manufacturing * 1000
    + marking * 1000
    + transport * 1000
    + packaging * 1000
    + endOfLife * 1000
  const global1000 = global * 1000

  if (total1000 === global1000) return null
  return (
    <Alert
      message={'L\'impact global n\'est pas égale à la somme des 5 valeurs'}
      type='warning'
      showIcon
    />
  )
}

export default notEqualToGlobal

import React from 'react'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
  Layout,
  Menu,
  Icon,
} from 'antd'
import { Logo, BadgeNew, BadgeBeta } from '..'

import './style.css'

const { Sider } = Layout
const { Item, SubMenu } = Menu

const NavMenu = ({
  history,
  user,
  shopOwner,
}) => (
  <Sider style={{ borderRight: '1px solid #EAEAEA', fontSize: '12px' }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
      onClick={() => history.push('/')}
    >
      <Logo
        type='blue-panopli-bird'
        width='70%'
        style={{
          padding: '0',
          cursor: 'pointer',
        }}
      />
    </div>
    <Menu
      mode='inline'
      style={{ width: '220px' }}
    >
      <Item
        onClick={() => {
          history.push('/')
        }}
      >
        <span className='titleMenu'>
          <Icon type='dashboard' />
          <FormattedMessage id='menu.dashboard' defaultMessage='Dashboard' />
        </span>
      </Item>
      <Item
        key='product'
        onClick={() => {
          history.push('/products')
        }}
        className='titleMenu'
      >
        <Icon type='skin' />
        <FormattedMessage id='products' defaultMessage='Produits' />
      </Item>
      <SubMenu
        key='subBoutique'
        title={(
          <div className='titleMenu'>
            <Icon type='shop' />
            <FormattedMessage id='shop' defaultMessage='Boutique' />
          </div>
        )}
      >

        {user.admin || user._id === shopOwner ? (
          <Item
            key='theme'
            onClick={() => {
              history.push('/shop/theme')
            }}
            className='titleMenu'
          >
            <FormattedMessage id='menu.theme' defaultMessage='Thème' />
          </Item>
        ) : null}
        <Item
          onClick={() => {
            history.push('/members')
          }}
          className='titleMenu'
        >
          <FormattedMessage id='members' defaultMessage='Membres' />
        </Item>
        <Item
          onClick={() => {
            history.push('/discounts')
          }}
          className='titleMenu'
        >
          <FormattedMessage id='discounts' defaultMessage='Promotions' />
        </Item>
        <Item
          key='preference'
          className='titleMenu'
          onClick={() => {
            history.push('/shop/settings')
          }}
        >
          <FormattedMessage id='menu.settings' defaultMessage='Préférences' />
        </Item>
      </SubMenu>

      <Item
        onClick={() => {
          history.push('/magicLinks')
        }}
      >
        <span className='titleMenu'>
          <Icon type='rocket' />
          <FormattedMessage id='menu.campaign' defaultMessage='Magic Link' />
        </span>
        <BadgeNew />
      </Item>
      <Item
        onClick={() => {
          history.push('/orders')
        }}
        className='titleMenu'
      >
        <Icon type='clock-circle' />
        <FormattedMessage id='orders' defaultMessage='Suivi envois' />
      </Item>
      <SubMenu
        key='subGestion'
        title={(
          <span className='titleMenu'>
            <Icon type='control' />
            <FormattedMessage id='menu.management' defaultMessage='Gestion' />
          </span>
        )}
      >

        {user.collaborator && !user.adminCollaborator ? null : (
          <Item
            onClick={() => {
              history.push('/entities')
            }}
            className='titleMenu'
          >
            <FormattedMessage id='entities' defaultMessage='Entités' />
          </Item>
        )}
        <Item
          onClick={() => {
            history.push('/developers')
          }}
          className='titleMenu'
        >
          <FormattedMessage
            id='developpers.page.title'
            defaultMessage='Développeurs'
          />
          <BadgeBeta />
        </Item>
        <Item
          onClick={() => {
            history.push('/invoices')
          }}
          className='titleMenu'
        >
          <FormattedMessage id='invoices' defaultMessage='Facturation' />
        </Item>
        <Item
          onClick={() => {
            history.push('/account')
          }}
        >
          <span className='titleMenu'>
            <FormattedMessage
              id='menu.settingsTitle'
              defaultMessage='Paramètres'
            />
          </span>
        </Item>
      </SubMenu>
      {user.admin ? (
        <SubMenu
          key='subAdmin'
          title={(
            <span className='titleMenu'>
              <Icon type='desktop' />
              <FormattedMessage id='menu.adminTitle' defaultMessage='Admin' />
            </span>
          )}
        >
          <Item
            onClick={() => {
              history.push('/workshop')
            }}
            className='titleMenu'
          >
            <FormattedMessage
              id='menu.workshop'
              defaultMessage='Atelier'
            />
          </Item>
          <Item
            onClick={() => {
              history.push('/catalog')
            }}
            className='titleMenu'
          >
            <FormattedMessage
              id='menu.catalog'
              defaultMessage='Catalogue'
            />
          </Item>
        </SubMenu>
      ) : null}
    </Menu>
    <Logo
      type='beige-bird'
      width='225px'
      height='auto'
      style={{
        position: 'fixed',
        bottom: '-35px',
        left: '-35px',
        opacity: 0.4,
        zIndex: 1,
      }}
    />
  </Sider>
)

export default withRouter(NavMenu)

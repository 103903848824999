import React from 'react'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { FormattedSwitch } from '../..'

const CampaignSurpriseInput = (props) => {
  const {
    campaign, // Required
    setCampaign, // Required
    setNeedUpdateCampaign, // Required
    checkedSurprise, // Required
    setCheckedSurprise, // Required
    isDisabled = false,
    size,
  } = props

  const onChangeSurprise = (checked) => {
    setCheckedSurprise(checked)
    // Si la surprise est activée, on vide la liste des produits
    if (checked) {
      setCampaign({ ...campaign, surprise: checked, products: [] })
    // Sinon on désactive la surprise
    } else {
      setCampaign({ ...campaign, surprise: checked })
    }
    // On enable la sauvegarde de campagne dans le header
      setNeedUpdateCampaign(true)
  }

  return (
    <FormattedSwitch
      checked={checkedSurprise}
      onChangeFunction={onChangeSurprise}
      title={(
        <FormattedMessage
          id='campaigns.create.step.parameters.surprise'
          defaultMessage='Cadeau surprise'
        />
      )}
      tooltipText={(
        <FormattedMessage
          id='campaigns.create.step.parameters.surprise.tooltip'
          defaultMessage='Les destinataires découvriront le cadeau sélectionné uniquement à la réception de leur colis.'
        />
      )}
      icon={faCircleInfo}
      isDisabled={isDisabled}
      size={size}
    />
  )
}

export default CampaignSurpriseInput

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { Dashboard } from '../../containers'
import { INIT_FETCH_DATA } from '../../store/dashboard/actions'

const DashboardPage = (props) => {
  const {
    history, user, shopId, fetchData,
  } = props
  // On récupère les données au montage du composant, l'effet ne sera relancé que si shopId est modifié
  // fetchData dispatch INIT_FETCH_DATA_DASHBOARD qui lance la saga de récupération de données
  useEffect(() => {
    fetchData()
  }, [shopId])

  return (
    <div style={{
      height: '100%',
      backgroundColor: 'white',
    }}
    >
      <Dashboard history={history} user={user} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(DashboardPage))

/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Cart as CartDrawer } from '../../components'
import {
  fromProducts, fromCart, fromSuppliers, fromShops, fromLocale, fromUsers, fromEntities,
} from '../../store/selectors'
import {
  EMPTY, REMOVE, EDIT_CUSTOMIZATIONS, COUNTRY,
} from '../../store/cart/actions'

export default connect(
  (state) => ({
    allProducts: fromProducts.allProducts(state),
    cartContent: fromCart.getContent(state),
    cartPrice: fromCart.getPrice(state),
    productsPrice: fromCart.getProductsPricing(state).total,
    deliveryPrice: fromCart.getDeliveryPricing(state).delivery_discounted,
    suppliers: fromSuppliers.getSuppliers(state),
    shop: fromShops.getShop(state),
    locale: fromLocale.getLocale(state),
    user: fromUsers.getUser(state),
    entities: fromEntities.getEntities(state),
  }),
  (dispatch) => bindActionCreators({
    empty: () => ({
      type: EMPTY,
    }),
    remove: (target) => ({
      type: REMOVE,
      payload: {
        target,
      },
    }),
    customizeProduct: (cart_id, clientCustomization) => ({
      type: EDIT_CUSTOMIZATIONS,
      payload: {
        cart_id,
        clientCustomization,
      },
    }),
    changeCartCountry: (country) => ({
      type: COUNTRY,
      payload: {
        country,
      },
    }),
  }, dispatch),
)(({
  visible,
  onClose,
  allProducts,
  cartContent,
  cartPrice,
  productsPrice,
  deliveryPrice,
  empty,
  remove,
  customizeProduct,
  shop,
  suppliers,
  changeCartCountry,
  locale = 'fr',
  entities,
  user,
}) => (
  <CartDrawer
    visible={visible}
    onClose={onClose}
    allProducts={allProducts}
    shop={shop}
    suppliers={suppliers}
    cartContent={cartContent}
    cartPrice={cartPrice}
    productsPrice={productsPrice}
    deliveryPrice={deliveryPrice}
    empty={empty}
    remove={remove}
    customizeProduct={customizeProduct}
    changeCartCountry={changeCartCountry}
    locale={locale}
    entities={entities}
    user={user}
  />
))

export const GET = 'GET_APIKEYS'
export const RECEIVED = 'RECEIVED_APIKEYS'
export const FETCH_FORCE = 'FETCH_FORCE_APIKEYS'
export const LOADING = 'LOADING_APIKEYS'
export const ERROR = 'ERROR_APIKEYS'
export const CREATE = 'CREATE_APIKEY'
export const CREATED = 'CREATED_APIKEY'
export const RESET = 'RESET_APIKEY'
export const UPDATE = 'UPDATE_APIKEY'
export const DELETE = 'DELETE_APIKEY'
export const INIT_FETCH_DATA = 'INIT_FETCH_DATA_APIKEYS'
export const END_FETCH_DATA = 'END_FETCH_DATA_APIKEYS'

import React, { useState } from 'react'
import {
  Steps, Col, Button, Icon, Popconfirm, Result,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import ButtonGroup from 'antd/lib/button/button-group'
import checkCreationProduct from '../../../helpers/products/creation'
import {
  ProductVariationsTransfer,
  ProductAWSUploadForm,
  ProductCustomizationForm,
  ProductInfoForm,
  ProductStockForm,
  ProductRecap,
} from '../..'

const { Step } = Steps

const NavigationButton = (props) => {
  const {
    product,
    currentStep,
    nextStep,
    previousStep,
  } = props
  if (product._id) return null
  return (
    <ButtonGroup style={{ margin: '20px 0px', width: '100%' }}>
      <Button
        type='primary'
        style={{ width: '50%' }}
        disabled={currentStep === 0}
        onClick={() => previousStep()}
      >
        <Icon type='left' />
        <FormattedMessage id='previous' defaultMessage='Précédent' />
      </Button>
      <Button
        type='primary'
        style={{ width: '50%' }}
        disabled={currentStep === 4}
        onClick={() => nextStep()}
      >
        <FormattedMessage id='next' defaultMessage='Suivant' />
        <Icon type='right' />
      </Button>
    </ButtonGroup>
  )
}
const SubmitButton = (props) => {
  try {
    const {
      displayButtonValidate,
      currentStep,
      createProduct,
      createStock,
      removeProducts,
      loadingCreation,
      product,
    } = props
    const condition = displayButtonValidate && currentStep === 5

    // Edition d'un produit
    if (condition && product._id) {
      return (
        <Button
          type='primary'
          loading={loadingCreation}
          onClick={() => {
            const callback = removeProducts(product.provisionalId)
            createStock({
              target: product._id,
              stock: product.stock,
              variations: product.variations,
            }, callback)
          }}
          block
        >
          <FormattedMessage
            id='baseProductForm.modifyProduct'
            defaultMessage='Modifier ce produit'
          />
          <span style={{ marginLeft: '10px' }} role='img' aria-label='rocket'>
            🚀
          </span>
        </Button>
      )
    }

    // Création d'un produit
    if (condition) {
      return (
        <Popconfirm
          icon={(
            <Icon
              style={{ fontSize: '16px', color: '#012169' }}
              type='info-circle'
            />
          )}
          title={
            <FormattedMessage id='lastStep' defaultMessage='Dernière étape !' />
          }
          onConfirm={() => {
            createProduct({ product })
            removeProducts(product.provisionalId)
          }}
          onCancel={() => {
            createProduct({ product })
          }}
          okText={(
            <FormattedMessage
              id='createProductAndClose'
              defaultMessage="Créer ce produit et fermer l'onglet"
            />
          )}
          cancelText={(
            <FormattedMessage
              id='createProduct'
              defaultMessage='Créer ce produit'
            />
          )}
        >
          <Button type='primary' loading={loadingCreation} block>
            <FormattedMessage
              id='createProduct'
              defaultMessage='Créer ce produit'
            />
            <span style={{ marginLeft: '10px' }} role='img' aria-label='rocket'>
              🚀
            </span>
          </Button>
        </Popconfirm>
      )
    }
    return null
  } catch (e) {
    console.log('error organisms/ProductOnDemandForm/SubmitButton', e)
    return null
  }
}

const ProductOnDemandForm = (props) => {
  try {
    const {
      baseProducts,
      product,
      updateProduct,
      upload,
      createProduct,
      createStock,
      allShops,
      shopId,
      removeProducts,
      createdId,
      history,
      loadingCreation,
      allCodes,
      allEntities,
      locale,
    } = props

    const { baseProduct } = product
    let variations = baseProduct?.variations

    // * Si pas de variations au niveau du BP accroché au CP on récupère les variations directement depuis le BP
    if (!variations || variations?.length === 0) {
      const baseProductFound = baseProducts?.find((bp) => bp?._id === product?.supplierBaseProductId || bp?._id === product?.baseProduct?._id)
      variations = baseProductFound?.variations
    }

    const [currentStep, setCurrentStep] = useState(0)
    const [displayButtonValidate, setDisplayButtonValidate] = useState(false)
    const [errorCreation, setErrorCreation] = useState([])

    if (createdId === product.provisionalId) {
      return (
        <Result
          icon={<Icon type='check-circle' style={{ color: '#012169' }} />}
          title={(
            <FormattedMessage
              id='productCreated'
              defaultMessage='Parfait, le produit est créé !'
            />
          )}
          extra={[
            <Button
              type='primary'
              onClick={() => {
                updateProduct(product.provisionalId, {
                  provisionalId: Math.random().toString(8),
                  stock: [],
                  stocked: false,
                })
              }}
            >
              <FormattedMessage
                id='continueProductCreation'
                defaultMessage='Reprendre la création du produit'
              />
            </Button>,
            <Button
              onClick={() => {
                history.push('/products')
              }}
            >
              <FormattedMessage
                id='closeWorkshop'
                defaultMessage="Fermer l'atelier"
              />
            </Button>,
          ]}
        />
      )
    }

    const handleDisplayButton = () => {
      const arrayError = checkCreationProduct(product, setCurrentStep)
      setErrorCreation(arrayError)
      if (arrayError.length === 0) {
        setDisplayButtonValidate(true)
      }
      if (arrayError.length) {
        setDisplayButtonValidate(false)
      }
    }

    const previousStep = () => {
      const previous = currentStep - 1
      setCurrentStep(previous)
      handleDisplayButton()
    }

    const nextStep = () => {
      const next = currentStep + 1
      setCurrentStep(next)
      handleDisplayButton()
    }

    return (
      <div>
        <Col
          span={6}
          style={{
            padding: '24px',
          }}
        >
          <Steps
            direction='vertical'
            current={currentStep}
            onChange={(current) => {
              setCurrentStep(current)
              handleDisplayButton()
            }}
          >
            <Step
              title={(
                <FormattedMessage
                  id='steps.variationsChoice'
                  defaultMessage='Choix des variations'
                />
              )}
            />
            <Step
              icon={product._id && <Icon type='stop' />}
              disabled={product._id}
              title={(
                <FormattedMessage
                  id='steps.settings'
                  defaultMessage='Paramètres généraux'
                />
              )}
            />
            <Step
              title={(
                <FormattedMessage
                  id='steps.stock'
                  defaultMessage='Gestion du stock'
                />
              )}
              disabled={product?.baseProduct?.dematerialized}
              description={
                product?.baseProduct?.dematerialized ? (
                  <FormattedMessage
                    id='steps.stockHelper'
                    defaultMessage='Stock créer automatiquement pour les produits dématérialisé'
                  />
                ) : (
                  false
                )
              }
            />
            <Step
              icon={product._id && <Icon type='stop' />}
              disabled={product._id}
              title={(
                <FormattedMessage
                  id='steps.images'
                  defaultMessage='Gestion des images'
                />
              )}
            />
            <Step
              icon={product._id && <Icon type='stop' />}
              disabled={product._id}
              title={(
                <FormattedMessage
                  id='steps.moreSettings'
                  defaultMessage='Paramètres supplémentaires'
                />
              )}
            />
            <Step
              title={(
                <FormattedMessage
                  id='steps.summary'
                  defaultMessage='Récapitulatif'
                />
              )}
            />
          </Steps>
          <NavigationButton product={product} currentStep={currentStep} previousStep={previousStep} nextStep={nextStep} />
          <SubmitButton
            displayButtonValidate={displayButtonValidate}
            currentStep={currentStep}
            createProduct={createProduct}
            createStock={createStock}
            removeProducts={removeProducts}
            loadingCreation={loadingCreation}
            product={product}
          />
        </Col>
        <Col
          span={18}
          style={{
            padding: '24px',
          }}
        >
          {currentStep === 0 && (
            <ProductVariationsTransfer
              product={product}
              variations={variations}
              updateProduct={updateProduct}
            />
          )}
          {currentStep === 1 && (
            <ProductInfoForm
              product={product}
              updateProduct={updateProduct}
              allShops={allShops}
              shopId={shopId}
              allCodes={allCodes}
            />
          )}
          {currentStep === 2 && (
            <ProductStockForm
              product={product}
              updateProduct={updateProduct}
              allEntities={allEntities}
              allShops={allShops}
            />
          )}
          {currentStep === 3 && (
            <ProductAWSUploadForm
              product={product}
              updateProduct={updateProduct}
              upload={upload}
            />
          )}
          {currentStep === 4 && (
            <ProductCustomizationForm
              product={product}
              updateProduct={updateProduct}
              locale={locale}
            />
          )}
          {currentStep === 5 && (
            <ProductRecap
              product={product}
              allShops={allShops}
              errorCreation={errorCreation}
              createdId={createdId}
              locale={locale}
            />
          )}
        </Col>
      </div>
    )
  } catch (e) {
    console.log('error organisms/ProductOnDemandForm', e)
    return null
  }
}

export default ProductOnDemandForm

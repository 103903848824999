import { faShop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Button,
  Layout,
  Select,
} from 'antd'
import React from 'react'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import theme from '../../../config/theme'

import { setLocale as setLocaleAction } from '../../../store/locale/actions'
import { fromLocale } from '../../../store/selectors'
import { getShop } from '../../../store/shops/actions'

const { Header } = Layout
const { Option } = Select

const ShopSwitch = ({ availableShops: shops, switch: shopSwitch }) => {
  const intl = useIntl()
  return (
    <Select
      showSearch
      style={{ width: '200px' }}
      placeholder={intl.formatMessage({
        id: 'header.shopSwitch',
        defaultMessage: 'Changer de boutique',
      })}
      optionFilterProp='children'
      onChange={shopSwitch}
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {shops.map((shop) => (
        <Option value={shop._id} key={shop._id}>
          {shop.settings.name}
        </Option>
      ))}
    </Select>
  )
}

const LanguageSwitch = ({ locale, setLocale }) => (
  <Select
    showSearch
    style={{ width: '60px', border: 'none' }}
    placeholder={<FormattedMessage id='menu.language' defaultMessage='Langue' />}
    value={locale}
    onChange={(value) => setLocale(value)}
  >
    <Option value='fr'>
      🇫🇷
    </Option>
    <Option value='en'>
      🇬🇧
    </Option>
  </Select>
)

const HeaderPanopli = ({
  history,
  shopUrl,
  availableShops = [],
  switch: switchAction,
  fetchShop,
  user,
  locale,
  setLocale,
}) => (
  <Header
    style={{
      backgroundColor: theme.white,
      height: 64,
      padding: '0px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderBottom: '1px solid #EAEAEA',
      gap: '10px',
    }}
  >
    <Button
      style={{ width: '175px', zIndex: '100' }}
      type='link'
      href={`https://${shopUrl}.niceshop.co`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <span style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        cursor: 'pointer',
      }}
      >
        <FontAwesomeIcon icon={faShop} />
        <FormattedMessage id='menu.seeMyShop' defaultMessage='Voir ma boutique' />
      </span>
    </Button>
    {availableShops.length > 1 ? (
      <ShopSwitch switch={switchAction} availableShops={availableShops} />
    ) : null}
    <LanguageSwitch locale={locale} setLocale={setLocale} />
    <div
      id='userHeaderHover'
      onClick={() => {
        history.push('/account')
        fetchShop()
      }}
      style={{
        cursor: 'pointer', display: 'flex', alignItems: 'center',
      }}
    >
      <Avatar icon='user' />
      <span
        style={{
          margin: '0 20px 0 10px',
          height: '48px',
          display: 'flex',
          alignItems: 'center',
          minWidth: 'auto',
          color: theme.character.secondary,
        }}
      >
        {user?.additionalInfos?.firstName || ''}
      </span>
    </div>
  </Header>
)

export default injectIntl(withRouter(connect((state) => ({
  locale: fromLocale.getLocale(state),
}), (dispatch) => bindActionCreators({
  fetchShop: getShop,
  setLocale: (locale) => dispatch(setLocaleAction(locale)),
}, dispatch))(HeaderPanopli)))

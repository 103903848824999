import React from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { FormattedMessage } from 'react-intl'
import formattedData, { headers } from '../../helpers/formatForCSV'
import {
  fromOrders, fromProducts, fromLocale,
} from '../../store/selectors'

export default connect(
  (state) => ({
    allOrders: fromOrders.getOrders(state),
    allProducts: fromProducts.allProducts(state),
    locale: fromLocale.getLocale(state),
  }),
  null,
)(({
  allOrders, allProducts, selectedData, locale = 'fr',
}) => {
  const formatted = formattedData(selectedData.length ? selectedData : allOrders, allProducts, locale)
  return (
    <CSVLink
      data={formatted}
      headers={headers(locale)}
      filename='extract-orders.csv'
      target='_blank'
    >
      <Button key='1' type='primary'>
        <FormattedMessage id='CSVDowloader.export' defaultMessage='Exporter en CSV' />
      </Button>
    </CSVLink>
  )
})

/* eslint-disable camelcase */
import React from 'react'
// import { FormattedMessage } from 'react-intl'
import getBPVariations from '../getBPVariations'

const iconCurrency = () => ('€')

const getUnitPrice = (item) => {
  try {
    return (
      parseFloat(item.baseProduct.unitPrice || 0)
      + parseFloat(item.pricing.customizationPrice || 0)
      + parseFloat(item.pricing.margin || 0)
    )
  } catch (e) {
    return false
  }
}

const getPricePanopli = (item) => {
  try {
    return (
      parseFloat(item.baseProduct.unitPrice || 0)
      + parseFloat(item.pricing.customizationPrice || 0)
    )
  } catch (e) {
    return false
  }
}

const getPriceExport = (product, productFromOrder) => {
  try {
    return (
      parseFloat(product.unitPrice || product.baseProduct.unitPrice || 0)
      + parseFloat(product.pricing.customizationPrice || 0)
      + parseFloat(product.pricing.margin || 0)
      + parseFloat(productFromOrder.variations.size?.priceImpact || 0)
      + parseFloat(productFromOrder.variations.color?.priceImpact || 0)
    )
  } catch (e) {
    return false
  }
}

/**
 * Permet de gérer l'affichage d'un prix avec ou sans décimales en fonction de sa valeur (présence ou absence de centimes)
 * @param {number} price - Le prix à afficher (en euros, avec ou sans centimes)
 * @returns Le prix à afficher (sans virgule si le montant en centimes est nul, avec deux décimales sinon)
 */
const displayPrice = (price) => (typeof (price) === 'number' && price?.toFixed(2).replace('.00', '')) || '-'

/**
 *
 * @description returns a price object with two keys: the price, and the impact
 * @param {Object} init_variations_selected current
 *  product variations (should include color and size)
 * @param {Object} product The product
 * @param {Object} campaign the shop current campaign
 * @param {boolean} isReduct Shall we apply discounts
 * @param {Object} [theme={}] the shop theme
 * @param {Object} address delivery address
 * @returns {Object} the pricing object
 */
// eslint-disable-next-line max-params
const getPrice = (
  init_variations_selected,
  product,
  campaign,
  isReduct,
  address,
  displayImpact = true,
) => {
  try {
    let impact = false
    // let oldPrice = null
    const variations_selected = Object.keys(init_variations_selected).map((va) => init_variations_selected[va]
          || product.baseProduct.variations.find((_va) => _va.type === va)) || {}

    const bp_vars = product.baseProduct.variations
      ? product.baseProduct.variations.filter((va) => Object.values(variations_selected)
        .find((va_) => va_.reference === va.reference && va_.type === va.type))
      : []

    // NOTE get basic price
    let price = getUnitPrice(product)

    if (displayImpact) {
      if (bp_vars && !bp_vars.length) {
        const variations_color = getBPVariations(product, 'color')
        const variations_size = getBPVariations(product, 'size')
        // no init_variations selected , fallback on product.variations to find the lowest priceImpact
        let colorMin = 0
        let sizeMin = 0
        if (variations_color.length) {
          colorMin = Math.min(...variations_color.map((v) => v.priceImpact || 0))
        }
        if (variations_size.length) {
          sizeMin = Math.min(...variations_size.map((v) => v.priceImpact || 0))
        }
        price = price + colorMin + sizeMin
      } else {
        price += bp_vars.map((va) => parseFloat(va.priceImpact) || 0).reduce((a, b) => a + b, 0)
      }
    }
    // apply campaigns / discounts
    // if (product.pricing && product.pricing.discountValue) {
    //   oldPrice = price
    //   price = price - product.pricing.discountValue
    //   price = Math.round(price * 100) / 100
    // }

    // apply BP variation impact
    if (getBPVariations(product).find((va) => va.priceImpact)) {
      impact = true
    }

    // convert to custom currency
    // if (theme.custom && theme.custom.conversion && theme.custom.conversion.rateEurosToCredit) {
    //   price =
    //     price % 1 === 0 ?
    //       parseInt(price, 10) * theme.custom.conversion.rateEurosToCredit :
    //       price * theme.custom.conversion.rateEurosToCredit
    //   if (oldPrice) {
    //     oldPrice =
    //     oldPrice % 1 === 0 ?
    //       parseInt(oldPrice, 10) * theme.custom.conversion.rateEurosToCredit :
    //       oldPrice * theme.custom.conversion.rateEurosToCredit
    //   }
    // }

    return {
      value: price % 1 === 0 ? parseInt(price, 10) : parseFloat(price.toFixed(2)),
      impact,
    }
  } catch (e) {
    // console.log('getPrice error', e, { init_variations_selected, product })
    return false
  }
}

const getSubTotal = (productsPricing, theme) => {
  try {
    let price = productsPricing.total
    if (theme.custom && theme.custom.conversion && theme.custom.conversion.rateEurosToCredit) {
      price *= theme.custom.conversion.rateEurosToCredit
    }
    return (
      <span className='class-discount'>
        {displayPrice(price)}
        {iconCurrency(theme)}
      </span>
    )
  } catch (e) {
    console.log('getSubTotal error', e)
    return false
  }
}

const getDelivery = (deliveryPricing, theme) => {
  try {
    let price = deliveryPricing
    if (theme.custom && theme.custom.conversion && theme.custom.conversion.rateEurosToCredit) {
      price = deliveryPricing * theme.custom.conversion.rateEurosToCredit
    }
    if (!price) {
      return (
        // <FormattedMessage
        //   id='CheckoutRecap.freeDelivery'
        //   defaultMessage='Gratuit'
        // />
        'Gratuit'
      )
    }
    return (
      <span className='class-discount'>
        {displayPrice(price)}
        {iconCurrency(theme)}
      </span>
    )
  } catch (e) {
    console.log('getDelivery error', e)
    return false
  }
}

const getDiscounted = (productsPricing, theme) => {
  try {
    let price = productsPricing.campaign_discount + productsPricing.code_discount
    if (theme.custom && theme.custom.conversion && theme.custom.conversion.rateEurosToCredit) {
      price *= theme.custom.conversion.rateEurosToCredit
    }
    return (
      <span>
        {'- '}
        {displayPrice(price)}
        {iconCurrency(theme)}
      </span>
    )
  } catch (e) {
    console.log('getDiscounted error', e)
    return false
  }
}

const getTotal = (_price, theme = {}) => {
  try {
    let price = parseFloat(_price)
    if (
      theme
      && theme.custom
      && theme.custom.conversion
      && theme.custom.conversion.rateEurosToCredit
    ) {
      price *= theme.custom.conversion.rateEurosToCredit
    }
    return (
      <span className='class-discount'>
        {displayPrice(price)}
        {iconCurrency(theme)}
      </span>
    )
  } catch (e) {
    console.log('getTotal error', e)
    return false
  }
}

export default getPrice
export {
  getUnitPrice,
  getPricePanopli,
  getPriceExport,
  displayPrice,
  getPrice,
  getDelivery,
  getDiscounted,
  getTotal,
  getSubTotal,
}

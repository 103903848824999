import React from 'react'
import {
  List, Card,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import './index.css'
import theme from '../../../../config/theme'

export default class CodeTypeForm extends React.Component {
  handleNumberChange = (e) => {
    const number = parseInt(e.target.value || 0, 10)
    if (Number.isNaN(number)) {
      return
    }
    this.triggerChange({ number })
  };

  handleTypeChange = (type) => {
    this.triggerChange({ type })
  };

  triggerChange = (changedValue) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(changedValue.type)
    }
  };

  renderInfos = (type) => {
    const style = { fontWeight: 'bold' }
    switch (type) {
    case 'code':
      return (
        <p style={style}>
          <FormattedMessage
            id='codeTypeForm.helper.code'
            defaultMessage='Proposez une réduction sur une sélection de produits ou sur l’ensemble de votre boutique
            (code à renseigner lors de la validation du panier)'
          />
        </p>
      )
    case 'campaign':
      return (
        <p style={style}>
          <FormattedMessage
            id='codeTypeForm.helper.campaign'
            defaultMessage='Appliquez une réduction temporaire sur une sélection de produits
            ou sur l’ensemble de votre boutique.'
          />
        </p>
      )
    default:
      return null
    }
  }

  renderFormattedMessage = (type) => {
    switch (type) {
    case 'code':
      return <FormattedMessage id='code' defaultMessage='Code' />
    case 'campaign':
      return <FormattedMessage id='campaign' defaultMessage='Campagne' />
    default:
      return null
    }
  }

  render() {
    const { value } = this.props
    const data = ['code', 'campaign']
    return (
      <div>
        <List
          grid={{ gutter: 16, column: 2 }}
          dataSource={data}
          renderItem={(item) => {
            const style = value === item ? { boxShadow: `0 0 0 4px ${theme.primaryColor[6]}` } : {}
            return (
              <List.Item>
                <Card
                  style={{
                    height: '250px', textAlign: 'center', ...style, cursor: 'pointer',
                  }}
                  title={(
                    <span style={{ textAlign: 'center' }}>
                      {this.renderFormattedMessage(item)}
                    </span>
                  )}
                  onClick={() => this.handleTypeChange(item)}
                  hoverable
                >
                  {this.renderInfos(item)}
                </Card>
              </List.Item>
            )
          }}
        />
      </div>
    )
  }
}

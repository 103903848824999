import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Form,
  Tag,
  Checkbox,
} from 'antd'

import { StreamlabsTest } from '../..'

import config from '../../../config'

class StreamlabsSettings extends React.Component {
  constructor(props) {
    super(props)
    const {
      shop: {
        settings: {
          streamlabs = {},
        },
      },
    } = props
    const { options = {} } = streamlabs
    const { displayUserMessage = false } = options
    this.state = {
      displayMessage: displayUserMessage,
      displayButton: false,
    }
  }

  componentDidMount() {
    const { shop } = this.props
    if (new URL(window.location.href).searchParams.get('code')
    && shop.settings
    && !shop.settings.streamlabs) {
      try {
        const { modifyShop } = this.props

        const saveSettings = {
          settings: {
            ...shop.settings,
            streamlabs: {
              ...shop.settings.streamlabs,
              temporaryCode: new URL(window.location.href).searchParams.get('code'),
            },
          },
        }
        modifyShop({
          shop_id: shop._id,
          modifications: saveSettings,
        })
      } catch (e) {
        console.log(e, 'error StreamlabsSettings/componentDidMount')
      }
    }
  }

  connectStreamlabs = () => {
    try {
      const response_type = 'code'
      const { client_id, redirect_uri } = config.streamlabs
      const scope = 'donations.create'
      window.location.replace(`https://streamlabs.com/api/v1.0/authorize?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}`)
    } catch (e) {
      console.log('Cannot connect to streamlabs', e)
    }
  }

  disconnectStreamlabs = () => {
    const { shop, modifyShop } = this.props
    const saveSettings = {
      settings: {
        ...shop.settings,
      },
    }
    delete saveSettings.settings.streamlabs
    modifyShop({
      shop_id: shop._id,
      modifications: saveSettings,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFields,
      },
      modifyShop,
      shop,
    } = this.props
    validateFields((err, _values) => {
      const values = _values
      if (!err) {
        const {
          displayUserMessage,
        } = values
        const saveSettings = {
          settings: {
            ...shop.settings,
            streamlabs: {
              ...shop.settings.streamlabs,
              options: {
                ...shop.settings.streamlabs.options,
                displayUserMessage,
              },
            },
          },
        }
        modifyShop({
          shop_id: shop._id,
          modifications: saveSettings,
        })
        this.setState({ displayButton: false })
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        resetFields,
      },
      shop,
      testNotificationStreamlabs,
    } = this.props
    const { displayMessage, displayButton } = this.state
    if (shop.settings && !shop.settings.streamlabs) {
      return (
        <Button onClick={this.connectStreamlabs} style={{ marginBottom: 20 }}>
          <FormattedMessage id='streamlabs.connect' defaultMessage='Connecter Panopli à Streamlabs' />
        </Button>
      )
    } return (
      <Form id='streamlabs'>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ marginBottom: 10 }}>
            <FormattedMessage
              id='streamlabs.helper1'
              defaultMessage='Vous pouvez directement definir sur Streamlabs le message affiché en live.'
            />
          </span>
          <span>
            <FormattedMessage
              id='streamlabs.variables'
              defaultMessage='Les variables utilisables sont'
            />
            <Tag style={{ marginLeft: 7 }}>name</Tag>
            <FormattedMessage id='and' defaultMessage='et' />
            <Tag style={{ marginLeft: 7 }}>message</Tag>
          </span>
          <span style={{ marginTop: 10 }}>
            <FormattedMessage
              id='streamlabs.helper2'
              defaultMessage='Pour vous laisser définir un affichage spécifique,
            nous avons fixé la valeur de la donation à'
            />
            <Tag style={{ marginLeft: 7 }}>0.1</Tag>
            .
          </span>
        </div>
        <Form.Item style={{ marginBottom: 10 }}>
          {getFieldDecorator('displayUserMessage', {
            valuePropName: 'checked',
            initialValue: shop.settings
              && shop.settings.streamlabs
              && shop.settings.streamlabs.options
              && shop.settings.streamlabs.options.displayUserMessage,
          })(
            <Checkbox
              onChange={(e) => this.setState({
                displayMessage: e.target.checked,
                displayButton: true,
              })}
            >
              <FormattedMessage id='streamlabs.helper3' defaultMessage='Autoriser vos acheteurs à laisser un message' />
            </Checkbox>,
          )}
        </Form.Item>
        {displayButton ? (
          <Form.Item>
            <Button
              type='primary'
              style={{ marginRight: 20 }}
              onClick={this.handleSubmit}
            >
              <FormattedMessage id='modify' defaultMessage='Modifier' />
            </Button>
            <Button onClick={() => resetFields()}>
              <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
            </Button>
          </Form.Item>
        ) : null}
        <StreamlabsTest
          shop={shop}
          testNotificationStreamlabs={testNotificationStreamlabs}
          displayMessage={displayMessage}
        />
        <Button style={{ marginBottom: 20 }} onClick={this.disconnectStreamlabs}>
          <FormattedMessage id='streamlabs.disconnect' defaultMessage='Me déconnecter' />
        </Button>
      </Form>
    )
  }
}

export default Form.create({ name: 'streamlabs' })(withRouter(StreamlabsSettings))

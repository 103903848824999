/*
 * Returns the id of the entity of the user
 * @param {*} user
 * @param {*} entities
 * @param {*} shop
 * @returns
 */
const getEntityId = (user, entities = [], shop) => {
  try {
    const { collaborator, adminCollaborator } = user
    if (user._id === shop.owner || user.admin) {
      // Si on a une seule entité, on la retourne
      if (entities.length === 1) return { entityId: entities.find(Boolean)._id, error: false }

      // Si on plusieurs entités, on retourne la main
      const entity = entities.find((ent) => ent.main)
      return { entityId: entity?._id, error: false }
    } if (collaborator || adminCollaborator) {
      const entity = entities.find((ent) => ent.collaborators.includes(user._id)
        || ent.adminCollaborators.includes(user._id))
      return { entityId: entity?._id, error: false }
    }
    return { entityId: entities.find((e) => e.main)._id, error: false }
  } catch (e) {
    console.log(e, 'error getEntityId ')
    return e
  }
}

const getEntityCollaborators = (allCollaborators, entity) => {
  try {
    const { collaborators = [] } = entity
    const result = allCollaborators.filter((collaborator) => collaborators.includes(collaborator._id))
    return result
  } catch (e) {
    console.log('getEntityCollaborators - error', e)
    return []
  }
}

const getUserEntityStatus = (user, shop) => {
  try {
    const {
      _id, admin, adminCollaborator, collaborator,
    } = user
    const { owner } = shop
    if (admin) return 'admin'
    if (String(_id) === owner) return 'owner'
    if (adminCollaborator) return 'adminCollaborator'
    if (collaborator) return 'collaborator'
    return 'noEntity'
  } catch (e) {
    console.log('userStatus - error', e)
    return null
  }
}

export { getEntityCollaborators, getEntityId, getUserEntityStatus }

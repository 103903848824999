import React from 'react'
import {
  Form,
  Input,
  message,
  Button,
  Row,
} from 'antd'
import { FormattedMessage } from 'react-intl'

class MonitoringForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
        resetFields,
      },
      modifyShop,
      shop,
    } = this.props

    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          facebookPixel,
          ga,
          gtm,
        } = values
        const modifications = {
          settings: {
            ...shop.settings,
            tracking: {
              facebookPixel,
              ga,
              gtm,
            },
          },
        }

        modifyShop({
          shop_id: shop._id,
          modifications,
          callback: () => {
            resetFields()
            message.info(
              <FormattedMessage
                id='updateSuccess.shop'
                defaultMessage='Boutique mise à jour'
              />,
            )
            return true
          },
        })
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        isFieldsTouched,
        resetFields,
      },
      shopSettings,
    } = this.props

    const {
      tracking = {},
    } = shopSettings
    // const prefixSelector = getFieldDecorator('prefix', {
    //   initialValue: '33',
    // })(
    //   <Select style={{ width: 70 }}>
    //     <Option value='86'>+86</Option>
    //     <Option value='87'>+87</Option>
    //   </Select>,
    // )
    // TODO better input phone

    return (
      <Form
        onSubmit={this.handleSubmit}
      >
        <Form.Item label='Google Analytics'>
          {getFieldDecorator('ga', {
            rules: [
            ],
            initialValue: tracking.ga,
          })(<Input placeholder='UA-XXXXXX' />)}
        </Form.Item>
        <Form.Item label='Google Tag'>
          {getFieldDecorator('gtm', {
            rules: [
            ],
            initialValue: tracking.gtm,
          })(<Input placeholder='GTM-XXXXXX' />)}
        </Form.Item>
        <Form.Item label='Facebook Pixel'>
          {getFieldDecorator('facebookPixel', {
            rules: [
            ],
            initialValue: tracking.facebookPixel,
          })(<Input placeholder='XXXXXX' />)}
        </Form.Item>
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  marginRight: '12px',
                }}
              >
                <FormattedMessage id='save' defaultMessage='Enregistrer' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    )
  }
}

export default Form.create({ name: 'shopBasicSettings' })(MonitoringForm)

import React, { useState } from 'react'
import {
  Drawer, Button, Icon, notification, Switch,
} from 'antd'
import { CSVReader } from 'react-papaparse'
import { FormattedMessage } from 'react-intl'

const formatOrders = (rows) => rows.map((row) => {
  const { data } = row
  if (!data.EMAIL) {
    return false
  }
  return {
    email: data.EMAIL,
    tracking_number: data.TRACKING,
    order_number: data.ID,
    // FIXME
    custom_variables: {
      tracking_url: data.TRACKINGURL,
      shop_name: 'ZEVENT',
    },
  }
}).filter(Boolean)

const Shipup = (props) => {
  const { subscribe, closeShipupDrawer } = props
  const [orders, setOrders] = useState([])
  const [lang, setLang] = useState('fr')
  return (
    <div style={{ textAlign: 'center' }}>
      <h4>
        <FormattedMessage id='shipup.upload' defaultMessage='Uploadez votre CSV' />
      </h4>
      <CSVReader
        isReset
        onDrop={async (rows) => {
          const result = formatOrders(rows)
          if (!result.length) {
            notification.error({
              message: <FormattedMessage id='shipup.wrongFormat' defaultMessage='CSV mal formatté' />,
              description: <FormattedMessage
                id='shipup.helper1'
                defaultMessage='Votre CSV est mal formatté ! (informations importantes manquantes / mauvais intitulés de colonnes...)'
              />,
            })
            return setOrders([])
          }
          return setOrders(result)
        }}
        config={{ header: true }}
        onRemoveFile={() => setOrders([])}
        addRemoveButton
        style={{
          dropFile: {
            height: 100,
            borderRadius: 3,
            width: 150,
            padding: 0,
          },
          fileSizeInfo: {
            display: 'none',
          },
          fileNameInfo: {
            padding: 5,
            lineHeight: 'inherit',
          },
          progressBar: {
            backgroundColor: '#0000FF',
          },
        }}
      >
        <Button type='primary'>
          <Icon type='upload' />
          <FormattedMessage id='filledCSV' defaultMessage='CSV rempli' />
        </Button>
      </CSVReader>
      <h4 style={{ marginTop: 15 }}>
        <FormattedMessage id='shipup.targetLanguage' defaultMessage='Choisissez la langue du destinataire' />
      </h4>
      <span>
        <FormattedMessage id='shipup.helper2' defaultMessage="(elle s'appliquera pour tous)" />
      </span>
      <div style={{ marginTop: 15 }}>
        <Switch
          unCheckedChildren='🇫🇷'
          checkedChildren='🇬🇧'
          onChange={(checked) => setLang(checked ? 'en' : 'fr')}
        />
      </div>
      <Button
        onClick={async () => {
          await subscribe(orders, lang)
          closeShipupDrawer()
        }}
        disabled={!(orders.length)}
        style={{ marginTop: 15 }}
      >
        <FormattedMessage id='send' defaultMessage='Envoyer' />
        {' '}
        🚀
      </Button>
    </div>
  )
}

const ShipupDrawer = (props) => {
  const {
    visible, subscribe, closeShipupDrawer,
  } = props
  return (
    <Drawer
      visible={visible}
      onClose={closeShipupDrawer}
      destroyOnClose
    >
      <Shipup subscribe={subscribe} closeShipupDrawer={closeShipupDrawer} />
    </Drawer>
  )
}

export default ShipupDrawer

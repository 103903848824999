import React, { useState } from 'react'
import {
  Icon, Select, Divider, Alert, DatePicker, Switch,
} from 'antd'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { uniq, uniqBy } from 'lodash'

import { trimInput } from '../../../helpers/trim'
import { hasValidEmailFormat, hasAlreadyBeenSent } from '../../../helpers/magicLinks'

import { MagicLinksCreationWarnings, MagicLinksCreationEmailsInsertion, MagicLinksCreationList } from '..'

const { Option } = Select

const MagicLinksCreationForm = (props) => {
  try {
    const {
      magicLinks,
      campaigns,
      campaign,
      creationState,
      setCreationState,
    } = props

    const {
      campaignId,
      newMagicLinks,
      wrongFormat,
      alreadySent,
    } = creationState

    const todayDateMoment = moment()
    const dateFormat = 'DD/MM/YYYY'
    const dateHoursFormat = 'DD/MM/YYYY HH:mm'

    const [checkSentAt, setCheckSentAt] = useState(false)
    const [sentAt, setSentAt] = useState(todayDateMoment)

    /**
    * Ajoute un magicLink à la liste d'envois
    * @param {Object} magicLink - le magicLink à ajouter
    */
    const handleAdd = (magicLink) => {
      const newList = newMagicLinks
      newList.push(magicLink)
      const result = uniqBy(newList, 'email')
      // On met à jour le state gérant les magicLinks et en vidant l'input d'ajout d'emails
      setCreationState({ ...creationState, newMagicLinks: result, inputFirstName: undefined })
    }

    /**
    * Retire le magicLink donné de la liste d'envois
    * @param {Object} magicLink - le magicLink à retirer
    */
    const handleDelete = (magicLink) => {
      const newList = newMagicLinks
      newList.splice(newMagicLinks.indexOf(magicLink), 1)
      setCreationState({ ...creationState, newMagicLinks: newList })
    }

    /**
    * Compare la date d'aujourd'hui et la date renseigné pour savoir le temps de vie du magicLink
    * @param {Object} momentDate - la date (format moment) qui a été sélectionnée
    */
    const handleLifeTime = (momentDate) => {
      const lifetime = momentDate.diff(todayDateMoment, 'days')
      setCreationState({ ...creationState, lifetime: lifetime || 1 })
    }

    const handleSentAt = (momentDate = false) => {
      setSentAt(momentDate || todayDateMoment)
      setCreationState({ ...creationState, sentAt: momentDate || todayDateMoment })
    }

    /**
    * Vérifie que le magicLink doit bien figurer dans la liste d'envois
    * @param {Object} magicLink - Le magicLink à vérifier ({ email, firstname })
    * @param {String} origin - L'origine de l'email (input ou CSV => facultatif pour le changement de campagne)
    */
    const arbitrate = (magicLink, origin) => {
      try {
        const { email, firstName } = magicLink
        const trimmedEmail = trimInput(email)
        if (trimmedEmail.length) {
          if (!hasValidEmailFormat(trimmedEmail)) {
            // Si l'origine de l'email provient de l'input, on affiche le message d'erreur sous l'input
            if (origin === 'input') setCreationState({ ...creationState, isUnvalidInput: true })
            // Si l'origine de l'email provient du CVS, on ajoute l'email à la liste de warning du CSV
            if (origin === 'CSV') {
              const newWrongFormat = wrongFormat
              newWrongFormat.push(trimmedEmail)
              setCreationState({ ...creationState, wrongFormat: uniq(newWrongFormat) })
            }
            return null
          }
          // Si une campagne a déjà été sélectionnée
          if (campaignId) {
            if (hasAlreadyBeenSent(magicLink.email, magicLinks, campaignId)) {
              // On l'ajoute à la liste des mails déjà envoyés pour cette campagne en évitant les doublons
              const newAlreadySent = alreadySent
              newAlreadySent.push(trimmedEmail)
              setCreationState({ ...creationState, alreadySent: uniq(newAlreadySent) })
              // S'il était déjà présent dans la liste d'envois, on l'enlève
              if (newMagicLinks.includes(magicLink)) handleDelete(magicLink)
              return null
            }
          }
          // Si l'email est valide et non-utilisé dans la campagne, on l'ajoute à la liste d'envois
          handleAdd({ email: trimmedEmail, firstName })
        }
        return true
      } catch (e) {
        console.log('molecules/MagicLinkCreationForm/arbitrate:', e)
        return false
      }
    }

    // FIXME: même si le cas est géré en back, il serait bien de vérifier la liste d'envois
    // au changement de campagne (la même liste peut contenir des adresses mails déjà utilisées
    // dans la nouvelle campagne sélectionnée) pour informer l'utilisateur des adresses
    // qui ne feront pas l'objet d'un envoi car déjà utilisées dans la campagne sélectionnée.
    // Problème : l'ajout de la vérification empêche le changement de campagne => ligne 116 commentée
    // et listes vidées au changement de campagne (solution temporaire)
    /**
   * Gère le changement de campagne depuis le sélecteur
   * @param {String} value - L'id de la campagne sélectionnée
   */
    const handleCampaignChange = (value) => {
    // On modifie l'id de la campagne sélectionnée dans le state
      setCreationState({
        ...creationState, campaignId: value, alreadySent: [], newMagicLinks: [], wrongFormat: [], emailsByCSV: false,
      })
      // Si des emails ont déjà été entrés dans la liste d'envois, on vérifie qu'ils sont disponibles pour la campagne sélectionnée
      // if (newMagicLinks.length) newMagicLinks.map((magicLink) => arbitrate(magicLink))
    }

    return (
      <div style={{ padding: 24 }}>
        <MagicLinksCreationWarnings
          wrongFormat={wrongFormat}
          alreadySent={alreadySent}
        />
        <h3 style={{ fontWeight: 'bold', marginBottom: 10 }}>
          <FormattedMessage
            id='campaign'
            defaultMessage='Campagne'
          />
        </h3>
        {campaigns.length > 1 ? (
          <Select
            style={{ width: '100%' }}
            placeholder={(
              <>
                <Icon type='rocket' style={{ paddingRight: 4 }} />
                <FormattedMessage
                  id='campaignTrackingModal.placeholders.campaign'
                  defaultMessage='Sélectionnez la campagne correspondante'
                />
              </>
            )}
            value={campaignId}
            onChange={(value) => handleCampaignChange(value)}
            // disabled={campaignId && newMagicLinks.length}
          >
            {campaigns.map((iCampaign) => <Option key={iCampaign._id}>{iCampaign.name}</Option>)}
          </Select>
        ) : (
          <div style={{ fontWeight: 'bold', fontSize: '13px' }}>
            <FormattedMessage
              id='campaignTrackingModal.campaignSelected'
              defaultMessage='{campaignName}'
              values={{ campaignName: campaigns[0]?.name || 'campagne sans nom' }}
            />
            <Divider />
          </div>
        )}
        <h3 style={{ fontWeight: 'bold', margin: '10px 0' }}>
          <FormattedMessage
            id='expiration.date.link'
            defaultMessage={'Date d\'expiration du lien'}
          />
        </h3>
        <DatePicker
          defaultValue={moment(todayDateMoment, 'DD-MM-YYYY').add(14, 'days')}
          showToday={false}
          format={dateFormat}
          onChange={handleLifeTime}
          allowClear={false}
          disabledDate={(current) => current && current < moment(todayDateMoment, dateFormat)}
        />
        <h3 style={{ fontWeight: 'bold', margin: '10px 0' }}>
          <FormattedMessage
            id='send.date.link'
            defaultMessage={'Planifier l\'envoi'}
          />
        </h3>
        <div style={{
          display: 'flex', alignItems: 'center', transition: '0.02s',
        }}
        >
          <Switch
            checked={checkSentAt}
            onChange={(checked) => {
              handleSentAt()
              setCheckSentAt(checked)
            }}
            style={{ margin: '0px 10px 0 0' }}
          />
          <DatePicker
            style={{ opacity: checkSentAt ? 1 : 0, display: checkSentAt ? 'inline' : 'none' }}
            showTime={{ format: 'HH:mm' }}
            value={sentAt}
            format={dateHoursFormat}
            onChange={handleSentAt}
            allowClear={false}
            disabledDate={(current) => current && current < moment(todayDateMoment, dateHoursFormat)}
          />
        </div>
        <MagicLinksCreationEmailsInsertion
          creationState={creationState}
          setCreationState={setCreationState}
          arbitrate={arbitrate}
        />
        <MagicLinksCreationList
          creationState={creationState}
          setCreationState={setCreationState}
          handleDelete={handleDelete}
        />
        {campaign && (
          <div style={{ margin: '10px 0 10px 0' }}>
            {campaign?.products?.length > 1 ? (
              <FormattedMessage
                id='campaignTrackingModal.helper2.plurial'
                defaultMessage='Les destinataires recevront les produits de cette campagne ainsi que l’email correspondant.'
              />
            ) : (
              <FormattedMessage
                id='campaignTrackingModal.helper2.singular'
                defaultMessage='Les destinataires recevront le produit de cette campagne ainsi que l’email correspondant.'
              />
            )}
          </div>
        )}
        {campaign?.template?.body?.includes('{firstNameClient}') && (
          <Alert
            message={(
              <FormattedMessage
                id='campaignTrackingModal.template.firstNameClient'
                defaultMessage='Le template du mail que vous avez selectionné utilise le prénom du client'
              />
            )}
            type='warning'
          />
        )}
      </div>
    )
  } catch (e) {
    console.log('molecules/MagicLinksCreationForm error:', e)
    return null
  }
}

export default MagicLinksCreationForm

import { flatten } from 'lodash'
import { trimColorName } from '../trim'
import { getPrice } from '../baseProducts/creation'

const translateKey = (key) => {
  switch (key) {
  case 'constitution':
    return 'Constitution'
  case 'weight':
    return 'Poids'
  case 'originProduct':
    return 'Origine du produit'
  case 'originMark':
    return 'Origine marquage'
  case 'delayDelivery':
    return 'Délais de livraison en jours ouvrés'
  case 'dimension':
    return 'Dimension'
  case 'capacity':
    return 'Contenance'
  default:
    return ''
  }
}

const formatDescription = (description) => {
  if (!description || !description.fr) {
    return null
  }
  return `<h2> Description </h2><p> ${description.fr} </p>`
}
const formatCaracteristics = (caracteristics) => {
  if (!caracteristics) {
    return ''
  }
  const keys = Object.keys(caracteristics)
  let string = ''
  keys.map((k) => {
    if (!caracteristics[k]) {
      return null
    }
    // need to translate key
    return `<br> ${translateKey(k)} : ${caracteristics[k]}`
  }).filter(Boolean).map((carac) => {
    string += carac
    return true
  })
  return `<h2> Caractéristiques </h2> <p> ${string} </p>`
}

const formatTags = (baseProduct, providers) => {
  const {
    providerProductId, category: { fr }, subCategory, caracteristics = {},
  } = baseProduct
  const { delayDelivery = false, originProduct = false } = caracteristics
  const concernedProvider = providers.find((prov) => String(prov._id) === baseProduct.providerId)
  const { name } = concernedProvider
  const pro = 'membre pro : 10%'
  const id = `id : ${baseProduct.idPanopli}`
  const supplier = name ? `supplier : ${name}` : null
  const supplierRef = providerProductId ? `supplier : ${providerProductId}` : null
  const categories = fr.map((cat) => cat)
  const sub = `${subCategory.fr}`
  const origin = originProduct ? `origine : ${originProduct}` : null
  const delay = delayDelivery ? `délai : ${delayDelivery} jours ouvrés` : null
  const tags = [pro, id, supplier, supplierRef, ...categories, sub, delay, origin].filter(Boolean).join()
  return tags
}

const formatBodyHtml = (baseProduct) => {
  const { caracteristics, description } = baseProduct
  const formattedDescription = formatDescription(description)
  const formattedCaracteristics = formatCaracteristics(caracteristics)
  const result = `${formattedDescription} <!--split--> ${formattedCaracteristics}`
  return result
}

const generatePriceForVariant = (technic, color, stage, baseProduct) => {
  try {
    const { families, technics, pricing: { priceHT, costHT, margin } } = baseProduct
    const concernedTechnics = technics.find((t) => t.idPanopli === technic)
    const concernedFamily = families.find((t) => t.idPanopli === color)
    if (!concernedTechnics || !concernedFamily || !stage) {
      return {
        error: true, concernedFamily, concernedTechnics, stage, message: 'vital param not found',
      }
    }
    const { parameters: { price } } = concernedTechnics
    const { priceImpact = 0 } = concernedFamily
    const parsedStage = parseInt(stage, 10) - 1
    const concernedPrice = price[parsedStage]
    // Si l'on ne trouve pas de plage de prix pour la technique
    if (!concernedPrice && concernedPrice !== 0) {
      // On affiche une erreur dans la console (pour info)
      console.log('helpers/shopify/generatePriceForVariant error:', {
        error: true,
        message: 'no technic price found from stage',
      })
      // Et l'on retourne zéro pour ne pas faire bugger le calcul final ci-dessous
      return 0
    }
    // Si l'on ne trouve pas de prix pour la couleur
    if (!priceImpact && priceImpact !== 0) {
      // On affiche une erreur dans la console (pour info)
      console.log('helpers/shopify/generatePriceForVariant error:', {
        error: true,
        message: 'no color priceImpact',
      })
      // Et l'on retourne zéro pour ne pas faire bugger le calcul final ci-dessous
      return 0
    }
    return ((priceHT || getPrice(costHT, margin)) + priceImpact + concernedPrice).toFixed(2)
  } catch (e) {
    console.log('helpers/shopify/generatePriceForVariant error:', e)
    return {
      error: true,
    }
  }
}

const generateOptions = (techniques, colorFamilies) => {
  const Techniques = {
    name: 'Techniques',
    values: techniques.map((t) => t.idPanopli),
  }
  const Couleurs = {
    name: 'Couleurs',
    values: colorFamilies.map((c) => c.idPanopli),
  }
  let maxStageTechnic = { parameters: { stage: [] } }
  techniques.map((t) => {
    if (t.parameters.stage.length > maxStageTechnic.parameters.stage.length) {
      maxStageTechnic = t
    }
    return true
  })
  const Paliers = {
    name: 'Paliers',
    values: maxStageTechnic.parameters.stage.map((s, index) => String(index + 1)),
  }
  return [Techniques, Couleurs, Paliers]
}

const generateTitleForVariant = (subOption1, subOption2, subOption3) => `${subOption1} / ${subOption2} / ${subOption3}`

const compactTechnics = (baseProduct = {}) => {
  try {
    const { technics } = baseProduct
    return technics.map((technic) => {
      const { parameters } = technic
      const { stage, price, cost } = parameters
      return {
        ...technic,
        parameters: {
          price: price.length > 1 ? price.slice(0, price.length - 1) : price,
          cost: cost.length > 1 ? cost.slice(0, cost.length - 1) : cost,
          stage: stage.length > 1 ? stage.slice(0, stage.length - 1) : stage,
        },
      }
    })
  } catch (e) {
    return baseProduct.technics
  }
}

const absorbedColors = (families) => {
  const newFamilies = families.map((fam) => {
    const { absorbed } = fam
    if (absorbed && absorbed.length) {
      const allColors = flatten(absorbed.map((ab) => ab && ab.colors)).filter(Boolean)
      return {
        ...fam,
        colors: fam.colors.concat(allColors),
      }
    }
    return fam
  })
  return newFamilies
}

/**
 * Regroupement des couleurs pour éviter d'avoir trop de variations.
 *
 * On regroupe si la comparaison est inférieure à minPriceForAbsorption.
 */
const compactColors = (baseProduct) => {
  const { families } = baseProduct
  const minPriceForAbsorption = 0.35
  const sortedFamilies = families.sort((a, b) => b.priceImpact - a.priceImpact)
  const filtered = []
  const result = sortedFamilies.map((family, index) => {
    const absorbed = sortedFamilies.slice(index + 1).map((_fam) => {
      const calcule = family.priceImpact - _fam.priceImpact

      const comparaison = calcule >= 0 ? calcule : 0

      if (family.idPanopli === _fam.idPanopli || filtered.includes(_fam.idPanopli) || filtered.includes(family.idPanopli)) {
        return null
      }
      if (comparaison <= minPriceForAbsorption) {
        filtered.push(_fam.idPanopli)

        return _fam
      }
      return null
    })

    return {
      ...family,
      absorbed,
    }
  }).filter((fam) => !filtered.includes(fam.idPanopli))
  return result
}

const reducParams = (baseProduct, colorParam, techParam) => {
  const colors = { }
  const technics = { }
  if (colorParam) {
    colors.families = absorbedColors(compactColors(baseProduct))
  } if (techParam) {
    technics.technics = compactTechnics(baseProduct)
  }
  return {
    families: [...(colors.families || baseProduct.families)],
    technics: [...(technics.technics || baseProduct.technics)],
  }
}

const generateVariants = (product) => {
  try {
    const { caracteristics = {} } = product
    const { technics, families } = product
    const options = generateOptions(technics, families)
    const { weight } = caracteristics
    const Technique = options.find((o) => o.name === 'Techniques')
    const Color = options.find((o) => o.name === 'Couleurs')
    const { values } = Technique
    const variants = flatten(values.map((subOption1) => {
      const secondValues = Color.values
      return flatten(secondValues.map((subOption2) => {
        const concernedTechnics = product.technics.find((t) => t.idPanopli === subOption1)
        if (!concernedTechnics) {
          console.log('ERROR checkCreationBaseProduct')
          throw new Error('technics not found')
        }
        const thirdValues = concernedTechnics.parameters.stage.map((s, i) => String(i + 1))
        return flatten(thirdValues.map((subOption3) => {
          const price = (generatePriceForVariant(subOption1, subOption2, subOption3, product))
          const title = generateTitleForVariant(subOption1, subOption2, subOption3)
          return {
            inventory_management: 'shopify',
            inventory_policy: 'continue',
            grams: weight * 1000,
            weight_unit: 'g',
            weight: weight * 1000,
            taxable: true,
            inventory_quantity: 0,
            requires_shipping: true,
            option1: subOption1,
            option2: subOption2,
            option3: subOption3,
            price,
            title,
          }
        }))
      }))
    }))
    return variants
  } catch (e) {
    console.log('generateVariants - error', e)
    return {
      error: true,
      e,
    }
  }
}

const formatBp = (baseProduct, providers) => {
  try {
    const { technics, families, name: { fr } } = baseProduct
    const options = generateOptions(technics, families)
    const variants = generateVariants(baseProduct)
    if (variants.error) {
      throw new Error('cannot format variants')
    }

    if (variants.length > 100) {
      return {
        requireAction: true,
      }
    }
    const bodyHtml = formatBodyHtml(baseProduct)
    const tags = formatTags(baseProduct, providers)
    const title = fr
    const images = baseProduct.images.map((i) => ({ src: i.src }))
    const handle = `${title.toLowerCase().replaceAll(' ', '-')}-${baseProduct.idPanopli}`
    return {
      handle,
      variants,
      body_html: bodyHtml,
      tags,
      title,
      options,
      images,
    }
  } catch (e) {
    console.log('FORMAT BP - error', e)
    return -1
  }
}

const formatProductJson = (baseProduct) => {
  const {
    category, subCategory, technics, families, variations,
  } = baseProduct
  return [{
    id: baseProduct.idPanopli,
    categorie: category.fr[0],
    'sous-categorie': subCategory.fr,
    techniques: technics.map((t) => t.idPanopli),
    couleurs: families.map((f) => f.idPanopli),
    tailles: variations.filter((v) => v.type === 'size').map((v) => v.name),
  }]
}
const formatTechnicJson = (technic) => ({
  id: technic.idPanopli,
  name: technic.name,
  parametres: {
    moq: technic.parameters.moq,
    paliers: technic.parameters.stage,
  },
})

const formatColorJson = (color) => ({
  famille: color.idPanopli,
  couleurs: color.colors.map((c) => trimColorName(c)),
})

const shopifyFormat = (product, dbProviders, dbTechnics) => {
  try {
    let TechnicsToCreate
    if (product) {
    // WE FORMAT THE BASEPRODUCT
      const formattedProduct = formatBp(product, dbProviders)
      const { requireAction } = formattedProduct
      // MAP ON TECHNICS -> DO WE NEED TO CREATE SOME OF THEM ON SHOPIFY ?
      const { technics } = product
      // WE FILTERED DB TECHNICS BECAUSE THEY ALREAY EXIST ON SHOPIFY
      const filteredTechnics = technics.filter((t) => !dbTechnics.find((dbT) => dbT.idPanopli === t.idPanopli))
      // NOW WE CHECK IF NON CANONICAL TECHNICS HAS BEEN MODIFIED

      const { families } = product

      if (filteredTechnics.length) {
      // WE FOUND SOME TECHNICS TO CREATE
        TechnicsToCreate = filteredTechnics.map((t) => formatTechnicJson(t))
      }
      // WE FORMAT COLORS JSON
      const ColorsToCreate = families.map((f) => formatColorJson(f))
      // WE FORMAT PRODUCT JSON
      const ProductToCreate = formatProductJson(product)
      return {
        formattedProduct,
        TechnicsToCreate,
        ColorsToCreate,
        ProductToCreate,
        requireAction,
      }
    // MAP ON COLORS -> DO WE NEED TO CREATE SOME OF THEM ON SHOPIFY ?
    // GENERATE PRODUCT.JSON
    }
    return true
  } catch (e) {
    console.log('helpers/shopify/shopifyFormat error:', e)
    return false
  }
}

export {
  generateVariants, generatePriceForVariant, formatBodyHtml, shopifyFormat, compactColors, compactTechnics, reducParams,
}

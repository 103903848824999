/* eslint-disable camelcase */
import React from 'react'
import {
  Table,
  Button,
  Card,
  Divider,
  Icon,
  InputNumber,
} from 'antd'
import ProductImage from '@balibart/product-image'
import { FormattedMessage } from 'react-intl'
import config from '../../../config'
import './index.css'

const { image_base_url: imageBaseUrl } = config

const RecordTitle = ({ record }) => {
  try {
    if (record.type && record.type === 'custom') {
      return (
        <>
          <img
            alt={record._id}
            src={`${imageBaseUrl}/Designs/${record.design._id}/design.png`}
            style={{
              maxHeight: 32,
              maxWidth: 32,
              marginRight: 10,
              borderRadius: 3,
            }}
          />
          <FormattedMessage
            id='organizationTable.customBlock'
            defaultMessage='Bloc personnalisé'
          />
        </>
      )
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{
          maxHeight: 32,
          maxWidth: 32,
          flex: 1,
        }}
        >
          <ProductImage
            base_url={imageBaseUrl}
            product={record}
            className='test'
            variation={record.favColor}
            currentPrintingZone={record.favPz || 'front'}
          />
        </div>
        <span style={{ marginLeft: 10 }}>{record.name}</span>
      </div>
    )
  } catch (error) {
    console.log('OrganizationTable - RecordTitle', { error })
    return null
  }
}

const RecordActions = ({
  editDesign,
  deleteCustomBlock,
  record,
}) => {
  try {
    if (record.type && record.type === 'custom') {
      return (
        <span>
          <Button
            type='primary'
            onClick={() => editDesign(record)}
          >
            <FormattedMessage id='modify' defaultMessage='Modifier' />
          </Button>
          <Divider type='vertical' />
          <Button
            type='danger'
            onClick={() => deleteCustomBlock(record._id)}
          >
            <Icon type='delete' key='edit' style={{ marginRight: 10 }} />
            <FormattedMessage id='delete' defaultMessage='Supprimer' />
          </Button>
        </span>
      )
    }
    return null
  } catch (error) {
    console.log('OrganizationTable - RecordActions', { error })
    return null
  }
}
class OrganizationTable extends React.Component {
  render() {
    const {
      items,
      currentListing,
      deleteCustomBlock,
      editDesign,
      setNewIndex,
    } = this.props

    const data = items.map((item, index) => {
      let { name } = item
      if (item.type && item.type === 'custom') {
        name = (
          <FormattedMessage
            id='organizationTable.customBlock'
            defaultMessage='Bloc personnalisé'
          />
        )
      }

      return {
        ...item,
        key: index,
        position: currentListing.indexOf(item._id),
        name,
      }
    }).sort((a, b) => {
      if (a.position > b.position) {
        return 1
      }
      return -1
    })

    const columns = [
      {
        title: (
          <FormattedMessage id='lastName' defaultMessage='Nom' />
        ),
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <RecordTitle
            record={record}
          />
        ),
      },
      {
        title: (
          <FormattedMessage
            id='organizatioNTable.position'
            defaultMessage='Position'
          />
        ),
        dataIndex: 'position',
        key: 'position',
        render: (text, record) => (
          <InputNumber
            value={record.position}
            onChange={(e) => setNewIndex(record._id, e)}
          />
        ),
      },
      {
        title: <FormattedMessage id='action' defaultMessage='Action' />,
        key: 'action',
        render: (text, record) => (
          <RecordActions
            record={record}
            deleteCustomBlock={deleteCustomBlock}
            editDesign={editDesign}
          />
        ),
      },
    ]
    return (
      <Card>
        <Table
          columns={columns}
          tableLayout='fixed'
          dataSource={data}
          onChange={this.handleChange}
        />
      </Card>
    )
  }
}

export default OrganizationTable

import { initialState } from './selectors'
import * as actions from './actions'


// export const GET_FAMILIES = 'GET_FAMILIES'
// export const RECEIVED_FAMILIES = 'RECEIVED_FAMILIES'
// export const ERROR_FAMILIES = 'ERROR_FAMILIES'

export default (state = initialState, { type, payload = {} }) => {
  const {
    data,
  } = payload
  switch (type) {
  case actions.GET_FAMILIES:
    return {
      ...state,
      loading: true,
      error: false,
    }
  case actions.RECEIVED_FAMILIES:
    return {
      ...state,
      data,
      loading: false,
      error: false,
    }
  case actions.ERROR_FAMILIES:
    return {
      ...state,
      loading: false,
      error: true,
    }
  default:
    return state
  }
}

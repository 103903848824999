let yourconfig
const {
  dropshippingPrice = {},
  storagePricePerDay,
} = require('@balibart/invoice-calculator')

export const AWS_REGION = 'eu-west-3'
const AWS_BASE_URL = `https://s3.${AWS_REGION}.amazonaws.com`
export const BUCKET_NAME = 'panopli-images'
export const IMAGE_PREFIX = 'shopImages'

try {
  // eslint-disable-next-line
  yourconfig = require('./yourconfig.js')
} catch (e) {
  console.log('no yourconfig')
  // IDEA default to a minimal config?
}

export default {
  base_url: AWS_BASE_URL,
  allowModifications: true,
  isDev: process.env.NODE_ENV !== 'production',
  basename: process.env.PUBLIC_PATH,
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 3000,
  apiURL: 'http://localhost:10010',
  stripeKey: 'pk_test_XeS7u56LZqr1DScSgqUVjTjn',
  stripeAppId: 'ca_BmiHy1UgNa2lmu8R6Q6lulN1ZROEm826',
  // NOTE this is the preprod value
  intercom: '',
  bucketName: BUCKET_NAME,
  image_base_url: `${AWS_BASE_URL}/${BUCKET_NAME}/${IMAGE_PREFIX}`,
  fallBacks: {
    deliveries: {
      deliveryMin: 9,
      deliveryMax: 15,
    },
  },
  analytics: {
    DEBUG: true,
  },
  pricings: {
    storage: storagePricePerDay || 0.002,
    drop: {
      firstItem: dropshippingPrice.orderManangementPrice || 2.10,
      additionnal: dropshippingPrice.skuPrice || 0.66,
    },
  },
  // TODO harmonize with above stripeKey
  stripe_status: 'preprod',
  stripe_keys: {
    preprod: 'pk_test_XeS7u56LZqr1DScSgqUVjTjn',
  },
  fontApiUrl: 'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCfCcWcZMEgwGziJGXBVajG2ZQfGX4KlbI',
  autoComplete: {
    publicApiKey: 'AIzaSyDeqQ2p_D567dCf8uTLMoj8Fq7KSkq94Dc',
  },
  // NOTE your config overwrites certain keys
  ...yourconfig,
}

export const initialState = {}

export const getLastNeedReload = () => {
  try {
    return localStorage.getItem('last_need_reload_seller') || ''
  } catch (error) {
    console.log('settings.getLastDeployment error : ', { error })
    return false
  }
}

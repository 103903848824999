import React from 'react'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'

import { CustomizableModal } from '../..'
import { repushMagicLink, getMagicLinksResendable } from '../../../helpers/magicLinks'

import theme from '../../../config/theme'

const MagicLinksResendModal = (props) => {
  const {
    magicLinks, isModalVisible, setIsModalVisible, resendOne, setResendOne, selection, setSelection, modifyMagicLinks, onCancel,
  } = props

  // On ne relance que les magicLinks qui ne sont pas encore dépensés
  const magicLinksToResend = getMagicLinksResendable(magicLinks, selection, resendOne)

  return (
    <CustomizableModal
      isModalVisible={isModalVisible && magicLinksToResend}
      iconBackgroundColor={theme.primaryColor[1]}
      icon={faPaperPlane}
      iconColor={theme.primaryColor[6]}
      formattedMessageTitle={(
        <FormattedMessage
          id='magicLinks.resends.modals.title'
          defaultMessage='Souhaitez-vous relancer {nbSenders, plural, one {le Magic Link} other {les Magic Links}} ?'
          values={{ nbSenders: magicLinksToResend?.length || 0 }}
        />
      )}
      FormattedMessageDescriptions={
        magicLinksToResend.length === 1 ? (
          <FormattedMessage
            id='magicLinks.resend.modals.message'
            defaultMessage='Un mail de relance sera envoyé à {email}'
            values={{ email: magicLinksToResend.find(Boolean).email }}
          />
        ) : (
          <FormattedMessage
            id='magicLinks.resends.modals.message'
            defaultMessage='Un mail de relance sera envoyé {nbSenders, plural,
              one {au destinataire sélectionné} other { aux # destinataires sélectionnés}}'
            values={{ nbSenders: magicLinksToResend?.length || 0 }}
          />
        )
      }
      onCancel={onCancel}
    >
      <Button
        style={{
          width: '100%',
          display: 'block',
          margin: '24px 0 12px 0',
          height: '40px',
        }}
        key='send'
        onClick={() => {
          repushMagicLink(modifyMagicLinks, magicLinksToResend)
          setSelection([])
          setIsModalVisible(false)
          setResendOne(false)
        }}
        type='primary'
      >
        <FormattedMessage id='magicLinks.resends.modals.button.resend' defaultMessage='Renvoyer la campagne emailing' />
      </Button>
      <Button
        style={{
          width: '100%',
          display: 'block',
          margin: '0',
          height: '40px',
        }}
        key='view'
        onClick={() => {
          setIsModalVisible(false)
          setResendOne(false)
        }}
      >
        <FormattedMessage
          id='magicLinks.resends.modals.button.cancel'
          defaultMessage='Annuler'
        />
      </Button>
    </CustomizableModal>
  )
}

export default MagicLinksResendModal

import React, { useState } from 'react'
import {
  Card,
  List,
  Icon,
  Tag,
  Spin,
  Button,
  Checkbox,
} from 'antd'
import { sortBy } from 'lodash'
import { displayMinimumPrices } from '../../../helpers/baseProducts/catalog'
import { formatColorsFromFamilies } from '../../../helpers/baseProducts/creation'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const CatalogBP = (props) => {
  try {
    const {
      baseProduct,
      isSelectable,
      selectedProductsToDownload,
      setSelectedProductsToDownload,
      trimmedTimeLimit,
      getMinimumMOQ,
      setVisible,
      setActiveBP,
      filters,
      setURLFilters,
    } = props
    const {
      _id,
      supplierBaseProductReference,
      status,
      families,
      caracteristics,
    } = baseProduct

    const { Meta } = Card

    const [showInfos, setShowInfos] = useState(false)
    const [loadedImage, setLoadedImage] = useState(true)

    const displayImage = () => {
      if (baseProduct.images && baseProduct.images.length) {
        return sortBy(baseProduct.images, 'position').find(((image) => image)).src
      } return `${imageBaseUrl}/SVGS/${_id}/baseProduct/display.png`
    }

    const isSelected = selectedProductsToDownload.find((bpSelected) => bpSelected._id === baseProduct._id)

    return (
      <div>
        <List.Item
          key={supplierBaseProductReference}
          style={{
            cursor: 'auto',
          }}
        >
          <Card
            className='catalog-card'
            bordered={false}
            hoverable
            onMouseEnter={() => setShowInfos(true)}
            onMouseLeave={() => setShowInfos(false)}
            onClick={() => {
              if (isSelectable) {
                if (!isSelected) {
                  const newSelectedProductsToDownload = [...selectedProductsToDownload]
                  newSelectedProductsToDownload.push(baseProduct)
                  setSelectedProductsToDownload(newSelectedProductsToDownload)
                } else {
                  const newSelectedProductsToDownload = selectedProductsToDownload.filter((bpSelected) => bpSelected._id !== baseProduct._id)
                  setSelectedProductsToDownload(newSelectedProductsToDownload)
                }
              } else {
                setVisible(true)
                setActiveBP(baseProduct)
                setURLFilters({ ...filters, product: baseProduct._id })
              }
            }}
            cover={(
              <div className='catalog-card-cover'>
                {loadedImage ? (<Spin style={{ maxHeight: '275px' }} />) : (
                  null
                )}
                <div className={loadedImage ? 'rect-img-container-no' : 'rect-img-container'}>
                  <img
                    alt='example'
                    key='baseProductImage'
                    src={displayImage()}
                    style={loadedImage ? { display: 'none' } : {
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                    }}
                    onLoad={() => setLoadedImage(false)}
                    onError={(e) => {
                      e.target.src = baseProduct.shopifyImages && baseProduct.shopifyImages.length ? baseProduct.shopifyImages[0].src
                        : 'https://developers.google.com/maps/documentation/streetview/images/error-image-generic.png'
                    }}
                  />
                  {caracteristics?.OOS ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                        zIndex: 1000,
                        right: -15,
                        bottom: 4,
                        width: '80px',
                        transform: 'rotate(-35deg)',
                        backgroundColor: '#FF0D3D',
                        color: 'white',
                        fontWeight: 700,
                      }}
                    >
                      <div style={{ marginLeft: 6 }}>
                        OOS
                      </div>
                    </div>
                  ) : null}
                </div>
                {status ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      left: 5,
                      top: 5,
                      position: 'absolute',
                      borderRadius: '2px',
                      zIndex: 1000,
                      padding: '4px 30px 4px 4px',
                      fontWeight: 700,
                      flexWrap: 'wrap',
                    }}
                  >
                    {status.map((s) => <Tag key={s} style={{ marginBottom: '7px' }}>{s}</Tag>)}
                  </div>
                ) : null}
              </div>
            )}
          >
            <Meta
              title={(
                <div>
                  <div style={{
                    height: '20px',
                    margin: '4px 0 0 1px',
                    display: 'flex',
                    alignContent: 'center',
                  }}
                  >
                    {showInfos && trimmedTimeLimit(baseProduct) ? (
                      <span>
                        <Icon
                          type='clock-circle'
                          style={{ margin: '0 2px' }}
                        />
                        {trimmedTimeLimit(baseProduct)}
                      </span>
                    ) : null}
                    {showInfos && getMinimumMOQ(baseProduct) ? (
                      <span>
                        <Icon
                          type='unlock'
                          style={{ margin: '0 1px 0 2px' }}
                        />
                        {getMinimumMOQ(baseProduct)}
                      </span>
                    ) : null}
                    {showInfos ? (formatColorsFromFamilies(families)
                      .map((color) => (
                        <img
                          key={`baseProductColor-${color.name}`}
                          style={{
                            height: '14px',
                            width: '14px',
                            margin: '4px 0 0 4px',
                          }}
                          src={`${imageBaseUrl}/Pastille/low/${color.name}.png`}
                          onError={(e) => {
                            e.target.src = `${imageBaseUrl}/Pastille/low/error.png`
                          }}
                          alt={color.name}
                        />
                      ))
                    ) : null}
                  </div>
                  <div style={{ margin: '0px 20px 10px 20px' }}>
                    <div className='catalog-card-title'>
                      {baseProduct.name.fr}
                    </div>
                    <div className='catalog-card-price'>
                      {displayMinimumPrices(baseProduct)}
                    </div>
                  </div>
                </div>
              )}
            />
          </Card>
          <div
            style={{
              right: 15,
              top: 15,
              position: 'absolute',
              zIndex: 1000,
              padding: '4px',
            }}
          >
            {isSelectable ? (
              <Checkbox checked={isSelected} />
            ) : (
              <Button
                style={{ fontWeight: 700, height: '22px', width: '23px' }}
                href={`https://app.panopli.co/catalog/?product=${_id}`}
                target='_blank'
                rel='noopener noreferrer'
                size='small'
              >
                +
              </Button>
            )}
          </div>
        </List.Item>
      </div>
    )
  } catch (e) {
    console.log('e CatalogBP', e)
    return null
  }
}

export default CatalogBP

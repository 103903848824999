/* eslint-disable camelcase */
// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import {
  put, call, takeEvery, select, all, takeLatest,
} from 'redux-saga/effects'
import date from 'dayjs'

import { fromShops, fromUsers } from '../selectors'
import * as actions from './actions'

const { getShop } = fromShops
const { getUser, getAuth } = fromUsers

// import { update } from '../../services/api/shop'

/**
 * `*modifyShop` updates a shop
 * ---
 * Generator function doc: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/function*'
 * ---
 * Different cases depending on wether we are on the server on in the client:
 * Case for server + product page : fetch shop + one product
 * Case for server and somewhere else : fetch shop
 *  subCase for preview (sellerinterface iframe - no checkout)
 *  subCase for secret (noMrgin shop)
 * Case for client: see if we got the shop object
 *    Case for no : fetch it
 *    Case for yes : use it as is
 *  Then fetch all products this time
 * @param {Object} api the api service
 * @param {Object} action the action
 * @param {Object} action.payload the payload object
 * @param {String} action.payload.url the current url
 * @param {String} action.payload.hostname the current hostname
 * @return {Boolean} true if everything went ok
 */

// eslint-disable-next-line require-yield
export function* reload() {
  window.document.location.reload(true)
}

export function* updateOldState(api) {
  const shop = yield select(getShop)
  const { _id } = shop
  const authorization = yield select(fromUsers.getAuth)
  const payload = yield call([api, api.get], `/getOneShop/${_id}`, {
    headers: {
      authorization,
      fromSeller: true,
    },
  })
  yield put({
    type: actions.RECEIVED_SHOP,
    payload: { shop: payload },
  })
  const allShops = yield select(fromShops.allShops)
  const newAllShops = allShops.map((s) => {
    if (s._id === _id) {
      return payload.data
    }
    return s
  })
  yield put({
    type: actions.RECEIVED_SHOPS,
    payload: {
      shops: newAllShops,
    },

  })
  yield put({
    type: 'RELOAD',
  })
}

export function* modifyShop(api, action) {
  try {
    const {
      shop_id,
      modifications,
      fileHashes,
      callback,
    } = action.payload

    const authorization = yield select(getAuth)

    let headers = {
      authorization,
    }

    if (fileHashes) {
      headers = {
        authorization,
        fileHashes: JSON.stringify(fileHashes),
      }
    }

    const browserShop = yield select((getShop))
    const dbCall = yield call([api, api.get], `/getOneShop/${shop_id}`, {
      headers: {
        fromSeller: true,
        authorization,
      },
    })
    const dbShop = (dbCall && dbCall.data) || {}
    if (date(browserShop.updatedAt).isBefore(date(dbShop.updatedAt))) {
      yield put({ type: actions.CHECK_REFETCH, payload: { shouldRefetchShop: true } })
      return false
    }
    modifications.owner = dbShop.owner
    const response = yield call([api, api.put], '/shop', {
      _id: shop_id,
      // NOTE we only send modifications
      ...modifications,
    }, {
      headers,
    })
    // TODO upload tokens are here!
    yield put({
      type: actions.MODIFIED,
      payload: {
        updatedAt: response.updatedAt,
      },
    })

    // const user = yield select(fromShops.allShops)
    // const edited = user.shops.find(s => s._id === shop_id)
    //
    // const newShops = user.shops
    //   .filter(f => f._id !== shop_id)
    // newShops.push({
    //   ...edited,
    //   ...modifications,
    // })
    // yield put({
    //   type: actions.RECEIVED_SHOPS,
    //   payload: { shops: newShops, user },
    // })

    if (callback) {
      callback(response)
    }

    return true
  } catch (error) {
    console.log('*modifyShop', { error })
    yield put({
      type: actions.UPDATE_ERROR,
      payload: {
        error,
      },
    })
    return false
  }
}

export function* fetchShop(api) {
  try {
    const shop = yield select(getShop)
    let shopId
    const authorization = yield select(fromUsers.getAuth)
    if (shop && Object.keys(shop).length) {
      // NOTE: we already have a shop
      shopId = shop._id
      // TODO check for cache validity
    }

    const payload = yield call([api, api.get], `/getOneShop/${shopId}`, {
      headers: {
        fromSeller: true,
        authorization,
      },
    })
    yield put({
      type: actions.RECEIVED_SHOP,
      payload: { shop: payload },
    })

    return true
  } catch (e) {
    console.log('getShop error', e, e.message)
    return false
  }
}

export function* createShop(api, { payload: { shop, callback } }) {
  try {
    // NOTE if we receive an auth, we should reAuthenticate
    const user = yield select(getUser)
    const authorization = yield select(getAuth)

    const {
      name,
      url,
    } = shop

    const modifiedShop = {
      settings: {
        name,
        url,
        grid: [
          '122',
          '221',
          '212',
          '2222'],
        theme: {
          color1: 'rgba(28, 97, 198, 1)',
          pageBackgroundColor: 'rgba(255, 255, 255, 1)',
          productBackgroundColor: 'rgba(245, 245, 245, 0.7)',
          fontColor: 'rgba(96, 96, 96, 1)',
        },
      },
      owner: user._id,
      status: 'Inactive',
    }

    const result = yield call([api, api.post], '/shop', modifiedShop, {
      headers: {
        mode: 'cors',
        authorization,
        'Content-Type': 'application/json',
      },
      cache: 'default',
    })

    yield put({
      type: actions.RECEIVED_SHOPS,
      payload: { shops: [{ ...modifiedShop, _id: result.id }] },
    })

    if (callback) {
      callback()
    }
    return true
  } catch (e) {
    console.log('fetch user error', e, e.message)
    if (callback) {
      callback(e)
    }
    yield put({ type: actions.CREATE_ERROR, payload: { error: e } })
    return false
  }
}

export function* fetchShops(api, {
  payload: {
    user,
  },
}) {
  try {
    const authorization = yield select(getAuth)
    const shops = yield call([api, api.get], '/shop', {
      headers: {
        authorization,
      },
    })
    yield put({
      type: actions.RECEIVED_SHOPS,
      payload: {
        shops: shops.data,
        user,
      },
    })
    return true
  } catch (error) {
    console.log('fetchShops', { error })
    // TODO put ERROR
    return false
  }
}

export function* testNotificationStreamlabs(api, {
  payload,
}) {
  try {
    const authorization = yield select(getAuth)
    yield call([api, api.post], '/streamlabs/test', payload, {
      headers: {
        mode: 'cors',
        authorization,
        'Content-Type': 'application/json',
      },
      cache: 'default',
    })
  } catch (e) {
    console.log(e, 'error shops/sagas/testNotificationStreamlabs')
  }
}

export function* getDataForThemePage(api) {
  yield all([
    call(fetchShop, api),
  ])
  yield put({ type: actions.END_FETCH_DATA })
}

export default function* ({ api }) {
  yield takeLatest(actions.INIT_FETCH_DATA, getDataForThemePage, api)
  yield takeEvery(actions.MODIFY, modifyShop, api)
  yield takeEvery(actions.CREATE, createShop, api)
  yield takeEvery(actions.GET_SHOP, fetchShop, api)
  yield takeEvery(actions.GET_SHOPS, fetchShops, api)
  yield takeEvery(actions.CHANGE_SHOP, fetchShop, api)
  yield takeEvery(actions.TEST_NOTIFICATION, testNotificationStreamlabs, api)
  yield takeEvery(actions.UPDATE_OLD_STATE, updateOldState, api)
  yield takeEvery('RELOAD', reload)
}

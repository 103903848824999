import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import {
  Drawer,
  Descriptions,
  Tag,
  Table,
  Icon,
} from 'antd'
import date from 'dayjs'

import {
  DetailedOrder,
} from '..'

import { fromOrders, fromShops, fromLocale } from '../../store/selectors'
import { translateMemberInfos } from '../../helpers/trim'

const { Item } = Descriptions

export default connect(
  (state) => ({
    modifiedOrders: fromOrders.getModifiedOrders(state),
    allOrders: fromOrders.getOrders(state),
    shop: fromShops.getShop(state),
    locale: fromLocale.getLocale(state),
  }), null,
)((props) => {
  const {
    visibleDrawer,
    hideDrawer,
    detailedMember,
    allOrders,
    displayDrawerOrder,
    detailedOrder,
    visibleDrawerOrder,
    hideDrawerOrder,
    shop: {
      settings: {
        membersGroups,
      },
    },
    locale = 'fr',
  } = props

  const columns = [
    {
      title: <FormattedMessage id='orderNumber' defaultMessage='Numéro de commande' />,
      dataIndex: 'customerOrderId',
      key: 'customerOrderId',
    },
    {
      title: <FormattedMessage id='date' defaultMessage='Date' />,
      key: 'date',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        const originalDate = createdAt
        const newDate = originalDate.split('T')[0].split('-')
        const formattedDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`
        return (<span>{formattedDate}</span>)
      },
      sorter: (a, b) => date().isAfter(b.createdAt),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id='totalPriceIncl' defaultMessage='Prix total TTC' />,
      key: 'totalPrice',
      render: (record) => {
        const r = record
        return (
          <span>
            {`${(r.detailedPricing && r.detailedPricing.total)
              ? (r.detailedPricing.total
                + r.detailedPricing.delivery_discounted).toFixed(2)
              : (r.transaction.amount / 100).toFixed(2)} €`}
          </span>
        )
      },
      sorter: (a, b) => a.transaction.amount - b.transaction.amount,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '',
      key: 'actions',
      render: (order) => (
        <Icon
          type='info-circle'
          style={{ cursor: 'pointer', fontSize: '15px' }}
          onClick={() => displayDrawerOrder(order)}
        />
      ),
    },
  ]
  if (!detailedMember) return null
  const groups = detailedMember.groups.map((groupName) => membersGroups
    .find((group) => group.name === groupName))
  return (
    <Drawer
      title={(
        <FormattedMessage
          id='detailedMember.title'
          defaultMessage='Informations complémentaires - {firstName} {lastName}'
          values={{
            firstName: detailedMember.name,
            lastName: detailedMember.lastName,
          }}
        />
      )}
      width={640}
      closable
      visible={visibleDrawer}
      onClose={hideDrawer}
      className='DeliveryView'
    >
      <DetailedOrder
        detailedOrder={detailedOrder}
        visibleDrawer={visibleDrawerOrder}
        hideDrawer={hideDrawerOrder}
      />
      <Descriptions layout='vertical'>
        <Item label={<FormattedMessage id='detailedMember.name' defaultMessage='Prénom / Nom' />}>
          <span style={{ marginRight: 5 }}>
            {detailedMember.name}
          </span>
          <span>
            {detailedMember.lastName}
          </span>
        </Item>
        <Item label={<FormattedMessage id='email' defaultMessage='Email' />}>
          {detailedMember.email}
        </Item>
        <Item label={<FormattedMessage id='phone' defaultMessage='Téléphone' />}>
          {detailedMember.phone}
        </Item>
        <Item label={<FormattedMessage id='group' defaultMessage='Groupe' />}>
          {groups.map((group) => (
            <Tag color={group.color} key={group.name}>
              {group.name}
            </Tag>
          ))}
        </Item>
        <Item label={<FormattedMessage id='infos' defaultMessage='Informations' />}>
          {translateMemberInfos(detailedMember.informations, locale)}
        </Item>
        <Item label={<FormattedMessage id='detailedMember.last' defaultMessage='Dernière connexion' />}>
          {detailedMember.lastConnexion}
        </Item>
      </Descriptions>
      <Descriptions title={<FormattedMessage id='orders' defaultMessage='Commandes' />} layout='vertical' />
      <Table
        rowKey={(record) => record._id}
        dataSource={allOrders.filter((order) => order.client.email === detailedMember.email)}
        columns={columns}
      />
    </Drawer>
  )
})

import React, { useState } from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFilter,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import {
  SearchProduct,
  RecentSorter,
  CategoryFilter,
  // ProductsTypeFilters
} from '../..'
import theme, { character } from '../../../config/theme'

const ProductStepGridFilters = (props) => {
  const {
    filters,
    setFilters,
    setSorter,
    filteredProducts,
  } = props
  const [isFilterDisplayed, setIsFilterDisplayed] = useState(false)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {/* // TODO logique de filtres à faire, caché tant qu'on ne traite pas les produits demat
        <ProductsTypeFilters
          filters={filters}
          setFilters={setFilters}
        /> */}
        <div />
        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <SearchProduct
            filters={filters}
            setFilters={setFilters}
          />
          <Button
            onClick={() => setIsFilterDisplayed(!isFilterDisplayed)}
            style={{
              ...(isFilterDisplayed && { backgroundColor: theme.neutralColor[2] }),
              height: '40px',
              color: character.secondary,
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <FontAwesomeIcon icon={isFilterDisplayed ? faXmark : faFilter} />
            <FormattedMessage id='campaigns.create.step.products.filters' defaultMessage='Filtres' />
          </Button>
        </div>
      </div>
      <div style={{
        display: isFilterDisplayed ? 'flex' : 'none',
        flexDirection: 'row',
        backgroundColor: theme.neutralColor[2],
        borderRadius: '8px',
        padding: '16px',
      }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
          <RecentSorter
            setSorter={setSorter}
          />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
          marginLeft: '16px',
        }}
        >
          <CategoryFilter
            filters={filters}
            setFilters={setFilters}
            productsToFilter={filteredProducts}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductStepGridFilters

import { initialState } from './selectors'
import * as actions from './actions'

export default (state = initialState, { type, payload = {} }) => {
  const {
    error_details,
  } = payload
  switch (type) {
  case actions.CREATE_DESIGN:
    return {
      ...state,
      loading: true,
      error: false,
    }
  case actions.DESIGN_CREATED:
    return {
      ...state,
      loading: false,
      error: false,
    }
  case actions.ERROR:
    return {
      ...state,
      loading: false,
      error: error_details,
    }
  default:
    return state
  }
}

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'
import {
  InvoiceTable, Placeholder,
} from '../../components/organisms'
import {
  fromInvoices, fromUsers, fromProducts, fromOrders, fromLocale,
} from '../../store/selectors'
import { Spin } from '../../components'
import { extractParams, writeUrl } from '../../helpers/urlHelpers'

const { TabPane } = Tabs

const Invoices = (props) => {
  const {
    invoices,
    error,
    loading,
    history,
    isAdmin,
    user,
    allProducts,
    allOrders,
    locale = 'fr',
  } = props
  const [filters, setFilters] = useState({ ...extractParams(history.location.search) })

  const setFiltering = (object) => {
    setFilters({
      filters: {
        ...filters,
        ...object,
      },
    }, () => history.push(writeUrl({
      ...filters,
      ...object,
    })))
  }

  // const hasFilters = () => !!Object.values(filters).filter(Boolean).length

  // const resetFiltering = () => {
  //   const { history } = props
  //   setFilters({
  //     filters: {},
  //   }, () => history.push(writeUrl({})))
  // }

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
      }}
      >
        <Spin />
      </div>
    )
  }

  if (!invoices.length) {
    return (
      <Placeholder />
    )
  }

  return (
    <div>
      <Tabs>
        {invoices.map((invoice) => (
          <TabPane
            tab={invoice.name}
            key={invoice.name}
          >
            <InvoiceTable
              filtering={setFiltering}
              filters={filters}
              client={invoice}
              invoices={invoice.invoices}
              upcoming
              error={error}
              loading={loading}
              history={history}
              upComingInvoice={invoice.new_up_coming_invoice}
              newInvoiceItems={invoice.new_up_coming_invoice_items}
              isAdmin={isAdmin}
              user={user}
              allProducts={allProducts}
              allOrders={allOrders}
              locale={locale}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}
export default connect(
  (state) => ({
    isAdmin: fromUsers.isAdmin(state),
    invoices: fromInvoices.getInvoices(state),
    loading: fromInvoices.loading(state),
    error: fromInvoices.error(state),
    user: fromUsers.getUser(state),
    allProducts: fromProducts.allProducts(state),
    allOrders: fromOrders.getOrders(state),
    locale: fromLocale.getLocale(state),
  }),
  null,
)(Invoices)

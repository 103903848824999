import React from 'react'
import {
  faPaperPlane,
  // faStar,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, List, Tag } from 'antd'
import { FormattedMessage } from 'react-intl'
import {
  character, primaryColor, validColor, white,
} from '../../../config/theme'

const MagicLinksDashboardNewCampaigns = (props) => {
  try {
    const {
      campaigns, magicLinks, history, setActiveKey,
    } = props

    const lastCreatedCampaigns = campaigns.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    const last3Campaigns = lastCreatedCampaigns.slice(0, 3)

    const data = last3Campaigns.map((campaign) => {
      const sent = magicLinks.filter((magicLink) => magicLink.campaignId === campaign._id && magicLink.sentAt).length
      const nps = 2 // TODO à remplacer par la vraie stat quand elle sera disponible !
      return {
        // Pas de déstructuration ici car 'public' est interdit en strict mode
        name: campaign.name,
        isPublic: campaign.public,
        sent,
        nps,
      }
    })

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '16px',
        background: white,
        boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.03)',
        borderRadius: '16px',
      }}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '16px',
        }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ fontWeight: '600', fontSize: '16px', color: character.title }}>
              <FormattedMessage id='magicLinks.dashboard.newCreatedCampaigns' defaultMessage='Nouvelles campagnes créées' />
            </span>
            <Button
              type='link'
              onClick={() => {
                history.push('/magicLinks?tabs=campaigns')
                setActiveKey('campaigns')
              }}
              style={{
                fontWeight: '500', fontSize: '14px', padding: '5px 8px', gap: '10px',
              }}
            >
              <span style={{ fontWeight: '500', fontSize: '14px', color: primaryColor[6] }}>
                <FormattedMessage id='magicLinks.dashboard.allCampaigns' defaultMessage='Toutes les campagnes' />
              </span>
            </Button>
          </div>
          <div>
            <List
              bordered={false}
              size='large'
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px', width: '100%',
                  }}
                  >
                    <div style={{
                      display: 'flex', alignItems: 'center', gap: '8px', maxWidth: '75%',
                    }}
                    >
                      <span style={{
                        fontWeight: '500',
                        fontSize: '14px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: character.title,
                      }}
                      >
                        {item.name}
                      </span>
                      <span style={{
                        fontWeight: '500', fontSize: '12px', marginTop: '2px',
                      }}
                      >
                        {item.isPublic ? (
                          <Tag style={{
                            background: validColor[1],
                            color: validColor[6],
                            borderRadius: '1000px',
                            padding: '1px 8px',
                            fontWeight: 500,
                            fontSize: '12px',
                            border: 0,
                          }}
                          >
                            <FormattedMessage id='public.female' defaultMessage='Publique' />
                          </Tag>
                        ) : (
                          <Tag style={{
                            background: primaryColor[1],
                            color: primaryColor[6],
                            borderRadius: '1000px',
                            padding: '1px 8px',
                            height: '22px',
                            lineHeight: '20px',
                          }}
                          >
                            <FormattedMessage id='private.female' defaultMessage='Privée' />
                          </Tag>
                        )}
                      </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <FontAwesomeIcon size='sm' icon={faPaperPlane} style={{ color: character.secondary }} />
                        <span style={{
                          color: character.secondary, fontWeight: '400', fontSize: '12px', lineHeight: '20px',
                        }}
                        >
                          {item.sent}
                        </span>
                      </div>
                      {/* TODO à réactiver lorsque l'on aura les NPS
                      {item.nps > 0 && (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                          <FontAwesomeIcon size='sm' icon={faStar} style={{ color: character.secondary }} />
                          <span style={{
                            color: character.secondary, fontWeight: '400', fontSize: '12px', lineHeight: '20px',
                          }}
                          >
                            {item.nps}
                            /5
                          </span>
                        </div>
                      )}
                      */}
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecules/MagicLinksDashboardNewCampaigns error:', e)
    return null
  }
}

export default MagicLinksDashboardNewCampaigns

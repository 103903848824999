import React, { useState } from 'react'
import {
  Button, Divider, Icon, Drawer, Collapse, Card, message,

} from 'antd'
import { FormattedMessage } from 'react-intl'

import ProductRulesForm from '../ProductRulesForm'
import theme from '../../../config/theme'

const { Panel } = Collapse

const translateUnit = (unit) => {
  switch (unit) {
  case 'days':
    return 'jour(s)'
  case 'weeks':
    return 'semaine(s)'
  case 'months':
    return 'mois'
  case 'years':
    return 'année(s)'
  default:
    return ''
  }
}

// Produit restreint aux utilisateurs
const displayPlaceholder = (values, ruleType, members) => {
  if (ruleType === 'seniority') {
    return (
      <FormattedMessage
        id='restrictedManagement.seniorityPeriod'
        defaultMessage="avec {period} {unit} d'ancienneté"
        values={{ period: values.period, unit: translateUnit(values.unit) }}
      />
    )
  }
  if (ruleType === 'groups') {
    let string = ''
    // eslint-disable-next-line no-return-assign
    values.map((v) => string += `${v}, `)
    return (
      <span>
        <FormattedMessage
          id='restrictedManagement.users'
          defaultMessage='aux utilisateurs'
        />
        {' '}
        {values.length > 1
          ? <FormattedMessage id='restrictedManagement.groups' defaultMessage='des groupes' />
          : <FormattedMessage id='restrictedManagement.group' defaultMessage='du groupe' />}
        {' '}
        {string}
      </span>
    )
  }
  if (ruleType === 'member') {
    let string = ''
    values.map((v) => {
      const member = members.find((m) => String(m._id) === v) || {}
      string += `${member.email}, `
      return 1
    })
    return (
      <span>
        {values.length > 1
          ? <FormattedMessage id='restrictedManagement.followingUsers.plurial' defaultMessage='aux utilisateurs suivants :' />
          : <FormattedMessage id='restrictedManagement.followingUsers.singular' defaultMessage="à l'utilisateur suivant :" />}
        {' '}
        {string}
      </span>
    )
  }
  return null
}

const RuleDisplayer = (props) => {
  const {
    packOfRules, members,
  } = props
  const data = packOfRules.map((rule, i) => {
    const { type, value } = rule
    const isLast = i === packOfRules.length - 1
    return (
      <div key={`${type}-${value}`}>
        <div style={{
          backgroundColor: '#fafafa',
          padding: 5,
          textAlign: 'center',
          maxHeight: '150px',
          overflowY: 'scroll',
        }}
        >
          {displayPlaceholder(value, type, members)}
        </div>
        {!isLast && (<Divider> et </Divider>)}
      </div>
    )
  })
  return (
    <div style={{
      borderRadius: 5,
      padding: 10,
    }}
    >
      {data}
    </div>
  )
}
const RulesDisplayer = (props) => {
  const {
    rules, edition, suppression, members, onDelete, setEdited, setVisible,
  } = props
  if (!rules || !rules.length) {
    return (
      <div>
        <FormattedMessage id='noProductRules' defaultMessage='Aucune règle pour ce produit' />
      </div>
    )
  }
  const data = rules.map((setOfRules, index) => {
    const isLast = index === rules.length - 1
    return (
      <div key={String(setOfRules)}>
        <Card
          actions={[
            edition ? (
              <Icon
                type='edit'
                key='edit'
                onClick={() => {
                  setEdited({ rules: setOfRules, index })
                  setVisible(true)
                }}
              />
            ) : null,
            suppression ? <Icon type='delete' key='delete' onClick={() => onDelete(index)} /> : null,
          ]}
        >
          <div style={{ width: '100%' }}>
            <RuleDisplayer
              packOfRules={setOfRules}
              index={index}
              edition={edition}
              suppression={suppression}
              members={members}
            />
          </div>
        </Card>
        {!isLast && (<Divider> OU </Divider>)}
      </div>
    )
  })
  return (
    <div>
      <p>
        <FormattedMessage id='restrictedProduct' defaultMessage='Produit restreint' />
      </p>
      {data}
    </div>
  )
}

const RestrictedManagement = (props) => {
  const {
    product,
    membersGroups,
    members,
    onChange,
  } = props

  const { rules = [] } = product

  const [visible, setVisible] = useState(false)
  const [edited, setEdited] = useState(null)

  const handleChange = (newRules) => {
    onChange({
      rules: newRules,
      isRestricted: newRules.length > 0,
    })
    message.success(<FormattedMessage id='productModified' defaultMessage='Produit modifié !' />)
  }

  const modifyRule = (index, rule) => {
    const newRules = rules.map((r, i) => {
      if (i === index) {
        return rule
      }
      return r
    })
    handleChange(newRules)
  }

  const deleteRule = (index) => {
    const newRules = rules.filter((r, i) => i !== index)
    handleChange(newRules)
  }

  const addComponentRule = (rule) => {
    const newRules = rules.concat([rule])
    handleChange(newRules)
  }

  return (
    <div>
      <h4 style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
        <FormattedMessage id='restrictedManagement.restrictionRules' defaultMessage='Règles de restriction du produit' />
      </h4>
      <Drawer
        title={<FormattedMessage id='restrictedManagement.addNewRule' defaultMessage='Ajouter une nouvelle règle' />}
        placement='right'
        onClose={() => setVisible(false)}
        visible={visible}
        width='50%'
        destroyOnClose
      >
        <ProductRulesForm
          edited={edited}
          membersGroups={membersGroups}
          members={members}
          rules={rules}
          addComponentRule={addComponentRule}
          hide={() => setVisible(false)}
          modifyRule={modifyRule}
          edition={edited}
        />
      </Drawer>
      <Collapse defaultActiveKey={rules.length === 0 ? ['1'] : null}>
        <Panel
          header={(
            <FormattedMessage
              id='restrictedManagement.header.rulesNumber'
              defaultMessage='{number} règle{agreement}'
              values={{ number: rules.length, agreement: rules.length > 1 ? 's' : '' }}
            />
          )}
          key='1'
        >
          <RulesDisplayer
            rules={rules}
            edition
            suppression
            members={members}
            onDelete={deleteRule}
            setEdited={setEdited}
            setVisible={setVisible}
          />
          <div style={{ textAlign: 'center', marginTop: '5px' }}>
            <Button
              onClick={() => {
                setVisible(true)
                setEdited(null)
              }}
              type='primary'
            >
              <Icon type='plus-circle' />
              {' '}
              <FormattedMessage id='restrictedManagement.addNewRule' defaultMessage='Ajouter une nouvelle règle' />
            </Button>
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default RestrictedManagement
export { RulesDisplayer }

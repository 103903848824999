import {
  Button,
  Divider,
  Drawer,
  Form,
  Icon,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  message,
} from 'antd'
import date from 'dayjs'
import { uniqBy } from 'lodash'
import React from 'react'
import { CSVLink } from 'react-csv'
import { FormattedMessage } from 'react-intl'
import { CSVReader } from 'react-papaparse'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CountrySelector, GroupCreation, PreviewNewMembers } from '../../components'
import { getUserEntityStatus } from '../../helpers/entities'
import { ADD_MEMBERS, MODIFY_MEMBER } from '../../store/members/actions'
import {
  fromEntities,
  fromLocale,
  fromMembers,
  fromShops,
  fromUsers,
} from '../../store/selectors'
import { MODIFY } from '../../store/shops/actions'

const { Item } = Form
const { Option } = Select

class MemberCreation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      members: [],
      groups: [],
      membersToUpdate: [],
    }
  }

  parseEntityName = (name) => name.toLowerCase().replaceAll(' ', '')

  addMembersFromCSV = (_file) => {
    try {
      const { members, membersToUpdate } = this.state
      const {
        user, shop, entities, allMembers,
      } = this.props
      const file = _file
      delete file[0]
      file.map((line) => {
        const { data } = line
        if (!data.length
          || ((user.admin || user?._id === shop.owner) && data[0] === '')
          || data[1] === '') return false

        const _entityId = entities.length === 1
          ? entities[0]._id
          : entities.find((e) => this.parseEntityName(e.name) === this.parseEntityName(data[0]))._id || 'N/C'
        const groups = data[7].split(',').find((group) => group !== '')
          ? data[7].split(',').map((group) => group)
          : []
        const newMember = {
          entityId: _entityId,
          email: data[1]?.toLowerCase(),
          name: data[2],
          lastName: data[3],
          credit: data[4],
          freeDelivery: (data[5]?.toLowerCase() === 'true'),
          mailCreation: (data[6]?.toLowerCase() === 'true'),
          groups,
          language: data[8]?.toLowerCase(),
          birthday: date(data[9], 'DD/MM/YYYY'),
          companyBirthday: date(data[10], 'DD/MM/YYYY'),
          size: data[11],
        }
        const dbMember = allMembers.find((member) => member.email?.toLowerCase() === data[1]?.toLowerCase())
        if (dbMember) {
          newMember.credit = parseInt(newMember.credit, 10) + parseInt(dbMember.credit, 10)
          delete newMember.entityId
          delete newMember.email
          delete newMember.mailCreation
          return membersToUpdate.push({ ...dbMember, ...newMember })
        }
        return members.push(newMember)
      })
      this.setState({ members, membersToUpdate })
      console.log('🚀 | MemberCreation | members:', members)
    } catch (e) {
      console.log('error containers/MemberCreation/addMembersFromCSV', e)
      message.error(<FormattedMessage id='error.download' defaultMessage='Erreur lors du téléchargement' />)
    }
  }

  addMemberFromForm = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
        resetFields,
      },
      allMembers,
      entities,
      user,
    } = this.props

    const {
      members,
      groups,
    } = this.state

    validateFieldsAndScroll((err, _values) => {
      const values = _values

      if (!err) {
        // ! Si membre déjà en DB : on n'ajoute pas le membre.
        if (allMembers.filter((member) => values.email?.toLowerCase() === member.email?.toLowerCase()).length
      || members.filter((member) => values.email?.toLowerCase() === member.email?.toLowerCase()).length) {
          message.error(<FormattedMessage id='email.alreadyUsed' defaultMessage='Cette adresse email est déjà utilisée' />)
          return false
        }

        values.groups = groups

        // * Si le membre n'a pas d'entityId (l'utilisateur n'est ni admin Panopli ni owner du shop)
        if (!values.entityId) {
          // Si l'utilisateur a une entité rattachée, on utilise cette entité par défaut.
          if (user.entityId) {
            values.entityId = user.entityId
          } else {
            // Sinon on prend la première entité disponible pour cet utilisateur.
            values.entityId = (entities[0] && entities[0]._id) || 'N/C' // 'N/C' normalement innateignable
          }
        }

        members.push(values)
        resetFields()
        this.setState({
          members,
        })
        return true
      }
      return true
    })
  }

  deleteMember = (_member) => {
    const { members, membersToUpdate } = this.state
    const newMembers = members.filter((member) => member.email !== _member.email)
    const updatedMembers = membersToUpdate.filter((member) => member.email !== _member.email)
    this.setState({ members: newMembers, membersToUpdate: updatedMembers })
  }

  emptyMembers = () => {
    this.setState({ members: [] })
  }

  emptyMembersToUpdate = () => {
    this.setState({ membersToUpdate: [] })
  }

  onSubmit = async () => {
    const {
      addMembers,
      hideDrawer,
      shop,
      shop: {
        settings: {
          membersGroups, // groupes existants du shop en cours
        },
      },
      modifyShop,
      modifyMember,
    } = this.props

    const {
      members, // nouveaux membres
      membersToUpdate, // membres existants à update
    } = this.state

    let groups = []

    // On récupère tous les groupes des nouveaux membres (y compris les nouveaux groupes)
    members.map((member) => member.groups.map((group) => groups.push(membersGroups
      .find((_group) => _group.name === group) || { name: group })))

    groups = uniqBy(groups, 'name')

    // Les nouveaux groupes n'ont pas encore de couleur associée.
    const groupsNotCreate = groups.filter((group) => !group.color)

    // * Création des nouveaux groupes
    if (groupsNotCreate.length) {
      const newGroups = membersGroups || []

      groupsNotCreate.map((group) => {
        // Création d'un groupe (id-name-color-date)
        newGroups.push({
          id: Math.random().toString(36).substring(7),
          name: group.name,
          color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
          createdAt: new Date(),
        })

        return true
      })
      const saveSettings = {
        settings: {
          ...shop.settings,
          membersGroups: newGroups,
        },
      }

      // Une fois qu'on a reconstitué membersGroups : on met à jour le shop
      // AUDIT : on devrait pouvoir ne modifier que les groupes du shop non ? là on écrase tout..
      await modifyShop({
        shop_id: shop._id,
        modifications: saveSettings,
        callback: () => {
          message.info(<FormattedMessage id='memberCreation.info.groupCreated' defaultMessage='Groupe créé !' />)
          return true
        },
      })
    }

    // * Membres déjà connus : update
    if (membersToUpdate.length) {
      membersToUpdate.map((member) => modifyMember(member)) // forEach instead : call MODIFY_MEMBER actions : call unitaire ??
      this.emptyMembersToUpdate() // clear modifications ?
      if (!members.length) hideDrawer()
    }

    // * Nouveaux membres : à créer
    // action ADD_MEMBERS
    if (members.length) addMembers(members, hideDrawer, this.emptyMembers) // pourquoi ici on fait le hideDrawer + emptyMembers dans l'action ?
  }

  render() {
    const {
      visibleDrawer,
      hideDrawer,
      form: {
        getFieldDecorator,
        resetFields,
      },
      user,
      shop,
      modifyShop,
      entities,
      locale = 'fr',
    } = this.props

    const {
      settings: {
        membersGroups = [],
      },
    } = shop

    const {
      members,
      membersToUpdate,
      visibleGroupeCreation,
      preFillGroup,
    } = this.state

    const status = getUserEntityStatus(user, shop)

    const headersCSV = [
      {
        label: locale === 'fr' ? 'Entité (super admin seulement)' : 'Entity (super admin only)',
        key: 'entity',
      },
      {
        label: 'Email',
        key: 'email',
      },
      {
        label: locale === 'fr' ? 'Prénom' : 'First name',
        key: 'name',
      },
      {
        label: locale === 'fr' ? 'Nom' : 'Last name',
        key: 'lastName',
      },
      {
        label: locale === 'fr' ? 'Crédit à ajouter' : 'Credit to add',
        key: 'credit',
      },
      {
        label: locale === 'fr' ? 'Frais de livraison gratuits (true/false)' : 'Free delivery costs (true/false)',
        key: 'freeDelivery',
      },
      {
        label: locale === 'fr' ? 'Envoyer un mail a la création (true/false)' : 'Send an email to creation (true/false)',
        key: 'mailCreation',
      },
      {
        label: locale === 'fr'
          ? 'Groupe (si plusieurs groupes, séparer par une virgule et sans espace)'
          : 'Group (if several groups, separate with a comma and without space)',
        key: 'groups',
      },
      {
        label: locale === 'fr' ? 'Langue (fr/en)' : 'Language (en/fr)',
        key: 'language',
      },
      {
        label: locale === 'fr' ? 'Date d’anniversaire' : 'Birthday',
        key: 'birthday',
      },
      {
        label: locale === 'fr' ? 'Date d’entrée dans l’entreprise' : 'Employee\'s entry date',
        key: 'companyBirthday',
      },
      {
        label: locale === 'fr' ? 'Taille' : 'Size',
        key: 'size',
      },
    ]

    try {
      return (
        <Drawer
          visible={visibleDrawer}
          width={1000}
          onClose={hideDrawer}
          title={<FormattedMessage id='memberCreation.title' defaultMessage='Création Membre' />}
          id='memberCreation'
        >
          <GroupCreation
            visible={visibleGroupeCreation}
            onClose={() => this.setState({ visibleGroupeCreation: false })}
            shop={shop}
            modifyShop={modifyShop}
            preFillGroup={preFillGroup}
          />
          <span>
            <FormattedMessage id='memberCreation.text.CSV' defaultMessage='Vous pouvez ajouter vos employés avec ce CSV pré-rempli' />
            {' '}

          </span>
          <div style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginTop: 15,
          }}
          >
            <CSVLink
              data={[{
                entity: 'Tatooine',
                email: 'john.doe@panopli.co',
                name: 'John',
                lastName: 'Doe',
                credit: '1000',
                freeDelivery: 'true',
                mailCreation: 'true',
                groups: 'Développeur,Test',
                language: 'fr',
                birthday: 'DD/MM/YYYY',
                companyBirthday: 'DD/MM/YYYY',
                size: 'M',
              }]}
              headers={headersCSV}
              filename='members.csv'
              target='_blank'
              onClick={() => message.success(
                <FormattedMessage id='CSVDownloaded' defaultMessage='CSV téléchargé !' />,
              )}
            >
              <Button type='primary'>
                <Icon type='download' />
                {' '}
                <FormattedMessage id='preFilledCSV' defaultMessage='CSV pré-rempli' />
              </Button>
            </CSVLink>
            <Icon type='arrow-right' />
            <CSVReader
              onDrop={this.addMembersFromCSV}
              addRemoveButton
              style={{
                dropFile: {
                  height: 50,
                  borderRadius: 3,
                  width: 150,
                  padding: 0,
                },
                fileSizeInfo: {
                  display: 'none',
                },
                fileNameInfo: {
                  padding: 5,
                  lineHeight: 'inherit',
                },
                progressBar: {
                  backgroundColor: '#0000FF',
                },
              }}
            >
              <Button
                type='primary'
              >
                <Icon type='upload' />
                {' '}
                <FormattedMessage id='memberCreation.button.completedCSV' defaultMessage='CSV complété' />
              </Button>
            </CSVReader>
          </div>
          <Divider />
          <Form onSubmit={this.addMemberFromForm} id='MemberCreation'>
            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
              {entities.length && (status === 'owner' || status === 'admin' || status === 'adminCollaborator') ? (
                <Item label={<FormattedMessage id='entity' defaultMessage='Entité' />} style={{ flex: '1 2 auto', paddingRight: 10 }}>
                  {
                    getFieldDecorator('entityId', {
                      rules: [{
                        required: true,
                        message: <FormattedMessage
                          id='memberCreation.required.entity'
                          defaultMessage="Veuillez sélectionner l'entité du membre"
                        />,
                      }],
                    })(
                      <Select>
                        {entities.map((entity) => <Option key={entity._id} value={entity._id}>{entity.name}</Option>)}
                      </Select>,
                    )
                  }
                </Item>
              ) : null}
              <Item label={<FormattedMessage id='firstName' defaultMessage='Prénom' />} style={{ flex: '1 2 auto', paddingRight: 5 }}>
                {
                  getFieldDecorator('name', {
                    rules: [{
                      required: true,
                      message: <FormattedMessage
                        id='memberCreation.required.firstName'
                        defaultMessage='Veuillez entrer le prénom du membre'
                      />,
                    }],
                  })(<Input />)
                }
              </Item>
              <Item label={<FormattedMessage id='lastName' defaultMessage='Nom' />} style={{ flex: '1 2 auto', paddingLeft: 5 }}>
                {
                  getFieldDecorator('lastName', {
                    rules: [{
                      required: true,
                      message: <FormattedMessage
                        id='memberCreation.required.lastName'
                        defaultMessage='Veuillez entrer le nom du membre'
                      />,
                    }],
                  })(<Input />)
                }
              </Item>
            </div>
            <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
              <Item label={<FormattedMessage id='email' defaultMessage='Email' />} style={{ flex: '1 2 auto', paddingRight: 5 }}>
                {
                  getFieldDecorator('email', {
                    rules: [{
                      required: true,
                      message: <FormattedMessage
                        id='memberCreation.required.email'
                        defaultMessage="Veuillez entrer l'email du membre"
                      />,
                    }, {
                      type: 'email',
                      message: <FormattedMessage id='email.notValid' defaultMessage="L'Email n'est pas valide" />,
                    }],
                  })(<Input />)
                }
              </Item>
              <Item label='Pays' style={{ flex: '1 2 auto', paddingLeft: 5 }}>
                {
                  getFieldDecorator('country', {
                    rules: [{
                      required: true,
                      message: <FormattedMessage
                        id='memberCreation.required.country'
                        defaultMessage='Veuillez choisir un pays'
                      />,
                    }],
                  })(<CountrySelector />)
                }
              </Item>
              <Item label={<FormattedMessage id='language' defaultMessage='Langue' />} style={{ flex: '1 2 auto', paddingLeft: 5 }}>
                {
                  getFieldDecorator('language', {
                    rules: [{
                      required: true,
                      message: <FormattedMessage
                        id='memberCreation.required.language'
                        defaultMessage='Veuillez choisir une langue'
                      />,
                    }],
                  })(
                    <Radio.Group style={{ lineHeight: '38px' }}>
                      <Radio.Button value='fr'>
                        <FormattedMessage id='french' defaultMessage='Français' />
                      </Radio.Button>
                      <Radio.Button value='en'>
                        <FormattedMessage id='english' defaultMessage='Anglais' />
                      </Radio.Button>
                    </Radio.Group>,
                  )
                }
              </Item>
            </div>
            <div style={{ display: 'flex', margin: '5px 0 10px 0' }}>
              <Item label={<FormattedMessage id='credit' defaultMessage='Crédit' />} style={{ flex: '1 3 auto' }}>
                {
                  getFieldDecorator('credit', {
                    rules: [{
                      required: true,
                      message: <FormattedMessage
                        id='memberCreation.required.amount'
                        defaultMessage='Veuillez entrer un montant'
                      />,
                    }],
                  })(<InputNumber style={{ width: '100%' }} />)
                }
              </Item>
              <Item
                label={(
                  <FormattedMessage
                    id='freeDeliveryCosts'
                    defaultMessage='Frais de livraison gratuits'
                  />
                )}
                style={{ flex: '1 3 auto', margin: '0 0 0 10px' }}
              >
                {
                  getFieldDecorator('freeDelivery', { valuePropName: 'checked' })(<Switch />)
                }
              </Item>
              <Item
                label={(
                  <FormattedMessage
                    id='memberCreation.form.mail'
                    defaultMessage='Envoyer un mail à la création'
                  />
                )}
                style={{ flex: '1 2 auto', margin: '0 0 0 10px' }}
              >
                {
                  getFieldDecorator('mailCreation', { valuePropName: 'checked' })(<Switch />)
                }
              </Item>
            </div>
            <Item label={<FormattedMessage id='memberCreation.form.addGroup' defaultMessage='Ajouter à des groupes' />} id='addGroup'>
              <div style={{ display: 'flex', margin: '5px 0 10px 0' }}>
                {getFieldDecorator('groups')(
                  <Select
                    mode='multiple'
                    onChange={(newGroups) => this.setState({ groups: newGroups })}
                  >
                    {membersGroups.map((group) => <Option key={group.name}>{group.name}</Option>)}
                  </Select>,
                )}
                <Button
                  onClick={() => this.setState({ visibleGroupeCreation: true })}
                  style={{ marginLeft: 10 }}
                  type='primary'
                >
                  <FormattedMessage id='memberCreation.form.createGroup' defaultMessage='Créer un nouveau groupe' />
                </Button>
              </div>
            </Item>
            <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type='primary'
                htmlType='submit'
                style={{ marginRight: 20 }}
              >
                <FormattedMessage id='add' defaultMessage='Ajouter' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          </Form>
          <PreviewNewMembers
            shop={shop}
            members={members.concat(membersToUpdate)}
            deleteMember={this.deleteMember}
            entities={entities}
          />
          { members?.length || membersToUpdate?.length ? (
            <Button
              type='primary'
              onClick={() => this.onSubmit()}
            >
              <FormattedMessage id='memberCreation.button.createMembers' defaultMessage='Créer les membres de la liste preview' />
            </Button>
          ) : null}
        </Drawer>
      )
    } catch (e) {
      console.log('error containers/MemberCreation', e)
      return null
    }
  }
}

export default connect(
  (state) => ({
    user: fromUsers.getUser(state),
    shop: fromShops.getShop(state),
    allMembers: fromMembers.getMembers(state),
    entities: fromEntities.getEntities(state),
    locale: fromLocale.getLocale(state),
  }), (dispatch) => bindActionCreators({
    addMembers: (members, hideDrawer, emptyMembers) => ({
      type: ADD_MEMBERS,
      members,
      callback: () => {
        hideDrawer()
        emptyMembers()
      },
    }),
    modifyMember: (member) => ({
      type: MODIFY_MEMBER,
      member,
    }),
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)(Form.create({ name: 'memberCreation' })(MemberCreation))

/* eslint-disable camelcase */
import assignbps from '@balibart/assignbps'
import { calculateCartPrice } from '@balibart/pricing'
import { calculateShippingPrice } from '@balibart/shipping'
import { flatten } from 'lodash'
import validators from './validators'

import { getDeliveryDelays } from '../../store/cart/selectors'

const headersCSV = (locale = 'fr') => [
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: locale === 'fr' ? 'Prénom' : 'First name',
    key: 'firstName',
  },
  {
    label: locale === 'fr' ? 'Nom' : 'Last name',
    key: 'lastName',
  },
  {
    label: locale === 'fr' ? 'Adresse' : 'Street address',
    key: 'streetAddress',
  },
  {
    label: locale === 'fr' ? 'Pays (Code ISO)' : 'Country (ISO code)',
    key: 'country',
  },
  {
    label: locale === 'fr' ? 'Société' : 'Company',
    key: 'company',
  },
  {
    label: locale === 'fr' ? 'Ville' : 'City',
    key: 'city',
  },
  {
    label: locale === 'fr' ? 'Complément' : 'Complement',
    key: 'complement',
  },
  {
    label: locale === 'fr' ? 'Code postal' : 'Postal code',
    key: 'postalCode',
  },
  {
    label: locale === 'fr' ? 'Téléphone' : 'Phone',
    key: 'phone',
  },
]

const getColumnFromProduct = (product, availableStocks) => {
  try {
    if (product.stocked) {
      return availableStocks.map((stock) => {
        const { variationCombo } = stock
        const color = variationCombo.find((v) => v.type === 'color')
        const size = variationCombo.find((v) => v.type === 'size')
        return `${product.name} | ${color.name} | ${size.name}`
      })
    }
    // Si le produit est pas stocké, on map sur tous les variations selon leur type
    const colors = product.variations.filter((variation) => variation.type === 'color')
    const sizes = product.variations.filter((variation) => variation.type === 'size')
    return flatten(colors.map((color) => sizes.map((size) => `${product.name} | ${color.name} | ${size.name}`)))
  } catch (e) {
    console.log('getColumnFromProduct - error', e)
    return []
  }
}

const getColumnsFromProducts = (products, stocks) => flatten(products.map(
  (p) => getColumnFromProduct(p, stocks.filter((s) => s.productId === p._id && s.qty > 0)),
))

const formatSeller = (shop) => {
  const { settings } = shop
  const { name, logo, url } = settings
  return {
    seller_name: name,
    seller: String(shop._id),
    seller_logo: logo,
    // SHOULD WE USE DNS IF PRESENT ? -> helper
    seller_url: window.location.protocol.concat('//', window.location.host),
    seller_email: `${url}@panopli.co`,
  }
}

const checkAddress = (_address) => {
  const address = { ..._address }
  delete address.errorDetails
  delete address.error
  const keys = Object.keys(address)
  const verified = { errorDetails: [] }
  keys.map((k) => {
    if (validators[k]) {
      const check = validators[k](address[k], address.country)
      if (!check.error) {
        verified[k] = check.value
      } else {
        verified.errorDetails.push(check)
        verified.error = true
        verified[k] = address[k]
      }
    } else {
      verified[k] = address[k]
    }
    return true
  })
  return verified
}

const getIdFromName = (name, allProducts) => {
  try {
    const concernedProduct = allProducts.find((p) => p.name.split('|')[0].replace(/ /g, '') === name.replace(/ /g, ''))
    console.log('getIdFromName.concernedProduct', concernedProduct)
    return concernedProduct
  } catch (e) {
    console.log('getIdFromName - error', { e, name, allProducts })
    throw e
  }
}

const getProductNameFromRow = (value) => {
  const splitted = value.split('|')
  return splitted[0].trim()
}

const getVariationsFromRow = (value) => {
  const splitted = value.split('|').reverse()
  return {
    chosenColor: splitted[1],
    chosenSize: splitted[0],
  }
}

/**
 * Permet de récupérer les entêtes d'un CSV uploadé via CSVReader (avec l'option headers: true)
 * @param {array} data - Les données extraites du CSV
 * @returns {array} - Les entêtes du CSV
 */
const getCSVHeaders = (data) => data[0]?.meta?.fields || []

const formatAdresse = (data, allProducts, locale) => {
  const expectedColumns = ['firstName', 'lastName', 'email', 'company', 'city', 'postalCode', 'phone', 'streetAddress', 'country', 'complement']
  const requiredColumns = ['firstName', 'lastName', 'email', 'postalCode', 'phone', 'streetAddress', 'country']
  const productsColumns = Object.keys(data[0].data).filter((col) => !headersCSV(locale).find((h) => h.label === col))
  const trimmedProductsColumns = productsColumns.filter((p) => {
    const name = getProductNameFromRow(p)
    const product = getIdFromName(name, allProducts)
    return product
  })
  const result = data.map((d) => {
    const obj = {}

    expectedColumns.map((col) => {
      const header = headersCSV(locale).find((h) => h.key === col) || {}
      const country = headersCSV(locale).find((h) => h.key === 'country') || {}
      const countryValue = d.data[country.label]
      const rowValue = d.data[header.label]
      if (requiredColumns.includes(col)) {
        const check = validators[col] && validators[col](rowValue, countryValue)
        const { value, error, errorDetails } = check
        obj[col] = error ? rowValue : value
        if (errorDetails) {
          obj.errorDetails = (obj.errorDetails || []).concat(errorDetails)
        }
      } else {
        obj[col] = rowValue
      }
      return true
    })
    trimmedProductsColumns.map((col) => {
      obj.products = (obj.products || []).concat([{
        target: col,
        qty: parseInt(d.data[col], 10) || 0,
      }])
      return null
    })
    return obj
  })
  return result.map((a) => ({ ...a, key: Math.random() }))
}

const formatClient = (values) => {
  const {
    firstName, lastName, email, city, postalCode, phone, streetAddress, country, complement, company,
  } = values

  return {
    firstName,
    lastName,
    email,
    city,
    postalCode,
    phone,
    company,
    address: {
      streetAddress,
      city,
      postalCode,
      country,
      complement,
    },
  }
}

const formatVariations = ({ chosenColor, chosenSize }, product) => {
  const color = product.variations.find((v) => v.type === 'color' && v.name.replace(/ /g, '') === chosenColor.replace(/ /g, ''))
  const size = product.variations.find((v) => v.type === 'size' && v.name.replace(/ /g, '') === chosenSize.replace(/ /g, ''))
  return { size, color }
}

const trimProducts = ({ target, qty }, allProducts) => {
  try {
    if (qty) {
      const name = getProductNameFromRow(target, allProducts)
      const { _id } = getIdFromName(name, allProducts)

      const dbProduct = allProducts.find((p) => String(p._id) === String(_id))
      const { baseProduct } = dbProduct

      const variations = formatVariations(getVariationsFromRow(target), dbProduct)

      // CHECK UNIT PRICE , MARGIN ETC
      const final = {
        id: String(dbProduct._id),
        _id: String(dbProduct._id),
        variations,
        quantity: qty,
        baseProduct: baseProduct.supplierBaseProductReference || 'Custom',
        supplierBaseProductId: baseProduct._id ? String(baseProduct._id) : '000000000000000000000000',
        name: dbProduct.name,
        unitPrice: 0,
        margin: 0,
        stock: dbProduct.stock,
        custom: dbProduct.custom,
        category_fr: baseProduct && baseProduct.category && baseProduct.category.fr,
        sub_category_fr: baseProduct && baseProduct.subCategory && baseProduct.subCategory.fr,
        category_en: baseProduct && baseProduct.category && baseProduct.category.en,
        sub_category_en: baseProduct && baseProduct.subCategory && baseProduct.subCategory.en,
        designs: [],
      }

      return final
    }
    return null
  } catch (e) {
    console.log('trimProducts - error ici', e)
    return null
  }
}

const getProductsFromRow = (row, allProducts) => {
  const { products } = row
  const result = products?.map((p) => trimProducts(p, allProducts))
  return result
}

const formatOrder = async (client, products, sellerParams, allProducts, shop, suppliers, bps, stocks, entityId) => {
  const {
    seller_name, seller, seller_logo, seller_url, seller_email,
  } = sellerParams

  const defaultConfigDeliveries = getDeliveryDelays() // handle default values for delieries

  const base = {
    products: products?.filter(Boolean).map((p) => ({ ...p, entityId })),
    client: formatClient(client),
    seller_name,
    seller,
    seller_logo,
    seller_url,
    seller_email,
    shippingPrice: '0.00',
    entityId,
    customerOrderId: Math.random().toString(36).substr(2, 10),
    shipping: {
      shippingMode: 'standard',
      pickupStoreId: '',
      pickupInfos: {},
    },
    discounts: {
      campaign: {},
      gift: false,
    },
    transaction: {
      amount: 0,
      currency: 'eur',
      transaction_type: 'batch',
      source: false,
    },
    detailedPricing: {
      total: 0,
      delivery: 0,
      delivery_discounted: 0,
    },
    deliveryDelays: {
      deliveryMin: defaultConfigDeliveries.min,
      deliveryMax: defaultConfigDeliveries.max,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  }

  let pricing = 0
  try {
    pricing = await calculateCartPrice(base, allProducts, true, stocks)
  } catch (e) {
    console.log('PRICING ERROR', e)
    return { error: true, error_step: 'shipping', ...base }
  }
  let shipping = 0
  try {
    const assignedProducts = await assignbps(base.products, allProducts, bps)
    shipping = await calculateShippingPrice({
      ...base,
      address: base.client.address,
    }, shop, allProducts, suppliers, assignedProducts, stocks)
  } catch (e) {
    console.log('shipping error', { e, shipping })
    return { error: true, error_step: 'shipping', ...base }
  }
  base.transaction.amount = parseInt((pricing + shipping.price) * 100, 10)
  base.detailedPricing.delivery = shipping.price
  return base
}

export {
  checkAddress, formatAdresse, formatOrder, formatSeller, getCSVHeaders, getColumnsFromProducts, getProductsFromRow, headersCSV,
}

// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
export const FETCH = 'FETCH_USER'
export const FETCH_FORCE_USER = 'FETCH_FORCE_USER'
export const RECEIVE = 'RECEIVE_USER'
export const USER_ENTITY = 'USER_ENTITY'
export const MODIFY_USER_ENTITY = 'MODIFY_USER_ENTITY'
export const RECEIVE_USER_ENTITY = 'RECEIVE_USER_ENTITY'
export const LOGOUT = 'LOGOUT'
export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const ERROR = 'ERROR'
export const ERROR_ENTITY = 'ERROR_ENTITY'
export const REQUEST_TOKEN_FOR_PW_REINIT = 'REQUEST_TOKEN_FOR_PW_REINIT'
export const REINIT_PW = 'REINIT_PW'
export const MODIFY = 'MODIFY_USER'
export const MODIFY_FACTURATION = 'MODIFY_FACTURATION'
export const MODIFIED = 'MODIDFIED_USER'
export const FETCH_SHOP_USERS = 'FETCH_SHOP_USERS'
export const RECEIVE_FACTURATION = 'RECEIVE_FACTURATION'
export const SET_NEW_TOKENS = 'SET_NEW_TOKENS'
export const GET_NEW_TOKENS = 'GET_NEW_TOKENS'
export const SET_NEW_TOKENS_ERROR = 'SET_NEW_TOKENS_ERROR'
export const FORCE_LOG_OUT = 'FORCE_LOG_OUT'
export const CLEAR_SESSION = 'CLEAR_SESSION'
export const INIT_CLEAR_SESSION = 'INIT_CLEAR_SESSION'
export const SEND_USER_MAIL = 'SEND_USER_MAIL'
export const REINIT_PW_AUTHENTICATE = 'REINIT_PW_AUTHENTICATE'

export const forceLogout = () => ({
  type: FORCE_LOG_OUT,
})

export const logout = () => ({
  type: LOGOUT,
})

export const login = (data, callback) => {
  const { email, password } = data
  return {
    type: FETCH,
    payload: {
      email,
      password,
      callback,
    },
  }
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons'

import { ErrorMessage, MagicLinksInputTitle } from '../..'

import {
  hasAlreadyBeenUsed,
  hasAlreadyBeenSent,
  isUsableEmailAddress,
  displayUnvalidFormat,
} from '../../../helpers/magicLinks'
import { trimInput } from '../../../helpers/trim'
import { character } from '../../../config/theme'

const MagicLinksSendingManuallyHeader = (props) => {
  const {
    mails,
    magicLinks,
    currentCampaignId,
    emailInput,
    setEmailInput,
    firstNameInput,
    setFirstNameInput,
    handleEmailAdd,
  } = props

  // Logique de contrôle d'une adresse pour ajout à la liste d'envoi
  const unvalidFormat = displayUnvalidFormat(emailInput)
  const alreadyUsed = hasAlreadyBeenUsed(mails, emailInput)
  const alreadySent = hasAlreadyBeenSent(emailInput, magicLinks, currentCampaignId)
  const disabledAdd = !isUsableEmailAddress(emailInput, mails, magicLinks, currentCampaignId)

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', gap: '16px' }}>
      <MagicLinksInputTitle
        title={(<FormattedMessage id='emailAddress' defaultMessage='Adresse email' />)}
      >
        <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
          <Input
            value={emailInput}
            placeholder='thomas@mail.com'
            onChange={(e) => setEmailInput(trimInput(e.target.value))}
            prefix={(<FontAwesomeIcon icon={faEnvelope} style={{ color: character.disabledPlaceholder }} />)}
          />
          <ErrorMessage isVisible={unvalidFormat}>
            <FormattedMessage
              id='auth.checkEmail'
              defaultMessage='Le format de cette adresse mail est incorrect'
            />
          </ErrorMessage>
          <ErrorMessage isVisible={alreadyUsed}>
            <FormattedMessage
              id='email.alreadyInSendList'
              defaultMessage='Cet email est déjà dans la liste'
            />
          </ErrorMessage>
          <ErrorMessage isVisible={alreadySent}>
            <FormattedMessage
              id='email.alreadySent'
              defaultMessage='Cet email a déjà été envoyé'
            />
          </ErrorMessage>
        </div>
      </MagicLinksInputTitle>
      <MagicLinksInputTitle
        title={(<FormattedMessage id='firstName' defaultMessage='Prénom' />)}
        info={(
          <span style={{
            padding: -4,
            fontWeight: 400,
            fontSize: '14px',
            color: character.secondary,
          }}
          >
            <FormattedMessage id='field.optional' defaultMessage='(facultatif)' />
          </span>
        )}
      >
        <Input
          value={firstNameInput}
          placeholder='Thomas'
          onChange={(e) => setFirstNameInput(e.target.value)}
          prefix={(<FontAwesomeIcon icon={faUser} style={{ color: character.disabledPlaceholder }} />)}
        />
      </MagicLinksInputTitle>
      <Button
        type='primary'
        disabled={disabledAdd}
        onClick={handleEmailAdd}
      >
        <FormattedMessage id='add' defaultMessage='Ajouter' />
      </Button>
    </div>
  )
}

export default MagicLinksSendingManuallyHeader

export const initialState = {
  loading: false,
  error: false,
  members: [],
  member: {},
  isMembersPageReady: false,
}

export const getMembers = (state = initialState) => state.members || []

export const getMembersLength = (state = initialState) => getMembers(state).length

export const getMember = (state = initialState) => state.member

export const loading = (state = initialState) => state.loading

export const error = (state = initialState) => (state.error ? state.error_details : false)

export const isMembersPageReady = (state = initialState) => state.isMembersPageReady

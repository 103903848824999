const version = 17
// eslint-disable-next-line camelcase
const state_key = (url, v) => `${url}:${v}`

const trimStateLoader = (_state) => {
  const state = _state
  delete state.savedAt
  return state
}

const loadState = () => {
  try {
    const serializedState = localStorage.getItem(state_key(window.location.hostname, version))
    // NOTE: we clear localStorage on load
    localStorage.clear()
    if (!serializedState) {
      return {}
    }
    const parsedState = JSON.parse(serializedState)
    if (!serializedState) {
      return {}
    }
    return trimStateLoader(parsedState)
  } catch (err) {
    console.log('stateLoader load Err', err)
    return {}
  }
}

const saveState = (state) => {
  try {
    // const previousState = loadState()
    const date = new Date()
    const serializedState = JSON.stringify({
      cart: state.cart,
      users: state.users,
      savedAt: date.toString(),
      baseProductsCart: {
        content: state.baseProductsCart.content,
      },
      shops: {
        currentShopId: state.shops.currentShopId,
        currentShop: state.shops.currentShop,
        allShops: state.shops.allShops,
      },
      products: {
        draftProducts: state.products.draftProducts,
      },
      // orders: state.orders,
      // checkout: state.checkout || previousState.checkout,
    })
    localStorage.setItem(state_key(window.location.hostname, version), serializedState)
  } catch (err) {
    console.log('stateLoader save Err', err)
  }
}

export const getAuth = () => {
  try {
    // const stateLoader = new StateLoader()
    // TODO check validity of auth (expiration)
    const auth = loadState().user.userauth
    // console.log('getting auth', auth)
    return auth
  } catch (e) {
    return ''
  }
}

export default {
  loadState,
  saveState,
}

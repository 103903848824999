// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import { put, takeEvery, all } from 'redux-saga/effects'

import * as actions from './actions'
import signedUpload from '../../helpers/s3'

export function *upload({ payload: _payload }) {
  try {
    const payload = _payload
    const {
      files = [],
      uploadTokens,
      callback,
    } = payload

    const fileTypes = Object.keys(files)
    if (files && fileTypes.length > 0) {
      yield all(
        files.map(async (file) => {
          const uploadToken = uploadTokens
            .find((token) => token.object === file.hash.object).uri

          if (uploadToken) {
            await signedUpload(
              file.file,
              uploadToken,
            )
          }
          return true
        }),
      )
    }
    yield put({
      type: actions.UPLOAD_SUCCESS,
    })

    if (callback) {
      callback()
    }
    return true
  } catch (error) {
    console.log('*upload', { error })
    yield put({
      type: actions.UPLOAD_SUCCESS,
    })

    return false
  }
}

export default function *({ api }) {
  yield takeEvery(actions.UPLOAD, upload)
  // yield put({ type: actions.FETCH, payload: {} })
}

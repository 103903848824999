import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Form,
  Input,
  Row,
  Button,
} from 'antd'

class SecurityForm extends React.Component {
  constructor() {
    super()
    this.state = {
      confirmDirty: false,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
        resetFields,
      },
      onSubmit,
    } = this.props
    validateFieldsAndScroll((err, values) => {
      const { newPassword, oldPassword } = values
      if (!err) {
        onSubmit({ newPassword, oldPassword, callback: resetFields })
      }
    })
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target
    const { confirmDirty } = this.state
    this.setState({ confirmDirty: confirmDirty || !!value })
  }

   hasValue = (rule, value, callback) => {
     if (!value) {
       callback(
         <FormattedMessage
           id='securityForm.oldPasswordRequired'
           defaultMessage='Veuillez renseignez votre ancien mot de passe'
         />,
       )
     }
   }

  compareToFirstPassword = (rule, value, callback) => {
    const {
      form: {
        getFieldValue,
      },
    } = this.props
    if (value && value !== getFieldValue('newPassword')) {
      callback(<FormattedMessage id='securityForm.differentPassword' defaultMessage='Votre mot de passe est différent' />)
    } else {
      callback()
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props
    const { confirmDirty } = this.state
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        getFieldValue,
        isFieldsTouched,
        resetFields,
      },
    } = this.props

    // const prefixSelector = getFieldDecorator('prefix', {
    //   initialValue: '33',
    // })(
    //   <Select style={{ width: 70 }}>
    //     <Option value='86'>+86</Option>
    //     <Option value='87'>+87</Option>
    //   </Select>,
    // )
    // TODO better input phone

    return (
      <Form
        id='SecurityForm'
        onSubmit={this.handleSubmit}
      >
        <span>
          <FormattedMessage id='passwordInfo' defaultMessage='Pour modifier votre mot de passe veuillez renseignez l&apos;ancien, puis le nouveau' />
        </span>
        <Form.Item label={<FormattedMessage id='oldPassword' defaultMessage='Veuillez renseignez votre ancien mot de passe' />} hasFeedback>
          {getFieldDecorator('oldPassword', {
            rules: [
              {
                required: true,
              },
            ],
          })(<Input.Password style={{ width: 300 }} />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage id='newPassword' defaultMessage='Nouveau mot de passe' />} hasFeedback>
          {getFieldDecorator('newPassword', {
            rules: [
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password style={{ width: 300 }} />)}
        </Form.Item>
        {getFieldValue('newPassword') ? (
          <Form.Item label={<FormattedMessage id='confirmPassword' defaultMessage='Confirmez votre mot de passe' />} hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: !!getFieldValue('newPassword'),
                  message: <FormattedMessage id='passwordReset.differentInputs' defaultMessage='Veuillez vérifier votre mot de passe' />,
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password style={{ width: 300 }} onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
        ) : null}
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{ marginRight: 20 }}
              >
                <FormattedMessage id='modify' defaultMessage='Modifier' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    )
  }
}

export default Form.create({ name: 'SecurityForm' })(SecurityForm)

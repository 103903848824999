import React from 'react'
import {
  List, Card, Menu, Checkbox, Pagination, Button, Icon, Tag,
} from 'antd'
import ProductImage from '@balibart/product-image'
import { FormattedMessage } from 'react-intl'
import theme from '../../../../config/theme'
import config from '../../../../config'

const { image_base_url: imageBaseUrl } = config

const { primaryColor } = theme

const { SubMenu } = Menu

const RecapFilters = (props) => {
  const { filters } = props
  return (
    <div style={{ margin: '10px 0px 10px 0px' }}>
      {filters.map((filter) => (
        <Tag color={primaryColor[6]}>
          {filter.value}
        </Tag>
      ))}
    </div>
  )
}

const FiltersMenu = (props) => {
  const {
    collections = [], categories = [], subCategories = [], setFilter, resetFilters, filters,
  } = props

  const renderItems = (items, type) => items.map((item) => (
    <Menu.Item onClick={() => setFilter({ type, value: item })}>
      {item}
    </Menu.Item>
  ))

  return (
    <div>
      <RecapFilters filters={filters} />
      <Menu mode='horizontal' style={{ marginBottom: 10 }}>

        <SubMenu title={<FormattedMessage id='collections' defaultMessage='Collections' />}>
          <Menu.ItemGroup>
            {renderItems(collections, 'collection')}
          </Menu.ItemGroup>
        </SubMenu>
        <SubMenu title={<FormattedMessage id='categories' defaultMessage='Catégories' />}>
          <Menu.ItemGroup>
            {renderItems(categories, 'category')}
          </Menu.ItemGroup>
        </SubMenu>
        <SubMenu title={<FormattedMessage id='subCategories' defaultMessage='Sous-Catégories' />}>
          <Menu.ItemGroup>
            {renderItems(subCategories, 'subCategory')}
          </Menu.ItemGroup>
        </SubMenu>
        { filters.length ? (
          <Menu.Item onClick={() => resetFilters()}>
            Reset Filters
          </Menu.Item>
        ) : null }
      </Menu>
    </div>
  )
}

const ProductsRecap = (props) => {
  const {
    products, selected = [], select,
  } = props
  const selectedProducts = products.filter((p) => selected.includes(p._id))

  return (
    <div style={{ width: '600px', textALign: 'center', height: '80vh' }}>
      <List
        size='large'
        header={(
          <div style={{ textAlign: 'center' }}>
            <FormattedMessage id='productsForm.selectedProducts' defaultMessage='Produits sélectionnés' />
          </div>
        )}
        dataSource={selectedProducts}
        renderItem={(item) => (
          <List.Item>
            {item.name}
            {' '}
            <Icon type='close' onClick={() => select(String(item._id))} />
            {' '}
          </List.Item>
        )}
      />
    </div>
  )
}

export default class ProductsForm extends React.Component {
  constructor(props) {
    super(props)
    // const state = props.stateLoader.loadState()
    this.state = {
      pages: 1,
      filters: [],
    }
  }

  resetFilters = () => {
    this.setState(() => ({
      filters: [],
    }))
  }

  setFilter = (filter) => {
    this.setState((state) => {
      const { filters } = state
      const isPresent = filters.find((f) => f.type === filter.type)
      if (isPresent) {
        const newFilters = filters.map((f) => {
          if (f.type === filter.type) {
            return filter
          }
          return f
        })
        return {
          filters: newFilters,
        }
      }
      return {
        filters: filters.concat([filter]),
      }
    })
  }

  applyFilters = (data, start, end, lang = 'fr') => {
    const { filters } = this.state
    let filteredData = data
    filters.map((filter) => {
      if (filter.type === 'collection') {
        filteredData = filteredData.filter((product) => {
          const { collections = [] } = product
          return collections.includes(filter.value)
        })
      }
      if (filter.type === 'category') {
        filteredData = filteredData.filter((product) => product.baseProduct.category[lang].includes(filter.value))
      }
      if (filter.type === 'subCategory') {
        filteredData = filteredData.filter((product) => product.baseProduct.subCategory[lang] === filter.value)
      }
      return 1
    })
    return filteredData.slice(start, end)
  }

  render() {
    const {
      products = [],
      collections,
      categories,
      subCategories,
      setProducts,
      select,
      selected = [],
      closeDrawer,
    } = this.props
    const { pages, filters } = this.state
    const start = 10 * (pages - 1)
    const end = 10 * pages
    const data = this.applyFilters(products, start, end)
    return (
      <div>
        <h1 style={{ textAlign: 'center' }}>
          <FormattedMessage id='productsForm.selectProducts' defaultMessage='Sélectionnez vos produits' />
        </h1>
        <p>
          { selected.length
            ? (
              <Button onClick={() => closeDrawer()}>
                <FormattedMessage id='productsForm.addProducts' defaultMessage=' Ajouter mes produits' />
              </Button>
            )
            : null }
        </p>
        <FiltersMenu
          collections={collections}
          categories={categories}
          subCategories={subCategories}
          setFilter={this.setFilter}
          filters={filters}
          resetFilters={this.resetFilters}
        />
        <div style={{ display: 'flex' }}>
          <List
            grid={{
              gutter: 5,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 6,
              xxl: 6,
            }}
            tableLayout='auto'
            style={{ margin: '0 10px', minWidth: 700 }}
            dataSource={data}
            renderItem={(product) => (
              <List.Item>
                <Card
                  extra={(
                    <Checkbox
                      checked={selected.includes(String(product._id))}
                      onClick={() => select(String(product._id))}
                    />
                  )}
                  title={(
                    <span style={{ fontSize: 10 }}>
                      {' '}
                      {product.name}
                      {' '}
                    </span>
                  )}
                  size='small'
                >
                  <ProductImage
                    base_url={imageBaseUrl}
                    product={product}
                    variation={product.favColor}
                    currentPrintingZone={product.favPz || 'front'}
                  />
                </Card>
              </List.Item>
            )}
          />
          <ProductsRecap
            products={products}
            selected={selected}
            select={select}
            setProducts={setProducts}
          />
        </div>
        <Pagination
          defaultCurrent={1}
          total={products.length}
          onChange={(_pages) => this.setState({ pages: _pages })}
          style={{ marginTop: 10 }}
        />
      </div>
    )
  }
}

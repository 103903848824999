import React from 'react'
import {
  Select, InputNumber, Form, Typography, Checkbox,
} from 'antd'
import { FormattedMessage } from 'react-intl'

const { Option } = Select
const { Title } = Typography

export default class BenefitsForm extends React.Component {
  constructor() {
    super()
    this.state = {
      typeInput: false,
    }
  }

  handleNumberChange = (e) => {
    const number = parseFloat(e || 0)
    if (Number.isNaN(number)) {
      return
    }
    this.triggerChange({ value: number })
  }

 handleBenefitChange = (benefit) => {
   this.setState({ typeInput: benefit })
   this.triggerChange({ benefit })
 }

 handleZoneChange = (value) => {
   this.triggerChange({ value })
 }

 handleShippingChange = (e) => {
   this.triggerChange({ spreadToShipping: e.target.checked })
 }

   triggerChange = (changedValue) => {
     const { onChange, value } = this.props
     if (onChange) {
       onChange({
         ...value,
         ...changedValue,
       })
     }
   };

   render() {
     const { type = {}, value = {} } = this.props
     const { typeInput } = this.state
     const { benefit } = value
     if (!type.value) {
       return null
     }
     if (type.value === 'campaign') {
       const component = benefit === 'shipping' ? (
         <Select
           value={value.value}
           style={{ width: '300px' }}
           onChange={this.handleZoneChange}
           placeholder={<FormattedMessage id='benefitsForm.chooseArea' defaultMessage='Choisissez la zone concernée' />}
         >
           <Option value='FR'>
             <FormattedMessage id='france' defaultMessage='France' />
           </Option>
           <Option value='EU'>
             <FormattedMessage id='europe' defaultMessage='Europe' />
           </Option>
           <Option value='World'>
             <FormattedMessage id='world' defaultMessage='Monde' />
           </Option>
         </Select>
       ) : (
         <InputNumber
           style={{ width: '100px', margin: '0 0 0 10px' }}
           type='text'
           value={value.value}
           onChange={this.handleNumberChange}
           max={typeInput === 'percent' ? 100 : Infinity}
           parser={(_value) => _value.replace('-', '')}
           placeholder='Quel montant souhaitez vous Offrir'
         />
       )
       return (
         <div>
           <Title level={4}>
             <FormattedMessage id='benefitsForm.chooseGift' defaultMessage='Que souhaitez vous offrir ?' />
           </Title>
           <Select
             value={benefit}
             style={{ width: '300px', margin: 0 }}
             onChange={this.handleBenefitChange}
             placeholder={<FormattedMessage id='benefitsForm.giftOptions' defaultMessage='Frais de port offert / % de réduction ' />}
           >
             <Option value='percent'>
               <FormattedMessage id='benefitsForm.percentReduction' defaultMessage='Pourcentage de Réduction' />
             </Option>
             <Option value='shipping'>
               <FormattedMessage id='benefitsForm.freeDelivery' defaultMessage='Frais de Livraison Offerts' />
             </Option>
           </Select>
           { benefit ? component : null}
         </div>
       )
     }
     return (
       <div>
         <Title level={4}>
           <FormattedMessage id='benefitsForm.chooseGift' defaultMessage='Que souhaitez vous offrir ?' />
         </Title>
         <div style={{ display: 'flex' }}>
           <Form.Item
             hasFeedback
             validateStatus={value && value.value ? 'success' : null}
             style={{ margin: 0 }}
           >
             <InputNumber
               type='text'
               value={value.value}
               onChange={this.handleNumberChange}
               max={typeInput === 'percent' ? 100 : Infinity}
               parser={(_value) => _value.replace('-', '')}
               style={{ width: '250px', margin: 0 }}
               placeholder='Quel montant souhaitez vous offrir'
             />
           </Form.Item>
           <Form.Item
             hasFeedback
             validateStatus={value && value.benefit ? 'success' : null}
             style={{ margin: '0 0 0 10px' }}
           >
             <Select
               value={value.benefit}
               style={{ width: '100px' }}
               onChange={this.handleBenefitChange}
               placeholder='€ / %'
             >
               <Option value='percent'> % </Option>
               <Option value='amount'> € </Option>
             </Select>
           </Form.Item>
         </div>
         <Form.Item>
           <Checkbox
             checked={value.spreadToShipping}
             onChange={this.handleShippingChange}
           >
             <FormattedMessage id='benefitsForm.extend' defaultMessage='Etendre votre promotion aux frais de livraison ?' />
           </Checkbox>
         </Form.Item>
       </div>
     )
   }
}

export const GET = 'GET_CAMPAIGNS'
export const RECEIVED = 'RECEIVED_CAMPAIGNS'
export const FETCH_FORCE = 'FETCH_FORCE_CAMPAIGNS'
export const LOADING = 'LOADING_CAMPAIGNS'
export const ERROR = 'ERROR_CAMPAIGNS'
export const CREATE = 'CREATE_CAMPAIGN'
export const UPDATE = 'UPDATE_CAMPAIGN'
export const SEND_TEST = 'SEND_TEST'
export const INIT_FETCH_DATA = 'INIT_FETCH_DATA_CAMPAIGNS'
export const END_FETCH_DATA = 'END_FETCH_DATA_CAMPAIGNS'
export const REMOVE_DRAFT_CAMPAIGN = 'REMOVE_DRAFT_CAMPAIGN_CAMPAIGNS'

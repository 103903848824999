import { hasCampaignEnoughProducts, isCampaignActionsValid, isCampaignMessageValid } from '../campaignValidators'
import { errorNotify } from '../notification'

const campaignHasValidMessageConstraint = (campaign, intl) => {
  const isMessageValid = isCampaignMessageValid(campaign)
  if (!isMessageValid) {
    errorNotify({
      intl,
      messageId: 'campaigns.edit.step.impossible.save.notification.title',
      defaultMessage: 'Impossible de sauvegarder la campagne',
      descriptionId: 'campaigns.edit.step.message.save.notification.description',
      defaultDescription: 'Veuillez remplir le titre et le contenu du message ou désactiver le message dans l\'onglet Message',
    })
  }
  return isMessageValid
}

const campaignHasEnoughProductsConstraint = (campaign, intl) => {
  const hasEnoughProducts = hasCampaignEnoughProducts(campaign)
  if (!hasEnoughProducts) {
    errorNotify({
      intl,
      messageId: 'campaigns.edit.step.impossible.save.notification.title',
      defaultMessage: 'Impossible de sauvegarder la campagne',
      descriptionId: 'campaigns.edit.step.products.save.notification.description',
      defaultDescription: 'Veuillez paramétrer un don ou sélectionner au moins un produit depuis l\'onglet Produits',
    })
  }
  return hasEnoughProducts
}

const campaignHasValidActionsConstraint = (campaign, intl) => {
  const isActionsValid = isCampaignActionsValid(campaign)
  if (!isActionsValid) {
    errorNotify({
      intl,
      messageId: 'campaigns.edit.step.impossible.save.notification.title',
      defaultMessage: 'Impossible de sauvegarder la campagne',
      descriptionId: 'campaigns.edit.step.actions.save.notification.description',
      defaultDescription: 'Veuillez renseigner tous les champs de l\'Action',
    })
  }
  return isActionsValid
}

export {
  campaignHasValidActionsConstraint,
  campaignHasValidMessageConstraint,
  campaignHasEnoughProductsConstraint,
}

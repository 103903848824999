import React from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import { ProductImage } from '../..'
import {
  primaryColor, neutralColor, white, character,
} from '../../../config/theme'

const CampaignProductCard = (props) => {
  const {
    product, // Required
    addSelectedProduct, // Required
    removeSelectedProduct, // Required
    isDisabled = false,
    isSelected = false,
  } = props

  const handleCardClick = () => {
    if (!isSelected && !isDisabled) {
      addSelectedProduct(product)
    } else if (isSelected) {
      removeSelectedProduct(product)
    }
  }

  return (
    <div
      style={{
        ...(isSelected && { boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px ${white}, 0px 0px 0px 4px ${primaryColor[6]}` }),
        ...(isDisabled && { opacity: '0.5' }),
        cursor: 'pointer',
        borderRadius: 12,
        border: `1px solid ${neutralColor[2]}`,
        height: '100%',
        position: 'relative',
      }}
      onClick={handleCardClick}
    >
      <ProductImage product={product} />
      <div style={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '64px',
        backgroundColor: 'rgb(239, 242, 245, 0.5)',
        backdropFilter: 'blur(6px)',
        borderRadius: '0 0 12px 12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px',
      }}
      >
        <span style={{
          color: character[6],
          fontSize: 12,
          fontWeight: 500,
          width: '80%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        >
          {product.name}
        </span>
        {isSelected && (
          <Button
            style={{
              width: '32px',
              height: '32px',
              padding: '0',
              backgroundColor: primaryColor[6],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleCardClick}
          >
            <FontAwesomeIcon icon={faMinus} style={{ color: white }} />
          </Button>
        )}
        {!isSelected && !isDisabled && (
          <Button
            style={{
              width: '32px',
              height: '32px',
              padding: '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleCardClick}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </div>
    </div>
  )
}

export default CampaignProductCard

import React, { useState } from 'react'
import {
  Form, Input, Button, Row, message, Tooltip,
} from 'antd'
import { withRouter } from 'react-router'
import { FormattedMessage, injectIntl } from 'react-intl'
import theme from '../../../config/theme'
import { hasEnoughComplexity, PasswordRules } from '../../../helpers/password'

const { secondaryColor } = theme

const PasswordResetForm = ({
  form,
  reinitPassword,
  intl,
  token,
  user,
  form: {
    getFieldDecorator,
    isFieldsTouched,
    validateFieldsAndScroll,
  },
  history,
}) => {
  const [confirmDirty, setConfirmDirty] = useState(false)

  const handleSubmit = (e) => {
    try {
      e.preventDefault()
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          reinitPassword({
            password: values.password,
            token,
            callback: (error) => {
              if (error) {
                console.log('PasswordResetForm - Update error', { error })
                message.error(
                  <FormattedMessage
                    id='passwordReset.updateError'
                    defaultMessage='Une erreur est survenue'
                  />,
                )
                return false
              }
              message.success(
                <FormattedMessage
                  id='updateSuccess.password'
                  defaultMessage='Votre mot de passe a été modifié'
                />,
              )
              history.push('/')
              return true
            },
          })
        }
      })
    } catch (error) {
      console.log('PasswordResetForm - HandleSubmit', { error })
    }
  }

  const handleConfirmBlur = (e) => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(intl.formatMessage({
        id: 'passwordReset.differentInputs',
        defaultMessage: 'Les mots de passe sont différents',
      }))
    } else {
      callback()
    }
  }

  const validateToNextPassword = (rule, value, callback) => {
    if (value) {
      // on utilise passwordStrength pour déterminer la complexité du password
      const test = hasEnoughComplexity(value)
      if (test) {
        form.validateFields(['confirm'], { force: true })
      } else {
        callback(intl.formatMessage({
          id: 'passwordReset.lowComplexity',
          defaultMessage: 'Votre mot de passe ne respecte pas les exigences de sécurité',
        }))
      }
    }
    callback()
  }

  return (
    <div style={{ maxWidth: 250 }}>
      { user?.pristine ? (
        <span style={{ color: secondaryColor[6], fontWeight: 'bold' }}>
          <FormattedMessage
            id='passwordReset.firstConnection'
            defaultMessage='Veuillez changer votre mot de passe 😉'
          />
        </span>
      ) : (
        <span style={{ color: secondaryColor[6], fontWeight: 'bold' }}>
          <FormattedMessage
            id='passwordReset.forgetPassword'
            defaultMessage='Choisissez un nouveau mot de passe 😉'
          />
        </span>
      )}
      <Form
        onSubmit={handleSubmit}
      >
        <Tooltip style={{ padding: 5 }} title={(<PasswordRules />)} placement='rightBottom'>
          <Form.Item
            label={(
              <FormattedMessage
                id='password'
                defaultMessage='Mot de passe'
              />
            )}
            hasFeedback
          >
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'passwordReset.requiredPW',
                    defaultMessage: 'Choisissez un mot de passe',
                  }),
                },
                {
                  validator: validateToNextPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
        </Tooltip>
        <Form.Item
          label={(
            <FormattedMessage
              id='passwordReset.PWC'
              defaultMessage='Confirmez le mot de passe'
            />
          )}
          hasFeedback
        >
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'passwordReset.requiredPWConfirm',
                  defaultMessage: 'Confirmez votre mot de passe',
                }),
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password
            onBlur={handleConfirmBlur}
            onPaste={(e) => {
              e.preventDefault()
              return false
            }}
          />)}
        </Form.Item>
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
              >
                <FormattedMessage
                  id='resetPassword.confirm'
                  defaultMessage='Changer mon mot de passe'
                />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    </div>

  )
}

export default Form.create()(withRouter(injectIntl(PasswordResetForm)))

/* eslint-disable import/prefer-default-export */
import React from 'react'
import { passwordStrength } from 'check-password-strength'
import { FormattedMessage } from 'react-intl'

export const VALID_INDEX = 1
export const UNVALID_INDEX = 0

const passwordComplexityRules = [
  {
    id: UNVALID_INDEX,
    value: 'Not Ok',
    minDiversity: 0,
    minLength: 0,

  },
  {
    id: VALID_INDEX,
    value: 'Ok',
    minDiversity: 4,
    minLength: 12,
  },
]

export const hasEnoughComplexity = (value) => {
  try {
    const complexity = passwordStrength(value, passwordComplexityRules)
    const { id } = complexity
    return id === VALID_INDEX
  } catch (e) {
    return false
  }
}

// eslint-disable-next-line max-len
const rulesAsString = 'Votre password doit contenir au moins : {br}- 12 caractères {br}- une majuscule{br}- une minuscule{br} -un nombre{br}- un caractère spécial'

export const PasswordRules = () => (
  <FormattedMessage
    id='passwordReset.passwordComplexityRules'
    defaultMessage={rulesAsString}
    values={{
      br: <br />,
    }}
  />
)

import {
  ADD,
  CART_LOADING,
  CART_TOTAL,
  CART_DELIVERY_PRICE,
  CART_DELIVERY_PRICE_ERROR,
  CART_PRODUCTS_PRICE,
  CHECKOUT_ERROR,
  COUNTRY,
  DECREMENT,
  DELIVERABLE,
  DISMISS_CHECKOUT_ERROR,
  EDIT_CUSTOMIZATIONS,
  EMPTY,
  INCREMENT,
  PICKUP_INFOS,
  PURGE_DISCOUNT,
  REMOVE,
  TOGGLE_PREFILLED,
  UNDELIVERABLE,
  UPDATE_CLIENT_INFOS,
  UPDATE_DELIVERY_MODE,
} from './actions'

import getNewContent from '../../helpers/newCartContent'
import { initialState } from './selectors'

export default (state = initialState, { type, payload }) => {
  const {
    checkoutError,
    country,
    deliveryMode,
    error_details,
    error,
    infos,
    isOverWeight,
    isPrefilledAddress,
    pickupInfos,
    products_pricing,
    target,
    undeliverable,
    clientCustomization,
    cart_id,
    shipping,
  } = payload || {}
  const {
    content,
  } = state
  const result = []
  switch (type) {
  case CART_LOADING:
    return {
      ...state,
      loading: payload.loading,
    }
  case PURGE_DISCOUNT:
    return {
      ...state,
      gift: false,
      discounts: {},
    }
  case CART_TOTAL:
    return {
      ...state,
      price: (state.products_pricing.total + state.delivery_pricing.delivery_discounted).toFixed(2),
    }
  case CART_PRODUCTS_PRICE:
    return {
      ...state,
      products_pricing: {
        total: products_pricing.total,
      },
    }
  case CART_DELIVERY_PRICE:
    return {
      ...state,
      delivery_pricing: {
        delivery_discounted: shipping.price,
        delivery: shipping.price,
        delivery_error: false,
      },
      delays: shipping.delays,
    }
  case CART_DELIVERY_PRICE_ERROR:
    return {
      ...state,
      delivery_pricing: {
        delivery_discounted: ' - ',
        delivery: ' - ',
        delivery_error: error,
      },
      delays: initialState.delays,
    }
  case CHECKOUT_ERROR:
    return {
      ...state,
      checkoutError,
      error_details,
    }
  case DISMISS_CHECKOUT_ERROR:
    return {
      ...state,
      code: null,
      checkoutError: false,
      error_details: null,
    }
  case UPDATE_DELIVERY_MODE:
    return {
      ...state,
      deliveryMode,
    }
  case TOGGLE_PREFILLED:
    return {
      ...state,
      isPrefilledAddress,
    }
  case PICKUP_INFOS:
    return {
      ...state,
      client: {
        ...state.client,
        pickupInfos,
      },
    }
  case EMPTY:
    return {
      ...initialState,
    }
  case COUNTRY:
    return {
      ...state,
      address: {
        ...state.address,
        country,
      },
      client: {
        ...state.client,
        address: {
          ...state.client.address,
          country,
        },
      },
    }
  case UPDATE_CLIENT_INFOS:
    return {
      ...state,
      client: { ...state.client, ...infos },
    }
  case INCREMENT:
    // TODO customizations
    state.content.map((item) => {
      if (item.cart_id !== target) {
        result.push(item)
      } else {
        result.push({ ...item, quantity: item.quantity + 1 })
      }
      return true
    })
    return {
      ...state,
      content: result,
    }
  case DECREMENT:
    // TODO customizations
    state.content.map((item) => {
      if (item.cart_id !== target) {
        result.push(item)
      } else {
        if (item.quantity === 1) {
          return {
            ...state,
            content: state.content.filter(_item => _item.cart_id !== target),
          }
        }
        return result.push({ ...item, quantity: item.quantity - 1 })
      }
      return true
    })
    return {
      ...state,
      content: result,
    }
  case ADD:
    // TODO customizations?
    // NOTE ADD.payload is the item
    return {
      ...state,
      content: getNewContent(payload, content),
    }
  case EDIT_CUSTOMIZATIONS:
    state.content.map((item) => {
      if (item.cart_id !== cart_id) {
        result.push(item)
        return true
      }
      result.push({ ...item, clientCustomization })
      return true
    })
    return {
      ...state,
      content: result,
      toEdit: null,
    }
  case REMOVE:
    return {
      ...state,
      content: state.content.filter((item) => item.cart_id !== target),
    }
  case UNDELIVERABLE:
    // NOTE: set payload.undeliverable to undeliverable
    return {
      ...state,
      content: state.content.map((p) => {
        const _product = p
        if (undeliverable && undeliverable.find((id) => _product._id === id)) {
          _product.undeliverable = true
        }
        if (isOverWeight && isOverWeight.find((id) => _product._id === id)) {
          _product.isOverWeight = true
        }
        return _product
      }),
    }
  case DELIVERABLE:
    // NOTE: will set all products to deliverable
    return {
      ...state,
      content: state.content.map((p) => {
        const _product = p
        delete _product.undeliverable
        return _product
      }),
    }
  default:
    return state
  }
}

import React, { useState, useEffect } from 'react'
import { List } from 'antd'

import { CatalogBP, CatalogBPDrawer } from '../../molecules'
import { decodeQuery } from '../../../helpers/filters'

const CatalogBPList = (props) => {
  const {
    products,
    pushProducts,
    baseProducts,
    DBBaseProducts,
    shopId,
    userId,
    allShops,
    isSelectable,
    selectedProductsToDownload,
    setSelectedProductsToDownload,
    trimmedTimeLimit,
    getMinimumMOQ,
    history,
    saveDraft,
    allProviders,
    draftProducts,
    uploadToShopify,
    postComment,
    allComments,
    deleteComment,
    modifyProvider,
    modifyBP,
    providerModalOpened,
    statusModalOpened,
    closeProviderModal,
    closeStatusModal,
    shopifyLoading,
    filters,
    setURLFilters,
    shopifyActionModal,
    closeActionModal,
    getAllCategories,
    getAllSubCategories,
    getAllOrigins,
    getAllStatus,
    allTechnics,
  } = props

  const [pageSize, setPageSize] = useState(32)
  const [visible, setVisible] = useState(false)
  const [activeBP, setActiveBP] = useState()

  useEffect(() => {
    if (decodeQuery(history.location.search).product) {
      setActiveBP(baseProducts.find((bp) => decodeQuery(history.location.search).product === bp._id))
      setVisible(true)
    }
  })
  const onClose = () => {
    setVisible(false)
    setURLFilters({ ...filters, product: '' })
  }

  try {
    return (
      <>
        <List
          grid={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4,
            gutter: [20, 20],
          }}
          id='listCatalog'
          dataSource={baseProducts}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            style: { textAlign: 'left' },
            pageSize,
            onShowSizeChange: (current, _pageSize) => {
              setPageSize(_pageSize)
            },
            pageSizeOptions: ['32', '64', '128'],
          }}
          renderItem={(baseProduct) => (
            <CatalogBP
              key={String(baseProduct._id)}
              products={products}
              baseProducts={baseProducts}
              baseProduct={baseProduct}
              pushProducts={pushProducts}
              shopId={shopId}
              userId={userId}
              allShops={allShops}
              deleteComment={deleteComment}
              isSelectable={isSelectable}
              selectedProductsToDownload={selectedProductsToDownload}
              setSelectedProductsToDownload={setSelectedProductsToDownload}
              trimmedTimeLimit={trimmedTimeLimit}
              getMinimumMOQ={getMinimumMOQ}
              saveDraft={saveDraft}
              allProviders={allProviders}
              draftProducts={draftProducts}
              postComment={postComment}
              setActiveBP={setActiveBP}
              setVisible={setVisible}
              filters={filters}
              setURLFilters={setURLFilters}
            />
          )}
        />
        {activeBP && (
          <CatalogBPDrawer
            visible={visible}
            onClose={onClose}
            baseProduct={activeBP}
            pushProducts={pushProducts}
            history={history}
            saveDraft={saveDraft}
            baseProducts={baseProducts}
            DBBaseProducts={DBBaseProducts}
            allProviders={allProviders}
            draftProducts={draftProducts}
            comments={allComments}
            userId={userId}
            postComment={postComment}
            deleteComment={deleteComment}
            modifyProvider={modifyProvider}
            modifyBP={modifyBP}
            providerModalOpened={providerModalOpened}
            statusModalOpened={statusModalOpened}
            closeProviderModal={closeProviderModal}
            closeStatusModal={closeStatusModal}
            shopifyLoading={shopifyLoading}
            uploadToShopify={uploadToShopify}
            shopifyActionModal={shopifyActionModal}
            closeActionModal={closeActionModal}
            getAllCategories={getAllCategories}
            getAllSubCategories={getAllSubCategories}
            getAllOrigins={getAllOrigins}
            getAllStatus={getAllStatus}
            allTechnics={allTechnics}
          />
        )}
      </>
    )
  } catch (e) {
    console.log('CatalogBPList - error:', e)
    return null
  }
}

export default CatalogBPList

import React, { useState } from 'react'
import date from 'dayjs'
import {
  Form,
  Input,
  Drawer,
  Button,
  Select,
  Popconfirm,
  Icon,
  Row,
  Col,
  Table,
  Checkbox,
  message,
  Divider,
  Switch,
  Popover,
  Typography,
  DatePicker,
  InputNumber,
  Tabs,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { flatten, uniq } from 'lodash'
import moment from 'moment'
import { countries } from '@balibart/shipping'
import { postalCodeValidator } from '../../../helpers/postalCodeValidator'
import addressFormatter from '../../../helpers/formatters/addressFormatter'
import { getUserEntityStatus } from '../../../helpers/entities'
import { DefineAddressesEntityDrawer } from '../../../containers'

const { Title } = Typography
const { TextArea } = Input
const { Option } = Select
const { TabPane } = Tabs

const initialState = {
  editing: false,
  currentAddress: {
    address: {},
  },
  selection: {},
  visibleDrawerDefineEntity: false,
  currentTab: null,
}

const PresetAddressesForm = (props) => {
  const [state, setState] = useState(initialState)

  const intl = useIntl()

  const onClose = () => {
    setState(initialState)
  }

  const handleDelete = (record) => {
    const {
      modifyShop,
      shopSettings,
      shop,
    } = props

    const addresses = shopSettings.addresses
      .filter((add) => add.identifier !== record.rec.identifier)

    const modifications = {
      settings: {
        ...shop.settings,
        addresses,
      },
    }

    modifyShop({
      shop_id: shop._id,
      modifications,
      callback: () => {
        message.info(
          <FormattedMessage
            id='shops.deleteSuccess.address'
            defaultMessage='Addresse supprimée'
          />,
        )
        return true
      },
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
        resetFields,
      },
      modifyShop,
      shopSettings,
      shop,
      entities,
    } = props

    const {
      currentAddress,
      customFrequency,
    } = state

    validateFieldsAndScroll((err, _values) => {
      const values = _values

      if (!err) {
        const formattedAddress = addressFormatter(values, customFrequency)
        if (entities.length === 1) formattedAddress.entityId = entities[0]._id
        let addresses = shopSettings.addresses || []
        if (currentAddress && currentAddress.identifier) {
          // If we're editing an address
          addresses = [
            ...addresses.filter((add) => add.identifier !== currentAddress.identifier),
            {
              ...formattedAddress,
              identifier: currentAddress.identifier,
              frequency: parseInt(formattedAddress.frequency, 10),
            },
          ]
        } else {
          // If we're adding an address
          addresses = [
            ...addresses,
            {
              ...formattedAddress,
              identifier: formattedAddress.name,
              frequency: parseInt(formattedAddress.frequency, 10),
            },
          ]
        }

        const modifications = {
          settings: {
            ...shop.settings,
            addresses,
          },
        }

        modifyShop({
          shop_id: shop._id,
          modifications,
          callback: () => {
            resetFields()
            onClose()

            message.info(
              <FormattedMessage
                id='shops.addressUpdate'
                defaultMessage='Addresse mise à jour'
              />,
            )
            return true
          },
        })
        setState({ ...state, isVisible: false })
      }
    })
  }

  const submitRestriction = () => {
    try {
      const {
        modifyShop,
        shopSettings,
        shop,
      } = props

      const { restrictedToCA } = state

      const modifications = {
        settings: {
          ...shopSettings,
          restrictToCustomAddresses: restrictedToCA,
        },
      }

      modifyShop({
        shop_id: shop._id,
        modifications,
        callback: (response) => {
          if (response.error) {
            message.error(
              <FormattedMessage
                id='shops.updateFail'
                defaultMessage='Une erreur est survenue'
              />,
            )
            return false
          }
          setState({
            ...state,
            isTouched: false,
          })
          message.info(
            <FormattedMessage
              id='updateSuccess.shop'
              defaultMessage='Boutique mise à jour'
            />,
          )
          return true
        },
      })
    } catch (error) {
      console.log('PresetAddressesForm - submitRestriction', { error })
    }
  }

  const validatePostalCode = (rule, value, cb) => {
    try {
      const {
        form: {
          getFieldValue,
        },
      } = props

      const country = getFieldValue('country')

      if (
        !country
        || (
          country
          && postalCodeValidator(value, country)
        )
      ) {
        cb()
      } else {
        cb('code postal incorrect')
      }
    } catch (e) {
      console.log('error validatePostalCode', e)
      cb('code postal incorrect')
    }
    return false // Juste pour calmer le linter
  }

  const editAddress = (record) => {
    const {
      form: {
        resetFields,
      },
    } = props

    setState({
      ...state,
      editing: true,
      currentAddress: record.rec,
    }, () => {
      resetFields()
    })
  }

  const assignEntity = () => {
    setState({
      ...state,
      visibleDrawerDefineEntity: true,
    })
  }

  const restrictToCA = (restrictedToCA) => {
    setState({
      ...state,
      restrictedToCA,
      isTouched: true,
    })
  }

  // const displayButton = (id) => {
  //   const {
  //     displayButton,
  //   } = state
  //   displayButton[id] = true
  //   setState({ ...state, displayButton })
  // }

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const { currentTab = 'global', selection } = state
    const newSelection = { ...selection }
    newSelection[currentTab] = selectedRows.map((r) => r.rec.identifier)
    setState({ ...state, selection: newSelection })
  }

  const displayFrequency = (frequency) => {
    switch (frequency) {
    case 1: return <FormattedMessage id='everyDay' defaultMessage='Tous les jours' />
    case 7: return <FormattedMessage id='everyWeek' defaultMessage='Toutes les semaines' />
    case 30: return <FormattedMessage id='everyMonth' defaultMessage='Tous les mois' />
    case 365: return <FormattedMessage id='everyYear' defaultMessage='Tous les ans' />
    default: return ''
    }
  }

  try {
    const {
      editing,
      currentAddress,
      isTouched,
      regrouped,
      frequency,
      selection,
      visibleDrawerDefineEntity,
    } = state

    const {
      form: {
        getFieldDecorator,
      },
      shop,
      user,
      shopSettings,
      entities,
      locale,
    } = props

    const { addresses = [] } = shopSettings

    const data = addresses.map((address, index) => ({
      key: index,
      name: address.name,
      address: address.address.street,
      rec: address,
      entityId: address.entityId,
    }))

    const status = getUserEntityStatus(user, shop)

    const columns = [
      {
        title: <FormattedMessage id='lastName' defaultMessage='Nom' />,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: <FormattedMessage id='address' defaultMessage='Adresse' />,
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: <FormattedMessage id='action' defaultMessage='Action' />,
        key: 'action',
        render: (text, record) => (
          <span>
            <Button onClick={() => editAddress(record)}>
              <FormattedMessage id='modify' defaultMessage='Modifier' />
            </Button>
            <Divider type='vertical' />
            <Popconfirm
              title={(
                <FormattedMessage
                  id='form.confirmDelete'
                  defaultMessage='Êtes vous sûr ?'
                />
              )}
              onConfirm={() => handleDelete(record)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='danger'
              >
                <FormattedMessage id='delete' defaultMessage='Supprimer' />
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ]

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => onSelectChange(selectedRowKeys, selectedRows),
    }

    // const prefixSelector = getFieldDecorator('prefix', {
    //   initialValue: '33',
    // })(
    //   <Select style={{ width: 70 }}>
    //     <Option value='86'>+86</Option>
    //     <Option value='87'>+87</Option>
    //   </Select>,
    // )
    // TODO better input phone

    const content = (
      <div
        style={{
          width: 300,
          textAlign: 'justify',
        }}
      >
        <p>
          <FormattedMessage
            id='settings.presetAddressesHelper'
            defaultMessage='Activez cette fonctionnalité si
                vous souhaitez que vos utilisateurs soient obligés
                de se faire livrer à l’une des adresses que vous avez
                ajouté.'
          />
        </p>
        <p>
          <FormattedMessage
            id='settings.presetAddressesHelper2'
            defaultMessage='Ils ne pourront plus choisir de se faire livrer chez eux.'
          />
        </p>
      </div>
    )

    const dateFormatList = locale === 'en' ? ['MM/DD/YYYY', 'MM/DD/YY'] : ['DD/MM/YYYY', 'DD/MM/YY']

    const selectionAuthorized = getUserEntityStatus(user, shop) !== ('noEntity' || 'collaborator') && entities.length

    let editedAddress = {
      address: {},
    }

    if (currentAddress && currentAddress.identifier) {
      editedAddress = shopSettings.addresses
        .find((add) => add.identifier === currentAddress.identifier)
    }

    return (
      <>
        <Row gutter={16} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Col span={12}>
            <Button
              onClick={() => editAddress({})}
              style={{ margin: '0 8px 8px 0' }}
              type='primary'
            >
              <FormattedMessage
                id='addressForm.addAddress'
                defaultMessage='Ajouter une adresse'
              />
            </Button>
            {selectionAuthorized ? (
              <Button
                onClick={() => assignEntity()}
                style={{ margin: '0 8px 8px 0' }}
                type='primary'
                disabled={!uniq(flatten(Object.values(selection))).length}
              >
                <FormattedMessage
                  id='addressForm.addEntity'
                  defaultMessage='Assigner à une entité'
                />
              </Button>
            ) : null}
          </Col>
          <Col span={12}>
            <Switch
              onChange={restrictToCA}
              disabled={addresses.length < 1}
              defaultChecked={shopSettings.restrictToCustomAddresses}
              style={{ marginRight: 10 }}
            />
            <FormattedMessage
              id='shopSettings.restrictToCA'
              defaultMessage='Restreindre aux adresses personnalisées'
            />
            <Popover
              content={content}
              title={(
                <FormattedMessage
                  id='shopSettings.restrictToCATitle'
                  defaultMessage='Restriction aux adresses personnalisées'
                />
              )}
            >
              <Icon type='question-circle' style={{ fontSize: 12, marginLeft: 10 }} />
            </Popover>
            {isTouched ? (
              <Form.Item>
                <Button
                  type='primary'
                  onClick={submitRestriction}
                  style={{
                    marginRight: '12px',
                  }}
                >
                  <FormattedMessage id='save' defaultMessage='Enregistrer' />
                </Button>
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        {entities.length && (status === 'owner' || status === 'admin') ? (
          <Tabs defaultActiveKey='global' type='card' onChange={(key) => setState({ ...state, currentTab: key })}>
            <TabPane tab='Global' key='global'>
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
              />
            </TabPane>
            {entities.sort((a, b) => (date(b.createdAt).isBefore(a.createdAt) && 1) || -1).map((entity) => (
              <TabPane tab={entity.name} key={entity._id}>
                <Table
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={data.filter((d) => d.entityId === entity._id)}
                />
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <Table
            columns={columns}
            dataSource={getUserEntityStatus(user, shop) === 'noEntity'
              ? data.filter((d) => !d.entityId)
              : data.filter((d) => d.entityId === entities[0]?._id)}
            rowSelection={selectionAuthorized ? rowSelection : null}
          />
        )}
        <DefineAddressesEntityDrawer
          destroyOnClose
          visibleDrawer={visibleDrawerDefineEntity}
          identifiers={uniq(flatten(Object.values(selection)))}
          onClose={onClose}
        />
        <Drawer
          destroyOnClose
          title={(
            <FormattedMessage
              id='presetAddressesForm.title'
              defaultMessage='Editer mon adresse'
            />
          )}
          placement='right'
          closable={false}
          width='60%'
          onClose={onClose}
          bodyStyle={{ paddingBottom: 80 }}
          visible={editing}
        >
          <Form
            layout='vertical'
            hideRequiredMark
            onSubmit={handleSubmit}
          >
            {entities.length && (status === 'owner' || status === 'admin') ? (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={<FormattedMessage id='entity' defaultMessage='Entité' />}>
                    {getFieldDecorator('entityId', {
                      rules: [{
                        required: true,
                        message: <FormattedMessage id='required.entity' defaultMessage='Veuillez choisir une entité' />,
                      }],
                      initialValue: entities.find((entity) => entity._id === editedAddress.entityId)?.name,
                    })(
                      <Select
                        placeholder={<FormattedMessage id='addresses.placeholder' defaultMessage='Choisissez une entité...' />}
                        showSearch
                        filterOption={(input, option) => option.props.children
                          .toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {
                          entities.map((entity) => (
                            <Option value={entity._id} key={entity._id}>{entity.name}</Option>
                          ))
                        }
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={<FormattedMessage id='name' defaultMessage='Nom' />}>
                  {getFieldDecorator('name', {
                    rules: [{
                      required: true,
                      message: <FormattedMessage
                        id='presetAddressesFrom.required.deliveryAddress.name'
                        defaultMessage='Veuillez nommer votre addresse de livraison'
                      />,
                    }],
                    initialValue: editedAddress.name,
                  })(<Input
                    prefix={<Icon type='tag-o' style={{ fontSize: 13 }} />}
                    placeholder={intl.formatMessage({
                      id: 'presetAddressesFrom.placeholder.nameYourAddress',
                      defaultMessage: 'Nommez votre adresse',
                    })}
                  />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={<FormattedMessage id='address' defaultMessage='Adresse' />}>
                  {
                    getFieldDecorator('street', {
                      rules: [{
                        required: true,
                        message: <FormattedMessage
                          id='presetAddressesFrom.required.deliveryAddress.input'
                          defaultMessage='Veuillez entrer votre addresse de livraison'
                        />,
                      }],
                      initialValue: editedAddress.address.street,
                    })(<Input
                      prefix={<Icon type='home' style={{ fontSize: 13 }} />}
                      placeholder={intl.formatMessage({
                        id: 'presetAddressesFrom.placeholder.address',
                        defaultMessage: 'Adresse',
                      })}
                    />)
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={<FormattedMessage id='additionalAddress' defaultMessage="Complément d'adresse" />}>
                  {getFieldDecorator('complement', {
                    rules: [],
                    initialValue: editedAddress.address.complement,
                  })(<Input
                    prefix={<Icon type='environment' style={{ fontSize: 13 }} />}
                    placeholder={intl.formatMessage({
                      id: 'presetAddressesFrom.placeholder.additionnalAddress',
                      defaultMessage: 'Complément d\'adresse',
                    })}
                  />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={<FormattedMessage id='compagny' defaultMessage='Société' />}>
                  {getFieldDecorator('company', {
                    initialValue: editedAddress.address.company,
                  })(<Input
                    prefix={<Icon type='database' style={{ fontSize: 13 }} />}
                    placeholder={intl.formatMessage({
                      id: 'presetAddressesFrom.placeholder.company',
                      defaultMessage: 'Société',
                    })}
                  />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={<FormattedMessage id='country' defaultMessage='Pays' />}>
                  {
                    getFieldDecorator('country', {
                      rules: [
                        {
                          required: true,
                          message: <FormattedMessage
                            id='presetAddressesFrom.required.deliveryAddress.country'
                            defaultMessage='Veuillez entrer le pays de livraison'
                          />,
                        },
                      ],
                      initialValue: editedAddress.address.country,
                    })(
                      <Select
                        placeholder={<FormattedMessage id='presetAddressesFrom.placeholder.country' defaultMessage='Choisissez un pays...' />}
                        showSearch
                        filterOption={(input, option) => option.props.children
                          .toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {
                          countries.map((country) => (
                            <Option value={country.value}>{country.fr}</Option>
                          ))
                        }
                      </Select>,
                    )
                  }
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={<FormattedMessage id='city' defaultMessage='Ville' />}>
                  {getFieldDecorator('city', {
                    rules: [{
                      required: true,
                      message: <FormattedMessage
                        id='presetAddressesFrom.required.deliveryAddress.city'
                        defaultMessage='Veuillez entrer la ville de livraison'
                      />,
                    }],
                    initialValue: editedAddress.address.city,
                  })(<Input
                    prefix={<Icon type='home' style={{ fontSize: 13 }} />}
                    placeholder={intl.formatMessage({
                      id: 'presetAddressesFrom.placeholder.city',
                      defaultMessage: 'Ville',
                    })}
                  />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={<FormattedMessage id='postalCode' defaultMessage='Code postal' />}>
                  {getFieldDecorator('postalCode', {
                    rules: [
                      {
                        required: true,
                        message: <FormattedMessage
                          id='presetAddressesFrom.required.deliveryAddress.postalCode'
                          defaultMessage='Veuillez entrer le code postal de livraison'
                        />,
                      },
                      {
                        validator: (rule, value, cb) => validatePostalCode(rule, value, cb),
                        message: <FormattedMessage
                          id='presetAddressesFrom.invalidPostalCode'
                          defaultMessage='Code postal invalide'
                        />,
                      },
                    ],
                    initialValue: editedAddress.address.postalCode,
                  })(<Input
                    prefix={<Icon type='home' style={{ fontSize: 13 }} />}
                    placeholder={intl.formatMessage({
                      id: 'presetAddressesFrom.placeholder.postalCode',
                      defaultMessage: 'Code postal',
                    })}
                  />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={(
                  <FormattedMessage
                    id='district'
                    defaultMessage='Province'
                  />
                )}
                >
                  {getFieldDecorator('province', {
                    rules: [],
                    initialValue: editedAddress.address.province,
                  })(<Input
                    prefix={<Icon type='environment' style={{ fontSize: 13 }} />}
                    placeholder={intl.formatMessage({
                      id: 'presetAddressesFrom.placeholder.district',
                      defaultMessage: 'État/Province',
                    })}
                  />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label={<FormattedMessage id='description' defaultMessage='Description' />}>
                  {getFieldDecorator('description', {
                    rules: [],
                    initialValue: editedAddress.description,
                  })(<TextArea
                    rows={4}
                    placeholder={intl.formatMessage({
                      id: 'presetAddressesFrom.placeholder.deliveryInfos',
                      defaultMessage: 'Entrez les informations utiles pour la récupération du colis',
                    })}
                  />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label={(
                  <FormattedMessage
                    id='presetAddressesFrom.batchDeliveries'
                    defaultMessage='Cette adresse prend elle en charge les livraisons groupées ?'
                  />
                )}
                >
                  {getFieldDecorator('regrouped', {
                    rules: [],
                    initialValue: editedAddress.regrouped || false,
                  })(<Checkbox
                    checked={regrouped || editedAddress.regrouped}
                    onChange={(e) => setState({ ...state, regrouped: e.target.checked })}
                  />)}
                </Form.Item>
              </Col>
            </Row>
            {
              regrouped || editedAddress.regrouped ? (
                <>
                  <Title level={3}>
                    <FormattedMessage id='presetAddressesFrom.title.contactDetails' defaultMessage='Coordonnées de la personne à contacter' />
                  </Title>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label={<FormattedMessage id='lastName' defaultMessage='Nom' />}>
                        {getFieldDecorator('lastName', {
                          rules: [
                            { required: true, message: <FormattedMessage id='required' defaultMessage='Requis' /> },
                          ],
                          initialValue: (editedAddress && editedAddress.contact && editedAddress.contact.lastName) || '',
                        })(<Input
                          prefix={<Icon type='user' style={{ fontSize: 13 }} />}
                          placeholder={intl.formatMessage({
                            id: 'presetAddressesFrom.placeholder.lastname',
                            defaultMessage: 'Nom',
                          })}
                        />)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={<FormattedMessage id='firstName' defaultMessage='Prénom' />}>
                        {getFieldDecorator('firstName', {
                          rules: [{ required: true, message: <FormattedMessage id='required' defaultMessage='Requis' /> }],
                          initialValue: (editedAddress && editedAddress.contact && editedAddress.contact.firstName) || '',
                        })(<Input
                          prefix={<Icon type='user' style={{ fontSize: 13 }} />}
                          placeholder={intl.formatMessage({
                            id: 'presetAddressesFrom.placeholder.firstname',
                            defaultMessage: 'Prénom',
                          })}
                        />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label={<FormattedMessage id='email' defaultMessage='Email' />}>
                        {getFieldDecorator('email', {
                          rules: [
                            { required: true, message: <FormattedMessage id='required' defaultMessage='Requis' /> },
                          ],
                          initialValue: (editedAddress && editedAddress.contact && editedAddress.contact.email) || '',
                        })(<Input
                          prefix={<Icon type='mail' style={{ fontSize: 13 }} />}
                          placeholder={intl.formatMessage({
                            id: 'presetAddressesFrom.placeholder.email',
                            defaultMessage: 'Email',
                          })}
                        />)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={<FormattedMessage id='phone' defaultMessage='Téléphone' />}>
                        {getFieldDecorator('phone', {
                          rules: [{ required: true, message: <FormattedMessage id='required' defaultMessage='Requis' /> }],
                          initialValue: (editedAddress && editedAddress.contact && editedAddress.contact.phone) || '',
                        })(<Input
                          prefix={<Icon type='phone' style={{ fontSize: 13 }} />}
                          placeholder={intl.formatMessage({
                            id: 'presetAddressesFrom.placeholder.phoneNumber',
                            defaultMessage: 'N° de Téléphone',
                          })}
                        />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label={<FormattedMessage id='presetAddressesFrom.dateOfFirstDispatch' defaultMessage='Date du premier envoi' />}>
                        {getFieldDecorator('reminder', {
                          rules: [
                            { required: true, message: <FormattedMessage id='required' defaultMessage='Requis' /> },
                          ],
                          initialValue: (editedAddress && moment(editedAddress.reminder)) || '',
                        })(
                          <DatePicker
                            showTime={{ format: 'HH:mm' }}
                            format={dateFormatList}
                            placeholder={<FormattedMessage id='presetAddressesFrom.placeholder.date' defaultMessage='Sélectionnez une date...' />}
                          />,
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={<FormattedMessage id='frequency' defaultMessage='Fréquence' />}>
                        {getFieldDecorator('frequency', {
                          rules: [{ required: true }],
                          initialValue: displayFrequency(editedAddress?.frequency),
                        })(
                          <Select
                            style={{ width: 200 }}
                            placeholder={<FormattedMessage id='select' defaultMessage='Sélectionner...' />}
                            onChange={(value) => {
                              setState({ ...state, frequency: value, customFrequency: null })
                            }}
                          >
                            <Option key={1} value={1}>
                              <FormattedMessage id='everyDay' defaultMessage='Tous les jours' />
                            </Option>
                            <Option key={7} value={7}>
                              <FormattedMessage id='everyWeek' defaultMessage='Toutes les semaines' />
                            </Option>
                            <Option key={30} value={30}>
                              <FormattedMessage id='everyMonth' defaultMessage='Tous les mois' />
                            </Option>
                            <Option key={365} value={365}>
                              <FormattedMessage id='everyYear' defaultMessage='Tous les ans' />
                            </Option>
                            <Option
                              key='custom'
                              value='custom'
                              onClick={() => setState({ ...state, isVisible: true })}
                            >
                              <FormattedMessage id='presetAddressesFrom.customFrequency' defaultMessage='Personnalisée...' />
                            </Option>
                          </Select>,
                        )}
                        {frequency === 'custom' && (
                          <InputNumber
                            min={2}
                            onChange={(value) => setState({ ...state, customFrequency: value })}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : null
            }
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                <FormattedMessage id='cancel' defaultMessage='Annuler' />
              </Button>
              <Button
                htmlType='submit'
                type='primary'
              >
                <FormattedMessage id='save' defaultMessage='Enregistrer' />
              </Button>
            </div>
          </Form>
        </Drawer>
      </>
    )
  } catch (e) {
    console.log(e, 'error PresetAddressesForm')
    return null
  }
}

export default Form.create({ name: 'shopPresetAddresses' })(injectIntl(PresetAddressesForm))

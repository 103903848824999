// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import {
  put, call, takeEvery,
} from 'redux-saga/effects'
import * as actions from './actions'

/**
 * Récupère les settings côté coreAPI.
 *
 * @param {*} api
 * @returns true if ok false otherwise
 */
export function* getSettings(api) {
  try {
    const result = yield call([api, api.get], '/settings/builds', { ...api.headers, force: true })

    localStorage.setItem('last_need_reload_seller', result.last_need_reload_seller || '')

    return true
  } catch (error) {
    console.log('settings.sagas.getSettings error : ', error)
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(actions.GET_SETTINGS_BUILDS, getSettings, api)

  // NOTE on app load, get settings
  yield put({ type: actions.GET_SETTINGS_BUILDS })
}

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PopoverColorPicker } from '..'

const PopoverFontColorPicker = (props) => {
  const {
    setFontColor, // Required
    fontColor, // Required
    setNeedUpdateCampaign, // Required
    isDisabled = false,
  } = props

  const [fontColorPopoverState, setFontColorPopoverState] = useState(false)

  const handleFontColorVisibleChange = (visible) => setFontColorPopoverState(visible)
  const onChangeCompleteFontColor = (selectedColor) => {
    setFontColor(selectedColor)
    setNeedUpdateCampaign(true)
  }

  return (
    <PopoverColorPicker
      popoverTitle={(
        <FormattedMessage
          id='theme.fontColor'
          defaultMessage='Couleur de police'
        />
      )}
      isPopoverVisible={fontColorPopoverState}
      popoverOnVisibleChange={handleFontColorVisibleChange}
      buttonStyle={{
        backgroundColor: fontColor, width: '49%', height: '40px',
      }}
      colorPickerColor={fontColor}
      onChangeColorPickerAction={(color) => onChangeCompleteFontColor(color)}
      isDisabled={isDisabled}
    />
  )
}

export default PopoverFontColorPicker

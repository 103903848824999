import React from 'react'

const ImageSection = (props) => {
  const { src } = props
  return (
    <div style={{ marginRight: '20px' }}>
      <img src={src} alt='the product' style={{ width: '50px', borderRadius: '10px' }} />
    </div>

  )
}

export default ImageSection

import React from 'react'
import { FormattedMessage } from 'react-intl'

export const dateEstimationv2 = (createdAt) => {
  try {
    const newDate = new Date(createdAt)
    const date1 = new Date()
    const date2 = new Date()

    date1.setDate(newDate.displayDate() + 5)
    const dd = date1.displayDate()
    const mm = date1.getMonth() + 1
    const y = date1.getFullYear()
    const formattedDate = `${dd}/${mm}/${y}`

    date2.setDate(newDate.displayDate() + 9)
    const dd2 = date2.displayDate()
    const mm2 = date2.getMonth() + 1
    const y2 = date2.getFullYear()
    const formattedDate2 = `${dd2}/${mm2}/${y2}`

    return { formattedDate, formattedDate2 }
  } catch (e) {
    console.log('dateEstimation Error', e)
    return null
  }
}

export const validOrderId = (numberOrder) => {
  try {
    let result
    if (numberOrder) {
      if (numberOrder.length === 10 || numberOrder.length === 24) {
        result = 'success'
      } else {
        result = 'error'
      }
    }
    return result
  } catch (e) {
    console.log(e)
    return null
  }
}

export const isEmail = (email) => {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const renderDate = (date) => {
  try {
    const msec = Date.parse(date)
    const newDate = new Date(msec)

    const formatedDate = `${newDate.toLocaleDateString()} à ${newDate.toLocaleTimeString()}`
    return formatedDate
  } catch (e) {
    console.log(e)
    return null
  }
}

export const noMarginTransactionAmount = (order) => {
  try {
    return order.transaction.amount
    - (order.products.reduce((
      accumulator,
      product,
    ) => accumulator + (product.margin * product.quantity), 0) * 100)
  } catch (e) {
    console.log('error helpers/orders/noMarginTransactionAmount', e)
    return order.transaction.amount
  }
}

export const getStatus = (status) => {
  switch (status) {
  case 'Waiting':
    return <FormattedMessage id='waiting' defaultMessage='En Attente' />
  case 'Received':
    return <FormattedMessage id='received.female' defaultMessage='Reçue' />
  case 'Point Relais':
  case 'Shipped':
    return <FormattedMessage id='shipped.female' defaultMessage='Envoyée' />
  case 'Delivered':
    return <FormattedMessage id='delivered.female' defaultMessage='Livrée' />
  case 'Cancelled':
    return <FormattedMessage id='canceled.female' defaultMessage='Annulée' />
  case 'In Production':
  case 'Flagged':
  default:
    return <FormattedMessage id='inProduction' defaultMessage='En Production' />
  }
}

export const getStatusColor = (status) => {
  switch (status) {
  case 'Waiting':
    return 'purple'
  case 'Received':
    return 'orange'
  case 'Shipped':
    return 'blue'
  case 'Delivered':
    return 'green'
  case 'Cancelled':
    return 'grey'
  case 'In Production':
  case 'Flagged':
  default:
    return 'volcano'
  }
}

export const getType = (type) => {
  switch (type) {
  case 'MagicLink':
    return 'MagicLink'
  case 'Admin':
    return 'Admin'
  case 'Self Send':
    return 'Self Send'
  case 'Cadeau':
    return <FormattedMessage id='gift' defaultMessage='Cadeau' />
  case 'Interne':
    return <FormattedMessage id='internal' defaultMessage='Interne' />
  case 'Groupée':
    return <FormattedMessage id='groupedOrder' defaultMessage='Groupée' />
  default:
    return <FormattedMessage id='shop' defaultMessage='Boutique' />
  }
}

import React from 'react'
import {
  Select,
  Input,
  Icon,
  Switch,
} from 'antd'
import { FormattedMessage } from 'react-intl'

const { Option } = Select

const UserEntityForm = (props) => {
  const {
    entities = false,
    email,
    setEmail,
    errorEmail,
    setErrorEmail,
    setLanguage,
    name,
    setName,
    lastName,
    setLastName,
    setAdminCollaborator,
    pressetEntityId,
    setPressetEntityId,
    alreadySetupEntityId,
  } = props

  const verifyEmail = (value) => {
    // eslint-disable-next-line max-len
    const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    if (regex.test(value.toLowerCase()) || !value) {
      setErrorEmail(false)
    } else {
      setErrorEmail(true)
    }
    setEmail(value)
  }
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{
          flex: '2 3', display: 'flex', margin: '0 10px 0 0', flexDirection: 'column',
        }}
        >
          <div style={{   fontWeight: 'bold', margin: '0 10px 0 0' }}>
            <FormattedMessage id='userEmail' defaultMessage="Email de l'utilisateur" />
          </div>
          <span style={{ position: 'relative' }}>
            <Input
              defaultValue={email}
              onChange={(e) => verifyEmail(e.target.value)}
            />
            {errorEmail && (
              <span style={{
                color: '#FEDD00', position: 'absolute', left: 0, top: 34,
              }}
              >
                <Icon type='warning' style={{ margin: '0 5px 0 0' }} />
                <FormattedMessage id='userEntityForm.checkEmail' defaultMessage="Veuillez vérifier l'email renseigné" />
              </span>
            )}
          </span>
        </div>
        <div style={{
          flex: '1 3', display: 'flex', flexDirection: 'column',
        }}
        >
          <div style={{   fontWeight: 'bold', margin: '0 10px 0 0' }}>
            <FormattedMessage id='language' defaultMessage='Langue' />
          </div>
          <Select
            style={{ width: '100%' }}
            onChange={(value) => {
              setLanguage(value)
            }}
          >
            <Option value='fr'>FR 🇫🇷</Option>
            <Option value='en'>EN 🇬🇧</Option>
          </Select>
        </div>
      </div>
      <div style={{ display: 'flex', margin: '20px 0 0 0' }}>
        <div style={{
          flex: '1 2', display: 'flex', margin: '0 10px 0 0', flexDirection: 'column',
        }}
        >
          <div style={{   fontWeight: 'bold', margin: '0 10px 0 0' }}>
            <FormattedMessage id='firstName' defaultMessage='Prénom' />
          </div>
          <Input
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div style={{ flex: '1 2', display: 'flex', flexDirection: 'column' }}>
          <div style={{   fontWeight: 'bold', margin: '0 10px 0 0' }}>
            <FormattedMessage id='lastName' defaultMessage='Nom' />
          </div>
          <Input
            defaultValue={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      {entities && entities.length && !alreadySetupEntityId ? (
        <>
          <div style={{   fontWeight: 'bold', margin: '20px 0 0 0' }}>
            <FormattedMessage id='userEntityForm.assignToAnEntity' defaultMessage='Assigner cet utilisateur à une entité' />
          </div>
          <Select
            style={{ width: '100%' }}
            onChange={(value) => {
              setPressetEntityId(value)
            }}
          >
            {entities.map((entity) => (<Option value={entity._id}>{entity.name}</Option>))}
          </Select>
        </>
      ) : null}
      {entities && entities.length && pressetEntityId ? (
        <>
          <div style={{   fontWeight: 'bold', margin: '20px 0 0 0' }}>
            <FormattedMessage
              id='userEntityForm.confirmAssignment'
              defaultMessage='Est-ce que vous voulez laisser cet utilisateur administrer l’entité'
            />
            {' '}
            {(entities.find((entity) => entity._id === pressetEntityId) && entities.find((entity) => entity._id === pressetEntityId).name) || ''}
            {' '}
            ?
          </div>
          <Switch
            checkedChildren={<Icon type='check' />}
            unCheckedChildren={<Icon type='close' />}
            onChange={(checked) => {
              setAdminCollaborator(checked)
            }}
          />
        </>
      ) : null}
    </div>
  )
}

export default UserEntityForm

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreenButton, faLaptop } from '@fortawesome/free-solid-svg-icons'
import theme from '../../../config/theme'

const MobileDesktopChoice = (props) => {
  const {
    isMobileFocused,
    setIsMobileFocused,
  } = props

  return (
    <>
      <div
        style={{
          backgroundColor: isMobileFocused ? theme.primaryColor[1] : theme.white,
          border: `1px solid ${isMobileFocused ? theme.primaryColor[1] : theme.neutralColor[3]}`,
          borderRadius: '8px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          width: '32px',
          height: '32px',
          padding: '7px 7px 7px 7px',
          cursor: 'pointer',
          marginRight: '4px',
        }}
        onClick={() => {
          setIsMobileFocused(true)
        }}
      >
        <FontAwesomeIcon
          icon={faMobileScreenButton}
          style={{
            width: '16px',
            height: '16px',
            color: isMobileFocused ? theme.primaryColor[6] : theme.neutralColor[13],
          }}
        />
      </div>
      <div
        style={{
          backgroundColor: !isMobileFocused ? theme.primaryColor[1] : theme.white,
          border: `1px solid ${!isMobileFocused ? theme.primaryColor[1] : theme.neutralColor[3]}`,
          borderRadius: '8px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          width: '32px',
          height: '32px',
          padding: '7px 7px 7px 7px',
          cursor: 'pointer',
        }}
        onClick={() => {
          setIsMobileFocused(false)
        }}
      >
        <FontAwesomeIcon
          icon={faLaptop}
          style={{
            width: '16px',
            height: '16px',
            color: !isMobileFocused ? theme.primaryColor[6] : theme.neutralColor[13],
          }}
        />
      </div>
    </>
  )
}

export default MobileDesktopChoice

import { useState, useEffect } from 'react'

/**
 * @param {*} props - props.fetchData -> fonction pour fetch les données
 * refresh automatiquement les données de page sur un render
 * @returns null
 */
const Refresher = (props) => {
  try {
    const TTL = 1800000
    const [lastFetched, setLastFetched] = useState(new Date())
    const { fetchData } = props
    const newDate = new Date()
    useEffect(() => {
      const compare = newDate.getTime() - lastFetched.getTime()
      if (compare > TTL) {
        fetchData()
        setLastFetched(newDate)
      }
    })
    return null
  } catch (e) {
    console.log('Refresher error', e)
    return null
  }
}

export default Refresher

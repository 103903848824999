import React from 'react'
import { Popover } from 'antd'

const CustomizablePopover = (props) => {
  const {
    children, // Required
    title, // Required
    visible = false,
    content, // Required
    onVisibleChange, // Required
    trigger = 'click',
    placement = 'top',
  } = props

  return (
    <Popover
      content={content}
      title={title}
      trigger={trigger}
      placement={placement}
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      { children }
    </Popover>
  )
}

export default CustomizablePopover

import React, { useState } from 'react'
import {
  Input, Select, Button,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'

import { ButtonCollapseFilter } from '../../atoms'
import '../../../App.css'

import {
  character,
  neutralColor, white,
} from '../../../config/theme'

const { Option } = Select

const CampaignFilter = (props) => {
  const intl = useIntl()

  const {
    filters, magicLinks, setFiltersState, user, collaborators, shopOwner,
  } = props

  const { search, status } = filters

  const [openFilter, setOpenFilter] = useState(false)

  return (
    <>
      <div style={{
        display: 'flex', gap: '16px',
      }}
      >
        <Input
          className='searchCampaign'
          value={search}
          allowClear
          placeholder={intl.formatMessage({
            id: 'campaignTracking.search',
            defaultMessage: 'Rechercher une campagne',
          })}
          prefix={<FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: character.disabledPlaceholder }} />}
          onChange={(e) => setFiltersState({ ...filters, search: e.target.value })}
        />
        <Select
          className='statusCampaign'
          value={status}
          showArrow
          allowClear
          style={{ minWidth: '210px' }}
          placeholder={<FormattedMessage id='magicLinks.dashboard.allCampaigns' defaultMessage='Toutes les campagnes' />}
          onChange={(value) => setFiltersState({ ...filters, status: value })}
        >
          <Option value='active'>
            <FormattedMessage id='campaignTracking.campaigns.active' defaultMessage='Campagnes actives' />
          </Option>
          <Option value='draft'>
            <FormattedMessage id='campaignTracking.campaigns.draft' defaultMessage='Campagnes brouillon' />
          </Option>
          <Option value='archived'>
            <FormattedMessage id='campaignTracking.campaigns.archived' defaultMessage='Campagnes archivées' />
          </Option>
        </Select>
        <Button
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '5px 8px',
            background: !openFilter ? white : neutralColor[2],
          }}
          onClick={() => setOpenFilter(!openFilter)}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: character.secondary,
            fontWeight: '500',
            fontSize: '14px',
            gap: '20px',
          }}
          >
            <FormattedMessage id='campaignTrackingFilters.openFilters' defaultMessage='Filtres' />
            {!openFilter
              ? <FontAwesomeIcon icon={faFilter} />
              : <FontAwesomeIcon icon={faXmark} />}
          </div>
        </Button>
      </div>
      <ButtonCollapseFilter
        magicLinks={magicLinks}
        collaborators={collaborators}
        openFilter={openFilter}
        setFiltersState={setFiltersState}
        filters={filters}
        user={user}
        shopOwner={shopOwner}
      />
    </>
  )
}

export default injectIntl(CampaignFilter)

/* eslint-disable no-shadow */
/* eslint-disable max-len */
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Icon,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  message,
} from 'antd'
import React, { useState } from 'react'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'

const { Option } = Select

const initialState = {
  editing: false,
  currentMedia: {},
}
const SocialsForm = (props) => {
  const [state, setState] = useState(initialState)

  const intl = useIntl()

  const {
    form: {
      getFieldDecorator,
    },
    shopSettings,
  } = props

  const {
    editing,
    currentMedia,
  } = state

  const handleDelete = (record) => {
    const {
      modifyShop,
      shopSettings,
      shop,
    } = props

    const socials = shopSettings.socials
      .filter((media) => media.type.split('-')[0] !== record.type.split('-')[0]
        && media.url !== record.url && media.id !== record.id)

    const modifications = {
      settings: {
        ...shop.settings,
        socials,
      },
    }

    modifyShop({
      shop_id: shop._id,
      modifications,
      callback: () => {
        message.info(
          <FormattedMessage
            id='shops.deleteSuccess.media'
            defaultMessage='Média supprimé'
          />,
        )
        return true
      },
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
        resetFields,
      },
      modifyShop,
      shopSettings,
      shop,
    } = props

    const {
      currentMedia,
    } = state

    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          type,
          url,
        } = values

        let socials = shopSettings.socials || []
        // If we're adding a media
        if (currentMedia && currentMedia.type) {
          socials = [
            ...socials
              .filter((media) => media.type.split('-')[0] !== currentMedia.type.split('-')[0]),
            {
              type: currentMedia.type.split('-')[0],
              url,
            },
          ]
        } else {
          socials = [
            ...socials,
            {
              type: type.split('-')[0],
              url,
              id: Math.random().toString(36).substring(7),
            },
          ]
        }

        const modifications = {
          settings: {
            ...shop.settings,
            socials,
          },
        }

        modifyShop({
          shop_id: shop._id,
          modifications,
          callback: () => {
            resetFields()
            onClose()

            message.info(
              <FormattedMessage
                id='updateSuccess.address'
                defaultMessage='Addresse mise à jour'
              />,
            )
            return true
          },
        })
      }
    })
  }

  const editMedia = (record) => {
    const {
      form: {
        resetFields,
      },
    } = props

    setState({
      ...state,
      editing: true,
      currentMedia: record,
    }, () => {
      resetFields()
    })
  }

  const onClose = () => {
    setState(initialState)
  }

  let editedMedia = {}

  if (currentMedia && currentMedia.type) {
    editedMedia = shopSettings.socials.find((add) => add.type === currentMedia.type)
  }

  const columns = [
    {
      title: <FormattedMessage id='icon' defaultMessage='Icône' />,
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        // HOTFIX : on voulait (absolument) avoir le logo Dailymotion, j'ai fait un quick-win
        <span>
          {
            record.type === 'dailymotion' ? <DailymotionIcon /> : <Icon type={record.type.split('-')[0]} />
          }
        </span>
      ),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: <FormattedMessage id='action' defaultMessage='Action' />,
      key: 'action',
      render: (text, record) => (
        <span>
          <Button onClick={() => editMedia(record)}>
            <FormattedMessage id='modify' defaultMessage='Modifier' />
          </Button>
          <Divider type='vertical' />
          <Popconfirm
            title={(
              <FormattedMessage
                id='form.confirmDelete'
                defaultMessage='Êtes vous sûr ?'
              />
            )}
            onConfirm={() => handleDelete(record)}
            okText={<FormattedMessage id='yes' defaultMessage='Oui' />}
            cancelText={<FormattedMessage id='no' defaultMessage='Non' />}
          >
            <Button
              type='danger'
            >
              <FormattedMessage id='delete' defaultMessage='Supprimer' />
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ]

  const { socials = [] } = shopSettings

  const data = socials.map((media, index) => ({
    key: index,
    type: media.type,
    url: media.url,
  }))

  const icones = [
    'youtube',
    'instagram',
    'twitter-circle',
    'home',
    'behance-square',
    'facebook',
    'linkedin',
    'dailymotion',
  ].filter((icon) => !socials.map((media) => media.type).includes(icon))

  const DailymotionSvg = () => (
    <svg
      version='1.1'
      id='Dailymotion'
      xmlns='http://www.w3.org/2000/svg'
      height='1em'
      viewBox='0 0 96 96'
    >
      <g>
        <path
          id='Medium_00000036224591124208101180000008332054064288928391_'
          className='st0'
          style={{ fill: '#0E0E0E' }}
          d='M44,10H16c-1.1,0-2,0.9-2,2v12
  c0,0.5,0.2,1,0.6,1.4l12,12C27,37.8,27.5,38,28,38h16c5.5,0,10,4.5,10,10s-4.5,10-10,10H22c-1.1,0-2,0.9-2,2v12
  c0,0.5,0.2,1,0.6,1.4l12,12C33,85.8,33.5,86,34,86h10c21,0,38-17,38-38S65,10,44,10z M18,16.8l8,8v6.3l-8-8V16.8z M58,48
  c0-7.7-6.3-14-14-14H30v-8h14c12.1,0,22,9.9,22,22s-9.9,22-22,22h-9.2l-8-8H44C51.7,62,58,55.7,58,48z M24,64.8l8,8v6.3l-8-8V64.8z
   M44,82h-8v-8h8c14.3,0,26-11.7,26-26S58.3,22,44,22H28.8l-8-8H44c18.7,0,34,15.3,34,34S62.7,82,44,82z'
        />
        <rect x='0' className='st1' width='96' height='96' style={{ fill: 'none' }} />
      </g>
    </svg>
  )

  const DailymotionIcon = () => <Icon component={DailymotionSvg} />

  const renderIcons = icones.map((icone) => (
    // HOTFIX : on voulait (absolument) avoir le logo Dailymotion, j'ai fait un quick-win
    <Option value={icone} key={icone}>
      {
        icone === 'dailymotion' ? (<DailymotionIcon />)
          : (<Icon type={icone} theme='filled' />)
      }
      <span style={{ marginLeft: '5px' }}>{icone.split('-')[0]}</span>
    </Option>
  ))

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Button
            onClick={() => editMedia({})}
            style={{ marginRight: 8 }}
            type='primary'
          >
            <FormattedMessage
              id='socialsForm.addMedia'
              defaultMessage='Ajouter un média'
            />
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table columns={columns} dataSource={data} />
      <Drawer
        title={(
          <FormattedMessage
            id='SocialsForm.title'
            defaultMessage='Mon média'
          />
        )}
        placement='right'
        closable={false}
        width='50%'
        onClose={onClose}
        bodyStyle={{ paddingBottom: 80 }}
        visible={editing}
      >
        <Form
          layout='vertical'
          hideRequiredMark
          onSubmit={handleSubmit}
        >
          <Row gutter={16}>
            <Col span={4}>
              {
                editedMedia && editedMedia.type
                  ? (
                    <div>
                      <h3>Type</h3>
                      <p>{editedMedia.type}</p>
                    </div>
                  )
                  : (
                    <Form.Item label={<FormattedMessage id='type' defaultMessage='Type' />}>
                      {getFieldDecorator('type', {
                        rules: [{
                          required: true,
                          message: <FormattedMessage id='required.icon' defaultMessage='Choisissez une icône' />,
                        }],
                        initialValue: icones[0],
                      })(
                        <Select
                          placeholder={<FormattedMessage id='icon' defaultMessage='Icône' />}
                          className='socialSelect'
                          dropdownStyle={{ fontSize: '20px', textAlign: 'center' }}
                        >
                          {renderIcons}
                        </Select>,
                      )}
                    </Form.Item>
                  )
              }
            </Col>
            <Col span={12}>
              <Form.Item label='URL'>
                {
                  getFieldDecorator('url', {
                    rules: [
                      {
                        required: true,
                        message: <FormattedMessage id='required.URL' defaultMessage='Entrez une URL' />,
                      },
                      {
                        type: 'url',
                        message: <FormattedMessage id='required.validURL' defaultMessage='Entrez une URL valide' />,
                      },
                    ],
                    initialValue: editedMedia.url,
                  })(<Input
                    prefix={<Icon type='home' style={{ fontSize: 13 }} />}
                    placeholder={intl.formatMessage({
                      id: 'socialsForm.placeholder.URL',
                      defaultMessage: 'URL du média',
                    })}
                  />)
                }
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              <FormattedMessage id='cancel' defaultMessage='Annuler' />
            </Button>
            <Button
              htmlType='submit'
              type='primary'
            >
              <FormattedMessage id='save' defaultMessage='Enregistrer' />
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default Form.create({ name: 'shopSocials' })(injectIntl(SocialsForm))

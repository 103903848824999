import React from 'react'
import date from 'dayjs'
import { FormattedMessage } from 'react-intl'
import { uniq, flatten } from 'lodash'
import {
  Drawer,
  Typography,
  Button,
  Table,
  InputNumber,
  Tabs,
  // message,
  notification,
} from 'antd'
import { getProductStocks, getProductGlobalStocks, getProductStocksByEntity } from '../../../helpers/stocks'
import { getUserEntityStatus } from '../../../helpers/entities'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const slack = require('slack-notify')('https://hooks.slack.com/services/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')

const { Title, Paragraph } = Typography
const { TabPane } = Tabs

export default class RestockDrawer extends React.Component {
  constructor() {
    super()
    this.state = {
      newStocks: [],
    }
  }

  setNewStocks = (stock, modifications) => {
    const { newStocks } = this.state
    const newStock = {
      ...stock,
      ...modifications,
      modifications,
    }
    const previous = newStocks.find((s) => s._id === stock._id)
    if (previous) {
      newStocks.splice(newStocks.indexOf(previous), 1)
      newStock.modifications = {
        ...previous.modifications,
        ...modifications,
      }
    }
    newStocks.push(newStock)
    this.setState({ newStocks })
  }

  render() {
    const {
      product,
      visible,
      onClose,
      user,
      shop,
      allStocks,
      entities,
    } = this.props
    const { newStocks } = this.state

    // NOTE case for !data
    if (!product || !product.stock) {
      return (
        <Drawer
          visible={visible}
          width='55vw'
          onClose={onClose}
          title='Restock'
        />
      )
    }

    const status = getUserEntityStatus(user, shop)

    const columns = [
      {
        title: <FormattedMessage id='variations' defaultMessage='Variations' />,
        key: 'variationCombo',
        render: (stock) => {
          if (!stock.variationCombo?.length) return null
          return (
            <span>
              {`${stock.variationCombo[0].name} | ${stock.variationCombo[1].name}`}
            </span>
          )
        },
      },
      {
        title: <FormattedMessage id='actualStock' defaultMessage='Stock actuel' />,
        dataIndex: 'qty',
        key: 'qty',
      },
      {
        title: <FormattedMessage id='desiredQuantity' defaultMessage='Quantité désirée' />,
        key: 'desiredQuantity',
        render: (stock) => (
          <InputNumber
            style={{ width: '110px' }}
            min={1}
            value={newStocks.find((s) => s._id === stock._id)?.desiredQuantity}
            onChange={(value) => {
              this.setNewStocks(stock, { desiredQuantity: value })
            }}
          />
        ),
      },
    ]

    const confirmation = () => {
      notification.open({
        message: (
          <div style={{

          }}
          >
            <FormattedMessage id='restocking' defaultMessage='Réassort' />
          </div>),
        placement: 'bottomRight',
        duration: 10,
        description: <FormattedMessage
          id='productRestockDrawer.success'
          defaultMessage='Votre demande a bien été prise en compte, un chargé de compte va revenir vers vous.'
        />,
        key: Math.random(),
        onClose: notification.close(),
      })
    }

    const content = () => {
      const entitiesList = uniq(newStocks.map((stock) => stock.entityId)).map((id) => entities.find((e) => e._id === id))
      const result = flatten(entitiesList.map((e) => [
        {
          type: 'divider',
        },
        {
          type: 'section',
          fields: [{
            type: 'mrkdwn',
            text: `*Entité : ${e.name}*\nId entité : ${e._id}\n\nListe des variations sélectionnées :`,
          }],
        },
        {
          type: 'section',
          fields: newStocks.filter((s) => s.entityId === e._id).map((stock) => ({
            type: 'mrkdwn',
            text: `• ${stock.variationCombo[0].name} | ${stock.variationCombo[1].name} : ${stock.desiredQuantity}\n     Id stock : ${stock._id}`,
          })),
        },
      ]))
      return result
    }

    return (
      <Drawer
        visible={visible}
        width='55vw'
        footer={null}
        onClose={onClose}
        title={(
          <span>
            <FormattedMessage id='restocking' defaultMessage='Réassort' />
            -
            {' '}
            {product.name}
          </span>
        )}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title level={3}>
            <FormattedMessage id='productRestockDrawer.stockLevel' defaultMessage='Niveau des stocks' />
          </Title>
          <Paragraph style={{ margin: '8px' }}>
            <FormattedMessage
              id='productRestockDrawer.estimation'
              defaultMessage='Estimation réassort : {number} jours'
              values={{ number: product.estimationRestock || '25' }}
            />
          </Paragraph>
        </div>
        {entities.length && (status === 'owner' || status === 'admin') ? (
          <Tabs defaultActiveKey='global' type='card'>
            <TabPane tab='Global' key='global'>
              <Table
                rowKey={(record) => record._id}
                columns={columns.slice(0, 2)}
                dataSource={getProductGlobalStocks(allStocks, product._id || product.id)}
              />
            </TabPane>
            {entities.sort((a, b) => (date(b.createdAt).isBefore(a.createdAt) && 1) || -1).map((entity) => (
              <TabPane tab={entity.name} key={entity._id}>
                <Table
                  rowKey={(record) => record._id}
                  columns={columns}
                  dataSource={getProductStocksByEntity(allStocks, product._id || product.id, entity._id)}
                />
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <Table
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={getProductStocks(allStocks, product._id || product.id)}
          />
        )}
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Button
              style={{ marginTop: 10 }}
              type='primary'
              onClick={() => {
                onClose()
                slack.send({
                  channel: '#restock-notifs',
                  unfurl_links: 1,
                  username: 'Demande de restock',
                  blocks: [
                    {
                      type: 'divider',
                    },
                    {
                      type: 'header',
                      text: {
                        type: 'plain_text',
                        text: `:truck: Le shop ${shop.settings.name} a fait une demande de restock`,
                        emoji: true,
                      },
                    },
                    {
                      type: 'section',
                      fields: [{
                        type: 'mrkdwn',
                        text: `>*Nom*\n>${product.name}\n>*Id produit*\n>${product._id}`,
                      }],
                      accessory: {
                        type: 'image',
                        image_url: product.picList && product.picList.length
                          ? `${imageBaseUrl}/Products/${product._id}/customProduct/${product.picList[0]}`
                          : `${imageBaseUrl}/Products/${product._id}/${product.favPz || 'front'}/mockup.png`,
                        alt_text: 'product image',
                      },
                    },
                  ].concat(content()).concat(
                    {
                      type: 'divider',
                    },
                    {
                      type: 'section',
                      fields: [{
                        type: 'mrkdwn',
                        // eslint-disable-next-line max-len
                        text: `<https://${shop.settings.url}.niceshop.co/product/${product.baseProduct.category.fr[0]}/${product.baseProduct.subCategory.fr}/${product.name}/${product._id}|Voir le produit>\n<https://app.forestadmin.com/Panopli/Production/All%20Panopli/data/1576500/index/record/1576500/${product._id}/summary|Fiche produit Forest>\n<mailto:${user.email}|Envoyer un mail>`,
                      }],
                    },
                  ),
                }, (err) => {
                  if (err) {
                    console.log('API error:', err)
                  } else {
                    // message.success('Votre demande a bien été prise en compte, un chargé de compte va revenir vers vous')
                    confirmation()
                  }
                })
              }}
            >
              <FormattedMessage id='restock.validate' defaultMessage='Valider l&apos;ensemble des onglets' />
            </Button>
          </div>
        </div>
      </Drawer>
    )
  }
}

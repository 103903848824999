import React from 'react'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'
import {
  Descriptions,
  Divider,
} from 'antd'

const { Item } = Descriptions

export default withRouter((props) => {
  const {
    detailedOrder,
  } = props

  const {
    discounts,
  } = detailedOrder

  let promotion
  if (!discounts) return null
  if (isEmpty(discounts.campaign) && !discounts.code) return null
  if (discounts.code) {
    promotion = discounts.code
  } else if (!isEmpty(discounts.campaign)) {
    promotion = discounts.campaign
  }

  const displayType = () => {
    switch (promotion.type) {
    case 'gift':
      return <FormattedMessage id='gift' defaultMessage='Cadeau' />
    case 'campaign':
      return <FormattedMessage id='campaign' defaultMessage='Campagne' />
    default:
      return <FormattedMessage id='code' defaultMessage='Code' />
    }
  }

  const valueDiscount = () => {
    if (promotion.benefits && promotion.benefits.value) {
      if (promotion.benefits.type === 'amount') return `${promotion.benefits.value} €`
      return `${promotion.benefits.value}%`
    } return '-'
  }

  return (
    <div>
      <Divider />
      <Descriptions title={<FormattedMessage id='discount' defaultMessage='Promotion' />} layout='vertical'>
        <Item label={<FormattedMessage id='lastName' defaultMessage='Nom' />}>
          <a
            href={`${window.location.origin}/discounts?name=${promotion.name}&type=${promotion.type}`}
          >
            {promotion.name}
          </a>
        </Item>
        <Item label={<FormattedMessage id='type' defaultMessage='Type' />}>
          {displayType()}
        </Item>
        <Item label={<FormattedMessage id='value' defaultMessage='Valeur' />}>
          {valueDiscount()}
        </Item>
      </Descriptions>
    </div>
  )
})

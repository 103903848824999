import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from 'antd'
import { MagicLinksTrackingTable, MagicLinksResendModal } from '../..'
import { character, primaryColor } from '../../../config/theme'

const MagicLinksDashboardNewShipments = (props) => {
  try {
    const {
      magicLinks, // Required
      campaigns, // Required
      collaborators, // Required
      user, // Required
      shopOwner, // Required
      history, // Required
      locale, // Required
      setActiveKey, // Required
      modifyMagicLinks, // Required
    } = props

    const [selection, setSelection] = useState([])
    const [resendOne, setResendOne] = useState(false)
    const [stepBeforeResend, setStepBeforeResend] = useState(false)

    const lastShipments = magicLinks.sort((firstMagicLink, secondMagicLink) => (firstMagicLink.createdAt > secondMagicLink.createdAt ? -1 : 1))
    const last10Shipments = lastShipments.slice(0, 10)

    // Si pas de magicLinks, on ne montre pas ce composant
    if (!magicLinks.length) return null

    return (
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '16px', width: '100%',
      }}
      >
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
        }}
        >
          <span style={{ fontWeight: '600', fontSize: '16px', color: character.title }}>
            <FormattedMessage id='magicLinks.dashboard.lastShipments' defaultMessage='Les 10 derniers envois' />
          </span>
          <Button
            type='link'
            onClick={() => {
              history.push('/magicLinks?tabs=tracking')
              setActiveKey('tracking')
            }}
            style={{
              fontWeight: '500', fontSize: '14px', padding: '5px 8px', gap: '10px', color: primaryColor[6],
            }}
          >
            <FormattedMessage id='magicLinks.dashboard.allShipments' defaultMessage='Tous les envois' />
          </Button>
        </div>
        <MagicLinksResendModal
          magicLinks={last10Shipments}
          isModalVisible={stepBeforeResend}
          setIsModalVisible={setStepBeforeResend}
          resendOne={resendOne}
          setResendOne={setResendOne}
          selection={selection}
          setSelection={setSelection}
          modifyMagicLinks={modifyMagicLinks}
          onCancel={() => setStepBeforeResend(false)}
        />
        <MagicLinksTrackingTable
          selection={selection}
          setSelection={setSelection}
          resendOne={resendOne}
          setResendOne={setResendOne}
          setStepBeforeResend={setStepBeforeResend}
          user={user}
          shopOwner={shopOwner}
          locale={locale}
          collaborators={collaborators}
          campaigns={campaigns}
          magicLinks={last10Shipments}
          wantedColumns={[
            'formatted.date',
            'email',
            'formatted.campaign',
            'formatted.admin',
            'formatted.status',
            'formatted.nps',
            'action',
          ]}
        />
      </div>
    )
  } catch (e) {
    console.log('molecules/MagicLinksDashboardNewShipments error:', e)
    return null
  }
}

export default MagicLinksDashboardNewShipments

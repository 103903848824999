// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import { channel } from 'redux-saga'
import {
  put, call,
  takeEvery,
} from 'redux-saga/effects'
import * as actions from './actions'
import getAllPastilles from '../../helpers/pastilles'

const fetchedPastilles = channel()

export function* fetchPastilles() {
  const pastilles = yield call(getAllPastilles)
  fetchedPastilles.put({ type: 'S_PROGRESS', payload: { data: pastilles.filter(Boolean) } })
  return 1
}

export function* watchProgressPastilles(action) {
  const { payload } = action
  yield put({ type: actions.PASTILLES_RECEIVED, payload })
}

export default function* () {
  yield takeEvery(actions.FETCH_PASTILLES, fetchPastilles)
  yield takeEvery(fetchedPastilles, watchProgressPastilles)
}

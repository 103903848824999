/* eslint-disable max-len */
import React, { useState } from 'react'
import {
  Input,
  InputNumber,
  Select,
  Switch,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import {
  getNumberOfSameSubCategory, getAllCategory, getAllSubCategory, getAllTags, cleanId, getPrice, checkIdPanopliModification,
} from '../../../helpers/baseProducts/creation'
import { LangSwitch } from '../../atoms'

const { Option } = Select
const { TextArea } = Input

const BaseProductInfoForm = (props) => {
  try {
    const {
      product,
      updateProduct,
      baseProducts,
      products,
    } = props

    const [nameFR, setNameFR] = useState(product.name && product.name.fr ? product.name.fr : '')
    const [nameEN, setNameEN] = useState(product.name && product.name.en ? product.name.en : '')
    const [descriptionFR, setDescriptionFR] = useState(product.description && product.description.fr ? product.description.fr : '')
    const [descriptionEN, setDescriptionEN] = useState(product.description && product.description.en ? product.description.en : '')
    const [category, setCategory] = useState(product.category ? product.category : [])
    const [subCategory, setSubCategory] = useState(product.subCategory ? product.subCategory : [])
    const [constitution, setConstitution] = useState(product.caracteristics && product.caracteristics.constitution ? product.caracteristics.constitution : '')
    const [weight, setWeight] = useState(product.caracteristics && product.caracteristics.weight ? product.caracteristics.weight : '')
    const [originProduct, setOriginProduct] = useState(product.caracteristics && product.caracteristics.originProduct ? product.caracteristics.originProduct : '')
    const [originMark, setOriginMark] = useState(product.caracteristics && product.caracteristics.originMark ? product.caracteristics.originMark : '')
    const [dimension, setDimension] = useState(product.caracteristics && product.caracteristics.dimension ? product.caracteristics.dimension : '')
    const [delayDelivery, setDelayDelivery] = useState(product.caracteristics && product.caracteristics.delayDelivery && (!product.caracteristics.delayDelivery.max || !product.caracteristics.delayDelivery.min) ? product.caracteristics.delayDelivery : '')
    const [capacity, setCapacity] = useState(product.caracteristics && product.caracteristics.capacity ? product.caracteristics.capacity : '')
    const [capicityMesure, setCapicityMesure] = useState(product.caracteristics && product.caracteristics.capacityMesure ? product.caracteristics.capacityMesure : 'litre')
    const [customCode, setCustomCode] = useState(product.caracteristics && product.caracteristics.customCode ? product.caracteristics.customCode : '')
    const [OOS, setOOS] = useState(product.caracteristics?.OOS)
    const [costHT, setCost] = useState(product.pricing && (product.pricing.costHT || product.pricing.costHT === 0) ? product.pricing.costHT : '')
    const [margin, setMargin] = useState(product.pricing && (product.pricing.margin || product.pricing.margin === 0) ? product.pricing.margin : '')
    const [tva, setTVA] = useState(product.pricing && product.pricing.tva ? product.pricing.tva : '')
    const [tags, setTags] = useState(product.tags && product.tags.length ? product.tags : [])
    const [supplierProductUrl, setSupplierProductUrl] = useState(product.supplierProductUrl ? product.supplierProductUrl : '')
    const [providerProductId, setProviderProductId] = useState(product.providerProductId && product.providerProductId ? product.providerProductId : '')
    const [customizable, setCustomizable] = useState(product.customizable || true)
    const [dematerialized, setDematerialized] = useState(product.dematerialized || product.idCodes || false)
    const [urlDematerialized, setUrlDematerialized] = useState(product.urlDematerialized || '')
    const [language, setLanguage] = useState('fr')

    const intl = useIntl()

    const { allCategoryFR, allCategoryEN } = getAllCategory(baseProducts)
    const { allSubCategoryFR, allSubCategoryEN } = getAllSubCategory(baseProducts)

    const createPanopliId = (value, iteration = 0) => `pan-${cleanId(value).toLowerCase()}-${parseFloat(getNumberOfSameSubCategory(baseProducts, value, products)) + parseFloat(iteration)}`

    const onChangeFrSubCategory = (value, iteration = 0) => {
      // On créer l'idPanopli
      const idPanopli = createPanopliId(value, iteration)
      // On vérifie si l'idPanopli créé n'existe pas
      if (checkIdPanopliModification(idPanopli, product, baseProducts, products)) {
        // Si l'idPanopli existe déjà, on relance le process mais avec iteration + 1
        onChangeFrSubCategory(value, iteration + 1)
      } else if (value) {
        setSubCategory({
          ...subCategory,
          fr: value,
        })
        updateProduct(product.provisionalId, {
          subCategory: {
            ...product.subCategory,
            fr: value,
          },
          ...(!product.existInDB && { idPanopli }),
        })
      }
    }

    const onChangeEnSubCategory = (value) => {
      if (value) {
        setSubCategory({
          ...subCategory,
          en: value,
        })
        updateProduct(product.provisionalId, {
          subCategory: {
            ...product.subCategory,
            en: value,
          },
        })
      }
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{
          display: 'flex', flexDirection: 'row', width: '100%',
        }}
        >
          <div style={{
            fontWeight: 'bold', width: '100%',
          }}
          >
            {language === 'fr' ? 'Nom*' : 'Name*'}
            <div style={{
              display: 'flex', flexDirection: 'row',
            }}
            >
              {language === 'fr' ? (
                <Input
                  value={nameFR}
                  onChange={(e) => {
                    setNameFR(e.target.value)
                    updateProduct(product.provisionalId, {
                      name: {
                        ...product.name,
                        fr: e.target.value,
                      },
                    })
                  }}
                  style={{ marginBottom: '10px', marginRight: '10px' }}
                />
              ) : (
                <Input
                  value={nameEN}
                  onChange={(e) => {
                    setNameEN(e.target.value)
                    updateProduct(product.provisionalId, {
                      name: {
                        ...product.name,
                        en: e.target.value,
                      },
                    })
                  }}
                  style={{ marginBottom: '10px', marginRight: '10px' }}
                />
              )}
              <div>
                <LangSwitch
                  defaultValue={language}
                  onChange={(e) => {
                    setLanguage(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div style={{
            fontWeight: 'bold', flex: '1 1',
          }}
          >
            <FormattedMessage id='description' defaultMessage='Description' />
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
            }}
            >
              {language === 'fr' ? (
                <TextArea
                  value={descriptionFR}
                  onChange={(e) => {
                    setDescriptionFR(e.target.value)
                    updateProduct(product.provisionalId, {
                      description: {
                        ...product.description,
                        fr: e.target.value,
                      },
                    })
                  }}
                  style={{ marginBottom: '10px' }}
                />
              ) : (
                <TextArea
                  value={descriptionEN}
                  onChange={(e) => {
                    setDescriptionEN(e.target.value)
                    updateProduct(product.provisionalId, {
                      description: {
                        ...product.description,
                        en: e.target.value,
                      },
                    })
                  }}
                  style={{ marginBottom: '10px' }}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div style={{
            fontWeight: 'bold', margin: '0px 5px 10px 0px', flex: '1 2',
          }}
          >
            {language === 'fr' ? 'Catégorie*' : 'Category*'}
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
            }}
            >
              {language === 'fr' ? (
                <Select
                  id='frCategory'
                  mode='tags'
                  style={{ width: '100%' }}
                  value={category.fr}
                  placeholder='Bureau'
                  onChange={(value) => {
                    setCategory({
                      ...category,
                      fr: value,
                    })
                    updateProduct(product.provisionalId, {
                      category: {
                        ...product.category,
                        fr: value,
                      },
                    })
                  }}
                >
                  {allCategoryFR.map((cat) => (
                    <Option value={cat}>{cat}</Option>
                  ))}
                </Select>
              ) : (
                <Select
                  id='enCategory'
                  mode='tags'
                  style={{ width: '100%' }}
                  value={category.en}
                  placeholder='Office'
                  onChange={(value) => {
                    setCategory({
                      ...category,
                      en: value,
                    })
                    updateProduct(product.provisionalId, {
                      category: {
                        ...product.category,
                        en: value,
                      },
                    })
                  }}
                >
                  {allCategoryEN.map((cat) => (
                    <Option value={cat}>{cat}</Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
          <div style={{
            fontWeight: 'bold', margin: '0px 0px 10px 5px', flex: '1 2',
          }}
          >
            <FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />
            *
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
            }}
            >
              {language === 'fr' ? (
                <Select
                  showSearch
                  id='frSubCategory'
                  style={{ width: '100%' }}
                  value={subCategory.fr}
                  placeholder='Stylo'
                  onChange={(value) => onChangeFrSubCategory(value)}
                  onSearch={(value) => onChangeFrSubCategory(value)}
                >
                  {allSubCategoryFR.map((cat) => (
                    <Option value={cat}>{cat}</Option>
                  ))}
                </Select>
              ) : (
                <Select
                  showSearch
                  id='enSubCategory'
                  style={{ width: '100%' }}
                  value={subCategory.en}
                  placeholder='Pencil'
                  onChange={(value) => onChangeEnSubCategory(value)}
                  onSearch={(value) => onChangeEnSubCategory(value)}
                >
                  {allSubCategoryEN.map((cat) => (
                    <Option value={cat}>{cat}</Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div style={{
            fontWeight: 'bold', margin: '0px 5px 10px 0px', flex: '1 3',
          }}
          >
            <FormattedMessage id='baseProductInfoForm.supplierProductId' defaultMessage='ID du produit chez le fournisseur*' />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                defaultValue={providerProductId}
                placeholder='STTW068'
                onChange={(e) => {
                  setProviderProductId(e.target.value)
                  updateProduct(product.provisionalId, {
                    providerProductId: e.target.value,
                  })
                }}
                style={{ marginBottom: '10px' }}
              />
            </div>
          </div>
          <div style={{
            fontWeight: 'bold', margin: '0px 5px 10px 5px', flex: '1 3',
          }}
          >
            <FormattedMessage id='supplierProductLink' defaultMessage='Lien du produit chez le fournisseur' />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                defaultValue={supplierProductUrl}
                onChange={(e) => {
                  setSupplierProductUrl(e.target.value)
                  updateProduct(product.provisionalId, {
                    supplierProductUrl: e.target.value,
                  })
                }}
                style={{ marginBottom: '10px' }}
              />
            </div>
          </div>
          <div style={{
            fontWeight: 'bold', margin: '0px 0px 10px 5px', flex: '1 3',
          }}
          >
            <FormattedMessage id='tags' defaultMessage='Tags' />
            <div style={{ display: 'flex', flexDirection: 'column', fontWeight: '500' }}>
              <Select
                mode='tags'
                value={tags}
                style={{ width: '100%' }}
                placeholder={<FormattedMessage id='baseProductInfoForm.placeholder.tags' defaultMessage='Responsable...' />}
                onChange={(value) => {
                  setTags(value)
                  updateProduct(product.provisionalId, {
                    tags: value,
                  })
                }}
              >
                {getAllTags(baseProducts).map((tag) => <Option key={tag}>{tag}</Option>)}
              </Select>
            </div>
          </div>
        </div>
        <div style={{
          border: '1px solid #EAEAEA', padding: '10px', borderRadius: '5px', backgroundColor: '#EAEAEA',
        }}
        >
          <div style={{
            fontWeight: 'bold', marginBottom: '10px',
          }}
          >
            <FormattedMessage id='characteristics' defaultMessage='Caractéristiques' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{
              margin: '0px 5px 10px 0px', flex: '1 3 auto',
            }}
            >
              <FormattedMessage id='constitution' defaultMessage='Constitution' />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  defaultValue={constitution}
                  placeholder={intl.formatMessage({
                    id: 'baseProductInfoForm.placeholder.constitution',
                    defaultMessage: 'Acier inoxydable, plastique',
                  })}
                  onChange={(e) => {
                    setConstitution(e.target.value)
                    updateProduct(product.provisionalId, {
                      caracteristics: {
                        ...product.caracteristics,
                        constitution: e.target.value,
                      },
                    })
                  }}
                  style={{ marginBottom: '10px' }}
                />
              </div>
            </div>
            <div style={{
              margin: '0px 5px 10px 5px', flex: '1 3 auto',
            }}
            >
              <FormattedMessage id='baseProductInfoForm.weight' defaultMessage='Poids en kg' />
              *
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <InputNumber
                  defaultValue={weight}
                  placeholder='0,064'
                  onChange={(value) => {
                    setWeight(value)
                    updateProduct(product.provisionalId, {
                      caracteristics: {
                        ...product.caracteristics,
                        weight: value,
                      },
                      deliveryContribution: value,
                    })
                  }}
                  style={{ marginBottom: '10px', width: '100%' }}
                />
              </div>
            </div>
            <div style={{
              margin: '0px 0px 10px 5px', flex: '1 3 auto',
            }}
            >
              <FormattedMessage id='baseProductInfoForm.capacity' defaultMessage='Contenance' />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  defaultValue={capacity}
                  placeholder='0,064'
                  addonAfter={(
                    <Select
                      value={capicityMesure}
                      onChange={(value) => {
                        setCapicityMesure(value)
                        updateProduct(product.provisionalId, {
                          caracteristics: {
                            ...product.caracteristics,
                            capacityMesure: value,
                          },
                        })
                      }}
                    >
                      <Option value='litre'>
                        <FormattedMessage id='liter' defaultMessage='litre' />
                      </Option>
                      <Option value='cl'>cl</Option>
                      <Option value='ml'>ml</Option>
                    </Select>
                  )}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value)
                    setCapacity(value)
                    updateProduct(product.provisionalId, {
                      caracteristics: {
                        ...product.caracteristics,
                        capacity: value,
                      },
                    })
                  }}
                  style={{ marginBottom: '10px', width: '100%' }}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{
              margin: '0px 5px 10px 0px', flex: '1 2',
            }}
            >
              <FormattedMessage id='productOrigin' defaultMessage='Origine du produit' />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  defaultValue={originProduct}
                  placeholder='France'
                  onChange={(e) => {
                    setOriginProduct(e.target.value)
                    updateProduct(product.provisionalId, {
                      caracteristics: {
                        ...product.caracteristics,
                        originProduct: e.target.value,
                      },
                    })
                  }}
                  style={{ marginBottom: '10px' }}
                />
              </div>
            </div>
            <div style={{
              margin: '0px 5px 10px 5px', flex: '1 2',
            }}
            >
              <FormattedMessage id='originOfMarking' defaultMessage='Origine du marquage' />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  defaultValue={originMark}
                  placeholder='France'
                  onChange={(e) => {
                    setOriginMark(e.target.value)
                    updateProduct(product.provisionalId, {
                      caracteristics: {
                        ...product.caracteristics,
                        originMark: e.target.value,
                      },
                    })
                  }}
                  style={{ marginBottom: '10px' }}
                />
              </div>
            </div>
            <div style={{
              margin: '0px 0px 10px 5px', flex: '1 4',
            }}
            >

              <FormattedMessage id='catalog.creation.SH' defaultMessage='Code SH*' />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  defaultValue={customCode}
                  onChange={(e) => {
                    setCustomCode(e.target.value)
                    updateProduct(product.provisionalId, {
                      caracteristics: {
                        ...product.caracteristics,
                        customCode: e.target.value,
                      },
                    })
                  }}
                  style={{ marginBottom: '10px' }}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ margin: '0px 5px 10px 0px', flex: '2 4' }}>
              <FormattedMessage id='deliveryDelay' defaultMessage='Délais de livraison' />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Select
                  onChange={(value) => {
                    setDelayDelivery(value)
                    updateProduct(product.provisionalId, {
                      caracteristics: {
                        ...product.caracteristics,
                        delayDelivery: value,
                      },
                    })
                  }}
                  placeholder={<FormattedMessage id='deliveryDelay' defaultMessage='Délais de livraison' />}
                  value={delayDelivery}
                  allowClear
                  style={{ width: '100%' }}
                >
                  <Option value={1}>
                    <FormattedMessage id='within24hours' defaultMessage='Sous 24 heures' />
                  </Option>
                  <Option value={2}>
                    <FormattedMessage id='within2days' defaultMessage='Sous 2 jours' />
                  </Option>
                  <Option value={5}>
                    <FormattedMessage id='within5days' defaultMessage='Sous 5 jours' />
                  </Option>
                  <Option value={7}>
                    <FormattedMessage id='within7days' defaultMessage='Sous 7 jours' />
                  </Option>
                  <Option value={15}>
                    <FormattedMessage id='within15days' defaultMessage='Sous 15 jours' />
                  </Option>
                  <Option value={30}>
                    <FormattedMessage id='within30days' defaultMessage='Sous 30 jours' />
                  </Option>
                </Select>
              </div>
            </div>
            <div style={{
              margin: '0px 5px 10px 5px', flex: '1 4',
            }}
            >
              <FormattedMessage id='dimensions' defaultMessage='Dimensions' />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  defaultValue={dimension}
                  placeholder='aaa x bbb x ccc'
                  addonAfter='cm'
                  onChange={(e) => {
                    setDimension(e.target.value)
                    updateProduct(product.provisionalId, {
                      caracteristics: {
                        ...product.caracteristics,
                        dimension: e.target.value,
                      },
                    })
                  }}
                  style={{ marginBottom: '10px' }}
                />
              </div>
            </div>
            <div style={{
              margin: '0px 5px 10px 5px', flex: '1 4', display: 'flex', flexDirection: 'column',
            }}
            >
              <FormattedMessage id='baseProductInfoForm.OOS' defaultMessage='Rupture de stock (OOS)' />
              <div style={{ display: 'flex', marginTop: '5px' }}>
                <Switch
                  checked={OOS}
                  onClick={(value) => {
                    setOOS(value)
                    updateProduct(product.provisionalId, {
                      caracteristics: {
                        ...product.caracteristics,
                        OOS: value,
                      },
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={{
            border: '1px solid #EAEAEA', padding: '10px', borderRadius: '5px', backgroundColor: '#EAEAEA', marginTop: '10px', marginBottom: '10px',
          }}
          >
            <div style={{
              fontWeight: 'bold', marginBottom: '10px',
            }}
            >
              <FormattedMessage id='pricing' defaultMessage='Pricing' />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <div style={{
                margin: '0px 5px 0px 0px', flex: '1 3 auto',
              }}
              >
                <FormattedMessage id='purchasePriceExcl' defaultMessage="Prix d'achat HT" />
                *
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input
                    type='number'
                    defaultValue={costHT}
                    addonAfter='€'
                    onChange={(e) => {
                      const intValue = parseFloat(e.target.value)
                      setCost(intValue)
                      const newPrice = getPrice(intValue, margin)
                      updateProduct(product.provisionalId, {
                        pricing: {
                          ...product.pricing,
                          costHT: intValue,
                          priceHT: newPrice,
                        },
                        unitCost: intValue,
                        unitPrice: newPrice,
                      })
                    }}
                    style={{ marginBottom: '10px', width: '100%' }}
                  />
                </div>
              </div>
              <div style={{
                margin: '0px 0px 0px 5px', flex: '1 3 auto',
              }}
              >
                <FormattedMessage id='baseProductInfoForm.recommendedMargin' defaultMessage='Marge conseillée' />
                *
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input
                    type='number'
                    defaultValue={margin}
                    addonAfter='%'
                    onChange={(e) => {
                      const intValue = parseFloat(e.target.value)
                      setMargin(intValue)
                      const newPrice = getPrice(costHT, intValue)
                      updateProduct(product.provisionalId, {
                        pricing: {
                          ...product.pricing,
                          margin: intValue,
                          priceHT: newPrice,
                        },
                        priceHT: newPrice,
                      })
                    }}
                    style={{ marginBottom: '10px', width: '100%' }}
                  />
                </div>
              </div>
              <div style={{
                margin: '0px 0px 0px 5px', flex: '1 3 auto',
              }}
              >
                <FormattedMessage id='customVAT' defaultMessage='TVA personnalisée' />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input
                    type='number'
                    defaultValue={tva}
                    addonAfter='%'
                    onChange={(e) => {
                      const intValue = parseFloat(e.target.value)
                      setTVA(intValue)
                      updateProduct(product.provisionalId, {
                        pricing: {
                          ...product.pricing,
                          tva: intValue,
                        },
                      })
                    }}
                    style={{ marginBottom: '10px', width: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{
              fontWeight: 'bold', margin: '0px 5px 10px 0px', flex: '1 3',
            }}
            >
              <FormattedMessage id='baseProductInfoForm.dematerialized' defaultMessage='Dématérialisé' />
              <div>
                <Switch
                  checked={dematerialized}
                  onClick={(value) => {
                    setDematerialized(value)
                    updateProduct(product.provisionalId, {
                      dematerialized: value,
                    })
                  }}
                />
              </div>
              {dematerialized && (
                <div style={{
                  margin: '10px 5px',
                }}
                >
                  <FormattedMessage id='baseProductInfoForm.helper1' defaultMessage='Le lien du site pour utiliser le code' />
                  <div>
                    <Input
                      defaultValue={urlDematerialized}
                      onChange={(e) => {
                        setUrlDematerialized(e.target.value)
                        updateProduct(product.provisionalId, {
                          urlDematerialized: e.target.value,
                        })
                      }}
                      style={{ marginBottom: '10px' }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div style={{
              fontWeight: 'bold', margin: '0px 5px 10px 0px', flex: '1 3',
            }}
            >
              <FormattedMessage id='stock' defaultMessage='Stock' />
              <div>
                <Switch
                  checkedChildren={<FormattedMessage id='baseProductInfoForm.unlimited' defaultMessage='Illimité' />}
                  defaultChecked
                  disabled
                />
              </div>
            </div>
            <div style={{
              fontWeight: 'bold', margin: '0px 5px 10px 0px', flex: '1 3',
            }}
            >
              <FormattedMessage id='customizable' defaultMessage='Personnalisable' />
              <div>
                <Switch
                  checked={customizable}
                  onClick={(value) => {
                    setCustomizable(value)
                    updateProduct(product.provisionalId, {
                      customizable: value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/BaseProductInfoForm')
    return null
  }
}

export default injectIntl(BaseProductInfoForm)

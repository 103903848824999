import React from 'react'
import { Tooltip } from 'antd'

export const ColumnEllipsis = (props) => {
  const {
    children,
    minWidth = '40px',
    maxWidth = '85px',
    placement = 'topLeft',
    title,
  } = props

  return (
    <div style={{
      minWidth,
      ...(window.innerWidth <= 1600 && { maxWidth }),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }}
    >
      <Tooltip placement={placement} title={title || children}>
        {children}
      </Tooltip>
    </div>
  )
}

export default ColumnEllipsis

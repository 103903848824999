import React from 'react'
import {
  CampaignParametersCard,
  PreviewContainer,
  MessagePreview,
} from '../..'

const CampaignEditParameters = (props) => {
  const {
    shop, // Required
    campaign, // Required
    setCampaign, // Required
    setNeedUpdateCampaign, // Required
    createdCampaignId, // Required
    isDisabled = false,
  } = props

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
      <CampaignParametersCard
        shop={shop}
        campaign={campaign}
        setCampaign={setCampaign}
        setNeedUpdateCampaign={setNeedUpdateCampaign}
        createdCampaignId={createdCampaignId}
        isDisabled={isDisabled}
      />
      <PreviewContainer>
        <MessagePreview campaign={campaign} />
      </PreviewContainer>
    </div>
  )
}

export default CampaignEditParameters

import { initialState } from './selectors'
import {
  SET_LOCALE,
} from './actions'

const locale = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
  case SET_LOCALE:
    return {
      ...state,
      locale: payload,
    }
  default:
    return state
  }
}

export default locale

/* eslint-disable camelcase */
// Doc:
// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
// https://github.com/diegohaz/arc/wiki/Actions#async-actions
import React from 'react'
import {
  put, call, all, takeEvery, select, takeLatest,
} from 'redux-saga/effects'
import fetch from 'axios'
import { notification } from 'antd'
import { FormattedMessage } from 'react-intl'
import * as actions from './actions'
import { uploadAction } from '../../helpers/imageUpload'
import {
  fromShops,
  fromUsers,
  fromProducts,
  fromBaseProducts,
} from '../selectors'
import { getMembers } from '../members/sagas'
import { fetchSuppliers } from '../suppliers/sagas'
import { fetchBPs } from '../baseProducts/sagas'
import { getOrders } from '../orders/sagas'
import { fetchStocks } from '../stocks/sagas'
import { fetchAllEntities, fetchEntities } from '../entities/sagas'
import { getProviders } from '../providers/sagas'
// import { shouldRefetch } from '../../helpers/refetch'

export function* getMore(api) {
  try {
    const pagination = yield select(fromProducts.pagination)
    if (!pagination.hasMore) return true
    yield put({
      type: actions.GET_MORE,
    })
    const shop = yield select(fromShops.getId)
    const authorization = yield select(fromUsers.getAuth)
    const payload = yield call([api, api.get], '/product', {
      headers: {
        authorization,
        shop,
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      },
    })
    yield put({
      type: actions.RECEIVED_MORE,
      payload,
    })
    const existingBPs = yield select(fromBaseProducts.getBPs)
    yield put({ type: actions.ASSIGN, payload: { data: existingBPs, products: payload.data } })
    return true
  } catch (error) {
    console.log('*getMore', { error })
    yield put({ type: actions.ERROR, payload: { error_details: error.message || error } })
    return false
  }
}

export function* getProducts(api) {
  try {
    const shop = yield select(fromShops.getId)
    const authorization = yield select(fromUsers.getAuth)
    // const lastFetchProducts = yield select(fromProducts.getLastFetchedProducts)
    // const storedOrigin = yield select(fromProducts.getStoredOrigin)
    // const shouldProductsBeRefetch = bypass || shouldRefetch(lastFetchProducts, new Date(), shop, storedOrigin)
    // if (!shouldProductsBeRefetch) {
    //   console.log('bypass , shouldnt seethis')
    //   return true
    // }
    yield put({
      type: actions.GET,
    })
    yield put(actions.setLoading(true))
    const payload = yield call([api, api.get], '/product', {
      headers: {
        authorization,
        shop,
      },
    })
    yield put({
      type: actions.RECEIVED,
      payload: {
        ...payload,
        storedOrigin: shop,
      },
    })
    yield put(actions.setLoading(false))
    const existingBPs = yield select(fromBaseProducts.getBPs)
    yield put({ type: actions.ASSIGN, payload: { data: existingBPs, products: payload.data } })
    return true
  } catch (e) {
    console.log({ '*getProducts': e })
    yield put({ type: actions.ERROR, payload: { error_details: e.message || e } })
    return false
  }
}

export function* modifyProduct(api, action) {
  try {
    const {
      product_id,
      modifications,
    } = action.payload
    const authorization = yield select(fromUsers.getAuth)
    const products = yield select(fromProducts.allProducts)
    const product = products.find((p) => p._id === product_id)
    const { uploadTokens } = yield call([api, api.put], '/product', {
      _id: product_id,
      // NOTE we only send modifications and required data (unchanged)
      pricing: product.pricing,
      supplierBaseProductId: product.supplierBaseProductId,
      owner: product.owner,
      ...modifications,
    }, {
      headers: {
        authorization,
        fileHashes: JSON.stringify(product.fileHashes || []),
      },
    })
    // NOTE upload tokens are here!
    if (uploadTokens && uploadTokens.length) {
      // NOTE we upload files one by one
      yield fetch({
        url: uploadTokens[0].uri,
        method: 'PUT',
        data: product.files[0],
        cache: 'default',
        headers: {
          'Content-Type': 'image/png',
          'Content-Encoding': 'base64',
        },
      })
    }
    yield call(getProducts, api)
    yield put({
      type: actions.MODIFIED,
    })
    return true
  } catch (error) {
    console.log('*modifyProduct', { error })
    yield put({
      type: actions.MODIFICATION_ERROR,
      payload: {
        error,
      },
    })
    return false
  }
}

export function* createProduct(api, action) {
  try {
    const {
      product,
    } = action.payload
    const authorization = yield select(fromUsers.getAuth)
    const result = yield call([api, api.post], '/product', {
      ...product,
      previewFile: null,
      printingFile: null,
      picListToUpload: null,
      status: 'Inactive',
      custom: true,
      favPZ: 'front',
    }, {
      headers: {
        authorization,
        fileHashes: JSON.stringify(product.fileHashes || []),
      },
    })
    const { newProduct } = result
    if (product.previewFile) {
      if (product.previewFile.front && product.previewFile.front.length) {
        yield uploadAction(
          `Products/${newProduct._id}/front/previewFace.png`,
          product.previewFile.front[0],
        )
      } if (product.previewFile.back && product.previewFile.back.length) {
        yield uploadAction(
          `Products/${newProduct._id}/back/previewDos.png`,
          product.previewFile.back[0],
        )
      }
    } if (product.printingFile) {
      if (product.printingFile.front && product.printingFile.front.length) {
        yield uploadAction(
          `Products/${newProduct._id}/front/print.png`,
          product.printingFile.front[0],
        )
      } if (product.printingFile.back && product.printingFile.back.length) {
        yield uploadAction(
          `Products/${newProduct._id}/back/print.png`,
          product.printingFile.back[0],
        )
      }
    } if (product.picListToUpload && product.picListToUpload.length) {
      yield product.picListToUpload.map((pic) => uploadAction(
        `Products/${newProduct._id}/customProduct/${pic.name}`,
        pic,
      ))
    }
    yield put({
      type: actions.FETCH_FORCE,
    })
    yield put({
      type: actions.CREATED,
      payload: {
        newProduct,
        createdId: product.provisionalId,
      },
    })
    notification.open({
      message: (<FormattedMessage id='store.bp.created' defaultMessage='{name} créé avec succès !' values={{ name: product.name }} />),
      placement: 'bottomRight',
      duration: '8',
    })
    return result
  } catch (error) {
    yield put({
      type: actions.CREATE_ERROR,
      payload: {
        error,
      },
    })
    notification.open({
      message: (<FormattedMessage id='store.bp.notCreated' defaultMessage="Le produit n'a pas été créé" />),
      placement: 'bottomRight',
      duration: '8',
    })
    return false
  }
}

export function* modifyProducts(api) {
  try {
    const products = yield select(fromProducts.allProducts)
    const selectedProducts = products.filter((p) => p.being_modified)
    const authorization = yield select(fromUsers.getAuth)
    yield all(selectedProducts.map((product) => call([api, api.put], '/product', {
      _id: product._id,
      // NOTE we only send modifications and required data (unchanged)
      pricing: product.pricing,
      supplierBaseProductId: product.supplierBaseProductId,
      owner: product.owner,
      ...product.modifications,
    }, {
      headers: {
        authorization,
      },
    })))
    yield put({
      type: actions.MODIFIED,
    })
    return true
  } catch (error) {
    console.log('*modifyProducts', { error })
    yield put({
      type: actions.MODIFICATION_ERROR,
      payload: {
        error,
      },
    })
    return false
  }
}

export function* getDataForProductsPage(api) {
  const page = 0
  const pageSize = 200
  yield all([
    call(getProducts, api),
    call(getMembers, api),
    call(fetchSuppliers, api),
    call(fetchBPs, api),
    call(getOrders, api, { payload: { page, pageSize } }),
    call(fetchStocks, api),
    call(fetchEntities, api),
    call(fetchAllEntities, api),
    call(getProviders, api),
  ])
  yield put({ type: actions.END_FETCH_DATA })
}

export default function* ({ api }) {
  yield takeLatest(actions.INIT_FETCH_DATA, getDataForProductsPage, api)
  yield takeLatest(actions.FETCH_FORCE, getProducts, api)
  yield takeLatest(actions.RECEIVED, getMore, api)
  yield takeLatest(actions.RECEIVED_MORE, getMore, api)
  yield takeEvery(actions.MODIFY, modifyProduct, api)
  yield takeEvery(actions.MODIFY_SEVERAL, modifyProducts, api)
  yield takeEvery(actions.CREATE, createProduct, api)
}

import React from 'react'
import { ContinueStepButton, PreviousStepButton, CreateCampaignStepButton } from '../../atoms'

const buttonStyle = {
  width: '100%',
  height: '40px',
  padding: '5px 8px',
}

const MovingStepButton = (props) => {
  const {
    moveNextStep,
    isContinueDisabled, // Required only if moveNextStep is set
    movePreviousStep,
    createCampaign,
    style,
    continueType,
  } = props

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '8px',
      ...style,
    }}
    >
      {movePreviousStep && (
        <PreviousStepButton
          onClickFunction={movePreviousStep}
          style={buttonStyle}
        />
      )}
      {moveNextStep && (
        <ContinueStepButton
          onClickFunction={moveNextStep}
          disabled={isContinueDisabled}
          style={movePreviousStep && buttonStyle}
          type={continueType}
        />
      )}
      {createCampaign && (
        <CreateCampaignStepButton
          onClickFunction={createCampaign}
          style={(moveNextStep || movePreviousStep) && buttonStyle}
          type='primary'
        />
      )}
    </div>
  )
}

export default MovingStepButton

// https://github.com/diegohaz/arc/wiki/Actions
const req = require.context('.', true, /\.\/.+\/actions\.js$/)
const all_exports = {}
req.keys().forEach((key) => {
  const actions = req(key)
  const [, reducer] = key.split('/')
  if (!all_exports[reducer]) all_exports[reducer] = {}
  Object.keys(actions).forEach((name) => {
    // console.log({ reducer, name })
    all_exports[reducer][name] = actions[name]
  })
  return true
})

export default all_exports

import { isEqual } from '../cart'

export default (content, currentCart) => content.reduce((acc, product) => {
  let same = false
  const result = acc.map((item) => {
    if (isEqual(item, product)) {
      same = true
      return JSON.parse(JSON.stringify({
        ...item,
        quantity: product.quantity + item.quantity,
      }))
    }
    return JSON.parse(JSON.stringify(item))
  })
  if (!same) {
    result.push(JSON.parse(JSON.stringify({
      ...product,
      cart_id: `${Math.random().toString(36).substr(2, 10)}_${currentCart.lenght}`,
    })))
  }
  return result
}, currentCart)

import React from 'react'
import { connect } from 'react-redux'
import {
  fromProducts,
  fromShops,
  fromBaseProducts,
  fromDiscounts,
  fromLocale,
} from '../../store/selectors'
import { createDiscount, modifyDiscount } from '../../store/discounts/actions'
import { DiscountCreation } from '../../components'

const DiscountCreationContainer = (props) => {
  const {
    allProducts,
    shopCollections,
    shopCategories,
    shopSubCategories,
    create,
    closeDiscountDrawer,
    edition,
    allDiscounts,
    discount,
    modify,
    locale = 'fr',
  } = props

  return (
    <DiscountCreation
      products={allProducts}
      collections={shopCollections}
      categories={shopCategories}
      subCategories={shopSubCategories}
      create={create}
      closeDiscountDrawer={closeDiscountDrawer}
      edition={edition}
      discount={discount}
      modify={modify}
      allDiscounts={allDiscounts}
      locale={locale}
    />
  )
}

const mapStateToProps = (state) => ({
  allProducts: fromProducts.allProducts(state),
  shopCollections: fromShops.shopCollections(state),
  shopCategories: fromBaseProducts.getAllCategories(state),
  shopSubCategories: fromBaseProducts.getAllSubCategories(state),
  allDiscounts: fromDiscounts.getDiscounts(state),
  locale: fromLocale.getLocale(state),
})

const mapDispatchToProps = (dispatch) => ({
  create: (discount) => dispatch(createDiscount(discount)),
  modify: (discount) => dispatch(modifyDiscount(discount)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscountCreationContainer)

import React from 'react'
import {
  Switch,
  InputNumber,
} from 'antd'
import { FormattedMessage } from 'react-intl'

import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

const HiddenCustomBanner = (props) => {
  try {
    const { newCustom, setNewCustom } = props
    const { banner } = newCustom
    const { margin, carousel, autoplay } = banner
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage
            id='custom.banner'
            defaultMessage='Bannière'
          />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.bannerMargin'
                defaultMessage='Taille de marge de la bannière'
              />
            </div>
            <InputNumber
              placeholder='Margin...'
              value={margin}
              style={{ width: '100px' }}
              onChange={(value) => setNewCustom({ ...newCustom, banner: { ...banner, margin: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.bannerCarousel'
                defaultMessage='Activer le carrousel'
              />
            </div>
            <Switch
              checked={carousel}
              onChange={(value) => setNewCustom({ ...newCustom, banner: { ...banner, carousel: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.bannerAutoplay'
                defaultMessage="Activer l'autoplay du carrousel"
              />
            </div>
            <Switch
              checked={autoplay}
              onChange={(value) => setNewCustom({ ...newCustom, banner: { ...banner, autoplay: value } })}
            />
          </div>
          <div style={columnStyle} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecule/HiddenCustomBanner error:', e)
    return null
  }
}

export default HiddenCustomBanner

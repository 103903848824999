import React, { useState } from 'react'
import {
  Icon,
  Select,
  Button,
  Input,
  notification,
  Modal,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { getMagicLinksByFilters } from '../../../helpers/magicLinks'
import theme from '../../../config/theme'

const { Option } = Select

const MagicLinksTrackingTableFilters = (props) => {
  try {
    const intl = useIntl()

    const {
      state, setState, campaigns, magicLinks, collaborators, modifyMagicLinks,
    } = props
    const [resendModalOpened, setResendModalOpened] = useState(false)
    const { filters, selection } = state

    const data = getMagicLinksByFilters(campaigns, magicLinks, collaborators, filters, false)

    // eslint-disable-next-line
    const repush = (ids) => {
      const modifications = { sentAt: new Date() }
      const payload = { ids, modifications }
      const callback = notification.open({
        message: (<FormattedMessage id='store.magicLinks.update.success.plurial' defaultMessage='Les magics links ont bien été relancés !' />),
        placement: 'bottomRight',
        duration: 8,
      })
      return modifyMagicLinks(payload, callback)
    }

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0px 0 10px 0',
        justifyContent: 'space-between',
      }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontSize: 13,
          color: 'rgba(0, 0, 0, 0.85)',
          flex: '4 8',
        }}
        >
          {selection?.length ? (
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            >
              <span>
                {selection?.length}
                {' '}
                {selection?.length > 1 ? (
                  <FormattedMessage id='campaignTrackingTableFilter.selection.plurial' defaultMessage='éléments sélectionnés' />
                ) : (
                  <FormattedMessage id='campaignTrackingTableFilter.selection.singular' defaultMessage='élément sélectionné' />
                )}
              </span>
              <Button
                style={{
                  backgroundColor: theme.primaryColor[6],
                  color: 'white',
                  margin: '0 16px',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  setResendModalOpened(true)
                }}
              >
                <FormattedMessage id='campaignTrackingTable.selection.resend' defaultMessage='Relancer' />
              </Button>
            </div>
          ) : (
            <span style={{ fontWeight: 'bold' }}>
              {data?.length || 0}
              {' '}
              {data?.length > 1 ? (
                <FormattedMessage id='campaignTrackingTableFilter.sending.plurial' defaultMessage='envois' />
              ) : (
                <FormattedMessage id='campaignTrackingTableFilter.sending.singular' defaultMessage='envoi' />
              )}
            </span>
          )}
        </div>
        <Select
          mode='multiple'
          showArrow
          allowClear
          style={{ marginLeft: 8, minWidth: '220px', flex: '1 8' }}
          value={filters.statuses}
          placeholder={(
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
            >
              <Icon type='audit' style={{ paddingRight: 4 }} />
              <FormattedMessage
                id='campaignTracking.placeholders.status'
                defaultMessage='Tous les statuts'
              />
            </div>
          )}
          onChange={(value) => setState({ ...state, filters: { ...filters, statuses: value } })}
        >
          <Option value='committed'>
            <FormattedMessage id='campaignTracking.status.committed' defaultMessage='Engagé' />
          </Option>
          <Option value='spent'>
            <FormattedMessage id='campaignTracking.status.spent' defaultMessage='Dépensé' />
          </Option>
          <Option value='expired'>
            <FormattedMessage id='campaignTracking.status.expired' defaultMessage='Lien expiré' />
          </Option>
        </Select>
        <Input
          style={{ flex: '1 8' }}
          placeholder={intl.formatMessage({
            id: 'campaignTracking.search',
            defaultMessage: 'Rechercher',
          })}
          onChange={(e) => setState({ ...state, filters: { ...filters, search: e.target.value } })}
        />
        <Modal
          destroyOnClose
          visible={resendModalOpened}
          closable={false}
          centered
          className='modalMagicLinkSend'
          style={{
            borderRadius: 16,
          }}
          footer={(
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
                margin: 0,
              }}
            >
              <Button
                type='primary'
                onClick={() => {
                  setResendModalOpened(false)
                  repush(selection)
                }}
              >
                <FormattedMessage id='campaignTracking.resend.confirm' defaultMessage='Envoyer un mail de relance' />
              </Button>
              <Button style={{ margin: 0 }} onClick={() => { setResendModalOpened(false) }}>
                <FormattedMessage id='campaignTracking.resend.cancel' defaultMessage='Annuler' />
              </Button>
            </div>
          )}
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{
              fontSize: 24,
              marginBottom: 24,
              padding: 12,
              borderRadius: 100,
              background: '#E6E6FF',
              color: 'blue',
            }}
          />
          <h2 style={{ fontWeight: 'bold', marginBottom: 8 }}>
            <FormattedMessage id='campaignTracking.resend.question' defaultMessage='Souhaitez-vous relancer les Magic Links ?' />
          </h2>
          <span style={{ marginBottom: 48 }}>
            <FormattedMessage
              id='campaignTracking.resend.number'
              defaultMessage='{number} envois sélectionés seront relancés'
              values={{ number: selection?.length }}
            />
          </span>
        </Modal>
      </div>
    )
  } catch (e) {
    console.log('MagicLinksTrackingTableFilters error:', e)
    return null
  }
}

export default injectIntl(MagicLinksTrackingTableFilters)

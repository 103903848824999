import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

const FadingTransition = (props) => {
  const {
    children, // Required
    id, // Required
    initial = false,
    mode,
    transition,
    initialProps,
    animateProps,
  } = props
  return (
    <AnimatePresence initial={initial} mode={mode}>
      <motion.div
        key={id}
        initial={{
          opacity: 0,
          ...initialProps,
        }}
        animate={{
          opacity: 1,
          ...animateProps,
        }}
        transition={{
          duration: 2,
          ...transition,
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default FadingTransition

// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
export const GET_SHOP = 'GET_SHOP'
export const GET_SHOPS = 'GET_SHOPS'
export const CHANGE_SHOP = 'CHANGE_SHOP'
export const RECEIVED_SHOP = 'RECEIVED_SHOP'
export const RECEIVED_SHOPS = 'RECEIVED_SHOPS'
export const MODIFY = 'MODIFY_SHOP'
export const CREATE = 'CREATE_SHOP'
export const MODIFIED = 'MODIFIED_SHOP'
export const UPDATE_ERROR = 'UPDATE_ERROR'
export const CREATE_ERROR = 'CREATE_ERROR'
export const CHECK_REFETCH = 'CHECK_REFETCH'

export const TEST_NOTIFICATION = 'TEST_NOTIFICATION'

export const SHOP_NOT_FOUND = 'SHOP_NOT_FOUND'
export const SHOP_UNAVAILABLE = 'SHOP_UNAVAILABLE'
export const UPDATE_OLD_STATE = 'UPDATE_OLD_STATE'
export const INIT_FETCH_DATA = 'INIT_FETCH_DATA_SHOP'
export const END_FETCH_DATA = 'END_FETCH_DATA_SHOP'

export const getShop = (shop) => ({
  type: GET_SHOP,
  payload: {
    shop,
  },
  meta: {
    thunk: true,
  },
})

export const shopSwitch = (id) => ({
  type: CHANGE_SHOP,
  payload: id,
})

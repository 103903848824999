import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { Typography, Descriptions, Tag } from 'antd'
import { fromDiscounts } from '../../store/selectors'

const { Paragraph } = Typography

export default withRouter(connect(
  (state, {
    product,
  }) => ({
    discounts: fromDiscounts.getDiscounts(state).reduce((_a, c) => {
      const a = _a
      const isConcerned = !(c.limits && c.limits.products && c.limits.products.length && !c.limits.products.includes(product._id))
      if (!isConcerned) return a
      a.total += 1
      switch (c.type) {
      case 'gift':
        a.giftCount += 1
        if (c.status === 'Active') a.activeGiftCount += 1
        break
      case 'campaign':
        a.campaignCount += 1
        if (c.status === 'Active') a.activeCampaignCount += 1
        break
      case 'code':
        a.codeCount += 1
        if (c.status === 'Active') a.activeCodeCount += 1
        break
      default:
        break
      }
      return a
    }, {
      codeCount: 0,
      activeCodeCount: 0,
      campaignCount: 0,
      activeCampaignCount: 0,
      giftCount: 0,
      total: 0,
    }),
  }),
)(({ discounts }) => (discounts.total ? (
  <>
    <Paragraph>
      <Descriptions bordered column={24}>
        <Descriptions.Item
          span={24}
          label={<FormattedMessage id='discount' defaultMessage='Promotion' />}
        >
          <FormattedMessage
            id='discount.product.codeCount'
            defaultMessage='total: {count} dont actif(s): {activeCount}'
            values={{
              count: discounts.codeCount || 0,
              activeCount: discounts.activeCodeCount || 0,
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          span={24}
          label={<FormattedMessage id='campaign' defaultMessage='Campagne' />}
        >
          <FormattedMessage
            id='discount.product.campaignCount'
            defaultMessage='total: {count} dont actif(s): {activeCount}'
            values={{
              count: discounts.campaignCount || 0,
              activeCount: discounts.activeCampaignCount || 0,
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          span={24}
          label={<FormattedMessage id='gift' defaultMessage='Cadeau' />}
        >
          <FormattedMessage
            id='discount.product.campaignCount'
            defaultMessage='total: {count} dont actif(s): {activeCount}'
            values={{
              count: discounts.giftCount || 0,
              activeCount: discounts.activeGiftCount || 0,
            }}
          />
        </Descriptions.Item>
      </Descriptions>
    </Paragraph>
  </>
) : (
  <Tag>
    <FormattedMessage id='noDiscount' defaultMessage='Pas de promotion' />
  </Tag>
))))

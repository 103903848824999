import React from 'react'
import {
  Drawer,
  Form,
  Input,
  Button,
  Row,
  message,
} from 'antd'
import { CirclePicker } from 'react-color'
import { FormattedMessage } from 'react-intl'

class GroupCreation extends React.Component {
  constructor() {
    super()
    this.state = {
      color: '#1C61C6',
    }
  }

  handleChangeComplete = (color) => {
    this.setState({ color: color.hex })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
      },
      shop,
      modifyShop,
    } = this.props
    const {
      settings: {
        membersGroups = [],
      },
    } = shop
    const {
      color,
    } = this.state
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          name,
        } = values
        if (membersGroups.filter((group) => values.name === group.name).length) {
          message.error(<FormattedMessage id='groupCreation.nameAlreadyUsed' defaultMessage='Ce nom de groupe est déjà utilisé' />)
          return false
        }
        const newGroups = shop.settings.membersGroups || []
        newGroups.push({
          id: Math.random().toString(36).substring(7),
          name,
          color,
          createdAt: new Date(),
        })
        const saveSettings = {
          settings: {
            ...shop.settings,
            membersGroups: newGroups,
          },
        }

        modifyShop({
          shop_id: shop._id,
          modifications: saveSettings,
          callback: () => {
            message.info(<FormattedMessage id='memberCreation.info.groupCreated' defaultMessage='Groupe créé !' />)
            return true
          },
        })
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        isFieldsTouched,
        resetFields,
      },
      visible,
      onClose,
      preFillGroup,
    } = this.props
    return (
      <Drawer
        visible={visible}
        width={640}
        onClose={onClose}
        title={<FormattedMessage id='groupCreation.newGroup' defaultMessage="Création d'un nouveau groupe" />}
        id='GroupCreation'
      >
        <Form
          id='GroupCreationForm'
          onSubmit={this.handleSubmit}
        >
          <Form.Item label={<FormattedMessage id='groupCreation.name' defaultMessage='Nom du groupe' />}>
            {getFieldDecorator('name', {
              rules: [{
                required: true,
                message: <FormattedMessage id='groupCreation.required.name' defaultMessage='Veuillez renseigner un nom' />,
                whitespace: true,
              }],
              initialValue: preFillGroup && preFillGroup.name,
            })(<Input style={{ width: 300 }} />)}
          </Form.Item>
          <Form.Item label={<FormattedMessage id='color' defaultMessage='Couleur' />}>
            <CirclePicker
              onChangeComplete={this.handleChangeComplete}
            />
          </Form.Item>
          <Row>
            {isFieldsTouched() || (preFillGroup && preFillGroup.name) ? (
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  style={{ marginRight: 20 }}
                >
                  <FormattedMessage id='create' defaultMessage='Créer' />
                </Button>
                <Button onClick={() => resetFields()}>
                  <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
                </Button>
              </Form.Item>
            ) : null}
          </Row>
        </Form>
      </Drawer>
    )
  }
}

export default Form.create({ name: 'GroupCreationForm' })(GroupCreation)

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FormattedSwitch } from '../..'

const CampaignDeliveryInput = (props) => {
  const {
    campaign, // Required
    setCampaign, // Required
    setNeedUpdateCampaign, // Required
    createdCampaignId, // Required
    isDisabled = false,
    size,
  } = props

  const onChangeIsDeliveryOnlyRelayPoint = (isDeliveryOnlyRelayPoint) => {
    setCampaign({ ...campaign, isDeliveryOnlyRelayPoint })
    if (createdCampaignId) {
      setNeedUpdateCampaign(true)
    }
  }

  return (
    <FormattedSwitch
      checked={campaign?.isDeliveryOnlyRelayPoint}
      onChangeFunction={onChangeIsDeliveryOnlyRelayPoint}
      title={(
        <FormattedMessage
          id='campaigns.create.step.parameters.relay_point_delivery'
          defaultMessage='Livraison en point relais uniquement'
        />
      )}
      isDisabled={isDisabled}
      size={size}
    />
  )
}

export default CampaignDeliveryInput

import {
  put, call, takeEvery, select,
} from 'redux-saga/effects'
import * as actions from './actions'
import { fromShops, fromUsers } from '../selectors'
import * as shopActions from '../shops/actions'

const { getId } = fromShops
const { getAuth } = fromUsers

export function* fetchCollaborators(api) {
  try {
    yield put({ type: actions.GET })
    yield put({ type: actions.LOADING, payload: { loading: true } })
    const shop = yield select(getId)
    const auth = yield select(getAuth)
    const { data } = yield call([api, api.get], '/collaborators', { headers: { authorization: auth, shop } })
    yield put({ type: actions.RECEIVED, payload: { data } })
    return true
  } catch (e) {
    console.log('fetchCollaborators error', e, e.message)
    yield put({
      type: actions.ERROR,
      error_details: (e.message || e),
    })
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(shopActions.CHANGE_SHOP, fetchCollaborators, api)
  yield takeEvery(actions.FETCH_FORCE, fetchCollaborators, api)
}

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import {
  Row, Col, Button,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import {
  LoginDrawer,
  ForgottenPassword,
} from '../../components'
import { MODIFY } from '../../store/shops/actions'
import { REQUEST_TOKEN_FOR_PW_REINIT, REINIT_PW } from '../../store/users/actions'
import { fromUsers } from '../../store/selectors'

const mapStateToProps = (state) => ({
  authError: fromUsers.getError(state),
  loading: fromUsers.getLoading(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  modifyShop: (payload) => ({
    type: MODIFY,
    payload,
  }),
  requestNewPW: (payload) => ({
    type: REQUEST_TOKEN_FOR_PW_REINIT,
    payload,
  }),
  reinitPassword: (payload) => ({
    type: REINIT_PW,
    payload,
  }),
}, dispatch)

const AuthContainer = (props) => {
  const {
    authError,
    login,
    requestNewPW,
    loading,

  } = props
  return (
    <Row style={{
      display: 'flex',
      width: '100%',
    }}
    >
      <Col
        span={8}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <FormattedMessage
          id='auth.signupCall'
          defaultMessage='Pas encore inscrit ?'
        />
        <Button
          type='link'
          onClick={() => {
            window.open('https://www.panopli.co/formulaire-pricing')
          }}
          style={{ fontWeight: 'bold' }}
        >
          <FormattedMessage
            id='register.newAccount'
            defaultMessage='Créer mon compte'
          />
        </Button>
      </Col>
      <Col
        span={16}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderLeft: '1px solid #ddd',
          paddingLeft: 30,
        }}
      >
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <LoginDrawer
              login={login}
              authError={authError}
              loading={loading}
            />
            <ForgottenPassword requestNewPW={requestNewPW} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer))

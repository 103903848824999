import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import {
  Button, Divider, Icon, Spin, message,
} from 'antd'

import {
  fromShops,
  fromBaseProducts,
  fromUsers,
  fromProducts,
  fromProviders,
  fromComments,
  fromTechnics,
  fromBaseProductsCart,
} from '../../store/selectors'
import { UPLOAD } from '../../store/uploads/actions'
import {
  UPLOAD_TO_SHOPIFY,
  FETCH_FORCE,
  closeShopifyActionModal,
  MODIFY_BASEPRODUCT,
  CLOSE_STATUS_MODIFICATION_MODAL,
} from '../../store/baseProducts/actions'
import { CREATE, SAVE_DRAFT } from '../../store/products/actions'
import { POST_COMMENT, DELETE_COMMENT } from '../../store/comments/actions'
import { CLOSE_CONFIRMATION_MODAL, MODIFY_PROVIDER } from '../../store/providers/actions'
import useAddProducts from '../../hooks/Products'
import CatalogCartDisplayButton from '../BaseProductsCart/molecules/CatalogCartDisplayButton'
import { CatalogBPList, CatalogSheet } from '../../components/organisms'
import { CatalogBPFilters } from '../../components/molecules'
import { InnerLayout } from '../../layouts'
import { encodeQuery } from '../../helpers/filters'
import {
  trimmedTimeLimit,
  getMinimumMOQ,
  getFiltersFromURL,
  getFilteredBPs,
  // getAllOptions,
  getAllCategories,
  getAllSubCategories,
  getAllTags,
  getAllMaterials,
  getAllOrigins,
  getAllColors,
  getAllProviders,
  getAllStatus,
} from '../../helpers/baseProducts/catalog'

const Catalog = (props) => {
  try {
    const {
      loadingBaseProducts,
      loadingTechnics,
      baseProducts: dbBaseProducts,
      allShops,
      shopId,
      userId,
      history,
      saveDraft,
      postComment,
      draftProducts,
      allProviders,
      uploadToShopify,
      allComments,
      refresh,
      modifyProvider,
      modifyBP,
      deleteComment,
      providerModalOpened,
      statusModalOpened,
      closeProviderModal,
      closeStatusModal,
      shopifyLoading,
      shopifyActionModal,
      closeActionModal,
      allTechnics,
      catalogCartVisible,
      catalogCartContent,
    } = props

    if (loadingBaseProducts || loadingTechnics) {
      return (
        <div style={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
        }}
        >
          <Spin size='large' />
        </div>
      )
    }

    const [_draftProducts, setDraftProducts] = useState(
      draftProducts && draftProducts.length ? draftProducts : [],
    )

    const {
      products,
      pushProducts,
    } = useAddProducts()

    const { search } = history.location

    if (_draftProducts && _draftProducts.length) {
      pushProducts(_draftProducts)
      setDraftProducts([])
    }

    const initialFilter = '?status=Catalogue%202.0'
    const [filters, setFilters] = useState({ ...getFiltersFromURL(search || initialFilter) })
    const [baseProducts, setBaseProducts] = useState([])
    const [isSelectable, setIsSelectable] = useState(false)
    const [selectedProductsToDownload, setSelectedProductsToDownload] = useState([])

    const setURLFilters = (newFilters) => {
      history.push(`/catalog?${encodeQuery(newFilters)}`)
      setFilters(newFilters)
    }

    const resetFilters = () => {
      history.push('/catalog')
      setFilters({})
    }

    const hasFilters = () => Object.entries(filters).some(([key, value]) => (
      (key && !key.includes('product'))
      && (value && Array.isArray(value)
        ? value.length
        : (value && value !== ''))))

    useEffect(() => {
      // Met à jour le titre du document via l’API du navigateur
      setBaseProducts(getFilteredBPs(dbBaseProducts, allProviders, search || initialFilter))
    }, [filters])

    const extra = () => {
      try {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
            key={Math.random()}
          >
            {isSelectable ? (
              <div style={{
                display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center',
              }}
              >
                <span>
                  {selectedProductsToDownload?.length}
                  {' '}
                  produits sélectionnés
                </span>
                <Button
                  type='dashed'
                  disabled={!selectedProductsToDownload?.length}
                  onClick={async () => {
                    const loading = message.loading('En cours de création', 0)
                    const blob = await pdf((
                      <CatalogSheet
                        baseProducts={selectedProductsToDownload}
                      />
                    )).toBlob()
                    if (blob) saveAs(blob, 'proposition')
                    setTimeout(loading, 500)
                  }}
                >
                  <Icon type='download' />
                  .pdf
                </Button>
                <Button
                  onClick={() => {
                    setIsSelectable(false)
                    setSelectedProductsToDownload([])
                  }}
                >
                  Annuler
                </Button>
              </div>
            ) : (
              <Button
                type='primary'
                onClick={() => {
                  setIsSelectable(true)
                }}
                key='downloadProducts'
              >
                <FormattedMessage id='catalog.download.products' defaultMessage='Télécharger des fiches produits' />
              </Button>
            )}
            <CatalogCartDisplayButton catalogCartContent={catalogCartContent} catalogCartVisible={catalogCartVisible} />

            <Divider style={{ height: '30px', margin: '0px' }} type='vertical' />
            <Button
              onClick={refresh}
              key='refresh'
            >
              <Icon type='reload' />
            </Button>
            <Button
              key='exit'
              onClick={() => {
                history.push('/products')
              }}
            >
              <FormattedMessage id='exit' defaultMessage='Quitter' />
            </Button>
          </div>
        )
      } catch (e) {
        console.log('Catalog extra', e)
        return null
      }
    }

    return (
      <InnerLayout
        title={(
          <div style={{ marginBottom: 10 }}>
            <FormattedMessage id='catalog.title' defaultMessage='Le Catalogue Panopli' />
            <img
              src='/logo-panopli-04.png'
              alt='logo'
              style={{
                width: 60,
                margin: -40,
                marginLeft: -6,
                marginBottom: -35,
              }}
            />
          </div>
        )}
        extra={[
          extra(),
        ]}
      >
        <div id='catalogContainer' style={{ display: 'flex' }}>
          <div style={{
            width: '20%',
            minWidth: '205px',
            position: 'relative',
            paddingLeft: '20px',
          }}
          >
            <CatalogBPFilters
              baseProducts={baseProducts}
              DBBaseProducts={dbBaseProducts}
              allProviders={allProviders}
              history={history}
              filters={filters}
              hasFilters={hasFilters}
              setURLFilters={setURLFilters}
              resetFilters={resetFilters}
              getFilteredBPs={getFilteredBPs}
              // getAllOptions={getAllOptions}
              getAllCategories={getAllCategories}
              getAllSubCategories={getAllSubCategories}
              getAllTags={getAllTags}
              getAllMaterials={getAllMaterials}
              getAllOrigins={getAllOrigins}
              getAllColors={getAllColors}
              getAllProviders={getAllProviders}
              getAllStatus={getAllStatus}
            />
          </div>
          <div style={{ width: '80%', padding: '26px', position: 'relative' }}>
            <CatalogBPList
              products={products}
              pushProducts={pushProducts}
              baseProducts={baseProducts}
              DBBaseProducts={dbBaseProducts}
              shopId={shopId}
              userId={userId}
              deleteComment={deleteComment}
              allShops={allShops}
              history={history}
              saveDraft={saveDraft}
              isSelectable={isSelectable}
              selectedProductsToDownload={selectedProductsToDownload}
              setSelectedProductsToDownload={setSelectedProductsToDownload}
              trimmedTimeLimit={trimmedTimeLimit}
              getMinimumMOQ={getMinimumMOQ}
              allProviders={allProviders}
              draftProducts={draftProducts}
              uploadToShopify={uploadToShopify}
              postComment={postComment}
              allComments={allComments}
              modifyProvider={modifyProvider}
              modifyBP={modifyBP}
              providerModalOpened={providerModalOpened}
              statusModalOpened={statusModalOpened}
              closeProviderModal={closeProviderModal}
              closeStatusModal={closeStatusModal}
              shopifyLoading={shopifyLoading}
              shopifyActionModal={shopifyActionModal}
              closeActionModal={closeActionModal}
              filters={filters}
              setURLFilters={setURLFilters}
              getAllCategories={getAllCategories}
              getAllSubCategories={getAllSubCategories}
              getAllOrigins={getAllOrigins}
              getAllStatus={getAllStatus}
              allTechnics={allTechnics}
            />
          </div>
        </div>
      </InnerLayout>
    )
  } catch (e) {
    console.log('Containers/Catalog error:', e)
    return null
  }
}

export default connect(
  (state) => ({
    catalogCartContent: fromBaseProductsCart.getBpCartContent(state),
    catalogCartVisible: fromBaseProductsCart.isVisible(state),
    loadingBaseProducts: fromBaseProducts.loading(state),
    loadingTechnics: fromTechnics.loading(state),
    shop: fromShops.getShop(state),
    allShops: fromShops.allShops(state),
    shopId: fromShops.getId(state),
    userId: fromUsers.getUserId(state),
    baseProducts: fromBaseProducts.getShopifyBps(state),
    userInfos: fromUsers.getUserInformations(state),
    createdId: fromProducts.getCreatedId(state),
    shopifyLoading: fromBaseProducts.loading(state),
    loadingCreation: fromProducts.loadingCreation(state),
    draftProducts: fromProducts.getDraftProducts(state),
    allProviders: fromProviders.getProviders(state),
    providerModalOpened: fromProviders.modalOpened(state),
    statusModalOpened: fromBaseProducts.modalOpened(state),
    shopifyActionModal: fromBaseProducts.shopifyRequireAction(state),
    allComments: fromComments.getComments(state),
    allTechnics: fromTechnics.getAllTechnics(state),
  }), (dispatch) => bindActionCreators({
    uploadToShopify: (payload) => ({
      type: UPLOAD_TO_SHOPIFY,
      payload,
    }),
    closeActionModal: () => closeShopifyActionModal(),
    closeProviderModal: () => ({
      type: CLOSE_CONFIRMATION_MODAL,
    }),
    closeStatusModal: () => ({
      type: CLOSE_STATUS_MODIFICATION_MODAL,
    }),
    upload: (payload) => ({
      type: UPLOAD,
      payload,
    }),
    createProduct: (payload) => ({
      type: CREATE,
      payload,
    }),
    saveDraft: (payload) => ({
      type: SAVE_DRAFT,
      payload,
    }),
    postComment: (payload) => ({
      type: POST_COMMENT,
      payload,
    }),
    deleteComment: (payload) => ({
      type: DELETE_COMMENT,
      payload,
    }),
    refresh: () => ({
      type: FETCH_FORCE,
      payload: {
        force: true,
      },
    }),
    modifyProvider: (payload) => ({
      type: MODIFY_PROVIDER,
      payload,
    }),
    modifyBP: (baseProduct) => ({
      type: MODIFY_BASEPRODUCT,
      payload: { baseProduct },
    }),
  }, dispatch),
)(Catalog)

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { OnboardingForm } from '../../components'
import { CREATE } from '../../store/shops/actions'
import { fromUsers } from '../../store/selectors'

export default connect(
  (state) => ({
    user: fromUsers.getUser(state),
    userInfos: fromUsers.getUserInformations(state),
  }),
  (dispatch) => bindActionCreators({
    createShop: (payload) => ({
      type: CREATE,
      payload,
    }),
  }, dispatch),
)(({
  userInfos,
  createShop,
  user,
}) => (
  <div id='onboarding'>
    <OnboardingForm
      userInfos={userInfos}
      user={user}
      createShop={createShop}
    />
  </div>
))

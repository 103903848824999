import React from 'react'
import { Button, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { character } from '../../../config/theme'

const CampaignCardButton = (props) => {
  const {
    buttonStyle = {},
    onClick, // Required
    icon, // Required
    iconStyle = {},
    tooltipFormattedMessage, // Required
    tooltipPlacement = 'topRight',
  } = props

  return (
    <>
      <Tooltip
        title={tooltipFormattedMessage}
        placement={tooltipPlacement}
      >
        <Button
          style={{
            width: '32px',
            height: '32px',
            padding: '0',
            position: 'absolute',
            bottom: '16px',
            right: '16px',
            ...buttonStyle,
          }}
          onClick={onClick}
        >
          <FontAwesomeIcon
            icon={icon}
            style={{ color: character.title, fontSize: '14px', ...iconStyle }}
          />
        </Button>
      </Tooltip>
    </>
  )
}

export default CampaignCardButton

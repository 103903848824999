// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import React from 'react'
import {
  put, call, takeEvery, select, takeLatest, takeLeading,
} from 'redux-saga/effects'
import bcrypt from 'bcrypt-nodejs'
import { notification, Icon } from 'antd'
import { FormattedMessage } from 'react-intl'

import config from '../../config'
// NOTE * Generator function doc: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/function*'
import { fromUsers, fromShops } from '../selectors'

import * as actions from './actions'
import * as shopActions from '../shops/actions'
import * as membersActions from '../members/actions'
import * as entitiesActions from '../entities/actions'
import * as productsActions from '../products/actions'
import * as collaboratorsActions from '../collaborators/actions'

export function* refreshAuthToken(api) {
  try {
    const refreshToken = yield select(fromUsers.getRefreshToken)
    const authToken = yield select(fromUsers.getAuth)
    const refreshTokenResponse = yield call([api, api.post], '/token', { refreshToken, authToken })
    const { authToken: newAuthToken, refreshToken: newRefreshToken, lastEmittedAt } = refreshTokenResponse
    yield put({
      type: actions.SET_NEW_TOKENS,
      payload: {
        newAuthToken, lastEmittedAt, newRefreshToken,
      },
    })
    return 1
  } catch (e) {
    console.log('refreshAuthToken error', e)
    yield put({ type: actions.SET_NEW_TOKENS_ERROR })
    return -1
  }
}

export function* authenticateUser(api, { payload: { email, password, callback } }) {
  try {
    if (!email && !password) {
      return null
    }
    const authentification = yield call([api, api.post], '/authenticate', { email, password }, {
      headers: {
        mode: 'cors',
      },
    })
    const {
      user, tokenManagement,
    } = authentification

    if (!user?.admin && user?.shops?.some((shop) => shop.planId)) {
      window.location.replace('https://app.beta.panopli.com')
      return true
    }

    if (user.shops) {
      yield put({ type: shopActions.RECEIVED_SHOPS, payload: { shops: user.shops, user } })
    }
    yield put({
      type: actions.RECEIVE,
      payload: {
        user,
        tokenManagement,
      },
    })
    if (callback) {
      callback(null, user)
    }
    return true
  } catch (error) {
    console.log('fetch user error', error, error.message)
    if (callback) {
      callback(error)
    }
    yield put({ type: actions.ERROR, payload: { error } })
    return false
  }
}

const { getUser } = fromUsers
const { getId } = fromShops

export function* createUser(api, { payload: { user, callback } }) {
  try {
    // NOTE if we receive an auth, we should reAuthenticate
    const {
      additionalInfos,
      password,
    } = user

    const modifiedUser = {
      password: bcrypt.hashSync(password),
      email: user.email.toLowerCase(),
      additionalInfos,
    }

    const result = yield call([api, api.post], '/user', modifiedUser, {
      headers: {
        mode: 'cors',
        'Panopli-API-Key': config.api,
        'Content-Type': 'application/json',
      },
      cache: 'default',
    })
    if (result && result.id) {
      const auth = Buffer.from(`${modifiedUser.email}:${user.password}`).toString('base64')

      yield put({ type: actions.SIGN_UP_SUCCESS, payload: { auth } })
      yield put({ type: actions.RECEIVE, payload: { ...modifiedUser, _id: result.id } })
    }

    if (callback) {
      callback()
    }
    return true
  } catch (e) {
    console.log('fetch user error', e, e.message)
    if (callback) {
      callback(e)
    }
    yield put({ type: actions.ERROR, payload: { error: e } })
    return false
  }
}

export function* createUserEntity(api, { payload, callback, callbackError }) {
  try {
    const authorization = yield select(fromUsers.getAuth)
    const shopId = yield select(getId)
    yield call([api, api.post], '/user/entity', { ...payload, shopId }, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    if (callback) {
      callback()
    }
    yield put({
      type: membersActions.ADD_MEMBERS,
      members: [{
        ...payload,
        credit: 0,
        groups: [],
        freeDelivery: false,
        mailCreation: false,
      }],
    })
    yield put({ type: entitiesActions.FETCH_FORCE })
    yield put({ type: collaboratorsActions.FETCH_FORCE })
    return true
  } catch (e) {
    console.log('create user error', e, e.message)
    if (callbackError) {
      callbackError(e)
    }
    // yield put({ type: actions.ERROR, payload: { error: e } })
    return false
  }
}

export function* modifyUserEntity(api, { payload: { user, callback } }) {
  try {
    const modifiedUser = {
      ...user,
      password: bcrypt.hashSync(user.password),
      username: user.email.toLowerCase(),
    }
    yield call([api, api.put], '/user/entity', modifiedUser, {
      headers: {
        mode: 'cors',
      },
    })
    if (callback) {
      callback()
    }
    return true
  } catch (e) {
    console.log('fetch user error', e, e.message)
    // yield put({ type: actions.ERROR, payload: { error: e } })
    return false
  }
}

/**
 * @return {Boolean} did the saga run to completion?
 * @param {*} api the api service
 * @param {*} action { payload: { email, callback } } the action object
 */

export function* fetchForceUserEntity(api, { id }) {
  try {
    const user = yield call([api, api.get], '/user/entity', {
      headers: {
        mode: 'cors',
        id,
      },
    })
    yield put({ type: actions.RECEIVE_USER_ENTITY, payload: user })
    return true
  } catch (e) {
    console.log(e, 'error fetchForceUser')
    yield put({ type: actions.ERROR_ENTITY })
    return false
  }
}

export function* reinitRequest(api, { payload: { email, callback } }) {
  try {
    const response = yield call([api, api.get], '/password/token', {
      headers: {
        'Content-Type': 'application/json',
        email,
        'Panopli-API-Key': email,
      },
    })
    const user = yield select(fromUsers.getUser)
    if (response && response.status === 200) {
      if (callback) {
        callback(null, user)
      }
    } else {
      throw new Error()
    }
  } catch (error) {
    console.log('users - reinitRequest', { error })
    if (callback) {
      callback(error)
    }
  }
}

export function* reinitPasswordAuthenticate(api, { payload: { newPassword, oldPassword, callback } }) {
  const message = <FormattedMessage id='password.modify' defaultMessage='Modification de mot de passe' />
  try {
    const authorization = yield select(fromUsers.getAuth)
    yield call([api, api.post], '/user/password', { newPassword, oldPassword }, {
      headers: {
        authorization,
      },
    })
    notification.open({
      message,
      description: (<FormattedMessage id='password.modify.success' defaultMessage='Mot de passe modifié' />),
      icon: <Icon type='smile' style={{ color: '#108ee9' }} />,
    })
    if (callback) {
      callback()
    }
    return 1
  } catch (e) {
    console.log('reinitPasswordAuthenticate - error', e)
    notification.open({
      message,
      description: (<FormattedMessage id='password.modify.failed' defaultMessage='Cela n&apos;a pas fonctionné' />),
      icon: <Icon type='smile' style={{ color: '#108ee9' }} />,
    })
    return -1
  }
}

export function* reinitPassword(api, { payload: { token, password, callback } }) {
  try {
    const user = yield select(fromUsers.getUser)
    const response = yield call([api, api.get], '/password', {
      headers: {
        'Content-Type': 'application/json',
        password: bcrypt.hashSync(password),
        token,
      },
    })
    if (response && response.status === 200) {
      if (user && user.pristine) {
        const tokenManagement = yield select(fromUsers.getTokenManagement)
        yield put({
          type: actions.RECEIVE,
          payload: {
            user: { ...user, pristine: false },
            tokenManagement,
          },
        })
      }
      if (callback) {
        callback()
      }
    } else {
      throw new Error()
    }
    return 1
  } catch (error) {
    if (callback) {
      callback(error)
    }
    return -1
  }
}

/**
 * `*fetchAllUsers` fetches all the users from the current shop
 * ---
 * TODO multiusers support here
 * @param {Object} api the api service
 * @return {Boolean} true if everything went ok
 */
export function* fetchAllUsers() {
  try {
    yield select(getUser)
    yield select(fromShops.getShop)
    return true
  } catch (e) {
    console.log('fetch user error', e, e.message)
    return false
  }
}

/**
 * `*modifyUser` PUT /user user modifications
 * ---
 * @param {Object} api the api service
 * @param {Object} payload the payload object
 * @return {Boolean} true if everything went ok
 */
export function* modifyUser(api, { payload: _payload }) {
  try {
    const payload = _payload
    const authorization = yield select(fromUsers.getAuth)
    const user = yield select(fromUsers.getUser)
    if (!payload.email) {
      // NOTE email unchanged but swagger requires a email
      payload.email = user.email
    } else {
      payload.new_auth = Buffer.from(`${payload.email}:${user.password}`).toString('base64')
    }
    payload._id = user._id

    yield call([api, api.put], '/user', payload, {
      headers: {
        authorization,
      },
    })
    yield put({
      type: actions.MODIFIED,
    })
    return true
  } catch (error) {
    console.log('*modiftyUser', { error })
    return false
  }
}

// PUT /user/facturation
export function* modifyFacturation(api, { payload: _payload }) {
  try {
    const payload = _payload
    const stripeCustomerId = yield select(fromUsers.getStripeId)
    const authorization = yield select(fromUsers.getAuth)
    // NOTE if we receive an auth, we should reAuthenticate
    const result = yield call([api, api.put], '/user/facturation', {
      stripeCustomerId,
      payload,
    }, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    yield put({
      type: actions.RECEIVE_FACTURATION,
      payload: {
        data: result,
      },
    })
    return true
  } catch (error) {
    console.log('*modifyFacturation', { error })
    return false
  }
}

// GET /user/facturation
export function* getFacturation(api) {
  // AUDIT : on n'a plus besoin de ça depuis qu'on a un mode par entité non ?
  try {
    const stripeCustomerId = yield select(fromUsers.getStripeId)
    const authorization = yield select(fromUsers.getAuth)
    // NOTE if we receive an auth, we should reAuthenticate
    const result = yield call([api, api.get], '/user/facturation', {
      headers: {
        mode: 'cors',
        authorization,
        stripeCustomerId,
      },
    })
    yield put({
      type: actions.RECEIVE_FACTURATION,
      payload: {
        data: result,
      },
    })
    return true
  } catch (e) {
    console.log('error fetchedPaymentMethods', e)
    return false
  }
}

export function* deleteUserSession(api) {
  try {
    const authToken = yield select(fromUsers.getAuth)
    const refreshToken = yield select(fromUsers.getRefreshToken)
    yield call([api, api.post], '/user/logout', { authToken, refreshToken }, {
      headers: {
        mode: 'cors',
        authorization: authToken,
      },
    })
  } catch (e) {
    console.log('deleteUserSession failed', e)
  } finally {
    yield put({ type: actions.CLEAR_SESSION })
  }
}

export function* forceLogoutUser(api) {
  yield call(deleteUserSession, api)
  notification.open({
    message: 'Fin de session',
    description:
      'Votre session a expiré veuillez vous reconnecter',
    icon: <Icon type='smile' style={{ color: '#108ee9' }} />,
  })
}

export function* sendMail(api, action) {
  try {
    const authorization = yield select(fromUsers.getAuth)
    const { payload } = action
    const { email } = payload
    const data = { ...payload }
    const resent = yield call([api, api.post], '/mail', data, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    if (resent) {
      notification.success({
        message: (<FormattedMessage
          id='userCreation.resendMail.success'
          defaultMessage='Mail réenvoyé à {email}'
          values={{ email }}
        />),
        placement: 'topRight',
        duration: 8,
      })
    } else {
      notification.warning({
        message: (<FormattedMessage
          id='userCreation.resendMail.fail'
          defaultMessage="Le mail {email} n'a pas pu être réenvoyé"
          values={{ email }}
        />),
        placement: 'topRight',
        duration: 8,
      })
    }
    return true
  } catch (e) {
    console.log('sendMail - error', e, e.message)
    return false
  }
}

// eslint-disable-next-line func-names
export default function* ({ api }) {
  yield takeLeading(actions.LOGOUT, deleteUserSession, api)
  yield takeLeading(actions.FORCE_LOG_OUT, forceLogoutUser, api)
  yield takeLatest(actions.GET_NEW_TOKENS, refreshAuthToken, api)
  yield takeEvery(actions.REQUEST_TOKEN_FOR_PW_REINIT, reinitRequest, api)
  yield takeLatest(actions.REINIT_PW_AUTHENTICATE, reinitPasswordAuthenticate, api)
  yield takeEvery(actions.REINIT_PW, reinitPassword, api)
  yield takeEvery(actions.FETCH, authenticateUser, api)
  yield takeEvery(actions.SIGN_UP, createUser, api)
  yield takeEvery(actions.USER_ENTITY, createUserEntity, api)
  yield takeEvery(actions.MODIFY_USER_ENTITY, modifyUserEntity, api)
  yield takeEvery(actions.FETCH_FORCE_USER, fetchForceUserEntity, api)
  yield takeEvery(actions.FETCH_SHOP_USERS, fetchAllUsers, api)
  yield takeEvery(actions.MODIFY, modifyUser, api)
  yield takeEvery(actions.SEND_USER_MAIL, sendMail, api)
  yield takeEvery(actions.MODIFY_FACTURATION, modifyFacturation, api)
  yield takeEvery(productsActions.RECEIVED, getFacturation, api)

  // NOTE on app load, we emit the first event:
  // yield put({ type: actions.FETCH, payload: {} })
}

/* eslint-disable camelcase */
import React from 'react'
import {
  put,
  select,
  takeEvery,
  takeLatest,
  call,
  all,
} from 'redux-saga/effects'
import { notification, Icon } from 'antd'
import { FormattedMessage } from 'react-intl'
import { fromShops, fromUsers, fromOrders } from '../selectors'
import { fetchEntities } from '../entities/sagas'
import { noMarginTransactionAmount } from '../../helpers/orders'
import * as actions from './actions'

import theme from '../../config/theme'

export function* getOrders(api, { payload = {}, type }) {
  try {
    const shop = yield select(fromShops.getId)
    const auth = yield select(fromUsers.getAuth)
    const {
      page = 0,
      pageSize = 200,
    } = payload
    if (type !== actions.FETCH_MORE) yield put({ type: actions.FETCH })

    const { data, pagination } = yield call([api, api.get], '/order', {
      headers: {
        authorization: auth, shop, page, pageSize,
      },
    })

    if (type !== actions.FETCH_MORE) {
      yield put({ type: actions.RECEIVED, payload: { data, pagination } })
    } else {
      yield put({ type: actions.RECEIVED_MORE, payload: { data, pagination } })
    }
    if (pagination.hasMore) {
      yield put({
        type: actions.FETCH_MORE,
        payload: {
          page: page + 1,
          pageSize,
        },
      })
    }
    return true
  } catch (e) {
    console.log('getOrder Error', e)
    yield put({ type: 'ERROR', payload: { error_details: e.message } })
    return false
  }
}

export function* modifyOrder(api, action) {
  try {
    const {
      order_id,
      modifications,
    } = action.payload

    const authorization = yield select(fromUsers.getAuth)
    const order = yield select(fromOrders.getOrder, order_id)

    yield call([api, api.put], '/order', {
      _id: order_id,
      client: order.client,
      products: order.products,
      transaction: order.transaction,
      ...modifications,
    }, {
      headers: {
        authorization,
      },
    })
    return yield put({
      type: actions.MODIFIED,
      payload: {
        order_id,
        modifications,
      },
    })
  } catch (error) {
    console.log('*modifyOrder', { error })
    return false
  }
}

export function* sendBatchOrders(api, action) {
  try {
    const {
      orders,
      amount,
      bypass_transaction = false,
      stripe,
      intent,
      paymentMethod,
      transaction_type,
      history,
    } = action.payload
    yield put({
      type: actions.BATCH_LOADING,
      payload: {
        batchLoading: true,
      },
    })
    yield put(actions.setError({ batchError: false, errorDetails: null }))
    const authorization = yield select(fromUsers.getAuth)
    const batchPayload = {
      amount, orders, bypass_transaction, paymentMethod, intent, transaction_type,
    }
    const result = yield call([api, api.post], '/batchOrders', {
      ...batchPayload,
      bypass_transaction: transaction_type === 'admin' ? true : bypass_transaction,
    }, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })

    const description = (
      <FormattedMessage
        id='store.cart.sendMultiple'
        defaultMessage='Votre commande a bien été prise en compte !'
      />
    )

    if (result.status === 'success') {
      history.push('/orders')

      notification.open({
        message: (<FormattedMessage id='store.cart.orderValidated' defaultMessage='Commande validée !' />),
        description,
        duration: 0,
        icon: <Icon type='check-circle' style={{ color: theme.primaryColor[6] }} />,
      })
    }

    if (result.requires_action) {
      const { clientSecret } = result
      const handleCardAction = yield call(stripe.confirmCardPayment, clientSecret)
      console.log('handleCardAction', handleCardAction)
      if (handleCardAction.error) {
      // DISPATCH CHECKOUT ERROR
        yield put(actions.setError({ batchError: true, errorDetails: 'payment failed' }))
        return -1
      }
      const { paymentIntent } = handleCardAction
      const secondAttempt = yield call([api, api.post], '/batchOrders', {
        ...batchPayload, bypass_transaction: true, intent: paymentIntent.id, transaction_type: 'confirm',
      }, {
        headers: {
          mode: 'cors',
          authorization,
        },
      })
      if (secondAttempt.error) {
        const { errorDetails } = secondAttempt
        yield put(actions.setError({ error: true, errorDetails }))
        return -1
      }
      if (secondAttempt.status === 'success') {
        history.push('/orders')

        notification.open({
          message: (<FormattedMessage id='store.cart.orderValidated' defaultMessage='Commande validée !' />),
          description,
          duration: 0,
          icon: <Icon type='check-circle' style={{ color: theme.primaryColor[6] }} />,
        })
      }
    }

    yield put({ type: 'BATCH_LOADING', payload: { batchLoading: false } })
    yield put(actions.setError({ batchError: false, errorDetails: null }))

    return true
  } catch (e) {
    console.log('IN THE CATCH', e)
    yield put({ type: 'BATCH_LOADING', payload: { batchLoading: false } })
    yield put(actions.setError({ batchError: true, errorDetails: e.message }))

    return false
  }
}

export function* modifyGiftOrder(api, action) {
  try {
    const {
      order_id,
      stripe,
    } = action.payload

    const location = window.location.protocol.concat('//', window.location.host)
    const authorization = yield select(fromUsers.getAuth)
    const order = yield select(fromOrders.getOrder, order_id)
    const result = yield call([api, api.put], '/order', {
      _id: order_id,
      client: order.client,
      products: order.products,
      transaction: {
        ...order.transaction,
        validated: false,
        transaction_type: 'session',
        stripeSource: {
          success_url: `${
            location
          }/orders?customerOrderId=${
            order.customerOrderId
          }`,
          cancel_url: `${
            location
          }/orders?customerOrderId=${
            order.customerOrderId
          }`,
        },
        amount: noMarginTransactionAmount(order),
      },
      giftOrder: order.giftOrder || false,
    }, {
      headers: {
        authorization,
      },
    })
    if (result && result.action_required) {
      return stripe.redirectToCheckout({
        sessionId: result.session.id,
      })
    }
    return true
  } catch (error) {
    console.log('*modifyGiftOrder', { error })
    return false
  }
}

export function* cancelGiftOrder(api, action) {
  try {
    const {
      order_id,
    } = action.payload

    const authorization = yield select(fromUsers.getAuth)
    const order = yield select(fromOrders.getOrder, order_id)
    yield call([api, api.put], '/order', {
      _id: order_id,
      client: order.client,
      products: order.products,
      transaction: order.transaction,
      status: 'Cancelled',
    }, {
      headers: {
        authorization,
      },
    })
    return yield put({
      type: actions.CANCELLED_GIFT,
      payload: {
        order_id,
      },
    })
  } catch (error) {
    console.log('*cancelGiftOrder', { error })
    return false
  }
}

export function* addGiftToBilling(api, action) {
  try {
    const {
      order_id,
    } = action.payload
    const authorization = yield select(fromUsers.getAuth)
    const id = yield select(fromShops.getOwner)
    yield call([api, api.post], '/addGiftToBilling', {
      _id: order_id,
    }, {
      headers: {
        idOwner: String(id),
        authorization,
      },
    })
    // TODO upload tokens are here!
    return yield put({
      type: actions.GIFTED_TO_BILLING,
      payload: {
        order_id,
      },
    })
  } catch (error) {
    console.log('*modifyOrder', { error })
    return false
  }
}

export function* sendGroupedOrders(api, action) {
  try {
    const {
      selectedAddresses,
      callback,
      callbackFail,
    } = action.payload

    const authorization = yield select(fromUsers.getAuth)
    const shop = yield select(fromShops.getShop)
    const result = yield call([api, api.post], '/groupedOrders', {
      addresses: selectedAddresses,
      shop,
    }, {
      headers: {
        authorization,
      },
    })
    if (result?.success) callback()
    else callbackFail()
    return true
  } catch (error) {
    console.log('*sendGroupedOrders', { error })
    return false
  }
}
// on garde de mémoire on a en basoin pour subscribeExternalOrdersToShipup je sais pas pourquoi c'était pas appelé
// const formatOrders = (rows, lang) => rows.map((row) => ({
//   email: row.EMAIL,
//   tracking_number: row.TRACKING,
//   order_number: row.ID,
//   // FIXME
//   language_code: lang,
//   custom_variables: {
//     tracking_url: row.TRACKINGURL,
//   },
// }))

export function* subscribeExternalOrdersToShipup(api, action) {
  try {
    const { orders, lang } = action.payload
    const authorization = yield select(fromUsers.getAuth)
    yield call([api, api.post], '/shipupsubscribe',
      orders.map((o) => ({ ...o, language_code: lang })), {
        headers: {
          authorization,
        },
      })
    notification.open({
      message: 'Success',
      description: (<FormattedMessage
        id='store.orders.success'
        defaultMessage='Vos commandes sont bien abonnées sur shipup ! Vérifiez quand même on ne sait jamais !'
      />),
    })
    return 1
  } catch (e) {
    notification.error({
      message: 'Failed',
      description: (<FormattedMessage
        id='store.orders.failed'
        defaultMessage="Aïe aïe aïe , ça n'a pas fonctionné, vérifiez sur shipup on ne sait jamais !"
      />),
    })
    return -1
  }
}

export function* getOrdersPageData(api) {
  // TODO INIT FETCH DATA SHOULD SOULD PAGE ADN PAGESIZE AS PAYLOAD
  const page = 0
  const pageSize = 200
  yield all([
    call(fetchEntities, api),
    call(getOrders, api, { payload: { page, pageSize } }),
  ])

  yield put({ type: actions.END_FETCH_DATA })
}

// eslint-disable-next-line func-names
export default function* ({ api }) {
  yield takeLatest(actions.INIT_FETCH_DATA, getOrdersPageData, api)
  // yield takeEvery(actions.FETCH_FORCE, getOrders, api)
  // HACK, on evite les dispatch parallèle sur le fetchMore
  yield takeLatest(actions.FETCH_MORE, getOrders, api)
  yield takeEvery(actions.MODIFY, modifyOrder, api)
  yield takeEvery(actions.MODIFY_GIFT, modifyGiftOrder, api)
  yield takeEvery(actions.CANCEL_GIFT, cancelGiftOrder, api)
  yield takeEvery(actions.GIFT_TO_BILLING, addGiftToBilling, api)
  yield takeEvery(actions.SEND_MANY_ORDERS, sendBatchOrders, api)
  yield takeEvery(actions.SEND_GROUPED_ORDERS, sendGroupedOrders, api)
  yield takeEvery(actions.SHIPUP_SUBSCRIBE, subscribeExternalOrdersToShipup, api)
}

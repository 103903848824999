export const initialState = {
  loading: false,
  error: false,
  data: [],
  apiKey: '',
}

export const loading = (state = initialState) => state.loading

export const error = (state = initialState) => state.error

export const getApiKeys = (state = initialState) => state.data.reverse() || []

// Drawer de création de nouvelle clé api
export const getApiKeyCreated = (state = initialState) => state.apiKey

import React from 'react'
import { flatten } from 'lodash'
import { PieChart, Pie, Sector } from 'recharts'

import themeColors from '../../../config/theme'

export default class PriceChart extends React.Component {
  constructor(props) {
    super(props)
    const { data } = props
    this.state = {
      data: [Object.keys(data).map((color) => ({
        value: Object.keys(data[color]).filter((k) => k !== 'color').reduce((a, k) => a + data[color][k], 0),
        name: color,
        color: data[color].color,
      })), flatten(Object.keys(data).map((color) => Object.keys(data[color]).filter((s) => s !== 'color').map((size) => ({
        name: size,
        value: data[color][size],
        color: data[color].color,
      }))))],
    }
  }

  renderActiveShape = (incoming) => {
    try {
      const RADIAN = Math.PI / 180
      const {
        cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, value, outterPie,
      } = incoming
      const sin = Math.sin(-RADIAN * midAngle)
      const cos = Math.cos(-RADIAN * midAngle)
      const sx = cx + (outerRadius + 10) * cos
      const sy = cy + (outerRadius + 10) * sin
      const mx = cx + (outerRadius + 30) * cos
      const my = cy + (outerRadius + 30) * sin
      const ex = mx + (cos >= 0 ? 1 : -1) * 22
      const ey = my
      const textAnchor = cos >= 0 ? 'start' : 'end'
      const { currentSizeValue } = this.state
      return (
        <g>
          {outterPie ? null : <text x={cx} y={cy} textAnchor='middle' fill={fill}>{`${value}`}</text>}
          {outterPie ? null : <text x={cx} y={cy} dy={14} textAnchor='middle' fill={fill}>{`${currentSizeValue}`}</text>}
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={outterPie ? fill : payload.color.value}
          />
          {outterPie ? (
            <>
              <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
              />
              <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
              <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
              <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor}>
                {`${payload.color.name}`}
              </text>
              <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor}>
                {`${payload.name}`}
              </text>
            </>
          ) : null}
        </g>
      )
    } catch (error) {
      console.log('breakdownChart renderActiveShape', { error })
      return null
    }
  }

  render() {
    try {
      const { innerActive, outterActive, data } = this.state
      const { height = 400, width = 600 } = this.props
      return (
        <PieChart
          // FIXME we cannot not have width & height but it fucks the responsiveness
          width={width}
          height={height}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          wrapperStyle={{
            margin: '0',
          }}
        >
          <Pie
            activeIndex={innerActive}
            activeShape={this.renderActiveShape}
            data={data[0]}
            cx={width / 2}
            cy={height / 2}
            innerRadius={40}
            outerRadius={60}
            fill={themeColors.primaryColor[6]}
            dataKey='value'
          />
          <Pie
            activeIndex={outterActive}
            activeShape={(stuff) => this.renderActiveShape({
              ...stuff,
              outterPie: true,
            })}
            data={data[1]}
            cx={width / 2}
            cy={height / 2}
            innerRadius={70}
            outerRadius={90}
            fill={themeColors.primaryColor[6]}
            dataKey='value'
            onMouseEnter={(d, index) => {
              this.setState({
                outterActive: index,
                innerActive: data[0].findIndex((v) => v.color.name === d.payload.payload.color.name),
                currentSizeValue: d.value,
              })
            }}
          />
        </PieChart>
      )
    } catch (error) {
      console.log('breakdownChart render', { error })
      return null
    }
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { uniq, flatten } from 'lodash'

import {
  Icon,
  Tabs,
  Spin,
  Button,
  Alert,
  Typography,
} from 'antd'

import {
  fromUsers,
  fromProducts,
  fromBaseProducts,
  fromShops,
  fromEntities,
  fromMembers,
  fromLocale,
  fromStocks,
  fromSuppliers,
  fromProviders,
} from '../../store/selectors'

import {
  Info, ProductEditionTab, ProductMoreInfosTab, ProductStockTab, Loader,
} from '../../components'
import { MODIFY } from '../../store/products/actions'
import { MODIFY_STOCK } from '../../store/stocks/actions'

import { getEntityId, getUserEntityStatus } from '../../helpers/entities'
import { decodeQuery } from '../../helpers/filters'

import { OrderBreakDown } from '..'

const { TabPane } = Tabs
const { Paragraph } = Typography

/**
 * Affiche un loader en attendant le produit
 * @returns JSX.Element
 */
const ProductLoader = () => (
  <div style={{
    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%',
  }}
  >
    <Spin indicator={<Icon type='loading' style={{ fontSize: 24, marginBottom: '50px' }} spin />} />
    <Alert
      message={(
        <FormattedMessage
          id='product.loading.message'
          defaultMessage='Recherche du produit...'
        />
      )}
      description={(
        <FormattedMessage
          id='product.loading.description'
          defaultMessage="Si l'identifiant du produit dans l'url n'est pas valide, veuillez vous rediriger vers la page 'Produits'."
        />
      )}
      type='warning'
    />
  </div>
)

/**
 * Container global de la page du produit
 *
 * @param {*} props
 * @returns JSX.Element
 */
const ProductContainer = (props) => {
  try {
    const {
      user,
      shop,
      history,
      allProducts,
      allBaseProducts,
      allStocks,
      allProviders,
      entities,
      members,
      membersGroups,
      modifyProduct,
      modifyStock,
      suppliers,
      locale,
      isBaseProductsLoading,
      isProductsLoading,
    } = props

    // Récupération des informations contenues dans l'URL
    const query = decodeQuery(history.location.search)
    // Récupération du produit sélectionné de son baseProduct en db
    const productId = query.id
    const product = allProducts?.find((_product) => _product._id === productId)
    const baseProduct = allBaseProducts?.find((bp) => bp._id === product?.supplierBaseProductId)
    // Récupération des collections des produits du
    const allCollections = uniq(flatten(allProducts.map((p) => p.collections)).filter(Boolean))
    // Récupération des mails des alertes de stocks de tous les produits du shop (pour les options du sélecteur)
    const alertees = uniq(flatten(allProducts?.map((_product) => _product.stockAlertees || [])))
    // Récupération de l'onglet actif depuis l'URL
    const tabFromURL = query.tab || 'informations'

    const { entityId } = getEntityId(user, entities, shop)
    const userStatus = getUserEntityStatus(user, shop)
    const allowedToModify = userStatus !== 'collaborator'

    const allEmbededBPs = allProducts.map((p) => p.baseProduct)

    const name = product?.name || ''
    if (isProductsLoading || isBaseProductsLoading) {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
        >
          <Loader />
        </div>
      )
    }
    return (
      <div style={{ height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 15 }}>
          <Button
            onClick={() => history.push('/products')}
          >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Icon type='left' style={{ paddingRight: 7 }} />
              <FormattedMessage id='previous' defaultMessage='Précédent' />
            </div>
          </Button>
          <h2 style={{ fontWeight: 'bold', margin: '10px' }}>{name}</h2>
        </div>
        {product ? (
          <div style={{ padding: '0 15px 15px 15px' }}>
            <Tabs
              defaultActiveKey={tabFromURL}
              tabBarStyle={{ borderBottom: 0, height: '100%' }}
              onChange={(activeKey) => history.push(`/product?id=${product._id}&tab=${activeKey}`)}
            >
              <TabPane
                key='informations'
                tab={(
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    <FormattedMessage
                      id='product.tabs.informations'
                      defaultMessage='Informations générales'
                    />
                  </span>
                )}
              >
                <ProductEditionTab
                  product={product}
                  allCollections={allCollections}
                  allEmbededBPs={allEmbededBPs}
                  membersGroups={membersGroups}
                  members={members}
                  modifyProduct={modifyProduct}
                  allowedToModify={allowedToModify}
                />
              </TabPane>
              <TabPane
                key='baseProduct'
                tab={(
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    <FormattedMessage
                      id='product.tabs.moreInfos'
                      defaultMessage='Informations complémentaires'
                    />
                    <Info
                      title={(
                        <FormattedMessage
                          id='product.tabs.moreInfos'
                          defaultMessage='Informations complémentaires'
                        />
                      )}
                      content={(
                        <Paragraph style={{ width: '25vw' }}>
                          <FormattedMessage
                            id='product.tabs.moreInfos.helper'
                            defaultMessage='Les éléments de cet onglet sont l’ensemble des informations sur ce produit
                            issues de notre catalogue Panopli. Vous avez la possibilité de reprendre ces informations
                            pour compléter votre fiche produit.'
                          />
                        </Paragraph>
                      )}
                    />
                  </span>
                )}
              >
                <ProductMoreInfosTab
                  product={product}
                  baseProduct={baseProduct}
                  modifyProduct={modifyProduct}
                  shop={shop}
                  products={allProducts}
                  suppliers={suppliers}
                  allStocks={allStocks}
                  allProviders={allProviders}
                  locale={locale}
                />
              </TabPane>
              <TabPane
                key='stocks'
                tab={(
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    <FormattedMessage
                      id='product.tabs.stocks'
                      defaultMessage='Stocks'
                    />
                  </span>
                )}
              >
                <ProductStockTab
                  product={product}
                  shop={shop}
                  user={user}
                  allStocks={allStocks}
                  entities={entities}
                  entityId={entityId}
                  alertees={alertees}
                  modifyProduct={modifyProduct}
                  modifyStock={modifyStock}
                  allowedToModify={allowedToModify}
                />
              </TabPane>
              <TabPane
                key='statistics'
                tab={(
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    <FormattedMessage
                      id='product.tabs.statistics'
                      defaultMessage='Statistiques'
                    />
                  </span>
                )}
              >
                <OrderBreakDown product={product} />
              </TabPane>
            </Tabs>
          </div>
        ) : <ProductLoader />}
      </div>
    )
  } catch (e) {
    console.log('containers/ProductContainer error:', e)
    return null
  }
}

export default connect((state) => ({
  shop: fromShops.getShop(state),
  user: fromUsers.getUser(state),
  allProducts: fromProducts.allProducts(state),
  isProductsLoading: fromProducts.loading(state),
  isBaseProductsLoading: fromBaseProducts.loading(state),
  allBaseProducts: fromBaseProducts.getBPs(state),
  allStocks: fromStocks.getStocks(state),
  allProviders: fromProviders.getProviders(state),
  entities: fromEntities.getEntities(state),
  members: fromMembers.getMembers(state),
  membersGroups: fromShops.shopMembersGroups(state),
  suppliers: fromSuppliers.getSuppliers(state),
  locale: fromLocale.getLocale(state),
}), (dispatch) => bindActionCreators({
  // eslint-disable-next-line camelcase
  modifyProduct: (product_id, modifications, fileHashes, files) => ({
    type: MODIFY,
    payload: {
      product_id,
      modifications,
      fileHashes,
      files,
    },
  }),
  modifyStock: (target, modifications) => ({
    type: MODIFY_STOCK,
    payload: {
      target,
      modifications,
    },
  }),
}, dispatch))(ProductContainer)

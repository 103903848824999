import {
  put,
  call,
  takeEvery,
  select,
  all,
  takeLatest,
} from 'redux-saga/effects'
import { fromUsers, fromShops } from '../selectors'

import * as invoicesActions from './actions'

export function* getInvoices(api) {
  try {
    const authorization = yield select(fromUsers.getAuth)
    const shopId = yield select(fromShops.getId)
    // NOTE if we receive an auth, we should reAuthenticate
    const result = yield call([api, api.get], '/invoice', {
      headers: {
        mode: 'cors',
        authorization,
        shopId,
      },
    })
    yield put({
      type: invoicesActions.RECEIVED,
      payload: {
        invoices: result.invoices,
      },
    })
    return true
  } catch (e) {
    console.log('getInvoices - error', e, e.message)
    return false
  }
}

export function* getDataForInvoicesPage(api) {
  yield all([
    call(getInvoices, api),
  ])
  yield put({ type: invoicesActions.END_FETCH_DATA })
}

export default function* ({ api }) {
  yield takeLatest(invoicesActions.INIT_FETCH_DATA, getDataForInvoicesPage, api)
  yield takeEvery(invoicesActions.FETCH_FORCE, getInvoices, api)
}

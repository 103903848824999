import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import {
  Table,
  Spin,
  Tag,
  Icon,
  Divider,
  Popconfirm,
  message,
  Checkbox,
  Popover,
  Radio,
  Select,
} from 'antd'

import { RatioMembership } from '../../components'

import { GET_SHOP, MODIFY } from '../../store/shops/actions'
import { DELETE_MEMBER, SEND_MAIL, MODIFY_MEMBERS_RATIO } from '../../store/members/actions'

import {
  fromMembers, fromShops, fromEntities, fromUsers,
} from '../../store/selectors'
import Placeholder from '../../components/organisms/Placeholder'
import { getColumnSearchProps, filterData } from '../../helpers/filters'
import { getFormattedMembers } from '../../store/globalSelectors'

const { Option } = Select

const initialState = {
  membersFiltered: [],
  pageSize: 40,
}

const Members = (props) => {
  const [membersPageState, setMembersPageState] = useState(initialState)
  const {
    onSelectChange,
    loading,
    error,
    allMembers,
    displayDrawer,
    deleteMember,
    selectedRowKeys,
    shop: {
      settings: {
        membersGroups = [],
      },
    },
    displayDrawerEdit,
    sendMail,
    modifyShop,
    shop,
    modifyMembersRatio,
    filtering,
    filters,
    entities,
    onAddCreditIconClick,
  } = props
  const { membersFiltered, pageSize } = membersPageState

  const handleChange = (data) => {
    const selectedData = membersFiltered.length
      ? membersFiltered.filter((member) => data.includes(member._id))
      : allMembers.filter((member) => data.includes(member._id))
    onSelectChange(selectedData)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: handleChange,
    hideDefaultSelections: true,
    selections: [
      {
        key: 'all-data',
        text: <FormattedMessage id='selectAll' defaultMessage='Tout sélectionner' />,
        onSelect: () => {
          handleChange(membersFiltered.length
            ? membersFiltered.map((member) => member._id)
            : allMembers.map((member) => member._id))
        },
      },
      {
        key: 'reset-data',
        text: <FormattedMessage id='deselectAll' defaultMessage='Tout désélectionner' />,
        onSelect: () => {
          handleChange([])
        },
      },
    ],
  }

  const columns = [
    {
      title: <FormattedMessage id='entity' defaultMessage='Entité' />,
      key: 'entity',
      dataIndex: 'formatted.entity',
      ...getColumnSearchProps('entity', filtering, {
        filterDropdown: () => (
          <div
            className='orderId-filter-dropdown container'
            style={{
              textAlign: 'center',
              padding: '5px',
            }}
          >
            <Select
              onChange={(value) => {
                filtering({ entityId: value })
              }}
              style={{ width: 200 }}
              placeholder={<FormattedMessage id='entity.select' defaultMessage='Sélectionnez une entité' />}
              allowClear
            >
              {entities.map((entity) => (
                <Option key={entity._id} value={entity._id}>
                  {entity.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
      }),
    },
    {
      title: <FormattedMessage id='firstName' defaultMessage='Prénom' />,
      key: 'name',
      dataIndex: 'formatted.name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps('name', filtering),
    },
    {
      title: <FormattedMessage id='lastName' defaultMessage='Nom' />,
      key: 'lastName',
      dataIndex: 'formatted.lastName',
      filterIcon: <Icon type='search' />,
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      ...getColumnSearchProps('lastName', filtering),
    },
    {
      title: <FormattedMessage id='email' defaultMessage='Email' />,
      dataIndex: 'email',
      key: 'formatted.email',
      filterIcon: <Icon type='search' />,
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...getColumnSearchProps('email', filtering),
    },
    {
      title: <FormattedMessage id='group' defaultMessage='Groupe' />,
      dataIndex: 'groups',
      key: 'groups',
      ...getColumnSearchProps('groups', filtering, {
        render: (groups) => {
          try {
            const _groups = groups.map((groupName) => membersGroups
              .find((_group) => _group.name === groupName))
            return (
              <span>
                {_groups.map((group) => (
                  <Tag color={group.color} key={Math.random()} style={{ marginBottom: '4px' }}>
                    {group.name}
                  </Tag>
                ))}
              </span>
            )
          } catch (e) {
            console.log('err containers/Members/groups', e)
            return false
          }
        },
      }),
    },
    {
      title: <FormattedMessage id='credit' defaultMessage='Crédit' />,
      key: 'credit',
      render: (member) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            type='plus-circle'
            style={{ cursor: 'pointer', fontSize: '15px', marginRight: 10 }}
            onClick={() => {
              onAddCreditIconClick(member)
            }}
          />
          {member.credit}
        </div>
      ),
    },
    {
      title: <FormattedMessage id='freeDelivery' defaultMessage='Livraison gratuite' />,
      key: 'freeDelivery',
      dataIndex: 'formatted.freeDelivery',
      ...getColumnSearchProps('freeDelivery', filtering, {
        filterDropdown: () => {
          const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
          }
          return (
            <Radio.Group style={{ padding: 20 }} onChange={(e) => filtering({ freeDelivery: e.target.value })}>
              <Radio style={radioStyle} value='gratuite'>
                <FormattedMessage id='freeDelivery' defaultMessage='Livraison gratuite' />
              </Radio>
              <Radio value='payante'>
                <FormattedMessage id='paidDelivery' defaultMessage='Livraison payante' />
              </Radio>
            </Radio.Group>
          )
        },
        render: (value) => <Checkbox checked={value === 'gratuite'} />,
      }),
    },
    {
      title: <FormattedMessage id='infos' defaultMessage='Informations' />,
      key: 'formatted.informations',
      filters: [{
        text: <FormattedMessage id='mail.send' defaultMessage='Email envoyé' />,
        value: 'Email envoyé' || 'Email sent',
      },
      {
        text: <FormattedMessage id='upToDate' defaultMessage='À jour' />,
        value: 'À jour' || 'Up to date',
      }],
      onFilter: (value, member) => member.informations.includes(value),
      render: (member) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Popover content={<FormattedMessage id='member.resendMail' defaultMessage='Renvoyer le mail' />}>
            <Icon
              type='reload'
              style={{ cursor: 'pointer', fontSize: '15px', marginRight: 10 }}
              onClick={() => sendMail(member)}
            />
          </Popover>
          {member.formatted.informations}
        </div>
      ),
    },
    {
      key: 'action',
      render: (member) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Popconfirm
            title={<FormattedMessage id='popConfirm.memberDeletion' defaultMessage='Êtes-vous sûr de supprimer ce membre ?' />}
            onConfirm={() => deleteMember(member)}
            okText={<FormattedMessage id='yes' defaultMessage='Oui' />}
            cancelText={<FormattedMessage id='no' defaultMessage='Non' />}
          >
            <Icon
              type='delete'
              style={{ cursor: 'pointer' }}
            />
          </Popconfirm>
          <Divider type='vertical' />
          <Icon
            type='edit'
            style={{ cursor: 'pointer', fontSize: '15px' }}
            onClick={() => displayDrawerEdit(member)}
          />
          <Divider type='vertical' />
          <Icon
            type='info-circle'
            style={{ cursor: 'pointer', fontSize: '15px' }}
            onClick={() => displayDrawer(member)}
          />
        </div>
      ),
    },
  ]

  const filteredMembers = useMemo(() => filterData(allMembers, filters, {
    entityId: (value, record) => record.entityId && record.entityId === value,
  }), [allMembers.length, filters])

  if (error) return <div>Error placeholder</div>
  if (loading) return <Spin size='large' />

  return (
    <div>
      <RatioMembership
        modifyShop={modifyShop}
        shop={shop}
        display={allMembers.length || shop?.settings?.membership?.currency?.creditConversion}
        page='listing'
        modifyMembersRatio={modifyMembersRatio}
        allMembers={allMembers}
      />
      {allMembers.length > 0 ? (
        <Table
          rowKey={(record) => record._id}
          rowSelection={rowSelection}
          dataSource={filteredMembers}
          columns={columns}
          pagination={{
            showQuickJumper: true,
            pageSize,
            showSizeChanger: true,
            onShowSizeChange: (current, _pageSize) => setMembersPageState({
              ...membersPageState,
              pageSize: _pageSize,
            }),
            pageSizeOptions: [20, 40, 160],
          }}
        />
      ) : (
        <Placeholder />
      )}
    </div>
  )
}

export default connect(
  (state) => ({
    allMembers: getFormattedMembers(state),
    loading: fromMembers.loading(state),
    error: fromMembers.error(state),
    shop: fromShops.getShop(state),
    user: fromUsers.getUser(state),
    entities: fromEntities.getEntities(state),
  }), (dispatch) => bindActionCreators({
    deleteMember: (member) => ({
      type: DELETE_MEMBER,
      member,
      callback: message.info(<FormattedMessage id='deletedMember' defaultMessage='Membre supprimé' />),
    }),
    sendMail: (member) => ({
      type: SEND_MAIL,
      member,
    }),
    fetchShop: () => ({
      type: GET_SHOP,
    }),
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
    modifyMembersRatio: (payload) => ({
      type: MODIFY_MEMBERS_RATIO,
      payload,
    }),
  }, dispatch),
)(Members)

export const initialState = {
  loading: false,
  error: false,
  data: [],
}

export const getStocks = (state = initialState) => {
  try {
    return state.data
  } catch (e) {
    console.log('getStocks - error', e)
    return []
  }
}

/**
 * @param {object} state le state redux
 * @param {string} productId l'id d'un produit
 * @param {string} entityId  l'id d'une entité
 * @returns {Array.<object>} Les stocks qui concerne ce produit et cette entité
 */
export const getSpecificStock = (state = initialState, productId, entityId) => {
  const stocks = getStocks(state).filter((stock) => {
    const { productId: productIdFromStock, entityId: entityFromStock } = stock
    return productId === productIdFromStock && entityId === entityFromStock
  })
  return stocks
}

export const getStock = (state = initialState, stockId) => {
  try {
    return state.data.find((stock) => stock._id === stockId)
  } catch (e) {
    console.log('getStock - error', e)
    return []
  }
}

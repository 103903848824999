import React from 'react'
import { Avatar, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  character, tertiaryColor, white,
} from '../../../config/theme'

const DataBlock = (data) => {
  const { title = '', value = 'N/C', icon = null } = data
  return (
    <div style={{
      padding: '12px',
      background: white,
      boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.03)',
      flexDirection: 'row',
      alignItems: 'flex-start',
      borderRadius: '16px',
      display: 'flex',
      height: '68px',
      width: '100%',
      gap: '16px',
    }}
    >
      <Avatar
        shape='square'
        icon={<FontAwesomeIcon size='xs' icon={icon} />}
        size={44}
        style={{
          backgroundColor: tertiaryColor[1],
          color: tertiaryColor[7],
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: 0 }}>
        <Tooltip title={title}>
          <h3 style={{
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '20px',
            color: character.secondary,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          >
            {title}
          </h3>
        </Tooltip>
        <h1 style={{
          fontSize: '16px', fontWeight: '600', lineHeight: '24px', color: character.title,
        }}
        >
          {value}
        </h1>
      </div>
    </div>
  )
}

export default DataBlock

import React from 'react'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { CampaignCardButton } from '../..'

const CampaignCardSendButton = (props) => {
  const {
    icon = faPaperPlane,
    onClick, // Required
    tooltipFormattedMessage = <FormattedMessage id='magicLink.send' defaultMessage='Envoyer des Magic Links' />,
  } = props

  return (
    <CampaignCardButton
      onClick={onClick}
      icon={icon}
      tooltipFormattedMessage={tooltipFormattedMessage}
    />
  )
}

export default CampaignCardSendButton

export const GET = 'GET_ENTITIES'
export const RECEIVED = 'RECEIVED_ENTITES'
export const GET_ALL = 'GET_ENTITIES_ALL'
export const RECEIVED_ALL = 'RECEIVED_ENTITES_ALL'
export const CREATE_ENTITY = 'CREATE_ENTITY'
export const MODIFY_ENTITY = 'MODIFY_ENTITY'
export const FETCH_FORCE = 'FETCH_FORCE'
export const LOADING = 'LOADING_ENTITIES'
export const SET_STRIPE = 'SET_STRIPE'
export const ERROR = 'ERROR_ENTITIES'
export const INIT_FETCH_DATA = 'INIT_FETCH_DATA_ENTITIES'
export const END_FETCH_DATA = 'END_FETCH_DATA'

import React from 'react'
import { List, Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { RecapItem, CartPrice } from '../..'

export default ({
  cartContent,
  allProducts,
  remove,
  manageCustomizations,
  changeCartCountry,
  shop,
  suppliers,
  cartPrice,
  deliveryPrice,
  deliveryError,
  productsPrice,
  hideCountrySelector,
  empty,
  onClose,
  history,
  showButton,
  location,
  locale = 'fr',
}) => {
  const displayButton = () => {
    if (showButton) {
      return (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%',
          }}
        >
          <Button
            onClick={() => {
              empty()
              onClose()
            }}
            style={{
              borderRadius: 0,
              width: '50%',
            }}
          >
            <FormattedMessage id='empty' defaultMessage='Vider' />
          </Button>
          <Button
            type='primary'
            // TODO disabled if ! custo for ex
            disabled={false}
            onClick={() => {
              history.push('/checkout')
            }}
            style={{
              width: '50%',
              borderRadius: 0,
            }}
          >
            <FormattedMessage id='ordering' defaultMessage='Commander' />
          </Button>
        </div>
      )
    } return null
  }

  const height = showButton ? 'calc(100vh - 205px)' : '75vh'
  return (
    <div>
      <List
        itemLayout='horizontal'
        dataSource={cartContent}
        style={{
          height,
          overflowY: 'scroll',
        }}
        renderItem={(p) => (
          <RecapItem
            product={p}
            canonicalProduct={allProducts
              .find((product) => String(product._id) === String(p._id || p.id))}
            allProducts={allProducts}
            remove={() => { remove(p.cart_id) }}
            manageCustomizations={manageCustomizations}
            key={String(p.cart_id)}
            showButton={showButton}
            location={location}
          />
        )}
      />
      <CartPrice
        products={allProducts}
        suppliers={suppliers}
        hideCountrySelector={hideCountrySelector}
        shop={shop}
        cartContent={cartContent}
        changeCartCountry={changeCartCountry}
        cartPrice={cartPrice}
        productsPrice={productsPrice}
        deliveryPrice={deliveryPrice}
        deliveryError={deliveryError}
        locale={locale}
      />
      {displayButton()}
    </div>
  )
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { MagicLinkSelectFilter } from '../..'

const MagicLinkCampaignFilter = (props) => {
  const {
    setFilters, // Required
    filters, // Required
    options, // Required
  } = props

  return (
    <MagicLinkSelectFilter
      options={options}
      title={<FormattedMessage id='campaigns' defaultMessage='Campagnes' />}
      onChange={(value) => setFilters({ ...filters, campaignsIds: value })}
      placeholder={<FormattedMessage id='magicLinks.dashboard.allCampaigns' defaultMessage='Toutes les campagnes' />}
      onResetFilter={() => setFilters({ ...filters, campaignsIds: [] })}
      selectedValues={filters?.campaignsIds}
    />
  )
}

export default MagicLinkCampaignFilter

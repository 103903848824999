/* eslint-disable camelcase */
import React from 'react'
import ProductImage from '@balibart/product-image'
import { uniqBy, times, isEqual } from 'lodash'
import { FormattedMessage } from 'react-intl'
import {
  Button, Drawer, Row, Select, Typography, Tag, Popconfirm,
} from 'antd'
import { ColorSpan, Info, MultipleSize } from '../..'
import { CartContainer as CartDrawer } from '../../../containers'
import config from '../../../config'
import { getEntityId, getUserEntityStatus } from '../../../helpers/entities'

const { image_base_url: imageBaseUrl } = config

const { Title, Paragraph } = Typography
const { Option } = Select

/**
 * Permet l'initialisation du drawer
 * @param {*} user l'utilisateur connecté
 * @param {*} entities  les entités récupérés depuis l'api
 * @param {*} shop le shop associé à l'utilisateur
 * @returns l'entité dont fait parti l'utilisateur ( l'entié principale si c'est le super admin )
 */
const getEntityIdForStateInitialisation = (user, entities, shop) => {
  if (user._id === shop.owner || user.admin) {
    return entities.find((e) => e.main)
  } if (user.adminCollaborator || user.collaborator) {
    return entities.find((entity) => {
      const { adminCollaborators = [], collaborators = [] } = entity
      return adminCollaborators.includes(user._id) || collaborators.includes(user._id)
    })
  }
  throw new Error('cannot initialize entity state in order drawer')
}

export default class OrderDrawer extends React.Component {
  initialState = {
    embededCartVisible: false,
    color: {},
    sizes: [],
    entity: {},
    visiblePopconfirmEntity: false,
  }

  constructor(props) {
    super(props)
    const {
      product, entities, user, shop,
    } = props
    const color = product?.variations?.find((v) => v.type === 'color') || {}
    this.state = {
      ...this.inialState,
      entity: getEntityIdForStateInitialisation(user, entities, shop),
      color,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      product, shop, entities, user,
    } = this.props
    const { color = {} } = this.state
    if (prevState.color.reference !== color.reference) {
      this.resetSizes()
    } else if (prevProps.product._id !== product._id) {
      const productColor = product?.variations?.find((v) => v.type === 'color') || {}
      this.setState({ color: productColor })
    }
    // Si on a changé de shop, il faut mettre à jour le state du composant
    if (String(shop?._id) !== String(prevProps?.shop?._id) || !isEqual(prevProps?.entities, entities)) {
      this.setState({ entity: getEntityIdForStateInitialisation(user, entities, shop) })
    }
  }

  getColorsOptions = () => {
    const { product } = this.props
    try {
      return uniqBy(product.variations.filter((v) => v.type === 'color'), 'reference').map((c) => (
        <Option
          key={c.reference}
          value={c.reference}
        >
          <ColorSpan color={c} />
        </Option>
      ))
    } catch (error) {
      return []
    }
  }

  setColor = (color) => {
    this.setState(() => ({ color }))
  }

  resetSizes = () => this.setState(() => ({ sizes: [] }))

  setSize = (s) => {
    const { product } = this.props
    const size = product.variations.find((v) => v.type === 'size' && v.reference === s.size)
    const { quantity } = s
    this.setState((state) => {
      const isPresent = state.sizes.find((si) => si.size.reference === s.size)
      const hasValue = !!s.quantity
      let newSizes
      if (isPresent && !hasValue) {
        newSizes = state.sizes.filter((si) => si.size.reference !== s.size)
      } else if (isPresent && hasValue) {
        newSizes = state.sizes.map((si) => {
          if (si.size.reference === s.size) {
            return { ...si, quantity }
          }
          return si
        })
      } else {
        newSizes = state.sizes.concat([{ size, quantity }])
      }
      return { sizes: newSizes }
    })
  }

  isValid = () => {
    try {
      const { cart } = this.props
      // console.log('CART', cart)
      if (!cart || !cart.content || !cart.content.length) {
        return { valid: false, reason: 'no products' }
      }
      const test = cart.content.map((prod) => {
        if (prod.clientCustomization) {
          const check = prod && prod.temporary_clientCustomizations
            && prod.temporary_clientCustomizations.length === prod.clientCustomization.length
          prod.temporary_clientCustomizations.map((custo) => custo.values.length
            === prod.quantity)
          if (check && check.length && (check.filter(Boolean).length !== check.length)) {
            return { valid: false, reason: 'missing custmizations' }
          }
        }
        return { valid: true }
      })
      return { valid: test.filter((r) => r.valid).length === test.length }
    } catch (e) {
      console.log('isValid', e)
      return { valid: false, reason: 'catched' }
    }
  }

  resetState = () => {
    const { product } = this.props
    const { entity } = this.state
    const color = product && product.variations ? product.variations.find((v) => v.type === 'color') : {}
    this.setState({
      ...this.initialState,
      color: color || {},
      entity,
    })
  }

  addProduct = (entityId) => {
    const {
      product,
      add,
      allBaseProducts,
    } = this.props
    const { color, sizes, entity } = this.state
    const formattedProducts = sizes.map((v) => {
      if (product.clientCustomization && product.clientCustomization.length) {
        return ({
          id: product._id,
          _id: product._id,
          entityId,
          variations: {
            color,
            size: v.size,
          },
          name: product.name,
          quantity: v.quantity,
          unitPrice: allBaseProducts
            .find((bp) => bp._id === product.supplierBaseProductId)
            && allBaseProducts
              .find((bp) => bp._id === product.supplierBaseProductId).unitPrice
            ? allBaseProducts
              .find((bp) => bp._id === product.supplierBaseProductId).unitPrice : 0,
          clientCustomization: times(v.quantity, () => product.clientCustomization),
        })
      } return ({
        id: product._id,
        _id: product._id,
        entityId: (entity && entity._id) || entityId,
        variations: {
          color,
          size: v.size,
        },
        name: product.name,
        quantity: v.quantity,
        unitPrice: allBaseProducts
          .find((bp) => bp._id === product.supplierBaseProductId)
          && allBaseProducts
            .find((bp) => bp._id === product.supplierBaseProductId).unitPrice
          ? allBaseProducts
            .find((bp) => bp._id === product.supplierBaseProductId).unitPrice : 0,
      })
    })
    add(formattedProducts)
    this.resetState()
    this.setState({
      embededCartVisible: true,
    })
    return false
  }

  render() {
    const {
      visible,
      onCancel,
      product = {},
      stocks,
      entities,
      user,
      cartContent,
      shop,
      emptyCart,
      locale,
    } = this.props
    const status = getUserEntityStatus(user, shop)
    const { entityId } = getEntityId(user, entities, shop)
    const {
      color, sizes = [], embededCartVisible, entity, visiblePopconfirmEntity,
    } = this.state
    return (
      <Drawer
        visible={visible}
        title={(
          <div style={{ paddingRight: '20px', overflowWrap: 'anywhere' }}>
            <FormattedMessage id='ordering' defaultMessage='Commander' />
            {' - '}
            {product.name}
          </div>
        )}
        onClose={onCancel}
        width='50vw'
        bodyStyle={{
          height: '80vh',
        }}
      >

        <span style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
        >

          <div style={{
            marginTop: '5px',
            width: '50%',
          }}
          >
            {product ? (
              <ProductImage
                base_url={imageBaseUrl}
                product={product}
                variation={color}
                // baseProduct={product.baseProduct}
                currentPrintingZone={
                  product.favPz || 'front'
                }
              />
            ) : null}
            {product.clientCustomization && product.clientCustomization.length ? (
              <Row>
                <Tag style={{ margin: '0 auto' }}>
                  <FormattedMessage id='productOrderingDrawer.helper1' defaultMessage='Ce produit est customizable' />
                  <span role='img' aria-label='paintbrush'>🎨</span>
                </Tag>
                {' '}
                <Info
                  title={(
                    <span>
                      <FormattedMessage
                        id='productOrderingDrawer.customizableProduct'
                        defaultMessage='Produit customizable'
                      />
                      <span role='img' aria-label='paintbrush'>🎨</span>
                    </span>
                  )}
                  content={(
                    <Paragraph style={{ width: '25vw' }}>
                      <FormattedMessage
                        id='productOrderingDrawer.helper2'
                        defaultMessage="Ce produit est customizable à l'unité. Vous pourrez renseigner les customizations dans le panier."
                      />
                    </Paragraph>
                  )}
                />
              </Row>
            ) : null}
          </div>
          <div style={{
            paddingLeft: '20px',
            width: '50%',
          }}
          >
            {entities.length > 1 && (status === 'owner' || status === 'admin') ? (
              <>
                <Title level={4}>
                  <FormattedMessage id='entity' defaultMessage='Entité' />
                </Title>
                <Select
                  onChange={(value) => {
                    try {
                      const newEntity = entities.find((_entity) => _entity._id === value)
                      this.setState({ entity: newEntity })
                      this.resetSizes()
                      return true
                    } catch (error) {
                      return false
                    }
                  }}
                  value={entity && entity._id}
                  style={{
                    width: '100%',
                    marginBottom: '12px',
                  }}
                >
                  {entities.map((_entity) => (
                    <Option key={Math.random()} value={_entity._id}>
                      {_entity.name}
                    </Option>
                  ))}
                </Select>
              </>
            ) : null}
            <Title level={4}>
              <FormattedMessage id='color' defaultMessage='Couleur' />
            </Title>
            <Select
              onChange={(value) => {
                try {
                  const newColor = product.variations.find((v) => v.type === 'color' && v.reference === value)
                  this.setColor(newColor)
                  return true
                } catch (error) {
                  return false
                }
              }}
              value={color.reference}
              style={{
                width: '100%',
                marginBottom: '12px',
              }}
            >
              {this.getColorsOptions()}
            </Select>
            {product?.variations?.some((v) => v.type === 'size' && v.reference) ? (
              <>
                <Title level={4}>
                  <FormattedMessage id='productOrderingDrawer.sizeAndType' defaultMessage='Taille / Type' />
                </Title>
                <MultipleSize
                  product={product}
                  sizes={sizes}
                  setSize={this.setSize}
                  color={color}
                  that={this}
                  stocks={stocks}
                  entityId={(entity && entity._id) || entityId}
                  cartContent={cartContent}
                  // content={content}
                  // HACK TODO we need to know the stock already expanded in cart
                />
              </>
            ) : null}
          </div>
        </span>
        <Popconfirm
          title={(
            <FormattedMessage
              id='productOrderingDrawer.oneEntityOnly'
              defaultMessage="Le panier peut contenir qu'une seule entité, voulez vous effacer le panier en cours ?"
            />
          )}
          onConfirm={() => {
            emptyCart()
            this.addProduct(true)
            this.setState({
              embededCartVisible: true,
            })
          }}
          onCancel={() => this.resetState()}
          visible={visiblePopconfirmEntity}
          okText={<FormattedMessage id='yes' defaultMessage='Oui' />}
          cancelText={<FormattedMessage id='no' defaultMessage='Non' />}
        >
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: '100%',
            }}
          >
            <Button
              disabled={!sizes.length}
              onClick={() => {
                if (cartContent.length && cartContent[0].entityId !== ((entity && entity._id) || entityId)) {
                  this.setState({ visiblePopconfirmEntity: true })
                  return false
                }
                return this.addProduct((entity && entity._id) || entityId)
              }}
              style={{
                width: '50%',
                borderRadius: 0,
              }}
              type='primary'
            >
              <FormattedMessage id='productOrderingDrawer.addToCart' defaultMessage='Ajouter au panier' />
            </Button>
            <Button
              onClick={() => this.setState({
                embededCartVisible: true,
              })}
              style={{
                width: '50%',
                borderRadius: 0,
              }}
            >
              <FormattedMessage id='productOrderingDrawer.seeCart' defaultMessage='Voir le Panier' />
            </Button>
          </div>
        </Popconfirm>
        <CartDrawer
          visible={embededCartVisible}
          onClose={() => this.setState({
            embededCartVisible: false,
          })}
          locale={locale}
        />
      </Drawer>
    )
  }
}

import React from 'react'
import { Tag } from 'antd'
import { FormattedMessage } from 'react-intl'
import {
  character, neutralColor, primaryColor, validColor, white,
} from '../../config/theme'

const availableCampaignStatus = {
  draft: 'draft',
  active: 'active',
  archived: 'archived',
}
const getCampaignStatusTag = (campaign = {}) => {
  const { status, public: isPublic } = campaign
  switch (true) {
  case status === availableCampaignStatus.draft:
    return (
      <Tag
        style={{
          fontWeight: 500,
          fontSize: '12px',
          background: character.disabledPlaceholder,
          color: white,
          border: 0,
        }}
      >
        <FormattedMessage id='draft' defaultMessage='Brouillon' />
      </Tag>
    )
  case status === availableCampaignStatus.archived:
    return (
      <Tag
        style={{
          fontWeight: 500,
          fontSize: '12px',
          background: neutralColor[2],
          color: character.disabledPlaceholder,
          border: 0,
        }}
      >
        <FormattedMessage id='archived' defaultMessage='Archivée' />
      </Tag>
    )
  case isPublic === true:
    return (
      <Tag
        style={{
          fontWeight: 500,
          fontSize: '12px',
          background: validColor[1],
          color: character.valid,
          border: 0,
        }}
      >
        <FormattedMessage id='public.female' defaultMessage='Publique' />
      </Tag>
    )
  case isPublic === false:
    return (
      <Tag
        style={{
          fontWeight: 500,
          fontSize: '12px',
          background: primaryColor[1],
          color: primaryColor[6],
          border: 0,
        }}
      >
        <FormattedMessage id='private.female' defaultMessage='Privée' />
      </Tag>
    )
  default:
    return null
  }
}
// eslint-disable-next-line import/prefer-default-export
export { availableCampaignStatus, getCampaignStatusTag }

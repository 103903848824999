import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  faCubes,
  faShirt,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'
import { campaignStatAdmin } from '../../../helpers/magicLinks'
import { DataBlockNPS, DataBlock } from '../../atoms'

const MagicLinksTrackingDashboard = (props) => {
  try {
    const {
      campaigns, magicLinks, shopId, user,
    } = props

    const infoStats = campaignStatAdmin(campaigns, user, magicLinks)

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px 0',
        margin: '0px 0px 10px',
        gap: '16px',
      }}
      >
        <DataBlock
          title={(
            <FormattedMessage
              id='campaignTracking.cards.createdCampaigns'
              defaultMessage='Campagnes créées'
            />
          )}
          value={infoStats[0]?.length || 0}
          icon={faCubes}
        />
        <DataBlock
          title={(
            <FormattedMessage
              id='campaignTracking.cards.presentedProducts'
              defaultMessage='Produits présentés'
            />
          )}
          value={infoStats[1] || 0}
          icon={faShirt}
        />
        <DataBlock
          title={(
            <FormattedMessage
              id='magicLinks.dashboard.sentMagicLinks'
              defaultMessage='Magic Links envoyés'
            />
          )}
          value={infoStats[2]?.length || 0}
          icon={faPaperPlane}
        />
        <DataBlockNPS npsSource='USER' shopId={shopId} />
      </div>
    )
  } catch (e) {
    console.log('MagicLinksTracking/Dashboard error:', e)
    return null
  }
}

export default MagicLinksTrackingDashboard

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { MagicLinkSelectFilter } from '../..'

const MagicLinkAdminFilter = (props) => {
  const {
    setFilters, // Required
    filters, // Required
    options, // Required
  } = props

  const { adminsIds } = filters

  return (
    <MagicLinkSelectFilter
      options={options}
      title={<FormattedMessage id='administrator' defaultMessage='Administrateur' />}
      onChange={(value) => setFilters({ ...filters, adminsIds: value })}
      placeholder={<FormattedMessage id='campaignTracking.placeholders.admins' defaultMessage='Tous les admins' />}
      onResetFilter={() => { setFilters({ ...filters, adminsIds: [] }) }}
      selectedValues={adminsIds}
    />
  )
}

export default MagicLinkAdminFilter

import { Menu } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import theme from '../../../config/theme'

const CampaignEditSteps = (props) => {
  const {
    currentStep, // Required
    setCurrentStep, // Required
  } = props

  return (
    <Menu
      onClick={setCurrentStep}
      selectedKeys={[currentStep]}
      mode='horizontal'
      style={{
        backgroundColor: theme?.neutralColor[0],
      }}
    >
      <Menu.Item key='dashboard'>
        <span style={{ fontSize: '14px' }}>
          <FormattedMessage id='dashboard' defaultMessage='Dashboard' />
        </span>
      </Menu.Item>
      <Menu.Item key='emailing'>
        <span style={{ fontSize: '14px' }}>
          <FormattedMessage id='campaign.create.step.emailing' defaultMessage='Emailing' />
        </span>
      </Menu.Item>
      <Menu.Item key='products'>
        <span style={{ fontSize: '14px' }}>
          <FormattedMessage id='campaign.create.step.products' defaultMessage='Produits' />
        </span>
      </Menu.Item>
      <Menu.Item key='message'>
        <span style={{ fontSize: '14px' }}>
          <FormattedMessage id='message' defaultMessage='Message' />
        </span>
      </Menu.Item>
      <Menu.Item key='theme'>
        <span style={{ fontSize: '14px' }}>
          <FormattedMessage id='campaign.create.step.theme' defaultMessage='Thème' />
        </span>
      </Menu.Item>
      <Menu.Item key='parameters'>
        <span style={{ fontSize: '14px' }}>
          <FormattedMessage id='campaign.create.step.parameters' defaultMessage='Paramètres' />
        </span>
      </Menu.Item>
    </Menu>
  )
}

export default CampaignEditSteps

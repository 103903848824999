import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { character, primaryColor } from '../../../config/theme'

import {
  MagicLinksDashboardStarGift,
  CampaignEditDashboardStats,
  MagicLinksTrackingTable,
  MagicLinksCampaignDashboardFilters,
  FormattedButton,
  MagicLinksResendModal,
} from '../..'

const CampaignEditDashboard = (props) => {
  try {
    const {
      campaign,
      currentCampaignId,
      magicLinks,
      orders,
      products,
      user,
      shopOwner,
      collaborators,
      locale,
      modifyMagicLinks,
      isDisabled,
    } = props

    const [filters, setFilters] = useState({
      adminsIds: [],
      campaignsIds: [],
      range: [],
      statuses: [],
      search: '',
    })
    const [selection, setSelection] = useState([])
    const [resendOne, setResendOne] = useState(false)
    const [stepBeforeResend, setStepBeforeResend] = useState(false)

    const campaignMagicLinks = magicLinks?.filter((magicLink) => magicLink?.campaignId === currentCampaignId)

    // TODO HELPER retrieve data for campaign
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '28px',
      }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <CampaignEditDashboardStats
            currentCampaignId={currentCampaignId}
            magicLinks={campaignMagicLinks}
          />
          <div style={{ display: 'flex', gap: '16px', width: '100%' }}>
            <MagicLinksDashboardStarGift
              magicLinks={campaignMagicLinks}
              orders={orders}
              products={products}
            />
            {/* //TODO AVIS ET NPS */}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            fontWeight: 600, fontSize: '16px', lineHeight: '24px', color: character.title,
          }}
          >
            <FormattedMessage
              id='magicLinks.dashboard.sentMagicLinks'
              defaultMessage='Magic Links envoyés'
            />
          </div>
          <FormattedButton
            messageId='campaign.edit.dashboard.downloadCSV'
            defaultMessage='Télécharger le CSV des actions'
            // TODO CSV DOWNLOAD
            onClickFunction={() => null}
            textStyle={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '22px',
              color: primaryColor[6],
            }}
            icon={faDownload}
            type='link'
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <MagicLinksResendModal
            magicLinks={campaignMagicLinks}
            isModalVisible={stepBeforeResend}
            setIsModalVisible={setStepBeforeResend}
            resendOne={resendOne}
            setResendOne={setResendOne}
            selection={selection}
            setSelection={setSelection}
            modifyMagicLinks={modifyMagicLinks}
            onCancel={() => setStepBeforeResend(false)}
          />
          <MagicLinksCampaignDashboardFilters
            filters={filters}
            setFilters={setFilters}
            collaborators={collaborators}
            campaigns={[campaign]}
            magicLinks={campaignMagicLinks}
          />
          <MagicLinksTrackingTable
            filters={filters}
            selection={selection}
            setSelection={setSelection}
            user={user}
            shopOwner={shopOwner}
            locale={locale}
            collaborators={collaborators}
            campaigns={[campaign]}
            setResendOne={setResendOne}
            setStepBeforeResend={setStepBeforeResend}
            magicLinks={campaignMagicLinks}
            modifyMagicLinks={modifyMagicLinks}
            style={{ marginTop: '24px' }}
            selectable={!isDisabled}
            wantedColumns={[
              'formatted.date',
              'formatted.expireAt',
              'email',
              'formatted.status',
              'formatted.nps',
              'action',
            ]}
            isPaginated
          />
        </div>
      </div>
    )
  } catch (e) {
    console.log('organisms/CampaignEditDashboard error:', e)
    return null
  }
}

export default CampaignEditDashboard

import React, { useState } from 'react'
import { faList } from '@fortawesome/free-solid-svg-icons'
import { Select } from 'antd'

import { FormattedMessage } from 'react-intl'
import {
  ErrorMessage,
  FormattedSwitch,
  StepCardForm,
  FormStepInput,
  MovingStepButton,
} from '../..'
// eslint-disable-next-line import/no-named-as-default
import { actionUrlIsValid } from '../../../helpers/actions'

const { Option } = Select

const CampaignCreationActions = (props) => {
  const {
    setCampaign, // Required
    campaign, // Required
    moveNextStep,
    movePreviousStep,
    setNeedUpdateCampaign, // Required
    isDisabled = false,
  } = props

  const action = campaign.actions?.find(Boolean)
  const [actionsEnabled, setActionsEnabled] = useState(!!action)
  const [isActionUrlValid, setIsActionUrlValid] = useState(true)
  const canGoToNextStep = !actionsEnabled
    || (action?.type?.length > 0 && action?.origin?.length > 0 && action?.url?.length > 0 && isActionUrlValid)

  const calendarSelectOptions = [
    {
      value: 'CALENDLY',
      label: 'Calendly',
    },
    {
      value: 'HUBSPOT',
      label: 'Hubspot',
    },
  ]

  const onToggleActionsEnabled = () => {
    const newEnabled = !actionsEnabled
    setActionsEnabled(newEnabled)
    setCampaign({
      ...campaign,
      actions: newEnabled
        ? [
          {
            type: 'CALENDAR',
            origin: calendarSelectOptions?.find(Boolean)?.value,
            url: '',
          },
        ]
        : [],
    })
    setNeedUpdateCampaign(true)
  }

  const onChangeActionOrigin = (newOrigin) => {
    setIsActionUrlValid(actionUrlIsValid(action?.url, action?.type, newOrigin))
    setCampaign({
      ...campaign,
      actions: [
        {
          ...action,
          origin: newOrigin,
        },
      ],
    })
    setNeedUpdateCampaign(true)
  }

  const onChangeActionLink = (event) => {
    const newUrl = event.target.value
    // TODO: next step is to pass the action in the function to test with the right regiex
    setIsActionUrlValid(actionUrlIsValid(newUrl, action?.type, action?.origin))
    setCampaign({
      ...campaign,
      actions: [
        {
          ...action,
          url: newUrl,
        },
      ],
    })
    setNeedUpdateCampaign(true)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
      <StepCardForm
        avatarIcon={faList}
        titleMessageId='campaigns.create.step.actions.header'
        titleDefaultMessage='Actions de la campagne'
        descriptionMessageId='campaigns.create.step.actions.header.description'
        descriptionDefaultMessage='Paramétrez, personnalisez et organisez les actions'
        isDisabled={isDisabled}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          {/* Content of the card here */}
          <FormattedSwitch
            checked={actionsEnabled}
            onChangeFunction={onToggleActionsEnabled}
            title={(
              <FormattedMessage
                id='campaigns.create.step.actions.toggleActions'
                defaultMessage='Débloquer le cadeau avec un rendez-vous dans votre calendrier'
              />
            )}
            isDisabled={isDisabled}
          />
          <span style={{ display: 'block' }}>
            <FormattedMessage
              id='campaign.create.step.actions.description'
              defaultMessage='Votre destinataire devra prendre un rendez-vous dans votre agenda pour finaliser sa commande et recevoir le cadeau'
            />
          </span>
          <span style={{ display: 'block' }}>
            <FormattedMessage
              id='campaigns.step.actions.vote'
              defaultMessage='Vous avez aimé cette action ?
             Participez dès maintenant au vote pour la prochaine action à retrouver dans votre Magic Link : {link} !'
              values={{
                link: (
                  <a href='https://tally.so/r/mBavQ7' target='_blank' rel='noreferrer'>
                    <FormattedMessage
                      id='campaigns.step.actions.vote.cta'
                      defaultMessage="c'est par ici"
                    />
                  </a>
                ),
              }}
            />
          </span>
          {actionsEnabled && (
            <>
              <Select onChange={onChangeActionOrigin} defaultValue={action?.origin} disabled={isDisabled}>
                {calendarSelectOptions.map((option) => <Option value={option.value}>{option.label}</Option>)}
              </Select>

              <FormStepInput
                onChangeFunction={onChangeActionLink}
                titleMessageId='campaigns.create.step.actions.link.title'
                titleDefaultMesage='Lien de votre calendrier'
                placeholderMessageId='campaigns.create.step.actions.title.placeholder'
                placeholderDefaultMessage={action?.origin === 'HUBSPOT'
                  ? 'Exemple: https://meetings-eu1.hubspot.com/john-smith/'
                  : 'Exemple: https://calendly.com/john-d/30min'}
                inputValue={action?.url}
                disabled={!actionsEnabled || isDisabled}
              />

              <ErrorMessage isVisible={!isActionUrlValid}>
                <FormattedMessage
                  id='campaigns.create.step.actions.link.formatError'
                  defaultMessage='Le format de cette url est incorrect'
                />
              </ErrorMessage>
            </>

          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '128px',
              justifyContent: 'space-between',
            }}
          >
            {moveNextStep && movePreviousStep && (
              <MovingStepButton
                moveNextStep={moveNextStep}
                isContinueDisabled={
                  !canGoToNextStep
                }
                movePreviousStep={movePreviousStep}
              />
            )}
          </div>
        </div>
      </StepCardForm>
    </div>
  )
}

export default CampaignCreationActions

export const initialState = {
  loading: false,
  error: false,
  data: [],
  budget: false,
}

export const loading = (state = initialState) => state.loading
export const error = (state = initialState) => state.error
export const getBudgets = (state = initialState) => state.data || []
export const getBudget = (state = initialState) => state.budget || false

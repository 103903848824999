import React from 'react'

import { Badge } from 'antd'

// NOTE cannot assign style to Badge

export default ({ color }) => (
  <span>
    {color.name}
    <span style={{
      marginLeft: '10px',
    }}
    >
      <Badge
        color={color.value}
      />
    </span>
  </span>
)

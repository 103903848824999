import React, { useState } from 'react'
import {
  Card,
  Button,
  notification,
  List,
  Modal,
  Row,
  Spin,
  Descriptions,
  Switch,
} from 'antd'
import { uniqBy, sortBy } from 'lodash'
import { FormattedMessage } from 'react-intl'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const ButtonGroup = Button.Group

const Bp = (props) => {
  const {
    products,
    baseProduct,
    pushProducts,
    shopId,
    allShops,
    onClose,
    setCurrentProduct,
  } = props

  const {
    supplierBaseProductReference,
    name,
    _id,
    caracteristics = {},
    category = {},
    subCategory,
    customizable = false,
  } = baseProduct

  const openNotification = (newProduct) => {
    const key = '1234'
    const _products = [...products]
    _products.push(newProduct)
    const cart = uniqBy(_products.filter((product) => !product.newBaseProduct), 'supplierBaseProductId')
    const recap = (
      cart.map((productCart) => (
        <div>
          x
          {_products.filter((product) => productCart.supplierBaseProductId === product.supplierBaseProductId).length}
          {' '}
          {productCart.baseProduct.baseProductInfo.detail.fr}
          <br />
        </div>
      ))
    )
    const btn = (
      <div>
        {cart.length && cart.length > 0 ? (
          <div>
            <FormattedMessage id='recap' defaultMessage='Récapitulatif :' />
            <br />
            {recap}
          </div>
        ) : null}
        <Row>
          <p>
            <FormattedMessage id='bp.helper1' defaultMessage='Voulez-vous continuer à ajouter des produits ?' />
          </p>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size='small'
            onClick={() => notification.close(key)}
          >
            <FormattedMessage id='yes' defaultMessage='Oui' />
          </Button>
          <Button
            type='primary'
            size='small'
            style={{ marginLeft: '8px' }}
            onClick={() => {
              notification.close(key)
              onClose()
              setCurrentProduct(cart[0])
            }}
          >
            <FormattedMessage id='noStartCustomizing' defaultMessage='Non, commencer à les personnaliser' />
          </Button>
        </div>
      </div>
    )
    notification.open({
      message: <FormattedMessage
        id='bp.productAdded'
        defaultMessage='Article "{name}" ajouté ! 🚀`'
        values={{ name: baseProduct.subCategory.fr }}
      />,
      description: (baseProduct.baseProductInfo && baseProduct.baseProductInfo.detail && baseProduct.baseProductInfo.detail.fr) || '',
      placement: 'bottomRight',
      duration: 8,
      btn,
      key,
      onClose: notification.close(),
    })
  }

  const [loadedImage, setLoadedImage] = useState(true)
  const displayImage = () => {
    if (baseProduct.images && baseProduct.images.length) {
      return sortBy(baseProduct.images, 'position').find(((image) => image)).src
    } return `${imageBaseUrl}/SVGS/${_id}/baseProduct/display.png`
  }

  const getDataForCarousel = () => {
    if (baseProduct.images && baseProduct.images.length) return sortBy(baseProduct.images, 'position')
    if (baseProduct.picList && baseProduct.picList.length) return baseProduct.picList
    if (baseProduct.shopifyImages && baseProduct.shopifyImages) return baseProduct.shopifyImages
    return [{ src: `${imageBaseUrl}/SVGS/${_id}/baseProduct/display.png` }]
  }

  return (
    <List.Item
      key={supplierBaseProductReference}
      style={{
        cursor: 'auto',
      }}
    >
      <Card
        title={(name && name.fr) || ''}
        bordered
        cover={(
          <div className='catalog-card-cover'>
            {loadedImage ? (<Spin style={{ maxHeight: '275px' }} />) : (
              null
            )}
            <div className={loadedImage ? 'rect-img-container-no' : 'rect-img-container'}>
              <img
                alt='example'
                src={displayImage()}
                style={loadedImage ? { display: 'none' } : {
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
                onLoad={() => setLoadedImage(false)}
                onError={(e) => {
                  e.target.src = baseProduct.shopifyImages && baseProduct.shopifyImages.length ? baseProduct.shopifyImages[0].src
                    : 'https://developers.google.com/maps/documentation/streetview/images/error-image-generic.png'
                }}
              />
              {caracteristics?.OOS ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'absolute',
                    zIndex: 1000,
                    right: -15,
                    bottom: 4,
                    width: '80px',
                    transform: 'rotate(-35deg)',
                    backgroundColor: '#FF0D3D',
                    color: 'white',
                    fontWeight: 700,
                  }}
                >
                  <div style={{ marginLeft: 6 }}>
                    OOS
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Button
              type='primary'
              onClick={async () => {
                const newProduct = {
                  baseProduct: { ...baseProduct, unitPrice: 0 },
                  provisionalId: Math.random().toString(8),
                  supplierBaseProductId: baseProduct._id,
                  name: (baseProduct.name && baseProduct.name.fr) || baseProduct.subCategory.fr,
                  owner: allShops.find((shop) => shop._id === shopId).owner,
                  shops: [shopId],
                  displayDescription: {
                    fr: baseProduct.description?.fr || baseProduct.baseProductInfo?.detail?.fr || '',
                    en: baseProduct.description?.en || baseProduct.baseProductInfo?.detail?.en || '',
                  },
                  pricing: {
                    margin: 0,
                    customizationPrice: 0,
                  },
                  design_ids: [],
                  printingFile: null,
                  previewFile: null,
                  unitPrice: baseProduct.unitPrice || 0,
                  stocked: false,
                }
                await pushProducts([newProduct])
                openNotification(newProduct)
              }}
            >
              <FormattedMessage id='add' defaultMessage='Ajouter' />
            </Button>
            <Button
              icon='info-circle'
              onClick={() => {
                Modal.info({
                  closable: true,
                  title: baseProduct.name.fr,
                  width: '60%',
                  content: (
                    <div style={{ display: 'flex' }}>
                      <div style={{
                        flex: '1 2', display: 'flex', width: '100%', justifyContent: 'flex-start', margin: '10px 0', flexWrap: 'wrap',
                      }}
                      >
                        {getDataForCarousel().map((image) => (
                          <img
                            alt={image.alt}
                            src={image.src}
                            key={image.src}
                            style={{
                              width: '150px',
                              maxHeight: '100%',
                              borderRadius: '4px',
                              margin: '0 5px 5px 0',
                            }}
                          />
                        ))}
                      </div>
                      <div style={{ flex: '1 2' }}>
                        <Descriptions title={<FormattedMessage id='characteristics' defaultMessage='Caractéristiques' />}>
                          <Descriptions.Item span={1} label={<FormattedMessage id='category' defaultMessage='Catégorie' />}>
                            {category.fr && category.fr.length
                              ? category.fr.find((cat) => cat)
                              : 'NC'}
                          </Descriptions.Item>
                          <Descriptions.Item span={2} label={<FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />}>
                            {subCategory.fr || 'NC'}
                          </Descriptions.Item>
                          <Descriptions.Item label={<FormattedMessage id='weight' defaultMessage='Poids' />}>
                            {caracteristics.weight || ''}
                          </Descriptions.Item>
                          <Descriptions.Item label={<FormattedMessage id='capacity' defaultMessage='Capacité' />}>
                            {caracteristics.capacity || ''}
                          </Descriptions.Item>
                          <Descriptions.Item label={<FormattedMessage id='dimension' defaultMessage='Dimension' />}>
                            {caracteristics.dimension || ''}
                          </Descriptions.Item>
                          <Descriptions.Item span={2} label={<FormattedMessage id='constitution' defaultMessage='Constitution' />}>
                            {caracteristics.constitution || ''}
                          </Descriptions.Item>
                          <Descriptions.Item label={<FormattedMessage id='customizable' defaultMessage='Personnalisable' />}>
                            <Switch checked={customizable} />
                          </Descriptions.Item>
                          <Descriptions.Item label='OOS'>
                            <Switch checked={caracteristics?.OOS} />
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </div>
                  ),
                  onOk() {},
                })
              }}
            />
          </ButtonGroup>
        </div>
      </Card>
    </List.Item>
  )
}

export default Bp

import date from 'dayjs'
import { initialState } from './selectors'

import {
  ERROR,
  RECEIVED,
  RECEIVED_MORE,
  FETCH,
  MODIFY,
  MODIFIED,
  MODIFY_GIFT,
  CANCEL_GIFT,
  CANCELLED_GIFT,
  GIFT_TO_BILLING,
  GIFTED_TO_BILLING,
  FETCH_MORE,
  BATCH_LOADING,
  BATCH_ERROR,
} from './actions'

export default (state = initialState, { type, payload }) => {
  const {
    // eslint-disable-next-line camelcase
    data, error_details, pagination, batchLoading, errorDetails, batchError,
  } = payload || {}
  switch (type) {
  case BATCH_ERROR:
    return {
      ...state,
      errorDetails,
      batchError,
    }
  case BATCH_LOADING:
    return {
      ...state,
      batchLoading,
    }
  case RECEIVED:
    return {
      ...state,
      error: false,
      loading: false,
      loading_more: false,
      order_loading: false,
      data,
      pagination,
    }
  case RECEIVED_MORE:
    return {
      ...state,
      error: false,
      loading: false,
      loading_more: true,
      data: state.data.concat(data),
      pagination,
    }
  case FETCH:
    return {
      ...state,
      error: false,
      loading: true,
      lastFetched: date(),
    }
  case FETCH_MORE:
    return {
      ...state,
      loading_more: true,
      loading: false,
    }
  case MODIFY:
    return {
      ...state,
      data: state.data.map((o) => {
        if (o._id !== payload.order_id) return o
        return {
          ...o,
          being_modified: true,
        }
      }),
    }
  case MODIFIED:
    return {
      ...state,
      data: state.data.map((o) => {
        if (o._id !== payload.order_id) return o
        return {
          ...o,
          being_modified: true,
          ...payload.modifications,
        }
      }),
    }
  case MODIFY_GIFT:
    return {
      ...state,
      data: state.data.map((o) => {
        if (o._id !== payload.order_id) return o
        return {
          ...o,
          being_modified: true,
        }
      }),
    }
  case CANCEL_GIFT:
    return {
      ...state,
      data: state.data.map((o) => {
        if (o._id !== payload.order_id) return o
        return {
          ...o,
          being_modified: true,
          status: 'Cancelled',
        }
      }),
    }
  case CANCELLED_GIFT:
    return {
      ...state,
      data: state.data.map((o) => {
        if (o._id !== payload.order_id) return o
        return {
          ...o,
          being_modified: true,
          status: 'Cancelled',
        }
      }),
    }
  case GIFT_TO_BILLING:
    return {
      ...state,
      order_loading: true,
    }
  case GIFTED_TO_BILLING:
    return {
      ...state,
      order_loading: false,
      data: state.data.map((o) => {
        if (o._id !== payload.order_id) return o
        return {
          ...o,
          being_modified: true,
          status: 'Received',
        }
      }),
    }
  case ERROR:
    return {
      ...state,
      error_details,
      error: true,
    }
  default:
    return state
  }
}

// On a besoin de selecteurs "globaux" pour reselect
// Si on déclare ce selecteur dans store/orders/selectors par exemple, notre module /store/selectors.js va modifier
// son scope pour ne lui donner que state.orders
// les selecteurs getEntities et getLocale n'auront pas accès au bon state
const { createSelector } = require('reselect')
const { trimAllRecordsForOrdersTable, rebuildOrders, trimAllRecordsForMembersTable } = require('../helpers/trim')
// on doit importer les intialState de chaque store concerné pour récupérer nos valeurs par défault
const { initialState: ordersInitialState } = require('./orders/selectors')
const { initialState: entitiesInitialState } = require('./entities/selectors')
const { initialState: localeInitialState } = require('./locale/selectors')
const { initialState: membersInitialState } = require('./members/selectors')
const { initialState: shopsInitialState } = require('./shops/selectors')

const globalState = {
  orders: ordersInitialState,
  entities: entitiesInitialState,
  locale: localeInitialState,
  members: membersInitialState,
  shops: shopsInitialState,
}

// on rajoute des sélecteurs globaux
// un selecteur déclaré dans un dossier n'aurait pas besoin de faire state.orders , il ferait direct state.data
const getGlobalOrders = (state = globalState) => state.orders.data
const getGlobalEntities = (state = globalState) => state.entities.data
const getGlobalLocale = (state = globalState) => state.locale
const getGlobalMembers = (state = globalState) => state.members.members
// createSelector va mémoriser le résultat de trimAllRecordsForOrdersTable et ne récalculer que si certaines variables changes

const getRebuiltOrders = createSelector([getGlobalOrders], rebuildOrders)

const getFormattedOrders = createSelector([
  getRebuiltOrders,
  getGlobalEntities,
  getGlobalLocale,
], trimAllRecordsForOrdersTable)

const getFormattedMembers = createSelector([
  getGlobalMembers,
  getGlobalLocale,
  getGlobalEntities,
], trimAllRecordsForMembersTable)

module.exports = { getFormattedOrders, getRebuiltOrders, getFormattedMembers }

import React from 'react'
import date from 'dayjs'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import {
  Drawer,
  message,
  Card,
  Button,
} from 'antd'

import { GroupCard, GroupCreation } from '../../components'

import { MODIFY } from '../../store/shops/actions'
import { MODIFY_MEMBERS_GROUPS } from '../../store/members/actions'
import { fromMembers, fromShops } from '../../store/selectors'

class GroupsDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibleCreation: false,
    }
  }

  getMembersByGroup = (group) => {
    const { members } = this.props
    return members.filter((member) => member.groups
      .find((_group) => _group === group.name))
  }

  saveGroup = (group, goIn, goOut) => {
    const { modifyMembersGroups } = this.props
    const membersModified = []
    goIn.map((member) => membersModified.push(member))
    goOut.map((member) => membersModified.push(member))
    const data = {
      membersIds: membersModified.map((member) => member?._id),
      group,
      goIn,
      goOut,
    }
    modifyMembersGroups({
      data,
      callback: () => {
        if (goIn.length) {
          message.info(<FormattedMessage id='groupsDrawer.membersAdded' defaultMessage='Membres ajoutés !' />)
        } if (goOut.length) {
          message.info(<FormattedMessage id='groupsDrawer.membersDeleted' defaultMessage='Membres supprimés !' />)
        }
        return true
      },
    })
  }

  render() {
    const {
      visibleDrawer,
      hideDrawer,
      members,
      shop,
      modifyShop,
    } = this.props
    const {
      settings: {
        membersGroups = [],
      },
    } = shop
    const {
      visibleCreation,
    } = this.state

    return (
      <Drawer
        title={<FormattedMessage id='groupsDrawer.title' defaultMessage='Gérer mes groupes' />}
        width={640}
        closable
        visible={visibleDrawer}
        onClose={hideDrawer}
      >
        <GroupCreation
          visible={visibleCreation}
          onClose={() => this.setState({ visibleCreation: false })}
          shop={shop}
          modifyShop={modifyShop}
        />
        <div>
          <Card
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type='primary'
              onClick={() => this.setState({ visibleCreation: true })}
            >
              <FormattedMessage id='groupsDrawer.newGroup' defaultMessage='Créer un nouveau groupe' />
            </Button>
          </Card>
          {membersGroups
            .sort((a, b) => (date(a.createdAt).isBefore(date(b.createdAt)) ? 1 : -1))
            .map((group) => (
              <GroupCard
                group={group}
                members={members}
                groupMembers={this.getMembersByGroup(group)}
                saveGroup={this.saveGroup}
                shop={shop}
                modifyShop={modifyShop}
                displayDrawerRules={this.displayDrawerRules}
                key={group.name}
              />
            ))}
        </div>
      </Drawer>
    )
  }
}

export default connect(
  (state) => ({
    members: fromMembers.getMembers(state),
    shop: fromShops.getShop(state),
  }), (dispatch) => bindActionCreators({
    modifyMembersGroups: (payload) => ({
      type: MODIFY_MEMBERS_GROUPS,
      payload,
    }),
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)(GroupsDrawer)

import React from 'react'
import {
  Empty,
} from 'antd'
import { FormattedMessage } from 'react-intl'

const Placeholder = (props) => {
  const {
    description,
    children,
    style,
    innerStyle,
  } = props
  return (
    <div style={{
      position: 'absolute',
      top: '40%',
      left: '40%',
      ...style,
    }}
    >
      <Empty
        style={{
          width: '300px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          ...innerStyle,
        }}
        image='/5_PERSONNAGE_PLATEFORME-17.png'
        description={description || (
          <FormattedMessage
            id='placeholderText'
            defaultMessage='Telle une jeune pousse, cette page a besoin de votre attention pour grandir en contenu...'
          />
        )}
      >
        {children}
      </Empty>
    </div>
  )
}

export default Placeholder

import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DatePicker } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const { RangePicker } = DatePicker

const MagicLinkPeriodFilter = (props) => {
  const {
    setFilters, // Required
    filters, // Required
  } = props

  const dateFormatList = ['DD/MM/YYYY', 'MM/DD/YYYY']

  return (
    <div
      style={{
        display: 'flex', flex: 1, flexDirection: 'column',
      }}
    >
      <h4 style={{ marginBottom: '8px' }}>
        <FormattedMessage id='campaignTrackingFilters.label.date' defaultMessage='Période' />
      </h4>
      <RangePicker
        style={{
          minWidth: '220px',
          flex: '1 8',
        }}
        format={dateFormatList}
        onChange={(dates) => setFilters({ ...filters, range: dates })}
        separator={(
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ width: '12px', marginTop: '5px' }}
          />
        )}
      />
    </div>
  )
}

export default MagicLinkPeriodFilter

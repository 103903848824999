/* eslint-disable consistent-return */
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { flatten, uniqBy } from 'lodash'
import { getUnitPrice } from '../getPrice'
import { getProductStocks } from '../stocks'

dayjs.extend(isBetween)

const backgroundColors = [
  '#0000FF',
  '#F5D4B4',
  '#FEDD00',
  '#FF0D3D',
  '#0000FF',
  '#015D50',
  '#000000',
  '#516BA7',
]

const hasOOSProducts = (allProducts, allStocks) => allProducts.some(
  (product) => allStocks.some((stock) => stock.productId === product._id && stock.qty < 1),
)

const getPhysicallyStockedProducts = (allProducts, allStocks) => allProducts.filter(
  (product) => allStocks.some((stock) => stock.productId === product._id && stock.qty > 0),
)

const getStockValorisation = (allProducts, allStocks) => {
  try {
    const stockedProducts = getPhysicallyStockedProducts(allProducts, allStocks)
    const result = stockedProducts.map((product) => (getUnitPrice(product) || 0)
      * parseInt(getProductStocks(allStocks, product._id).map((s) => parseInt(s.qty || 0, 10))
        .reduce((acc, cur) => acc + cur, 0), 10))
      .reduce((acc, cur) => acc + cur, 0).toFixed(2) || 0
    return `${result} €`
  } catch (e) {
    console.log('getStockValorisation error:', e)
    return []
  }
}

const getPhysicallyStockedQuantities = (allStocks) => flatten(allStocks?.map((stock) => parseInt(stock.qty, 10) || 0))
  .reduce((acc, cur) => acc + cur, 0)

const getOrderedProducts = (allOrders) => {
  try {
    const result = flatten(allOrders.map(
      (order) => order.subOrders && flatten(order.subOrders.map(
        (subOrder) => flatten(subOrder.products).filter(
          (product) => product._id,
        ),
      )),
    ).filter(Boolean))
    return result
  } catch (e) {
    console.log('getOrderedProducts error:', e)
    return []
  }
}

const getProductsTop3 = (allOrders, range) => {
  try {
    const start = range[0]
    const end = range[1]
    const products = getOrderedProducts(allOrders)
    const ranged = products.filter(
      (product) => dayjs(product.createdAt).isBetween(dayjs(start), dayjs(end), null, []),
    )
    const productsList = uniqBy(ranged, '_id')
    const counts = productsList.map(
      (product) => {
        const count = products.filter(
          (p) => p._id === product._id,
        ).length
        return { product, count }
      },
    )
    const sorted = counts.sort((a, b) => b.count - a.count)
    const result = sorted.length ? [sorted[0].product, sorted[1].product, sorted[2].product] : []
    return result
  } catch (e) {
    console.log('getProductsTop3 error:', e)
    return []
  }
}

const getShopifyNewBps = (shopifyBps) => {
  try {
    const sorted = shopifyBps.sort((a, b) => b.createdAt - a.createdAt).filter((bp) => bp.isOnShopify)
    const result = [sorted[0], sorted[1], sorted[2], sorted[3], sorted[4]]
    return result
  } catch (e) {
    console.log('getShopifyNewBps error:', e)
    return []
  }
}

const hasABirthdayThisMonth = (member) => {
  try {
    const { birthday } = member
    if (!birthday) {
      return false
    }
    const thisYear = new Date().getFullYear()
    const memberNextBirthday = thisYear.toString().concat(birthday.substr(4))
    return dayjs(memberNextBirthday).isBetween(dayjs(), dayjs().endOf('month'), null, [])
    // between now and the end of the month
  } catch (e) {
    console.log('hasABirthdayThisMonth error:', e)
    return []
  }
}

const getNextBirthdaysThisMonth = (allMembers) => {
  try {
    const thisYear = new Date().getFullYear()
    const result = allMembers?.filter((member) => {
      try {
        const { birthday = '' } = member
        if (!birthday) {
          return false
        }
        const memberNextBirthday = thisYear.toString().concat(birthday.substr(4))
        return dayjs(memberNextBirthday).isBetween(dayjs(), dayjs().endOf('month'), null, []) // between now and the end of the month
      } catch (e) {
        return false
      }
    })
    return result
  } catch (e) {
    console.log('getNextBirthdaysThisMonth error:', e)
    return []
  }
}

const getSizesPercentage = (membersSizes) => {
  const standardSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL']
  const result = standardSizes.map(
    (size) => {
      const count = membersSizes?.filter(
        (memberSize) => memberSize === size,
      ).length
      const percentage = ((count / membersSizes?.length) * 100).toFixed(2)
      const toDisplay = percentage === 'NaN' ? null : percentage
      return [toDisplay]
    },
  )
  return result
}

const getSizesPercentageByGender = (allMembers) => {
  try {
    const menSizes = allMembers?.filter((member) => member.sex === 'male')
      .map((member) => member.size || null).filter(Boolean)
    const womenSizes = allMembers?.filter((member) => member.sex === 'female')
      .map((member) => member.size || null).filter(Boolean)
    return { men: getSizesPercentage(menSizes), women: getSizesPercentage(womenSizes) }
  } catch (e) {
    console.log('getSizesPercentageByGender error:', e)
    return []
  }
}

const getOrdersByDateRange = (allOrders = [], range = []) => {
  try {
    const start = range[0]
    const end = range[1]
    const result = allOrders.filter(
      (order) => dayjs(order.createdAt).isBetween(dayjs(start), dayjs(end), null, []),
    )
    return result
  } catch (e) {
    console.log('getOrdersByDateRange error:', e)
    return []
  }
}

const getOrdersByFilters = (allOrders, params = {}, status) => {
  try {
    const { range = [], addresses = [] } = params
    const ordersByRange = getOrdersByDateRange(allOrders, range)
    let result = ordersByRange
    if (status) {
      result = result.filter(
        (order) => status.includes(order.status),
      )
    }
    if (addresses.length > 0) {
      result = result.filter(
        (order) => addresses.some((address) => address === order.client?.prefilledAddress),
      )
    }
    return result
  } catch (e) {
    console.log('getOrdersByFilters error:', e)
    return []
  }
}

const getOrdersLabels = (range, locale) => {
  try {
    const start = range[0]
    const end = range[1]
    const months = locale === 'fr'
      ? ['jan.', 'fév.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sep.', 'oct.', 'nov.', 'déc.']
      : ['jan.', 'feb.', 'mar.', 'apr.', 'may', 'june', 'july', 'aug.', 'sep.', 'oct.', 'nov.', 'dec.']
    const nbOfMonths = end.diff(start, 'month')
    const result = []
    for (let i = 0, count = 0; i <= nbOfMonths; i += 1) {
      // if the month is january but not the first january encountered in range, ++year
      if (i !== 0 && ((i + start.month()) % 12) === 0) count += 1
      const year = parseInt(start.format('YY'), 10) + count
      // ex. "jan. 21"
      result.push(months[(i + start.month()) % 12].concat(` ${year}`))
    }
    return result
  } catch (e) {
    console.log('getOrdersLabels error:', e)
    return []
  }
}

const getChartOrdersCustomData = (shopId, allOrders = [], range, identifier) => {
  try {
    const orders = getOrdersByDateRange(shopId, allOrders, range)
    const start = range[0]
    const end = range[1]
    const nbOfMonths = end.diff(start, 'month')
    const months = []
    for (let i = 0; i <= nbOfMonths; i += 1) {
      const current = dayjs(start).add(i, 'month')
      months.push(`${current.month()}/${current.year()}`)
    }
    const result = months.map((month) => orders.filter((order) => {
      const current = dayjs(order.createdAt)
      const format = `${current.month()}/${current.year()}`
      return identifier ? format === month && identifier === order.client.prefilledAddress : format === month
    }).length)
    return result
  } catch (e) {
    console.log('getChartOrdersData error:', e)
    return []
  }
}

const getOrdersDataSets = (shop, allOrders = [], params = {}, locale) => {
  try {
    const { _id, settings } = shop
    const { range, addresses } = params
    return addresses.length ? addresses.map((add) => {
      const address = settings.addresses.find((a) => a.identifier === add)
      const color = backgroundColors[(addresses.indexOf(add)) % backgroundColors.length]
      return {
        data: getChartOrdersCustomData(_id, allOrders, range, address.identifier),
        label: address.name,
        borderColor: color,
        backgroundColor: color,
        fill: false,
      }
    }) : [{
      data: getChartOrdersCustomData(_id, allOrders, range),
      label: locale === 'fr' ? 'Toutes les commandes' : 'All orders',
      borderColor: '#012169',
      backgroundColor: '#012169',
      fill: false,
    }]
  } catch (e) {
    console.log('getOrdersDataSets error:', e)
    return []
  }
}

export {
  backgroundColors,
  getPhysicallyStockedQuantities,
  getNextBirthdaysThisMonth,
  getSizesPercentageByGender,
  getProductsTop3,
  getOrdersByDateRange,
  getOrdersByFilters,
  getOrdersLabels,
  getChartOrdersCustomData,
  getShopifyNewBps,
  getOrdersDataSets,
  getStockValorisation,
  hasOOSProducts,
  hasABirthdayThisMonth,
}

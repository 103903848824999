import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Drawer,
} from 'antd'

import { FormattedMessage } from 'react-intl'
import { RulesCard } from '../../components'

import { MODIFY } from '../../store/shops/actions'
import { fromShops, fromEntities, fromUsers } from '../../store/selectors'

const RulesDrawer = (props) => {
  const {
    visibleDrawer,
    hideDrawer,
    shop,
    entities,
    user,
    modifyShop,
  } = props

  return (
    <Drawer
      title={<FormattedMessage id='handleRules' defaultMessage='Gérer les règles' />}
      width={640}
      closable
      visible={visibleDrawer}
      onClose={hideDrawer}
    >
      <RulesCard
        shop={shop}
        entities={entities}
        user={user}
        modifyShop={modifyShop}
      />
    </Drawer>
  )
}

export default connect(
  (state) => ({
    shop: fromShops.getShop(state),
    entities: fromEntities.getEntities(state),
    user: fromUsers.getUser(state),
  }), (dispatch) => bindActionCreators({
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)(RulesDrawer)

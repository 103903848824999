import React from 'react'
import { InputNumber } from 'antd'

import notEqualToGlobal from '../../../helpers/baseProducts/carbonImpact'

const BaseProductImpactCarbon = (props) => {
  try {
    const { product, updateProduct } = props
    const { carbonImpact = {} } = product
    const {
      global = 0,
      manufacturing = 0,
      marking = 0,
      transport = 0,
      packaging = 0,
      endOfLife = 0,
    } = carbonImpact

    return (
      <>
        {notEqualToGlobal(carbonImpact)}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '10px',
            marginTop: '10px',
          }}
        >
          <div>
            <div style={{ fontWeight: 'bold' }}>Impact global</div>
            <InputNumber
              defaultValue={global}
              onChange={(value) => {
                updateProduct(product.provisionalId, {
                  carbonImpact: {
                    ...product.carbonImpact,
                    global: value,
                  },
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <div>
              <div style={{ fontWeight: 'bold' }}>Fabrication</div>
              <InputNumber
                defaultValue={manufacturing}
                onChange={(value) => {
                  updateProduct(product.provisionalId, {
                    carbonImpact: {
                      ...product.carbonImpact,
                      manufacturing: value,
                    },
                  })
                }}
              />
            </div>
            <div>
              <div style={{ fontWeight: 'bold' }}>Marquage</div>
              <InputNumber
                defaultValue={marking}
                onChange={(value) => {
                  updateProduct(product.provisionalId, {
                    carbonImpact: {
                      ...product.carbonImpact,
                      marking: value,
                    },
                  })
                }}
              />
            </div>
            <div>
              <div style={{ fontWeight: 'bold' }}>Transport</div>
              <InputNumber
                defaultValue={transport}
                onChange={(value) => {
                  updateProduct(product.provisionalId, {
                    carbonImpact: {
                      ...product.carbonImpact,
                      transport: value,
                    },
                  })
                }}
              />
            </div>
            <div>
              <div style={{ fontWeight: 'bold' }}>Packaging</div>
              <InputNumber
                defaultValue={packaging}
                onChange={(value) => {
                  updateProduct(product.provisionalId, {
                    carbonImpact: {
                      ...product.carbonImpact,
                      packaging: value,
                    },
                  })
                }}
              />
            </div>
            <div>
              <div style={{ fontWeight: 'bold' }}>Fin de vie</div>
              <InputNumber
                defaultValue={endOfLife}
                onChange={(value) => {
                  updateProduct(product.provisionalId, {
                    carbonImpact: {
                      ...product.carbonImpact,
                      endOfLife: value,
                    },
                  })
                }}
              />
            </div>
          </div>
        </div>
      </>
    )
  } catch (e) {
    console.log(e, 'error molecules/BaseProductInfoForm')
    return null
  }
}

export default BaseProductImpactCarbon

import React, { useState } from 'react'
import {
  Drawer,
  Button,
  Form,
  Row,
  Input,
  Icon,
  Col,
  message,
} from 'antd'
import { FormattedMessage } from 'react-intl'

const ForgottenPassword = ({
  requestNewPW,
  form: {
    getFieldDecorator,
  },
}) => {
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [touched, setTouched] = useState(false)

  return (
    <>
      <Button
        type='link'
        onClick={() => setOpen(true)}
        style={{
          padding: 0,
        }}
      >
        <FormattedMessage
          id='forgottenpassword.CTA'
          defaultMessage='Mot de passe oublié ?'
        />
      </Button>
      <Drawer
        title={(
          <FormattedMessage
            id='forgottenPassword.title'
            defaultMessage='Mot de passe oublié'
          />
        )}
        visible={open}
        width='30%'
        onClose={() => setOpen(false)}
      >
        <Form>
          <Row>
            <Col span={24}>
              <Form.Item label={(
                <FormattedMessage
                  id='forgottenPassword.label'
                  defaultMessage='Votre adresse mail'
                />
              )}
              >
                {getFieldDecorator('name', {
                  rules: [
                    {
                      type: 'email',
                      message: <FormattedMessage id='forgottenPassword.invalidMail' defaultMessage='Email invalide' />,
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      setTouched(true)
                    }}
                  />,
                )}
              </Form.Item>

            </Col>
          </Row>
        </Form>
        <Row>
          <Col span={24}>
            <Button
              type='primary'
              disabled={!touched}
              size='large'
              block
              onClick={
                () => requestNewPW({
                  email,
                  callback: (error) => {
                    if (error) {
                      if (error === 404) {
                        message.error(
                          <FormattedMessage
                            id='forgottenPassword.unknownMail'
                            defaultMessage='Adresse mail inconnue'
                          />,
                        )
                      } else {
                        message.error(
                          <FormattedMessage
                            id='forgottenPassword.error'
                            defaultMessage='Une erreur est survenue'
                          />,
                        )
                      }
                      return false
                    }
                    message.success(
                      <FormattedMessage
                        id='forgottenPassword.mailSent'
                        defaultMessage='Mail envoyé'
                      />,
                    )
                    return true
                  },
                })
              }
            >
              <FormattedMessage
                id='forgottenPassword.sendMail'
                defaultMessage='Envoyer le mail'
              />
            </Button>
          </Col>
        </Row>
      </Drawer>
    </>
  )
}

export default Form.create({ name: 'forgottenPassword' })(ForgottenPassword)

/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  fromProducts,
  fromShops,
  fromSuppliers,
  fromBaseProducts,
  fromOrders,
  fromMembers,
  fromStocks,
  fromUsers,
  fromEntities,
  fromLocale,
} from '../../store/selectors'
import { sendManyOrders } from '../../store/orders/actions'
import { MultipleOrders } from '../../components'

const MultipleOrdersContainer = (props) => {
  const {
    selected = [],
    allProducts,
    shop,
    suppliers,
    sendOrders,
    allBps,
    isLoading,
    setLoading,
    history,
    errorDetails,
    hasError,
    members,
    groups,
    user,
    allStocks,
    entities,
    isAdmin,
    locale,
  } = props
  const concernedProducts = selected.length ? allProducts.filter((p) => selected.includes(String(p._id))) : allProducts
  const disabled = !selected.length
  return (
    <MultipleOrders
      isLoading={isLoading}
      products={concernedProducts}
      errorDetails={errorDetails}
      hasError={hasError}
      shop={shop}
      suppliers={suppliers}
      sendOrders={sendOrders}
      bps={allBps}
      setLoading={setLoading}
      history={history}
      disabled={disabled}
      members={members}
      groups={groups}
      user={user}
      allStocks={allStocks}
      entities={entities}
      isAdmin={isAdmin}
      locale={locale}
    />
  )
}

const mapStateToProps = (state) => ({
  allProducts: fromProducts.allProducts(state),
  shop: fromShops.getShop(state),
  suppliers: fromSuppliers.getSuppliers(state),
  allBps: fromBaseProducts.getBPs(state),
  isLoading: fromOrders.isLoading(state),
  hasError: fromOrders.getBatchError(state),
  errorDetails: fromOrders.getBatchErrorDetails(state),
  members: fromMembers.getMembers(state),
  groups: fromShops.shopMembersGroups(state),
  allStocks: fromStocks.getStocks(state),
  user: fromUsers.getUser(state),
  entities: fromEntities.getEntities(state),
  locale: fromLocale.getLocale(state),
})

const mapDispatchToProps = (dispatch) => ({
  sendOrders: ({
    orders, amount, paymentMethod, stripe, transaction_type, history,
  }) => dispatch(sendManyOrders({
    orders, amount, paymentMethod, stripe, transaction_type, history,
  })),
  setLoading: (batchLoading) => dispatch({ type: 'BATCH_LOADING', payload: { batchLoading } }),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultipleOrdersContainer))

// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
import { initialState } from './selectors'
import {
  LOADING,
  ERROR,
  RECEIVED_STOCK,
  MODIFY_STOCK,
  MODIFIED_STOCK,
} from './actions'

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case LOADING:
    return {
      ...state,
      loading: true,
    }
  case ERROR:
    return {
      ...state,
      error: true,
      loading: false,
    }
  case RECEIVED_STOCK:
    return {
      ...state,
      data: payload.data,
      loading: false,
      error: false,
    }
  case MODIFY_STOCK:
    return {
      ...state,
      data: state.data.map((s) => {
        if (s._id !== payload.stockId) return s
        return {
          ...s,
          being_modified: true,
        }
      }),
    }
  case MODIFIED_STOCK:
    return {
      ...state,
      data: state.data.map((s) => {
        if (s._id !== payload.stockId) return s
        return {
          ...s,
          being_modified: true,
          ...payload.modifications,
        }
      }),
    }

  default:
    return state
  }
}

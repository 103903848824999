import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Switch,
  Tag,
  message,
} from 'antd'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MODIFY_MEMBERS_CREDIT } from '../../store/members/actions'

const { Item } = Form
const { TextArea } = Input

const AddCreditDrawer = (props) => {
  const {
    visibleDrawer,
    hideDrawer,
    modifyMembersCredit,
    form: {
      getFieldDecorator,
      isFieldsTouched,
      resetFields,
      validateFieldsAndScroll,
    },
    selectedRowKeys,
    allMembers,
  } = props

  const [loading, setLoading] = useState(false)
  const [sendMailCheck, setSendMailCheck] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          credit,
          replace,
          sendMail,
          messageMail,
        } = values
        const data = {
          membersIds: selectedRowKeys.length ? selectedRowKeys : false,
          credit,
          replace,
          sendMail,
          messageMail,
        }

        modifyMembersCredit({
          data,
          callback: () => {
            setLoading(false)
            resetFields()
            hideDrawer()
            message.info(
              <FormattedMessage id='accountCredits.creditsAdded' defaultMessage='Crédits ajoutés !' />,
            )
            return true
          },
        })
      }
      setLoading(false)
    })
  }

  return (
    <Drawer
      title={
        <FormattedMessage id='accountCredits.title' defaultMessage='Ajouter des crédits' />
      }
      width={640}
      closable
      visible={visibleDrawer}
      onClose={hideDrawer}
    >
      <Form
        id='creditForm'
        onSubmit={handleSubmit}
        style={{ position: 'relative' }}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', margin: '5px 0',
        }}
        >
          <div style={{ color: 'black', margin: '0 5px 8px 0' }}>
            <FormattedMessage id='defineMembersEntity.selection' defaultMessage='Membre(s) sélectionné(s) :' />
          </div>
          <div style={{
            display: 'flex', flexWrap: 'wrap', maxHeight: '75vh', overflowY: 'scroll',
          }}
          >
            {selectedRowKeys.map((k) => {
              const member = allMembers.find((m) => m._id === k)
              return (
                <Tag style={{ margin: '0 5px 5px 0' }} key={k}>
                  {member?.name}
                  {' '}
                  {member?.lastName}
                </Tag>
              )
            })}
          </div>
        </div>
        <Item label={<FormattedMessage id='accountCredits.labels.credits' defaultMessage='Crédits' />}>
          {getFieldDecorator('credit', {
            rules: [
              {
                required: true,
                message: <FormattedMessage id='accountCredits.rules.amount' defaultMessage='Veuillez renseigner un montant de crédits à attribuer' />,
              },
            ],
          })(<InputNumber />)}
        </Item>
        <Item label={<FormattedMessage id='accountCredits.labels.replace' defaultMessage='Voulez vous remplacer leur crédit par cette valeur' />}>
          {getFieldDecorator('replace', { valuePropName: 'checked' })(<Switch />)}
        </Item>
        <Item label={<FormattedMessage id='accountCredits.labels.mail' defaultMessage='Envoyer un mail au(x) membre(s) concerné(s)' />}>
          {
            getFieldDecorator('sendMail', { valuePropName: 'checked' })(<Switch onChange={(checked) => setSendMailCheck(checked)} />)
          }
        </Item>
        {sendMailCheck
          ? (
            <Item label={<FormattedMessage id='accountCredits.labels.message' defaultMessage='Votre message personnalisé' />}>
              {
                getFieldDecorator('messageMail')(<TextArea rows={4} />)
              }
            </Item>
          ) : null}
        {isFieldsTouched() ? (
          <Button
            type='primary'
            htmlType='submit'
            style={{
              width: '100%',
              borderRadius: 0,
            }}
            disabled={loading}
            loading={loading}
            onClick={(e) => {
              setLoading(true)
              handleSubmit(e)
            }}
          >
            <FormattedMessage id='accountCredits.buttons.add' defaultMessage='Ajouter' />
          </Button>
        ) : null}
      </Form>
    </Drawer>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  modifyMembersCredit: (payload) => ({
    type: MODIFY_MEMBERS_CREDIT,
    payload,
  }),
}, dispatch)

const WrapperAddCreditDrawer = Form.create({ name: 'creditForm' })(AddCreditDrawer)

export default connect(null, mapDispatchToProps)(WrapperAddCreditDrawer)

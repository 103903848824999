import React from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import theme from '../../../config/theme'

const CampaignSaveButton = (props) => {
  const {
    campaign, // Required
    saveCampaign, // Required
    needUpdateCampaign, // Required
    needCreateCampaign,
  } = props

  const { products, donation = { active: false } } = campaign
  const { active } = donation

  const isSaveButtonDisabled = (!products?.length && !active) || (!needCreateCampaign && !needUpdateCampaign)

  return (
    <Button
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 14px',
        width: '40px',
        height: '40px',
      }}
      onClick={saveCampaign}
      disabled={isSaveButtonDisabled}
    >
      <FontAwesomeIcon
        icon={faFloppyDisk}
        style={{
          width: '14px',
          height: '14px',
          color: isSaveButtonDisabled ? theme.character.disabledPlaceholder : theme.character.title,
        }}
      />
    </Button>
  )
}

export default CampaignSaveButton

/* eslint-disable max-len */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Descriptions,
  Icon,
  Tag,
  Table,
  Collapse,
} from 'antd'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const { Panel } = Collapse

const columnsfamilies = [
  {
    title: <FormattedMessage id='pastille' defaultMessage='Pastille' />,
    key: 'pastille',
    width: '40%',
    render: (families) => (
      <div style={{ display: 'flex' }}>
        {families.colors.map((color) => (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '5px 10px',
            width: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            <img
              style={{
                width: '30px',
                height: '30px',
                marginBottom: 5,
              }}
              alt='example'
              src={`${imageBaseUrl}/Pastille/low/${color}.png`}
            />
            {color}
          </div>
        ))}
      </div>
    ),
  },
  {
    title: <FormattedMessage id='baseProductRecap.priceImpact' defaultMessage='Impact sur le prix (par famille)' />,
    width: '30%',
    render: (families) => (
      <div>{`${families.priceImpact} €`}</div>
    ),
  },
  {
    title: <FormattedMessage id='family' defaultMessage='Famille' />,
    dataIndex: 'idPanopli',
  },
]

const columnsTechnics = [
  {
    title: <FormattedMessage id='lastName' defaultMessage='Nom' />,
    dataIndex: 'name',
  },
  {
    title: 'MOQ',
    dataIndex: 'parameters.moq',
  },
  {
    title: <FormattedMessage id='stages' defaultMessage='Paliers' />,
    dataIndex: 'parameters.stage',
    key: 'stage',
    render: (stage) => (
      <div>
        {stage.map((s) => (
          <span>
            <FormattedMessage
              id='baseProductRecap.minimumQuantity'
              defaultMessage='à partir de {number} unités'
              values={{ number: s }}
            />
            <br />
          </span>
        ))}
      </div>
    ),
  },
  {
    title: <FormattedMessage id='baseProductRecap.sellingPriceExcl' defaultMessage='Prix de vente HT' />,
    key: 'cost',
    dataIndex: 'parameters.cost',
    render: (cost) => (
      <div>{cost.map((c) => (<div>{`${c} €`}</div>))}</div>
    ),
  },
  {
    title: <FormattedMessage id='margin' defaultMessage='Marge' />,
    key: 'marge',
    dataIndex: 'parameters.margins',
    render: (margins) => (
      <div>{(margins && margins.length && margins.map((m) => (<div>{`${m} %`}</div>))) || '-' }</div>
    ),
  },
  {
    title: <FormattedMessage id='baseProductRecap.price' defaultMessage='Palier de quantité avec prix' />,
    key: 'price',
    dataIndex: 'parameters.price',
    render: (price) => (
      <div>{price.map((p) => (<div>{`${p} €`}</div>))}</div>
    ),
  },
]

const BaseProductRecap = (props) => {
  try {
    const {
      product,
      errorCreation,
    } = props

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {errorCreation.length ? (
          <div style={{
            display: 'flex',
            border: '1px solid rgb(255, 135, 147)',
            backgroundColor: 'rgb(255, 230, 230)',
            color: 'rgba(0, 0, 0, 0.65)',
            borderRadius: '5px',
            padding: '10px 0px 0px 0px',
            flexWrap: 'wrap',
            marginBottom: '10px',
          }}
          >
            <div style={{
              width: '100%',
              marginLeft: '15px',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <Icon type='close-circle' style={{ marginRight: '10px', color: 'red', fontSize: '20px' }} />
              <span style={{ fontWeight: 600 }}>
                <FormattedMessage id='baseProductRecap.errorInCreation' defaultMessage='Erreur qui empêche la création du produit :' />
              </span>
            </div>
            <ul>
              {errorCreation.map((errorDisplay) => errorDisplay)}
            </ul>
          </div>
        ) : null}
        <Collapse defaultActiveKey={['1']}>
          <Panel
            header={(
              <div style={{ fontWeight: 'bold' }}>
                <FormattedMessage id='baseProductRecap.generalInformations' defaultMessage='Informations générales' />
              </div>
            )}
            key='1'
          >
            <Descriptions bordered column={4}>
              <Descriptions.Item
                span={2}
                label={(
                  <span>
                    <FormattedMessage id='lastName' defaultMessage='Nom' />
                    {' 🇫🇷'}
                  </span>
                )}
              >
                {product.name && product.name.fr}
              </Descriptions.Item>
              <Descriptions.Item
                span={2}
                label={(
                  <span>
                    <FormattedMessage id='lastName' defaultMessage='Nom' />
                    {' 🇬🇧'}
                  </span>
                )}
              >
                {product.name && product.name.en}
              </Descriptions.Item>
              <Descriptions.Item
                span={2}
                label={(
                  <span>
                    <FormattedMessage id='description' defaultMessage='Description' />
                    {' 🇫🇷'}
                  </span>
                )}
              >
                {product.description && product.description.fr}

              </Descriptions.Item>
              <Descriptions.Item
                span={2}
                label={(
                  <span>
                    <FormattedMessage id='description' defaultMessage='Description' />
                    {' 🇬🇧'}
                  </span>
                )}
              >
                {product.description && product.description.en}
              </Descriptions.Item>
              <Descriptions.Item
                span={2}
                label={(
                  <span>
                    <FormattedMessage id='category' defaultMessage='Catégorie' />
                    {' 🇫🇷'}
                  </span>
                )}
              >
                {product.category && product.category.fr && product.category.fr.length && product.category.fr.map((tag) => <Tag style={{ margin: '2px' }} key={tag}>{tag}</Tag>)}
              </Descriptions.Item>
              <Descriptions.Item
                span={2}
                label={(
                  <span>
                    <FormattedMessage id='category' defaultMessage='Catégorie' />
                    {' 🇬🇧'}
                  </span>
                )}
              >
                {product.category && product.category.en && product.category.en.length && product.category.en.map((tag) => <Tag style={{ margin: '2px' }} key={tag}>{tag}</Tag>)}
              </Descriptions.Item>
              <Descriptions.Item
                span={2}
                label={(
                  <span>
                    <FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />
                    {' 🇫🇷'}
                  </span>
                )}
              >
                {product.subCategory && product.subCategory.fr}
              </Descriptions.Item>
              <Descriptions.Item
                span={2}
                label={(
                  <span>
                    <FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />
                    {' 🇬🇧'}
                  </span>
                )}
              >
                {product.subCategory && product.subCategory.en}
              </Descriptions.Item>
              <Descriptions.Item span={4} label={<FormattedMessage id='sizes' defaultMessage='Tailles' />}>{product.sizes && product.sizes.length && product.sizes.map((tag) => <Tag style={{ margin: '2px' }} key={tag.name}>{tag.name}</Tag>)}</Descriptions.Item>
              <Descriptions.Item span={2} label={<FormattedMessage id='baseProductRecap.priceExcl' defaultMessage='Prix en HT' />}>{product.pricing && product.pricing.priceHT}</Descriptions.Item>
              <Descriptions.Item span={2} label={<FormattedMessage id='margin' defaultMessage='Marge' />}>{product.pricing && product.pricing.margin}</Descriptions.Item>
              <Descriptions.Item span={1} label={<FormattedMessage id='constitution' defaultMessage='Constitution' />}>{product.caracteristics && product.caracteristics.constitution}</Descriptions.Item>
              <Descriptions.Item span={1} label={<FormattedMessage id='baseProductRecap.kgWeight' defaultMessage='Poids en kg' />}>{product.caracteristics && product.caracteristics.weight}</Descriptions.Item>
              <Descriptions.Item span={1} label={<FormattedMessage id='baseProductRecap.literCapacity' defaultMessage='Contenance en litre' />}>{product.caracteristics && product.caracteristics.capacity}</Descriptions.Item>
              <Descriptions.Item span={1} label={<FormattedMessage id='productOrigin' defaultMessage='Origine du produit' />}>{product.caracteristics && product.caracteristics.originProduct}</Descriptions.Item>
              <Descriptions.Item span={1} label={<FormattedMessage id='originOfMarking' defaultMessage='Origine du marquage' />}>{product.caracteristics && product.caracteristics.originMark}</Descriptions.Item>
              {product.caracteristics && product.caracteristics.delayDelivery ? (
                <Descriptions.Item span={1} label={<FormattedMessage id='originOfMarking' defaultMessage='Origine du marquage' />}>
                  {`min: ${(product.caracteristics && product.caracteristics.delayDelivery && product.caracteristics.delayDelivery.min) || ''}`}
                  {` max: ${(product.caracteristics && product.caracteristics.delayDelivery && product.caracteristics.delayDelivery.max) || ''}`}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item span={2} label={<FormattedMessage id='tags' defaultMessage='Tags' />}>{product.tags && product.tags.length && product.tags.map((tag) => <Tag style={{ margin: '2px' }} key={tag}>{tag}</Tag>)}</Descriptions.Item>
              {product.picListToUpload && product.picListToUpload.length ? (
                <Descriptions.Item span={4} label={<FormattedMessage id='baseProductRecap.presentationImages' defaultMessage='Images de présentation' />}>
                  {product.picListToUpload.map((pic) => (
                    <img key={pic.name} src={pic.thumbUrl} alt={pic.name} style={{ width: '100px', margin: '0 0 0 5px' }} />
                  ))}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </Panel>
          {product.families && product.families.length && (
            <Panel
              header={(
                <div style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id='baseProductRecap.colorFamilies' defaultMessage='Familles de couleur' />
                </div>
              )}
              key='2'
            >
              <Table
                rowKey={(families) => families.idPanopli}
                columns={columnsfamilies}
                dataSource={product.families}
              />
            </Panel>
          )}
          {product.technics && product.technics.length && (
            <Panel
              header={(
                <div style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id='techniques' defaultMessage='Techniques' />
                </div>
              )}
              key='3'
            >
              <Table
                rowKey={(technic) => technic.idPanopli}
                columns={columnsTechnics}
                dataSource={product.technics}
              />
            </Panel>
          )}
          {(product.provider && (
            <Panel
              header={(
                <div style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id='baseProductRecap.supplierInformations' defaultMessage='Informations fournisseur' />
                </div>
              )}
              key='4'
            >
              <Descriptions bordered column={4}>
                <Descriptions.Item span={2} label={<FormattedMessage id='name' defaultMessage='Nom' />}>{product.provider.name}</Descriptions.Item>
                <Descriptions.Item span={2} label={<FormattedMessage id='supplierProductId' defaultMessage='ID du produit chez le fournisseur' />}>{product.providerProductId}</Descriptions.Item>
                <Descriptions.Item span={2} label={<FormattedMessage id='email' defaultMessage='Email' />}>{product.provider.email}</Descriptions.Item>
                <Descriptions.Item span={2} label={<FormattedMessage id='phone' defaultMessage='Téléphone' />}>{product.provider.phone}</Descriptions.Item>
                <Descriptions.Item span={2} label={<FormattedMessage id='websiteLink' defaultMessage='Lien du site' />}>{product.provider.websiteUrl}</Descriptions.Item>
                <Descriptions.Item span={2} label={<FormattedMessage id='baseProductRecap.URL' defaultMessage='URL générique de suivi de commande' />}>{product.provider.trackingURL}</Descriptions.Item>
              </Descriptions>
            </Panel>
          ))}
        </Collapse>
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/BaseProductRecap')
    return null
  }
}

export default BaseProductRecap

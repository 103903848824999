import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Values from 'values.js'
import theme from '../../../config/theme'

const ThemePreview = (props) => {
  const { isMobileFocused, campaign } = props

  const mainColor = new Values(campaign?.theme?.mainColor)
  const tintsMainColor = mainColor.all(20)
  const fontColor = new Values(campaign?.theme?.fontColor)
  const tintsFontColor = fontColor.all(20)
  const colors = {
    primary50: tintsMainColor[1].tint(50).hexString(),
    main300: tintsMainColor[3].hexString(),
    main500: mainColor.hexString(),

    font50: tintsFontColor[1].tint(50).hexString(),
    font500: fontColor.hexString(),
  }

  return (
    <div
      style={{
        borderRadius: '8px',
        boxShadow: '0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)',
        border: `1px solid  ${theme.neutralColor[2]}`,
        width: isMobileFocused ? '210px' : '516px',
        height: isMobileFocused ? '456px' : '322px',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: isMobileFocused ? '6px 8px 6px 8px' : '6px 16px 6px 16px',
        }}
      >
        <img src={campaign?.theme?.logo} alt='' style={{ maxHeight: '28px', width: 'auto' }} />
        <div
          style={{
            backgroundColor: colors?.main500,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '28px',
            height: '28px',
          }}
        >
          <FontAwesomeIcon icon={faBars} style={{ color: theme.white }} />
        </div>
      </div>
      <hr style={{
        backgroundColor: colors.main500, margin: '0px', height: '1px', border: '0',
      }}
      />
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors?.font50,
        }}
      >
        <div style={{
          borderRadius: '50%',
          width: isMobileFocused ? '190px' : '170px',
          height: isMobileFocused ? '190px' : '170px',
          backgroundColor: colors?.main500,
          position: 'absolute',
          inset: isMobileFocused ? '-70px auto auto -40px' : '-100px auto auto 100px',
          boxShadow: '0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)',
        }}
        />
        <div
          style={{
            borderRadius: '50%',
            width: '100px',
            height: '100px',
            backgroundColor: colors?.main300,
            position: 'absolute',
            inset: isMobileFocused ? '50px 0px auto auto' : '10px 120px auto auto',
            boxShadow: '0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)',
          }}
        />
        <img
          src={campaign?.theme?.logo}
          alt=''
          style={{
            maxHeight: '48px',
            width: 'auto',
            marginBottom: isMobileFocused ? '80px' : '50px',
          }}
        />
        <div
          style={{
            borderRadius: '50%',
            width: isMobileFocused ? '120px' : '100px',
            height: isMobileFocused ? '120px' : '100px',
            backgroundColor: theme.white,
            position: 'absolute',
            inset: isMobileFocused ? 'auto auto 110px 0px' : 'auto auto 55px 120px',
            boxShadow: '0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)',
          }}
        />
        <div
          style={{
            borderRadius: '50%',
            width: '80px',
            height: '80px',
            backgroundColor: colors?.font500,
            position: 'absolute',
            inset: isMobileFocused ? 'auto 16px 64px auto' : 'auto 160px 30px auto',
            boxShadow: '0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)',
          }}
        />
      </div>
    </div>
  )
}

export default ThemePreview

import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'
import { fromShops, fromProducts } from '../../store/selectors'
import { managePreOrderDrawer } from '../../store/products/actions'

// const { MODIFY_SEVERAL } = products

export default connect(
  (state) => ({
    shop: fromShops.getId(state),
    preOrderDrawerOpen: fromProducts.preOrderDrawer(state),
  }),
  (dispatch) => ({
    openDrawer: (value) => dispatch(managePreOrderDrawer(value)),
  }),
)(({ openDrawer }) => (
  <Button
    key='preOrder'
    onClick={() => openDrawer(true)}
  >
    <FormattedMessage id='preOrder' defaultMessage='Précommande' />
  </Button>
))

import React from 'react'
import { Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'

import { character } from '../../../config/theme'

import { CampaignProductCard } from '..'

const ProductStepGrid = (props) => {
  const {
    products, // Required
    removeSelectedProduct, // Required
    addSelectedProduct, // Required
    selectedProducts, // Required
    MAX_PRODUCTS_SELECT, // Required
    isDisabled = false,
    columnWidth = '20%',
  } = props

  const isLimitReached = () => selectedProducts.length >= MAX_PRODUCTS_SELECT

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '16px', height: '100%', overflowY: 'auto', padding: '0 8px',
    }}
    >
      <span style={{ fontSize: 16, color: character.disabledPlaceholder }}>
        {MAX_PRODUCTS_SELECT === 1 ? (
          <FormattedMessage id='products.selection.onlyOne' defaultMessage='Sélectionnez 1 produit' />
        ) : (
          <FormattedMessage
            id='products.selection.upTo'
            defaultMessage='Sélectionnez jusqu’à {number} produits'
            values={{ number: MAX_PRODUCTS_SELECT }}
          />
        )}
      </span>
      <Row type='flex' gutter={[16, 16]}>
        {products.map((product) => (
          <Col style={{ width: columnWidth }} key={product._id}>
            <CampaignProductCard
              product={product}
              addSelectedProduct={addSelectedProduct}
              removeSelectedProduct={removeSelectedProduct}
              isDisabled={isDisabled || (!selectedProducts.includes(product._id) && isLimitReached())}
              isSelected={!isDisabled && selectedProducts.includes(product._id)}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default ProductStepGrid

const search = (googleResponse = [], type) => googleResponse.find((elem) => elem.types.includes(type))

const formatAutoComplete = (googleResponse) => {
  // Google Place API: long_name et short_name correspondent à des formats (longs et courts)
  // de données comprises dans la 'place' retournée par l'API de Google à la sélection d'une adresse
  // https://developers.google.com/maps/documentation/places/web-service/details#PlaceDetailsResponses
  const streetNumber = search(googleResponse, 'street_number')?.long_name
  const streetName = search(googleResponse, 'route')?.long_name || ''
  const postalCode = search(googleResponse, 'postal_code')?.long_name || ''
  const country = search(googleResponse, 'country')?.short_name || 'FR'
  const city = search(googleResponse, 'locality')?.long_name || ''
  const streetAddress = streetNumber && streetName ? `${streetNumber} ${streetName}` : streetName
  return {
    streetAddress,
    postalCode,
    country,
    city,
  }
}

export default formatAutoComplete

// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
import { initialState } from './selectors'
import {
  FETCH,
  FETCH_FORCE,
  RECEIVED,
} from './actions'

export default (state = initialState, {
  type,
  payload = {},
}) => {
  const {
    invoices,
    upcoming,
    upcoming_items,
  } = payload
  switch (type) {
  case RECEIVED:
    return {
      ...state,
      invoices,
      upcoming,
      upcoming_items,
      loading: false,
      error: false,
    }
  case FETCH:
    return {
      ...state,
      error: false,
      loading: true,
    }
  case FETCH_FORCE:
    return {
      ...state,
      error: false,
      loading: true,
    }
  default:
    return state
  }
}

import React from 'react'
import {
  Form,
  Input,
  message,
  Button,
  Row,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'

const SEOForm = (props) => {
  const {
    form: {
      getFieldDecorator,
      isFieldsTouched,
      resetFields,
    },
    shopSettings,
  } = props

  const {
    seo = {},
  } = shopSettings

  const intl = useIntl()

  const handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
      },
      modifyShop,
      shop,
    } = props
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          title,
          description,
        } = values

        const modifications = {
          settings: {
            ...shop.settings,
            seo: {
              title,
              description,
            },
          },
        }

        modifyShop({
          shop_id: shop._id,
          modifications,
          callback: () => {
            resetFields()

            message.info(
              <FormattedMessage
                id='updateSuccess.shop'
                defaultMessage='Boutique mise à jour'
              />,
            )
            return true
          },
        })
      }
    })
  }

  // const prefixSelector = getFieldDecorator('prefix', {
  //   initialValue: '33',
  // })(
  //   <Select style={{ width: 70 }}>
  //     <Option value='86'>+86</Option>
  //     <Option value='87'>+87</Option>
  //   </Select>,
  // )
  // TODO better input phone

  return (
    <Form
      onSubmit={handleSubmit}

    >
      <Form.Item label={[
        <FormattedMessage
          id='title'
          defaultMessage='Titre'
        />,
      ]}
      >
        {getFieldDecorator('title', {
          rules: [
          ],
          initialValue: seo.title,
        })(<Input />)}
      </Form.Item>
      <Form.Item label={<FormattedMessage id='description' defaultMessage='Description' />}>
        {getFieldDecorator('description', {
          rules: [],
          initialValue: seo.description,
        })(<Input.TextArea
          rows={4}
          placeholder={intl.formatMessage({
            id: 'SEOForm.placeholder.deliveryInfos',
            defaultMessage: 'Entrez les informations utiles pour la récupération du colis',
          })}
        />)}
      </Form.Item>
      <Row>
        {isFieldsTouched() ? (
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{
                marginRight: '12px',
              }}
            >
              <FormattedMessage id='save' defaultMessage='Enregistrer' />
            </Button>
            <Button onClick={() => resetFields()}>
              <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
            </Button>
          </Form.Item>
        ) : null}
      </Row>
    </Form>
  )
}

export default Form.create({ name: 'shopBasicSettings' })(injectIntl(SEOForm))

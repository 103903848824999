export const initialState = {
  data: [],
  loading: false,
  error: false,
}

export const getDiscounts = (state = initialState) => state.data || []

export const loading = (state = initialState) => state.loading || false

export const error = (state = initialState) => state.error || false

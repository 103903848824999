import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Confetti from 'react-confetti'
import { InnerLayout } from '../../layouts'

class Success extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    const { history } = this.props
    const { location = {} } = history || {}
    const { orders = [] } = location.state || {}
    // this.setState({
    //   history: history
    // })

    // NOTE : en dur en attendant la version finale :
    const nbOOrders = orders.length

    return (
      <InnerLayout
        title={(
          <h3>
            <FormattedMessage
              id='success.pageTitle'
              defaultMessage='Confirmation de commandes'
            />
          </h3>
        )}
      >
        <h2
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
            height: '50%',
          }}
        >
          {nbOOrders && nbOOrders > 1 ? (
            <FormattedMessage
              id='success.confirmationMessage.plurial'
              defaultMessage="Merci, vos {number} commandes ont bien été prises en compte, on s'occupe de suite de les expédier !"
              values={{
                number: nbOOrders,
              }}
            />
          ) : (
            <FormattedMessage
              id='success.confirmationMessage.singular'
              defaultMessage="Merci, votre commande a bien été prise en compte, on s'occupe de suite de l'expédier !"
            />
          )}
        </h2>
        <Confetti />
      </InnerLayout>
    )
  }
}
export default withRouter(Success)

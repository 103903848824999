import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Drawer,
} from 'antd'
import {
  fromApiKeys,
} from '../../store/selectors'
import { CREATE, UPDATE, RESET } from '../../store/apiKeys/actions'
import { ApiKeyCreated, ApiKeyForm } from '../../components'

const ApiKeyDrawer = (props) => {
  const {
    apiKeyCreated,
    currentApiKey,
    setCurrentApiKey,
    drawerVisible,
    setDrawerVisible,
    createApiKey,
    updateApiKey,
    resetApiKey,
  } = props

  const [name, setName] = useState('')
  const [scopes, setScopes] = useState([])
  const [loading, setLoading] = useState(false)

  const isEdition = currentApiKey && currentApiKey._id
  useEffect(() => {
    if (isEdition) {
      setName(currentApiKey.name)
      setScopes(currentApiKey.scopes)
    }
  }, [currentApiKey._id])

  useEffect(() => setLoading(false), [apiKeyCreated])

  const onClose = () => {
    setDrawerVisible(false)
    resetApiKey()
    setCurrentApiKey(false)
  }

  const onSubmit = () => {
    const newApiKey = {
      name,
      scopes,
    }
    setLoading(true)
    if (isEdition) {
      updateApiKey({ id: String(currentApiKey._id), modifications: newApiKey })
      setCurrentApiKey(false)
      setDrawerVisible(false)
    } else {
      createApiKey(newApiKey)
    }
  }

  const onChangeCheckboxGroup = (event, scope, scopeName) => {
    const { target: { checked } } = event
    if (checked) {
      const newScopes = [...scopes]
      newScopes.push(`${scope.toLowerCase()}.${scopeName.toLowerCase()}`)
      setScopes(newScopes)
    } else {
      const newScopes = scopes.filter((_scope) => _scope !== `${scope.toLowerCase()}.${scopeName.toLowerCase()}`)
      setScopes(newScopes)
    }
  }

  const onChangeName = ({ target: { value } }) => setName(value)

  return (
    <Drawer
      placement='right'
      closable={false}
      maskClosable={false}
      onClose={onClose}
      visible={drawerVisible}
      width='30%'
    >
      <div style={{
        position: 'absolute',
        inset: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        opacity: loading ? 1 : 0,
        transition: 'opacity 0.3s',
        zIndex: loading ? 10 : 0,
      }}
      />
      <ApiKeyForm
        apiKeyCreated={apiKeyCreated}
        name={name}
        onChangeName={onChangeName}
        scopes={scopes}
        setScopes={setScopes}
        onChangeCheckboxGroup={onChangeCheckboxGroup}
        onClose={onClose}
        onSubmit={onSubmit}
        isEdition={isEdition}
      />
      <ApiKeyCreated
        apiKeyCreated={apiKeyCreated}
        onClose={onClose}
      />
    </Drawer>
  )
}

export default connect((state) => ({
  apiKeyCreated: fromApiKeys.getApiKeyCreated(state),
}),
(dispatch) => bindActionCreators({
  createApiKey: (payload, callback) => ({
    type: CREATE,
    payload,
    callback,
  }),
  updateApiKey: (payload) => ({
    type: UPDATE,
    payload,
  }),
  resetApiKey: () => ({ type: RESET }),
}, dispatch))(ApiKeyDrawer)

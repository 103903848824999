import React from 'react'
import { Size } from '../..'
import sortSizes from '../../../helpers/sortSizes'
import { checkedSizes } from '../../../helpers/getVariations'

const MultipleSize = (props) => {
  try {
    const {
      product, setSize, color, ref, that, sizes, stocks, entityId, cartContent = [],
    } = props
    const toSort = sortSizes(product?.variations?.filter((v) => v.type === 'size'))
    const result = checkedSizes(toSort, cartContent, color, product, stocks, entityId).map((s) => {
      const match = sizes.find((currentSize) => currentSize.size.reference === s.reference)
      return (
        <Size
          size={s}
          setSize={setSize}
          color={color}
          _id={String(product._id)}
          ref={ref}
          value={(match && match.quantity) || 0}
          that={that}
          key={s.name}
          unavailable={!s.available}
          product={product}
          quantityInCart={s.quantityInCart}
          stocks={stocks}
          entityId={entityId}
        />
      )
    })
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {result}
      </div>
    )
  } catch (error) {
    console.log('MultipleSize - error', error)
    return null
  }
}

export default MultipleSize

import {
  Button, Empty,
  Tabs,
} from 'antd'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { BaseProductForm, ProductOnDemandForm } from '..'
import DraggableTabs from '../../molecules/DraggableTabs'

const { TabPane } = Tabs

const MyCustomTabPane = (props) => {
  const {
    product,
    index,
    updateProduct,
    createProduct,
    createStock,
    allShops,
    shopId,
    removeProducts,
    createdIdBp,
    modifiedIdBp,
    history,
    loadingCreation,
    upload,
    createdId,
    baseProducts,
    createBaseProduct,
    modifyBaseProduct,
    allProviders,
    allTechnics,
    allFamilies,
    allCodes,
    allEntities,
    products,
    locale,
    colorChips,
  } = props
  return (
    // eslint-disable-next-line
    <TabPane {...props}>
      {product.newBaseProduct ? (
        <BaseProductForm
          product={product}
          updateProduct={updateProduct}
          upload={upload}
          removeProducts={removeProducts}
          createdIdBp={createdIdBp}
          modifiedIdBp={modifiedIdBp}
          history={history}
          loadingCreation={loadingCreation}
          baseProducts={baseProducts}
          createBaseProduct={createBaseProduct}
          modifyBaseProduct={modifyBaseProduct}
          allProviders={allProviders}
          allTechnics={allTechnics}
          allFamilies={allFamilies}
          products={products}
          colorChips={colorChips}
        />
      ) : (
        <ProductOnDemandForm
          baseProducts={baseProducts}
          product={product}
          index={index}
          updateProduct={updateProduct}
          upload={upload}
          createProduct={createProduct}
          createStock={createStock}
          allShops={allShops}
          shopId={shopId}
          removeProducts={removeProducts}
          createdId={createdId}
          history={history}
          loadingCreation={loadingCreation}
          allCodes={allCodes}
          allEntities={allEntities}
          locale={locale}
        />
      )}

    </TabPane>
  )
}

const TabHeader = (props) => {
  const { product } = props
  let title = ''
  if (product.newBaseProduct) {
    if (product.name && product.name.fr) {
      title = product.name.fr
    } else {
      title = ''
    }
  } else if (!product.name) {
    title = product.baseProduct.supplierBaseProductReference
  } else {
    title = product.name
  }
  return (
    <span id={product.provisionalId}>
      {title}
    </span>
  )
}

const ProductsTab = (props) => {
  const {
    products,
    pushProducts,
    removeProducts,
    updateProduct,
    upload,
    createProduct,
    createStock,
    createBaseProduct,
    modifyBaseProduct,
    allShops,
    shopId,
    createdId,
    createdIdBp,
    modifiedIdBp,
    history,
    loadingCreation,
    baseProducts,
    allProviders,
    currentProduct,
    setCurrentProduct,
    allTechnics,
    allFamilies,
    allCodes,
    allEntities,
    locale,
    colorChips,
  } = props

  useEffect(() => {
    const handleKeyPress = (e) => {
      e.stopPropagation()
      const nomTouche = e.key
      const currentIndex = products.map((p) => p.provisionalId)
        .indexOf(currentProduct.provisionalId)
      if (e.shiftKey && nomTouche === 'ArrowRight' && products[currentIndex + 1] && products[currentIndex + 1].provisionalId) {
        setCurrentProduct(products[currentIndex + 1 > products.length ? products.length : currentIndex + 1])
      }
      if (e.shiftKey && nomTouche === 'ArrowLeft' && products.length > 1) {
        setCurrentProduct(products[currentIndex - 1 < 0 ? 0 : currentIndex - 1])
      }
      if (nomTouche === '∆' && ((currentProduct && currentProduct.baseProduct) || products.length)) {
        // Même si event.key n'est pas 'Control' (par ex., 'a' is pressed),
        // event.ctrlKey peut être true si la touche Ctrl est pressée dans le même temps.
        const updatedProduct = products
          .find((product) => product.provisionalId === currentProduct.provisionalId || products[0].provisionalId)
        pushProducts([{
          ...updatedProduct,
          provisionalId: Math.random().toString(8),
        }])
      }
    }
    return () => document.removeEventListener('keydown', handleKeyPress)
  })

  if (!products.length) {
    return (
      <Empty
        style={{
          height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 'calc(50vh - 100px)',
        }}
        description={false}
      />
    )
  }
  return (
    <DraggableTabs
      type='editable-card'
      style={{ marginTop: '10px', height: '100%' }}
      id='productsTab'
      hideAdd
      activeKey={currentProduct.provisionalId || (products.find(Boolean) || {}).provisionalId}
      onEdit={removeProducts}
      tabBarExtraContent={(
        <Button
          style={{ marginRight: '25px' }}
          disabled={!!currentProduct.newBaseProduct}
          type='primary'
          onClick={() => {
            try {
              const updatedProduct = products
                .find((product) => product.provisionalId === (currentProduct.provisionalId || products[0].provisionalId))
              return pushProducts([{
                ...updatedProduct,
                provisionalId: Math.random().toString(8),
                existInDB: null,
              }])
            } catch (e) {
              console.log(e, 'error addToDraftProducts organisms/ProductsTab')
              return null
            }
          }}
        >
          <FormattedMessage id='duplicate' defaultMessage='Dupliquer' />
        </Button>
      )}
      onChange={(key) => setCurrentProduct(products.find((p) => p.provisionalId === key))}
    >
      {products.map((p, i) => (
        <MyCustomTabPane
          key={p.provisionalId}
          tab={(
            <TabHeader
              product={p}
              removeProducts={removeProducts}
            />
          )}
          product={p}
          index={i}
          updateProduct={updateProduct}
          upload={upload}
          createProduct={createProduct}
          createStock={createStock}
          allShops={allShops}
          shopId={shopId}
          removeProducts={removeProducts}
          createdId={createdId}
          createdIdBp={createdIdBp}
          modifiedIdBp={modifiedIdBp}
          history={history}
          loadingCreation={loadingCreation}
          baseProducts={baseProducts}
          createBaseProduct={createBaseProduct}
          modifyBaseProduct={modifyBaseProduct}
          allProviders={allProviders}
          allTechnics={allTechnics}
          allFamilies={allFamilies}
          allCodes={allCodes}
          allEntities={allEntities}
          products={products}
          locale={locale}
          colorChips={colorChips}
        />
      ))}
    </DraggableTabs>
  )
}

export default ProductsTab

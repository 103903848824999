import React from 'react'
import { Alert } from 'antd'

const MagicLinksSendingAlert = (props) => {
  try {
    const { children, onClose } = props
    return (
      <Alert
        message={(
          <div style={{
            display: 'flex', justifyContent: 'space-between', fontWeight: 500, paddingTop: '1px', gap: '20px',
          }}
          >
            {children}
          </div>
        )}
        type='error'
        showIcon
        closable
        onClose={onClose}
      />
    )
  } catch (e) {
    console.log('molecules/MagicLinksSendingAlert error:', e)
    return null
  }
}

export default MagicLinksSendingAlert

import React from 'react'
import { Typography, message } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import {
  StripeProvider,
  Elements,
} from 'react-stripe-elements'

import {
  AddPayment,
} from '..'
import {
  AccountForm,
  NotificationsForm,
  StreamlabsSettings,
  // LanguageForm,
  SecurityForm,
  ListPayments,
} from '../../components'
import { fromUsers, fromShops, fromPayments } from '../../store/selectors'
import {
  CREATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  DEFAULT_PAYMENT_METHOD,
} from '../../store/payments/actions'
import { MODIFY, MODIFY_FACTURATION, REINIT_PW_AUTHENTICATE } from '../../store/users/actions'
import { MODIFY as MODIFY_SHOP, TEST_NOTIFICATION } from '../../store/shops/actions'

import config from '../../config'

const { Title } = Typography

export default connect(
  (state) => ({
    userInfos: fromUsers.getUserInformations(state),
    shop: fromShops.getShop(state),
    email: fromUsers.getUserNotificationsSettings(state).email,
    allPaymentMethods: fromPayments.getPaymentMethods(state),
    paymentsLoading: fromPayments.loading(state),
  }),
  (dispatch) => bindActionCreators({
    modifyUser: (payload) => ({
      type: MODIFY,
      payload,
      callback:
      message.info(
        <FormattedMessage
          id='updateSuccess.account'
          defaultMessage='Compte mis à jour'
        />,
      ),
    }),
    modifyPassword: ({ newPassword, oldPassword, callback }) => ({
      type: REINIT_PW_AUTHENTICATE,
      payload: { newPassword, oldPassword, callback },
    }),
    modifyShop: (payload) => ({
      type: MODIFY_SHOP,
      payload,
      callback:
      message.info(
        <FormattedMessage
          id='updateSuccess.account'
          defaultMessage='Compte mis à jour'
        />,
      ),
    }),
    modifyFacturation: (payload) => ({
      type: MODIFY_FACTURATION,
      payload,
      callback:
      message.info(
        <FormattedMessage
          id='updateSuccess.account'
          defaultMessage='Compte mis à jour'
        />,
      ),
    }),
    testNotificationStreamlabs: (payload) => ({
      type: TEST_NOTIFICATION,
      payload,
      callback:
      message.info(
        <FormattedMessage
          id='account.testSent'
          defaultMessage='Test envoyé !'
        />,
      ),
    }),
    createPaymentMethod: (payload) => ({
      type: CREATE_PAYMENT_METHOD,
      payload,
    }),
    deletePaymentMethod: (payload) => ({
      type: DELETE_PAYMENT_METHOD,
      payload,
    }),
    defaultPaymentMethod: (payload) => ({
      type: DEFAULT_PAYMENT_METHOD,
      payload,
    }),
  }, dispatch),
)(({
  userInfos,
  modifyUser,
  modifyShop,
  shop,
  testNotificationStreamlabs,
  email,
  allPaymentMethods,
  deletePaymentMethod,
  defaultPaymentMethod,
  paymentsLoading,
  modifyPassword,
}) => (
  <div className='settingsBlock'>
    <Title id='personnel' level={3}>
      <FormattedMessage id='account.title.personnal' defaultMessage='Informations personnelles' />
    </Title>
    <AccountForm
      userInfos={userInfos}
      onSubmit={modifyUser}
    />

    <Title id='notification' level={3}>
      <FormattedMessage id='notifications' defaultMessage='Notifications' />
    </Title>
    <NotificationsForm
      userInfos={userInfos}
      notifications={email}
      onSubmit={modifyUser}
    />
    <Title id='payment' level={3}>
      <FormattedMessage id='account.title.payments' defaultMessage='Paiements enregistrés' />
    </Title>
    <StripeProvider apiKey={config.stripe_keys[config.stripe_status]}>
      <Elements>
        <div>
          <AddPayment />
          <ListPayments
            allPaymentMethods={allPaymentMethods}
            deletePaymentMethod={deletePaymentMethod}
            defaultPaymentMethod={defaultPaymentMethod}
            paymentsLoading={paymentsLoading}
          />
        </div>
      </Elements>
    </StripeProvider>
    <Title id='streamlabs' level={3}>Streamlabs</Title>
    <StreamlabsSettings
      shop={shop}
      modifyShop={modifyShop}
      testNotificationStreamlabs={testNotificationStreamlabs}
    />
    {
    /*
    <Title id='language' level={3}>
      <FormattedMessage id='language' defaultMessage='Langue' />
    </Title>
     <LanguageForm
      onSubmit={modifyUser}
    /> */
    }
    <Title id='security' level={3}>
      <FormattedMessage id='account.title.security' defaultMessage='Sécurité' />
    </Title>
    <SecurityForm
      onSubmit={modifyPassword}
    />
  </div>
))

// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
import { initialState } from './selectors'
import {
  GET_SHOP,
  RECEIVED_SHOP,
  RECEIVED_SHOPS,
  SHOP_NOT_FOUND,
  SHOP_UNAVAILABLE,
  CHANGE_SHOP,
  MODIFIED,
  MODIFY,
  CHECK_REFETCH,
} from './actions'

const choseShop = (shops = [], user = {}, id) => {
  try {
    if (id) {
      const shop = shops.find(s => s._id === id)
      if (shop) return shop
    }
    return shops.find(s => s.owner === user._id) || shops[0]
  } catch (error) {
    console.log('choseshop', { error })
    return shops[0]
  }
}

export default (state = initialState, { type, payload }) => {
  let shop
  switch (type) {
  case CHECK_REFETCH:
    return {
      ...state,
      shouldRefetchShop: payload.shouldRefetchShop,
    }
  case RECEIVED_SHOP:
    return {
      ...state,
      currentShop: payload.shop.data,
      isPreview: !!payload.isPreview,
      noMargin: !!payload.noMargin,
      loading: false,
      error: false,
    }
  case RECEIVED_SHOPS:
    shop = choseShop(payload.shops, payload.user, state.currentShopId)
    return {
      ...state,
      currentShop: shop,
      currentShopId: shop && String(shop._id),
      allShops: payload.shops,
      loading: false,
      error: false,
      lastFetched: new Date(),
    }
  case SHOP_NOT_FOUND:
    return {
      ...state,
      error: true,
      error_details: 'not_found',
      loading: false,
    }
  case SHOP_UNAVAILABLE:
    return {
      ...state,
      error: true,
      error_details: 'unavailable',
      loading: false,
    }
  case GET_SHOP:
    return {
      ...state,
      error: false,
      loading: true,
    }
  case MODIFY:
    return {
      ...state,
      being_modified: true,
      modifications: payload.modifications,
    }
  case MODIFIED:
    return {
      ...state,
      allShops: state.allShops.map((s) => {
        if (s._id === state.currentShop._id) {
          return {
            ...s,
            updatedAt: payload.updatedAt,
          }
        }
        return s
      }),
      currentShop: {
        ...state.currentShop,
        ...state.modifications,
        updatedAt: payload.updatedAt,
        modifications: [],
      },
      being_modified: false,
    }
  case CHANGE_SHOP:
    return {
      ...state,
      currentShop: state.allShops.find(s => String(s._id) === String(payload)),
      currentShopId: String(payload),
      error: false,
      loading: false,
    }
  default:
    return state
  }
}

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { Button, Icon } from 'antd'
import {
  fromEntities, fromCollaborators, fromUsers, fromShops, fromLocale,
} from '../../store/selectors'
import { USER_ENTITY, SEND_USER_MAIL } from '../../store/users/actions'
import { UsersContainer } from '..'
import { getUserEntityStatus } from '../../helpers/entities'
import { CREATE_ENTITY, MODIFY_ENTITY } from '../../store/entities/actions'
import {
  EntitiesList,
  EntityCreation,
  EntityEdition,
  UserCreation,
  Placeholder,
} from '../../components'

const EntitiesContainer = (props) => {
  const {
    entities,
    history,
    allCollaborators,
    sendMailCreationUser,
    createEntity,
    modifyEntity,
    setActions,
    user,
    shop,
    collaborator,
    adminCollaborator,
    locale = 'fr',
    resendUserCreationMail,
  } = props

  const viewType = collaborator || adminCollaborator ? 'users' : 'entities'

  const [visibleDrawerEditionEntity, setVisibleDrawerEditionEntity] = useState(false)
  const [entityEdition, setEntityEdition] = useState({})
  const [visibleDrawerCreationUser, setVisibleDrawerCreationUser] = useState(false)
  const [visibleDrawerCreationEntity, setVisibleDrawerCreationEntity] = useState(false)
  const [view, setView] = useState(viewType)
  const [pressetEntityId, setPressetEntityId] = useState(false)
  const [alreadySetupEntityId, setAlreadySetupEntityId] = useState(false)

  const status = getUserEntityStatus(user, shop)

  const actions = () => {
    const result = []
    if (status !== ('collaborator' || 'noEntity')) {
      result.push(
        <Button
          key='2'
          type='primary'
          disabled={!entities.length}
          onClick={() => {
            setPressetEntityId(false)
            setAlreadySetupEntityId(false)
            setVisibleDrawerCreationUser(true)
          }}
        >
          <FormattedMessage id='entities.newuser' defaultMessage='Créer un nouvel utilisateur' />
        </Button>,
      )
    }
    if (status === 'admin' || status === 'owner') {
      result.push(
        <Button key='3' onClick={() => setView(view === 'entities' ? 'users' : 'entities')}>
          { view === 'entities' ? <Icon type='user' /> : <Icon type='shop' />}
        </Button>,
      )
      result.unshift(
        <Button
          key='1'
          type='primary'
          onClick={() => {
            setPressetEntityId(false)
            setAlreadySetupEntityId(true)
            setVisibleDrawerCreationEntity(true)
          }}
        >
          <FormattedMessage id='entities.addNewEntity' defaultMessage='Ajouter une nouvelle Entitée' />
        </Button>,
      )
    }
    return result
  }
  setActions(actions())

  if (entities?.length === 0) {
    return (
      <>
        <Placeholder />
        <EntityCreation
          visibleDrawerCreationEntity={visibleDrawerCreationEntity}
          setVisibleDrawerCreationEntity={setVisibleDrawerCreationEntity}
          createEntity={createEntity}
          alreadySetupEntityId={alreadySetupEntityId}
        />
        <UserCreation
          visibleDrawerCreationUser={visibleDrawerCreationUser}
          setVisibleDrawerCreationUser={setVisibleDrawerCreationUser}
          entities={entities}
          sendMailCreationUser={sendMailCreationUser}
          setPressetEntityId={setPressetEntityId}
          pressetEntityId={pressetEntityId}
          alreadySetupEntityId={alreadySetupEntityId}
        />
      </>
    )
  }

  return (
    <div>
      {view === 'users' ? (
        <UsersContainer />
      ) : (
        <EntitiesList
          history={history}
          entities={entities}
          allCollaborators={allCollaborators}
          setVisibleDrawerEditionEntity={setVisibleDrawerEditionEntity}
          setEntityEdition={setEntityEdition}
          setVisibleDrawerCreationUser={setVisibleDrawerCreationUser}
          setPressetEntityId={setPressetEntityId}
          setAlreadySetupEntityId={setAlreadySetupEntityId}
          locale={locale}
          shop={shop}
          resendUserCreationMail={resendUserCreationMail}
        />
      )}
      <EntityCreation
        visibleDrawerCreationEntity={visibleDrawerCreationEntity}
        setVisibleDrawerCreationEntity={setVisibleDrawerCreationEntity}
        createEntity={createEntity}
        entities={entities}
        pressetEntityId={pressetEntityId}
        alreadySetupEntityId={alreadySetupEntityId}
      />
      <EntityEdition
        entityEdition={entityEdition}
        visibleDrawerEditionEntity={visibleDrawerEditionEntity}
        setVisibleDrawerEditionEntity={setVisibleDrawerEditionEntity}
        modifyEntity={modifyEntity}
        setEntityEdition={setEntityEdition}
      />
      <UserCreation
        visibleDrawerCreationUser={visibleDrawerCreationUser}
        setVisibleDrawerCreationUser={setVisibleDrawerCreationUser}
        entities={entities}
        sendMailCreationUser={sendMailCreationUser}
        setPressetEntityId={setPressetEntityId}
        pressetEntityId={pressetEntityId}
        alreadySetupEntityId={alreadySetupEntityId}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: fromUsers.getUser(state),
  loading: fromEntities.loading(state),
  error: fromEntities.error(state),
  entities: fromEntities.getEntities(state),
  allCollaborators: fromCollaborators.getCollaborators(state),
  shop: fromShops.getShop(state),
  locale: fromLocale.getLocale(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  sendMailCreationUser: (payload, callback, callbackError) => ({
    type: USER_ENTITY,
    payload,
    callback,
    callbackError,
  }),
  createEntity: (payload, callback) => ({
    type: CREATE_ENTITY,
    payload,
    callback,
  }),
  modifyEntity: (payload, callback) => ({
    type: MODIFY_ENTITY,
    payload,
    callback,
  }),
  resendUserCreationMail: (payload, callback) => ({
    type: SEND_USER_MAIL,
    payload,
    callback,
  }),
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesContainer)

import React from 'react'
import {
  Button,
} from 'antd'

const checkCreationProduct = (product, setCurrentStep) => {
  const errorCreationArray = []
  if (!product.name) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(1)} />
        Le nom est obligatoire
      </div>,
    )
  }

  if ((!product?.pricing?.marketValue || product?.pricing?.marketValue === 0)) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(1)} />
        La valeur marchande est obligatoire
      </div>,
    )
  }
  if (!product.variations || !product.variations.length || !product.variations.filter((variation) => variation.type === 'color').length) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(0)} />
        Une couleur minimun est obligatoire
      </div>,
    )
  } if (!product.variations || !product.variations.length || !product.variations.filter((variation) => variation.type === 'size').length) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(0)} />
        Une taille minimun est obligatoire
      </div>,
    )
  } if (product.baseProduct.printingZone && product.baseProduct.printingZone.front) {
    if (product.clientCustomization && product.clientCustomization.filter((custo) => custo.side === 'front').length) {
      if ((!product.previewFile || !product.previewFile.front || !product.previewFile.front.length)) {
        errorCreationArray.push(
          <div>
            <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(2)} />
            La preview front est obligatoire pour ce produit
          </div>,
        )
      }
      if (product.printingFile && product.printingFile.front && product.printingFile.front.length) {
        errorCreationArray.push(
          <div>
            <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(2)} />
            Veuillez supprimer le fichier d&apos;impression front, le produit n&apos;en a pas besoin car il a une customisation
          </div>,
        )
      }
    } else {
      if ((!product.printingFile || !product.printingFile.front || !product.printingFile.front.length)
        && product.previewFile && product.previewFile.front && product.previewFile.front.length
        && !(product.clientCustomization && product.clientCustomization.length)) {
        errorCreationArray.push(
          <div>
            <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(2)} />
            Le fichier d&apos;impression front est obligatoire pour ce produit
          </div>,
        )
      } if ((!product.previewFile || !product.previewFile.front || !product.previewFile.front.length)
        && product.printingFile && product.printingFile.front && product.printingFile.front.length) {
        errorCreationArray.push(
          <div>
            <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(2)} />
            La preview front est obligatoire pour ce produit
          </div>,
        )
      }
    }
  } if (product.baseProduct.printingZone && product.baseProduct.printingZone.back) {
    if (product.clientCustomization && product.clientCustomization.filter((custo) => custo.side === 'back').length) {
      if ((!product.previewFile || !product.previewFile.back || !product.previewFile.back.length)) {
        errorCreationArray.push(
          <div>
            <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(2)} />
            La preview back est obligatoire pour ce produit
          </div>,
        )
      }
      if (product.printingFile && product.printingFile.back && product.printingFile.back.length) {
        errorCreationArray.push(
          <div>
            <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(2)} />
            Veuillez supprimer le fichier d&apos;impression back, le produit n&apos;en a pas besoin car il a une customisation
          </div>,
        )
      }
    } else {
      if ((!product.printingFile || !product.printingFile.back || !product.printingFile.back.length)
    && product.previewFile && product.previewFile.back && product.previewFile.back.length
    && !(product.clientCustomization && product.clientCustomization.length)) {
        errorCreationArray.push(
          <div>
            <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(2)} />
            Le fichier d&apos;impression back est obligatoire pour ce produit
          </div>,
        )
      } if ((!product.previewFile || !product.previewFile.back || !product.previewFile.back.length)
    && product.printingFile && product.printingFile.back && product.printingFile.back.length) {
        errorCreationArray.push(
          <div>
            <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(2)} />
            La preview back est obligatoire pour ce produit
          </div>,
        )
      }
    }
  }
  console.log('errorCreationArray', errorCreationArray)
  return errorCreationArray
}

export default checkCreationProduct

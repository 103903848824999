import React from 'react'
import {
  Avatar,
  Button,
  Divider,
  InputNumber,
} from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import theme from '../../../config/theme'

const BudgetAddCreditModal = (props) => {
  try {
    const {
      setVisibleModal,
      addCredit,
      collaborator,
      allCampaigns,
      budgets,
      credit,
      setCredit,
      onAbort,
    } = props
    const campaigns = allCampaigns.filter((campaign) => campaign.userId === collaborator._id)
    const budget = budgets.find((_budget) => _budget.userId === collaborator._id)

    const newBalance = () => parseFloat(budget.balance) + parseFloat(credit)
    return (
      <>
        <div>
          <div style={{ padding: '16px 24px 1px 24px', backgroundColor: '#fbfcfd', borderRadius: '16px 16px 0 0' }}>
            <div style={{ width: '100%', textAlign: 'center', marginBottom: '8px' }}>
              <Avatar
                size={48}
                icon='user'
              />
            </div>
            <h3 style={{
              fontWeight: 'bold',
              textAlign: 'center',
              color: theme.neutralColor[6][12],
              margin: '0 0 20px 0',
            }}
            >
              <span style={{
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '24px',
                color: 'var #14191e',
              }}
              >
                {`${collaborator?.additionalInfos?.firstName || ''} ${collaborator?.additionalInfos?.lastName || ''}`}
              </span>
            </h3>
            <h4 style={{
              textAlign: 'center', margin: '0 0 20px 0',
            }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                color: '#4e647a',
                fontWeight: '400',
                gap: '8px',
                fontSize: '14px',
                lineHeight: '22px',
              }}
              >
                <FormattedMessage
                  id='campaigns'
                  defaultMessage='Campagnes'
                />
                {` : ${campaigns?.length || 0}`}
                <Divider type='vertical' />
                <FormattedMessage
                  id='campaignTracking.cards.creditSpent'
                  defaultMessage='Dépensés'
                />
                {` : ${budget?.creditSpent || 0}`}
                <Divider type='vertical' />
                <FormattedMessage
                  id='campaignTracking.cards.committedCredit'
                  defaultMessage='Engagés'
                />
                {` : ${budget?.committedCredit || 0}`}
              </div>
            </h4>
          </div>
          <div style={{ display: 'flex', gap: '16px', padding: '24px 24px 0px 24px' }}>
            <div style={{ flex: '1' }}>
              <h4 style={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '22px',
                color: '#14191E',
              }}
              >
                <FormattedMessage
                  id='campaigns.modal.addCredit.inputTitle'
                  defaultMessage='Ajouter des links'
                />
                <FontAwesomeIcon style={{ marginLeft: '4px', color: '#4E647A' }} icon={faCircleInfo} />
              </h4>
              <InputNumber
                style={{
                  width: '100%',
                  border: '1px solid #E0E5EA',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '8px',
                }}
                type='number'
                min='1'
                value={credit}
                onChange={(_credit) => {
                  if (!_credit) {
                    setCredit(1)
                  } else {
                    setCredit(parseInt(_credit, 10))
                  }
                }}
              />
            </div>
            <div>
              <h4 style={{ fontWeight: '500', fontSize: '14px', lineHeight: '22px' }}>
                <FormattedMessage
                  id='campaigns.modal.addCredit.newCreditSold'
                  defaultMessage='Solde de links'
                />
              </h4>
              <InputNumber
                style={{
                  border: '1px solid #E0E5EA',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '8px',
                }}
                type='number'
                disabled
                value={newBalance()}
              />
            </div>
          </div>
        </div>
        <div style={{
          padding: '0 24px',
        }}
        >
          <Divider />
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '0px 24px 16px 24px',
        }}
        >
          <Button
            style={{ fontWeight: 'bold', marginBottom: '12px' }}
            disabled={!credit || parseInt(credit, 10) < 1}
            type='primary'
            onClick={() => {
              addCredit({
                balance: parseInt(credit, 10),
                _id: budget._id,
              })
              setVisibleModal(false)
              setCredit(1)
            }}
          >
            <FormattedMessage
              id='campaignAdmin.action'
              defaultMessage='Ajouter des links'
            />
          </Button>
          <Button
            onClick={onAbort}
          >
            <FormattedMessage
              id='cancel'
              defaultMessage='Annuler'
            />
          </Button>
        </div>
      </>
    )
  } catch (e) {
    console.log('error molecules/BudgetAddCreditModal', e)
    return null
  }
}

export default injectIntl(BudgetAddCreditModal)

import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import ProductImage from '@balibart/product-image'
import { Tag, Icon } from 'antd'
import { sortBy } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Loader } from '..'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const titleStyle = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '22px',
  color: '#000000',
  textAlign: 'center',
}

// const Top3Dot = ({ onClick, ...rest }) => {
//   const {
//     index,
//     active,
//     data,
//   } = rest
//   const carouselItems = ['🥇', '🥈', '🥉']
//   // active is provided by this lib for checking if the item is active or not.
//   return data.length ? (
//     <button
//       className={active ? 'active' : 'inactive'}
//       onClick={() => onClick()}
//       type='button'
//       style={{
//         border: 'none',
//         backgroundColor: active ? 'lightgrey' : '#FFFFFF',
//         width: '33.33%',
//         fontSize: active ? '150%' : '75%',
//       }}
//     >
//       {React.Children.toArray(carouselItems)[index]}
//     </button>
//   ) : null
// }
//
// const CustomDot = ({ onClick, ...rest }) => {
//   const {
//     index,
//     active,
//     images,
//   } = rest
//   const carouselItems = images.map((image) => (
//     <img
//       alt={image.alt}
//       src={image.src}
//       key={Math.random()}
//       style={{
//         width: '100%',
//         margin: '4px 0',
//         borderRadius: '10px',
//       }}
//     />
//   ))
//   // active is provided by this lib for checking if the item is active or not.
//   return (
//     <button
//       className={active ? 'active' : 'inactive'}
//       onClick={() => onClick()}
//       type='button'
//       style={{
//         border: 'none',
//         backgroundColor: active ? 'lightgrey' : '#FFFFFF',
//         width: '20%',
//       }}
//     >
//       {React.Children.toArray(carouselItems)[index]}
//     </button>
//   )
// }

const getDataForCarousel = (baseProduct) => {
  if (baseProduct?.images?.length) return sortBy(baseProduct.images, 'position')[0]
  if (baseProduct?.picList?.length) return baseProduct.picList[0]
  if (baseProduct?.shopifyImages?.length) return baseProduct.shopifyImages[0]
  return [{ src: `${imageBaseUrl}/SVGS/${baseProduct?._id}/baseProduct/display.png` }]
}

const DashboardCarousel = (props) => {
  const {
    data, title, loading, isTop3, top3RangeSwitch, width = '25%',
  } = props
  const carouselItems = ['🥇', '🥈', '🥉']
  return (
    <div style={{
      background: '#FFFFFF',
      border: '1px solid #D5D5D5',
      position: 'relative',
      width,
      margin: '10px 5px',
      borderRadius: 3,
    }}
    >
      {loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
        >
          <Loader />
        </div>
      ) : (
        <Carousel
          showDots={false}
          responsive={responsive}
          infinite
          arrows
          dotListClass='custom-dot-list-style'
        >
          {data.length ? data?.map((product, index) => (
            isTop3
              ? (
                <div
                  key={Math.random()}
                  style={{
                    width: '100%',
                    maxHeight: '100%',
                    position: 'relative',
                  }}
                >
                  <ProductImage
                    base_url={imageBaseUrl}
                    product={product}
                    variation={product.favColor}
                    currentPrintingZone={product.favPz || 'front'}
                  />
                  <span style={{
                    position: 'absolute', top: 10, right: 10, fontSize: '28px',
                  }}
                  >
                    {carouselItems[index]}
                  </span>
                </div>
              )
              : (
                <div
                  key={Math.random()}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <img
                    alt={getDataForCarousel(product).alt}
                    src={getDataForCarousel(product).src}
                    key={Math.random()}
                    style={{
                      width: '100%',
                      maxHeight: '100%',
                    }}
                  />
                </div>
              )
          )) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                margin: '30px',
                minHeight: '100px',
              }}
            >
              <FormattedMessage id='dashboardCarousel.noOrder' defaultMessage="Aucune commande n'a été passée pour cette période." />
            </div>
          )}
        </Carousel>
      )}
      <div
        style={{
          left: 10,
          top: 10,
          position: 'absolute',
          zIndex: 1000,
        }}
      >
        <Tag style={{ ...titleStyle, borderRadius: '3px', padding: '2px 6px' }}>
          <Icon type='exclamation-circle' style={{ marginRight: '4px' }} />
          {title}
        </Tag>
      </div>
      {isTop3 && top3RangeSwitch ? (
        <div
          style={{
            width: '100%',
            position: 'absolute',
            bottom: 10,
            left: 10,
          }}
        >
          {top3RangeSwitch}
        </div>
      ) : null}
    </div>
  )
}

export default DashboardCarousel

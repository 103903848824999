// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import {
  put, call,
  takeEvery, select,
} from 'redux-saga/effects'
import * as actions from './actions'
import { fromUsers } from '../selectors'

export function* getProviders(api) {
  try {
    const result = yield call([api, api.get], '/providers', {
      headers: {
        mode: 'cors',
        authorization: yield select(fromUsers.getAuth),
      },
    })
    yield put({
      type: actions.RECEIVED_PROVIDERS,
      payload: {
        data: result.providers,
      },
    })
    return true
  } catch (e) {
    console.log('getProviders - error', e, e.message)
    return false
  }
}

export function* modifyProvider(api, action) {
  try {
    yield call([api, api.put], '/providers',
      {
        ...action.payload,
      }, {
        headers: {
          mode: 'cors',
          authorization: yield select(fromUsers.getAuth),
        },
      })
    yield put({
      type: actions.MODIFIED_PROVIDER,
    })
    yield put({
      type: actions.GET_PROVIDERS,
    })
    return true
  } catch (e) {
    console.log('modifyProvider - error', e)
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(actions.GET_PROVIDERS, getProviders, api)
  yield takeEvery(actions.MODIFY_PROVIDER, modifyProvider, api)
}

import React from 'react'
import {
  Input,
  Tooltip,
  Icon,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'

import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

import { ColorButton } from '../../atoms'

const HiddenCustomRollingMessage = (props) => {
  try {
    const {
      newCustom, setNewCustom, colorState, setColorState,
    } = props
    const { rollingMessage } = newCustom
    const {
      font, fontColor, backgroundColor,
    } = rollingMessage
    const intl = useIntl()
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage
            id='custom.rollingMessage'
            defaultMessage='Message déroulant'
          />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.rollingFont'
                defaultMessage='Police du message'
              />
            </div>
            <Tooltip
              title={(
                <>
                  <FormattedMessage
                    id='custom.fontInfo'
                    defaultMessage='Veuillez entrer un nom de police issu de Google Fonts :'
                  />
                  <a
                    href=' https://fonts.google.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: 'white' }}
                  >
                    {' '}
                    <Icon type='link' />
                  </a>
                </>
              )}
              placement='bottom'
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'custom.font',
                  defaultMessage: 'Police...',
                })}
                value={font}
                onChange={(e) => setNewCustom({ ...newCustom, rollingMessage: { ...rollingMessage, font: e.target.value } })}
              />
            </Tooltip>
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.rollingFontColor'
                defaultMessage='Couleur de police'
              />
            </div>
            <ColorButton
              color={fontColor}
              onClick={() => setColorState({
                ...colorState,
                currentColor: fontColor,
                groupName: 'rollingMessage',
                propName: 'fontColor',
              })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.rollingBackgroundColor'
                defaultMessage="Couleur de l'arrière plan"
              />
            </div>
            <ColorButton
              color={backgroundColor}
              onClick={() => setColorState({
                ...colorState,
                currentColor: backgroundColor,
                groupName: 'rollingMessage',
                propName: 'backgroundColor',
              })}
            />
          </div>
          <div style={columnStyle} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecule/HiddenCustomRollingMessage error:', e)
    return null
  }
}

export default injectIntl(HiddenCustomRollingMessage)

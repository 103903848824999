import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 500 : -500,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? 500 : -500,
    opacity: 0,
  }),
}

const CarouselScreen = (props) => {
  const {
    images, index, style, imageStyle,
  } = props
  const [page, direction] = index

  return (
    <div style={{
      position: 'relative', overflow: 'hidden', flexShrink: 0, ...style,
    }}
    >
      <AnimatePresence mode='wait' initial={false} custom={direction}>
        <motion.img
          key={page}
          src={images[page]?.src}
          custom={direction}
          variants={variants}
          initial='enter'
          animate='center'
          exit='exit'
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            ...imageStyle,
          }}
        />
      </AnimatePresence>
    </div>
  )
}

export default CarouselScreen

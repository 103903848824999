import React from 'react'
import { CGU } from '../../helpers/legals'

const TOS = (props) => {
  const { locale = 'fr' } = props
  return (
    <>
      {CGU[locale]}
    </>
  )
}

export default TOS

import { Input } from 'antd'
import React from 'react'
import { injectIntl, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { character, neutralColor } from '../../../config/theme'

const CampaignSearchMagicLink = (props) => {
  const {
    setFilters, // Required
    filters, // Required
  } = props

  const intl = useIntl()

  return (
    <Input
      className='searchAdminInput'
      prefix={(
        <FontAwesomeIcon
          style={{
            color: character.disabledPlaceholder,
            width: '16px',
            height: '16px',
            marginRight: '12px',
          }}
          icon={faMagnifyingGlass}
        />
      )}
      style={{
        flex: '1',
        border: `1px solid ${neutralColor[3]}`,
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        borderRadius: '8px',
      }}
      placeholder={intl.formatMessage({
        id: 'campaignTracking.shipmentSearch',
        defaultMessage: 'Rechercher un envoi',
      })}
      onChange={(e) => setFilters({ ...filters, search: e.target.value })}
    />
  )
}

export default injectIntl(CampaignSearchMagicLink)

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Drawer, Table, Tag, Button,
} from 'antd'

import { getColumnSearchProps, filterData } from '../../../helpers/filters'
import { trimRecordForMembersTable } from '../../../helpers/trim'

const MembersForm = (props) => {
  const {
    visible,
    members,
    setRowsFromMembers,
    setMemberFormState,
    groups,
    selectedRowKeys,
    setSelectedRowKeys,
    locale,
  } = props

  const [filters, setFilters] = useState({})

  const setFiltering = (object) => {
    setFilters({ ...filters, ...object })
  }

  return (
    <Drawer
      title={<FormattedMessage id='batchOrdersMembersForm.title' defaultMessage='Sélection des membres' />}
      placement='right'
      closable
      visible={visible}
      onClose={() => setMemberFormState(false)}
      width={1400}
    >
      <MembersTable
        groups={groups}
        filters={filters}
        members={members}
        filtering={setFiltering}
        setRowsFromMembers={setRowsFromMembers}
        locale={locale}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <div style={{ textAlign: 'center' }}>
        <Button onClick={() => setMemberFormState(false)} type='primary'>
          <FormattedMessage id='ok' defaultMessage='Ok' />
        </Button>
      </div>
    </Drawer>
  )
}

const MembersTable = (props) => {
  const {
    members,
    filters,
    filtering,
    setRowsFromMembers,
    groups,
    selectedRowKeys,
    setSelectedRowKeys,
    locale,
  } = props

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys, rows) => {
      setSelectedRowKeys(keys)
      setRowsFromMembers(rows.map(({
        name, lastName, email, address, complement, city, postalCode, country, phone, company,
      }) => (
        {
          firstName: name,
          lastName,
          email,
          streetAddress: address,
          complement,
          city,
          postalCode,
          country,
          phone,
          company,
        })))
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  }

  const columns = [{
    title: <FormattedMessage id='firstName' defaultMessage='Prénom' />,
    dataIndex: 'formatted.name',
    key: 'name',
    ...getColumnSearchProps('name', filtering),
  }, {
    title: <FormattedMessage id='lastName' defaultMessage='Nom' />,
    dataIndex: 'formatted.lastName',
    key: 'lastName',
    ...getColumnSearchProps('lastName', filtering),
  }, {
    title: <FormattedMessage id='email' defaultMessage='Email' />,
    dataIndex: 'formatted.email',
    key: 'email',
    ...getColumnSearchProps('email', filtering),
  }, {
    title: <FormattedMessage id='address' defaultMessage='Adresse' />,
    dataIndex: 'formatted.address',
    key: 'address',
    ...getColumnSearchProps('address', filtering),
  }, {
    title: <FormattedMessage id='postalCode' defaultMessage='Code postal' />,
    dataIndex: 'formatted.postalCode',
    key: 'postalCode',
    ...getColumnSearchProps('postalCode', filtering),
  }, {
    title: <FormattedMessage id='city' defaultMessage='Ville' />,
    dataIndex: 'formatted.city',
    key: 'city',
    ...getColumnSearchProps('city', filtering),
  }, {
    title: <FormattedMessage id='groups' defaultMessage='Groupes' />,
    dataIndex: 'groups',
    key: 'groups',
    ...getColumnSearchProps('groups', filtering, {
      render: (membersGroups) => membersGroups.map((group) => {
        const concernedGroup = (groups && groups.find((g) => g.name === group)) || {}
        return (
          <Tag color={concernedGroup.color} key={Math.random()}>
            {group}
          </Tag>
        )
      }),
    }),
  }]

  const trimData = members.map((m, index) => {
    const trimmed = trimRecordForMembersTable(m, locale)
    return { ...trimmed, key: index }
  })
  const filteredData = filterData(trimData, filters)

  return (
    <>
      <Table
        dataSource={filteredData}
        columns={columns}
        rowSelection={rowSelection}
      />
    </>
  )
}

export default MembersForm

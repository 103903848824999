export const GET = 'GET_BPS'
export const RECEIVED = 'RECEIVED_BPS'
export const ERROR = 'ERROR_BPS'
export const CREATE_BASEPRODUCT = 'CREATE_BASEPRODUCT'
export const MODIFY_BASEPRODUCT = 'MODIFY_BASEPRODUCT'
export const MODIFIED_BASEPRODUCT = 'MODIFIED_BASEPRODUCT'
export const MODIFY_BASEPRODUCT_ERROR = 'MODIFY_BASEPRODUCT_ERROR'
export const CREATED = 'CREATED'
export const CREATE_ERROR = 'CREATE_ERROR'
export const UPLOAD_TO_SHOPIFY = 'UPLOAD_TO_SHOPIFY'
export const FETCH_FORCE = 'FETCH_FORCE'
export const RECEIVED_FORCE = 'RECEIVED_FORCE'
export const UPLOADED_TO_SHOPIFY = 'UPLOADED_TO_SHOPIFY'
export const SHOPIFY_ERROR = 'SHOPIFY_ERROR'
export const SHOPIFY_REQUIRE_ACTION = 'SHOPIFY_REQUIRE_ACTION'
export const CLOSE_STATUS_MODIFICATION_MODAL = 'CLOSE_STATUS_MODIFICATION_MODAL'
export const INIT_FETCH_DATA_CATALOG = 'INIT_FETCH_DATA_CATALOG'
export const END_FETCH_DATA_CATALOG = 'END_FETCH_DATA_CATALOG'
export const INIT_FETCH_DATA_WORKSHOP = 'INIT_FETCH_DATA_WORKSHOP'
export const END_FETCH_DATA_WORKSHOP = 'END_FETCH_DATA_WORKSHOP'

export const closeShopifyActionModal = () => ({
  type: SHOPIFY_REQUIRE_ACTION,
  payload: {
    requireAction: false,
  },
})

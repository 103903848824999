import React from 'react'
import { FormStepTextarea } from '../..'

const CampaignDescriptionInput = (props) => {
  const {
    campaign, // Required
    setCampaign, // Required
    setNeedUpdateCampaign, // Required
    createdCampaignId, // Required
    isDisabled = false,
  } = props

  const DESCRIPTION_LIMIT_LENGTH = 100

  const onChangeDescription = (event) => {
    try {
      setCampaign({ ...campaign, description: event.target.value })
      if (createdCampaignId) {
        setNeedUpdateCampaign(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <FormStepTextarea
      titleMessageId='campaigns.create.step.parameters.description'
      titleDefaultMesage='Description de la campagne'
      placeholderMessageId='campaigns.create.step.parameters.description.placeholder'
      placeholderDefaultMessage='Campagne de closing à destination des équipes Marketing'
      onChangeFunction={onChangeDescription}
      textAreaValue={campaign?.description}
      currentDescriptionLength={campaign?.description?.length}
      descriptionLimitLength={DESCRIPTION_LIMIT_LENGTH}
      isOptional
      disabled={isDisabled}
    />
  )
}

export default CampaignDescriptionInput

// https://github.com/diegohaz/arc/wiki/Selectors
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
export const initialState = {
  loading: false,
  error: false,
  tokenManagement: {},
  data: {
    shops: [],
    notificationSettings: {
      email: {
        blockToBeValidated: false,
        sendAutoValidated: false,
      },
      app: {},
      push: {},
    },
  },
}

export const getError = (state = initialState) => state.error
export const getLoading = (state = initialState) => state.loading

export const getUser = (state = initialState) => {
  try {
    return state.data
  } catch (error) {
    console.log('getUser error', { error })
    return {}
  }
}

export const getUserId = (state = initialState) => {
  try {
    return state.data._id
  } catch (error) {
    console.log('getUserId error', { error })
    return {}
  }
}
export const isAdmin = (state = initialState) => {
  try {
    const result = !!(getUser(state).admin)
    return result
  } catch (error) {
    console.log('isAdmin error', { error })
    return false
  }
}

export const getUserInformations = (state = initialState) => {
  try {
    const {
      email,
      phone,
      additionalInfos,
      newsletter,
    } = state.data
    return {
      ...additionalInfos,
      email,
      phone,
      newsletter,
    }
  } catch (error) {
    console.log('getUserInformations error', { error })
    return {}
  }
}

export const getFacturation = (state = initialState) => {
  try {
    return state.data.facturation
  } catch (error) {
    console.log('getUser error', { error })
    return {}
  }
}

// TODO type definitions UserState & NotificationsSettings
/**
 * @return {NotificationsSettings} the user notifications settings
 * @param {UserState} state the user state
 */
export const getUserNotificationsSettings = (state = initialState) => {
  try {
    const {
      notificationSettings = {
        email: {},
        app: {},
        push: {},
      },
    } = state.data
    return notificationSettings
  } catch (error) {
    console.log('getUser error', { error })
    return initialState.notificationSettings
  }
}

export const isLoggedIn = (state = initialState) => {
  try {
    const hasTokenInformation = !!(Object.keys(state.tokenManagement).length)
    const result = !state.loading && !state.error && !state.data.pristine && hasTokenInformation && !!state.data._id
    return result
  } catch (error) {
    console.log('isLoggedIn', { error })
    return false
  }
}

export const getTokenManagement = (state = initialState) => state.tokenManagement

export const getAuth = (state = initialState) => getTokenManagement(state).authToken

export const getTTL = (state = initialState) => getTokenManagement(state).ttl
export const getRefreshToken = (state = initialState) => getTokenManagement(state).refreshToken
export const getTokenCreationDate = (state = initialState) => getTokenManagement(state).lastEmittedAt

const isExpired = (tokenEmitionDate, ttl, securityPeriod = 0) => {
  const parsedDate = new Date(tokenEmitionDate)
  const expiration = new Date().getTime() - parsedDate.getTime() > (ttl * 1000) - (securityPeriod * 1000)
  return expiration
}

const willExpire = (tokenEmitionDate, ttl, securityPeriod) => isExpired(tokenEmitionDate, ttl, securityPeriod)

export const checkTokenExpiration = (state = initialState) => {
  try {
    const ttl = getTTL(state)
    const tokenEmitionDate = getTokenCreationDate(state)
    return {
      tokenIsExpired: isExpired(tokenEmitionDate, ttl),
      tokenWillExpireSoon: willExpire(tokenEmitionDate, ttl / 2),
    }
  } catch (e) {
    return false
  }
}

export const availableShops = (state = initialState) => {
  try {
    return state.data.shops
  } catch (error) {
    console.log('availableshops', { error })
    return []
  }
}

export const getStripeId = (state = initialState) => state.data.stripeCustomerId || ''

export const hasAccessToProductCreation = (state = initialState) => state.data.admin || state.data.hasAccessToProductCreation

export const getTempUser = (state) => state.tempUser || false

export const getErrorEntity = (state) => state.errorEntity || false

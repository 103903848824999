import axios from 'axios'
// NOTE signed AWS upload
export const signedUpload = (file, signedRequest, options) => {
  try {
    const appliedOptions = {
      encoding: 'base64',
      hideToast: false,
      ...options,
    }

    const req = {
      url: signedRequest,
      method: 'PUT',
      data: file,
      headers: {
        'Content-Type': file.type,
      },
      cache: 'default',
    }
    if (appliedOptions.encoding) {
      req.ContentEncoding = appliedOptions.encoding
    }
    return axios(req)
  } catch (error) {
    console.log('signedUpload error :', error)
    return false
  }
}

export default signedUpload

import React from 'react'
import {
  Form,
  Button,
  Col,
  Divider,
  message,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import Sortable from 'sortablejs'
import OrganizationEditor from '../OrganizationEditor'
import OrganizationFilters from '../OrganizationFilters'
import { DecorativeBlockManager } from '../../molecules'

class OrganizationGrid extends React.Component {
  constructor(props) {
    super(props)

    const {
      shopSettings: {
        listingOrder = [],
      },
    } = props

    this.state = {
      touched: false,
      listing: listingOrder,
      editedDesign: null,
      isDesignDrawerOpen: false,
    }
  }

  resetGrid = () => {
    const {
      shopSettings: {
        listingOrder,
      },
    } = this.props

    const grid = document.getElementById('supergrid')

    if (grid) {
      Sortable?.get(grid)?.sort(listingOrder)
      this.setState({
        touched: false,
      })
    }
  }

  closeDesignDrawer = () => {
    this.setState({
      isDesignDrawerOpen: false,
      // design: null,
    })
  }

  openDesignDrawer = () => {
    this.setState({
      isDesignDrawerOpen: true,
      // design: null,
    })
  }

  editDesign = (editedDesign) => {
    this.setState({
      isDesignDrawerOpen: true,
      editedDesign,
    })
  }

  deleteCustomBlock = (id, callback) => {
    try {
      const {
        shop,
        modifyShop,
        shopSettings,
        shopSettings: {
          listingOrder = [],
          listingElements = [],
        },
      } = this.props

      const modifications = {
        settings: {
          ...shopSettings,
          listingOrder: listingOrder.filter((listingItem) => listingItem !== id),
          listingElements: listingElements.filter((listingItem) => listingItem._id !== id),
        },
      }

      modifyShop({
        shop_id: shop._id,
        modifications,
        callback: () => {
          message.info(
            <FormattedMessage
              id='updateSuccess.shop'
              defaultMessage='Boutique mise à jour'
            />,
          )
          this.setState({
            touched: false,
            isDesignDrawerOpen: false,
            editedDesign: null,
          })
          if (callback) {
            callback()
          }
          return true
        },
      })
      return true
    } catch (error) {
      console.log('OrganizationGrid - DelteCustomBlock', { error })
      return false
    }
  }

  saveOrder = () => {
    const {
      listing,
    } = this.state

    const {
      shop,
      modifyShop,
    } = this.props

    const modifications = {
      settings: {
        ...shop.settings,
        listingOrder: listing,
      },
    }

    modifyShop({
      shop_id: shop._id,
      modifications,
      callback: () => {
        message.info(
          <FormattedMessage
            id='updateSuccess.shop'
            defaultMessage='Boutique mise à jour'
          />,
        )
        this.setState({ touched: false })
        return true
      },
    })
  }

  setNewIndex = (id, position) => {
    const {
      listing,
    } = this.state

    const newListing = listing
      .filter((el) => el !== id)
      //
    newListing.splice(position, 0, id)

    this.setState({
      touched: true,
      listing: newListing,
    })
  }

  setListingOrder = (listing) => {
    this.setState({
      touched: true,
      listing,
    })
  }

  render() {
    try {
      const {
        allProducts,
        shopSettings,
        viewport,
        listingView,
        metaDisplay,
        modifyShop,
        upload,
        shop,
        createDesign,
      } = this.props

      const {
        listingOrder = [],
      } = shopSettings

      const {
        touched,
        isDesignDrawerOpen,
        editedDesign,
        listing,
      } = this.state

      // const updatedProducts = all_products.map((product) => {
      //   return {
      //     ...product,
      //     timesOrdered: orders
      //       .filter(o => o.products.find(p => p.id === product._id))
      //       .reduce((acc, order) => order.products.filter(p => p.id === String(product._id))
      //         .reduce((ac, p) => p.quantity + ac, 0) + acc, 0),
      //   }
      // })

      const getRightSpan = () => {
        switch (viewport) {
        case 'mobile':
          return 6
        case 'tablet':
          return 12
        case 'desktop':
          return 18
        default:
          return 18
        }
      }

      return (
        <>
          <Col span={6} style={{ paddingLeft: '24px' }}>
            {touched ? (
              <>
                <Form.Item>
                  <Button
                    type='primary'
                    block
                    onClick={this.saveOrder}
                    style={{
                      marginRight: '12px',
                    }}
                  >
                    <FormattedMessage id='save' defaultMessage='Enregistrer' />
                  </Button>
                  <Button block onClick={() => this.resetGrid()}>
                    <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
                  </Button>
                </Form.Item>
                <Divider />
              </>
            ) : null}
            <OrganizationFilters
              setListingOrder={this.setListingOrder}
              items={allProducts}
            />
            <Divider />
            <h3>
              <FormattedMessage
                id='organization.blocksHelper'
                defaultMessage='Blocs personnalisés'
              />
            </h3>
            <Button
              type='primary'
              block
              onClick={this.openDesignDrawer}
            >
              <FormattedMessage
                id='organization.addBlock'
                defaultMessage='Ajouter un bloc décoratif'
              />
            </Button>
          </Col>
          <Col
            id='rightContainer'
            span={getRightSpan(viewport)}
            style={{
              padding: '0 24px 24px 24px',
              overflowY: 'auto',
            }}
          >
            <OrganizationEditor
              items={allProducts}
              viewport={viewport}
              listingView={listingView}
              deleteCustomBlock={this.deleteCustomBlock}
              editDesign={this.editDesign}
              setNewIndex={this.setNewIndex}
              showMeta={metaDisplay}
              shopSettings={shopSettings}
              sortingArray={listingOrder}
              currentListing={listing}
              setListingOrder={this.setListingOrder}
            />
          </Col>
          <DecorativeBlockManager
            createDesign={createDesign}
            deleteCustomBlock={this.deleteCustomBlock}
            editedDesign={editedDesign}
            modifyShop={modifyShop}
            onClose={this.closeDesignDrawer}
            opened={isDesignDrawerOpen}
            setNewIndex={this.setNewIndex}
            shop={shop}
            shopSettings={shopSettings}
            upload={upload}
          />
        </>
      )
    } catch (error) {
      console.log('OrganizationGrid - render', { error })
      return null
    }
  }
}

export default OrganizationGrid

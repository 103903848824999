import React from 'react'
import {
  Form, Radio,
} from 'antd'
import { FormattedMessage } from 'react-intl'

const CheckoutFormDeliveryMode = ({ type = 'client', getFieldDecorator, onChangeShippingMode }) => (
  <Form.Item className='shippingMode' style={{ margin: 0 }}>
    {getFieldDecorator(`${type}.shippingMode`, {
      rules: [
        {
          required: true,
          message: <FormattedMessage id='checkoutForm.required.delivery' defaultMessage='Veuillez choisir votre type de livraison' />,
          whitespace: true,
        },
      ],
      initialValue: 'standard',
    })(
      <Radio.Group onChange={onChangeShippingMode}>
        <Radio value='standard'>
          <FormattedMessage id='checkoutForm.deliveryMode.standart' defaultMessage='Standard' />
        </Radio>
        <Radio value='pickup'>
          <FormattedMessage id='checkoutForm.deliveryMode.pickupPoint' defaultMessage='En Point Relais' />
        </Radio>
      </Radio.Group>,
    )}
  </Form.Item>
)

export default CheckoutFormDeliveryMode

import React from 'react'
import {
  Form,
  Input,
  message,
  Button,
  Row,
} from 'antd'
import { FormattedMessage } from 'react-intl'

class AuthMembersForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
        resetFields,
      },
      modifyShop,
      shop,
      shopSettings,
    } = this.props
    const {
      auth = {},
    } = shopSettings
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          googleClientId,
          microsoftClientId,
        } = values
        const modifications = {
          settings: {
            ...shopSettings,
            auth: {
              ...auth,
              googleClientId,
              microsoftClientId,
            },
          },
        }
        modifyShop({
          shop_id: shop._id,
          modifications,
          callback: () => {
            resetFields()
            message.info(
              <FormattedMessage
                id='updateSuccess.shop'
                defaultMessage='Boutique mise à jour'
              />,
            )
            return true
          },
        })
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        isFieldsTouched,
        resetFields,
      },
      shopSettings,
    } = this.props

    const {
      auth = {},
    } = shopSettings

    return (
      <Form
        onSubmit={this.handleSubmit}
      >
        <Form.Item label='Google Client Id'>
          {getFieldDecorator('googleClientId', {
            initialValue: auth.googleClientId || '',
          })(<Input />)}
        </Form.Item>
        <Form.Item label='Microsoft Client Id'>
          {getFieldDecorator('microsoftClientId', {
            initialValue: auth.microsoftClientId || '',
          })(<Input />)}
        </Form.Item>
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  marginRight: '12px',
                }}
              >
                <FormattedMessage id='save' defaultMessage='Enregistrer' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    )
  }
}

export default Form.create({ name: 'shopBasicSettings' })(AuthMembersForm)

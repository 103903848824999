import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Progress,
} from 'antd'
import { IntercomAPI } from 'react-intercom'
import { FormattedMessage } from 'react-intl'
import { TrackingView } from '../../../containers'
import { getStatus } from '../../../helpers/orders'

export default withRouter((props) => {
  const {
    subOrder,
    giftOrder,
    detailedOrder,
  } = props

  const {
    status,
  } = subOrder

  if (giftOrder && status === 'Waiting') return null

  let percent = 5
  let error = 'active'
  switch (status) {
  case 'Waiting':
    percent = 5
    break
  case 'Received':
    percent = 20
    break
  case 'In Production':
    percent = 40
    break
  case 'Shipped':
    percent = 75
    break
  case 'Point Relais':
    percent = 90
    break
  case 'Delivered':
    percent = 100
    break
  case 'Cancelled':
    percent = 0
    error = 'exception'
    break
  case 'Flagged':
    percent = 0
    error = 'exception'
    break
  default:
    percent = 0
    error = 'exception'
    break
  }

  const statusHightLighted = (type) => (
    <span style={{
      ...((type === status || (type === 'Shipped' && status === 'Point Relais')) && { fontWeight: 700 }),
    }}
    >
      {getStatus(type)}
    </span>
  )

  return (
    <div style={{ margin: '20px' }}>
      <TrackingView
        subOrder={subOrder}
        detailedOrder={detailedOrder}
      />
      {subOrder?.shippingRef ? (
        <div>
          {' '}
          <FormattedMessage id='deliveryView.trackingLink' defaultMessage='Lien de suivi :' />
          {' '}
          <a target='_blank' rel='noopener noreferrer' href={subOrder?.trackingURL || ''}>{subOrder?.shippingRef}</a>
          {' '}

        </div>
      ) : null}
      {status !== 'Flagged' ? (
        <div>
          <Progress
            strokeColor={{
              '0%': '#99A6C3',
              '100%': '#0000FF',
            }}
            percent={percent}
            status={error}
            active
            showInfo={false}
          />
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: '30px',
            marginTop: '5px',
          }}
          >
            {statusHightLighted('Waiting')}
            {statusHightLighted('In Production')}
            {statusHightLighted('Shipped')}
            {statusHightLighted('Delivered')}
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <a
            onClick={() => IntercomAPI(
              'showNewMessage',
              `Un envoi de ma commande ${detailedOrder.customerOrderid} est bloqué.`,
            )}
          >
            <FormattedMessage
              id='deliveryView.helper'
              defaultMessage="Cet envoi a besoin d'être vérifié, veuillez contacter notre service client"
            />
          </a>
        </div>
      )}
    </div>
  )
})

import defaultDesign from '../templateEmail/magicLink/design.json'
import defaultBody from '../templateEmail/magicLink/body'
import { availableCampaignStatus } from '../campaignStatus'

const baseCampaign = {
  name: '',
  description: '',
  public: true,
  surprise: false,
  isDeliveryOnlyRelayPoint: false,
  products: [],
  template: {
    title: '',
    body: String(defaultBody),
    design: defaultDesign,
  },
  theme: {
    mainColor: null,
    fontColor: null,
    logo: null,
    logoName: null,
  },
  status: availableCampaignStatus.draft,
  message: {
    title: '',
    content: '',
    isEnabled: true,
  },
  actions: [],
}

export {
  // eslint-disable-next-line import/prefer-default-export
  baseCampaign,
}

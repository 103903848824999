import React from 'react'
import {
  Upload,
  Button,
  message,
  Row,
  Col,
  Icon,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import config from '../../../config'
import './style.css'

const ImageCTA = ({ shopSettings, type }) => {
  if (shopSettings[type]) {
    return (
      <FormattedMessage id='modify' defaultMessage='Modifier' />
    )
  }
  return (
    <FormattedMessage id='add' defaultMessage='Ajouter' />
  )
}

const UploadList = ({ shopSettings, CTAType, uploaderProps }) => {
  try {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Upload {...uploaderProps}>
        <Button type='primary' block>
          <Icon type='edit' key='edit' style={{ marginRight: 10 }} />
          <ImageCTA
            type={CTAType}
            shopSettings={shopSettings}
          />
        </Button>
      </Upload>
    )
  } catch (error) {
    console.log('ShopPicturesInputs - uploadList', { error })
    return false
  }
}

class ShopPicturesInputs extends React.Component {
  constructor(props) {
    super(props)

    const fileList = []

    this.state = {
      fileList,
      touched: false,
      uploading: false,
      bannerIndex: 0,
    }
  }

  componentDidMount() {
    this.initInputs()
  }

  initInputs = () => {
    const {
      shopSettings,
    } = this.props

    const fileList = []

    if (shopSettings.logo) {
      fileList.push({
        hash: {
          object: 'logo',
        },
        file: {
          uid: '0',
          name: 'logo.png',
          status: 'done',
          url: shopSettings.logo,
        },
        init: true,
      })
    }

    if (shopSettings.favicon) {
      fileList.push({
        hash: {
          object: 'favicon',
        },
        file: {
          uid: '1',
          name: 'favicon.png',
          status: 'done',
          url: shopSettings.favicon,
        },
        init: true,
      })
    }

    if (shopSettings.banners) {
      shopSettings.banners.map((banner, index) => {
        fileList.push({
          hash: {
            object: 'banner',
          },
          file: {
            uid: (index + 2).toString(),
            name: banner.img,
            status: 'done',
            url: banner.img,
          },
          init: true,
        })
        return true
      })
    }

    this.setState({
      fileList,
      touched: false,
    })
  }

  handleUpload = () => {
    try {
      const {
        upload,
        shop,
        shopSettings,
        modifyShop,
      } = this.props
      const { fileList } = this.state
      const random = Math.random().toString(36).substring(7)
      const fileHashes = fileList.map((file) => file.hash)

      let logo

      if (fileHashes.find((hash) => hash.object === 'logo')) {
        logo = `${config.image_base_url}/Shops/${shop._id}/theme/logo.png?${random}`
      }

      let favicon

      if (fileHashes.find((hash) => hash.object === 'favicon')) {
        favicon = `${config.image_base_url}/Shops/${shop._id}/theme/favicon.png?${random}`
      }

      let banners

      if (fileHashes.find((hash) => hash.object === 'banner')) {
        banners = [{
          img: `${config.image_base_url}/Shops/${shop._id}/theme/banner.png?${random}`,
        }]
      }

      const modifications = {
        settings: {
          ...shopSettings,
          logo,
          favicon,
          banners,
        },
        owner: shop.owner,
      }

      modifyShop({
        shop_id: shop._id,
        modifications,
        fileHashes,
        callback: (data) => {
          if (data.uploadTokens) {
            upload({
              files: fileList.filter((f) => !f.init),
              uploadTokens: data.uploadTokens,
              callback: () => {
                message.info(
                  <FormattedMessage
                    id='uploads.success'
                    defaultMessage='Transfert terminé'
                  />,
                )
              },
            })
          }
          this.setState({
            touched: false,
          })
          return true
        },
      })
    } catch (error) {
      console.log('ShopPicturesInputs - HandleUpload', { error })
    }
  }

  render() {
    const { uploading, fileList, touched } = this.state
    const { shopSettings } = this.props

    const getProps = (fileType) => ({
      onRemove: () => {
        const newFileList = fileList.filter((iter) => iter.hash.object !== fileType)

        this.setState({
          fileList: newFileList,
          touched: true,
        })
      },
      className: 'uploadDiv',
      beforeUpload: (file) => {
        this.setState((prevState) => ({
          touched: true,
          bannerIndex: prevState.bannerIndex + 1,
          fileList: [
            ...prevState.fileList.filter((iter) => iter.hash.object !== fileType),
            {
              file,
              hash: {
                object: fileType,
                type: file.type,
              },
            },
          ],
        }))
        return false
      },
      listType: 'picture',
      fileList: fileList
        .filter((file) => file.hash.object === fileType)
        .map((file) => file.file),
    })

    return (
      <div style={{ padding: '24px' }}>
        <Row gutter={16}>
          <Col span={12} className='imageHeader'>
            <h2>
              <FormattedMessage id='images' defaultMessage='Images' />
            </h2>
          </Col>
        </Row>
        <Row gutter={16} className='imageInputs'>
          <Col span={6}>
            <h4>
              <FormattedMessage
                id='theme.banner'
                defaultMessage='Bannière'
              />
            </h4>
            <UploadList
              uploaderProps={getProps('banner')}
              shopSettings={shopSettings}
              CTAType='banners'
            />
          </Col>
          <Col span={6}>
            <h4>
              <FormattedMessage
                id='theme.logo'
                defaultMessage='Logo'
              />
            </h4>
            <UploadList
              uploaderProps={getProps('logo')}
              shopSettings={shopSettings}
              CTAType='logo'
            />
          </Col>
          <Col span={6}>
            <h4>
              <FormattedMessage
                id='theme.favicon'
                defaultMessage='Favicon'
              />
            </h4>
            <UploadList
              uploaderProps={getProps('favicon')}
              shopSettings={shopSettings}
              CTAType='favicon'
            />
          </Col>
        </Row>
        <Row style={{ paddingTop: 16 }} gutter={16}>
          <Col span={6}>
            <Button
              type='primary'
              size='large'
              onClick={this.handleUpload}
              disabled={!touched}
              loading={uploading}
              style={{ marginRight: 10 }}
            >
              <FormattedMessage id='save' defaultMessage='Enregistrer' />
            </Button>
            <Button
              size='large'
              onClick={this.initInputs}
              disabled={!touched}
            >
              <FormattedMessage id='cancel' defaultMessage='Annuler' />
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ShopPicturesInputs

import React from 'react'
import date from 'dayjs'
import { FormattedMessage } from 'react-intl'
import {
  Select,
  Input,
  Form,
  DatePicker,
  Switch,
  Radio,
  Button,
} from 'antd'
import { CountrySelector } from '../..'

const { Item } = Form
const { Option } = Select

const GroupCard = (props) => {
  const {
    getFieldDecorator,
    member,
    membersGroups,
    emailEdition,
    admin,
    onClick,
    onChange,
  } = props

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {emailEdition ? (
          <Item label={<FormattedMessage id='email' defaultMessage='Email' />} style={{ flex: '1 3 auto', paddingRight: 5 }}>
            {
              getFieldDecorator('email', {
                initialValue: member.email,
                rules: [{ required: !admin }, {
                  type: 'email',
                  message: <FormattedMessage id='email.notValid' defaultMessage="L'Email n'est pas valide" />,
                }],
              })(<Input />)
            }
          </Item>
        ) : null}
        <Item label={<FormattedMessage id='language' defaultMessage='Langue' />} style={{ flex: '1 3 auto', paddingLeft: 5 }}>
          {
            getFieldDecorator('language', {
              initialValue: member.language,
              rules: [{ required: true }],
            })(
              <Radio.Group style={{ lineHeight: '38px' }}>
                <Radio.Button value='fr'>
                  <FormattedMessage id='french' defaultMessage='Français' />
                </Radio.Button>
                <Radio.Button value='en'>
                  <FormattedMessage id='english' defaultMessage='Anglais' />
                </Radio.Button>
              </Radio.Group>,
            )
          }
        </Item>
        {admin ? (
          <Item
            label={(
              <FormattedMessage
                id='freeDeliveryCosts'
                defaultMessage='Frais de livraison gratuits'
              />
            )}
            style={{ flex: '1 3 auto', paddingLeft: 5 }}
          >
            {
              getFieldDecorator('freeDelivery', { valuePropName: 'checked', initialValue: member.freeDelivery })(<Switch />)
            }
          </Item>
        ) : null}
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Item label={<FormattedMessage id='firstName' defaultMessage='Prénom' />} style={{ flex: '1 2 auto', paddingRight: 5 }}>
          {
            getFieldDecorator('name', {
              initialValue: member.name,
              rules: [{ required: !admin }],
            })(<Input />)
          }
        </Item>
        <Item label={<FormattedMessage id='lastName' defaultMessage='Nom' />} style={{ flex: '1 2 auto', paddingLeft: 5 }}>
          {
            getFieldDecorator('lastName', {
              initialValue: member.lastName,
              rules: [{ required: !admin }],
            })(<Input />)
          }
        </Item>
      </div>
      <Item label={<FormattedMessage id='groups' defaultMessage='Groupes' />} id='groups' style={{ flex: 1, paddingRight: 5 }}>
        {
          getFieldDecorator('groups', {
            initialValue: member.groups,
          })(
            <Select
              mode='multiple'
              showArrow
              onChange={onChange}
            >
              {membersGroups.map((group) => <Option key={group.name}>{group.name}</Option>)}
            </Select>,
          )
        }
        <Button onClick={onClick}>
          <FormattedMessage id='groupCard.form.createGroup' defaultMessage='Créer un nouveau groupe' />
        </Button>
      </Item>
      <Item label={<FormattedMessage id='address' defaultMessage='Adresse' />} style={{ flex: '1', paddingRight: 5 }}>
        {
          getFieldDecorator('address', {
            initialValue: member.address,
            rules: [{ required: !admin }],
          })(<Input />)
        }
      </Item>
      <Item label={<FormattedMessage id='additionalAddress' defaultMessage="Complément d'adresse" />} style={{ flex: '1', paddingRight: 5 }}>
        {
          getFieldDecorator('complement', {
            initialValue: member.complement,
          })(<Input />)
        }
      </Item>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Item label={<FormattedMessage id='city' defaultMessage='Ville' />} style={{ flex: '1 2 auto', paddingRight: 5 }}>
          {
            getFieldDecorator('city', {
              initialValue: member.city,
              rules: [{ required: !admin }],
            })(<Input />)
          }
        </Item>
        <Item label={<FormattedMessage id='postalCode' defaultMessage='Code postal' />} style={{ flex: '1 2 auto', paddingLeft: 5 }}>
          {
            getFieldDecorator('postalCode', {
              initialValue: member.postalCode,
              rules: [{ required: !admin }],
            })(<Input />)
          }
        </Item>
        <Item label='Pays' style={{ flex: '1 2 auto', paddingLeft: 5 }}>
          {
            getFieldDecorator('country', {
              rules: [{ required: !admin }],
            })(<CountrySelector defaultValue={member.country} />)
          }
        </Item>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Item label={<FormattedMessage id='sex' defaultMessage='Sexe' />} style={{ flex: '1 2 auto', paddingRight: 5 }}>
          {
            getFieldDecorator('sex', {
              initialValue: member.sex,
            })(
              <Select>
                <Option value='male'>
                  <FormattedMessage id='man' defaultMessage='Homme' />
                </Option>
                <Option value='female'>
                  <FormattedMessage id='woman' defaultMessage='Femme' />
                </Option>
              </Select>,
            )
          }
        </Item>
        <Item label={<FormattedMessage id='size' defaultMessage='Taille' />} style={{ flex: '1 2 auto', paddingLeft: 5 }}>
          {
            getFieldDecorator('size', {
              initialValue: member.size,
            })(
              <Select>
                <Option value='XXS'>XXS</Option>
                <Option value='XS'>XS</Option>
                <Option value='S'>S</Option>
                <Option value='M'>M</Option>
                <Option value='L'>L</Option>
                <Option value='XL'>XL</Option>
                <Option value='XXL'>XXL</Option>
                <Option value='XXXL'>XXXL</Option>
              </Select>,
            )
          }
        </Item>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Item label={<FormattedMessage id='dateOfBirth' defaultMessage='Date de naissance' />} style={{ flex: '1 3 auto' }}>
          {
            getFieldDecorator('birthday', {
              initialValue: date(member.birthday),
            })(<DatePicker style={{ width: '100%' }} />)
          }
        </Item>
        <Item
          label={(
            <FormattedMessage
              id='arrivalDate'
              defaultMessage="Date d'arrivée dans l'entreprise"
            />
          )}
          style={{ flex: '1 3 auto', padding: '0 5px' }}
        >
          {
            getFieldDecorator('companyBirthday', {
              initialValue: date(member.companyBirthday),
            })(<DatePicker style={{ width: '100%' }} />)
          }
        </Item>
        <Item label={<FormattedMessage id='phone' defaultMessage='Téléphone' />} style={{ flex: '1 2 auto' }}>
          {
            getFieldDecorator('phone', {
              initialValue: member.phone,
            })(<Input />)
          }
        </Item>
      </div>
    </div>
  )
}

export default GroupCard

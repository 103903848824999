import { Steps } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const { Step } = Steps

const CampaignCreationSteps = (props) => {
  const {
    currentStep, // Required
  } = props

  const steps = [
    {
      id: 'campaign.create.step.parameters',
      defaultMessage: 'Paramètres',
    },
    {
      id: 'campaign.create.step.theme',
      defaultMessage: 'Thème',
    },
    {
      id: 'campaign.create.step.message',
      defaultMessage: 'Message',
    },
    {
      id: 'campaign.create.step.products',
      defaultMessage: 'Produits',
    },
    {
      id: 'campaign.create.step.emailing',
      defaultMessage: 'Emailing',
    },
  ]

  return (
    <Steps
      progressDot
      current={currentStep}
      style={{ margin: '40px 0 24px 0' }}
    >
      {steps.map((step, index) => (
        <Step
          title={(
            <span style={{
              display: 'flex',
              fontWeight: index === currentStep ? 600 : 400,
              fontSize: '16px',
              gap: '4px',
              alignItems: 'center',
            }}
            >
              <FormattedMessage
                id={step?.id}
                defaultMessage={step?.title}
              />
            </span>
          )}
          key={step.id}
          disabled
        />
      ))}
    </Steps>
  )
}

export default CampaignCreationSteps

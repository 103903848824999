import React, { useState, useEffect } from 'react'
import EmailEditor from 'react-email-editor'
import defaultDesign from '../../../helpers/templateEmail/magicLink/design.json'
import config from '../../../config'

const { unlayer: { projectId } } = config
/**
 * Composant qui permet d'afficher l'editeur de mail Unlayer
 *
 * @param {string} emailEditorRef
 * @param {object} design le design du template par défaut ou celui sauvegardé
 * @param {function} setEmailEditorState
 * @returns
 */

const EmailEditorAtom = (props) => {
  try {
    const {
      emailEditorRef,
      design,
      setEmailEditorState,
    } = props

    const [isReady, setIsReady] = useState(false)

    // Permet de faire une update du body + design à chaque changement dans l'editeur
    useEffect(() => {
      if (isReady && emailEditorRef?.current) {
        emailEditorRef.current.addEventListener(
          'design:updated',
          () => {
            emailEditorRef.current.editor.exportHtml((data) => {
              const { html } = data

              setEmailEditorState({
                body: html,
                design: data.design,
              })
            })
          },
        )
      }
    }, [emailEditorRef, isReady])

    // Défini que l'éditeur est prêt et charge le design + les variables utilisables + le lien pour le ML
    const onReady = () => {
      if (!isReady) {
        setIsReady(true)
        if (emailEditorRef?.current?.editor) {
          emailEditorRef.current.editor.loadDesign(design || defaultDesign)
          // loadListener()
          // On rajoute une nouvelle possibilité pour les liens, le lien qui redirige au magicLink
          emailEditorRef.current.editor.setLinkTypes([
            {
              name: 'magic_link',
              label: 'Link to the gift',
              attrs: {
                id: 'magic_link',
                href: 'magic_link',
                target: '_blank',
              },
            },
            {
              name: 'web',
              enabled: true,
            },
            {
              name: 'phone',
              enabled: false,
            },
            {
              name: 'email',
              enabled: true,
            },
            {
              name: 'sms',
              enabled: false,
            },
          ])
          // On permet à l'editeur d'afficher des variables utilisables
          emailEditorRef.current.setMergeTags({
            email_client: {
              name: 'Email du client',
              value: '{emailClient}',
              sample: 'john@panopli.co',
            },
            firstName_client: {
              name: 'Prénom du client',
              value: '{firstNameClient}',
              sample: 'John',
            },
            email_sender: {
              name: 'Email de l\'envoyeur',
              value: '{emailSender}',
              sample: 'lucas@random.com',
            },
            firstName_sender: {
              name: 'Prénom de l\'envoyeur',
              value: '{firstNameSender}',
              sample: 'Lucas',
            },
          })
        }
      }
    }

    return (
      <>
        <EmailEditor projectId={projectId} ref={emailEditorRef} minHeight='600px' onReady={onReady} />
      </>
    )
  } catch (e) {
    console.log('error atom/QuillEditor', e)
    return null
  }
}

export default EmailEditorAtom

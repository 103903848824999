import React from 'react'
import {
  Collapse,
  Checkbox,
} from 'antd'
import apiScopes from '../../../config/apiScopes'

const { Panel } = Collapse

const ApiScopes = (props) => {
  const {
    scopes,
    onChangeCheckboxGroup,
  } = props

  // Si c'est une édition, permet d'ouvrir les panels avec les scopes déjà sélectionnés
  const getAllActivePanel = () => scopes.map((scope) => scope.split('.')[1])

  return (
    <Collapse defaultActiveKey={getAllActivePanel()}>
      {apiScopes.map((apiScope) => (
        <Panel header={apiScope.name} key={apiScope.name.toLowerCase()}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            {apiScope.scopes.map((scope) => (
              <Checkbox
                style={{ margin: 0 }}
                checked={scopes.includes(`${scope.toLowerCase()}.${apiScope.name.toLowerCase()}`)}
                onChange={(event) => onChangeCheckboxGroup(event, scope, apiScope.name)}
              >
                {scope}
              </Checkbox>
            ))}
          </div>
        </Panel>
      ))}
    </Collapse>
  )
}

export default ApiScopes

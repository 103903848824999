import React from 'react'
import {
  put, call, takeEvery, select,
} from 'redux-saga/effects'
import { notification } from 'antd'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import * as magicLinksActions from './actions'
import * as budgetsActions from '../budgets/actions'
import { fromUsers, fromShops } from '../selectors'
import { character } from '../../config/theme'

const { getAuth } = fromUsers
const { getId } = fromShops

const NOTIFICATION_PLACEMENT = 'bottomRight'
const NOTIFICATION_DURATION = 4

/**
 * Fonction qui centralise la gestion des erreurs pour cette sagas.
 *
 * Cas couverts :
 * - 403 : warn
 * - 412 : warn
 * - Default : error
 *
 * @param {*} contexte - contexte dans lequel la fonction est appelée
 * @param {*} error - error à handle
 */
const handleErrors = (contexte, error) => {
  console.log(`Retour KO dans '${contexte}'`, error)

  switch (error.status) {
  case 400:
    notification.warning({
      message: (
        <FormattedMessage
          id='store.magicLinks.badRequest'
          defaultMessage='Requête non conforme fonctionnellement, code erreur {status}.'
          values={{ status: error.status }}
        />
      ),
      placement: NOTIFICATION_PLACEMENT,
      duration: NOTIFICATION_DURATION,
    })
    break
  case 403:
    notification.warning({
      message: (
        <FormattedMessage
          id='store.magicLinks.notEnoughCredits'
          defaultMessage='Pas assez de crédits, Il vous manque {neededCredit} crédit(s) pour effectuer cette action !'
          values={{ neededCredit: error?.response?.neededCredit || '??' }}
        />
      ),
      placement: NOTIFICATION_PLACEMENT,
      duration: NOTIFICATION_DURATION,
    })
    break
  case 412:
    notification.warning({
      message: (
        <FormattedMessage
          id='store.magicLinks.noBudgetAtAll'
          defaultMessage="Votre compte n'est pas configuré pour pouvoir envoyer des magiclinks, code erreur {status}."
          values={{ status: error.status }}
        />
      ),
      placement: NOTIFICATION_PLACEMENT,
      duration: NOTIFICATION_DURATION,
    })
    break
  default:
    notification.error({
      message: (
        <FormattedMessage
          id='store.magicLinks.errorNotHandled'
          // ? Est-ce que c'est suffisant uniquement error.status pour debug ?
          defaultMessage='Une erreur est survenue, code erreur {status}.'
          values={{ status: error.status }}
        />
      ),
      placement: NOTIFICATION_PLACEMENT,
      duration: NOTIFICATION_DURATION,
    })
  }
}

export function* fetchMagicLinks(api) {
  try {
    yield put({ type: magicLinksActions.GET })
    yield put({ type: magicLinksActions.LOADING, payload: { loading: true } })
    const shop = yield select(getId)
    const auth = yield select(getAuth)
    const { data } = yield call([api, api.get], '/magicLinks', { headers: { authorization: auth, shop } })
    yield put({ type: magicLinksActions.RECEIVED, payload: { data } })
    return true
  } catch (e) {
    console.log('Erreur technique sur le fetchMagicLinks', e, e.message)
    return false
  }
}

/**
 * Permet la création de magicLinks (envois MagicLink) en DB
 * @param {*} api
 * @param {object} action L'action "CREATE_MAGICLINKS" avec le payload contenant l'id de la campagne et un tableau d'adresses mail
 */
export function* createMagicLinks(api, action) {
  try {
    const { payload } = action
    const { newMagicLinks } = payload

    // ! Si pas de magiclinks dans le payload, arrêt traitements
    if (!newMagicLinks.length) {
      console.log('store/magicLinks/createMagicLinks warn: no magicLink in payload:', payload)
      notification.warning({
        message: (<FormattedMessage
          id='store.magicLinks.noMagicLinks'
          defaultMessage='Votre requète ne contient pas les éléments necéssaire pour effectuer des envois'
        />
        ),
        placement: NOTIFICATION_PLACEMENT,
        duration: NOTIFICATION_DURATION,
      })
      return false
    }

    const magicLinksCreated = yield call([api, api.post], '/magicLinks', payload,
      {
        headers: {
          mode: 'cors',
          authorization: yield select(getAuth),
        },
      })

    // * Success
    if (magicLinksCreated) {
      yield put({ type: magicLinksActions.FETCH_FORCE })
      yield put({ type: budgetsActions.FETCH_FORCE })

      notification.success({
        message: (
          <span style={{ fontWeight: 600 }}>
            <FormattedMessage
              id='store.magicLinks.creation.success'
              defaultMessage='Les Magic Links ont bien été envoyés'
            />
          </span>
        ),
        placement: NOTIFICATION_PLACEMENT,
        duration: NOTIFICATION_DURATION,
        icon: <FontAwesomeIcon icon={faCircleCheck} style={{ color: character.valid }} />,
      })
    }

    return true
  } catch (error) {
    // Gestion des retours proprement
    handleErrors('sagas.createMagicLinks', error)
    return false
  }
}

/**
 * Permet de modifier un ou plusieurs magicLinks en DB
 * @param {*} api
 * @param {*} action - Un tableau de magicLinks mis-à-jour (status, dates, etc...)
 */
export function* modifyMagicLinks(api, { payload, callback }) {
  try {
    yield put({ type: magicLinksActions.LOADING, payload: { loading: true } })
    const auth = yield select(getAuth)
    const magicLinkModified = yield call([api, api.put], '/magicLinks', payload, {
      headers: {
        mode: 'cors',
        authorization: auth,
      },
    })
    if (magicLinkModified) {
      yield put({ type: magicLinksActions.LOADING, payload: { loading: false } })
      yield put({ type: magicLinksActions.FETCH_FORCE })
      if (callback) {
        callback()
      }
    }
    return true
  } catch (e) {
    console.log('modifyMagicLinks error', e, e.message)

    notification.open({
      message: (
        <span style={{ fontWeight: 600 }}>
          <FormattedMessage id='store.magicLinks.update.failure' defaultMessage="Les Magic Links n'ont pas été modifiés..." />
        </span>
      ),
      placement: 'bottomRight',
      duration: NOTIFICATION_DURATION,
    })
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(magicLinksActions.FETCH_FORCE, fetchMagicLinks, api)
  yield takeEvery(magicLinksActions.CREATE_MAGICLINKS, createMagicLinks, api)
  yield takeEvery(magicLinksActions.MODIFY_MAGICLINKS, modifyMagicLinks, api)
}

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Form, InputNumber, Button, Select, Divider, Typography, Tag,
} from 'antd'

const { Paragraph } = Typography

const { Option } = Select

const getComponentFromType = (type, props) => {
  const {
    modifyCondition, condition, membersGroups, members, hasError,
  } = props
  switch (type) {
  case 'groups':
    return (
      <GroupsCondition modifyCondition={modifyCondition} condition={condition} membersGroups={membersGroups} hasError={hasError} />
    )
  case 'member':
    return (
      <MemberCondition modifyCondition={modifyCondition} condition={condition} members={members} hasError={hasError} />
    )
  case 'seniority':
    return (
      <SeniorityCondition modifyCondition={modifyCondition} condition={condition} hasError={hasError} />
    )
  default:
    return null
  }
}

const SeniorityCondition = (props) => {
  const { modifyCondition, condition, hasError } = props
  const { value = {} } = condition
  const { period, unit } = value || {}
  return (
    <div style={{ display: 'block' }}>
      <Divider>
        <FormattedMessage id='duration' defaultMessage='Durée' />
      </Divider>
      <div style={{ display: 'flex' }}>
        <InputNumber
          value={period}
          onChange={(e) => modifyCondition({ ...condition, value: { ...value, period: e } })}
          min={0}
        />
        <Select
          style={{ width: 120 }}
          value={unit}
          onChange={(e) => modifyCondition({ ...condition, value: { ...value, unit: e } })}
        >
          <Option value='days'>
            <FormattedMessage id='days' defaultMessage='Jours' />
          </Option>
          <Option value='weeks'>
            <FormattedMessage id='weeks' defaultMessage='Semaines' />
          </Option>
          <Option value='months'>
            <FormattedMessage id='month' defaultMessage='Mois' />
          </Option>
          <Option value='years'>
            <FormattedMessage id='years' defaultMessage='Années' />
          </Option>
        </Select>
      </div>
      {hasError ? (
        <p style={{ color: 'red', maxWidth: '220px' }}>
          <FormattedMessage id='productRulesForm.helper1' defaultMessage='Veuillez choisir une durée ou supprimez la ligne' />
        </p>
      ) : null }
    </div>
  )
}

const GroupsCondition = (props) => {
  const {
    modifyCondition, condition, membersGroups = [], hasError,
  } = props
  const { value = [] } = condition
  const allGroups = membersGroups.map((m) => m.name)
  const options = membersGroups.map((m) => (
    <Option value={m.name}>
      <Tag color={m.color}>
        {m.name}
      </Tag>
    </Option>
  ))
  options.unshift(
    <Option value='all'>
      <div>
        <FormattedMessage id='allGroups' defaultMessage='Tous les groupes' />
      </div>
      <div style={{ borderBottom: '1px solid lightGrey', width: '100%', marginTop: '4px' }} />
    </Option>,
  )
  return (
    <div>
      <Divider>
        <FormattedMessage id='groups' defaultMessage='Groupes' />
      </Divider>
      <Select
        mode='multiple'
        style={{
          width: '220px',
          maxHeight: '190px',
          overflowY: 'scroll',
        }}
        value={value || []}
        allowClear
        onChange={(e) => {
          if (e && e.includes('all')) {
            modifyCondition({ ...condition, value: allGroups })
          } else modifyCondition({ ...condition, value: e })
        }}
      >
        {options}
      </Select>
      {hasError ? (
        <p style={{ color: 'red', maxWidth: '220px' }}>
          <FormattedMessage id='productRulesForm.helper2' defaultMessage='Veuillez choisir au moins un groupe ou supprimez la ligne' />
        </p>
      ) : null }
    </div>
  )
}

const MemberCondition = (props) => {
  const {
    modifyCondition, condition, members, hasError,
  } = props
  const { value = [] } = condition
  const allMembers = members.map((m) => String(m._id))
  const options = members.map((m) => (
    <Option value={String(m._id)} member={m}>{m.email}</Option>
  ))
  options.unshift(
    <Option value='all'>
      <FormattedMessage id='allMembers' defaultMessage='Tous les membres' />
      <div style={{ borderBottom: '1px solid lightGrey', width: '100%', marginTop: '4px' }} />
    </Option>,
  )
  return (
    <div>
      <Divider>
        <FormattedMessage id='members' defaultMessage='Membres' />
      </Divider>
      <Select
        showSearch
        style={{
          width: '220px',
          maxHeight: '190px',
          overflowY: 'scroll',
        }}
        placeholder={<FormattedMessage id='productRulesForm.placeholder.member' defaultMessage='Choisissez un membre' />}
        optionFilterProp='children'
        mode='multiple'
        allowClear
        value={value || []}
        onChange={(e) => {
          if (e && e.includes('all')) {
            modifyCondition({ ...condition, value: allMembers })
          } else modifyCondition({ ...condition, value: e })
        }}
        filterOption={(input, option) => {
          const { member } = option.props
          const isName = member.lastName.includes(input) || member.name.includes(input)
          return isName || option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
      >
        {options}
      </Select>
      {hasError ? (
        <p style={{ color: 'red', maxWidth: '220px' }}>
          <FormattedMessage id='productRulesForm.helper3' defaultMessage='Veuillez choisir des membres ou supprimez la ligne' />
        </p>
      ) : null }
    </div>
  )
}

const ConditionForm = (props) => {
  const { condition, modifyCondition } = props
  const { type } = condition
  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly', minWidth: '500px' }}>
      <div>
        <Divider> Type </Divider>
        <Select style={{ width: 185, margin: '0 auto' }} value={type} onChange={(e) => modifyCondition({ ...condition, type: e })}>
          <Option value='groups'>
            <FormattedMessage id='productRulesForm.byGroup' defaultMessage='Par groupe' />
          </Option>
          <Option value='member'>
            <FormattedMessage id='productRulesForm.byMember' defaultMessage='Par Membre' />
          </Option>
          <Option value='seniority'>
            <FormattedMessage id='productRulesForm.bySeniority' defaultMessage='Par Ancienneté' />
          </Option>
        </Select>
      </div>
      {type ? getComponentFromType(type, props) : null }
    </div>
  )
}

const ConditionDisplayer = (props) => {
  const {
    isLast, remove, condition, modifyCondition, membersGroups, members, hasError,
  } = props
  return (
    <>
      <div style={{ display: 'flex' }}>
        <ConditionForm modifyCondition={modifyCondition} condition={condition} membersGroups={membersGroups} members={members} hasError={hasError} />
        <div style={{ display: 'block' }}>
          <Divider> - </Divider>
          <Button onClick={() => remove(condition.id)}>
            <FormattedMessage id='empty' defaultMessage='Vider' />
          </Button>
        </div>
      </div>
      { isLast ? null : <Divider> ET </Divider> }
    </>
  )
}

const ProductRulesForm = (props) => {
  const {
    membersGroups, members, addComponentRule, hide, modifyRule, edition, edited = {},
  } = props
  const { rules, index } = edited || {}
  const [conditions, setConditions] = useState(edition ? rules : [{ type: 'groups', id: Math.random() }])
  const modifyCondition = (condition) => {
    const isPresent = conditions.find((c) => c.id === condition.id)
    if (isPresent) {
      const newConditions = conditions.map((c) => {
        if (c.id === isPresent.id) {
          if (isPresent.type !== condition.type) {
            return {
              ...condition,
              value: null,
            }
          }
          return condition
        }
        return c
      })
      setConditions(newConditions)
    }
  }

  const remove = (id) => {
    const newLines = conditions.filter((line) => line.id !== id)
    setConditions(newLines)
  }
  const add = () => {
    const newLines = conditions.concat([{ id: Math.random(), type: 'groups' }])
    setConditions(newLines)
  }
  const hasErrors = (condition) => {
    const { value } = condition
    const { unit, period } = value || {}
    if (condition.type === 'seniority') {
      return !value || (value && (!period || !unit))
    }
    return !value || (value && !value.length)
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Form>
        <Paragraph>
          <FormattedMessage
            id='productRulesForm.helper4'
            defaultMessage="Vous pouvez rajouter des conditions.
            Le produit sera accessible pour tous les membres qui remplissent l'ensemble de ces conditions."
          />
        </Paragraph>
        {conditions.map((condition, i) => (
          <ConditionDisplayer
            condition={condition}
            modifyCondition={modifyCondition}
            key={condition.value}
            isLast={i === conditions.length - 1}
            remove={remove}
            membersGroups={membersGroups}
            members={members}
            hasError={hasErrors(condition)}
          />
        ))}
        <div style={{ padding: 25, textAlign: 'center' }}>
          <Button type='secondary' onClick={add}>
            <FormattedMessage id='productRulesForm.helper5' defaultMessage='Rajouter une nouvelle condition' />
          </Button>
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button
            type='primary'
            disabled={conditions.map((c) => hasErrors(c)).find(Boolean)}
            onClick={(e) => {
              e.preventDefault()
              hide()
              return edition ? modifyRule(index, conditions) : addComponentRule(conditions)
            }}
          >
            { edition
              ? <FormattedMessage id='productRulesForm.modifyRule' defaultMessage='Modifier ma règle' />
              : <FormattedMessage id='productRulesForm.validRule' defaultMessage='Valider ma règle' />}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default ProductRulesForm

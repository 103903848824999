import React from 'react'
import { Switch } from 'antd'
import { FormattedMessage } from 'react-intl'

import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

const HiddenCustomCheckout = (props) => {
  try {
    const { newCustom, setNewCustom } = props
    const { checkout } = newCustom
    const { hideNewsletter, hideDeliveryDate } = checkout
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage
            id='custom.checkout'
            defaultMessage='Checkout'
          />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.checkoutHideNewsletter'
                defaultMessage='Masquer la checkbox newsletter'
              />
            </div>
            <Switch
              checked={hideNewsletter}
              onChange={(value) => setNewCustom({ ...newCustom, checkout: { ...checkout, hideNewsletter: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.checkoutHideDeliveryDate'
                defaultMessage='Masquer les délais de livraison'
              />
            </div>
            <Switch
              checked={hideDeliveryDate}
              onChange={(value) => setNewCustom({ ...newCustom, checkout: { ...checkout, hideDeliveryDate: value } })}
            />
          </div>
          <div style={columnStyle} />
          <div style={columnStyle} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecule/HiddenCustomCheckout error:', e)
    return null
  }
}

export default HiddenCustomCheckout

import React from 'react'
import { Tag } from 'antd'

const getTagColorFromStatus = (status) => {
  switch (status) {
  case 'Brouillon':
    return 'pink'
  case 'Ouvert':
    return 'blue'
  case 'Payée':
    return 'green'
  case 'Erreur':
    return 'orange'
  case 'Annulée':
  case 'Draft':
    return 'pink'
  case 'Open':
    return 'blue'
  case 'Paid':
    return 'green'
  case 'Error':
    return 'orange'
  case 'Canceled':
  default:
    return 'red'
  }
}

const getInvoiceStatus = (status, locale = 'fr') => {
  switch (status) {
  case 'draft':
    return locale === 'fr' ? 'Brouillon' : 'Draft'
  case 'open':
    return locale === 'fr' ? 'Ouvert' : 'Open'
  case 'paid':
    return locale === 'fr' ? 'Payée' : 'Paid'
  case 'uncollectible':
    return locale === 'fr' ? 'Erreur' : 'Error'
  case 'void':
    return locale === 'fr' ? 'Annulée' : 'Canceled'
  default:
    return locale === 'fr' ? 'Erreur' : 'Error'
  }
}

const renderStatusTag = (status) => (
  <Tag color={getTagColorFromStatus(status)}>
    {status}
  </Tag>
)

const calculInvoiceUpComing = (total, result) => (
  (((result.dropShippingPrice || 0)
    + (result.storagePrice || 0)
    + result.giftAmount || 0)
    - result.excessAmount || 0) + (total / 100)
)

export default renderStatusTag
export {
  calculInvoiceUpComing,
  getInvoiceStatus,
}

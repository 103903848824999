import React from 'react'
import {
  Form,
  Input,
  Switch,
  Button,
  Row,
  message,
} from 'antd'
import { FormattedMessage } from 'react-intl'

class BasicsSettingsForm extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
        resetFields,
      },
      modifyShop,
      shop,
    } = this.props
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          name,
          url,
        } = values

        const modifications = {
          ...shop,
          settings: {
            ...shop.settings,
            name,
            url,
          },
        }

        modifyShop({
          shop_id: shop._id,
          modifications,
          callback: () => {
            resetFields()

            message.info(
              <FormattedMessage
                id='updateSuccess.shop'
                defaultMessage='Boutique mise à jour'
              />,
            )
            return true
          },
        })

        // onSubmit(values)
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        isFieldsTouched,
        resetFields,
      },
      shopSettings,
      shop,
      modifyShop,
    } = this.props
    const { loading } = this.state
    return (
      <Form
        id='basicsForm'
        onSubmit={this.handleSubmit}
      >
        <Switch
          loading={loading}
          onClick={(value) => {
            this.setState({ loading: true })
            const modifications = {
              ...shop,
              status: value ? 'Active' : 'Inactive',
            }

            modifyShop({
              shop_id: shop._id,
              modifications,
              callback: () => {
                message.info(
                  <FormattedMessage
                    id='updateSuccess.shop'
                    defaultMessage='Boutique mise à jour'
                  />,
                )
                this.setState({ loading: false })
                return true
              },
            })
          }}
          checked={shop.status === 'Active'}
          style={{ marginRight: 10 }}
        />
        <FormattedMessage
          id='shopStatus.active'
          defaultMessage='Activer ma boutique'
        />
        <Form.Item label={<FormattedMessage id='lastName' defaultMessage='Nom' />}>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: <FormattedMessage id='chooseAName' defaultMessage='Choisissez un nom' />,
                whitespace: true,
              },
            ],
            initialValue: shopSettings.name,
          })(<Input size='default' style={{ width: 300 }} />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage id='URL' defaultMessage='URL' />}>
          {getFieldDecorator('url', {
            rules: [
              {
                required: true,
                message: <FormattedMessage id='chooseURL' defaultMessage='Choisissez une URL' />,
                whitespace: true,
              },
            ],
            initialValue: shopSettings.url,
          })(<Input size='default' style={{ width: 300 }} addonAfter='.niceshop.co' />)}
        </Form.Item>
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  marginRight: '12px',
                }}
              >
                <FormattedMessage id='save' defaultMessage='Enregistrer' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    )
  }
}

export default Form.create({ name: 'shopBasicSettings' })(BasicsSettingsForm)

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { uniq, flatten } from 'lodash'
import {
  Drawer,
  Form,
  Button,
  Radio,
  Select,
  Row,
  Typography,
  InputNumber,
} from 'antd'

import { Info } from '../../components'

import { MODIFY_SEVERAL, PRODUCT_DRAWER } from '../../store/products/actions'
import { fromProducts, fromShops, fromMembers } from '../../store/selectors'

const { Option } = Select
const { Paragraph } = Typography

/**
 * Permet de savoir quelle valeur afficher en fonction du nombre de produits sélectionnés
 * @param {string} type - Le type de valeur à vérifier
 * @param {array} products - Les produits sélectionnés par l'utilisateur
 * @returns La valeurs à afficher
 */
const getInitialValue = (type, products = []) => {
  // On vérifie s'il y a un ou plusieurs produits sélectionnés
  const onlyOneSelectedProduct = products.length === 1
  // S'il n'y a qu'un seul produit, on récupère ses valeurs pour les afficher
  const product = products.find(Boolean) || {}
  const { status = null, collections = [], pricing = {} } = product
  const { margin = null } = pricing
  // S'il n'y a pas qu'un seul produit, on affiche les placeholders à la place
  switch (type) {
  case 'status':
    return onlyOneSelectedProduct ? status : null
  case 'collections':
    return onlyOneSelectedProduct ? collections : []
  case 'margin':
    return onlyOneSelectedProduct ? margin : null
  default:
    return false
  }
}

export default connect(
  (state) => ({
    allProducts: fromProducts.allProducts(state),
    productDrawerOpen: fromProducts.productDrawer(state),
    membersGroups: fromShops.shopMembersGroups(state),
    members: fromMembers.getMembers(state),
  }),
  (dispatch) => bindActionCreators({
    modifyProducts: (payload) => ({
      type: MODIFY_SEVERAL,
      payload,
    }),
    closeDrawer: () => ({
      type: PRODUCT_DRAWER,
      payload: {
        open: false,
      },
    }),
  }, dispatch),
)(Form.create({ name: 'productsModification' })((props) => {
  const {
    form: {
      resetFields,
      isFieldsTouched,
      getFieldDecorator,
    },
    allProducts,
    selected,
    closeDrawer,
    modifyProducts,
    productDrawerOpen,
    isAdmin,
  } = props

  const products = allProducts.filter((p) => selected.includes(p._id))

  const allCollections = uniq(flatten(allProducts.map((p) => p.collections))).filter(Boolean)

  const collectionOptions = allCollections.map((coll) => (
    <Option value={coll} key={coll}>{coll}</Option>
  ))

  return (
    <Drawer
      visible={productDrawerOpen}
      width='500px'
      onClose={closeDrawer}
      destroyOnClose
      title={(
        <FormattedMessage
          id='productModificationDrawer.title'
          defaultMessage='Modifier {number} produit{agreement}'
          values={{
            number: selected.length,
            agreement: selected.length > 1 ? 's' : '',
          }}
        />
      )}
      style={{ zIndex: 1000 }}
    >
      <Form
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
        }}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
        }}
        onSubmit={(e) => {
          e.preventDefault()
          const {
            form: {
              validateFieldsAndScroll,
            },
          } = props
          validateFieldsAndScroll((err, values) => {
            if (!err) {
              modifyProducts({
                values,
                selected,
                products,
              })
            }
          })
        }}

      >
        <Row>
          <Form.Item label={<FormattedMessage id='visibility' defaultMessage='Visibilité' />}>
            {getFieldDecorator('status', { initialValue: getInitialValue('status', products) })(
              <Radio.Group buttonStyle='solid'>
                <Radio.Button value='Active'>
                  <FormattedMessage id='active.female' defaultMessage='Active' />
                </Radio.Button>
                <Radio.Button value='Inactive'>
                  <FormattedMessage id='inactive.female' defaultMessage='Inactive' />
                </Radio.Button>
                {isAdmin ? (
                  <Radio.Button value='Locked'>
                    <FormattedMessage id='locked' defaultMessage='Locked' />
                  </Radio.Button>
                ) : null}
              </Radio.Group>,
            )}
          </Form.Item>
        </Row>
        <Form.Item label={<FormattedMessage id='collections' defaultMessage='Collections' />}>
          {getFieldDecorator('collections', { initialValue: getInitialValue('collections', products) })(
            <Select
              showArrow
              allowClear
              mode='tags'
              type='collections'
              placeholder={<FormattedMessage id='collections' defaultMessage='Collections' />}
            >
              {collectionOptions}
            </Select>,
          )}
        </Form.Item>
        <Row>
          <Form.Item label={(
            <span>
              <Info
                title={<FormattedMessage id='sellerMargin' defaultMessage='Marge Vendeur' />}
                content={(
                  <Paragraph style={{ width: '25vw' }}>
                    <FormattedMessage
                      id='productModificationDrawer.helper'
                      // eslint-disable-next-line max-len
                      defaultMessage="Il s'agit du montant reversé à chaque achat de ce produit, modulo les effets de stocks ou de discounts. Ceux-ci peuvent influencer ce montant en positif comme en négatif."
                    />
                  </Paragraph>
                )}
              />
              <FormattedMessage id='sellerMargin' defaultMessage='Marge Vendeur' />
            </span>
          )}
          >
            {getFieldDecorator('margin', { initialValue: getInitialValue('margin', products) })(
              <InputNumber
                suffix='€'
                type='number'
                min={0}
                step={0.01}
              />,
            )}
          </Form.Item>
        </Row>
        <Row>
          {isFieldsTouched() ? (
            <Form.Item
              wrapperCol={{
                span: 12,
                xs: {
                  span: 24,
                  offset: 0,
                },
                sm: {
                  span: 16,
                  offset: 8,
                },
              }}
            >
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  margin: '0 12px',
                }}
              >
                <FormattedMessage id='modify' defaultMessage='Modifier' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    </Drawer>
  )
}))

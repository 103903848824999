import {
  Button,
} from 'antd'
import {
  compact, flatten,
  uniq,
  uniqBy,
} from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { fetchImage } from '../../imageUpload'

const checkCreationBaseProduct = (product, setCurrentStep) => {
  const errorCreationArray = []
  if (!product.name) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(0)} />
        <FormattedMessage
          id='catalog.creation.error.name'
          defaultMessage='Le nom est obligatoire'
        />
      </div>,
    )
  } if (!product.category) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(0)} />
        <FormattedMessage
          id='catalog.creation.error.category'
          defaultMessage='Une catégorie est obligatoire'
        />
      </div>,
    )
  } if (!product.subCategory) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(0)} />
        <FormattedMessage
          id='catalog.creation.error.subCategory'
          defaultMessage='Une sous-catégorie est obligatoire'
        />
      </div>,
    )
  } if (!product.providerProductId) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(0)} />
        <FormattedMessage
          id='catalog.creation.error.productId'
          defaultMessage="L'id du produit chez le fournisseur est obligatoire"
        />
      </div>,
    )
  } if (product.pricing) {
    if (!product.pricing.priceHT && product.pricing.priceHT !== 0) {
      errorCreationArray.push(
        <div>
          <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(0)} />
          <FormattedMessage
            id='catalog.creation.error.price'
            defaultMessage='Un prix HT est obligatoire'
          />
        </div>,
      )
    } if (!product.pricing.margin) {
      errorCreationArray.push(
        <div>
          <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(0)} />
          <FormattedMessage
            id='catalog.creation.error.margin'
            defaultMessage='Une marge est obligatoire'
          />
        </div>,
      )
    }
  } if (product.caracteristics && !product.caracteristics.weight) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(0)} />
        <FormattedMessage
          id='catalog.creation.error.weight'
          defaultMessage="Le produit n'a pas de poids"
        />
      </div>,
    )
  } if (!product.provider) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(2)} />
        <FormattedMessage
          id='catalog.creation.error.supplier'
          defaultMessage="Le produit n'a pas de fournisseur"
        />
      </div>,
    )
  } if (!product.families || !product.families.length) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(3)} />
        <FormattedMessage
          id='catalog.creation.error.family'
          defaultMessage='Les couleurs doivent appartenir à une famille'
        />
      </div>,
    )
  } if (!product.sizes || !product.sizes.length) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(3)} />
        <FormattedMessage
          id='catalog.creation.error.size'
          defaultMessage="Le produit n'a pas de taille"
        />
      </div>,
    )
  } if (!product.technics || !product.technics.length) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(4)} />
        <FormattedMessage
          id='catalog.creation.error.techniques'
          defaultMessage="Le produit n'a pas de techniques"
        />
      </div>,
    )
  } if ((!product.images || !product.images.length) && (!product.picListToUpload || !product.picListToUpload.length)) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(5)} />
        <FormattedMessage
          id='catalog.creation.error.image'
          defaultMessage="Le produit a besoin d'une image"
        />
      </div>,
    )
  } if (!product?.caracteristics?.customCode) {
    errorCreationArray.push(
      <div>
        <Button type='link' shape='circle' icon='link' size='small' onClick={() => setCurrentStep(0)} />
        <FormattedMessage
          id='catalog.creation.error.SH'
          defaultMessage='Le code SH est obligatoire'
        />
      </div>,
    )
  }
  return errorCreationArray
}

const getNumberOfSameSubCategory = (baseProducts, subCategory, products = []) => {
  let allBaseProductsWithDraft = baseProducts
  products.map((draft) => {
    if (draft.idPanopli && !baseProducts.find((bp) => bp.idPanopli === draft.idPanopli)) {
      allBaseProductsWithDraft.push(draft)
    } return true
  })
  allBaseProductsWithDraft = uniqBy(allBaseProductsWithDraft, 'idPanopli')
  const number = allBaseProductsWithDraft.filter((bp) => bp.subCategory && bp.subCategory.fr === subCategory).length + 1
  return number
}

const getAllCategory = (baseProducts) => {
  let allCategoryFR = []
  let allCategoryEN = []
  baseProducts.map((bp) => {
    if (bp.category && bp.category.fr && bp.category.fr.length) {
      bp.category.fr.map((cat) => allCategoryFR.push(cat))
    } if (bp.category && bp.category.en && bp.category.en.length) {
      bp.category.en.map((cat) => allCategoryEN.push(cat))
    }
    return true
  })
  allCategoryFR = compact(uniq(allCategoryFR))
  allCategoryEN = compact(uniq(allCategoryEN))
  return ({
    allCategoryFR,
    allCategoryEN,
  })
}

const getAllSubCategory = (baseProducts) => {
  let allSubCategoryFR = []
  let allSubCategoryEN = []

  baseProducts.map((bp) => {
    if (bp.subCategory && bp.subCategory.fr) allSubCategoryFR.push(bp.subCategory.fr)
    if (bp.subCategory && bp.subCategory.en) allSubCategoryEN.push(bp.subCategory.en)
    return true
  })
  allSubCategoryFR = compact(uniq(allSubCategoryFR))
  allSubCategoryEN = compact(uniq(allSubCategoryEN))
  return ({
    allSubCategoryFR,
    allSubCategoryEN,
  })
}

const getAllTags = (baseProducts) => {
  let allTags = []
  baseProducts.map((bp) => {
    if (bp.tags && bp.tags.length) {
      bp.tags.map((tag) => allTags.push(tag))
    } return true
  })
  allTags = compact(uniq(allTags))
  return allTags
}

const getCost = (price, margin) => parseFloat((price * (1 - (margin / 100))).toFixed(2)) || 0

const getMargin = (price, cost) => parseFloat(((1 - (cost / price)) * 100).toFixed(2)) || 0

const getPrice = (cost, margin) => parseFloat((cost / (1 - (margin / 100))).toFixed(2)) || 0

const asyncFetchImage = async (image) => {
  const base64 = await fetchImage(image.src)
  return base64
}

const cleanId = (id) => {
  try {
    return id.replaceAll('à', 'a')
      .replaceAll('é', 'e')
      .replaceAll('è', 'e')
      .replaceAll('ç', 'c')
      .replaceAll('ù', 'u')
      .replaceAll(/[^a-zA-Z0-9-]/g, '')
      .replaceAll(' ', '')
  } catch (e) {
    return id
  }
}

const generateColorFamilyId = (product, shopifyColors, number) => {
  const arbitratedNumber = number || (product.families && product.families.length ? product.families.length + 1 : 1)
  const id = cleanId(`${product.idPanopli}-color-${arbitratedNumber}`)
  if (shopifyColors.length) {
    const alReadyExist = shopifyColors?.find((t) => t.famille === id)
    if (alReadyExist) {
      return generateColorFamilyId(product, shopifyColors, arbitratedNumber + 1)
    }
  }
  return id
}

const generateTechnicId = (shopifyTechnics, product, baseProducts, number) => {
  const id = cleanId(`${product.idPanopli}-tech-${number}`)
  // si oui, on boucle avec un + 1
  if (shopifyTechnics.length) {
  // on vérifie qu'on a pas de doublon d'id dans les technics shopify et du produit même
    const alReadyExistInProduct = product?.technics?.find((t) => t.idPanopli === id)
    const alReadyExist = shopifyTechnics?.find((t) => t.id === id)
    if (alReadyExist || alReadyExistInProduct) {
      return generateTechnicId(shopifyTechnics, product, baseProducts, number + 1)
    }
  }
  return id
}

const formatColorsFromFamilies = (families) => flatten(families.map((f) => f.colors.map((c) => ({
  type: 'color',
  name: c,
  reference: Math.random(),
  comboExclusion: [],
  comboSpecific: [],
  priceImpact: f.priceImpact,
  costImpact: f.costImpact,
}))))

const trimBaseProductForDraft = async (baseProduct, baseProducts, allProviders, options = {}) => {
  try {
    const { bypassUpload = false } = options
    const setPicListToUpload = async () => {
      let picListToUpload = []
      if (baseProduct.shopifyImages && baseProduct.shopifyImages.length) {
        picListToUpload = await Promise.all(baseProduct.shopifyImages.map(async (image) => {
          const base64 = await asyncFetchImage(image)
          return ({
            name: `${image.name || image.alt} - ${image.id}.png`,
            percent: 0,
            status: 'done',
            type: 'image/png',
            response: 'ok',
            uid: image.id,
            fromShopify: true,
            thumbUrl: `data:image/png;base64,${base64}`,
          })
        }))
      }
      return picListToUpload
    }
    const picListToUpload = bypassUpload ? (baseProduct.picList || []) : await setPicListToUpload()
    return ({
      ...baseProduct,
      provisionalId: baseProduct._id,
      provider: allProviders.find((provider) => provider._id === baseProduct.providerId),
      existInDB: true,
      idPanopli: baseProduct.idPanopli || cleanId(`pan-${(baseProduct.subCategory.fr).replaceAll(' ', '')
        .toLowerCase()}-${getNumberOfSameSubCategory(baseProducts, baseProduct.subCategory.fr)}`),
      colors: formatColorsFromFamilies(baseProduct.families).map((color) => ({ name: color.name })),
      sizes: baseProduct.variations.filter((variation) => variation.type === 'size'),
      picListToUpload,
    })
  } catch (e) {
    console.log(e, 'error trimBaseProductForDraft')
    return false
  }
}

const checkIdPanopliModification = (value, baseProduct, baseProducts, draftProducts) => {
  try {
    // we check if the new idPanopli isn't allready used by another base product
    const idNotUniq = (baseProducts.some((bp) => bp.idPanopli === value)
      // we check if it isn't used in a draft product in creation from workshop
      || draftProducts.some((bp) => bp.idPanopli === value))
      && value !== baseProduct.idPanopli // to avoid to find itself after a reversed modification
    return idNotUniq
  } catch (e) {
    console.log('checkIdPanopliModification error:', e)
    return false
  }
}

const basicBaseProduct = () => {
  const provisionalId = Math.random().toString(8)
  return {
    newBaseProduct: true,
    provisionalId,
    customizable: true,
    name: {
      fr: 'Nouvelle référence',
      en: 'New reference',
    },
    caracteristics: {},
    pricing: {
      costHT: 0,
      priceHT: 0,
      margin: 0,
      tva: 20,
    },
  }
}

export {
  asyncFetchImage, basicBaseProduct, checkCreationBaseProduct, checkIdPanopliModification, cleanId, formatColorsFromFamilies, generateColorFamilyId, generateTechnicId, getAllCategory,
  getAllSubCategory,
  getAllTags,
  getCost,
  getMargin, getNumberOfSameSubCategory, getPrice,
  trimBaseProductForDraft,
}

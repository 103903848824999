import React from 'react'
import { Layout } from 'antd'
import AuthContainer from '../../containers/Auth'
import { PasswordReinitContainer } from '../../containers'

const { Header, Content } = Layout

const Auth = (props) => {
  const { login, user, type } = props
  if (type === 'PASSWORD_REINIT') {
    return <PasswordReinitContainer user={user} />
  }
  return (
    <Layout className='layout' style={{ width: '100%' }}>
      <Header
        style={{
          backgroundColor: 'transparent',
          padding: 0,
        }}
      >
        <img
          style={{ height: 64 }}
          src='/logo-panopli-02.png'
          alt='logo'
        />
      </Header>
      <Content
        style={{
          padding: '0 20%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AuthContainer
          login={login}
          user={user}
        />
      </Content>
    </Layout>
  )
}

export default Auth

import React from 'react'
import {
  Popover, Icon,
} from 'antd'

export default ({
  title,
  content,
  children,
  placement = 'top',
}) => (
  <Popover content={content} title={title} placement={placement}>
    {children || <Icon style={{ margin: '0 12px' }} type='info-circle' />}
  </Popover>
)

import React, { useState } from 'react'
import {
  Modal,
  Steps,
  Icon,
  Button,
  message,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import theme from '../../../config/theme'
import defaultDesign from '../../../helpers/templateEmail/magicLink/design.json'
import defaultBody from '../../../helpers/templateEmail/magicLink/body'
import {
  CampaignCreationParameters,
  CampaignCreationProducts,
} from '../..'

const { Step } = Steps

const CampaignCreationModal = (props) => {
  try {
    const {
      visibleModal,
      setVisibleModal,
      products,
      createCampaign,
    } = props

    const baseCampaign = {
      name: '',
      public: false,
      surprise: true,
      isDeliveryOnlyRelayPoint: false,
      products: [],
      template: {
        title: 'Vous avez reçu un cadeau !',
        // eslint-disable-next-line max-len
        body: String(defaultBody),
        design: defaultDesign,
      },
    }
    const [currentStep, setCurrentStep] = useState(0)
    const [campaign, setCampaign] = useState(baseCampaign)
    const onChangeStep = (current) => setCurrentStep(current)
    return (
      <Modal
        destroyOnClose
        visible={visibleModal}
        width='80%'
        confirmLoading={false}
        onCancel={() => {
          setVisibleModal(false)
          setCampaign(baseCampaign)
          setCurrentStep(0)
        }}
        footer={null}
      >
        <div style={{ padding: '0 20px' }}>
          <h3 style={{
            fontWeight: 'bold',
            textAlign: 'center',
            color: theme.neutralColor[6][12],
            marginBottom: '10px',
          }}
          >
            <FormattedMessage
              id='campaigns.createCampaign'
              defaultMessage='Créer une campagne'
            />
          </h3>
          <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>
            <FormattedMessage
              id='campaigns.modal.create.description'
              defaultMessage='Vous allez créer une nouvelle campagne.
              Vous pouvez entrer toutes les informations maintenant ou les remplir plus tard !'
            />
          </h4>
          <Steps
            onChange={onChangeStep}
            size='small'
            current={currentStep}
            className='stepsCampaignModal'
          >
            <Step
              title={(
                <h3>
                  <FormattedMessage
                    id='campaigns.modal.step.title.info'
                    defaultMessage='Informations'
                  />
                </h3>
              )}
              icon={<Icon type='info-circle' style={{ fontSize: '20px' }} />}
            />
            <Step
              title={(
                <h3>
                  <FormattedMessage
                    id='campaigns.modal.step.title.template'
                    defaultMessage='Template'
                  />
                </h3>
              )}
              icon={<Icon type='profile' style={{ fontSize: '20px' }} />}
            />
            <Step
              title={(
                <h3>
                  <FormattedMessage
                    id='campaigns.modal.step.title.products'
                    defaultMessage='Produits'
                  />
                </h3>
              )}
              icon={<Icon type='skin' style={{ fontSize: '20px' }} />}
            />
          </Steps>
          {currentStep === 0 && (
            <CampaignCreationParameters
              setCampaign={setCampaign}
              campaign={campaign}
            />
          )}
          {currentStep === 2 && (
            <CampaignCreationProducts
              setCampaign={setCampaign}
              campaign={campaign}
              products={products}
            />
          )}
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '40px',
        }}
        >
          <Button
            style={{ fontWeight: 'bold' }}
            disabled={!campaign.name || !campaign?.products?.length}
            type='primary'
            onClick={() => {
              if (!campaign.template.body.includes('magic_link')) {
                message.warning('Le mail a besoin d\'une redirection au cadeau')
                setCurrentStep(1)
              } else {
                createCampaign(campaign)
                setVisibleModal(false)
                setCampaign(baseCampaign)
                setCurrentStep(0)
              }
            }}
          >
            {(!campaign.name || !campaign?.products?.length) ? (
              <FormattedMessage
                id='campaigns.modal.button.create.changeNeeded'
                defaultMessage='La campagne a besoin {needName} {and} {needProduct}'
                values={{
                  needName: !campaign.name ? 'd\'un nom' : false,
                  needProduct: !campaign?.products?.length ? 'd\'un produit' : false,
                  and: !campaign.name && !campaign?.products?.length ? 'et' : false,
                }}
              />
            ) : (
              <FormattedMessage
                id='campaigns.createCampaign'
                defaultMessage='Créer la campagne'
              />
            )}
          </Button>
        </div>
      </Modal>
    )
  } catch (e) {
    console.log('error molecules/CampaignCreationModal', e)
    return null
  }
}

export default CampaignCreationModal

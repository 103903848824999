import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'

import { fromShops } from '../../store/selectors'
import { PRODUCT_DRAWER } from '../../store/products/actions'

export default connect(
  (state) => ({
    shop: fromShops.getId(state),
  }),
  (dispatch) => bindActionCreators({
    openDrawer: (value) => ({
      type: PRODUCT_DRAWER,
      payload: {
        open: value,
      },
    }),
  }, dispatch),
)(({ openDrawer, type }) => (
  <Button
    key='modify'
    type={type}
    onClick={() => openDrawer(true)}
  >
    <FormattedMessage id='modify' defaultMessage='Modifier' />
  </Button>
))

import config from '../../config'

// eslint-disable-next-line import/prefer-default-export
export const getGoogleFonts = async () => {
  try {
    const response = await fetch(config.fontApiUrl)
    return await response.json()
  } catch (err) {
    console.log(err, `unable to fetch ${config.fontApiUrl}`)
    return []
  }
}

import React from 'react'

export default ({
  type,
  height = 'inherit',
  width = 'inherit',
  style = {},
}) => {
  let src
  switch (type) {
  case 'white-bird':
    src = '../logo-panopli-08.png'
    break
  case 'blue-bird':
    src = '../logo-panopli-04.png'
    break
  case 'blue-panopli-bird':
    src = '../logo-panopli-02.png'
    break
  case 'blue-panopli':
    src = '../logo-panopli-03.png'
    break
  case 'white-panopli':
    src = '../logo-panopli-06.png'
    break
  case 'beige-bird':
    src = '../poussin_beige.png'
    break
  default:
    src = '../logo-panopli-02.png'
    break
  }
  return (
    <img
      style={{
        ...style,
        height,
        width,
      }}
      src={src}
      alt=''
    />
  )
}

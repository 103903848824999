import React from 'react'
import { Table } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Placeholder } from '../../organisms'
import { white, neutralColor } from '../../../config/theme'

const CustomizableTable = (props) => {
  const {
    columns, // Required
    dataSource, // Required
    isPaginated = false,
    isSelectable = false,
    rowSelection,
    rowKey,
    style,
  } = props

  const tableContainerStyle = {
    width: '100%',
    background: white,
    boxShadow: '0px 0px 24px 0px rgba(16, 24, 40, 0.03)',
    border: `1px solid ${neutralColor[3]}`,
    borderRadius: '16px',
    overflow: 'hidden',
    ...style,
  }

  return (
    <>
      {dataSource?.length === 0
        ? (
          <div
            style={{
              position: 'relative',
              marginTop: '10%',
            }}
          >
            <Placeholder
              description={<FormattedMessage id='search.notFound' defaultMessage='Aucun résultat ne correspond à votre recherche' />}
            />
          </div>
        ) : (
          <div style={tableContainerStyle}>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={isPaginated ? {
                defaultPageSize: 12,
                defaultCurrent: 1,
                pageSizeOptions: ['12', '24', '48'],
                showSizeChanger: true,
                total: dataSource?.length,
                showTotal: (total) => (
                  <FormattedMessage
                    id='grid.results'
                    defaultMessage='{items, plural, =0 {# résultat} one {# résultat} other {# résultats}}'
                    values={{
                      items: total,
                    }}
                  />
                ),
              } : false}
              rowSelection={isSelectable ? rowSelection : null}
              rowKey={isSelectable ? rowKey : null}
            />
          </div>
        )}
    </>
  )
}

export default CustomizableTable

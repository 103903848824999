import { flatten } from 'lodash'

export const initialState = {
  data: [],
  error_details: null,
  loading: true,
  loading_more: true,
  error: false,
  order_loading: false,
  batchError: false,
  errorDetails: null,
}

export const isLoading = (state = initialState) => state.batchLoading

export const getOrderState = (state = initialState) => state

export const getErrorsDetails = (state = initialState) => {
  try {
    return state.error_details
  } catch (e) {
    console.log('getErrorsDetails', e)
    return ''
  }
}

export const getBatchErrorDetails = (state = initialState) => {
  try {
    return state.errorDetails
  } catch (e) {
    return null
  }
}

export const getBatchError = (state = initialState) => {
  try {
    return state.batchError
  } catch (e) {
    return false
  }
}

export const getOrderCount = (state = initialState, productId) => {
  try {
    const result = flatten(state.data.map((o) => o.products))
      .filter((p) => String(p.id || p._id) === String(productId))
      .reduce((a, c) => a + c.quantity, 0)
    return result
  } catch (e) {
    console.log('getOrderCount', { e })
    return 0
  }
}

export const getOrder = (state = initialState, orderId) => state.data
  .find((o) => o._id === orderId).map((order) => ({
    ...order,
    status: order.status === 'Received' ? 'In Production' : order.status,
  }))

export const getModifiedOrders = (state = initialState) => state.data
  .filter((order) => order.being_modified) || {}

export const getRelatedOrders = (state = initialState, filters = {}) => {
  try {
    const {
      product,
    } = filters
    if (product) {
      return state.data.filter((o) => o.products.some((p) => p._id === product || p.id === product))
    }
    return state.data
  } catch (e) {
    console.log('getRelatedData', { e })
    return state.data
  }
}

export const orderLoading = (state = initialState) => state.order_loading || false

export const lastFetched = (state = initialState) => state.lastFetched || false

export const getOrders = (state = initialState, filter) => {
  if (!filter) return state.data || []
  // console.log(filter)
  // const {
  //   // createdAt,
  //   // product,
  //   // order_id,
  //   // status,
  //   // customerOrderId,
  // } = filter
  // TODO filter
  return state.data || []
}

export const loading = (state = initialState) => state.loading || false

export const error = (state = initialState) => state.error || false

const matchedCategoriesByName = (category, locale, filterCategory) => {
  if (!(category instanceof Object)
    || typeof locale !== 'string'
    || typeof filterCategory !== 'string'
  ) {
    console.log(
      'expected category, locale, filterCategory to be array, string, string, got %o, %o, %o',
      category,
      locale,
      filterCategory,
    )
    return []
  }
  return category[locale].filter(
    (categoryName) => categoryName === filterCategory,
  )
}

const isCategoriesMatchedByName = (
  category,
  locale, filterCategory,
) => !!(matchedCategoriesByName(category, locale, filterCategory).length)

export { matchedCategoriesByName, isCategoriesMatchedByName }

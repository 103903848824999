const getCategory = (cat) => {
  try {
    if (cat && Array.isArray(cat) && cat.length > 1) {
      return 'Unisexe'
    }
    if (cat) {
      return cat
    }
    return 'category'
  } catch (error) {
    console.log('URLs - getCategory', { error })
    return null
  }
}

/**
 * @description generates a path to access the productPage
 * @param {object} product our product
 * @param {string} [locale='fr'] the app locale
 * @returns {string} The product URL
 */
// TODO remove? if not tests
const getProductURL = (product, locale = 'fr') => {
  try {
    const { baseProduct } = product
    let cat = getCategory(baseProduct.category[locale])
    let subCat = baseProduct.subCategory[locale]

    let { name } = product
    if (!cat || !subCat) {
      return `/product/${name}/${product._id}`
    }
    cat = cat.toString().replace(/ /g, '-')
    name = name.toString().replace(/ /g, '-')
    subCat = subCat.toString().replace(/ /g, '-')
    return `/product/${encodeURIComponent(cat) || 'category'}/${encodeURIComponent(subCat)
      || 'subcategory'}/${encodeURIComponent(name) || 'name'}/${product._id || product.id}`
  } catch (e) {
    console.log('Get product URL', e)
    if (product.id) return `/product/category/subcategory/name/${product.id}`
    return null
  }
}

// TODO docs
// TODO tests
const extractParams = (queryString) => {
  try {
    const query = {}
    const pairs = (queryString[0] === '?'
      ? queryString.substr(1)
      : queryString
    ).split('&')
    for (let i = 0; i < pairs.length; i += 1) {
      const pair = pairs[i].split('=')
      if (pair[0] !== '') {
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
      }
    }
    return query
  } catch (e) {
    console.log('extractParams error:', e)
    return null
  }
}

const extractDataAndParams = (queryString, separator) => {
  try {
    if (queryString.includes(separator)) {
      const splitted = queryString.split(separator)
      const dataFromURL = extractParams(splitted[0])
      const queryFromURL = extractParams(splitted[1])
      return { dataFromURL, queryFromURL }
    }
    return { dataFromURL: null, queryFromURL: extractParams(queryString) }
  } catch (e) {
    console.log('extractDataAndParams error:', e)
    return null
  }
}

// TODO doc
// TODO tests
const writeUrl = (filters = {}) => {
  try {
    const url = Object.keys(filters).map((key) => {
      if (filters[key]) {
        return `${key}=${filters[key]}&`
      } return ''
    }).join('')
    return `?${url}`
  } catch (e) {
    console.log('error writeUrl', e)
    return '?'
  }
}

export default getProductURL
export { extractParams, extractDataAndParams, writeUrl }

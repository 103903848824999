import React from 'react'
import { ContinueStepButton } from '../..'

const PreviousStepButton = (props) => {
  const {
    messageId = 'campaigns.create.step.theme.previous',
    defaultMessage = 'Précédent',
    onClickFunction,
    disabled = false,
    style = {
      width: '100%',
      marginTop: '32px',
      backgroundColor: disabled ? theme.white : null,
    },
  } = props

  return (
    <>
      <ContinueStepButton
        messageId={messageId}
        defaultMessage={defaultMessage}
        onClickFunction={onClickFunction}
        style={style}
      />
    </>
  )
}

export default PreviousStepButton

import { Collapse } from 'antd'
import React from 'react'
import { neutralColor } from '../../../config/theme'
import { CampaignSearchMagicLink, CampaignDisplayFiltersButton } from '../../atoms'

const { Panel } = Collapse

const MagicLinkFilters = (props) => {
  const {
    setFilters, // Required
    filters, // Required
    setOpenFilter, // Required
    openFilter, // Required
    filterNumber, // Required
    children, // Required, contains one or more filters
  } = props

  return (
    <>
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px',
      }}
      >
        <CampaignSearchMagicLink
          setFilters={setFilters}
          filters={filters}
        />
        <CampaignDisplayFiltersButton
          setOpenFilter={setOpenFilter}
          openFilter={openFilter}
          filterNumber={filterNumber}
        />
      </div>
      <Collapse activeKey={openFilter} bordered={false}>
        <Panel key='1' showArrow={false} className='antd-collapse-no-panel'>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            padding: 16,
            borderRadius: 8,
            background: neutralColor[2],
            marginTop: '12px',
            gap: 16,
          }}
          >
            {children}
          </div>
        </Panel>
      </Collapse>
    </>
  )
}

export default MagicLinkFilters

export const initialState = {
  data: [],
  loading: false,
  error: false,
  confirmationModalOpened: false,
}

export const getProviders = (state = initialState) => state.data || []

export const loading = (state = initialState) => (state.loading || false)

export const error = (state = initialState) => state.error || false

export const modalOpened = (state = initialState) => state.confirmationModalOpened

import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import {
  CampaignDonationDrawer,
  CampaignProductsPanel,
  Placeholder,
  ProductStepGrid,
  ProductStepGridFilters,
} from '../..'

import { applySorter, sortProductsByMoreRecentDate } from '../../../helpers/sorters'
import { applyFilters } from '../../../helpers/filters'

const CampaignCreationProducts = (props) => {
  try {
    const {
      setCampaign, // Required
      campaign, // Required
      products, // Required
      moveNextStep, // Required
      movePreviousStep, // Required
      setNeedUpdateCampaign, // Required
      isDisabled = false,
      columnWidth = '20%',
      creationMode = false,
      entity, // Required
      shop, // Required
      user, // Required
    } = props

    // Set an array of 1 function instead of a function because if we set a function inside useState it will execute it immediatly
    // which is not the expected behaviour.
    const [sorter, setSorter] = useState([sortProductsByMoreRecentDate])
    const [filters, setFilters] = useState([])
    const [filteredProducts, setFilteredProducts] = useState(products)
    const [displayDonationDrawer, setDisplayDonationDrawer] = useState(!products.length && !campaign.donation?.active)
    const [checkedDonation, setCheckedDonation] = useState(campaign.donation?.active || !products.length)
    const [checkedSurprise, setCheckedSurprise] = useState(campaign.surprise)

    const campaignProducts = products?.filter((product) => campaign?.products?.includes(product._id || product.id))
    const MAX_PRODUCTS_SELECT = checkedSurprise ? 1 : 5

    useEffect(() => {
      let handledProducts = products.slice()
      handledProducts = applyFilters(filters, handledProducts)
      if (sorter && sorter[0]) {
        setFilteredProducts(applySorter(sorter[0], handledProducts))
      }
    }, [sorter, filters, products])

    const addSelectedProduct = (product) => {
      if (campaign?.products?.length < MAX_PRODUCTS_SELECT
        && !campaign?.products?.includes(product._id)
      ) {
        const newProducts = campaign?.products?.slice() ?? []
        newProducts?.push(product._id)
        setCampaign({ ...campaign, products: newProducts })
        setNeedUpdateCampaign(true)
      }
    }

    const removeSelectedProduct = (removedProduct) => {
      const newProducts = campaign.products.filter((product) => product !== removedProduct._id)
      setCampaign({ ...campaign, products: newProducts })
      setNeedUpdateCampaign(true)
    }

    const onClickSetDonationAmountButton = () => {
      setCheckedDonation(true)
      setDisplayDonationDrawer(true)
    }

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        height: creationMode ? 'calc(100vh - 210px)' : 'calc(100vh - 264px)',
      }}
      >
        <CampaignDonationDrawer
          displayDonationDrawer={displayDonationDrawer}
          setDisplayDonationDrawer={setDisplayDonationDrawer}
          campaign={campaign}
          setCampaign={setCampaign}
          setCheckedDonation={setCheckedDonation}
          setCheckedSurprise={setCheckedSurprise}
          setNeedUpdateCampaign={setNeedUpdateCampaign}
          entity={entity}
          shop={shop}
          user={user}
        />
        <CampaignProductsPanel
          campaign={campaign}
          setCampaign={setCampaign}
          setNeedUpdateCampaign={setNeedUpdateCampaign}
          selectedProducts={campaignProducts}
          removeSelectedProduct={removeSelectedProduct}
          moveNextStep={moveNextStep}
          movePreviousStep={movePreviousStep}
          setDisplayDonationDrawer={setDisplayDonationDrawer}
          checkedDonation={checkedDonation}
          setCheckedDonation={setCheckedDonation}
          checkedSurprise={checkedSurprise}
          setCheckedSurprise={setCheckedSurprise}
          entity={entity}
          shop={shop}
          user={user}
        />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          width: '100%',
        }}
        >
          {!products.length && (
            <Placeholder
              style={{
                position: 'none',
                width: '100%',
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              innerStyle={{ width: 'auto' }}
            >
              <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px',
              }}
              >
                <FormattedMessage
                  id='products.placeholder.donation'
                  defaultMessage='Vous pouvez néanmoins créer une campagne et proposer à vos destinataire de faire un don à une association.'
                />
                <Button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '500',
                    fontSize: '14px',
                    height: '40px',
                    gap: '5px',
                  }}
                  type='primary'
                  onClick={onClickSetDonationAmountButton}
                >
                  <FormattedMessage
                    id='donation.set.subtitle'
                    defaultMessage='Définir le montant du don'
                  />
                </Button>
              </div>
            </Placeholder>
          )}
          {!isDisabled && !!products.length && (
            <ProductStepGridFilters
              filters={filters}
              setFilters={setFilters}
              setSorter={setSorter}
              filteredProducts={filteredProducts}
            />
          )}
          {filteredProducts?.length > 0 && Array.isArray(campaign?.products) && (
            <ProductStepGrid
              products={isDisabled ? campaignProducts : filteredProducts}
              removeSelectedProduct={removeSelectedProduct}
              addSelectedProduct={addSelectedProduct}
              selectedProducts={campaign?.products}
              MAX_PRODUCTS_SELECT={MAX_PRODUCTS_SELECT}
              isDisabled={isDisabled}
              columnWidth={columnWidth}
            />
          )}
        </div>
      </div>
    )
  } catch (e) {
    console.log('error molecules/CampaignCreationModal/ProductsStep', e)
    return null
  }
}

export default CampaignCreationProducts

export const initialState = {
  data: [],
  loading: false,
  error: false,
}

export const getEntities = (state = initialState) => state.data || []
export const getAllEntities = (state = initialState) => state.allEntities || []
export const loading = (state = initialState) => state.loading
export const error = (state = initialState) => state.error

import React, { useState, useEffect } from 'react'
import {
  Alert, Button, Drawer, Input,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import DrawerTitle from '../DrawerTitle'
import { MagicLinksInputTitle } from '../../atoms'
import { character, neutralColor, secondaryColor } from '../../../config/theme'
import {
  trimCentsToEuros,
  trimEurosToCents,
  getDonationServiceCharge,
  getTotalDonationCost,
  isValidInputEntry,
  getTitle,
  getDescription,
  isCharitipsClientAccountSetUp,
  sendSlackNotificationOnDonationUseAttempt,
} from '../../../helpers/donation'
import { fromUsers } from '../../../store/selectors'

const CampaignDonationDrawer = (props) => {
  try {
    const {
      campaign, // Required
      setCampaign, // Required
      displayDonationDrawer, // Required
      setDisplayDonationDrawer, // Required
      setNeedUpdateCampaign, // Required
      setCheckedDonation, // Required
      setCheckedSurprise, // Required
      amountIcon,
      shop, // Required
      entity, // Required
      user, // Required
    } = props

    const { donation } = campaign

    const isDonationDisabled = !isCharitipsClientAccountSetUp(shop, entity)
    const authToken = useSelector(fromUsers.getAuth)

    // On transforme le montant en DB de centimes en unité monétaire (nombre entier)
    const [inputValue, setInputValue] = useState(trimCentsToEuros(donation?.amount))

    const onChange = (e) => {
      const { value } = e.target
      // On ne garde que les chiffres, pour obtenir un nombre entier
      if (isValidInputEntry(value)) setInputValue(value)
    }

    const onClose = () => {
      // Si la campagne avait une prop "donation.active" en false, on se contente de décocher le switch Donation
      if (!donation?.active) setCheckedDonation(false)
      // On ferme proprement le drawer
      setDisplayDonationDrawer(false)
    }

    const onConfirm = () => {
      // On inscrit les modifications dans l'objet "campaign" qui sera envoyé en DB lors de la sauvegarde de la campagne
      setCampaign({ ...campaign, donation: { active: true, amount: trimEurosToCents(inputValue) } })
      // On désactive l'option surprise si elle était déjà activée
      setCheckedSurprise(false)
      // On enable la sauvegarde de campagne dans le header
      setNeedUpdateCampaign(true)
      // On ferme le drawer simplement
      setDisplayDonationDrawer(false)
    }

    const onCancel = () => {
      // On réinitialise l'input à la valeur initiale de la prop "donation" de la campagne
      setInputValue(trimCentsToEuros(donation?.amount))
      // On ferme proprement le drawer en gérant correctement le switch Donation
      onClose()
    }

    useEffect(() => {
      // Quand le drawer s'ouvre avec changement de displayDonationDrawer, on vérifie
      // si l'utilisateur essaie d'utiliser la feature donation sans avoir les infos nécessaires
      if (displayDonationDrawer && !isCharitipsClientAccountSetUp(shop, entity)) {
        sendSlackNotificationOnDonationUseAttempt(authToken, shop, entity, user)
      }
    }, [displayDonationDrawer, shop, entity])

    return (
      <Drawer
        visible={displayDonationDrawer}
        maskClosable={false}
        closable={false}
        destroyOnClose
        width='480px'
        bodyStyle={{
          padding: '48px 24px 24px',
          height: '100vh',
          position: 'relative',
        }}
      >
        <DrawerTitle
          title={getTitle(campaign)}
          subtitle={getDescription(campaign)}
          subtitleStyle={{ maxWidth: '368px' }}
          closeButtonStyle={{
            border: `1 solid ${neutralColor[2]}`,
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          }}
          closeButtonIconStyle={{ fontSize: 14, color: character.title }}
          onClose={onClose}
        />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'center',
        }}
        >
          {isDonationDisabled
          && (
            <Alert
              message={(
                <FormattedMessage
                  id='donation.warning.missingData'
                  defaultMessage={'Pour pouvoir utiliser cette fonctionnalité et bénéficier de reçus fiscaux, '
                  + 'nous avons besoin d&apos;informations supplémentaires sur votre entreprise (SIREN, adresse, gestionnaire de la boutique) !\n'
                  + 'Nous allons vous recontacter très rapidement.'}
                />
              )}
              type='warning'
              style={{ width: '100%' }}
            />
          )}
          <MagicLinksInputTitle
            title={(
              <FormattedMessage
                id='donation.amount'
                defaultMessage='Montant du don'
              />
            )}
          >
            <Input
              style={{ width: '100%' }}
              suffix={<span style={{ fontSize: 14, color: character.disabledPlaceholder }}>€</span>}
              value={inputValue}
              onChange={onChange}
            />
            {inputValue && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '0 16px',
                  justifyContent: 'space-between',
                  gap: '8px',
                  color: character.disabledPlaceholder,
                  fontSize: 12,
                }}
                >
                  <FormattedMessage id='serviceCharge' defaultMessage='Frais de service' />
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <span>{getDonationServiceCharge(inputValue)}</span>
                    <span>{amountIcon || '€'}</span>
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: '16px',
                  background: neutralColor[0],
                  width: '100%',
                  padding: '16px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '16px',
                  fontSize: 14,
                  fontWeight: 600,
                  color: character.mark,
                }}
                >
                  <FormattedMessage id='total' defaultMessage='Total' />
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 8px',
                    gap: '4px',
                    background: 'rgba(230, 233, 240, 0.5)',
                    backdropFilter: 'blur(11px)',
                    borderRadius: 1000,
                    color: secondaryColor[6],
                  }}
                  >
                    <span>{getTotalDonationCost(inputValue)}</span>
                    <span>{amountIcon || '€'}</span>
                  </div>
                </div>
              </div>
            )}
          </MagicLinksInputTitle>
          <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
          >
            <Button
              type='primary'
              block
              style={{ height: 40 }}
              disabled={!inputValue || isDonationDisabled}
              onClick={onConfirm}
            >
              {donation?.active
                ? <FormattedMessage id='donation.confirm.amount' defaultMessage='Modifier le montant du don' />
                : <FormattedMessage id='donation.confirm' defaultMessage='Confirmer le don' />}
            </Button>
            <Button
              block
              style={{ height: 40 }}
              onClick={onCancel}
            >
              <FormattedMessage id='cancel' defaultMessage='Annuler' />
            </Button>
          </div>
        </div>
      </Drawer>
    )
  } catch (e) {
    console.log('molecules/CampaignDonationDrawer error:', e)
    return null
  }
}

export default CampaignDonationDrawer

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input } from 'antd'
import theme from '../../../config/theme'
import { StepInputLabel } from '../..'

const FormStepInput = (props) => {
  const {
    titleMessageId, // Required
    titleDefaultMesage, // Required
    placeholderMessageId = '',
    placeholderDefaultMessage = '',
    inputValue = '',
    onChangeFunction = null,
    disabled = false,
  } = props
  const intl = useIntl()

  return (
    <>
      <StepInputLabel
        title={<FormattedMessage id={titleMessageId} defaultMessage={titleDefaultMesage} />}
        titleStyle={{ marginBottom: '8px', display: 'block' }}
      />
      <Input
        placeholder={placeholderMessageId
          && intl.formatMessage({
            id: placeholderMessageId,
            defaultMessage: placeholderDefaultMessage,
          })}
        value={inputValue}
        onChange={onChangeFunction}
        disabled={disabled}
        style={{ borderColor: theme.neutralColor[3] }}
      />
    </>
  )
}

export default FormStepInput

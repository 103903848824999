import { initialState } from './selectors'
import * as actions from './actions'

export default (state = initialState, { type, payload = {} }) => {
  const {
    data,
    budget,
    loading,
  } = payload
  switch (type) {
  case actions.GET:
    return {
      ...state,
    }
  case actions.LOADING:
    return {
      ...state,
      loading,
    }
  case actions.RECEIVED:
    return {
      ...state,
      loading: false,
      data,
      budget,
    }
  default:
    return state
  }
}

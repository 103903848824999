
import { initialState } from './selectors'
import * as actions from './actions'

export default (state = initialState, { type, payload = {} }) => {
  const {
    error_details,
    error_source,
    data,
    loading,
    discount,
    ids,
  } = payload
  switch (type) {
  case actions.CREATE_DISCOUNT:
    return {
      ...state,
    }
  case actions.CREATED_DISCOUNT:
    return {
      ...state,
      data: state.data.concat([discount])
    }
  case actions.RECEIVED:
    return {
      ...state,
      data,
      loading: false,
      error: false,
    }
  case actions.MODIFY:
    return {
      ...state,
      data: state.data.map((d) => {
        if (d._id !== discount._id) return d
        return {
          ...d,
          being_modified: true,
          modifications: discount,
        }
      }),
    }
  case actions.MODIFIED:
    return {
      data: state.data.map((d) => {
        if (!d.being_modified) return d
        return {
          ...d.modifications,
        }
      }),
    }
  case actions.DELETE:
    return {
      data: state.data.map((d) => {
        if (!ids.includes(d._id)) return d
        return {
          ...d,
          being_deleted: true,
        }
      }),
    }
  case actions.DELETED:
    return {
      data: state.data.filter((d) => !d.being_deleted),
    }
  case actions.GET:
    return {
      ...state,
      loading: true,
      error: false,
      lastFetched: new Date(),
    }
  case actions.ERROR:
    return {
      ...state,
      error: true,
      loading: false,
      error_source,
      error_details,
    }
  case actions.LOADING:
    return {
      ...state,
      loading,
    }
  default:
    return state
  }
}

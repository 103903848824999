import React, { useState, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { uniqBy } from 'lodash'
import {
  Table,
  Button,
  Icon,
  Select,
  Badge,
  Tag,
  DatePicker,
} from 'antd'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import { ColumnEllipsis, Loader, Placeholder } from '../../components'

import { displayDate } from '../../helpers/trim'
import { getColumnSearchProps, filterData } from '../../helpers/filters'
import { getStatus, getStatusColor, getType } from '../../helpers/orders'
import { getFormattedOrders } from '../../store/globalSelectors'
import {
  fromOrders,
  fromUsers,
  fromEntities,
  fromLocale,
} from '../../store/selectors'

import './index.css'

const { Option } = Select
const { RangePicker } = DatePicker

const dateFormatList = ['DD/MM/YYYY', 'MM/DD/YYYY']

const OrdersTable = (props) => {
  const {
    loading,
    error,
    filtering,
    displayDrawer,
    selectedRowKeys,
    allOrders,
    filters,
    onSelectChange,
    isAdmin,
    entities,
    locale = 'fr',
  } = props

  const { range } = filters

  const [pageSize, setPageSize] = useState(40)

  const getProductsFromOrders = () => {
    const products = []
    allOrders.map((order) => order.products.map((product) => products.push(product)))
    return uniqBy(products, 'name')
  }

  const displayNotification = (order) => {
    if (order.status === 'Waiting' && order.giftOrder) return 1
    if (order.subOrders && order.subOrders.find((subOrder) => subOrder.supplier === '5b06affc8427184204089fa0')) return 1
    return 0
  }

  const orders = structuredClone(allOrders)
  const products = getProductsFromOrders()

  // on Formate les données pour le tableau et on memoize le resultat pour optimiser le temps de traitement
  // On recalculera uniquement si orders.length change

  // on applique les filtres sur les orders et on memorise le résultat.
  // on recalculera si orders.length change ou bien l'objet filters (qui contient les filtres)
  const filteredData = useMemo(() => filterData(orders, filters, {
    product: (value, record) => record.products.find((_product) => (_product._id || _product.id) === value),
    entityId: (value, record) => record.entityId && record.entityId === value,
    range: (value, record) => {
      dayjs.extend(isBetween)
      return dayjs(record.createdAt).isBetween(dayjs(value[0]), dayjs(value[1]), null, [])
    },
  }), [allOrders.length, filters])

  if (error) return <div>Error placeholder</div>
  if (loading && !orders.length) return <Loader />
  if (!orders.length) return <Placeholder />

  const columns = [
    {
      title: <FormattedMessage id='date' defaultMessage='Date' />,
      key: 'date',
      sorter: (a, b) => dayjs().isAfter(b.createdAt),
      sortDirections: ['descend', 'ascend'],
      dataIndex: 'formatted.createdAt',
      ...getColumnSearchProps('range', filtering, {
        filterDropdown: () => (
          <div
            className='orderId-filter-dropdown container'
            style={{
              textAlign: 'center',
              padding: '5px',
            }}
          >
            <RangePicker
              format={locale === 'en' ? dateFormatList[1] : dateFormatList[0]}
              value={range}
              onChange={(datesString) => {
                const start = dayjs(datesString[0], dateFormatList[1])
                const end = dayjs(datesString[1], dateFormatList[1])
                filtering({ range: [start, end] })
              }}
              style={{ width: '300px' }}
              allowClear={false}
            />
            ,
          </div>
        ),
      }),
      render: (date) => (
        <ColumnEllipsis>
          {displayDate(date, locale)}
        </ColumnEllipsis>
      ),
    },
    {
      title: <FormattedMessage id='orderNumber' defaultMessage='Numéro de commande' />,
      key: 'customerOrderId',
      dataIndex: 'formatted.customerOrderId',
      ...getColumnSearchProps('customerOrderId', filtering),
    },
    {
      title: <FormattedMessage id='entity' defaultMessage='Entité' />,
      key: 'entity',
      dataIndex: 'formatted.entity',
      ...getColumnSearchProps('entity', filtering, {
        filterDropdown: () => (
          <div
            className='orderId-filter-dropdown container'
            style={{
              textAlign: 'center',
              padding: '5px',
            }}
          >
            <Select
              onChange={(value) => {
                filtering({ entityId: value })
              }}
              style={{ width: 200 }}
              placeholder={<FormattedMessage id='entity.select' defaultMessage='Sélectionnez une entité' />}
              allowClear
            >
              {entities.map((entity) => (
                <Option key={entity._id} value={entity._id}>
                  {entity.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
      }),
      render: (data) => <ColumnEllipsis>{data}</ColumnEllipsis>,
    },
    {
      title: <FormattedMessage id='address' defaultMessage='Adresse' />,
      key: 'address',
      dataIndex: 'formatted.address',
      ...getColumnSearchProps('address', filtering),
      render: (data) => <ColumnEllipsis>{data}</ColumnEllipsis>,
    },
    {
      title: <FormattedMessage id='lastName' defaultMessage='Nom' />,
      key: 'name',
      dataIndex: 'formatted.name',
      ...getColumnSearchProps('name', filtering),
      render: (data) => <ColumnEllipsis>{data}</ColumnEllipsis>,
    },
    {
      title: <FormattedMessage id='email' defaultMessage='Email' />,
      key: 'email',
      dataIndex: 'formatted.email',
      ...getColumnSearchProps('email', filtering),
      render: (data) => <ColumnEllipsis>{data}</ColumnEllipsis>,
    },
    {
      title: <FormattedMessage id='type' defaultMessage='Type' />,
      key: 'purchase',
      dataIndex: 'formatted.type',
      ...getColumnSearchProps('type', filtering, {
        filterDropdown: () => (
          <div
            className='orderId-filter-dropdown container'
            style={{
              textAlign: 'center',
              padding: '5px',
            }}
          >
            <Button onClick={() => filtering({ type: 'Boutique' })}>
              <FormattedMessage id='shop' defaultMessage='Boutique' />
            </Button>
            <Button style={{ margin: '0 5px' }} onClick={() => filtering({ type: 'Interne' })}>
              <FormattedMessage id='internal' defaultMessage='Interne' />
            </Button>
            <Button style={{ margin: '0 5px' }} onClick={() => filtering({ type: 'MagicLink' })}>
              <FormattedMessage id='magicLink' defaultMessage='MagicLink' />
            </Button>
            <Button onClick={() => filtering({ type: 'Cadeau' })}>
              <FormattedMessage id='gift' defaultMessage='Cadeau' />
            </Button>
            <Button style={{ marginLeft: '5px' }} onClick={() => filtering({ type: 'Groupée' })}>
              <FormattedMessage id='groupedOrder' defaultMessage='Groupée' />
            </Button>
            <Button style={{ marginLeft: '5px' }} onClick={() => filtering({ type: 'Self Send' })}>
              Self Send
            </Button>
            {isAdmin ? (
              <Button style={{ marginLeft: '5px' }} onClick={() => filtering({ type: 'Admin' })}>
                Admin
              </Button>
            ) : null}
          </div>
        ),
      }),
      render: (type) => getType(type),
    },
    {
      title: <FormattedMessage id='discount' defaultMessage='Promotion' />,
      align: 'center',
      key: 'discount',
      dataIndex: 'formatted.discount',
      ...getColumnSearchProps('discount', filtering),
      render: (data) => <ColumnEllipsis>{data}</ColumnEllipsis>,
    },
    {
      title: <FormattedMessage id='totalPriceIncl' defaultMessage='Prix total TTC' />,
      align: 'center',
      key: 'totalPrice',
      dataIndex: 'formatted.price',
      sorter: (a, b) => a.transaction.amount - b.transaction.amount,
      sortDirections: ['descend', 'ascend'],
      render: (data) => <ColumnEllipsis>{data}</ColumnEllipsis>,
    },
    {
      title: <FormattedMessage id='status' defaultMessage='Statut' />,
      key: 'status',
      align: 'center',
      dataIndex: 'formatted.status',
      ...getColumnSearchProps('status', filtering, {
        filterDropdown: (
          <div
            className='orderId-filter-dropdown container'
            style={{
              textAlign: 'center',
              padding: '5px',
            }}
          >
            <Button onClick={() => filtering({ status: locale === 'fr' ? 'En Attente' : 'Waiting' })}>
              <FormattedMessage id='waiting' defaultMessage='En attente' />
            </Button>
            <Button style={{ marginLeft: '5px' }} onClick={() => filtering({ status: locale === 'fr' ? 'Envoyée' : 'Shipped' })}>
              <FormattedMessage id='shipped.female' defaultMessage='Envoyée' />
            </Button>
            <Button style={{ marginLeft: '5px' }} onClick={() => filtering({ status: locale === 'fr' ? 'Livrée' : 'Delivered' })}>
              <FormattedMessage id='delivered.female' defaultMessage='Livrée' />
            </Button>
            <Button style={{ margin: '0 5px' }} onClick={() => filtering({ status: locale === 'fr' ? 'Annulée' : 'Canceled' })}>
              <FormattedMessage id='canceled' defaultMessage='Annulée' />
            </Button>
            <Button onClick={() => filtering({ status: locale === 'fr' ? 'En Production' : 'In Production' })}>
              <FormattedMessage id='inProduction' defaultMessage='En Production' />
            </Button>
          </div>
        ),
      }),
      render: (status) => (
        <Tag color={getStatusColor(status)}>
          {getStatus(status)}
        </Tag>
      ),
    },
    {
      title: <FormattedMessage id='products' defaultMessage='Produits' />,
      key: 'products',
      dataIndex: 'formatted.products',
      ...getColumnSearchProps('products', filtering, {
        filterDropdown: () => (
          <div
            className='orderId-filter-dropdown container'
            style={{
              textAlign: 'center',
              padding: '5px',
            }}
          >
            <Select
              onChange={(value) => {
                filtering({ product: value })
              }}
              style={{ width: 200 }}
              placeholder={<FormattedMessage id='productName' defaultMessage='Nom du produit' />}
            >
              {products.map((product) => (
                <Option key={Math.random().toString(36).substr(2, 10)} value={product._id}>
                  {product.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        onFilter: (value, record) => record.find((_product) => (_product._id || _product.id) === value),
      }),
      render: (number) => (
        <FormattedMessage
          id='campaign.card.products'
          defaultMessage='{items, plural, =0 {# produit} one {# produit} other {# produits}}'
          values={{ items: number }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      render: (record) => (
        <Badge dot count={displayNotification(record)}>
          <span onClick={() => displayDrawer(record)}>
            <Icon type='info-circle' style={{ cursor: 'pointer', fontSize: '15px' }} />
          </span>
        </Badge>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={filteredData}
      rowKey={(record) => record._id}
      size='small'
      pagination={{
        showQuickJumper: true,
        pageSize,
        showSizeChanger: true,
        onShowSizeChange: (current, _pageSize) => {
          console.log('PAGE', _pageSize)
          setPageSize(_pageSize)
        },
        pageSizeOptions: ['40', '80', '160'],
      }}
      rowSelection={{
        selectedRowKeys,
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'all-data',
            text: <FormattedMessage id='selectAll' defaultMessage='Tout sélectionner' />,
            onSelect: () => {
              onSelectChange(allOrders)
            },
          },
          {
            key: 'reset-data',
            text: <FormattedMessage id='deselectAll' defaultMessage='Tout désélectionner' />,
            onSelect: () => {
              onSelectChange([])
            },
          },
        ],
        getCheckboxProps: (record) => ({
          disabled: !!record.statusLinkedTo,
        }),
      }}
      rowClassName={(record) => (record.statusLinkedTo
        ? 'children' : null)}
    />
  )
}

export default connect(
  (state) => ({
    allOrders: getFormattedOrders(state),
    loading: fromOrders.loading(state),
    error: fromOrders.error(state),
    isAdmin: fromUsers.isAdmin(state),
    entities: fromEntities.getEntities(state),
    locale: fromLocale.getLocale(state),
  }), null,
)(OrdersTable)

export const ABANDONNED_CART = 'ABANDONNED_CART'
export const ADD = 'ADD'
export const CART_LOADING = 'CART_LOADING'
export const CART_DELIVERY_PRICE = 'CART_DELIVERY_PRICE'
export const CART_DELIVERY_PRICE_ERROR = 'CART_DELIVERY_PRICE_ERROR'
export const CART_PRODUCTS_PRICE = 'CART_PRODUCTS_PRICE'
export const CART_TOTAL = 'CART_TOTAL'
export const CHECKOUT = 'CHECKOUT'
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR'
export const CLOSE_CUSTOMIZATIONS = 'CLOSE_CUSTOMIZATIONS'
export const CODE = 'CODE'
export const COUNTRY = 'COUNTRY'
export const DECREMENT = 'DECREMENT'
export const DELIVERABLE = 'DELIVERABLE'
export const DISCOUNT = 'DISCOUNT'
export const DISMISS_CHECKOUT_ERROR = 'DISMISS_CHECKOUT_ERROR'
export const EDIT_CUSTOMIZATIONS = 'EDIT_CUSTOMIZATIONS'
export const EMPTY = 'EMPTY'
export const INCREMENT = 'INCREMENT'
export const PICKUP_INFOS = 'PICKUP_INFOS'
export const PRICING = 'PRICING'
export const PURGE_DISCOUNT = 'PURGE_DISCOUNT'
export const REMOVE = 'REMOVE'
export const SET_CUSTOM_EDIT = 'SET_CUSTOM_EDIT'
export const SUBMIT_PROMO = 'SUBMIT_PROMO'
export const UNDELIVERABLE = 'UNDELIVERABLE'
export const UPDATE_CLIENT_INFOS = 'UPDATE_CLIENT_INFOS'
export const UPDATE_DELIVERY_MODE = 'UPDATE_DELIVERY_MODE'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const TOGGLE_PREFILLED = 'TOGGLE_PREFILLED'

export const add = (product) => ({
  type: ADD,
  payload: {
    // NOTE we need deep cloning to enforce objects detanglement
    product: JSON.parse(JSON.stringify(product)),
  },
})

export const remove = (target) => ({
  type: REMOVE,
  payload: {
    target,
  },
})

export const increment = (target) => ({
  type: INCREMENT,
  payload: {
    target,
  },
})

export const decrement = (target) => ({
  type: DECREMENT,
  payload: {
    target,
  },
})

export const checkout = (stripe, history, form) => ({
  type: CHECKOUT,
  payload: {
    stripe,
    history,
    form,
  },
})

export const checkoutError = () => ({
  type: CHECKOUT_ERROR,
})

export const dismissCheckoutError = () => ({
  type: DISMISS_CHECKOUT_ERROR,
})


export const editCustomization = (target) => ({
  type: SET_CUSTOM_EDIT,
  payload: {
    target,
  },
})

export const changeCustomizations = (target, cartItemUid) => ({
  type: EDIT_CUSTOMIZATIONS,
  payload: {
    target,
    cartItemUid,
  },
})

export const submit_promo = (discountCode) => ({
  type: SUBMIT_PROMO,
  payload: {
    discountCode,
  },
})

export const changeCountry = (country) => {
  console.log({ country })
  return {
    type: COUNTRY,
    payload: {
      country,
    },
  }
}

export const emptyCart = () => ({
  type: EMPTY,
})

export const setUndeliverable = (undeliverable) => ({
  type: UNDELIVERABLE,
  payload: {
    undeliverable,
  },
})

export const setPrefilledAddress = (isPrefilledAddress) => ({
  type: TOGGLE_PREFILLED,
  payload: {
    isPrefilledAddress,
  },
})

export const setDeliverable = () => ({
  type: DELIVERABLE,
})


export const purge_discount = () => ({
  type: PURGE_DISCOUNT,
})

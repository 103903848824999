const addressFormatter = (address, customFrequency) => {
  try {
    const formattedAddress = {
      name: address.name,
      description: address.description,
      regrouped: address.regrouped,
      frequency: customFrequency || address.frequency,
      reminder: address.reminder,
      contact: {
        firstName: address.firstName,
        lastName: address.lastName,
        email: address.email,
        phone: address.phone,
      },
      address: {
        ...address,
      },
    }
    if (address.entityId) formattedAddress.entityId = address.entityId
    delete formattedAddress.address.description
    delete formattedAddress.address.name
    delete formattedAddress.address.firstName
    delete formattedAddress.address.lastName
    delete formattedAddress.address.email
    delete formattedAddress.address.phone
    delete formattedAddress.address.entityId

    return formattedAddress
  } catch (error) {
    console.log('formatters - addressFormatter', { error })
    return false
  }
}

export default addressFormatter

// https://github.com/diegohaz/arc/wiki/Sagas
import {
  all, fork, actionChannel, take, select, call,
} from 'redux-saga/effects'

import { fromUsers } from './selectors'
import { refreshAuthToken, forceLogoutUser } from './users/sagas'

const req = require.context('.', true, /\.\/.+\/sagas\.js$/)

const sagas = req.keys().map((key) => req(key).default)

// watchRequest écoute toutes les actions et dispatch une action de refresh token si nécéssaire
function* watchRequest({ api }) {
  const requestChan = yield actionChannel('*')
  // spécifique a redux-saga , permet d'éxécuter la saga en continu
  while (true) {
    const { tokenIsExpired, tokenWillExpireSoon } = yield select(fromUsers.checkTokenExpiration)
    const isLoggedIn = yield select(fromUsers.isLoggedIn)
    yield take(requestChan)
    // si le token est expiré on déconnecte le user
    if (tokenIsExpired && isLoggedIn) {
      yield call(forceLogoutUser, api)
      // si il va bientot expirer on dispatch une action pour refresh le token
    } else if (tokenWillExpireSoon && isLoggedIn) {
      yield call(refreshAuthToken, api)
    }
  }
}

export default function* (services = {}) {
  yield all([call(watchRequest, services), ...sagas.map((saga) => fork(saga, services))])
}

import { countries } from '@balibart/shipping'
import postalCodesVerificator from 'postal-codes-js'
import { format, parsePhoneNumberFromString } from 'libphonenumber-js'
import { isEmailValid } from '../../emailValidation/index'

export default {
  firstName: (value) => {
    const errorDetails = { error: true, key: 'firstName' }
    if (!value || value === 'undefined') return { ...errorDetails, message: 'missing value' }
    return { value, key: errorDetails.key }
  },
  lastName: (value) => {
    const errorDetails = { error: true, key: 'lastName' }
    if (!value || value === 'undefined') return { ...errorDetails, message: 'missing value' }
    return { value, key: errorDetails.key }
  },
  email: (value) => {
    const errorDetails = { error: true, key: 'email' }
    if (!value || value === 'undefined') return { ...errorDetails, message: 'missing value' }
    return isEmailValid(value) ? { value, key: errorDetails.key } : { ...errorDetails, message: 'wrong formating' }
  },
  phone: (value, country) => {
    const errorDetails = { error: true, key: 'phone', message: 'wrong formatting' }
    try {
      if (!value || value === 'undefined') return { error: true, key: 'phone', message: 'missing value' }
      if (!country) return { ...errorDetails, message: 'missing country code' }
      const check = format(value, country, 'International')
      const phoneNumber = parsePhoneNumberFromString(check, 'country')
      const final = check && (phoneNumber?.isValid()) ? { value: check, key: errorDetails.key } : errorDetails
      return final
    } catch (e) {
      console.log('multiple Orders phone validator - error', e)
      return errorDetails
    }
  },
  streetAddress: (value) => {
    const errorDetails = { error: true, key: 'streetAddress' }
    if (!value || value === 'undefined') return { ...errorDetails, message: 'missing value' }
    return { value, key: errorDetails.key }
  },
  postalCode: (value, country) => {
    const errorDetails = { error: true, key: 'postalCode' }
    if (!value || value === 'undefined') return { ...errorDetails, message: 'missing value' }
    const check = postalCodesVerificator.validate(country, value)
    switch (check) {
    case true:
      return { value, key: errorDetails.key }
    case 'Missing country code.':
      return { ...errorDetails, message: 'missing country code' }
    default:
      return { ...errorDetails, message: 'unknow postal code' }
    }
  },
  city: (value) => {
    const errorDetails = { error: true, key: 'city' }
    if (!value || value === 'undefined') return { ...errorDetails, message: 'missing value' }
    return { value, key: errorDetails.key }
  },
  country: (value) => {
    const errorDetails = { error: true, key: 'country' }
    if (!value || value === 'undefined') return { ...errorDetails, message: 'missing value' }
    const country = countries.find((c) => c.value.toUpperCase() === value.toUpperCase())
    return (country?.value) ? { value: country.value, key: errorDetails.key } : { ...errorDetails, message: 'country not found' }
  },
}

/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import {
  Empty,
  Avatar,
  Button,
  Input,
  Table,
  Modal,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'

import {
  faUsers,
  faClockFour,
  faWallet,
  faCoins,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataBlock } from '../../atoms'
import { BudgetAddCreditModal } from '../..'
import { character, neutralColor } from '../../../config/theme'

const CampaignAdmin = (props) => {
  try {
    const intl = useIntl()
    const {
      collaborators,
      campaigns,
      budgets,
      addCredit,
    } = props

    if (budgets && !budgets.length) {
      return (
        <Empty
          imageStyle={{
            height: 80,
          }}
          description={(
            <FormattedMessage
              id='campaigns.parameters.user.empty'
              defaultMessage='Pas de budget créée'
            />
          )}
        />
      )
    }
    const [visibleModal, setVisibleModal] = useState(false)
    const [collaboratorsFiltered, setCollaborators] = useState(collaborators)
    const [selectedCollaborator, setSelectedCollaborator] = useState(false)
    const [credit, setCredit] = useState(1)

    const allCreditSpent = budgets.map((budget) => budget.creditSpent).reduce((prev, curr) => parseFloat(prev || 0) + parseFloat(curr))
    const allCommittedCredit = budgets.map((budget) => budget.committedCredit).reduce((prev, curr) => parseFloat(prev || 0) + parseFloat(curr))
    const allRemainingCredit = budgets.map((budget) => budget.balance).reduce((prev, curr) => parseFloat(prev || 0) + parseFloat(curr))
    const columns = [
      {
        title: (
          <span
            className='adminTabHeader'
          >
            <FormattedMessage id='campaignTracking.placeholders.admins' defaultMessage='Administrateurs' />
          </span>
        ),
        key: 'name',
        sorter: (adminCollaboratorA, adminCollaboratorB) => {
          const { additionalInfos: additionalInfosA } = adminCollaboratorA
          const { additionalInfos: additionalInfosB } = adminCollaboratorB
          const nameA = (additionalInfosA?.firstName || '') + (additionalInfosA?.lastName || '')
          const nameB = (additionalInfosB?.firstName || '') + (additionalInfosB?.lastName || '')
          return (nameA.toLowerCase().localeCompare(nameB.toLowerCase()))
        },
        render: (adminCollaborator) => {
          const { additionalInfos } = adminCollaborator
          return (
            <span>
              <Avatar size='small' icon='user' style={{ marginRight: '8px' }} />
              {additionalInfos?.firstName || ''} {additionalInfos?.lastName || ''}
            </span>
          )
        },
      },
      {
        title: (
          <span
            className='adminTabHeader'
          >
            <FormattedMessage id='campaignCollaboratorsTable.nbCampaignCreated' defaultMessage='Campagnes créées' />
          </span>
        ),
        key: 'lengthCampaigns',
        sorter: (adminCollaboratorA, adminCollaboratorB) => {
          const campaignA = campaigns.filter((campaign) => campaign.userId === adminCollaboratorA._id).length || 0
          const campaignB = campaigns.filter((campaign) => campaign.userId === adminCollaboratorB._id).length || 0
          return (campaignB - campaignA)
        },
        render: (adminCollaborator) => {
          const lengthCampaignsCreated = campaigns.filter((campaign) => campaign.userId === adminCollaborator._id)?.length || 0
          return (
            <span>
              {lengthCampaignsCreated}
            </span>
          )
        },
      },
      {
        title: (
          <span
            className='adminTabHeader'
          >
            <FormattedMessage id='campaignTracking.cards.creditSpent' defaultMessage='Links dépensés' />
          </span>
        ),
        key: 'creditSpent',
        sorter: (adminCollaboratorA, adminCollaboratorB) => {
          // je cherche le budget du adminCollaboratorA et adminCollaboratorB
          const budgetA = budgets.find((budget) => budget.userId === adminCollaboratorA._id)
          const budgetB = budgets.find((budget) => budget.userId === adminCollaboratorB._id)
          // je les soustraits pour savoir si il en a plus ou moins
          return (budgetB?.creditSpent - budgetA?.creditSpent)
        },
        render: (adminCollaborator) => {
          const budget = budgets.find((iBudget) => iBudget.userId === adminCollaborator._id)
          if (!budget) return 'N/C'
          return (
            <span>
              {`${budget.creditSpent || 0}`}
            </span>
          )
        },
      },
      {
        title: (
          <span
            className='adminTabHeader'
          >
            <FormattedMessage id='campaignTracking.cards.committedCredit' defaultMessage='Links engagés' />
          </span>
        ),
        key: 'committedCredit',
        sorter: (adminCollaboratorA, adminCollaboratorB) => {
          const budgetA = budgets.find((budget) => budget.userId === adminCollaboratorA._id)
          const budgetB = budgets.find((budget) => budget.userId === adminCollaboratorB._id)
          return (budgetB?.committedCredit - budgetA?.committedCredit)
        },
        render: (adminCollaborator) => {
          const budget = budgets.find((iBudget) => iBudget.userId === adminCollaborator._id)
          if (!budget) return 'N/C'
          return (
            <span>
              {budget.committedCredit || 0}
            </span>
          )
        },
      },
      {
        title: (
          <span
            className='adminTabHeader'
          >
            <FormattedMessage id='campaignCollaboratorsTable.balance' defaultMessage='Links restants' />
          </span>
        ),
        key: 'balance',
        sorter: (adminCollaboratorA, adminCollaboratorB) => {
          const budgetA = budgets.find((budget) => budget.userId === adminCollaboratorA._id)
          const budgetB = budgets.find((budget) => budget.userId === adminCollaboratorB._id)
          return (budgetB?.balance - budgetA?.balance)
        },
        render: (adminCollaborator) => {
          const budget = budgets.find((iBudget) => iBudget.userId === adminCollaborator._id)
          if (!budget) return 'N/C'
          return (
            <span>
              {budget.balance || 0}
            </span>
          )
        },
      },
      {
        title: (
          <span
            className='adminTabHeader'
          >
            <FormattedMessage id='campaignTrackingTable.action' defaultMessage='Action' />
          </span>
        ),
        key: 'action',
        render: (collaborator) => (
          <Button
            style={{ fontWeight: 'bold', padding: '0' }}
            onClick={() => {
              setVisibleModal(true)
              setSelectedCollaborator(collaborator)
            }}
            type='link'
          >
            <FormattedMessage
              id='campaignAdmin.action'
              defaultMessage='Ajouter links'
            />
          </Button>
        ),
      },
    ]

    return (
      <>
        <Modal
          id='budget'
          className='budget-modal'
          destroyOnClose
          visible={visibleModal}
          closable={false}
          width='512px'
          confirmLoading={false}
          onCancel={() => {
            setVisibleModal(false)
            setCredit(1)
          }}
          footer={null}
        >
          <BudgetAddCreditModal
            visibleModal={visibleModal}
            setVisibleModal={setVisibleModal}
            collaborator={selectedCollaborator}
            allCampaigns={campaigns}
            budgets={budgets}
            addCredit={addCredit}
            credit={credit}
            setCredit={setCredit}
            onAbort={() => {
              setVisibleModal(false)
              setCredit(1)
            }}
          />
        </Modal>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <div style={{ display: 'flex', gap: '16px' }}>
            <DataBlock
              title={(
                <FormattedMessage
                  id='campaignTracking.cards.admin'
                  defaultMessage='Administrateurs'
                />
              )}
              value={collaborators.length}
              icon={faUsers}
            />
            <DataBlock
              title={(
                <FormattedMessage
                  id='campaignTracking.cards.creditSpent'
                  defaultMessage='Links dépensés'
                />
              )}
              value={allCreditSpent}
              icon={faCoins}
            />
            <DataBlock
              title={(
                <FormattedMessage
                  id='campaignTracking.cards.committedCredit'
                  defaultMessage='Links engagés'
                />
              )}
              value={allCommittedCredit}
              icon={faClockFour}
            />
            <DataBlock
              title={(
                <FormattedMessage
                  id='campaignCollaboratorsTable.balance'
                  defaultMessage='Links restants'
                />
              )}
              value={allRemainingCredit}
              icon={faWallet}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              className='searchAdminInput'
              prefix={(
                <FontAwesomeIcon
                  style={{
                    color: character.disabledPlaceholder,
                    width: '16px',
                    height: '16px',
                    marginRight: '12px',
                  }}
                  icon={faMagnifyingGlass}
                />
              )}
              style={{
                flex: '1',
                border: `1px solid ${neutralColor[3]}`,
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                borderRadius: '8px',
              }}
              placeholder={intl.formatMessage({
                id: 'campaignTracking.adminSearch',
                defaultMessage: 'Rechercher un administrateur',
              })}
              onChange={(e) => {
                const value = e.target.value.toLowerCase()

                const newCollaborators = collaborators.filter((collaborator) => collaborator?.additionalInfos?.firstName?.toLowerCase()
                  .includes(value)
                || collaborator?.additionalInfos?.lastName?.toLowerCase().includes(value))
                setCollaborators(newCollaborators)
              }}
            />
          </div>
          {collaboratorsFiltered.length ? (
            <Table
              className='adminTable'
              columns={columns}
              dataSource={collaboratorsFiltered}
              style={{
                borderRadius: '16px',
                boxShadow: '0px 0px 24px rgba(16, 24, 40, 0.03)',
                border: `1px solid ${neutralColor[2]}`,
                overflow: 'hidden',
              }}
              pagination={{
                defaultPageSize: 12,
                defaultCurrent: 1,
                pageSizeOptions: ['12', '24', '48'],
                showSizeChanger: true,
              }}
            />
          ) : (
            <div>
              Aucun résultat ne correspond à votre recherche
            </div>
          )}
        </div>
      </>
    )
  } catch (e) {
    console.log('CampaignAdmin error:', e)
    return false
  }
}

export default injectIntl(CampaignAdmin)

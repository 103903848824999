const getUserCreationMailTemplate = (user, shop) => {
  try {
    const { settings: { name } } = shop
    const { _id, language = 'fr' } = user

    const id = language === 'fr'
      ? 'Creation User Entity FR'
      : 'Creation User Entity EN'
    const subject = language === 'fr'
      ? `${name} - Invitation à rejoindre l'interface de Panopli !`
      : `${name} - Invitation to join the Panopli interface!`
    const dynamicContent = [{
      name: 'CREATION_LINK',
      content: `https://app.panopli.co/signup/${String(_id)}`,
    }, {
      name: 'SHOP_NAME',
      content: name,
    }, {
      name: 'MAIN_COLOR',
      content: '#012169',
    }, {
      name: 'BACKGROUND_COLOR',
      content: 'white',
    }]
    const template = {
      id,
      subject,
      dynamicContent,
    }
    return template
  } catch (e) {
    console.log('helpers/getUserCreationMailTemplate error:', e)
    return false
  }
}

export default getUserCreationMailTemplate

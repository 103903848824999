import React, { useState, cloneElement } from 'react'
import theme from '../../../config/theme'
import { MobileDesktopChoice } from '../..'

const PreviewContainer = (props) => {
  const {
    children, // Required
  } = props

  // if !isMobileFocused means that it is desktop focused
  const [isMobileFocused, setIsMobileFocused] = useState(true)

  const clonedPreview = cloneElement(children, { isMobileFocused })

  return (
    <div
      style={{
        width: '50%',
        backgroundColor: theme.white,
        borderRadius: '16px',
        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.03)',
        padding: '24px 24px 24px 24px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <h5 style={{ fontSize: '16px', fontWeight: '600' }}>
          Preview
        </h5>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <span style={{ display: 'block', marginRight: '16px' }}>Affichage</span>
          <MobileDesktopChoice
            isMobileFocused={isMobileFocused}
            setIsMobileFocused={setIsMobileFocused}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          paddingBottom: '32px',
        }}
      >
        {clonedPreview}
      </div>
    </div>
  )
}

export default PreviewContainer

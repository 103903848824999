import React from 'react'
import {
  Form,
  Drawer,
} from 'antd'
import './index.css'
import ProductsForm from './ProductsForm'

import InfoForm from './InfoForm'

import { trimDiscountForComponent } from '../../../helpers/trim'

const MainForm = Form.create({
  name: 'global_state',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields)
  },
  mapPropsToFields(props) {
    return {
      name: Form.createFormField({
        ...props.name,
        value: props.name.value,
      }),
      type: Form.createFormField({
        ...props.type,
        value: props.type.value,
      }),
      code: Form.createFormField({
        ...props.code,
        value: props.code.value,
      }),
      startsAt: Form.createFormField({
        ...props.startsAt,
        value: props.startsAt.value,
      }),
      stopsAt: Form.createFormField({
        ...props.stopsAt,
        value: props.stopsAt ? props.stopsAt.value : undefined,
      }),
      benefits: Form.createFormField({
        ...props.benefits,
        value: props.benefits ? props.benefits.value : {},
      }),
      amount: Form.createFormField({
        ...props.amount,
        value: props.amount ? parseFloat(props.amount.value) : undefined,
      }),
      maxAmount: Form.createFormField({
        ...props.maxAmount,
        value: props.maxAmount ? parseFloat(props.maxAmount.value) : undefined,
      }),
      limitUses: Form.createFormField({
        ...props.limitUses,
        value: props.limitUses ? parseInt(props.limitUses.value, 10) : undefined,
      }),
    }
  },
  onValuesChange(_, values) {
    // console.log('values changes', { _, values })
    if (values.type) {
      _.form.resetFields(['benefits', 'benefits_value'])
    }
  },
})(InfoForm)

export default class DiscountCreation extends React.Component {
  constructor(props) {
    super(props)
    // const state = props.stateLoader.loadState()
    const trimedDiscount = trimDiscountForComponent(props.discount)
    const { selected } = trimedDiscount
    const initialFieldsState = {
      name: {
        value: '',
      },
      type: {
        value: undefined,
      },
      code: {
        value: Math.random().toString(36).substring(2, 10).toUpperCase(),
      },
      test: {
        value: '',
      },
      startsAt: {
        value: null,
        stoptsAt: null,
      },
      benefits: {
        value: undefined,
      },
    }
    this.state = {
      step: 0,
      selected: selected || [],
      fields: props.edition ? { ...initialFieldsState, ...trimedDiscount } : initialFieldsState,
    }
  }

  handleFormChange = (changedFields) => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields },
    }))
  }

  select = (id) => {
    this.setState((state) => {
      const { selected } = state
      const isPresent = selected.includes(id)
      if (isPresent) {
        return {
          selected: selected.filter((item) => item !== id),
        }
      }
      return {
        selected: selected.concat([id]),
      }
    })
  }

  setRestrictions = (limit) => {
    this.setState((state) => {
      const { limits = {} } = state
      limits[limit.restriction] = limit.value

      return {
        limits,
      }
    }, () => console.log('callback ', this.state))
  }

  openDrawer = () => {
    this.setState(() => ({ visible: true }))
  }

  resetProducts = () => {
    this.setState(() => ({ selected: [] }))
  }

  closeDrawer = () => {
    this.setState(() => ({ visible: false }))
  }

  randomCode = () => {
    try {
      const randomCode = Math.random().toString(36).substring(2, 10).toUpperCase()
      this.setState((state) => {
        const { fields } = state
        const { code } = fields
        return {
          fields: {
            ...fields,
            code: {
              ...code,
              value: randomCode,
            },
          },
        }
      })
    } catch (err) {
      console.log('randomCode', err)
    }
  }

  render() {
    const {
      fields, limits, visible, selected,
    } = this.state
    const {
      products,
      collections,
      categories,
      subCategories,
      create,
      closeDrawer,
      closeDiscountDrawer,
      allDiscounts,
      discount,
      edition,
      modify,
      locale,
    } = this.props
    const trimedDiscount = trimDiscountForComponent(discount)
    const { maxAmount, limitUses, amount } = trimedDiscount || {}
    const hasLimits = { maxAmount, limitUses, amount }
    return (
      <>
        <MainForm
          {...fields}
          onChange={this.handleFormChange}
          setStep={this.setStep}
          randomCode={this.randomCode}
          limits={limits}
          setRestrictions={this.setRestrictions}
          openDrawer={this.openDrawer}
          selected={selected}
          create={create}
          resetProducts={this.resetProducts}
          closeDrawer={closeDrawer}
          closeDiscountDrawer={closeDiscountDrawer}
          hasLimits={hasLimits}
          edition={edition}
          modify={modify}
          discount={discount}
          allDiscounts={allDiscounts}
          locale={locale}
        />
        <Drawer visible={visible} width='70%' closable onClose={() => this.setState({ visible: false })}>
          <ProductsForm
            products={products}
            collections={collections}
            categories={categories}
            subCategories={subCategories}
            selected={selected}
            select={this.select}
            closeDrawer={this.closeDrawer}
          />
        </Drawer>
      </>

    )
  }
}

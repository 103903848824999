import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock, faMessage, faStar, faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import {
  Avatar, Button, Divider, Popconfirm, Tag, Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'

import {
  neutralColor, character, secondaryColor, white, primaryColor,
} from '../../config/theme'
import { availableCampaignStatus } from '../campaignStatus'
// eslint-disable-next-line import/no-cycle
import { getStatusTag, isPlannedMagicLink } from '../magicLinks'

const getFormattedDate = (locale) => ({
  title: (
    <div style={{ position: 'relative' }}>
      <Divider
        type='vertical'
        style={{
          position: 'absolute', height: '22px', left: '-26px', top: 'calc(50% - 11px)', border: `1px solid ${neutralColor[2]}`,
        }}
      />
      <span style={{
        fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: character.secondary,
      }}
      >
        <FormattedMessage id='campaignTrackingTable.sentOn' defaultMessage='Envoyé le' />
      </span>
    </div>
  ),
  dataIndex: 'formatted.date',
  sorter: (a, b) => dayjs(a.sentAt) - (dayjs(b.sentAt)),
  sortDirections: ['descend', 'ascend'],
  render: (sentAt) => (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      minWidth: '134px',
      color: character.secondary,
    }}
    >
      {sentAt}
      {isPlannedMagicLink(sentAt, locale) ? (
        <Tag
          style={{
            background: secondaryColor[3],
            color: white,
            borderRadius: '1000px',
            padding: '1px 8px',
            fontWeight: 500,
            fontSize: '12px',
            border: 0,
            marginLeft: '10px',
          }}
        >
          <FontAwesomeIcon size='sm' icon={faClock} style={{ marginRight: '8px' }} />
          <FormattedMessage id='campaignTrackingTable.planned' defaultMessage='Planifié' />
        </Tag>
      ) : null}
    </div>
  ),
})

const getFormattedExpireAt = () => ({
  title: (
    <div style={{ position: 'relative' }}>
      <Divider
        type='vertical'
        style={{
          position: 'absolute', height: '22px', left: '-26px', top: 'calc(50% - 11px)', border: `1px solid ${neutralColor[2]}`,
        }}
      />
      <span style={{
        fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: character.secondary,
      }}
      >
        <FormattedMessage id='campaignTrackingTable.expireAt' defaultMessage='Expire le' />
      </span>
    </div>
  ),
  dataIndex: 'formatted.expireAt',
  sorter: (firstMagicLink, secondMagicLink) => dayjs(firstMagicLink.sentAt)
    .add(firstMagicLink.lifetime, 'day')
    .diff(dayjs(secondMagicLink.sentAt).add(secondMagicLink.lifetime, 'day')),
  sortDirections: ['descend', 'ascend'],
  render: (expireAt) => (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      minWidth: '134px',
      color: character.secondary,
    }}
    >
      {expireAt}
    </div>
  ),
})

const getFormattedCampaign = () => ({
  title: (
    <div style={{ position: 'relative' }}>
      <Divider
        type='vertical'
        style={{
          position: 'absolute', height: '22px', left: '-26px', top: 'calc(50% - 11px)', border: `1px solid ${neutralColor[2]}`,
        }}
      />
      <span style={{
        fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: character.secondary,
      }}
      >
        <FormattedMessage id='campaignTrackingTable.campaign' defaultMessage='Campagne' />
      </span>
    </div>
  ),
  dataIndex: 'formatted.campaign',
  onCell: () => ({
    style: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '150px',
    },
  }),
  render: (campaign) => (
    <span style={{
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: character.title,
    }}
    >
      {campaign}
    </span>
  ),
})

const getEmail = (title) => ({
  title: (
    <div style={{ position: 'relative' }}>
      <Divider
        type='vertical'
        style={{
          position: 'absolute', height: '22px', left: '-26px', top: 'calc(50% - 11px)', border: `1px solid ${neutralColor[2]}`,
        }}
      />

      <span style={{
        fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: character.secondary,
      }}
      >
        {title || (<FormattedMessage id='campaignTrackingTable.recipient' defaultMessage='Destinataire' />)}
      </span>
    </div>
  ),
  dataIndex: 'email',
  onCell: () => ({
    style: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '150px',
    },
  }),
  render: (mail) => (
    <span style={{
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: character.title,
    }}
    >
      {mail}
    </span>
  ),
})

const getFormattedAdmin = () => ({
  title: (
    <div style={{ position: 'relative' }}>
      <Divider
        type='vertical'
        style={{
          position: 'absolute', height: '22px', left: '-26px', top: 'calc(50% - 11px)', border: `1px solid ${neutralColor[2]}`,
        }}
      />
      <span style={{
        fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: character.secondary,
      }}
      >
        <FormattedMessage id='campaignTrackingTable.admin' defaultMessage='Expéditeur' />
      </span>
    </div>
  ),
  dataIndex: 'formatted.admin',
  render: (sender) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Avatar icon='user' style={{ flexShrink: 0 }} />
      <span style={{
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: character.title,
      }}
      >
        {sender}
      </span>
    </div>
  ),
})

const getFirstName = (title) => ({
  title: (
    <div style={{ position: 'relative' }}>
      <Divider
        type='vertical'
        style={{
          position: 'absolute', height: '22px', left: '-26px', top: 'calc(50% - 11px)', border: `1px solid ${neutralColor[2]}`,
        }}
      />

      <span style={{
        fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: character.secondary,
      }}
      >
        {title || <FormattedMessage id='firstName' defaultMessage='Prénom' />}
      </span>
    </div>
  ),
  dataIndex: 'firstName',
  onCell: () => ({
    style: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '150px',
    },
  }),
  render: (firstName) => (
    <span style={{
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: character.title,
    }}
    >
      {firstName}
    </span>
  ),
})

const getFormattedStatus = () => ({
  title: (
    <div style={{ position: 'relative' }}>
      <Divider
        type='vertical'
        style={{
          position: 'absolute', height: '22px', left: '-26px', top: 'calc(50% - 11px)', border: `1px solid ${neutralColor[2]}`,
        }}
      />

      <span style={{
        fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: character.secondary,
      }}
      >
        <FormattedMessage id='status' defaultMessage='Statut' />
      </span>
    </div>
  ),
  dataIndex: 'formatted.status',
  key: 'status',
  render: (status) => getStatusTag(status),
})

const getFormattedNPS = () => ({
  title: (
    <div style={{ position: 'relative' }}>
      <Divider
        type='vertical'
        style={{
          position: 'absolute', height: '22px', left: '-26px', top: 'calc(50% - 11px)', border: `1px solid ${neutralColor[2]}`,
        }}
      />
      <span style={{
        fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: character.secondary,
      }}
      >
        NPS
      </span>
    </div>
  ),
  dataIndex: 'formatted.nps',
  key: 'NPS',
  sorter: (a, b) => {
    const scoreA = a.formatted.nps.score ?? 0
    const scoreB = b.formatted.nps.score ?? 0
    return scoreA - scoreB
  },
  sortDirections: ['descend', 'ascend'],
  render: (nps = { score: null, comment: null }) => {
    const { score, comment } = nps
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: character.title,
      }}
      >
        {score
          ? (
            <>
              <span>{score}</span>
              <FontAwesomeIcon size='sm' icon={faStar} style={{ marginLeft: '4px' }} />
            </>
          )
          : 'N/C'}

        {comment && (
          <Tooltip title={comment} placement='bottom'>
            <FontAwesomeIcon size='sm' icon={faMessage} style={{ marginLeft: '4px' }} />
          </Tooltip>
        ) }
      </div>
    )
  },
})

const getAction = (callback, locale, campaigns) => ({
  title: (
    <div style={{ position: 'relative' }}>
      <Divider
        type='vertical'
        style={{
          position: 'absolute', height: '22px', left: '-26px', top: 'calc(50% - 11px)', border: `1px solid ${neutralColor[2]}`,
        }}
      />
      <span style={{
        fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: character.secondary,
      }}
      >
        <FormattedMessage id='campaignTrackingTable.action' defaultMessage='Action' />
      </span>
    </div>
  ),
  key: 'action',
  render: (record) => {
    const { formatted = {} } = record
    const { status = availableCampaignStatus?.archived, sentAt = dayjs() } = formatted
    const spent = status === 'spent'
    const planned = isPlannedMagicLink(sentAt, locale)
    const recordCampaign = campaigns?.find((campaign) => String(campaign?._id) === record?.campaignId || String(campaign?.id) === record?.campaignId)
    const isResendDisabled = (
      (recordCampaign?.status || 'active') ?? availableCampaignStatus?.archived) === availableCampaignStatus?.archived || spent

    // TODO à utiliser quand on aura les actions
    // <Button
    //     type='link'
    //     style={{
    //       padding: 0,
    //       fontWeight: 500,
    //       fontSize: '14px',
    //       lineHeight: '22px',
    //       color: primaryColor[6],
    //     }}
    //
    //     onClick={() => null}
    //   >
    //     <FormattedMessage id='campaignTrackingTable.statusType.actionsResult' defaultMessage='Résultat des actions' />
    //   </Button>
    return (
      <Button
        type='link'
        style={{
          padding: 0,
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '22px',
          opacity: planned ? '50%' : false,
          color: isResendDisabled ? character.disabledPlaceholder : primaryColor[6],
        }}
        // If the campaign doesn't have a status, we placehold a default status.
        // Every campaign should have a status, if not, we use archived status to disable button.
        disabled={planned || isResendDisabled}
        onClick={() => callback(record)}
      >
        <FormattedMessage id='campaignTrackingTable.statusType.resend' defaultMessage='Relancer' />
      </Button>
    )
  },
})

const getSendingActions = ({ customMessageAllowed, customizeMessage, deleteLink }) => ({
  title: (
    <div style={{ position: 'relative' }}>
      <Divider
        type='vertical'
        style={{
          position: 'absolute', height: '22px', left: '-26px', top: 'calc(50% - 11px)', border: `1px solid ${neutralColor[2]}`,
        }}
      />
      <span style={{
        fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: character.secondary,
      }}
      >
        <FormattedMessage id='actions' defaultMessage='Actions' />
      </span>
    </div>
  ),
  key: 'actions',
  render: (record) => (
    <span style={{
      display: 'flex',
      alignItems: 'center',
      gap: '26px',
    }}
    >
      {customMessageAllowed && (
        <Tooltip
          placement='bottomRight'
          arrowPointAtCenter
          title={<FormattedMessage id='magiclinks.sending.actions.customMessage' defaultMessage='Personnaliser le message pour ce destinataire' />}
        >
          <FontAwesomeIcon
            icon={faMessage}
            onClick={() => customizeMessage(record.email)}
            color={record.message ? character.valid : primaryColor[6]}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      )}
      <Popconfirm
        title={(
          <span style={{ color: character.primary, fontSize: '14px' }}>
            <FormattedMessage id='magiclinks.sending.actions.deleteLink' defaultMessage='Retirer le destinataire de la liste ?' />
          </span>
        )}
        placement='bottomRight'
        arrowPointAtCenter
        okText={(<FormattedMessage id='delete' defaultMessage='Supprimer' />)}
        onConfirm={() => deleteLink(record)}
        okButtonProps={{
          style: {
            height: '32px',
            background: character.danger,
            borderColor: character.danger,
            color: character.primaryInverse,
            fontWeight: 500,
          },
        }}
        cancelButtonProps={{
          style: {
            height: '32px',
            fontWeight: 500,
          },
        }}
      >
        <FontAwesomeIcon
          icon={faTrashCan}
          color={character.secondary}
          style={{ cursor: 'pointer' }}
        />
      </Popconfirm>
    </span>
  ),
})

export {
  getFormattedDate,
  getFormattedExpireAt,
  getFormattedCampaign,
  getFormattedAdmin,
  getFirstName,
  getFormattedStatus,
  getFormattedNPS,
  getEmail,
  getAction,
  getSendingActions,
}

import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import {
  Button, Drawer,
} from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { InnerLayout } from '../../layouts'
import {
  Discounts, DiscountCreationContainer, DeleteDiscounts,
} from '../../containers'
import { Refresher } from '../../components/molecules'
import { extractParams, writeUrl } from '../../helpers/urlHelpers'
import { INIT_FETCH_DATA } from '../../store/discounts/actions'

const initialState = {
  selectedData: [],
  visible: false,
  singleDiscount: null,
  drawerMode: 'creation',
  searchText: '',
  searchedColumn: '',
}

const DiscountsPage = (props) => {
  const {
    history, shopId, location: { search }, fetchData,
  } = props

  const query = extractParams(search)
  const composedFilters = { ...query }

  const [filters, setFilters] = useState(composedFilters)
  // on rajoute les filtres dans l'url quand ils sont modifiés
  useEffect(() => {
    history.push(writeUrl(filters))
  }, [filters])

  useEffect(() => {
    fetchData()
  }, [shopId])

  const [discountsPageState, setDiscountsPageState] = useState(initialState)

  const hasFilters = () => {
    const { searchText } = discountsPageState
    return !!(Object.values(filters).filter(Boolean).length || searchText)
  }

  const setSearchText = ({ searchText, searchedColumn }) => setDiscountsPageState(() => ({
    ...discountsPageState,
    searchText,
    searchedColumn,
  }))

  const setSingleDiscount = (singleDiscount) => setDiscountsPageState({
    ...discountsPageState,
    singleDiscount,
  })

  const setDrawerMode = (drawerMode) => setDiscountsPageState({
    ...discountsPageState,
    drawerMode,
  })

  const onSelectChange = (selectedData) => {
    setDiscountsPageState({
      ...discountsPageState,
      selectedData,
    })
  }

  const setFiltering = (object) => {
    setFilters({
      ...filters,
      ...object,
    })
  }

  const resetFiltering = () => {
    setFilters({})
  }
  const closeDiscountDrawer = () => setDiscountsPageState(() => ({
    ...discountsPageState,
    visible: false,
  }))

  const openDiscountDrawer = () => setDiscountsPageState(() => ({
    ...discountsPageState,
    visible: true,
  }))

  const assignClearFilters = (clearFilters) => {
    setDiscountsPageState({ clearFilters })
  }

  const actions = () => {
    const { selectedData = [] } = discountsPageState
    const ids = selectedData.map((discount) => String(discount._id))
    let defaultActions = [
      <Button
        key='1'
        type='primary'
        onClick={() => setDiscountsPageState({
          ...discountsPageState,
          visible: true,
          drawerMode: 'creation',
        })}
      >
        <FormattedMessage id='discounts.addAction' defaultMessage='Ajouter' />
      </Button>,
      <DeleteDiscounts selected={ids} />,
    ]
    if (hasFilters()) {
      defaultActions = [
        <Button key='reset' onClick={resetFiltering}>
          <FormattedMessage id='resetFilters' defaultMessage='Réinitialiser les filtres' />
        </Button>,
      ].concat(defaultActions)
    }
    return defaultActions
  }
  const {
    drawerMode, visible, selectedData, searchText, searchedColumn,
  } = discountsPageState
  const edition = drawerMode === 'edition'
  return (
    <InnerLayout
      title={(
        <div style={{
          fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
        }}
        >
          <FormattedMessage id='discounts' defaultMessage='Promotions' />
        </div>
      )}
      extra={actions()}
    >
      <Drawer
        title={(
          <FormattedMessage
            id='discounts.title'
            defaultMessage='{type} de votre Promotion'
            values={{ type: edition ? 'Edition' : 'Création' }}
          />
        )}
        placement='right'
        closable
        visible={visible}
        onClose={closeDiscountDrawer}
        width='50%'
        edition={edition}
        destroyOnClose
      >
        <DiscountCreationContainer
          closeDiscountDrawer={closeDiscountDrawer}
          discount={edition ? singleDiscount : null}
          edition={edition}
        />
      </Drawer>
      <div className='Discounts page'>
        <Refresher fetchData={fetchData} />
        <Discounts
          filters={filters}
          filtering={setFiltering}
          selected={selectedData}
          onSelectChange={onSelectChange}
          setSingleDiscount={setSingleDiscount}
          openDrawer={openDiscountDrawer}
          setDrawerMode={setDrawerMode}
          searchText={searchText}
          searchedColumn={searchedColumn}
          setSearchText={setSearchText}
          assignClearFilters={assignClearFilters}
          resetFiltering={resetFiltering}
        />
      </div>
    </InnerLayout>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(DiscountsPage))

import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import {
  Modal, Button, Empty,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import LineItem from './molecules/LineItem'
import HubspotDealValidator from './organisms/HubspotDealValidator'
import { fromBaseProductsCart, fromProviders } from '../../store/selectors'
import { setVisibleState, emptyCart } from '../../store/baseProductsCart/actions'
import CartRecap from './molecules/CartRecap'
import './index.css'
import { getTotalDeliveryPrice } from '../../helpers/catalog-hubspot'

const { getBpCartContent, isVisible } = fromBaseProductsCart

const displayLineItems = (content) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
    {content.map((lineItem) => <LineItem lineItem={lineItem} />)}
  </div>
)

const ModalFooter = (props) => {
  const dispatch = useDispatch()
  const {
    cartContent, openHubspotVerify, totalPrice, totalQuantity, providersDeliveryRecap,
  } = props
  return (
    <div style={{ textAlign: 'center', marginTop: '16px' }}>
      <CartRecap
        totalQuantity={totalQuantity}
        nbOfProducts={cartContent.length}
        totalPrice={totalPrice}
        providersDeliveryRecap={providersDeliveryRecap}
      />
      <div style={{
        display: 'flex', justifyContent: 'center', padding: '9px 16px', gap: '10px',
      }}
      >
        <Button
          style={{ width: '100%', fontSize: '14px' }}
          type='primary'
          onClick={() => {
            dispatch(setVisibleState(false))
            openHubspotVerify(true)
          }}
        >
          <FormattedMessage id='bp-cart-send-products' defaultMessage='Envoyer mes produits' />
        </Button>
      </div>
    </div>
  )
}

const ModalHeader = (props) => {
  const dispatch = useDispatch()
  const { isEmpty } = props

  return (
    <div style={{
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '14px',
    }}
    >
      {!isEmpty ? (
        <Button
          type='button'
          style={{
            fontSize: '14px', fontWeight: 500, marginRight: '10px', cursor: 'pointer', border: 'none',
          }}
          onClick={() => dispatch(emptyCart())}
        >
          <FormattedMessage id='bp-cart.empty-cart' defaultMessage='Tout supprimer' />
        </Button>
      ) : null}
      <button
        type='button'
        style={{
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          width: '32px',
          height: '32px',
          borderRadius: '8px',
          border: '1px solid #E0E5EA',
          backgroundColor: '#FFFFFF',
          cursor: 'pointer',
        }}
        className='hoverable'
        onClick={() => dispatch(setVisibleState(false))}
      >
        {' '}
        X
      </button>
    </div>
  )
}
//

// pour chaque produit de mon panier
// je détermine son fournisseur et je le regroupe par fournisseur
// pour chaque fournisseur , je calcule la quantité de marchandise et je détermine son forfait
// je ratoute une ligne forfait de livraison sur le devis Hubspot avec la somme des forfaits de livraison

const BaseProductsCartContainer = () => {
  const dispatch = useDispatch()
  const [isHubspotVerifyOpen, setIsHubspotVerifyOpen] = useState(false)
  const cartContent = useSelector(getBpCartContent)
  const isDisplayed = useSelector(isVisible)
  const providers = useSelector(fromProviders.getProviders)
  const totalQuantity = cartContent.reduce((acc, currentProduct) => acc + currentProduct.qty, 0)
  const totalPrice = cartContent.reduce((acc, currentProduct) => acc + (currentProduct.unitPrice * currentProduct.qty), 0)
  const providersDeliveryRecap = getTotalDeliveryPrice(cartContent, providers)
  const isEmpty = !(cartContent.length)
  return (
    <>
      <Modal
        centered={false}
        closable={false}
        style={{ zIndex: 100000, left: '25%' }}
        mask={false}
        title={(
          <span style={{ fontSize: 'large', fontWeight: 'bold' }}>
            <FormattedMessage id='bp-cart.cart' defaultMessage='Panier' />
          </span>
        )}
        visible={isDisplayed}
        maskClosable
        width='656px'
        onCancel={() => dispatch(setVisibleState(false))}
        footer={null}
        onOk={() => console.log('ok clicked')}
      >
        <div>
          <ModalHeader isEmpty={isEmpty} />
          {!isEmpty ? displayLineItems(cartContent)
            : (
              <Empty
                description={(
                  <FormattedMessage
                    id='bp-cart.emptyCart'
                    defaultMessage='<p>Votre panier est vide ! {br} Ajoutez des produits à votre panier pour commencer</p>'
                    values={{
                      p: (...chunks) => <p>{chunks}</p>,
                      br: <br />,
                    }}
                  />
                )}
                style={{
                  height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                }}
                image='5_PERSONNAGE_PLATEFORME-17.png'
              />
            )}
          {!isEmpty ? (
            <ModalFooter
              cartContent={cartContent}
              openHubspotVerify={setIsHubspotVerifyOpen}
              totalQuantity={totalQuantity}
              totalPrice={totalPrice}
              providersDeliveryRecap={providersDeliveryRecap}
            />
          ) : null}
        </div>

      </Modal>
      { /*  Another Modal in the HubspotDealValidator */}
      <HubspotDealValidator
        isValidatorVisible={isHubspotVerifyOpen}
        totalPrice={totalPrice}
        totalQuantity={totalQuantity}
        closeModal={() => setIsHubspotVerifyOpen(false)}
        providersDeliveryRecap={providersDeliveryRecap}
      />
    </>
  )
}

export default withRouter(BaseProductsCartContainer)

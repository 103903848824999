import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Table } from 'antd'

import { neutralColor, white } from '../../../config/theme'
import {
  getEmail,
  getFirstName,
  getSendingActions,
} from '../../../helpers/magicLinkGridColumn'

import {
  FadingTransition,
  MagicLinksSendingManuallyHeader,
  MagicLinksSendingSelectionActions,
} from '../..'

import { STEP_DRAWER_MESSAGE } from '../../../helpers/magicLinks'

const tableContainerStyle = {
  width: '100%',
  background: white,
  boxShadow: '0px 0px 24px 0px rgba(16, 24, 40, 0.03)',
  border: `1px solid ${neutralColor[3]}`,
  borderRadius: '16px',
  overflow: 'hidden',
}

const MagicLinksSendingManually = (props) => {
  try {
    const {
      mails,
      setMails,
      magicLinks,
      currentCampaignId,
      setStep,
      selection,
      setSelection,
      customMessageAllowed,
    } = props

    const [emailInput, setEmailInput] = useState(null)
    const [firstNameInput, setFirstNameInput] = useState(null)

    const handleManualAdd = () => {
      const newMail = { email: emailInput, firstName: firstNameInput }
      setMails([...mails, newMail])
      setEmailInput(null)
      setFirstNameInput(null)
    }

    const handleDelete = (emails) => {
      const newMails = [...mails]
      emails.map((mail) => newMails.splice(mails.indexOf(mail), 1))
      setMails(newMails)
    }

    const handleSelectionDelete = () => {
      handleDelete(selection)
      setSelection([])
    }

    const columns = [
      getEmail(<FormattedMessage id='emailAddress' defaultMessage='Adresse email' />),
      getFirstName(),
      getSendingActions({
        customMessageAllowed,
        customizeMessage: (record) => {
          setSelection([record])
          setStep(STEP_DRAWER_MESSAGE)
        },
        deleteLink: (record) => handleDelete([record]),
      }),
    ]

    const rowSelection = {
      // On définit les lignes sélectionnées directement à partir de celles enregistrées dans le state
      selectedRowKeys: selection,
      onChange: (selectedRowKeys) => setSelection(selectedRowKeys),
    }

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
      }}
      >
        <MagicLinksSendingManuallyHeader
          mails={mails}
          magicLinks={magicLinks}
          currentCampaignId={currentCampaignId}
          emailInput={emailInput}
          setEmailInput={setEmailInput}
          firstNameInput={firstNameInput}
          setFirstNameInput={setFirstNameInput}
          handleEmailAdd={handleManualAdd}
        />
        {!!selection.length && (
          <MagicLinksSendingSelectionActions
            selection={selection}
            setSelection={setSelection}
            handleSelectionDelete={handleSelectionDelete}
            setStep={setStep}
            customMessageAllowed={customMessageAllowed}
          />
        )}
        <FadingTransition>
          {!!mails.length && (
            <div style={tableContainerStyle}>
              <Table
                columns={columns}
                dataSource={mails}
                rowSelection={rowSelection}
                rowKey={(record) => record.email}
                pagination={false}
              />
            </div>
          )}
        </FadingTransition>
      </div>
    )
  } catch (e) {
    console.log('molecules/MagicLinksSendingManually error:', e)
    return null
  }
}

export default MagicLinksSendingManually

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Popover } from 'antd'

import {
  FadingTransition,
  MagicLinksSendingManually,
  MagicLinksSendingMailsHeader,
  MagicLinksSendingByCSV,
  MagicLinksSendingAlert,
  MagicLinksSendingStepsButtons,
} from '../..'

import {
  displayWrongMails,
  STEP_DRAWER_PARAMS,
  STEP_DRAWER_MANUALLY,
  STEP_DRAWER_BYCSV,
} from '../../../helpers/magicLinks'

import { character } from '../../../config/theme'

const MagicLinksSendingMails = (props) => {
  try {
    const {
      magicLinks,
      currentCampaignId,
      subpart,
      setSubpart,
      mails,
      setMails,
      selection,
      setSelection,
      nextDisabled,
      setStep,
      handleSending,
      customMessageAllowed,
    } = props

    const [unvalidCSVEmails, setUnvalidCSVEmails] = useState([])
    const [wrongCSVHeaders, setWrongCSVHeaders] = useState(false)

    const displayErrors = displayWrongMails(unvalidCSVEmails, subpart)

    const displayStep = () => {
      switch (subpart) {
      case STEP_DRAWER_MANUALLY:
        return (
          <MagicLinksSendingManually
            mails={mails}
            setMails={setMails}
            magicLinks={magicLinks}
            currentCampaignId={currentCampaignId}
            customMessageAllowed={customMessageAllowed}
            setStep={setStep}
            selection={selection}
            setSelection={setSelection}
          />
        )
      case STEP_DRAWER_BYCSV:
        return (
          <MagicLinksSendingByCSV
            mails={mails}
            setMails={setMails}
            setUnvalidCSVEmails={setUnvalidCSVEmails}
            setSubpart={setSubpart}
            magicLinks={magicLinks}
            currentCampaignId={currentCampaignId}
            setWrongCSVHeaders={setWrongCSVHeaders}
          />
        )
      default:
        return null
      }
    }

    return (
      <>
        <div style={{
          display: 'flex',
          flexDirection:
        'column',
          gap: '24px',
          height: '100%',
        }}
        >
          <MagicLinksSendingMailsHeader
            mails={mails}
            subpart={subpart}
            setSubpart={setSubpart}
          />
          {wrongCSVHeaders && (
            <MagicLinksSendingAlert onClose={() => setWrongCSVHeaders(false)}>
              <FormattedMessage id='wrongCSVheaders' defaultMessage='Les entêtes de votre fichier CSV diffèrent du modèle.' />
            </MagicLinksSendingAlert>
          )}
          {displayErrors && (
            <MagicLinksSendingAlert onClose={() => setUnvalidCSVEmails([])}>
              <span style={{ color: character.title }}>
                <FormattedMessage
                  id='magicLinks.sending.wrongFormat'
                  defaultMessage="{number, plural,
                        one {Le fichier CSV contient une adresse e-mail invalide. Celle-ci n'a pas été ajoutée à la liste d'envoi.}
                        other {Le fichier CSV contient # adresses e-mail invalides. Celles-ci n'ont pas été ajoutées à la liste d'envoi.}}"
                  values={{ number: unvalidCSVEmails.length }}
                />
              </span>
              <Popover
                trigger='click'
                placement='bottomRight'
                arrowPointAtCenter
                overlayStyle={{ minWidth: '212px' }}
                title={(
                  <span style={{ fontWeight: 500, color: character.title }}>
                    <FormattedMessage
                      id='magicLinks.sending.wrongFormat.details.title'
                      defaultMessage='{number, plural, =0 {# adresse e-mail invalide} one {# adresse e-mail invalide}
                other {# adresses e-mail invalides}}'
                      values={{ number: unvalidCSVEmails.length }}
                    />
                  </span>
                )}
                content={(
                  <div style={{
                    display: 'flex', flexDirection: 'column', fontWeight: 400, color: character.title,
                  }}
                  >
                    {unvalidCSVEmails.map((mail) => <span key={mail.email}>{mail.email}</span>)}
                  </div>
                )}
              >
                <span style={{
                  color: character.danger,
                  cursor: 'pointer',
                  paddingRight: '10px',
                  whiteSpace: 'nowrap',
                }}
                >
                  <FormattedMessage id='seeDetails' defaultMessage='Voir le détail' />
                </span>
              </Popover>
            </MagicLinksSendingAlert>
          )}
          <FadingTransition
            id={subpart}
            initialProps={subpart === STEP_DRAWER_BYCSV && { height: 'calc(100vh - (80px + 225px))' }}
            animateProps={subpart === STEP_DRAWER_BYCSV && { height: 'calc(100vh - (80px + 225px))' }}
          >
            {displayStep()}
          </FadingTransition>
        </div>
        <MagicLinksSendingStepsButtons
          previousTitle={<FormattedMessage id='return' defaultMessage='Retour' />}
          nextTitle={<FormattedMessage id='magicLink.send' defaultMessage='Envoyer des Magic Links' />}
          handlePreviousStep={() => setStep(STEP_DRAWER_PARAMS)}
          handleNextStep={handleSending}
          nextDisabled={nextDisabled}
        />
      </>
    )
  } catch (e) {
    console.log('molecules/MagicLinksSendingMails error:', e)
    return null
  }
}

export default MagicLinksSendingMails

import React from 'react'
import date from 'dayjs'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import {
  Table,
  Divider,
  Button,
  Switch,
  Tag,
  Icon,
  Popconfirm,
  message,
  Select,
} from 'antd'
import { Spin, DiscountStatistics } from '../../components'
import { modifyDiscount, deleteDiscounts } from '../../store/discounts/actions'
import { getColumnSearchProps, filterData } from '../../helpers/filters'
import { trimRecordForDiscountTable } from '../../helpers/trim'
import {
  fromDiscounts, fromOrders, fromEntities, fromUsers, fromLocale,
} from '../../store/selectors'
import Placeholder from '../../components/organisms/Placeholder'
import './index.css'

const { Option } = Select

// TODO
// clear filters
// filter, sort, search

const Details = (props) => {
  try {
    const { discount, user } = props
    const { type, limits, benefits } = discount
    const { dates } = limits
    const { value = '' } = benefits
    const { startsAt, stopsAt } = dates || {}
    const translateName = (_type) => {
      switch (_type) {
      case 'campaign':
        return <FormattedMessage id='campaign' defaultMessage='Campagne' />
      case 'code':
        return <FormattedMessage id='code' defaultMessage='Code' />
      case 'gift':
        return <FormattedMessage id='giftCode' defaultMessage='Code cadeau' />
      case 'shipping':
        return <FormattedMessage id='shippingCost' defaultMessage='Frais de livraison' />
      case 'amount':
      case 'percent':
        return <FormattedMessage id='reduction' defaultMessage='Réduction' />
      default:
        return _type
      }
    }
    const getIcon = (_benefits) => {
      if (_benefits.type === 'percent') {
        return '%'
      }
      if (_benefits.type === 'amount') {
        return '€'
      }
      return null
    }
    const hasBenefits = !!(benefits && Object.keys(benefits).length)
    return (
      <div style={{ display: 'flex' }}>
        <div>
          <p style={{ display: 'flex' }}>
            <span className='bold-span'>
              <FormattedMessage id='discount.discountType' defaultMessage='Type de Promotion :' />
            </span>
            {'\u00a0'}
            {translateName(type) }
          </p>
          <p style={{ display: 'flex' }}>
            <span className='bold-span'>
              <FormattedMessage id='discount.startingDate' defaultMessage='Date de début :' />
            </span>
            <span>
              {'\u00a0'}
              {(startsAt && date(startsAt).format()) || '-' }
            </span>
          </p>
          <p style={{ display: 'flex' }}>
            <span className='bold-span'>
              <FormattedMessage id='discount.endingDate' defaultMessage='Date de fin :' />
            </span>
            <span>
              {'\u00a0'}
              {(stopsAt && date(stopsAt).format()) || '-' }
            </span>
          </p>
          { hasBenefits ? (
            <p style={{ display: 'flex' }}>
              <span className='bold-span'>
                <FormattedMessage id='discount.advantages' defaultMessage='Avantages :' />
              </span>
              <span>
                {'\u00a0'}
                {translateName(benefits.type)}
                {'\u00a0'}
                {value}
                {'\u00a0'}
                {getIcon(benefits)}
              </span>
            </p>
          ) : null }
        </div>
        <DiscountStatistics orders={discount.orders} user={user} />
      </div>
    )
  } catch (e) {
    console.log('Details - error', e)
    return null
  }
}

class DiscountsTable extends React.Component {
  getDiscounts = () => {
    const {
      allDiscounts,
      filters: {
        type,
      },
    } = this.props
    let discounts = allDiscounts.sort((a, b) => (date(b.createdAt).isAfter(a.createdAt) && 1) || -1)
    if (type) {
      discounts = discounts.filter((o) => o.type === type)
    }
    return discounts
  }

  onSelectChange = (_selectedData) => {
    const {
      onSelectChange,
      allDiscounts,
    } = this.props
    const selectedData = allDiscounts.filter((order) => _selectedData.includes(order._id))
    onSelectChange(selectedData)
  }

  copyCode = (e, code) => {
    e.stopPropagation()
    const toCopy = document.createElement('input')
    document.body.appendChild(toCopy)
    toCopy.setAttribute('value', code)
    toCopy.select()
    document.execCommand('copy')
    message.info(<FormattedMessage id='copy' defaultMessage='Copié' />)
    document.body.removeChild(toCopy)
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    const { setSearchText } = this.props
    confirm()
    const params = {
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    }
    setSearchText(params)
  };

  handleReset = (clearFilters) => {
    const { resetSearchText } = this.props
    clearFilters()
    resetSearchText('')
  };

  showError = (source) => {
    const placeholder = (word) => {
      switch (word) {
      case 'deletion':
        return <FormattedMessage id='deletion' defaultMessage='Suppression' />
      case 'edition':
        return <FormattedMessage id='edition' defaultMessage='Edition' />
      case 'creation':
        return <FormattedMessage id='creation' defaultMessage='Création' />
      default:
        return <FormattedMessage id='action' defaultMessage='Action' />
      }
    }
    const content = (
      <FormattedMessage
        id='discount.error'
        defaultMessage='Votre {value} a échouée :( '
        values={{ value: placeholder(source) }}
      />
    )
    message.error(content, 6)
  }

  render() {
    try {
      const {
        loading,
        error,
        filtering,
        _modify,
        selectedData,
        setSingleDiscount,
        openDrawer,
        setDrawerMode,
        allOrders,
        _delete,
        resetFiltering,
        filters,
        allDiscounts,
        entities,
        user,
        locale = 'fr',
      } = this.props
      const discounts = allDiscounts

      // NOTE Border cases
      if (discounts.length === 0 && !loading) return <Placeholder />
      if (error.error) {
        console.log('error', error)
        if (error.error_source) {
          this.showError()
        } else {
          return (<div> Error placeHolder </div>)
        }
      }
      if (loading) {
        return (
          <div>
            <Spin />
          </div>
        )
      }
      const filteredOrders = allOrders.filter((order) => order.discounts)
      const data = discounts.map((d) => {
        const orders = filteredOrders.filter((o) => (
          o && o.discounts && o.discounts.code && o.discounts.code._id === d._id))
        return {
          ...d,
          orders,
        }
      })
      // NOTE Columns
      const columns = [
        {
          title: <FormattedMessage id='date' defaultMessage='Date' />,
          key: 'date',
          defaultSortOrder: 'descend',
          dataIndex: 'formatted.createdAt',
          sorter: (a, b) => date(a.createdAt).isAfter(b.createdAt),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: <FormattedMessage id='entity' defaultMessage='Entité' />,
          key: 'entity',
          dataIndex: 'formatted.entity',
          ...getColumnSearchProps('entity', filtering, {
            filterDropdown: () => (
              <div
                className='orderId-filter-dropdown container'
                style={{
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                <Select
                  onChange={(value) => {
                    filtering({ entityId: value })
                  }}
                  style={{ width: 200 }}
                  placeholder={<FormattedMessage id='entity.select' defaultMessage='Sélectionnez une entité' />}
                  allowClear
                >
                  {entities.map((entity) => (
                    <Option key={entity._id} value={entity._id}>
                      {entity.name}
                    </Option>
                  ))}
                </Select>
              </div>
            ),
          }),
        },
        {
          title: <FormattedMessage id='type' defaultMessage='Type' />,
          key: 'purchase',
          dataIndex: 'formatted.type',
          ...getColumnSearchProps('type', filtering, {
            render: (type) => {
              const getColor = (_type) => {
                switch (_type) {
                case 'code':
                  return '#0000FF'
                case 'campaign':
                  return '#C7C9C7'
                case 'gift':
                  return '#006D5D'
                default:
                  return ''
                }
              }
              const getName = (_type) => {
                switch (_type) {
                case 'code':
                  return <FormattedMessage id='code' defaultMessage='Code' />
                case 'campaign':
                  return <FormattedMessage id='campaign' defaultMessage='Campagne' />
                case 'gift':
                  return <FormattedMessage id='gift' defaultMessage='Cadeau' />
                default:
                  return ''
                }
              }
              return (
                <Tag color={getColor(type)}>
                  {getName(type)}
                </Tag>
              )
            },
            filterDropdown: (
              <div style={{
                textAlign: 'center',
                padding: 8,
              }}
              >
                <Button onClick={() => filtering({ type: 'Campagne' })}>
                  <FormattedMessage id='campaign' defaultMessage='Campagne' />
                </Button>
                <Button style={{ margin: '0 8px' }} onClick={() => filtering({ type: 'Code' })}>
                  <FormattedMessage id='code' defaultMessage='Code' />
                </Button>
                <Button onClick={() => filtering({ type: 'Cadeau' })}>
                  <FormattedMessage id='gift' defaultMessage='Cadeau' />
                </Button>
              </div>
            ),
          }),
        },
        {
          title: <FormattedMessage id='code' defaultMessage='Code' />,
          key: 'code',
          dataIndex: 'code',
          ...getColumnSearchProps('code', filtering, {
            render: (code) => (
              <div style={{ cursor: 'pointer' }} onClick={(e) => this.copyCode(e, code)}>
                {code }
              </div>
            ),
          }),
        },
        {
          title: <FormattedMessage id='name' defaultMessage='Nom' />,
          key: 'name',
          dataIndex: 'formatted.name',
          ...getColumnSearchProps('name', filtering),
        },
        {
          title: <FormattedMessage id='status' defaultMessage='Statut' />,
          key: 'status',
          render: (record) => {
            const checked = !!(record.status === 'Active')
            const status = checked ? 'Inactive' : 'Active'
            return (
              <Switch
                checked={checked}
                checkedChildren='Actif'
                unCheckedChildren='Inactif'
                onChange={(e) => console.log('e', e)}
                onClick={() => _modify({ ...record, status })}
                loading={record.being_modified}
              />
            )
          },
          // filterDropdown: (
          //   // this.renderStatusFilters(allStatus)
          // ),
        },
        {
          title: '',
          key: 'delete',
          render: (record) => (
            <>
              <Popconfirm
                title={`Vous allez supprimer la promotion ${record.name}`}
                onConfirm={() => _delete([String(record._id)])}
                // okText='Confirmer'
                // cancelText='Annuler'
                key='delete-single'
              >
                <Icon
                  type='delete'
                  style={{ cursor: 'pointer' }}
                />
              </Popconfirm>
              <Divider type='vertical' />
              <Icon
                type='edit'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setDrawerMode('edition')
                  setSingleDiscount(record)
                  openDrawer()
                }}
              />
            </>
          ),
        },
      ]
      // NOTE Table

      const rowSelection = {
        selectedData,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'all',
            text: 'select all datas',
            onSelect: () => this.onSelectChange(discounts.map((d) => d._id)),
          },
        ],
      }
      const formatted = data.map((d) => trimRecordForDiscountTable(d, entities, locale))
      const filtered = filterData(formatted, filters, {
        entityId: (value, record) => record.entityId && record.entityId === value,
      })

      return (
        <Table
          columns={columns}
          dataSource={filtered}
          rowKey={(record) => record._id}
          rowSelection={rowSelection}
          expandRowByClick
          scroll={{ x: 1000 }}
          locale={{
            filterConfirm: <FormattedMessage id='ok' defaultMessage='Ok' />,
            filterReset: <FormattedMessage id='cancel' defaultMessage='Annuler' />,
            emptyText: (
              <span>
                <Button onClick={resetFiltering}>
                  <FormattedMessage id='discount.noMatch' defaultMessage='Pas de résultats - Réinitialiser les filtres' />
                </Button>
              </span>
            ),
          }}
          expandedRowRender={(record) => {
            try {
              return (
                <Details discount={record} user={user} />
              )
            } catch (e) {
              return null
            }
          }}
        />
      )
    } catch (e) {
      console.log('error containers/Discounts', e)
      return null
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  _modify: (discount) => dispatch(modifyDiscount(discount)),
  _delete: (id) => dispatch(deleteDiscounts(id)),
})

const mapStateToProps = (state) => ({
  allDiscounts: fromDiscounts.getDiscounts(state),
  loading: fromDiscounts.loading(state),
  error: fromDiscounts.error(state),
  allOrders: fromOrders.getOrders(state),
  entities: fromEntities.getEntities(state),
  user: fromUsers.getUser(state),
  locale: fromLocale.getLocale(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscountsTable)

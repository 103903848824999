import React, { useState } from 'react'
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { LangSwitch } from '../../atoms'

const InformationBannerForm = (props) => {
  const [language, setLanguage] = useState('fr')
  const intl = useIntl()
  const getKey = () => (language === 'fr' ? 'text' : language)

  const handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
        resetFields,
      },
      modifyShop,
      shop,
    } = props
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const modifications = {
          settings: {
            ...shop.settings,
            messageBanner: {
              ...shop.settings.messageBanner,
              [getKey()]: values[getKey()],
            },
          },
        }
        modifyShop({
          shop_id: shop._id,
          modifications,
          callback: () => {
            resetFields()
            message.info(
              <FormattedMessage
                id='updateSuccess.shop'
                defaultMessage='Boutique mise à jour'
              />,
            )
            return true
          },
        })
      }
    })
  }

  const {
    form: {
      getFieldDecorator,
      isFieldsTouched,
      resetFields,
    },
    shopSettings,
  } = props

  return (
    <Form
      onSubmit={handleSubmit}
    >
      <div
        style={{
          textAlign: 'left',
          padding: '24px',
        }}
      >
        <Row gutter={16}>
          <Col span={21} className='imageHeader'>
            <h2>
              <FormattedMessage
                id='theme.informationBanner'
                defaultMessage='Message déroulant'
              />
            </h2>
            <p>
              <FormattedMessage
                id='theme.informationBannerHelper'
                defaultMessage='Affiche un message personnalisé en haut de votre boutique.'
              />
            </p>
          </Col>
          <Col span={3} className='imageHeader'>
            <LangSwitch
              defaultValue={language}
              style={{ float: 'right' }}
              onChange={(e) => {
                setLanguage(e.target.value)
              }}
            />
          </Col>
        </Row>
        <Form.Item label={(
          <FormattedMessage
            id='message'
            defaultMessage='Message'
          />
        )}
        >
          {getFieldDecorator(getKey(), {
            rules: [
            ],
            initialValue: shopSettings && shopSettings.messageBanner && shopSettings.messageBanner[getKey()],
          })(<Input placeholder={intl.formatMessage({
            id: 'informationBannerForm.placeholder.message',
            defaultMessage: 'Votre message',
          })}
          />)}
        </Form.Item>
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  marginRight: '12px',
                }}
              >
                <FormattedMessage id='save' defaultMessage='Enregistrer' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </div>
    </Form>
  )
}

export default Form.create({ name: 'shopBasicSettings' })(injectIntl(InformationBannerForm))

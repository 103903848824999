import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CampaignSaveButton, CampaignLeaveModal, CampaignCreateModal } from '../..'
import { availableCampaignStatus } from '../../../helpers/campaignStatus'

const CreateCampaignHeader = (props) => {
  const {
    history, // Required
    saveCampaign, // Required
    needCreateCampaign, // Required
    needUpdateCampaign, // Required
    currentStep, // Required
    createdCampaignId, // Required
    campaign, // Required
    saveAndSendOrViewCampaign, // Required
  } = props

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ marginRight: '16px' }}>
          <CampaignLeaveModal
            history={history}
            saveCampaign={saveCampaign}
            needCreateCampaign={needCreateCampaign}
            needUpdateCampaign={needUpdateCampaign}
            campaign={campaign}
          />
        </div>
        <div>
          <h2>
            <FormattedMessage id='campaign.create.title' defaultMessage='Créer une campagne' />
          </h2>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{
          marginRight: '32px', display: 'flex', flexDirection: 'row', alignItems: 'center',
        }}
        >
          {/* TODO : uncomment following lines later */}
          {/* <FontAwesomeIcon icon={faCircleQuestion} />
          <h4 style={{ marginLeft: '4px' }}>
            <FormattedMessage id='campaign.create.tutorial' defaultMessage='Tutoriel' />
          </h4> */}
        </div>
        <div style={{ marginRight: '16px' }}>
          <CampaignSaveButton
            campaign={campaign}
            saveCampaign={saveCampaign}
            needCreateCampaign={needCreateCampaign}
            needUpdateCampaign={needUpdateCampaign}
          />
        </div>
        <div>
          <CampaignCreateModal
            disabled={currentStep !== 5}
            onSendCampaign={() => history.push(`/campaign/edit?id=${createdCampaignId}&sending=true`)}
            onViewCampaign={() => history.push(`/campaign/edit?id=${createdCampaignId}`)}
            onUpdateCampaign={() => {
              const activeCampaign = { ...campaign, status: availableCampaignStatus?.active }
              saveAndSendOrViewCampaign(activeCampaign)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CreateCampaignHeader

import { Switch } from 'antd'
import React from 'react'
import theme from '../../../config/theme'
import { StepInputLabel } from '..'

const FormattedSwitch = (props) => {
  const {
    checked, // Required
    icon, // Required
    title, // Required
    titleStyle,
    subtitle,
    subtitleStyle,
    tooltipText,
    isOptional = false,
    isDisabled = false,
    onChangeFunction = null,
    defaultChecked = checked,
    style,
    iconStyle,
    size,
  } = props

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '11px',
      ...style,
    }}
    >
      <Switch
        onChange={onChangeFunction}
        defaultChecked={defaultChecked}
        checked={checked}
        style={{ ...((!checked || isDisabled) && { backgroundColor: theme.neutralColor[6] }) }}
        disabled={isDisabled}
        size={size}
      />
      <StepInputLabel
        title={title}
        titleStyle={titleStyle}
        subtitle={subtitle}
        subtitleStyle={subtitleStyle}
        tooltipText={tooltipText}
        icon={icon}
        iconStyle={iconStyle}
        isOptional={isOptional}
      />
    </div>
  )
}

export default FormattedSwitch

import React, { useState } from 'react'
import {
  Tabs,
  Select,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { LegalNoticeForm } from '../..'
// import { countries } from '@balibart/shipping'

const { TabPane } = Tabs
const { Option } = Select

let newTabIndex = 0

const options = (intl) => [
  {
    lang: 'fr',
    title: intl.formatMessage({ id: 'french', defaultMessage: 'Français' }),
  },
  {
    lang: 'en',
    title: intl.formatMessage({ id: 'english', defaultMessage: 'Anglais' }),
  },
  // {
  //   lang: 'es',
  //   title: intl.formatMessage({ id: 'spanish', defaultMessage: 'Espagnol' }),
  // },
  // {
  //   lang: 'de',
  //   title: intl.formatMessage({ id: 'german', defaultMessage: 'Allemand' }),
  // },
]

const LegalNoticeTabs = (props) => {
  try {
    const intl = useIntl()

    const {
      shop,
      modifyShop,
      shopSettings,
    } = props

    const { legals = {} } = shopSettings

    const createNewPane = (lang) => {
      newTabIndex += 1
      const _activeKey = `${newTabIndex}`
      return {
        title: options(intl).find((o) => o.lang === lang)?.title,
        content: <LegalNoticeForm shop={shop} modifyShop={modifyShop} legals={legals} lang={lang} />,
        lang: `${lang}`,
        key: _activeKey,
      }
    }

    const initialPanes = Object.keys(legals).map((lang) => createNewPane(lang))

    const [state, setState] = useState({
      activeKey: initialPanes[0]?.key,
      panes: initialPanes,
      selection: undefined,
    })

    const { panes, activeKey, selection } = state

    const getOptions = () => {
      const selected = panes.map((p) => p.lang)
      const result = options(intl).filter((o) => !selected.find((s) => s === o.lang))
      return result
    }

    const onChange = (_activeKey) => {
      setState({ ...state, activeKey: _activeKey, selection: undefined })
    }

    const add = (lang) => {
      const newPanes = panes
      const newPane = createNewPane(lang)
      newPanes.push(newPane)
      setState({ ...state, panes: newPanes, activeKey: newPane.key })
    }

    const remove = (targetKey) => {
      let _activeKey = activeKey
      let lastIndex
      panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1
        }
      })
      const otherPanes = panes.filter((pane) => pane.key !== targetKey)
      if (otherPanes.length && _activeKey === targetKey) {
        if (lastIndex >= 0) {
          _activeKey = otherPanes[lastIndex].key
        } else {
          _activeKey = otherPanes[0].key
        }
      }
      setState({ ...state, panes: otherPanes, activeKey: _activeKey })
    }

    const onEdit = (targetKey, action) => (action === 'add' ? add() : remove(targetKey))

    return (
      <div>
        <Select
          placeholder={<FormattedMessage id='legalNoticeForm.chooseLanguage' defaultMessage='Choisissez une langue...' />}
          onChange={(key) => add(key)}
          style={{ width: 232, marginBottom: '15px' }}
          value={selection}
        >
          {getOptions().map((o) => <Option key={o.lang}>{o.title}</Option>)}
        </Select>
        <Tabs
          hideAdd
          onChange={onChange}
          activeKey={activeKey}
          type='editable-card'
          onEdit={(targetKey, action) => onEdit(targetKey, action)}
        >
          {panes.map((pane) => (
            <TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
              {pane.content}
            </TabPane>
          ))}
        </Tabs>
      </div>
    )
  } catch (e) {
    console.log('Organisms/LegalNoticeForm error:', e)
    return null
  }
}

export default injectIntl(LegalNoticeTabs)

export const initialState = {
  loading: false,
  error: false,
  isVisible: false,
  error_details: null,
  content: [],
}

export const getBpCartContent = (state) => state.content
export const hasError = (state) => state.error

export const isVisible = (state) => state.isVisible

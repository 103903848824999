import React, { useState } from 'react'
import {
  MagicLinkStatusFilter,
  MagicLinkPeriodFilter,
  MagicLinkFilters,
} from '../..'

const MagicLinksCampaignDashboardFilters = (props) => {
  const {
    setFilters,
    filters,
  } = props

  const [openFilter, setOpenFilter] = useState([])
  const { statuses } = filters
  const filterNumber = statuses.length

  return (
    <MagicLinkFilters
      setFilters={setFilters}
      filters={filters}
      setOpenFilter={setOpenFilter}
      openFilter={openFilter}
      filterNumber={filterNumber}
    >
      <MagicLinkStatusFilter
        setFilters={setFilters}
        filters={filters}
      />
      <MagicLinkPeriodFilter
        setFilters={setFilters}
        filters={filters}
      />
    </MagicLinkFilters>
  )
}

export default MagicLinksCampaignDashboardFilters

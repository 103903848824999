// https://github.com/diegohaz/arc/wiki/Selectors
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
export const initialState = {
  uploading: false,
  error: null,
}

export const getUploadState = (state = initialState) => {
  try {
    return state.uploading
  } catch (error) {
    console.log('getUploadState error', { error })
    return {}
  }
}

export const getUploadError = (state = initialState) => {
  try {
    return state.error
  } catch (error) {
    console.log('getUploadState error', { error })
    return {}
  }
}

import * as AWS from 'aws-sdk'
import axios from 'axios'
import config, { AWS_REGION, BUCKET_NAME, IMAGE_PREFIX } from '../../config'

const {
  key,
  secret_key: secretKey,
} = config.aws || {}

AWS.config.update({
  accessKeyId: key,
  secretAccessKey: secretKey,
  region: AWS_REGION,
})

const s3 = new AWS.S3()

const getBase64 = (file) => new Promise((resolve) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
})

const getFileAsString = (file) => new Promise((resolve) => {
  const reader = new FileReader()
  reader.readAsArrayBuffer(file)
  reader.onload = () => {
    const arrayBuffer = reader.result
    const buffer = Buffer.from(arrayBuffer)
    resolve(buffer.toString('base64'))
  }
})

const fetchImage = async (url) => axios
  .get(url, {
    responseType: 'arraybuffer',
  })
  .then((response) => Buffer.from(response.data, 'binary').toString('base64'))

const uploadAction = async (location, file) => {
  try {
    const upload = await s3.putObject({
      Bucket: BUCKET_NAME,
      Body: Buffer.from(file.thumbUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64'),
      Key: `${IMAGE_PREFIX}/${location}`,
      ContentType: file.type ? file.type : 'image/png',
    }, (err) => {
    // Handle any error and exit
      if (err) return { error: true, message: err }
      return true
    })
    return upload
  } catch (e) {
    console.log(e, 'error during upload')
    return null
  }
}

const getBlobFromUrl = async (url) => {
  let blob = null
  try {
    const response = await fetch(url)
    blob = await response.blob()
  } catch (exception) {
    console.log(e, `error: unable to fetch file from url [${url}]`)
  }

  return blob
}

export default getBase64
export {
  uploadAction, fetchImage, getBlobFromUrl, getFileAsString,
}

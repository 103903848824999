import React, { useEffect } from 'react'

import {
  Button, Icon, Upload, Typography,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import Sortable from 'sortablejs'
import { Info } from '../..'

import theme from '../../../config/theme'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const { Paragraph } = Typography

const ProductImagesManager = (props) => {
  try {
    const {
      product,
      modifyProduct,
      size = 140,
      allowedToModify = true,
    } = props

    const { _id, picList = [] } = product

    useEffect(() => {
      if (picList.length > 1) {
        const el = document.getElementById('items')
        const list = Sortable.create(el, {
          animation: 150,
          draggable: '.draggable',
          onEnd: () => {
            const newPicList = list.toArray()
            modifyProduct(_id, { picList: newPicList })
          },
        })
      }
    })

    /**
     * Permet de gérer le comportement de téléchargement d'une image
     * @param {object} image - L'image à télécharger
     */
    const beforeUpload = (image) => {
      // TODO: voir si on peut rajouter un versioning au nom du fichier
      // après le ".png" => .png?v=${Math.random().toString(36).substring(7)}
      // et reprendre le nom du fichier avant le ".png" via file.name
      // (en enlevant l'extension si déjà dans le file.name => split('.').find(Boolean))
      const imageName = `${Math.random().toString(36).substring(7)}.png`
      const newPicList = [...picList]
      newPicList.push(imageName)
      modifyProduct(_id, { picList: newPicList }, [{ object: `picList_${imageName}` }], [image])
    }

    /**
     * Permet de retirer une image de la picList du produit
     * @param {string} imageName - Le nom de l'image
     */
    const removeImage = (imageName) => {
      const newPicList = [...picList].filter((pic) => pic !== imageName)
      modifyProduct(_id, { picList: newPicList })
    }

    const uploadButton = (
      <Button style={{
        height: size,
        width: size,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 12,
      }}
      >
        <div style={{ fontSize: 24, marginBottom: 8 }}>
          <Icon type='plus' />
        </div>
        <div style={{ fontSize: 14, fontWeight: 'bold' }}>
          <FormattedMessage id='upload' defaultMessage='Upload' />
        </div>
      </Button>
    )

    const fileList = picList.length > 0
      ? picList.map((image, i) => ({
        uid: i + 1,
        name: image,
        status: 'done',
        url: `${imageBaseUrl}/Products/${_id}/customProduct/${image}`,
        thumbUrl: `${imageBaseUrl}/Products/${_id}/customProduct/${image}`,
      }))
      : []

    return (
      <>
        <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
          <FormattedMessage id='product.images.title' defaultMessage='Images du produit' />
          <Info
            title={<FormattedMessage id='product.images.title' defaultMessage='Images du produit' />}
            content={(
              <Paragraph style={{ width: '25vw' }}>
                <FormattedMessage
                  id='ProductsImagesManager.images.helper'
                  defaultMessage="Vous pouvez ajouter ou retirer des images à votre produit.
                  Vous pouvez également modifier leur ordre d'apparition (de gauche à droite) en déplaçant vos images dans l'ordre voulu."
                />
              </Paragraph>
            )}
          />

        </div>
        <div id='items' style={{ display: 'flex', flexWrap: 'wrap' }}>
          {picList.map((imageName) => (
            <div
              className={allowedToModify ? 'draggable' : 'notDraggable'}
              data-id={imageName}
              key={imageName}
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 15px 15px 0',
              }}
            >
              <img
                src={`${imageBaseUrl}/Products/${_id}/customProduct/${imageName}`}
                alt='Example'
                style={{
                  height: size, width: size, border: '1px solid lightGrey', borderRadius: 12,
                }}
              />
              {allowedToModify && (
                <Button
                  size='small'
                  shape='circle'
                  icon='delete'
                  style={{ marginTop: 5 }}
                  onClick={() => removeImage(imageName)}
                />
              )}
            </div>
          ))}
          {allowedToModify && (
            <Upload
              fileList={fileList}
              beforeUpload={beforeUpload}
              className='uploadInput'
              showUploadList={false}
            >
              {uploadButton}
            </Upload>
          )}
        </div>
      </>
    )
  } catch (e) {
    console.log('molecules/ProductImagesManager error:', e)
    return null
  }
}

export default ProductImagesManager

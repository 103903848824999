import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Table,
  Icon,
  Tag,
  Typography,
  Checkbox,
} from 'antd'

const { Title } = Typography

const PreviewNewMembers = (props) => {
  const {
    members,
    deleteMember,
    shop: {
      settings: {
        membersGroups,
      },
    },
    entities,
  } = props

  const columns = [
    {
      title: <FormattedMessage id='entity' defaultMessage='Entité' />,
      key: 'entity',
      render: (member) => {
        const { entityId } = member
        return entities.find((e) => e._id === entityId)?.name || 'N/C'
      },
    },
    {
      title: <FormattedMessage id='member' defaultMessage='Membre' />,
      key: 'member',
      render: (member) => {
        const { name, lastName } = member
        return (
          <div>
            <span style={{ marginRight: 5 }}>
              {name}
            </span>
            <span>
              {lastName}
            </span>
          </div>
        )
      },
    },
    {
      title: <FormattedMessage id='email' defaultMessage='Email' />,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: <FormattedMessage id='group' defaultMessage='Groupe' />,
      dataIndex: 'groups',
      key: 'groups',
      render: (groups) => {
        if (!groups || !groups.length) return null
        const _groups = groups.map((groupName) => membersGroups
          .find((_group) => _group.name === groupName) || { name: groupName })
        return (
          <span>
            {_groups.map((group) => (
              <Tag color={group.color || 'grey'} key={group.name}>
                {group.name}
              </Tag>
            ))}
          </span>
        )
      },
    },
    {
      title: <FormattedMessage id='credit' defaultMessage='Crédit' />,
      dataIndex: 'credit',
      key: 'credit',
    },
    {
      title: <FormattedMessage
        id='freeDeliveryCosts'
        defaultMessage='Frais de livraison gratuits'
      />,
      dataIndex: 'freeDelivery',
      key: 'freeDelivery',
      render: (freeDelivery) => <Checkbox checked={freeDelivery} />,
    },
    {
      title: '',
      key: 'action',
      render: (member) => (
        <Icon
          type='user-delete'
          style={{ cursor: 'pointer' }}
          onClick={() => deleteMember(member)}
        />
      ),
    },
  ]

  return (
    <div>
      <Title level={4}>Preview</Title>
      <Table
        dataSource={members}
        columns={columns}
        size='small'
      />
    </div>
  )
}

export default PreviewNewMembers

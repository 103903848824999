/* eslint-disable camelcase */
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  Spin, notification,
} from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { CheckoutForm, CheckoutRecap } from '../../components'
import {
  CHECKOUT,
  COUNTRY,
  EDIT_CUSTOMIZATIONS,
  REMOVE,
} from '../../store/cart/actions'
import {
  CREATE_PAYMENT_METHOD,
} from '../../store/payments/actions'
import {
  fromCart,
  fromEntities, fromLocale,
  fromPayments,
  fromProducts,
  fromShops,
  fromSuppliers,
  fromUsers,
} from '../../store/selectors'

export default withRouter(connect(
  (state) => ({
    cartContent: fromCart.getContent(state),
    allProducts: fromProducts.allProducts(state),
    client: fromCart.getClient(state),
    country: fromCart.getCountry(state),
    cartPrice: fromCart.getPrice(state),
    productsPrice: fromCart.getProductsPricing(state).total,
    deliveryPrice: fromCart.getDeliveryPricing(state).delivery_discounted,
    deliveryError: fromCart.getDeliveryPricing(state).delivery_error,
    loading: fromCart.getLoading(state),
    suppliers: fromSuppliers.getSuppliers(state),
    shop: fromShops.getShop(state),
    allPaymentMethods: fromPayments.getPaymentMethods(state),
    isAdmin: fromUsers.isAdmin(state),
    entities: fromEntities.getEntities(state),
    locale: fromLocale.getLocale(state),
  }),
  (dispatch, { history }) => bindActionCreators({
    remove: (target) => ({
      type: REMOVE,
      payload: {
        target,
      },
    }),
    customizeProduct: (cart_id, clientCustomization) => ({
      type: EDIT_CUSTOMIZATIONS,
      payload: {
        cart_id,
        clientCustomization,
      },
    }),
    changeCartCountry: (country) => ({
      type: COUNTRY,
      payload: {
        country,
      },
    }),
    createPaymentMethod: (payload) => ({
      type: CREATE_PAYMENT_METHOD,
      payload,
    }),
    checkout: (data, stripeError, stripePM, handleCardAction) => ({
      type: CHECKOUT,
      payload: {
        data,
        stripeError,
        stripePM,
        history,
        handleCardAction,
      },
    }),
  }, dispatch),
)(({
  cartContent,
  allProducts,
  client,
  country,
  remove,
  changeCartCountry,
  customizeProduct,
  cartPrice,
  productsPrice,
  shop,
  suppliers,
  checkout,
  deliveryPrice,
  deliveryError,
  loading,
  allPaymentMethods,
  createPaymentMethod,
  isAdmin,
  entities,
  locale = 'fr',
}) => {
  const stripe = useStripe()
  const elements = useElements()
  return (cartContent && cartContent.length ? (
    <div style={{ position: 'relative', display: 'flex' }}>
      {loading ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgb(217, 217, 217, 0.5)',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin size='large' />
        </div>
      ) : null }
      <div style={{ width: '70%' }}>
        <CheckoutForm
          paymentMethods={allPaymentMethods}
          isAdmin={isAdmin}
          country={country}
          changeCartCountry={changeCartCountry}
          entities={entities}
          locale={locale}
          onSubmit={async (data) => {
            if (data.transactionType === 'invoice') {
              // eslint-disable-next-line
              return checkout(data)
            } if (data.transactionType === 'billing') {
              // eslint-disable-next-line
              return checkout(data)
            }
            if (data.transactionType === 'admin') {
              return checkout(data)
            }
            if (data.paymentMethodSelected) {
              return checkout(data, false, data.paymentMethodSelected, stripe.handleCardAction)
            }
            const cardElement = elements.getElement(CardElement)
            const method_result = await stripe.createPaymentMethod({
              type: 'card',
              card: cardElement,
              billing_details: {
                name: data.cardHolder,
                email: data.client.email,
              },
            })
            const { paymentMethod, error } = method_result
            if (paymentMethod) {
              // eslint-disable-next-line
              if (data.savePaymentMethod) {
                const result = await createPaymentMethod({ type: 'card', stripe, paymentMethodId: paymentMethod.id })
                if (result) {
                  notification.open({
                    message: <FormattedMessage id='cardRecorded' defaultMessage='Carte enregistrée !' />,
                    duration: 0,
                  })
                }
              }
              return checkout(data, error, paymentMethod, stripe.handleCardAction)
            }
            console.log('caught', error)
            return false
          }}
          client={client}
          shop={shop}
        />
      </div>
      <div
        style={{
          position: 'sticky',
          top: '0px',
          right: '0px',
          height: '100vh',
          width: '30%',
          borderLeft: '1px solid rgb(235, 237, 240)',
        }}
      >
        <CheckoutRecap
          cartContent={cartContent}
          allProducts={allProducts}
          shop={shop}
          suppliers={suppliers}
          remove={remove}
          cartPrice={cartPrice}
          productsPrice={productsPrice}
          deliveryPrice={deliveryPrice}
          deliveryError={deliveryError}
          customizeProduct={customizeProduct}
          // changeCartCountry={changeCartCountry}
          hideCountrySelector
          location='checkout'
          locale={locale}
        />
      </div>
    </div>
  ) : (
    <Redirect to='/products' />
  ))
}))

import React from 'react'
import {
  Button,
  Drawer,
  Upload,
  Icon,
  Divider,
  Input,
  Tooltip,
  message,
  Form,
  Row,
  Col,
  Card,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import ImageCropper from '../ImageCropper'

class DecorativeBlockManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      touched: false,
      fileList: [],
      loading: false,
      link: '',
      crop: {
        aspect: 1 / 1,
      },
      src: null,
      imgRef: null,
    }
  }

  editBlock = (id) => {
    const {
      fileList,
      link,
    } = this.state

    const {
      shopSettings: {
        listingElements = [],
      },
      modifyShop,
      editedDesign,
      shop,
      onClose,
    } = this.props

    const newMatch = {
      ...listingElements.find((el) => el._id === id),
      link,
    }

    const newListingElements = [
      ...listingElements.filter((el) => el._id !== id),
      newMatch,
    ]

    const modifications = {
      settings: {
        ...shop.settings,
        listingElements: newListingElements,
      },
    }

    modifyShop({
      shop_id: shop._id,
      modifications,
      callback: () => {
        message.info(
          <FormattedMessage
            id='updateSuccess.shop'
            defaultMessage='Boutique mise à jour'
          />,
        )
        this.setState({
          loading: false,
          touched: false,
        })
        onClose()
        return true
      },
    })
  }

  createAndUpload = () => {
    try {
      const {
        fileList,
        link,
        imgRef,
      } = this.state

      const {
        createDesign,
        upload,
        shopSettings: {
          listingElements = [],
          listingOrder = [],
        },
        modifyShop,
        shop,
        onClose,
      } = this.props

      const designFile = fileList[0].file

      const finalImage = new Buffer(imgRef.replace(/^data:image\/\w+;base64,/, ''), 'base64')

      const design = {
        name: designFile.name,
        owner: shop.owner,
        shop: shop._id,
      }

      const toUpload = [
        {
          file: finalImage,
          hash: {
            object: 'design',
            type: 'image/png',
          },
        },
      ]

      createDesign({
        design,
        fileType: designFile.type,
        callback: (data) => {
          const blockId = Math.random().toString(36).substring(7)

          upload({
            files: toUpload,
            uploadTokens: data.uploadTokens,
            callback: () => {
              const newListing = [
                ...listingElements,
                {
                  id: data.id,
                  design: {
                    ...design,
                    collections: [],
                    _id: data.id,
                  },
                  link,
                  type: 'custom',
                  _id: blockId,
                },
              ]

              const modifications = {
                settings: {
                  ...shop.settings,
                  listingElements: newListing,
                  listingOrder: [
                    blockId,
                    ...listingOrder,
                  ],
                },
              }

              modifyShop({
                shop_id: shop._id,
                modifications,
                callback: () => {
                  message.info(
                    <FormattedMessage
                      id='updateSuccess.shop'
                      defaultMessage='Boutique mise à jour'
                    />,
                  )
                  this.setState({
                    loading: false,
                    fileList: [],
                    link: '',
                    touched: false,
                    imgRef: null,
                  })
                  onClose()
                  return true
                },
              })
            },
          })
        },
      })

      this.setState({
        loading: true,
      })
    } catch (error) {
      console.log('DecorativeBlockManager - createAndUpload()', { error })
      return false
    }
  }

  delete =(id) => {
    const {
      deleteCustomBlock,
    } = this.props

    this.setState({
      loading: true,
    }, () => {
      deleteCustomBlock(id, () => {
        this.setState({
          loading: false,
        })
      })
    })
  }

  setCrop = (crop) => {
    this.setState((prevState) => ({
      crop,
      touched: true,
    }))
  }

  setImgRef = (imgRef) => {
    this.setState({ imgRef })
  }

  setlink = (event) => {
    this.setState({
      link: event.target.value,
      touched: true,
    })
  }

  render() {
    try {
      const {
        fileList,
        touched,
        loading,
        crop,
        src,
      } = this.state

      const {
        opened,
        editedDesign,
        deleteCustomBlock,
        onClose,
        form: {
          getFieldDecorator,
          isFieldsTouched,
          resetFields,
        },
      } = this.props

      if (editedDesign) {
        return (

          <Drawer
            title={(
              <FormattedMessage
                id='decorativeBlock.editTitle'
                defaultMessage='Modifier un bloc décoratif'
              />
            )}
            visible={opened}
            width='40%'
            onClose={onClose}
          >
            <Form>
              <Row>
                <Col span={24}>
                  <Form.Item label={(
                    <FormattedMessage
                      id='decorativeBlock.link'
                      defaultMessage='Lien (optionnel)'
                    />
                  )}
                  >
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          type: 'url',
                          message: <FormattedMessage id='chooseAName' defaultMessage='Choisissez un nom' />,
                        },
                      ],
                      initialValue: editedDesign.link,
                    })(
                      <Input
                        prefix={<Icon type='uslinker' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        suffix={(
                          <Tooltip
                            title={(
                              <FormattedMessage
                                id='decorativeBlock.helper1'
                                defaultMessage='Si vous ajoutez un lien, vos clients pourront y accéder en cliquant sur le bloc décoratif'
                              />
                            )}
                          >
                            <Icon type='info-circle' style={{ color: 'rgba(0,0,0,.45)' }} />
                          </Tooltip>
                        )}
                        onChange={this.setlink}
                      />,
                    )}
                  </Form.Item>

                </Col>
              </Row>
            </Form>
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  type='primary'
                  disabled={!touched}
                  block
                  onClick={() => this.editBlock(editedDesign._id)}
                >
                  <Icon type='edit' key='edit' />
                  <FormattedMessage
                    id='modify'
                    defaultMessage='Modifier'
                  />
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type='danger'
                  block
                  onClick={() => this.delete(editedDesign._id)}
                >
                  <Icon type='delete' key='edit' />
                  {' '}
                  <FormattedMessage id='delete' defaultMessage='Supprimer' />
                </Button>
              </Col>
            </Row>
          </Drawer>
        )
      }

      const uploaderProps = {
        onRemove: () => {
          this.setState({
            fileList: [],
            touched: true,
          })
        },
        className: 'uploadDiv',
        beforeUpload: (file) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => this.setState({
            src: reader.result,
            touched: true,
            fileList: [
              ...fileList,
              {
                file,
              },
            ],
          }))
          reader.readAsDataURL(file)
          return false
        },
        listType: 'picture',
        fileList: fileList
          .map((file) => file.file),
      }

      return (
        <Drawer
          title={(
            <FormattedMessage
              id='decorativeBlock.title'
              defaultMessage='Ajouter un bloc décoratif'
            />
          )}
          visible={opened}
          width='40%'
          onClose={onClose}
        >
          <Form>
            {
              touched
                ? (
                  <>
                    <p style={{
                      maxWidth: '70%',
                      textAlign: 'justify',
                    }}
                    >
                      <FormattedMessage
                        id='decorativeBlock.cropperHelper'
                        defaultMessage="Choisissez la partie de l'image à utiliser."
                      />
                    </p>
                    <Card
                      style={{
                        maxHeight: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ImageCropper
                        src={src}
                        setResult={this.setImgRef}
                      />
                    </Card>
                    <Form.Item label={(
                      <FormattedMessage
                        id='decorativeBlock.link'
                        defaultMessage='Lien (optionnel)'
                      />
                    )}
                    >
                      {getFieldDecorator('name', {
                        rules: [
                          {
                            type: 'url',
                            message: <FormattedMessage id='chooseAName' defaultMessage='Choisissez un nom' />,
                          },
                        ],
                      })(
                        <Input
                          prefix={<Icon type='uslinker' style={{ color: 'rgba(0,0,0,.25)' }} />}
                          suffix={(
                            <Tooltip
                              title={(
                                <FormattedMessage
                                  id='decorativeBlock.helper1'
                                  defaultMessage='Si vous ajoutez un lien, vos clients pourront y accéder en cliquant sur le bloc décoratif'
                                />
                              )}
                            >
                              <Icon type='info-circle' style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                          )}
                          onChange={this.setlink}
                        />,
                      )}
                    </Form.Item>
                    <Divider />
                    <Button
                      onClick={this.createAndUpload}
                      block
                      type='primary'
                      loading={loading}
                    >
                      {
                        loading
                          ? (
                            <span style={{ marginLeft: 10 }}>
                              <FormattedMessage
                                id='decorativeBlock.loading'
                                defaultMessage='Ajout du bloc...'
                              />
                            </span>
                          )
                          : (
                            <FormattedMessage id='validate' defaultMessage='Valider' />
                          )
                      }
                    </Button>
                  </>
                )
                : (
                  <>
                    <p style={{
                      maxWidth: '70%',
                      textAlign: 'justify',
                    }}
                    >
                      <FormattedMessage
                        id='decorativeBlock.helper2'
                        defaultMessage='Ajoutez des blocs personnalisés à votre liste de produits.
                      Ces blocs peuvent être configurés pour renvoyer vers
                      le lien de votre choix'
                      />
                    </p>
                    <Upload {...uploaderProps}>
                      <Button type='primary' size='large' disabled={loading}>
                        <Icon type='edit' key='edit' style={{ marginRight: 10 }} />
                        {' '}
                        <FormattedMessage
                          id='decorativeBlock.action'
                          defaultMessage='Choisir une image...'
                        />
                      </Button>
                    </Upload>
                  </>
                )
            }
          </Form>
        </Drawer>
      )
    } catch (error) {
      console.log('DecorativeBlockManager - render', { error })
      return null
    }
  }
}

export default Form.create({ name: 'decorativeBlock' })(DecorativeBlockManager)

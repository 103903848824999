import {
  put, call, takeEvery, select,
} from 'redux-saga/effects'
import {
  fromUsers,
} from '../selectors'
import * as actions from './actions'

const { getAuth } = fromUsers

export function* fetchTechnics(api) {
  try {
    const auth = yield select(getAuth)
    const result = yield call([api, api.get], '/technics')
    const { data } = result
    const shopifyTechnics = yield call([api, api.get],
      '/shopify', {
        headers: {
          authorization: auth, technics: true,
        },
      })
    const { technics } = shopifyTechnics
    yield put({ type: actions.RECEIVED_TECHNICS, payload: { data: { shopifyTechnics: technics, dbTechnics: data } } })
    return true
  } catch (e) {
    console.log('fetchTechnics sagas error ', e)
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(actions.GET_TECHNICS, fetchTechnics, api)
}

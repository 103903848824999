/**
 * Permet de reconstituer "custom" et de l'initialiser de façon à avoir un modèle complet
 * @param {object} custom - Les paramètres custom du thème
 * @returns {object} Un object "custom" reconstitué
 */
const getInitialCustom = (custom = {}) => {
  const {
    font = '',
    header = {
      fontColor: null,
      backgroundColor: null,
      hideSocial: false,
      hideLogin: false,
    },
    rollingMessage = {
      font: '',
      fontColor: null,
      backgroundColor: null,
    },
    listing = {
      alignTitle: null,
      detailsOnHover: false,
      hideDetailsOnMobile: false,
      pagination: false,
      priceColor: null,
    },
    productPage = {
      priceColor: null,
      displayAllDescription: false,
      hideDetailsProduct: false,
      hideSocialShare: false,
      hideColorVariation: false,
      hideQuantityPicker: false,
      alwaysAddToCart: false,
    },
    navMenu = {
      hideButton: false,
      hideSocial: false,
    },
    banner = {
      margin: '',
      carousel: false,
      autoplay: false,
    },
    filters = {
      hideAll: false,
    },
    checkout = {
      hideNewsletter: false,
      hideDeliveryDate: false,
    },
    footer = {
      display: false,
      displaySocial: false,
    },
    conversion = {
      symbol: '',
      icon: false,
      rateEurosToCredit: null,
    },
  } = custom
  return {
    font,
    header,
    rollingMessage,
    listing,
    productPage,
    navMenu,
    banner,
    filters,
    checkout,
    footer,
    conversion,
  }
}

const blockStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
  boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.03)',
  borderRadius: '16px',
}

const rowStyle = {
  display: 'flex',
  color: 'rgba(0, 0, 0, 0.85)',
  marginBottom: '30px',
  width: '100%',
}

const columnStyle = {
  display: 'flex',
  flex: '1 2',
  flexDirection: 'column',
  marginRight: '10px',
  alignItems: 'center',
}

const mainTitleStyle = {
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#14191E',
  marginBottom: '8px',
}

const titleStyle = {
  flex: '1 1',
  marginBottom: '10px',
  textAlign: 'center',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '22px',
}

export {
  getInitialCustom, blockStyle, rowStyle, columnStyle, titleStyle, mainTitleStyle,
}

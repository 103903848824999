import {
  put,
  call,
  takeEvery,
  select,
} from 'redux-saga/effects'
import { fromUsers } from '../selectors'

import * as actions from './actions'

/**
* @description getPaymentMethods
*
* return {Array} - fetchedPaymentMethods
*/

// GET /payment
export function* getPaymentMethods(api) {
  try {
    const stripeCustomerId = yield select(fromUsers.getStripeId)
    const authorization = yield select(fromUsers.getAuth)
    // NOTE if we receive an auth, we should reAuthenticate
    const result = yield call([api, api.get], '/payment', {
      headers: {
        mode: 'cors',
        authorization,
        stripeCustomerId,
      },
    })
    yield put({
      type: actions.RECEIVE_PAYMENT_METHODS,
      payload: {
        data: result.fetchedPaymentMethods,
      },
    })
    return true
  } catch (e) {
    console.log('error fetchedPaymentMethods', e)
    return false
  }
}

/**
* @description createPaymentMethod
*
* @param {String} - type - type of the payment method
* @param {Object} - stripe - stripe setup
* @param {String} - iban - Iban
*/

// PUT /payment
export function* createPaymentMethod(api, action) {
  const {
    type, stripe, iban, paymentMethodId,
  } = action.payload
  yield put({
    type: actions.LOADING,
  })
  try {
    let data
    const user = yield select(fromUsers.getUserInformations)
    if (type === 'card') {
      data = {
        billing_details: {
          name: user.firstName,
          email: user.email,
        },
      }
    } else {
      data = {
        sepa_debit: {
          iban,
        },
        billing_details: {
          name: user.firstName,
          email: user.email,
        },
      }
    }
    const stripeCustomerId = yield select(fromUsers.getStripeId)
    const authorization = yield select(fromUsers.getAuth)
    // NOTE if we receive an auth, we should reAuthenticate
    const createdPaymentMethod = yield stripe.createPaymentMethod(type, data)
    const result = yield call([api, api.post], '/payment', {
      paymentMethodId: (createdPaymentMethod.paymentMethod
        && createdPaymentMethod.paymentMethod.id) || paymentMethodId,
      stripeCustomerId,
    }, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    if (result) {
      yield put({
        type: actions.LOADING,
      })
      yield put({
        type: actions.FORCE_FETCH,
      })
    }
    return true
  } catch (e) {
    console.log('error createPaymentMethod', e)
    return false
  }
}

/**
* @description deletePaymentMethod
*
* @param {String} - paymentMethod id
*/

// DELETE /payment
export function* deletePaymentMethod(api, action) {
  const { id } = action.payload
  yield put({
    type: actions.LOADING,
  })
  try {
    const stripeCustomerId = yield select(fromUsers.getStripeId)
    const authorization = yield select(fromUsers.getAuth)
    // NOTE if we receive an auth, we should reAuthenticate
    const result = yield call([api, api.delete], '/payment', {
      headers: {
        mode: 'cors',
        authorization,
        paymentMethodId: id,
        stripeCustomerId,
      },
    })
    if (result) {
      yield put({
        type: actions.LOADING,
      })
      yield put({
        type: actions.FORCE_FETCH,
      })
    }
    return true
  } catch (e) {
    console.log('error deletePaymentMethod', e)
    return false
  }
}

/**
* @description defaultPaymentMethod
*
* @param {String} - paymentMethod id
*/

// PUT /payment
export function* defaultPaymentMethod(api, action) {
  const { id } = action.payload
  yield put({
    type: actions.LOADING,
  })
  try {
    const stripeCustomerId = yield select(fromUsers.getStripeId)
    const authorization = yield select(fromUsers.getAuth)
    // NOTE if we receive an auth, we should reAuthenticate
    const result = yield call([api, api.put], '/payment', {
      paymentMethodId: id,
      stripeCustomerId,
    }, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    if (result) {
      yield put({
        type: actions.LOADING,
      })
      yield put({
        type: actions.FORCE_FETCH,
      })
    }
    return true
  } catch (e) {
    console.log('error defaultPaymentMethod', e)
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(actions.FORCE_FETCH, getPaymentMethods, api)
  yield takeEvery(actions.CREATE_PAYMENT_METHOD, createPaymentMethod, api)
  yield takeEvery(actions.DELETE_PAYMENT_METHOD, deletePaymentMethod, api)
  yield takeEvery(actions.DEFAULT_PAYMENT_METHOD, defaultPaymentMethod, api)
}

import React, { useState } from 'react'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'
import {
  Form, Input, Button, Switch, DatePicker, Typography, InputNumber,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import CodeTypeForm from '../CodeTypeForm'
import BenefitsForm from '../BenefitsForm'
import { trimDiscountForApi } from '../../../../helpers/trim'
import './index.css'

const { Title } = Typography

const LimitsForm = injectIntl((props) => {
  const {
    hasAmountLimit,
    hasMaxAmountLimit,
    hasLimitUseLimit,
    setLimit,
    form,
    type = false,
    hasStartsAtDate,
    hasStopsAtDate,
  } = props
  const { getFieldDecorator } = form
  const intl = useIntl()

  if (!type.value) {
    return null
  }
  const placeholder = intl.formatMessage({ id: 'infoForm.placeholder.whatAmount', defaultMessage: 'Quel montant ?' })
  return (
    <>
      <Title level={4}>
        <FormattedMessage id='infoForm.termsOfUse' defaultMessage="Conditions d'utilisations" />
      </Title>
      <Form.Item>
        <div className='limit-container'>
          <span>
            <FormattedMessage id='infoForm.minimumCart' defaultMessage='Ajouter une valeur de panier MINIMUM' />
          </span>
          <span>
            {' '}
            <Switch checked={hasAmountLimit} onChange={() => setLimit('hasAmountLimit')} style={{ marginLeft: 5 }} />
            {' '}
          </span>
        </div>
        { hasAmountLimit
          ? getFieldDecorator('amount')(<InputNumber style={{ width: '200px', marginRight: '3%' }} placeholder={placeholder} />)
          : null }
      </Form.Item>
      <Form.Item>
        <div className='limit-container'>
          <FormattedMessage id='infoForm.maximumCart' defaultMessage='Ajouter une valeur de panier MAXIMUM' />
          <Switch checked={hasMaxAmountLimit} onChange={() => setLimit('hasMaxAmountLimit')} style={{ marginLeft: 5 }} />
        </div>
        { hasMaxAmountLimit
          ? getFieldDecorator('maxAmount')(<InputNumber style={{ width: '200px', marginRight: '3%' }} placeholder={placeholder} />)
          : null }
      </Form.Item>
      <Form.Item>
        <div className='limit-container'>
          <span>
            <FormattedMessage id='infoForm.useLimit' defaultMessage="Fixer une limite d'utilisation" />
          </span>
          <span>
            {' '}
            <Switch checked={hasLimitUseLimit} onChange={() => setLimit('hasLimitUseLimit')} style={{ marginLeft: 5 }} />
            {' '}
          </span>
        </div>
        { (hasLimitUseLimit
          ? getFieldDecorator('limitUses')(
            <Input
              style={{ width: '200px', marginRight: '3%' }}
              placeholder={intl.formatMessage({ id: 'limitsForm.placeholder.howMuch', defaultMessage: 'Combien ?' })}
            />,
          )
          : null) }
      </Form.Item>
      <Form.Item>
        <div className='limit-container'>
          <span>
            <FormattedMessage id='infoForm.date.start' defaultMessage='Fixer une date de début' />
          </span>
          <span>
            {' '}
            <Switch checked={hasStartsAtDate} onChange={() => setLimit('hasStartsAtDate')} style={{ marginLeft: 5 }} />
            {' '}
          </span>
        </div>
        { (hasStartsAtDate ? getFieldDecorator('startsAt')(<DatePicker style={{ width: 300 }} />) : null) }
      </Form.Item>
      <Form.Item>
        <div className='limit-container'>
          <span>
            <FormattedMessage id='infoForm.date.end' defaultMessage='Fixer une date de fin' />
          </span>
          <span>
            {' '}
            <Switch checked={hasStopsAtDate} onChange={() => setLimit('hasStopsAtDate')} style={{ marginLeft: 5 }} />
            {' '}
          </span>
        </div>
        { hasStopsAtDate ? getFieldDecorator('stopsAt')(<DatePicker style={{ width: 300 }} />) : null }
      </Form.Item>
    </>
  )
})

const InfoForm = (props) => {
  // const state = props.stateLoader.loadState()
  const {
    type,
    form,
    openDrawer,
    selected,
    edition,
    hasLimits,
    locale,
  } = props

  const { maxAmount, amount, limitUses } = hasLimits

  const [state, setState] = useState({
    hasAmountLimit: amount,
    hasMaxAmountLimit: maxAmount,
    hasLimitUseLimit: limitUses,
  })

  const {
    hasAmountLimit,
    hasMaxAmountLimit,
    hasLimitUseLimit,
    hasStartsAtDate,
    hasStopsAtDate,
  } = state

  const intl = useIntl()

  const { getFieldDecorator } = form

  const formValues = form.getFieldsValue()

  const hasBenefitsValues = formValues.benefits

  dayjs.locale(locale)

  const handleSubmit = (e) => {
    try {
      e.preventDefault()
      const {
        create,
        resetProducts,
        closeDiscountDrawer,
        modify,
        discount,
      } = props
      const {
        owner, shop, _id, createdAt, updatedAt,
      } = discount || {}
      form.validateFieldsAndScroll((err, values) => {
        if (!err && values) {
          const test = trimDiscountForApi({
            ...values, selected, owner, shop, _id, createdAt, updatedAt,
          })
          if (edition) {
            modify(test)
          } else {
            create(test)
          }
          form.resetFields()
          resetProducts()
          closeDiscountDrawer()
        }
      })
    } catch (error) {
      console.log('catched', error)
    }
  }

  const setLimit = (limit) => {
    setState((st) => {
      const _state = st
      _state[limit] = !st[limit]
      return {
        ...state,
        state: _state,
      }
    })
  }

  const randomCode = () => {
    const _randomCode = Math.random().toString(36).substring(2, 10).toUpperCase()
    form.setFieldsValue({ code: _randomCode }, (e, v) => console.log({ e, v }))
  }

  // const renderOptions = (_type) => {
  //   const result = [(
  //     <Option value='percent'>
  //       <FormattedMessage id='percent' defaultMessage='Pourcentage' />
  //     </Option>)]
  //   if (_type === 'code') {
  //     result.push((
  //       <Option value='amount'>
  //         <FormattedMessage id='amount' defaultMessage='Montant' />
  //       </Option>))
  //   } else if (_type === 'campaign') {
  //     result.push((
  //       <Option value='shipping'>
  //         <FormattedMessage id='shippingCost' defaultMessage='Frais de Livraison' />
  //       </Option>))
  //   }
  //   return result
  // }

  // const isValid = (_form) => {
  //   const { value, benefit } = _form.getFieldValue('benefits') || {}
  //   if (value && benefit) {
  //     return true
  //   }
  //   return false
  // }

  const codeCheck = (rule, value, callback) => {
    try {
      const { discount, allDiscounts } = props
      const hasSpace = value.length && value.includes(' ')
      const isSame = discount ? allDiscounts.find((d) => d.code === value && String(discount._id) !== String(d._id))
        : allDiscounts.find((d) => d.code === value)
      if (hasSpace) {
        callback(<FormattedMessage id='infoForm.noSpaceAllowed' defaultMessage='Il y a un espace qui se cache dans ce code !' />)
      }
      if (isSame) {
        callback(<FormattedMessage id='infoForm.alreadyUsed' defaultMessage='Ce code est déjà utilisé ailleurs...' />)
      }
      callback()
      return true
    } catch (e) {
      console.log('InfoForm / codeCheck error:', e)
      return callback()
    }
  }

  return (
    <>
      <Form layout='vertical' onSubmit={handleSubmit}>
        <Title level={4}>
          <FormattedMessage id='infoForm.discountType' defaultMessage='Quel type de promotion ?' />
        </Title>
        <Form.Item>
          {getFieldDecorator('type', {
            rules: [{
              required: true,
              message: <FormattedMessage id='infoForm.required.discountType' defaultMessage='Veuillez choisir un type de promotion' />,
            }],
          })(<CodeTypeForm />)}
        </Form.Item>
        { type.value ? (
          <>
            <Form.Item>
              {getFieldDecorator('benefits', {
                rules: [
                  { required: true },
                  {
                    validator: (rule, values, callback) => {
                      const errors = []
                      if (!values.benefit) {
                        errors.push(new Error(
                          <FormattedMessage id='infoForm.required.missingType' defaultMessage='Type manquant' />,
                        ))
                      }
                      if (!values.value) {
                        errors.push(new Error(
                          <FormattedMessage id='infoForm.required.missingValue' defaultMessage='Choisissez une valeur' />,
                        ))
                      }
                      callback(errors)
                    },
                  },
                ],
                initialValue: { benefit: 'percent', value: 0, spreadToShipping: false },
              })(
                <BenefitsForm type={type} />,
              ) }
            </Form.Item>
          </>
        ) : null}
        { type.value ? (
          <Form.Item>
            <Title level={4}>
              <FormattedMessage
                id='infoForm.restrictedDiscount'
                defaultMessage='Voulez vous restreindre votre promotion à des produits en particuliers  ?'
              />
            </Title>
            <Button
              type='primary'
              onClick={() => openDrawer()}
            >
              { selected.length ? (
                <span>
                  <FormattedMessage
                    id='infoForm.modifyRestrictedDiscount'
                    defaultMessage='Votre promotion est restreinte à {number} produit{agreement}, modifier ?'
                    values={{
                      number: selected.length,
                      agreement: selected.length > 1 ? 's' : '',
                    }}
                  />
                </span>
              ) : (
                <span>
                  <FormattedMessage id='yes' defaultMessage='Oui' />
                </span>
              )}
            </Button>
          </Form.Item>
        ) : null }
        { type.value ? (
          <>
            <Title level={4}>
              <FormattedMessage id='infoForm.discountName' defaultMessage='Votre promotion doit porter un nom (à usage interne)' />
            </Title>
            <Form.Item hasFeedback>
              { getFieldDecorator('name', {
                rules: [{
                  required: true,
                  message: <FormattedMessage id='infoForm.restricted.discountName' defaultMessage='Votre promotion doit avoir un nom !' />,
                }],
              })(<Input
                style={{ width: 300 }}
                placeholder={intl.formatMessage({
                  id: 'infoForm.placeholder.discountName',
                  defaultMessage: 'Choisissez un nom pour votre promotion',
                })}
              />)}
            </Form.Item>
          </>
        ) : null }
        { type.value && type.value === 'code' ? (
          <>
            <Title level={4}>
              <FormattedMessage id='infoForm.code' defaultMessage='Choissisez un code à saisir dans votre boutique' />
            </Title>
            <Form.Item>
              <div style={{ display: 'flex' }}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('code', {
                    rules: [
                      {
                        required: true,
                        message: <FormattedMessage id='infoForm.restricted.code' defaultMessage='Un code sans ... code ?' />,
                      },
                      { validator: (rule, value, cb) => codeCheck(rule, value, cb) },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item>
                  <Button onClick={() => randomCode()} style={{ margin: '0 0 0 10px' }}>
                    <FormattedMessage id='infoForm.random' defaultMessage='Aléatoire' />
                  </Button>
                </Form.Item>
              </div>
            </Form.Item>
          </>
        ) : null}
        <LimitsForm
          type={type}
          hasAmountLimit={hasAmountLimit}
          hasMaxAmountLimit={hasMaxAmountLimit}
          hasLimitUseLimit={hasLimitUseLimit}
          hasStartsAtDate={hasStartsAtDate}
          hasStopsAtDate={hasStopsAtDate}
          setLimit={setLimit}
          // eslint-disable-next-line
              {...props}
          openDrawer={openDrawer}
          selected={selected}
        />
        <Button type='primary' htmlType='submit' disabled={(!edition && !hasBenefitsValues)}>
          { edition
            ? <FormattedMessage id='edit' defaultMessage='Editer' />
            : <FormattedMessage id='create' defaultMessage='Créer' />}
          {'\u00a0'}
          <FormattedMessage id='infoForm.myDiscount' defaultMessage='ma Promotion' />
        </Button>
      </Form>
    </>
  )
}

export default injectIntl(InfoForm)

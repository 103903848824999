// import { isEmpty } from 'lodash'
// import getBPVariations from '../../helpers/getBPVariations'
// https://github.com/diegohaz/arc/wiki/Selectors
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
export const initialState = {
  loading: true,
  error: false,
  lastFetched: null,
  fetchPagination: {
    page: 0,
    pageSize: 10,
  },
  data: [],
  productDrawerOpen: false,
  preOrderDrawerOpen: false,
  createdId: false,
  loadingCreation: false,
  draftProducts: [],
  storedOrigin: null,
}

export const getStoredOrigin = (state = initialState) => state.storedOrigin

export const getLastFetchedProducts = (state = initialState) => state.lastFetched

export const allProducts = (state = initialState) => state.data || []

export const loading = (state = initialState) => state.loading || false

export const error = (state = initialState) => state.error || false

export const pagination = (state = initialState) => state.fetchPagination || {}

export const productDrawer = (state = initialState) => state.productDrawerOpen

export const preOrderDrawer = (state = initialState) => state.preOrderDrawerOpen

export const getCreatedId = (state = initialState) => state.createdId

export const loadingCreation = (state = initialState) => state.loadingCreation

export const getDraftProducts = (state = initialState) => state.draftProducts

export const allStockedProducts = (state = initialState) => state.data?.filter((product) => product.stocked) || []

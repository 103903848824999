import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {
  Button,
  Menu,
  Dropdown,
  Icon,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { bindActionCreators } from 'redux'

import dayjs from 'dayjs'
import { InnerLayout } from '../../layouts'
import {
  Orders,
  DetailedOrder,
  RefreshOrders,
  CSVDownloader,
  ShipupContainer,
} from '../../containers'
import { Refresher } from '../../components/molecules'

import { INIT_FETCH_DATA } from '../../store/orders/actions'
import { fromOrders } from '../../store/selectors'

import { extractDataAndParams, writeUrl } from '../../helpers/urlHelpers'

const initialState = {
  detailedOrder: null,
  visibleDrawer: false,
  selectedData: [],
  defaultActions: [],
  shipupDrawerVisible: false,
}

const initialFilters = {
  customerOrderId: '',
  type: '',
  address: '',
  name: '',
  status: '',
  product: '',
}

const OrdersPage = (props) => {
  const {
    location: { search },
    shopId,
    fetchData,
    history,
    isAdmin,
    allOrders,
  } = props

  const { dataFromURL, queryFromURL } = extractDataAndParams(search, '§')

  /**
   * Permet de récupérer une étendue de dates depuis l'URL
   * @returns un tableau avec les dates de début et de fin inscrites dans l'URL, au format dayjs
   */
  const getRangeFromURL = () => {
    // On récupère la range depuis l'URL sous forme de string
    const urlRange = queryFromURL.range
    if (urlRange) {
      // On recrée un tableau à partir de la string de l'URL en choisissant comme séparateur 'GMT,'
      const splitted = urlRange.split('GMT,')
      // On rajoute le 'GMT' manquant (suite au split) dans le premier élément du tableau
      splitted[0] += 'GMT'
      // On convertit au format dayjs pour pouvoir filtrer avec isBetween
      const start = dayjs(splitted[0])
      const end = dayjs(splitted[1])
      // On retourne l'étendue sous forme de tableau
      return [start, end]
    }
    // Si l'on a pas trouvé de clef "range" dans l'URL, on retourne null pour ne pas trigger les filtres
    return null
  }

  const composedFilters = { ...initialFilters, ...queryFromURL, range: getRangeFromURL() }

  const [filters, setFilters] = useState(composedFilters)
  const [ordersPageState, setOrdersPageState] = useState({ ...initialState })

  const {
    shipupDrawerVisible, selectedData, selectedRowKeys, detailedOrder, visibleDrawer,
  } = ordersPageState

  // On récupère les données au montage du composant, l'effet ne sera relancé que si shopId est modifié
  // fetchData dispatch INIT_FETCH_DATA_ORDERS qui lance la saga de récupération de données
  useEffect(() => {
    fetchData()
  }, [shopId])

  // on rajoute les filtres dans l'url quand ils sont modifiés
  useEffect(() => {
    history.push(writeUrl(filters))
  }, [filters])

  const setFiltering = (object) => {
    setFilters({
      ...filters,
      ...object,
    })
  }

  const handleMenuAction = (e) => {
    switch (e.key) {
    case 'gift':
      setFiltering({ type: 'Cadeau', status: 'Waiting' })
      break
    case 'selfSend':
      setFiltering({ type: 'Self Send', status: 'Waiting' })
      break
    default:
      console.log('orders handleMenuAction error')
      break
    }
  }

  const menuAction = () => (
    <Menu onClick={handleMenuAction}>
      <Menu.Item key='gift'>
        <FormattedMessage id='orders.giftFilter' defaultMessage='Filtrer les commandes cadeaux en attente' />
      </Menu.Item>
      <Menu.Item key='selfSend'>
        <FormattedMessage id='orders.selfSendFilter' defaultMessage='Filtrer les commandes Self Send en attente' />
      </Menu.Item>
    </Menu>
  )

  const hasFilters = () => !!Object.values(filters).filter(Boolean).length

  const resetFiltering = () => {
    setFilters(initialFilters)
  }

  const actions = [
    [
      <CSVDownloader selectedData={selectedData} />,
      <RefreshOrders />,
      <Dropdown overlay={menuAction} style={{ margin: '0 5px' }}>
        <Button key='2' type='default'>
          <FormattedMessage id='actions' defaultMessage='Actions' />
          {' '}
          <Icon type='down' />
        </Button>
      </Dropdown>,
    ],
    hasFilters() ? [
      <Button key='reset' onClick={resetFiltering} style={{ margin: '0 0 0 5px' }}>
        <FormattedMessage id='resetFilters' defaultMessage='Réinitialiser les filtres' />
      </Button>,
    ] : null,
    isAdmin ? [
      <Button
        key='shipup'
        onClick={() => setOrdersPageState({ ...ordersPageState, shipupDrawerVisible: true })}
        style={{ margin: '0 5px' }}
      >
        Shipup
      </Button>,
    ] : null,
  ]

  const displayDrawer = (order) => {
    setOrdersPageState({
      ...ordersPageState,
      detailedOrder: order,
      visibleDrawer: true,
    })
  }

  const onSelectChange = (newSelection) => {
    setOrdersPageState({
      ...ordersPageState,
      selectedData: allOrders.filter((order) => newSelection.includes(order._id)),
      selectedRowKeys: newSelection,
    })
  }

  const closeShipupDrawer = () => {
    setOrdersPageState({ ...ordersPageState, shipupDrawerVisible: false })
  }

  return (
    <InnerLayout
      title={(
        <div style={{
          fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
        }}
        >
          <FormattedMessage
            id='orders'
            defaultMessage='Commandes'
          />
        </div>
      )}
      extra={(<div style={{ display: 'flex' }}>{actions.map((action) => action)}</div>)}
    >
      <Refresher fetchData={fetchData} />
      <ShipupContainer
        visible={shipupDrawerVisible}
        closeShipupDrawer={closeShipupDrawer}
      />
      <Orders
        filters={filters}
        filtering={setFiltering}
        displayDrawer={displayDrawer}
        onSelectChange={onSelectChange}
        selectedData={selectedData}
        selectedRowKeys={selectedRowKeys}
        dataFromURL={dataFromURL}
        shopId={shopId}
      />
      {/* <OrderPreFilledAddress
        defaultActions={defaultActions}
        // setActions={setActions}
      /> */}
      <DetailedOrder
        detailedOrder={detailedOrder}
        visibleDrawer={visibleDrawer}
        hideDrawer={() => {
          setOrdersPageState({
            ...ordersPageState,
            detailedOrder: null,
            visibleDrawer: false,
          })
        }}
      />
    </InnerLayout>
  )
}

const mapStateToProps = (state) => ({
  allOrders: fromOrders.getOrders(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrdersPage),
)

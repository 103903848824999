import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { INIT_FETCH_DATA } from '../../store/campaigns/actions'
import { CampaignEditContainer } from '../../containers'
import { Refresher } from '../../components'
import theme from '../../config/theme'

/**
 * Page accessible pour l'édition d'une campagne.
 */
const CampaignEditPage = (props) => {
  const {
    history, user, shopId, fetchData,
  } = props

  useEffect(() => {
    fetchData()
  }, [shopId])

  return (
    <div style={{
      backgroundColor: theme.neutralColor[0],
      padding: '24px',
      height: 'calc(100vh - 65px)', // Hauteur du viewport - hauteur du footer
      overflow: 'auto',
    }}
    >
      <Refresher fetchData={fetchData} />
      <CampaignEditContainer
        user={user}
        history={history}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

// Injection de proprs ici
// withRouter injecte history
// mapDispatchToProps injecte la fonction fetchData
export default withRouter(connect(null, mapDispatchToProps)(CampaignEditPage))

import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { fromUsers } from '../../../store/selectors'
import config from '../../../config'

import { DataBlock } from '..'

/*
* A Data Block component that displays an average NPS Score.
* Needs props:
* - campaignId: the id of the current campaign (required if npsSource === 'CAMPAIGN)
* - entityId: the id of the current entity (required if npsSource === 'ENTITY')
* - npsSource (required): one of 'CAMPAIGN' | 'USER' so the component can fetch and display the appropriate nps score
*/
const DataBlockNPS = (props) => {
  try {
    const [averageNpsScore, setAverageNpsScore] = useState('N/C')
    const authToken = useSelector(fromUsers.getAuth)
    const {
      campaignId, entityId, shopId, npsSource,
    } = props

    const fetchAverageNpsScore = async () => {
      let res
      switch (npsSource) {
      case 'CAMPAIGN':
        res = await fetch(`${config.apiURL}/campaigns/averageNps/campaign/${campaignId}`, {
          method: 'GET',
          headers: { 'Content-type': 'application/json', Authorization: authToken },
        })
        break
      case 'ENTITY':
        res = await fetch(`${config.apiURL}/campaigns/averageNps/entity/${entityId}`, {
          method: 'GET',
          headers: { 'Content-type': 'application/json', Authorization: authToken },
        })
        break
      case 'USER':
        res = await fetch(`${config.apiURL}/campaigns/averageNps/user?shopId=${shopId}`, {
          method: 'GET',
          headers: { 'Content-type': 'application/json', Authorization: authToken },
        })
        break
      case 'SHOP':
        res = await fetch(`${config.apiURL}/campaigns/averageNps/shop/${shopId}`, {
          method: 'GET',
          headers: { 'Content-type': 'application/json', Authorization: authToken },
        })
        break
      default:
        break
      }
      if (!res.status || res.status !== 200) {
        setAverageNpsScore('N/C')
      }
      const data = await res.json()
      const score = data.data
      if (score === -1) {
        setAverageNpsScore('N/C')
      } else {
        setAverageNpsScore(_.round(data.data, 1))
      }
    }

    useEffect(() => {
      fetchAverageNpsScore()
    }, [campaignId, entityId, shopId])

    return (
      <DataBlock
        title={(
          <FormattedMessage
            id='magicLinks.dashboard.averageNPS'
            defaultMessage='NPS moyen'
          />
        )}
        value={averageNpsScore}
        icon={faStar}
      />
    )
  } catch (e) {
    console.log('molecules/DataBlockNPS error:', e)
    return null
  }
}

export default DataBlockNPS

import { uniqWith, isEqual } from 'lodash'

export default (product, type) => {
  try {
    if (product.variations.constructor === Array) {
      const result = uniqWith(product.baseProduct.variations
        .filter((baseProductVariation) => (
          product.variations
            .filter(type ? (v) => v.type === type : Boolean)
            .find((v) => (
              v.type === baseProductVariation.type
              && v.reference === baseProductVariation.reference
            ))
        )), isEqual)
      return result
    }
    return []
  } catch (error) {
    // console.log('Cannot get product Variations from BP', error)
    return []
  }
}

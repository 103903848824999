import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Spin } from 'antd'

import {
  CardPayment,
} from '../..'

import { neutralColor } from '../../../config/theme'

const ListPayments = () => {
  try {
    const {
      allPaymentMethods,
      deletePaymentMethod,
      defaultPaymentMethod,
      paymentsLoading,
    } = this.props

    let disableDeleteAction = false

    if (!allPaymentMethods.length) {
      return (
        <div style={{
          textAlign: 'center',
          padding: '20px',
          border: '1px solid #d9d9d9',
          backgroundColor: '#fafafa',
          borderRadius: '5px',
          marginBottom: '10px',
        }}
        >
          <FormattedMessage id='listPayments.noRecord' defaultMessage='Aucun moyen de paiement enregistré' />
        </div>
      )
    }
    // On disable l'action de supprimer une carte si il en reste plus qu'une
    if (allPaymentMethods.length === 1) {
      disableDeleteAction = true
    }

    return (
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',
        justifyContent: 'spaceAround',
      }}
      >
        {paymentsLoading ? (
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: neutralColor[6],
              opacity: 0.6,
              zIndex: 10,
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin id='paymentsLoading' />
          </div>
        ) : null}
        {allPaymentMethods.map((paymentMethod) => (
          <CardPayment
            paymentMethod={paymentMethod}
            deletePaymentMethod={deletePaymentMethod}
            defaultPaymentMethod={defaultPaymentMethod}
            disableDeleteAction={disableDeleteAction}
          />
        ))}
      </div>
    )
  } catch (e) {
    console.log('containers - Payments', e)
    return null
  }
}

export default ListPayments

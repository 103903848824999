/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Line, Pie } from 'react-chartjs-2'
import { FormattedMessage } from 'react-intl'
import dayjs from 'dayjs'
import {
  Select, DatePicker, Button, Icon, Switch,
} from 'antd'
import { encodeQuery } from '../../helpers/filters'
import { DashboardCard, DashboardCarousel, Loader } from '../../components/molecules'
import {
  fromShops,
  fromOrders,
  fromMembers,
  fromProducts,
  fromStocks,
  fromBaseProducts,
  fromLocale,
} from '../../store/selectors'
import {
  backgroundColors,
  getPhysicallyStockedQuantities,
  getNextBirthdaysThisMonth,
  getSizesPercentageByGender,
  getProductsTop3,
  getOrdersByFilters,
  getOrdersLabels,
  getShopifyNewBps,
  getOrdersDataSets,
  getStockValorisation,
} from '../../helpers/dashboard'

const { Option } = Select
const { RangePicker } = DatePicker
const dateFormatList = ['DD/MM/YYYY', 'MM/DD/YYYY']

const Dashboard = (props) => {
  const {
    shop,
    allOrders,
    allMembers,
    allProducts,
    shopifyBps,
    history,
    bpsLoading,
    ordersLoading,
    membersLoading,
    productsLoading,
    allStocks,
    locale = 'fr',
  } = props

  if (!shop) {
    return <Redirect to='/products' />
  }

  const { men, women } = getSizesPercentageByGender(allMembers)
  const nbOfBirthdays = getNextBirthdaysThisMonth(allMembers).length
  const nbOfMembers = allMembers.length

  const menDatasets = [{
    data: men,
    backgroundColor: backgroundColors,
    // hoverOffset: 4,
  }]

  const womenDatasets = [{
    data: women,
    backgroundColor: backgroundColors,
    // hoverOffset: 4,
  }]

  const PieStyle = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
    textAlign: 'center',
    background: '#FFFFFF',
    border: '1px solid #D5D5D5',
    borderRadius: '2px',
    margin: '0 10px 10px 0',
    width: '100%',
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }

  const messageStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '320px',
    padding: '30px',
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
    },
  }

  const initRange = [dayjs(dayjs().startOf('year'), dateFormatList[0]), dayjs(dayjs(), dateFormatList[1])]

  const [params, setParams] = useState({
    range: initRange,
    addresses: [],
    labels: getOrdersLabels(initRange, locale),
    datasets: getOrdersDataSets(shop, allOrders, { range: initRange, addresses: [] }, locale),
  })

  const { settings } = shop
  const {
    range, addresses, labels, datasets,
  } = params

  const nbOfOrders = getOrdersByFilters(allOrders, params, ['In Production', 'Shipped', 'Delivered']).length
  const nbOfOrdersInProduction = getOrdersByFilters(allOrders, params, ['In Production']).length
  const nbOfShippedOrders = getOrdersByFilters(allOrders, params, ['Delivered', 'Shipped']).length

  const addressesSelect = (
    <Select
      id='selectAddresses'
      allowClear
      showArrow
      mode='multiple'
      style={{ minWidth: 250, margin: '5px 0' }}
      onChange={(value) => {
        setParams({
          ...params,
          addresses: value,
          datasets: getOrdersDataSets(shop, allOrders, { ...params, addresses: value }, locale),
        })
      }}
      value={addresses}
      placeholder={<FormattedMessage id='dashboard.addressesFilter' defaultMessage='Filtrer par adresses pré-remplies...' />}
    >
      {settings.addresses?.map((add) => (
        <Option
          value={add.identifier}
          key={Math.random()}
        >
          {add.name}
        </Option>
      ))}
    </Select>
  )

  const rangePicker = (
    <RangePicker
      key='1'
      format={locale === 'en' ? dateFormatList[1] : dateFormatList[0]}
      value={range}
      onChange={(datesString) => {
        const newRange = [dayjs(datesString[0], dateFormatList[1]), dayjs(datesString[1], dateFormatList[1])]
        const newLabels = getOrdersLabels(newRange, locale)
        const newDatasets = getOrdersDataSets(shop, allOrders, { ...params, range: newRange }, locale)
        setParams({
          ...params,
          range: newRange,
          labels: newLabels,
          datasets: newDatasets,
        })
      }}
      style={{ width: '250px', margin: '5px 0' }}
      allowClear={false}
    />
  )

  const viewButton = (
    nbOfOrders
      ? (
        <Button
          type='primary'
          style={{ margin: '5px 0' }}
          onClick={
            () => history.push(`/orders/?${encodeQuery({ start: range[0], end: range[1], addresses })}§${encodeQuery({ fromDashboard: 'true' })}`)
          }
        >
          <Icon type='search' />
          {' '}
          <FormattedMessage id='dashboard.ordersDetails' defaultMessage='Voir ces commandes en détail' />
        </Button>
      )
      : null
  )

  const extra = [
    {
      content: addressesSelect,
      key: 'addressesSelect',
    },
    {
      content: rangePicker,
      key: 'rangePicker',
    },
    {
      content: viewButton,
      key: 'viewButton',
    },
  ]

  const viewButtonMembers = (
    nbOfBirthdays
      ? (
        <Button
          type='primary'
          onClick={
            () => history.push(`/members/?${encodeQuery({ birthday: 'true' })}`)
          }
        >
          <Icon type='select' />
        </Button>
      )
      : null
  )

  const [rangeStock, setRange] = useState([dayjs('2017/11/01'), dayjs()])

  const onChange = (checked) => {
    if (checked) setRange([dayjs('2017/11/01'), dayjs()])
    else setRange([dayjs().startOf('month'), dayjs()])
  }

  const top3RangeSwitch = (
    <Switch
      checkedChildren={<FormattedMessage id='dashboard.top3.always' defaultMessage='Depuis toujours' />}
      unCheckedChildren={<FormattedMessage id='dashboard.top3.thisMonth' defaultMessage='Ce mois-ci' />}
      defaultChecked
      onChange={onChange}
    />
  )

  const stockedProductsNumber = getPhysicallyStockedQuantities(allStocks)

  return (
    <div id='dashboard' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '50%',
        margin: '5px',
        justifyContent: 'space-evenly',
      }}
      >
        <DashboardCard
          title={stockedProductsNumber > 1
            ? <FormattedMessage id='dashboard.cards.stockedProducts' defaultMessage='Produits stockés' />
            : <FormattedMessage id='dashboard.cards.stockedProduct' defaultMessage='Produit stocké' />}
          data={stockedProductsNumber}
          alertTitle={<FormattedMessage id='dashboard.cards.OOSAlert' defaultMessage='Certains de vos produits sont en rupture de stock (OOS)' />}
          loading={ordersLoading}
          width='48%'
        />
        <DashboardCard
          title={(
            <FormattedMessage
              id='dashboard.cards.valorization'
              defaultMessage='Total du prix de vente (HT) des produits actuellement stockés'
            />
          )}
          data={getStockValorisation(allProducts, allStocks)}
          loading={ordersLoading}
          width='48%'
        />
        <DashboardCard
          title={nbOfOrders > 1
            ? <FormattedMessage id='dashboard.cards.placedOrders' defaultMessage='Commandes passées' />
            : <FormattedMessage id='dashboard.cards.placedOrder' defaultMessage='Commande passée' />}
          data={nbOfOrders}
          loading={ordersLoading}
          width='31%'
        />
        <DashboardCard
          title={nbOfOrdersInProduction > 1
            ? <FormattedMessage id='dashboard.cards.ordersInProduction' defaultMessage='Commandes en production' />
            : <FormattedMessage id='dashboard.cards.orderInProduction' defaultMessage='Commande en production' />}
          data={nbOfOrdersInProduction}
          loading={ordersLoading}
          width='31%'
        />
        <DashboardCard
          title={nbOfShippedOrders > 1
            ? <FormattedMessage id='dashboard.cards.shippedOrders' defaultMessage='Commandes expédiées' />
            : <FormattedMessage id='dashboard.cards.shippedOrder' defaultMessage='Commande expédiée' />}
          data={nbOfShippedOrders}
          loading={ordersLoading}
          width='31%'
        />
      </div>
      <div style={{ width: '48%' }}>
        <DashboardCarousel
          data={getShopifyNewBps(shopifyBps)}
          title={<FormattedMessage id='dashboard.newProducts' defaultMessage='Nouveaux produits Panopli !' />}
          loading={bpsLoading}
          width='100%'
        />
      </div>
      <div style={{ width: allMembers.length ? '70%' : '100%' }}>
        <div style={{
          display: 'flex', justifyContent: 'flex-end', marginLeft: '10px', flexWrap: 'wrap',
        }}
        >
          {extra.map((ex) => <span key={Math.random()} style={{ margin: '0 10px 0 0' }}>{ex.content}</span>)}
        </div>
        {ordersLoading
          ? (
            <div style={{ height: '160px', display: 'flex', alignItems: 'center' }}>
              <Loader />
            </div>
          )
          : (
            <div style={{
              border: '1px solid #D5D5D5', borderRadius: '3px', padding: 10, margin: '10px 10px 0 10px',
            }}
            >
              <Line
                data={{
                  labels,
                  datasets,
                }}
              />
            </div>
          )}
      </div>
      {allMembers.length ? (
        <div style={{ width: '29%', marginTop: '5px' }}>
          <div style={PieStyle}>
            {membersLoading
              ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
                >
                  <Loader />
                </div>
              )
              : men.some((size) => !!Object.values(size).some((value) => !!value))
                ? (
                  <>
                    <FormattedMessage id='dashboard.menSizes' defaultMessage='Tailles hommes (%)' />
                    <div style={{ width: '55%' }}>
                      <Pie
                        data={{
                          labels: [' XXS', ' XS', ' S', ' M', ' L', ' XL', ' XXL', ' XXXL'],
                          datasets: menDatasets,
                        }}
                        options={options}
                      />
                    </div>
                  </>
                )
                : (
                  <div style={messageStyle}>
                    <FormattedMessage
                      id='dashboard.noMenSize'
                      defaultMessage="Aucune taille n'a encore été entrée pour vos membres masculins..."
                    />
                  </div>
                )}
          </div>
          <div style={PieStyle}>
            {membersLoading
              ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
                >
                  <Loader />
                </div>
              )
              : women.some((size) => !!Object.values(size).some((value) => !!value))
                ? (
                  <>
                    <FormattedMessage id='dashboard.womenSizes' defaultMessage='Tailles femmes (%)' />
                    <div style={{ width: '55%' }}>
                      <Pie
                        data={{
                          labels: [' XXS', ' XS', ' S', ' M', ' L', ' XL', ' XXL', ' XXXL'],
                          datasets: womenDatasets,
                        }}
                        options={options}
                      />

                    </div>
                  </>
                )
                : (
                  <div style={messageStyle}>
                    <FormattedMessage
                      id='dashboard.noWoenSize'
                      defaultMessage="Aucune taille n'a encore été entrée pour vos membres féminins..."
                    />
                  </div>
                )}
          </div>
        </div>
      ) : null}
      <DashboardCarousel
        data={getProductsTop3(allOrders, rangeStock)}
        title={<FormattedMessage id='dashboard.Top3Title' defaultMessage='Top 3 ventes' />}
        isTop3
        top3RangeSwitch={top3RangeSwitch}
        loading={productsLoading}
        width='49%'
      />
      <div style={{ margin: '5px 0', width: '50%', display: 'flex' }}>
        <DashboardCard
          title={nbOfMembers > 1
            ? <FormattedMessage id='members' defaultMessage='Membres' />
            : <FormattedMessage id='member' defaultMessage='Membre' />}
          data={nbOfMembers}
          loading={membersLoading}
          width='50%'
        />
        <DashboardCard
          title={nbOfBirthdays > 1
            ? <FormattedMessage id='dashboard.birthdays' defaultMessage='Anniversaires ce mois-ci' />
            : <FormattedMessage id='dashboard.birthday' defaultMessage='Anniversaire ce mois-ci' />}
          data={nbOfBirthdays}
          viewButton={viewButtonMembers}
          loading={membersLoading}
          width='50%'
        />
      </div>
    </div>
  )
}

export default connect(
  (state) => ({
    shop: fromShops.getShop(state),
    shopId: fromShops.getId(state),
    allOrders: fromOrders.getOrders(state),
    shopifyBps: fromBaseProducts.getShopifyBps(state),
    allMembers: fromMembers.getMembers(state),
    allProducts: fromProducts.allProducts(state),
    bpsLoading: fromBaseProducts.loading(state),
    ordersLoading: fromOrders.loading(state),
    membersLoading: fromMembers.loading(state),
    productsLoading: fromProducts.loading(state),
    allStocks: fromStocks.getStocks(state),
    locale: fromLocale.getLocale(state),
  }),
)(Dashboard)

/* eslint-disable camelcase */
// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
import { initialState } from './selectors'
import {
  GET,
  RECEIVED,
  RECEIVED_MORE,
  ERROR,
  ASSIGN,
  MODIFY,
  MODIFY_SEVERAL,
  MODIFIED,
  MODIFICATION_ERROR,
  PRODUCT_DRAWER,
  PREORDER_DRAWER,
  CREATE,
  CREATED,
  CREATE_ERROR,
  SAVE_DRAFT,
  LOADING,
} from './actions'

export default (state = initialState, { type, payload = {} }) => {
  let concerned_products = []
  let unconcerned_products = state.data
  const {
    values = {}, shop, selected, open, loading, storedOrigin,
  } = payload
  const { margin, status, shops } = values
  // NOTE we need to modify those values further
  delete values.margin
  delete values.status
  switch (type) {
  case LOADING:
    return {
      ...state,
      loading,
    }
  case RECEIVED:
    return {
      ...state,
      data: payload.data,
      fetchPagination: payload.pagination,
      lastFetched: new Date(),
      storedOrigin,
      error: false,
    }
  case RECEIVED_MORE:
    return {
      ...state,
      data: state.data.concat(payload.data),
      fetchPagination: payload.pagination,
    }
  case GET:
    return {
      ...state,
      error: false,
    }
  case ERROR:
    return {
      ...state,
      error: true,
      loading: false,
    }
  case MODIFICATION_ERROR:
    return {
      ...state,
      data: state.data.map((p) => {
        if (!p.being_modified) return p
        const new_p = {
          ...p,
        }
        delete new_p.modifications
        delete new_p.being_modified
        return new_p
      }),
    }
  case ASSIGN:
    try {
      concerned_products = (payload && payload.products) || state.data
      unconcerned_products = (payload && payload.products) ? state.data
        .filter((p) => !((payload && payload.products) || []).find((P) => p._id === P._id)) : []
    } catch (error) {
      console.log('case ASSIGN', { error })
    }
    return {
      ...state,
      data: (unconcerned_products || []).concat((concerned_products || []).map((product) => {
        const baseProduct = {
          ...payload.data
            .find((bp) => String(bp._id) === String(product.supplierBaseProductId)) || {},
          ...product.baseProduct,
        }
        const newVariations = product.variations.filter((v) => {
          const bpVariations = baseProduct.variations || []
          const exist = bpVariations.find((bpv) => bpv.type === v.type && bpv.reference === v.reference)
          return exist
        })
        return {
          ...product,
          variations: newVariations,
          baseProduct,
        }
      })),
      error: false,
      loading: false,
    }
  case CREATE:
    return {
      ...state,
      loadingCreation: true,
    }
  case CREATED:
    return {
      ...state,
      data: state.data.concat(payload.newProduct),
      createdId: payload.createdId,
      loadingCreation: false,
    }
  case CREATE_ERROR:
    return {
      ...state,
      loadingCreation: false,
      errorCreation: true,
    }
  case SAVE_DRAFT:
    return {
      ...state,
      draftProducts: payload.draftProducts,
    }
  case MODIFY:
    return {
      ...state,
      data: state.data.map((p) => {
        if (p._id !== payload.product_id) return p
        return {
          ...p,
          being_modified: true,
          modifications: payload.modifications,
          fileHashes: payload.fileHashes,
          files: payload.files,
        }
      }),
    }
  case MODIFY_SEVERAL:
    console.log('MODIFY SEVERAL VALUES', values)
    return {
      ...state,
      data: state.data.map((p) => {
        if (!selected.includes(p._id)) return p
        const modifications = {
          ...values,
        }
        modifications.status = status
        if (typeof margin === 'number') {
          modifications.pricing = {
            ...p.pricing,
            margin,
          }
        }
        if (shops) {
          p.shops.splice(p.shops.indexOf(shop), 1)
          modifications.shops = p.shops
        }
        return {
          ...p,
          being_modified: true,
          modifications,
        }
      }),
    }
  case MODIFIED:
    return {
      ...state,
      data: state.data.map((p) => {
        if (!p.being_modified) return p
        const new_p = {
          ...p,
          ...p.modifications,
        }
        delete new_p.modifications
        delete new_p.being_modified
        delete new_p.fileHashes
        delete new_p.files
        return new_p
      }).filter(Boolean),
      productDrawerOpen: false,
    }
  case PRODUCT_DRAWER:
    return {
      ...state,
      productDrawerOpen: open,
    }
  case PREORDER_DRAWER:
    return {
      ...state,
      preOrderDrawerOpen: open,
    }
  default:
    return state
  }
}

import React from 'react'
import {
  Typography, Tag,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { CountrySelector } from '..'

const { Title } = Typography

export default ({
  productsPrice = 0,
  cartPrice = 0,
  hideCountrySelector,
  deliveryPrice,
  deliveryError,
  changeCartCountry,
  locale,
}) => (
  <div
    style={{
      borderTop: '1px solid rgb(235, 237, 240)',
      padding: '0px 5px',
    }}
  >
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid rgb(235, 237, 240)',
      padding: '5px 0',
    }}
    >
      <Title level={4} style={{ marginBottom: 0 }}>
        <FormattedMessage id='cartPrice.subTotal' defaultMessage='Sous Total' />
      </Title>
      {`${productsPrice} €`}
    </div>
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid rgb(235, 237, 240)',
      padding: '5px 0',
    }}
    >
      <Title level={4} style={{ marginBottom: 0 }}>
        <FormattedMessage id='delivery' defaultMessage='Livraison' />
      </Title>
      {deliveryError ? (
        <div>
          <Tag>
            <FormattedMessage id='cartPrice.notDeliverable' defaultMessage='Non livrable' />
          </Tag>
        </div>
      ) : null}
      {hideCountrySelector ? null : (
        <div>
          <CountrySelector
            width='175px'
            onChange={(value) => {
              changeCartCountry(value)
            }}
            locale={locale}
          />
        </div>
      )}
      <div>
        <span>
          {`${deliveryPrice} €`}
        </span>
      </div>
    </div>
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
    >
      <Title level={3} style={{ marginBottom: 0 }}>
        <FormattedMessage id='total' defaultMessage='Total' />
      </Title>
      <Title level={4} style={{ marginBottom: 0, marginTop: 0 }}>
        {`${cartPrice} €`}
      </Title>
    </div>
  </div>
)

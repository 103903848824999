import React from 'react'
import { FormattedMessage } from 'react-intl'
import { faPalette } from '@fortawesome/free-solid-svg-icons'
import { CopyTag, FormattedButton, StepTooltipLabel } from '../..'
import theme from '../../../config/theme'

const TemplateVariables = () => {
  try {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h4
          style={{
            display: 'flex',
            color: theme.neutralColor[13],
            fontSize: '14px',
            marginBottom: '8px',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <FormattedMessage
            id='campaigns.modal.step.template.variablesEmail'
            defaultMessage='Variables à copier'
          />
          <StepTooltipLabel
            title={(
              <FormattedMessage
                id='campaigns.step.emailing.variables.tooltip'
                defaultMessage="Copiez et collez les variables dans votre template
            pour personnaliser l'emailing avec les informations du destinataire"
              />
            )}
          />
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
        >
          <div>
            <CopyTag
              formattedMessageId='campaigns.modal.step.template.emailClient'
              defaultFormattedMessage='Email destinataire'
              valueToCopy='{emailClient}'
            />
            <CopyTag
              formattedMessageId='campaigns.modal.step.template.firstNameClient'
              defaultFormattedMessage='Prénom destinataire'
              valueToCopy='{firstNameClient}'
            />
            <CopyTag
              formattedMessageId='campaigns.modal.step.template.emailSender'
              defaultFormattedMessage='Email expéditeur'
              valueToCopy='{emailSender}'
            />
            <CopyTag
              formattedMessageId='campaigns.modal.step.template.firstNameSender'
              defaultFormattedMessage='Prénom expéditeur'
              valueToCopy='{firstNameSender}'
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormattedButton
              style={{ display: 'block', height: 'auto', padding: '0' }}
              messageId='campaigns.create.step.emailing.selectExistingTemplate'
              defaultMessage='Sélectionner un template existant'
              icon={faPalette}
              type='link'
              // TODO: to enable when existing template selection is done
              disabled
            />
            {/* TODO: remove this message when existing template selection is done */}
            <span style={{ display: 'block' }}>
              <FormattedMessage
                id='campaigns.create.step.actions.description'
                defaultMessage='Coming soon !'
              />
            </span>
          </div>
        </div>
      </div>
    )
  } catch (e) {
    console.log('error molecules/CampaignCreationModal/TemplateStep', e)
    return null
  }
}

export default TemplateVariables

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { REQUEST_TOKEN_FOR_PW_REINIT, REINIT_PW } from '../../store/users/actions'
import { fromUsers } from '../../store/selectors'
import { PasswordResetForm } from '../../components'

const getTokenFromPathname = (location) => {
  try {
    const splitted = location.split('/')
    const token = splitted.length > 1 && splitted.reverse().find(Boolean)
    return token
  } catch (e) {
    console.log('getTokenFromPathName - error', e)
    return false
  }
}

const PasswordReinitContainer = (props) => {
  const { user, reinitPassword, history } = props
  const { pathname } = history.location
  const token = getTokenFromPathname(pathname)
  return (
    <div>
      <PasswordResetForm user={user} reinitPassword={reinitPassword} token={token} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  authError: fromUsers.getError(state),
  loading: fromUsers.getLoading(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  requestNewPW: (payload) => ({
    type: REQUEST_TOKEN_FOR_PW_REINIT,
    payload,
  }),
  reinitPassword: (payload) => ({
    type: REINIT_PW,
    payload,
  }),
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordReinitContainer))

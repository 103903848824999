module.exports = {
    sentry: 'https://2c3fab78116d41d288691eed38b579b4@sentry.io/1376884',
    intercom: 'hf6w2yvr',
    stripeKey: 'pk_live_bcXHLj0WJlSfCmHp2Wk3G89Q',
    stripe_keys: {
        prod: 'pk_live_bcXHLj0WJlSfCmHp2Wk3G89Q'
    },
    stripe_status: 'prod',
    apiURL: 'https://api.production.panopli.co',
    allowModifications: true,
    stripeAppId: 'ca_BmiHSnd6epR8r36pq39ycNTxj63sDYD8',
    catalog: {
        catalogApiUrl: 'https://catalog-panopli.vercel.app',
      },
    slack: {
        channels: {
          donation_use_alert: 'C0572E274TY',
        },
    },
    streamlabs: {
        client_id: 'oh4lP2qYGT9XnsypLyR6Kvr9Gb4qk30zRfmO688w',
        client_secret: 'L2XBzNfk9KbBQvFCPkeqYDEGrz1hgWrD6Bx4nn6Z',
        redirect_uri: 'https://app.panopli.co/account',
    },
    unlayer: {
        projectId: '88040',
    },
    aws: {
        key: 'AKIAZNR5OD5BPDQ2KZUX',
        secret_key: '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',
        region: 'eu-west-3',
    },
}
import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { bindActionCreators } from 'redux'
import {
  Drawer,
  Button,
  Form,
  message,
} from 'antd'

import {
  MemberForm as FormMember, GroupCreation,
} from '../../components'

import { MODIFY_MEMBER, FETCH_FORCE_MEMBERS, SEND_MAIL } from '../../store/members/actions'
import { MODIFY } from '../../store/shops/actions'
import { fromMembers, fromShops } from '../../store/selectors'

class MemberEdition extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibleGroupeCreation: false,
      preFillGroup: [],
      newGroups: null,
    }
  }

  modifyMemberForm = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
      },
      modifyMember,
      fetchMembers,
      hideDrawer,
      member,
      allMembers,
      sendMail,
    } = this.props

    const { newGroups } = this.state

    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const newMember = {
          ...member,
          ...values,
        }
        if (allMembers.filter((_member) => (values.email === _member.email && member._id !== _member._id)).length) {
          message.error(<FormattedMessage id='email.alreadyUsed' defaultMessage='Cette adresse email est déjà utilisée' />)
          return false
        }
        if (newGroups) newMember.groups = newGroups
        await modifyMember(newMember)
        if (values.email !== member.email) {
          await sendMail(newMember)
        }
        await fetchMembers()
        message.success(<FormattedMessage id='memberModified' defaultMessage='Membre modifié !' />)
        hideDrawer()
      }
      return true
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
      },
      visibleDrawer,
      hideDrawer,
      member,
      shop,
      modifyShop,
    } = this.props

    const { name, lastName } = member

    const {
      settings: {
        membersGroups = [],
      },
    } = shop

    const {
      visibleGroupeCreation,
      preFillGroup,
    } = this.state

    if (!member) return null

    return (
      <Drawer
        title={(
          <FormattedMessage
            id='memberEdition.title'
            defaultMessage='Modifier - {name} {lastName}'
            values={{ name, lastName }}
          />
        )}
        width={640}
        closable
        visible={visibleDrawer}
        onClose={hideDrawer}
      >
        <GroupCreation
          visible={visibleGroupeCreation}
          onClose={() => this.setState({ visibleGroupeCreation: false })}
          shop={shop}
          modifyShop={modifyShop}
          preFillGroup={preFillGroup}
        />
        <Form onSubmit={this.modifyMemberForm} style={{ width: '100%' }}>
          <FormMember
            getFieldDecorator={getFieldDecorator}
            member={member}
            membersGroups={membersGroups}
            emailEdition
            admin
            onClick={() => this.setState({ visibleGroupeCreation: true })}
            onChange={(value) => this.setState({ newGroups: value })}
          />
          <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type='primary'
              htmlType='submit'
            >
              <FormattedMessage id='validate' defaultMessage='Valider' />
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    )
  }
}

export default connect(
  (state) => ({
    allMembers: fromMembers.getMembers(state),
    shop: fromShops.getShop(state),
  }),
  (dispatch) => bindActionCreators({
    modifyMember: (member) => ({
      type: MODIFY_MEMBER,
      member,
    }),
    fetchMembers: () => ({
      type: FETCH_FORCE_MEMBERS,
    }),
    sendMail: (member) => ({
      type: SEND_MAIL,
      member,
    }),
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)(Form.create({ name: 'modifyMemberForm' })(MemberEdition))

import React from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import theme from '../../../config/theme'

const CampaignDuplicateButton = (props) => {
  const {
    duplicateCampaign, // Required
  } = props

  return (
    <Button
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 14px',
        width: '40px',
        height: '40px',
      }}
      onClick={duplicateCampaign}
    >
      <FontAwesomeIcon
        icon={faCopy}
        style={{
          width: '14px',
          height: '14px',
          color: theme.character.title,
        }}
      />
    </Button>
  )
}

export default CampaignDuplicateButton

/* eslint-disable import/prefer-default-export */

/**
 * return vrai si l'utilisateur est un admin de shop
 * @param {object} user
 * @param {object} shop
 * @returns {boolean}
 */
export const isAShopOwner = (user, shop) => {
  if (!user || !shop) {
    return false
  }
  const { owner } = shop
  const { _id } = user
  if (!owner || !_id) {
    return false
  }
  return _id === owner
}

/**
 * return vrai si l'utilisateur est un admin de panopli
 * @param {object} user
 * @param {object} shop
 * @returns {boolean}
 */
export const isPanopliAdmin = (user) => {
  if (!user) {
    return false
  }
  return !!user.admin
}

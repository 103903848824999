import React, { useState } from 'react'
import {
  Button,
  Pagination,
  Popover,
  Modal,
  Table,
  Switch,
  Icon,
  Popconfirm,
  Divider,
} from 'antd'
import { uniq } from 'lodash'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'

import formatScopes from '../../../helpers/apiKeys'
import theme from '../../../config/theme'

const getCollaborator = (record, collaborators, user) => {
  if (user._id === record.userId) {
    return user
  }
  return collaborators.find((collaborator) => collaborator._id === record.userId) || false
}

const PopoverContent = (props) => {
  const { scopes } = props
  const formattedScopes = formatScopes(scopes)
  return (
    <div>
      {formattedScopes.map((formattedScope) => (
        <div style={{ padding: '5px' }}>
          <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{`${formattedScope.name} : `}</span>
          <span>
            {formattedScope.scopes.map((scope, index) => <span>{`${index ? ' /' : ''} ${scope.charAt(0).toUpperCase() + scope.slice(1)}`}</span>)}
          </span>
        </div>
      ))}
    </div>
  )
}

const ModalDelete = (props) => {
  const {
    deleteApiKeyWarning, setDeleteApiKeyWarning, deleteApiKey,
  } = props

  return (
    <Modal
      centered
      visible={!!deleteApiKeyWarning}
      onCancel={() => setDeleteApiKeyWarning(false)}
      title={null}
      footer={null}
    >
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
      }}
      >
        <div id='warning-icon-danger'>
          <Icon type='warning' theme='filled' style={{ color: theme.errorColor }} />
        </div>
        <h1 style={{ fontWeight: 'bold', marginTop: '20px' }}>
          <FormattedMessage
            id='developpers.page.modal.delete'
            defaultMessage='Supprimer la clé API'
          />
        </h1>
        <h3>
          <FormattedMessage
            id='developpers.page.modal.delete.warning'
            defaultMessage='Êtes-vous sûr de vouloir supprimer la clé API ?'
          />
        </h3>
        <h3 style={{ textAlign: 'center' }}>
          <FormattedMessage
            id='developpers.page.modal.delete.warning.1'
            defaultMessage='Toute suppression est définitive, nous ne serons pas en mesure de la restaurer ultérieurement'
          />
        </h3>
        <Divider />
        <div style={{
          display: 'flex', flexDirection: 'column', width: '100%', gap: '10px',
        }}
        >
          <Button
            type='danger'
            onClick={() => {
              deleteApiKey({ id: deleteApiKeyWarning }, () => setDeleteApiKeyWarning(false))
            }}
          >
            <FormattedMessage
              id='delete'
              defaultMessage='Supprimer'
            />
          </Button>
          <Button onClick={() => setDeleteApiKeyWarning(false)}>
            <FormattedMessage
              id='cancel'
              defaultMessage='Annuler'
            />
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const ApiKeysTable = (props) => {
  const {
    apiKeys,
    collaborators,
    user,
    setDrawerVisible,
    setCurrentApiKey,
    updateApiKey,
    deleteApiKey,
  } = props

  const [deleteApiKeyWarning, setDeleteApiKeyWarning] = useState(false)

  const columns = [
    {
      title: (<FormattedMessage
        id='developpers.page.table.name'
        defaultMessage='Nom de la clé API'
      />),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (<FormattedMessage
        id='developpers.page.table.scopes'
        defaultMessage='Droits'
      />),
      dataIndex: 'scopes',
      key: 'scopes',
      width: '20%',
      render: (scopes) => {
        const nameScopes = uniq(scopes.map((scope) => scope.split('.')[1]))
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              {nameScopes.map((name, index) => <span>{`${index ? ' /' : ''} ${name.charAt(0).toUpperCase() + name.slice(1)}`}</span>)}
            </div>
            <Popover placement='bottom' title={null} content={<PopoverContent scopes={scopes} />} trigger='hover'>
              <Icon type='info-circle' />
            </Popover>
          </div>
        )
      },
    },
    {
      title: (<FormattedMessage
        id='developpers.page.table.createBy'
        defaultMessage='Créé par'
      />),
      key: 'createBy',
      render: (text, record) => {
        try {
          const collaborator = getCollaborator(record, collaborators, user)
          const {
            firstName = '',
            lastName = '',
          } = collaborator?.additionalInfos
          return (
            <span>
              {`${firstName} ${lastName}`}
            </span>
          )
        } catch (err) {
          return <span>NC</span>
        }
      },
    },
    {
      title: (<FormattedMessage
        id='developpers.page.table.createdAt'
        defaultMessage='Créé le'
      />),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (
        <span>{dayjs(createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
    {
      title: (<FormattedMessage
        id='developpers.page.table.updateAt'
        defaultMessage='Mis à jour le'
      />),
      dataIndex: 'updateAt',
      key: 'updatedAt',
      render: (updatedAt) => (
        <span>{dayjs(updatedAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
    {
      title: (<FormattedMessage
        id='developpers.page.table.isActive'
        defaultMessage='Status'
      />),
      key: 'isActive',
      width: '100px',
      render: (text, record) => {
        if (record.isActive) {
          return (
            <Popconfirm
              title={(
                <FormattedMessage
                  id='developpers.page.table.isActive.warning'
                  defaultMessage='Souhaitez-vous désactivé la clé ?'
                />
              )}
              onConfirm={() => updateApiKey({ id: String(record._id), modifications: { isActive: false } })}
              okType='danger'
              okText={(
                <FormattedMessage
                  id='disable'
                  defaultMessage='Désactiver'
                />
              )}
              cancelText={(
                <FormattedMessage
                  id='cancel'
                  defaultMessage='Annuler'
                />
              )}
            >
              <Switch
                checkedChildren={(
                  <FormattedMessage
                    id='active.female'
                    defaultMessage='Active'
                  />
                )}
                unCheckedChildren={(
                  <FormattedMessage
                    id='inactive.female'
                    defaultMessage='Inactive'
                  />
                )}
                checked={record.isActive}
              />
            </Popconfirm>
          )
        }
        return (
          <Switch
            checkedChildren={(
              <FormattedMessage
                id='active.female'
                defaultMessage='Active'
              />
            )}
            unCheckedChildren={(
              <FormattedMessage
                id='inactive.female'
                defaultMessage='Inactive'
              />
            )}
            checked={record.isActive}
            onChange={() => {
              updateApiKey({ id: String(record._id), modifications: { isActive: true } })
            }}
          />
        )
      },
    },
    {
      title: '',
      key: 'actions',
      width: '100px',
      render: (text, record) => (
        <>
          <Icon
            className='table-action'
            type='delete'
            onClick={() => setDeleteApiKeyWarning(String(record._id))}
          />
          <Divider type='vertical' />
          <Icon
            className='table-action'
            type='edit'
            onClick={() => {
              setCurrentApiKey(record)
              setDrawerVisible(true)
            }}
          />
        </>
      ),
    },
  ]

  return (
    <div style={{ margin: '0 10px 10px 10px' }}>
      <ModalDelete
        deleteApiKeyWarning={deleteApiKeyWarning}
        setDeleteApiKeyWarning={setDeleteApiKeyWarning}
        deleteApiKey={deleteApiKey}
      />
      <Table
        columns={columns}
        dataSource={apiKeys}
        pagination={(
          <Pagination
            defaultPageSize={10}
            defaultCurrent={1}
            total={apiKeys.length}
          />
        )}
      />
    </div>

  )
}

export default ApiKeysTable

import React from 'react'
import ProductImage from '@balibart/product-image'
import {
  List, Card, Icon, Button, Popover,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { stockRating as rateStock } from '@balibart/stocks'
import theme from '../../../config/theme'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const {
  warningColor,
  backgroundColor,
  errorColor,
  neutralColor,
} = theme

const ProductItem = (props) => {
  const {
    product,
    selected,
    select,
    setProductOrdering,
    allStocks,
    entityId,
    history,
  } = props

  const {
    status,
    favColor,
    variations,
    favPz,
    preOrder,
    stock,
    name,
  } = product

  const filteredStocks = allStocks.filter((s) => s.productId === String(product._id || product.id))
  const rating = rateStock(filteredStocks, entityId)
  const styleSelect = {
    width: 25,
    height: 25,
    border: `2px solid ${neutralColor[2]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    right: 5,
    top: 5,
    position: 'absolute',
    backgroundColor,
    zIndex: 1000,
  }
  const extra = selected.includes(product._id) ? (
    <div style={styleSelect}>
      <Icon type='check' style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '18px' }} />
    </div>
  ) : (
    <div style={styleSelect} />
  )
  const statusDisplay = () => {
    switch (status) {
    case 'Active':
      return <FormattedMessage id='active.male' defaultMessage='Actif' />
    case 'Inactive':
      return <FormattedMessage id='inactive.male' defaultMessage='Inactif' />
    default:
      return 'Locked'
    }
  }
  const statusBackgroundColor = () => {
    switch (status) {
    case 'Active':
      return '#09b520'
    case 'Inactive':
      return backgroundColor
    default:
      return 'rgba(0, 0, 0, 0.65)'
    }
  }
  const statusColor = () => {
    switch (status) {
    case 'Active':
      return 'white'
    case 'Inactive':
      return 'rgba(0, 0, 0, 0.65)'
    default:
      return backgroundColor
    }
  }
  return (
    <List.Item
      className='productCardItem'
      key={product._id}
      style={{
        cursor: 'auto',
        textAlign: 'center',
        padding: '10px 30px',
      }}
    >
      <Card
        onClick={(e) => {
          e.stopPropagation()
          select()
        }}
        hoverable
        cover={(
          <div style={{ position: 'relative' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                left: 5,
                top: 5,
                position: 'absolute',
                backgroundColor: statusBackgroundColor(),
                color: statusColor(),
                zIndex: 1000,
                padding: '2px 7px',
                fontWeight: 700,
              }}
            >
              {statusDisplay()}
            </div>
            {extra}
            <ProductImage
              base_url={imageBaseUrl}
              product={product}
              variation={favColor && favColor.reference
                ? favColor
                : variations.find((variation) => variation.type === 'color')}
              currentPrintingZone={favPz || 'front'}
            />
          </div>
        )}
        className='productCard'
        style={{
          backgroundColor,
        }}
      >
        {rating === 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 10px',
              backgroundColor: warningColor[6],
              fontWeight: 700,
              position: 'absolute',
              bottom: '30px',
              left: 0,
              right: 0,
              lineHeight: 1,
            }}
          >
            <div>
              <Icon type='warning' style={{ fontSize: '16px', marginRight: '5px', textAlign: 'left' }} />
            </div>
            <Popover content={(
              <div>
                <FormattedMessage id='productCard.OOSRisk' defaultMessage='Risque de rupture de stock' />
              </div>
            )}
            >
              <div style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'left',
              }}
              >
                <FormattedMessage id='productCard.OOSRisk' defaultMessage='Risque de rupture de stock' />
              </div>
            </Popover>
          </div>
        )}
        {rating === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 10px',
              backgroundColor: errorColor[6],
              fontWeight: 700,
              position: 'absolute',
              bottom: '30px',
              left: 0,
              right: 0,
              lineHeight: 1,
            }}
          >
            <div>
              <Icon type='warning' style={{ fontSize: '16px', marginRight: '5px' }} />
            </div>
            <Popover content={(
              <div>
                <FormattedMessage id='productCard.OOS' defaultMessage='Rupture de stock' />
              </div>
            )}
            >
              <div style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'left',
              }}
              >
                <FormattedMessage id='productCard.OOS' defaultMessage='Rupture de stock' />
              </div>
            </Popover>
          </div>
        )}
        {preOrder && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 10px',
              backgroundColor: neutralColor[6],
              fontWeight: 700,
              position: 'absolute',
              bottom: '30px',
              left: 0,
              right: 0,
              lineHeight: 1,
            }}
          >
            <div>
              <Icon type='info-circle' style={{ fontSize: '16px', marginRight: '5px' }} />
            </div>
            <Popover content={(
              <div>
                <FormattedMessage id='preOrder' defaultMessage='Précommande' />
              </div>
            )}
            >
              <div style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'left',
              }}
              >
                <FormattedMessage id='preOrder' defaultMessage='Précommande' />
              </div>
            </Popover>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 10px',
            backgroundColor,
            fontWeight: 700,
            height: '30px',
            lineHeight: 1,
          }}
        >
          <div style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: stock && stock.length ? '50%' : '100%',
            textAlign: 'left',
          }}
          >
            <Popover content={(<div>{name}</div>)}>
              {name}
            </Popover>
          </div>
          {filteredStocks && filteredStocks.length ? (
            <div style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '50%',
              textAlign: 'right',
            }}
            >
              <Popover content={(
                <div>
                  <FormattedMessage id='globalStock' defaultMessage='Stock global :' />
                  {` ${filteredStocks.length === 1
                    ? filteredStocks.find(Boolean).qty
                    : filteredStocks.map((s) => s.qty).reduce((a, b) => a + b)}`}
                </div>
              )}
              >
                <FormattedMessage id='globalStock' defaultMessage='Stock global :' />
                {` ${filteredStocks.length === 1
                  ? filteredStocks.find(Boolean).qty
                  : filteredStocks.map((s) => s.qty).reduce((a, b) => a + b)
                }`}
              </Popover>
            </div>
          ) : null}
        </div>
      </Card>
      <div
        style={{
          display: 'flex',
          flexFlow: 'column wrap',
          alignItems: 'flex-start',
        }}
      >
        {filteredStocks && filteredStocks.length ? (
          <Button
            style={{ height: 25, margin: '10px 0 5px 0', fontWeight: 700 }}
            type='primary'
            onClick={() => history.push(`/product?id=${product._id}&tab=stocks`)}
          >
            <FormattedMessage id='productCard.stockHandle' defaultMessage='Gérer mon stock' />
          </Button>
        ) : null}
        <Button
          style={{ height: 25, margin: filteredStocks && filteredStocks.length ? '0 0 5px 0' : '10px 0 5px 0', fontWeight: 700 }}
          type='primary'
          onClick={() => history.push(`/product?id=${product._id}`)}
        >
          <FormattedMessage id='productCard.productInformations' defaultMessage='Informations produit' />
        </Button>
        <Button
          style={{ height: 25, fontWeight: 700 }}
          type='primary'
          onClick={(e) => {
            e.stopPropagation()
            setProductOrdering(product._id)
          }}
        >
          <FormattedMessage id='productCard.productOrdering' defaultMessage='Commander ce produit' />
        </Button>
        {filteredStocks && filteredStocks.length ? null : (
          <div style={{ height: 30 }} />
        )}
      </div>
    </List.Item>
  )
}

export default ProductItem

/* eslint-disable max-len */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import {
  Drawer,
  Divider,
  Descriptions,
  Table,
} from 'antd'

import { fromOrders, fromUsers, fromLocale } from '../../store/selectors'

import { DeliveryView, PromotionView } from '../../components'
import { GiftView } from '..'

const { Item } = Descriptions

export default connect(
  (state) => ({
    modifiedOrders: fromOrders.getModifiedOrders(state),
    user: fromUsers.getUser(state),
    locale: fromLocale.getLocale(state),
  }), null,
)((props) => {
  const {
    visibleDrawer,
    hideDrawer,
    detailedOrder,
    modifiedOrders,
    user,
    locale,
  } = props

  let i = 0

  if (!detailedOrder) return null

  let order = detailedOrder
  if (modifiedOrders.length && modifiedOrders.find((o) => o._id === detailedOrder._id)) {
    order = modifiedOrders.find((o) => o._id === detailedOrder._id)
  }

  const { subOrders } = order

  // const marge = order.products.map((product) => product.margin)
  //   .reduce((accumulator, currentValue) => accumulator + currentValue)
  //
  //   <Item label='Marge gagnée'>
  //     {`${marge.toFixed(2)} €`}
  //   </Item>

  const columns = [
    {
      title: <FormattedMessage id='product' defaultMessage='Produit' />,
      key: 'name',
      dataIndex: 'name',
      render: (name) => {
        if (name === 'Don') {
          return name
        }
        return (
          <a
            href={`${window.location.origin}/products?name=${name}`}
          >
            {name}
          </a>
        )
      },
    },
    {
      title: <FormattedMessage id='category' defaultMessage='Catégorie' />,
      key: 'category',
      dataIndex: `category_${locale}`,
    },
    {
      title: <FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />,
      key: 'sub_category',
      dataIndex: `sub_category_${locale}`,
    },
    {
      title: <FormattedMessage id='quantity' defaultMessage='Quantité' />,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: <FormattedMessage id='color' defaultMessage='Couleur' />,
      key: 'color',
      dataIndex: 'variations.color.name',
    },
    {
      title: <FormattedMessage id='size' defaultMessage='Taille' />,
      key: 'size',
      dataIndex: 'variations.size.name',
    },
  ]

  return (
    <Drawer
      title={(
        <FormattedMessage
          id='detailedOrder.title'
          defaultMessage='Détails de la commande n°{id}'
          values={{ id: order.customerOrderId }}
        />
      )}
      width={640}
      visible={visibleDrawer}
      onClose={hideDrawer}
      className='DeliveryView'
    >
      <Descriptions
        title={(
          <FormattedMessage id='detailedOrder.shipmentInfos' defaultMessage="Informations d'expédition" />
        )}
        layout='vertical'
      >
        <Item label={<FormattedMessage id='name' defaultMessage='Nom' />}>
          {`${order.client.firstName} ${order.client.lastName}`}
        </Item>
        <Item label={<FormattedMessage id='email' defaultMessage='Email' />}>
          {order.client.email}
        </Item>
        <Item label={<FormattedMessage id='phone' defaultMessage='Téléphone' />}>
          {order.client.phone}
        </Item>
        <Item label={<FormattedMessage id='address' defaultMessage='Adresse' />} span={2}>
          {`${(order.client.address && order.client.address.streetAddress)}, ${detailedOrder.client.address && detailedOrder.client.address.postalCode}, ${detailedOrder.client.address && detailedOrder.client.address.city}, ${order.client.address && order.client.address.country}`}
        </Item>
      </Descriptions>
      <GiftView
        detailedOrder={order}
      />
      {subOrders?.length > 1 ? (
        <div>
          <Divider />
          <h3 style={{ textAlign: 'center' }}>
            <FormattedMessage
              id='detailedOrder.shipments'
              defaultMessage='Cette commande contient {number} envois différents'
              values={{ number: subOrders?.length }}
            />
          </h3>
        </div>
      ) : null}
      {subOrders?.map((subOrder) => {
        i += 1
        return (
          <div>
            <Divider />
            <div style={{
              padding: '20px',
              backgroundColor: '#E5E8F0',
              borderRadius: '5px',
            }}
            >
              <h3>
                <FormattedMessage
                  id='detailedOrder.shipmentNumber'
                  defaultMessage='Envoi n°{i}'
                  values={{ i }}
                />
              </h3>
              <DeliveryView
                subOrder={subOrder}
                giftOrder={order.giftOrder || false}
                detailedOrder={order}
                user={user}
              />
              <Table
                columns={columns}
                dataSource={subOrder?.products}
                size='middle'
                pagination={false}
              />
            </div>
          </div>
        )
      })}
      <PromotionView
        detailedOrder={order}
      />
      <Divider />
      <Descriptions
        title={(
          <FormattedMessage id='detailedOrder.moreInfos' defaultMessage='Informations supplémentaires' />
        )}
        layout='vertical'
      >
        <Item label={<FormattedMessage id='totalPrice' defaultMessage='Prix total' />}>
          {`${(order.detailedPricing && order.detailedPricing.total)
            ? (order.detailedPricing.total + order.detailedPricing.delivery_discounted).toFixed(2)
            : (order.transaction.amount / 100).toFixed(2)} €`}
        </Item>
        <Item label={<FormattedMessage id='Credits used' defaultMessage='Crédits utilisés' />}>
          {`${parseFloat(order.transaction?.creditAmount || 0).toFixed(0)}`}
        </Item>
        <Item label={<FormattedMessage id='delivery' defaultMessage='Livraison' />}>
          {`${order.detailedPricing.delivery_discounted.toFixed(2)} €`}
        </Item>
        <Item label={<FormattedMessage id='deliveryDelay' defaultMessage='Délais de livraison' />}>
          <FormattedMessage
            id='detailedOrder.deliveryDelay'
            defaultMessage='de {min} à {max} jours'
            values={{
              min: order.deliveryDelays.deliveryMin,
              max: order.deliveryDelays.deliveryMax,
            }}
          />
        </Item>
        <Item label={<FormattedMessage id='deliveryType' defaultMessage='Type de livraison' />}>
          {order.shipping.shippingMode}
        </Item>
      </Descriptions>
    </Drawer>
  )
})

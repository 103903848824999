import React from 'react'

import { Badge } from 'antd'
import { primaryColor, neutralColor, white } from '../../../config/theme'

const BadgeNew = () => (
  <Badge
    style={{
      marginLeft: '10px',
      backgroundColor: white,
      color: primaryColor[6],
      boxShadow: `0 0 0 1px ${neutralColor[2]} inset`,
    }}
    count='new ✨'
  />
)

export default BadgeNew

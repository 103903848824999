import React from 'react'
import { notification } from 'antd'
import {
  put, call, takeEvery, select, all, takeLatest,
} from 'redux-saga/effects'
import { FormattedMessage } from 'react-intl'
import * as actions from './actions'
import { fetchCollaborators } from '../collaborators/sagas'
import { fetchEntities } from '../entities/sagas'
import { fromShops, fromUsers, fromEntities } from '../selectors'

const { getId } = fromShops
const { getAuth, getUserId } = fromUsers
const { getEntities } = fromEntities

const endPointAPI = '/apiKeys'

export function* fetchApiKeys(api) {
  try {
    yield put({ type: actions.GET })
    const shop = yield select(getId)
    const auth = yield select(getAuth)
    const { data } = yield call([api, api.get], endPointAPI, { headers: { authorization: auth, shop } })
    yield put({ type: actions.RECEIVED, payload: { data } })
    return true
  } catch (e) {
    console.log('fetchApiKeys error', e, e.message)
    yield put({
      type: actions.ERROR,
      error_details: (e.message || e),
    })
    return false
  }
}

export function* createApiKey(api, { payload }) {
  try {
    const shopId = yield select(getId)
    const userId = yield select(getUserId)
    const auth = yield select(getAuth)
    const entities = yield select(getEntities)
    const entityId = entities.find((_entity) => _entity)._id
    const apiKeyCreated = yield call([api, api.post], endPointAPI, {
      ...payload,
      userId,
      shopId,
      entityId,
    }, {
      headers: {
        mode: 'cors',
        authorization: auth,
      },
    })
    if (apiKeyCreated.code === 201 && apiKeyCreated.apiKey) {
      yield put({ type: actions.CREATED, payload: { apiKey: apiKeyCreated.apiKey } })
      yield put({ type: actions.FETCH_FORCE })
    } else {
      throw new Error(apiKeyCreated.code)
    }
    return true
  } catch (e) {
    console.log('createApiKey error', e, e.message)
    yield put({
      type: actions.ERROR,
      error_details: (e.message || e),
    })
    notification.error({
      message: (<FormattedMessage
        id='deleteAPIKey.error'
        defaultMessage='Oups, une erreur est survenue...'
      />),
      placement: 'topRight',
      duration: 4,
    })
    return false
  }
}

export function* updateAPIKey(api, { payload, callback }) {
  try {
    const auth = yield select(getAuth)

    // Call API pour update la campagne
    const apiKeyUpdated = yield call([api, api.put], endPointAPI, payload, {
      headers: {
        mode: 'cors',
        authorization: auth,
      },
    })

    // Si campagne mise à jour, on refresh les données via callback ou actions sagas
    if (apiKeyUpdated.code === 200) {
      if (callback) {
        callback()
      }
      console.log(`updateAPIKey : apiKey id=${payload._id} mise à jour`)

      yield put({ type: actions.FETCH_FORCE })
      console.log('updateAPIKey : apiKeys actualisées')

      notification.success({
        message: (<FormattedMessage id='update.success' defaultMessage='Mise à jour réussie !' />),
        placement: 'topRight',
        duration: 4,
      })
    } else {
      throw new Error(apiKeyUpdated.code)
    }
    return true
  } catch (e) {
    console.log('updateAPIKey error', e, e.message)
    notification.error({
      message: (<FormattedMessage
        id='deleteAPIKey.error'
        defaultMessage='Oups, une erreur est survenue...'
      />),
      placement: 'topRight',
      duration: 4,
    })
    return false
  }
}

export function* deleteAPIKey(api, { payload, callback }) {
  try {
    const auth = yield select(getAuth)
    const { id } = payload
    // Call API pour update la campagne
    const apiKeyDeleted = yield call([api, api.delete], endPointAPI, {
      headers: {
        mode: 'cors',
        authorization: auth,
        id,
      },
    })

    if (apiKeyDeleted.code === 200) {
      console.log(`deleteAPIKey : apiKeys id=${id} supprimé`)

      if (callback) {
        callback()
      }
      yield put({ type: actions.FETCH_FORCE })
      console.log('deleteAPIKey : apiKeys actualisées')

      notification.success({
        message: (<FormattedMessage id='apiKey.delete.success' defaultMessage='Clé API supprimée !' />),
        placement: 'topRight',
        duration: 4,
      })
    } else {
      throw new Error(apiKeyDeleted.code)
    }
    return true
  } catch (e) {
    console.log('deleteAPIKey error', e, e.message)
    notification.error({
      message: (<FormattedMessage
        id='deleteAPIKey.error'
        defaultMessage='Oups, une erreur est survenue...'
      />),
      placement: 'topRight',
      duration: 4,
    })
    return false
  }
}

export function* getDataForDeveloperPage(api) {
  yield all([
    call(fetchApiKeys, api),
    call(fetchCollaborators, api),
    call(fetchEntities, api),
  ])
  yield put({ type: actions.END_FETCH_DATA })
}

export default function* ({ api }) {
  yield takeLatest(actions.INIT_FETCH_DATA, getDataForDeveloperPage, api)
  yield takeLatest(actions.FETCH_FORCE, fetchApiKeys, api)
  yield takeEvery(actions.CREATE, createApiKey, api)
  yield takeEvery(actions.UPDATE, updateAPIKey, api)
  yield takeEvery(actions.DELETE, deleteAPIKey, api)
}

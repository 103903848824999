import React, { useState } from 'react'
import {
  Input,
  Drawer,
  Button,
  InputNumber,
  Divider,
  message,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { SubscriptionWarning, UserEntityForm } from '../..'

const EntityCreation = (props) => {
  try {
    const {
      setVisibleDrawerCreationEntity,
      createEntity,
      entities,
      alreadySetupEntityId,
    } = props

    const [nameEntity, setNameEntity] = useState('')
    const [tax, setTax] = useState(20)
    const [email, setEmail] = useState('')
    const [errorEmail, setErrorEmail] = useState(false)
    const [language, setLanguage] = useState('FR')
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [loading, setLoading] = useState(false)

    return (
      <div>
        <SubscriptionWarning
          content={(
            <FormattedMessage
              id='entityCreation.helper'
              defaultMessage='100€ / entité par mois, ajoutés à la facture mensuelle de la boutique'
            />
          )}
        />
        <div style={{ display: 'flex' }}>
          <div style={{
            flex: '2 3', display: 'flex', margin: '0 10px 0 0', flexDirection: 'column',
          }}
          >
            <div style={{   fontWeight: 'bold', margin: '0 10px 0 0' }}>
              <FormattedMessage id='name' defaultMessage='Nom' />
            </div>
            <Input
              defaultValue={nameEntity}
              placeholder='Paris'
              onChange={(e) => setNameEntity(e.target.value)}
            />
          </div>
          <div style={{
            flex: '1 3', display: 'flex', flexDirection: 'column',
          }}
          >
            <div style={{   fontWeight: 'bold', margin: '0 10px 0 0' }}>
              TVA
            </div>
            <InputNumber
              defaultValue={tax}
              min={0}
              max={100}
              style={{ width: '100%' }}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
              onChange={(value) => setTax(value)}
            />
          </div>
        </div>
        <Divider />
        <UserEntityForm
          email={email}
          setEmail={setEmail}
          errorEmail={errorEmail}
          setErrorEmail={setErrorEmail}
          setLanguage={setLanguage}
          name={name}
          setName={setName}
          lastName={lastName}
          setLastName={setLastName}
          entities={entities}
          alreadySetupEntityId={alreadySetupEntityId}
        />
        <Button
          type='primary'
          style={{ margin: '20px 0 0 0' }}
          disabled={!(name && email && language && name && lastName && (tax || tax === 0))}
          loading={loading}
          onClick={() => {
            setLoading(true)
            createEntity({
              entity: { name: nameEntity, tax },
              user: {
                email,
                language,
                name,
                lastName,
                adminCollaborator: true,
              },
            }, () => {
              setVisibleDrawerCreationEntity(false)
              message.success(<FormattedMessage id='entityCreation.success' defaultMessage='Nouvelle entité créée avec succès !' />)
            })
          }}
        >
          <FormattedMessage id='entityCreation.newEntity' defaultMessage='Créer la nouvelle entitée' />
        </Button>
      </div>
    )
  } catch (e) {
    console.log('Entity Creation - error', e)
    return null
  }
}

const DrawerEntityCreation = (props) => {
  const {
    visibleDrawerCreationEntity,
    setVisibleDrawerCreationEntity,
  } = props
  return (
    <Drawer
      title={<FormattedMessage id='entityCreation.createNewEntity' defaultMessage='Créer une nouvelle entité' />}
      placement='right'
      width='35%'
      onClose={() => setVisibleDrawerCreationEntity(false)}
      visible={visibleDrawerCreationEntity}
      destroyOnClose
    >
      <EntityCreation {...props} />
    </Drawer>
  )
}

export default DrawerEntityCreation

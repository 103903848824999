import React from 'react'
import { Alert } from 'antd'
import { Loader } from '../..'

const DashboardCard = (props) => {
  const {
    title,
    data,
    width = '25%',
    loading,
    alert = null,
    alertTitle,
    viewButton = null,
  } = props
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width,
        background: '#FFFFFF',
        border: '1px solid #D5D5D5',
        borderRadius: '3px',
        position: 'relative',
        margin: '5px',
      }}
    >
      {viewButton ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            position: 'absolute',
            top: 7,
            right: 7,
          }}
        >
          {viewButton}
        </div>
      ) : null}
      {loading ? (
        <div style={{ height: '160px', display: 'flex', alignItems: 'center' }}>
          <Loader />
        </div>
      ) : (
        <div>
          {alert && alertTitle ? (
            <Alert
              message={alertTitle}
              type='warning'
              closable
              showIcon
              style={{
                position: 'absolute',
                margin: '10px',
                left: 0,
                top: 0,
              }}
            />
          ) : null}
          <div
            style={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '25px',
              lineHeight: '22px',
              textAlign: 'center',
              color: '#000000',
              marginBottom: '15px',
              padding: '0 20px',
            }}
          >
            {data}
          </div>
          <div
            style={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '14px',
              lineHeight: '22px',
              color: '#000000',
              textAlign: 'center',
              padding: '0 20px',
            }}
          >
            {title}
          </div>
        </div>
      )}
    </div>
  )
}

export default (DashboardCard)

import React from 'react'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'

import { FormattedSwitch } from '../..'

const CampaignPostalCardSwitch = (props) => {
  const {
    // TODO props à réactiver avec le dev des cartes postales
    // campaign, // Required
    // setCampaign, // Required
    // setNeedUpdateCampaign, // Required
    // createdCampaignId, // Required
    isDisabled = false,
    size,
  } = props

  // TODO onChange function

  return (
    <FormattedSwitch
      checked={false} // TODO reprendre le paramètre cartes postales de campagne correspondant
      onChangeFunction={null} // TODO onChange fonction
      title={(
        <FormattedMessage
          id='campaigns.create.step.parameters.postalCard'
          defaultMessage='Carte postale imprimée'
        />
      )}
      subtitle='Coming Soon!' // TODO à mettre à jour avec le dev des cartes postales
      tooltipText='Coming Soon!' // TODO à mettre à jour avec le dev des cartes postales
      icon={faCircleInfo}
      isDisabled={isDisabled}
      size={size}
    />
  )
}

export default CampaignPostalCardSwitch

/* eslint-disable max-len */
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Select,
  Icon,
  Button,
  Modal,
  Table,
  InputNumber,
  Alert,
  message,
} from 'antd'
import { uniq, uniqBy, compact } from 'lodash'

import Cropper from 'rc-cropping'
import Dialog from 'rc-dialog'
import 'rc-cropping/assets/index.css'
import 'rc-dialog/assets/index.css'

import { generateColorFamilyId } from '../../../helpers/baseProducts/creation'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const { Option } = Select

const BaseProductVariationsForm = (props) => {
  try {
    const {
      product,
      updateProduct,
      colorsFromAWS,
      allFamilies,
    } = props

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [allColors, setAllColors] = useState(product.colors && product.colors.length ? product.colors : [])
    const [allSizes, setAllSizes] = useState(product.sizes && product.sizes.length ? product.sizes.map((size) => size.name) : [])
    const [pastilleToUpload, setPastilleToUpload] = useState(product.pastilleToUpload && product.pastilleToUpload.length ? product.pastilleToUpload : [])
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage] = useState('')
    const [previewTitle] = useState('')
    const columns = [
      {
        title: <FormattedMessage id='pastille' defaultMessage='Pastille' />,
        key: 'pastille',
        render: (color) => {
          const { name } = color
          if (colorsFromAWS && colorsFromAWS.length && colorsFromAWS.filter((awsColor) => awsColor === name).length) {
            return (
              <img
                style={{
                  width: '40px',
                  height: '40px',
                }}
                alt='example'
                src={`${imageBaseUrl}/Pastille/low/${name}.png`}
              />
            )
          } if (pastilleToUpload && pastilleToUpload.length && pastilleToUpload.find((pastil) => pastil.color === name)) {
            return (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                  alt={pastilleToUpload.find((pastil) => pastil.color === name).color}
                  src={pastilleToUpload.find((pastil) => pastil.color === name).thumbUrl}
                />
                <Button
                  icon='delete'
                  style={{ marginLeft: '10px' }}
                  size='small'
                  onClick={() => {
                    const newPastilleToUpload = pastilleToUpload.filter((pastil) => pastil.color !== name)
                    setPastilleToUpload(newPastilleToUpload)
                    updateProduct(product.provisionalId, {
                      pastilleToUpload: newPastilleToUpload,
                    })
                  }}
                />
              </div>
            )
          } return (
            <Cropper
              locale='en-US'
              circle
              showActions
              outputImgSize={{ width: 33, height: 33 }}
              src={pastilleToUpload && pastilleToUpload.length && pastilleToUpload.find((pastil) => pastil.color === name) ? pastilleToUpload.find((pastil) => pastil.color === name).thumbUrl : null}
              getSpinContent={() => <span>loading...</span>}
              renderModal={() => <Dialog />}
              onChange={async (file) => {
                if (file) {
                  const _file = file
                  const reader = new FileReader()
                  reader.readAsDataURL(_file)
                  reader.onload = async () => {
                    _file.thumbUrl = reader.result
                    let newPastilleToUpload = pastilleToUpload
                    newPastilleToUpload.push({
                      ..._file,
                      color: name,
                    })
                    newPastilleToUpload = uniqBy(newPastilleToUpload, 'color')
                    const newStatus = (product.status && product.status.length && product.status.filter((s) => s !== 'Pastille')) || []
                    await setPastilleToUpload(newPastilleToUpload)
                    return updateProduct(product.provisionalId, {
                      pastilleToUpload: newPastilleToUpload,
                      status: newStatus,
                    })
                  }
                } return null
              }}
            />
          )
        },
        width: '100px',
      },
      {
        title: <FormattedMessage id='lastName' defaultMessage='Nom' />,
        key: 'name',
        render: (color) => (
          <span>{color.name}</span>
        ),
      },
      {
        title: <FormattedMessage id='BaseProductVariationsForm.priceImpact' defaultMessage="Impact sur le prix d'achat HT (par famille)" />,
        key: 'price',
        width: '20%',
        render: (color) => {
          if (product.families && product.families.filter((fami) => fami.colors.includes(color.name)).length) {
            return (
              <InputNumber
                defaultValue={product.families.find((fami) => fami.colors.includes(color.name)).costImpact || 0}
                value={product.families.find((fami) => fami.colors.includes(color.name)).costImpact || 0}
                style={{
                  width: '50%',
                }}
                formatter={(value) => `${value} €`}
                parser={(value) => value.replace('€', '').replace(' ', '')}
                onChange={(value) => {
                  const newfamilies = product.families.map((f) => {
                    const { idPanopli } = product.families.find((fami) => fami.colors.includes(color.name))
                    if (f.idPanopli === idPanopli) {
                      return ({
                        ...f,
                        costImpact: value,
                      })
                    } return f
                  })
                  updateProduct(product.provisionalId, {
                    families: compact(newfamilies),
                  })
                }}
              />
            )
          } return (
            <div>
              <FormattedMessage id='BaseProductVariationsForm.needFamilySummons' defaultMessage="Besoin d'être assigné à une famille" />
            </div>
          )
        },
      },
      {
        title: <FormattedMessage id='family' defaultMessage='Famille' />,
        key: 'families',
        render: (color) => {
          if (product.families && product.families.filter((fami) => fami.colors.includes(color.name)).length) {
            const { idPanopli } = product.families.find((fami) => fami.colors.includes(color.name))
            return (
              <div style={{ display: 'flex' }}>
                {idPanopli}
                <div
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    const newfamilies = product.families.map((f) => {
                      if (f.idPanopli === idPanopli) {
                        if (f.colors.filter((_color) => _color !== color.name).length) {
                          return ({
                            ...f,
                            colors: f.colors.filter((_color) => _color !== color.name),
                          })
                        } return null
                      } return f
                    })
                    updateProduct(product.provisionalId, {
                      families: compact(newfamilies),
                    })
                  }}
                >
                  <Icon type='delete' />
                </div>
              </div>
            )
          } if (product.families && product.families.length) {
            return (
              <Select
                style={{
                  width: '100%',
                }}
                onChange={(idPanopli) => {
                  const newfamilies = product.families.map((f) => {
                    if (f.idPanopli === idPanopli) {
                      const newColors = f.colors
                      newColors.push(color.name)
                      return ({
                        ...f,
                        colors: newColors,
                      })
                    } return f
                  })
                  updateProduct(product.provisionalId, {
                    families: compact(newfamilies),
                  })
                }}
              >
                {product.families.map((fam) => (
                  <Option value={fam.idPanopli}>
                    {fam.idPanopli}
                  </Option>
                ))}
              </Select>
            )
          } return null
        },
        width: '30%',
      },
    ]

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img alt='example' style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <div style={{

          fontWeight: 'bold',
          marginBottom: '10px',
        }}
        >
          <FormattedMessage id='BaseProductVariationsForm.addSizes' defaultMessage='Ajouter les tailles de ce produit' />
        </div>
        <Select
          mode='tags'
          style={{ width: '100%' }}
          defaultValue={allSizes}
          placeholder={<FormattedMessage id='BaseProductVariationsForm.addSizes' defaultMessage='Ajouter les tailles de ce produit' />}
          onChange={(value) => {
            setAllSizes(value)
            updateProduct(product.provisionalId, {
              sizes: value.map((v) => ({ name: v })),
            })
          }}
        >
          {allSizes.map((size) => (
            <Option key={size}>
              {size}
            </Option>
          ))}
        </Select>
        <div style={{

          fontWeight: 'bold',
          marginBottom: '10px',
          marginTop: '10px',
        }}
        >
          <FormattedMessage id='BaseProductVariationsForm.addColors' defaultMessage='Ajouter les couleurs de ce produit' />
        </div>
        <Alert
          message={<FormattedMessage id='BaseProductVariationsForm.alertMessage' defaultMessage="Attention : le sélecteur de couleur est lent car il contient beaucoup de data. Une prochaine version d'antd va régler ce souci." />}
          type='warning'
          closable
          showIcon
          style={{ marginBottom: '10px' }}
        />
        <Select
          mode='tags'
          style={{ width: '100%' }}
          defaultValue={allColors.map((color) => color.name)}
          placeholder={<FormattedMessage id='BaseProductVariationsForm.addColors' defaultMessage='Ajouter les couleurs de ce produit' />}
          onChange={(value) => {
            const newColors = value.map((v) => ({
              name: v,
            }))
            setAllColors(newColors)
            const params = {
              colors: newColors,
            }
            if (product.families && product.families.length) {
              const newFamilies = product.families.map((family) => {
                const colors = compact(family.colors.map((color) => {
                  if (value.includes(color)) {
                    return color
                  } return null
                }))
                if (!colors.length) {
                  return null
                }
                return ({
                  ...family,
                  colors,
                })
              })
              params.families = compact(newFamilies)
            }
            const newPastilleToUpload = []
            if (pastilleToUpload && pastilleToUpload.length) {
              value.map((v) => {
                if (product.pastilleToUpload.filter((pastille) => v === pastille.color).length) {
                  newPastilleToUpload.push(product.pastilleToUpload.filter((pastille) => v === pastille.color))
                } return false
              })
              params.pastilleToUpload = newPastilleToUpload
              setPastilleToUpload(newPastilleToUpload)
            }
            if (!(colorsFromAWS && colorsFromAWS.length && colorsFromAWS.filter((awsColor) => awsColor === value).length)) {
              const newStatus = product.status || []
              newStatus.push('Pastille')
              params.status = uniq(newStatus)
            }
            return updateProduct(product.provisionalId, params)
          }}
        >
          {colorsFromAWS.map((color) => (
            <Option key={color}>
              <span>
                <img
                  style={{ width: '18px', marginRight: '10px' }}
                  alt='example'
                  src={`${imageBaseUrl}/Pastille/low/${color}.png`}
                />
              </span>
              {color}
            </Option>
          ))}
        </Select>
        <div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '20px 0 10px 0',
          }}
          >
            <div style={{

              fontWeight: 'bold',
            }}
            >
              <FormattedMessage id='BaseProductVariationsForm.colorsList' defaultMessage='Liste des couleurs' />
            </div>
            {allColors.length && product.idPanopli ? (
              <Button
                type='primary'
                onClick={() => {
                  const familyId = generateColorFamilyId(product, allFamilies)
                  const newfamilies = product.families || []
                  if (product.families && selectedRowKeys.filter((color) => product.families.filter((fami) => fami.colors.includes(color.name)).length).length) {
                    return message.warning(<FormattedMessage
                      id='baseProductVariationsForm.alertMessage'
                      defaultMessage='Certaines des couleurs sélectionnées ont déjà une famille'
                    />)
                  }
                  newfamilies.push({
                    idPanopli: familyId,
                    colors: selectedRowKeys.map((color) => color.name),
                    priceImpact: 0,
                  })
                  updateProduct(product.provisionalId, {
                    families: newfamilies,
                  })
                  return setSelectedRowKeys([])
                }}
              >
                <FormattedMessage id='BaseProductVariationsForm.createFamily' defaultMessage='Créer une famille avec les couleurs sélectionnés' />
              </Button>
            ) : null}
          </div>
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: (newSelectedRowKeys, selectedRows) => setSelectedRowKeys(selectedRows),
            }}
            rowKey={(color) => color}
            columns={columns}
            dataSource={allColors}
            pagination={{ pageSize: 100 }}
          />
        </div>
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/BaseProductVariationsForm')
    return null
  }
}

export default BaseProductVariationsForm

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { CustomizableDragger } from '../..'
import { getFileAsString } from '../../../helpers/imageUpload'

const CampaignLogoInput = (props) => {
  const {
    campaign, // Required
    setCampaign, // Required
    setNeedUpdateCampaign, // Required
    isDisabled = false,
    uploadImage, // Required
    createdCampaignId, // Required
  } = props

  return (
    <CustomizableDragger
      name='logo'
      customRequest={({ onSuccess, file }) => {
        (async () => {
          uploadImage({
            image64: await getFileAsString(file),
            imageName: file?.name,
            uploadType: 'campaign',
            objectId: createdCampaignId,
          }, (imageUrl) => {
            setCampaign({
              ...campaign,
              theme: {
                ...campaign?.theme,
                logo: imageUrl,
                logoName: file?.name,
              },
            })
            setNeedUpdateCampaign(true)
            onSuccess('ok')
          })
        })()
      }}
      formattedMessageId='campaigns.create.step.theme.logotype'
      formattedDefaultMessage='Votre logotype :'
      isDisabled={isDisabled}
    >
      <img src={campaign?.theme?.logo} alt='' style={{ maxHeight: '32px', width: 'auto' }} />
      <span style={{ display: 'block' }}>{campaign?.theme?.logoName}</span>
      <span className='ant-upload-text' style={{ display: 'block' }}>
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: '4px' }} />
        <FormattedMessage
          id='campaigns.create.step.theme.logotype.description'
          defaultMessage='Sélectionnez ou glissez un autre fichier'
        />
      </span>
    </CustomizableDragger>
  )
}

export default CampaignLogoInput

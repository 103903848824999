import React from 'react'
import { FormattedMessage } from 'react-intl'
import { AutoComplete, Button } from 'antd'

export default class Auto extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    try {
      const {
        placeholder,
        data,
        onPressEnter,
      } = this.props
      const {
        value,
      } = this.state
      return (
        <div style={{ padding: 8 }}>
          <AutoComplete
            placeholder={placeholder}
            onChange={(v) => this.setState({ value: v })}
            onSelect={(v) => this.setState({ value: v })}
            onPressEnter={() => onPressEnter(value)}
            dataSource={data}
            filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => onPressEnter(value)}
            icon='search'
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            <FormattedMessage id='search' defaultMessage='Recherche' />
          </Button>
        </div>
      )
    } catch (error) {
      console.log('error message', { error })
      return null
    }
  }
}

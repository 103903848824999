import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'antd'
import React from 'react'
import theme from '../../../config/theme'

const CustomizableModal = (props) => {
  const {
    isModalVisible, // Required
    iconBackgroundColor, // Required
    icon, // Required
    iconColor, // Required
    formattedMessageTitle, // Required
    FormattedMessageDescriptions, // Required
    onCancel, // Required
    children,
  } = props

  return (
    <Modal
      closable
      visible={isModalVisible}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: '24px 24px 16px 24px' }}
    >
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '48px',
      }}
      >
        <div style={{
          width: '48px',
          height: '48px',
          backgroundColor: iconBackgroundColor,
          borderRadius: '1000px',
          padding: '12px 12px 12px 12px',
          marginBottom: '24px',
        }}
        >
          <FontAwesomeIcon icon={icon} style={{ width: '24px', height: '24px', color: iconColor }} />
        </div>
        <h4 style={{
          fontWeight: '600', fontSize: '20px', color: theme.character.title, textAlign: 'center',
        }}
        >
          {formattedMessageTitle}
        </h4>
        <span style={{
          display: 'block', fontSize: '14px', color: theme.character.secondary, textAlign: 'center',
        }}
        >
          {FormattedMessageDescriptions}
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <hr style={{ width: '100%', border: `1px solid ${theme.neutralColor[2]}`, margin: '0' }} />
        {children}
      </div>
    </Modal>
  )
}

export default CustomizableModal

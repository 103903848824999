import React from 'react'
import {
  Input,
  Switch,
  Tooltip,
  Icon,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'

import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

const HiddenCustomShop = (props) => {
  try {
    const {
      newCustom, setNewCustom, newHideReassurance, setNewHideReassurance,
    } = props
    const { font } = newCustom
    const intl = useIntl()
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage
            id='shop.en'
            defaultMessage='Shop'
          />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='hiddenCustom.font'
                defaultMessage='Police du shop'
              />
            </div>
            <Tooltip
              title={(
                <>
                  <FormattedMessage
                    id='custom.fontInfo'
                    defaultMessage='Veuillez entrer un nom de police issu de Google Fonts :'
                  />
                  <a
                    href=' https://fonts.google.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: 'white' }}
                  >
                    {' '}
                    <Icon type='link' />
                  </a>
                </>
              )}
              placement='bottom'
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'custom.font',
                  defaultMessage: 'Police...',
                })}
                value={font}
                onChange={(e) => setNewCustom({ ...newCustom, font: e.target.value })}
              />
            </Tooltip>
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.reassurances'
                defaultMessage='Masquer les assurances'
              />
            </div>
            <Switch
              checked={newHideReassurance}
              onChange={(value) => setNewHideReassurance(value)}
            />
          </div>
          <div style={columnStyle} />
          <div style={columnStyle} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecule/HiddenCustomShop error:', e)
    return null
  }
}

export default injectIntl(HiddenCustomShop)

/* eslint-disable camelcase */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { stockRating as rateStock } from '@balibart/stocks'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import day from 'dayjs'
import {
  flatten,
  uniq,
} from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'
import { getPrice } from '../../helpers/getPrice'
import { getEntityId } from '../../helpers/entities'
import {
  ProductList,
  Loader,
  ProductOrdering,
} from '../../components'
import {
  fromProducts,
  fromOrders,
  fromCart,
  fromShops,
  fromBaseProducts,
  fromUsers,
  fromMembers,
  fromEntities,
  fromStocks,
  fromLocale,
} from '../../store/selectors'
import { MODIFY } from '../../store/products/actions'
import { ADD, EMPTY } from '../../store/cart/actions'
import Placeholder from '../../components/organisms/Placeholder'
import { getEntityStocks } from '../../helpers/stocks'
import { isOOS } from '../../helpers/products'
import { isAShopOwner } from '../../helpers/users'

const { isAdmin } = fromUsers

/**
   * getProducts manages the sorting and filtering of products
   * when in listmode (in table mode, antd does it)
   * @return {Array} an arry of products properly filtered and sorted
   */

const getProducts = (props) => {
  const {
    filters: {
      name,
      category,
      subCategory,
      sort,
      status,
      entityId,
      stocked = null,
    },
    allProducts,
    allStocks,
    entities,
  } = props

  let products = allProducts

  if (entityId || entities.length === 1) {
    const id = entityId || entities[0]._id
    const stocksProductsIds = getEntityStocks(allStocks, id)?.map((s) => s.productId)?.filter(Boolean)
    products = products.filter((p) => stocksProductsIds?.includes(p._id) || p.stocked === false)
  } if (stocked) {
    if (stocked === 'stocked') products = products.filter((p) => p?.stocked)
    if (stocked === 'onDemand') products = products.filter((p) => p?.stocked === false)
  } if (name) {
    products = products.filter((p) => p?.name?.toLowerCase().includes(name.toLowerCase()))
  } if (category) {
    products = products.filter((p) => p?.baseProduct?.category?.fr[0] === category)
  } if (subCategory) {
    products = products.filter((p) => p?.baseProduct?.subCategory?.fr === subCategory)
  }
  if (sort) {
    const {
      date,
      stock,
    } = sort
    // NOTE we can only have one sorter at a time
    if (date) {
      products = products.sort(({ createdAt: a }, { createdAt: b }) => {
        const modifier = date === 'ascend' ? -1 : 1
        return day(a).isBefore(day(b)) ? modifier : -modifier
      })
    } else if (stock) {
      // NOTE we only want to see non On Demand products
      products = products.filter((p) => p.stockRating >= 0).sort(({ stockRating: a }, { stockRating: b }) => {
        if (a === b) return 0
        const modifier = stock === 'ascend' ? 1 : -1
        return a < b ? modifier : -modifier
      })
    }
  } if (status && status !== 'all') {
    if (status === 'Inactive') {
      products = products.filter((p) => p.status === status || p.status === 'Locked')
    } else {
      products = products.filter((p) => p.status === status)
    }
  }
  return products
}

const ProductsContainer = (props) => {
  const {
    history,
    allProducts,
    loading,
    error,
    // lang = 'fr',
  } = props

  // NOTE Border cases
  if (error) return <div> Error placeholder </div>
  if (loading && !allProducts.length) return <Loader />
  if (!allProducts.length) {
    return (
      <Placeholder
        style={{
          position: 'none',
          width: '100%',
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        innerStyle={{ width: 'auto' }}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px',
        }}
        >
          <FormattedMessage
            id='products.placeholder.donation'
            defaultMessage='Vous pouvez néanmoins créer une campagne et proposer à vos destinataire de faire un don à une association.'
          />
          <Button
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: '500',
              fontSize: '14px',
              height: '40px',
              gap: '5px',
            }}
            type='primary'
            onClick={() => history.push('/campaign/create')}
          >
            <FormattedMessage
              id='createCampaign.donation'
              defaultMessage='Créer une campagne de don'
            />
          </Button>
        </div>
      </Placeholder>
    )
  }

  // NOTE Normal case
  const products = getProducts(props)

  const {
    view,
    filtering,
    selected,
    changeSelection,
    setSelectedProducts,
    cartContent,
    cartPrice,
    addToCart,
    toggleCart,
    customizeProduct,
    shop,
    allBaseProducts,
    filters,
    user,
    allStocks,
    entities,
    emptyCart,
    locale = 'fr',
  } = props

  const [ordering, setOrdering] = useState()
  const { entityId } = getEntityId(user, entities, shop)

  return (
    <div className='ProductsList'>
      <ProductList
        view={view}
        entityId={entityId}
        products={products}
        selected={selected}
        setProductOrdering={(ord) => setOrdering(ord)}
        allProducts={allProducts}
        filtering={filtering}
        filters={filters}
        allStocks={allStocks}
        entities={entities}
        history={history}
        changeSelection={(id) => changeSelection(id)}
        setSelectedProducts={setSelectedProducts}
      />
      <ProductOrdering
        user={user}
        shop={shop}
        entities={entities}
        visible={!!ordering}
        cartContent={cartContent}
        cartPrice={cartPrice}
        add={addToCart}
        toggleCart={toggleCart}
        customizeProduct={customizeProduct}
        allBaseProducts={allBaseProducts}
        product={ordering ? allProducts.find((p) => String(p._id) === ordering) : {}}
        stocks={allStocks}
        locale={locale}
        onOk={() => {
          setOrdering(false)
          return <Redirect to='/checkout' />
        }}
        onCancel={() => setOrdering(false)}
        emptyCart={emptyCart}
        allCollections={uniq(flatten(allProducts.map((p) => p.collections)).filter(Boolean))}
      />
    </div>
  )
}

export default connect(
  (state) => ({
    cartContent: fromCart.getContent(state),
    entities: fromEntities.getEntities(state),
    membersGroups: fromShops.shopMembersGroups(state),
    cartPrice: fromCart.getPrice(state),
    members: fromMembers.getMembers(state),
    allProducts: (() => {
      // on récupère la boutique depuis redux
      const shop = fromShops.getShop(state)
      // on récupère l'utilisareur depuis redux
      const user = fromUsers.getUser(state)
      // on récupère les entités depuis redux
      const entities = fromEntities.getEntities(state)
      // on détermine l'entité' auquel l'utilisateur à accès
      const { entityId } = getEntityId(user, entities, shop)
      const isAPanopliAdmin = isAdmin(state)
      return fromProducts.allProducts(state).map((product) => {
        // on récupère les stocks associés au produit et ) à l'ennti
        const relatedStocks = fromStocks.getSpecificStock(state, product._id, entityId)
        // on vérifie si on veut l'afficher sur le listing
        // on l'affiche uniquement si
        // - le produit n'est pas stocké ( donc onDemand )
        // - le produit n'est pas complètement out of stock
        // - si l'utilisateur n'est pas le super admin de la boutique
        // - si l'utilisateur est un admin panopli
        // sinon on ne l'affiche pas
        const displayed = !product.stocked || !isOOS(relatedStocks) || isAShopOwner(user, shop) || isAPanopliAdmin
        if (!displayed) {
          // on renvoi false qui serra filtré par le .filter(Boolean)
          return false
        }

        const price = getPrice([], product)
        return ({
          ...product,
          ordered: fromOrders.getOrderCount(state, String(product._id)),
          unitPrice: price.value,
          stockRating: rateStock(product.stock, entityId),
        })
      }).filter(Boolean).reverse()
    })(),
    loading: fromProducts.loading(state),
    error: fromProducts.error(state),
    shop: fromShops.getShop(state),
    user: fromUsers.getUser(state),
    allBaseProducts: fromBaseProducts.getBPs(state),
    allStocks: fromStocks.getStocks(state),
    locale: fromLocale.getLocale(state),
  }),
  (dispatch) => bindActionCreators({
    modifyProduct: (product_id, modifications, fileHashes, files) => ({
      type: MODIFY,
      payload: {
        product_id,
        modifications,
        fileHashes,
        files,
      },
    }),
    addToCart: (items) => ({
      type: ADD,
      payload: items,
    }),
    emptyCart: () => ({
      type: EMPTY,
    }),
  }, dispatch),
)(ProductsContainer)

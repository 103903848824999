import React from 'react'
import { Radio } from 'antd'

export default ({
  onChange,
  style,
  defaultValue,
}) => (
  <Radio.Group
    defaultValue={defaultValue}
    buttonStyle='solid'
    onChange={onChange}
    style={{ display: 'flex', ...style }}
  >
    <Radio.Button value='fr'><span role='img' aria-label='fr-flag'>🇫🇷</span></Radio.Button>
    <Radio.Button value='en'><span role='img' aria-label='en-flag'>🇬🇧</span></Radio.Button>
  </Radio.Group>
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Button } from 'antd'
import { FormattedMessage } from 'react-intl'

const { Footer } = Layout

export default withRouter(({
  history,
  logout,
}) => (
  <Footer style={{ textAlign: 'center' }}>
    <Button
      type='link'
      onClick={() => {
        window.scrollTo(0, 0)
        history.push('/tos')
      }}
    >
      <FormattedMessage id='termsOfServices' defaultMessage="Conditions générales d'utilisation" />
    </Button>
    <Button
      type='link'
      onClick={() => {
        window.scrollTo(0, 0)
        history.push('/legal-notice')
      }}
    >
      <FormattedMessage id='termsOfSales' defaultMessage='Conditions générales de vente' />
    </Button>
    <Button
      type='link'
      onClick={() => {
        window.scrollTo(0, 0)
        history.push('/privacy-policy')
      }}
    >
      <FormattedMessage id='privacyPolicy' defaultMessage='Politique de confidentialité' />
    </Button>
    <Button
      type='link'
      onClick={() => {
        logout()
      }}
    >
      Logout
    </Button>
    Panopli ©2020
  </Footer>
))

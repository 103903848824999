import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Values from 'values.js'
import { FormattedMessage } from 'react-intl'
import { Switch } from 'antd'
import theme from '../../../config/theme'

const MessagePreview = (props) => {
  const {
    isMobileFocused, // Required
    campaign, // Required
    onChangeIsMessageEnabled,
  } = props

  const mainColor = new Values(campaign?.theme?.mainColor)
  const colors = {
    main500: mainColor.hexString(),
  }

  return (
    <div
      style={{
        borderRadius: '8px',
        boxShadow: '0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)',
        border: `1px solid  ${theme.neutralColor[2]}`,
        width: isMobileFocused ? '210px' : '516px',
        height: isMobileFocused ? '456px' : '322px',
        overflow: 'hidden',
        ...(!campaign?.message?.isEnabled && { position: 'relative' }),
      }}
    >
      {onChangeIsMessageEnabled && !campaign?.message?.isEnabled && (
        <>
          <div
            style={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              opacity: '0.7',
              backgroundColor: theme.white,
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              zIndex: '1',
            }}
          />
          <Switch
            onChange={onChangeIsMessageEnabled}
            defaultChecked={campaign?.message?.isEnabled}
            style={{
              ...(!campaign?.message?.isEnabled && { backgroundColor: theme.neutralColor[6] }),
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              zIndex: '2',
            }}
          />
          <span
            style={{
              position: 'absolute',
              left: '50%',
              top: isMobileFocused ? '70%' : '75%',
              transform: 'translate(-50%,-50%)',
              zIndex: '2',
              color: theme.white,
              textAlign: 'center',
              fontSize: '13px',
            }}
          >
            <FormattedMessage
              id='campaigns.create.step.message.preview.enable'
              defaultMessage="Activer l'option de message"
            />
          </span>
        </>
      )}
      <div style={{
        ...(!campaign?.message?.isEnabled && { filter: 'blur(4px)' }),
        width: '100%',
        height: '100%',
      }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: isMobileFocused ? '6px 8px 6px 8px' : '6px 16px 6px 16px',
          }}
        >
          <img src={campaign?.theme?.logo} alt='' style={{ maxHeight: '28px', width: 'auto' }} />
          <div
            style={{
              backgroundColor: colors?.main500,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '28px',
              height: '28px',
            }}
          >
            <FontAwesomeIcon icon={faBars} style={{ color: theme.white }} />
          </div>
        </div>
        <hr style={{
          backgroundColor: colors.main500, margin: '0px', height: '1px', border: '0',
        }}
        />
        <div
          style={{
            backgroundColor: colors?.main500,
            padding: '8px',
            width: '100%',
            height: '100%',
          }}
        >
          <h1
            style={{
              color: theme.white,
              lineHeight: 'normal',
              padding: isMobileFocused ? '16px 16px 16px 16px' : '64px 32px 32px 32px',
              textAlign: 'center',
              wordBreak: 'break-word',
            }}
          >
            {campaign?.message?.title ? campaign?.message?.title : (
              <FormattedMessage
                id='campaigns.create.step.message.preview.title.placeholder'
                defaultMessage='Un cadeau pour vous remercier de votre confiance !'
              />
            )}
          </h1>
          <span
            style={{
              display: 'block',
              color: theme.white,
              textAlign: 'center',
              fontSize: '10px',
              padding: 'auto auto auto 10px',
              ...(!isMobileFocused && { marginTop: '30px' }),
              wordBreak: 'break-word',
            }}
          >
            {campaign?.message?.content ? campaign?.message?.content : (
              <FormattedMessage
                id='campaigns.create.step.message.message.placeholder'
                defaultMessage='Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              />
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

export default MessagePreview

import config from '../../config'

const { apiURL } = config

export default function fetchToken(callback) {
  try {
    fetch(`${apiURL}/colissimo`, {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    })
      .then((result) => result.json())
      .then((result) => {
        if (callback) {
          callback(result.data)
        }
      })
      .catch((e) => console.log('Error in colissimo Token Fetch', e))
  } catch (e) {
    console.log('error fetchtoken', e)
  }
}

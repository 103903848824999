import { uniqWith, isEqual } from 'lodash'

const hasIncompatibilities = (variations) => {
  try {
    const test = variations
      && Object.values(variations)
      && Object.values(variations).length > 1
      && Object.values(variations).some((va) => (va.comboExclusion && va.comboExclusion.length)
        || (va.comboSpecific && va.comboSpecific.length))
    return test
  } catch (e) {
    console.log('hasIncompatibilities', e)
    return false
  }
}

const canMatch = (variations) => {
  try {
    const { size, color } = variations

    if (size && color) {
      if (
        size.comboExclusion
        && size.comboExclusion.length > 0
        && size.comboExclusion.some((v) => v === color.name || v === color.reference)
      ) {
        return false
      }
      if (
        size.comboSpecific
        && size.comboSpecific.length > 0
        && !size.comboSpecific.some((v) => v === color.name || v === color.reference)
      ) {
        return false
      }
      if (
        color.comboExclusion
        && color.comboExclusion.length > 0
        && color.comboExclusion.some((v) => v === size.name || v === size.reference)
      ) {
        return false
      }
      if (
        color.comboSpecific
        && color.comboSpecific.length > 0
        && !color.comboSpecific.some((v) => v === size.name || v === size.reference)
      ) {
        return false
      }
    }
    return true
  } catch (error) {
    console.log('canMatch', error)
    return false
  }
}

const checkCompatibility = (_variations) => {
  try {
    let variations = {}
    // NOTE in case of unexisting variation
    if (typeof _variations.size === 'undefined') {
      variations = {
        color: _variations.color,
        size: { type: 'size', reference: -1, name: 'dull size' },
      }
    } else {
      variations = _variations
    }
    if (typeof _variations.color === 'undefined') {
      variations = {
        color: { type: 'color', reference: -1, name: 'dull color' },
        size: variations.color,
      }
    } else {
      variations = _variations
    }
    if (hasIncompatibilities(variations)) {
      return canMatch(variations)
    }
    return true
  } catch (e) {
    console.log('checkCompatibility error', { e, _variations })
    return false
  }
}

const isAvailable = (product, size, color, stocks, entityId) => {
  try {
    // check stocks
    if (
      product.baseProduct
      && product.baseProduct.variations
      && product.baseProduct.variations.length > 0
    ) {
      // Check that color exists and is available

      const match = product.baseProduct.variations.find((v) => v.reference === size.reference)

      if (!match) {
        return false
      }
      if (match.oosFlag) {
        return false
      }

      if (product.baseProduct.oosFlag) {
        return false
      }
    }
    if (product.stocked) {
      const concernedStock = stocks?.find((stock) => {
        const colorCondition = stock?.variationCombo?.find((v) => v.type === 'color' && v.reference === color.reference)
        const sizeCondition = stock?.variationCombo?.find((v) => v.type === 'size' && v.reference === size.reference)

        return colorCondition && sizeCondition && (stock.entityId ? stock.entityId === entityId : true) && stock.productId === String(product._id)
      })
      return !!(concernedStock)
    }
    // if (product.stock && product.stock.length
    //   && ((product.stock.filter((s) => s.variationCombo[0] === size.name && !s.qty).length
    //     || product.stock.filter((s) => s.variationCombo[1] === size.name && !s.qty).length)
    //     && (product.stock.filter((s) => s.variationCombo[0] === color.name && !s.qty).length
    //       || product.stock.filter((s) => s.variationCombo[1] === color.name && !s.qty).length))) {
    //   return false
    // }
    if (
      !checkCompatibility({
        size,
        color,
      })
    ) {
      return false
    }

    return true
  } catch (error) {
    console.log('productVariations - isAvailable', error)
    return false
  }
}

export default (product, type) => {
  try {
    if (product.variations.constructor === Array) {
      const result = uniqWith(product.baseProduct.variations
        .filter((baseProductVariation) => (
          product.variations
            .filter(type ? (v) => v.type === type : Boolean)
            .find((v) => v.type === baseProductVariation.type
              && v.reference === baseProductVariation.reference)
        )), isEqual)
      return result
    }
    return []
  } catch (error) {
    console.log('Cannot get product Variations from BP', error)
    return []
  }
}

// eslint-disable-next-line arrow-body-style
const checkedSizes = (productVariationSizes, cartContent, color, product, stocks, entityId) => {
  return productVariationSizes?.map((productVariationSize) => {
    const findInCart = cartContent.find((productInCart) => {
      const { variations } = productInCart
      return color.reference === variations.color.reference
        && productVariationSize.reference === variations.size.reference
        && String(product._id) === String(productInCart._id)
    })
    return {
      ...productVariationSize,
      available: color ? isAvailable(product, productVariationSize, color, stocks, entityId) : true,
      quantityInCart: (findInCart && findInCart.quantity) || 0,
    }
  })
}

export { isAvailable, checkedSizes }

import React from 'react'
import { Button } from 'antd'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import theme from '../../../config/theme'
import { CustomizableModal } from '../..'

const ExitCampaignCreationModal = (props) => {
  const {
    isModalVisible, // Required
    handleSaveAndLeave, // Required
    handleLeave, // Required
    isCreateFlow = true,
    onCancel, // Required
  } = props

  return (
    <CustomizableModal
      isModalVisible={isModalVisible}
      iconBackgroundColor={theme.warningColor[1]}
      icon={faTriangleExclamation}
      iconColor={theme.warningColor[6]}
      formattedMessageTitle={
        <FormattedMessage id='campaigns.create.step.modals.leave.title' defaultMessage='Souhaitez-vous vraiment quitter ?' />
      }
      FormattedMessageDescriptions={(
        <FormattedMessage
          id='campaigns.create.step.modals.leave.message'
          defaultMessage='Toutes les modifications apportées ne seront pas sauvegardées'
        />
      )}
      onCancel={onCancel}
    >
      <Button
        style={{ width: '100%', display: 'block', margin: '24px 0 12px 0' }}
        key='back'
        onClick={handleLeave}
        type='danger'
      >
        <FormattedMessage id='campaigns.create.step.modals.leave.button.quit' defaultMessage='Quitter sans sauvegarder' />
      </Button>
      <Button
        style={{ width: '100%', display: 'block', margin: '0' }}
        key='campaigns'
        onClick={handleSaveAndLeave}
      >
        <FormattedMessage
          id={isCreateFlow ? 'campaigns.create.step.modals.leave.button.saveAndQuit' : 'campaigns.edit.step.modals.leave.button.saveAndQuit'}
          defaultMessage={isCreateFlow ? 'Sauvegarder le brouillon et quitter' : 'Enregistrer les modifications et quitter'}
        />
      </Button>
    </CustomizableModal>
  )
}

export default ExitCampaignCreationModal

import React, { useState } from 'react'
import {
  Drawer,
  Input,
  InputNumber,
  Select,
  Button,
  Alert,
  Switch,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { modifyTechnicsIdPanopli, modifyFamiliesIdPanopli } from '../../../helpers/baseProducts/catalog'
import notEqualToGlobal from '../../../helpers/baseProducts/carbonImpact'
import { cleanId, checkIdPanopliModification } from '../../../helpers/baseProducts/creation'
import { LangSwitch } from '../../atoms'

const rowStyle = {
  display: 'flex',
  color: 'rgba(0, 0, 0, 0.85)',
  marginBottom: '30px',
}

const columnStyle = {
  display: 'flex',
  flex: '1 2',
  flexDirection: 'column',
  marginRight: '10px',
}

const titleStyle = {
  fontWeight: 'bold',
  flex: '1 1',
  marginBottom: '10px',
}

const { Option } = Select

const CatalogBPEditionDrawer = (props) => {
  try {
    const {
      visible,
      onClose,
      baseProduct,
      modifyBP,
      DBBaseProducts,
      getAllCategories,
      getAllSubCategories,
      getAllOrigins,
      getAllStatus,
      // allTechnics,
      draftProducts,
    } = props

    const {
      name,
      category,
      subCategory,
      caracteristics = null,
      status,
      idPanopli,
      technics,
      families,
      carbonImpact = {},
    } = baseProduct

    const {
      global = 0,
      manufacturing = 0,
      marking = 0,
      transport = 0,
      packaging = 0,
      endOfLife = 0,
    } = carbonImpact

    const initialState = {
      name, category, subCategory, caracteristics, status, idPanopli, technics, families, carbonImpact,
    }

    const [language, setLanguage] = useState('fr')
    const [bpState, setBpState] = useState(initialState)
    const [inputs, setInputs] = useState({})
    const [validated, setValidated] = useState(true)

    const hasBeenModified = JSON.stringify(bpState) !== JSON.stringify(initialState)

    const getOption = (property, add = false) => (
      <Option value={property} key={Math.random()}>
        {add ? 'New: ' : null}
        {property}
      </Option>
    )

    return (
      <Drawer
        visible={visible}
        onClose={onClose}
        width='40%'
        destroyOnClose
        title={(
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '95%',
          }}
          >
            {name[language] ? name[language] : <FormattedMessage id='catalogBPDrawer.notTranslated' defaultMessage='*Pas traduit*' />}
            <LangSwitch
              defaultValue={language}
              onChange={(e) => {
                setLanguage(e.target.value)
              }}
              style={{ marginRight: '10px' }}
            />
          </div>
        )}
      >
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage id='productName' defaultMessage='Nom du produit' />
            </div>
            <Input
              value={bpState.name[language]}
              placeholder={language === 'fr' ? 'Nom du produit...' : 'Product Name...'}
              onChange={(e) => (language === 'fr'
                ? setBpState({ ...bpState, name: { ...name, fr: e.target.value } })
                : setBpState({ ...bpState, name: { ...name, en: e.target.value } }))}
            />
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              {language === 'fr' ? 'Statut(s)' : 'Status(es)'}
            </div>
            <Select
              id='modifyStatuses'
              mode='multiple'
              allowClear
              showArrow
              defaultValue={status}
              placeholder={language === 'fr' ? 'Statut(s)' : 'Status(es)'}
              onChange={(value) => setBpState({
                ...bpState,
                status: value,
              })}
              onSearch={(value) => {
                const trimmedValue = value?.trim() !== '' ? value : null
                setInputs({ ...inputs, status: trimmedValue })
              }}
            >
              {inputs.status && !status.includes(inputs.status)
                ? getOption(inputs.status, true)
                : null}
              {getAllStatus(DBBaseProducts).map((stat) => getOption(stat))}
            </Select>
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              {language === 'fr' ? 'Catégorie(s)' : 'Category(ies)'}
            </div>
            <Select
              id='modifyCategories'
              mode='multiple'
              allowClear
              showArrow
              value={bpState.category[language]}
              placeholder={language === 'fr' ? 'Catégorie(s)...' : 'Category(ies)...'}
              onChange={(value) => (language === 'fr'
                ? setBpState({
                  ...bpState,
                  category: { ...category, fr: value },
                })
                : setBpState({
                  ...bpState,
                  category: { ...category, en: value },
                }))}
              onSearch={(value) => {
                const trimmedValue = value?.trim() !== '' ? value : null
                setInputs({ ...inputs, category: trimmedValue })
              }}
            >
              {inputs.category && !category.fr.includes(inputs.category)
                ? getOption(inputs.category, true)
                : null}
              {getAllCategories(DBBaseProducts).map((cat) => getOption(cat))}
            </Select>
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />
            </div>
            <Select
              id='modifySubCategory'
              allowClear
              showSearch
              onChange={(value) => (language === 'fr'
                ? setBpState({
                  ...bpState,
                  subCategory: { ...subCategory, fr: value },
                })
                : setBpState({
                  ...bpState,
                  subCategory: { ...subCategory, en: value },
                }))}
              onSearch={(value) => {
                const trimmedValue = value?.trim() !== '' ? value : null
                setInputs({ ...inputs, subCategory: trimmedValue })
              }}
              value={bpState.subCategory[language]}
              placeholder={(
                <span>
                  <FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />
                  ...
                </span>
              )}
            >
              {inputs.subCategory && !subCategory.fr.includes(inputs.subCategory)
                ? getOption(inputs.subCategory, true)
                : null}
              {getAllSubCategories(DBBaseProducts).map(
                (subCat) => getOption(subCat),
              )}
            </Select>
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              {language === 'fr' ? 'Origine' : 'Origin'}
            </div>
            <Select
              id='modifyOrigin'
              allowClear
              showSearch
              onChange={(value) => setBpState({
                ...bpState,
                caracteristics: { ...caracteristics, originProduct: value },
              })}
              onSearch={(value) => {
                const trimmedValue = value?.trim() !== '' ? value : null
                setInputs({ ...inputs, originProduct: trimmedValue })
              }}
              defaultValue={caracteristics?.originProduct}
              placeholder={language === 'fr' ? 'Origine...' : 'Origin...'}
            >
              {inputs.originProduct && !caracteristics?.originProduct?.includes(inputs.originProduct)
                ? getOption(inputs.originProduct, true)
                : null}
              {getAllOrigins(DBBaseProducts).map(
                (ori) => getOption(ori),
              )}
            </Select>
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              Id Panopli
            </div>
            <Input
              value={bpState.idPanopli}
              placeholder='Id Panopli...'
              onChange={(e) => {
                setValidated(!checkIdPanopliModification(e.target.value, baseProduct, DBBaseProducts, draftProducts))
                const newTechnics = modifyTechnicsIdPanopli(baseProduct, e.target.value)
                const newFamilies = modifyFamiliesIdPanopli(baseProduct, e.target.value)
                setBpState({
                  ...bpState,
                  idPanopli: cleanId(e.target.value),
                  technics: newTechnics?.length ? newTechnics : technics,
                  families: newFamilies?.length ? newFamilies : families,
                })
              }}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              Rupture de stock (OOS)
            </div>
            <div style={{ paddingBottom: '5px' }}>
              <Switch
                checked={bpState.caracteristics?.OOS}
                onClick={(value) => {
                  setBpState({
                    ...bpState,
                    caracteristics: { ...caracteristics, OOS: value },
                  })
                }}
              />
            </div>
          </div>
        </div>
        {notEqualToGlobal(bpState.carbonImpact)}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '10px',
            color: 'rgba(0, 0, 0, 0.85)',
            margin: '10px 0',
          }}
        >
          <div>
            <div style={titleStyle}>Impact global</div>
            <InputNumber
              defaultValue={global}
              onChange={(value) => {
                setBpState({
                  ...bpState,
                  carbonImpact: {
                    ...bpState.carbonImpact,
                    global: value,
                  },
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <div>
              <div style={titleStyle}>Fabrication</div>
              <InputNumber
                defaultValue={manufacturing}
                onChange={(value) => {
                  setBpState({
                    ...bpState,
                    carbonImpact: {
                      ...bpState.carbonImpact,
                      manufacturing: value,
                    },
                  })
                }}
              />
            </div>
            <div>
              <div style={titleStyle}>Marquage</div>
              <InputNumber
                defaultValue={marking}
                onChange={(value) => {
                  setBpState({
                    ...bpState,
                    carbonImpact: {
                      ...bpState.carbonImpact,
                      marking: value,
                    },
                  })
                }}
              />
            </div>
            <div>
              <div style={titleStyle}>Transport</div>
              <InputNumber
                defaultValue={transport}
                onChange={(value) => {
                  setBpState({
                    ...bpState,
                    carbonImpact: {
                      ...bpState.carbonImpact,
                      transport: value,
                    },
                  })
                }}
              />
            </div>
            <div>
              <div style={titleStyle}>Packaging</div>
              <InputNumber
                defaultValue={packaging}
                onChange={(value) => {
                  setBpState({
                    ...bpState,
                    carbonImpact: {
                      ...bpState.carbonImpact,
                      packaging: value,
                    },
                  })
                }}
              />
            </div>
            <div>
              <div style={titleStyle}>Fin de vie</div>
              <InputNumber
                defaultValue={endOfLife}
                onChange={(value) => {
                  setBpState({
                    ...bpState,
                    carbonImpact: {
                      ...bpState.carbonImpact,
                      endOfLife: value,
                    },
                  })
                }}
              />
            </div>
          </div>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          {validated ? null : (
            <Alert
              message={(
                <FormattedMessage
                  id='catalogBPEditionDrawer.alertMessage'
                  defaultMessage='Attention : cette idPanopli est déjà utilisée...'
                />
              )}
              type='warning'
              closable
              showIcon
              style={{ marginBottom: '10px' }}
            />
          )}
          {hasBeenModified && validated ? (
            <Button
              onClick={() => {
                modifyBP({ ...baseProduct, ...bpState })
                onClose()
              }}
            >
              <FormattedMessage id='modify' defaultMessage='Modifier' />
            </Button>
          ) : null }
        </div>
      </Drawer>
    )
  } catch (e) {
    console.log('CatalogStatusDrawer', e)
    return null
  }
}

export default CatalogBPEditionDrawer

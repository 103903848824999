import React from 'react'
import FormattedButton from '../FormattedButton'
import theme from '../../../config/theme'

const ContinueStepButton = (props) => {
  const {
    messageId = 'continue',
    defaultMessage = 'Continuer',
    onClickFunction,
    disabled = false,
    style = {
      width: '100%',
      marginTop: '32px',
      padding: '9px 0 9px 0',
      height: 'auto',
      backgroundColor: disabled ? theme.white : null,
      fontWeight: 500,
    },
    type,
  } = props

  return (
    <FormattedButton
      disabled={disabled}
      style={style}
      messageId={messageId}
      defaultMessage={defaultMessage}
      onClickFunction={onClickFunction}
      textStyle={{ fontWeight: 500, fontSize: '14px' }}
      type={type}
    />
  )
}

export default ContinueStepButton

import moment from 'moment'
import { trimProducts } from '../trim'
import config from '../../config'

/**
 * Check customizations exist
 * @return {Boolean} does it exist?
 * @param {Object} item a product
 */
const hasCustomizations = (item) => item.clientCustomization && item.clientCustomization.length

/**
 * We try to find a field that has the same id but different value, if we can't they are not the same
 * @return {Boolean} is tthe same?
 * @param {Object} itemA a customized product
 * @param {Object} itemB another customized product
 */
export const sameCustomizations = (itemA, itemB) => {
  try {
    // NOTE both have no custo -> true
    if (!hasCustomizations(itemA) && !hasCustomizations(itemB)) return true
    // NOTE they don't both have custo -> false
    if (!(hasCustomizations(itemA) && hasCustomizations(itemB))) return false
    // NOTE there shouldn't be a field with different custo
    const result = !itemA.clientCustomization.find((field) => itemB.clientCustomization
      .find((f) => f.fieldId === field.fieldId && f.value !== field.value))
    return result
  } catch (error) {
    return false
  }
}

export const isEqual = (product, item) => (product._id === item._id
      && product.variations.color.reference === item.variations.color.reference
      && product.variations.size.reference === item.variations.size.reference
      && sameCustomizations(item, product))

export const formatCart = (cart, form, shop) => {
  try {
    const { values } = form.checkout
    const newCart = {
      ...cart,
      seller: shop.data._id,
      client: {
        email: values.client.email,
        phone: values.client.phone,
        firstName: values.client.firstName,
        lastName: values.client.lastName,
      },
      address: {
        ...values.client,
      },
      content: trimProducts(cart.content),
    }
    // TODO TRIM PRODUCTS
    return newCart
  } catch (e) {
    console.log('cartFromState', { e, form })
    return cart
  }
}

export const checkCampaign = (campaign) => {
  try {
    const today = moment()
    if (campaign
      && campaign.status === 'Active'
      && campaign.type === 'campaign'
      && moment(campaign.limits.dates.startsAt < moment(today))
    ) {
      return campaign.benefits.type
    }
    return false
  } catch (e) {
    console.log('error checkCampaign', e)
    return false
  }
}

export const restToPay = (cart, campaign) => {
  try {
    const { amount } = campaign.limits
    const { total } = cart.products_pricing
    return amount - total
  } catch (error) {
    console.log('restToPay', error)
    return false
  }
}

export const submitPromo = (cart) => fetch(`${config.connections[config.env]}/discount`, {
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json',
  },

  cache: 'default',
  body: JSON.stringify(cart),
})
  .then((result) => result.json())
  .then((json) => json)
  .catch((e) => {
    console.log('error in subMitPromo', e)
  })

export const trimProductsForOrder = (products) => {
  try {
  // TODO getPrice helpers
    return products.map((item) => ({
      id: item._id,
      variations: item.variations,
      quantity: item.quantity,
      name: item.name,
      type: item.baseProduct.subCategory,
      unitPrice: 0,
      payw: parseFloat(item.payw || 0),
      clientCustomization: item.clientCustomization,
      preOrder: item.preOrder,
      preOrderInfos: item.preOrderInfos,
    }))
  } catch (e) {
    console.log('Eror in trimProductsForOrder', e)
    return products
  }
}

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { displayPrice } from '../getPrice'
import config from '../../config'

const CHARITIPS_SERVICE_CHARGE_PERCENTAGE = 0.1 // 10% du don
const PANOPLI_SERVICE_CHARGE_PERCENTAGE = 0.05 // 5% du don

/**
 * Permet de vérifier que la valeur entrée en input est un nombre entier
 * @param {string} value - La valeur entrée en input
 * @returns {boolean} Si la valeur est un nombre entier
 */
const isValidInputEntry = (value) => {
  const regex = /^[0-9]*$/
  return regex.test(value) && value?.split().some((character) => character !== '0')
}

/**
 * Permet de transformer un nombre de centimes en un montant en unités monétaires
 * @param {number} amount - Le montant en centimes
 * @returns {number | null} Le montant en unités monétaires
 */
const trimCentsToEuros = (amount) => (amount && (amount / 100)) || null

/**
 * Permet de convertir un montant en unités monétaires en un nombre de centimes
 * @param {number} amount - Le montant (en unités monétaires)
 * @returns {number | null} Le montant en centimes
 */
const trimEurosToCents = (amount) => (amount && (parseInt(amount, 10) * 100)) || null

/**
 * Permet de connaître le montant des frais de service de l'alternative don
 * @param {number} amount - Le montant du don (en unités monétaires)
 * @returns {string | null} Le montant des frais de service (en unités monétaires)
 */
const getDonationServiceCharge = (amount) => (amount && displayPrice(
  amount * (CHARITIPS_SERVICE_CHARGE_PERCENTAGE + PANOPLI_SERVICE_CHARGE_PERCENTAGE),
)) || null

/**
 * Permet de connaître le montant total (avec frais de service) de l'alternative don
 * @param {number} amount  - Le montant du don (en unités monétaires)
 * @returns {string | null} Le montant total de l'alternative don facturé au client Panopli (en unités monétaires)
 */
const getTotalDonationCost = (amount) => (amount && displayPrice(
  parseFloat(getDonationServiceCharge(amount), 10) + parseFloat((amount), 10),
)) || null

const getTitle = (campaign) => {
  const { products, donation = {} } = campaign
  const { active } = donation
  switch (true) {
  case active:
    return (
      <FormattedMessage
        id='donation.modify.title'
        defaultMessage='Modifier le montant'
      />
    )
  case !products?.length:
    return (
      <FormattedMessage
        id='donation.propose.title'
        defaultMessage='Proposer un don'
      />
    )
  default:
    return (
      <FormattedMessage
        id='donation.exchange.title'
        defaultMessage='Échanger contre un don'
      />
    )
  }
}

const getDescription = (campaign) => {
  const { products, donation = {} } = campaign
  const { active } = donation
  if (active || !products?.length) {
    return (
      <FormattedMessage
        id='donation.drawer.description'
        defaultMessage='Votre destinataire aura la possibilité de faire un don du montant de votre choix
      à une association sur le site de notre partenaire Charitips.'
      />
    )
  }
  return (
    <FormattedMessage
      id='donation.drawer.description.exchange'
      defaultMessage="Activez l'option d'échange et définissez le montant du don que votre destinataire pourra faire
    à l'association de son choix via notre site partenaire Charitips si aucun cadeau ne lui plaît."
    />

  )
}

/**
 * Indique si le client a un compte Charitips pour pouvoir activer la feature de dons
 */
const isCharitipsClientAccountSetUp = (shop, entity) => !!(shop?.settings?.charitipsAccountId && entity?.charitipsCampaignId)

/**
 * Calls API to send a slack notification to alert a user tried to use the donation feature
 */
const sendSlackNotificationOnDonationUseAttempt = (authToken, shop, entity, user) => {
  const messagePayload = {
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: ':warning: *Un client souhaite utiliser la feature Produits Dons* :warning:',
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Utilisateur:* ${user?.email}\n*Shop:* ${shop?.settings?.name}\n*Entity:* ${entity?.name}`,
        },
      },
    ],
  }

  fetch(`${config.apiURL}/slack`, {
    method: 'POST',
    headers: { 'Content-type': 'application/json', Authorization: authToken, mode: 'cors' },
    body: JSON.stringify({
      channelId: config.slack.channels.donation_use_alert,
      messagePayload: JSON.stringify(messagePayload),
    }),
  })
}

export {
  isValidInputEntry,
  trimCentsToEuros,
  trimEurosToCents,
  getDonationServiceCharge,
  getTotalDonationCost,
  getTitle,
  getDescription,
  isCharitipsClientAccountSetUp,
  sendSlackNotificationOnDonationUseAttempt,
}

import React from 'react'
import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import { neutralColor, white } from '../../../config/theme'

const defaultContainerStyle = {
  position: 'relative',
  backgroundColor: neutralColor[1],
  borderRadius: '1000px',
  padding: 2,
  display: 'flex',
  alignItems: 'center',
}

const defaultChildrenStyle = {
  position: 'relative',
  padding: '3px 11px',
  margin: 0,
  cursor: 'pointer',
}

const defaultMessageStyle = {
  position: 'relative',
  zIndex: 1,
  fontSize: '14px',
  fontWeight: 400,
  color: neutralColor[9],
}

const defaultSelectionStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: white,
  borderRadius: '1000px',
  top: 0,
  left: 0,
}

const CustomSwitch = (props) => {
  const {
    childrens, // Required
    subpart, // Required
    setSubpart, // Required
    style,
    containerStyle,
    childrenStyle,
    messageStyle,
    selectionStyle,
  } = props

  return (
    <div
      style={{
        display: 'flex',
        placeItems: 'center',
        placeContent: 'center',
        ...style,
      }}
    >
      <div style={{ ...defaultContainerStyle, ...containerStyle }}>
        {childrens.map(({ id, defaultMessage }) => (
          <div
            key={id}
            style={{ ...defaultChildrenStyle, ...childrenStyle }}
            onClick={() => setSubpart(id)}
          >
            <span style={{ ...defaultMessageStyle, ...messageStyle }}>
              <FormattedMessage id={id} defaultMessage={defaultMessage} />
            </span>
            {id === subpart && (
              <motion.div
                style={{ ...defaultSelectionStyle, ...selectionStyle }}
                layoutId='selected'
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomSwitch

const getScore = (size) => {
  try {
    if (['6', '8', '10', '12', '14'].filter((valid) => size === valid).length === 1) {
      switch (size) {
      case '6':
        return 1
      case '8':
        return 2
      case '10':
        return 3
      case '12':
        return 4
      case '14':
        return 5
      default:
        return 1
      }
    }
    if (size.includes('X')
      && parseInt(size.split('X')[0], 10)
      && parseInt(size.split('X')[1], 10)
    ) {
      return parseInt(size.split('X')[0], 10) * parseInt(size.split('X')[1], 10)
    }
    if (['XL', 'XS', 'M', 'L', 'S', 'XXL', 'XXS', 'XXXL'].filter((valid) => size.includes(valid)).length === 0) {
      return -100
    }
    let multiplier = 0
    if (parseInt(size[0], 10)) {
      multiplier = parseInt(size[0], 10)
    } else if (size.includes('l') || size.includes('L')) {
      multiplier = 1
    } else if (size.includes('s') || size.includes('S')) {
      multiplier = -1
    }
    const count = (size.match(/X/g) || []).length
    return (count + 1) * multiplier
  } catch (e) {
    console.log('Cannot compute size score', e)
    return 0
  }
}

const sortSizes = (unsortedSizes) => {
  try {
    const result = unsortedSizes
      .map((a) => ({ ...a, name: a.name.toUpperCase() }))
      .sort((a, b) => (getScore(a.name.replace(' ANS', '')) >= getScore(b.name.replace(' ANS', '')) ? 1 : -1))
    return result
  } catch (e) {
    console.log('error sortSizes', e)
    return unsortedSizes
  }
}

export default sortSizes

import React from 'react'
import {
  put, call, takeEvery, select,
} from 'redux-saga/effects'
import { FormattedMessage } from 'react-intl'
import { notification } from 'antd'

import { fromShops, fromUsers } from '../selectors'
import * as productsActions from '../products/actions'
import * as actions from './actions'

export function* fetchStocks(api) {
  try {
    const authorization = yield select(fromUsers.getAuth)
    const shopId = yield select(fromShops.getId)
    const { data } = yield call([api, api.get], '/stock', {
      headers: {
        authorization,
        shop: shopId,
      },
    })
    yield put({
      type: actions.RECEIVED_STOCK,
      payload: {
        data,
      },
    })
    return 1
  } catch (e) {
    console.log('FETCH STOCKS CATCHED', e)
    return -1
  }
}

export function* modifyStock(api, action) {
  try {
    const {
      target,
      modifications,
    } = action.payload
    const authorization = yield select(fromUsers.getAuth)
    yield call([api, api.put], '/stock', {
      target,
      ...modifications,
    }, {
      headers: {
        authorization,
      },
    })
    return yield put({
      type: actions.MODIFIED_STOCK,
      payload: {
        target,
        modifications,
      },
    })
  } catch (error) {
    console.log('*modifyStock', { error })
    return false
  }
}

export function* createStock(api, { payload }) {
  try {
    const {
      target, // id du produit
      stock, // tout le stock de ce produit, avec le nouveau et l'ancien
      variations, // les nouvelles variations
    } = payload

    const data = { target, stock, variations }

    const authorization = yield select(fromUsers.getAuth)
    const stockCreated = yield call([api, api.post], '/stock', {
      data,
    }, {
      headers: {
        authorization,
      },
    })

    if (stockCreated) {
      notification.success({
        message: (<FormattedMessage id='update.success' defaultMessage='Mise à jour réussie !' />),
        placement: 'topRight',
        duration: 8,
      })
    }

    return true
  } catch (error) {
    console.log('*createStock', { error })
    notification.error({
      message: (<FormattedMessage
        id='store.editCampaign.update.error'
        defaultMessage='Oups, une erreur est survenue...'
      />),
      placement: 'topRight',
      duration: 8,
    })
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(productsActions.FETCH_FORCE, fetchStocks, api)
  yield takeEvery(actions.GET_STOCK, fetchStocks, api)
  yield takeEvery(actions.MODIFY_STOCK, modifyStock, api)
  yield takeEvery(actions.CREATE_STOCK, createStock, api)
}

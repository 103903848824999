import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import {
  Button,
  Card,
  Checkbox,
  Drawer,
  notification,
} from 'antd'

import { SEND_GROUPED_ORDERS, FETCH_FORCE } from '../../store/orders/actions'
import { fromOrders, fromUsers, fromShops } from '../../store/selectors'
import { getRebuiltOrders } from '../../store/globalSelectors'

const OrderPreFilledAddress = (props) => {
  const {
    loading,
    error,
    allOrders,
    shopSettings,
    isAdmin,
    defaultActions,
    setActions,
    sendGroupedOrders,
    refresh,
  } = props

  const {
    addresses = [],
  } = shopSettings

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState([])
  const [loadingRequest, setLoadingRequest] = useState(false)

  const orders = isAdmin ? allOrders.map((o) => o)
    : allOrders.filter((o) => o.transaction.transaction_type !== 'admin' && o.transaction.transaction_type !== 'batch-admin')

  if (error || loading) return null
  if (!loading && isAdmin && orders.filter((order) => order.client?.prefilledAddress && order.status === 'Waiting').length
    && !defaultActions.find((action) => action.key === 'send') && addresses.length) {
    const newDefaultActions = [
      <Button type='primary' key='send' onClick={() => setVisible(true)} style={{ marginRight: '5px' }}>
        <FormattedMessage id='launchOrders' defaultMessage='Lancer les commandes' />
      </Button>,
    ].concat(defaultActions)
    setActions(newDefaultActions)
  }

  const onChangeSelected = (bool, address) => {
    const newSelection = selected
    if (bool) {
      newSelection.push(address.identifier)
    } else {
      const index = newSelection.indexOf(address.identifier)
      newSelection.splice(index, 1)
    }
    setSelected([...newSelection])
  }

  return (
    <Drawer
      title={<FormattedMessage id='groupedOrdersLaunch' defaultMessage='Lancement des commandes groupées' />}
      visible={visible}
      onClose={() => setVisible(false)}
      width='50%'
      destroyOnClose
    >
      {addresses.filter((address) => address.regrouped).map((address) => {
        const orderAddress = orders.filter((order) => order.status === 'Waiting' && order.client?.prefilledAddress === address.identifier)
        const lengthOrder = orderAddress.length
        return (
          <div style={{ position: 'relative', marginBottom: '10px' }}>
            {!lengthOrder ? (
              <div
                style={{
                  backgroundColor: 'grey',
                  opacity: 0.5,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 100,
                }}
              />
            ) : null}
            <Card
              size='small'
              title={(
                <div>
                  <span>
                    {address.name}
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    {` - ${address.address?.street}, ${address.address?.city} ${address.address?.postalCode}`}
                  </span>
                </div>
              )}
              hoverable
              onClick={() => {
                onChangeSelected(!selected.includes(address.identifier), address)
              }}
              extra={(
                <Checkbox
                  checked={selected.includes(address.identifier)}
                />
              )}
            >
              <div>
                <FormattedMessage
                  id='orderPrefilledAddress.waitingOrders'
                  defaultMessage='Commandes en attentes : {number}'
                  values={{ number: lengthOrder }}
                />
              </div>
            </Card>
          </div>
        )
      })}
      <Button
        type='primary'
        loading={loadingRequest}
        onClick={async () => {
          const selectedAddresses = addresses.filter((address) => selected.includes(address.identifier))
          setLoadingRequest(true)
          sendGroupedOrders(selectedAddresses, () => {
            setLoadingRequest(false)
            setSelected([])
            refresh()
            notification.open({
              message: <FormattedMessage
                id='orderPrefilledAddress.successfullLaunch'
                defaultMessage="Le lancement de vos commandes groupées c'est bien passé !"
              />,
              placement: 'bottomRight',
            })
            setVisible(false)
          }, () => {
            setLoadingRequest(false)
            notification.open({
              message: <FormattedMessage
                id='orderPrefilledAddress.failedLaunch'
                defaultMessage="L'envoi en production de vos commandes a rencontré un problème, veuillez contacter notre service client..."
              />,
              placement: 'bottomRight',
            })
          })
        }}
        disabled={!selected.length}
      >
        <FormattedMessage id='orderPrefilledAddress.sendToProd' defaultMessage='Envoyer en production : ' />
        {selected.length
          ? (
            <span>
              {`${selected.length}`}
              {' '}
              {selected.length === 1
                ? <FormattedMessage id='orderPrefilledAddress.selectedAddresses.singular' defaultMessage='adresse sélectionnée' />
                : <FormattedMessage id='orderPrefilledAddress.selectedAddresses.plurial' defaultMessage='adresses sélectionnées' />}
              {' 🚀'}
            </span>
          )
          : ''}
      </Button>
    </Drawer>
  )
}

export default connect(
  (state) => ({
    allOrders: getRebuiltOrders(state),
    shopSettings: fromShops.getShopSettings(state),
    loading: fromOrders.loading(state),
    error: fromOrders.error(state),
    isAdmin: fromUsers.isAdmin(state),
  }), (dispatch) => bindActionCreators({
    sendGroupedOrders: (selectedAddresses, callback, callbackFail) => ({
      type: SEND_GROUPED_ORDERS,
      payload: {
        selectedAddresses,
        callback,
        callbackFail,
      },
    }),
    refresh: () => ({
      type: FETCH_FORCE,
      payload: {
        force: true,
      },
    }),
  }, dispatch),
)(OrderPreFilledAddress)

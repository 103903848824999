import React from 'react'
import {
  Form,
  Input,
} from 'antd'
import { FormattedMessage } from 'react-intl'

class AddressForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
      },
      onSubmit,
    } = this.props
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          email,
          firstName,
          lastName,
        } = values
        // console.log('Received values of form: ', values)
        const result = {
          email,
          additionalInfos: {
            firstName,
            lastName,
          },
        }
        onSubmit(result)
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
      },
      rules,
      infos,
    } = this.props
    return (
      <Form
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
        }}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
        }}
        onSubmit={this.handleSubmit}
        style={{
          padding: '0 12px',
        }}
      >
        <Form.Item label={<FormattedMessage id='address' defaultMessage='Adresse' />}>
          {getFieldDecorator('address', {
            rules: rules.address,
            initialValue: infos.address,
          })(<Input />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage id='additional' defaultMessage='Complément' />}>
          {getFieldDecorator('complement', {
            rules: rules.complement,
            initialValue: infos.complement,
          })(<Input />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage id='postalCode' defaultMessage='Code postal' />}>
          {getFieldDecorator('postalCode', {
            rules: rules.postalCode,
            initialValue: infos.postalCode,
          })(<Input />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage id='city' defaultMessage='Ville' />}>
          {getFieldDecorator('city', {
            rules: rules.city,
            initialValue: infos.city,
          })(<Input />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage id='country' defaultMessage='Pays' />}>
          {getFieldDecorator('country', {
            rules: rules.country,
            initialValue: infos.country,
          })(<Input />)}
        </Form.Item>
      </Form>
    )
  }
}

export default AddressForm

import { Button } from 'antd'
import React from 'react'
import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import { faX, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MagicLinksSelection = (props) => {
  const {
    selection, // required
    setSelection, // required
    magicLinks, // required
    setStepBeforeResend, // required
  } = props

  const nbSelection = selection.length

  if (!nbSelection) return null

  return (
    <motion.div
      initial={{ y: -50 }}
      animate={{ y: 0 }}
      style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8, marginTop: 24,
      }}
    >
      <div>
        <span style={{ marginRight: 4 }}>
          {nbSelection}
        </span>
        <FormattedMessage
          id='magicLinks.selection.nb'
          defaultMessage='{nbSelection, plural, one {sélectionné} other {sélectionnés}}'
          values={{ nbSelection }}
        />
      </div>
      <Button
        disabled={nbSelection === magicLinks.length}
        type='link'
        onClick={() => {
          const magicLinksIds = magicLinks.map((magicLink) => String(magicLink._id))
          setSelection(magicLinksIds)
        }}
      >
        <FormattedMessage id='magicLinks.selection.all' defaultMessage='Sélectionner tous les envois' />
      </Button>
      <Button type='primary' onClick={() => setStepBeforeResend(true)}>
        <FontAwesomeIcon size='sm' icon={faPaperPlane} style={{ marginRight: 8 }} />
        <FormattedMessage id='magicLinks.actions.resend' defaultMessage='Relancer' />
      </Button>
      <div
        style={{
          width: 16, heigth: 16, marginLeft: 8, cursor: 'pointer',
        }}
        onClick={() => setSelection([])}
      >
        <FontAwesomeIcon icon={faX} />
      </div>
    </motion.div>
  )
}

export default MagicLinksSelection

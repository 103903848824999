import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { countries } from '@balibart/shipping'
import { Select } from 'antd'

const { Option } = Select

const CountrySelector = (props) => {
  const {
    onChange,
    value,
    width = '20vw',
    locale = 'fr',
  } = props

  const initialValue = value?.length > 0 ? value : undefined

  const [selectedValue, setSelectedValue] = useState()

  useEffect(() => {
    setSelectedValue(initialValue)
  }, [value])

  const handleChange = (e) => {
    setSelectedValue(e)
    onChange(e)
  }
  const filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

  return (
    <Select
      placeholder={<FormattedMessage id='country' defaultMessage='Pays' />}
      value={selectedValue}
      onChange={handleChange}
      filterOption={filterOption}
      style={{ width }}
      showSearch
    >
      {countries.map((country) => <Option value={country.value} key={country.value}>{country[locale]}</Option>)}
    </Select>
  )
}

export default CountrySelector

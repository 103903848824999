import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import { LegalNoticeTabs, Info } from '../../components'
import { MODIFY } from '../../store/shops/actions'
import { fromShops } from '../../store/selectors'

const { Title } = Typography

const LegalNotice = (props) => {
  try {
    const {
      shopSettings,
      modifyShop,
      shop,
    } = props

    return (
      <div id='legalNotice'>
        <Title level={2}>
          <FormattedMessage
            id='settings.legalNotice'
            defaultMessage='Mentions Légales'
          />
        </Title>
        <p>
          <FormattedMessage
            id='settings.legalNotice.helper'
            defaultMessage='Ajoutez vos mentions légales.'
          />
          <Info
            title={<FormattedMessage id='settings.legalNotice.markdown' defaultMessage='Mentions légales / Markdown' />}
            content={(
              <p style={{ width: '25vw' }}>
                <FormattedMessage
                  id='settings.legalNotice.helper1'
                  defaultMessage='Vous pouvez utiliser du markdown pour mettre en forme la description
                   de vos mentions légales et autres documents officiels.'
                />
                {' '}
                <FormattedMessage
                  id='settings.legalNotice.helper2'
                  defaultMessage='Exemples :'
                />
                <br />
                <FormattedMessage
                  id='settings.legalNotice.helper3'
                  defaultMessage="- titres : ajoutez un ou plusieurs symboles #
                  puis un espace devant une ligne pour créer un titre (taille du titre décroissante avec l'ajout de #),"
                />
                <br />
                <FormattedMessage
                  id='settings.legalNotice.helper4'
                  defaultMessage='- italique : placez un ou plusieurs mots entre deux symboles * pour un affichage en italique,'
                />
                <br />
                <FormattedMessage
                  id='settings.legalNotice.helper5'
                  defaultMessage='- sauts-de-ligne : ajoutez deux espaces à la fin de votre ligne.'
                />
                <br />
                <FormattedMessage id='settings.legalNotice.helper6' defaultMessage="Plus d'informations sur :" />
                {' '}
                <a href='https://www.markdownguide.org/basic-syntax/' target='_blank' rel='noopener noreferrer'>
                  markdownguide.org
                </a>
                <br />
                <FormattedMessage id='settings.legalNotice.helper7' defaultMessage='N.B. : gras non-disponible avec la police par défaut.' />
              </p>
            )}
          />
        </p>
        <LegalNoticeTabs
          shopSettings={shopSettings}
          modifyShop={modifyShop}
          shop={shop}
        />
      </div>
    )
  } catch (e) {
    console.log('Containers/LegalNotice error:', e)
    return null
  }
}

export default connect(
  (state) => ({
    shopSettings: fromShops.getShopSettings(state),
    canActivate: fromShops.canActivate(state),
    shop: fromShops.getShop(state),
  }),
  (dispatch) => bindActionCreators({
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)(LegalNotice)

export const initialState = {
  data: [],
  loading: false,
  error: false,
}


export const getAllColorFamilies = (state = initialState) => {
  try {
    return state.data
  } catch (e) {
    console.log('getAllTechnics - error', e)
    return initialState.data
  }
}

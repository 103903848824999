import React from 'react'
import {
  Form,
  Switch,
  message,
} from 'antd'
import { FormattedMessage } from 'react-intl'

import config from '../../../config'

const { stripeAppId } = config

const BasicsSettingsForm = (props) => {
  const {
    entityId,
    entities,
    modifyEntity,
  } = props

  const entity = entities.find((ent) => ent._id === entityId)
  return (
    <Form
      style={{
        marginBottom: '20px',
      }}
    >
      <Switch
        onClick={() => {
          if (!(entity && entity.facturation && entity.facturation.mode === 'standard')) {
            window.location = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${
              stripeAppId
            }&scope=read_write&redirect_uri=${
              window.location.origin + window.location.pathname
            }`
          } else {
            modifyEntity({ ...entity, facturation: false }, () => {
              message.success(<FormattedMessage
                id='updateSuccess.shop'
                defaultMessage='Boutique mise à jour'
              />)
            })
          }
        }}
        checked={(entity && entity.facturation && entity.facturation.mode === 'standard')}
        style={{ marginRight: 10 }}
      />
      <FormattedMessage
        id='shopStatus.stripeConnect'
        defaultMessage='Accepter les paiements via Stripe (Obligatoire pour mettre votre shop en ligne)'
      />
    </Form>
  )
}

export default Form.create({ name: 'shopFacturationSettings' })(BasicsSettingsForm)

import React from 'react'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { FormattedSwitch } from '../..'

const CampaignPublicInput = (props) => {
  const {
    campaign, // Required
    setCampaign, // Required
    setNeedUpdateCampaign, // Required
    createdCampaignId, // Required
    isDisabled = false,
    size,
  } = props

  const onChangePrivacy = (checked) => {
    setCampaign({ ...campaign, public: !checked })
    if (createdCampaignId) {
      setNeedUpdateCampaign(true)
    }
  }

  return (
    <FormattedSwitch
      checked={!campaign?.public}
      onChangeFunction={onChangePrivacy}
      title={(
        <FormattedMessage
          id='campaigns.create.step.parameters.public'
          defaultMessage='Campagne privée'
        />
      )}
      tooltipText={(
        <FormattedMessage
          id='campaigns.create.step.parameters.public.tooltip'
          defaultMessage='Les campagnes privées ne sont pas visibles par les autres utilisateurs.'
        />
      )}
      icon={faCircleInfo}
      isDisabled={isDisabled}
      size={size}
    />
  )
}

export default CampaignPublicInput

import React from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Icon } from 'antd'
import config from '../../../../config'
import { deleteBpFromCart } from '../../../../store/baseProductsCart/actions'
import ImageSection from '../ImageSection'
import { neutralColor } from '../../../../config/theme'

const { image_base_url: imageBaseUrl } = config

const ActionSection = (props) => {
  const dispatch = useDispatch()
  const { cartId } = props
  return (
    <div style={{
      margin: 'auto', width: '10%', display: 'flex', justifyContent: 'center',
    }}
    >
      <Icon type='delete' width='2em' height='2em' style={{ fontSize: '12px' }} onClick={() => dispatch(deleteBpFromCart(cartId))} />
    </div>
  )
}

const ColorDot = (props) => {
  const { color } = props
  const src = String(`${imageBaseUrl}/Pastille/low/${color.name}.png`)
  return (<img alt='a colored circle' style={{ height: '12px', width: '12px' }} src={src} />)
}
const SecondSection = (props) => {
  const {
    quantity, unitPrice, margin,
  } = props
  return (
    <div style={{ width: '50%' }}>
      <p style={{
        padding: '5px', display: 'flex', justifyContent: 'space-between', fontSize: '10px',
      }}
      >
        <span>
          <span>
            <FormattedMessage id='bp-cart-quantity' defaultMessage='Quantité : ' />
          </span>
          <span style={{ fontWeight: 'bold' }}>
            {quantity}
          </span>
        </span>
        <span>
          <FormattedMessage id='bp-cart-margin' defaultMessage='Taux de marque : ' />
          {`${margin.toFixed(2)} %`}
        </span>
      </p>
      <p style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{
          fontSize: '14px',
          fontWeight: 'bold',
        }}
        >
          {`${(unitPrice * quantity).toFixed(2)} € HT`}
        </span>
        <span>
          {`Prix/u (HT) ${unitPrice.toFixed(2)}`}
        </span>
      </p>
    </div>
  )
}

const FirstSection = (props) => {
  const { name, color, technicsSelected } = props
  return (
    <div style={{ width: '50%', borderRight: `1px solid ${neutralColor[2]}`, marginRight: '10px' }}>
      <p style={{
        fontSize: '12px',
        fontWeight: 'bold',
      }}
      >
        {name}
      </p>
      <div>
        <span style={{ marginRight: '5px' }}>
          <FormattedMessage
            id='bp-cart.technics-selected'
            defaultMessage='{nbOfTechnics, plural, =0 {# technique} one {# technique} other {# techniques}}'
            values={{
              nbOfTechnics: technicsSelected.length,
            }}
          />
        </span>
        <span style={{ marginRight: '5px' }}> | </span>
        <span style={{ marginRight: '5px' }}>
          <ColorDot color={color} />
        </span>
        <span>
          {color.name}
        </span>
      </div>
    </div>
  )
}

const LineItem = (props) => {
  const { lineItem } = props
  const {
    name, unitPrice, color, technicsSelected, qty, image, cartId, margin,
  } = lineItem
  return (
    <div style={{ display: 'flex' }}>
      <ImageSection src={image} />
      <FirstSection name={name} color={color} technicsSelected={technicsSelected} qty={qty} />
      <SecondSection quantity={qty} unitPrice={unitPrice} margin={margin} />
      <ActionSection cartId={cartId} />
    </div>
  )
}

export default LineItem

import { initialState } from './selectors'
import * as actions from './actions'

export default (state = initialState, { type }) => {
  switch (type) {
  case actions.UPLOAD:
    return {
      ...state,
    }
  case actions.SUCCESS_UPLOAD:
    return {
      ...state,
      loading: false,
      error: false,
    }
  case actions.ERROR:
    return {
      ...state,
      loading: false,
      error: true,
    }
  case actions.START_UPLOAD:
    return {
      ...state,
      loading: true,
    }
  default:
    return state
  }
}

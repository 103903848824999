import date from 'dayjs'
import { hasABirthdayThisMonth } from '../dashboard'
import getPrice from '../getPrice'
import { getInvoiceStatus } from '../invoiceHelpers'
// eslint-disable-next-line import/no-cycle
import { getAdminName, getMagicLinkStatus } from '../magicLinks'

/**
 * Formate les emails pour enlever les espaces et les mettre en minuscules
 * @param {string} value
 * @returns {string} La valeur trimmée
 */
const trimInput = (value) => value?.replaceAll(' ', '').toLowerCase() || ''

/**
  * Permet de trimer les inputs des filtres de recherche par mot clé pour une meilleure efficacité
  * @param {string} input - La valeur entrée dans un input.
  * @returns {string} La valeur trimmée sans tiret ni espace, en minuscule
  */
const trimSearch = (input) => trimInput(input).replaceAll('-', '')

const trimProducts = (products) => {
  try {
    return products.map((item) => ({
      id: item._id,
      variations: item.variations,
      quantity: item.quantity,
      name: item.name,
      type: item.baseProduct.subCategory,
      unitPrice: getPrice(item.variations, item, {}, {}, {}).value,
      payw: parseFloat(item.payw || 0),
      clientCustomization: item.clientCustomization,
      preOrder: item.preOrder,
      preOrderInfos: item.preOrderInfos,
      custom: item.custom,
    }))
  } catch (e) {
    console.log('trimProducts', e)
    return products
  }
}

const trimIdsForDiscountEndpoint = (ids) => {
  try {
    let string = ''
    ids.map((id) => {
      string = `${string},${id}`
      return null
    })
    return string
  } catch (e) {
    return ''
  }
}

const trimColorName = (colorName) => {
  try {
    return colorName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/&/gi, 'et').toLowerCase()
      .split(' ')
      .join('-')
  } catch (e) {
    return colorName
  }
}

const isSelfSend = (record) => record.subOrders?.some((subOrder) => subOrder.supplier === '5b06affc8427184204089fa0')
|| record.children?.some((child) => child.subOrders?.some((subOrder) => subOrder.supplier === '5b06affc8427184204089fa0'))

const getType = (record) => {
  const {
    magicLinkId, transaction, giftOrder, internalOrder, children,
  } = record
  switch (true) {
  case magicLinkId:
    return 'MagicLink'
  case transaction.transaction_type === 'admin' || transaction.transaction_type === 'batch-admin':
    return 'Admin'
  case isSelfSend(record):
    return 'Self Send'
  case giftOrder:
    return 'Cadeau'
  case internalOrder || transaction.transaction_type === 'batch':
    return 'Interne'
  case children:
    return 'Groupée'
  default:
    return 'Boutique'
  }
}

const getDiscount = (record) => {
  if (record.discounts && record.discounts.code
    && record.discounts.code.name) {
    return record.discounts.code.name
  }
  return '-'
}

/**
 * Renvoie une date formatée selon la langue de l'utilisateur.
 * @param {date} rawDate - La date telle qu'inscrite en DB.
 * @param {string} locale - La langue choisie par l'utilisateur.
 * @returns {string} La date formatée selon la locale
 */
const displayDate = (rawDate, locale = 'fr') => {
  const formattedDate = locale === 'en'
    ? date(rawDate).format('MM/DD/YYYY hh:mm A')
    : date(rawDate).format('DD/MM/YYYY HH:mm')
  return formattedDate
}

const translateMemberInfos = (informations, locale = 'fr') => {
  switch (informations) {
  case 'Email non envoyé':
    return locale === 'fr' ? 'Email non envoyé' : 'Email not sent'
  case 'Email envoyé':
    return locale === 'fr' ? 'Email envoyé' : 'Email sent'
  case 'À jour':
    return locale === 'fr' ? 'À jour' : 'Up to date'
  default: return 'N/C'
  }
}

const trimRecordForMembersTable = (record, locale = 'fr', entities) => {
  const {
    freeDelivery, entityId, informations,
  } = record
  return {
    ...record,
    formatted: {
      ...record,
      freeDelivery: freeDelivery ? 'gratuite' : 'payante',
      birthday: hasABirthdayThisMonth(record),
      entity: entities?.find((e) => e._id === entityId)?.name,
      informations: translateMemberInfos(informations, locale),
    },
  }
}

const trimAllRecordsForMembersTable = (members = [], locale = 'fr', entities) => members.map((member) => trimRecordForMembersTable(
  member,
  locale,
  entities,
))

const trimRecordForInvoiceTable = (record, locale = 'fr') => ({
  ...record,
  formatted: {
    ...record,
    status: getInvoiceStatus(record.status, locale),
  },
})

const trimRecordForDiscountTable = (record, entities, locale) => {
  try {
    const {
      code, type, createdAt, status, name, entityId,
    } = record
    return {
      ...record,
      formatted: {
        code,
        type,
        createdAt: displayDate(createdAt, locale),
        status,
        name,
        entity: entities?.find((e) => e._id === entityId)?.name,
      },
    }
  } catch (e) {
    console.log('trimRecordForDiscountTable error:', e)
    return record
  }
}

const trimRecordForOrdersTable = (record, entities) => {
  try {
    const {
      customerOrderId,
      status,
      products,
      client = {},
      detailedPricing,
      entityId,
      createdAt,
    } = record
    const {
      address = {}, firstName, lastName, email,
    } = client
    const { city = '', country = '' } = address
    const price = detailedPricing && detailedPricing.total
      ? detailedPricing.total + detailedPricing.delivery_discounted
      : (record.transaction.amount / 100)
    const credits = (record.transaction.creditAmount && parseFloat(record.transaction.creditAmount).toFixed(2)) || '-'
    const formatted = {
      name: `${firstName} ${lastName}`,
      email,
      customerOrderId,
      status,
      products: products.length,
      type: getType(record),
      address: `${city}, ${country}`,
      discount: getDiscount(record),
      createdAt,
      price: `${price.toFixed(2)} €`,
      creditAmount: `${credits}`,
      entity: entities?.find((e) => e._id === entityId)?.name,
    }
    if (record.children) {
      return {
        ...record,
        formatted,
        // eslint-disable-next-line no-use-before-define
        children: trimAllRecordsForOrdersTable(record.children, entities),
      }
    }
    return {
      ...record,
      formatted,
    }
  } catch (e) {
    console.log('error helpers/trim - trimRecordForOrdersTable', e)
    return record
  }
}

const trimAllRecordsForOrdersTable = (orders, entities) => orders.map((order) => trimRecordForOrdersTable(order, entities))

const trimRecordForUsersTable = (user) => {
  try {
    const {
      additionalInfos,
    } = user
    const { firstName = '', lastName = '' } = additionalInfos
    const formatted = {
      name: firstName.length || lastName.length ? `${firstName} ${lastName}` : 'N/C',
    }
    return {
      key: Math.random(),
      ...user,
      formatted,
    }
  } catch (e) {
    return user
  }
}

const trimRecordForMagicLinksTable = (record, campaigns, collaborators, locale) => {
  try {
    const {
      campaignId, sendAt, sentAt, _id, lifetime = 14,
    } = record
    const realSendAtDate = sentAt || sendAt
    return {
      ...record,
      formatted: {
        admin: getAdminName(record, collaborators),
        campaign: campaigns?.find((campaign) => campaign?._id === campaignId)?.name || 'N/C',
        date: displayDate(realSendAtDate, locale),
        status: getMagicLinkStatus(record),
        nps: (record.progress && record.progress.nps) ? { ...record.progress.nps } : { },
        expireAt: displayDate(date(realSendAtDate).add(lifetime, 'day').toString(), locale),
        sentAt: realSendAtDate,
      },
      key: _id,
    }
  } catch (e) {
    console.log('helpers/trim/trimRecordForMagicLinksTable error:', e)
    return record
  }
}

const trimProductsForCartPricing = (products) => {
  try {
    return products.map((item) => ({
      id: item._id,
      variations: item.variations,
      quantity: item.quantity,
      name: item.name,
      // baseProduct: item.baseProduct,
      supplierBaseProductId: item.supplierBaseProductId,
      type: item.baseProduct.subCategory,
      unitPrice: getPrice(item.variations, item, {}, true, {}).value,
      payw: parseFloat(item.payw || 0),
      clientCustomization: item.clientCustomization,
      preOrder: item.preOrder,
      preOrderInfos: item.preOrderInfos,
      pricing: item.pricing,
      custom: item.custom,
    }))
  } catch (e) {
    console.log('tromProductsForCartPricing', e)
    return products
  }
}

const handleBenefits = (benefits = {}) => {
  const {
    zone, type, value, spreadToShipping = false,
  } = benefits
  return {
    value: {
      benefit: type,
      value: zone || value,
      spreadToShipping,
    },
  }
}

const rebuildOrders = (orders) => {
  try {
    const isAMotherOrder = (order) => order.hasRegroupedLinkedTo
    const isARegroupedOrder = (order) => order.statusLinkedTo

    const getChildren = (groupedOrder, allOrders) => {
      const children = allOrders.filter((order) => order.statusLinkedTo
   && order.statusLinkedTo === groupedOrder._id)
      return (!!children.length) && children
    }
    const buildedOrders = orders.map((order) => {
      try {
        if (isARegroupedOrder(order)) {
          return null
        }
        if (isAMotherOrder(order)) {
          return {
            ...order,
            children: getChildren(order, orders),
          }
        } return order
      } catch (error) {
        console.log('build order fail', { orderId: String(order._id, error) })
        return order
      }
    }).filter(Boolean)
    buildedOrders.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    return buildedOrders
  } catch (error) {
    console.log('rebuildOrders catch', error)
    return orders
  }
}

const trimDiscountForComponent = (_discount = {}, recursive = false) => {
  try {
    const discount = _discount || {}
    const excludedKeys = ['shop', 'owner', 'status', 'products', 'dates', 'benefits', '_id', 'createdAt', 'updatedAt']
    const keys = Object.keys(discount)
    const { benefits, limits = {} } = discount
    let newDiscount = {}
    keys.map((k) => {
      if (!excludedKeys.includes(k)) {
        if (typeof discount[k] === 'object') {
          const trimedObject = trimDiscountForComponent(discount[k])
          if (k === 'limits') {
            newDiscount = { ...newDiscount, ...trimedObject }
          } else {
            newDiscount[k] = trimDiscountForComponent(discount[k], true)
          }
        } else if (k === 'type' && typeof discount[k] === 'number') {
          newDiscount.benefit = discount[k]
        } else {
          newDiscount[k] = { value: discount[k] }
        }
      } else {
        newDiscount[k] = discount[k]
      }
      return null
    })
    if (!recursive) {
      newDiscount.benefits = handleBenefits(benefits)
      newDiscount.selected = limits.products || []
    }
    return newDiscount
  } catch (e) {
    console.log('trimedFailed', e)
    return {}
  }
}

const trimNullKeys = (object) => {
  try {
    const newObj = object
    const allKeys = Object.keys(object)
    allKeys.map((k) => {
      if (newObj[k] === null || newObj[k] === undefined) {
        delete newObj[k]
      } else if (typeof newObj[k] === 'object') {
        return trimNullKeys(newObj[k])
      }
      return false
    })
    return newObj
  } catch (e) {
    return object
  }
}

const trimDiscountForApi = (params) => {
  const {
    type, benefits, name, code, startsAt, stopsAt, selected, limitUses, amount, maxAmount, _id, shop, owner, createdAt, updatedAt,
  } = params
  const _benefits = {}
  const products = selected.length ? selected : []
  _benefits.type = benefits.benefit
  _benefits[benefits.benefit === 'shipping' ? 'zone' : 'value'] = benefits.value
  _benefits.spreadToShipping = benefits.spreadToShipping
  const discount = {
    _id,
    name,
    code,
    type,
    limits: {
      // trim date
      limitUses,
      amount,
      maxAmount,
      dates: {
        startsAt: startsAt ? startsAt.toDate() : null,
        stopsAt: stopsAt ? stopsAt.toDate() : null,
      },
      products,
    },
    benefits: _benefits,
    status: 'Inactive',
    shop,
    owner,
    createdAt,
    updatedAt,
  }
  const trimed = trimNullKeys(discount)
  return trimed
}

const trimStateLoader = (state) => {
  try {
    const { cart, checkout } = state
    const newCart = {
      ...cart,
      gift: false,
    }
    return { ...state, cart: newCart, checkout }
  } catch (e) {
    console.log('trimStateLoader', e)
    return {}
  }
}

const trimCartForApi = (cart) => {
  try {
    const newCart = { ...cart, products: trimProductsForCartPricing(cart.content) }
    if (newCart.content) {
      delete newCart.content
    }
    return newCart
  } catch (e) {
    console.log('trimCartForApi', e)
    return cart
  }
}

/*eslint-disable */
const getDiffBetweenObject = (object, base) => {
  // eslint-disable-next-line no-shadow
  function changes(object, base) {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        // eslint-disable-next-line no-param-reassign
        result[key] = value
      }
    })
  }
  return changes(object, base)
}
/* eslint-enable */

export {
  displayDate,
  getAdminName,
  getDiffBetweenObject,
  rebuildOrders, translateMemberInfos, trimAllRecordsForMembersTable, trimAllRecordsForOrdersTable, trimCartForApi, trimColorName, trimDiscountForApi,
  trimDiscountForComponent, trimIdsForDiscountEndpoint, trimInput, trimProducts,
  trimProductsForCartPricing, trimRecordForDiscountTable,
  trimRecordForInvoiceTable,
  trimRecordForMagicLinksTable, trimRecordForMembersTable, trimRecordForOrdersTable, trimRecordForUsersTable, trimSearch, trimStateLoader,
}

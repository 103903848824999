export const FETCH = 'FETCH_ORDERS'
export const INIT_FETCH_DATA = 'INIT_FETCH_DATA_ORDERS'
export const END_FETCH_DATA = 'END_FETCH_DATA_ORDERS'
export const FETCH_MORE = 'FETCH_MORE_ORDERS'
export const FETCH_FORCE = 'FETCH_FORCE_ORDERS'
export const RECEIVED = 'RECEIVE_ORDERS'
export const RECEIVED_MORE = 'RECEIVE_MORE_ORDERS'
export const MODIFY = 'MODIFY'
export const MODIFIED = 'MODIFIED'
export const MODIFY_GIFT = 'MODIFY_GIFT'
export const CANCEL_GIFT = 'CANCEL_GIFT'
export const CANCELLED_GIFT = 'CANCELLED_GIFT'
export const GIFT_TO_BILLING = 'GIFT_TO_BILLING'
export const GIFTED_TO_BILLING = 'GIFTED_TO_BILLING'
export const ERROR = 'ERROR_ORDERS'
export const SEND_MANY_ORDERS = 'SEND_MANY_ORDERS'
export const SEND_GROUPED_ORDERS = 'SEND_GROUPED_ORDERS'
export const BATCH_LOADING = 'BATCH_LOADING'
export const BATCH_ERROR = 'BATCH_ERROR'
export const SHIPUP_SUBSCRIBE = 'SHIPUP_SUBSCRIBE'

export const shipupSubscribe = (orders, lang) => ({
  type: SHIPUP_SUBSCRIBE,
  payload: {
    orders,
    lang,
  },
})

export const sendManyOrders = ({
  // eslint-disable-next-line camelcase
  orders, amount, paymentMethod, stripe, transaction_type, history,
}) => ({
  type: SEND_MANY_ORDERS,
  payload: {
    orders,
    amount,
    paymentMethod,
    stripe,
    transaction_type,
    history,
  },
})

export const setLoading = (batchLoading) => ({
  type: BATCH_LOADING,
  payload: {
    batchLoading,
  },
})

export const setError = ({ batchError, errorDetails }) => ({
  type: BATCH_ERROR,
  payload: {
    batchError,
    errorDetails,
  },
})

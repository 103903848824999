import React from 'react'
import {
  Typography,
  Select,
} from 'antd'
import { FormattedMessage } from 'react-intl'

const { Title } = Typography
const { Option } = Select

const PresetAddresses = (props) => {
  try {
    const {
      addresses, handleChangePreSetAdresses, changeCountry, entities,
    } = props
    const authorized = entities?.length === 1 ? addresses.filter((a) => a.entityId === entities[0]?._id) : addresses
    if (!authorized.length) return null
    return (
      <div>
        <Title level={4} style={{ marginLeft: '10px' }}>
          <FormattedMessage id='presetAddresses.title' defaultMessage='Vos adresses enregistrées' />
        </Title>
        <Select
          allowClear
          placeholder={<FormattedMessage id='presetAddresses.placeholder' defaultMessage='Sélectionner une adresse enregistrée' />}
          style={{
            padding: '0 10px 10px 10px',
          }}
          className='addresses'
          onChange={(value) => {
            const country = handleChangePreSetAdresses(value)
            // We update the country and the shippingPrice based on presetAddress's country (could be different than previously)
            changeCountry(country)
          }}
        >
          {authorized.map((address) => (
            <Option value={address.name}>
              {`${address.name} : ${address.address.street}, ${address.address.city} ${address.address.postalCode}`}
            </Option>
          ))}
        </Select>
      </div>
    )
  } catch (error) {
    console.log('PresetAddresses - render()', { error })
    return null
  }
}

export default PresetAddresses

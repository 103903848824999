import React from 'react'
import {
  Icon,
  Select,
  DatePicker,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { uniqBy } from 'lodash'
import { getAdminName, isMagicLinksAdmin } from '../../../helpers/magicLinks'

const { Option } = Select
const { RangePicker } = DatePicker
const dateFormatList = ['DD/MM/YYYY', 'MM/DD/YYYY']

const MagicLinksTrackingTopFilters = (props) => {
  try {
    const {
      state, setState, user, shopOwner, campaigns, magicLinks, collaborators, locale,
    } = props
    const { filters } = state
    const { adminsIds, campaignsIds } = filters

    return (
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0 0 10px 0',
      }}
      >
        <Icon type='filter' />
        {isMagicLinksAdmin(user, shopOwner) ? (
          <Select
            mode='multiple'
            showArrow
            allowClear
            style={{ marginLeft: 8, minWidth: '220px' }}
            value={adminsIds}
            placeholder={(
              <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
              >
                <Icon type='team' style={{ paddingRight: 4 }} />
                <FormattedMessage
                  id='campaignTracking.placeholders.admins'
                  defaultMessage='Tous les admins'
                />
              </div>
            )}
            onChange={(value) => setState({ ...state, filters: { ...filters, adminsIds: value } })}
          >
            {uniqBy(magicLinks, 'userId').map((magicLink) => <Option key={magicLink.userId}>{getAdminName(magicLink, collaborators)}</Option>)}
          </Select>
        ) : null}
        <Select
          mode='multiple'
          showArrow
          allowClear
          style={{ marginLeft: 8, minWidth: '220px' }}
          value={campaignsIds}
          placeholder={(
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
            >
              <Icon type='rocket' style={{ paddingRight: 4 }} />
              <FormattedMessage id='magicLinks.dashboard.allCampaigns' defaultMessage='Toutes les campagnes' />
            </div>
          )}
          onChange={(value) => setState({ ...state, filters: { ...filters, campaignsIds: value } })}
        >
          {campaigns.map((campaign) => <Option key={campaign._id}>{campaign.name}</Option>)}
        </Select>
        <RangePicker
          format={locale === 'en' ? dateFormatList[1] : dateFormatList[0]}
          onChange={(dates) => setState({ ...state, filters: { ...filters, range: dates } })}
          style={{ width: '300px', marginLeft: 8 }}
        />
      </div>
    )
  } catch (e) {
    console.log('organisms/MagicLinksTrackingTopFilters error:', e)
    return null
  }
}

export default MagicLinksTrackingTopFilters

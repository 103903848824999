import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input } from 'antd'
import { StepInputLabel } from '../..'
import { character } from '../../../config/theme'

const { TextArea } = Input

const FormStepTextarea = (props) => {
  const {
    titleMessageId, // Required
    titleDefaultMesage, // Required
    rows = 2,
    placeholderMessageId, // Required
    placeholderDefaultMessage, // Required
    onChangeFunction = null,
    textAreaValue = '',
    currentDescriptionLength = -1,
    descriptionLimitLength = -1,
    isOptional = false,
    disabled = false,
  } = props
  const intl = useIntl()

  return (
    <>
      <StepInputLabel
        title={<FormattedMessage id={titleMessageId} defaultMessage={titleDefaultMesage} />}
        titleStyle={{ marginBottom: '8px', display: 'block' }}
        isOptional={isOptional}
      />
      <TextArea
        rows={rows}
        placeholder={intl.formatMessage({
          id: placeholderMessageId,
          defaultMessage: placeholderDefaultMessage,
        })}
        onChange={onChangeFunction}
        value={textAreaValue}
        maxLength={descriptionLimitLength > 0 && descriptionLimitLength}
        disabled={disabled}
      />
      {currentDescriptionLength >= 0 && descriptionLimitLength > 0
      && (
        <span style={{ alignSelf: 'flex-end', color: character.disabledPlaceholder }}>
          { `${currentDescriptionLength}/${descriptionLimitLength}` }
        </span>
      )}
    </>
  )
}

export default FormStepTextarea

import React from 'react'
import date from 'dayjs'
import {
  Button, Collapse, Icon,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { Users } from '../..'
import { encodeQuery } from '../../../helpers/filters'
import { getEntityCollaborators } from '../../../helpers/entities'

const { Panel } = Collapse

const EntitiesList = (props) => {
  const {
    entities = [],
    allCollaborators = [],
    history,
    setVisibleDrawerEditionEntity,
    setEntityEdition,
    setVisibleDrawerCreationUser,
    setPressetEntityId,
    setAlreadySetupEntityId,
    shop,
    resendUserCreationMail,
    locale,
  } = props

  const extra = (entity) => {
    try {
      const { name, _id } = entity
      const onClick = (event, page) => {
        event.stopPropagation()
        history.push(`/${page}/?${encodeQuery({ entityId: _id })}`)
      }
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
          }}
          >
            {name}
          </div>
          <div>
            <Button
              style={{ margin: '0 5px' }}
              onClick={(event) => {
                setPressetEntityId(_id)
                setAlreadySetupEntityId(true)
                setVisibleDrawerCreationUser(true)
                event.stopPropagation()
              }}
            >
              <Icon type='user-add' />
            </Button>
            <Button
              style={{ margin: '0 5px' }}
              onClick={(event) => onClick(event, 'members')}
            >
              <FormattedMessage
                id='members'
                defaultMessage='Membres'
              />
            </Button>
            <Button
              style={{ margin: '0 5px' }}
              onClick={(event) => onClick(event, 'products')}
            >
              <FormattedMessage id='products' defaultMessage='Produits' />
            </Button>
            <Button
              style={{ margin: '0 5px' }}
              onClick={(event) => onClick(event, 'orders')}
            >
              <FormattedMessage
                id='orders'
                defaultMessage='Commandes'
              />
            </Button>
            <Button
              style={{ margin: '0 5px' }}
              onClick={(event) => onClick(event, 'discounts')}
            >
              <FormattedMessage id='discounts' defaultMessage='Promotions' />
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              type='primary'
              onClick={() => {
                setVisibleDrawerEditionEntity(true)
                setEntityEdition(entity)
              }}
            >
              <FormattedMessage id='edit' defaultMessage='Editer' />
            </Button>
          </div>
        </div>
      )
    } catch (e) {
      console.log('Entities - extra error:', e)
      return null
    }
  }

  return (
    <div>
      <Collapse bordered={false}>
        {entities.sort((a, b) => (date(b.createdAt).isBefore(a.createdAt) && 1) || -1).map((entity) => {
          const collaborators = getEntityCollaborators(allCollaborators, entity)
          return (
            <Panel
              key={Math.random()}
              header={extra(entity)}
            >
              <Users
                users={collaborators}
                shop={shop}
                locale={locale}
                resendUserCreationMail={resendUserCreationMail}
              />
            </Panel>
          )
        })}
      </Collapse>
    </div>
  )
}

export default EntitiesList

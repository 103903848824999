import React from 'react'
import { FormattedMessage } from 'react-intl'
import { faGifts } from '@fortawesome/free-solid-svg-icons'

import { DataBlock } from '..'

const DataBlockSpentMagicLinks = (props) => {
  try {
    const { magicLinks } = props

    const numberOfSpentMagicLinks = magicLinks?.filter((magicLink) => magicLink.spent).length ?? 'N/C'

    return (
      <DataBlock
        title={(
          <FormattedMessage
            id='magicLinks.dashboard.spentMagicLinks'
            defaultMessage='Links dépensés'
          />
        )}
        value={numberOfSpentMagicLinks}
        icon={faGifts}
      />
    )
  } catch (e) {
    console.log('molecules/DataBlockSpentMagicLinks error:', e)
    return null
  }
}

export default DataBlockSpentMagicLinks

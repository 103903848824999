import React from 'react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts'
import date from 'dayjs'
import { useIntl, injectIntl } from 'react-intl'

const data = [
  {
    name: 'Janvier', uv: 4000, utilisations: 3, amt: 2400,
  },
  {
    name: 'Fevrier', uv: 3000, utilisations: 5, amt: 2210,
  },
  {
    name: 'Mars', uv: 2000, utilisations: 7, amt: 2290,
  },
  {
    name: 'Avril', uv: 2780, utilisations: 9, amt: 2000,
  },
  {
    name: 'Mai', uv: 1890, utilisations: 21, amt: 2181,
  },
  {
    name: 'Juil', uv: 2390, utilisations: 24, amt: 2500,
  },
  {
    name: 'Juillet', uv: 3490, utilisations: 37, amt: 2100,
  },
  {
    name: 'Aout', uv: 3490, utilisations: 10, amt: 2100,
  },
  {
    name: 'Sept', uv: 3490, utilisations: 12, amt: 2100,
  },
  {
    name: 'Oct', uv: 3490, utilisations: 16, amt: 2100,
  },
  {
    name: 'Nov', uv: 3490, utilisations: 19, amt: 2100,
  },
  {
    name: 'Dec', uv: 3490, utilisations: 38, amt: 2100,
  },
]

const Example = (props) => {
  const jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/'

  const { orders } = props

  const getData = (datas) => {
    const intl = useIntl()
    const months = [
      { name: intl.formatMessage({ id: 'discountStatistics.january', defaultMessage: 'Janvier' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.february', defaultMessage: 'Février' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.march', defaultMessage: 'Mars' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.april', defaultMessage: 'Avril' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.may', defaultMessage: 'Mai' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.june', defaultMessage: 'Juin' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.july', defaultMessage: 'Juillet' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.august', defaultMessage: 'Août' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.september', defaultMessage: 'Septembre' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.october', defaultMessage: 'Octobre' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.november', defaultMessage: 'Novembre' }), utilisations: 0 },
      { name: intl.formatMessage({ id: 'discountStatistics.december', defaultMessage: 'Décembre' }), utilisations: 0 },
    ]
    datas.map((order) => {
      const month = date(order.createdAt).month()
      months[month] = { ...months[month], utilisations: (months[month].utilisations || 0) + 1 }
      return true
    })
    return months
  }

  return (
    <BarChart
      width={800}
      height={300}
      data={getData(orders)}
      margin={{
        top: 5, right: 30, left: 20, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='name' />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey='utilisations' fill='#8884d8' />
    </BarChart>
  )
}

export default injectIntl(Example)

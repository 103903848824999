import React from 'react'
import { Select } from 'antd'
import { FormattedMessage } from 'react-intl'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const { Option } = Select

const PaymentMethodsSelect = (props) => {
  try {
    const {
      paymentMethods,
      handleSelectionPaymentMethod,
    } = props

    if (!paymentMethods.length) return null

    // On ne permet pas de payer avec un sepa_debit
    return (
      <Select
        placeholder={<FormattedMessage id='paymentMethodsSelect.placeholder' defaultMessage='Sélectionner un moyen de paiement enregistré' />}
        onChange={handleSelectionPaymentMethod}
        allowClear
        id='paymentMethods'
      >
        {paymentMethods.map((paymentMethod) => {
          if (paymentMethod.card) {
            const { card, id } = paymentMethod
            return (
              <Option value={id}>
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  <img
                    src={`${imageBaseUrl}/divers/paymentMethods/${card.brand}.png`}
                    alt='paymentMethod-logo'
                    style={{ width: '50px' }}
                  />
                  <span>
                    {` ···· ···· ···· ${card.last4}`}
                  </span>
                  <span>
                    {`${card.exp_month}/${card.exp_year}`}
                  </span>
                </div>
              </Option>
            )
          } return null
        })}
      </Select>
    )
  } catch (e) {
    console.log('Account - AddPayment', e)
    return null
  }
}

export default PaymentMethodsSelect

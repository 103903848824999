import React, { useState } from 'react'
import { uniqBy } from 'lodash'
import { getAdminName } from '../../../helpers/magicLinks'
import {
  MagicLinkStatusFilter,
  MagicLinkCampaignFilter,
  MagicLinkAdminFilter,
  MagicLinkPeriodFilter,
  MagicLinkFilters,
} from '../..'

const MagicLinksTrackingFilters = (props) => {
  try {
    const {
      filters, setFilters, magicLinks, campaigns, collaborators,
    } = props

    const [openFilter, setOpenFilter] = useState([])

    const { statuses, campaignsIds, adminsIds } = filters

    const filterNumber = statuses.length + campaignsIds.length + adminsIds.length

    return (
      <MagicLinkFilters
        setFilters={setFilters}
        filters={filters}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        filterNumber={filterNumber}
      >
        <MagicLinkStatusFilter
          setFilters={setFilters}
          filters={filters}
        />
        <MagicLinkCampaignFilter
          setFilters={setFilters}
          filters={filters}
          options={campaigns.map((campaign) => ({
            value: campaign._id,
            label: campaign.name,
          }))}
        />
        <MagicLinkAdminFilter
          setFilters={setFilters}
          filters={filters}
          options={uniqBy(magicLinks, 'userId').map((magicLink) => ({
            value: magicLink?.userId,
            label: getAdminName(magicLink, collaborators),
          }))}
        />
        <MagicLinkPeriodFilter
          setFilters={setFilters}
          filters={filters}
        />
      </MagicLinkFilters>
    )
  } catch (e) {
    console.log('organisms/MagicLinksTrackingFilters error:', e)
    return null
  }
}

export default MagicLinksTrackingFilters

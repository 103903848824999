import Moment from 'moment'

const newDate = new Moment()

const prettyDate = (type, product) => {
  try {
    if (product.preOrderInfos.endDate && type === 'live') {
      return Moment(product.preOrderInfos.endDate).format('D / MM / YY')
    } if (product.preOrderInfos.startDate && type === 'soon') {
      return Moment(product.preOrderInfos.startDate).format('D / MM / YY')
    } return null
  } catch (e) {
    console.log('helpers - preOrder - prettyDate', e)

    return null
  }
}

const preOrderCurrentlyGoingOn = (product) => {
  try {
    if (product.preOrderInfos) {
      return (Moment(product.preOrderInfos.startDate).isBefore(newDate) &&
      Moment(product.preOrderInfos.endDate).isAfter(newDate))
    }
    return false
  } catch (e) {
    console.log('helpers - preOrder - preOrderCurrentlyGoingOn', e)
    return false
  }
}

const preOrderSoon = (product) => {
  try {
    if (product.preOrderInfos) {
      return Moment(product.preOrderInfos.startDate).isAfter(newDate)
    }
    return false
  } catch (e) {
    console.log('helpers - preOrder - preOrderSoon', e)
    return false
  }
}

const isPreorder = (product) => {
  try {
    if (product.preOrder) {
      if (preOrderCurrentlyGoingOn(product)) {
        return 'live'
      } if (preOrderSoon(product)) {
        return 'soon'
      } return 'done'
    } return false
  } catch (e) {
    console.log('helpers - preOrder - isPreorder', e)
    return false
  }
}

const shippingEstimate = (product) => {
  try {
    if (product.preOrderInfos.shippingEstimate) {
      const date = Moment(product.preOrderInfos.shippingEstimate)
      const day = date.format('D')
      let estimation
      if (day < 10) {
        estimation = 'début '
      } else if (day >= 20) {
        estimation = 'fin '
      } else {
        estimation = 'mi-'
      }
      return `${estimation}${date.lang('fr').format('MMMM')}`
    } return null
  } catch (e) {
    console.log('helpers - preOrder - shippingEstimate', e)
    return null
  }
}

export default isPreorder
export { shippingEstimate, prettyDate }

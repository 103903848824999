import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import { ShopFacturationForm } from '../../components'
import { getEntityId } from '../../helpers/entities'
import { GET_SHOP } from '../../store/shops/actions'
import { MODIFY_ENTITY, SET_STRIPE, FETCH_FORCE } from '../../store/entities/actions'
import { fromShops } from '../../store/selectors'

const { Title } = Typography

const FacturationSettingsForm = (props) => {
  const {
    setFacturation,
    history,
    location: {
      search,
    },
    fetchShop,
    fetchEntities,
    entities,
    modifyEntity,
    user,
    shop,
  } = props
  if (!shop) {
    fetchShop()
  }
  if (!entities || !entities.length) {
    fetchEntities()
  }
  if (search && search.includes('code') && entities && entities.length) {
    const a = {}
    search.slice(1, search.length).split('&').map((p) => {
      const [key, val] = p.split('=')
      a[key] = val
      return null
    })
    if (a.code) {
      setFacturation({ code: a.code })
      history.push('/shop/settings')
    }
  }

  // ?scope=read_write&code=ac_KMho6UczUwjCqvCkdSoumkCDeJf38Ebv
  const { entityId } = getEntityId(user, entities, shop)

  return (
    <div id='facturation'>
      <Title level={2}>
        <FormattedMessage
          id='settings.facturation'
          defaultMessage='Stripe'
        />
      </Title>
      <ShopFacturationForm
        entities={entities}
        entityId={entityId}
        modifyEntity={modifyEntity}
      />
    </div>
  )
}

export default withRouter(connect(
  (state) => ({
    shopId: fromShops.getId(state),
  }),
  (dispatch) => bindActionCreators({
    setFacturation: (payload) => ({
      type: SET_STRIPE,
      payload,
    }),
    modifyEntity: (payload, callback) => ({
      type: MODIFY_ENTITY,
      payload,
      callback,
    }),
    fetchShop: () => ({
      type: GET_SHOP,
    }),
    fetchEntities: () => ({
      type: FETCH_FORCE,
    }),
  }, dispatch),
)(FacturationSettingsForm))

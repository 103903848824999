import React, { useState } from 'react'
import {
  faPaperPlane,
  faStar,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'

import { Button } from 'antd'
import { getTopProducts, getProductMainImage } from '../../../helpers/magicLinks'
import { CarouselScreen } from '../..'
import { character, neutralColor } from '../../../config/theme'

const MagicLinksDashboardStarGift = (props) => {
  try {
    const { magicLinks, orders, products } = props

    const [[page, direction], setPage] = useState([0, 0])

    const topProducts = getTopProducts(orders, magicLinks, products, 5)

    if (!topProducts?.length) return null

    const currentProduct = topProducts[page]
    const topPicList = topProducts.map((product) => getProductMainImage(product))

    // TODO : afficher le NPS du cadeau présenté quand on aura la data
    const starGiftNPS = null

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: 'white',
        boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.03)',
        borderRadius: '16px',
        padding: '12px',
      }}
      >
        <div style={{ display: 'flex', heigth: '100%', gap: '16px' }}>
          <CarouselScreen
            images={topPicList}
            index={[page, direction]}
            style={{ width: '128px', height: '128px' }}
            imageStyle={{ borderRadius: '8px' }}
          />
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%',
          }}
          >
            <div style={{
              display: 'flex', flexDirection: 'column',
            }}
            >
              <div style={{
                fontWeight: '400', fontSize: '12px', lineHeight: '20px', color: character.secondary,
              }}
              >
                <FormattedMessage id='magicLinks.dashboard.starGift' defaultMessage='Cadeau star !' />
              </div>
              <div style={{
                fontWeight: '600', fontSize: '16px', lineHeight: '24px', color: character.title,
              }}
              >
                {currentProduct?.name || 'N/C'}
              </div>
              <div style={{ gap: '16px' }}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '20px',
                  color: character.secondary,
                }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <FontAwesomeIcon size='sm' icon={faPaperPlane} />
                    {currentProduct?.count ? (
                      <FormattedMessage
                        id='magicLinks.dashboard.starGift.shipments'
                        defaultMessage='{number} envois'
                        values={{ number: currentProduct.count }}
                      />
                    ) : 'N/C'}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <FontAwesomeIcon size='sm' icon={faStar} />
                    {starGiftNPS || 'N/C'}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
              <div style={{ display: 'flex', gap: '12px' }}>
                {topPicList.map((img, index) => (
                  <div onClick={() => setPage([index, 0])} key={img?.src}>
                    <img
                      alt={img?.alt}
                      src={img?.src}
                      // key={img?.src}
                      style={{
                        borderRadius: '100px',
                        objectFit: 'cover',
                        height: '32px',
                      }}
                    />
                  </div>
                ))}
              </div>
              <div style={{ display: 'flex', gap: '8px' }}>
                <Button
                  style={{
                    border: `1px solid ${neutralColor[3]}`,
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '100px',
                    height: '32px',
                    width: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => setPage([Math.max(page - 1, 0), -1])}
                  disabled={page <= 0}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <Button
                  style={{
                    border: `1px solid ${neutralColor[3]}`,
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '100px',
                    height: '32px',
                    width: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => setPage([Math.min(page + 1, 5 - 1), +1])}
                  disabled={page >= (topProducts.length - 1)}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecules/MagicLinksDashboardStarGift error:', e)
    return null
  }
}

export default MagicLinksDashboardStarGift

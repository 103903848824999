import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { errorColor } from '../../../config/theme'

const css = {
  position: 'absolute',
  fontWeight: '500',
  fontSize: '12px',
  color: errorColor[6],
  lineHeight: '20px',
  textShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.1)',
}

const variants = {
  enter: {
    y: 20,
    opacity: 0,
  },
  center: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 20,
    opacity: 0,
  },
}

const ErrorMessage = (props) => {
  try {
    const { isVisible, children } = props
    return (
      <AnimatePresence>
        {isVisible && (
          <motion.div
            variants={variants}
            initial='enter'
            animate='center'
            exit='exit'
            transition={{
              y: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            <div style={css}>
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    )
  } catch (e) {
    console.log('atoms/ErrorMessage error:', e)
    return null
  }
}

export default ErrorMessage

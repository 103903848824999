import React, { useState } from 'react'

import {
  Button,
  Input,
  Switch,
  Card,
  List,
  Icon,
  Popover,
} from 'antd'

import ProductImage from '@balibart/product-image'
import { FormattedMessage } from 'react-intl'
import { CampaignEditProductsModal } from '../..'
import theme from '../../../config/theme'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const { Meta } = Card

/**
 * Composant qui gère le bouton switch pour gérer le mode de livraison de la campagne.
 *
 * @param {*} isAdminPanopli - indicateur si l'utilisateur est un administrateur Panopli
 * @param {*} editedCampaign - campagne en cours d'édition
 * @param {*} updateCampaign - fonction dispatchée pour l'update call saga
 * @param {*} setEditedCampaign - hook fonction pour maj la campagne en cours d'édition
 * @returns
 */
const EditionDeliveryMethodCampaign = (props) => {
  const {
    isAdminPanopli,
    editedCampaign,
    updateCampaign,
    setEditedCampaign,
  } = props

  const onChange = () => {
    updateCampaign({ _id: editedCampaign._id, isDeliveryOnlyRelayPoint: !editedCampaign.isDeliveryOnlyRelayPoint })

    setEditedCampaign({
      ...editedCampaign, isDeliveryOnlyRelayPoint: !editedCampaign.isDeliveryOnlyRelayPoint,
    })
  }

  if (!isAdminPanopli) {
    return null
  }
  return (
    <div style={{ marginTop: '15px' }}>

      <h3 style={{ fontWeight: 'bold' }}>Mode de livraison</h3>
      <Popover
        content={(
          <FormattedMessage
            id='campaign.edit.switchDeliveryMethod.popover'
            defaultMessage='Si l option est activée, livraison en point relais uniquement.'
          />
        )}
        title={(
          <FormattedMessage
            id='campaign.edit.switchDeliveryMethod.popoverTitle'
            defaultMessage='Option affichée uniquement aux admins Panopli'
          />
        )}
      >
        <Switch checked={editedCampaign?.isDeliveryOnlyRelayPoint} style={{ margin: '10px 0' }} onChange={onChange} />
      </Popover>

      {
        editedCampaign?.isDeliveryOnlyRelayPoint ? (
          <>
            <div style={{ fontSize: '12px', marginBottom: '10px', marginTop: '10px' }}>
              <FormattedMessage
                id='campaign.edit.switchDeliveryMethodOnlyRelayPoint.description'
                defaultMessage='Livraison uniquement en point relais.'
              />
            </div>
          </>

        )
          : (
            <>
              <div style={{ fontSize: '12px', marginBottom: '10px', marginTop: '10px' }}>
                <FormattedMessage
                  id='campaign.edit.switchDeliveryAllMethod.description'
                  defaultMessage='Pas de restriction sur le mode de livraison.'
                />
              </div>
            </>
          )
      }
    </div>
  )
}

/**
 * Retourne la section d'édition du titre d'une campagne
 * @param {*} isDisplayOnly - indicateur de mode de consultation (lecture seule / modification)
 * @param {*} onChangeName - fonction appelée à chaque changement de l'input
 * @param {*} currentCampaign - campagne en cours (lecture seule)
 * @param {*} isButtonEditTitleDisabled - indicateur si boutton d'enregistrement d'édition est désactivé
 * @param {*} editedCampaign - campagne en cours d'édition
 * @param {*} updateCampaign - fonction dispatchée pour l'update call saga
 * @param {*} setDisabledEditTitle - hook fonction pour mettre à jour le bouton d'enregistrement
 * @returns JSX.element
 */
const EditionTitleCampaign = (props) => {
  const {
    isDisplayOnly,
    currentCampaign,
    isButtonEditTitleDisabled,
    editedCampaign,
    updateCampaign,
    setDisabledEditTitle,
    setEditedCampaign,
  } = props
  /**
* Appelée à chaque chaque changement de l'utilisateur dans l'input du titre de la campagne
* @param {*} e
*/
  const onChangeName = (e) => {
    // on met à jour le nom de la campagne éditée
    setEditedCampaign({
      ...editedCampaign, name: e.target.value,
    })

    // Si le titre n'est pas modifié, le bouton pour enregistrer les changements est désactivé
    setDisabledEditTitle(e.target.value === currentCampaign.name || e.target.value.trim().length === 0)
  }

  return (
    <div style={{
      color: theme.neutralColor[6][12],
      fontWeight: 'bold',
      marginBottom: '2px',
      fontSize: '13px',
    }}
    >
      <div>
        <span style={{ color: theme.errorColor[6], marginRight: '5px' }}>
          *
        </span>
        <FormattedMessage
          id='campaign.edit.titleCampaign'
          defaultMessage='Titre de la campagne'
        />
      </div>
      <Input
        disabled={isDisplayOnly}
        onChange={onChangeName}
        defaultValue={`${currentCampaign.name}`}
        style={{ margin: '5px 0', width: '300px' }}
      />

      <Button
        type='primary'
        style={{ margin: '0 10px' }}
        disabled={isButtonEditTitleDisabled}
        onClick={() => {
          updateCampaign({ _id: editedCampaign._id, name: editedCampaign.name })
          setDisabledEditTitle(true)
        }}
      >
        <FormattedMessage
          id='campaign.edit.saveNewTitleCampaign'
          defaultMessage='Enregistrer'
        />
      </Button>
    </div>
  )
}

/**
 * Retourne la section d'édition des produits d'une campagne
 *
 * @param {*} isDisplayOnly - indicateur de mode de consultation (lecture seule / modification)
 * @param {*} setVisibleModalModifyProducts - hook fonction pour cacher ou faire apparaître la modal de modification produits
 * @param {*} currentCampaign - campagne en cours (lecture seule)
 * @param {*} products - tableau des produits éligibles pour cette campagne
 * @param {*} isVisibleModalModifyProducts - indicateur si modal modification produit affichée ou non
 * @param {*} updateCampaign - fonction dispatchée pour l'update call saga
 * @returns JSX.element
 */
const EditionProductsCampaign = (props) => {
  const {
    isDisplayOnly,
    setVisibleModalModifyProducts,
    currentCampaign,
    products,
    isVisibleModalModifyProducts,
    updateCampaign,
  } = props

  return (
    <div style={{ marginTop: '30px', display: 'flex', flexWrap: 'wrap' }}>
      <div>
        <h2 style={{ width: '270px', fontWeight: 'bold' }}>
          <FormattedMessage
            id='campaigns.title.product'
            defaultMessage='Produits'
          />
        </h2>
      </div>

      <Button
        type='link'
        disabled={isDisplayOnly}
        onClick={() => setVisibleModalModifyProducts(true)}
      >
        <FormattedMessage
          id='campaigns.parameters.updateProducts'
          defaultMessage='Modifier les produits'
        />
      </Button>

      <CampaignEditProductsModal
        currentCampaign={currentCampaign}
        products={products}
        isVisibleModalModifyProducts={isVisibleModalModifyProducts}
        setVisibleModalModifyProducts={setVisibleModalModifyProducts}
        updateCampaign={updateCampaign}
      />
    </div>
  )
}

/**
 * Retourne la section surprise d'une campagne.
 * @param {*} currentCampaign - campagne en cours
 * @returns JSX.element
 */
const SectionSurpriseCampaign = (props) => {
  const {
    currentCampaign,
  } = props
  return (
    <>
      <h3 style={{ fontWeight: 'bold' }}>
        <FormattedMessage
          id='campaigns.title.surpriseGift'
          defaultMessage='Cadeau surprise'
        />
      </h3>

      <Switch disabled checked={currentCampaign.surprise} style={{ margin: '10px 0' }} />

      {// Si uniquement un produit
        currentCampaign.products.length === 1 && currentCampaign.surprise ? (
          <>
            <div style={{ fontSize: '12px', marginBottom: '10px', marginTop: '10px' }}>
              <FormattedMessage
                id='campaign.edit.switchSurpriseGift.description'
                defaultMessage='Ce produit ne sera pas vu par les destinataires avant sa réception.'
              />
            </div>
          </>

        ) : (null)
      }
    </>
  )
}

/**
 * Retourne les produits dans un composant List antd.
 *
 * @param {*} currentProductsCampaign - produits en cours dans la campagne
 * @returns JSX.element
 */
const SectionDisplayProducts = (props) => {
  const {
    currentProductsCampaign,
    history,
  } = props

  return (
    <>
      <List
        style={{ marginRight: '20px' }}
        dataSource={currentProductsCampaign}
        grid={{
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 4,
          xxl: 6,
          gutter: 20,
        }}
        renderItem={(product) => (
          <List.Item>

            <Card
              hoverable
              cover={(
                <ProductImage
                  base_url={imageBaseUrl}
                  product={product}
                />
              )}
            >
              <Meta
                description={(
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '85%',
                      display: 'block',
                    }}
                    >
                      {product.name}
                    </div>
                    <Icon type='info-circle' style={{ fontSize: '16px' }} onClick={() => history.push(`/product?id=${product._id}`)} />
                  </div>
                )}
              />
            </Card>

          </List.Item>
        )}
      />
    </>
  )
}

/**
 * Composant qui gère la tab "Information" de la page d'édition d'une campagne (paramètres campagne)
 *
 */
const CampaignEditInformationOrganism = (props) => {
  try {
    const {
      isDisplayOnly, // si true toutes les modifications sont désactivées
      currentCampaign, // campagne en cours
      products, // nécessaire à la modal affichée par le bouton "modifier les produits"
      updateCampaign, // dispatchée depuis le container
      currentProductsCampaign, // produits de la campagne en cours
      isAdminPanopli,
      history,
    } = props

    // Gestion de la mise à jour du titre de la campagne
    const [editedCampaign, setEditedCampaign] = useState(currentCampaign)

    // Gestion disabled/enabled boutton
    const [isButtonEditTitleDisabled, setDisabledEditTitle] = useState(true)

    // Gestion visibilité éléments
    const [isVisibleModalModifyProducts, setVisibleModalModifyProducts] = useState(false)

    return (
      <div style={{ marginTop: '20px' }}>

        {/** Section d'édition du titre de la campagne */}
        <EditionTitleCampaign
          isDisplayOnly={isDisplayOnly}
          currentCampaign={currentCampaign}
          isButtonEditTitleDisabled={isButtonEditTitleDisabled}
          editedCampaign={editedCampaign}
          updateCampaign={updateCampaign}
          setDisabledEditTitle={setDisabledEditTitle}
          setEditedCampaign={setEditedCampaign}
        />

        {/** Section d'édition du mode de livraison only en point relai pour la campagne */}
        <EditionDeliveryMethodCampaign
          isAdminPanopli={isAdminPanopli}
          editedCampaign={editedCampaign}
          updateCampaign={updateCampaign}
          setEditedCampaign={setEditedCampaign}
        />

        {/** Section d'édition des produits de la campagne */ }
        <EditionProductsCampaign
          isDisplayOnly={isDisplayOnly}
          setVisibleModalModifyProducts={setVisibleModalModifyProducts}
          currentCampaign={currentCampaign}
          products={products}
          isVisibleModalModifyProducts={isVisibleModalModifyProducts}
          updateCampaign={updateCampaign}
        />

        {/** Section indication surprice de la campagne */}
        <SectionSurpriseCampaign
          currentCampaign={currentCampaign}
        />

        {/** Section d'affichage des produits */}
        <SectionDisplayProducts
          currentProductsCampaign={currentProductsCampaign}
          history={history}
        />
      </div>

    )
  } catch (e) {
    console.log('error organism/CampaignEditInformationOrganism', e)
    return null
  }
}

export default CampaignEditInformationOrganism

import { initialState } from './selectors'
import * as actions from './actions'

export default (state = initialState, { type, payload = {} }) => {
  const {
    error_details,
    data,
  } = payload
  switch (type) {
  case actions.RECEIVED:
    return {
      ...state,
      data,
      loading: false,
      error: false,
    }
  case actions.GET:
    return {
      ...state,
      loading: true,
      error: false,
      lastFetched: new Date(),
    }
  case actions.ERROR:
    return {
      ...state,
      error: true,
      loading: false,
      error_details,
    }
  default:
    return state
  }
}

import { Button, Badge } from 'antd'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faFilter } from '@fortawesome/free-solid-svg-icons'
import { neutralColor, character } from '../../../config/theme'

const CampaignDisplayFiltersButton = (props) => {
  const {
    setOpenFilter, // Required
    openFilter, // Required
    filterNumber, // Required
  } = props

  const [isButtonCliked, setIsButtonCliked] = useState(false)

  return (
    <Button
      style={{
        border: `1px solid ${neutralColor[3]}`,
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        padding: '5px 8px',
        height: '34px',
        ...(isButtonCliked && { backgroundColor: neutralColor[2] }),
        color: character?.title,
      }}
      onClick={() => {
        setOpenFilter(openFilter.length === 0 ? ['1'] : [])
        setIsButtonCliked(!isButtonCliked)
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: character.secondary,
        fontWeight: '500',
        fontSize: '14px',
        gap: '20px',
      }}
      >
        <FormattedMessage
          id='campaignTrackingFilters.openFilters'
          defaultMessage='Filtres'
        />
        {openFilter.length === 0 && (filterNumber > 0) && (
          <Badge count={filterNumber} />
        )}
        {openFilter.length === 0 && filterNumber === 0 && <FontAwesomeIcon icon={faFilter} />}
        {openFilter.length !== 0 && <FontAwesomeIcon icon={faXmark} />}
      </div>
    </Button>
  )
}

export default CampaignDisplayFiltersButton

import React from 'react'
import {
  put,
  call,
  takeEvery,
  select,
} from 'redux-saga/effects'
import { notification, Icon, Button } from 'antd'
import { calculateShippingPrice } from '@balibart/shipping'
import { calculateCartPrice } from '@balibart/pricing'
import assignBps from '@balibart/assignbps'
import { FormattedMessage } from 'react-intl'
import * as actions from './actions'
import * as shopActions from '../shops/actions'
import * as orderActions from '../orders/actions'
import * as stocksActions from '../stocks/actions'

import {
  fromShops,
  fromCart,
  fromProducts,
  fromSuppliers,
  fromBaseProducts,
  fromUsers,
  fromStocks,
  fromLocale,
} from '../selectors'
import { formatOrder } from '../../helpers/checkout'

import theme from '../../config/theme'

const { getShop } = fromShops
const { getCart } = fromCart

export function* watchPricing() {
  try {
    const cart = yield select(getCart)
    const stocks = yield select(fromStocks.getStocks)
    if (!cart || !cart.content || !cart.content.length) {
      yield put({
        type: actions.CART_PRODUCTS_PRICE,
        payload: {
          products_pricing: {
            total: 0,
          },
        },
      })
      return true
    }
    const products = yield select(fromProducts.allProducts)
    const total = calculateCartPrice(
      {
        products: cart.content,
        address: cart.address,
      },
      products,
      true,
      stocks,
    )
    yield put({
      type: actions.CART_PRODUCTS_PRICE,
      payload: {
        products_pricing: {
          total,
        },
      },
    })
    yield put({
      type: actions.CART_TOTAL,
    })
    return true
  } catch (error) {
    console.log('watchPricing', { error })
    return false
  }
}

export function* watchShipping(api, action) {
  try {
    const { payload: { country = 'FR' } } = action
    const cart = yield select(getCart)
    const suppliers = yield select(fromSuppliers.getSuppliers)
    const products = yield select(fromProducts.allProducts)
    const bps = yield select(fromBaseProducts.getBPs)
    const assignedProducts = assignBps(cart.content, products, bps)
    const shop = yield select(getShop)
    const stocks = yield select(fromStocks.getStocks)
    const shipping = calculateShippingPrice(
      {
        products: cart.content,
        address: {
          country: country || cart.address.country,
        },
      },
      shop,
      products,
      suppliers,
      assignedProducts,
      stocks,
    )
    yield put({
      type: actions.CART_DELIVERY_PRICE,
      payload: {
        shipping,
      },
    })
    yield put({
      type: actions.CART_TOTAL,
    })
    return true
  } catch (e) {
    console.log('*watchShipping', e)
    yield put({
      type: actions.CART_DELIVERY_PRICE_ERROR,
    })
    return false
  }
}

/**
 * Checkout generator
 * @param {Object} api API service
 * @param {Object} action the action Object
 * @return {Boolean} did the saga complete without error
 */
export function* watchCheckout(api, action) {
  try {
    yield put({ type: actions.CART_LOADING, payload: { loading: true } })
    const {
      data,
      stripeError,
      stripePM,
      handleCardAction,
      history,
    } = action.payload
    const authorization = yield select(fromUsers.getAuth)

    if (stripeError) {
      // TODO dispatch toast?
      yield put({
        type: 'CHECKOUT_ERROR',
        payload: { checkoutError: true, error_details: stripeError },
      })
      return false
    }
    const cart = yield select(fromCart.getCart)
    const shop = yield select(fromShops.getShop)
    const { entityId } = cart.content.find(Boolean)
    const clientLang = yield select(fromLocale.getLocale)
    const order = formatOrder({ ...cart, entityId }, data, shop, stripePM, clientLang)
    const sentOrder = yield call([api, api.post], '/order', order, {
      headers: {
        authorization,
      },
    })
    // NOTE SCA support (we need to POST a second time after having validated the token)
    if (sentOrder.requires_action) {
      const handleCard = yield call(handleCardAction, sentOrder.action_token)
      if (handleCard.error) {
        yield put({
          type: 'CHECKOUT_ERROR',
          payload: { checkoutError: true, error_details: handleCard.error.code },
        })
        yield put({ type: actions.CART_LOADING, payload: { loading: false } })
        return false
      }
      order.transaction = {
        ...order.transaction,
        source: { ...order.transaction.source, id: handleCard.paymentIntent.id },
      }
      yield call([api, api.post], '/order', order)
    }

    yield put({ type: actions.DISMISS_CHECKOUT_ERROR })
    yield put({ type: orderActions.FETCH_FORCE, payload: { force: true } })
    const { customerOrderId, deliveryDelays } = order
    const {
      deliveryMin,
      deliveryMax,
    } = deliveryDelays
    // NOTE redirect to TY
    const description = (
      <FormattedMessage
        id='store.cart.helper1'
        defaultMessage='Vous allez recevoir par mail votre confirmation de commande. Votre commande sera expédiée en {min} à {max} jours ouvrés.'
        values={{ min: deliveryMin, max: deliveryMax }}
      />
    )
    notification.open({
      message: (<FormattedMessage id='store.cart.orderValidated' defaultMessage='Commande validée !' />),
      description,
      duration: 0,
      icon: <Icon type='check-circle' style={{ color: theme.primaryColor[6] }} />,
      btn: (
        <Button type='primary' onClick={() => history.push(`/orders?customerOrderId=${customerOrderId}`)}>
          Voir ma commande
        </Button>),
    })
    yield put({ type: actions.CART_LOADING, payload: { loading: false } })
    yield put({ type: 'EMPTY' })
    yield put({ type: stocksActions.GET_STOCK })
    return true
  } catch (e) {
    console.log('watchCheckout', {
      level: 'error',
      error: e,
      error_details: e && e.message ? e.message : e,
    })
    yield put({ type: actions.CART_LOADING, payload: { loading: false } })
    yield put({
      type: actions.CHECKOUT_ERROR,
      payload: {
        checkoutError: true,
        error_details: e && e.message ? e.message : e,
      },
    })
    return false
  }
}

export function* emptyCart() {
  try {
    yield put({
      type: actions.EMPTY,
    })
  } catch (e) {
    console.log('emptyCart', {
      level: 'error',
      error: e,
      error_details: e && e.message ? e.message : e,
    })
  }
}

export default function* ({ api }) {
  yield takeEvery(actions.ADD, watchPricing, api)
  yield takeEvery(actions.REMOVE, watchPricing, api)
  yield takeEvery(actions.INCREMENT, watchPricing, api)
  yield takeEvery(actions.DECREMENT, watchPricing, api)
  yield takeEvery(actions.ADD, watchShipping, api)
  yield takeEvery(actions.REMOVE, watchShipping, api)
  yield takeEvery(actions.INCREMENT, watchShipping, api)
  yield takeEvery(actions.DECREMENT, watchShipping, api)
  yield takeEvery(actions.UPDATE_DELIVERY_MODE, watchShipping, api)
  yield takeEvery(actions.COUNTRY, watchShipping, api)
  yield takeEvery(actions.CHECKOUT, watchCheckout, api)
  yield takeEvery(shopActions.CHANGE_SHOP, emptyCart)
}

/* eslint-disable */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import {
  Divider,
  Button,
  Checkbox,
} from 'antd'

import { neutralColor } from '../../config/theme'
import { fromShops, fromOrders } from '../../store/selectors'
import {
  CANCEL_GIFT,
  GIFT_TO_BILLING,
} from '../../store/orders/actions'

class GiftView extends Component {
  constructor() {
    super()
    this.state = {
      billing: false,
    }
  }

  noMarginTransactionAmount = () => {
    const { detailedOrder } = this.props
    try {
      return detailedOrder.transaction.amount
      - (detailedOrder.products.reduce((
        accumulator,
        product,
      ) => accumulator + (product.margin * product.quantity), 0) * 100)
    } catch (e) {
      console.log('error noMarginTransactionAmount', e)
      return detailedOrder.transaction.amount
    }
  }

  validateGiftOrder = async () => {
    const {
      detailedOrder,
      giftToBilling,
    } = this.props
    return giftToBilling(detailedOrder._id)
  }

  onChangeBilling = () => {
    const {
      billing,
    } = this.state
    this.setState({ billing: !billing })
  }

  displayCheckboxBilling = () => {
    const {
      shopSettings,
    } = this.props
    const {
      billing,
    } = this.state

    if (shopSettings.enableMonthlyInvoicing
      && !(shopSettings.gift && shopSettings.gift.autoValidate)) {
      return (
        <Checkbox
          onChange={this.onChangeBilling}
          checked={billing}
          style={{ width: '100%', marginBottom: 10 }}
        >
          <FormattedMessage id='giftView.addToInvoice' defaultMessage='Ajouter à ma facture mensuelle' />
        </Checkbox>
      )
    } return null
  }

  render() {
    const {
      detailedOrder,
      cancelGiftOrder,
      modifiedOrders,
      orderLoading,
    } = this.props

    if (!detailedOrder.giftOrder || (detailedOrder.status !== 'Waiting' && detailedOrder.status !== 'Cancelled')) return null

    const modifiedOrder = modifiedOrders.find((order) => order._id === detailedOrder._id)
    if ((detailedOrder.giftOrder && detailedOrder.status === 'Cancelled')
      || (modifiedOrder && modifiedOrder.status === 'Cancelled')) {
      return (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Divider />
          <FormattedMessage id='giftView.canceledGiftOrder' defaultMessage='Cette commande cadeau a été annulée' />
        </div>
      )
    }

    return (
      <div>
        <Divider />
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative',
          padding: 10,
        }}
        >
          {orderLoading ? (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: neutralColor[6],
                opacity: 0.6,
                zIndex: 10,
                borderRadius: '5px',
              }}
            />
          ) : null}
          <span role='img' aria-label='gift'>
            <FormattedMessage id='giftView.giftOrder' defaultMessage='Cette commande est un cadeau 🎁' />
          </span>
          <span style={{ margin: '10px 0' }}>
            <FormattedMessage
              id='giftView.description.firstLine'
              defaultMessage="La commande sera envoyée en production une fois que vous l'aurez validée."
            />
            <br />
            <FormattedMessage
              id='giftView.description.secondLine'
              defaultMessage="L'acheteur recevra un email de notification à ce moment."
            />
            <br />
            <FormattedMessage
              id='giftView.description.thirdLine'
              defaultMessage="Vous pouvez aussi décider de l'annuler."
            />
          </span>
          {this.displayCheckboxBilling()}
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Button
              type='primary'
              onClick={() => this.validateGiftOrder()}
            >
              <FormattedMessage id='validate' defaultMessage='Valider' />
            </Button>
            <Button
              onClick={() => cancelGiftOrder(detailedOrder._id)}
            >
              <FormattedMessage id='cancel' defaultMessage='Annuler' />
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  shopSettings: fromShops.getShopSettings(state),
  modifiedOrders: fromOrders.getModifiedOrders(state),
  orderLoading: fromOrders.orderLoading(state),
}), (dispatch) => bindActionCreators({
  cancelGiftOrder: (order_id) => ({
    type: CANCEL_GIFT,
    payload: {
      order_id,
    },
  }),
  giftToBilling: (order_id) => ({
    type: GIFT_TO_BILLING,
    payload: {
      order_id,
    },
  }),
}, dispatch))(GiftView)

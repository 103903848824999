import React from 'react'
import moment from 'moment'
import {
  Table,
  Tag,
  Icon,
  Popover,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { trimRecordForUsersTable, displayDate } from '../../../helpers/trim'
import getUserCreationMailTemplate from '../../../helpers/userCreationMail'

const Users = (props) => {
  const {
    users, shop, locale, resendUserCreationMail,
  } = props

  const { _id } = shop

  moment.locale(locale)

  const displayStatut = (status) => {
    switch (status) {
    case 'completed':
      return (
        <FormattedMessage
          id='entities.completedStatusCollab'
          defaultMessage='Complété'
        />
      )
    case 'pending':
    default:
      return (
        <FormattedMessage
          id='entities.pendingStatusCollab'
          defaultMessage='En attente'
        />
      )
    }
  }
  const columns = [
    {
      title: (
        <div style={{ fontWeight: 'bold' }}>
          <FormattedMessage
            id='entities.userColumnTitle'
            defaultMessage='Utilisateur'
          />
        </div>
      ),
      align: 'center',
      key: 'name',
      dataIndex: 'formatted.name',
      render: (name) => name || 'N/C',
    },
    {
      title: (
        <div style={{ fontWeight: 'bold' }}>
          <FormattedMessage id='email' defaultMessage='Email' />
        </div>
      ),
      align: 'center',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: (
        <div style={{ fontWeight: 'bold' }}>
          <FormattedMessage id='phone' defaultMessage='Téléphone' />
        </div>
      ),
      align: 'center',
      key: 'phone',
      dataIndex: 'phone',
      render: (phone) => phone || 'N/C',
    },
    {
      title: (
        <div style={{ fontWeight: 'bold' }}>
          <FormattedMessage id='language' defaultMessage='Langue' />
        </div>
      ),
      align: 'center',
      key: 'language',
      dataIndex: 'language',
      render: (language) => (language ? language.toUpperCase() : 'N/C'),
    },
    {
      title: (
        <div style={{ fontWeight: 'bold' }}>
          <FormattedMessage
            id='entities.lastActiveColumnTitle'
            defaultMessage='Dernière activité'
          />
        </div>
      ),
      align: 'center',
      key: 'lastActive',
      dataIndex: 'lastActive',
      render: (lastActive) => (lastActive ? displayDate(lastActive, locale) : 'N/C'),
    },
    {
      title: (
        <div style={{ fontWeight: 'bold' }}>
          <FormattedMessage id='status' defaultMessage='Statut' />
        </div>
      ),
      align: 'center',
      key: 'status',
      dataIndex: 'status',
      render: (status, user) => {
        const { email, name } = user
        const template = getUserCreationMailTemplate(user, shop)
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {status === 'completed' ? null
              : (
                <Popover content={<FormattedMessage id='member.resendMail' defaultMessage='Renvoyer le mail' />}>
                  <Icon
                    type='reload'
                    style={{ cursor: 'pointer', fontSize: '15px', marginRight: 10 }}
                    onClick={() => resendUserCreationMail({
                      email,
                      name,
                      shopId: _id,
                      template,
                    })}
                  />
                </Popover>
              )}
            <Tag color={status === 'completed' ? 'green' : 'orange'}>
              {displayStatut(status)}
            </Tag>
          </div>
        )
      },
    },
    {
      title: (
        <div style={{ fontWeight: 'bold' }}>
          <FormattedMessage
            id='entities.adminColumnTitle'
            defaultMessage='Admin'
          />
        </div>
      ),
      align: 'center',
      key: 'admin',
      dataIndex: 'admin',
      render: (admin) => (admin ? <Icon type='check' /> : null),
    },
  ]

  const formatted = users.map((user) => trimRecordForUsersTable(user))
  return (
    <Table
      dataSource={formatted}
      columns={columns}
      pagination={users.length >= 15 ? {} : false}
    />
  )
}

export default Users

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import { SocialNetworksForm } from '../../components'
import { MODIFY } from '../../store/shops/actions'
import { fromShops } from '../../store/selectors'

const { Title } = Typography

export default connect(
  (state) => ({
    shopSettings: fromShops.getShopSettings(state),
    shop: fromShops.getShop(state),
  }),
  (dispatch) => bindActionCreators({
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)(({
  shopSettings,
  modifyShop,
  shop,
}) => (
  <div id='socials'>
    <Title level={2}>
      <FormattedMessage
        id='settings.socials'
        defaultMessage='Réseaux Sociaux'
      />
    </Title>
    <p>
      <FormattedMessage
        id='settings.socials.helper'
        defaultMessage='Ajoutez les liens de vos réseaux sociaux sur votre boutique'
      />
    </p>
    <SocialNetworksForm
      shopSettings={shopSettings}
      modifyShop={modifyShop}
      shop={shop}
    />
  </div>
))

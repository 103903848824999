module.exports = ([
  {
    name: 'MagicLinks',
    scopes: ['Post', 'Get'],
  },
  {
    name: 'Campaigns',
    scopes: ['Get'],
  },
  {
    name: 'Users',
    scopes: ['Get'],
  },
  {
    name: 'Budgets',
    scopes: ['Get', 'Put'],
  },
  {
    name: 'Members',
    scopes: ['Post', 'Get', 'Put'],
  },
  {
    name: 'Orders',
    scopes: ['Get'],
  },
  {
    name: 'Products',
    scopes: ['Get'],
  },
])

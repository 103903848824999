import React from 'react'
import { Avatar, Row } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import theme from '../../../config/theme'

const StepCardForm = (props) => {
  const {
    children,
    avatarIcon,
    shape = 'square',
    size = 'large',
    backgroundColor = theme.tertiaryColor[1],
    color = theme.tertiaryColor[7],
    titleMessageId,
    titleDefaultMessage,
    descriptionMessageId,
    descriptionDefaultMessage,
    isSingleCard = false,
    rightHeaderElement,
    descriptionValues = {},
    isDisabled = false,
  } = props

  return (
    <div style={{
      width: isSingleCard ? '100%' : '50%',
      backgroundColor: theme.white,
      borderRadius: '16px',
      boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.03)',
    }}
    >
      <div style={{
        margin: '24px 24px 0px 24px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
      }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <Avatar
              icon={<FontAwesomeIcon icon={avatarIcon} />}
              shape={shape}
              size={size}
              style={{ backgroundColor, color }}
            />
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '16px',
            ...(isDisabled && { justifyContent: 'center' }),
          }}
          >
            <span style={{
              fontWeight: '600',
              fontSize: '16px',
              display: 'block',
              ...(!isDisabled && { flex: 'auto' }),
            }}
            >
              <FormattedMessage
                id={titleMessageId}
                defaultMessage={titleDefaultMessage}
              />
            </span>
            {!isDisabled && (
              <span style={{
                display: 'block',
                color: theme.neutralColor[9],
                fontSize: '12px',
              }}
              >
                <FormattedMessage
                  id={descriptionMessageId}
                  defaultMessage={descriptionDefaultMessage}
                  values={descriptionValues}
                />
              </span>
            )}
          </div>

        </div>
        {rightHeaderElement && (
          <Row style={{ display: 'flex', flexDirection: 'row' }} gutter={12}>
            {rightHeaderElement}
          </Row>
        )}
      </div>
      <div style={{ margin: '32px 24px 24px 24px' }}>
        {children}
      </div>
    </div>
  )
}

export default StepCardForm

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ShipupDrawer } from '../../components'
import { shipupSubscribe } from '../../store/orders/actions'

const ShipupContainer = (props) => (
  <ShipupDrawer {...props} />
)

export default connect(
  null,
  (dispatch) => bindActionCreators({
    subscribe: (orders, lang) => shipupSubscribe(orders, lang),
  }, dispatch),
)(ShipupContainer)

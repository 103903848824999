/* eslint-disable camelcase */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import {
  Input,
  Form,
  Select,
} from 'antd'

import { MODIFY } from '../../store/orders/actions'

const { Option } = Select
const { Item } = Form

const TrackingView = (props) => {
  const intl = useIntl()

  const {
    subOrder,
    form,
  } = props

  const { getFieldDecorator } = form

  const handleSubmit = (e) => {
    try {
      const {
        detailedOrder,
        modifyOrder,
      } = props
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          const new_subOrders = detailedOrder.subOrders
          const selfSendSubOrder = detailedOrder.subOrders.find((so) => so.supplier === '5b06affc8427184204089fa0')
          if (selfSendSubOrder) {
            const index = detailedOrder.subOrders.indexOf(selfSendSubOrder)
            selfSendSubOrder.status = values.status
            selfSendSubOrder.trackingURL = values.trackingURL
            new_subOrders.splice(index, 1, selfSendSubOrder)
          }
          modifyOrder(detailedOrder._id, {
            subOrders: new_subOrders,
          })
        }
      })
    } catch (err) {
      console.log(err, 'error handleSubmit - TrackingView')
    }
  }

  try {
    if (subOrder.supplier !== '5b06affc8427184204089fa0') return null
    return (
      <Form
        onSubmit={handleSubmit}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Item>
          {getFieldDecorator('trackingURL', {
            rules: [
              {
                message: <FormattedMessage id='trackingView.required.link' defaultMessage='Renseigner votre lien' />,
              },
            ],
          })(
            <Input
              type='text'
              placeholder={intl.formatMessage({
                id: 'trackingView.placeholder.trackingLink',
                defaultMessage: 'Ajouter mon lien de tracking',
              })}
            />,
          )}
        </Item>
        <Item>
          {getFieldDecorator('status', {
            rules: [
              {
                required: true,
                message: <FormattedMessage id='trackingView.required.status' defaultMessage='Sélectionner un status' />,
              },
            ],
          })(
            <Select
              placeholder={<FormattedMessage id='status' defaultMessage='Statut' />}
              style={{ width: '200px' }}
            >
              <Option value='In Production'>
                <FormattedMessage id='inProduction' defaultMessage='En Production' />
              </Option>
              <Option value='Shipped'>
                <FormattedMessage id='shipped.male' defaultMessage='Envoyé' />
              </Option>
              <Option value='Delivered'>
                <FormattedMessage id='delivered.male' defaultMessage='Livré' />
              </Option>
            </Select>,
          )}
        </Item>
        <Item>
          <FormattedMessage id='save' defaultMessage='Enregistrer' />
        </Item>
      </Form>
    )
  } catch (err) {
    console.log(err, 'error handleSubmit - TrackingView')
    return false
  }
}

export default connect(null, (dispatch) => bindActionCreators({
  modifyOrder: (order_id, modifications) => ({
    type: MODIFY,
    payload: {
      order_id,
      modifications,
    },
  }),
}, dispatch))(Form.create({ name: 'trackingURLModification' })(injectIntl(TrackingView)))

import { useState, useEffect } from 'react'

function useAddProducts() {
  const [products, addProducts] = useState([])

  const getProducts = () => products

  const pushProducts = (toAdd) => {
    try {
      if (toAdd && toAdd.length) {
        const newProducts = products.concat(toAdd)
        return addProducts(newProducts)
      }
    } catch (e) {
      console.log('error hooks/pushProducts', e)
      return products
    }
  }

  const removeProducts = (id) => {
    const newProducts = products.filter((p) => p.provisionalId !== id)
    return addProducts(newProducts)
  }

  const updateProduct = (id, modification, callback) => {
    const newProducts = products.map((p) => {
      if (p.provisionalId === id) {
        return ({
          ...p,
          ...modification,
        })
      } return p
    })
    addProducts(newProducts)
    if (callback) return callback()
    return true
  }

  return {
    getProducts,
    pushProducts,
    products,
    removeProducts,
    updateProduct,
  }
}

export default useAddProducts

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { uniq, without } from 'lodash'
import { decodeQuery } from '../../filters'
import { getPrice, cleanId } from '../creation'
import { trimSearch } from '../../trim'

const minPriceForTechnics = (technics = []) => {
  try {
    const minPrices = technics.map((technic) => {
      if (!technic || !technic.parameters) {
        return 0
      }
      const { parameters: { cost, stage }, idPanopli, onEstimate = false } = technic
      const minStage = Math.max(...stage)
      const minStageIndex = stage.indexOf(minStage)
      return {
        minPrice: cost[minStageIndex] || 0,
        idPanopli,
        onEstimate,
      }
    })
    return minPrices.sort((a, b) => a.minPrice - b.minPrice)
  } catch (e) {
    console.log('minPriceForTechics - error:', e)
    return null
  }
}

const minPriceForFamilies = (families = []) => {
  try {
    if (families.length) {
      return Math.min(...families.map((family) => family.priceImpact || 0))
    }
    return 0
  } catch (e) {
    return null
  }
}

const getMinimumBuyingPrice = (baseProduct) => {
  try {
    const { pricing: { costHT = 0 }, technics, families } = baseProduct
    const minimumTechnicsPrices = minPriceForTechnics(technics)
    const minForTechnic = minimumTechnicsPrices.find((t) => !t.onEstimate)
    const minColorPrices = minPriceForFamilies(families)
    return costHT + ((minForTechnic && minForTechnic.minPrice) || 0) + minColorPrices
  } catch (e) {
    console.log('error helpers/getMinimumBuyingPrice error:', e)
    return 'error'
  }
}

const minSellingPriceForTechnics = (technics = []) => {
  try {
    const minPrices = technics.map((technic) => {
      if (!technic || !technic.parameters) {
        return 0
      }
      const { parameters: { price, stage }, idPanopli, onEstimate = false } = technic
      const minStage = Math.max(...stage)
      const minStageIndex = stage.indexOf(minStage)
      return {
        minPrice: price[minStageIndex] || 0,
        idPanopli,
        onEstimate,
      }
    })
    return minPrices.sort((a, b) => a.minPrice - b.minPrice)
  } catch (e) {
    console.log('minPriceForTechics - error:', e)
    return null
  }
}

const getMinimumSellingPrice = (baseProduct) => {
  try {
    const { pricing: { costHT = 0, margin = 0 }, technics, families } = baseProduct
    const minimumTechnicsPrices = minSellingPriceForTechnics(technics)
    const minForTechnic = minimumTechnicsPrices.find((t) => !t.onEstimate)
    const minColorPrices = minPriceForFamilies(families)
    return getPrice(costHT, margin) + ((minForTechnic && minForTechnic.minPrice) || 0) + minColorPrices
  } catch (e) {
    console.log('error helpers/getMinimumSellingPrice error:', e)
    return 'error'
  }
}

const displayMinimumPrices = (baseProduct) => {
  try {
    const { technics } = baseProduct
    const minimumTechnicsPrices = minPriceForTechnics(technics)
    const hasOnlyEstimateTechnics = !!(minimumTechnicsPrices.filter((t) => t.onEstimate).length === minimumTechnicsPrices.length)
    if (hasOnlyEstimateTechnics) {
      return (
        <>
          <br />
          <FormattedMessage id='catalog.card.estimate' defaultMessage='Uniquement sur devis' />
        </>
      )
    }
    const buyingPrice = getMinimumBuyingPrice(baseProduct).toFixed(2)
    const sellingPrice = getMinimumSellingPrice(baseProduct).toFixed(2)
    return (
      <>
        <FormattedMessage
          id='catalog.card.buyingPrice'
          defaultMessage='PAHT à partir de : {buyingPrice} €'
          values={{ buyingPrice }}
        />
        <br />
        <FormattedMessage
          id='catalog.card.sellingPrice'
          defaultMessage='PVHT à partir de : {sellingPrice} €'
          values={{ sellingPrice }}
        />
      </>
    )
  } catch (e) {
    console.log('error helpers/displayMinimumPrices error:', e)
    return 'error'
  }
}

const getMinimumMOQ = (baseProduct) => {
  try {
    const result = baseProduct.technics.map(
      (technic) => {
        if (!technic || !technic.parameters) {
          return 'NaN'
        }
        return technic.parameters.moq
      },
    ).sort((a, b) => a - b)[0]
    return result
  } catch (e) {
    console.log('getMinimumMOQ error:', e)
    return null
  }
}

const trimmedTimeLimit = (baseProduct) => {
  try {
    const timeLimit = (baseProduct && baseProduct.caracteristics
    && baseProduct.caracteristics.delayDelivery) || ''
    if (typeof timeLimit === 'number') return timeLimit
    const index = timeLimit && timeLimit.includes('-') ? timeLimit.indexOf('-') + 1 : null
    const trimmed = index ? timeLimit.substring(index, index + 2) : undefined
    return parseInt(trimmed, 10)
  } catch (e) {
    console.log('trimmedTimeLimit error:', e)
    return null
  }
}

const unusedTechIdPanopliNumber = (technics, idPanopli) => {
  try {
    // we recover a list of technics which have pretty similar idPanopli
    const similarTechIdPanopli = uniq(technics.filter((tech) => tech.idPanopli?.includes(cleanId(idPanopli))))
    // and for each one we recover the number at the end of the idPanopli
    const allReadyUsedNumbers = similarTechIdPanopli.map((t) => t.idPanopli.split('-tech-').pop())
      // and we sort this list of number in descending order
      .sort((a, b) => b - a)
      // we pick up the fist one (the most elevated) to parse it (or zero in there's no tech with a similar idPanopli)
    const number = parseInt(allReadyUsedNumbers[0], 10) || 0
    return number
  } catch (e) {
    console.log('unusedTechIdPanopliNumber error:', e)
    return false
  }
}

const modifyTechnicsIdPanopli = (baseProduct, value) => {
  try {
    const { technics = [], idPanopli = '' } = baseProduct
    const technicsToModify = technics.filter((tech) => !Object.keys(tech).find((key) => key === '_id'))
    if (technicsToModify.length) {
      let unusedNumber = unusedTechIdPanopliNumber(technics, idPanopli)
      const rebuiltTechnics = technics.map((tech) => {
        if (technicsToModify.some((t) => t.name === tech.name)) {
          // if the tech has an previous id we recover its number
          const number = tech.idPanopli?.split('-tech-').pop()
            // else for each tech without idPanopli, the number is increased from unused number
            || (unusedNumber += 1)
          const newIdPanopli = cleanId(`${value}-tech-${number}`)
          return { ...tech, idPanopli: newIdPanopli }
        }
        return tech
      })
      return rebuiltTechnics
    }
    return true
  } catch (e) {
    console.log('modifyTechnicsIdPanopli error:', e)
    return false
  }
}

const modifyFamiliesIdPanopli = (baseProduct, value) => {
  try {
    const { families = [] } = baseProduct
    if (families.length) {
      const rebuiltFamilies = families.map((family) => {
        const splitted = family.idPanopli?.split('-')
        const type = splitted[splitted.length - 2]
        const number = splitted[splitted.length - 1]
        const endOfId = `${type}-${number}`
        const newIdPanopli = cleanId(`${value}-${endOfId}`)
        return { ...family, idPanopli: newIdPanopli }
      })
      return rebuiltFamilies
    }
    return families
  } catch (e) {
    console.log('modifyFamiliesIdPanopli error:', e)
    return false
  }
}

const getFiltersFromURL = (search) => {
  try {
    const filters = decodeQuery(search)
    if (filters.category) filters.category = filters.category.split(',')
    if (filters.subCategory) filters.subCategory = filters.subCategory.split(',')
    if (filters.tags) filters.tags = filters.tags.split(',')
    if (filters.material) filters.material = filters.material.split(',')
    if (filters.origin) filters.origin = filters.origin.split(',')
    if (filters.family) filters.family = filters.family.split(',')
    if (filters.colors) filters.colors = filters.colors.split(',')
    if (filters.provider) filters.provider = filters.provider.split(',')
    if (filters.status) filters.status = filters.status.split(',')
    return filters
  } catch (e) {
    console.log('getFiltersFromURL error:', e)
    return null
  }
}

const keywordSearch = (bp = {}, allProviders = [], search = '') => {
  try {
    const filters = getFiltersFromURL(search)
    const provider = allProviders.find((pro) => bp.providerId === pro._id) || {}
    const input = trimSearch(filters.keyword)
    if (bp._id && trimSearch(bp._id).includes(input)) return true
    if (bp.idPanopli && trimSearch(bp.idPanopli).includes(input)) return true
    if (bp.name?.fr && trimSearch(bp.name.fr).includes(input)) return true
    if (bp.category?.fr && Object.values(bp.category).some(
      (language) => language.some(
        (cat) => trimSearch(cat).includes(input),
      ),
    )) return true
    if (bp.subCategory?.fr && trimSearch(bp.subCategory.fr).includes(input)) return true
    if (bp.caracteristics?.originProduct && trimSearch(bp.caracteristics.originProduct).includes(input)) return true
    if (bp.technics && bp.technics.some(
      (tech) => (tech && tech.name && trimSearch(tech.name).includes(input))
      || (tech && tech.idPanopli && trimSearch(tech.idPanopli).includes(input))
      || (tech && tech.family && trimSearch(tech.family).includes(input)),
    )) return true
    if (provider.name && trimSearch(provider.name).includes(input)) return true
    if (bp.providerId && trimSearch(bp.providerId).includes(input)) return true
    if (bp.providerProductId && trimSearch(bp.providerProductId).includes(input)) return true
    if (bp.supplierProductUrl && trimSearch(bp.supplierProductUrl).includes(input)) return true
    if (bp.supplierBaseProductReference && trimSearch(bp.supplierBaseProductReference).includes(input)) return true
    if (bp.status && bp.status.some(
      (stat) => trimSearch(stat).includes(input),
    )) return true
    if (bp.baseProductInfo?.detail?.fr && trimSearch(bp.baseProductInfo.detail.fr).includes(input)) return true
    if (bp.tags && bp.tags.some((tag) => trimSearch(tag).includes(input))) return true
    if (bp.caracteristics?.constitution && trimSearch(bp.caracteristics.constitution).includes(input)) return true
    if (bp.variations && bp.variations.some(
      (variation) => variation.type === 'color'
      && trimSearch(variation.name).includes(input),
    )) return true
    return false
  } catch (e) {
    console.log('keywordSearch error:', e)
    return []
  }
}

const getFilteredBPs = (baseProducts, allProviders = [], search = '', concerned = '') => {
  try {
    const filters = getFiltersFromURL(search)
    let filteredBPs = baseProducts
    if (!filteredBPs.length) {
      return filteredBPs
    }
    if (filters.keyword && concerned !== 'keyword') {
      filteredBPs = filteredBPs.filter(
        (bp) => keywordSearch(bp, allProviders, search),
      )
    }
    if (filters.category && concerned !== 'category') {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.category
        && bp.category.fr
        && filters.category.includes(bp.category.fr[0]),
      )
    }
    if (filters.subCategory && concerned !== 'subCategory') {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.subCategory
        && bp.subCategory.fr
        && filters.subCategory.includes(bp.subCategory.fr),
      )
    }
    if (filters.tags && concerned !== 'tags') {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.tags
        && filters.tags.some(
          (tag) => bp.tags.includes(tag),
        ),
      )
    }
    if (filters.material && concerned !== 'material') {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.caracteristics
        && bp.caracteristics.constitution
        && filters.material.includes(bp.caracteristics.constitution),
      )
    }
    if (filters.origin && concerned !== 'origin') {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.caracteristics
        && bp.caracteristics.originProduct
        && filters.origin.includes(bp.caracteristics.originProduct),
      )
    }
    if (filters.family && concerned !== 'family') {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.technics && bp.technics.length && bp.technics.some(
          (tech) => {
            if (tech && tech.family) {
              return filters.family.includes(String(tech.family))
            } return false
          },
        ),
      )
    }
    if (filters.colors && concerned !== 'colors') {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.variations
        && filters.colors.some(
          (color) => bp.variations.some(
            (variation) => variation.type === 'color'
            && variation.name === color,
          ),
        ),
      )
    }
    if (filters.numberOfColors) {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.technics
        && bp.technics.some(
          (tech) => {
            if (tech && tech.colorSupported) {
              return tech.colorSupported === filters.numberOfColors
            } return false
          },
        ),
      )
    }
    if (filters.provider && concerned !== 'provider') {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.providerId
        && filters.provider.includes((allProviders.find((pro) => bp.providerId === pro._id) || {}).name),
      )
    }
    if (filters.status && concerned !== 'status') {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.status
        && filters.status.some(
          (status) => bp.status.includes(status),
        ),
      )
    }
    if (filters.deliveryTime) {
      filteredBPs = filteredBPs.filter(
        (bp) => bp.caracteristics
        && bp.caracteristics.delayDelivery
        && trimmedTimeLimit(bp)
        && trimmedTimeLimit(bp) <= filters.deliveryTime,
      )
    }
    if (filters.moq) {
      filteredBPs = filteredBPs.filter(
        (bp) => getMinimumMOQ(bp) <= filters.moq,
      )
    }
    if (filters.buyingPrice) {
      filteredBPs = filteredBPs.filter(
        (bp) => getMinimumBuyingPrice(bp) <= filters.buyingPrice,
      )
    }
    if (filters.sellingPrice) {
      filteredBPs = filteredBPs.filter(
        (bp) => getMinimumSellingPrice(bp) <= filters.sellingPrice,
      )
    }
    if (filters.oos) {
      switch (filters.oos) {
      case 'only':
        filteredBPs = filteredBPs.filter(
          (bp) => bp.caracteristics?.OOS,
        )
        break
      case 'hide':
        filteredBPs = filteredBPs.filter(
          (bp) => !bp.caracteristics?.OOS,
        )
        break
      case 'all':
      default:
        break
      }
    }
    return filteredBPs
  } catch (e) {
    console.log('getFilteredBPs error:', e)
    return []
  }
}

const optionsList = (result) => without(uniq(result), null, undefined, '').sort()

// Note : waiting for AntD V4 to transform the keywordSearch in a Select, refering code has been silenced in form of comments

// const getAllOptions = (baseProducts, allProviders = [], search = '') => {
//   try {
//     const result = []
//     getFilteredBPs(baseProducts, allProviders, search, 'keyword').map(
//       (bp) => {
//         result.push(
//           // bp._id,
//           bp.baseProductInfo && bp.baseProductInfo.detail.fr,
//           bp.category && bp.category.fr.find(Boolean),
//           bp.subCategory && bp.subCategory.fr,
//           bp.caracteristics && bp.caracteristics.constitution,
//           bp.caracteristics && bp.caracteristics.originProduct,
//           bp.provider && bp.provider.name,
//         )
//         bp.tags?.map((tag) => result.push(tag))
//         bp.technics?.map((tech) => result.push(tech.name))
//         bp.variations?.map((variation) => (variation.type === 'color'
//           ? result.push(variation.name) : null))
//         bp.status?.map((status) => result.push(status))
//         return null
//       },
//     )
//     return optionsList(result)
//   } catch (e) {
//     console.log('getAllOptions', e)
//     return []
//   }
// }

const getAllCategories = (baseProducts, allProviders = [], search = '') => {
  try {
    const result = getFilteredBPs(baseProducts, allProviders, search, 'category').map(
      (bp) => bp.category && bp.category.fr.find(Boolean),
    )
    return optionsList(result)
  } catch (e) {
    console.log('getAllCategories error:', e)
    return []
  }
}

const getAllSubCategories = (baseProducts, allProviders = [], search = '') => {
  try {
    const result = getFilteredBPs(baseProducts, allProviders, search, 'subCategory').map(
      (bp) => bp.subCategory && bp.subCategory.fr,
    )
    return optionsList(result)
  } catch (e) {
    console.log('getAllSubCategories error:', e)
    return []
  }
}

const getAllTags = (baseProducts, allProviders = [], search = '') => {
  try {
    const result = []
    getFilteredBPs(baseProducts, allProviders, search, 'tags').forEach(
      (bp) => bp.tags && bp.tags.forEach(
        (tag) => result.push(tag),
      ),
    )
    return optionsList(result)
  } catch (e) {
    console.log('getAllTags error:', e)
    return []
  }
}

const getAllMaterials = (baseProducts, allProviders = [], search = '') => {
  try {
    const result = getFilteredBPs(baseProducts, allProviders, search, 'material').map(
      (bp) => bp.caracteristics && bp.caracteristics.constitution,
    )
    return optionsList(result)
  } catch (e) {
    console.log('getAllMaterials error:', e)
    return []
  }
}

const getAllOrigins = (baseProducts, allProviders = [], search = '') => {
  try {
    const result = getFilteredBPs(baseProducts, allProviders, search, 'origin').map(
      (bp) => bp.caracteristics && bp.caracteristics.originProduct,
    )
    return optionsList(result)
  } catch (e) {
    console.log('getAllOrigins error:', e)
    return []
  }
}

// const getAllTechniques = (baseProducts, allProviders = [], search = '') => {
//   try {
//     const result = []
//     getFilteredBPs(baseProducts, allProviders, search).forEach(
//       (bp) => bp.technics && bp.technics.forEach(
//         (tech) => result.push(tech.name),
//       ),
//     )
//     return optionsList(result)
//   } catch (e) {
//     console.log('getAllTechniques', e)
//     return []
//   }
// }

const getAllColors = (baseProducts, allProviders = [], search = '') => {
  try {
    const result = []
    getFilteredBPs(baseProducts, allProviders, search, 'colors').forEach(
      (bp) => bp.variations && bp.variations.forEach(
        (variation) => (variation.type === 'color'
          ? result.push(variation.name) : null),
      ),
    )
    return optionsList(result)
  } catch (e) {
    console.log('getAllColors error:', e)
    return []
  }
}

const getAllProviders = (baseProducts, allProviders = [], search = '') => {
  try {
    const result = getFilteredBPs(baseProducts, allProviders, search, 'provider').map(
      (bp) => {
        const prov = allProviders.find((pro) => bp.providerId === pro._id)
        const { name } = prov || {}
        return name || 'not found'
      },
    )
    return optionsList(result)
  } catch (e) {
    console.log('getAllProviders error:', e)
    return []
  }
}

const getAllStatus = (baseProducts, allProviders = [], search = '') => {
  try {
    const result = []
    getFilteredBPs(baseProducts, allProviders, search, 'status').forEach(
      (bp) => bp.status && bp.status.forEach(
        (status) => result.push(status),
      ),
    )
    return optionsList(result)
  } catch (e) {
    console.log('getAllStatus error:', e)
    return []
  }
}

// const filtersRecap = (search) => {
//   try {
//     const filters = getFiltersFromURL(search)
//     return (
//       <span style={{
//         border: 'solid 1px',
//         borderColor: '#d9d9d9',
//         borderRadius: '4px',
//         padding: '5px 0px 4px 15px',
//         marginRight: '7px',
//       }}
//       >
//         {filters.keyword ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Mot-clé : ${filters.keyword}`}
//           </span>
//         ) : null}
//         {filters.category ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Catégorie : ${filters.category}`}
//           </span>
//         ) : null}
//         {filters.subCategory ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Sous-catégorie : ${filters.subCategory}`}
//           </span>
//         ) : null}
//         {filters.tags ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Tags : ${filters.tags.map((f) => ` ${f}`)}`}
//           </span>
//         ) : null}
//         {filters.material ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Matière : ${filters.material}`}
//           </span>
//         ) : null}
//         {filters.origin ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Origine : ${filters.origin}`}
//           </span>
//         ) : null}
//         {filters.family ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Famille : ${filters.family}`}
//           </span>
//         ) : null}
//         {filters.techniques ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Technique : ${filters.techniques.map((f) => ` ${f}`)}`}
//           </span>
//         ) : null}
//         {filters.colors ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Couleur : ${filters.colors.map((f) => ` ${f}`)}`}
//           </span>
//         ) : null}
//         {filters.numberOfColors ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Couleurs supportées : ${filters.numberOfColors}`}
//           </span>
//         ) : null}
//         {filters.provider ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Fournisseur : ${filters.provider}`}
//           </span>
//         ) : null}
//         {filters.status ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Statut : ${filters.status.map((f) => ` ${f}`)}`}
//           </span>
//         ) : null}
//         {filters.deliveryTime ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Délai de livraison : sous ${
//               filters.deliveryTime === 1 ? '24 heures'
//                 : `${filters.deliveryTime} jours`
//             }`}
//           </span>
//         ) : null}
//         {filters.moq ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`M.O.Q. : ${filters.moq}`}
//           </span>
//         ) : null}
//         {filters.buyingPrice ? (
//           <span style={{ paddingRight: '15px' }}>
//             {`Prix de base HT : ${filters.buyingPrice} €`}
//           </span>
//         ) : null}
//       </span>
//     )
//   } catch (e) {
//     console.log('filtersRecap error:', e)
//     return null
//   }
// }

export {
  displayMinimumPrices,
  trimmedTimeLimit,
  getMinimumMOQ,
  getFiltersFromURL,
  getFilteredBPs,
  // getAllOptions,
  getAllCategories,
  getAllSubCategories,
  getAllTags,
  getAllMaterials,
  getAllOrigins,
  // getAllTechniques,
  getAllColors,
  getAllProviders,
  getAllStatus,
  // filtersRecap,
  modifyTechnicsIdPanopli,
  modifyFamiliesIdPanopli,
}

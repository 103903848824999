import React, { useState, useEffect } from 'react'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { Select } from 'antd'
import { CustomizableSelect } from '..'
import { getGoogleFonts } from '../../../helpers/fonts'

const { Option } = Select

const CampaignFontSelect = (props) => {
  const {
    selectedFontName = '',
    setFont, // Required
    setNeedUpdateCampaign, // Required
    isDisabled = false,
  } = props

  const neededFontSizes = [
    'regular', // correspond to 400
    '500',
    '600',
    '800',
  ]

  // contains all fonts
  const [fonts, setFonts] = useState([])
  // contains fonts which match with the user input
  const [matchedFonts, setMatchedFonts] = useState([])

  const handleOnSearchFont = (fontName) => {
    if (fontName) {
      setMatchedFonts(fonts.filter((font) => font.family.includes(fontName)))
    }
  }

  const handleOnChangeFont = (fontName) => {
    const searchedFont = fonts.find((font) => font.family === fontName)
    if (searchedFont) {
      setFont(searchedFont?.family)
    }
    setNeedUpdateCampaign(true)
  }

  useEffect(() => {
    (async () => {
      const brutFonts = await getGoogleFonts()
      const neededFonts = brutFonts?.items?.filter(
        (font) => neededFontSizes.every(
          (fontSize) => font?.variants?.includes(fontSize),
        ),
      ) ?? []
      setFonts(neededFonts)
      setMatchedFonts(neededFonts)
      setFont(
        brutFonts?.items?.find(
          (font) => font?.family === selectedFontName,
        )?.family ?? 'Montserrat',
      )
    })()
  }, [])

  return (
    <CustomizableSelect
      showSearch
      value={selectedFontName}
      defaultActiveFirstOption
      onSearch={handleOnSearchFont}
      onChange={handleOnChangeFont}
      formattedMessageId='campaigns.create.step.theme.typo'
      formattedDefaultMessage='Typo'
      formattedMessageTooltipId='campaigns.create.step.theme.typo.tooltip'
      formattedDefaultMessageTooltip='La typographie sélectionnée sera appliquée à tout le template'
      icon={faCircleInfo}
      titleBlockStyle={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '8px',
      }}
      isDisabled={isDisabled}
    >
      {matchedFonts.map((font) => <Option value={font.family} key={font.family}>{font.family}</Option>)}
    </CustomizableSelect>
  )
}

export default CampaignFontSelect

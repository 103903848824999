import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { INIT_FETCH_DATA } from '../../store/invoices/actions'
import { InnerLayout } from '../../layouts'
import {
  Invoices,
} from '../../containers'
import { Refresher } from '../../components/molecules'

const InvoicesPage = (props) => {
  const { history, fetchData, shopId } = props

  useEffect(() => {
    fetchData()
  }, [shopId])

  return (
    <InnerLayout
      title={(
        <div style={{
          fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
        }}
        >
          <FormattedMessage
            id='invoices'
            defaultMessage='Facturation'
          />
        </div>
      )}
    >
      <Refresher fetchData={fetchData} />
      <Invoices history={history} />
    </InnerLayout>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(InvoicesPage))

import React from 'react'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import {
  Input,
  Divider,
} from 'antd'

export default injectIntl(({ setFilterName }) => {
  const intl = useIntl()
  return (
    <div style={{
      position: 'sticky',
      top: '20px',
      left: '20px',
    }}
    >
      <div style={{ fontWeight: 'bold' }}>
        <FormattedMessage id='filters' defaultMessage='Filtres' />
      </div>
      <Divider />
      <Input
        style={{ width: '175px' }}
        onPressEnter={(e) => setFilterName(e.target.value)}
        placeholder={intl.formatMessage({
          id: 'baseProductFilters.referenceOrName',
          defaultMessage: 'Nom / Référence',
        })}
      />
    </div>
  )
})

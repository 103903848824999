import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { CustomizableButton } from '../..'

const MagiclinksSendButton = (props) => {
  const {
    onClick, // Required
    type,
    style,
    intl,
  } = props
  return (
    <CustomizableButton
      onClick={onClick}
      style={style}
      type={type}
      content={(
        <>
          <FontAwesomeIcon icon={faPaperPlane} />
          {intl ? (
            intl.formatMessage({
              id: 'magicLink.send',
              defaultMessage: 'Envoyer des Magic Links',
            })
          ) : (
            <FormattedMessage id='magicLink.send' defaultMessage='Envoyer des Magic Links' />
          )}
        </>
      )}
    />
  )
}

export default MagiclinksSendButton

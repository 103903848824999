import React from 'react'
import { List } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { SkinFilled } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { CampaignCardSendButton, CampaignCardRemoveButton } from '../..'
import { character, neutralColor } from '../../../config/theme'
import { availableCampaignStatus, getCampaignStatusTag } from '../../../helpers/campaignStatus'

const detailsStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '20px',
  color: character.disabledPlaceholder,
  gap: '8px',
}

const CampaignCard = (props) => {
  const {
    campaign,
    magicLinks,
    history,
    setVisibleMagicLinksDrawer,
    setCurrentCampaignId,
    removeDraftCampaign,
  } = props

  return (
    <List.Item>
      <div style={{
        height: '210px',
        padding: '16px',
        position: 'relative',
        border: '1px solid',
        borderColor: neutralColor[2],
        borderRadius: '8px',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1)',
      }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '12px',
            cursor: 'pointer',
          }}
          onClick={() => (campaign?.status === availableCampaignStatus?.draft
            ? history.push(`/campaign/create?id=${campaign._id}`)
            : history.push(`/campaign/edit?id=${campaign._id}`))}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            <h3
              className='twoLinesEllipsis'
              style={{
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                color: character.title,
              }}
            >
              {campaign.name}
            </h3>
            <h5
              className='twoLinesEllipsis'
              style={{
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '20px',
              }}
            >
              {campaign.description}
            </h5>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '14px' }}>
                <h5 style={detailsStyle}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                  <FormattedMessage
                    id='campaign.card.sendings'
                    defaultMessage='{items, plural, =0 {# envoi} one {# envoi} other {# envois}}'
                    values={{
                      items: magicLinks.filter((magicLink) => magicLink?.campaignId === campaign?._id).length,
                    }}
                  />
                </h5>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h5 style={detailsStyle}>
                  <SkinFilled />
                  <FormattedMessage
                    id='campaign.card.products'
                    defaultMessage='{items, plural, =0 {# produit} one {# produit} other {# produits}}'
                    values={{
                      items: campaign?.products?.length,
                    }}
                  />
                </h5>
                {/* // TODO Actions  */}
              </div>
            </div>
            <div>
              {getCampaignStatusTag(campaign)}
            </div>
          </div>
        </div>
        {campaign.status === 'active' && ( // On limite les envois aux campagnes actives
          <CampaignCardSendButton
            onClick={() => {
              setCurrentCampaignId(campaign._id)
              setVisibleMagicLinksDrawer(true)
            }}
          />
        )}
        {campaign?.status === 'draft' && (
          <CampaignCardRemoveButton
            onClick={() => { removeDraftCampaign(campaign) }}
          />
        )}
      </div>
    </List.Item>
  )
}

export default CampaignCard

import config from '../../config'

export const initialState = {
  content: [],
  address: {
    country: 'FR',
  },
  client: {
    address: {
      country: {
        label: 'France Métropolitaine',
        value: 'FR',
      },
    },
  },
  deliveryMode: 'standard',
  products_pricing: {
    total: 0,
    campaign_discount: 0,
    code_discount: 0,
  },
  delivery_pricing: {
    delivery: 0,
    delivery_discounted: 0,
  },
  price: 0,
  cartRefractory: new Date(),
  campaign: {},
  checkoutError: false,
  toEdit: null,
  loading: false,
}

export const getCart = (state = initialState) => state

export const getContent = (state = initialState) => state.content

export const getClient = (state = initialState) => state.client

export const getForm = (state) => state.form

export const getCheckoutValues = (state) => {
  try {
    return state.form.checkout && state.form.checkout.values
  } catch (e) {
    console.log('selectors - getCheckoutValues', e)
    return {}
  }
}

export const getProducts = (state) => {
  try {
    return state.content
  } catch (e) {
    console.log('selectors - getProducts', e)
    return []
  }
}

export const getDeliveryMode = (state) => {
  try {
    return state.deliveryMode
  } catch (e) {
    console.log('selectors - getDeliveryMode', e)
    return []
  }
}

export const getProductsPricing = (state) => {
  try {
    return state.products_pricing
  } catch (e) {
    console.log('selectors - getProductsPricing', e)
    return 0
  }
}

export const getPrefilledAddress = (state) => {
  try {
    return state.isPrefilledAddress
  } catch (e) {
    console.log('selectors - getPrefilledAddress', e)
    return false
  }
}

export const getDeliveryPricing = (state) => {
  try {
    return state?.delivery_pricing
  } catch (e) {
    console.log('selectors - getDeliveryPricing', e)
    return {
      delivery: 0,
      delivery_discount: 0,
    }
  }
}

export const getDeliveryDelays = (state) => {
  try {
    const pricing = state ? getDeliveryPricing(state) : { deliveryMin: 5, deliveryMax: 7 }
    const { deliveryMin, deliveryMax } = pricing
    return { deliveryMin, deliveryMax }
  } catch (error) {
    console.log('getDeliveryDelays', error)
    return {
      deliveryMin: config.fallBacks.deliveries.deliveryMin,
      deliveryMax: config.fallBacks.deliveries.deliveryMax,
    }
  }
}

export const getPrice = (state) => {
  try {
    return state.price
  } catch (e) {
    console.log('selectors - getPrice', e)
    return NaN
  }
}

export const getToEdit = (state) => {
  try {
    return state.toEdit
  } catch (e) {
    console.log('selectors - getToEdit', e)
    return null
  }
}

export const getLoading = (state) => {
  try {
    return state.loading
  } catch (e) {
    console.log('selectors - getLoading', e)
    return false
  }
}

export const getError = (state) => {
  try {
    return state.checkoutError
  } catch (e) {
    console.log('selectors - getError', e)
    return false
  }
}

export const getErrorDetails = (state) => {
  try {
    return state.error_details
  } catch (error) {
    console.log('getErrorDetails', error)
    return null
  }
}

export const getGift = (state) => {
  try {
    return state.gift
  } catch (error) {
    console.log('getGift', error)
    return false
  }
}

export const getCountry = (state) => {
  try {
    return state.address.country
  } catch (error) {
    console.log('selectors - getCountryObject', error)
    return initialState.address.country
  }
}
export const getModes = (state) => {
  try {
    return state.modes
  } catch (error) {
    console.log('selectors - getModes', error)
    return null
  }
}

export const getCountryValue = (state) => {
  try {
    return getCountry(state).value
  } catch (error) {
    console.log('selectors - getCountryValue', error)
    return initialState.address.country.value
  }
}

import React from 'react'
import { Switch } from 'antd'
import { FormattedMessage } from 'react-intl'

import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

const HiddenCustomFooter = (props) => {
  try {
    const { newCustom, setNewCustom } = props
    const { footer } = newCustom
    const { display, displaySocial } = footer
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage
            id='custom.footer'
            defaultMessage='Footer'
          />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.footerDisplay'
                defaultMessage='Afficher le footer'
              />
            </div>
            <Switch
              checked={display}
              onChange={(value) => setNewCustom({ ...newCustom, footer: { ...footer, display: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.footerDisplaySocial'
                defaultMessage='Afficher les réseaux sociaux'
              />
            </div>
            <Switch
              checked={displaySocial}
              onChange={(value) => setNewCustom({ ...newCustom, footer: { ...footer, displaySocial: value } })}
            />
          </div>
          <div style={columnStyle} />
          <div style={columnStyle} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecule/HiddenCustomFooter error:', e)
    return null
  }
}

export default HiddenCustomFooter

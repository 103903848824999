import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PopoverColorPicker } from '..'

const PopoverMainColorPicker = (props) => {
  const {
    setMainColor, // Required
    mainColor, // Required
    setNeedUpdateCampaign, // Required
    isDisabled = false,
  } = props

  const [mainColorPopoverState, setMainColorPopoverState] = useState(false)

  const handleMainColorVisibleChange = (visible) => setMainColorPopoverState(visible)
  const onChangeCompleteMainColor = (selectedColor) => {
    setMainColor(selectedColor)
    setNeedUpdateCampaign(true)
  }

  return (
    <PopoverColorPicker
      popoverTitle={(
        <FormattedMessage
          id='theme.mainColor'
          defaultMessage='Couleur principale'
        />
      )}
      isPopoverVisible={mainColorPopoverState}
      popoverOnVisibleChange={handleMainColorVisibleChange}
      buttonStyle={{
        backgroundColor: mainColor, width: '49%', height: '40px',
      }}
      colorPickerColor={mainColor}
      onChangeColorPickerAction={(color) => onChangeCompleteMainColor(color)}
      isDisabled={isDisabled}
    />
  )
}

export default PopoverMainColorPicker

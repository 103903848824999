import React from 'react'
import { Popconfirm, Button } from 'antd'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { deleteDiscounts } from '../../store/discounts/actions'

const DeleteDiscounts = (props) => {
  const { selected, delete_discounts } = props
  return (
    <Popconfirm
      title={selected.length > 1
        ? (
          <FormattedMessage
            id='deleteDiscounts.title.plural'
            defaultMessage='Vous allez supprimer {number} promotions'
            values={{ number: selected.length }}
          />
        )
        : (
          <FormattedMessage
            id='deleteDiscounts.title.singular'
            defaultMessage='Vous allez supprimer {number} promotion'
            values={{ number: selected.length }}
          />
        )}
      onConfirm={() => delete_discounts(selected)}
      // okText='Confirmer'
      // cancelText='Annuler'
      key='delete'
    >
      <Button>
        <FormattedMessage id='delete' defaultMessage='Supprimer' />
      </Button>
    </Popconfirm>
  )
}

const mapDispatchToProps = (dispatch) => ({
  delete_discounts: (discount) => dispatch(deleteDiscounts(discount)),
})

export default connect(null, mapDispatchToProps)(DeleteDiscounts)

/* eslint-disable camelcase */
import React from 'react'
import {
  Icon,
  Dropdown,
  Menu,
  Button,
} from 'antd'
import { FormattedMessage } from 'react-intl'

import './style.css'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

class CardPayment extends React.Component {
  colorCreditCard = (brand) => {
    switch (brand) {
    case 'visa':
      return ['#99ceff', '#cce7ff']
    case 'mastercard':
      return ['#ff7d8c', '#ffc4cb']
    default:
      return ['#ffd87d', '#fff0cf']
    }
  }

  actionMenu = () => {
    const {
      paymentMethod,
      deletePaymentMethod,
      defaultPaymentMethod,
      disableDeleteAction,
    } = this.props

    return (
      <Menu>
        <Menu.Item disabled={disableDeleteAction}>
          <div onClick={() => deletePaymentMethod({ id: paymentMethod.id })}>
            <FormattedMessage id='delete' defaultMessage='Supprimer' />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => defaultPaymentMethod({ id: paymentMethod.id })}>
            <FormattedMessage id='cardPayment.defaultPayment' defaultMessage='Définir comme paiement par défaut' />
          </div>
        </Menu.Item>
      </Menu>
    )
  }

  render() {
    try {
      const { paymentMethod } = this.props

      if (paymentMethod.card) {
        const { card, defaultPayment = false } = paymentMethod
        const styleBackground = `linear-gradient(to bottom right, ${this.colorCreditCard(card.brand)[0]}, ${this.colorCreditCard(card.brand)[1]})`
        return (
          <div
            style={{ background: styleBackground }}
            className='card'
          >
            {
              defaultPayment ? (
                <span className='default-card'>
                  <FormattedMessage
                    id='cardPayment.byDefault'
                    defaultMessage='Par défaut'
                  />
                  <Icon type='check-circle' className='default-card-icon' />
                </span>
              ) : null
            }
            <Dropdown overlay={this.actionMenu()}>
              <Button className='action-button'>
                <Icon type='ellipsis' />
              </Button>
            </Dropdown>
            <span className='code'>
              {`···· ···· ···· ${card.last4}`}
            </span>
            <span className='expiration-date'>
              {`${card.exp_month}/${card.exp_year}`}
            </span>
            <img
              src={`${imageBaseUrl}/divers/paymentMethods/${card.brand}.png`}
              alt='paymentMethod-logo'
              className='brand-logo'
            />
          </div>
        )
      } if (paymentMethod.sepa_debit) {
        const { sepa_debit, defaultPayment } = paymentMethod

        return (
          <div className='sepa'>
            {
              defaultPayment ? (
                <span className='default-card'>
                  <FormattedMessage
                    id='cardPayment.byDefault'
                    defaultMessage='Par défaut'
                  />
                  <Icon type='check-circle' className='default-card-icon' />
                </span>
              ) : null
            }
            <Dropdown overlay={this.actionMenu()}>
              <Button className='action-button'>
                <Icon type='ellipsis' />
              </Button>
            </Dropdown>
            <span>
              {sepa_debit.country}
            </span>
            <span>
              {sepa_debit.bank_code}
            </span>
            <span>
              {sepa_debit.branch_code}
            </span>
            <span>
              {sepa_debit.last4}
            </span>
            <span className='iban-logo'>
              <FormattedMessage
                id='IBAN'
                defaultMessage='IBAN'
              />
            </span>
          </div>
        )
      } return null
    } catch (e) {
      console.log('molecules - CardPayment', e)
      return null
    }
  }
}

export default CardPayment

import React from 'react'
import {
  Input,
  Select,
  Button,
  Icon,
  Radio,
} from 'antd'
import { uniq } from 'lodash'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'

const { Option } = Select

export default injectIntl(({
  filters,
  filtering,
  products,
  entities,
}) => {
  const {
    name,
    sort = {},
    status,
    category,
    subCategory,
    entityId,
    stocked,
  } = filters

  const { date, stock } = sort

  const intl = useIntl()

  const handleCategoryChange = (value) => {
    filtering({ category: value })
  }

  const handleSubCategoryChange = (value) => {
    filtering({ subCategory: value })
  }

  const handleTypeChange = (value) => {
    filtering({ stocked: value })
  }

  const handleEntityChange = (value) => {
    filtering({ entityId: value })
  }

  const getCategories = () => {
    if (filters.subCategory) {
      return uniq(products.filter((product) => product.baseProduct
    && product.baseProduct.category
      && product.baseProduct.category.fr[0] && filters.subCategory === product.baseProduct.subCategory.fr).map((product) => product.baseProduct
        && product.baseProduct.category
        && product.baseProduct.category.fr[0]))
    }
    return uniq(products.map((product) => product.baseProduct
  && product.baseProduct.category
  && product.baseProduct.category.fr[0]))
  }

  const getSubCategories = () => {
    if (filters.category) {
      return uniq(products.filter((product) => product.baseProduct
    && product.baseProduct.subCategory
    && product.baseProduct.subCategory.fr && filters.category === product.baseProduct.category.fr[0]).map((product) => product.baseProduct
  && product.baseProduct.subCategory
  && product.baseProduct.subCategory.fr))
    }
    return uniq(products.map((product) => product.baseProduct
  && product.baseProduct.subCategory
  && product.baseProduct.subCategory.fr))
  }

  const displaySortIcon = (value) => {
    if (!value) {
      return null
    } if (value === 'ascend') {
      return <Icon type='caret-up' />
    } return <Icon type='caret-down' />
  }

  return (
    <div style={{ borderBottom: '1px solid #EAEAEA', borderTop: '1px solid #EAEAEA', padding: '10px 0' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <h4 style={{ fontWeight: 'bold', margin: '0 20px 0 10px' }}>
          <FormattedMessage id='filters' defaultMessage='Filtres' />
        </h4>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Input
            style={{ width: '150px', margin: '0px 5px 5px 0' }}
            onChange={(e) => {
              filtering({ name: e.target.value })
            }}
            placeholder={intl.formatMessage({
              id: 'productListHeader.placeholder.productName',
              defaultMessage: 'Nom du produit',
            })}
            value={name}
          />
          <Select
            id='selectType'
            allowClear
            style={{ width: '150px', margin: '0px 5px 5px 0' }}
            onChange={handleTypeChange}
            placeholder={<FormattedMessage id='productsType' defaultMessage='Type de produits' />}
            value={stocked}
          >
            <Option key='stocked' value='stocked'>
              <FormattedMessage id='stockedProducts' defaultMessage='Produits stockés' />
            </Option>
            <Option key='onDemand' value='onDemand'>
              <FormattedMessage id='onDemandProducts' defaultMessage='Produits à la demande' />
            </Option>
          </Select>
          <Select
            allowClear
            placeholder={<FormattedMessage id='category' defaultMessage='Catégorie' />}
            style={{ width: '150px', margin: '0px 5px 5px 0' }}
            onChange={handleCategoryChange}
            value={category}
          >
            {getCategories().map((cat) => <Option key={cat} value={cat}>{cat}</Option>)}
          </Select>
          <Select
            id='selectCategory'
            allowClear
            placeholder={<FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />}
            style={{ width: '150px', margin: '0px 5px 5px 0' }}
            onChange={handleSubCategoryChange}
            value={subCategory}
          >
            {getSubCategories().map((subCat) => <Option key={subCat} value={subCat}>{subCat}</Option>)}
          </Select>
          {entities.length > 1 ? (
            <Select
              allowClear
              style={{ width: '150px', margin: '0px 5px 5px 0' }}
              onChange={handleEntityChange}
              placeholder={<FormattedMessage id='entity' defaultMessage='Entité' />}
              value={entityId}
            >
              {entities.map((entity) => <Option key={entity._id} value={entity._id}>{entity.name}</Option>)}
            </Select>
          ) : null}
          <Button
            style={{ margin: '0px 5px 5px 0' }}
            onClick={() => filtering({
              sort: {
                date: (date === 'ascend' || !date) ? 'descend' : 'ascend',
              },
            })}
          >
            <FormattedMessage id='creationDate' defaultMessage='Date de création' />
            {displaySortIcon(date)}
          </Button>
          <Button
            style={{ margin: '0px 5px 5px 0' }}
            onClick={() => filtering({
              sort: {
                stock: (stock === 'ascend' || !stock) ? 'descend' : 'ascend',
              },
            })}
          >
            <FormattedMessage id='stockAlerts' defaultMessage='Alertes de stock' />
            {displaySortIcon(stock)}
          </Button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <h4 style={{ margin: '0 20px 0 10px', fontWeight: 'bold' }}>
          <FormattedMessage id='status' defaultMessage='Statut' />
        </h4>
        <Radio.Group
          onChange={(e) => filtering({
            status: e.target.value,
          })}
          value={status || 'all'}
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          <Radio value='all'>
            <FormattedMessage id='all' defaultMessage='Tout' />
          </Radio>
          <Radio value='Active'>
            <FormattedMessage id='active.male' defaultMessage='Actif' />
          </Radio>
          <Radio value='Inactive'>
            <FormattedMessage id='inactive.male' defaultMessage='Inactif' />
          </Radio>
        </Radio.Group>
      </div>
    </div>
  )
})

export const initialState = {
  loading: true,
  error: false,
  data: [],
}

export const getAllTechnics = (state = initialState) => {
  try {
    return state.data
  } catch (e) {
    console.log('getAllTechnics - error', e)
    return initialState.data
  }
}

export const loading = (state = initialState) => state.loading || false

import React, { useState } from 'react'

import {
  MagicLinksTrackingFilters,
  MagicLinksTrackingTable,
  MagicLinksResendModal,
} from '../..'

const columns = [
  'formatted.date',
  'email',
  'formatted.campaign',
  'formatted.admin',
  'formatted.status',
  'formatted.nps',
  'action',
]

const MagicLinksTracking = (props) => {
  try {
    const {
      user,
      shopOwner,
      locale,
      collaborators,
      campaigns,
      magicLinks,
      modifyMagicLinks,
    } = props

    const [filters, setFilters] = useState({
      adminsIds: [],
      campaignsIds: [],
      range: [],
      statuses: [],
      search: '',
    })

    const [selection, setSelection] = useState([])
    const [resendOne, setResendOne] = useState(false)
    const [stepBeforeResend, setStepBeforeResend] = useState(false)

    return (
      <div style={{
        display: 'flex', flexDirection: 'column',
      }}
      >
        <MagicLinksResendModal
          magicLinks={magicLinks}
          isModalVisible={stepBeforeResend}
          setIsModalVisible={setStepBeforeResend}
          resendOne={resendOne}
          setResendOne={setResendOne}
          selection={selection}
          setSelection={setSelection}
          modifyMagicLinks={modifyMagicLinks}
          onCancel={() => setStepBeforeResend(false)}
        />
        <MagicLinksTrackingFilters
          filters={filters}
          setFilters={setFilters}
          collaborators={collaborators}
          campaigns={campaigns}
          magicLinks={magicLinks}
        />
        <MagicLinksTrackingTable
          filters={filters}
          selection={selection}
          setSelection={setSelection}
          user={user}
          shopOwner={shopOwner}
          locale={locale}
          collaborators={collaborators}
          campaigns={campaigns}
          magicLinks={magicLinks}
          setStepBeforeResend={setStepBeforeResend}
          setResendOne={setResendOne}
          style={{ marginTop: '24px' }}
          selectable
          wantedColumns={columns}
          isPaginated
        />
      </div>
    )
  } catch (e) {
    console.log('MagicLinksTracking error:', e)
    return null
  }
}

export default (MagicLinksTracking)

import React from 'react'
import { Card } from 'antd'

export default ({
  content = [],
  bordered,
  title,
}) => (
  <div style={{
    background: '#ECECEC',
    padding: '30px',
  }}
  >
    <Card title={title || ''} bordered={!!bordered} style={{ width: '100%' }}>
      {content.map((c) => (<p>{c}</p>))}
    </Card>
  </div>
)
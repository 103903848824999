import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins } from '@fortawesome/free-solid-svg-icons'

import { CustomSwitch } from '../..'

import { character, primaryColor } from '../../../config/theme'

const tagStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '2px 8px',
  gap: '10px',
  borderRadius: '1000px',
  background: primaryColor[1],
  color: primaryColor[6],
  height: '24px',
}

const MagicLinksSendingMailsHeader = (props) => {
  const {
    mails,
    subpart,
    setSubpart,
  } = props

  return (
    <div style={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
    }}
    >
      <div style={{
        display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center',
      }}
      >
        <div style={{ color: character.title, fontWeight: 600, fontSize: '16px' }}>
          <FormattedMessage id='addRecipients' defaultMessage='Ajouter des destinataires' />
        </div>
        <div style={tagStyle}>
          <FontAwesomeIcon icon={faCoins} />
          <FormattedMessage
            id='creditCommitted'
            defaultMessage='{items, plural, =0 {# link engagé} one {# link engagé} other {# links engagés}}'
            values={{
              items: mails?.length,
            }}
          />
        </div>
      </div>
      <CustomSwitch
        childrens={[{ id: 'emails', defaultMessage: 'Emails' }, { id: 'CSVFile', defaultMessage: 'Fichier CSV' }]}
        subpart={subpart}
        setSubpart={setSubpart}
      />
    </div>
  )
}

export default MagicLinksSendingMailsHeader

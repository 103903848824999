import React from 'react'
import {
  Button,
  Row,
  Col,
  Anchor,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { InnerLayout } from '../../layouts'
import { ShopPreviewModal } from '../../components'
import {
  NameAndURL,
  PresetAddresses,
  SocialNetworks,
  AuthMembers,
  Monitoring,
  SEO,
  LegalNotice,
  // Integrations,
  FacturationSettings,
  // TVA,
} from '../../containers'

import { getUserEntityStatus } from '../../helpers/entities'

const { Link } = Anchor

class Customization extends React.Component {
  originalState = {
    previewModal: false,
  }

  constructor() {
    super()
    this.state = this.originalState
  }

  actions = (user, shop) => {
    const { owner } = shop
    return user.admin || user._id === owner
      ? ([
        <Button
          key='preview'
          type='primary'
          onClick={() => {
            this.setState({ previewModal: true })
          }}
        >
          Preview
        </Button>,
      ]) : null
  }

  render() {
    // TODO: Add <Integrations />
    const {
      previewModal,
    } = this.state
    const {
      shopId,
      user,
      shop,
      entities,
    } = this.props
    const status = getUserEntityStatus(user, shop)
    return (
      <div className='Customization page'>
        <InnerLayout
          title={(
            <div style={{
              fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
            }}
            >
              <FormattedMessage
                id='settings.choices'
                defaultMessage='Préférences'
              />
            </div>
          )}
          extra={this.actions(user, shop)}
        >
          <div className='Customization page'>
            <Row>
              <Col
                span={5}
                style={{
                  padding: '24px',
                }}
              >
                <Anchor
                  getContainer={() => document.getElementById('pageContent')}
                  bounds={80}
                  offsetTop={20}
                >
                  {(status === 'owner' || status === 'admin')
                    && (
                      <Link
                        href='#basics'
                        title={(
                          <FormattedMessage id='customization.basics' defaultMessage='Général' />
                        )}
                      />
                    )}
                  {(status === 'owner' || status === 'admin' || status === 'adminCollaborator')
                    && (
                      <Link
                        href='#facturation'
                        title={(
                          <FormattedMessage id='invoices' defaultMessage='Facturation' />
                        )}
                      />
                    )}
                  {(status === 'owner' || status === 'admin')
                    && (
                      <Link
                        href='#socials'
                        title={(
                          <FormattedMessage id='customization.socials' defaultMessage='Réseaux sociaux' />
                        )}
                      />
                    )}
                  <Link
                    href='#presetAddresses'
                    title={(
                      <FormattedMessage id='customization.presetAddresses' defaultMessage='Adresses personnalisées' />
                    )}
                  />
                  {(status === 'owner' || status === 'admin') ? (
                    <>
                      <Link
                        href='#AuthMembers'
                        title={(
                          <FormattedMessage id='customization.authMembers' defaultMessage='OAuth' />
                        )}
                      />
                      <Link
                        href='#monitoring'
                        title={(
                          <FormattedMessage id='customization.monitoring' defaultMessage='Monitoring' />
                        )}
                      />
                      <Link
                        href='#seo'
                        title={(
                          <FormattedMessage id='customization.seo' defaultMessage='Référencement' />
                        )}
                      />
                      <Link
                        href='#legalNotice'
                        title={(
                          <FormattedMessage id='legalNoticeForm.terms' defaultMessage='Mentions légales' />
                        )}
                      />
                    </>
                  ) : null}
                </Anchor>
              </Col>
              <Col
                span={19}
                style={{
                  padding: '24px',
                }}
              >
                {(status === 'owner' || status === 'admin') && <NameAndURL />}
                {(status === 'owner'
                || status === 'admin'
                || status === 'adminCollaborator') && <FacturationSettings entities={entities} user={user} shop={shop} />}
                {(status === 'owner' || status === 'admin') && <SocialNetworks />}
                <PresetAddresses />
                {(status === 'owner' || status === 'admin') ? (
                  <>
                    <AuthMembers />
                    <Monitoring />
                    <SEO />
                    <LegalNotice />
                  </>
                ) : null}
              </Col>
            </Row>
          </div>
        </InnerLayout>
        <ShopPreviewModal
          visible={previewModal}
          url={`https://preview-${shopId}.niceshop.co/`}
          onCancel={() => this.setState({
            previewModal: false,
          })}
        />
      </div>
    )
  }
}

export default Customization

import React from 'react'

import {
  MagicLinksDashboardStats, MagicLinksDashboardNewCampaigns, MagicLinksDashboardStarGift, MagicLinksDashboardNewShipments,
} from '../..'

const MagicLinksDashboard = (props) => {
  try {
    const {
      campaigns,
      magicLinks,
      orders,
      products,
      collaborators,
      user,
      shopOwner,
      history,
      locale,
      setActiveKey,
      modifyMagicLinks,
      shop,
    } = props

    return (
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '16px',
      }}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '16px', width: '100%',
        }}
        >
          <div style={{
            display: 'flex', gap: '16px', width: '100%',
          }}
          >
            <MagicLinksDashboardStats shop={shop} campaigns={campaigns} magicLinks={magicLinks} user={user} />
            <MagicLinksDashboardStarGift magicLinks={magicLinks} orders={orders} products={products} />
          </div>
          <div style={{
            display: 'flex', gap: '16px', width: '100%',
          }}
          >
            <MagicLinksDashboardNewCampaigns campaigns={campaigns} magicLinks={magicLinks} history={history} setActiveKey={setActiveKey} />
          </div>
        </div>
        <MagicLinksDashboardNewShipments
          campaigns={campaigns}
          magicLinks={magicLinks}
          collaborators={collaborators}
          user={user}
          shopOwner={shopOwner}
          history={history}
          locale={locale}
          setActiveKey={setActiveKey}
          modifyMagicLinks={modifyMagicLinks}
        />
      </div>
    )
  } catch (e) {
    console.log('oraganims/MagicLinksDashboard error:', e)
    return null
  }
}

export default MagicLinksDashboard

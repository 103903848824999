import React, { useState, useCallback } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export default function ImageCropper({ src, setResult }) {
  const [imgRef, setImgRef] = useState(null)
  const [crop, setCrop] = useState({
    aspect: 1,
    unit: '%',
    width: 100,
  })

  const onLoad = useCallback((img) => {
    setImgRef(img)
  }, [])

  const getCroppedImg = (image, curCrop) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = curCrop.width
    canvas.height = curCrop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      curCrop.x * scaleX,
      curCrop.y * scaleY,
      curCrop.width * scaleX,
      curCrop.height * scaleY,
      0,
      0,
      curCrop.width,
      curCrop.height,
    )

    return canvas.toDataURL('image/jpeg')
  }

  const makeClientCrop = (curCrop) => {
    if (imgRef && curCrop.width && curCrop.height) {
      const croppedImageUrl = getCroppedImg(imgRef, curCrop, 'newFile.jpeg')
      setResult(croppedImageUrl)
    }
  }

  return (
    <div className='App'>
      <ReactCrop
        src={src}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={makeClientCrop}
      />
    </div>
  )
}

import React, { useState } from 'react'
import { isEqual } from 'lodash'
import {
  Input,
  Drawer,
  Button,
  InputNumber,
  message,
} from 'antd'

import { FormattedMessage } from 'react-intl'

const EntityEdition = (props) => {
  const {
    entityEdition,
    setVisibleDrawerEditionEntity,
    modifyEntity,
  } = props

  const initialAddress = {
    city: '',
    country: '',
    line1: '',
    postal_code: '',
    state: '',
  }

  const [name, setName] = useState(entityEdition.name)
  const [tax, setTax] = useState(entityEdition.tax || 0)
  const [address, setAddress] = useState(entityEdition?.facturation?.address || initialAddress)

  /**
   * Besoin d'update si changement dans l'adresse de facturation ou dans le nom ou dans la tax.
   *
   * @returns needUpdate boolean
   */
  const noUpdateNeeded = () => (entityEdition?.facturation?.address
    ? isEqual(address, entityEdition?.facturation?.address) : isEqual(address, initialAddress))
    && isEqual(name, entityEdition.name)
    && (isEqual(tax, entityEdition.tax) || tax === 0)

  return (
    <>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div style={{
          flex: '2 3', display: 'flex', margin: '0 10px 0 0', flexDirection: 'column',
        }}
        >
          <div style={{ fontWeight: 'bold', margin: '0 10px 0 0' }}>
            <FormattedMessage id='entityEdition.input.name' defaultMessage='Nom' />
          </div>
          <Input
            value={name}
            placeholder='Paris'
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div style={{
          flex: '1 3', display: 'flex', flexDirection: 'column',
        }}
        >
          <div style={{ fontWeight: 'bold', margin: '0 10px 0 0' }}>
            <FormattedMessage id='entityEdition.input.TVA' defaultMessage='TVA' />
          </div>
          <InputNumber
            value={tax}
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            onChange={(value) => setTax(value)}
          />
        </div>
      </div>

      <h3>
        <FormattedMessage id='entityEdition.informations.facturation' defaultMessage='Informations de facturation' />
      </h3>

      <div style={{ fontWeight: 'bold', margin: '5px 10px 0 0' }}>
        <FormattedMessage id='entityEdition.informations.facturation.address.state' defaultMessage='Pays' />
        <Input
          value={address.state}
          placeholder='France'
          onChange={(e) => setAddress({ ...address, state: e.target.value })}
        />
      </div>

      <div style={{ fontWeight: 'bold', margin: '15px 10px 0 0' }}>
        <FormattedMessage id='entityEdition.informations.facturation.address.line1' defaultMessage='Adresse' />
        <Input
          value={address.line1}
          placeholder='8 rue du champs de mars'
          onChange={(e) => setAddress({ ...address, line1: e.target.value })}
        />
      </div>

      <div style={{ fontWeight: 'bold', margin: '15px 10px 0 0' }}>
        <FormattedMessage id='entityEdition.informations.facturation.address.postalCode' defaultMessage='Code postal' />
        <Input
          value={address.postal_code}
          placeholder='93400'
          onChange={(e) => { setAddress({ ...address, postal_code: e.target.value }) }}
        />
      </div>

      <div style={{ fontWeight: 'bold', margin: '15px 10px 0 0' }}>
        <FormattedMessage id='entityEdition.informations.facturation.address.city' defaultMessage='Ville' />
        <Input
          value={address.city}
          placeholder='Saint-Ouen'
          onChange={(e) => setAddress({ ...address, city: e.target.value })}
        />
      </div>

      <Button
        type='primary'
        style={{ margin: '20px 0 0 0' }}
        disabled={noUpdateNeeded()}
        onClick={() => {
          // On construit le payload en fonction des champs qui ont changé
          const payload = {
            _id: entityEdition._id,
            ...(!isEqual(name, entityEdition.name) && { name }),
            ...(!isEqual(tax, entityEdition.tax) && !isEqual(tax, 0) && { tax }),
            ...((entityEdition?.facturation?.address
              ? !isEqual(address, entityEdition?.facturation?.address) : !isEqual(address, initialAddress))
              && {
                facturation: {
                  ...entityEdition.facturation,
                  address,
                },
              }),
          }

          modifyEntity(payload)
          setVisibleDrawerEditionEntity(false)
          message.success(<FormattedMessage id='entityEdition.success' defaultMessage='Entité mise à jour !' />)
        }}
      >
        <FormattedMessage id='entityEdition.modifyButton' defaultMessage="Modifier l'entité" />
      </Button>
    </>
  )
}

const DrawerEntityEdition = (props) => {
  const {
    entityEdition,
    visibleDrawerEditionEntity,
    setVisibleDrawerEditionEntity,
    setEntityEdition,
    modifyEntity,
  } = props
  return (
    <Drawer
      title={<FormattedMessage id='entityEdition.drawerTitle' defaultMessage='Modifier une entitée' />}
      placement='right'
      width='35%'
      onClose={() => {
        setVisibleDrawerEditionEntity(false)
        setEntityEdition({})
      }}
      visible={visibleDrawerEditionEntity}
      destroyOnClose
    >
      <EntityEdition
        entityEdition={entityEdition}
        setVisibleDrawerEditionEntity={setVisibleDrawerEditionEntity}
        modifyEntity={modifyEntity}
      />
    </Drawer>
  )
}

export default DrawerEntityEdition

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { Button } from 'antd'
import { character } from '../../../config/theme'

const CloseDrawerButton = (props) => {
  const {
    onClick,
    style,
    iconStyle,
    icon = faXmark,
  } = props
  return (
    <Button
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        aspectRatio: '1/1',
        border: 'none',
        boxShadow: 'none',
        ...style,
      }}
    >
      <FontAwesomeIcon
        style={{
          color: character.disabledPlaceholder,
          fontSize: '24px',
          ...iconStyle,
        }}
        icon={icon}
      />
    </Button>
  )
}

export default CloseDrawerButton

import React from 'react'

import { white } from '../../../config/theme'
import {
  CampaignPostalCardSwitch,
  CampaignDonationSwitch,
  CampaignProductsRecap,
  CampaignSurpriseInput,
  MovingStepButton,
} from '../..'

import { hasCampaignEnoughProducts } from '../../../helpers/campaignValidators'

const stepButtonsStyle = {
  display: 'flex',
  flexDirection: 'column-reverse',
  gap: '8px',
}

const CampaignProductsPanel = (props) => {
  try {
    const {
      campaign, // Required
      setCampaign, // Required
      setNeedUpdateCampaign, // Required
      selectedProducts, // Required
      removeSelectedProduct, // Required
      moveNextStep, // Required
      movePreviousStep, // Required
      setDisplayDonationDrawer, // Required
      checkedDonation, // Required
      setCheckedDonation, // Required
      checkedSurprise, // Required
      setCheckedSurprise, // Required
      shop,
      entity,
    } = props

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '24px 16px',
        gap: '24px',
        background: white,
        boxShadow: '0px 0px 24px rgba(16, 24, 40, 0.03)',
        borderRadius: '16px',
        width: '350px',
        overflow: 'hidden',
      }}
      >
        <CampaignDonationSwitch
          campaign={campaign}
          setCampaign={setCampaign}
          checkedDonation={checkedDonation}
          setCheckedDonation={setCheckedDonation}
          setNeedUpdateCampaign={setNeedUpdateCampaign}
          setDisplayDonationDrawer={setDisplayDonationDrawer}
          setCheckedSurprise={setCheckedSurprise}
          size='small'
          shop={shop}
          entity={entity}
        />
        <CampaignProductsRecap
          selectedProducts={selectedProducts}
          removeSelectedProduct={removeSelectedProduct}
        />
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', gap: '24px',
        }}
        >
          {/* // TODO passer les paramètres adéquats avec le dev des dons et cartes postales */}
          <CampaignSurpriseInput
            campaign={campaign}
            setCampaign={setCampaign}
            setNeedUpdateCampaign={setNeedUpdateCampaign}
            checkedSurprise={checkedSurprise}
            setCheckedSurprise={setCheckedSurprise}
            isDisabled={checkedDonation}
            size='small'
          />
          <CampaignPostalCardSwitch size='small' isDisabled />
          {moveNextStep && movePreviousStep && (
            <MovingStepButton
              moveNextStep={moveNextStep}
              isContinueDisabled={!hasCampaignEnoughProducts(campaign)}
              movePreviousStep={movePreviousStep}
              style={stepButtonsStyle}
              continueType='primary'
            />
          )}
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecules/CampaignProductsPanel error:', e)
    return null
  }
}

export default CampaignProductsPanel

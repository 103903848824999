/* eslint-disable max-len */
import React, { useState } from 'react'
import {
  Upload,
  Button,
  Icon,
  Input,
} from 'antd'
import { uniqBy } from 'lodash'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import getBase64 from '../../../helpers/imageUpload'

const uploadButton = () => (
  <div>
    <Icon type='plus' />
    <div style={{ marginTop: 8 }}>
      <FormattedMessage id='upload' defaultMessage='Upload' />
    </div>
  </div>
)

const BaseProductImageForm = (props) => {
  try {
    const {
      product,
      updateProduct,
    } = props
    const [images, setPicLists] = useState(product.images && product.images.length ? product.images : [])
    const [picListToUpload, setPicListToUpload] = useState(product.picListToUpload && product.picListToUpload.length ? product.picListToUpload : [])

    const intl = useIntl()

    const deleteImage = (image) => {
      try {
        const newPicLists = images
        newPicLists.splice(newPicLists.indexOf(image), 1)
        setPicLists(newPicLists)
        updateProduct(product.provisionalId, { images: newPicLists })
      } catch (e) {
        console.log('Workshop delete image error', e)
      }
    }

    return (
      <div className='bpWorkshop'>
        {images.length
          ? (
            <>
              <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                <FormattedMessage id='baseProductImageForm.library' defaultMessage="Bibliothèque d'images" />
              </div>
              <div style={{ display: 'flex', marginBottom: '10px', flexWrap: 'wrap' }}>
                {images.map((image) => (
                  <div style={{
                    display: 'flex', flexDirection: 'column', margin: '10px 10px 0 0', width: '250px',
                  }}
                  >
                    <img
                      src={image.src}
                      alt={image.alt}
                      style={{ borderRadius: '5px' }}
                    />
                    <div
                      style={{ display: 'flex', width: '100%', margin: '10px 0 0 0' }}
                    >
                      <Input
                        style={{ flex: '2 3' }}
                        addonBefore={intl.formatMessage({
                          id: 'baseProductImageForm.addonBefore.position',
                          defaultMessage: 'Position',
                        })}
                        defaultValue={image.position}
                        onChange={(e) => {
                          const newPicLists = images.map((pic) => {
                            if (pic._id === image._id) {
                              const value = parseFloat(e.target.value || 0)
                              return ({
                                ...pic,
                                position: parseFloat(value),
                              })
                            } return pic
                          })
                          updateProduct(product.provisionalId, { images: newPicLists })
                          setPicLists(newPicLists)
                        }}
                      />
                      <Button
                        style={{ marginLeft: '5px' }}
                        type='danger'
                        onClick={async () => deleteImage(image)}
                      >
                        <Icon type='delete' />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}
        <div>
          <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            <FormattedMessage id='baseProductImageForm.add' defaultMessage='Ajouter des images' />
          </div>
          <Upload
            name='picListToUpload'
            listType='picture-card'
            fileList={picListToUpload.length ? picListToUpload : []}
            customRequest={({ onSuccess }) => {
              setTimeout(() => {
                onSuccess('ok')
              }, 1000)
            }}
            onChange={async ({ file }) => {
              const _file = file
              let _picList = picListToUpload.length ? picListToUpload : []
              if (file.status === 'removed') {
                await setPicListToUpload(_picList.filter((pic) => pic.name !== file.name))
                return updateProduct(product.provisionalId, {
                  picListToUpload: _picList.filter((pic) => pic.name !== file.name),
                })
              }
              _file.thumbUrl = await getBase64(file.originFileObj)
              _picList.push(_file)
              _picList = uniqBy(_picList, 'name')
              await setPicListToUpload(_picList)
              return updateProduct(product.provisionalId, {
                picListToUpload: _picList,
              })
            }}
          >
            {uploadButton()}
          </Upload>
        </div>
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/BaseProductImageForm')
    return null
  }
}

export default injectIntl(BaseProductImageForm)

// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import {
  put, call, takeEvery, select,
} from 'redux-saga/effects'
import { fromUsers } from '../selectors'
import * as actions from './actions'

const { getAuth } = fromUsers

/**
 * `*fetchDiscounts` fetches the info we need from the API
 * ---
 * Generator function doc: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/function*'
 * ---
 * @param {Object} api the api service
 * @return {Boolean} true if everything went ok
 */
export function* createDesign(api, { payload: _payload }) {
  try {
    const payload = _payload

    const {
      design,
      fileType,
      callback,
    } = payload

    const authorization = yield select(getAuth)

    const data = yield call([api, api.post], '/design', design, {
      headers: {
        authorization,
        fileHashes: JSON.stringify([{
          object: 'design',
          type: fileType,
        }]),
      },
    })

    if (callback) {
      callback(data)
    }

    yield put({ type: actions.DESIGN_CREATED, payload: { data } })
    return true
  } catch (e) {
    console.log('createDesign error', e, e.message)
    yield put({
      type: actions.ERROR,
      error_details: (e.message || e),
    })
    return false
  }
}

export function* watchReceiveShops(api) {
  yield call(createDesign, api)
}

export default function* ({ api }) {
  yield takeEvery(actions.CREATE_DESIGN, createDesign, api)
}

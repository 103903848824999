import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Icon } from 'antd'
// TODO should be products
import * as actions from '../../store/actions'

export default connect(
  null,
  (dispatch, {
    store,
  }) => bindActionCreators({
    refresh: () => ({
      type: actions[store].FETCH_FORCE,
      payload: {
        force: true,
      },
    }),
  }, dispatch),
)(({
  refresh,
}) => (
  <Button
    onClick={refresh}
    key='refresh'
  >
    <Icon type='reload' />
  </Button>
))

/* eslint-disable camelcase */
import React, { useState } from 'react'
import date from 'dayjs'
import { FormattedMessage } from 'react-intl'
import { uniq, flatten } from 'lodash'
import {
  Typography,
  Button,
  Divider,
  Switch,
  Icon,
  Select,
  Table,
  InputNumber,
  Tabs,
  notification,
  Col,
  Row,
} from 'antd'

import { StockLevels, Placeholder, Info } from '../..'
import { getProductStocks, getProductGlobalStocks, getProductStocksByEntity } from '../../../helpers/stocks'
import { getUserEntityStatus } from '../../../helpers/entities'
import theme from '../../../config/theme'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const slack = require('slack-notify')('https://hooks.slack.com/services/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')

const { Paragraph } = Typography
const { Option } = Select
const { TabPane } = Tabs

const ProductStockTab = (props) => {
  try {
    const {
      product = {},
      user,
      shop,
      allStocks = [],
      entities,
      entityId,
      alertees = [],
      modifyProduct,
      modifyStock,
      allowedToModify,
    } = props

    const {
      stockAlertees = [], _id, name, baseProduct, stockAlert, picList, favPz, estimationRestock, being_modified,
    } = product

    const { category, subCategory } = baseProduct

    // Récupération d'un seuil d'alerte par défaut pour l'ensemble des stocks
    const defaultAlertThreshold = allStocks?.find((stock) => stock.alertThreshold !== null)?.alertThreshold || null

    const [stocksState, setStocksState] = useState({
      currentAlertees: stockAlertees,
      alertThreshold: defaultAlertThreshold,
      modifiedStocks: [],
    })

    const {
      currentAlertees,
      alertThreshold,
      modifiedStocks,
    } = stocksState

    // NOTE case for !data
    if (!product || !allStocks.length) {
      return (
        <Placeholder />
      )
    }

    const status = getUserEntityStatus(user, shop)

    const hasMailAlerteesChanged = !_.isEqual(currentAlertees, stockAlertees)

    const hasAlertThresholdChanged = !_.isEqual(alertThreshold, defaultAlertThreshold)

    const hasDesiredQuantityChanged = modifiedStocks.some(
      (stock) => {
        const previous = allStocks.find((s) => s._id === stock._id)
        return !_.isEqual(stock.desiredQuantity, previous?.desiredQuantity || null)
      },
    )

    /**
   * Permet de gérer la modification des stocks au niveau du state
   * @param {object} stock - Le stock à modifer
   * @param {object} modifications - Les modifications apportées au stock
   */
    const setNewStocks = (stock, modifications) => {
      const newModifiedStocks = [...modifiedStocks]
      const previous = modifiedStocks.find((s) => s._id === stock._id)
      if (previous) {
        newModifiedStocks.splice(modifiedStocks.indexOf(previous), 1)
      }
      const mergedModifications = {
        ...previous?.modifications,
        ...modifications,
      }
      const modifiedStock = {
        ...stock,
        ...mergedModifications,
        modifications: mergedModifications,
      }
      newModifiedStocks.push(modifiedStock)
      setStocksState({ ...stocksState, modifiedStocks: newModifiedStocks })
    }

    /**
   * Permet d'afficher un message de confirmation personnalisé
   * @param {JSX} title - Le titre du message (en JSX avec reactIntl)
   * @param {JSX} description - La description du message (en JSX avec reactIntl)
   */
    const confirmation = (title, description) => {
      notification.success({
        message: title,
        placement: 'topRight',
        duration: 8,
        description,
      })
    }

    // Détail de la demande de réassort dans la notification Slack
    const content = () => {
      const entitiesList = uniq(modifiedStocks.map((stock) => stock.entityId)).map((id) => entities.find((e) => e._id === id))
      const result = flatten(entitiesList.map((e) => [
        {
          type: 'divider',
        },
        {
          type: 'section',
          fields: [{
            type: 'mrkdwn',
            text: `*Entité : ${e.name}*\nId entité : ${e._id}\n\nListe des variations sélectionnées :`,
          }],
        },
        {
          type: 'section',
          fields: modifiedStocks.filter((s) => (s.entityId === e._id) && s.desiredQuantity).map((stock) => ({
            type: 'mrkdwn',
            text: `• ${stock.variationCombo[0].name} | ${stock.variationCombo[1].name} : ${stock.desiredQuantity}\n     Id stock : ${stock._id}`,
          })),
        },
      ]))
      return result
    }

    // Gère l'envoi d'une notification Slack pour une demande de réassort
    const sendSlackNotification = () => {
      slack.send({
        channel: '#restock-notifs',
        unfurl_links: 1,
        username: 'Demande de restock',
        blocks: [
          {
            type: 'divider',
          },
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: `:truck: Le shop ${shop.settings.name} a fait une demande de restock`,
              emoji: true,
            },
          },
          {
            type: 'section',
            fields: [{
              type: 'mrkdwn',
              text: `>*Nom*\n>${name}\n>*Id produit*\n>${_id || product.id}`,
            }],
            accessory: {
              type: 'image',
              image_url: picList?.length
                ? `${imageBaseUrl}/Products/${_id || product.id}/customProduct/${picList[0]}`
                : `${imageBaseUrl}/Products/${_id || product.id}/${favPz || 'front'}/mockup.png`,
              alt_text: 'product image',
            },
          },
        ].concat(content()).concat(
          {
            type: 'divider',
          },
          {
            type: 'section',
            fields: [{
              type: 'mrkdwn',
              // eslint-disable-next-line max-len
              text: `<https://${shop.settings.url}.niceshop.co/product/${category?.fr[0]}/${subCategory?.fr}/${name}/${_id || product.id}|Voir le produit>\n<https://app.forestadmin.com/Panopli/Production/All%20Panopli/data/1576500/index/record/1576500/${_id || product.id}/summary|Fiche produit Forest>\n<mailto:${user.email}|Envoyer un mail>`,
            }],
          },
        ),
      }, (err) => {
        if (err) {
          console.log('API error:', err)
        } else {
          confirmation((
            <div>
              <FormattedMessage id='restocking' defaultMessage='Réassort' />
            </div>
          ), (
            <FormattedMessage
              id='productRestockDrawer.success'
              defaultMessage='Votre demande a bien été prise en compte, un chargé de compte va revenir vers vous.'
            />
          ))
        }
      })
    }

    // Permet de gérer le comportement du bouton de validation des paramêtres du seuil d'alerte
    const handleAlerteesValidation = () => {
      if (hasMailAlerteesChanged) {
        modifyProduct(_id || product.id, {
          stockAlertees: currentAlertees,
        })
      }
      if (hasAlertThresholdChanged) {
        allStocks.map((stock) => modifyStock(
          stock._id, { alertThreshold },
        ))
      }
      confirmation((
        <FormattedMessage id='product.stock.alertThresholds.validation.title' defaultMessage="Seuil d'alerte et adresses mails" />
      ), (
        <FormattedMessage
          id='product.stock.alertThresholds.validation.message'
          defaultMessage="Vos nouveaux paramêtres de seuil d'alerte ont bien été enregistrés"
        />
      ))
    }

    // Permet de gérer le comportement du bouton de validation de la demande de réassort
    const handleRestockingValidation = () => {
      sendSlackNotification()
      // La quantité désirée n'est utile que pour la demande de restock
      // On ne souhaite pas l'ajouter en clé en base de données => on l'enlève des modifications
      const cleanedModifiedStocks = modifiedStocks.map((stock) => {
        const newStock = { ...stock }
        delete newStock.modifications.desiredQuantity
        return newStock
      }).filter((stock) => Object.values(stock.modifications)?.length) // On enlève les éventuels stocks sans modification
      // On modifie les stocks en DB
      cleanedModifiedStocks.map((stock) => {
        modifyStock(
          stock._id,
          stock.modifications,
        )
        return true
      })
      // On vide les inputs des colonnes de réassort après validation
      setStocksState({ ...stocksState, modifiedStocks: [] })
    }

    const validateButton = (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <Info
          title={<FormattedMessage id='product.restocking.validation.title' defaultMessage='Validation des demandes de réassort' />}
          content={(
            <Paragraph style={{ width: '25vw' }}>
              <FormattedMessage
                id='product.stock.validate.helper'
                defaultMessage="Vous pouvez modifier tranquillement vos demandes de réassort avant de les valider toutes en une fois.
                  Un membre de notre équipe vous recontactera pour s'assurer que celles-ci
                  soient bien conformes à vos désirs."
              />
            </Paragraph>
          )}
        />
        <Button
          type='primary'
          onClick={handleRestockingValidation}
        >
          <FormattedMessage
            id='product.restocking.validate'
            defaultMessage='Valider mon réassort'
          />
        </Button>
      </div>
    )

    const columns = [
      {
        title: <FormattedMessage id='variations' defaultMessage='Variations' />,
        key: 'variationCombo',
        render: (stock) => {
          if (!stock.variationCombo?.length) return null
          return (
            <span>
              {`${stock.variationCombo[0].name} | ${stock.variationCombo[1].name}`}
            </span>
          )
        },
      },
      {
        title: <FormattedMessage id='actualStock' defaultMessage='Stock actuel' />,
        dataIndex: 'qty',
        key: 'qty',
      },
      {
        title: <FormattedMessage id='desiredRestocking' defaultMessage='Réassort souhaité' />,
        key: 'desiredQuantity',
        render: (stock) => (
          <InputNumber
            style={{ width: '110px' }}
            min={1}
            value={modifiedStocks.find((s) => s._id === stock._id)?.desiredQuantity}
            onChange={(value) => {
              setNewStocks(stock, { desiredQuantity: value })
            }}
            disabled={!allowedToModify}
          />
        ),
      },
    ]

    return (
      <>
        <div>
          <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
            <FormattedMessage id='productStockDrawer.globalLevel' defaultMessage='Niveau Global' />
          </div>
          <Paragraph>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <FormattedMessage
                id='productStockDrawer.helper1'
                // eslint-disable-next-line max-len
                defaultMessage="Le niveau global de stock sur un produit est le niveau de stock le plus bas pour l'ensemble des déclinaisons disponibles :"
              />
              <span style={{ marginLeft: 12 }}>
                <StockLevels stock={getProductStocks(allStocks, _id || product.id)} entityId={entityId} />
              </span>
            </span>
          </Paragraph>
          {allowedToModify && (
            <Paragraph style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                type='primary'
                checkedChildren={<Icon type='bell' />}
                unCheckedChildren={<Icon type='bell' />}
                defaultChecked={stockAlert}
                onChange={(value) => {
                  modifyProduct(_id || product.id, {
                    stockAlert: value,
                  })
                }}
                style={{ marginRight: 12 }}
              />
              <FormattedMessage
                id='productStockDrawer.helper2'
                defaultMessage="Je souhaite être averti(e) lorsque le niveau d'un stock pour ce produit devient problématique."
              />
            </Paragraph>
          )}
          {allowedToModify && stockAlert && (
            <div style={{ display: 'flex' }}>
              <Col style={{ marginRight: 15 }}>
                <Row style={{ height: 25 }}>
                  <FormattedMessage id='alertThreshold' defaultMessage="Seuil d'alerte" />
                  <Info
                    title={<FormattedMessage id='alertThreshold' defaultMessage="Seuil d'alerte" />}
                    content={(
                      <Paragraph style={{ width: '25vw' }}>
                        <FormattedMessage
                          id='product.restocking.alertThreshold.helper'
                          defaultMessage="Ce seuil d'alerte permet de vous avertir
                          lorsque le stock d'une variation de l'un de vos produits tombe en dessous."
                        />
                      </Paragraph>
                    )}
                  />
                </Row>
                <Row style={{ height: 25 }}>
                  <InputNumber
                    style={{ width: '80px' }}
                    min={0}
                    defaultValue={defaultAlertThreshold}
                    onChange={(value) => {
                      setStocksState({ ...stocksState, alertThreshold: value })
                    }}
                  />
                </Row>
              </Col>
              <Col style={{ marginRight: 15 }}>
                <Row style={{ height: 25 }}>
                  <FormattedMessage
                    id='productStockDrawer.helper3'
                    defaultMessage='Qui devons nous également avertir ?'
                  />
                  <Info
                    title={<FormattedMessage id='product.restocking.mailAlertees.title' defaultMessage='Alertes multiples' />}
                    content={(
                      <Paragraph style={{ width: '25vw' }}>
                        <FormattedMessage
                          id='product.restocking.mailAlertees.helper'
                          defaultMessage="Ces personnes recevront également un email d'avertissement
                          en cas d'atteinte du seuil d'alerte sur un stock."
                        />
                      </Paragraph>
                    )}
                  />
                </Row>
                <Row style={{ height: 25 }}>
                  <Select
                    mode='tags'
                    placeholder={<FormattedMessage id='emails' defaultMessage='Emails' />}
                    defaultValue={stockAlertees}
                    allowClear
                    style={{
                      width: '100%',
                      marginBottom: '5px',
                    }}
                    onChange={(value) => setStocksState({ ...stocksState, currentAlertees: value })}
                  >
                    {uniq(alertees).map((email) => <Option key={email} value={email}>{email}</Option>)}
                  </Select>
                </Row>
              </Col>
              <Col style={{ marginRight: 15 }}>
                <Row style={{ height: 25 }} />
                <Row style={{ height: 25 }}>
                  {(allowedToModify && (hasAlertThresholdChanged || hasMailAlerteesChanged)) && (
                    <>
                      <Button
                        type='primary'
                        disabled={!alertThreshold}
                        onClick={handleAlerteesValidation}
                        loading={being_modified}
                      >
                        <FormattedMessage id='confirm' defaultMessage='Confirmer' />
                      </Button>
                      {!alertThreshold && (
                        <Info
                          title={(
                            <FormattedMessage
                              id='product.restocking.mailAlertees.validation.title'
                              defaultMessage="Validation du seuil d'alerte"
                            />
                          )}
                          content={(
                            <Paragraph style={{ width: '25vw' }}>
                              <FormattedMessage
                                id='product.restocking.mailAlertees.validation.helper'
                                defaultMessage="Vous ne pouvez enregistrer vos modifications que si un seuil d'alerte est renseigné."
                              />
                            </Paragraph>
                          )}
                        />
                      )}
                    </>
                  )}
                </Row>
              </Col>
            </div>
          )}
        </div>
        <Divider />
        <div style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px',
        }}
        >
          <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold' }}>
            <FormattedMessage id='productStockDrawer.stockLevel' defaultMessage='Niveau des stocks' />
          </div>
          {allowedToModify && hasDesiredQuantityChanged && validateButton}
          <div
            style={{ marginRight: 10 }}
          >
            <FormattedMessage
              id='productStockDrawer.estimation'
              defaultMessage='Estimation réassort : {number} jours'
              values={{ number: estimationRestock || '25' }}
            />
          </div>
        </div>
        {entities.length && (status === 'owner' || status === 'admin') ? (
          <Tabs defaultActiveKey='global' type='card'>
            <TabPane tab='Global' key='global'>
              <Table
                rowKey={(record) => record._id}
                columns={columns.slice(0, 2)}
                dataSource={getProductGlobalStocks(allStocks, _id || product.id)}
              />
            </TabPane>
            {entities.sort((a, b) => (date(b.createdAt).isBefore(a.createdAt) && 1) || -1).map((entity) => (
              <TabPane tab={entity.name} key={entity._id}>
                <Table
                  rowKey={(record) => record._id}
                  columns={columns}
                  dataSource={getProductStocksByEntity(allStocks, _id || product.id, entity._id)}
                />
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <Table
            rowKey={(record) => record._id}
            columns={columns.slice(0, 2)}
            dataSource={getProductStocks(allStocks, _id || product.id)}
          />
        )}
      </>
    )
  } catch (e) {
    console.log('organisms/ProductStockTab error:', e)
    return null
  }
}

export default ProductStockTab

import {
  Elements,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  Button,
  Collapse,
  Drawer,
  Icon,
  Steps,
  Table,
} from 'antd'
import { flatten, uniq } from 'lodash'
import React, { useState } from 'react'
import { CSVLink } from 'react-csv'
import { FormattedMessage } from 'react-intl'
import { CSVReader } from 'react-papaparse'
import {
  checkAddress,
  formatAdresse,
  formatOrder,
  formatSeller,
  getCSVHeaders,
  getColumnsFromProducts,
  getProductsFromRow,
  headersCSV,
} from '../../../helpers/multipleOrders'
import BatchCheckout from '../../molecules/BatchCheckout'
import EditableTable from '../../molecules/EditableTable'
import BatchOrdersMembersForm from '../BatchOrdersMembersForm'

import config from '../../../config'
import { getEntityId } from '../../../helpers/entities'
import { displayDate } from '../../../helpers/trim'

const { Panel } = Collapse

const stripePromise = loadStripe(config.stripeKey)

const { Step } = Steps

const StepPannel = (props) => {
  const { step } = props
  return (
    <Steps
      type={<FormattedMessage id='navigation' defaultMessage='Navigation' />}
      current={step}
      style={{ padding: '5px 15px 15px 15px' }}
    >
      <Step title={<FormattedMessage id='dataUpload' defaultMessage='Récupération des données' />} />
      <Step title={<FormattedMessage id='ordersRecap' defaultMessage='Récapitulatif des commandes' />} />
      <Step title={<FormattedMessage id='invoices' defaultMessage='Facturation' />} />
    </Steps>
  )
}

const ErrorsRecap = (props) => {
  try {
    const { headersErrors, addresses } = props

    const translateMessage = (message) => {
      switch (message) {
      case 'headers':
        return <FormattedMessage id='incorrectHeaders' defaultMessage='Entêtes incorrects' />
      case 'missing value':
        return <FormattedMessage id='missingData' defaultMessage='Données manquantes' />
      case 'wrong formatting':
        return <FormattedMessage id='wrongFormatting' defaultMessage='Données au mauvais format' />
      case 'firstName':
        return <FormattedMessage id='firstName' defaultMessage='Prénom' />
      case 'lastName':
        return <FormattedMessage id='lastName' defaultMessage='Nom' />
      case 'email':
        return <FormattedMessage id='email' defaultMessage='Email' />
      case 'phone':
        return <FormattedMessage id='phone' defaultMessage='Téléphone' />
      case 'streetAddress':
        return <FormattedMessage id='address' defaultMessage='Adresse' />
      case 'postalCode':
        return <FormattedMessage id='postalCode' defaultMessage='Code postal' />
      case 'unknow postal code':
        return <FormattedMessage id='countryNotFound' defaultMessage='Code pays inconnu' />
      case 'missing country code':
        return <FormattedMessage id='multipleOrders.countryColumnRequired.ISO' defaultMessage='Colonne Pays requise (ISO)' />
      case 'city':
        return <FormattedMessage id='city' defaultMessage='Ville' />
      case 'country':
        return <FormattedMessage id='country' defaultMessage='Pays' />
      case 'country not found':
        return <FormattedMessage id='multipleOrders.unknownCountryCode.ISO' defaultMessage='Code pays inconnu (ISO)' />
      default:
        return ''
      }
    }

    const getFocus = (key) => {
      if (typeof window !== 'undefined') {
        const totalKey = `focus-${key}`
        const focused = window.document.getElementsByClassName(totalKey)
        if (focused && focused.length) {
          focused[0].scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
        }
      }
    }

    const renderErrorsDetails = (details) => {
      const customPanelStyle = {
        background: '#f7f7f7',
        borderRadius: 4,
        marginBottom: 24,
        border: 0,
        overflow: 'hidden',
      }
      const result = details.map((err) => {
        const header = (
          <FormattedMessage
            id='multipleOrders.errors.title'
            defaultMessage='{type} | {length} erreur(s)'
            values={{ type: translateMessage(err.type), length: err.details.length }}
          />
        )
        return (
          <Panel header={header} key={header} style={customPanelStyle}>
            <FormattedMessage id='multipleOrders.lines' defaultMessage='Ligne(s) :' />
            <div style={{
              display: 'flex', flexWrap: 'wrap', flexDirection: 'column', maxHeight: 100, overflowY: 'scroll',
            }}
            >
              {err.details.map((detail) => (
                <div
                  style={{ width: '40px', marginRight: 5 }}
                  onClick={() => getFocus(detail.rowKey)}
                  key={detail.rowKey}
                >
                  {`# ${detail.line}`}
                </div>
              ))}
            </div>
          </Panel>
        )
      })
      return (
        <Collapse bordered={false}>
          {result}
        </Collapse>
      )
    }

    const renderAllErrors = (errors) => (
      <Collapse bordered={false} defaultActiveKey={['headers']}>
        {headersErrors.length > 0 ? (
          <Panel header={translateMessage('headers')} key='headers'>
            <FormattedMessage
              id='multipleOrders.headers.errors.helper'
              defaultMessage="Les entêtes de votre CSV contiennent des erreurs empêchant la récupération
              des données. Pour y remédier, vous pouvez télécharger à l'étape précédente le CSV pré-rempli
              contenant les bons entêtes et y insérer vos données, ou modifier votre CSV pour chaque erreur
              d'entête ci-dessous avant de le recharger :"
            />
            {headersErrors.map((err) => {
              const { current, index, correct } = err
              return (
                <div style={{ marginTop: 5 }}>
                  <FormattedMessage
                    id='multipleOrders.headers.errors.details'
                    defaultMessage="- colonne {index} : l'entête {current} de votre CSV doit être remplacé par {correct}."
                    values={{ current, index: index + 1, correct }}
                  />
                </div>
              )
            })}
          </Panel>
        ) : (
          errors.map((err) => {
            const header = translateMessage(err.key)
            return (
              <Panel header={header} key={err.key}>
                {renderErrorsDetails(err.errors)}
              </Panel>
            )
          })
        )}
      </Collapse>
    )

    const errors = ['firstName', 'lastName', 'email', 'phone', 'streetAddress', 'postalCode', 'city', 'country']

    const details = errors.map((error) => {
      const typeofError = uniq(flatten(addresses.map((add) => {
        const errs = flatten(add.errorDetails && add.errorDetails.filter((detail) => detail.key === error))
        return errs && uniq(errs.map((err) => err.message))
      })))
      const allErrors = typeofError.map((type) => {
        const result = addresses.map((add, index) => {
          const hasError = add.errorDetails && add.errorDetails.find((detail) => detail.key === error && type === detail.message)
          if (hasError) {
            return {
              key: error, index, line: index + 1, rowKey: add.key, message: hasError && hasError.message,
            }
          }
          return false
        }).filter(Boolean)
        return {
          type,
          details: result,
        }
      })
      return { key: error, errors: allErrors }
    })

    const hasErrors = details.some((det) => det.errors.length)

    if (!hasErrors) return null

    return (
      <div style={{
        padding: 25,
        border: '1px solid red',
        width: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 25,
        marginTop: 20,
        borderRadius: 5,
        position: 'sticky',
        top: '25px',
        backgroundColor: 'white',
        zIndex: 1000,
      }}
      >
        <div style={{ fontWeight: 600, fontSize: '16px', marginBottom: 5 }}>
          <FormattedMessage id='errorList' defaultMessage='Liste des erreurs' />
        </div>
        {renderAllErrors(details.filter((det) => det.errors.length), headersErrors)}
      </div>
    )
  } catch (e) {
    console.log('RENDER ERRORS CATCH', e)
    return null
  }
}

const ProductsRecap = (props) => {
  const { addresse, handleProductsModifications } = props
  const { products = [] } = addresse || {}

  const formatDataSource = (prods) => {
    const format = { }
    prods.map((p) => {
      format[p.target] = parseInt(p.qty, 10)
      return true
    })
    return format
  }

  const columns = products.map((p) => ({
    title: p.target,
    key: p.target,
    dataIndex: p.target,
    editable: true,
  }))

  return (
    <EditableTable dataSource={[formatDataSource(products)]} columns={columns} handleSave={(obj) => handleProductsModifications(obj, addresse.key)} />
  )
}

const RecapOrders = (props) => {
  const {
    orders, step, setStep, locale = 'fr',
  } = props
  const columns = [
    {
      title: <FormattedMessage id='date' defaultMessage='Date' />,
      key: 'date',
      dataIndex: 'createdAt',
      render: (createdAt) => (<span>{displayDate(createdAt, locale)}</span>),
    },
    {
      title: <FormattedMessage id='orderNumber' defaultMessage='Numéro de commande' />,
      key: 'customerOrderId',
      dataIndex: 'customerOrderId',
    },
    {
      title: <FormattedMessage id='type' defaultMessage='Type' />,
      key: 'purchase',
      render: (record) => {
        const { giftOrder, internalOrder } = record
        if (giftOrder) {
          return (<span> Cadeau </span>)
        } if (internalOrder) {
          return (<span> Interne </span>)
        }
        return (<span> Boutique </span>)
      },
    },
    {
      title: <FormattedMessage id='address' defaultMessage='Adresse' />,
      key: 'address',
      render: (record) => {
        const { client } = record
        return (
          <span>
            {client.address.city}
            {', '}
            {client.address.country}
          </span>
        )
      },
    },
    {
      title: <FormattedMessage id='lastName' defaultMessage='Nom' />,
      key: 'name',
      render: (record) => {
        const { client } = record
        return (
          <span>
            {' '}
            {client.firstName}
            {' '}
            {client.lastName}
          </span>
        )
      },
    },
    {
      title: <FormattedMessage id='discount' defaultMessage='Promotion' />,
      key: 'discount',
      render: (record) => {
        const { discounts } = record
        if (discounts?.code?.name) {
          return <span>{record.discounts.code.name}</span>
        }
        return <span>-</span>
      },
    },
    {
      title: <FormattedMessage id='totalPriceIncl' defaultMessage='Prix total TTC' />,
      key: 'totalPrice',
      render: (record) => {
        const { detailedPricing, transaction } = record
        return (
          <span>
            {`${(detailedPricing?.total)
              ? (detailedPricing.total
              + detailedPricing.delivery_discounted).toFixed(2)
              : (transaction.amount / 100).toFixed(2)} €`}
          </span>
        )
      },
      sorter: (a, b) => a.transaction.amount - b.transaction.amount,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id='status' defaultMessage='Statut' />,
      key: 'status',
      render: (record) => {
        const { error } = record
        return (
          <span style={{ color: error ? 'red' : '' }}>
            { error ? 'Error' : 'Ok' }
          </span>
        )
      },
    },
    {
      title: <FormattedMessage id='products' defaultMessage='Produits' />,
      key: 'products',
      render: (record) => {
        const { products } = record
        return (
          <FormattedMessage
            id='multipleOrders.numberOfProducts'
            defaultMessage='{number} produit{agreement}'
            values={{
              number: products?.length,
              agreement: products?.length > 1 ? 's' : '',
            }}
          />
        )
      },
    },
  ]
  if (step !== 1) {
    return null
  }

  const disabled = !!(orders.find((o) => o.error))

  return (
    <>
      <Table dataSource={orders.map((o) => ({ ...o, key: o.customerOrderId }))} columns={columns} />
      <div style={{
        display: 'flex', justifyContent: 'center', position: 'sticky', bot: '0px', padding: 20,
      }}
      >
        <Button.Group>
          <Button
            type='primary'
            onClick={() => {
              setStep(0)
            }}
          >
            <Icon type='left' style={{ marginRight: 4 }} />
            <FormattedMessage id='return' defaultMessage='Retour' />
          </Button>
          <Button
            type='primary'
            disabled={disabled}
            onClick={() => {
              setStep(2)
            }}
          >
            <FormattedMessage id='validate' defaultMessage='Valider' />
            <Icon type='right' style={{ marginLeft: 4 }} />
          </Button>
        </Button.Group>
      </div>
    </>
  )
}

const RecapAdress = (props) => {
  const {
    headers = [],
    addresses = [],
    filterRow,
    setStep,
    pushOrders,
    step,
    modifyAddresse,
    handleAddressSelection,
    setVisible,
    setAddresses,
    locale,
  } = props

  if (!addresses.length || step !== 0) {
    return null
  }

  const columns = [
    {
      title: <FormattedMessage id='firstName' defaultMessage='Prénom' />,
      dataIndex: 'firstName',
      key: 'firstName',
      editable: true,
    },
    {
      title: <FormattedMessage id='lastName' defaultMessage='Nom' />,
      dataIndex: 'lastName',
      key: 'lastName',
      editable: true,
    },
    {
      title: <FormattedMessage id='compagny' defaultMessage='Société' />,
      dataIndex: 'company',
      key: 'company',
      editable: true,
    },
    {
      title: <FormattedMessage id='email' defaultMessage='Email' />,
      dataIndex: 'email',
      key: 'email',
      editable: true,
    },
    {
      title: <FormattedMessage id='phone' defaultMessage='Téléphone' />,
      dataIndex: 'phone',
      key: 'phone',
      editable: true,
    },
    {
      title: <FormattedMessage id='address' defaultMessage='Adresse' />,
      dataIndex: 'streetAddress',
      key: 'streetAddress',
      editable: true,
    },
    {
      title: <FormattedMessage id='additional' defaultMessage='Complément' />,
      dataIndex: 'complement',
      key: 'complement',
      editable: true,
      required: false,
    },
    {
      title: <FormattedMessage id='postalCode' defaultMessage='Code postal' />,
      dataIndex: 'postalCode',
      key: 'postalCode',
      editable: true,
    },
    {
      title: <FormattedMessage id='city' defaultMessage='Ville' />,
      dataIndex: 'city',
      key: 'city',
      editable: true,
    },
    {
      title: <FormattedMessage id='country.ISO' defaultMessage='Pays (ISO)' />,
      dataIndex: 'country',
      key: 'country',
      editable: true,
    },
    {
      title: <FormattedMessage id='products' defaultMessage='Produits' />,
      dataIndex: '',
      key: 'y',
      render: (row) => (
        <Button
          onClick={() => {
            handleAddressSelection(row.key)
            setVisible(true)
          }}
        >
          <Icon type='eye' />
        </Button>
      ),
    },
    {
      dataIndex: '',
      key: 'x',
      render: (row) => (
        <Button onClick={() => filterRow(row)}>
          <Icon type='delete' />
        </Button>
      ),
    },
  ]

  // La liste des index de données
  const headersList = [
    'firstName',
    'lastName',
    'company',
    'email',
    'phone',
    'streetAddress',
    'complement',
    'postalCode',
    'city',
    'country']

  // Les headers attendus par l'extracteur de données CSV,
  // tenant compte de la langue actuelle de l'application
  // (les headers du CSV pré-rempli changent selon la langue)
  const correctHeaders = [
    headersList.map((h) => {
      const concerned = headersCSV(locale).find((he) => he.key === h) || {}
      return concerned.label || h
    }),
  ].find(Boolean)

  // Liste des erreurs détectées dans les headers du CSV
  // avec les détails nécessaires pour leur affichage
  // (entête dans le CSV, l'index de sa colonne, l'entête attendu)
  const headersErrors = correctHeaders.map((h, index) => {
    const current = headers[index]
    if (h !== current) return { current, index, correct: h }
    return false
  }).filter(Boolean)

  const hasErrors = !!(addresses.find((a) => a.error)) || headersErrors.length > 0
  return (
    <div>
      { hasErrors ? (
        <ErrorsRecap
          headersErrors={headersErrors}
          addresses={addresses}
        />
      ) : null }
      <EditableTable
        dataSource={addresses}
        columns={columns}
        handleSave={modifyAddresse}
        scroll={{ x: true }}
        unrequired={['complement', 'company']}
      />
      <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
        <Button.Group>
          <Button
            type='primary'
            onClick={() => {
              setAddresses([])
            }}
          >
            <Icon type='left' style={{ marginRight: 4 }} />
            <FormattedMessage id='return' defaultMessage='Retour' />
          </Button>
          <Button
            type='primary'
            disabled={hasErrors}
            onClick={async () => {
              setStep(1)
              await pushOrders(addresses)
            }}
          >
            <FormattedMessage id='validate' defaultMessage='Valider' />
            <Icon type='right' style={{ marginLeft: 4 }} />
          </Button>
        </Button.Group>
      </div>
    </div>
  )
}

const CSVGiver = (props) => {
  const {
    columns = [], disabled, membersData = [], locale,
  } = props
  const header = [
    'firstName',
    'lastName',
    'company',
    'email',
    'phone',
    'streetAddress',
    'complement',
    'postalCode',
    'city',
    'country'].concat(columns)
  const members = membersData.map((member) => header.map((h) => member[h] || ''))
  const lignes = membersData.length ? members : [header.map(() => '')]
  const csvData = [
    header.map((h) => {
      const concerned = headersCSV(locale).find((he) => he.key === h) || {}
      return concerned.label || h
    }),
    ...lignes,
  ]
  return (
    <div style={{ textAlign: 'center' }}>
      <CSVLink data={csvData}>
        <Button disabled={disabled}>
          <FormattedMessage id='preFilledCSV' defaultMessage='CSV pré-rempli' />
        </Button>
      </CSVLink>
      { membersData.length ? (
        <p style={{ padding: 15 }}>
          <FormattedMessage
            id='multipleOrders.prefilledCSV'
            defaultMessage='Votre CSV sera pré-rempli avec {number} membre{agreement}'
            values={{
              number: membersData.length,
              agreement: membersData.length > 1 ? 's' : '',
            }}
          />
        </p>
      ) : null }
    </div>
  )
}

const CSV = (props) => {
  const {
    step, setHeaders, setAddresses, columns, disabled, members, groups, products, locale,
  } = props
  if (step !== 0) {
    return null
  }
  const [memberFormIsOpen, setMemberFormOpen] = useState(false)
  const [rowsFromMembers, setRowsFromMembers] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  return (
    <div style={{ height: window.innerHeight - 210 }}>
      <div style={{ textAlign: 'center', marginTop: 100 }}>
        <p>
          <FormattedMessage id='multipleOrders.membersInfos' defaultMessage='Vous pouvez pré-remplir votre CSV avec les informations membres' />
        </p>
        <div>
          <Button
            type='primary'
            onClick={() => setMemberFormOpen(true)}
          >
            <FormattedMessage id='open' defaultMessage='Ouvrir' />
          </Button>
        </div>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
      >
        <CSVGiver columns={columns} disabled={disabled} membersData={rowsFromMembers} locale={locale} />
        <Icon type='arrow-right' />
        <div>
          <CSVReader
            onDrop={(data) => {
              setHeaders(getCSVHeaders(data))
              setAddresses(formatAdresse(data, products, locale))
            }}
            addRemoveButton
            config={{ header: true }}
            onRemoveFile={() => setAddresses([])}
            onUploadAccepted={(results) => {
              console.log('CSVReader results:', results)
            }}
            style={{
              dropFile: {
                height: 100,
                borderRadius: 3,
                width: 150,
                padding: 0,
              },
              fileSizeInfo: {
                display: 'none',
              },
              fileNameInfo: {
                padding: 5,
                lineHeight: 'inherit',
              },
              progressBar: {
                backgroundColor: '#0000FF',
              },
            }}
          >
            <Button type='primary'>
              <Icon type='upload' />
              <FormattedMessage id='filledCSV' defaultMessage='CSV rempli' />
            </Button>
          </CSVReader>
        </div>
      </div>
      <BatchOrdersMembersForm
        visible={memberFormIsOpen}
        members={members}
        setRowsFromMembers={setRowsFromMembers}
        setMemberFormState={setMemberFormOpen}
        groups={groups}
        locale={locale}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  )
}

const MultipleOrders = (props) => {
  const {
    products,
    shop,
    suppliers,
    sendOrders,
    bps,
    isLoading,
    setLoading,
    history,
    errorDetails,
    hasError,
    disabled,
    members,
    groups,
    allStocks,
    user,
    entities,
    isAdmin,
    locale = 'fr',
  } = props

  const [headers, setHeaders] = useState([])
  const [addresses, setAddresses] = useState([])
  const [step, setStep] = useState(0)
  const [orders, setOrders] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [visible, setVisible] = useState(false)

  const modifyAddresse = (target) => {
    const modified = addresses.map((a) => {
      if (a.key === target.key) {
        const verified = checkAddress(target)
        return verified
      }
      return a
    })
    setAddresses(modified)
  }

  const handleAddressSelection = (key) => {
    const correctAddress = addresses.find((add) => add.key === key)
    return setSelectedAddress(correctAddress)
  }

  const handleProductsModifications = (modifiedProducts, key) => {
    const reFormatProductsFromRow = (prods) => Object.keys(prods).map((k) => ({ target: k, qty: parseInt(prods[k], 10) }))
    const concernedAdd = addresses.find((add) => add.key === key)
    modifyAddresse({ ...concernedAdd, products: reFormatProductsFromRow(modifiedProducts) })
    setSelectedAddress({ ...concernedAdd, products: reFormatProductsFromRow(modifiedProducts) })
  }

  const pushOrders = async (data) => {
    const entity = entities.find((ent) => {
      const { adminCollaborators, collaborators } = ent
      return user.admin ? shop.mainEntityId : (adminCollaborators.includes(String(user._id)) || collaborators.includes(String(user._id)))
    })
    const entityId = String(entity._id)
    const result = await Promise.all(data.map((row) => {
      const retrievedProducts = getProductsFromRow(row, products)
      return formatOrder(
        row,
        retrievedProducts,
        formatSeller(shop),
        products,
        shop,
        suppliers,
        bps,
        allStocks,
        entityId,
      )
    }))
    setOrders(result)
  }

  const entityId = getEntityId(user, entities, shop)?.entityId
  const entityStocks = allStocks.filter((s) => s.entityId === entityId)
  const columns = getColumnsFromProducts(products, entityStocks)

  const setTransactionType = async (transactionType) => {
    const newOrders = orders.map((o) => ({ ...o, transaction: { ...o.transaction, transaction_type: transactionType } }))
    return setOrders(newOrders)
  }

  const filterRow = ({ name, firstName, email }) => {
    const filteredAddresses = addresses.filter((add) => add.firstName !== firstName && add.lastName !== name && add.email !== email)
    setAddresses(filteredAddresses)
  }

  const checkedAddresses = addresses.map((a) => checkAddress(a))

  return (
    <>
      <Drawer visible={visible} onClose={() => setVisible(false)} width={1400}>
        <ProductsRecap
          products={addresses}
          addresse={selectedAddress}
          handleProductsModifications={handleProductsModifications}
        />
      </Drawer>
      <StepPannel step={step} />
      {addresses?.length ? null
        : (
          <CSV
            disabled={disabled}
            setHeaders={setHeaders}
            setAddresses={setAddresses}
            columns={columns}
            step={step}
            suppliers={suppliers}
            shop={shop}
            members={members}
            groups={groups}
            user={user}
            locale={locale}
            products={products}
          />
        )}
      <RecapAdress
        headers={headers}
        addresses={checkedAddresses}
        filterRow={filterRow}
        setStep={setStep}
        pushOrders={pushOrders}
        step={step}
        modifyAddresse={modifyAddresse}
        setVisible={setVisible}
        handleAddressSelection={handleAddressSelection}
        setAddresses={setAddresses}
        locale={locale}
        variationsColumns={columns}
      />
      <RecapOrders orders={orders} step={step} setStep={setStep} />
      <Elements stripe={stripePromise}>
        <BatchCheckout
          step={step}
          orders={orders}
          sendOrders={sendOrders}
          isLoading={isLoading}
          setLoading={setLoading}
          history={history}
          hasError={hasError}
          errorDetails={errorDetails}
          setTransactionType={setTransactionType}
          isAdmin={isAdmin}
        />
      </Elements>
    </>
  )
}

export default MultipleOrders

import React from 'react'
import {
  Table, Input, Form,
} from 'antd'
import './index.css'
import { FormattedMessage } from 'react-intl'

const EditableContext = React.createContext()

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr
      // eslint-disable-next-line
      {...props}
    />
  </EditableContext.Provider>
)

const EditableFormRow = Form.create()(EditableRow)

class EditableCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
    }
  }

  toggleEdit = () => {
    const { editing } = this.state
    const isEditing = !editing
    this.setState({ editing: isEditing }, () => {
      if (isEditing) {
        this.input.focus()
      }
    })
  };

  save = (e) => {
    const { record, handleSave } = this.props
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return
      }
      this.toggleEdit()
      handleSave({ ...record, ...values })
    })
  };

  renderCell = (form) => {
    this.form = form
    const {
      children, dataIndex, record, title, unrequired,
    } = this.props

    const { editing } = this.state

    const errorStyle = record.error && record.errorDetails.find((err) => err.key === dataIndex)
      ? { padding: 10, border: '1px solid red', color: 'red' }
      : {}
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: !unrequired.includes(dataIndex),
              message:
        <div>
          {title}
          {' : '}
          <FormattedMessage id='editableTable.required' defaultMessage='donnée requise' />
        </div>,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input
          ref={(node) => {
            this.input = node
            return true
          }}
          onPressEnter={this.save}
          onBlur={this.save}
          style={{ minWidth: '130px' }}
        />)}
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{
          minHeight: 32,
          paddingRight: 24,
          ...errorStyle,
          borderRadius: '5px',
        }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    )
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props
    return (
    // eslint-disable-next-line
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    )
  }
}

const EditableTable = (props) => {
  const {
    dataSource, handleSave, modifyAddresse, unrequired = [], columns, size = 'default',
  } = props
  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  }

  const myColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        save: modifyAddresse,
        handleSave,
        unrequired,
      }),
    }
  })

  return (
    <div>
      <Table
        components={components}
        rowClassName={(record) => `editable-row focus-${record.key}`}
        dataSource={dataSource}
        columns={myColumns}
        bordered={false}
        pagination={false}
        unrequired={unrequired}
        size={size}
      />
    </div>
  )
}

export default EditableTable

/* eslint-disable */

import { format } from 'libphonenumber-js'
import { getDeliveryDelays } from '../../store/cart/selectors'

export const cleanedString = (string) => {
  try {
    if (!string || typeof string === 'undefined') {
      return ''
    }
    return string.replace(/ /g, '')
  } catch (e) {
    console.log('cleanedString', e)
    return string
  }
}

export const getInitialClientValues = (addresses, initialValues, checkout) => {
  try {
    if (
      addresses
      && addresses.length >= 1
    ) {
      const picked = addresses[0].address

      return {
        ...picked,
        prefilledAddress: addresses[0].identifier,
        editableUser: addresses[0].editableUser,
        editableData: addresses[0].editableData,
      }
    }

    if (checkout) {
      return checkout.client
    }

    return initialValues.client
  } catch (error) {
    console.log('checkout - ', { error })
    return initialValues.client
  }
}

export const createStripeToken = (name, stripe) => {
  try {
    return stripe.createPaymentMethod('card', {
      billing_details: {
        // TODO: create metadata
        name,
      },
    })
      .then((methodResult) => {
        const { paymentMethod } = methodResult
        if (paymentMethod) {
          return paymentMethod
        }
        return false
      })
      .catch((error) => {
        console.log('createStripeToken caught 1', { error })
      })
  } catch (e) {
    console.log('createStripeToken caught 2', { error: e })
    return false
  }
}

const formatAddress = ({
  city,
  company,
  complement,
  country = 'FR',
  email,
  firstName,
  lastName,
  phone,
  postalCode,
  street,
  lang = 'fr',
}) => ({
  firstName,
  lastName,
  email,
  // company,
  // complement,
  // addressComplement,
  // city,
  // postalCode,
  phone: format(
    phone,
    country || 'FR',
    'International',
  ),
  client_lang: lang.toLowerCase(),
  address: {
    streetAddress: street,
    city,
    company,
    complement,
    postalCode,
    country,
  },
})

/**
 * @return {Object} a formatteds order you can POST to the API
 * @param {*} cart the cart object
 * @param {*} checkout the checkout form content (client, billing ...)
 * @param {*} shop the shop object
 * @param {*} source the payment method
 */
export const formatOrder = ({
  content,
  delivery_pricing, // AUDIT : est-ce que delivery_pricing.deliveryMin/deliveryMax existent ici ?
  products_pricing,
  price,
  entityId,
}, checkout, shop, source = {}, clientLang = 'fr') => {
  try {
    const {
      billing,
    } = checkout
    const { _id } = shop
    const { name, logo, url } = shop.settings
    const {
      prefilledAddress,
    } = checkout.client

    let client = formatAddress(checkout.client)

    // TODO escape regrouped?
    if (prefilledAddress && prefilledAddress !== 'default') {
      client = {
        ...client,
        prefilledAddress,
      }
    }

    const amount = parseInt(parseFloat(price).toFixed(2) * 100, 10)

    if (!checkout.sameBillingAddress) {
      client.billingAddress = formatAddress(billing)
    } else {
      client.billingAddress = {
        ...client,
      }
    }

    client.client_lang = clientLang
    const { pickupInfos } = checkout.client
    const newProducts = []
    content.map((p) => {
      if (p.clientCustomization && p.clientCustomization.length) {
        p.clientCustomization.map((custo) => {
          newProducts.push({ ...p, clientCustomization: custo, quantity: 1 })
        })
      } else {
        newProducts.push(p)
      }
    })

    const defaultConfigDeliveries = getDeliveryDelays() // handle default values for delieries

    const raw_order = {
      products: newProducts.map((p) => ({ ...p, id: p._id, entityId })),
      client,
      seller_name: name,
      seller: _id,
      seller_logo: logo,
      seller_url: window.location.protocol.concat('//', window.location.host),
      seller_email: `${url}@panopli.co`,
      shippingPrice: parseFloat(delivery_pricing.delivery_discounted).toFixed(2),
      customerOrderId: Math.random().toString(36).substr(2, 10),
      entityId,
      shipping: {
        shippingMode: checkout.deliveryMode || 'standard',
        pickupStoreId: pickupInfos ? pickupInfos.identifiant : '',
        pickupInfos: pickupInfos || {},
      },
      transaction: {
        amount,
        currency: 'eur',
        transaction_type: checkout.transactionType || 'payment',
        source,
      },
      internalOrder: true,
      detailedPricing: {
        total: parseFloat(products_pricing.total),
        total_discounted: products_pricing.total_discounted,
        delivery: delivery_pricing.delivery,
        delivery_discounted: delivery_pricing.delivery_discounted,
      },
      deliveryDelays: {
        deliveryMin: (delivery_pricing && delivery_pricing.deliveryMin) || defaultConfigDeliveries.deliveryMin,
        deliveryMax: (delivery_pricing && delivery_pricing.deliveryMax) || defaultConfigDeliveries.deliveryMax,
      },
    }
    return raw_order
  } catch (e) {
    console.log('helpers - formatOrder > this should never fail', e)
    return {}
  }
}

export const getStripeKey = (facturation, fallbackKey) => {
  try {
    // NOTE: escape case for local dev
    if (
      typeof window !== 'undefined'
      && window.location.hostname.includes('localhost')
      && process.env.NODE_ENV !== 'test'
    ) {
      return fallbackKey
    }
    const stripe_key = (
      facturation
      && facturation.mode === 'standard'
      && facturation.stripeSettings
      && facturation.stripeSettings.stripe_publishable_key
    )
      ? facturation.stripeSettings.stripe_publishable_key
      : fallbackKey
    return stripe_key
  } catch (error) {
    console.log('getStripeKey', error)
    return fallbackKey
  }
}

export const isEmail = (email) => {
  try {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
  } catch (e) {
    console.log('isEmail', e)
    return false
  }
}

export const validate = {
  required: (value, error = 'Required') => (!value ? error : null),
  clean: (value, error = 'Required') => (cleanedString(value) ? null : error),
  email: (value, error = 'Invalid Email') => (isEmail(value) ? null : error),
  composeValidators: (...validators) => (value) => validators.reduce((error, validator) => error
  || validator(value), undefined),
}

// DO WE NEED A WARN FUNCTION ? -> yes but P4
export const warn = (values) => {
  const warnings = {}
  if (values.firstName === 'Jeremy') {
    warnings.firstName = 'Hmm, you seem a bit young...'
  }
  if (values.firstName === 'Mathieu') {
    warnings.firstName = 'Almighty CTO'
  }
  return warnings
}

export const createToken = (stripe, name) => stripe.createToken({ type: 'card', name })

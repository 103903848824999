/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { injectIntl, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  CampaignCreationEmailing,
  CampaignCreationMessage,
  CampaignCreationParameters,
  CampaignCreationProducts,
  CampaignCreationSteps,
  CampaignCreationTheme,
  CreateCampaignHeader,
} from '../../components'

import { baseCampaign } from '../../helpers/campaignFinder'
import { decodeQuery } from '../../helpers/filters'
import { successNotify } from '../../helpers/notification'
import { CREATE, SEND_TEST, UPDATE } from '../../store/campaigns/actions'
import { UPLOAD } from '../../store/images/actions'
import { fromCampaigns, fromEntities, fromProducts } from '../../store/selectors'

const CampaignCreateContainer = (props) => {
  const {
    shop, // Required
    products, // Required
    createCampaign, // Required
    updateCampaign, // Required
    history, // Required
    user, // Required
    sendTestEmail, // Required
    campaigns, // Required
    uploadImage, // Required
    entities, // Required
  } = props

  const intl = useIntl()

  const { location: { search } } = history

  const campaignIdFromQuery = decodeQuery(search).id ?? null

  const entity = entities.find((entityToFind) => (entityToFind._id === campaignIdFromQuery) || entityToFind.main)

  const [campaign, setCampaign] = useState({
    ...baseCampaign,
    template: {
      ...baseCampaign?.template,
      title: intl.formatMessage({
        id: 'campaigns.create.step.emailing.emailObject.defaultValue',
        defaultMessage: 'Vous avez reçu un cadeau !',
      }),
    },
    theme: {
      mainColor: shop?.settings?.theme?.mainColor,
      fontColor: shop?.settings?.theme?.fontColor,
      logo: shop?.settings?.logo,
      logoName: shop?.settings?.logo?.substring(
        shop?.settings?.logo?.lastIndexOf('/') + 1,
        shop?.settings?.logo?.indexOf('?'),
      ),
    },
  })

  const [currentStep, setCurrentStep] = useState(0)
  const [needCreateCampaign, setNeedCreateCampaign] = useState(true)
  const [needUpdateCampaign, setNeedUpdateCampaign] = useState(false)
  const [createdCampaignId, setCreatedCampaignId] = useState(campaignIdFromQuery)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  useEffect(() => {
    if (campaignIdFromQuery) {
      const campaignFound = campaigns?.find((campaignToFind) => campaignToFind?._id === campaignIdFromQuery)
      if (campaignFound) {
        setCampaign({ ...campaignFound })
        setNeedCreateCampaign(false)
        setCreatedCampaignId(campaignFound?._id)
      }
    }
  }, [campaignIdFromQuery])

  const moveNextStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const movePreviousStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const createNewCampaign = (handledCampaign, isMovingNextStep = true) => {
    createCampaign(
      { ...handledCampaign },
      (createdCampaign) => {
        setCreatedCampaignId(createdCampaign?._id)
        setCampaign(createdCampaign)
        setNeedCreateCampaign(false)
        if (isMovingNextStep) {
          moveNextStep()
        }
        successNotify({
          intl,
          messageId: 'store.editCampaign.create.success',
          defaultMessage: 'Campagne créée !',
        })
        console.log(`campaign created with id [${createdCampaign._id}]`)
      },
    )
  }

  const updateCreatedCampaign = (createdCampaign, isMovingNextStep = true) => {
    // eslint-disable-next-line no-unused-expressions
    needUpdateCampaign
      ? updateCampaign(
        { ...createdCampaign, _id: createdCampaignId },
        (updatedCampaign) => {
          setCampaign(updatedCampaign)
          setNeedUpdateCampaign(false)
          // eslint-disable-next-line no-unused-expressions
          if (isMovingNextStep) {
            moveNextStep()
          }
          successNotify({ intl, messageId: 'update.success', defaultMessage: 'Mise à jour réussie !' })
        },
      ) : moveNextStep()
  }

  const saveAndSendOrViewCampaign = (activeCampaign) => {
    updateCampaign(
      { ...activeCampaign, _id: createdCampaignId },
      (updatedCampaign) => {
        setCampaign(updatedCampaign)
        setNeedUpdateCampaign(false)
        successNotify({ intl, messageId: 'update.success', defaultMessage: 'Mise à jour réussie !' })
      },
    )
  }

  return (
    <>
      <CreateCampaignHeader
        history={history}
        saveCampaign={() => {
          if (needUpdateCampaign) {
            updateCreatedCampaign(campaign, false)
          } if (needCreateCampaign) {
            createNewCampaign(campaign, false)
          }
        }}
        needCreateCampaign={needCreateCampaign}
        needUpdateCampaign={needUpdateCampaign}
        currentStep={currentStep}
        createdCampaignId={createdCampaignId}
        campaign={campaign}
        saveAndSendOrViewCampaign={saveAndSendOrViewCampaign}
      />
      <div>
        <CampaignCreationSteps currentStep={currentStep} />
        {(() => {
          switch (currentStep) {
          case 0:
            return (
              <CampaignCreationParameters
                setCampaign={setCampaign}
                campaign={campaign}
                shop={shop}
                moveNextStep={() => {
                  if (needUpdateCampaign) {
                    updateCreatedCampaign(campaign)
                  } if (needCreateCampaign) {
                    createNewCampaign(campaign)
                  } else {
                    moveNextStep()
                  }
                }}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                createdCampaignId={createdCampaignId}
              />
            )
          case 1:
            return (
              <CampaignCreationTheme
                setCampaign={setCampaign}
                campaign={campaign}
                moveNextStep={() => { updateCreatedCampaign(campaign) }}
                movePreviousStep={movePreviousStep}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                createdCampaignId={createdCampaignId}
                uploadImage={uploadImage}
              />
            )
          case 2:
            return (
              <CampaignCreationMessage
                setCampaign={setCampaign}
                campaign={campaign}
                moveNextStep={() => { updateCreatedCampaign(campaign) }}
                movePreviousStep={movePreviousStep}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
              />
            )
          case 3:
            return (
              <CampaignCreationProducts
                setCampaign={setCampaign}
                campaign={campaign}
                moveNextStep={() => { updateCreatedCampaign(campaign) }}
                movePreviousStep={movePreviousStep}
                products={products}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                creationMode
                shop={shop}
                entity={entity}
                user={user}
              />
            )
          case 4:
            return (
              <CampaignCreationEmailing
                setCampaign={setCampaign}
                campaign={campaign}
                movePreviousStep={movePreviousStep}
                userEmail={user?.email}
                sendTestEmail={sendTestEmail}
                updateCampaign={updateCampaign}
                handleCampaign={(createdCampaign) => {
                  updateCampaign(
                    { ...createdCampaign, _id: createdCampaignId },
                    (updatedCampaign) => {
                      setCampaign(updatedCampaign)
                      setNeedUpdateCampaign(false)
                      successNotify({ intl, messageId: 'update.success', defaultMessage: 'Mise à jour réussie !' })
                    },
                  )
                }}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                createdCampaignId={createdCampaignId}
                history={history}
              />
            )
          default:
            return (
              <CampaignCreationParameters
                setCampaign={setCampaign}
                campaign={campaign}
                shop={shop}
                moveNextStep={() => { createNewCampaign(campaign) }}
              />
            )
          }
        })()}
      </div>
    </>
  )
}

export default connect((state) => ({
  products: fromProducts.allStockedProducts(state),
  campaigns: fromCampaigns.getCampaigns(state),
  entities: fromEntities.getEntities(state),
}),
(dispatch) => bindActionCreators({
  createCampaign: (payload, callback) => ({
    type: CREATE,
    payload,
    callback,
  }),
  updateCampaign: (payload, callback) => ({
    type: UPDATE,
    payload,
    callback,
  }),
  sendTestEmail: (payload) => ({
    type: SEND_TEST,
    payload,
  }),
  uploadImage: (payload, callback) => ({
    type: UPLOAD,
    payload,
    callback,
  }),
}, dispatch))(injectIntl(CampaignCreateContainer))

import React from 'react'
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  message,
  Divider,
  Checkbox,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import theme from '../../../config/theme'
import './style.css'

class RegisterForm extends React.Component {
  constructor() {
    super()
    this.state = {
      visible: false,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
      },
      createUser,
      history,
    } = this.props
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          email,
          phone,
          password,
          firstName,
          newsletter,
          lastName,
        } = values
        // console.log('Received values of form: ', values)
        const result = {
          email,
          additionalInfos: {
            firstName,
            lastName,
            phone,
          },
          password,
          newsletter,
        }

        createUser({
          user: result,
          callback: (error) => {
            this.setState({
              visible: false,
            })
            if (error) {
              message.error(
                <FormattedMessage
                  id='signup.error'
                  defaultMessage='Une erreur est survenue'
                />,
              )
              console.log('RegisterDrawer - error', { error })
              return false
            }
            message.success(
              <FormattedMessage
                id='signup.success'
                defaultMessage='Bienvenue chez Panopli !'
              />,
            )
            history.push('/onboarding')
            return true
          },
        })
      }
    })
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        getFieldValue,
        isFieldsTouched,
      },
    } = this.props

    const { visible } = this.state

    return (
      <div
        className='registrationForm'
      >
        <Button
          type='link'
          onClick={this.showDrawer}
          style={{ fontWeight: 'bold' }}
        >
          <FormattedMessage
            id='register.newAccount'
            defaultMessage='Créer mon compte'
          />
        </Button>
        <Drawer
          title={(
            <FormattedMessage
              id='register.title'
              defaultMessage='Créer mon compte'
            />
          )}
          width='50%'
          onClose={this.onClose}
          visible={visible}
        >
          <Form
            onSubmit={this.handleSubmit}
            style={{
              padding: '0 12px',
              color: theme.primaryColor[6],
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={<FormattedMessage id='email' defaultMessage='Email' />}>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: <FormattedMessage id='email.notValid' defaultMessage="L'Email n'est pas valide" />,
                      },
                      {
                        required: true,
                        message: <FormattedMessage id='required.email' defaultMessage='Veuillez renseigner un Email' />,
                      },
                    ],
                  })(<Input placeholder='email@company.com' />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<FormattedMessage id='phone' defaultMessage='Téléphone' />}
                >
                  {getFieldDecorator('phone', {
                    rules: [],
                  })(<Input placeholder='0678654543' />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  label={(
                    <FormattedMessage
                      id='password'
                      defaultMessage='Mot de passe'
                    />
                  )}
                >
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(<Input.Password placeholder='********' />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={(
                    <FormattedMessage
                      id='register.passwordConfirm'
                      defaultMessage='Mot de passe (confirmer)'
                    />
                  )}
                  hasFeedback
                >
                  {getFieldDecorator('confirm', {
                    rules: [
                      {
                        required: !!getFieldValue('password'),
                        message: <FormattedMessage id='required.passwordConfirmation' defaultMessage='Veuillez confirmer votre mot de passe' />,
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(<Input.Password placeholder='********' onBlur={this.handleConfirmBlur} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<FormattedMessage id='firstName' defaultMessage='Prénom' />}
                >
                  {getFieldDecorator('firstName', {
                    rules: [{
                      required: true,
                      message: <FormattedMessage id='required.firstName' defaultMessage='Veuillez renseigner un prénom' />,
                      whitespace: true,
                    }],
                  })(<Input placeholder='Bruce' />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={(
                    <FormattedMessage
                      id='lastName'
                      defaultMessage='Nom'
                    />
                  )}
                >
                  {getFieldDecorator('lastName', {
                    rules: [{ whitespace: true }],
                  })(<Input placeholder='Wayne' />)}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ marginBottom: 0 }}>
                  {getFieldDecorator('CGU', {
                    rules: [{
                      required: true,
                      message: <FormattedMessage id='required' defaultMessage='Requis' />,
                    }],
                  })(
                    <Checkbox>
                      <FormattedMessage
                        id='register.comply'
                        defaultMessage='En créant mon compte, je certifie accepter les'
                      />
                      <a target='_blank' href='/tos' style={{ marginLeft: 5 }}>
                        <FormattedMessage
                          id='register.CGU'
                          defaultMessage='CGU/CGV'
                        />
                      </a>
                    </Checkbox>,
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator('newsletter', {
                    rules: [],
                  })(
                    <Checkbox>
                      <FormattedMessage
                        id='register.newsletter'
                        defaultMessage='Tenez-moi au courant des nouveautés sur Panopli'
                      />
                    </Checkbox>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {isFieldsTouched() ? (
              <Row>
                <Divider />
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    block
                    style={{
                      marginRight: '12px',
                    }}
                  >
                    <FormattedMessage
                      id='registration.submit'
                      defaultMessage='Créer mon compte !'
                    />
                  </Button>
                </Form.Item>
              </Row>
            ) : null}
          </Form>
        </Drawer>
      </div>
    )
  }
}

export default Form.create()(RegisterForm)

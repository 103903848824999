import React from 'react'
import { privacyPolicy } from '../../helpers/legals'

const PrivacyPolicy = (props) => {
  const { locale = 'fr' } = props
  return (
    <>
      {privacyPolicy[locale]}
    </>
  )
}

export default PrivacyPolicy

import React, { useState } from 'react'

import {
  Icon,
  Radio,
  Button,
} from 'antd'

import { FormattedMessage } from 'react-intl'
import ReactHtmlParser from 'react-html-parser' // utilisé pour interpréter de l'html safely

const DEFAULT_SUBJECT_VALUE = 'Pas de sujet renseigné'
const DEFAULT_BODY_VALUE = 'Pas de body renseigné'
export const DEFAULT_CTA_VALUE = 'J\'y vais !'

/**
 * Composant qui permet de preview un mail en mode laptop (par défaut) ou en mode smartphone.
 *
 * @param {string} subject - Sujet du mail à preview
 * @param {String} body - Corps du mail à preview
 * @returns
 */
const EmailPreview = (props) => {
  try {
    const {
      subject = DEFAULT_SUBJECT_VALUE, // handle undefined field
      body = DEFAULT_BODY_VALUE, // handle undefined field
      cta = DEFAULT_CTA_VALUE, // handle undefined field
      shop,
    } = props

    const { logo, theme = {} } = shop.settings
    const { pageBackgroundColor = 'white', mainColor = 'black' } = theme
    const valueRadioLaptopPreview = 'laptopPreview'
    const valueRadioSmartphonePreview = 'smartphonePreview'

    // Gestion du format de la prévisualisation
    const [displayDefaultPreview, setDisplayDefaultPreview] = useState(true)

    /**
   * Fonction appelée au toggle du switch de preview
   * @param {*} checked
   */
    const onChangePreview = (e) => {
      // Si Switch checked, on désactive la preview par défaut
      setDisplayDefaultPreview(e.target.value === valueRadioLaptopPreview)
    }
    return (
      <>
        <h4 style={{ textAlign: 'center', marginBottom: '10px' }}>
          <FormattedMessage
            id='campaign.edit.template.preview'
            defaultMessage='Prévisualisation'
          />
          <Radio.Group
            style={{ marginLeft: '10px' }}
            defaultValue={valueRadioLaptopPreview}
            onChange={onChangePreview}
          >
            <Radio.Button value={valueRadioLaptopPreview}><Icon type='laptop' /></Radio.Button>
            <Radio.Button value={valueRadioSmartphonePreview}><Icon type='mobile' /></Radio.Button>
          </Radio.Group>
        </h4>
        <h3 style={{ fontWeight: 'bold', marginBottom: '10px' }}>
          <FormattedMessage
            id='emailPreview.defaultPreview.subject'
            defaultMessage='Sujet : {subject}'
            values={{ subject: subject?.length > 0 ? subject : DEFAULT_SUBJECT_VALUE }}
          />
        </h3>
        <div style={{
          backgroundColor: '#f8f8f8',
          padding: '15px',
          display: 'flex',
          justifyContent: 'center',
        }}
        >
          <div style={{
            backgroundColor: pageBackgroundColor,
            padding: 20,
            height: 500,
            width: displayDefaultPreview ? '100%' : '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflowY: 'auto',
          }}
          >
            {
              // Si pas de logo, on n'affiche pas l'image
              logo
                ? (
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <img src={logo} alt='logo' style={{ marginBottom: '20px', width: 'auto', height: '150px' }} />
                  </div>
                ) : null
            }
            {ReactHtmlParser(body?.length > 0 ? body : DEFAULT_BODY_VALUE)}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button style={{ backgroundColor: mainColor, color: pageBackgroundColor, fontWeight: 'bold' }}>
                {cta?.length > 0 ? cta : DEFAULT_CTA_VALUE}
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  } catch (e) {
    console.log('error molecules/EmailPreview', e)
    return null
  }
}

export default EmailPreview

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { InnerLayout } from '../../layouts'
import { INIT_FETCH_DATA } from '../../store/orders/actions'
import {
  MultipleOrdersContainer as BatchOrders,
} from '../../containers'

const MultipleOrders = (props) => {
  const {
    location, isAdmin, fetchData, shopId,
  } = props
  const { selected = [] } = location.state || {}

  useEffect(() => {
    fetchData()
  }, [shopId])

  return (
    <InnerLayout
      title={(
        <div style={{
          fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
        }}
        >
          <FormattedMessage
            id='multipleOrders.pageTitle'
            defaultMessage='Envoi Multiple'
          />
        </div>
      )}
    >
      <div>
        <BatchOrders selected={selected} isAdmin={isAdmin} />
      </div>
    </InnerLayout>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(
  connect(null, mapDispatchToProps)(MultipleOrders),
)

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button,
  Spin,
  Result,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import {
  fromApiKeys,
  fromCollaborators,
  fromUsers,
} from '../../store/selectors'
import { UPDATE, DELETE } from '../../store/apiKeys/actions'

import {
  Placeholder,
  ApiKeysTable,
} from '../../components'

const Developers = (props) => {
  const {
    loading,
    error,
    setExtra,
    setDrawerVisible,
    setCurrentApiKey,
    apiKeys,
    collaborators,
    user,
    updateApiKey,
    deleteApiKey,
  } = props

  useEffect(() => {
    setExtra([
      <Button href='https://panopli.readme.io/docs' target='_blank'>
        <FormattedMessage
          id='developpers.page.howitworks'
          defaultMessage='Comment ça marche ?'
        />
      </Button>,
      <Button
        type='primary'
        icon='plus'
        onClick={() => setDrawerVisible(true)}
      >
        <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
          <FormattedMessage
            id='developpers.page.create.new'
            defaultMessage='Créer une nouvelle clé API'
          />
        </span>
      </Button>,
    ])
  }, [])

  if (loading && (!apiKeys || !apiKeys.length)) {
    return (
      <div style={{
        inset: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
      }}
      >
        <Spin size='large' />
      </div>
    )
  }
  if (error) {
    return (
      <div style={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
      }}
      >
        <Result
          status='500'
          title='500'
          subTitle={(
            <FormattedMessage
              id='developpers.page.break'
              defaultMessage='Désolé, le serveur fait une pause.'
            />
          )}
        />
      </div>
    )
  }
  if (!apiKeys || !apiKeys.length) {
    return (
      <Placeholder>
        <Button
          type='primary'
          icon='plus'
          onClick={() => setDrawerVisible(true)}
        >
          <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
            <FormattedMessage
              id='developpers.page.create.first.new'
              defaultMessage='Créer ma première clé API'
            />
          </span>
        </Button>
      </Placeholder>
    )
  } return (
    <div style={{ position: 'relative' }}>
      <div style={{
        inset: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        opacity: loading ? 0.6 : 0,
        zIndex: loading ? 1000 : 0,
        transition: 'opacity 0.1s',
      }}
      >
        <Spin size='large' />
      </div>
      <ApiKeysTable
        apiKeys={apiKeys}
        collaborators={collaborators}
        user={user}
        setDrawerVisible={setDrawerVisible}
        setCurrentApiKey={setCurrentApiKey}
        updateApiKey={updateApiKey}
        deleteApiKey={deleteApiKey}
      />
    </div>
  )
}

export default connect((state) => ({
  apiKeys: fromApiKeys.getApiKeys(state),
  user: fromUsers.getUser(state),
  collaborators: fromCollaborators.getCollaborators(state),
  loading: fromApiKeys.loading(state),
  error: fromApiKeys.error(state),
}), (dispatch) => bindActionCreators({
  updateApiKey: (payload) => ({
    type: UPDATE,
    payload,
  }),
  deleteApiKey: (payload, callback) => ({ type: DELETE, payload, callback }),
}, dispatch))(Developers)

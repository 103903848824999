import React, { useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'
import { CreateCampaignStepButton, CustomizableModal } from '../../atoms'
import theme from '../../../config/theme'

const CampaignCreateModal = (props) => {
  const {
    disabled, // Required
    onSendCampaign, // Required
    onViewCampaign, // Required
    onUpdateCampaign, // Required
    buttonStyle,
  } = props

  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <>
      <CreateCampaignStepButton
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '9px 16px',
          height: '40px',
          ...buttonStyle,
        }}
        type='primary'
        disabled={disabled}
        onClickFunction={() => {
          onUpdateCampaign()
          setIsModalVisible(true)
        }}
      />
      <CustomizableModal
        isModalVisible={isModalVisible}
        iconBackgroundColor={theme.validColor[1]}
        icon={faCheck}
        iconColor={theme.validColor[6]}
        formattedMessageTitle={
          <FormattedMessage id='campaigns.create.step.modals.success.title' defaultMessage='Votre campagne a bien été créée' />
        }
        FormattedMessageDescriptions={(
          <FormattedMessage
            id='campaigns.create.step.modals.success.message'
            defaultMessage='Vous pouvez envoyer votre campagne dès maintenant'
          />
        )}
        onCancel={() => setIsModalVisible(false)}
      >
        <Button
          style={{
            width: '100%',
            display: 'block',
            margin: '24px 0 12px 0',
            height: '40px',
          }}
          key='send'
          onClick={() => {
            setIsModalVisible(false)
            onSendCampaign()
          }}
          type='primary'
        >
          <FormattedMessage id='magicLink.send' defaultMessage='Envoyer des Magic Links' />
        </Button>
        <Button
          style={{
            width: '100%',
            display: 'block',
            margin: '0',
            height: '40px',
          }}
          key='view'
          onClick={() => {
            setIsModalVisible(false)
            onViewCampaign()
          }}
        >
          <FormattedMessage
            id='campaigns.create.step.modals.success.button.view'
            defaultMessage='Voir ma campagne'
          />
        </Button>
      </CustomizableModal>
    </>

  )
}

export default CampaignCreateModal

import * as AWS from 'aws-sdk'
import config, { IMAGE_PREFIX, BUCKET_NAME, AWS_REGION } from '../../config'

const {
  key,
  secret_key: secretKey,
} = config.aws

AWS.config.update({
  accessKeyId: key,
  secretAccessKey: secretKey,
  region: AWS_REGION,
})

const s3 = new AWS.S3()

const PREFIX = `${IMAGE_PREFIX}/Pastille/low`

const params = {
  Bucket: BUCKET_NAME,
  Prefix: PREFIX,
}

const getAllPastilles = async () => {
  const pastilles = await s3.listObjectsV2(params).promise()
    .then((data) => data.Contents.map((content) => {
      const color = content.Key.replace(`${PREFIX}/`, '').replace('.png', '')
      if (color) {
        return color
      }
      return null
    }).filter(Boolean))
    .catch((error) => {
      console.log('error in getAllPastilles', error)
      return []
    })
  return pastilles
}

export default getAllPastilles

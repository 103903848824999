import React, { useState } from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { ExitCampaignCreationModal } from '../..'

const CampaignLeaveModal = (props) => {
  const {
    history, // Required
    saveCampaign, // Required
    isCreateFlow = true,
    needCreateCampaign, // Required
    needUpdateCampaign, // Required
    campaign, // Required
  } = props

  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <>
      <Button
        style={{
          padding: '0',
          width: '40px',
          height: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
        onClick={
          // eslint-disable-next-line no-unused-expressions
          () => { !needCreateCampaign && !needUpdateCampaign ? history.push('/magicLinks?tabs=campaigns') : setIsModalVisible(true) }
        }
      >
        <FontAwesomeIcon icon={faArrowLeftLong} />
      </Button>
      <ExitCampaignCreationModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleSaveAndLeave={() => {
          saveCampaign(campaign)
          setIsModalVisible(false)
          history.push('/magicLinks?tabs=campaigns')
        }}
        handleLeave={() => {
          setIsModalVisible(false)
          history.push('/magicLinks?tabs=campaigns')
        }}
        onCancel={() => setIsModalVisible(false)}
        isCreateFlow={isCreateFlow}
      />
    </>
  )
}

export default CampaignLeaveModal

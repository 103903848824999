/* eslint-disable max-len */
import React, { useState } from 'react'
import {
  Upload,
  Modal,
  Icon,
  Divider,
  Alert,
} from 'antd'
import { uniqBy } from 'lodash'
import { FormattedMessage } from 'react-intl'
import getBase64 from '../../../helpers/imageUpload'

const ProductAWSUploadForm = (props) => {
  try {
    const {
      product,
      updateProduct,
    } = props

    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [printFileFront, setPrintFileFront] = useState(product.printingFile?.front?.length ? product.printingFile.front : [])
    const [printFileBack, setPrintFileBack] = useState(product.printingFile?.back?.length ? product.printingFile.back : [])
    const [previewFront, setPreviewFront] = useState(product.previewFile?.front?.length ? product.previewFile.front : [])
    const [previewBack, setPreviewBack] = useState(product.previewFile?.back?.length ? product.previewFile.back : [])
    const [picList, setPicList] = useState(product.picListToUpload && product.picListToUpload.length ? product.picListToUpload : [])

    const uploadButton = (side) => (
      <div>
        <Icon type='plus' />
        <div style={{ marginTop: 8 }}>
          {`Upload ${side}`}
        </div>
      </div>
    )

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img alt='example' style={{ width: '100%' }} src={previewImage} />
        </Modal>
        {product.baseProduct.printingZone && (
          <>
            <div>
              <div style={{   fontWeight: 'bold', marginBottom: '10px' }}>
                <FormattedMessage id='productAWSUploadForm.addPritingFiles' defaultMessage="Ajouter des fichiers d'impression" />
              </div>
              <Alert
                message={(
                  <FormattedMessage
                    id='productAWSUploadForm.alertMessage.addPritingFiles'
                    defaultMessage="Attention : le fichier d'impression doit être de 150 dpi obligatoirement, sinon la commande ne passera pas"
                  />
                )}
                type='warning'
                closable
                showIcon
                style={{ marginBottom: '10px' }}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                  {product.baseProduct.printingZone.front && (
                    <Upload
                      name='front/print'
                      listType='picture-card'
                      fileList={printFileFront}
                      customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                          onSuccess('ok')
                        }, 500)
                      }}
                      onPreview={async (_file) => {
                        const file = _file
                        if (!file.url && !file.preview) {
                          file.preview = await getBase64(file.originFileObj)
                        }
                        setPreviewImage(file.url || file.preview)
                        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
                        setPreviewVisible(true)
                      }}
                      onChange={async ({ file }) => {
                        const _file = file
                        if (file.status === 'removed') {
                          await setPrintFileFront([])
                          const design_ids = product.design_ids.filter((design) => design.position !== 'front')
                          return updateProduct(product.provisionalId, {
                            printingFile: {
                              ...product.printingFile,
                              front: [],
                            },
                            design_ids,
                            pricing: {
                              ...product.pricing,
                              customizationPrice: design_ids.length ? (design_ids.length - 1) * 5 : 0,
                            },
                          })
                        }
                        _file.thumbUrl = await getBase64(file.originFileObj)
                        await setPrintFileFront([_file])
                        if (product.design_ids.filter((design) => design.position === 'front').length === 0) {
                          product.design_ids.push({ position: 'front' })
                        }
                        return updateProduct(product.provisionalId, {
                          printingFile: {
                            ...product.printingFile,
                            front: printFileFront,
                          },
                          design_ids: product.design_ids,
                          pricing: {
                            ...product.pricing,
                            customizationPrice: product.design_ids.length ? (product.design_ids.length - 1) * 5 : 0,
                          },
                        })
                      }}
                    >
                      {printFileFront.length === 1 ? null : uploadButton('front')}
                    </Upload>
                  )}
                  {product.baseProduct.printingZone.back && (
                    <Upload
                      name='back/print'
                      listType='picture-card'
                      fileList={printFileBack}
                      customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                          onSuccess('ok')
                        }, 500)
                      }}
                      onPreview={async (_file) => {
                        const file = _file
                        if (!file.url && !file.preview) {
                          file.preview = await getBase64(file.originFileObj)
                        }
                        setPreviewImage(file.url || file.preview)
                        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
                        setPreviewVisible(true)
                      }}
                      onChange={async ({ file }) => {
                        const _file = file
                        if (file.status === 'removed') {
                          await setPrintFileBack([])
                          const design_ids = product.design_ids.filter((design) => design.position !== 'back')
                          return updateProduct(product.provisionalId, {
                            printingFile: {
                              ...product.printingFile,
                              back: [],
                            },
                            design_ids,
                            pricing: {
                              ...product.pricing,
                              customizationPrice: design_ids.length ? (design_ids.length - 1) * 5 : 0,
                            },
                          })
                        }
                        _file.thumbUrl = await getBase64(file.originFileObj)
                        await setPrintFileBack([_file])
                        if (product.design_ids.filter((design) => design.position === 'back').length === 0) {
                          product.design_ids.push({ position: 'back' })
                        }
                        return updateProduct(product.provisionalId, {
                          printingFile: {
                            ...product.printingFile,
                            back: printFileBack,
                          },
                          design_ids: product.design_ids,
                          pricing: {
                            ...product.pricing,
                            customizationPrice: product.design_ids.length ? (product.design_ids.length - 1) * 5 : 0,
                          },
                        })
                      }}
                    >
                      {printFileBack.length === 1 ? null : uploadButton('back')}
                    </Upload>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <div>
              <div style={{   fontWeight: 'bold', marginBottom: '10px' }}>
                <FormattedMessage id='productAWSUploadForm.addPreviews' defaultMessage='Ajouter les previews' />
              </div>
              <div style={{ display: 'flex' }}>
                {product.baseProduct.printingZone.front && (
                  <Upload
                    name='front/preview'
                    listType='picture-card'
                    fileList={previewFront}
                    customRequest={({ onSuccess }) => {
                      setTimeout(() => {
                        onSuccess('ok')
                      }, 500)
                    }}
                    onPreview={async (_file) => {
                      const file = _file
                      if (!file.url && !file.preview) {
                        file.preview = await getBase64(file.originFileObj)
                      }
                      setPreviewImage(file.url || file.preview)
                      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
                      setPreviewVisible(true)
                    }}
                    onChange={async ({ file }) => {
                      const _file = file
                      if (file.status === 'removed') {
                        await setPreviewFront([])
                        return updateProduct(product.provisionalId, {
                          previewFile: {
                            ...product.printingFile,
                            front: [],
                          },
                        })
                      }
                      _file.thumbUrl = await getBase64(file.originFileObj)
                      await setPreviewFront([_file])
                      return updateProduct(product.provisionalId, {
                        previewFile: {
                          ...product.previewFile,
                          front: previewFront,
                        },
                      })
                    }}
                  >
                    {previewFront.length === 1 ? null : uploadButton('front')}
                  </Upload>
                )}
                {product.baseProduct.printingZone.back && (
                  <Upload
                    name='back/preview'
                    listType='picture-card'
                    fileList={previewBack}
                    customRequest={({ onSuccess }) => {
                      setTimeout(() => {
                        onSuccess('ok')
                      }, 500)
                    }}
                    onPreview={async (_file) => {
                      const file = _file
                      if (!file.url && !file.preview) {
                        file.preview = await getBase64(file.originFileObj)
                      }
                      setPreviewImage(file.url || file.preview)
                      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
                      setPreviewVisible(true)
                    }}
                    onChange={async ({ file }) => {
                      const _file = file
                      if (file.status === 'removed') {
                        await setPreviewBack([])
                        return updateProduct(product.provisionalId, {
                          previewFile: {
                            ...product.printingFile,
                            back: [],
                          },
                        })
                      }
                      _file.thumbUrl = await getBase64(file.originFileObj)
                      await setPreviewBack([_file])
                      return updateProduct(product.provisionalId, {
                        previewFile: {
                          ...product.previewFile,
                          back: previewBack,
                        },
                      })
                    }}
                  >
                    {previewBack.length === 1 ? null : uploadButton('back')}
                  </Upload>
                )}
              </div>
            </div>
            <Divider />
          </>
        )}
        <div>
          <div style={{   fontWeight: 'bold', marginBottom: '10px' }}>
            <FormattedMessage id='productAWSUploadForm.addImages' defaultMessage='Ajouter des images de présentation' />
          </div>
          <Alert
            message={(
              <FormattedMessage
                id='productAWSUploadForm.alertMessage.uploadOrder'
                defaultMessage="Attention à l'ordre d'upload, ce sera l'ordre d'affichage sur le shop"
              />
            )}
            type='warning'
            closable
            showIcon
            style={{ marginBottom: '10px' }}
          />
          <div style={{ display: 'flex' }}>
            <Upload
              name='picList'
              listType='picture-card'
              fileList={picList}
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess('ok')
                }, 1000)
              }}
              onPreview={async (_file) => {
                const file = _file
                if (!file.url && !file.preview) {
                  file.preview = await getBase64(file.originFileObj)
                }
                setPreviewImage(file.url || file.preview)
                setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
                setPreviewVisible(true)
              }}
              onChange={async ({ file }) => {
                const _file = file
                let _picList = picList
                if (file.status === 'removed') {
                  await setPicList(_picList.filter((pic) => pic.name !== file.name))
                  return updateProduct(product.provisionalId, {
                    picListToUpload: _picList.filter((pic) => pic.name !== file.name),
                    picList: _picList.filter((pic) => pic.name !== file.name).map((pic) => pic.name),
                  })
                }
                _file.thumbUrl = await getBase64(file.originFileObj)
                _picList.push(_file)
                _picList = uniqBy(_picList, 'name')
                await setPicList(_picList)
                return updateProduct(product.provisionalId, {
                  picListToUpload: _picList,
                  picList: _picList.map((pic) => pic.name),
                })
              }}
            >
              {uploadButton('')}
            </Upload>
          </div>
        </div>
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/ProductAWSUploadForm')
    return null
  }
}

export default ProductAWSUploadForm

import React, { useState } from 'react'
import {
  Table, Divider, Button, Typography, Switch, Icon,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { generateVariants, reducParams } from '../../../helpers/shopify'

const themeColors = require('../../../config/theme')

const {
  otherFontColor,
  validColor,
  warningColor,
} = themeColors

const { Title } = Typography

const Technics = (props) => {
  const { technics, selectedRowKeys, setSelectedRowKeys } = props
  const rowSelection = {
    selectedRowKeys,
    onChange: (selected) => {
      setSelectedRowKeys(selected)
    },
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'IdPanopli',
      dataIndex: 'idPanopli',
    },
    {
      title: 'Stage',
      dataIndex: 'parameters.stage',
      render: (record) => (
        <span>
          {' '}
          {record.length}
          {' '}
        </span>
      ),
    },
  ]
  return (
    <Table dataSource={technics} columns={columns} rowSelection={rowSelection} />
  )
}

const Step1 = (props) => {
  try {
    const {
      setRestrictColors, setRestrictTechnics, restrictColors, restrictTechnics,
    } = props
    return (
      <div>
        <Title level={3} style={{ marginTop: 15, marginBottom: 15 }}>
          <FormattedMessage id='shopifyActionModal.step1' defaultMessage='Etape 1 : Compacter les Familles de couleurs / Techniques' />
        </Title>
        <div style={{ display: 'flex' }}>
          {' '}
          <div style={{ width: 380, marginBottom: 15 }}>
            <FormattedMessage id='shopifyActionModal.helper1' defaultMessage='Compacter les familles de couleurs par impact de Prix' />
          </div>
          <Switch checked={restrictColors} onChange={(e) => setRestrictColors(e)} />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 380 }}>
            <FormattedMessage id='shopifyActionModal.helper2' defaultMessage='Compacter les derniers paliers de techniques' />
          </div>
          <Switch checked={restrictTechnics} onChange={(e) => setRestrictTechnics(e)} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('Step1 - error', e)
    return null
  }
}

const Step2 = (props) => {
  const {
    technics, selectedRowKeys, setSelectedRowKeys,
  } = props
  return (
    <div>
      <Title level={3} style={{ marginTop: 15, marginBottom: 15 }}>
        <FormattedMessage id='shopifyActionModal.step2' defaultMessage='Etape 2 : Retirer des techniques' />
      </Title>
      <div>
        <Technics technics={technics} setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys} />
      </div>
    </div>
  )
}

const MessageBlock = (props) => {
  const { nbOfVariants } = props
  if (nbOfVariants > 100) {
    return (
      <div>
        <p style={{ color: otherFontColor }}>
          <FormattedMessage
            id='shopifyActionModal.helper3'
            defaultMessage="Le produit que tu essayes d'envoyer sur Shopify à beaucoup trop de combinaisons de variations...
            Nous sommes malheuresement limité à 100.
            Je sais que ton produit est très stylé je vais  donc t'aider à compacter tout ca !"
          />
        </p>
      </div>
    )
  }
  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <FormattedMessage id='shopifyActionModal.helper4' defaultMessage='Bravo ! Le nombre de variants est passé à' />
      {' '}
      <span style={{ fontWeight: 'bold' }}>
        {' '}
        {nbOfVariants}
        {' '}
      </span>
      !
      <FormattedMessage
        id='shopifyActionModal.helper5'
        defaultMessage="C'est sous la barre des 100 ton produit est prêt à partir pour Shopify"
      />
    </div>
  )
}

const VariantsDisplay = (props) => {
  const { nbOfVariants } = props
  return (
    <div style={{ fontSize: 40 }}>
      <span>
        {' '}
        <span style={{ fontWeight: 'bold' }}>
          {nbOfVariants}
        </span>
        {' '}
        variants
        { nbOfVariants > 100
          ? <FormattedMessage id='shopifyActionModal.tooMuch' defaultMessage="... c'est beaucoup trop" />
          : <FormattedMessage id='shopifyActionModal.bravo' defaultMessage=' Bravo !' />}
      </span>
      { nbOfVariants > 100
        ? (<Icon style={{ marginLeft: 20, color: warningColor[6] }} type='warning' />)
        : (<Icon type='check-circle' style={{ marginLeft: 20, color: validColor[6] }} />)}
    </div>
  )
}

const ShopifyActionModal = (props) => {
  try {
    const { baseProduct, uploadToShopify, closeActionModal } = props
    const [restrictColors, setRestrictColors] = useState(false)
    const [restrictTechnics, setRestrictTechnics] = useState(false)
    const { technics, families } = reducParams(baseProduct, restrictColors, restrictTechnics)
    console.log('technics, families', { technics, families })
    const [selectedRowKeys, setSelectedRowKeys] = useState([...technics.map((t, i) => i)])
    const filteredTechnics = technics.map((t, index) => {
      if (!selectedRowKeys.includes(index)) {
        return false
      }
      return t
    }).filter(Boolean)
    const nbOfVariants = generateVariants({ ...baseProduct, technics: filteredTechnics, families }).length
    return (
      <div>
        <VariantsDisplay nbOfVariants={nbOfVariants} />
        <MessageBlock
          nbOfVariants={nbOfVariants}
        />
        <Divider />
        <Step1
          restrictColors={restrictColors}
          restrictTechnics={restrictTechnics}
          nbOfVariants={nbOfVariants}
          setRestrictColors={setRestrictColors}
          setRestrictTechnics={setRestrictTechnics}
        />
        <p style={{
          marginBottom: 15, marginTop: 15, fontWeight: 'bold', fontSize: 18,
        }}
        >
          <FormattedMessage id='shopifyActionModal.helper6' defaultMessage="Toujours trop de variants ? Pas de panique, on passe à l'étape 2" />
        </p>
        <Divider />
        <Step2
          technics={technics}
          families={families}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        <Button
          type='primary'
          disabled={nbOfVariants > 100}
          onClick={() => {
            uploadToShopify({ baseProduct: { ...baseProduct, technics: filteredTechnics, families } })
            closeActionModal()
          }}
        >
          <FormattedMessage id='shopifyActionModal.helper7' defaultMessage='Envoyer sur shopify' />
        </Button>
      </div>
    )
  } catch (e) {
    console.log('ShopifyActionModal - error', e)
    return null
  }
}

export default ShopifyActionModal

import React from 'react'
import {
  Page, Text, View, Document, Image, StyleSheet, Font,
} from '@react-pdf/renderer'
import { uniq } from 'lodash'

import MontserratRegular from '../../../config/Montserrat/static/Montserrat-Regular.ttf'
import MontserratBold from '../../../config/Montserrat/static/Montserrat-Bold.ttf'

import config from '../../../config'

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: MontserratRegular },
    { src: MontserratBold, fontWeight: 700 },
  ],
})

const { image_base_url: imageBaseUrl } = config

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontStyle: 'normal',
    flexDirection: 'column',
    backgroundColor: 'white',
    fontSize: '14px',
    padding: '52px',
  },
})

const darkGreen = 'rgba(0, 62, 46, 1)'
const lightGreen = 'rgba(0, 62, 46, 0.5)'
const iconeBackgroundGreen = 'rgba(230, 236, 235, 1)'

const darkBlue = 'rgba(1, 33, 105, 1)'
const lightBlue = 'rgba(1, 33, 105, 0.5)'

const background = 'rgba(250, 251, 251, 1)'

const dataDisplay = (color, title, value) => (
  <View style={{
    display: 'flex', flexDirection: 'row', marginBottom: '8px', fontSize: '12px', flex: '1 2',
  }}
  >
    <Text style={{ color: color === 'green' ? lightGreen : lightBlue, fontWeight: 700, marginRight: '14px' }}>
      {title}
    </Text>
    <Text style={{ color: color === 'green' ? darkGreen : darkBlue }}>{value || '-'}</Text>
  </View>
)

const ImageComponent = (props) => {
  const {
    url, key = '', style, alt,
  } = props

  return (
    <Image
      cache={false}
      style={style}
      key={key}
      source={{
        uri: url,
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' },
        body: '',
      }}
      alt={alt}
    />
  )
}

const ImagesSection = (props) => {
  try {
    const { baseProduct } = props
    const { images } = baseProduct
    const nbrImage = images.length

    if (nbrImage >= 5) {
      return (
        <View style={{
          display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between',
        }}
        >
          {images.map((image) => (
            <ImageComponent
              style={{ width: '255px', borderRadius: '16px', marginBottom: '16px' }}
              key={image.alt}
              url={image.src}
              alt={image.alt}
            />
          ))}
        </View>
      )
    }
    if (nbrImage === 4) {
      return (
        <View style={{
          display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between',
        }}
        >
          {images.map((image, index) => (
            <ImageComponent
              style={{ width: index === 0 ? '526px' : '164.6px', borderRadius: '16px', marginBottom: '16px' }}
              key={image.alt}
              url={image.src}
              alt={image.alt}
            />
          ))}
        </View>
      )
    }
    return (
      <View style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between',
      }}
      >
        {images.map((image, index) => (
          <ImageComponent
            style={{ width: index === 0 ? '526px' : '253px', borderRadius: '16px', marginBottom: '16px' }}
            key={image.alt}
            url={image.src}
            alt={image.alt}
          />
        ))}
      </View>
    )
  } catch (error) {
    console.log('ImagesSection', error)
    return null
  }
}

const TagsSection = (props) => {
  try {
    const { tags } = props
    const lowerCaseTags = tags?.length && tags.map((tag) => tag.toLowerCase().replaceAll('-', '').replaceAll(' ', ''))

    if (!lowerCaseTags?.length || (!lowerCaseTags.includes('ecoresponsable')
  && !lowerCaseTags.includes('surmesure')
  && !lowerCaseTags.includes('france')
  && !lowerCaseTags.includes('europe'))) return null

    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        {lowerCaseTags?.length && lowerCaseTags.includes('responsable')
      && (
        <ImageComponent
          url='/pastille/responsable.png'
          alt='responsable'
          style={{
            width: '40px', height: '40px', padding: '5px', marginLeft: '-5px',
          }}
        />
      )}
        {lowerCaseTags?.length && lowerCaseTags.includes('surmesure')
      && (
        <ImageComponent
          url='/pastille/surmesure.png'
          alt='surmesure'
          style={{
            width: '40px', height: '40px', padding: '5px', marginLeft: '-5px',
          }}
        />
      )}
        {lowerCaseTags?.length && lowerCaseTags.includes('france')
      && (
        <ImageComponent
          url='/pastille/france.png'
          alt='france'
          style={{
            width: '40px', height: '40px', padding: '5px', marginLeft: '-5px',
          }}
        />
      )}
        {lowerCaseTags?.length && lowerCaseTags.includes('europe')
      && (
        <ImageComponent
          url='/pastille/europe.png'
          alt='europe'
          style={{
            width: '40px', height: '40px', padding: '5px', marginLeft: '-5px',
          }}
        />
      )}
      </View>
    )
  } catch (error) {
    console.log('TagsSection', error)
    return null
  }
}

const DescriptionSection = (props) => {
  try {
    const { baseProduct } = props

    const {
      name, description, tags, idPanopli,
    } = baseProduct

    return (
      <View style={{ marginBottom: '16px', flex: '2' }}>
        <View style={{ padding: '16px', height: '100%' }}>
          <View style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <TagsSection tags={tags} />
            <Text style={{ fontSize: '14px', fontWeight: 400, color: darkBlue }}>
              {idPanopli}
            </Text>
          </View>
          <Text style={{
            fontSize: '16px', fontWeight: 700, color: darkBlue, margin: '8px 0px',
          }}
          >
            {name?.fr || ''}
          </Text>
          <Text style={{ fontSize: '12px', fontWeight: 400, color: darkBlue }}>
            {description?.fr || ''}
          </Text>
        </View>
      </View>
    )
  } catch (error) {
    console.log('DescriptionSection', error)
    return null
  }
}

const CarbonSection = (props) => {
  try {
    const { baseProduct } = props
    const {
      carbonImpact: {
        global = false,
        manufacturing = false,
        marking = false,
        transport = false,
        packaging = false,
      } = {},
      caracteristics: {
        originProduct, originMark, constitution,
      },
    } = baseProduct

    return (
      <View style={{ marginBottom: '16px', flex: '1' }}>
        <View style={{
          padding: '16px', borderRadius: '16px', backgroundColor: background, display: 'flex', flexDirection: 'column',
        }}
        >
          <View style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px',
          }}
          >
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <ImageComponent
                url='/pastille/carbon.png'
                alt='surmesure'
                style={{
                  width: '40px', height: '40px', marginRight: '16px', borderRadius: '8px',
                }}
              />
              <Text style={{ fontSize: '14px', fontWeight: 700, color: darkGreen }}>
                Empreinte carbone
              </Text>
            </View>
            <View style={{
              fontSize: '14px', fontWeight: 700, color: darkGreen, backgroundColor: iconeBackgroundGreen, borderRadius: '1000px', padding: '4px 8px',
            }}
            >
              {global ? (
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <ImageComponent
                    url='/pastille/leef.png'
                    alt='leef'
                    style={{
                      width: '20px', height: '20px', padding: '2px',
                    }}
                  />
                  <Text style={{ marginLeft: '8px' }}>
                    {`${global} kCO2eq`}
                  </Text>
                </View>
              ) : (
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <ImageComponent
                    url='/pastille/time.png'
                    alt='time'
                    style={{
                      width: '20px', height: '20px', padding: '2px',
                    }}
                  />
                  <Text style={{ marginLeft: '8px' }}>
                    Calcul en cours
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={{ display: 'flex' }}>
            <View style={{
              display: 'flex', flexDirection: 'row', width: '100%',
            }}
            >
              {dataDisplay('green', 'Fabrication', manufacturing)}
              {dataDisplay('green', 'Marquage', marking)}
            </View>
            <View style={{
              display: 'flex', flexDirection: 'row', width: '100%',
            }}
            >
              {dataDisplay('green', 'Packaging', packaging)}
              {dataDisplay('green', 'Transport', transport)}
            </View>
            <View style={{
              display: 'flex', flexDirection: 'row', width: '100%',
            }}
            >
              {dataDisplay('green', 'Origine', originProduct)}
              {dataDisplay('green', 'Origine du marquage', originMark)}
            </View>
            <View style={{
              display: 'flex', flexDirection: 'row', width: '80%', maxWidth: '80%',
            }}
            >
              {dataDisplay('green', 'Constitution', constitution)}
            </View>
          </View>
        </View>
      </View>
    )
  } catch (error) {
    console.log('CarbonSection', error)
    return null
  }
}

const CommentSection = () => (
  <View style={{ flex: '2' }}>
    <View style={{
      padding: '16px',
      borderRadius: '16px',
      backgroundColor: background,
      height: '100%',
    }}
    >
      <View style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
      >
        <ImageComponent
          url='/pastille/comment.png'
          alt='surmesure'
          style={{
            width: '40px', height: '40px', marginRight: '16px', borderRadius: '8px',
          }}
        />
        <Text style={{ fontSize: '14px', fontWeight: 700, color: darkBlue }}>
          Commentaires
        </Text>
      </View>
    </View>
  </View>
)

const CaracteristicSection = (props) => {
  try {
    const { baseProduct } = props
    const {
      caracteristics: {
        dimension, capacity, weight, delayDelivery,
      },
    } = baseProduct

    return (
      <View style={{ flex: '2', marginBottom: '16px' }}>
        <View style={{
          padding: '16px', borderRadius: '16px', backgroundColor: background, display: 'flex', flexDirection: 'column', height: '100%',
        }}
        >
          <View style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px',
          }}
          >
            <ImageComponent
              url='/pastille/caracteristic.png'
              alt='surmesure'
              style={{
                width: '40px', height: '40px', marginRight: '16px', borderRadius: '8px',
              }}
            />
            <Text style={{ fontSize: '14px', fontWeight: 700, color: darkBlue }}>
              Caractéristiques produit
            </Text>
          </View>
          <View>
            <View style={{
              display: 'flex', flexDirection: 'row', width: '100%',
            }}
            >
              {dataDisplay('default', 'Dimension', dimension)}
            </View>
            <View style={{
              display: 'flex', flexDirection: 'row', width: '100%',
            }}
            >
              {dataDisplay('default', 'Capacité', capacity)}
            </View>
            <View style={{
              display: 'flex', flexDirection: 'row', width: '100%',
            }}
            >
              {dataDisplay('default', 'Poids', weight)}
            </View>
            <View style={{
              display: 'flex', flexDirection: 'row', width: '100%',
            }}
            >
              {dataDisplay('default', 'Délais estimés', delayDelivery)}
            </View>
          </View>
        </View>
      </View>
    )
  } catch (error) {
    console.log('CaracteristicSection', error)
    return null
  }
}

const TechnicsSection = (props) => {
  try {
    const { technics, displayColors } = props

    if (!technics?.length) return null

    const families = uniq(technics.map((technic) => technic.family).filter(Boolean))

    return (
      <View style={{ flex: '2', marginBottom: displayColors ? '0px' : '16px' }}>
        <View style={{
          padding: '16px', borderRadius: '16px', backgroundColor: background, display: 'flex', flexDirection: 'column', height: '100%',
        }}
        >
          <View style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px',
          }}
          >
            <ImageComponent url='/pastille/personnalisation.png' alt='surmesure' style={{ width: '40px', height: '40px', marginRight: '16px' }} />
            <Text style={{ fontSize: '14px', fontWeight: 700, color: darkBlue }}>
              Personnalisation
            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
            {families.length ? families.map((family) => (
              <Text
                key={family}
                style={{
                  marginBottom: '4px', fontSize: '12px', color: darkBlue, width: '50%',
                }}
              >
                {family}
              </Text>
            )) : technics.map((technic) => (
              <Text
                key={technic.name}
                style={{
                  marginBottom: '4px', fontSize: '12px', color: darkBlue, width: '50%',
                }}
              >
                {technic.name}
              </Text>
            ))}
          </View>
        </View>
      </View>
    )
  } catch (error) {
    console.log('TechnicsSection', error)
    return null
  }
}

const Color = (props) => {
  try {
    const { color } = props

    const url = String(`${imageBaseUrl}/Pastille/low/${color.name}.png`)

    return (
      <View
        key={color.name}
        style={{
          marginBottom: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center',
        }}
      >
        <View>
          <ImageComponent
            style={{
              width: '12px', height: '12px', marginRight: '8px', padding: '2px',
            }}
            url={url}
            onError="this.style.display = 'none'"
            alt={`pastille: ${color.name}`}
          />
        </View>
        <Text style={{ fontSize: '12px', color: darkBlue }}>
          {color.name}
        </Text>
      </View>
    )
  } catch (error) {
    console.log('Color', error)
    return null
  }
}

const ColorsSection = (props) => {
  try {
    const { colors, displayColors } = props

    if (displayColors) return null

    return (
      <View style={{ flex: '4' }}>
        <View style={{
          padding: '16px', borderRadius: '16px', backgroundColor: background, display: 'flex', flexDirection: 'column', height: '100%',
        }}
        >
          <View style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px',
          }}
          >
            <ImageComponent url='/pastille/color.png' alt='surmesure' style={{ width: '40px', height: '40px', marginRight: '16px' }} />
            <Text style={{ fontSize: '14px', fontWeight: 700, color: darkBlue }}>
              Couleurs
            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
            {colors.map((color) => (
              <Color color={color} />
            ))}
          </View>
        </View>
      </View>
    )
  } catch (error) {
    console.log('ColorsSection', error)
    return null
  }
}

const PagePDF = (props) => {
  try {
    const { baseProduct } = props

    const {
      variations,
      technics,
      dematerialized = false,
    } = baseProduct

    const colors = variations.filter((variation) => variation.type === 'color').map((color) => ({ ...color, nameUrl: encodeURI(color.name) }))

    const displayColors = (!colors.length || colors.length === 1)

    if (dematerialized) {
      return (
        <Page size={['900', '1600']} key={baseProduct._id} orientation='landscape' style={styles.page}>
          <View style={{
            display: 'flex', height: '796px', width: '1496px', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <View style={{
              display: 'flex', flexDirection: 'row', width: '1496px', height: '796px',
            }}
            >
              <View style={{ width: '526px', height: '796px' }}>
                <ImagesSection baseProduct={baseProduct} />
              </View>
              <View style={{
                width: '954px', display: 'flex', flexDirection: 'column', height: '796px', margin: '0 16px',
              }}
              >
                <DescriptionSection baseProduct={baseProduct} />
                <CarbonSection baseProduct={baseProduct} />
                <CommentSection baseProduct={baseProduct} />
              </View>
            </View>
          </View>
        </Page>
      )
    }
    return (
      <Page size={['900', '1600']} key={baseProduct._id} orientation='landscape' style={styles.page}>
        <View style={{
          display: 'flex', height: '796px', width: '1496px', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <View style={{
            display: 'flex', flexDirection: 'row', width: '1496px', height: '796px',
          }}
          >
            <View style={{ width: '526px', height: '796px' }}>
              <ImagesSection baseProduct={baseProduct} />
            </View>
            <View style={{
              width: '580px', display: 'flex', flexDirection: 'column', height: '796px', margin: '0 16px',
            }}
            >
              <DescriptionSection baseProduct={baseProduct} />
              <CarbonSection baseProduct={baseProduct} />
              <CommentSection baseProduct={baseProduct} />
            </View>
            <View style={{
              width: '358px', display: 'flex', flexDirection: 'column', height: '796px',
            }}
            >
              <CaracteristicSection baseProduct={baseProduct} />
              <TechnicsSection technics={technics} displayColors={displayColors} />
              <ColorsSection colors={colors} displayColors={displayColors} />
            </View>
          </View>
        </View>
      </Page>
    )
  } catch (error) {
    console.log('PagePDF', error)
    return null
  }
}

const CatalogSheet = (props) => {
  try {
    const { baseProducts } = props

    return (
      <Document>
        {baseProducts.map((baseProduct) => (
          <PagePDF baseProduct={baseProduct} />
        ))}
      </Document>
    )
  } catch (error) {
    console.log('error CatalogSheet', error)
    return null
  }
}

export default CatalogSheet

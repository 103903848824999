import React from 'react'

import { Divider } from 'antd'

import {
  ProductInformationsEditor, RestrictedManagement, ProductImagesManager,
} from '../../molecules'

const ProductEditionTab = (props) => {
  try {
    const {
      product,
      allCollections,
      allEmbededBPs,
      membersGroups,
      members,
      modifyProduct,
      allowedToModify,
    } = props

    const { _id } = product

    const onChange = (modifications) => modifyProduct(_id, modifications)

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ProductInformationsEditor
          product={product}
          allCollections={allCollections}
          allEmbededBPs={allEmbededBPs}
          onChange={onChange}
          allowedToModify={allowedToModify}
        />
        <Divider />
        <ProductImagesManager
          product={product}
          modifyProduct={modifyProduct}
          size={200}
          allowedToModify={allowedToModify}
        />
        {allowedToModify && (
          <>
            <Divider />
            <RestrictedManagement
              product={product}
              membersGroups={membersGroups}
              members={members}
              onChange={onChange}
            />
          </>
        )}
      </div>
    )
  } catch (e) {
    console.log('organisms/ProductEditionTab error:', e)
    return null
  }
}

export default ProductEditionTab

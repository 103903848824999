import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Form,
  Input,
  Row,
  Button,
} from 'antd'

class AccountForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
      },
      onSubmit,
    } = this.props
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          firstName,
          lastName,
          phone,
          position,
          email,
        } = values
        const result = {
          phone,
          email,
          additionalInfos: {
            firstName,
            lastName,
            position,
          },
        }
        onSubmit(result)
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        isFieldsTouched,
        resetFields,
      },
      userInfos,
    } = this.props

    return (
      <Form
        id='AccountForm'
        onSubmit={this.handleSubmit}
      >
        <Form.Item label={<FormattedMessage id='email' defaultMessage='Email' />}>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: <FormattedMessage id='email.notValid' defaultMessage="L'Email n'est pas valide" />,
              },
              {
                required: true,
                message: <FormattedMessage id='required.email' defaultMessage='Veuillez renseigner un Email' />,
              },
            ],
            initialValue: userInfos.email,
          })(<Input style={{ width: 300 }} />)}
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id='firstName' defaultMessage='Prénom' />}
        >
          {getFieldDecorator('firstName', {
            rules: [{
              required: true,
              message: <FormattedMessage id='required.firstName' defaultMessage='Veuillez renseigner un prénom' />,
              whitespace: true,
            }],
            initialValue: userInfos.firstName,
          })(<Input style={{ width: 300 }} />)}
        </Form.Item>
        <Form.Item
          label={(
            <span>
              <FormattedMessage id='lastName' defaultMessage='Nom' />
            </span>
          )}
        >
          {getFieldDecorator('lastName', {
            rules: [{ whitespace: true }],
            initialValue: userInfos.lastName,
          })(<Input style={{ width: 300 }} />)}
        </Form.Item>
        <Form.Item
          label={(
            <span>
              <FormattedMessage id='job' defaultMessage='Poste' />
            </span>
          )}
        >
          {getFieldDecorator('position', {
            initialValue: userInfos.position,
          })(<Input style={{ width: 300 }} />)}
        </Form.Item>
        <Form.Item
          label={(
            <span>
              <FormattedMessage id='phone' defaultMessage='Téléphone' />
            </span>
          )}
        >
          {getFieldDecorator('phone', {
            rules: [{
              required: true,
              message: <FormattedMessage id='required.telephone' defaultMessage='Veuillez renseigner un numéro de téléphone' />,
            }],
            initialValue: userInfos.phone,
          })(<Input style={{ width: 300 }} />)}
        </Form.Item>
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{ marginRight: 20 }}
              >
                <FormattedMessage id='modify' defaultMessage='Modifier' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    )
  }
}

export default Form.create({ name: 'AccountForm' })(AccountForm)

export const GET_BASEPRODUCTS_CART = 'GET_BASEPRODUCTS_CART'
export const ADD_BASEPRODUCTS_CART = 'ADD_BASEPRODUCTS_CART'
export const DELETE_BASEPRODUCTS_CART = 'DELETE_BASEPRODUCTS_CART'
export const SET_BASEPRODUCTS_CART_VISIBLE = 'SET_BASEPRODUCTS_CART_VISIBLE'
export const SEND_TO_HUBSPOT = 'SEND_TO_HUBSPOT'
export const EMPTY_CART = 'EMPTY_CART'

export const emptyCart = () => ({
  type: EMPTY_CART,
})

export const setVisibleState = (isVisible) => ({
  type: SET_BASEPRODUCTS_CART_VISIBLE,
  payload: {
    isVisible,
  },
})

export const addToBPCart = (item) => ({
  type: ADD_BASEPRODUCTS_CART,
  payload: {
    item,
  },
})

export const deleteBpFromCart = (productId) => ({
  type: DELETE_BASEPRODUCTS_CART,
  payload: {
    cartId: productId,
  },
})

export const sendToHubspot = (dealId, deliveryTotal, onSuccess, onError) => ({
  type: SEND_TO_HUBSPOT,
  payload: {
    dealId,
    deliveryTotal,
  },
  onSuccess,
  onError,
})

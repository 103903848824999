import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Card,
  Button,
  notification,
  List,
  Modal,
  Row,
} from 'antd'
import { uniqBy } from 'lodash'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const ButtonGroup = Button.Group

const BpOnDemand = (props) => {
  const {
    products,
    baseProduct,
    pushProducts,
    shopId,
    allShops,
    onClose,
    setCurrentProduct,
  } = props
  const {
    supplierBaseProductReference,
  } = baseProduct

  const openNotification = (newProduct) => {
    const key = '1234'
    const _products = [...products]
    _products.push(newProduct)
    const cart = uniqBy(_products.filter((product) => !product.newBaseProduct), 'supplierBaseProductId')
    const recap = (
      cart.map((productCart) => (
        <div>
          x
          {_products.filter((product) => productCart.supplierBaseProductId === product.supplierBaseProductId).length}
          {' '}
          {productCart.baseProduct.baseProductInfo.detail.fr}
          <br />
        </div>
      ))
    )
    const btn = (
      <div>
        {cart.length && cart.length > 0 ? (
          <div>
            <FormattedMessage id='recap' defaultMessage='Récapitulatif :' />
            <br />
            {recap}
          </div>
        ) : null}
        <Row>
          <p>
            <FormattedMessage id='bpOnDemand.helper1' defaultMessage='Voulez-vous continuer à ajouter des produits ?' />
          </p>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size='small'
            onClick={() => notification.close(key)}
          >
            <FormattedMessage id='yes' defaultMessage='Oui' />
          </Button>
          <Button
            type='primary'
            size='small'
            style={{ marginLeft: '8px' }}
            onClick={() => {
              notification.close(key)
              onClose()
              setCurrentProduct(cart[0])
            }}
          >
            <FormattedMessage id='noStartCustomizing' defaultMessage='Non, commencer à les personnaliser' />
          </Button>
        </div>
      </div>
    )
    notification.open({
      message: <FormattedMessage
        id='bpOnDemand.productAdded'
        defaultMessage='Article "{name}" ajouté ! 🚀`'
        values={{ name: baseProduct.subCategory.fr }}
      />,
      description: (baseProduct.baseProductInfo && baseProduct.baseProductInfo.detail && baseProduct.baseProductInfo.detail.fr) || '',
      placement: 'bottomRight',
      duration: 8,
      btn,
      key,
      onClose: notification.close(),
    })
  }

  return (
    <List.Item
      key={supplierBaseProductReference}
      style={{
        cursor: 'auto',
      }}
    >
      <Card
        title={supplierBaseProductReference}
        bordered
        cover={(
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              alt='example'
              src={`${imageBaseUrl}/SVGS/${baseProduct._id}/baseProduct/display.png`}
              style={{ width: '100%' }}
              onError={(e) => {
                e.target.src = baseProduct.shopifyImages && baseProduct.shopifyImages.length ? baseProduct.shopifyImages[0].src
                  : 'https://developers.google.com/maps/documentation/streetview/images/error-image-generic.png'
              }}
            />
          </div>
        )}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Button
              type='primary'
              onClick={async () => {
                const newProduct = {
                  baseProduct,
                  provisionalId: Math.random().toString(8),
                  supplierBaseProductId: baseProduct._id,
                  name: (baseProduct.name && baseProduct.name.fr) || baseProduct.subCategory.fr,
                  owner: allShops.find((shop) => shop._id === shopId).owner,
                  shops: [shopId],
                  displayDescription: {
                    // eslint-disable-next-line no-nested-ternary
                    fr: (baseProduct.description && baseProduct.description.fr) ? baseProduct.description.fr : baseProduct.baseProductInfo
                    && baseProduct.baseProductInfo.detail && baseProduct.baseProductInfo.detail.fr ? baseProduct.baseProductInfo.detail.fr : '',
                    // eslint-disable-next-line no-nested-ternary
                    en: (baseProduct.description && baseProduct.description.en) ? baseProduct.description.en : baseProduct.baseProductInfo
                    && baseProduct.baseProductInfo.detail && baseProduct.baseProductInfo.detail.en ? baseProduct.baseProductInfo.detail.en : '',
                  },
                  pricing: {
                    margin: 0,
                    customizationPrice: 0,
                  },
                  design_ids: [],
                  printingFile: null,
                  previewFile: null,
                  unitPrice: baseProduct.unitPrice || 0,
                  stocked: false,
                }
                await pushProducts([newProduct])
                openNotification(newProduct)
              }}
            >
              <FormattedMessage id='add' defaultMessage='Ajouter' />
            </Button>
            <Button
              icon='info-circle'
              onClick={() => {
                Modal.info({
                  title: baseProduct.baseProductInfo.detail.fr,
                  width: 700,
                  content: (
                    <div>
                      <div style={{ marginBottom: '10px' }}>{baseProduct.supplierBaseProductReference}</div>
                      {baseProduct.baseProductInfo.caracteristics.fr.map((carac) => (
                        <div style={{ width: '100%', marginBottom: '10px' }}>
                          {carac.content}
                        </div>
                      ))}
                      <div style={{
                        display: 'flex', width: '100%', justifyContent: 'flex-start', marginTop: '10px',
                      }}
                      >
                        <img
                          src={`${imageBaseUrl}/SVGS/${String(baseProduct._id)}/baseProduct/1.png`}
                          alt='baseProduct-1'
                          style={{
                            width: '200px', height: '200px', border: '1px solid black', borderRadius: '4px', marginRight: '10px',
                          }}
                        />
                        <img
                          src={`${imageBaseUrl}/SVGS/${String(baseProduct._id)}/baseProduct/2.png`}
                          alt='baseProduct-1'
                          style={{
                            width: '200px', height: '200px', border: '1px solid black', borderRadius: '4px',
                          }}
                        />
                      </div>
                    </div>
                  ),
                  onOk() {},
                })
              }}
            />
          </ButtonGroup>
        </div>
      </Card>
    </List.Item>
  )
}

export default BpOnDemand

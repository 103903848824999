import { Select } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { StepInputLabel } from '../..'

const CustomizableSelect = (props) => {
  const {
    children,
    showSearch = false,
    value,
    placeholder = '',
    defaultActiveFirstOption = true,
    filterOption = false,
    onSearch,
    onChange,
    notFoundContent = null,
    style = { width: '100%' },
    formattedMessageId, // Required
    formattedDefaultMessage, // Required
    formattedMessageTooltipId,
    formattedDefaultMessageTooltip,
    icon,
    titleStyle,
    titleBlockStyle,
    isDisabled = false,
  } = props

  return (
    <>
      <div style={{ ...(titleBlockStyle && { ...titleBlockStyle }) }}>
        <StepInputLabel
          title={<FormattedMessage id={formattedMessageId} defaultMessage={formattedDefaultMessage} />}
          titleStyle={titleStyle}
          tooltipText={<FormattedMessage id={formattedMessageTooltipId} defaultMessage={formattedDefaultMessageTooltip} />}
          icon={icon}
        />
      </div>

      <Select
        showSearch={showSearch}
        value={value}
        placeholder={placeholder}
        defaultActiveFirstOption={defaultActiveFirstOption}
        filterOption={filterOption}
        onSearch={onSearch}
        onChange={onChange}
        notFoundContent={notFoundContent}
        style={style}
        disabled={isDisabled}
      >
        {children}
      </Select>
    </>
  )
}

export default CustomizableSelect

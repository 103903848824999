import React, { useState } from 'react'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { Input } from 'antd'

import {
  MagicLinksInputTitle,
  MagicLinksSendingStepsButtons,
} from '../..'

import { successNotify } from '../../../helpers/notification'
import { character } from '../../../config/theme'
import { STEP_DRAWER_MAILS } from '../../../helpers/magicLinks'

const { TextArea } = Input

const MagicLinksSendingMessage = (props) => {
  try {
    const {
      currentCampaign,
      selection,
      setSelection,
      setStep,
      mails,
      setMails,
    } = props

    const { message: { title, content } } = currentCampaign

    const intl = useIntl()

    // S'il n'y a qu'un message sélectionné et qu'il a déjà été modifié, on reprend ses infos à la réouverture
    const initialTitle = (selection.length === 1 && mails.find((mail) => selection.includes(mail.email)).message?.title) || title
    const initialContent = (selection.length === 1 && mails.find((mail) => selection.includes(mail.email)).message?.content) || content

    const [titleInput, setTitleInput] = useState(initialTitle)
    const [contentInput, setContentInput] = useState(initialContent)

    const handleStepChange = () => {
      setTitleInput()
      setContentInput()
      setSelection([])
      setStep(STEP_DRAWER_MAILS)
    }

    const setNewMails = () => {
      const newMails = mails.map((mail) => {
        if (selection.includes(mail.email)) {
          return {
            ...mail,
            message: {
              title: titleInput,
              content: contentInput,
            },
          }
        }
        return mail
      })
      setMails(newMails)
    }

    const displaySuccess = () => {
      if (selection.length === 1) {
        successNotify({
          intl,
          messageId: 'message.modify.success',
          defaultMessage: 'Le message a bien été modifié',
        })
      } else {
        successNotify({
          intl,
          messageId: 'messages.modify.success',
          defaultMessage: 'Les messages ont bien été modifiés',
        })
      }
    }

    const handleNextStep = async () => {
      await setNewMails()
      await displaySuccess()
      handleStepChange()
    }

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
          <MagicLinksInputTitle title={(<FormattedMessage id='title' defaultMessage='Titre' />)}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                value={titleInput}
                onChange={(e) => setTitleInput(e.target.value)}
                maxLength={100}
              />
              <span style={{ alignSelf: 'flex-end', color: character.disabledPlaceholder }}>
                { `${titleInput?.length}/${100}` }
              </span>
            </div>
          </MagicLinksInputTitle>
          <MagicLinksInputTitle title={(<FormattedMessage id='message' defaultMessage='Message' />)}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextArea
                value={contentInput}
                onChange={(e) => setContentInput(e.target.value)}
                rows={5}
                maxLength={400}
              />
              <span style={{ alignSelf: 'flex-end', color: character.disabledPlaceholder }}>
                { `${contentInput?.length}/${400}` }
              </span>
            </div>
          </MagicLinksInputTitle>
        </div>
        <MagicLinksSendingStepsButtons
          previousTitle={<FormattedMessage id='cancel' defaultMessage='Annuler' />}
          nextTitle={<FormattedMessage id='modify.message' defaultMessage='Modifier le message' />}
          handlePreviousStep={handleStepChange}
          handleNextStep={handleNextStep}
        />
      </>
    )
  } catch (e) {
    console.log('molecules/MagicLinksSendingMessage error:', e)
    return null
  }
}

export default injectIntl(MagicLinksSendingMessage)

// https://github.com/diegohaz/arc/wiki/Selectors
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
export const initialState = {
  loading: true,
  location: '',
  error: false,
  isPreview: false,
  noMargin: false,
  being_modified: false,
  modifications: {},
  currentShop: {},
  allShops: [],
}

export const shouldRefetchShop = (state = initialState) => {
  try {
    return state.shouldRefetchShop
  } catch (e) {
    console.log('selectors - shouldRefetchShop', e)
    return false
  }
}
export const getShop = (state = initialState) => {
  try {
    return state.currentShop
  } catch (e) {
    console.log('selectors - getShop', e)
    return initialState.data
  }
}
export const shopCollections = (state = initialState) => {
  try {
    console.log('state', state)
    return state.currentShop.settings.collections.map((collec) => collec.name)
  } catch (error) {
    console.log('selectors - shopCollections', error)
    return []
  }
}

export const allShops = (state = initialState) => state.allShops || []

export const getShopSettings = (state = initialState) => getShop(state).settings

export const canActivate = (state = initialState) => {
  try {
    const {
      facturation,
    } = getShopSettings(state)
    const {
      status,
    } = getShop(state)
    return status !== 'Locked' && facturation
    && (
      facturation.type === 'retroInvoicing'
      || (facturation.type === 'standard' && facturation.stripeSettings)
    )
  } catch (error) {
    console.log('canActivate', { error })
    return false
  }
}

export const getShopCampaign = (state = initialState) => {
  try {
    return getShop(state).campaign
  } catch (e) {
    console.log('selectors - getShopCampaign', e)
    return null
  }
}
export const getId = (state = initialState) => {
  try {
    return getShop(state)._id
  } catch (e) {
    console.log('selectors - getId', e)
    return null
  }
}

export const getOwner = (state = initialState) => {
  try {
    return getShop(state).owner
  } catch (e) {
    console.log('selectors - getOwner', e)
    return null
  }
}

export const getSettings = (state = initialState, value, defaultValue) => {
  try {
    return getShopSettings(state)[value]
  } catch (e) {
    console.log('selectors - getSettings', e)
    return defaultValue
  }
}

export const getMessageBanner = (state = initialState, language = 'fr') => {
  try {
    return state.data.settings.messageBanner ? state.data.settings.messageBanner[language === 'fr' ? 'text' : language] : ''
  } catch (e) {
    console.log('selectors - getMessageBanner', e)
    return ''
  }
}

export const shopPreview = (state = initialState) => {
  try {
    return state.isPreview
  } catch (error) {
    console.log('shopPreview', { error })
    return false
  }
}

export const shopNoMargin = (state = initialState) => {
  try {
    return state.noMargin
  } catch (error) {
    console.log('shopNoMargin', { error })
    return false
  }
}

export const shopMembersGroups = (state = initialState) => {
  try {
    return state.currentShop.settings.membersGroups || []
  } catch (error) {
    console.log('shopMembersGroups', { error })
    return []
  }
}

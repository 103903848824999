import { isNumber } from 'lodash'

import { actionUrlIsValid } from '../actions'

const isCampaignMessageValid = (campaign) => (
  (campaign?.message?.isEnabled
    && campaign?.message?.title?.length > 0
    && campaign?.message?.content?.length > 0)
  || (!campaign?.message?.isEnabled
    && campaign?.message?.title?.length <= 0
    && campaign?.message?.content?.length <= 0))

const hasCampaignEnoughProducts = (campaign) => {
  const { products, surprise, donation = { active: false } } = campaign
  const { active, amount } = donation
  return products?.length > 0 || (active && isNumber(amount) && (amount > 0) && !surprise)
}

const isCampaignActionsValid = (campaign) => {
  const { actions = null } = campaign
  if (actions && Array.isArray(actions)) {
    return actions.every((action) => actionUrlIsValid(action?.url, action?.type, action?.origin))
  }
  return true
}

export { isCampaignActionsValid, isCampaignMessageValid, hasCampaignEnoughProducts }

const actionUrlIsValid = (actionUrl, actionType, actionOrigin) => {
  const regexes = {
    CALENDAR: {
      HUBSPOT: new RegExp('^https://meetings-eu1\\.hubspot\\.com/..*'),
      // match urls like https://calendly.com/username/meeting
      CALENDLY: new RegExp('^https://calendly\\.com/..*/..*$'),
    },
  }
  return !!(regexes[actionType]?.[actionOrigin]?.test(actionUrl))
}

// eslint-disable-next-line import/prefer-default-export
export { actionUrlIsValid }

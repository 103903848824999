import React, { useState } from 'react'
import { Modal, Button, Icon } from 'antd'
import { FormattedMessage } from 'react-intl'

import { MagicLinksCreationForm } from '..'
import { EmailPreview, BudgetTags } from '../..'
import theme from '../../../config/theme'

const initialState = {
  visible: false,
  visiblePreview: false,
  newMagicLinks: [],
  lifetime: undefined,
  campaignId: undefined,
  confirmLoading: false,
  emailsByCSV: false,
  isUnvalidInput: false,
  wrongFormat: [],
  alreadySent: [],
  inputEmail: undefined,
  inputFirstName: undefined,
  sentAt: new Date(),
}

const MagicLinksCreation = (props) => {
  try {
    const [creationState, setCreationState] = useState(initialState)

    const {
      campaigns, magicLinks, createMagicLinks, shop, campaignSelected, budget,
    } = props

    const {
      visible,
      visiblePreview,
      newMagicLinks,
      lifetime,
      // On veut un "undefined" le placeholder dans le cas où plus d'une campagne, et l'id si une seule campagne
      campaignId = campaigns.length === 1 ? campaigns.find(Boolean)._id : undefined,
      confirmLoading,
      sentAt,
    } = creationState

    const campaign = campaigns.find((_campaign) => _campaign._id === campaignId) || campaignSelected

    const showModal = () => setCreationState({
      ...creationState,
      visible: true,
      campaignId: campaigns?.length === 1 ? campaigns.find(Boolean)._id : undefined,
    })

    /**
     * Ferme la modale en réinitialisant le state du composant
     */
    const handleClose = () => {
      setCreationState({
        ...initialState,
        newMagicLinks: [],
        wrongFormat: [],
        alreadySent: [],
      })
    }

    /**
     * Crée les magicLinks en DB (envoie les emails) et ferme la modale
     */
    const handleOk = async () => {
      setCreationState({ ...creationState, confirmLoading: true })
      await createMagicLinks({
        campaignId, newMagicLinks, lifetime, sentAt,
      })
      handleClose()
    }

    return (
      <div style={{
        display: 'flex',
      }}
      >
        <Button
          style={{
            backgroundColor: theme.primaryColor[6],
            color: 'white',
            margin: '0 16px',
            fontWeight: 'bold',
          }}
          onClick={showModal}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Icon type='usergroup-add' style={{ paddingRight: 4 }} />
            <FormattedMessage
              id='campaignTrackingModal.createMagicLink'
              defaultMessage='Créer un nouvel envoi'
            />
          </div>
        </Button>
        <Modal
          destroyOnClose
          title={(
            <h3
              style={{
                fontWeight: 'bold',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <FormattedMessage
                id='campaignTrackingModal.title'
                defaultMessage='Envoyer des Magic Links'
              />
            </h3>
          )}
          visible={visible}
          confirmLoading={confirmLoading}
          afterClose={handleClose}
          onCancel={handleClose}
          onOk={handleOk}
          okButtonProps={{ disabled: !newMagicLinks.length || !campaignId }}
          className='modalMagicLinkCreation'
          okText={(
            <FormattedMessage
              id='campaignTrackingModal.validate'
              defaultMessage="Valider l'envoi"
            />
          )}
        >
          <>
            <div style={{
              display: budget ? 'flex' : 'none', justifyContent: 'center', backgroundColor: 'rgba(245, 212, 180, 0.4)', padding: '5px',
            }}
            >
              <BudgetTags budget={budget} />
            </div>
            <MagicLinksCreationForm
              magicLinks={magicLinks}
              campaigns={campaigns}
              campaign={campaign}
              creationState={creationState}
              setCreationState={setCreationState}
              budget={budget}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
              <Button disabled={!campaignId} type='link' onClick={() => setCreationState({ ...creationState, visiblePreview: true })}>
                <FormattedMessage
                  id='campaigns.modal.step.template.preview'
                  defaultMessage='Prévisualiser l’email'
                />
              </Button>
            </div>
          </>
        </Modal>
        <Modal
          visible={visiblePreview}
          width='50%'
          onOk={() => setCreationState({ ...creationState, visiblePreview: false })}
          onCancel={() => setCreationState({ ...creationState, visiblePreview: false })}
          destroyOnClose
          footer={null}
        >
          <EmailPreview
            subject={campaign?.template?.title}
            body={campaign?.template?.body}
            cta={campaign?.template?.cta}
            shop={shop}
          />
        </Modal>
      </div>
    )
  } catch (e) {
    console.log('molecules/MagicLinksCreation error:', e)
    return null
  }
}

export default MagicLinksCreation

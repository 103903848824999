import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { getProductMainImage } from '../../../helpers/magicLinks'
import { character } from '../../../config/theme'

const ProductImage = (props) => {
  const {
    product, // Required
  } = props

  const [isProductImageBroken, setIsProductImageBroken] = useState(false)

  const handleOnImgError = (event) => {
    setIsProductImageBroken(true)
    // eslint-disable-next-line no-param-reassign
    event.target.style.display = 'none'
  }

  return (
    <div className={isProductImageBroken ? null : 'square-container'} style={{ height: '100%' }}>
      {isProductImageBroken ? (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          aspectRatio: '1/1',
          gap: '8px',
        }}
        >
          <FontAwesomeIcon
            icon={faImage}
            style={{ height: '30%', width: '30%' }}
          />
          <div style={{ height: '40px', padding: '0 16px' }}>
            <span
              className='twoLinesEllipsis'
              style={{
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '20px',
                color: character.disabledPlaceholder,
                textAlign: 'center',
              }}
            >
              {product.name}
            </span>
          </div>
        </div>
      ) : (
        <img
          src={getProductMainImage(product)?.src}
          alt={product.name}
          style={{ objectFit: 'cover', borderRadius: 12 }}
          onError={handleOnImgError}
        />
      )}
    </div>
  )
}

export default ProductImage

// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
export const GET = 'GET_PRODUCTS'
export const GET_MORE = 'GET_MORE_PRODUCTS'
export const FETCH_FORCE = 'FETCH_FORCE_PRODUCTS'
export const RECEIVED_MORE = 'RECEIVED_MORE_PRODUCTS'
export const RECEIVED = 'RECEIVED_PRODUCTS'
export const ERROR = 'ERROR_PRODUCTS'
export const ASSIGN = 'ASSIGN'
export const MODIFY = 'MODIFY_PRODUCT'
export const MODIFY_SEVERAL = 'MODIFY_PRODUCTS'
export const MODIFIED = 'MODIFIED_PRODUCT'
export const MODIFICATION_ERROR = 'ERRROR_MODIFICATION_PRODUCT'
export const PRODUCT_DRAWER = 'PRODUCT_DRAWER'
export const PREORDER_DRAWER = 'PREORDER_DRAWER'
export const CREATE = 'CREATE'
export const CREATED = 'CREATED'
export const CREATE_ERROR = 'CREATE_ERROR'
export const SAVE_DRAFT = 'SAVE_DRAFT'
export const INIT_FETCH_DATA = 'INIT_FETCH_DATA_PRODUCTS'
export const END_FETCH_DATA = 'END_FETCH_DATA_PRODUCTS'
export const LOADING = 'LOADING_PRODUCTS'

export function setLoading(loading) {
  return {
    type: LOADING,
    payload: {
      loading,
    },
  }
}

export function manageDrawer(value) {
  return {
    type: PRODUCT_DRAWER,
    payload: { open: value },
  }
}

export function managePreOrderDrawer(value) {
  return {
    type: PREORDER_DRAWER,
    payload: {
      open: value,
    },
  }
}

import React from 'react'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownLong, faDownload } from '@fortawesome/free-solid-svg-icons'
import { Button, Icon } from 'antd'
import { CSVLink } from 'react-csv'
import { CSVReader } from 'react-papaparse'
import { uniq } from 'lodash'

import { character, primaryColor } from '../../../config/theme'
import { trimInput } from '../../../helpers/trim'
import {
  hasValidEmailFormat,
  isUsableEmailAddress,
  hasCorrectCSVHeaders,
  STEP_DRAWER_MANUALLY,
} from '../../../helpers/magicLinks'
import { successNotify } from '../../../helpers/notification'

const csvHeaders = ['Email', 'FirstName']
const data = [csvHeaders, ['']]

const buttonStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
  fontWeight: 500,
  fontSize: '14px',
  height: '40px',
}

const MagicLinksSendingByCSV = (props) => {
  try {
    const {
      mails,
      setMails,
      setUnvalidCSVEmails,
      setSubpart,
      magicLinks,
      currentCampaignId,
      setWrongCSVHeaders,
    } = props

    const intl = useIntl()

    const getValidMails = (rows) => uniq(rows.map((row) => {
      const { data: { Email: email, FirstName: firstName } } = row
      const trimmedEmail = trimInput(email)
      if (isUsableEmailAddress(trimmedEmail, mails, magicLinks, currentCampaignId)) return { email: trimmedEmail, firstName }
      return false
    }).filter(Boolean))

    const getUnvalidMails = (rows) => uniq(rows.map((row) => {
      const { data: { Email: email } } = row
      const trimmedEmail = trimInput(email)
      if (trimmedEmail.length && !hasValidEmailFormat(trimmedEmail)) return { email: trimmedEmail }
      return false
    }).filter(Boolean))

    const checkMails = (rows) => {
      if (!hasCorrectCSVHeaders(csvHeaders, rows.find(Boolean))) {
        setUnvalidCSVEmails([]) // On vide la liste des mails erronés insérés via un précédent fichier CSV
        setWrongCSVHeaders(true)
      } else {
        setWrongCSVHeaders(false)
        const validEmails = getValidMails(rows)
        const unValidEmails = getUnvalidMails(rows)
        if (unValidEmails.length) setUnvalidCSVEmails([...unValidEmails])
        if (validEmails.length) {
          setMails([...mails, ...validEmails])
          successNotify({
            intl,
            messageId: 'contacts.add.success',
            defaultMessage: 'Les contacts ont bien été ajoutés',
          })
        }
      }
    }

    const onDrop = async (rows) => {
      await checkMails(rows)
      setSubpart(STEP_DRAWER_MANUALLY)
    }

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '24px',
        height: '100%',
      }}
      >
        <CSVLink data={data}>
          <Button style={buttonStyle}>
            <FontAwesomeIcon icon={faDownload} />
            <FormattedMessage id='downloadCSVmodel' defaultMessage='Télécharger et remplissez le modèle CSV' />
          </Button>
        </CSVLink>
        <FontAwesomeIcon icon={faArrowDownLong} color={character.disabledPlaceholder} style={{ fontSize: '24px' }} />
        <div>
          <CSVReader
            onDrop={onDrop}
            addRemoveButton
            config={{ header: true }}
            style={{
              dropFile: {
                height: 74,
                borderRadius: 16,
                width: 350,
                padding: 0,
              },
              fileSizeInfo: {
                display: 'none',
              },
              fileNameInfo: {
                borderRadius: 16,
                padding: 5,
                lineHeight: 'inherit',
              },
              progressBar: {
                backgroundColor: primaryColor[6],
              },
            }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}
            >
              <Icon type='inbox' style={{ color: primaryColor[6], fontSize: '36px' }} />
              <FormattedMessage id='uploadCSV' defaultMessage='Sélectionnez votre fichier .CSV rempli' />
            </div>
          </CSVReader>
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecules/MagicLinksSendingByCSV error:', e)
    return null
  }
}

export default injectIntl(MagicLinksSendingByCSV)

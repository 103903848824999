import React from 'react'
import { withRouter } from 'react-router'
import { MemberForm } from '../../containers'

const Member = (props) => {
  const {
    match: {
      params: {
        token = null,
        shopId = null,
      },
    },
    history,
  } = props
  return (
    <MemberForm
      history={history}
      token={token}
      shopId={shopId}
      style={{ transition: 0.2 }}
    />
  )
}

export default withRouter(Member)

/* eslint-disable max-len */
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Input,
  Select,
  Button,
  InputNumber,
  Switch,
  notification,
  Typography,
  Tag,
  Icon,
  Spin,
} from 'antd'

import { Info } from '../..'
import theme from '../../../config/theme'
import { getUnitPrice, getPricePanopli } from '../../../helpers/getPrice'
import { getAllCategory, getAllSubCategory } from '../../../helpers/baseProducts/creation'
import { trimInput } from '../../../helpers/trim'
import EditableTable from '../EditableTable'
import { LangSwitch } from '../../atoms'

const { Paragraph } = Typography
const { Option } = Select

const ProductInformationsEditor = (props) => {
  try {
    const {
      product,
      onChange,
      allCollections,
      allEmbededBPs,
      allowedToModify,
    } = props

    const initialFormData = {
      status: product.status,
      name: product.name,
      nameTranslations: product.nameTranslations,
      displayDescription: product.displayDescription,
      pricing: product.pricing,
      baseProduct: product.baseProduct,
      collections: product.collections,
      variations: product.variations,
    }

    const [language, setLanguage] = useState('fr')
    const [formData, setFormData] = useState(initialFormData)
    const [realPrice, setRealPrice] = useState(getUnitPrice(product))

    const {
      status,
      name,
      nameTranslations,
      displayDescription,
      pricing,
      baseProduct,
      collections,
      variations,
    } = formData

    const { category, subCategory } = baseProduct
    // Pour savoir si des modifications ont été apportées au produit
    const isFieldsTouched = !_.isEqual(formData, initialFormData)
    // Le prix du produit Panopli de base
    const panopliPrice = getPricePanopli(product)

    const handleReset = () => setFormData(initialFormData)

    const allCategoryOptions = getAllCategory(allEmbededBPs)
    const categoryOptions = language === 'fr' ? allCategoryOptions.allCategoryFR : allCategoryOptions.allCategoryEN

    const allSubCategoryOptions = getAllSubCategory(allEmbededBPs)
    const subCategoryOptions = language === 'fr' ? allSubCategoryOptions.allSubCategoryFR : allSubCategoryOptions.allSubCategoryEN

    /**
     * Permet de retrouver pour une variation du produit la variation du produit de base dont elle est issue
     * (et qui partage donc la même référence)
     * @param {string} reference - La référence de la variation du produit
     * @returns {object} - La variation du produit de base correspondant à la référence passée en paramêtre
     */
    const getBPVariation = (reference) => product.baseProduct.variations?.find((variation) => variation.reference === reference)

    /**
     * Permet de n'envoyer en requête d'update que les modifications du produit
     * et de gérer l'aspect custom du produit (si modification du produit de base)
     */
    const handleSubmit = () => {
      const modifications = { ...formData }
      // On parcourt les différentes clefs des données du formulaire pour comparer avec le produit
      Object.keys(modifications).map((key) => {
        // Si la propriété est inchangée par rapport à celle du produit
        if (_.isEqual(product[key], modifications[key])) {
          delete modifications[key] // On retire la propriété inchangée des modifications
          if (key === 'baseProduct') { // Si la propriété inchangée est le produit de base
            delete modifications.custom // L'aspect custom du produit ne concerne plus les modifications
          }
          // Sinon, si le produit de base a été modifié
        } else if (key === 'baseProduct') {
          modifications.custom = true // Le produit devient custom (s'il ne l'était pas déjà)
        // NOTE : le cas de figure suivant n'arrive pas avec notre composant EditableTable (de '/molecules')
        // car le nom est une donnée requise avec un message d'erreur explicite, mais au cas où l'on déciderait
        // d'utiliser un autre composant pour éditer le nom des variations, le cas est couvert...
        } else if (key === 'variations') { // Si les variations ont été modifiées
          Object.values(modifications[key]).map((variation, index) => {
            const { reference } = variation
            const bpVariationName = getBPVariation(reference)?.name
            // On vérifie qu'elles ont toutes un nom, sinon on remet le nom d'origine (celui du produit de base)
            if (modifications[key][index]?.name?.length === 0) modifications[key][index].name = bpVariationName
            return true // Juste pour tranquilliser le linter sur le retour dans les map
          })
        }
        return true // Juste pour tranquilliser le linter sur le retour dans les map
      })
      onChange(modifications) // On fait appel à la modification du produit en DB
      notification.success({
        message: (<FormattedMessage id='update.success' defaultMessage='Mise à jour réussie !' />),
        placement: 'topRight',
        duration: 8,
      })
    }

    /**
     * Permet de gérer la modification du nom du produit en fonction de la langue sélectionnée
     * @param {object} e - L'évènement du onChange
     */
    const handleNameChange = (e) => {
      // Si la modification ne concerne que le nom en français, on ne touche que le nom du produit
      if (language === 'fr') setFormData({ ...formData, name: e.target.value })
      // Sinon on ajoute la nouvelle traduction à l'objet produit contenant les traductions de ce nom
      else {
        const newNameTranslations = { ...nameTranslations }
        newNameTranslations[language] = e.target.value
        setFormData({ ...formData, nameTranslations: newNameTranslations })
      }
    }

    /**
     * Permet de gérer la modification de la description du produit en fonction de la langue sélectionnée
     * @param {object} e - L'évènement du onChange
     */
    const handleDescriptionChange = (e) => {
      const newDescription = { ...displayDescription }
      newDescription[language] = e.target.value
      setFormData({ ...formData, displayDescription: { ...newDescription } })
    }

    /**
     * Permet de gérer la modification du nom d'une catégorie en fonction de la langue sélectionnée
     * @param {object} e - L'évènement du onChange
     * @param {number} index - L'index de la catégorie dans le produit de base
     */
    const handleCategoryChange = (value) => {
      const newCategory = { ...category }
      newCategory[language] = value
      setFormData({ ...formData, baseProduct: { ...baseProduct, category: newCategory } })
    }

    /**
     * Permet de gérer la modification du nom d'une sous-catégorie en fonction de la langue sélectionnée
     * @param {object} e - L'évènement du onChange
     */
    const handleSubCategoryChange = (value) => {
      const newSubCategory = { ...subCategory }
      newSubCategory[language] = value.pop()
      setFormData({ ...formData, baseProduct: { ...baseProduct, subCategory: newSubCategory } })
    }

    /**
     * Permet de gérer la modification du nom d'une variation
     * @param {object} variation - La variation modifiée
     */
    const handleVariationChange = (variation) => {
      const index = variations.findIndex((v) => v.reference === variation.reference)
      const newVariations = [...variations]
      newVariations[index] = variation
      setFormData({ ...formData, variations: newVariations })
    }

    // Les colonnes utilisées par la table d'édition des variations
    const columns = [{
      title: (
        <span style={{ paddingLeft: 11 }}>
          <FormattedMessage id='product.name' defaultMessage='Nom' />
          <Icon type='edit' style={{ marginLeft: 5 }} />
        </span>
      ),
      key: 'name',
      dataIndex: 'name',
      editable: true,
    }, {
      title: <FormattedMessage id='type' defaultMessage='Type' />,
      key: 'type',
      dataIndex: 'type',
      editable: false,
      render: (type) => (
        <Tag>
          {type === 'size'
            ? <FormattedMessage id='size.variation' defaultMessage='Taille' />
            : <FormattedMessage id='color' defaultMessage='Couleur' />}
        </Tag>
      ),
    }, {
      title: <FormattedMessage id='productInformationsEditor.bpVariationName' defaultMessage="Nom d'origine" />,
      key: 'reference',
      dataIndex: 'reference',
      editable: false,
      render: (reference) => {
        const bpVariationName = getBPVariation(reference)?.name || '-'
        return (
          <span>
            {`"${bpVariationName}"`}
          </span>
        )
      },
    }]

    const hasNoName = trimInput(name).length === 0

    return (
      <div>
        <div style={{
          display: 'flex', marginBottom: 10, alignItems: 'center', justifyContent: 'space-between',
        }}
        >
          <Switch
            checked={status === 'Active'}
            checkedChildren={<FormattedMessage id='active.male' defaultMessage='Actif' />}
            unCheckedChildren={<FormattedMessage id='inactive.male' defaultMessage='Inactif' />}
            onChange={(checked) => setFormData({ ...formData, status: checked ? 'Active' : 'Inactive' })}
          />
          {allowedToModify && isFieldsTouched && (
            <div style={{ display: 'flex' }}>
              <Button
                type='primary'
                style={{ width: '50%', marginRight: 5 }}
                onClick={handleSubmit}
                disabled={hasNoName}
              >
                <FormattedMessage id='modify' defaultMessage='Modifier' />
              </Button>
              <Button
                style={{ width: '50%' }}
                onClick={handleReset}
              >
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </div>
          )}
          <LangSwitch
            defaultValue={language}
            onChange={(e) => {
              setLanguage(e.target.value)
              const element = document.getElementById('loadingSetLanguageProductEditor')
              element.classList.add('loading')
              setTimeout(() => element.classList.remove('loading'), 150)
            }}
          />
        </div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', width: '100%', position: 'relative',
        }}
        >
          <div id='loadingSetLanguageProductEditor'><Spin indicator={<Icon type='loading' style={{ fontSize: 24, marginBottom: '50px' }} spin />} /></div>
          <div style={{
            display: 'flex', flexDirection: 'column', width: '30%', zIndex: 10,
          }}
          >
            <div style={{ marginBottom: '10px' }}>
              <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '5px',
              }}
              >
                <span style={{ color: theme.errorColor[6], marginRight: '5px' }}>
                  *
                </span>
                <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold' }}>
                  <FormattedMessage id='product.name' defaultMessage='Nom' />
                  <Info
                    title={<FormattedMessage id='name' defaultMessage='Nom' />}
                    content={(
                      <Paragraph style={{ width: '25vw' }}>
                        <FormattedMessage
                          id='ProductsInfoManager.helper.name'
                          defaultMessage='Vous pouvez modifier ici le nom de votre produit en français ou en anglais, en utilisant le bouton de changement de langue en haut à droite de cet onglet.'
                        />
                      </Paragraph>
                    )}
                  />

                </div>
              </div>
              <Input
                value={language !== 'fr' ? nameTranslations && nameTranslations[language] : name}
                onChange={handleNameChange}
              />
              {hasNoName && (
                <div style={{ color: theme.errorColor[6], margin: '5px 0 0 5px' }}>
                  <FormattedMessage id='required.productName' defaultMessage='Veuillez renseigner le nom du produit' />
                </div>
              )}
            </div>
            <div>
              <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
                <FormattedMessage id='description' defaultMessage='Description' />
                <Info
                  title={<FormattedMessage id='productModificationForm.markdown' defaultMessage='Description / Markdown' />}
                  content={(
                    <p style={{ width: '25vw' }}>
                      <FormattedMessage id='productModificationForm.helper1' defaultMessage='Vous pouvez utiliser du markdown pour mettre en forme la description de votre produit.' />
                      {' '}
                      <FormattedMessage id='productModificationForm.helper2' defaultMessage='Exemples :' />
                      <br />
                      <FormattedMessage id='productModificationForm.helper3' defaultMessage="- titres : ajoutez un ou plusieurs symboles # puis un espace devant une ligne pour créer un titre (taille du titre décroissante avec l'ajout de #)," />
                      <br />
                      <FormattedMessage id='productModificationForm.helper4' defaultMessage='- italique : placez un ou plusieurs mots entre deux symboles * pour un affichage en italique,' />
                      <br />
                      <FormattedMessage id='productModificationForm.helper5' defaultMessage='- sauts-de-ligne : ajoutez deux espaces à la fin de votre ligne.' />
                      <br />
                      <FormattedMessage id='productModificationForm.helper6' defaultMessage="Plus d'informations sur :" />
                      {' '}
                      <a href='https://www.markdownguide.org/basic-syntax/' target='_blank' rel='noopener noreferrer'>
                        markdownguide.org
                      </a>
                      <br />
                      <FormattedMessage id='productModificationForm.helper7' defaultMessage='N.B. : gras non-disponible avec la police par défaut.' />
                    </p>
                  )}
                />
              </div>
              <Input.TextArea
                type='description'
                autoSize
                value={displayDescription[language]}
                onChange={handleDescriptionChange}
              />
            </div>
          </div>
          <div style={{
            display: 'flex', flexDirection: 'column', width: '30%', padding: '0 12px', justifyContent: 'space-between', zIndex: 10,
          }}
          >
            <div style={{ marginBottom: '10px' }}>
              <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
                <FormattedMessage id='category' defaultMessage='Catégorie' />
                <Info
                  title={<FormattedMessage id='category' defaultMessage='Catégorie' />}
                  content={(
                    <Paragraph style={{ width: '25vw' }}>
                      <FormattedMessage
                        id='ProductsInfoManager.helper.category'
                        defaultMessage='Vos pouvez ajouter une ou plusieurs catégories à votre produit.
                        La liste ci-dessous vous permet de choisir parmi les catégories déjà utilisée par un produit de votre boutique,
                        mais vous pouvez également entrer une nouvelle catégorie personnalisée.
                        Celle-ci sera alors disponible pour éditer les autres produits de votre boutique.'
                      />
                    </Paragraph>
                  )}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Select
                  mode='tags'
                  allowClear
                  style={{ width: '100%' }}
                  value={category[language] || []}
                  onChange={handleCategoryChange}
                >
                  {categoryOptions.map((cat) => <Option key={cat}>{cat}</Option>)}
                </Select>
              </div>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
                <FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />
                <Info
                  title={<FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />}
                  content={(
                    <Paragraph style={{ width: '25vw' }}>
                      <FormattedMessage
                        id='ProductsInfoManager.helper.subCategory'
                        defaultMessage='Vos pouvez ajouter une (et une seule) sous-catégorie à votre produit.
                        La liste ci-dessous vous permet de choisir une sous-catégorie déjà utilisée par un produit de votre boutique,
                        mais vous pouvez également entrer une nouvelle sous-catégorie personnalisée.
                        Celle-ci sera alors disponible pour éditer les autres produits de votre boutique.'
                      />
                    </Paragraph>
                  )}
                />
              </div>
              <Select
                allowClear
                mode='tags'
                style={{ width: '100%' }}
                value={subCategory[language] || []}
                onChange={handleSubCategoryChange}
              >
                {subCategoryOptions.map((sub) => <Option key={sub}>{sub}</Option>)}
              </Select>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
                <FormattedMessage id='collections' defaultMessage='Collections' />
                <Info
                  title={<FormattedMessage id='collections' defaultMessage='Collections' />}
                  content={(
                    <Paragraph style={{ width: '25vw' }}>
                      <FormattedMessage
                        id='ProductsInfoManager.helper.collections'
                        defaultMessage='Les collections permettent de regrouper facilement vos produits sur la boutique.
                          ⚠️ le nom des collections est case-sensitive.'
                      />
                    </Paragraph>
                  )}
                />
              </div>
              <Select
                mode='tags'
                placeholder={<FormattedMessage id='collections' defaultMessage='Collections' />}
                value={collections}
                onChange={(value) => setFormData({ ...formData, collections: value })}
                style={{ width: '100%' }}
              >
                {allCollections.map((collection) => (
                  <Option key={collection} value={collection}>{collection}</Option>
                ))}
              </Select>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: '1 2' }}>
                <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
                  <FormattedMessage id='panopli.price' defaultMessage='Prix Panopli' />
                </div>
                <div>
                  <InputNumber step={0.01} disabled value={panopliPrice} />
                  <span style={{ marginLeft: 5 }}>€</span>
                </div>
              </div>
              <div style={{ flex: '1 2' }}>
                <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
                  <FormattedMessage id='sell.price' defaultMessage='Valeur affichée' />
                  <Info
                    title={<FormattedMessage id='sell.price' defaultMessage='Valeur affichée' />}
                    content={(
                      <Paragraph style={{ width: '25vw' }}>
                        <FormattedMessage
                          id='popover.seller.price'
                          defaultMessage="Il s'agit du montant affiché sur votre boutique pour ce produit. Votre marge est donc la différence entre votre valeur affichée et le prix Panopli."
                        />
                      </Paragraph>
                    )}
                  />
                </div>
                <div>
                  <InputNumber
                    type='number'
                    value={realPrice.toFixed(2)}
                    step={0.01}
                    onChange={(value) => {
                      if (value >= panopliPrice) {
                        const margin = (parseFloat(value) - parseFloat(panopliPrice)).toFixed(2)
                        setRealPrice(value)
                        setFormData({ ...formData, pricing: { ...pricing, margin: parseFloat(margin) } })
                      } else {
                        setRealPrice(realPrice)
                      }
                    }}
                  />
                  <span style={{ marginLeft: 5 }}>€</span>
                </div>
              </div>
            </div>
          </div>
          <div style={{
            display: 'flex', flexDirection: 'column', width: '40%', zIndex: 10,
          }}
          >
            <span style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
              <FormattedMessage id='variations' defaultMessage='Variations' />
              <Info
                title={(
                  <span>
                    <FormattedMessage id='variations' defaultMessage='Variations' />
                    <Icon type='edit' style={{ marginLeft: 5 }} />
                  </span>
                )}
                content={(
                  <Paragraph style={{ width: '25vw' }}>
                    <FormattedMessage
                      id='ProductsInfoManager.variations.helper'
                      defaultMessage='Choisissez un nouveau nom pour vos variations de tailles et de couleurs (la variation reste inchangée, seul le nom affiché sera modifié).'
                    />
                  </Paragraph>
                )}
              />
            </span>
            <div>
              <EditableTable
                dataSource={variations}
                columns={columns}
                handleSave={handleVariationChange}
                size='small'
              />
            </div>
          </div>
        </div>
      </div>
    )
  } catch (error) {
    console.log('molecules/ProductInformationsEditor error:', error)
    return null
  }
}

export default ProductInformationsEditor

import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Form,
  Row,
  Button,
} from 'antd'
import { CountrySelector } from '../..'

class LanguageForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: { validateFieldsAndScroll },
      onSubmit,
    } = this.props
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        console.log('Received values of form: ', values)
        onSubmit(values)
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        isFieldsTouched,
        resetFields,
      },
    } = this.props

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label={<FormattedMessage id='language' defaultMessage='Langue' />}>
          {getFieldDecorator('lang')(<CountrySelector />)}
        </Form.Item>
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button type='primary' htmlType='submit' style={{ marginRight: 20 }}>
                <FormattedMessage id='modify' defaultMessage='Modifier' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    )
  }
}

export default Form.create({ name: 'languageForm' })(LanguageForm)

import {
  Form, Input, Typography,
} from 'antd'
import React, { useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'

import { CountrySelector } from '../..'
import config from '../../../config'
import formatAutoComplete from '../../../helpers/autoComplete'
import {
  noSpaceInEmail,
  requiresEmail,
  validateEmailWithCallback,
} from '../../../helpers/emailValidation'

const { autoComplete: { publicApiKey } } = config

const { Title } = Typography

const CheckoutFormAddress = (props) => {
  const intl = useIntl()

  const {
    form,
    type = 'client',
    changeCountry,
    setPresetAddress,
    locale,
  } = props

  const {
    getFieldDecorator, // Required
    getFieldValue, // Required
    setFieldsValue, // Required
  } = form

  if (setPresetAddress?.street) return null

  const [emailInput, setEmailInput] = useState(null)

  // Autocomplete selection
  const onPlaceSelected = (place) => {
    if (place) {
      // eslint-disable-next-line camelcase
      const { address_components } = place
      // On met au format les retours de de l'autoComplete Google
      const {
        streetAddress, postalCode, city, country,
      } = formatAutoComplete(address_components)
      // On change les valeurs des champs d'adresse
      setFieldsValue({
        [`${type}.street`]: streetAddress,
        [`${type}.postalCode`]: postalCode,
        [`${type}.city`]: city,
        [`${type}.country`]: country,
      })
      changeCountry(country)
    }
  }

  return (
    <>
      <Title level={4} style={{ marginLeft: '10px' }}>
        {type === 'client'
          ? <FormattedMessage id='checkoutForm.deliveryAddress' defaultMessage='Adresse de livraison' />
          : <FormattedMessage id='checkoutForm.billingAddress' defaultMessage='Adresse de facturation' />}
      </Title>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.firstName`, {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='checkoutForm.required.firstName' defaultMessage='Veuillez renseigner votre prénom' />,
                  whitespace: true,
                },
              ],
            })(<Input
              placeholder={intl.formatMessage({
                id: 'addressForm.placeholder.firstName',
                defaultMessage: 'Prénom',
              })}
            />)}
          </Form.Item>
        </div>
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.lastName`, {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='checkoutForm.required.lastName' defaultMessage='Veuillez renseigner votre nom' />,
                  whitespace: true,
                },
              ],
            })(<Input
              placeholder={intl.formatMessage({
                id: 'addressForm.placeholder.lastName',
                defaultMessage: 'Nom',
              })}
            />)}
          </Form.Item>
        </div>
      </div>
      <div
        style={{
          margin: '0 10px',
        }}
      >
        <Form.Item>
          {getFieldDecorator(`${type}.street`, {
            initialValue: '',
            rules: [
              {
                required: true,
                message: <FormattedMessage id='checkoutForm.required.address' defaultMessage='Veuillez renseigner votre adresse' />,
                whitespace: true,
              },
            ],
          })(<Autocomplete
            className='autoComplete'
            apiKey={publicApiKey}
            options={{ types: ['street_address', 'route'] }}
            placeholder={intl.formatMessage({
              id: 'addressForm.placeholder.address',
              defaultMessage: 'Adresse',
            })}
            onPlaceSelected={onPlaceSelected}
          />)}
        </Form.Item>
      </div>
      <div
        style={{
          margin: '0 10px',
        }}
      >
        <Form.Item>
          {getFieldDecorator(`${type}.complement`, {
            initialValue: '',
          })(<Input
            placeholder={intl.formatMessage({
              id: 'addressForm.placeholder.additionalAddress',
              defaultMessage: 'Complément d\'adresse',
            })}
          />)}
        </Form.Item>
      </div>
      <div
        style={{
          margin: '0 10px',
        }}
      >
        <Form.Item>
          {getFieldDecorator(`${type}.company`, {
            initialValue: '',
          })(<Input
            placeholder={intl.formatMessage({
              id: 'addressForm.placeholder.compagny',
              defaultMessage: 'Société',
            })}
          />)}
        </Form.Item>
      </div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.city`, {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='checkoutForm.required.city' defaultMessage='Veuillez renseigner votre ville' />,
                  whitespace: true,
                },
              ],
            })(<Input
              placeholder={intl.formatMessage({
                id: 'addressForm.placeholder.city',
                defaultMessage: 'Ville',
              })}
            />)}
          </Form.Item>
        </div>
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.postalCode`, {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='checkoutForm.required.postalCode' defaultMessage='Veuillez renseigner votre code postal' />,
                  whitespace: true,
                },
              ],
            })(<Input
              placeholder={intl.formatMessage({
                id: 'addressForm.placeholder.postalCode',
                defaultMessage: 'Code postal',
              })}
            />)}
          </Form.Item>
        </div>
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.country`, {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='checkoutForm.required.country' defaultMessage='Veuillez renseigner votre pays' />,
                  whitespace: true,
                },
              ],
            })(
              <CountrySelector
                value={getFieldValue(`${type}.country`)}
                onChange={(value) => changeCountry && changeCountry(value)}
                locale={locale}
              />,
            )}
          </Form.Item>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.email`, {
              initialValue: '',
              rules: [
                { validator: requiresEmail },
                { validator: validateEmailWithCallback },
                { validator: noSpaceInEmail },
              ],
            })(<Input
              placeholder={intl.formatMessage({
                id: 'addressForm.placeholder.email',
                defaultMessage: 'Email',
              })}
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value.replaceAll(' ', ''))}
            />)}
          </Form.Item>
        </div>
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.phone`, {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='checkoutForm.required.phone' defaultMessage='Veuillez entrer votre numéro de téléphone' />,
                  whitespace: true,
                },
              ],
            })(<Input
              placeholder={intl.formatMessage({
                id: 'addressForm.placeholder.phoneNumber',
                defaultMessage: 'Numéro de téléphone',
              })}
            />)}
          </Form.Item>
        </div>
      </div>
    </>
  )
}

export default injectIntl(CheckoutFormAddress)

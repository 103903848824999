import { Select } from 'antd'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'

import { character } from '../../../config/theme'

const { Option } = Select

const CampaignSelection = (props) => {
  const {
    currentCampaignId, // Required
    campaigns, // Required
    onChange, // Required
    icon,
    titleStyle,
    style,
  } = props

  const sortedCampaigns = campaigns.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '8px', width: '100%',
    }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        fontWeight: 500,
        fontSize: '14px',
        color: character.title,
        ...titleStyle,
      }}
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        <FormattedMessage id='campaign' defaultMessage='Campagne' />
      </div>
      <Select
        showArrow
        allowClear
        showSearch
        optionFilterProp='children'
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        placeholder={(
          <FormattedMessage
            id='campaigns.select.placeholder'
            defaultMessage='Sélectionnez une campagne'
          />
        )}
        defaultValue={currentCampaignId}
        onChange={onChange}
        style={style}
      >
        {sortedCampaigns.map((campaign) => (
          <Option key={campaign._id} value={campaign._id}>
            {campaign.name}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default CampaignSelection

// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import {
  put, call, takeEvery, select, all, takeLatest,
} from 'redux-saga/effects'
import { fromShops, fromUsers } from '../selectors'
import * as discountsActions from './actions'
import { trimIdsForDiscountEndpoint } from '../../helpers/trim'
import { getOrders } from '../orders/sagas'
import { fetchEntities } from '../entities/sagas'

const { getId, getOwner } = fromShops
const { getAuth } = fromUsers

/**
 * `*fetchDiscounts` fetches the info we need from the API
 * ---
 * Generator function doc: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/function*'
 * ---
 * @param {Object} api the api service
 * @return {Boolean} true if everything went ok
 */

export function* createDiscount(api, action) {
  try {
    const { discount } = action.payload
    const auth = yield select(getAuth)
    const shop = yield select(fromShops.getId)
    const owner = yield select(getOwner)
    const data = {
      ...discount,
      owner,
      shop,
    // createdAt: new Date(),
    // updatedAt: new Date(),
    }
    const insert = yield call([api, api.post], '/discount', data, { headers: { authorization: auth } })
    // GET CREATED AT AND _ID FROM RESPONSE
    yield put({ type: discountsActions.CREATED_DISCOUNT, payload: { discount: insert.discount } })
    return true
  // HANDLE SERVER RESPONSE}
  } catch (e) {
    console.log('createDiscount', e)
    yield put({ type: discountsActions.ERROR, payload: { error_details: e, error_source: 'creation' } })
    return false
  }
}
export function* fetchDiscounts(api) {
  try {
    yield put({ type: discountsActions.LOADING, payload: { loading: true } })
    const shop = yield select(getId)
    const auth = yield select(getAuth)
    yield put({ type: discountsActions.GET })
    const { data } = yield call([api, api.get], '/discount', { headers: { authorization: auth, shop } })
    yield put({ type: discountsActions.RECEIVED, payload: { data } })
    return true
  } catch (e) {
    console.log('getShop error', e, e.message)
    yield put({
      type: discountsActions.ERROR,
      error_details: (e.message || e),
    })
    return false
  }
}

export function* deleteDiscount(api, action) {
  try {
    const { ids } = action.payload
    const formatedIds = trimIdsForDiscountEndpoint(ids)
    const auth = yield select(getAuth)
    yield call([api, api.delete], '/discount', { headers: { authorization: auth, ids: formatedIds } })
    yield put({ type: discountsActions.DELETED })
  } catch (e) {
    console.log('sagas - deleteDiscount', e)
    yield put({ type: discountsActions.ERROR, payload: { error_details: e, error_source: 'deletion' } })
  }
}

export function* modifyDiscount(api, action) {
  try {
    const { discount } = action.payload
    const auth = yield select(getAuth)
    yield call([api, api.put], '/discount', discount, { headers: { authorization: auth } })
    yield put({ type: discountsActions.MODIFIED })
  } catch (e) {
    console.log('sagas - modifyDiscount', e)
    yield put({ type: discountsActions.ERROR, payload: { error_details: e, error_source: 'edition' } })
  }
}

export function* watchModifyDiscount(api, action) {
  yield call(modifyDiscount, api, action)
}

export function* watchReceiveShops(api) {
  yield call(fetchDiscounts, api)
}

export function* watchDeleteDiscount(api, action) {
  yield call(deleteDiscount(api, action))
}

export function* getDataForDiscountsPage(api) {
  const page = 0
  const pageSize = 200
  yield all([
    call(fetchDiscounts, api),
    call(getOrders, api, { payload: { page, pageSize } }),
    call(fetchEntities, api),
  ])
  yield put({ type: discountsActions.END_FETCH_DATA })
}

export default function* ({ api }) {
  yield takeLatest(discountsActions.INIT_FETCH_DATA, getDataForDiscountsPage, api)
  yield takeEvery(discountsActions.MODIFY, watchModifyDiscount, api)
  yield takeEvery(discountsActions.DELETE, deleteDiscount, api)
  yield takeEvery(discountsActions.FETCH_FORCE, watchReceiveShops, api)
  yield takeEvery(discountsActions.CREATE_DISCOUNT, createDiscount, api)
}

import React from 'react'
import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { character } from '../../../config/theme'

const StepTooltipLabel = (props) => {
  const {
    title,
    icon = faInfoCircle,
    placement = 'top',
    style,
  } = props

  return (
    <Tooltip
      placement={placement}
      title={title}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{
          color: character.disabledPlaceholder,
          ...style,
        }}
      />
    </Tooltip>
  )
}

export default StepTooltipLabel

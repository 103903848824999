import React from 'react'
import { Divider, Collapse } from 'antd'
import { FormattedMessage } from 'react-intl'

const { Panel } = Collapse

const DeliveryRecap = (props) => {
  const { deliveryRecap } = props
  const deliveries = deliveryRecap.map((provider) => {
    const { name, deliveryPrice } = provider
    return (
      <p style={{ fontSize: '10px', fontWeight: 'bold' }}>
        <span>
          {`${name} : ${deliveryPrice} €`}
        </span>
      </p>
    )
  })
  return (
    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>
      <FormattedMessage id='bp-cart.deliveryRecapTitle' defaultMessage='Récapitulatif Livraison : ' />
      {deliveries}
    </p>
  )
}

const CartRecap = (props) => {
  const {
    nbOfProducts, totalQuantity, totalPrice, providersDeliveryRecap,
  } = props
  const totalDeliveryPrice = providersDeliveryRecap?.reduce((acc, current) => acc + current.deliveryPrice, 0)
  return (
    <div style={{ textAlign: 'center' }}>
      <p>
        <span style={{ marginRight: '8px' }}>
          <FormattedMessage
            id='bp-cart.products-number'
            defaultMessage='{nbOfProducts, plural, =0 {# produit} one {# produit} other {# produits}}'
            values={{
              nbOfProducts,
            }}
          />
        </span>
        <Divider type='vertical' />
        <span style={{ marginRight: '8px', marginLeft: '8px' }}>
          <FormattedMessage id='bp-cart.totalQuantity' defaultMessage='Quantités totale : ' />
          {totalQuantity}
        </span>
        <Divider type='vertical' />
        <span style={{ marginLeft: '8px' }}>
          {`${(totalPrice / totalQuantity).toFixed(2)} €/u (HT)`}
        </span>
      </p>
      <p style={{ fontSize: '12px', fontWeight: 'bold' }}>
        {`${totalPrice} € Total Produits (HT)`}
      </p>

      <Collapse ghost bordered={false} style={{ backgroundColor: 'white' }}>
        <Panel header={(
          <p style={{ fontSize: '12px', fontWeight: 'bold' }}>
            {`${totalDeliveryPrice} € Total Livraison (HT)`}
          </p>
        )}
        >
          <DeliveryRecap deliveryRecap={providersDeliveryRecap} />
        </Panel>
      </Collapse>

      <p style={{ fontSize: '14px', fontWeight: 'bold' }}>
        {`${totalPrice + totalDeliveryPrice} € Total (HT)`}
      </p>
    </div>
  )
}

export default CartRecap

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { faClock } from '@fortawesome/free-solid-svg-icons'

import {
  DataBlockNPS, DataBlockSentMagicLinks, DataBlockSpentMagicLinks, DataBlock,
} from '../..'

import { getMagicLinkStatus } from '../../../helpers/magicLinks'

const CampaignEditDashboardStats = (props) => {
  try {
    const { currentCampaignId, magicLinks } = props
    const campaignMagicLinks = magicLinks.filter((magicLink) => magicLink.campaignId === currentCampaignId)
    const numberOfCommittedMagicLinks = campaignMagicLinks?.filter((magicLink) => getMagicLinkStatus(magicLink) === 'committed').length ?? 'N/C'

    return (
      <div style={{
        display: 'flex', gap: '16px', width: '100%',
      }}
      >
        <DataBlockSentMagicLinks magicLinks={campaignMagicLinks} />
        {/* //TODO passer les nps quand on les aura */}
        <DataBlockNPS npsSource='CAMPAIGN' campaignId={currentCampaignId} />
        <DataBlockSpentMagicLinks magicLinks={campaignMagicLinks} />
        <DataBlock
          title={(
            <FormattedMessage
              id='magicLinks.dashboard.committedMagicLinks'
              defaultMessage='Links engagés'
            />
          )}
          value={numberOfCommittedMagicLinks}
          icon={faClock}
        />
      </div>
    )
  } catch (e) {
    console.log('molecules/CampaignEditDashboardStats error:', e)
    return null
  }
}

export default CampaignEditDashboardStats

import React from 'react'
import { FormattedMessage } from 'react-intl'

// eslint-disable-next-line max-len, no-useless-escape
const REGEX_VALID_EMAIL = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const requiresEmail = (rule, value, callback) => (
  value.length ? callback() : callback(
    <FormattedMessage
      id='auth.emptyEmail'
      defaultMessage='Veuillez entrer une adresse mail'
    />,
  )
)

const validateEmailWithCallback = (rule, value, callback) => (value.length && !REGEX_VALID_EMAIL.test(value) ? callback(
  <FormattedMessage
    id='auth.checkEmail'
    defaultMessage='Le format de cette adresse mail est incorrect'
  />,
) : callback())

const noSpaceInEmail = (rule, value, callback) => (
  value.length && value.includes(' ') ? callback(
    <FormattedMessage
      id='auth.checkNoSpaceEmail'
      defaultMessage='Votre adresse mail contient un espace quelque part...'
    />,
  ) : callback()
)

// CHECK if the email passed in value is a valid email
const isEmailValid = (value) => value.length && REGEX_VALID_EMAIL.test(value)

export {
  requiresEmail, validateEmailWithCallback, noSpaceInEmail, isEmailValid,
}

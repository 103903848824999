/* eslint-disable max-len */
import {
  Alert,
  Button,
  Checkbox,
  InputNumber,
  Select,
  Switch,
  Tabs,
} from 'antd'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

const { Option } = Select
const { TabPane } = Tabs

const TableHeader = (props) => {
  try {
    const {
      product,
      selectedId,
      setSelectedId,
      allRestockThreshold,
      setAllRestockThreshold,
      allPriceImpact,
      setAllPriceImpact,
      allQuantity,
      setAllQuantity,
      allSupplier,
      setAllSupplier,
    } = props
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px 5px',
          fontWeight: 'bold',
          fontSize: '12px',
        }}
      >
        <span style={{ flex: '1 8' }}>
          <Checkbox
            checked={selectedId.length === product.stock.length}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedId(product.stock.map((stock) => stock.id))
              } else {
                setSelectedId([])
              }
            }}
            style={{ margin: '0 10px 0 0' }}
          />
          Tous les combos
        </span>
        <span style={{ flex: '1 8', textAlign: 'center' }}>
          SKU
        </span>
        <span style={{ flex: '1 8', textAlign: 'center' }}>
          Quantité
          <Checkbox
            checked={allQuantity}
            onChange={(e) => {
              setAllQuantity(e.target.checked)
              setSelectedId([])
            }}
            style={{ margin: '0 0 0 10px' }}
          />
        </span>
        <span style={{ flex: '1 8', textAlign: 'center' }}>
          <span style={{ whiteSpace: 'nowrap' }}>Seuil de réapprovisionnement</span>
          <Checkbox
            checked={allRestockThreshold}
            onChange={(e) => {
              setAllRestockThreshold(e.target.checked)
              setSelectedId([])
            }}
            style={{ margin: '0 0 0 10px' }}
          />
        </span>
        <span style={{ flex: '1 8', textAlign: 'center' }}>
          <span style={{ whiteSpace: 'nowrap' }}>Impact sur le prix</span>
          <Checkbox
            checked={allPriceImpact}
            onChange={(e) => {
              setAllPriceImpact(e.target.checked)
              setSelectedId([])
            }}
            style={{ margin: '0 0 0 10px' }}
          />
        </span>
        <span style={{ flex: '1 8', textAlign: 'center' }}>
          Fournisseur
          <Checkbox
            checked={allSupplier}
            onChange={(e) => {
              setAllSupplier(e.target.checked)
              setSelectedId([])
            }}
            style={{ margin: '0 0 0 10px' }}
          />
        </span>
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/ProductStockForm/TableHeader')
    return null
  }
}

const TableColumns = (props) => {
  const {
    onPressEnterInput,
    product,
    entity,
    stock,
    index,
    selectedId,
    setSelectedId,
    updateProduct,
    allRestockThreshold,
    setAllRestockThreshold,
    allPriceImpact,
    allQuantity,
    setAllQuantity,
    allSupplier,
    setAllSupplier,
  } = props

  return (
    <div style={{
      border: '1px solid #D5D5D5',
      padding: '5px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
    }}
    >
      <span style={{ flex: '1 8', display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={selectedId.find((id) => id === stock.id)}
          onChange={(e) => {
            let newSelectedId
            if (e.target.checked) {
              newSelectedId = [...selectedId]
              newSelectedId.push(stock.id)
            } else {
              newSelectedId = selectedId.filter((id) => id !== stock.id)
            }
            setAllQuantity(false)
            setAllSupplier(false)
            setAllRestockThreshold(false)
            setSelectedId(newSelectedId)
          }}
          style={{ margin: '0 10px 0 0' }}
        />
        <span>
          {stock.variationCombo[0].name}
          {' | '}
          {stock.variationCombo[1].name}
        </span>
      </span>
      <span style={{
        flex: '1 8',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '10px',
      }}
      >
        {stock.sku}
      </span>
      <span style={{ flex: '1 8', margin: '0 5px' }}>
        <InputNumber
          id={`qty-input-${entity._id}-${index}`}
          value={product._id ? (stock.qty + stock.qtyArrival).toFixed(0) : stock.qtyArrival}
          disabled={product._id && !stock.new}
          style={{
            width: '100%',
            boxShadow: allQuantity || selectedId.includes(stock.id) ? '0px 0px 0px 1px #012169' : null,
            borderRadius: allQuantity || selectedId.includes(stock.id) ? '3px' : null,
            textAlign: 'right',
            fontSize: '12px',
          }}
          onChange={(value) => {
            let stocks
            const restockThreshold = Math.floor(value * (1 / 4))
            if (allQuantity) {
              stocks = product.stock.map((iStock) => {
                if (iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    qtyArrival: value,
                    restockThreshold,
                  })
                }
                return iStock
              })
            } else if (selectedId.length) {
              stocks = product.stock.map((iStock) => {
                if (selectedId.includes(iStock.id) && iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    qtyArrival: value,
                    restockThreshold,
                  })
                }
                return iStock
              })
            } else {
              stocks = product.stock.map((iStock) => {
                console.log(stock.variationCombo[0].reference, iStock.variationCombo[0].reference)
                if (stock.variationCombo[0].reference === iStock.variationCombo[0].reference
            && stock.variationCombo[1].reference === iStock.variationCombo[1].reference && iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    qtyArrival: value,
                    restockThreshold,
                  })
                }
                return iStock
              })
            }
            updateProduct(product.provisionalId, {
              stock: stocks,
            })
          }}
          onPressEnter={() => onPressEnterInput(`qty-input-${entity._id}-${index + 1}`)}
        />
      </span>
      <span style={{ flex: '1 8', margin: '0 5px' }}>
        <InputNumber
          id={`restockThreshold-${entity._id}-input-${index}`}
          value={stock.restockThreshold}
          disabled={product._id && !stock.new}
          style={{
            width: '100%',
            boxShadow: allRestockThreshold || selectedId.includes(stock.id) ? '0px 0px 0px 1px #012169' : null,
            borderRadius: allRestockThreshold || selectedId.includes(stock.id) ? '3px' : null,
            fontSize: '12px',
          }}
          onChange={(value) => {
            let stocks
            if (allRestockThreshold) {
              stocks = product.stock.map((iStock) => {
                if (iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    restockThreshold: value,
                  })
                } return iStock
              })
            } else if (selectedId.length) {
              stocks = product.stock.map((iStock) => {
                if (selectedId.includes(iStock.id) && iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    restockThreshold: value,
                  })
                }
                return iStock
              })
            } else {
              stocks = product.stock.map((iStock) => {
                if (stock.variationCombo[0].reference === iStock.variationCombo[0].reference
            && stock.variationCombo[1].reference === iStock.variationCombo[1].reference && iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    restockThreshold: value,
                  })
                }
                return iStock
              })
            }
            updateProduct(product.provisionalId, {
              stock: stocks,
            })
          }}
          onPressEnter={() => onPressEnterInput(`restockThreshold-${entity._id}-input-${index + 1}`)}
        />
      </span>
      <span style={{ flex: '1 8', margin: '0 5px' }}>
        <InputNumber
          id={`plateformFeeImpact-${entity._id}-input-${index}`}
          value={stock.plateformFeeImpact}
          disabled={product._id && !stock.new}
          style={{
            width: '100%',
            boxShadow: allPriceImpact || selectedId.includes(stock.id) ? '0px 0px 0px 1px #012169' : null,
            borderRadius: allPriceImpact || selectedId.includes(stock.id) ? '3px' : null,
            fontSize: '12px',
          }}
          onChange={(value) => {
            let stocks
            if (allPriceImpact) {
              stocks = product.stock.map((iStock) => {
                if (iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    plateformFeeImpact: value,
                  })
                } return iStock
              })
            } else if (selectedId.length) {
              stocks = product.stock.map((iStock) => {
                if (selectedId.includes(iStock.id) && iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    plateformFeeImpact: value,
                  })
                }
                return iStock
              })
            } else {
              stocks = product.stock.map((iStock) => {
                if (stock.variationCombo[0].reference === iStock.variationCombo[0].reference
            && stock.variationCombo[1].reference === iStock.variationCombo[1].reference && iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    plateformFeeImpact: value,
                  })
                }
                return iStock
              })
            }
            updateProduct(product.provisionalId, {
              stock: stocks,
            })
          }}
          onPressEnter={() => onPressEnterInput(`plateformFeeImpact-${entity._id}-input-${index + 1}`)}
        />
      </span>
      <span style={{ flex: '1 8', margin: '0 5px' }}>
        <Select
          value={stock.shipperId}
          disabled={product._id}
          style={{
            width: '100%',
            boxShadow: allSupplier || selectedId.includes(stock.id) ? '0px 0px 0px 1px #012169' : null,
            borderRadius: allSupplier || selectedId.includes(stock.id) ? '3px' : null,
            fontSize: '12px',
          }}
          onChange={(value) => {
            let stocks
            if (allSupplier) {
              stocks = product.stock.map((iStock) => {
                if (iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    shipperId: value,
                  })
                }
                return iStock
              })
            } else if (selectedId.length) {
              stocks = product.stock.map((iStock) => {
                if (selectedId.includes(iStock.id) && iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    shipperId: value,
                  })
                }
                return iStock
              })
            } else {
              stocks = product.stock.map((iStock) => {
                if (stock.variationCombo[0].reference === iStock.variationCombo[0].reference
          && stock.variationCombo[1].reference === iStock.variationCombo[1].reference && iStock.entityId === entity._id) {
                  return ({
                    ...iStock,
                    shipperId: value,
                  })
                }
                return iStock
              })
            }
            updateProduct(product.provisionalId, {
              stock: stocks,
            })
          }}
        >
          <Option value='62739ad424da3315ccddb154'>ShippingBo</Option>
          <Option value='59a7d079bfc217a319b55694'>Brodelec</Option>
          <Option value='60f68a59850adb758bc3faff'>Wing</Option>
        </Select>
      </span>
    </div>
  )
}

const StockTable = (props) => {
  try {
    const {
      onPressEnterInput,
      product,
      updateProduct,
      entities,
      selectedId,
      setSelectedId,
      allRestockThreshold,
      setAllRestockThreshold,
      allPriceImpact,
      setAllPriceImpact,
      allQuantity,
      setAllQuantity,
      allSupplier,
      setAllSupplier,
    } = props

    return (
      <Tabs defaultActiveKey={entities.find((entity) => entity._id)}>
        {entities.map((entity) => (
          <TabPane tab={entity.name} key={entity._id}>
            <div style={{ backgroundColor: '#EAEAEA', borderRadius: '5px' }}>
              <TableHeader
                product={product}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                allRestockThreshold={allRestockThreshold}
                setAllRestockThreshold={setAllRestockThreshold}
                allPriceImpact={allPriceImpact}
                setAllPriceImpact={setAllPriceImpact}
                allQuantity={allQuantity}
                setAllQuantity={setAllQuantity}
                allSupplier={allSupplier}
                setAllSupplier={setAllSupplier}
              />
              {product.stock.filter((stock) => stock.entityId === entity._id).map((stock, index) => (
                <TableColumns
                  onPressEnterInput={onPressEnterInput}
                  product={product}
                  updateProduct={updateProduct}
                  entity={entity}
                  stock={stock}
                  index={index}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  allRestockThreshold={allRestockThreshold}
                  setAllRestockThreshold={setAllRestockThreshold}
                  allPriceImpact={allPriceImpact}
                  setAllPriceImpact={setAllPriceImpact}
                  allQuantity={allQuantity}
                  setAllQuantity={setAllQuantity}
                  allSupplier={allSupplier}
                  setAllSupplier={setAllSupplier}
                />
              ))}
            </div>
          </TabPane>
        ))}
      </Tabs>
    )
  } catch (e) {
    console.log(e, 'error molecules/ProductStockForm/StockTable')
    return null
  }
}

const ProductStockForm = (props) => {
  try {
    const {
      product,
      updateProduct,
      allEntities,
      allShops,
    } = props

    const entities = allEntities.filter((entity) => entity.shop === product.shops.find((shop) => shop))
    const [selectedId, setSelectedId] = useState([])
    const [allRestockThreshold, setAllRestockThreshold] = useState(0)
    const [allPriceImpact, setAllPriceImpact] = useState(0)
    const [allQuantity, setAllQuantity] = useState(false)
    const [allSupplier, setAllSupplier] = useState(false)

    const generateSku = (color, size, shopName, productName, trimedColor, trimedSize, random) => {
      // si le combo de variation a déjà du stock dans une autre entité, alors on donne le même différenciant pour les entités
      if (product.stock && product.stock.length && product.stock.find((_stock) => color.reference === _stock.variationCombo.find((combo) => combo.type === 'color').reference && size.reference === _stock.variationCombo.find((combo) => combo.type === 'size').reference)) {
        return product.stock.find((_stock) => color.reference === _stock.variationCombo.find((combo) => combo.type === 'color').reference && size.reference === _stock.variationCombo.find((combo) => combo.type === 'size').reference).sku
      }
      // si nouveau combo de variation on le créer
      return `${shopName}${productName}${trimedColor}${trimedSize}-${random.slice(0, 7)}`.toUpperCase()
    }

    const addStocks = (checked) => {
      if (checked) {
        const stock = []
        const sizes = product.variations.filter((variation) => variation.type === 'size')
        const colors = product.variations.filter((variation) => variation.type === 'color')
        sizes.map((size) => {
          colors.map((color) => {
            const random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6)
            entities.map((entity) => {
              const foundedStock = product?.stock?.find((_stock) => color.reference === _stock.variationCombo.find((combo) => combo.type === 'color').reference && size.reference === _stock.variationCombo.find((combo) => combo.type === 'size').reference && String(_stock.entityId) === String(entity._id))

              if (product._id && foundedStock) {
                stock.push(foundedStock)
                return false
              }

              const randomString = Math.random().toString(36).substring(7)
              const shopName = allShops.find((shop) => shop._id === product.shops[0]).settings.name.replace(new RegExp('[^a-zA-Z0-9 -]', 'gi'), '').replace(new RegExp(/\s+/g, 'gi'), '').slice(0, 4)
              const productName = product.name.replace(new RegExp('[^a-zA-Z0-9 -]', 'gi'), '').replace(new RegExp(/\s+/g, 'gi'), '').slice(0, 4)
              const trimedColor = color ? color.name.replace(new RegExp('[^a-zA-Z0-9 -]', 'gi'), '').replace(new RegExp(/\s+/g, 'gi'), '').slice(0, 3) : ''
              const trimedSize = size ? size.name.replace(new RegExp('[^a-zA-Z0-9 -]', 'gi'), '').replace(new RegExp(/\s+/g, 'gi'), '').slice(0, 3) : ''
              const sku = generateSku(color, size, shopName, productName, trimedColor, trimedSize, random)
              stock.push({
                id: randomString,
                qtyArrival: 0,
                qty: 0,
                shipperId: '62739ad424da3315ccddb154',
                restockThreshold: 0,
                plateformFeeImpact: 0,
                oosPolicy: 'none',
                variationCombo: [color, size],
                variationSpecific: true,
                entityId: entity._id,
                sku,
                new: true,
              })
              return true
            })
            return true
          })
          return true
        })

        updateProduct(product.provisionalId, {
          stock,
          stocked: true,
        })
      } else {
        updateProduct(product.provisionalId, {
          stock: [],
          stocked: false,
        })
      }
    }

    const onPressEnterInput = (id) => {
      if (document.getElementById(id)) {
        document.getElementById(id).focus()
        document.getElementById(id).select()
      }
    }

    if (!product.variations || !product.variations.length) {
      return (
        <Alert
          message={(
            <FormattedMessage
              id='productStockForm.alertMessage.noVariation'
              defaultMessage="Le produit n'a pas encore de variations pour créer le stock"
            />
          )}
          type='warning'
        />
      )
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div>
          <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            {!product._id && (
              <>
                <FormattedMessage id='productStockForm.productStocked' defaultMessage='Ce produit est stocké' />
                <Switch
                  defaultChecked={product.stock && product.stock.length}
                  style={{ margin: '0 0 0 10px' }}
                  onChange={(checked) => addStocks(checked)}
                />
              </>
            )}
            {product._id && (
              <Button onClick={addStocks}>
                Créer du stock pour les nouvelles variations/entitées
              </Button>
            )}
          </div>
        </div>
        {product.stock && product.stock.length ? (
          <StockTable
            onPressEnterInput={onPressEnterInput}
            product={product}
            updateProduct={updateProduct}
            entities={entities}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            allRestockThreshold={allRestockThreshold}
            setAllRestockThreshold={setAllRestockThreshold}
            allPriceImpact={allPriceImpact}
            setAllPriceImpact={setAllPriceImpact}
            allQuantity={allQuantity}
            setAllQuantity={setAllQuantity}
            allSupplier={allSupplier}
            setAllSupplier={setAllSupplier}
          />
        ) : null}
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/ProductStockForm')
    return null
  }
}

export default ProductStockForm

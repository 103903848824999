import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useIntl, injectIntl } from 'react-intl'
import { findIndex } from 'lodash'
import { notification } from 'antd'
import produce from 'immer'
import {
  CampaignEditSteps,
  CampaignEditParameters,
  CampaignCreationTheme,
  CampaignCreationMessage,
  CampaignCreationProducts,
  CampaignCreationActions,
  CampaignCreationEmailing,
  CampaignEditDashboard,
  EditCampaignHeader,
  MagicLinksSendingDrawer,
  MagiclinksSendButton,
} from '../../components'
import {
  fromProducts,
  fromCampaigns,
  fromCollaborators,
  fromShops,
  fromLocale,
  fromMagicLinks,
  fromOrders,
  fromBudgets,
  fromEntities,
} from '../../store/selectors'
import { CREATE_MAGICLINKS, MODIFY_MAGICLINKS } from '../../store/magicLinks/actions'
import { CREATE, UPDATE, SEND_TEST } from '../../store/campaigns/actions'
import { UPLOAD } from '../../store/images/actions'
import { successNotify } from '../../helpers/notification'
import { availableCampaignStatus } from '../../helpers/campaignStatus'
import {
  campaignHasEnoughProductsConstraint,
  campaignHasValidMessageConstraint,
  campaignHasValidActionsConstraint,
} from '../../helpers/campaignConstraints'
import { decodeQuery } from '../../helpers/filters'

const CampaignEditContainer = (props) => {
  try {
    const {
      magicLinks, // Required
      shop, // Required
      campaigns, // Required
      createCampaign, // Required
      updateCampaign, // Required
      history, // Required
      products, // Required
      user, // Required
      sendTestEmail, // Required
      orders, // Required
      collaborators, // Required
      locale,
      shopOwner,
      modifyMagicLinks,
      budget,
      createMagicLinks,
      uploadImage, // Required
      entities, // Required
    } = props

    const { location: { search } } = history

    const [currentCampaignId, setCurrentCampaignId] = useState(decodeQuery(search).id)

    const entity = entities.find((entityToFind) => (entityToFind._id === currentCampaignId) || entityToFind.main)

    // Récupération de la campagne courrante
    const currentCampaign = campaigns?.find((campaign) => campaign?._id === currentCampaignId)
    const currentCampaignWithDefaultValues = {
      ...currentCampaign,
      description: currentCampaign?.description ?? '',
      theme: {
        ...currentCampaign?.theme,
        mainColor: currentCampaign?.theme?.mainColor ?? shop?.settings?.theme?.mainColor,
        fontColor: currentCampaign?.theme?.fontColor ?? shop?.settings?.theme?.fontColor,
        logoName: currentCampaign?.theme?.logoName ?? shop?.settings?.logo?.substring(
          shop?.settings?.logo?.lastIndexOf('/') + 1,
          shop.settings?.logo?.indexOf('?'),
        ),
        logo: currentCampaign?.theme?.logo ?? shop?.settings?.logo,
      },
      message: {
        ...currentCampaign?.message,
        title: currentCampaign?.message?.title ?? '',
        content: currentCampaign?.message?.content ?? '',
        isEnabled: currentCampaign?.message?.isEnabled ?? false,
      },
    }

    const [needUpdateCampaign, setNeedUpdateCampaign] = useState(false)
    const [currentStep, setCurrentStep] = useState('dashboard')
    const [campaign, setCampaign] = useState(currentCampaignWithDefaultValues)
    const [stateCampaigns, setStateCampaigns] = useState([...campaigns])
    const [visibleMagicLinksDrawer, setVisibleMagicLinksDrawer] = useState(decodeQuery(search).sending)

    // Récupération des informations du créateur de la campagne courrante
    const currentCampaignCreator = collaborators?.filter((collaborator) => collaborator?._id === campaign?.userId)[0]
    // On autorise l'édition uniquement si admin panopli ou admin collabotateur ou propriétaire campagne
    const isDisplayOnly = !(
      user?.admin
      || user?.adminCollaborator
      || user?._id === shop.owner
      || user?._id === currentCampaignCreator?._id
    ) || campaign?.status === availableCampaignStatus?.archived

    const intl = useIntl()

    const saveCampaign = (campaignToSave) => {
      const isMessageValid = campaignHasValidMessageConstraint(campaignToSave, intl)
      const hasEnoughProducts = campaignHasEnoughProductsConstraint(campaignToSave, intl)
      const isActionValid = campaignHasValidActionsConstraint(campaignToSave, intl)

      if (isMessageValid && hasEnoughProducts && isActionValid) {
        updateCampaign({ ...campaignToSave })
        const key = `campaignSaved${Date.now()}`
        const onClick = () => {
          notification.close(key)
          setCurrentCampaignId(campaign._id)
          setVisibleMagicLinksDrawer(true)
        }
        const btn = campaign.status === 'active' && (
          <MagiclinksSendButton type='link' onClick={onClick} intl={intl} />
        )
        successNotify({
          intl,
          messageId: 'campaigns.edit.save.success',
          defaultMessage: 'La campagne a bien été modifiée.',
          key,
          btn,
        })
        setNeedUpdateCampaign(false)
      }
    }

    const duplicateCampaign = () => {
      createCampaign(
        {
          ...campaign,
          name: intl.formatMessage({
            id: 'copieOf',
            defaultMessage: 'Copie de ',
          }) + campaign.name,
        },
        successNotify({
          intl,
          messageId: 'campaign.duplicate.success',
          defaultMessage: 'Campagne dupliquée !',
        }),
      )
    }

    const setCampaignStatus = (status) => {
      const currentCampaignWithNewStatus = produce(campaign, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.status = status
      })
      setCampaign(currentCampaignWithNewStatus)
      const index = findIndex(stateCampaigns, (campaignToReplace) => campaignToReplace?._id === currentCampaignWithNewStatus?._id)
      if (index !== -1) {
        const newCampaigns = [...stateCampaigns]
        newCampaigns[index] = currentCampaignWithNewStatus
        setStateCampaigns(newCampaigns)
        setNeedUpdateCampaign(true)
        saveCampaign(currentCampaignWithNewStatus)
      }
    }

    useEffect(() => {
      if (currentCampaign) {
        setCampaign(currentCampaignWithDefaultValues)
      }
    }, [currentCampaign])

    useEffect(() => {
      if (campaigns?.length > 0) {
        setStateCampaigns([...campaigns])
      }
    }, [campaigns])

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [currentStep])

    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <MagicLinksSendingDrawer
          currentCampaignId={currentCampaignId}
          setCurrentCampaignId={setCurrentCampaignId}
          visible={visibleMagicLinksDrawer}
          setVisible={setVisibleMagicLinksDrawer}
          email={`${shop?.settings?.url}@panopli.co`}
          testMail={user.email}
          campaigns={stateCampaigns}
          collaborators={collaborators}
          budget={budget}
          magicLinks={magicLinks}
          sendTestEmail={sendTestEmail}
          createMagicLinks={createMagicLinks}
          locale={locale}
        />
        <EditCampaignHeader
          history={history}
          campaign={campaign}
          needUpdateCampaign={needUpdateCampaign}
          duplicateCampaign={duplicateCampaign}
          saveCampaign={() => { saveCampaign(campaign) }}
          setVisibleMagicLinksDrawer={setVisibleMagicLinksDrawer}
          setCurrentCampaignId={setCurrentCampaignId}
          setCampaignStatus={setCampaignStatus}
        />
        <CampaignEditSteps currentStep={currentStep} setCurrentStep={(e) => setCurrentStep(e?.key)} />
        {(() => {
          switch (currentStep) {
          case 'dashboard':
            return (
              <CampaignEditDashboard
                currentCampaignId={currentCampaignId}
                magicLinks={magicLinks}
                orders={orders}
                products={products}
                user={user}
                shopOwner={shopOwner}
                locale={locale}
                collaborators={collaborators}
                campaign={campaign}
                modifyMagicLinks={modifyMagicLinks}
                isDisabled={isDisplayOnly}
              />
            )
          case 'emailing':
            return (
              <CampaignCreationEmailing
                setCampaign={setCampaign}
                campaign={campaign}
                userEmail={user?.email}
                sendTestEmail={sendTestEmail}
                updateCampaign={updateCampaign}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                createdCampaignId={campaign?._id}
                isDisabled={isDisplayOnly}
              />
            )
          case 'products':
            return (
              <CampaignCreationProducts
                setCampaign={setCampaign}
                campaign={campaign}
                products={products}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                isDisabled={isDisplayOnly}
                columnWidth='33%'
                entity={entity}
                shop={shop}
                user={user}
              />
            )
          case 'actions':
            return (
              <CampaignCreationActions
                setCampaign={setCampaign}
                campaign={campaign}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                isDisabled={isDisplayOnly}
              />
            )
          case 'message':
            return (
              <CampaignCreationMessage
                setCampaign={setCampaign}
                campaign={campaign}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                isDisabled={isDisplayOnly}
              />
            )
          case 'theme':
            return (
              <CampaignCreationTheme
                setCampaign={setCampaign}
                campaign={campaign}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                createdCampaignId={campaign?._id}
                isDisabled={isDisplayOnly}
                uploadImage={uploadImage}
              />
            )
          case 'parameters':
            return (
              <CampaignEditParameters
                shop={shop}
                campaign={campaign}
                setCampaign={setCampaign}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                createdCampaignId={campaign?._id}
                isDisabled={isDisplayOnly}
              />
            )
          default:
            return (
              <CampaignEditParameters
                shop={shop}
                campaign={campaign}
                setCampaign={setCampaign}
                setNeedUpdateCampaign={setNeedUpdateCampaign}
                createdCampaignId={campaign?._id}
                isDisabled={isDisplayOnly}
              />
            )
          }
        })()}
      </div>
    )
  } catch (e) {
    console.log('error /containers/CompaignEditContainer', e)
    return null
  }
}

export default connect((state) => ({
  campaigns: fromCampaigns.getCampaigns(state),
  products: fromProducts.allStockedProducts(state),
  orders: fromOrders.getOrders(state),
  collaborators: fromCollaborators.getCollaborators(state),
  shop: fromShops.getShop(state),
  magicLinks: fromMagicLinks.getMagicLinks(state),
  budget: fromBudgets.getBudget(state),
  shopOwner: fromShops.getOwner(state),
  locale: fromLocale.getLocale(state),
  entities: fromEntities.getEntities(state),
}), (dispatch) => bindActionCreators({
  createCampaign: (payload, callback) => ({
    type: CREATE,
    payload,
    callback,
  }),
  updateCampaign: (payload, callback) => ({
    type: UPDATE,
    payload,
    callback,
  }),
  createMagicLinks: (payload) => ({
    type: CREATE_MAGICLINKS,
    payload,
  }),
  modifyMagicLinks: (payload, callback) => ({
    type: MODIFY_MAGICLINKS,
    payload,
    callback,
  }),
  sendTestEmail: (payload) => ({
    type: SEND_TEST,
    payload,
  }),
  uploadImage: (payload, callback) => ({
    type: UPLOAD,
    payload,
    callback,
  }),
}, dispatch))(injectIntl(CampaignEditContainer))

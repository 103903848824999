import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Radio } from 'antd'

const RadioGroup = Radio.Group

const BillingOption = () => (
  <Radio
    value='billing'
    checked
  >
    <FormattedMessage
      id='checkoutForm.BillingPayment'
      defaultMessage='Régler cette commande avec mon paiement mensuel'
    />
  </Radio>
)

const TransactionTypes = (props) => {
  try {
    const {
      onChangeTransactionType,
      enableMonthlyInvoicing,
      transactionType,
      isAdmin,
    } = props
    return (
      <div>
        <RadioGroup
          onChange={(e) => onChangeTransactionType(e)}
          value={transactionType}
        >
          <Radio
            value='payment'
          >
            <FormattedMessage
              id='checkoutForm.immediatePayment'
              defaultMessage='Paiement immédiat'
            />
          </Radio>
          <BillingOption
            enableMonthlyInvoicing={enableMonthlyInvoicing}
            onChangeTransactionType={onChangeTransactionType}
          />
          {isAdmin ? (
            <Radio
              value='admin'
            >
              <FormattedMessage
                id='checkoutForm.adminMode'
                defaultMessage='Mode Admin - la commande ne sera pas facturée'
              />
            </Radio>
          ) : null }
        </RadioGroup>
      </div>
    )
  } catch (error) {
    console.log('TransactionTypes - render()', { error })
    return null
  }
}

export default TransactionTypes

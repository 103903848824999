// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import React from 'react'
import {
  put, call,
  takeEvery, select,
} from 'redux-saga/effects'
import { notification } from 'antd'
import { FormattedMessage } from 'react-intl'
import * as actions from './actions'
import { fromUsers } from '../selectors'

export function* getComments(api) {
  try {
    const result = yield call([api, api.get], '/comments', {
      headers: {
        mode: 'cors',
        authorization: yield select(fromUsers.getAuth),
      },
    })
    yield put({
      type: actions.RECEIVED_COMMENTS,
      payload: {
        data: result.comments,
      },
    })
    return true
  } catch (e) {
    console.log('getMembers - error', e, e.message)
    return false
  }
}

export function* postComment(api, action) {
  try {
    const user = yield select(fromUsers.getUser)
    yield call([api, api.put], '/comments', {
      ...action.payload,
      idUser: user._id,
      author: (user.additionalInfos && user.additionalInfos.firstName) || 'Anonyme',
    }, {
      headers: {
        mode: 'cors',
        authorization: yield select(fromUsers.getAuth),
      },
    })

    yield call(getComments, api)
    return 1
  } catch (e) {
    console.log('ModifyBaseProduct - error', e)
    notification.open({
      message: (<FormattedMessage id='store.comments.notSent' defaultMessage="Le commentaire n'a pas été posté" />),
      placement: 'bottomRight',
      duration: 8,
    })
    return -1
  }
}

export function* deleteComment(api, action) {
  try {
    yield call([api, api.delete], '/comments', {
      headers: {
        mode: 'cors',
        authorization: yield select(fromUsers.getAuth),
        ...action.payload,
      },
    })

    yield call(getComments, api)
    return 1
  } catch (e) {
    console.log('ModifyBaseProduct - error', e)
    notification.open({
      message: (<FormattedMessage id='store.comments.notSent' defaultMessage="Le commentaire n'a pas été posté" />),
      placement: 'bottomRight',
      duration: 8,
    })
    return -1
  }
}

export default function* ({ api }) {
  yield takeEvery(actions.GET_COMMENTS, getComments, api)
  yield takeEvery(actions.POST_COMMENT, postComment, api)
  yield takeEvery(actions.DELETE_COMMENT, deleteComment, api)
}

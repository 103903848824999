import React from 'react'
import {
  Switch,
  Select,
} from 'antd'
import { FormattedMessage } from 'react-intl'

import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

import { ColorButton } from '../../atoms'

const { Option } = Select

const HiddenCustomListing = (props) => {
  try {
    const {
      newCustom, setNewCustom, colorState, setColorState,
    } = props
    const { listing } = newCustom
    const {
      alignTitle, detailsOnHover, hideDetailsOnMobile, pagination, priceColor,
    } = listing
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage
            id='custom.listing'
            defaultMessage='Listing'
          />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.listingAlignTitle'
                defaultMessage='Alignement des détails'
              />
            </div>
            <Select
              placeholder={<FormattedMessage id='alignment' defaultMessage='Alignement' />}
              value={alignTitle}
              style={{ width: '100px' }}
              onChange={(value) => setNewCustom({ ...newCustom, listing: { ...listing, alignTitle: value } })}
            >
              <Option key='1' value='left'>
                <FormattedMessage id='left' defaultMessage='Gauche' />
              </Option>
              <Option key='2' value='center'>
                <FormattedMessage id='center' defaultMessage='Centre' />
              </Option>
              <Option key='3' value='right'>
                <FormattedMessage id='right' defaultMessage='Droite' />
              </Option>
            </Select>
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.listingDetailsOnHover'
                defaultMessage='Afficher les détails au survol'
              />
            </div>
            <Switch
              checked={detailsOnHover}
              onChange={(value) => setNewCustom({ ...newCustom, listing: { ...listing, detailsOnHover: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.listingHideDetailsOnMobile'
                defaultMessage='Masquer les détails sur mobile'
              />
            </div>
            <Switch
              checked={hideDetailsOnMobile}
              onChange={(value) => setNewCustom({ ...newCustom, listing: { ...listing, hideDetailsOnMobile: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.listingPagination'
                defaultMessage='Activer la pagination'
              />
            </div>
            <Switch
              checked={pagination}
              onChange={(value) => setNewCustom({ ...newCustom, listing: { ...listing, pagination: value } })}
            />
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.listingPriceColor'
                defaultMessage='Couleur de police pour les prix'
              />
            </div>
            <ColorButton
              color={priceColor}
              onClick={() => setColorState({
                ...colorState,
                currentColor: priceColor,
                groupName: 'listing',
                propName: 'priceColor',
              })}
            />
          </div>
          <div style={columnStyle} />
          <div style={columnStyle} />
          <div style={columnStyle} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecule/HiddenCustomListing error:', e)
    return null
  }
}

export default HiddenCustomListing

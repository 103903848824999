import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { getProductMainImage } from '../../../helpers/magicLinks'
import { neutralColor } from '../../../config/theme'

const ProductAvatar = (props) => {
  const {
    product, // Required
    style,
  } = props

  const [isProductImageBroken, setIsProductImageBroken] = useState(false)

  const handleOnImgError = (event) => {
    setIsProductImageBroken(true)
    // eslint-disable-next-line no-param-reassign
    event.target.style.display = 'none'
  }

  return (
    <div>
      {isProductImageBroken ? (
        <FontAwesomeIcon
          icon={faImage}
          style={{
            height: '40px',
            width: '40px',
          }}
        />
      ) : (
        <img
          src={getProductMainImage(product)?.src}
          alt={product.name}
          style={{
            border: `1px solid ${neutralColor[1]}`,
            objectFit: 'cover',
            borderRadius: 8,
            height: '40px',
            width: '40px',
            ...style,
          }}
          onError={handleOnImgError}
        />
      )}
    </div>
  )
}

export default ProductAvatar

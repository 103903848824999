import React from 'react'
import { Tag } from 'antd'
import { FormattedMessage } from 'react-intl'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import copyToClipboard from '../../../helpers/copyToClipboard'
import theme from '../../../config/theme'

const CopyTag = (props) => {
  const {
    formattedMessageId, // Required
    defaultFormattedMessage, // Required
    valueToCopy, // Required
  } = props

  return (
    <Tag
      style={{
        cursor: 'pointer',
        padding: '9px 8px',
        backgroundColor: theme?.white,
        border: `1px solid ${theme?.neutralColor[3]}`,
        borderRadius: '8px',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        fontSize: '14px',
        fontWeight: '500',
        color: theme?.character?.title,
      }}
      className='templateVariables'
      onClick={(e) => copyToClipboard(e, valueToCopy)}
    >
      <FormattedMessage
        id={formattedMessageId}
        defaultMessage={defaultFormattedMessage}
      />
      <FontAwesomeIcon icon={faCopy} style={{ margin: '0 0 0 5px', color: theme?.character?.disabledPlaceholder }} />
    </Tag>
  )
}

export default CopyTag

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Col,
  Row,
  Input,
  Button,
  Icon,
  Checkbox,
  Modal,
  Select,
  message,
} from 'antd'
import { FormattedMessage } from 'react-intl'

import { Logo } from '../../components'
import { FETCH_FORCE_USER, MODIFY_USER_ENTITY } from '../../store/users/actions'
import { fromUsers, fromLocale } from '../../store/selectors'
import { setLocale as setLocaleAction } from '../../store/locale/actions'
import { CGU, CGV, privacyPolicy } from '../../helpers/legals'

const { Option } = Select

const LanguageSwitch = ({ locale, setLocale }) => (
  <Select
    showSearch
    style={{
      width: '60px',
      border: 'none',
      position: 'absolute',
      top: '20px',
      right: '20px',
    }}
    placeholder={<FormattedMessage id='menu.language' defaultMessage='Langue' />}
    value={locale}
    onChange={(value) => setLocale(value)}
  >
    <Option value='fr'>
      🇫🇷
    </Option>
    <Option value='en'>
      🇬🇧
    </Option>
  </Select>
)

const SignUpUser = (props) => {
  try {
    const {
      userId,
      history,
      fetchForceUser,
      user,
      modifyUser,
      error,
      locale = 'fr',
      setLocale,
    } = props

    const { _id } = user

    const [newUser, setNewUser] = useState({})
    const [samePassword, setSamePassword] = useState(false)
    const [legalsType, setLegalsType] = useState('CGV')
    const [isTermsVisible, setIsTermsVisible] = useState(false)
    const [isAgreed, setIsAgreed] = useState(false)

    const {
      additionalInfos,
      additionalInfos: { firstName, lastName } = {},
      password,
      shop,
    } = newUser

    if (!_id || (_id !== userId && !error)) {
      fetchForceUser(userId)
    }

    const type = { CGU, CGV, privacyPolicy }

    const disabled = !(firstName && lastName && password && samePassword && isAgreed)

    if (error) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <FormattedMessage id='nonExistantUser' defaultMessage="Cet utilisateur n'existe pas" />
        </div>
      )
    }

    return (
      <>
        <LanguageSwitch locale={locale} setLocale={setLocale} />
        <Modal
          visible={isTermsVisible}
          onCancel={() => setIsTermsVisible(false)}
          footer={[
            <Button onClick={() => setIsTermsVisible(false)}>
              OK
            </Button>,
          ]}
          width='calc(100vw - 65px)'
        >
          {type[legalsType][locale]}
        </Modal>
        <Row style={{
          display: 'flex',
          width: '100%',
        }}
        >
          <Col
            span={8}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              margin: '0 20px 0 0',
            }}
          >
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap',
            }}
            >
              <Logo type='blue-panopli-bird' height='75px' />
              {shop?.logoUrl ? (
                <>
                  <Icon type='close' style={{ fontSize: '20px' }} />
                  <img src={shop?.logoUrl} alt='logoShop' style={{ height: '75px' }} />
                </>
              ) : null}
            </div>
            {shop && (
              <FormattedMessage
                id='signUpUser.signupCall'
                defaultMessage='Vous avez été invité à rejoindre {shop}'
                values={{ shop: shop?.name }}
              />
            )}
          </Col>
          <Col
            span={16}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              borderLeft: '1px solid #ddd',
              paddingLeft: 30,
            }}
          >
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{
                margin: '0px 5px 10px 0px', flex: '1 3',
              }}
              >
                <FormattedMessage id='firstName' defaultMessage='Prénom' />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input
                    defaultValue={firstName}
                    placeholder='Luke'
                    onChange={(e) => {
                      setNewUser({
                        ...newUser,
                        additionalInfos: {
                          ...additionalInfos,
                          firstName: e.target.value,
                        },
                      })
                    }}
                    style={{ marginBottom: '10px' }}
                  />
                </div>
              </div>
              <div style={{
                margin: '0px 5px 10px 0px', flex: '1 3',
              }}
              >
                <FormattedMessage id='lastName' defaultMessage='Nom' />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input
                    defaultValue={lastName}
                    placeholder='Skywalker'
                    onChange={(e) => {
                      setNewUser({
                        ...newUser,
                        additionalInfos: {
                          ...additionalInfos,
                          lastName: e.target.value,
                        },
                      })
                    }}
                    style={{ marginBottom: '10px' }}
                  />
                </div>
              </div>
              <div style={{
                margin: '0px 5px 10px 0px', flex: '1 3',
              }}
              >
                <FormattedMessage id='phone' defaultMessage='Téléphone' />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input
                    defaultValue={newUser?.phone}
                    placeholder='+33'
                    onChange={(e) => {
                      setNewUser({
                        ...newUser,
                        phone: e.target.value,
                      })
                    }}
                    style={{ marginBottom: '10px' }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{
                margin: '0px 5px 10px 0px', flex: '1 2',
              }}
              >
                <FormattedMessage
                  id='password'
                  defaultMessage='Mot de passe'
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input.Password
                    defaultValue={newUser?.password}
                    onChange={(e) => {
                      setNewUser({
                        ...newUser,
                        password: e.target.value,
                      })
                    }}
                    style={{ marginBottom: '10px' }}
                  />
                </div>
              </div>
              <div style={{
                margin: '0px 5px 10px 0px', flex: '1 2',
              }}
              >
                <FormattedMessage
                  id='signUpUser.confirmPassword'
                  defaultMessage='Confirmation de mot de passe'
                />
                <Input.Password
                  onChange={(e) => {
                    if (e.target.value === password) {
                      setSamePassword(true)
                    } else {
                      setSamePassword(false)
                    }
                  }}
                  style={{ marginBottom: '10px' }}
                />
              </div>
            </div>
            {!samePassword && password && (
              <span style={{ color: '#FF0D3D' }}>
                <Icon type='warning' style={{ margin: '0 0 20px 0' }} />
                <FormattedMessage
                  id='signUpUser.errorSamePassword'
                  defaultMessage='Le mot de passe est différent'
                />
              </span>
            )}
            <div style={{
              display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '20px',
            }}
            >
              <Checkbox checked={isAgreed} onChange={() => setIsAgreed(!isAgreed)} />
              <span style={{ display: 'inline' }}>
                <FormattedMessage
                  id='legals.agreement'
                  defaultMessage="J'accepte les {CGU}, les {CGV} et la {privacyPolicy}"
                  values={{
                    CGU: (
                      <Button
                        type='link'
                        onClick={() => {
                          setLegalsType('CGU')
                          setIsTermsVisible(true)
                        }}
                        style={{ padding: 0, height: '22px' }}
                      >
                        <FormattedMessage
                          id='termsOfServices'
                          defaultMessage="Conditions Générales d'Utilisation"
                        />
                      </Button>
                    ),
                    CGV: (
                      <Button
                        type='link'
                        onClick={() => {
                          setLegalsType('CGV')
                          setIsTermsVisible(true)
                        }}
                        style={{ padding: 0, height: '22px' }}
                      >
                        <FormattedMessage
                          id='termsOfSales'
                          defaultMessage='Conditions Générales de Vente'
                        />
                      </Button>
                    ),
                    privacyPolicy: (
                      <Button
                        type='link'
                        onClick={() => {
                          setLegalsType('privacyPolicy')
                          setIsTermsVisible(true)
                        }}
                        style={{ padding: 0, height: '22px' }}
                      >
                        <FormattedMessage
                          id='privacyPolicy'
                          defaultMessage='Politique de Confidentialité'
                        />
                      </Button>
                    ),
                  }}
                />
              </span>
            </div>
            <Button
              type='primary'
              disabled={disabled}
              onClick={() => {
                modifyUser({
                  user: {
                    ...user,
                    ...newUser,
                  },
                }, () => {
                  history.push('/')
                  message.success(<FormattedMessage id='accountCreated' defaultMessage='Compte créé !' />)
                })
              }}
            >
              <FormattedMessage
                id='signUpUser.confirm'
                defaultMessage={'C\'est parti ! 🚀'}
              />
            </Button>
          </Col>
        </Row>
      </>
    )
  } catch (e) {
    console.log(e, 'error SignUpUser')
    return false
  }
}

export default connect((state) => ({
  user: fromUsers.getTempUser(state),
  error: fromUsers.getErrorEntity(state),
  locale: fromLocale.getLocale(state),
}), (dispatch) => bindActionCreators({
  fetchForceUser: (id) => ({
    type: FETCH_FORCE_USER,
    id,
  }),
  modifyUser: (payload, callback) => ({
    type: MODIFY_USER_ENTITY,
    payload: {
      ...payload,
      callback,
    },
  }),
  setLocale: (locale) => dispatch(setLocaleAction(locale)),
}, dispatch))(SignUpUser)

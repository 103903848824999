/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FormattedButton = ({
  style,
  messageId, // Required
  defaultMessage, // Required,
  onClickFunction = null,
  disabled = false,
  textStyle = { fontWeight: 400 },
  icon = null,
  type = null,
  ...props
}) => (
  <Button
    {...(onClickFunction && { onClick: onClickFunction })} // Spread onClick attribute to avoid onClick=null.
    style={style}
    disabled={disabled}
    {...props} // Spread props because in some cases, some components send implicitly props to child components like Popover from antd.
    type={type}
  >
    {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: '10px' }} />}
    {messageId && defaultMessage && (
      <span style={textStyle}>
        <FormattedMessage
          id={messageId}
          defaultMessage={defaultMessage}
        />
      </span>
    )}
  </Button>
)

export default FormattedButton

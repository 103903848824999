import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Table, Spin, Button } from 'antd'
import { compact } from 'lodash'
import {
  SubscriptionTable, PDFCreatorInvoice, Placeholder,
} from '..'
import invoicesStatus from '../../../helpers/invoiceHelpers'
import { getColumnSearchProps, filterData } from '../../../helpers/filters'
import { trimRecordForInvoiceTable } from '../../../helpers/trim'
import { extractParams, writeUrl } from '../../../helpers/urlHelpers'

const InvoiceTable = ({
  invoices,
  loading,
  error,
  history,
  upComingInvoice,
  newInvoiceItems,
  isAdmin,
  allProducts,
  allOrders,
  locale,
}) => {
  const [filters, setFilters] = useState({ ...extractParams(history.location.search) })
  const [visible, setVisible] = useState(false)

  const filtering = (object) => {
    setFilters({
      filters: {
        ...filters,
        ...object,
      },
    }, () => history.push(writeUrl({
      ...filters,
      ...object,
    })))
  }

  const hasFilters = () => !!Object.values(filters).filter(Boolean).length

  const resetFiltering = () => {
    setFilters({
      filters: {},
    }, () => history.push(writeUrl({})))
  }

  // eslint-disable-next-line no-unused-vars
  const [extra, setExtra] = useState(compact([
    hasFilters() ? (
      {
        content: (
          <Button key='reset' onClick={resetFiltering}>
            <FormattedMessage id='resetFilters' defaultMessage='Réinitialiser les filtres' />
          </Button>
        ),
        key: 'reset',
      }
    ) : null,
    {
      content: <SubscriptionTable
        language={locale}
        allProducts={allProducts}
        allOrders={allOrders}
        upComingInvoice={upComingInvoice}
        invoiceItems={newInvoiceItems}
        key='1'
      />,
      key: 'SubscriptionTable',
    },
    isAdmin ? ({
      content: (
        <Button onClick={() => setVisible(true)}>
          Générer une facture
        </Button>
      ),
      key: 'ProformaDrawer',
    }) : null,
  ]))
  try {
    if (error) return <div> Error placeholder</div>
    if (loading) return <Spin size='large' />
    if (!invoices.length) return <Placeholder />
    const columns = [{
      title: <FormattedMessage id='invoiceNumber' defaultMessage='Numéro de facture' />,
      dataIndex: 'formatted.id',
      key: 'id',
      ...getColumnSearchProps('id', filtering),
    }, {
      title: <FormattedMessage id='date' defaultMessage='Date' />,
      dataIndex: 'formatted.date',
      key: 'date',
    }, {
      title: <FormattedMessage id='type' defaultMessage='Type' />,
      dataIndex: 'formatted.type',
      key: 'type',
      ...getColumnSearchProps('type', filtering),
    }, {
      title: <FormattedMessage id='status' defaultMessage='Statut' />,
      dataIndex: 'formatted.status',
      key: 'status',
      ...getColumnSearchProps('status', filtering, {
        filterDropdown: () => (
          <div
            className='orderId-filter-dropdown container'
            style={{
              textAlign: 'center',
              padding: '5px',
            }}
          >
            <Button onClick={() => filtering({ status: locale === 'fr' ? 'Brouillon' : 'Draft' })}>
              <FormattedMessage id='draft' defaultMessage='Brouillon' />
            </Button>
            <Button style={{ margin: '0 5px' }} onClick={() => filtering({ status: locale === 'fr' ? 'Ouvert' : 'Open' })}>
              <FormattedMessage id='opened' defaultMessage='Ouvert' />
            </Button>
            <Button onClick={() => filtering({ status: locale === 'fr' ? 'Payée' : 'Paid' })}>
              <FormattedMessage id='paid' defaultMessage='Payée' />
            </Button>
            <Button style={{ marginLeft: '5px' }} onClick={() => filtering({ status: locale === 'fr' ? 'Erreur' : 'Error' })}>
              <FormattedMessage id='error' defaultMessage='Erreur' />
            </Button>
          </div>
        ),
        render: (status) => invoicesStatus(status),
      }),
    }, {
      title: <FormattedMessage id='total' defaultMessage='Total' />,
      dataIndex: 'formatted.total',
      key: 'total',
      render: (text, record) => (
        <span>
          {`${record.total / 100} €`}
        </span>
      ),
    }, {
      title: <FormattedMessage id='currency' defaultMessage='Devise' />,
      dataIndex: 'formatted.currency',
      key: 'currency',
      render: (text, record) => (
        <span style={{ textTransform: 'uppercase' }}>
          {record.currency}
        </span>
      ),
    }, {
      title: <FormattedMessage id='action' defaultMessage='Action' />,
      key: 'action',
      render: (text, record) => {
        if (record.status === 'draft') {
          return null
        }
        return (
          <span>
            {record.status === 'paid' ? null
              : (
                // TODO wrong atoms -> ant label?
                <span>
                  <a
                    href={record.action.hosted_invoice_url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FormattedMessage id='pay' defaultMessage='Payer' />
                  </a>
                  <span className='ant-divider' />
                </span>
              )}
            <a href={record.action.invoice_pdf} className='ant-dropdown-link'>
              <FormattedMessage id='download' defaultMessage='Télécharger' />
            </a>
          </span>
        )
      },
    }]
    const data = invoices.map((invoice) => trimRecordForInvoiceTable(invoice, locale))
    const filteredData = filterData(data, filters)
    return (
      <>
        <div>
          {extra.map((ex) => <span key={ex.key} style={{ margin: '0 4px' }}>{ex.content}</span>)}
        </div>
        <Table
          columns={columns}
          dataSource={filteredData}
        />
        <PDFCreatorInvoice
          visible={visible}
          setVisible={setVisible}
          // extra={extra}
          setExtra={setExtra}
          locale={locale}
        />
      </>
    )
  } catch (e) {
    console.log('account - StyledInvoices', e)
    return null
  }
}

export default InvoiceTable

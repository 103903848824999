import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Icon } from 'antd'
// TODO should be products
import { FETCH_FORCE } from '../../store/orders/actions'

export default connect(
  null,
  (dispatch) => bindActionCreators({
    refresh: () => ({
      type: FETCH_FORCE,
      payload: {
        force: true,
      },
    }),
  }, dispatch),
)(({
  refresh,
}) => (
  <Button
    onClick={refresh}
    key='refresh'
    style={{ margin: '0 5px' }}
  >
    <Icon type='reload' />
  </Button>
))

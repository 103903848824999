/* eslint-disable camelcase */
import preOrder from '../preOrder'

const getSizes = (product) => {
  try {
    return product.variations.filter((v) => v.type === 'size')
  } catch (error) {
    console.log('Error in getSize', error)
    return []
  }
}

const getColors = (product) => {
  try {
    return product.variations.filter((v) => v.type === 'color')
  } catch (error) {
    console.log('Error in getColors', error)
    return []
  }
}

const getInfos = (product) => {
  let detail = ''
  try {
    const info = product.baseProduct.baseProductInfo
    detail = info && info.detail && info.detail.fr ? info.detail.fr : product.baseProduct.subCategory.fr
  } catch (e) {
    detail = ''
  }
  return detail
}

const getSimilarProducts = (product, products) => {
  try {
    return products
      .filter((comparator) => {
        if (
          comparator.baseProduct.category.en[0] === product.baseProduct.category.en[0]
          && comparator.baseProduct.subCategory.en === product.baseProduct.subCategory.en
        ) {
          return true
        }
        return false
      })
      .slice(0, 6)
  } catch (error) {
    console.log(error)
    return products
  }
}

const formatPrice = (value) => {
  try {
    let result = (Math.round(value * 100) / 100).toFixed(2)
    if (result % 1 === 0) {
      result = parseInt(result, 10)
    }
    return result
  } catch (error) {
    console.log('helpers/products - formatPrice', { error })
    return value
  }
}

const getZone = (product) => {
  try {
    if (product.custom && !product.favPZ) {
      return 'front'
    }
    if (product.favPZ && product.design_ids.map((d) => d.position).includes(product.favPZ)) {
      return product.favPZ
    }
    if (product.favPZ && product.specialItemProduct) {
      return product.favPZ
    }
    return product.design_ids[0] && product.design_ids[0].position
  } catch (error) {
    console.log('getZone', error)
    return 'front'
  }
}

const getItemQuantityInCart = (cart, current_variations, product) => {
  try {
    let qty
    if (cart) {
      cart.map((cartItem) => {
        if (
          cartItem._id === product._id
          && current_variations.color.name === cartItem.variations.color.name
          && current_variations.size.name === cartItem.variations.size.name
        ) {
          qty = cartItem.quantity
        }
        return null
      })
    }
    return qty || 0
  } catch (error) {
    console.log(error)
    return 0
  }
}

const checkVariationCombo = (product, current_variations) => {
  try {
    let result = false
    if (product.variationCombo) {
      const comboVariation = product.variationCombo
      if (
        comboVariation.length === 2
        && comboVariation.find((variation) => variation === current_variations.color.name)
        && comboVariation.find((variation) => variation === current_variations.size.name)
      ) {
        result = true
      } else if (
        comboVariation.length === 1
        && (comboVariation.find((variation) => variation === current_variations.color.name)
          || comboVariation.find((variation) => variation === current_variations.size.name))
      ) {
        result = true
      }
    }
    return result
  } catch (e) {
    console.log(e)
    return false
  }
}

const checkQty = (availableStock, quantity, inCart) => {
  try {
    let result = 'ok'
    // console.log(availableStock)
    if (availableStock.qty - inCart <= 0 || availableStock.oosFlag) {
      result = 'oos'
    } else if (availableStock.qty - inCart >= 1 && availableStock.qty - inCart < quantity) {
      // NOTE the amount we want to add is too much for what remains in the stock
      result = 'notEnough'
    } else if (availableStock.qty - inCart >= 1
      && availableStock.qty - inCart < (availableStock.warningThreshold || 5)) {
      // NOTE there is less than 5 items left in stock
      result = 'warning'
    }
    return result
  } catch (e) {
    console.log(e)
    return null
  }
}

const checkStatus = (product, current_variations, quantity, cart, clientCustomization) => {
  try {
    if (preOrder(product) === 'soon') {
      return {
        status: 'preOrder_soon',
      }
    }
    if (preOrder(product) === 'done') {
      return {
        status: 'preOrder_done',
      }
    }
    if (preOrder(product) === 'live') {
      return {
        status: 'preOrder_live',
      }
    }
    if (clientCustomization && clientCustomization.filter((c) => !c.value).length) {
      return {
        status: 'missingCusto',
      }
    }
    if (product.baseProduct.oosFlag) {
      return {
        available: false,
        status: 'oos',
      }
    } if (current_variations.size && current_variations.color) {
      let stockSelected
      let result = 'ok'
      const inCart = getItemQuantityInCart(cart, current_variations, product)
      if (product.stock) {
        const { stock } = product
        stock.map((v) => {
          if (v.variationSpecific && v.oosPolicy === 'none') {
            if (checkVariationCombo(v, current_variations)) {
              result = checkQty(v, quantity, inCart)
              stockSelected = v
            } else {
              result = result || ''
            }
          } else if (v.oosPolicy === 'none' && !v.variationSpecific) {
            result = checkQty(v, quantity, inCart)
            stockSelected = v
          }
          return null
        })
      } else if (product.baseProduct && product.baseProduct.variations) {
        product.baseProduct.variations.map((variation) => {
          // WARNING - cannot read property 'name' of undefined
          if (
            variation.name === current_variations.color.name
            || variation.color === current_variations.size.name
          ) {
            if (variation.oosFlag) {
              result = 'oos'
            }
          }
          return null
        })
      }
      return {
        status: result,
        stockSelected,
      }
    } if (product.custom && product.stock && product.stock.length) {
      let variationSelected
      let result = 'ok'
      const inCart = getItemQuantityInCart(cart, current_variations)
      const { stock } = product
      stock.map((v) => {
        if (v.variationCombo && v.oosPolicy === 'none') {
          if (checkVariationCombo(v, current_variations)) {
            result = checkQty(v, quantity, inCart)
            variationSelected = v
          } else {
            result = result || ''
          }
        } else if (v.oosPolicy === 'none' && !v.variationCombo) {
          result = checkQty(v, quantity, inCart)
          variationSelected = v
        }
        return null
      })
      return {
        status: result,
        currentVariationSelected: variationSelected,
      }
    }
    return {
      currentVariationSelected: null,
      status: 'ok',
    }
  } catch (e) {
    console.log(e)
    return {
      currentVariationSelected: null,
      status: 'ko',
    }
  }
}

const getUnitPrice = (item, payw) => {
  try {
    return (
      parseFloat(item.baseProduct.unitPrice)
      + parseFloat(item.pricing.customizationPrice || 0)
      + parseFloat(payw || 0)
    )
  } catch (e) {
    return false
  }
}

const generateCartItem = (product, size, color, quantity, clientCustomization) => {
  try {
    return {
      ...product,
      quantity,
      variations: {
        color,
        size,
      },
      clientCustomization,
      uuid: Math.random()
        .toString(36)
        .substring(7),
    }
  } catch (error) {
    console.log('helpers - generateCartItem', error)
    return null
  }
}

const addProductToCart = (
  product,
  size,
  color,
  quantity,
  clientCustomization,
  addToCart,
  cartOpen,
) => {
  try {
    const toAdd = generateCartItem(product, size, color, quantity, clientCustomization)
    addToCart(toAdd)
    cartOpen()
  } catch (error) {
    console.log(error, 'error addProductToCart')
  }
}

/**
 * true if category exists in given locale, false otherwise
 *
 * @param {Product} product
 * @param {string} locale
 *
 * @returns {boolean}
 */
const isLocaleDefinedInProductCategory = (product, locale) => {
  if (typeof locale !== 'string') {
    console.log('expected locale to be string, %o given', locale)
    return false
  }
  return product?.baseProduct?.category && locale in product?.baseProduct?.category
}

/**
 * @param {Array.<object>} relatedStocks les stocks qu'on souhaite analyser
 * @returns {boolean} vrai si tous les stocks sont à 0
 */
const isOOS = (relatedStocks) => relatedStocks.every((stock) => stock.qty <= 0)

export {
  addProductToCart,
  checkStatus,
  formatPrice,
  generateCartItem,
  getColors,
  getInfos,
  getSimilarProducts,
  getSizes,
  getUnitPrice,
  getZone,
  isLocaleDefinedInProductCategory,
  isOOS,
}

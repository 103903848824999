import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Tabs, Button } from 'antd'
import { FormattedMessage } from 'react-intl'

import {
  fromUsers,
  fromShops,
  fromLocale,
  fromCollaborators,
  fromCampaigns,
  fromMagicLinks,
  fromBudgets,
  fromProducts,
  fromOrders,
} from '../../store/selectors'
import { CREATE_MAGICLINKS, MODIFY_MAGICLINKS } from '../../store/magicLinks/actions'
import { CREATE, REMOVE_DRAFT_CAMPAIGN, SEND_TEST } from '../../store/campaigns/actions'
import { ADD } from '../../store/budgets/actions'

import {
  MagicLinksDashboard,
  CampaignAdmin,
  CampaignList,
  MagicLinksTracking,
  MagicLinksSendingDrawer,
  CampaignCreationModal,
  Loader,
  Placeholder,
} from '../../components'

import { decodeQuery } from '../../helpers/filters'
import { neutralColor } from '../../config/theme'

const { TabPane } = Tabs

const filterState = {
  search: undefined,
  status: undefined,
  sort: undefined,
  type: undefined,
  adminsIds: [],
}

const MagicLinksContainer = (props) => {
  const {
    user,
    shop,
    shopOwner,
    history,
    locale,
    collaborators,
    campaigns,
    loading,
    magicLinks,
    budgets,
    budget,
    products,
    orders,
    createCampaign,
    createMagicLinks,
    modifyMagicLinks,
    addCredit,
    visibleMagicLinksDrawer,
    setVisibleMagicLinksDrawer,
    sendTestEmail,
    removeDraftCampaign,
  } = props

  const { location: { search } } = history

  const defaultActiveKey = decodeQuery(search).tabs || 'dashboard'

  const [filters, setFiltersState] = useState(filterState)
  const [activeKey, setActiveKey] = useState(defaultActiveKey)
  const [visibleCampaignCreationModal, setVisibleCampaignCreationModal] = useState(false)
  const [currentCampaignId, setCurrentCampaignId] = useState()

  // Si l'on charge encore les MLs
  if (loading && !magicLinks.length) {
    return (
      <div style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <Loader />
      </div>
    )
  }

  return (
    <div style={{ marginTop: '24px' }}>
      <MagicLinksSendingDrawer
        currentCampaignId={currentCampaignId}
        setCurrentCampaignId={setCurrentCampaignId}
        visible={visibleMagicLinksDrawer}
        setVisible={setVisibleMagicLinksDrawer}
        email={`${shop?.settings?.url}@panopli.co`}
        testMail={user.email}
        budget={budget}
        campaigns={campaigns}
        magicLinks={magicLinks}
        sendTestEmail={sendTestEmail}
        createMagicLinks={createMagicLinks}
        locale={locale}
        campaignSelection
      />
      <CampaignCreationModal
        visibleModal={visibleCampaignCreationModal}
        setVisibleModal={setVisibleCampaignCreationModal}
        products={products}
        createCampaign={createCampaign}
        shop={shop}
      />
      {!campaigns?.length ? ( // S'il n'y a pas encore de campagne créée
        <Placeholder>
          <Button
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: '500',
              fontSize: '14px',
              height: '40px',
              gap: '5px',
            }}
            type='primary'
            onClick={() => history.push('/campaign/create')}
          >
            <FormattedMessage
              id='campaigns.createCampaign'
              defaultMessage='Créer une campagne'
            />
          </Button>
        </Placeholder>
      ) : (
        <Tabs
          activeKey={activeKey}
          onChange={(key) => {
            history.push(`/magicLinks?tabs=${key}`)
            setActiveKey(key)
          }}
        >
          <TabPane
            tab={(
              <span style={{ fontSize: '14px', fontWeight: '400', color: neutralColor[12] }}>
                <FormattedMessage
                  id='dashboard'
                  defaultMessage='Dashboard'
                />
              </span>
            )}
            key='dashboard'
          >
            <MagicLinksDashboard
              campaigns={campaigns}
              magicLinks={magicLinks}
              collaborators={collaborators}
              user={user}
              shopOwner={shopOwner}
              orders={orders}
              products={products}
              history={history}
              locale={locale}
              setActiveKey={setActiveKey}
              modifyMagicLinks={modifyMagicLinks}
              shop={shop}
            />
          </TabPane>
          <TabPane
            tab={(
              <span style={{ fontSize: '14px', fontWeight: '400', color: neutralColor[12] }}>
                <FormattedMessage
                  id='campaigns'
                  defaultMessage='Campagnes'
                />
              </span>
            )}
            key='campaigns'
          >
            <CampaignList
              campaigns={campaigns}
              filters={filters}
              setFiltersState={setFiltersState}
              setVisibleMagicLinksDrawer={setVisibleMagicLinksDrawer}
              setCurrentCampaignId={setCurrentCampaignId}
              user={user}
              magicLinks={magicLinks}
              collaborators={collaborators}
              shopOwner={shopOwner}
              shopId={shop._id}
              history={history}
              removeDraftCampaign={removeDraftCampaign}
            />
          </TabPane>
          {magicLinks?.length && (
            <TabPane
              tab={(
                <span style={{ fontSize: '14px', fontWeight: '400', color: neutralColor[12] }}>
                  <FormattedMessage
                    id='campaigns.tab.shipments'
                    defaultMessage='Envois'
                  />
                </span>
              )}
              key='tracking'
            >
              <MagicLinksTracking
                user={user}
                shop={shop}
                shopOwner={shopOwner}
                locale={locale}
                collaborators={collaborators}
                campaigns={campaigns}
                magicLinks={magicLinks}
                modifyMagicLinks={modifyMagicLinks}
              />
            </TabPane>
          )}
          {user.admin || user.adminCollaborator || user._id === shopOwner ? (
            <TabPane
              tab={(
                <span style={{ fontSize: '14px', fontWeight: '400', color: neutralColor[12] }}>
                  <FormattedMessage
                    id='campaigns.tab.adminGestionTitle'
                    defaultMessage='Administrateurs'
                  />
                </span>
              )}
              key='admins'
            >
              <CampaignAdmin
                collaborators={collaborators}
                campaigns={campaigns}
                budgets={budgets}
                addCredit={addCredit}
              />
            </TabPane>
          ) : null}
        </Tabs>
      )}
    </div>
  )
}

export default connect((state) => ({
  user: fromUsers.getUser(state),
  shop: fromShops.getShop(state),
  shopOwner: fromShops.getOwner(state),
  locale: fromLocale.getLocale(state),
  collaborators: fromCollaborators.getCollaborators(state),
  campaigns: fromCampaigns.getCampaigns(state),
  loading: fromCampaigns.loading(state),
  magicLinks: fromMagicLinks.getMagicLinks(state),
  budgets: fromBudgets.getBudgets(state),
  budget: fromBudgets.getBudget(state),
  products: fromProducts.allStockedProducts(state),
  orders: fromOrders.getOrders(state),
}),
(dispatch) => bindActionCreators({
  createCampaign: (payload, callback) => ({
    type: CREATE,
    payload,
    callback,
  }),
  createMagicLinks: (payload) => ({
    type: CREATE_MAGICLINKS,
    payload,
  }),
  modifyMagicLinks: (payload, callback) => ({
    type: MODIFY_MAGICLINKS,
    payload,
    callback,
  }),
  addCredit: (payload, callback) => ({
    type: ADD,
    payload,
    callback,
  }),
  sendTestEmail: (payload) => ({
    type: SEND_TEST,
    payload,
  }),
  removeDraftCampaign: (payload) => ({
    type: REMOVE_DRAFT_CAMPAIGN,
    payload,
  }),
}, dispatch))(MagicLinksContainer)

import React from 'react'
import { Spin } from '../../atoms'

export default ({ content }) => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  }}
  >
    {content}
    <Spin />
  </div>
)

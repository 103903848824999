import React from 'react'
import { Switch } from 'antd'
import { FormattedMessage } from 'react-intl'

import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

import { ColorButton } from '../../atoms'

const HiddenCustomHeader = (props) => {
  try {
    const {
      newCustom, setNewCustom, colorState, setColorState,
    } = props
    const { header } = newCustom
    const {
      fontColor, backgroundColor, hideSocial, hideLogin,
    } = header
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage
            id='custom.header'
            defaultMessage='Entête'
          />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.headerFontColor'
                defaultMessage='Couleur de police'
              />
            </div>
            <ColorButton
              color={fontColor}
              onClick={() => setColorState({
                ...colorState,
                currentColor: fontColor,
                groupName: 'header',
                propName: 'fontColor',
              })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.headerBackgroundColor'
                defaultMessage="Couleur de l'arrière plan"
              />
            </div>
            <ColorButton
              color={backgroundColor}
              onClick={() => setColorState({
                ...colorState,
                currentColor: backgroundColor,
                groupName: 'header',
                propName: 'backgroundColor',
              })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.headerSocial'
                defaultMessage='Masquer les réseaux sociaux'
              />
            </div>
            <Switch
              checked={hideSocial}
              onChange={(value) => setNewCustom({ ...newCustom, header: { ...header, hideSocial: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.headerLogin'
                defaultMessage='Masquer "Se connecter"'
              />
            </div>
            <Switch
              checked={hideLogin}
              onChange={(value) => setNewCustom({ ...newCustom, header: { ...header, hideLogin: value } })}
            />
          </div>
        </div>
      </div>

    )
  } catch (e) {
    console.log('molecule/HiddenCustomHeader error:', e)
    return null
  }
}

export default HiddenCustomHeader

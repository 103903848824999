import React, { useState, useEffect } from 'react'
import { Select, Button } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import { flatten, uniq } from 'lodash'
import { StepInputLabel } from '..'
import { isLocaleDefinedInProductCategory } from '../../../helpers/products'
import { isCategoriesMatchedByName } from '../../../helpers/category'

const { Option } = Select

const CategoryFilter = (props) => {
  const {
    filters, // Required
    setFilters, // Required
    productsToFilter, // Required
  } = props
  const [selectedFilter, setSetectedFilter] = useState('')
  const [categories, setCategories] = useState([])
  const intl = useIntl()
  const isProductCategorySelected = (product, filter) => {
    if (!isLocaleDefinedInProductCategory(product, intl.locale)) {
      return false
    }
    return isCategoriesMatchedByName(
      product?.baseProduct?.category,
      intl.locale,
      filter,
    )
  }
  const filterByCategory = (products, selectedCategory) => products.filter((product) => isProductCategorySelected(product, selectedCategory))
  const handleOnChangeFilter = (selectedCategory) => {
    setSetectedFilter(selectedCategory)
    const filter = {
      callback: filterByCategory,
      parameters: [selectedCategory],
    }
    setFilters({ ...filters, category_filter: filter })
  }
  const handleResetFilter = () => {
    const cloneFilters = { ...filters }
    delete cloneFilters.category_filter
    setFilters(cloneFilters)
    setSetectedFilter('')
  }

  useEffect(() => {
    const categoriesToAdd = productsToFilter.map((product) => {
      if (isLocaleDefinedInProductCategory(product, intl.locale)) {
        return product?.baseProduct?.category[intl.locale]
      }
      return []
    })
    setCategories(uniq(flatten(categoriesToAdd)))
  }, [intl.locale])

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <StepInputLabel
          title={<FormattedMessage id='category' defaultMessage='Catégorie' />}
        />
        <Button
          type='link'
          style={{
            display: 'block',
            width: 'fit-content',
            height: '0px',
            padding: '0',
          }}
          onClick={handleResetFilter}
        >
          <FormattedMessage
            id='campaigns.create.step.products.filters.category.reset'
            defaultMessage='Réinitialiser'
          />
        </Button>
      </div>
      <Select
        onChange={handleOnChangeFilter}
        value={selectedFilter}
        style={{ width: '100%' }}
      >
        {categories.map((category) => <Option value={category} key={category}>{category}</Option>)}
      </Select>
    </>

  )
}

export default CategoryFilter

import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'

import { DataBlock } from '..'

const DataBlockSentMagicLinks = (props) => {
  try {
    const { magicLinks } = props
    return (
      <DataBlock
        title={(
          <FormattedMessage
            id='magicLinks.dashboard.sentMagicLinks'
            defaultMessage='Magic Links envoyés'
          />
        )}
        value={magicLinks?.length || 'N/C'}
        icon={faPaperPlane}
      />
    )
  } catch (e) {
    console.log('molecules/DataBlockSentMagicLinks error:', e)
    return null
  }
}

export default DataBlockSentMagicLinks

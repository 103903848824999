/* eslint-disable camelcase */
import React from 'react'
import { Carousel } from 'antd'
import ProductImage from '@balibart/product-image'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

const ProductDisplayer = (props) => {
  try {
    const {
      product,
    } = props

    if (
      product.custom
        && !product.picsDefaultToRegularProduct
        && product.picList
    ) {
      return (
        <div
          style={{
            backgroundColor: '#EAEAEA',
          }}
        >
          <Carousel
            autoplay
          >
            {product.picList.map((image) => (
              <img
                alt={`${product._id}-img`}
                src={`${imageBaseUrl}/Products/${product._id}/customProduct/${image}`}
                style={{
                  width: '100px',
                }}
                key={image}
              />
            ))}
          </Carousel>
        </div>
      )
    }

    let zones = []
    if (
      product.specialItemProduct
        && product.baseProduct
        && product.baseProduct.specialItem.shopDisplays
    ) {
      zones = product.baseProduct.specialItem.shopDisplays
    } else if (product.baseProduct && product.baseProduct.printingZone) {
      zones = Object.keys(product.baseProduct.printingZone)
    } else {
      zones = ['front']
    }

    let items = []

    if (product.picList) {
      items = items.concat(product.picList.map(((image) => (
        <img
          alt={`${product._id}-img`}
          src={`${imageBaseUrl}/Products/${product._id}/customProduct/${image}`}
          style={{
            width: '100px',
          }}
        />
      ))))
    }
    const color = product.variations && product.variations.length ? product.variations.find((v) => v.type === 'color') : {}
    if (!product.showOnlyCustom) {
      items = items.concat(zones.map((zone) => (
        <div
          className='sliderElement'
          key={`${zone}`}
          style={{
            width: '100px',
          }}
        >
          <ProductImage
            product={product}
            forceSVGRender
            source='ProductDisplayer'
            base_url={imageBaseUrl}
            variation={product.favColor || color}
            currentPrintingZone={zone}
          />
        </div>
      )))
    }
    return (
      <div
        style={{
          backgroundColor: '#EAEAEA',
        }}
      >
        <Carousel
          autoplay
          style={{
            minHeight: '150px',
          }}
        >
          {items}
        </Carousel>
      </div>
    )
  } catch (error) {
    console.log('ProductDisplayer - render()', error)
    return null
  }
}

export default ProductDisplayer

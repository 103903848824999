import React from 'react'
import { Row } from 'antd'
import { CardElement } from '@stripe/react-stripe-js'
import theme from '../../../config/theme'

import {
  PaymentMethodsSelect,
} from '../..'

export default ({
  onChange,
  paymentMethods,
  handleSelectionPaymentMethod,
  paymentMethodSelected,
}) => (
  <div>
    <PaymentMethodsSelect
      paymentMethods={paymentMethods}
      handleSelectionPaymentMethod={handleSelectionPaymentMethod}
    />
    {
      !paymentMethodSelected ? (
        <Row
          style={{
            margin: '12px 0',
            border: '1px solid rgb(217, 217, 217)',
            padding: '5px',
            borderRadius: '5px',
          }}
        >
          <CardElement
            onChange={onChange}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: theme.primaryColor[6],
                  '::placeholder': {
                    color: 'rgb(217, 217, 217)',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />

        </Row>
      ) : null
    }
  </div>
)

/* eslint-disable max-len */
import React from 'react'
import {
  Descriptions,
  Icon,
  Timeline,
  Checkbox,
  List,
  Input,
  Card,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { displayDate } from '../../../helpers/trim'

const { TextArea } = Input

const ProductRecap = (props) => {
  try {
    const {
      product,
      allShops,
      errorCreation,
      locale,
    } = props

    const intl = useIntl()

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {errorCreation.length ? (
          <div style={{
            display: 'flex',
            border: '1px solid rgb(255, 135, 147)',
            backgroundColor: 'rgb(255, 230, 230)',
            color: 'rgba(0, 0, 0, 0.65)',
            borderRadius: '5px',
            padding: '10px 0px 0px 0px',
            flexWrap: 'wrap',
            marginBottom: '10px',
          }}
          >
            <div style={{
              width: '100%',
              marginLeft: '15px',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <Icon type='close-circle' style={{ marginRight: '10px', color: 'red', fontSize: '20px' }} />
              <span style={{ fontWeight: 600, fontSize: '16px' }}>
                <FormattedMessage id='productRecap.errorAtCreation' defaultMessage='Erreur qui empêche la création du produit :' />
              </span>
            </div>
            <ul>
              {errorCreation.map((errorDisplay) => errorDisplay)}
            </ul>
          </div>
        ) : null}
        <Descriptions bordered column={4}>
          <Descriptions.Item span={2} label={<FormattedMessage id='lastName' defaultMessage='Nom' />}>{product.name}</Descriptions.Item>
          <Descriptions.Item span={2} label={<FormattedMessage id='shop.en' defaultMessage='Shop' />}>{allShops.find((shop) => shop._id === product.shops[0]).settings.name}</Descriptions.Item>
          <Descriptions.Item span={2} label={<FormattedMessage id='frenchDescription' defaultMessage='Description FR' />}>{product.displayDescription.fr}</Descriptions.Item>
          <Descriptions.Item span={2} label={<FormattedMessage id='englishDescription' defaultMessage='Description EN' />}>{product.displayDescription.en}</Descriptions.Item>
          <Descriptions.Item span={2} label={<FormattedMessage id='margin' defaultMessage='Marge' />}>{`${product.pricing.margin} €`}</Descriptions.Item>
          <Descriptions.Item span={2} label={<FormattedMessage id='customizationPrice' defaultMessage='Prix de customisation' />}>{`${product.pricing.customizationPrice} €`}</Descriptions.Item>
          <Descriptions.Item span={2} label={<FormattedMessage id='colors' defaultMessage='Couleurs' />}>
            {product.variations && product.variations.length && product.variations.filter((v) => v.type === 'color').map((v) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{
                  width: 10, height: 10, backgroundColor: v.value, marginRight: 10,
                }}
                />
                {`${v.name} (ref: ${v.reference})`}
                {product.favColor && product.favColor.reference === v.reference ? (
                  <Icon
                    type='star'
                    style={{
                      color: '#FEDD00', fontSize: '16px', marginLeft: '5px', marginBottom: '3px',
                    }}
                    theme='filled'
                  />
                ) : null}
              </div>
            ))}
          </Descriptions.Item>
          <Descriptions.Item span={2} label={<FormattedMessage id='sizes' defaultMessage='Tailles' />}>
            {product.variations && product.variations.length && product.variations.filter((v) => v.type === 'size').map((v) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {`${v.name} (ref: ${v.reference})`}
              </div>
            ))}
          </Descriptions.Item>
          {product.printingFile && product.printingFile.front && product.printingFile.front.length ? (
            <Descriptions.Item span={1} label={<FormattedMessage id='productRecap.printing.front' defaultMessage="Fichiers d'impression - front" />}>
              <img src={product.printingFile.front[0].thumbUrl} alt={product.printingFile.front[0].name} style={{ width: '100px' }} />
            </Descriptions.Item>
          ) : null}
          {product.printingFile && product.printingFile.back && product.printingFile.back.length ? (
            <Descriptions.Item span={1} label={<FormattedMessage id='productRecap.printing.back' defaultMessage="Fichiers d'impression - back" />}>
              <img src={product.printingFile.back[0].thumbUrl} alt={product.printingFile.back[0].name} style={{ width: '100px' }} />
            </Descriptions.Item>
          ) : null}
          {product.previewFile && product.previewFile.front && product.previewFile.front.length ? (
            <Descriptions.Item span={1} label={<FormattedMessage id='productRecap.mockups.front' defaultMessage='Mockups - front' />}>
              <img src={product.previewFile.front[0].thumbUrl} alt={product.previewFile.front[0].name} style={{ width: '100px' }} />
            </Descriptions.Item>
          ) : null}
          {product.previewFile && product.previewFile.back && product.previewFile.back.length ? (
            <Descriptions.Item span={1} label={<FormattedMessage id='productRecap.mockups.back' defaultMessage='Mockups - back' />}>
              <img src={product.previewFile.back[0].thumbUrl} alt={product.previewFile.back[0].name} style={{ width: '100px' }} />
            </Descriptions.Item>
          ) : null}
          {product.picListToUpload && product.picListToUpload.length ? (
            <Descriptions.Item span={4} label={<FormattedMessage id='productRecap.presentationImages' defaultMessage='Images de présentation' />}>
              {product.picListToUpload.map((pic) => (
                <img src={pic.thumbUrl} alt={pic.name} style={{ width: '100px', margin: '0 0 0 5px' }} />
              ))}
            </Descriptions.Item>
          ) : null}
          {product.preOrder && product.preOrderInfos ? (
            <Descriptions.Item span={2} label={<FormattedMessage id='preOrder' defaultMessage='Précommande' />}>
              <div>
                <Timeline className='productRecap'>
                  <Timeline.Item>
                    <FormattedMessage
                      id='productRecap.startingDate'
                      defaultMessage='Débute le {start}'
                      values={{ start: displayDate(product.preOrderInfos.startDate, locale) }}
                    />
                  </Timeline.Item>
                  <Timeline.Item>
                    <FormattedMessage
                      id='productRecap.endingDate'
                      defaultMessage='Fini le {end}'
                      values={{ end: displayDate(product.preOrderInfos.endDate, locale) }}
                    />
                  </Timeline.Item>
                  <Timeline.Item>
                    <FormattedMessage
                      id='productRecap.estimation'
                      defaultMessage='Estimation de livraison {date}'
                      values={{ date: displayDate(product.preOrderInfos.shippingEstimate, locale) }}
                    />
                  </Timeline.Item>
                </Timeline>
                <Checkbox checked={product.preOrderInfos.displayBandOnCustomImage} style={{ marginRight: '5px' }} />
                <FormattedMessage id='productRecap.banner' defaultMessage='Affiche un bandeau sur eCommerce' />
              </div>
            </Descriptions.Item>
          ) : null}
          {product.clientCustomization && product.clientCustomization.length ? (
            <Descriptions.Item span={2} label={<FormattedMessage id='productRecap.clientCustomization' defaultMessage='Customisation client' />}>
              <List
                grid={{ column: 2 }}
                dataSource={product.clientCustomization || []}
                renderItem={(item) => (
                  <List.Item>
                    <Card key={item.id}>
                      <Input
                        addonBefore={intl.formatMessage({
                          id: 'productRecap.addonBefore.lastName',
                          defaultMessage: 'Nom',
                        })}
                        style={{ marginTop: '5px' }}
                        defaultValue={item.fieldName}
                        disabled
                      />
                      <TextArea
                        autoSize
                        style={{ marginTop: '5px' }}
                        defaultValue={item.fieldDescription}
                        disabled
                      />
                      <Input
                        addonBefore='Placeholder'
                        style={{ marginTop: '5px' }}
                        defaultValue={item.placeholder}
                        disabled
                      />
                      <Input
                        addonBefore={intl.formatMessage({
                          id: 'productRecap.addonBefore.side',
                          defaultMessage: 'Côté',
                        })}
                        style={{ marginTop: '5px' }}
                        defaultValue={item.side}
                        disabled
                      />
                      <Input
                        addonBefore={intl.formatMessage({
                          id: 'productRecap.addonBefore.type',
                          defaultMessage: 'Type',
                        })}
                        style={{ marginTop: '5px' }}
                        defaultValue={item.type}
                        disabled
                      />
                    </Card>
                  </List.Item>
                )}
              />
            </Descriptions.Item>
          ) : null }
        </Descriptions>
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/ProductRecap')
    return null
  }
}

export default injectIntl(ProductRecap)

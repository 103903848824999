// https://github.com/diegohaz/arc/wiki/API-service
import fetch from 'isomorphic-fetch'
import merge from 'lodash/merge'
import { stringify } from 'query-string'

import config from '../../config'
import { getLastNeedReload } from '../../store/settings/selectors'

// NOTE allow modifications false will block all non-get calls to our api
const { apiURL = 'https://api.foo.com', allowModifications } = config
console.log('apiURL', apiURL)

/**
 * Gère l'affichage d'une notification au client si un refresh de l'application est nécessaire.
 *
 * @param {*} response
 */
/**
const handleRefreshAppIfNeeded = (response) => {
  if (localStorage.getItem('notificationRefreshDisplayed') !== 'true' && response.status === 409) {
    localStorage.setItem('notificationRefreshDisplayed', true)

    Modal.warning({
      title: 'Vous utilisez une ancienne version de app.panopli.co, veuillez rafraîchir la page s\'il-vous-plait',
      okText: 'Rafraîchir !',
      onOk: () => {
        localStorage.setItem('notificationRefreshDisplayed', false)
        window.location.reload()
      },
    })
  }
}
*/

export const parseJSON = (response) => response.json()

export const checkStatus = async (response) => {
  if (response.ok) {
    return response
  }

  // ! Hotfix : ça bloque tout pour rien, je le débraye pour le moment car au final je suis sûr que ça ne sert plus à rien
  // handleRefreshAppIfNeeded(response)

  let parsedResponse
  try {
    parsedResponse = await parseJSON(response)
    console.log('parsedResponse', parsedResponse)
  } catch (e) {
    throw new Error(response.status)
  }
  if (parsedResponse && parsedResponse.error_type === 'not_enough_stock') {
    const e = new Error('not_enough_stock')
    e.message = 'not enough stock'
    throw e
  }
  if (parsedResponse && parsedResponse.error === 'cannot be delivered') {
    const e = new Error('cannot be delivered')
    e.nonDeliverable = parsedResponse.nonDeliverable
    e.isOverWeight = parsedResponse.isOverWeight
    throw e
  }
  if (parsedResponse && parsedResponse.error === 'payment_failed') {
    const e = new Error('payment_failed')
    throw e
  }

  // ? On rethrow un objet avec les informations nécessaires pour pouvoir agir contextuellement côté UI
  // eslint-disable-next-line no-throw-literal
  throw { status: response.status, response: parsedResponse }
}

export const parseSettings = ({
  method = 'get', data, locale, ...otherSettings
} = {}) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    mode: 'cors',
    app_name: 'sellerInterface',
    last_need_reload: getLastNeedReload(),
  }
  const settings = merge({
    // eslint-disable-next-line no-undefined
    body: data ? JSON.stringify(data) : undefined,
    method,
    headers,
  }, otherSettings)
  return settings
}

export const parseEndpoint = (endpoint, params) => {
  const url = endpoint.indexOf('http') === 0 ? endpoint : apiURL + endpoint
  const querystring = params ? `?${stringify(params)}` : ''
  return `${url}${querystring}`
}

const api = {}

api.request = (endpoint, { params, ...settings } = {}) => fetch(
  parseEndpoint(endpoint, params),
  parseSettings(settings),
)
  .then(checkStatus)
  .then(parseJSON)
  .catch((error) => {
    // ! Les exceptions doivent être catch côté sagas.
    throw error
  })

const noBody = ['delete', 'get']
noBody.forEach((method) => {
  api[method] = (e, s) => api.request(e, { method, ...s })
})

const withBody = ['post', 'put', 'patch']
withBody.forEach((method) => {
  api[method] = (e, data, s) => api.request(e, { method, data, ...s })
})

api.create = (settings = {}) => ({
  settings,

  setToken(token) {
    this.settings.headers = {
      ...this.settings.headers,
      Authorization: `Bearer ${token}`,
    }
  },

  unsetToken() {
    // this.settings.headers = {
    //   ...this.settings.headers,
    //   // eslint-disable-next-line
    //   Authorization: undefined,
    // }
    delete this.settings.headers.Authorization
  },

  request(endpoint, s) {
    return api.request(endpoint, merge({}, this.settings, s))
  },

  post(endpoint, data, s) {
    if (!allowModifications) {
      console.log('Modifications are not allowed under the current configuration', { endpoint, data, s })
      return true
    }
    return this.request(endpoint, { method: 'post', data, ...s })
  },

  get(endpoint, s) {
    return this.request(endpoint, { method: 'get', ...s })
  },

  put(endpoint, data, s) {
    if (!allowModifications) {
      console.log('Modifications are not allowed under the current configuration', { endpoint, data, s })
      return true
    }
    return this.request(endpoint, { method: 'put', data, ...s })
  },

  patch(endpoint, data, s) {
    if (!allowModifications) {
      console.log('Modifications are not allowed under the current configuration', { endpoint, data, s })
      return true
    }
    return this.request(endpoint, { method: 'PATCH', data, ...s })
  },

  delete(endpoint, s) {
    if (!allowModifications) {
      console.log('Modifications are not allowed under the current configuration', { endpoint, s })
      return true
    }
    return this.request(endpoint, { method: 'delete', ...s })
  },
})

export default api

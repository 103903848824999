import { Upload } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { StepInputLabel } from '../..'

const { Dragger } = Upload

const CustomizableDragger = (props) => {
  const {
    children, // Required
    name, // Required
    showUploadList = false,
    customRequest = null, // If we set null, it will apply the default behavior, so an XMLHttpRequest will be executed.
    formattedMessageId, // Required
    formattedDefaultMessage, // Required
    isDisabled = false,
  } = props

  return (
    <>
      <StepInputLabel
        title={<FormattedMessage id={formattedMessageId} defaultMessage={formattedDefaultMessage} />}
        titleStyle={{ marginBottom: '8px', display: 'block' }}
      />
      <Dragger
        name={name}
        // Don't show the uploaded file under the drap and drop field.
        showUploadList={showUploadList}
        // By default, the Upload component will execute XMLHttpRequest on an action to post the uploaded file.
        // In our case, it's useless because files are stored in AWS S3.
        // So we will call put the file in the bucket at the final step.
        customRequest={customRequest}
        disabled={isDisabled}
      >
        {children}
      </Dragger>
    </>
  )
}

export default CustomizableDragger

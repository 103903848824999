import React, { useState } from 'react'
import {
  Button,
  Input,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'

const StreamlabsTest = (props) => {
  const [state, setState] = useState({
    name: '',
    message: '',
  })
  const intl = useIntl()

  const fakeAlert = () => {
    const { shop, testNotificationStreamlabs } = props
    const { name, message } = state
    testNotificationStreamlabs({ id: shop._id, name, message })
  }

  const { displayMessage } = props
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
      <Input
        placeholder={intl.formatMessage({
          id: 'streamlabsTest.placeholder.name',
          defaultMessage: 'Nom',
        })}
        onChange={(e) => setState({ ...state, name: e.target.value })}
        style={{ width: 200 }}
      />
      {displayMessage
        ? (
          <Input
            placeholder={intl.formatMessage({
              id: 'streamlabsTest.placeholder.message',
              defaultMessage: 'Message',
            })}
            style={{ width: 300, margin: '0 10px' }}
            onChange={(e) => setState({ ...state, message: e.target.value })}
          />
        )
        : null }
      <Button type='primary' onClick={fakeAlert} style={{ margin: '0 10px' }}>
        <FormattedMessage id='streamlabsTest.testDonation' defaultMessage='Envoyer une donation de test' />
      </Button>
    </div>
  )
}

export default injectIntl(StreamlabsTest)

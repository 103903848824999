// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import {
  call,
  takeEvery,
  select,
} from 'redux-saga/effects'
import { formatProductForHubpost } from '../../helpers/catalog-hubspot'
import { fromBaseProductsCart, fromProviders } from '../selectors'
import { SEND_TO_HUBSPOT } from './actions'
import config from '../../config'

const { catalog = {} } = config
const { catalogApiUrl } = catalog

// GET /codes
export function* sendCartToHubspot({ payload, onSuccess, onError }) {
  try {
    const { dealId, deliveryTotal } = payload
    const cartContent = yield select(fromBaseProductsCart.getBpCartContent)
    const providersIds = cartContent.map((item) => item.providerId)
    const providers = yield select(fromProviders.getProviders)
    const concernedProviders = providers.filter((provider) => providersIds.includes(provider._id))
    const formattedProducts = cartContent.map((item) => formatProductForHubpost(item, concernedProviders))
    const check = yield call(fetch, [`${catalogApiUrl}/api/items`],
      {
        method: 'POST',
        body: JSON.stringify({ dealId, items: formattedProducts, deliveryTotal }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    const parsed = yield check.json()
    const { error, valid } = parsed
    if (error && onError) {
      onError()
    } else if (valid && onSuccess) {
      onSuccess()
    }
    // do something
    return true
  } catch (e) {
    console.log('getCodes - error', e, e.message)
    return false
  }
}

export default function* () {
  yield takeEvery(SEND_TO_HUBSPOT, sendCartToHubspot)
}

import React from 'react'
import {
  Card,
  Row,
  Col,
  message,
  Button,
  Drawer,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { SketchPicker } from 'react-color'

const { Meta } = Card

const CardHelper = ({ modalColor }) => {
  switch (modalColor) {
  case 'mainColor':
    return (
      <FormattedMessage
        id='theme.mainColorHelper'
        defaultMessage='La couleur de toutes les accentuations de votre boutique'
      />
    )
  case 'pageBackgroundColor':
    return (
      <FormattedMessage
        id='theme.pageBackgroundColorHelper'
        defaultMessage='La couleur de fond de votre boutique'
      />
    )
  case 'productBackgroundColor':
    return (
      <FormattedMessage
        id='theme.productBackgroundColorHelper'
        defaultMessage='Couleur de fond des images de vos produits'
      />
    )
  case 'fontColor':
    return (
      <FormattedMessage
        id='theme.fontColorHelper'
        defaultMessage='La couleur de police de caractère utilisée sur votre boutique'
      />
    )
  default:
    return <FormattedMessage id='color' defaultMessage='Couleur' />
  }
}

const CardMeta = ({ modalColor }) => {
  switch (modalColor) {
  case 'mainColor':
    return (
      <FormattedMessage
        id='theme.mainColor'
        defaultMessage='Couleur principale'
      />
    )
  case 'pageBackgroundColor':
    return (
      <FormattedMessage
        id='theme.pageBackgroundColor'
        defaultMessage='Couleur de fond : page'
      />
    )
  case 'productBackgroundColor':
    return (
      <FormattedMessage
        id='theme.productBackgroundColor'
        defaultMessage='Couleur de fond : produit'
      />
    )
  case 'fontColor':
    return (
      <FormattedMessage
        id='theme.fontColor'
        defaultMessage='Couleur de police'
      />
    )
  default:
    return <FormattedMessage id='color' defaultMessage='Couleur' />
  }
}

const ColorCard = ({ color, modalColor, setModalColor }) => {
  try {
    return (
      <Col className='gutter-row' span={6}>
        <Card
          hoverable
          cover={(
            <div
              style={{
                backgroundColor: color,
                height: '100px',
                transition: '0.2s',
                borderRadius: '2px 2px 0 0',
                borderBottom: '1px solid #eee',
                width: '100%',
              }}
            />
          )}
          onClick={() => setModalColor(modalColor)}
        >
          <Meta
            title={(<CardMeta modalColor={modalColor} />)}
          />
        </Card>
      </Col>
    )
  } catch (error) {
    console.log('Theme - ColorCard', { error })
    return null
  }
}

export default class ThemeColorsForm extends React.Component {
  originalState = {
    modalColor: null,
  }

  constructor(props) {
    super(props)

    const { shopSettings } = props
    const { theme = false } = shopSettings
    let mainColor = 'white'
    let pageBackgroundColor = 'white'
    let fontColor = 'white'
    let productBackgroundColor = 'white'
    if (theme) {
      mainColor = theme.mainColor ? theme.mainColor : 'white'
      pageBackgroundColor = theme.pageBackgroundColor ? theme.pageBackgroundColor : 'white'
      fontColor = theme.fontColor ? theme.fontColor : 'white'
      productBackgroundColor = theme.productBackgroundColor ? theme.productBackgroundColor : 'white'
    }

    this.state = {
      modalColor: null,
      touched: false,
      mainColor,
      pageBackgroundColor,
      fontColor,
      productBackgroundColor,
    }
  }

  computeColor = (color) => `rgba(${color.r},${color.g},${color.b},${color.a})`

  setModalColor = (modalColor) => {
    try {
      this.setState({ modalColor })
    } catch (error) {
      console.log('Theme - setModalColor', { error })
    }
  }

  handleChangeComplete = (color) => {
    this.setState({
      currentColor: this.computeColor(color.rgb),
    })
  }

  saveShop = () => {
    try {
      const {
        mainColor,
        pageBackgroundColor,
        fontColor,
        productBackgroundColor,
      } = this.state

      const {
        shop,
        modifyShop,
      } = this.props

      // TODO: Save only modified stuff
      const saveSettings = {
        settings: {
          ...shop.settings,
          theme: {
            ...shop.settings.theme,
            mainColor,
            pageBackgroundColor,
            fontColor,
            productBackgroundColor,
          },
        },
        owner: shop.owner,
      }

      modifyShop({
        shop_id: shop._id,
        modifications: saveSettings,
        callback: () => {
          message.info(
            <FormattedMessage
              id='updateSuccess.shop'
              defaultMessage='Boutique mise à jour'
            />,
          )
          return true
        },
      })
      this.setState({
        touched: false,
      })
    } catch (error) {
      console.log('ThemeColorsForm - saveShop', { error })
    }
  }

  reinitColor = () => {
    const {
      shopSettings: {
        theme: {
          mainColor,
          pageBackgroundColor,
          fontColor,
          productBackgroundColor,
        },
      },
    } = this.props

    this.setState({
      mainColor,
      pageBackgroundColor,
      fontColor,
      productBackgroundColor,
      touched: false,
    })
  }

  saveColor = () => {
    try {
      const {
        modalColor,
        currentColor,
      } = this.state

      const newState = {}
      newState[modalColor] = currentColor

      this.setState({
        ...newState,
        modalColor: null,
        touched: true,
      })
    } catch (error) {
      console.log('Theme - saveColor', { error })
    }
  }

  render() {
    try {
      const {
        mainColor,
        pageBackgroundColor,
        fontColor,
        modalColor,
        touched,
        productBackgroundColor,
        currentColor,
      } = this.state
      return (
        <div
          className='themeInner'
          style={{
            textAlign: 'left',
            padding: '24px',
          }}
        >
          <Drawer
            title={(<CardMeta modalColor={modalColor} />)}
            visible={Boolean(modalColor)}
            width='40%'
            onClose={() => this.setModalColor(null)}
          >
            <p>
              <CardHelper modalColor={modalColor} />
            </p>
            <SketchPicker
              color={currentColor}
              width='auto'
              onChange={(color) => this.handleChangeComplete(color)}
              style={{
                padding: '10px 0',
                border: 'none',
              }}
            />
            <Button
              onClick={() => this.saveColor()}
              type='primary'
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '100%',
                borderRadius: 0,
              }}
            >
              <FormattedMessage id='validate' defaultMessage='Valider' />
            </Button>
          </Drawer>
          <Row gutter={16}>
            <Col span={12} className='imageHeader'>
              <h2>
                <FormattedMessage
                  id='theme.colors'
                  defaultMessage='Palette de couleur'
                />
              </h2>
            </Col>
          </Row>
          <Row gutter={16}>
            <ColorCard
              modalColor='mainColor'
              color={mainColor}
              setModalColor={this.setModalColor}
            />
            <ColorCard
              modalColor='pageBackgroundColor'
              color={pageBackgroundColor}
              setModalColor={this.setModalColor}
            />
            <ColorCard
              modalColor='productBackgroundColor'
              color={productBackgroundColor}
              setModalColor={this.setModalColor}
            />
            <ColorCard
              modalColor='fontColor'
              color={fontColor}
              setModalColor={this.setModalColor}
            />
          </Row>
          <Row style={{ paddingTop: 16 }} gutter={16}>
            <Col span={6}>
              <Button
                onClick={() => this.saveShop()}
                type='primary'
                size='large'
                disabled={!touched}
                style={{ marginRight: 10 }}
              >
                <FormattedMessage id='save' defaultMessage='Enregistrer' />
              </Button>
              <Button
                size='large'
                onClick={() => this.reinitColor()}
                disabled={!touched}
              >
                <FormattedMessage id='cancel' defaultMessage='Annuler' />
              </Button>
            </Col>
          </Row>
        </div>
      )
    } catch (error) {
      console.log('ThemeColorsForm - render', { error })
      return null
    }
  }
}

import React from 'react'
import { Layout } from 'antd'
import { Footer, NavMenu, Header } from '../../components'

const { Content } = Layout

/**
 * @name Layouts/BasicLayout
 * @description {{description}}{{}} {https://ant.design/components/page-header/ | PageHeader antd}
 * @return {*} ReactComponent Layout deriving from antd Layout
 * @param {*} props the props
 */
const BasicPageLayout = ({
  component,
  logout,
  shopSwitch,
  availableShops,
  shopId,
  shopName,
  shopUrl,
  shopOwner,
  user,
  isAdmin,
}) => (
  <Layout style={{ width: '100%' }}>
    <NavMenu
      switch={shopSwitch}
      availableShops={availableShops}
      shopId={shopId}
      shopName={shopName}
      user={user}
      isAdmin={isAdmin}
      shopOwner={shopOwner}
    />
    <Layout>
      <Header
        switch={shopSwitch}
        availableShops={availableShops}
        user={user}
        shopUrl={shopUrl}
      />
      <Layout>
        <Content style={{ padding: '0' }}>
          {component}
        </Content>
        <Footer logout={logout} />
      </Layout>
    </Layout>
  </Layout>
)

export default BasicPageLayout

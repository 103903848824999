// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
import { initialState } from './selectors'
import {
  FETCH_MEMBERS,
  FETCH_FORCE_MEMBERS,
  FETCH_FORCE_ONE_MEMBER,
  RECEIVED_MEMBERS,
  RECEIVED_ONE_MEMBER,
  RECEIVED_MORE,
  FETCH_MORE,
  END_FETCH_DATA,
} from './actions'

export default (state = initialState, {
  type,
  payload = {},
}) => {
  const {
    members,
    member,
    pagination,
    storedOrigin,
  } = payload
  switch (type) {
  case RECEIVED_MEMBERS:
    return {
      ...state,
      members,
      loading: false,
      error: false,
      lastFetched: new Date(),
      storedOrigin,
    }
  case FETCH_MORE:
    return {
      ...state,
      loading_more: true,
      loading: false,
    }
  case RECEIVED_MORE:
    return {
      ...state,
      loading: false,
      error: false,
      loading_more: true,
      members: state.members.concat(members),
      pagination,
    }
  case FETCH_MEMBERS:
    return {
      ...state,
      error: false,
      loading: true,
    }
  case FETCH_FORCE_MEMBERS:
    return {
      ...state,
      members,
      error: false,
      loading: true,
    }
  case FETCH_FORCE_ONE_MEMBER:
    return {
      ...state,
      error: false,
      loading: true,
    }
  case RECEIVED_ONE_MEMBER:
    return {
      ...state,
      member,
      error: false,
      loading: false,
    }
  case END_FETCH_DATA:
    return {
      ...state,
      isMembersPageReady: true,
    }
  default:
    return state
  }
}

import React, { useState } from 'react'
import { List } from 'antd'

import { Bp, BpOnDemand, BaseProductFilters } from '../../molecules'
import { trimSearch } from '../../../helpers/trim'

const BpList = (props) => {
  const {
    products,
    pushProducts,
    baseProducts,
    shopId,
    allShops,
    onClose,
    setCurrentProduct,
  } = props

  const [nameFilter, setFilterName] = useState('')
  const [pageSize, setPageSize] = useState('24')

  const getBaseProducts = () => baseProducts.filter(
    (bp) => trimSearch(bp.name?.fr)?.includes(trimSearch(nameFilter))
  || trimSearch(bp.supplierBaseProductReference)?.includes(trimSearch(nameFilter))
  || trimSearch(bp.idPanopli)?.includes(trimSearch(nameFilter)),
  )

  try {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: 200, position: 'relative', padding: '0px 20px 0px 0px' }}>
          <BaseProductFilters setFilterName={setFilterName} />
        </div>
        <List
          grid={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 4,
            xxl: 6,
            gutter: 8,
          }}
          style={{
            width: '100%',
            // textAlign: 'center',
          }}
          dataSource={getBaseProducts()}
          pagination={{
            showQuickJumper: true,
            style: { textAlign: 'left' },
            pageSize,
            showSizeChanger: true,
            onShowSizeChange: (current, _pageSize) => setPageSize(_pageSize),
            pageSizeOptions: ['24', '48', '96'],
          }}
          renderItem={(baseProduct) => (
            baseProduct.newBaseProduct ? (
              <Bp
                products={products}
                baseProduct={baseProduct}
                pushProducts={pushProducts}
                shopId={shopId}
                allShops={allShops}
                baseProducts={getBaseProducts()}
                onClose={onClose}
                setCurrentProduct={setCurrentProduct}
              />
            ) : (
              <BpOnDemand
                products={products}
                baseProduct={baseProduct}
                pushProducts={pushProducts}
                shopId={shopId}
                allShops={allShops}
                baseProducts={getBaseProducts()}
                onClose={onClose}
                setCurrentProduct={setCurrentProduct}
              />
            )
          )}
        />
      </div>
    )
  } catch (e) {
    console.log('BpList - error', e)
    return null
  }
}

export default BpList

import React, { Component } from 'react'
import { Icon } from 'antd'
import fetchToken from '../../../helpers/pickup'
import './index.css'

class PickupDiv extends Component {
  componentDidMount() {
    this.summonPicker()
  }

  summonPicker = () => {
    try {
      const {
        updatePickupInfos,
        selected,
      } = this.props
      if (typeof window !== 'undefined') {
        const callBackFrame = (value) => {
          updatePickupInfos(value)
          // selected(value)
          // close()
        }
        const { address } = this.props
        window.callBackFrame = callBackFrame

        fetchToken((data) => {
          const { token } = data
          const { jQuery } = window
          console.log('IN FETCH TOKEN ', jQuery);
          // eslint-disabled-next-line
          if (typeof jQuery('#widget-container').frameColissimoOpen === 'function') {
            // eslint-disabled-next-line
            jQuery('#widget-container').frameColissimoOpen({
              ceCountry: address && address.country ? address.country : '',
              ceLang: 'fr',
              ceCountryList: 'FR',
              dyPreparationTime: '1',
              callBackFrame: 'callBackFrame',
              URLColissimo: 'https://ws.colissimo.fr',
              ceAddress: address && address.street ? address.street : '',
              ceZipCode: address && address.postalCode ? address.postalCode : '',
              ceTown: address && address.city ? address.city : '',
              token,
            })
          }
        })
      }
    } catch (e) {
      console.log('PickupDiv - SummonDiv', e)
    }
  }

  render() {
    return (
      <div
        style={{
          height: 'auto',
        }}
        id='widget-container'
      />
    )
  }
}

const PickupModal = (props) => {
  const {
    open,
    selected,
    updatePickupInfos,
    currentAddress,
    close,
  } = props

  const isMobile = false

  try {
    if (!open) {
      return null
    }

    return (
      <div
        className='styled-pickup-modal'
      >
        <div className='content'>
          <div className='closer' onClick={close}>
            <Icon type='close-circle' />
          </div>
          <PickupDiv
            address={currentAddress}
            selected={selected}
            updatePickupInfos={updatePickupInfos}
          />
        </div>
      </div>
    )
  } catch (e) {
    console.log('PickupModal', e)
    return null
  }
}

export default PickupModal

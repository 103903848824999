import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import { MonitoringForm } from '../../components'
import { MODIFY } from '../../store/shops/actions'
import { fromShops } from '../../store/selectors'

const { Title } = Typography

export default connect(
  (state) => ({
    shopSettings: fromShops.getShopSettings(state),
    shop: fromShops.getShop(state),
  }),
  (dispatch) => bindActionCreators({
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)(({
  shopSettings,
  modifyShop,
  shop,
}) => (
  <div id='monitoring'>
    <Title level={2}>
      <FormattedMessage
        id='settings.monitoring.title'
        defaultMessage='Monitoring'
      />
    </Title>
    <p>
      <FormattedMessage
        id='settings.monitoring.helper'
        defaultMessage='Si vous utilisez Google Analytics ou
            Facebook Pixel, vous pouvez entrer vos informations
            de tracking pour suivre le trafic sur votre boutique.'
      />
    </p>
    <MonitoringForm
      shopSettings={shopSettings}
      modifyShop={modifyShop}
      shop={shop}
    />
  </div>
))

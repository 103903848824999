import React from 'react'
import {
  Page, Text, View, Document, PDFViewer, Image, StyleSheet,
} from '@react-pdf/renderer'
import { Alert } from 'antd'
import { FormattedMessage } from 'react-intl'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    fontSize: '8px',
    padding: 10,
  },
})

// <View style={{
//   display: 'flex',
//   margin: '20px 20px 0 20px',
//   border: '1px solid black',
// }}
// >
//   <View style={{ display: 'flex', flexDirection: 'row' }}>
//     <Text style={{
//       padding: '5px', borderBottom: '1px solid black', borderRight: '1px solid black', flex: '1 3',
//     }}
//     >
//       Mode de livraison / Delivery method
//     </Text>
//     <Text style={{
//       padding: '5px', borderBottom: '1px solid black', borderRight: '1px solid black', flex: '1 3',
//     }}
//     >
//       Quantité totale / Total Quantity
//     </Text>
//     <Text style={{ padding: '5px', borderBottom: '1px solid black', flex: '1 3' }}>
//       Poids total / Total Weight
//     </Text>
//   </View>
//   <View style={{ display: 'flex', flexDirection: 'row' }}>
//     <Text style={{ padding: '5px', borderRight: '1px solid black', flex: '1 3' }}>
//       Route / Road
//     </Text>
//     <Text style={{ padding: '5px', borderRight: '1px solid black', flex: '1 3' }}>
//       1
//     </Text>
//     <Text style={{ padding: '5px', flex: '1 3' }}>
//       {invoice.Poids}
//     </Text>
//   </View>
// </View>

const Destinataire = (props) => {
  const { invoice } = props
  return (
    <View
      style={{
        border: '1px solid black',
        flex: '1 2',
        margin: '10px 20px 0 20px',
        padding: '5px',
      }}
    >
      <Text style={{ borderBottom: '1px solid black', textAlign: 'center', marginBottom: '10px' }}>
        Destinataire / Consignee
      </Text>
      <View>
        <Text style={{ textTransform: 'capitalize' }}>
          {invoice['Prénom']}
          {' '}
          {invoice.Nom}
        </Text>
        <Text>
          {invoice.Adresse}
        </Text>
        <Text>
          {invoice.Ville}
          {' '}
          {invoice['Code Postale']}
        </Text>
        <Text>
          {invoice.Pays}
        </Text>
        <Text>
          Mail :
          {' '}
          {invoice.Mail}
        </Text>
        <Text>
          Tel :
          {' '}
          {invoice.Tel}
        </Text>
      </View>
    </View>
  )
}

const Expediteur = () => (
  <View
    style={{
      border: '1px solid black',
      flex: '1 2',
      margin: '10px 20px 0 20px',
      padding: '5px',
    }}
  >
    <Text style={{ borderBottom: '1px solid black', textAlign: 'center', marginBottom: '10px' }}>
      Expéditeur / Sender
    </Text>
    <View>
      <Text>
        Balibart SAS
      </Text>
      <Text>
        35 Impasse des Cherchevets
      </Text>
      <Text>
        92150 Suresnes
      </Text>
      <Text>
        France
      </Text>
      <Text>
        Mail : contact@panopli.co
      </Text>
      <Text>
        Tel : 01 76 42 05 01
      </Text>
      <Text style={{ marginTop: '10px' }}>
        EORI Number : FR79049354800016
      </Text>
      <Text>
        TVA Number : FR18790493548
      </Text>
    </View>
  </View>
)

const DisplayPDFViewer = (props) => {
  try {
    const {
      csvInvoices,
    } = props
    return (
      <>
        <Alert
          message={`Le processus peut prendre plusieurs minutes selon le nombre de facture à créer (par exemple: 550 factures, environ 15min).
          Si la page ne répond plus, c'est normal, veuillez patienter. Vous n'êtes pas obligé de rester sur la page pendant le processus.`}
          type='warning'
          showIcon
          closable
        />
        <PDFViewer style={{ width: '100%', height: '100vh', marginTop: '10px' }}>
          <Document>
            {csvInvoices.map((invoice) => (
              <Page size='A4' style={styles.page}>
                <View style={{ flexDirection: 'row' }}>
                  <View>
                    <Image src='/logo-panopli-03.png' style={{ width: '75px', left: '100px' }} />
                  </View>
                  <View style={{
                    left: '300px', top: '10px', fontWeight: '700',
                  }}
                  >
                    <Text>
                      DATE OF BILLING
                      {invoice['Date de facturation']}
                    </Text>
                    <Text>
                      FACTURE / INVOICE N°
                      {invoice['Numéro de facture']}
                    </Text>
                    <Text>
                      COMMANDE / ORDER N°
                      {invoice.Commande}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <Expediteur invoice={invoice} />
                  <Destinataire invoice={invoice} />
                </View>
                <View style={{
                  border: '1px solid black',
                  display: 'flex',
                  margin: '10px 20px 0 20px',
                }}
                >
                  <Text style={{ color: 'red', margin: '0 5px' }}>
                    Numéro de TVA Britannique / VAT Number (HMRC) : GB082685777000
                  </Text>
                  <Text style={{ margin: '0 5px' }}>
                    Description de la livraison / Clear and precise description of the goods :
                    {' '}
                    {invoice.products.map((product) => `${product.Produit}, `)}
                  </Text>
                  <Text style={{ margin: '0 5px' }}>
                    Conditions de livraison
                    / Terms of delivery :
                    {' '}
                    {invoice['Conditions de livraison']}
                  </Text>
                  <Text style={{ margin: '0 5px' }}>
                    Origin :
                    {' '}
                    {invoice.products.map((product) => `${product.Origine}, `)}
                  </Text>
                </View>
                <View style={{
                  display: 'flex',
                  margin: '10px 20px 0 20px',
                  border: '1px solid black',
                }}
                >
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{
                      padding: '5px', borderBottom: '1px solid black', borderRight: '1px solid black', flex: '1 8',
                    }}
                    >
                      Produit / Product
                    </Text>
                    <Text style={{
                      padding: '5px', borderBottom: '1px solid black', borderRight: '1px solid black', flex: '1 8',
                    }}
                    >
                      <FormattedMessage id='description' defaultMessage='Description' />
                    </Text>
                    <Text style={{
                      padding: '5px', borderBottom: '1px solid black', borderRight: '1px solid black', flex: '1 8',
                    }}
                    >
                      HS Code
                    </Text>
                    <Text style={{
                      padding: '5px', borderBottom: '1px solid black', borderRight: '1px solid black', flex: '1 8',
                    }}
                    >
                      Origine / Origin
                    </Text>
                    <Text style={{
                      padding: '5px', borderBottom: '1px solid black', borderRight: '1px solid black', flex: '1 8',
                    }}
                    >
                      Quantité / Quantity
                    </Text>
                    <Text style={{
                      padding: '5px', borderBottom: '1px solid black', borderRight: '1px solid black', flex: '1 8',
                    }}
                    >
                      Prix unitaire Hors Taxes / Single Price Without Taxes
                    </Text>
                    <Text style={{
                      padding: '5px', borderBottom: '1px solid black', borderRight: '1px solid black', flex: '1 8',
                    }}
                    >
                      Montant de TVA / VAT Amount
                    </Text>
                    <Text style={{ padding: '5px', borderBottom: '1px solid black', flex: '1 8' }}>
                      TOTAL
                    </Text>
                  </View>
                  {invoice.products.map((product) => (
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={{
                        padding: '5px', borderRight: '1px solid black', borderBottom: '1px solid black', flex: '1 8',
                      }}
                      >
                        {product.Produit}
                      </Text>
                      <Text style={{
                        padding: '5px', borderRight: '1px solid black', borderBottom: '1px solid black', flex: '1 8', fontSize: '8px',
                      }}
                      >
                        {product['Détail produit']}
                      </Text>
                      <Text style={{
                        padding: '5px', borderRight: '1px solid black', borderBottom: '1px solid black', flex: '1 8',
                      }}
                      >
                        {product['HS Code']}
                      </Text>
                      <Text style={{
                        padding: '5px', borderRight: '1px solid black', borderBottom: '1px solid black', flex: '1 8',
                      }}
                      >
                        {product.Origine}
                      </Text>
                      <Text style={{
                        padding: '5px', borderRight: '1px solid black', borderBottom: '1px solid black', flex: '1 8',
                      }}
                      >
                        {product['Quantité']}
                      </Text>
                      <Text style={{
                        padding: '5px', borderRight: '1px solid black', borderBottom: '1px solid black', flex: '1 8',
                      }}
                      >
                        {product['Single Price Without Taxes']}
                      </Text>
                      <Text style={{
                        padding: '5px', borderRight: '1px solid black', borderBottom: '1px solid black', flex: '1 8',
                      }}
                      >
                        {product['TVA Amount']}
                      </Text>
                      <Text style={{ padding: '5px', flex: '1 8', borderBottom: '1px solid black' }}>
                        {product['Total (without expe)']}
                      </Text>
                    </View>
                  ))}
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', margin: '0px 20px 0 20px' }}>
                  <View style={{ flex: '8 8', width: '100%' }}>
                    <View style={{ flexDirection: 'row', height: '20px' }}>
                      <Text style={{ flex: '7 8', textAlign: 'right', margin: '2px 5px 0 0' }}>
                        Total HT Marchandise / Total value of products Excluding Taxes
                      </Text>
                      <Text style={{
                        borderBottom: '1px solid black',
                        borderLeft: '1px solid black',
                        borderRight: '1px solid black',
                        flex: '1 4',
                      }}
                      >
                        {invoice['Single Price Without Taxes']}
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', height: '20px' }}>
                      <Text style={{ flex: '7 8', textAlign: 'right', margin: '2px 5px 0 0' }}>
                        Expédition et manipulation / Shipping and Handling
                      </Text>
                      <Text style={{
                        flex: '1 8', borderBottom: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black',
                      }}
                      >
                        {invoice['Expedition fee']}
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', height: '20px' }}>
                      <Text style={{ flex: '7 8', textAlign: 'right', margin: '2px 5px 0 0' }}>
                        Total HT / Total value Excluding Taxes
                      </Text>
                      <Text style={{
                        flex: '1 8', borderBottom: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black',
                      }}
                      >
                        {invoice['Total HT (including expe)']}
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', height: '20px' }}>
                      <Text style={{ flex: '7 8', textAlign: 'right', margin: '2px 5px 0 0' }}>
                        Total TVA Inclue / Total value including VAT
                      </Text>
                      <Text style={{
                        flex: '1 8', borderBottom: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black',
                      }}
                      >
                        {invoice['Total (vat included)']}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', margin: '10px 20px 0 20px' }}>
                  <View style={{
                    borderLeft: '1px solid black',
                    borderTop: '1px solid black',
                    borderRight: '1px solid black',
                    flex: '3 7',
                    flexDirection: 'row',
                  }}
                  >
                    <View style={{ flex: '2 3' }}>
                      <Text>
                        Signature :
                      </Text>
                      <Text>
                        Lieu / Place : Paris
                      </Text>
                      <Text>
                        Date :
                        {' '}
                        {invoice['Date de facturation']}
                      </Text>
                    </View>
                    <View style={{ flex: '1 3', width: '50px' }}>
                      <Image src='/signature_adrien.png' style={{ padding: '5px', width: '100%' }} />
                    </View>
                  </View>
                  <View style={{ flex: '1 7' }} />
                  <View style={{
                    flex: '3 7',
                    borderLeft: '1px solid black',
                    borderTop: '1px solid black',
                    borderRight: '1px solid black',
                    flexDirection: 'row',
                  }}
                  >
                    <Text style={{ flex: '2 3' }}>
                      Poids Total / Total Weight
                    </Text>
                    <Text style={{ flex: '1 3' }}>
                      {invoice.Poids}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', margin: '0px 20px 0 20px' }}>
                  <View style={{
                    flex: '3 7',
                    borderLeft: '1px solid black',
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    padding: '5px',
                    textAlign: 'center',
                  }}
                  >
                    <Text style={{ marginBottom: '10px' }}>
                      L&apos;exportateur des produits couverts par le présent document déclare que,
                      sauf indication claire du contraire, ces produits ont l&apos;origine préférentielle France
                    </Text>
                    <Text>
                      The exporter of the products covered by this document declares that,
                      expect where otherwise clearly indicated, these products are of France preferentiel origin
                    </Text>
                  </View>
                  <View style={{
                    flex: '4 7', border: '1px solid black', padding: '5px', textAlign: 'center',
                  }}
                  >
                    <Text>
                      Date de Paiement :
                      {' '}
                      {invoice['Date de facturation']}
                    </Text>
                    <Text style={{ marginBottom: '10px' }}>
                      Date of Payment :
                      {' '}
                      {invoice['Date de facturation']}
                    </Text>
                    <Text>
                      Paiement anticipé : 0,50% d&apos;escompte par mois entier
                    </Text>
                    <Text style={{ marginBottom: '10px' }}>
                      Early Payment : 0.50% discount per full month
                    </Text>
                    <Text>
                      Pénalités de retard : Une fois et demie le taux d&apos;intéret légal
                    </Text>
                    <Text>
                      Late penalties : 1.5 times legal rate of interest
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={{ textAlign: 'center', marginTop: '10px' }}>
                    Balibart SAS - 35 Impasse des cherchevets 92150 Suresnes France - RCS Nanterre 790493548 - tel : 01 76 42 05 01
                  </Text>
                </View>
              </Page>
            ))}
          </Document>
        </PDFViewer>
      </>
    )
  } catch (e) {
    console.log('error organisms/BaseProductForm', e)
    return null
  }
}

export default DisplayPDFViewer

import {
  put, call, takeEvery, select, all, takeLatest,
} from 'redux-saga/effects'
import * as entitiesActions from './actions'
import { fromShops, fromUsers, fromEntities } from '../selectors'
import * as usersActions from '../users/actions'

import { getEntityId } from '../../helpers/entities'
import { fetchCollaborators } from '../collaborators/sagas'

const { getId, getShop } = fromShops
const { getAuth, getUser } = fromUsers
const { getEntities } = fromEntities

export function* fetchEntities(api) {
  try {
    yield put({ type: entitiesActions.GET })
    yield put({ type: entitiesActions.LOADING, payload: { loading: true } })
    const shop = yield select(getId)
    const auth = yield select(getAuth)
    const { data } = yield call([api, api.get], '/entities', { headers: { authorization: auth, shop } })

    yield put({ type: entitiesActions.RECEIVED, payload: { entities: data } })
    return true
  } catch (e) {
    console.log('fetchEntities error', e, e.message)
    yield put({
      type: entitiesActions.ERROR,
      error_details: (e.message || e),
    })
    return false
  }
}

export function* fetchAllEntities(api) {
  try {
    yield put({ type: entitiesActions.GET_ALL })
    yield put({ type: entitiesActions.LOADING, payload: { loading: true } })
    const auth = yield select(getAuth)
    const { data } = yield call([api, api.get], '/entities/all', { headers: { authorization: auth } })
    yield put({ type: entitiesActions.RECEIVED_ALL, payload: { allEntities: data } })
    return true
  } catch (e) {
    console.log('fetchAllEntities error', e, e.message)
    yield put({
      type: entitiesActions.ERROR,
      error_details: (e.message || e),
    })
    return false
  }
}

export function* createEntity(api, { payload, callback }) {
  try {
    yield put({ type: entitiesActions.LOADING, payload: { loading: true } })
    const shop = yield select(getId)
    const auth = yield select(getAuth)
    const entityCreated = yield call([api, api.post], '/entities', payload.entity, {
      headers: {
        mode: 'cors',
        authorization: auth,
        shop,
      },
    })
    if (entityCreated) {
      if (callback) {
        callback()
      }
      yield put({ type: entitiesActions.FETCH_FORCE })
      yield put({
        type: usersActions.USER_ENTITY,
        payload: {
          ...payload.user,
          entityId: entityCreated._id,
        },
      })
    }
    return true
  } catch (e) {
    console.log('fetchEntities error', e, e.message)
    yield put({
      type: entitiesActions.ERROR,
      error_details: (e.message || e),
    })
    return false
  }
}

export function* modifyEntity(api, { payload, callback }) {
  try {
    yield put({ type: entitiesActions.LOADING, payload: { loading: true } })
    const auth = yield select(getAuth)
    const entityModified = yield call([api, api.put], '/entities', payload, {
      headers: {
        mode: 'cors',
        authorization: auth,
      },
    })
    if (entityModified) {
      if (callback) {
        callback()
      }
      yield put({ type: entitiesActions.FETCH_FORCE })
    }
    return true
  } catch (e) {
    console.log('fetchEntities error', e, e.message)
    yield put({
      type: entitiesActions.ERROR,
      error_details: (e.message || e),
    })
    return false
  }
}

export function* setStripe(api, { payload: { code } }) {
  try {
    const user = yield select(getUser)
    const shop = yield select(getShop)
    const entities = yield select(getEntities)
    const { entityId } = getEntityId(user, entities, shop)
    const auth = yield select(fromUsers.getAuth)
    console.log(user._id, shop.owner, entityId, 'ici setStripe')
    const result = yield call([api, api.put], '/entityStripeSetup', {
      id: entityId,
      stripe: code,
    }, {
      headers: {
        mode: 'cors',
        authorization: auth,
      },
    })
    console.log(result, 'ici result')

    return true
  } catch (e) {
    console.log('*setStripe error', e, e.message)
    return false
  }
}

export function* getDataForEntitiesPages(api) {
  yield all([
    call(fetchEntities, api),
    call(fetchAllEntities, api),
    call(fetchCollaborators, api),
  ])
}

export default function* ({ api }) {
  yield takeEvery(entitiesActions.FETCH_FORCE, fetchEntities, api)
  yield takeEvery(entitiesActions.FETCH_FORCE, fetchAllEntities, api)
  yield takeEvery(entitiesActions.CREATE_ENTITY, createEntity, api)
  yield takeEvery(entitiesActions.MODIFY_ENTITY, modifyEntity, api)
  yield takeEvery(entitiesActions.SET_STRIPE, setStripe, api)
  yield takeLatest(entitiesActions.INIT_FETCH_DATA, getDataForEntitiesPages, api)
}

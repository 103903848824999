export const initialState = {
  data: [],
  loading: false,
  error: false,
}

export const getComments = (state = initialState) => state.data || []

export const loading = (state = initialState) => (state.loading || false)

export const error = (state = initialState) => state.error || false

import { initialState } from './selectors'
import * as actions from './actions'

export default (state = initialState, { type, payload = {} }) => {
  const {
    item,
    isVisible,
    cartId,
  } = payload
  switch (type) {
  case actions.EMPTY_CART:
    return {
      ...state,
      content: [],
    }
  case actions.GET_BASEPRODUCTS_CART:
    return {
      ...state,
    }
  case actions.ADD_BASEPRODUCTS_CART:
    return {
      ...state,
      content: state.content.concat([item]),
    }
  case actions.DELETE_BASEPRODUCTS_CART:

    return {
      ...state,
      content: state.content.filter((product) => product.cartId !== cartId),
    }
  case actions.SET_BASEPRODUCTS_CART_VISIBLE:
    return {
      ...state,
      isVisible,
    }
  default:
    return state
  }
}

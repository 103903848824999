import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  // faQuestionCircle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { InnerLayout } from '../../layouts'
import { Refresher, MagiclinksSendButton } from '../../components'

import { MagicLinksContainer } from '../../containers'
import { INIT_FETCH_DATA } from '../../store/campaigns/actions'
// import { character, white } from '../../config/theme'

const MagicLinksPage = (props) => {
  const { history, shopId, fetchData } = props

  useEffect(() => {
    fetchData()
  }, [shopId])

  const [visibleMagicLinksDrawer, setVisibleMagicLinksDrawer] = useState(false)

  const actions = [
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '16px',
    }}
    >
      {/*
      // AUDIT: caché, en attente d'un éventuel tuto
      <span
        style={{
          color: character.title,
          backgroundColor: white,
          fontWeight: '500',
          borderColor: white,
          cursor: 'pointer',
          marginRight: '16px',
        }}
        onClick={() => console.log('lien vers tutoriel')}
      >
        <FontAwesomeIcon style={{ paddingRight: '10px' }} icon={faQuestionCircle} />
        <FormattedMessage
          id='campaign.create.tutorial'
          defaultMessage='Tutoriel'
        />
      </span>
      */}
      <MagiclinksSendButton onClick={() => setVisibleMagicLinksDrawer(true)} />
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: '500',
          fontSize: '14px',
          height: '40px',
          gap: '5px',
        }}
        type='primary'
        onClick={() => history.push('/campaign/create')}
      >
        <FontAwesomeIcon icon={faPlus} />
        <FormattedMessage
          id='campaigns.createCampaign'
          defaultMessage='Créer une campagne'
        />
      </Button>
    </div>,
  ]

  return (
    <InnerLayout
      title={(
        <div style={{
          fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
        }}
        >
          <FormattedMessage
            id='campaigns.page.title'
            defaultMessage='Magic Link'
          />
        </div>
      )}
      extra={actions}
    >
      <Refresher fetchData={fetchData} />
      <MagicLinksContainer
        history={history}
        visibleMagicLinksDrawer={visibleMagicLinksDrawer}
        setVisibleMagicLinksDrawer={setVisibleMagicLinksDrawer}
      />
    </InnerLayout>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(MagicLinksPage))

import {
  put, call, takeEvery, select,
} from 'redux-saga/effects'
import { notification } from 'antd'
import * as actions from './actions'
import { fromShops, fromUsers } from '../selectors'

const { getId } = fromShops
const { getAuth, getUserId } = fromUsers

export function* fetchBudgets(api) {
  try {
    yield put({ type: actions.GET })
    yield put({ type: actions.LOADING, payload: { loading: true } })
    const shopId = yield select(getId)
    const auth = yield select(getAuth)
    const { data } = yield call([api, api.get], '/budgets', { headers: { authorization: auth, shopId } })
    const userId = yield select(getUserId)
    const budget = data.find((iBudget) => iBudget.userId === userId)
    yield put({ type: actions.RECEIVED, payload: { data, budget } })
    return true
  } catch (e) {
    console.log('fetchBudgets error', e, e.message)
    yield put({
      type: actions.ERROR,
      error_details: (e.message || e),
    })
    return false
  }
}

export function* addCredit(api, { payload, callback }) {
  try {
    yield put({ type: actions.LOADING, payload: { loading: true } })
    const auth = yield select(getAuth)
    const budgetAdded = yield call([api, api.put], '/budget', {
      ...payload,
    }, {
      headers: {
        mode: 'cors',
        authorization: auth,
      },
    })
    if (budgetAdded) {
      if (callback) {
        callback()
      }
      yield put({ type: actions.FETCH_FORCE })
    }
    notification.info({
      message: 'Links ajoutés !',
      placement: 'bottomRight',
    })
    return true
  } catch (e) {
    console.log('addCredit error', e, e.message)
    yield put({
      type: actions.ERROR,
      error_details: (e.message || e),
    })
    notification.error({
      message: 'Erreur lors de l\'ajout de links.',
      placement: 'bottomRight',
    })
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(actions.FETCH_FORCE, fetchBudgets, api)
  yield takeEvery(actions.ADD, addCredit, api)
}

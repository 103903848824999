import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import { PresetAddressesForm } from '../../components'
import { MODIFY } from '../../store/shops/actions'
import {
  fromShops, fromEntities, fromUsers, fromLocale,
} from '../../store/selectors'

const { Title } = Typography

export default connect(
  (state) => ({
    shopSettings: fromShops.getShopSettings(state),
    shop: fromShops.getShop(state),
    entities: fromEntities.getEntities(state),
    user: fromUsers.getUser(state),
    locale: fromLocale.getLocale(state),
  }),
  (dispatch) => bindActionCreators({
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)(({
  shopSettings,
  modifyShop,
  shop,
  entities,
  user,
  locale,
}) => (
  <div id='presetAddresses'>
    <Title level={2}>
      <FormattedMessage
        id='settings.presetAddresses'
        defaultMessage='Adresses personnalisées'
      />
    </Title>
    <p>
      <FormattedMessage
        id='settings.presetAddresses.helper'
        // eslint-disable-next-line max-len
        defaultMessage='Ajoutez des adresses personnalisées qui pourront être sélectionnées au moment du choix de l’adresse de livraison afin de faire gagner du temps à vos utilisateurs.'
      />
    </p>
    <PresetAddressesForm
      shopSettings={shopSettings}
      modifyShop={modifyShop}
      shop={shop}
      entities={entities}
      user={user}
      locale={locale}
    />
  </div>
))

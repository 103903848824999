import React, { useState } from 'react'
import {
  Form,
  Input,
  Button,
  Icon,
  Row,
  message,
  Col,
  Avatar,
  Divider,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'

const OnboardingForm = (props) => {
  const [state, setState] = useState({
    step: 'name',
  })

  const {
    form: {
      getFieldDecorator,
      getFieldValue,
    },
  } = props

  const {
    step,
  } = state

  const intl = useIntl()

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault()
    }

    const {
      form: {
        validateFieldsAndScroll,
      },
      createShop,
      history,
    } = props

    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          name,
          url,
        } = values
        const toCreate = {
          name,
          url: url.toLowerCase(),
        }

        createShop({
          shop: toCreate,
          callback: (error) => {
            if (error) {
              message.error(
                <FormattedMessage
                  id='onboarding.error'
                  defaultMessage='Une erreur est survenue'
                />,
              )
              console.log('RegisterDrawer - error', { error })
              return false
            }
            message.success(
              <FormattedMessage
                id='onboarding.success'
                defaultMessage='Boutique créée !'
              />,
            )
            history.push('/')
            return true
          },
        })
      }
    })
  }

  const setStep = (_step) => {
    setState({ ...state, step: _step })
  }
  return (
    <Form
      id='basicsForm'
      onSubmit={handleSubmit}
      style={{ width: '50vw' }}
    >
      <Row
        style={{
          display: step === 'name' ? 'block' : 'none',
        }}
      >
        <Col span={2}>
          <Avatar
            src='https://i.picsum.photos/id/194/200/200.jpg'
          />
        </Col>
        <Col span={22}>
          <p style={{ fontWeight: 'bold' }}>
            Laura
          </p>
          <p>
            <FormattedMessage
              id='onboarding.urlHelper'
              defaultMessage="Hello ! Je m'appelle Laura, je vais vous poser
                quelques questions pour vous accompagner dans la création de votre boutique"
            />
          </p>
          <p>
            <FormattedMessage
              id='onboarding.urlHelper2'
              defaultMessage="Tout d'abord, choisissez le nom de votre boutique."
            />
          </p>
          <Form.Item>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='chooseAName' defaultMessage='Choisissez un nom' />,
                  whitespace: true,
                },
              ],
            })(<Input
              autoFocus
              placeholder={intl.formatMessage({
                id: 'onboardingForm.placeholder.myShop',
                defaultMessage: 'Ma boutique',
              })}
              size='default'
              style={{ width: 300 }}
            />)}
          </Form.Item>
          <Button
            onClick={() => {
              setState({ ...state, step: 'url' }, () => {
                document.getElementById('onboardingForm_url').focus()
              })
            }}
            type='primary'
            disabled={!getFieldValue('name')}
          >
            <FormattedMessage id='validate' defaultMessage='Valider' />
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          display: step === 'url' ? 'block' : 'none',
        }}
      >
        <Col span={2}>
          <Avatar
            src='https://i.picsum.photos/id/194/200/200.jpg'
          />
        </Col>
        <Col span={22}>
          <p style={{ fontWeight: 'bold' }}>
            Laura
          </p>
          <p>
            <FormattedMessage
              id='onboarding.nameHelper2'
              defaultMessage="Parfait ! Maintenant, choisissez l'URL de votre boutique"
            />
          </p>
          <Form.Item>
            {getFieldDecorator('url', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='chooseURL' defaultMessage='Choisissez une URL' />,
                  whitespace: true,
                },
              ],
            })(<Input id='urlInput' placeholder='myshop' autoFocus size='default' style={{ width: 300 }} addonAfter='.niceshop.co' />)}
          </Form.Item>
          <Button
            htmlType='submit'
            type='primary'
            disabled={!getFieldValue('url')}
          >
            <FormattedMessage id='validate' defaultMessage='Valider' />
          </Button>
          <Divider />
          <Button
            type='link'
            style={{
              padding: 0,
            }}
            onClick={() => setStep('name')}
          >
            <Icon type='swap-left' style={{ marginRight: 10 }} />
            <FormattedMessage id='return' defaultMessage='Retour' />
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default Form.create({ name: 'onboardingForm' })(withRouter(injectIntl(OnboardingForm)))

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { stockRating as rateStock } from '@balibart/stocks'
import {
  Tabs, Icon, Radio, Button,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import * as designActions from '../../store/designs/actions'
import {
  fromShops,
  fromCart,
  fromOrders,
  fromProducts,
  fromUsers,
  fromEntities,
} from '../../store/selectors'
import { ADD } from '../../store/cart/actions'
import { MODIFY } from '../../store/shops/actions'
import { UPLOAD } from '../../store/uploads/actions'
import getPrice from '../../helpers/getPrice'
import { decodeQuery } from '../../helpers/filters'
import { getEntityId } from '../../helpers/entities'
import {
  ThemeColorsForm, ShopPicturesInputs, InformationBannerForm, OrganizationGrid, ShopPreviewModal, HiddenCustom,
} from '../../components'

const { TabPane } = Tabs

const Theme = (props) => {
  try {
    const {
      shopSettings,
      modifyShop,
      upload,
      shopId,
      shop,
      canActivate,
      allProducts,
      createDesign,
      history,
    } = props

    const initialState = {
      previewModal: false,
      view: 'grid',
      metaDisplay: true,
      viewport: 'desktop',
    }

    const { search } = history.location

    const [state, setState] = useState(initialState)
    const [activeKey, setActiveKey] = useState(decodeQuery(search).tab || 'theme')

    const {
      view, metaDisplay, viewport, previewModal,
    } = state

    const changeView = () => {
      setState({
        ...state,
        view: view === 'grid' ? 'table' : 'grid',
      })
    }

    const changeMetaDisplay = () => {
      setState({
        ...state,
        metaDisplay: !metaDisplay,
      })
    }

    const changeViewport = (e) => {
      setState({
        ...state,
        viewport: e.target.value,
      })
    }

    const ViewportOptions = () => (
      <Radio.Group onChange={changeViewport} style={{ marginRight: '5px' }}>
        <Radio.Button value='desktop'>
          <Icon type='desktop' />
        </Radio.Button>
        <Radio.Button value='tablet'>
          <Icon type='tablet' />
        </Radio.Button>
        <Radio.Button value='mobile'>
          <Icon type='mobile' />
        </Radio.Button>
      </Radio.Group>
    )

    const handleTabsChange = (key) => {
      try {
        setActiveKey(key)
        history.push(`theme?tab=${key}`)
        return true
      } catch (e) {
        console.log('Theme - handleTabsChange error:', e)
        return null
      }
    }

    const actions = (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px',
        }}
      >
        {view === 'grid'
          ? (
            <>
              <ViewportOptions />
              <Button
                key='display'
                onClick={() => changeMetaDisplay()}
                style={{ marginRight: '5px' }}
              >
                <Icon type={metaDisplay ? 'info' : 'picture'} />
              </Button>
            </>
          ) : null}
        {viewport === 'desktop' ? (
          <Button
            key='view'
            onClick={() => changeView()}
            style={{ marginRight: '5px' }}
          >
            <Icon type={view === 'table' ? 'appstore' : 'menu'} />
          </Button>
        ) : null}
        <Button
          key='preview'
          type='primary'
          onClick={() => {
            setState({ ...state, previewModal: true })
          }}
        >
          <FormattedMessage
            id='shop.preview'
            defaultMessage='Aperçu'
          />
        </Button>
      </div>
    )

    // TODO
    const preprod = () => ''

    return (
      <div id='theme'>
        <Tabs
          activeKey={activeKey}
          tabBarExtraContent={actions}
          onChange={handleTabsChange}
          type='card'
        >
          <TabPane
            tab={(
              <span>
                <Icon type='highlight' />
                {' '}
                <FormattedMessage id='theme.themeTitle' defaultMessage='Thème' />
              </span>
            )}
            key='theme'
          >
            <ThemeColorsForm
              shop={shop}
              shopId={shopId}
              shopSettings={shopSettings}
              modifyShop={modifyShop}
              canActivate={canActivate}
            />
            <ShopPicturesInputs
              shopSettings={shopSettings}
              modifyShop={modifyShop}
              upload={upload}
              shop={shop}
              canActivate={canActivate}
            />
            <InformationBannerForm
              shopSettings={shopSettings}
              shop={shop}
              modifyShop={modifyShop}
            />
          </TabPane>
          <TabPane
            tab={(
              <span>
                <Icon type='sliders' />
                {' '}
                <FormattedMessage id='theme.advancedTitle' defaultMessage='Thème avancé' />
              </span>
            )}
            key='hiddenCustom'
          >
            <HiddenCustom
              shop={shop}
              modifyShop={modifyShop}
            />
          </TabPane>
          <TabPane
            tab={(
              <span>
                <Icon type='table' />
                {' '}
                <FormattedMessage id='theme.organizationTitle' defaultMessage='Organisation' />
              </span>
            )}
            key='organization'
          >
            <OrganizationGrid
              shopSettings={shopSettings}
              modifyShop={modifyShop}
              metaDisplay={metaDisplay}
              upload={upload}
              createDesign={createDesign}
              viewport={viewport}
              listingView={view}
              allProducts={allProducts}
              shop={shop}
            />
          </TabPane>
        </Tabs>
        <ShopPreviewModal
          visible={previewModal}
          url={`https://preview-${shopId}${preprod()}.niceshop.co/`}
          onCancel={() => setState({
            ...state,
            previewModal: false,
          })}
        />
      </div>
    )
  } catch (e) {
    console.log('Container/Theme error:', e)
    return null
  }
}

export default connect(
  (state) => ({
    shopSettings: fromShops.getShopSettings(state),
    canActivate: fromShops.canActivate(state),
    shopId: fromShops.getId(state),
    shop: fromShops.getShop(state),
    cartContent: fromCart.getContent(state),
    cartPrice: fromCart.getPrice(state),
    allProducts: (() => {
      const shop = fromShops.getShop(state)
      const user = fromUsers.getUser(state)
      const entities = fromEntities.getEntities(state)
      const { entityId } = getEntityId(user, entities, shop)
      return fromProducts.allProducts(state).map((product) => {
        const price = getPrice([], product)
        return ({
          ...product,
          ordered: fromOrders.getOrderCount(state, String(product._id)),
          unitPrice: price.value,
          hasImpact: price.impact,
          // TODO: (display somewhere as well)
          buyPrice: 0,
          stockRating: rateStock(product.stock, entityId),
        })
      })
    })(),
    loading: fromProducts.loading(state),
    error: fromProducts.error(state),
  }),
  (dispatch) => bindActionCreators({
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }), // on it
    addToCart: (items) => ({
      type: ADD,
      payload: items,
    }),
    upload: (payload) => ({
      type: UPLOAD,
      payload,
    }),
    createDesign: (payload) => ({
      type: designActions.CREATE_DESIGN,
      payload,
    }),
  }, dispatch),
)(Theme)

import React from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptop, faShirt } from '@fortawesome/free-solid-svg-icons'
import { primaryColor } from '../../../config/theme'

const buttonStyle = {
  border: `1px solid ${primaryColor[1]}`,
  borderRadius: '1000px',
  height: '40px',
  color: primaryColor[6],
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}

const ProductsTypeFilters = (props) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const { filters, setFilters } = props
    // TODO filters' logic
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <Button
          style={buttonStyle}
          onClick={null}
        >
          <FontAwesomeIcon icon={faShirt} color={primaryColor[6]} />
          <FormattedMessage id='physicalProducts' defaultMessage='Produits physiques' />
        </Button>
        <Button
          style={buttonStyle}
          onClick={null}
        >
          <FontAwesomeIcon icon={faLaptop} color={primaryColor[6]} />
          <FormattedMessage id='dematerializedProducts' defaultMessage='Produits dématérialisés' />
        </Button>
      </div>
    )
  } catch (e) {
    console.log('molecules/ProductsTypeFilters error:', e)
    return null
  }
}

export default ProductsTypeFilters

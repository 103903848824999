import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Card,
  Tag,
  Icon,
  Select,
  Button,
  Popconfirm,
  message,
} from 'antd'

const { Option } = Select

class GroupCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputVisible: false,
      actualGroupMembers: [],
      changed: false,
      goIn: [],
      goOut: [],
    }
  }

  showInput = () => {
    this.setState({
      inputVisible: true,
    })
  }

  deleteGroup = (group) => {
    const {
      shop,
      modifyShop,
      saveGroup,
      groupMembers,
    } = this.props
    const newGroups = shop.settings.membersGroups.filter((_group) => _group.name !== group.name)
    const saveSettings = {
      settings: {
        ...shop.settings,
        membersGroups: newGroups,
      },
    }

    saveGroup(group, [], groupMembers)
    modifyShop({
      shop_id: shop._id,
      modifications: saveSettings,
      callback: () => {
        message.info(<FormattedMessage id='groupCard.groupDeleted' defaultMessage='Groupe supprimé !' />)
        return true
      },
    })
  }

  handleClose = (member) => {
    const { groupMembers } = this.props
    const { goOut } = this.state
    const newMembers = groupMembers.filter((_member) => _member._id !== member._id)
    const newGoOut = [...goOut]
    const memberToRemove = groupMembers.find((_member) => _member._id === member._id)
    newGoOut.push(memberToRemove)
    this.setState({ actualGroupMembers: newMembers, goOut: newGoOut, changed: true })
  }

  handleSelect = (id) => {
    const { members } = this.props
    const { actualGroupMembers, goIn } = this.state
    const newGroupMembers = [...actualGroupMembers]
    const newMember = members.find((member) => member._id === id)
    newGroupMembers.push(newMember)
    goIn.push(newMember)
    this.setState({
      actualGroupMembers: newGroupMembers,
      changed: true,
      inputVisible: false,
      goIn,
    })
  }

  render() {
    const {
      group,
      saveGroup,
      members,
      groupMembers,
    } = this.props

    const {
      inputVisible,
      actualGroupMembers,
      changed,
      goIn,
      goOut,
    } = this.state

    const displayMembers = groupMembers.length ? groupMembers : actualGroupMembers
    return (
      <Card
        key={group.name}
        title={<Tag color={group.color} style={{ fontSize: 14 }}>{group.name}</Tag>}
        extra={[
          <Popconfirm
            title={<FormattedMessage id='popConfirm.groupDeletion' defaultMessage='Êtes-vous sûr de supprimer ce groupe ?' />}
            onConfirm={() => this.deleteGroup(group)}
            okText={<FormattedMessage id='yes' defaultMessage='Oui' />}
            cancelText={<FormattedMessage id='no' defaultMessage='Non' />}
            key={group.name}
          >
            <Button type='link'>
              <FormattedMessage id='delete' defaultMessage='Supprimer' />
            </Button>
          </Popconfirm>,
        ]}
        actions={changed ? [
          <Button
            onClick={() => {
              saveGroup(group, goIn, goOut)
              this.setState({
                changed: false,
                goIn: [],
                goOut: [],
                actualGroupMembers: [],
              })
            }}
            type='primary'
          >
            <FormattedMessage id='save' defaultMessage='Enregistrer' />
          </Button>,
        ] : null}
        style={{ width: '100%', margin: '10px 0px' }}
      >
        {displayMembers.map((member) => (
          <Tag
            key={member._id}
            closable={displayMembers.length >= 1}
            onClose={() => this.handleClose(member)}
          >
            {`${member.name} ${member.lastName}`}
          </Tag>
        ))}
        {inputVisible && (
          <Select
            showSearch
            placeholder={<FormattedMessage id='select' defaultMessage='Sélectionner...' />}
            size='small'
            style={{ width: 200 }}
            onChange={this.handleSelect}
            filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
          >
            {members.map((member) => {
              if (displayMembers.filter((_member) => _member._id === member._id).length) return null
              return (<Option key={member._id}>{`${member.name} ${member.lastName}`}</Option>)
            })}
          </Select>
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
            <Icon type='plus' />
            {' '}
            <FormattedMessage id='groupCard.addMember' defaultMessage='Ajouter un membre' />
          </Tag>
        )}
      </Card>
    )
  }
}

export default GroupCard

import React from 'react'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'

import { FormattedSwitch } from '../..'
import {
  getDescription, getTitle, trimCentsToEuros,
} from '../../../helpers/donation'
import { displayPrice } from '../../../helpers/getPrice'

const CampaignDonationSwitch = (props) => {
  const {
    campaign, // Required
    setCampaign, // Required
    checkedDonation, // Required
    setCheckedDonation, // Required
    setNeedUpdateCampaign, // Required
    setDisplayDonationDrawer, // Required
    isDisabled = false,
    size,
  } = props

  const { donation = {} } = campaign
  const { active, amount } = donation

  const parsedAmount = trimCentsToEuros(amount)

  const uncheck = () => {
    // On décoche le switch
    setCheckedDonation(false)
    // Si la campagne avait déjà une prop "donation", on la passe à "false" en conservant le montant
    if (donation) setCampaign({ ...campaign, donation: { ...donation, active: false } })
  }

  const onClick = () => {
    // On coche le switch
    setCheckedDonation(true)
    // On ouvre le drawer d'alternative don
    setDisplayDonationDrawer(true)
  }

  const onChange = async (checked) => {
    // On enable la sauvegarde de campagne dans le header
    setNeedUpdateCampaign(true)
    if (checked) {
      onClick()
    } else uncheck()
  }

  return (
    <FormattedSwitch
      checked={checkedDonation}
      onChangeFunction={onChange}
      title={(
        <span
          // Si la campagne avait déjà une prop "donation" active, on peut accéder au drawer sans avoir à actionner le switch
          className={(checkedDonation && 'hoverableLink') || null}
          onClick={() => checkedDonation && onClick()}
        >
          {getTitle(campaign)}
        </span>

      )}
      subtitle={active ? (
        <FormattedMessage
          id='donation.amount'
          defaultMessage='Don caritatif de {value} €'
          values={{ value: displayPrice(parsedAmount) }}
        />
      ) : (
        <FormattedMessage
          id='donation.set.subtitle'
          defaultMessage='Définir le montant du don'
        />
      )}
      tooltipText={getDescription(campaign)}
      icon={faCircleInfo}
      isDisabled={isDisabled}
      size={size}
    />
  )
}

export default CampaignDonationSwitch

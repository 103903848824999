import React from 'react'
import { connect } from 'react-redux'
import { Users } from '../../components'
import { fromCollaborators, fromShops, fromLocale } from '../../store/selectors'
import { SEND_USER_MAIL } from '../../store/users/actions'

const UsersContainer = (props) => {
  const {
    collaborators, shop, locale = 'fr', resendUserCreationMail,
  } = props
  return (
    <div>
      <Users
        users={collaborators}
        shop={shop}
        locale={locale}
        resendUserCreationMail={resendUserCreationMail}
      />
    </div>
  )
}

export default connect((state) => ({
  collaborators: fromCollaborators.getCollaborators(state),
  shop: fromShops.getShop(state),
  locale: fromLocale.getLocale(state),
}), (dispatch) => bindActionCreators({
  resendUserCreationMail: (payload, callback) => ({
    type: SEND_USER_MAIL,
    payload,
    callback,
  }),
}, dispatch))(UsersContainer)

import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Drawer,
  Button,
  Form,
  Input,
} from 'antd'
import { times } from 'lodash'
import { Tutorialization } from '../..'

export default class CustomizationDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customizations: props.cart_product.clientCustomization,
    }
  }

  componentDidUpdate(prevProps) {
    // NOTE we need to update the state on props change as the state is set on mount at empty
    const old_product = prevProps.cart_product
    const { cart_product: new_product } = this.props
    const old_id = old_product.cart_id
    const new_id = new_product.cart_id
    if (old_id !== new_id) {
      // NOTE it's ok since qe only do it in a few cases
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        customizations: new_product.clientCustomization,
      })
    }
  }

  customize(value, index, fieldId) {
    const {
      customizations,
    } = this.state
    customizations[index].find((c) => c.fieldId === fieldId).value = value
    this.setState({
      customizations,
    })
  }

  render() {
    const {
      visible,
      onClose,
      product = {},
      cart_product,
      cart_product: {
        cart_id,
        quantity,
        clientCustomization,
      },
      customizeProduct,
    } = this.props
    const { customizations = [] } = this.state
    const isIncomplete = customizations.map((custo) => {
      const scan = custo.find((c) => !c.value)
      return scan
    }).find(Boolean)
    return (
      <Drawer
        visible={visible}
        onClose={onClose}
        title={(
          <FormattedMessage
            id='customizationDrawer.title'
            defaultMessage='Customisation - {name}'
            values={{ name: product.name }}
          />
        )}
        width='50vw'
      >
        <Tutorialization
          title={<FormattedMessage id='tutorialization.title' defaultMessage='Personnalisation unitaire' />}
          content={[
            <FormattedMessage id='tutorialization.helper1' defaultMessage="Ces produits peuvent être personalisés à l'unité." />,
            <FormattedMessage id='tutorialization.helper2' defaultMessage='Vous devez remplir une personalisation par produit commandé.' />,
          ]}
        />
        {times(quantity, (index) => {
          console.log('clientCustomization index', { value: clientCustomization[index], index })
          return (
            <div key={index}>
              {
                clientCustomization[index].map(({
                  value,
                  placeholder,
                  fieldId,
                  fieldDescription,
                }) => (
                  <Form.Item
                    key={`${cart_id}-${index}-${fieldId}`}
                    label={(
                      <FormattedMessage
                        id='tutorialization.product'
                        defaultMessage='Produit {number} - {fieldDescription}'
                        values={{ number: index + 1, fieldDescription }}
                      />
                    )}
                    onChange={(e) => {
                      this.customize(e.target.value, index, fieldId)
                    }}
                  >
                    <Input value={value} placeholder={placeholder} />
                  </Form.Item>
                ))
              }
            </div>
          )
        })}
        <Button
          type='primary'
          disabled={!!isIncomplete}
          onClick={() => {
            customizeProduct(cart_id, customizations)
            onClose()
          }}
        >
          <FormattedMessage id='save' defaultMessage='Enregistrer' />
        </Button>
      </Drawer>
    )
  }
}

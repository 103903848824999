import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Workshop as WorkshopContainer,
} from '../../containers'
import { Refresher } from '../../components/molecules'
import { INIT_FETCH_DATA_WORKSHOP } from '../../store/baseProducts/actions'

const WorkshopPage = (props) => {
  const { history, fetchData } = props
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div style={{
      transition: '0.5s',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: 'white',
      zIndex: 100,
    }}
    >
      <Refresher fetchData={fetchData} />
      <WorkshopContainer history={history} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA_WORKSHOP,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(WorkshopPage))

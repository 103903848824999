import { pdf } from '@react-pdf/renderer'
import {
  Alert,
  Button,
  Comment,
  Descriptions,
  Divider,
  Drawer,
  Icon,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Rate,
  Select,
  Switch,
  Tag,
  notification,
} from 'antd'
import { saveAs } from 'file-saver'
import { compact, sortBy, uniqBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  formatColorsFromFamilies,
  getCost as getCostCalcul,
  getMargin,
  getPrice as getPriceCalcul,
  trimBaseProductForDraft,
} from '../../../helpers/baseProducts/creation'

import { formatProductForCatalogCart } from '../../../helpers/catalog-hubspot'
import { addToBPCart } from '../../../store/baseProductsCart/actions'
import { fromBaseProductsCart } from '../../../store/selectors'

import {
  CatalogBPEditionDrawer,
  CatalogProviderDrawer,
  CatalogSheet, LangSwitch,
  ShopifyActionModal,
} from '../..'

import config from '../../../config'
import CatalogCartDisplayButton from '../../../containers/BaseProductsCart/molecules/CatalogCartDisplayButton'

const { image_base_url: imageBaseUrl } = config

const { Option } = Select
const { TextArea } = Input

const Pricing = (props) => {
  const {
    baseProduct,
  } = props
  const {
    caracteristics = {},
    families,
    pricing,
    technics,
  } = baseProduct

  const dispatch = useDispatch()

  const cartContent = useSelector(fromBaseProductsCart.getBpCartContent)

  const [colors, setColors] = useState([])
  const [technicSelected, setTechnicSelected] = useState()
  const [technicsSelected, setTechnicsSelected] = useState([])
  const [color, setColor] = useState()
  const [cost, setCost] = useState(0)
  const [costByQuantity, setCostByQuantity] = useState(0)
  const [marge, setMarge] = useState(false)
  const [price, setPrice] = useState(0)
  const [priceByQuantity, setPriceByQuantity] = useState(0)
  const [moq, setMoq] = useState(1)
  const [quantity, setQuantity] = useState(0)
  // La répartition des tailles est une string non normé ( input libre ) atm, implémenté pour hubspot
  const [sizeRepartition, setSizeRepartition] = useState('')
  // la Zone de marquage est une string non normé atm, implémenté par hubspot
  const [markingZone, setMarkingZone] = useState('')
  // la couleur du marquage est une string non normé
  const [markingColor, setMarkingColor] = useState('')
  if (colors && !colors.length) {
    setColors(formatColorsFromFamilies(families))
  }

  const getCost = (_quantity, _technicsSelected, _color = color) => {
    try {
      let costImpact = 0
      if (_color && _color.costImpact) {
        costImpact = _color.costImpact
      } if (_color && _color.priceImpact && baseProduct.pricing.margin) {
        costImpact = getCostCalcul(_color.priceImpact, baseProduct.pricing.margin)
      }
      const totalCost = _technicsSelected.map((technic) => {
        let stageIndex = 0
        technic.parameters.stage.sort((a, b) => a - b).map((s) => {
          if (s <= _quantity) {
            stageIndex = technic.parameters.stage.indexOf(s)
            return true
          } return true
        })
        return technic.parameters.cost.sort((a, b) => a - b).reverse()[stageIndex]
      }).reduce((a, b) => a + b, 0)
      setCost((pricing.costHT + costImpact + totalCost).toFixed(2))
      setCostByQuantity(((pricing.costHT + costImpact + totalCost) * _quantity).toFixed(2))
      return (pricing.costHT + costImpact + totalCost).toFixed(2)
    } catch (e) {
      console.log(e, 'error molecules/CatalogBPDrawer/getCost()')
      return <FormattedMessage id='catalogBPDrawer.error' defaultMessage='Erreur, veuillez contacter un génie' />
    }
  }

  const getPrice = (_quantity, _marge = false, _technicsSelected, changeMarge = false, _cost = 0, _color = color) => {
    try {
      let priceImpact = 0
      let totalPrice = 0
      let priceHT = 0
      if (_color && _color.priceImpact) {
        priceImpact = _color.priceImpact
      } else if (_color && _color.costImpact && pricing.margin) {
        priceImpact = getPriceCalcul(_color.costImpact, pricing.margin)
      }
      if (_marge || _marge === 0) {
        priceHT = getPriceCalcul(pricing.costHT, _marge)
        totalPrice = _technicsSelected.map((technic) => {
          let stageIndex = 0
          technic.parameters.stage.sort((a, b) => a - b).map((s) => {
            if (s <= _quantity) {
              stageIndex = technic.parameters.stage.indexOf(s)
              return true
            } return true
          })
          return getPriceCalcul(technic.parameters.cost.sort((a, b) => a - b).reverse()[stageIndex], _marge)
        }).reduce((a, b) => a + b, 0)
      } else {
        if (pricing.priceHT === 0 && pricing.costHT !== 0) {
          priceHT = getPriceCalcul(pricing.costHT, pricing.margin)
        } else {
          priceHT = pricing.priceHT
        }
        totalPrice = _technicsSelected.map((technic) => {
          let stageIndex = 0
          technic.parameters.stage.sort((a, b) => a - b).map((s) => {
            if (s <= _quantity) {
              stageIndex = technic.parameters.stage.indexOf(s)
              return true
            } return true
          })
          return technic.parameters.price.sort((a, b) => a - b).reverse()[stageIndex]
        }).reduce((a, b) => a + b, 0)
      }
      const finalPrice = (priceHT + priceImpact + totalPrice)
      setPrice(finalPrice.toFixed(2))
      setPriceByQuantity((finalPrice * _quantity).toFixed(2))
      if (changeMarge && finalPrice && _cost) {
        setMarge(parseFloat(((finalPrice - (_cost)) / finalPrice) * 100).toFixed(2))
      }
      return true
    } catch (e) {
      console.log(e, 'error molecules/CatalogBPDrawer/getCost()')
      return <FormattedMessage id='catalogBPDrawer.error' defaultMessage='Erreur, veuillez contacter un génie' />
    }
  }

  // Quand une technique est rajouté, on recalcule le plus petit MOQ des techniques
  // on update le MOQ, la quantité, le Cost (prix d'achat HT) et le Price (prix de vente HT)
  useEffect(() => {
    let technicMoq = Math.max(...technicsSelected.map(((_technic) => _technic.parameters.stage?.sort((a, b) => a - b).find(Boolean))))
    if (technicMoq === Infinity) technicMoq = 1
    setMoq(technicMoq)
    setQuantity(technicMoq > quantity ? technicMoq : quantity)
    setPriceByQuantity()
    const _cost = getCost(technicMoq, technicsSelected)
    getPrice(technicMoq, marge, technicsSelected, true, _cost)
  }, [technicsSelected.length])

  useEffect(() => {
    if (!technicsSelected.length) {
      setQuantity(0)
    }
  }, [technicsSelected.length])

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex', color: 'rgba(0, 0, 0, 0.85)' }}>
        <div style={{
          display: 'flex', flex: '1 2', flexDirection: 'column', marginRight: '10px',
        }}
        >
          <div style={{
            fontWeight: 'bold', flex: '1 1', marginBottom: '10px',
          }}
          >
            <FormattedMessage id='techniques' defaultMessage='Techniques' />
          </div>
          <div style={{ display: 'flex' }}>
            <Select
              style={{ width: '100%' }}
              placeholder={<FormattedMessage id='catalogBPDrawer.placeholder.techniques' defaultMessage='Ajouter une ou plusieurs techniques' />}
              value={technicSelected ? technicSelected.idPanopli : undefined}
              allowClear
              onChange={(value) => {
                setTechnicSelected(technics.find((t) => t.idPanopli === value))
              }}
            >
              {technics && technics.length && technics.map((_technic) => (
                <Option value={_technic.idPanopli} key={_technic.idPanopli}>{_technic.name}</Option>
              ))}
            </Select>
            <Button
              disabled={!technicSelected}
              style={{ marginLeft: '10px' }}
              type='primary'
              onClick={async () => {
                const newSelected = technicsSelected
                newSelected.push(technicSelected)
                setTechnicsSelected(newSelected)
                setTechnicSelected()
              }}
            >
              <FormattedMessage id='add' defaultMessage='Ajouter' />
            </Button>
          </div>
        </div>
        <div style={{ display: 'flex', flex: '1 2', flexDirection: 'column' }}>
          <div style={{
            fontWeight: 'bold', marginBottom: '10px',
          }}
          >
            <FormattedMessage id='colors' defaultMessage='Couleurs' />
          </div>
          <Select
            style={{ width: '100%' }}
            value={color && color.reference}
            placeholder={<FormattedMessage id='catalogBPDrawer.placeholder.colors' defaultMessage='Sélectionner une couleur' />}
            allowClear
            onChange={async (value) => {
              if (!value) {
                setColor()
                const _cost = await getCost(quantity, technicsSelected, {})
                getPrice(quantity, false, technicsSelected, marge || false, _cost, {})
              } else {
                const newColor = colors.find((_var) => _var.reference === value)
                setColor(newColor)
                const _cost = await getCost(quantity, technicsSelected, newColor)
                getPrice(quantity, false, technicsSelected, marge || false, _cost, newColor)
              }
            }}
          >
            {colors.map((_color) => (
              <Option value={_color.reference} key={_color.reference}>
                <span>
                  <img
                    style={{ width: '18px', marginRight: '10px' }}
                    alt='pastille'
                    src={`${imageBaseUrl}/Pastille/low/${_color.name}.png`}
                  />
                </span>
                {_color.name}
              </Option>
            ))}
          </Select>
        </div>

      </div>
      {technicsSelected && technicsSelected.length ? (
        <>
          <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
            <div style={{
              display: 'flex', flexDirection: 'column', marginRight: '35px', width: '45%',
            }}
            >
              <span style={{ fontWeight: 'bold', marginTop: '10px', color: 'rgba(0, 0, 0, 0.85)' }}>
                <FormattedMessage id='catalogBpDrawer.sizes-repartition' defaultMessage='Répartition des tailles' />
              </span>

              <Input onChange={(e) => setSizeRepartition(e.target.value)} />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{
              display: 'flex', flexDirection: 'column', marginRight: '35px', width: '45%',
            }}
            >
              <span style={{ fontWeight: 'bold', marginTop: '10px', color: 'rgba(0, 0, 0, 0.85)' }}>
                <FormattedMessage id='catalogBpDrawer.marking-color' defaultMessage='Couleur(s) du marquage' />
              </span>

              <Input onChange={(e) => setMarkingColor(e.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
              <span style={{ fontWeight: 'bold', marginTop: '10px', color: 'rgba(0, 0, 0, 0.85)' }}>
                <FormattedMessage id='catalogBpDrawer.marking-zone' defaultMessage='Zone(s) de marquage' />
              </span>
              <Input onChange={(e) => setMarkingZone(e.target.value)} />
            </div>
          </div>
          <div style={{ display: 'flex' }}>

            <div style={{ flex: '1 2' }}>
              <div style={{
                fontWeight: 'bold', marginTop: '10px', color: 'rgba(0, 0, 0, 0.85)',
              }}
              >
                <FormattedMessage id='catalogBPDrawer.selectedTechniques' defaultMessage='Liste des techniques sélectionnées' />
              </div>
              <div style={{
                fontSize: 12, marginTop: '10px', display: 'flex', color: 'rgba(0, 0, 0, 0.85)', flexDirection: 'column',
              }}
              >
                {technicsSelected.map((technic, index) => (
                  <div style={{ marginBottom: '2px' }} key={`technic-${technic.name}`}>
                    {`- ${technic.name}`}
                    <Button
                      type='danger'
                      icon='delete'
                      shape='circle'
                      size='small'
                      style={{ marginLeft: '10px' }}
                      onClick={async () => {
                        const newTechnicsSelected = technicsSelected
                        newTechnicsSelected.splice(index, 1)
                        setTechnicsSelected(newTechnicsSelected)
                        const _cost = await getCost(quantity, newTechnicsSelected)
                        getPrice(quantity, false, newTechnicsSelected, true, _cost)
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div style={{ marginLeft: '10px', flex: '1 2' }}>
              <div style={{
                fontWeight: 'bold', flex: '1 1', marginTop: '10px', color: 'rgba(0, 0, 0, 0.85)',
              }}
              >
                <FormattedMessage id='quantity' defaultMessage='Quantité' />
              </div>
              <InputNumber
                type='number'
                value={quantity}
                style={{ width: '100%', textAlign: 'right' }}
                min={moq}
                onChange={async (value) => {
                  if (value < moq) return false
                  await setQuantity(value)
                  const _cost = await getCost(value, technicsSelected)
                  return getPrice(value, false, technicsSelected, true, _cost)
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: '10px', marginBottom: '5px' }}>
            <div style={{
              fontWeight: 'bold',
              flex: '1 1',
              marginBottom: '10px',
              color: 'rgba(0, 0, 0, 0.85)',
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <FormattedMessage id='purchasePriceExcl' defaultMessage="Prix d'achat HT" />
            </div>
            <div style={{
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <FormattedMessage
                id='catalogBPDrawer.cost'
                defaultMessage='{cost}€/u soit {costByQuantity}€'
                values={{ cost, costByQuantity }}
              />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{
              fontWeight: 'bold',
              flex: '1 2',
              marginBottom: '10px',
              color: 'rgba(0, 0, 0, 0.85)',
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <FormattedMessage id='catalogBPDrawer.markingRate' defaultMessage='Taux de marque' />
            </div>
            <div style={{ marginBottom: '10px', flex: '1 2' }}>
              <Input
                value={marge}
                addonAfter='%'
                style={{ textAlign: 'right' }}
                onChange={(e) => {
                  const { value } = e.target
                  setMarge(parseFloat(value))
                  getPrice(quantity, parseFloat(value), technicsSelected)
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{
              fontWeight: 'bold',
              marginBottom: '10px',
              color: 'rgba(0, 0, 0, 0.85)',
              display: 'flex',
              alignItems: 'center',
              width: '50%',
            }}
            >
              <FormattedMessage id='catalogBPDrawer.recommendedSellingPriceExcl' defaultMessage='Prix de vente HT conseillé' />
            </div>
            <div style={{
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
              width: '50%',
            }}
            >
              <Input
                value={price}
                style={{
                  width: '100%',
                  textAlign: 'right',
                  flex: '1 2',
                  marginRight: '5px',
                }}
                addonAfter='€/u'
                onChange={(e) => {
                  const floatValue = e.target.value
                  setPrice(floatValue)
                  const newMargin = getMargin(floatValue, cost)
                  setPriceByQuantity(floatValue * quantity)
                  setMarge(parseFloat(newMargin))
                }}
              />
              <FormattedMessage id='catalogBPDrawer.whether' defaultMessage='soit' />
              <Input
                value={priceByQuantity}
                style={{
                  width: '100%',
                  textAlign: 'right',
                  flex: '1 2',
                  marginLeft: '5px',
                }}
                addonAfter='€'
                onChange={(e) => {
                  const { value } = e.target
                  setPriceByQuantity(value)
                  const newPrice = value / quantity
                  const newMargin = getMargin(newPrice, cost)
                  setPrice(parseFloat(Math.round(newPrice) === newPrice ? newPrice : (newPrice).toFixed(2)))
                  setMarge(parseFloat(newMargin))
                }}
              />
            </div>

          </div>
          <div>
            <Button
              onClick={() => {
                if (cartContent.length >= 20) {
                  notification.error({ message: (<FormattedMessage id='bp-cart.limit' defaultMessage='Votre panier est limité à 20 produits' />) })
                  return false
                }
                return dispatch(addToBPCart(formatProductForCatalogCart(
                  baseProduct, price, cost, quantity, technicsSelected, color, sizeRepartition, markingZone, markingColor,
                )))
              }}
              type='primary'
              style={{ width: '100%' }}
              disabled={!color}
            >
              Ajouter au panier
            </Button>
          </div>
        </>
      ) : (
        <Alert
          message={<FormattedMessage id='catalogBPDrawer.required.technique' defaultMessage='Une technique doit être sélectionnée' />}
          type='warning'
          style={{ marginTop: '10px' }}
          closable
          showIcon
        />
      )}

      <Divider />
      <Descriptions title={<FormattedMessage id='catalogBPDrawer.deliveryInformations' defaultMessage='Informations livraison' />}>
        <Descriptions.Item label={<FormattedMessage id='deliveryDelay' defaultMessage='Délais de livraison' />}>
          {(caracteristics && caracteristics.delayDelivery) || 'NC'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='markingOrigin' defaultMessage='Origine de marquage' />}>
          {(caracteristics && caracteristics.originMark) || 'NC'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='productOrigin' defaultMessage='Origine du produit' />}>
          {(caracteristics && caracteristics.originProduct) || 'NC'}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

const Provider = (props) => {
  const { allProviders, baseProduct, setVisible } = props
  const provider = allProviders.find((pro) => baseProduct.providerId === pro._id)
  if (!provider) return null
  const {
    name = 'NC',
    websiteUrl,
    phone = 'NC',
    email = 'NC',
    nameContact = 'NC',
  } = provider
  return (
    <>
      <Descriptions
        title={(
          <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <FormattedMessage id='supplier' defaultMessage='Fournisseur' />
            <Button
              type='dashed'
              icon='edit'
              style={{
                color: 'black',
              }}
              onClick={() => setVisible(true)}
            >
              {' '}
              <FormattedMessage id='edit' defaultMessage='Editer' />
            </Button>
          </div>
        )}
      >
        <Descriptions.Item label={<FormattedMessage id='compagny' defaultMessage='Société' />}>{name}</Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='website' defaultMessage='Site web' />}>
          {websiteUrl ? (
            <a aria-label='Lien du site web' target='_blank' rel='noopener noreferrer' href={websiteUrl}>
              <FormattedMessage id='directLink' defaultMessage='lien direct' />
            </a>
          ) : 'NC'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='phone' defaultMessage='Téléphone' />}>{phone}</Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='contactName' defaultMessage='Nom du contact' />}>{nameContact}</Descriptions.Item>
        <Descriptions.Item span={2} label={<FormattedMessage id='email' defaultMessage='Email' />}>{email}</Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='supplierProductId' defaultMessage='ID du produit chez le fournisseur' />}>
          {baseProduct.providerProductId}
        </Descriptions.Item>
        <Descriptions.Item span={2} label={<FormattedMessage id='supplierProductLink' defaultMessage='Lien du produit chez le fournisseur' />}>
          {baseProduct.supplierProductUrl ? (
            <a aria-label='Lien du produit chez le fournisseur' target='_blank' rel='noopener noreferrer' href={baseProduct.supplierProductUrl}>
              <FormattedMessage id='directLink' defaultMessage='lien direct' />
            </a>
          ) : 'NC'}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
    </>
  )
}

const CarbonImpact = (props) => {
  const { baseProduct } = props
  const { carbonImpact = {} } = baseProduct
  const {
    global = 'NC',
    manufacturing = 'NC',
    marking = 'NC',
    transport = 'NC',
    packaging = 'NC',
    endOfLife = 'NC',
  } = carbonImpact

  return (
    <>
      <Descriptions column={5} title={<FormattedMessage id='carbonImpact' defaultMessage='Empreinte carbone (kgCO2eq)' />}>
        <Descriptions.Item span={5} label={<FormattedMessage id='global' defaultMessage='Impact total produit' />}>
          {global}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={<FormattedMessage id='manufacturing' defaultMessage='Fabrication' />}>
          {manufacturing}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={<FormattedMessage id='marking' defaultMessage='Marquage' />}>
          {marking}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={<FormattedMessage id='transport' defaultMessage='Transport' />}>
          {transport}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={<FormattedMessage id='packaging' defaultMessage='Packaging' />}>
          {packaging}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={<FormattedMessage id='endOfLife' defaultMessage='Fin de vie' />}>
          {endOfLife}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
    </>
  )
}

const Caracteristic = (props) => {
  const { baseProduct, language } = props
  const {
    category = {},
    subCategory,
    caracteristics = {},
    customizable = false,
    pricing,
  } = baseProduct

  return (
    <Descriptions title={<FormattedMessage id='characteristics' defaultMessage='Caractéristiques' />}>
      <Descriptions.Item span={1} label={<FormattedMessage id='category' defaultMessage='Catégorie' />}>
        {category[language] && category[language].length
          ? category[language].find((cat) => cat)
          : 'NC'}
      </Descriptions.Item>
      <Descriptions.Item span={2} label={<FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />}>
        {subCategory[language] || 'NC'}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id='weight' defaultMessage='Poids' />}>
        {(caracteristics && caracteristics.weight) || 'NC'}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id='capacity' defaultMessage='Capacité' />}>
        {(caracteristics && caracteristics.capacity) || 'NC'}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id='dimension' defaultMessage='Dimension' />}>
        {(caracteristics && caracteristics.dimension) || 'NC'}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id='constitution' defaultMessage='Constitution' />}>
        {(caracteristics && caracteristics.constitution) || 'NC'}
      </Descriptions.Item>
      {pricing && pricing.tva ? (
        <Descriptions.Item label={<FormattedMessage id='customVAT' defaultMessage='TVA personnalisée' />}>
          {`${pricing.tva} %`}
        </Descriptions.Item>
      ) : null}
      <Descriptions.Item label={<FormattedMessage id='customizable' defaultMessage='Personnalisable' />}>
        <Switch checked={customizable} />
      </Descriptions.Item>
      <Descriptions.Item label='Rupture de stock (OOS)'>
        <Switch checked={caracteristics?.OOS} />
      </Descriptions.Item>
    </Descriptions>
  )
}

const Comments = injectIntl((props) => {
  const {
    baseProduct, comments, content, setContent, postComment, userId, deleteComment,
  } = props
  const intl = useIntl()
  return (
    <div>
      <div style={{
        fontWeight: 'bold', flex: '1 1', color: 'rgba(0, 0, 0, 0.85)', marginBottom: 10,
      }}
      >
        <FormattedMessage id='comments' defaultMessage='Commentaires' />
      </div>
      {comments.length ? comments.map((comment) => (
        <div
          key='comment'
          style={{
            display: 'flex', width: '100%', alignItems: 'center', marginBottom: 10,
          }}
        >
          {userId === comment.idUser ? (
            <Button
              size='small'
              icon='delete'
              type='primary'
              onClick={() => {
                deleteComment({ id: comment._id })
              }}
            />
          ) : null}
          <Comment
            className='bpComment'
            author={<span style={{ fontSize: 14 }}>{comment.author || 'NC'}</span>}
            content={comment.content}
            datetime={<span style={{ fontSize: 12 }}>{moment(comment.createdAt).format('DD-MM-YYYY HH:mm')}</span>}
          />
        </div>
      )) : null}
      <div>
        <TextArea
          placeholder={intl.formatMessage({
            id: 'catalogBPDrawer.placeholder.leaveAComment',
            defaultMessage: 'Laissez un commentaire...',
          })}
          value={content}
          onChange={(e) => {
            setContent(e.target.value)
          }}
        />
        <Button
          type='primary'
          style={{ marginTop: 10 }}
          onClick={() => {
            if (content) {
              postComment({
                content,
                idBaseProduct: baseProduct._id,
              })
              setContent('')
            }
          }}
        >
          <FormattedMessage id='send' defaultMessage='Envoyer' />
        </Button>
      </div>
    </div>
  )
})

const CustomDot = ({
  onClick, active, index, carouselState, images,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { currentSlide } = carouselState
  return (
    <li style={{
      margin: '5px 5px 0 0',
    }}
    >
      <div
        style={{ background: active ? 'grey' : 'initial' }}
        onClick={() => onClick()}
      >
        <img
          src={images[index].src}
          alt={images[index].alt}
          style={{
            width: 90,
            borderRadius: active ? '0' : '4px',
            filter: active ? 'grayscale(100%)' : '',
            objectFit: 'cover',
            height: '100%',
          }}
        />
      </div>
    </li>
  )
}

const CatalogBPDrawer = (props) => {
  try {
    const {
      visible,
      onClose,
      key,
      baseProduct = {},
      baseProducts,
      DBBaseProducts,
      history,
      saveDraft,
      allProviders,
      draftProducts,
      uploadToShopify,
      postComment,
      comments = [],
      userId,
      deleteComment,
      modifyProvider,
      modifyBP,
      providerModalOpened,
      statusModalOpened,
      closeProviderModal,
      closeStatusModal,
      shopifyLoading,
      shopifyActionModal,
      closeActionModal,
      getAllCategories,
      getAllSubCategories,
      getAllOrigins,
      getAllStatus,
      allTechnics,
    } = props
    const {
      _id,
      idPanopli = baseProduct.shopifyHandle ? baseProduct.shopifyHandle : false,
      name = {},
      shopifyHandle = false,
      caracteristics,
    } = baseProduct
    const [language, setLanguage] = useState('fr')
    const [providerDrawerVisible, setProviderDrawerVisible] = useState(false)
    const [editionDrawerVisible, setEditionDrawerVisible] = useState(false)
    const catalogCartVisible = useSelector(fromBaseProductsCart.isVisible)
    const catalogCartContent = useSelector(fromBaseProductsCart.getBpCartContent)
    const onProviderDrawerClose = () => {
      setProviderDrawerVisible(false)
    }

    const onEditionDrawerClose = () => {
      setEditionDrawerVisible(false)
    }

    const [content, setContent] = useState('')

    const addToDraftProducts = async () => {
      const newDraftProduct = await trimBaseProductForDraft(baseProduct, baseProducts, allProviders)
      draftProducts.unshift(newDraftProduct)
      const _draftProducts = compact(uniqBy(draftProducts, '_id'))
      saveDraft({ draftProducts: _draftProducts })
      return _draftProducts
    }

    const getDataForCarousel = () => {
      if (baseProduct.images && baseProduct.images.length) return sortBy(baseProduct.images, 'position')
      if (baseProduct.picList && baseProduct.picList.length) return baseProduct.picList
      if (baseProduct.shopifyImages && baseProduct.shopifyImages) return baseProduct.shopifyImages
      return [{ src: `${imageBaseUrl}/SVGS/${_id}/baseProduct/display.png` }]
    }

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    }

    const providerModificationModal = () => {
      notification.open({
        message: (
          <div>
            <FormattedMessage id='modification' defaultMessage='Modification' />
          </div>),
        placement: 'bottomRight',
        duration: 10,
        description: <FormattedMessage id='catalogBPDrawer.message.supplierModified' defaultMessage='Le fournisseur a bien été modifié !' />,
        key: '123456789',
        onClose: notification.close(),
      })
      closeProviderModal()
    }

    const statusModificationModal = () => {
      notification.open({
        message: (
          <div>
            <FormattedMessage id='modification' defaultMessage='Modification' />
          </div>),
        placement: 'bottomRight',
        duration: 10,
        description: <FormattedMessage id='catalogBPDrawer.message.statusModified' defaultMessage='Le statut du produit a bien été modifié !' />,
        key: '987654321',
        onClose: notification.close(),
      })
      closeStatusModal()
    }

    const templateDownload = () => {
      try {
        const { newBaseProduct = false } = baseProduct
        if (newBaseProduct) {
          return (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '20px',
            }}
            >
              <Button
                type='primary'
                href={`${imageBaseUrl}/NewBaseProducts/${_id}/template.png`}
                download
                target='_blank'
              >
                <Icon type='download' />
                {' '}
                <FormattedMessage id='catalogBPDrawer.downloadTemplate' defaultMessage='Télécharger le gabarit' />
              </Button>
            </div>
          )
        }
        return null
      } catch (e) {
        console.log('templateDownload error', e)
        return null
      }
    }

    return (
      <Drawer
        key={key}
        destroyOnClose
        visible={visible}
        onClose={onClose}
        width='75%'
        title={(
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormattedMessage id='additionalInformations' defaultMessage='Informations complémentaires' />
            <div style={{ marginRight: '40px', display: 'flex' }}>
              <LangSwitch
                defaultValue={language}
                onChange={(e) => {
                  setLanguage(e.target.value)
                }}
                style={{ marginRight: '10px' }}
              />
              <Button
                style={{ marginRight: '10px' }}
                onClick={async () => {
                  const newDraftProducts = await addToDraftProducts()
                  notification.open({
                    message: (
                      <div>
                        <FormattedMessage id='steps.summary' defaultMessage='Récapitulatif' />
                      </div>),
                    placement: 'bottomRight',
                    duration: 10,
                    description: (
                      <>
                        <div>
                          {newDraftProducts.map((product) => (
                            <div>
                              {`- ${product.name && product.name.fr}`}
                            </div>
                          ))}
                        </div>
                        <Button
                          style={{ float: 'left', marginTop: '10px' }}
                          type='primary'
                          onClick={() => {
                            notification.close('12345')
                            history.push('/workshop')
                          }}
                        >
                          <FormattedMessage id='catalogBPDrawer.goToWorkshop' defaultMessage="Aller à l'atelier !" />
                          <span style={{ marginLeft: '5px' }} role='img' aria-label='emoji'>🔧</span>
                        </Button>
                      </>),
                    key: '12345',
                    onClose: notification.close(),
                  })
                }}
              >
                <FormattedMessage id='catalogBPDrawer.addToModifications' defaultMessage='Ajouter à la liste de modification' />
              </Button>
              <CatalogCartDisplayButton catalogCartContent={catalogCartContent} catalogCartVisible={catalogCartVisible} />
              <Button
                style={{ marginRight: '10px' }}
                type='primary'
                onClick={async () => {
                  await addToDraftProducts()
                  history.push('/workshop')
                }}
              >
                <FormattedMessage id='catalogBPDrawer.directModification' defaultMessage="Modifier directement dans l'atelier" />
              </Button>
              <Button
                type='dashed'
                style={{ marginRight: '10px' }}
                onClick={async () => {
                  const pdfBaseProduct = [baseProduct]

                  const blob = await pdf((
                    <CatalogSheet
                      baseProducts={pdfBaseProduct}
                    />
                  )).toBlob()
                  if (blob) saveAs(blob, name[language])
                }}
              >
                <Icon type='download' />
                .pdf
              </Button>
              <Popconfirm
                title={(
                  <span>
                    <FormattedMessage id='catalogBPDrawer.execute' defaultMessage="Exécuter l'action" />
                    baseProduct.isOnShopify
                    ?
                    {' '}
                    <FormattedMessage id='catalogBPDrawer.updateOnShopify' defaultMessage='Actualiser sur Shopify' />
                    :
                    {' '}
                    <FormattedMessage id='catalogBPDrawer.addToShopify' defaultMessage='Rajouter sur Shopify' />
                  </span>
                )}
                onConfirm={() => uploadToShopify({ baseProduct })}
                onCancel={(e) => console.log(e)}
                okText={<FormattedMessage id='yes' defaultMessage='Oui' />}
                cancelText={<FormattedMessage id='no' defaultMessage='Non' />}
                disabled={shopifyLoading}
              >
                <Button
                  loading={shopifyLoading}
                  disabled={shopifyLoading}
                >
                  { baseProduct.isOnShopify
                    ? <FormattedMessage id='catalogBPDrawer.updateOnShopify' defaultMessage='Actualiser sur Shopify' />
                    : <FormattedMessage id='catalogBPDrawer.addToShopify' defaultMessage='Rajouter sur Shopify' /> }
                </Button>
              </Popconfirm>
            </div>
          </div>
        )}
      >
        <div style={{
          display: 'flex',
        }}
        >
          <Modal
            visible={shopifyActionModal}
            width='75%'
            destroyOnClose
            closable
            footer={null}
            onCancel={() => closeActionModal()}
          >
            <ShopifyActionModal baseProduct={baseProduct} uploadToShopify={uploadToShopify} closeActionModal={closeActionModal} />
          </Modal>
          <div style={{ width: '35%', marginTop: '7px' }}>
            <div style={{ position: 'relative' }}>
              <Carousel
                showDots
                responsive={responsive}
                customDot={<CustomDot images={getDataForCarousel()} />}
                renderDotsOutside
                infinite
              >
                {getDataForCarousel().map((image) => (
                  <>
                    <img
                      alt={image.alt}
                      src={image.src}
                      key={image.src}
                      style={{
                        width: '100%',
                        maxHeight: '100%',
                        borderRadius: '4px',
                      }}
                    />
                    {caracteristics?.OOS ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          position: 'absolute',
                          zIndex: 1000,
                          right: -15,
                          bottom: 4,
                          width: '80px',
                          transform: 'rotate(-35deg)',
                          backgroundColor: '#FF0D3D',
                          color: 'white',
                          fontWeight: 700,
                        }}
                      >
                        <div style={{ marginLeft: 6 }}>
                          OOS
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
              </Carousel>
            </div>
            {templateDownload()}
          </div>
          <div style={{ width: '65%', padding: '0 20px' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1',
              marginBottom: '10px',
              color: 'rgba(0, 0, 0, 0.85)',
            }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold' }}>
                  {name[language] ? name[language] : <FormattedMessage id='catalogBPDrawer.notTranslated' defaultMessage='*Pas traduit*' />}
                </div>
                <Button
                  type='dashed'
                  icon='edit'
                  style={{
                    color: 'black',
                  }}
                  onClick={() => setEditionDrawerVisible(true)}
                >
                  {' '}
                  <FormattedMessage id='edit' defaultMessage='Editer' />
                </Button>
              </div>
              {idPanopli ? (
                <div style={{ color: 'grey' }}>
                  {idPanopli}
                  {shopifyHandle ? (
                    <>
                      <Divider type='vertical' />
                      <a
                        aria-label='Lien catalogue'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`https://www.panopli.co/produits-catalogue/${shopifyHandle}`}
                      >
                        <Icon type='link' style={{ color: '#012169', marginRight: '5px' }} />
                        <FormattedMessage id='catalogBPDrawer.shopifyLink' defaultMessage='Lien catalogue' />
                      </a>
                    </>
                  ) : null}
                  {baseProduct.rate && (
                    <>
                      <Divider type='vertical' />
                      <Rate
                        value={baseProduct.rate}
                        character={<Icon type='heart' theme='filled' />}
                        allowHalf
                        disabled
                      />
                    </>
                  )}
                </div>
              ) : null}
            </div>
            {baseProduct.tags && baseProduct.tags.length ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                flexWrap: 'wrap',
              }}
              >
                {baseProduct.tags.map((tag) => <Tag key={tag} style={{ marginBottom: '7px' }}>{tag}</Tag>)}
              </div>
            ) : null}
            <Pricing
              baseProduct={baseProduct}
            />
            <Divider />
            <Comments
              comments={comments.filter((comment) => comment.idBaseProduct === baseProduct._id)}
              baseProduct={baseProduct}
              content={content}
              setContent={setContent}
              postComment={postComment}
              userId={userId}
              deleteComment={deleteComment}
            />
            <Divider />
            {baseProduct.providerId
              ? (
                <Provider
                  allProviders={allProviders}
                  baseProduct={baseProduct}
                  setVisible={setProviderDrawerVisible}
                />
              )
              : null}
            <CarbonImpact baseProduct={baseProduct} />
            <Caracteristic baseProduct={baseProduct} language={language} />
          </div>
        </div>
        <CatalogProviderDrawer
          visible={providerDrawerVisible}
          onClose={onProviderDrawerClose}
          baseProduct={baseProduct}
          allProviders={allProviders}
          providerId={baseProduct.providerId}
          modifyProvider={modifyProvider}
        />
        <CatalogBPEditionDrawer
          visible={editionDrawerVisible}
          onClose={onEditionDrawerClose}
          baseProduct={baseProduct}
          modifyBP={modifyBP}
          DBBaseProducts={DBBaseProducts}
          getAllCategories={getAllCategories}
          getAllSubCategories={getAllSubCategories}
          getAllOrigins={getAllOrigins}
          getAllStatus={getAllStatus}
          allTechnics={allTechnics}
          draftProducts={draftProducts}
        />
        {providerModalOpened ? providerModificationModal() : null}
        {statusModalOpened ? statusModificationModal() : null}
      </Drawer>
    )
  } catch (e) {
    console.log('e CatalogBPDrawer', e)
    return null
  }
}

export default CatalogBPDrawer

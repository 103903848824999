// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
export const FETCH = 'FETCH_INVOICES'
export const RECEIVE = 'RECEIVE'
export const RECEIVED = 'RECEIVED'
export const FETCH_MORE = 'FETCH_MORE_INVOICES'
export const FETCH_FORCE = 'FETCH_FORCE_INVOICES'
export const RECEIVE_MORE = 'RECEIVE_MORE_INVOICES'
export const INIT_FETCH_DATA = 'INIT_FETCH_DATA_INVOICES'
export const END_FETCH_DATA = 'END_FETCH_DATA_INVOICES'

import React, { useState } from 'react'
import { Button, Drawer, message } from 'antd'
import { SketchPicker } from 'react-color'
import { FormattedMessage } from 'react-intl'

import { getInitialCustom } from '../../../helpers/hiddenCustom'

import {
  HiddenCustomShop,
  HiddenCustomNavMenu,
  HiddenCustomBanner,
  HiddenCustomHeader,
  HiddenCustomRollingMessage,
  HiddenCustomFilters,
  HiddenCustomListing,
  HiddenCustomProductPage,
  HiddenCustomCheckout,
  HiddenCustomFooter,
  HiddenCustomConversion,
} from '../../molecules'

const HiddenCustom = (props) => {
  try {
    const { shop, modifyShop } = props
    const { settings, _id, owner } = shop
    const { theme = {}, hideBottomReassurances } = settings
    const { custom } = theme

    const initialCustom = getInitialCustom(custom)

    const [newHideReassurance, setNewHideReassurance] = useState(Boolean(hideBottomReassurances))
    const [newCustom, setNewCustom] = useState(initialCustom)
    const [colorState, setColorState] = useState({
      currentColor: null,
      groupName: null,
      propName: null,
    })

    const { currentColor, groupName, propName } = colorState

    const touched = (newHideReassurance !== Boolean(hideBottomReassurances)) || (JSON.stringify(newCustom) !== JSON.stringify(initialCustom))

    const resetColor = () => setColorState({
      ...colorState,
      currentColor: null,
      groupName: null,
      propName: null,
    })

    const handleColorChange = (color) => {
      const trimColor = (rgbaColor) => `rgba(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b},${rgbaColor.a})`
      setColorState({ ...colorState, currentColor: trimColor(color.rgb) })
    }

    const saveColor = () => {
      try {
        const bis = { ...newCustom }
        bis[groupName][propName] = currentColor
        setNewCustom(bis)
        // Le fait de réinitialiser le hook de couleurs ferme également le drawer
        resetColor()
      } catch (e) {
        console.log('Hidden Custom - saveColor error:', e)
      }
    }

    const saveShop = () => {
      try {
        modifyShop({
          shop_id: _id,
          modifications: {
            settings: {
              ...settings,
              hideBottomReassurances: newHideReassurance,
              theme: {
                ...theme,
                custom: {
                  ...custom,
                  ...newCustom,
                },
              },
            },
            owner,
          },
          callback: () => {
            message.info(
              <FormattedMessage
                id='updateSuccess.shop'
                defaultMessage='Boutique mise à jour'
              />,
            )
            return true
          },
        })
      } catch (e) {
        console.log('Custom - saveShop error:', e)
      }
    }

    const reset = () => {
      setNewCustom(initialCustom)
      setNewHideReassurance(Boolean(hideBottomReassurances))
    }

    return (
      <div
        className='hiddenCustom'
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '24px',
          gap: '16px',
        }}
      >
        <Drawer
          title={(
            <FormattedMessage
              id='custom.colorSelect'
              defaultMessage='Sélecteur de couleur :'
            />
          )}
          visible={Boolean(groupName)}
          width='40%'
          onClose={resetColor}
        >
          <SketchPicker
            color={currentColor || 'rgba(255, 255, 255, 0)'}
            width='auto'
            onChange={(color) => handleColorChange(color)}
            style={{
              padding: '10px 0',
              border: 'none',
            }}
          />
          <Button
            onClick={saveColor}
            type='primary'
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: '100%',
              borderRadius: 0,
            }}
          >
            <FormattedMessage id='validate' defaultMessage='Valider' />
          </Button>
        </Drawer>
        <HiddenCustomShop
          newCustom={newCustom}
          setNewCustom={setNewCustom}
          newHideReassurance={newHideReassurance}
          setNewHideReassurance={setNewHideReassurance}
        />
        <HiddenCustomNavMenu newCustom={newCustom} setNewCustom={setNewCustom} />
        <HiddenCustomBanner newCustom={newCustom} setNewCustom={setNewCustom} />
        <HiddenCustomHeader
          newCustom={newCustom}
          setNewCustom={setNewCustom}
          colorState={colorState}
          setColorState={setColorState}
        />
        <HiddenCustomRollingMessage
          newCustom={newCustom}
          setNewCustom={setNewCustom}
          colorState={colorState}
          setColorState={setColorState}
        />
        <HiddenCustomFilters newCustom={newCustom} setNewCustom={setNewCustom} />
        <HiddenCustomListing
          newCustom={newCustom}
          setNewCustom={setNewCustom}
          colorState={colorState}
          setColorState={setColorState}
        />
        <HiddenCustomProductPage
          newCustom={newCustom}
          setNewCustom={setNewCustom}
          colorState={colorState}
          setColorState={setColorState}
        />
        <HiddenCustomCheckout newCustom={newCustom} setNewCustom={setNewCustom} />
        <HiddenCustomFooter newCustom={newCustom} setNewCustom={setNewCustom} />
        <HiddenCustomConversion newCustom={newCustom} setNewCustom={setNewCustom} />
        {touched && (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            position: 'fixed',
            top: 104,
            right: 15,
          }}
          >
            <Button type='primary' size='large' onClick={saveShop}>
              <FormattedMessage id='save' defaultMessage='Enregistrer' />
            </Button>
            <Button size='large' onClick={reset}>
              <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
            </Button>
          </div>
        )}
      </div>
    )
  } catch (e) {
    console.log('HiddenCustom error:', e)
    return null
  }
}

export default HiddenCustom

import React from 'react'
import { Empty, Card } from 'antd'
import ProductGrid from '../../molecules/ProductGrid'
import { OrganizationTable } from '..'

const OrganizationEditor = (props) => {
  try {
    const {
      items = [],
      shopSettings,
      listingView,
      setListingOrder,
      showMeta,
      setNewIndex,
      editDesign,
      currentListing,
      viewport,
    } = props

    const {
      listingOrder = [],
      listingElements = [],
    } = shopSettings

    const mergedItems = [
      ...listingElements,
      ...items.filter((i) => i.status === 'Active'),
    ]

    if (mergedItems.length < 1) {
      return (
        <Card>
          <Empty />
        </Card>
      )
    }

    if (listingView === 'grid') {
      return (
        <ProductGrid
          items={mergedItems}
          showMeta={showMeta}
          editDesign={editDesign}
          shopSettings={shopSettings}
          viewport={viewport}
          sortingArray={listingOrder}
          setListingOrder={setListingOrder}
        />
      )
    }

    return (
      <div style={{ paddingBottom: '24px' }}>
        <OrganizationTable
          items={mergedItems}
          shopSettings={shopSettings}
          currentListing={currentListing}
          editDesign={editDesign}
          setNewIndex={setNewIndex}
          sortingArray={listingOrder}
          setListingOrder={setListingOrder}
        />
      </div>
    )
  } catch (error) {
    console.log('OrganizationEditor - render', { error })
    return null
  }
}

export default OrganizationEditor

import React from 'react'
import {
  FormStepInput,
} from '../..'

const CampaignNameInput = (props) => {
  const {
    campaign, // Required
    setCampaign, // Required
    setIsContinueDisabled,
    setNeedUpdateCampaign, // Required
    createdCampaignId,
    isDisabled = false,
  } = props

  const isCampaignParametersSet = (name) => !!(name && name.length)

  const onChangeName = (e) => {
    setCampaign({ ...campaign, name: e.target.value })
    if (createdCampaignId) {
      setNeedUpdateCampaign(true)
    }
    if (setIsContinueDisabled) {
      setIsContinueDisabled(!isCampaignParametersSet(e.target.value))
    }
  }

  return (
    <FormStepInput
      titleMessageId='campaigns.create.step.parameters.title_campaign'
      titleDefaultMesage='Titre de la campagne'
      placeholderMessageId='campaigns.create.step.parameters.title_campaign.placeholder'
      placeholderDefaultMessage='Exemple : campagne closing'
      inputValue={campaign?.name}
      onChangeFunction={onChangeName}
      disabled={isDisabled}
    />
  )
}

export default CampaignNameInput

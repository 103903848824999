import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'antd'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { FormattedMessage } from 'react-intl'
import { Checkout } from '../../containers'
import { InnerLayout } from '../../layouts'
import config from '../../config'

const stripePromise = loadStripe(config.stripeKey)

export default withRouter(({ history }) => (
  <InnerLayout
    title={<h3><FormattedMessage id='ordering' defaultMessage='Commander' /></h3>}
    extra={[
      <Button
        type='primary'
        onClick={() => history.push('/products')}
      >
        <FormattedMessage id='return' defaultMessage='Retour' />
      </Button>,
    ]}
  >
    <div className='Checkout page'>
      <Elements stripe={stripePromise}>
        <Checkout />
      </Elements>
    </div>
  </InnerLayout>
))

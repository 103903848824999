import React from 'react'
import {
  Switch,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

const HiddenCustomFilters = (props) => {
  try {
    const { newCustom, setNewCustom } = props
    const { filters } = newCustom
    const { hideAll } = filters
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage id='filters' defaultMessage='Filtres' />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.filtersHideAll'
                defaultMessage='Masquer tous les filtres'
              />
            </div>
            <Switch
              checked={hideAll}
              onChange={(value) => setNewCustom({ ...newCustom, filters: { ...filters, hideAll: value } })}
            />
          </div>
          <div style={columnStyle} />
          <div style={columnStyle} />
          <div style={columnStyle} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecule/HiddenCustomFilters error:', e)
    return null
  }
}

export default HiddenCustomFilters

import React from 'react'
import {
  Switch,
  Tooltip,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

import { ColorButton } from '../../atoms'

const HiddenCustomProductPage = (props) => {
  try {
    const {
      newCustom, setNewCustom, colorState, setColorState,
    } = props
    const { productPage } = newCustom
    const {
      priceColor, displayAllDescription, hideDetailsProduct, hideSocialShare, hideColorVariation, hideQuantityPicker, alwaysAddToCart,
    } = productPage
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage
            id='custom.productPage'
            defaultMessage='Page produit'
          />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.productPagePriceColor'
                defaultMessage='Couleur de police pour les prix'
              />
            </div>
            <ColorButton
              color={priceColor}
              onClick={() => setColorState({
                ...colorState,
                currentColor: priceColor,
                groupName: 'productPage',
                propName: 'priceColor',
              })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.productPageDisplayAllDescription'
                defaultMessage='Afficher la description entière'
              />
            </div>
            <Switch
              checked={displayAllDescription}
              onChange={(value) => setNewCustom({ ...newCustom, productPage: { ...productPage, displayAllDescription: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.productPageHideDetailsProduct'
                defaultMessage='Masquer les détails du produit'
              />
            </div>
            <Switch
              checked={hideDetailsProduct}
              onChange={(value) => setNewCustom({ ...newCustom, productPage: { ...productPage, hideDetailsProduct: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.productPageHideSocialShare'
                defaultMessage='Masquer les réseaux sociaux'
              />
            </div>
            <Switch
              checked={hideSocialShare}
              onChange={(value) => setNewCustom({ ...newCustom, productPage: { ...productPage, hideSocialShare: value } })}
            />
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.productPageHideColorVariation'
                defaultMessage='Masquer le sélecteur de couleur'
              />
            </div>
            <Switch
              checked={hideColorVariation}
              onChange={(value) => setNewCustom({ ...newCustom, productPage: { ...productPage, hideColorVariation: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.productPageHideQuantityPicker'
                defaultMessage='Masquer le sélecteur de quantité'
              />
            </div>
            <Tooltip
              title={(
                <FormattedMessage
                  id='custom.helper1'
                  // eslint-disable-next-line max-len
                  defaultMessage="Masque le sélecteur de quantité pour tout le shop (ne permet d'ajouter au panier qu'un seul exemplaire de chaque produit)."
                />
              )}
              placement='bottom'
            >
              <Switch
                checked={hideQuantityPicker}
                onChange={(value) => setNewCustom({ ...newCustom, productPage: { ...productPage, hideQuantityPicker: value } })}
              />
            </Tooltip>
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.productPageAlwaysAddToCart'
                defaultMessage='Afficher seulement "Ajouter au panier"'
              />
            </div>
            <Switch
              checked={alwaysAddToCart}
              onChange={(value) => setNewCustom({ ...newCustom, productPage: { ...productPage, alwaysAddToCart: value } })}
            />
          </div>
          <div style={columnStyle} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecule/HiddenCustomProductPage error:', e)
    return null
  }
}

export default HiddenCustomProductPage

import React from 'react'

import { MagicLinksSelection, CustomizableTable } from '../..'
import {
  getMagicLinksByFilters, isMagicLinksAdmin, getWantedColumns,
} from '../../../helpers/magicLinks'
import { trimRecordForMagicLinksTable } from '../../../helpers/trim'

const MagicLinksTrackingTable = (props) => {
  try {
    const {
      filters = {},
      selection = [],
      setSelection,
      user,
      shopOwner,
      campaigns,
      magicLinks,
      collaborators,
      locale,
      setStepBeforeResend,
      setResendOne,
      selectable,
      style,
      wantedColumns,
      isPaginated = false,
    } = props

    const isAdmin = isMagicLinksAdmin(user, shopOwner)
    const columns = getWantedColumns(
      isAdmin,
      (magicLink) => {
        setResendOne(magicLink)
        setStepBeforeResend(true)
      },
      locale,
      wantedColumns,
      campaigns,
    )

    const rowSelection = {
      // On définit les lignes sélectionnées directement à partir de celles enregistrées dans le state
      selectedRowKeys: selection,
      onChange: (selections) => setSelection(selections),
    }

    // On format les magic
    const trimmedMagicLinks = magicLinks.map((magicLink) => trimRecordForMagicLinksTable(magicLink, campaigns, collaborators, locale))
    // On filtre les MagicLinks selon les filtres utilisés par le user
    const filteredMagicLinks = getMagicLinksByFilters(campaigns, trimmedMagicLinks, collaborators, filters)

    return (
      <>
        <MagicLinksSelection
          selection={selection}
          setSelection={setSelection}
          magicLinks={filteredMagicLinks}
          setStepBeforeResend={setStepBeforeResend}
        />
        <CustomizableTable
          columns={columns}
          dataSource={selectable ? filteredMagicLinks : trimmedMagicLinks}
          isPaginated={isPaginated}
          isSelectable={selectable}
          rowSelection={rowSelection}
          rowKey={(record) => record._id}
          style={style}
        />
      </>
    )
  } catch (e) {
    console.log('organism/MagicLinksTrackingTable error:', e)
    return null
  }
}

export default MagicLinksTrackingTable

import React, { useState } from 'react'
import { Select } from 'antd'
import { FormattedMessage } from 'react-intl'
import { CustomizableSelect } from '..'
import { sortProductsByMoreRecentDate, sortProductsByMoreRecentDateReverse } from '../../../helpers/sorters'

const { Option } = Select

const RecentSorter = (props) => {
  const {
    setSorter, // Required
  } = props
  const DEFAULT_FILTER = 'recent'
  const [selectedSorter, setSelectedSorter] = useState(DEFAULT_FILTER)

  const handleOnChangeDateSorter = (sorter) => {
    setSelectedSorter(sorter)
    setSorter(sorter === DEFAULT_FILTER ? [sortProductsByMoreRecentDate] : [sortProductsByMoreRecentDateReverse])
  }

  return (
    <CustomizableSelect
      value={selectedSorter}
      formattedMessageId='campaigns.create.step.products.filters.display'
      formattedDefaultMessage='Affichage'
      onChange={handleOnChangeDateSorter}
    >
      <Option value='recent' key='recent'>
        <FormattedMessage id='campaigns.create.step.products.filters.display.recent' defaultMessage='Ajouts récents' />
      </Option>
      <Option value='old' key='old'>
        <FormattedMessage id='campaigns.create.step.products.filters.display.old' defaultMessage='Ajouts anciens' />
      </Option>
    </CustomizableSelect>
  )
}

export default RecentSorter

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { AnimatePresence, motion } from 'framer-motion'

import { Tooltip } from 'antd'
import { character, primaryColor } from '../../../config/theme'
import { ProductAvatar, Placeholder } from '../..'

const placeholderVariants = {
  enter: { opacity: 0 },
  center: { opacity: 1 },
  exit: { opacity: 0 },
}

const productsVariants = {
  enter: {
    x: 300,
    opacity: 0,
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 300,
    opacity: 0,
  },
}

const CampaignProductsRecap = (props) => {
  try {
    const { selectedProducts, removeSelectedProduct } = props

    return (
      <div style={{ height: '100%' }}>
        {!selectedProducts.length ? (
          <motion.div
            variants={placeholderVariants}
            initial='enter'
            animate='center'
            exit='exit'
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.5 },
            }}
            style={{ height: '100%' }}
          >
            <Placeholder
              description={(
                <FormattedMessage
                  id='campaign.products.recap.placeholder'
                  defaultMessage='Sélectionnez les produits que vous souhaitez ajouter à votre campagne'
                />
              )}
              style={{
                position: 'none',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                lineHeight: '22px',
                color: character.secondary,
              }}
            />
          </motion.div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <AnimatePresence mode='wait'>
              {selectedProducts.map((product) => {
                const { name } = product
                const productId = product._id || product.id
                return (
                  <motion.div
                    key={productId}
                    variants={productsVariants}
                    initial='enter'
                    animate='center'
                    exit='exit'
                    transition={{
                      x: { type: 'spring', stiffness: 300, damping: 30 },
                      opacity: { duration: 0.5 },
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <ProductAvatar product={product} />
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                    }}
                    >
                      <Tooltip title={name}>
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 12,
                            lineHeight: '20px',
                            color: character.title,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {name}
                        </span>
                      </Tooltip>
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 10,
                          color: primaryColor[6],
                          cursor: 'pointer',
                        }}
                        onClick={() => removeSelectedProduct(product)}
                      >
                        <FormattedMessage id='delete' defaultMessage='Supprimer' />
                      </span>
                    </div>
                  </motion.div>
                )
              })}
            </AnimatePresence>
          </div>
        )}
      </div>
    )
  } catch (e) {
    console.log('molecules/CampaignProductsRecap error:', e)
    return null
  }
}

export default CampaignProductsRecap

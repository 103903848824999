/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import { notification } from 'antd'
import { flatten } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import config from '../../config'
import { trimBaseProductForDraft } from '../../helpers/baseProducts/creation'
import { uploadAction } from '../../helpers/imageUpload'
import { shopifyFormat } from '../../helpers/shopify'
import { getDiffBetweenObject } from '../../helpers/trim'
import { getCodes } from '../codes/sagas'
import { getComments } from '../comments/sagas'
import { fetchAllEntities, fetchEntities } from '../entities/sagas'
import * as familiesActions from '../families/actions'
import { getFamilies } from '../families/sagas'
import * as pastillesActions from '../pastilles/actions'
import * as productsActions from '../products/actions'
import * as providersActions from '../providers/actions'
import { getProviders } from '../providers/sagas'
import {
  fromBaseProducts,
  fromProviders,
  fromTechnics,
  fromUsers,
} from '../selectors'
import { fetchTechnics } from '../technics/sagas'
import * as actions from './actions'

const { image_base_url: imageBaseUrl } = config

const { getBPs } = fromBaseProducts
const { getAuth } = fromUsers
const { getAllTechnics } = fromTechnics

export function* assignTechnics(data) {
  const { dbTechnics } = yield select(getAllTechnics)
  return data.map((bp) => {
    if (bp.technics) {
      return {
        ...bp,
        technics: bp.technics.map((technic) => {
          if (technic._id) {
            return dbTechnics?.find((_technic) => _technic._id === technic._id)
          } return technic
        }),
      }
    }
    return bp
  })
}

export function* getBpForComparaison(id, api) {
  const auth = yield select(getAuth)
  const { data } = yield call([api, api.get], '/base_product', { headers: { authorization: auth } })
  const filtered = data.filter((bp) => String(bp._id) === String(id))
  const assigned = yield call(assignTechnics, filtered)
  return assigned.find(Boolean)
}

export function* uploadToShopify(api, action) {
  try {
    const { baseProduct } = action.payload
    const { isOnShopify } = baseProduct
    const auth = yield select(getAuth)
    const { dbTechnics } = yield select(getAllTechnics)
    const allProviders = yield select(fromProviders.getProviders)
    const test = shopifyFormat(baseProduct, allProviders, dbTechnics)
    const {
      ColorsToCreate, ProductToCreate, TechnicsToCreate, formattedProduct, requireAction,
    } = test
    if (requireAction) {
      return yield put({
        type: actions.SHOPIFY_REQUIRE_ACTION,
        payload: {
          requireAction,
        },
      })
    }

    // WE NEED TO CHECK ALL ID TO PREVENT COLISION
    // CARE FOR "RECURENT TECHNICS" AS THEY WILL EXIST

    if (isOnShopify) {
      yield call([api, api.put],
        '/shopify',
        {
          baseProduct: formattedProduct,
          target: String(baseProduct._id),
          colors: ColorsToCreate,
          technics: TechnicsToCreate,
          products: ProductToCreate,
        },
        {
          headers: { authorization: auth },
        })
    } else {
      yield call([api, api.post],
        '/shopify',
        {
          baseProduct: formattedProduct,
          target: String(baseProduct._id),
          colors: ColorsToCreate,
          technics: TechnicsToCreate,
          products: ProductToCreate,
        },
        {
          headers: { authorization: auth },
        })
    }

    yield put({
      type: actions.UPLOADED_TO_SHOPIFY,
    })
    notification.open({
      message: isOnShopify
        ? (<FormattedMessage id='store.bp.upToDate' defaultMessage='{name} actualisé avec succès !' values={{ name: baseProduct.name.fr }} />)
        : (<FormattedMessage id='store.bp.modified' defaultMessage='{name} modifié avec succès !' values={{ name: baseProduct.name.fr }} />),
      placement: 'bottomRight',
      duration: 8,
    })
    yield put({
      type: actions.FETCH_FORCE,
    })
    return 1
  } catch (e) {
    console.log('SAGA ERROR')
    return -1
  }
}

export function* fetchForceBPs(api) {
  try {
    const auth = yield select(getAuth)
    yield put({ type: actions.GET })
    const { data } = yield call([api, api.get], '/base_product', { headers: { authorization: auth } })
    const newData = yield call(assignTechnics, data)
    yield put({ type: actions.RECEIVED_FORCE, payload: { data: newData } })
    return true
  } catch (e) {
    console.log('getBPS error', e, e.message)
    yield put({
      type: actions.ERROR,
      payload: {
        error_details: (e.message || e),
      },
    })
    return false
  }
}

export function* fetchBPs(api) {
  try {
    // const products = yield select(allProducts)
    let existingBPs = yield select(getBPs)
    // TODO arbiter cache
    const auth = yield select(getAuth)
    yield put({ type: actions.GET })
    const { data } = yield call([api, api.get], '/base_product', { headers: { authorization: auth } })
    existingBPs = yield call(assignTechnics, data)
    yield put({ type: actions.RECEIVED, payload: { data: existingBPs } })
    // NOTE we only ask for proper ids (24length), not custom (only zeros), and for new ids
    // const bpsIds = uniq(products.map((p) => p.supplierBaseProductId)
    //   .filter((id) => id.length === 24 &&
    //     id !== '000000000000000000000000' &&
    //     !existingBPs.map((bp) => String(bp._id)).includes(id)))
    // NOTE we need to send all bps to ASSIGN
    yield put({ type: productsActions.ASSIGN, payload: { data: existingBPs } })
    yield put({ type: pastillesActions.FETCH_PASTILLES })
    yield put({ type: familiesActions.GET_FAMILIES })
    return true
  } catch (e) {
    console.log('getBPS error', e, e.message)
    yield put({
      type: actions.ERROR,
      payload: {
        error_details: (e.message || e),
      },
    })
    return false
  }
}

const formatVariationsFromFamiliesAndSizes = (families, sizes, baseProduct) => {
  const colors = families?.length ? flatten((families).map((f) => f.colors.map((c) => ({
    type: 'color',
    name: c,
    priceImpact: f.priceImpact,
    idPanopli: f.idPanopli,
    reference: String(Math.random()),
  })))) : baseProduct.families
  const formattedSizes = sizes?.length ? sizes.map((s) => ({
    name: s.name,
    type: 'size',
    reference: String(Math.random()),
  })) : baseProduct.variations.filter((v) => v.type === 'size')
  return colors.concat(formattedSizes)
}

const arbitrateModifications = (baseProduct, modifs = {}) => {
  const { picListToUpload, families, sizes } = modifs
  const spread = {}
  const newModifs = { ...modifs }
  if (picListToUpload) {
    delete newModifs.picListToUpload
  }
  if (families?.length || sizes?.length) {
    spread.variations = formatVariationsFromFamiliesAndSizes(families, sizes, baseProduct)
  }

  if (baseProduct.variations.length && baseProduct.variations.find((variation) => variation.colors)) {
    const newColorsVariations = []
    baseProduct.variations.map((variation) => {
      if (variation.colors) {
        variation.colors.map((color) => {
          newColorsVariations.push({
            name: color,
            priceImpact: 0,
            reference: Math.random().toString(36).substring(7),
            type: 'color',
            comboExclusion: [],
            comboSpecific: [],
          })
          return null
        })
      }
      return null
    })
    spread.variations = [...newColorsVariations, ...baseProduct.variations.filter((variation) => variation.type === 'size')]
  }

  return { ...newModifs, ...spread }
}

export function* modifyBaseProduct(api, action) {
  try {
    const { baseProduct } = action.payload
    const { pastilleToUpload } = baseProduct
    if (baseProduct.pastilleToUpload) {
      delete baseProduct.pastilleToUpload
    }
    const existingBps = yield select(getBPs)
    const existingProviders = yield select(fromProviders.getProviders)
    const canonicalBp = yield call(getBpForComparaison, String(baseProduct._id), api)
    const formatted = yield call(trimBaseProductForDraft, canonicalBp, existingBps, existingProviders, { bypassUpload: true })
    const reconciliate = getDiffBetweenObject(baseProduct, formatted)
    const { picListToUpload } = reconciliate
    const modifications = arbitrateModifications(baseProduct, reconciliate)
    yield call([api, api.put], '/base_product', {
      modifications,
      target: String(baseProduct._id),
    })
    // ON SUCCESS WE UPLOAD IMAGES
    const baseUrl = `NewBaseProducts/${String(baseProduct._id)}/`
    if (pastilleToUpload && pastilleToUpload.length) {
      yield pastilleToUpload.map((pic) => uploadAction(
        `Pastille/low/${pic.color}.png`,
        pic,
      ))
    }
    if (picListToUpload && picListToUpload.length) {
      yield Promise.all(picListToUpload.map((image) => {
        const { name, alt } = image
        return uploadAction(baseUrl + (name.replaceAll(' ', '') || alt.replaceAll(' ', '')), image)
      }))

      const { images = [] } = baseProduct
      const newImages = picListToUpload.map((image, index) => {
        const {
          name, alt, fromShopify = false, size,
        } = image
        return {
          id: String(Math.random()),
          src: `${imageBaseUrl}/${baseUrl}${name.replaceAll(' ', '').replaceAll('%', '%25') || alt.replaceAll(' ', '').replaceAll('%', '%25')}`,
          createdAt: new Date(),
          position: index + 1,
          isOnShopify: fromShopify,
          alt,
          name,
          size,
        }
      })
      yield call([api, api.put], '/base_product',
        {
          modifications: {
            images: images.concat(newImages),
            shopifyImages: null,
          },
          target: String(baseProduct._id),
        })
    }

    yield call(fetchForceBPs, api)

    yield put({
      type: providersActions.GET_PROVIDERS,
    })
    notification.open({
      message: (<FormattedMessage id='store.bp.modified' defaultMessage='{name} modifié avec succès !' values={{ name: baseProduct.name.fr }} />),
      placement: 'bottomRight',
      duration: 8,
    })
    return 1
  } catch (e) {
    console.log('ModifyBaseProduct - error', e)
    notification.open({
      message: (<FormattedMessage id='store.bp.notModified' defaultMessage="Le produit n'a pas été modifié" />),
      placement: 'bottomRight',
      duration: 8,
    })
    return -1
  }
}

export function* createBaseProduct(api, action) {
  try {
    const baseProduct = action.payload
    const authorization = yield select(fromUsers.getAuth)
    const result = yield call([api, api.post], '/base_product', {
      ...baseProduct,
      picListToUpload: null,
      picLists: null,
    }, {
      headers: {
        authorization,
      },
    })
    const { newBaseProduct } = result
    if (baseProduct.picListToUpload && baseProduct.picListToUpload.length) {
      yield baseProduct.picListToUpload.map((pic) => uploadAction(
        `NewBaseProducts/${newBaseProduct._id}/${pic.name.replaceAll(' ', '')}`,
        pic,
      ))
    } if (baseProduct.pastilleToUpload && baseProduct.pastilleToUpload.length) {
      yield baseProduct.pastilleToUpload.map((pic) => uploadAction(
        `Pastille/low/${pic.color}.png`,
        pic,
      ))
    }
    const picLists = baseProduct.picLists && baseProduct.picLists.length ? baseProduct.picLists : []

    const images = picLists.concat(baseProduct.picListToUpload.map((pic, index) => ({
      _id: Math.random().toString(36).substring(7),
      src: `${imageBaseUrl}/NewBaseProducts/${newBaseProduct._id}/${pic.name.replaceAll(' ', '').replaceAll('%', '%25')}`, // url
      name: pic.name,
      createdAt: new Date(),
      position: index + 1,
      isOnShopify: false,
      alt: pic.name,
      size: pic.size,
    })))
    const modifications = {
      images,
    }
    if (baseProduct.dematerialized) {
      modifications.dematerialized = true
      modifications.technics = baseProduct.technics
    }
    yield call([api, api.put], '/base_product',
      {
        modifications,
        target: String(newBaseProduct._id),
      })
    yield call(fetchForceBPs, api)
    yield put({
      type: providersActions.GET_PROVIDERS,
    })
    notification.open({
      message: (<FormattedMessage id='store.bp.created' defaultMessage='{name} créé avec succès !' values={{ name: baseProduct.name.fr }} />),
      placement: 'bottomRight',
      duration: 8,
    })
    return result
  } catch (error) {
    yield put({
      type: actions.CREATE_ERROR,
      payload: {
        error,
      },
    })
    notification.open({
      message: (<FormattedMessage id='store.bp.notCreated' defaultMessage="Le produit n'a pas été créé" />),
      placement: 'bottomRight',
      duration: 8,
    })
    return false
  }
}

export function* getDataForCatalogPage(api) {
  yield call(fetchTechnics, api)
  yield all([
    call(getProviders, api),
    call(getComments, api),
    call(fetchBPs, api),
  ])
  yield put({ type: actions.END_FETCH_DATA_CATALOG })
}

export function* getDataForWorkshopPage(api) {
  yield call(fetchTechnics, api)
  yield all([
    call(fetchBPs, api),
    call(getProviders, api),
    call(getFamilies, api),
    call(getCodes, api),
    call(fetchAllEntities, api),
    call(fetchEntities, api),
  ])
  yield put({ type: actions.END_FETCH_DATA_WORKSHOP })
}

export default function* ({ api }) {
  yield takeLatest(actions.INIT_FETCH_DATA_WORKSHOP, getDataForWorkshopPage, api)
  yield takeLatest(actions.INIT_FETCH_DATA_CATALOG, getDataForCatalogPage, api)
  yield takeEvery(actions.CREATE_BASEPRODUCT, createBaseProduct, api)
  yield takeEvery(actions.MODIFY_BASEPRODUCT, modifyBaseProduct, api)
  yield takeLatest(actions.UPLOAD_TO_SHOPIFY, uploadToShopify, api)
  yield takeEvery(actions.FETCH_FORCE, fetchForceBPs, api)
}

import React from 'react'
import { Divider } from 'antd'

import { character } from '../../../config/theme'
import { CloseDrawerButton } from '../..'

const DrawerTitle = (props) => {
  const {
    title, // Required
    subtitle, // Required
    onClose, // Required
    style,
    titleStyle,
    subtitleStyle,
    noDivider,
    closeButtonStyle,
    closeButtonIconStyle,
    closeButtonIcon,
    noCloseButton,
    children,
  } = props
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      ...style,
    }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      >
        <span style={{
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '32px',
          color: character.title,
          ...titleStyle,
        }}
        >
          {title}
        </span>
        {!noCloseButton && (
          <CloseDrawerButton
            onClick={onClose}
            style={closeButtonStyle}
            iconStyle={closeButtonIconStyle}
            icon={closeButtonIcon}
          />
        )}
      </div>
      <span style={{
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: character.secondary,
        ...subtitleStyle,
      }}
      >
        {subtitle}
      </span>
      {noDivider
        ? <div>{children}</div>
        : <Divider style={{ margin: '32px 0' }} />}
    </div>
  )
}

export default DrawerTitle

import React from 'react'
import { notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

import { errorColor, validColor } from '../../config/theme'

const notify = ({
  intl,
  type = 'open',
  messageId,
  defaultMessage,
  descriptionId,
  defaultDescription,
  placement = 'bottomRight',
  duration = 8,
  style = {
    fontSize: '14px',
    fontWeight: '600',
  },
  descriptionStyle = {
    fontSize: '12px',
    fontWeight: '500',
  },
  icon,
  btn,
  key,
}) => notification[type]({
  message: (
    <span style={style}>
      {intl.formatMessage({
        id: messageId,
        defaultMessage,
      })}
    </span>),
  ...(descriptionId && defaultDescription && {
    description: (
      <span style={descriptionStyle}>
        {intl.formatMessage({
          id: descriptionId,
          defaultDescription,
        })}
      </span>),
  }),
  placement,
  duration,
  icon,
  btn,
  key,
})

const successNotify = ({
  intl,
  messageId,
  defaultMessage,
  descriptionId,
  defaultDescription,
  descriptionStyle,
  style,
  btn,
  key,
}) => notify({
  intl,
  type: 'success',
  messageId,
  defaultMessage,
  descriptionId,
  defaultDescription,
  descriptionStyle,
  style,
  icon: (<FontAwesomeIcon icon={faCircleCheck} style={{ color: validColor[6] }} />),
  btn,
  key,
})

const errorNotify = ({
  intl,
  messageId,
  defaultMessage,
  descriptionId,
  defaultDescription,
  descriptionStyle,
  style,
  btn,
  key,
}) => notify({
  intl,
  type: 'error',
  messageId,
  defaultMessage,
  descriptionId,
  defaultDescription,
  descriptionStyle,
  style,
  icon: (<FontAwesomeIcon icon={faCircleExclamation} style={{ color: errorColor[6] }} />),
  btn,
  key,
})

export { notify, successNotify, errorNotify }

import React, { useState } from 'react'
import {
  Form, Input, Typography,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { requiresEmail, validateEmailWithCallback, noSpaceInEmail } from '../../../helpers/emailValidation'

const { Title } = Typography

const CheckoutFormContactInformation = injectIntl(({
  type = 'client',
  getFieldDecorator,
  setPresetAddress,
}) => {
  if (setPresetAddress && !setPresetAddress.street) return null
  const [input, setInput] = useState(null)
  const intl = useIntl()
  return (
    <>
      <Title level={4} style={{ marginLeft: '10px' }}>
        <FormattedMessage id='checkoutForm.contactDetails' defaultMessage='Vos coordonnées' />
      </Title>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.firstName`, {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='checkoutForm.required.firstName' defaultMessage='Veuillez renseigner votre prénom' />,
                  whitespace: true,
                },
              ],
            })(<Input
              placeholder={intl.formatMessage({
                id: 'contactInformation.placeholder.firstName',
                defaultMessage: 'Prénom',
              })}
            />)}
          </Form.Item>
        </div>
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.lastName`, {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='checkoutForm.required.lastName' defaultMessage='Veuillez renseigner votre nom' />,
                  whitespace: true,
                },
              ],
            })(<Input
              placeholder={intl.formatMessage({
                id: 'contactInformation.placeholder.lastName',
                defaultMessage: 'Nom',
              })}
            />)}
          </Form.Item>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.email`, {
              initialValue: '',
              rules: [
                { validator: requiresEmail },
                { validator: validateEmailWithCallback },
                { validator: noSpaceInEmail },
              ],
            })(<Input
              placeholder={intl.formatMessage({
                id: 'contactInformation.placeholder.eMail',
                defaultMessage: 'Email',
              })}
              value={input}
              onChange={(e) => setInput(e.target.value.replaceAll(' ', ''))}
            />)}
          </Form.Item>
        </div>
        <div
          style={{
            flex: '1 1',
            margin: '0 10px',
          }}
        >
          <Form.Item>
            {getFieldDecorator(`${type}.phone`, {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='checkoutForm.required.phone' defaultMessage='Veuillez entrer votre numéro de téléphone' />,
                  whitespace: true,
                },
              ],
            })(<Input
              placeholder={intl.formatMessage({
                id: 'contactInformation.placeholder.telephoneNumber',
                defaultMessage: 'Numéro de téléphone',
              })}
            />)}
          </Form.Item>
        </div>
      </div>
    </>
  )
})

export default CheckoutFormContactInformation

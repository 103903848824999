import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import {
  Drawer,
  Select,
  message,
  Form,
  Button,
  Tag,
} from 'antd'

import { fromShops, fromEntities } from '../../store/selectors'
import { MODIFY } from '../../store/shops/actions'

const { Item } = Form
const { Option } = Select

export default Form.create({ name: 'entityForm' })(connect(
  (state) => ({
    shop: fromShops.getShop(state),
    entities: fromEntities.getEntities(state),
  }), (dispatch) => bindActionCreators({
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)((props) => {
  const {
    visibleDrawer,
    onClose,
    modifyShop,
    form: {
      getFieldDecorator,
      isFieldsTouched,
      resetFields,
      validateFieldsAndScroll,
    },
    identifiers,
    entities,
    shop: { _id, settings },
  } = props

  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          entityId,
        } = values
        const addresses = settings.addresses.map((a) => (identifiers.includes(a.identifier) ? { ...a, entityId } : a))
        const modifications = {
          settings: {
            ...settings,
            addresses,
          },
        }
        modifyShop({
          shop_id: _id,
          modifications,
          callback: () => {
            setLoading(false)
            resetFields()
            onClose()
            message.info(
              <FormattedMessage id='defineEntity.success' defaultMessage='Entité définie !' />,
            )
            return true
          },
        })
      }
    })
  }

  return (
    <Drawer
      title={<FormattedMessage id='defineEntity.assignTitle' defaultMessage="Assigner à l'entité" />}
      width={640}
      closable
      visible={visibleDrawer}
      onClose={onClose}
    >
      <Form
        id='entityForm'
        onSubmit={handleSubmit}
        style={{ position: 'relative' }}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', margin: '5px 0',
        }}
        >
          <div style={{ color: 'black', margin: '0 5px 8px 0' }}>
            <FormattedMessage id='defineAddressesEntity.selection' defaultMessage='Adresse(s) sélectionnée(s) :' />
          </div>
          <div style={{
            display: 'flex', flexWrap: 'wrap', maxHeight: '75vh', overflowY: 'scroll',
          }}
          >
            {identifiers.map((r) => (
              <Tag style={{ margin: '0 5px 5px 0' }}>
                {settings.addresses.find((a) => a.identifier === r)?.name}
              </Tag>
            ))}
          </div>
        </div>
        <Item label={<FormattedMessage id='entity' defaultMessage='Entité' />}>
          {getFieldDecorator('entityId', {
            rules: [{
              required: true,
              message: <FormattedMessage id='required.entity' defaultMessage='Veuillez choisir une entité' />,
            }],
            initialValue: identifiers.length === 1
              ? entities.find((entity) => entity._id === settings?.addresses?.find((a) => a.identifier === identifiers[0])?.entityId)?.name
              : undefined,
          })(
            <Select
              placeholder={<FormattedMessage id='defineEntity.placeholder' defaultMessage='Choisissez une entité...' />}
              showSearch
              filterOption={(input, option) => option.props.children
                .toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                entities.map((entity) => (
                  <Option value={entity._id} key={entity._id}>{entity.name}</Option>
                ))
              }
            </Select>,
          )}
        </Item>
        {isFieldsTouched() ? (
          <Button
            type='primary'
            htmlType='submit'
            style={{
              width: '100%',
              borderRadius: 0,
            }}
            disabled={loading}
            loading={loading}
            onClick={(e) => {
              setLoading(true)
              handleSubmit(e)
            }}
          >
            <FormattedMessage id='validate' defaultMessage='Valider' />
          </Button>
        ) : null}
      </Form>
    </Drawer>
  )
}))

import React from 'react'
import { Input, Icon } from 'antd'
import { FormattedMessage } from 'react-intl'

const DealRecap = (props) => {
  const { deal, onClear } = props
  if (!deal) {
    return null
  }
  return (
    <div>
      <span><FormattedMessage id='bp-cart-associated-deal' defaultMessage='Deal associé' /></span>
      <Input
        value={deal.name}
        style={{ marginTop: '10px', width: '100%' }}
        suffix={<Icon type='close-circle' onClick={onClear} />}
      />
    </div>
  )
}

export default DealRecap

/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Popconfirm, Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { fromShops } from '../../store/selectors'
import { MODIFY_SEVERAL } from '../../store/products/actions'
import theme from '../../config/theme'

// const { MODIFY_SEVERAL } = products

const {
  white,
  errorColor,
} = theme

export default connect(
  (state) => ({
    shop: fromShops.getId(state),
  }),
  (dispatch, {
    selected,
  }) => bindActionCreators({
    deleteProducts: (shop) => ({
      type: MODIFY_SEVERAL,
      payload: {
        selected,
        values: {
          shops: 'delete',
        },
        shop,
      },
    }),
  }, dispatch),
)(({
  selected = [],
  delete_discounts,
  shop,
}) => (
  <Popconfirm
    title={selected.length > 1
      ? (
        <FormattedMessage
          id='deleteProducts.title.plural'
          defaultMessage='Vous allez supprimer {number} produits'
          values={{ number: selected.length }}
        />
      )
      : (
        <FormattedMessage
          id='deleteProducts.title.singular'
          defaultMessage='Vous allez supprimer {number} produit'
          values={{ number: selected.length }}
        />
      )}
    onConfirm={() => {
      deleteProducts(shop)
    }}
    // okText='Confirmer'
    // cancelText='Annuler'
    key='delete'
  >
    <Button
      style={{
        backgroundColor: errorColor[6],
        color: white,
      }}
    >
      <FormattedMessage id='delete' defaultMessage='Supprimer' />
    </Button>
  </Popconfirm>
))

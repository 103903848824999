/* eslint-disable import/prefer-default-export */
import {
  put,
  call,
  all,
  takeLatest,
} from 'redux-saga/effects'

import { getOrders } from '../orders/sagas'
import { fetchEntities } from '../entities/sagas'
import { getProducts } from '../products/sagas'
import { fetchStocks } from '../stocks/sagas'
import { getMembers } from '../members/sagas'
import { fetchBPs } from '../baseProducts/sagas'
import { fetchTechnics } from '../technics/sagas'

import { INIT_FETCH_DATA, END_FETCH_DATA } from './actions'

export function* getDataForDashboardPage(api) {
  try {
  // TODO INIT FETCH DATA SHOULD SOULD PAGE ADN PAGESIZE AS PAYLOAD
    const page = 0
    const pageSize = 200
    yield call(fetchTechnics, api)
    yield call(fetchBPs, api)
    yield all([
      call(getOrders, api, { payload: { page, pageSize } }),
      call(getMembers, api),
      call(fetchStocks, api),
      call(fetchEntities, api),
      call(getProducts, api),
    ])
    yield put({ type: END_FETCH_DATA })
    return 1
  } catch (e) {
    console.log('getDataForDashboardPage', e)
    return -1
  }
}

// eslint-disable-next-line func-names
export default function* ({ api }) {
  yield takeLatest(INIT_FETCH_DATA, getDataForDashboardPage, api)
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Form,
  Checkbox,
  Row,
  Button,
} from 'antd'

class NotificationsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: props.userInfos.newsletter,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: { validateFieldsAndScroll },
      onSubmit,
    } = this.props
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        onSubmit(values)
      }
    })
  }

  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    })
  };

  render() {
    const {
      form: {
        getFieldDecorator,
        isFieldsTouched,
        resetFields,
      },
    } = this.props

    const { checked } = this.state
    return (
      <Form
        id='NotificationsForm'
        onSubmit={this.handleSubmit}
      >
        <Form.Item label='Newsletter'>
          {getFieldDecorator('newsletter')(<Checkbox checked={checked} onChange={this.onChange} />)}
        </Form.Item>
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{ marginRight: 20 }}
              >
                <FormattedMessage id='modify' defaultMessage='Modifier' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    )
  }
}

export default Form.create({ name: 'NotificationsForm' })(NotificationsForm)

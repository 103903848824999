import React from 'react'
import { stockRating as rateStock } from '@balibart/stocks'
import { Tag, Icon } from 'antd'
import { FormattedMessage } from 'react-intl'
import theme from '../../../config/theme'
import InfoPopover from '../../atoms/InfoPopover'

const {
  warningColor,
  errorColor,
  primaryColor,
} = theme

export default ({
  stock,
  stockRating,
  onClick = () => {},
  individualStock,
  location = '',
  entityId,
}) => {
  const rating = typeof stockRating === 'number' ? stockRating : rateStock(stock, entityId)
  if (typeof rating !== 'number' || rating < 0) {
    return (
      <div>
        {
          location !== 'card' ? (
            <Tag style={{ margin: '0' }} disabled>A la Demande</Tag>
          ) : (
            <div style={{ margin: 0, padding: 5, borderTop: '1px solid rgb(232, 232, 232)' }} disabled>
              <FormattedMessage id='onDemand' defaultMessage='A la Demande' />
            </div>
          )
        }
      </div>
    )
  }
  let strokeColor = primaryColor[6]
  let content = <FormattedMessage id='stockLevels.stockAvailable' defaultMessage='Il y a du stock' />
  if (rating === 0) {
    strokeColor = errorColor
    content = individualStock
      ? (
        <FormattedMessage
          id='stockLevels.declinationNotAvailable'
          defaultMessage="Cette déclinaison n'est plus disponible (Out of stock)"
        />
      )
      : (
        <FormattedMessage
          id='stockLevels.someDeclinationsNotAvailable'
          defaultMessage='Certaines déclinaisons ne sont plus disponibles'
        />
      )
  } else if (rating === 1) {
    strokeColor = warningColor
    content = individualStock
      ? <FormattedMessage id='stockLevels.lowInStock' defaultMessage='Cette déclinaison a un stock très bas!' />
      : <FormattedMessage id='stockLevels.someAreLowInStock' defaultMessage='Certaines déclinaisons sont très bas en stock!' />
  } else if (rating === 2) {
    strokeColor = warningColor
    content = individualStock
      ? <FormattedMessage id='stockLevels.stockGoingLow' defaultMessage='Le stock de cette déclinaison commence à baisser' />
      : <FormattedMessage id='stockLevels.someStocksGoingLow' defaultMessage='Le stock de certaines déclinaisons commence à baisser' />
  }
  return (
    <div>
      {
        location !== 'card' ? (
          <Tag
            color={strokeColor[6]}
            style={{ margin: '0', cursor: 'pointer' }}
            onClick={onClick}
          >
            {rating
              ? <FormattedMessage id='stockLevels.inStock' defaultMessage='En Stock' />
              : 'OOS'}
          </Tag>
        ) : (
          <div
            style={{
              cursor: 'pointer',
              borderTop: '1px solid rgb(232, 232, 232)',
              width: '100%',
              display: 'flex',
            }}
            onClick={onClick}
          >
            {rating ? (
              <InfoPopover
                content={content}
                title={<FormattedMessage id='stockLevels.stockLevel' defaultMessage='Niveau de Stock' />}
                placement='top'
              >
                <Icon
                  type='smile'
                  style={{
                    backgroundColor: `${strokeColor}`,
                    color: 'white',
                    padding: 5,
                    fontSize: 20,
                  }}
                />
              </InfoPopover>
            ) : (
              <InfoPopover
                content={content}
                title={<FormattedMessage id='stockLevels.stockLevel' defaultMessage='Niveau de Stock' />}
                placement='top'
              >
                <Icon
                  type='frown'
                  style={{
                    backgroundColor: `${strokeColor}`,
                    color: 'white',
                    padding: 5,
                    fontSize: 20,
                  }}
                />
              </InfoPopover>
            )}
            <div
              style={{
                padding: 5,
                backgroundColor: `${strokeColor}`,
                color: 'white',
                width: '100%',
                borderLeft: '1px solid white',
              }}
            >
              <FormattedMessage id='stockLevels.manageMyStock' defaultMessage='Gérer mon stock' />
            </div>
          </div>
        )
      }
    </div>
  )
}

import { initialState } from './selectors'
import * as actions from './actions'

export default (state = initialState, { type, payload = {} }) => {
  const {
    error_details,
    requireAction,
    data,
  } = payload
  switch (type) {
  case actions.UPLOAD_TO_SHOPIFY:
    return {
      ...state,
      loading: true,
      error: false,
    }
  case actions.UPLOADED_TO_SHOPIFY:
    return {
      ...state,
      loading: false,
      error: false,
      requireAction: false,
    }
  case actions.RECEIVED:
    return {
      ...state,
      data,
      loading: false,
      error: false,
    }
  case actions.RECEIVED_FORCE:
    return {
      ...state,
      data,
      loading: false,
      error: false,
    }
  case actions.GET:
    return {
      ...state,
      loading: true,
      error: false,
      lastFetched: new Date(),
    }
  case actions.ERROR:
    return {
      ...state,
      error: true,
      loading: false,
      error_details,
    }
  case actions.SHOPIFY_REQUIRE_ACTION:
    return {
      ...state,
      loading: false,
      requireAction,
    }
  case actions.CREATE_ERROR:
    return {
      ...state,
      loadingCreation: false,
      errorCreation: true,
    }
  case actions.CREATE_BASEPRODUCT:
    return {
      ...state,
      loadingCreation: true,
    }
  case actions.CREATED:
    return {
      ...state,
      data: state.data.concat(payload.newBaseProduct),
      createdId: payload.createdId,
      loadingCreation: false,
    }
  case actions.MODIFIED_BASEPRODUCT:
    return {
      ...state,
      modifiedId: payload.modifiedId,
      loadingCreation: false,
    }
  case actions.CLOSE_STATUS_MODIFICATION_MODAL:
    return {
      ...state,
      statusModificationModalOpened: false,
    }
  default:
    return state
  }
}

// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
import { initialState } from './selectors'
import {
  FETCH,
  RECEIVE,
  SIGN_UP_SUCCESS,
  ERROR,
  MODIFY,
  MODIFIED,
  ERROR_ENTITY,
  RECEIVE_FACTURATION,
  RECEIVE_USER_ENTITY,
  SET_NEW_TOKENS,
  SET_NEW_TOKENS_ERROR,
  CLEAR_SESSION,
} from './actions'

const empty = {}

export default (state = initialState, { type, payload = empty }) => {
  const {
    user, tokenManagement = empty, newAuthToken, newRefreshToken, newCreationDate,
  } = payload
  switch (type) {
  case SET_NEW_TOKENS_ERROR:
    return {
      ...state,
      user: empty,
      tokenManagement: empty,
    }
  case SET_NEW_TOKENS:
    return {
      ...state,
      error: false,
      tokenManagement: {
        ...state.tokenManagement,
        authToken: newAuthToken,
        refreshToken: newRefreshToken,
        lastEmittedAt: newCreationDate,
      },
    }
  case RECEIVE:
    return {
      ...state,
      data: user,
      loading: false,
      tokenManagement,
      error: false,
    }
  case ERROR:
    return {
      ...state,
      data: empty,
      loading: false,
      error: true,
      tokenManagement: {},
    }
  case ERROR_ENTITY:
    return {
      ...state,
      errorEntity: true,
      tempUser: false,
    }
  case FETCH:
    return {
      ...state,
      error: false,
      loading: true,
    }
  case SIGN_UP_SUCCESS:
    return {
      ...state,
      auth: payload.auth || state.auth,
      error: false,
      loading: true,
      tempUser: false,
    }
  case CLEAR_SESSION:
    return {
      ...state,
      loading: false,
      error: false,
      data: {},
      tokenManagement: empty,
      auth: null,
    }
  case MODIFY: {
    return {
      ...state,
      modifiedData: payload,
      modifying: true,
    }
  }
  case MODIFIED: {
    return {
      ...state,
      data: {
        ...state.data,
        ...state.modifiedData,
        additionnalInfos: {
          ...state.data.additionnalInfos,
          ...state.modifiedData.additionnalInfos,
        },
      },
      modifying: false,
    }
  }
  case RECEIVE_FACTURATION: {
    return {
      ...state,
      data: {
        ...state.data,
        facturation: payload.data,
      },
    }
  }
  case RECEIVE_USER_ENTITY: {
    return {
      ...state,
      errorEntity: false,
      tempUser: {
        ...payload,
      },
    }
  }
  default:
    return state
  }
}

import React from 'react'
import { InputNumber, Icon, Row } from 'antd'
import theme from '../../../config/theme'
import { getMaxFromStock, renderPlaceHolder } from '../../../helpers/stocks'

const { errorColor, highlightColor } = theme

class Size extends React.Component {
  arbitrateEntityId = (entityId, stock) => entityId === stock.entityId

  render() {
    const {
      size, setSize, that, unavailable, value, quantityInCart, product, color, stocks, entityId,
    } = this.props
    const max = getMaxFromStock(product, size, color, stocks, entityId)
    const maximumWithCart = max !== null ? max - quantityInCart : Infinity
    const isOOS = max !== null ? max - quantityInCart <= 0 : false
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '5px 5px 5px 0',
          }}
        >
          <InputNumber
            style={{
              borderColor: unavailable || isOOS ? errorColor : highlightColor,
              width: '75px',
            }}
            disabled={unavailable || isOOS}
            value={value}
            min={0}
            max={maximumWithCart}
            onChange={(e) => {
              setSize({ size: size.reference, quantity: e })
            }}
            // eslint-disable-next-line
            ref={el => that[size.name] = el}
          />
          <Icon type='close' style={{ margin: '0 15px', width: 12 }} />
          <div
            style={{
              minWidth: '35px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              fontSize: '16px',
              fontWeight: '700',
            }}
          >
            {size.name}
          </div>
        </div>
        <Row>
          <div>
            {max !== null
              || unavailable ? renderPlaceHolder(unavailable, isOOS, maximumWithCart)
              : (null)}
          </div>
        </Row>
      </div>
    )
  }
}

export default Size

/* eslint-disable camelcase */
import React, { Component } from 'react'
import {
  Table,
  Drawer,
  Descriptions,
  Divider,
  Input,
  Button,
} from 'antd'
import dayjs from 'dayjs'
import invoice from '@balibart/invoice-calculator'

import { FormattedMessage } from 'react-intl'
import { calculInvoiceUpComing } from '../../../helpers/invoiceHelpers'

const { Search } = Input
const { Item } = Descriptions

const { invoiceCalculator } = invoice

let newInvoiceItems

class SubscriptionTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalUpComing: (props.upComingInvoice && props.upComingInvoice.total / 100) || 0,
      dropShippingPrice: 0,
      storagePrice: 0,
      giftAmount: 0,
      excessAmount: 0,
      visibleDrawer: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      const {
        allProducts,
        allOrders,
        invoiceItems,
        upComingInvoice,
      } = this.props

      if (upComingInvoice.total) {
        const { start_format, end_format } = upComingInvoice
        const filteredOrders = allOrders.filter((order) => dayjs(order.createdAt)
          .isBetween(start_format, end_format))

        const invoiceCalculated = invoiceCalculator(allProducts, filteredOrders)

        if (prevState.dropShippingPrice !== invoiceCalculated.dropShippingPrice) {
          this.dropShippingPrice(invoiceCalculated.dropShippingPrice)
        }
        if (prevState.storagePrice !== invoiceCalculated.storagePrice) {
          this.storagePrice(invoiceCalculated.storagePrice)
        }
        if (prevState.giftAmount !== invoiceCalculated.giftAmount) {
          this.giftAmount(invoiceCalculated.giftAmount)
        }
        if (prevState.excessAmount !== invoiceCalculated.excessAmount) {
          this.excessAmount(invoiceCalculated.excessAmount)
        }
        if (prevState.totalUpComing
          !== calculInvoiceUpComing(upComingInvoice.total, invoiceCalculated)) {
          this.totalUpComing(upComingInvoice, invoiceCalculated)
        }
        newInvoiceItems = invoiceItems.map((item) => {
          if (item.type === 'Dropshipping') {
            return {
              ...item,
              total: parseInt(invoiceCalculated.dropShippingPrice * 100, 10),
            }
          }
          if (item.type === 'Stockage') {
            return {
              ...item,
              total: parseInt(invoiceCalculated.storagePrice * 100, 10),
            }
          } if (item.type === 'Commande Cadeau') {
            return {
              ...item,
              total: parseInt((
                invoiceCalculated.giftAmount - invoiceCalculated.excessAmount) * 100, 10),
            }
          } return item
        })
      }
    } catch (e) {
      console.log('err Invoicespages', e)
    }
  }

  totalUpComing = (upComingInvoice, invoiceCalculated) => this.setState({
    totalUpComing: calculInvoiceUpComing(upComingInvoice.total, invoiceCalculated),
  })

  dropShippingPrice = (dropShippingPrice) => this.setState({
    dropShippingPrice,
  })

  storagePrice = (storagePrice) => this.setState({
    storagePrice,
  })

  giftAmount = (giftAmount) => this.setState({
    giftAmount,
  })

  excessAmount = (excessAmount) => this.setState({
    excessAmount,
  })

  displayInputAdminStripeCustomerId = () => {
    const { user, _fetchInvoices } = this.props
    if (user.admin) {
      return (
        <Search
          placeholder='stripeCustomerId'
          style={{ width: 200 }}
          onSearch={(stripeCustomerId) => {
            _fetchInvoices(stripeCustomerId)
          }}
        />
      )
    } return null
  }

  render() {
    try {
      const {
        upComingInvoice,
      } = this.props
      const {
        totalUpComing,
        visibleDrawer,
      } = this.state

      const columns = [{
        title: <FormattedMessage id='type' defaultMessage='Type' />,
        dataIndex: 'type',
        key: 'type',
      }, {
        title: <FormattedMessage id='description' defaultMessage='Description' />,
        dataIndex: 'description',
        key: 'description',
      }, {
        title: <FormattedMessage id='total' defaultMessage='Total' />,
        dataIndex: 'total',
        key: 'total',
        render: (text, record) => (
          <span style={{ whiteSpace: 'nowrap' }}>
            {`${record.total / 100} €`}
          </span>
        ),
      }, {
        title: <FormattedMessage id='currency' defaultMessage='Devise' />,
        dataIndex: 'currency',
        key: 'currency',
        render: (text, record) => (
          <span style={{ textTransform: 'uppercase' }}>
            {record.currency}
          </span>
        ),
      }]

      if (!upComingInvoice.total) {
        return null
      }
      return (
        <span>
          <Drawer
            title={(
              <FormattedMessage
                id='subscription.title'
                defaultMessage='Prévision - Facture mensuelle n°{id}'
                values={{ id: upComingInvoice.id }}
              />
            )}
            placement='right'
            onClose={() => {
              this.setState({
                visibleDrawer: false,
              })
            }}
            visible={visibleDrawer}
            className='SubscriptionTable'
          >
            <Descriptions
              title={(
                <FormattedMessage id='detailedOrder.moreInfos' defaultMessage='Informations supplémentaires' />
              )}
              layout='vertical'
            >
              <Item label={<FormattedMessage id='expiration' defaultMessage='Prochaine échéance' />}>
                {upComingInvoice.end}
              </Item>
              <Item label={<FormattedMessage id='amountToDate' defaultMessage='Montant à date' />}>
                {`${totalUpComing} €`}
              </Item>
            </Descriptions>
            <Divider />
            <Table
              columns={columns}
              dataSource={newInvoiceItems}
              pagination={{
                defaultPageSize: 8,
                size: 'small',
              }}
            />
          </Drawer>
          <Button
            key='1'
            type='primary'
            onClick={() => {
              this.setState({
                visibleDrawer: true,
              })
            }}
          >
            <FormattedMessage id='subscription.preview' defaultMessage='Aperçu de ma prochaine facture mensuelle' />
          </Button>
        </span>
      )
    } catch (e) {
      console.log('Invoices - SubscriptionTable', e)
      return null
    }
  }
}

export default SubscriptionTable

import React from 'react'
import {
  Input,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl, injectIntl } from 'react-intl'
import {
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { trimSearch } from '../../../helpers/trim'

const SearchProduct = (props) => {
  const {
    filters, // Required
    setFilters, // Required
  } = props
  const intl = useIntl()
  const filterProductsByName = (products, pattern) => products.filter(
    (product) => trimSearch(product.name).includes(trimSearch(pattern)),
  )
  const handleOnSearchProducts = (event) => {
    const pattern = event.target.value
    const filter = {
      callback: filterProductsByName,
      parameters: [pattern],
    }
    setFilters({ ...filters, search_product_filter: filter })
  }

  return (
    <Input
      placeholder={intl.formatMessage({
        id: 'campaignTracking.search',
        defaultMessage: 'Rechercher',
      })}
      prefix={
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      }
      onChange={handleOnSearchProducts}
      style={{ width: '139px' }}
    />
  )
}

export default injectIntl(SearchProduct)

/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect } from 'react'
import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { isEqual } from 'lodash'
import produce from 'immer'
import ReactHtmlParser from 'react-html-parser'
import * as DOMPurify from 'dompurify'
import {
  StepCardForm,
  FormStepInput,
  FormattedButton,
  TemplateVariables,
  EmailEditor,
  MovingStepButton,
  CampaignCreateModal,
} from '../..'
import { availableCampaignStatus } from '../../../helpers/campaignStatus'

const CampaignCreationEmailing = (props) => {
  const {
    userEmail, // Required
    campaign, // Required
    setCampaign, // Required
    movePreviousStep,
    sendTestEmail, // Required
    handleCampaign,
    setNeedUpdateCampaign, // Required
    createdCampaignId, // Required
    isDisabled = false,
    history, // Required
    updateCampaign, // TEST
  } = props

  const emailEditorRef = useRef(null)
  const [testEmail, setTestEmail] = useState(userEmail)
  const [emailEditorState, setEmailEditorState] = useState({ body: campaign?.template?.body, design: campaign?.template?.design })

  useEffect(() => {
    if (!isEqual(emailEditorState, { body: campaign?.template?.body, design: campaign?.template?.design })) {
      setCampaign(produce(campaign, (draft) => {
        draft.template.body = emailEditorState.body
        draft.template.design = emailEditorState.design
      }))
      setNeedUpdateCampaign(true)
    }
  }, [emailEditorState, emailEditorRef])

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <StepCardForm
        isSingleCard
        avatarIcon={faEnvelope}
        titleMessageId='campaigns.create.step.emailing.title'
        titleDefaultMessage='Template Emailing'
        descriptionMessageId='campaigns.create.step.emailing.description'
        descriptionDefaultMessage='Personnalisez le template du mail par vos destinataires'
        isDisabled={isDisabled}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '50%' }}>
              <FormStepInput
                titleMessageId='campaigns.create.step.emailing.emailObject'
                titleDefaultMesage='Objet du mail'
                inputValue={campaign?.template?.title}
                onChangeFunction={(event) => {
                  setNeedUpdateCampaign(true)
                  setCampaign({ ...campaign, template: { ...campaign?.template, title: event.target.value } })
                }}
                disabled={isDisabled}
              />
            </div>
            <div style={{ marginLeft: '16px', marginRight: '8px', width: '35%' }}>
              <FormStepInput
                titleMessageId='campaigns.create.step.emailing.testEmail'
                titleDefaultMesage='Mail test'
                inputValue={testEmail}
                onChangeFunction={(e) => setTestEmail(e.target.value)}
              />
            </div>
            <FormattedButton
              style={{
                display: 'block', alignSelf: 'flex-end', width: '15%', padding: '5px 8px',
              }}
              messageId='campaignEdit.button.sendTestEmail'
              defaultMessage='Envoyer un mail test'
              icon={faPaperPlane}
              type='primary'
              onClickFunction={() => {
                updateCampaign(campaign, () => {
                  sendTestEmail({ _id: createdCampaignId, email: testEmail })
                })
              }}
            />
          </div>
          {!isDisabled && (
            <div style={{ marginTop: '32px' }}>
              <TemplateVariables />
            </div>
          )}
          <div style={{ marginTop: '32px' }}>
            {isDisabled ? (
              <div>
                {ReactHtmlParser(DOMPurify.sanitize(campaign?.template?.body, { USE_PROFILES: { html: true } }))}
              </div>
            ) : (
              <EmailEditor
                design={campaign?.template?.design}
                emailEditorRef={emailEditorRef}
                setEmailEditorState={setEmailEditorState}
              />
            )}

          </div>
          {movePreviousStep && (
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '24px',
              justifyContent: 'space-between',
              width: '50%',
              gap: '8px',
            }}
            >
              <MovingStepButton movePreviousStep={movePreviousStep} style={{ width: '50%' }} />
              <CampaignCreateModal
                onSendCampaign={() => history.push(`/campaign/edit?id=${createdCampaignId}&sending=true`)}
                onViewCampaign={() => history.push(`/campaign/edit?id=${createdCampaignId}`)}
                onUpdateCampaign={() => {
                  const activeCampaign = { ...campaign, status: availableCampaignStatus?.active }
                  handleCampaign(activeCampaign)
                }}
                buttonStyle={{ width: '50%' }}
              />
            </div>
          )}
        </div>
      </StepCardForm>
    </div>
  )
}

export default CampaignCreationEmailing

/* eslint-disable camelcase */
import { displayDate } from '../trim'
import { getPriceExport } from '../getPrice'

const headers = (locale) => [
  {
    label: locale === 'fr' ? 'Numero de commande' : 'Customer Order Id',
    key: 'customerOrderId',
  },
  {
    label: locale === 'fr' ? 'Statut' : 'Status',
    key: 'status',
  },
  {
    label: 'Date',
    key: 'createdAt',
  },
  {
    label: locale === 'fr' ? 'Nom' : 'Last name',
    key: 'client.lastName',
  },
  {
    label: locale === 'fr' ? 'Prenom' : 'First name',
    key: 'client.firstName',
  },
  {
    label: 'Email',
    key: 'client.email',
  },
  {
    label: locale === 'fr' ? 'Adresse' : 'Street address',
    key: 'client.address.streetAddress',
  },
  {
    label: locale === 'fr' ? 'Code postal' : 'Postal code',
    key: 'client.address.postalCode',
  },
  {
    label: locale === 'fr' ? 'Ville' : 'City',
    key: 'client.address.city',
  },
  {
    label: locale === 'fr' ? 'Pays' : 'Country',
    key: 'client.address.country',
  },
  {
    label: locale === 'fr' ? 'Nom du produit' : 'Product Name',
    key: 'name',
  },
  {
    label: locale === 'fr' ? 'Categorie' : 'Category',
    key: 'category',
  },
  {
    label: locale === 'fr' ? 'Sous-categorie' : 'SubCategory',
    key: 'sub_category',
  },
  {
    label: locale === 'fr' ? 'Couleur' : 'Color',
    key: 'variations.color.name',
  },
  {
    label: locale === 'fr' ? 'Taille' : 'Size',
    key: 'variations.size.name',
  },
  {
    label: locale === 'fr' ? 'Cadeau' : 'Gift',
    key: 'giftOrder',
  },
  {
    label: locale === 'fr' ? 'Code promotionnel' : 'Promotional code',
    key: 'code',
  },
  {
    label: locale === 'fr' ? 'Campagne' : 'Campaign',
    key: 'campaign',
  },
  {
    label: locale === 'fr' ? 'Montant total du panier' : 'Cart total amount',
    key: 'transaction.amount',
  },
  {
    label: locale === 'fr' ? 'Credits utilises' : 'Credits used',
    key: 'transaction.creditAmount',
  },
  {
    label: locale === 'fr' ? 'Prix restant a payer' : 'Left to pay',
    key: 'transaction.leftToPay',
  },
  {
    label: locale === 'fr' ? 'Prix unitaire' : 'Unit price',
    key: 'unitPrice',
  },
  {
    label: locale === 'fr' ? 'Quantite' : 'Quantity',
    key: 'quantity',
  },
  {
    label: locale === 'fr' ? 'Prix Total (PU x quantite)' : 'Total price (UP x quantity)',
    key: 'totalPrice',
  },
  {
    label: locale === 'fr' ? 'Frais de port TTC' : 'Delivery price TTC',
    key: 'deliveryPricing',
  },
  {
    label: 'Total TTC',
    key: 'totalTTC',
  },
]

const formattedData = (data, allProducts = [], locale) => {
  const finalArray = []
  data.map((order) => order.products.map((product) => {
    const {
      _id, discounts = {}, createdAt, giftOrder, transaction = {}, detailedPricing = {}, status,
    } = order
    const { campaign = {}, code = {} } = discounts
    const {
      amount, creditAmount, leftToPay,
    } = transaction
    const concernedProduct = allProducts.find((p) => String(p._id) === product._id)
    // On calcule le prix du produit avec les priceImpacts des variations
    const productPrice = getPriceExport(concernedProduct, product)
    finalArray.push({
      ...order,
      ...product,
      unitPrice: productPrice,
      _id,
      createdAt: displayDate(createdAt, locale),
      // eslint-disable-next-line no-nested-ternary
      giftOrder: giftOrder
        ? locale === 'fr' ? 'Oui' : 'Yes'
        : locale === 'fr' ? 'Non' : 'No',
      campaign: (campaign && campaign.name) || locale === 'fr' ? 'Non' : 'No',
      code: (code && code.name) || locale === 'fr' ? 'Non' : 'No',
      totalPrice: parseFloat(productPrice * product.quantity).toFixed(2),
      transaction: {
        ...transaction,
        amount: amount / 100,
        creditAmount: creditAmount || 0,
        leftToPay: leftToPay / 100 || 0,
      },
      status,
      totalTTC: (parseFloat(detailedPricing?.total || 0) + parseFloat(detailedPricing?.delivery_discounted || 0)).toFixed(2),
      deliveryPricing: detailedPricing?.delivery_discounted || 0,
    })
    return true
  }))
  return finalArray
}

export default formattedData
export { headers }

/* eslint-disable camelcase */
import React from 'react'
import {
  Form,
  Input,
  message,
  Checkbox,
  Icon,
  Button,
  Row,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import config from '../../../config'

const StreamlabsConnect = injectIntl(({
  loading,
  handleClick,
  shopSettings,
  displayUserMessage,
  form,
  unplug,
  userMessage,
}) => {
  const {
    getFieldDecorator,
  } = form
  const intl = useIntl()

  if (shopSettings.streamlabs && Object.keys(shopSettings.streamlabs)[0]) {
    return (
      <div>
        <h3>
          <span className='title'>
            <FormattedMessage id='options' defaultMessage='Options' />
          </span>
        </h3>
        <Form.Item>
          {getFieldDecorator('allowMessage', {
            rules: [
            ],
            initialValue: shopSettings.vat || 20,
          })(
            <Checkbox
              checked={displayUserMessage}
            >
              <FormattedMessage id='integrationForm.allow' defaultMessage='Autoriser vos acheteurs à laisser un message' />
            </Checkbox>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('message', {
            rules: [
            ],
            initialValue: userMessage || '',
          })(
            <Input
              id='message'
              placeholder={intl.formatMessage({
                id: 'integrationForm.placeholder.newSale',
                defaultMessage: 'Nouvelle vente 🔥',
              })}
            />,
          )}
        </Form.Item>
        <Button
          onClick={(e) => unplug(e)}
          className='unplug-btn'
        >
          <FormattedMessage id='integrationForm.disconnect' defaultMessage='Déconnecter mon compte Streamlabs' />
        </Button>
      </div>
    )
  }
  return (
    <Form.Item>
      <h3>
        <span className='title'>
          Streamlabs (Twitch)
        </span>
      </h3>
      <Button
        onClick={() => handleClick()}
        className='plug-btn'
      >
        {loading
          ? <Icon type='loading' />
          : (
            <span className='title'>
              <FormattedMessage id='integrationForm.connect' defaultMessage='Connectez votre compte Streamlabs' />
            </span>
          )}
      </Button>
    </Form.Item>
  )
})

class IntegrationsForm extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
        resetFields,
      },
      modifyShop,
      shop,
    } = this.props
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const {
          vat,
        } = values

        const modifications = {
          settings: {
            ...shop.settings,
            vat,
          },
        }
        modifyShop({
          shop_id: shop._id,
          modifications,
          callback: () => {
            resetFields()
            message.info(
              <FormattedMessage
                id='updateSuccess.shop'
                defaultMessage='Boutique mise à jour'
              />,
            )
            return true
          },
        })
      }
    })
  }

  unplug = () => {
    try {
      const {
        modifyShop,
        shop,
        form: {
          resetFields,
        },
      } = this.props

      const modifications = {
        settings: {
          ...shop.settings,
          streamlabs: {},
        },
      }

      modifyShop({
        shop_id: shop._id,
        modifications,
        callback: () => {
          resetFields()

          message.info(
            <FormattedMessage
              id='updateSuccess.shop'
              defaultMessage='Boutique mise à jour'
            />,
          )
          return true
        },
      })
    } catch (e) {
      console.log('Error unplugging Streamlabs :', e)
    }
  }

  handleClick = () => {
    try {
      this.setState({
        loading: true,
      })
      const response_type = 'code'
      const { client_id } = config.streamlabs
      const redirect_uri = `${window.location.origin}${window.location.pathname}`
      const scope = 'alerts.create donations.create'
      // eslint-disable-next-line max-len
      window.location.replace(`https://streamlabs.com/api/v1.0/authorize?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}`)
    } catch (e) {
      console.log('Cannot connect to Streamlabs', e)
    }
  }

  render() {
    const {
      form: {
        isFieldsTouched,
        resetFields,
      },
      form,
      shopSettings,
    } = this.props

    const { loading } = this.state

    // const prefixSelector = getFieldDecorator('prefix', {
    //   initialValue: '33',
    // })(
    //   <Select style={{ width: 70 }}>
    //     <Option value='86'>+86</Option>
    //     <Option value='87'>+87</Option>
    //   </Select>,
    // )
    // TODO better input phone

    return (
      <Form
        onSubmit={this.handleSubmit}

      >
        <StreamlabsConnect
          loading={loading}
          shopSettings={shopSettings}
          handleClick={this.handleClick}
          form={form}
        />
        <Row>
          {isFieldsTouched() ? (
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  marginRight: '12px',
                }}
              >
                <FormattedMessage id='save' defaultMessage='Enregistrer' />
              </Button>
              <Button onClick={() => resetFields()}>
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </Form.Item>
          ) : null}
        </Row>
      </Form>
    )
  }
}

export default Form.create({ name: 'shopBasicSettings' })(IntegrationsForm)

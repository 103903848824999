import React from 'react'
import { Button } from 'antd'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { availableCampaignStatus, getCampaignStatusTag } from '../../../helpers/campaignStatus'
import copyToClipboard from '../../../helpers/copyToClipboard'
import theme from '../../../config/theme'
import {
  CampaignLeaveModal,
  CampaignSaveButton,
  CampaignDuplicateButton,
  MagiclinksSendButton,
  CustomizableButton,
} from '../..'

const EditCampaignHeader = (props) => {
  const {
    history, // Required
    campaign = {}, // Required
    duplicateCampaign, // Required
    saveCampaign, // Required
    needUpdateCampaign, // Required
    setVisibleMagicLinksDrawer, // required
    setCurrentCampaignId, // Required
    setCampaignStatus, // Required
  } = props

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <CampaignLeaveModal
          history={history}
          saveCampaign={saveCampaign}
          isCreateFlow={false}
          isCampaignCreated
          needUpdateCampaign={needUpdateCampaign}
          campaign={campaign}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
          }}
          >
            <h2 style={{
              fontSize: '24px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: '40vw',
            }}
            >
              {campaign?.name}
            </h2>
            {getCampaignStatusTag(campaign)}
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px',
          }}
          >
            <span style={{ fontSize: '12px', color: theme?.character?.secondary }}>{campaign?._id}</span>
            <FontAwesomeIcon
              icon={faCopy}
              onClick={(e) => copyToClipboard(e, campaign?._id)}
              style={{ color: theme?.character?.secondary, cursor: 'pointer' }}
            />
          </div>
        </div>
      </div>
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px',
      }}
      >
        {campaign?.status === availableCampaignStatus?.archived ? (
          <CustomizableButton
            onClick={() => setCampaignStatus(availableCampaignStatus?.active)}
            type='primary'
            content={(
              <FormattedMessage id='campaigns.edit.enable.campaign' defaultMessage='Réactiver la campagne' />
            )}
          />
        ) : (
          <>
            <div style={{
              marginRight: '16px', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
            >
              <Button
                type='link'
                style={{ padding: '0' }}
                onClick={() => setCampaignStatus(availableCampaignStatus?.archived)}
              >
                <h4 style={{ fontSize: '14px', fontWeight: '500' }}>
                  <FormattedMessage id='archive' defaultMessage='Archiver' />
                </h4>
              </Button>
            </div>
            <CampaignSaveButton
              campaign={campaign}
              saveCampaign={saveCampaign}
              needUpdateCampaign={needUpdateCampaign}
            />
            <CampaignDuplicateButton
              duplicateCampaign={duplicateCampaign}
            />

            {campaign.status === 'active' && (
              <MagiclinksSendButton
                type='primary'
                onClick={() => {
                  setCurrentCampaignId(campaign._id)
                  setVisibleMagicLinksDrawer(true)
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default EditCampaignHeader

// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import {
  put, call,
  takeEvery, select,
} from 'redux-saga/effects'
import * as actions from './actions'
import { fromUsers } from '../selectors'
import * as baseProductsActions from '../baseProducts/actions'
// GET /codes
export function* getCodes(api) {
  try {
    const result = yield call([api, api.get], '/codes', {
      headers: {
        mode: 'cors',
        authorization: yield select(fromUsers.getAuth),
      },
    })
    yield put({
      type: actions.RECEIVED_CODES,
      payload: {
        data: result.codes,
      },
    })
    return true
  } catch (e) {
    console.log('getCodes - error', e, e.message)
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(baseProductsActions.FETCH_FORCE, getCodes, api)
}

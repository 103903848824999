/* eslint-disable camelcase */
import React from 'react'
import {
  Card,
} from 'antd'
import ProductImage from '@balibart/product-image'
import { FormattedMessage } from 'react-intl'
import './style.css'

const { Meta } = Card

class ProductGridCard extends React.Component {
  render() {
    try {
      const {
        item,
        base_url,
        locale = 'fr',
        showMeta = true,
        editDesign,
      } = this.props

      if (item.type === 'custom') {
        const getDescription = (block) => {
          if (block.link) {
            return (
              <a href={block.link} target='_blank' rel='noopener noreferrer'>
                <FormattedMessage
                  id='orga.hasLink'
                  defaultMessage='Image lien'
                />
              </a>
            )
          }
          return (
            <FormattedMessage
              id='orga.noLink'
              defaultMessage='Image standard'
            />
          )
        }

        return (
          <Card
            onClick={() => editDesign(item)}
            className={showMeta ? 'metaDisplayed' : 'metaHidden'}
            hoverable
            cover={(
              <div className='squareImage'>
                <div className='imageContainer'>
                  <img
                    alt={item._id}
                    src={`${
                      base_url
                    }/Designs/${
                      item.design._id
                    }/${
                      item.design.thumbnail ? 'thumbnail' : 'design'
                    }.png`}
                  />
                </div>
              </div>
            )}
          >
            {
              showMeta
                ? (
                  <Meta
                    title={(
                      <FormattedMessage
                        id='orga.customImage'
                        defaultMessage='Image personnalisée'
                      />
                    )}
                    description={getDescription(item)}
                  />
                )
                : null
            }
          </Card>
        )
      }

      const getType = () => {
        if (
          item.baseProduct
          && item.baseProduct.subCategory
          && item.baseProduct.subCategory[locale]
        ) {
          return item.baseProduct.subCategory[locale]
        }
        return 'Custom'
      }

      return (
        <Card
          className={showMeta ? 'metaDisplayed' : 'metaHidden'}
          hoverable
          cover={(
            <ProductImage
              base_url={base_url}
              product={item}
              variation={item.favColor}
              currentPrintingZone={item.favPz || 'front'}
            />
          )}
        >
          {
            showMeta
              ? (
                <Meta
                  title={item.name}
                  description={getType(item)}
                />
              )
              : null
          }
        </Card>
      )
    } catch (error) {
      console.log('ProductGridCard - ', { error })
      return null
    }
  }
}

export default ProductGridCard

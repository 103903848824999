/* eslint-disable no-useless-escape */
import React from 'react'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { FormattedMessage } from 'react-intl'
import { Tag, notification } from 'antd'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  flatten, uniq, sortBy,
} from 'lodash'
// eslint-disable-next-line import/no-cycle
import { displayDate, trimInput } from '../trim'
import { primaryColor, validColor } from '../../config/theme'
import config from '../../config'
// eslint-disable-next-line import/no-cycle
import {
  getFormattedDate,
  getFormattedExpireAt,
  getFormattedCampaign,
  getFormattedAdmin,
  getFormattedStatus,
  getFormattedNPS,
  getEmail,
  getAction,
} from '../magicLinkGridColumn'

export const STEP_DRAWER_PARAMS = 'params'
export const STEP_DRAWER_MAILS = 'mails'
export const STEP_DRAWER_MESSAGE = 'message'
export const STEP_DRAWER_MANUALLY = 'emails' // id du FormattedMessage du CustomSwitch (MagicLinksSendingMailsHeader) => ne pas modifier
export const STEP_DRAWER_BYCSV = 'CSVFile' // id du FormattedMessage du CustomSwitch (MagicLinksSendingMailsHeader) => ne pas modifier

const { image_base_url: imageBaseUrl } = config

/**
 * Permet de trimer les inputs des filtres pour une meilleure efficacité
 * Note : la fonction similaire du helper "trim" n'est pas utilisée dans ce helper
 * pour éviter un phénomène de dépendance cyclique entre les deux helpers
 * @param {string} input - La valeur entrée dans un input.
 * @returns {string} La valeur trimmée sans espace, en minuscule
 */
const trim = (input) => input?.replaceAll(' ', '').toLowerCase() || ''

/**
 * Permet de vérifier la validité du format de l'email
 * @param {string} email - l'email à vérifier
 * @returns {boolean} Si le format de l'email est valide
 */
const hasValidEmailFormat = (email) => {
  // eslint-disable-next-line max-len
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

/**
 * Permet de savoir si on doit afficher un message de format invalide pour un email
 * @param {string} email - l'email à vérifier
 * @returns {boolean} S'il faut afficher un message d'erreur de format
 */
const displayUnvalidFormat = (email) => !!email?.length && !hasValidEmailFormat(email)

/**
 * Permet de vérifier si la date d'envoi est valide (par rapport à la date d'expiration)
 * @param {Date} sendDate - La date d'envoi des magiclinks
 * @param {Date} expirationDate - La date d'expiration des magiclinks
 * @returns {boolean} Si la date d'envoi n'est pas égale ou postérieur à la date d'expiration
 */
const isSendDateValid = (sendDate, expirationDate) => sendDate < expirationDate

/**
 * Permet de définir pour la partie "paramètres de campagne" si le passage à la prochaine étape doit être bloqué
 * @param {string} campaignId - L'id de la campagne sélectionnée
 * @param {Date} sendDate - La date d'envoi des magiclinks
 * @param {Date} expirationDate - La date d'expiration des magiclinks
 * @returns {boolean} Si la prochaine étape doit être bloquée
 */
const isParametersNextStepDisabled = (campaignId, sendDate, expirationDate) => !campaignId
|| !sendDate
|| !expirationDate
|| !isSendDateValid(sendDate, expirationDate)

/**
 * Permet de définir pour la partie "saisie d'emails" si l'envoi de magiclinks doit être bloqué
 * @param {array} mails - Les mails de la liste d'envoi de magiclinks
 * @param {string} subpart - L'état du switch entre les parties 'emails' et 'CSVfile'
 * @returns {boolean} Si la prochaine étape doit être bloquée
 */
const isSendingMailsNextDisabled = (mails, subpart) => !mails?.length || subpart !== STEP_DRAWER_MANUALLY

/**
 * Permet de définir si l'on doit afficher une alerte d'adresses mail erronées
 * @param {array} unvalidCSVEmails - Les adresses mail erronées
 * @param {string} subpart - L'état du switch entre les parties 'emails' et 'CSVfile'
 * @returns {boolean} Si on affiche l'alerte ou non
 */
const displayWrongMails = (unvalidCSVEmails, subpart) => !!unvalidCSVEmails?.length && subpart === STEP_DRAWER_MANUALLY

/**
 * Permet de savoir si une adresse mail à insérer se trouve déjà dans une liste d'envoi de magiclinks
 * @param {array} mails - La liste de mails pour l'envoi des magiclinks
 * @param {string} emailInput - L'adresse mail à insérer
 * @returns {boolean} Si l'adresse mail se trouve déjà dans la liste d'envoi de magiclinks
 */
const hasAlreadyBeenUsed = (mails, emailInput) => mails?.some((mail) => mail.email === emailInput)

/**
 * Permet de vérifier si une adresse mail a déjà été utilisée pour envoyer un MagicLink pour une campagne donnée
 * @param {string} email - L'adresse mail à vérifier
 * @param {array} magicLinks - Les MagicLinks déjà envoyés pour cette campagne
 * @param {string} campaignId - L'id de la campagne
 * @returns {boolean} Si l'adresse à déjà été utilisée ou non pour cette campagne
 */
const hasAlreadyBeenSent = (email, magicLinks, campaignId) => magicLinks?.some(
  (link) => link.email === email && link.campaignId === campaignId,
)

/**
 * Permet de savoir une adresse mail est recevable pour un envoi de MagicLinks
 * @param {*} emailInput - L'adresse mail à vérifier
 * @param {*} mails - La liste de mails pour l'envoi des magiclinks
 * @param {*} magicLinks - Les MagicLinks déjà envoyés pour cette campagne
 * @param {*} campaignId - L'id de la campagne sélectionnée
 * @returns {boolean} Si l'adresse mail est recevable
 */
const isUsableEmailAddress = (emailInput, mails, magicLinks, campaignId) => emailInput?.length
&& hasValidEmailFormat(emailInput)
&& !hasAlreadyBeenUsed(mails, emailInput)
&& !hasAlreadyBeenSent(emailInput, magicLinks, campaignId)

/**
 * Permet de vérifier si les entêtes d'un fichier CSV correspondent au modèle attendu
 * @param {array} csvHeaders - Les entêtes du modèle attendu
 * @param {array} row - Une ligne de données extraites du fichier CSV (via CSVReader)
 * @returns {boolean} Si les entêtes du fichier CSV correspondent au du modèle attendu
 */
const hasCorrectCSVHeaders = (csvHeaders, row) => Object.keys(row.data).every((key) => csvHeaders.includes(key))

/**
 *
 * @param {array} listBudget - liste de tout les budgets
 * @param {array} listCollaborators - liste de tout les collaborateurs
 * @param {Object} user - objet de l'utilisateur
 * @returns {string} - somme de tout les links restants
 */

const getBudgetCampaign = (listBudget, listCollaborators) => listBudget.filter((iBudget) => {
  const totalBudget = listCollaborators.filter((collab) => iBudget.userId === collab._id)
  return totalBudget
})

/**
 *
 * @param {array} campaignSelected - liste des campagnes selectionnée (admin ou non)
 * @param {array} allMagicLinks - liste de toutes les MagicLinks
 * @returns {array} - MagicLink filtré par campagne presente dans la liste
 */

const getMagicLinksCampaign = (campaignSelected, allMagicLinks) => allMagicLinks.filter((ml) => {
  const SelectedmagicLink = campaignSelected.find((c) => String(c._id) === ml.campaignId)
  return SelectedmagicLink
})

/**
 *
 * @param {array} allCampaign - liste de toutes les campagnes
 * @param {Object} user - objet de l'utilisateur
 * @param {array} allMagicLinks - liste de toute les campagnes
 * @returns {array} - array de tout les statistiques que l'on a besoin
 */

const campaignStatAdmin = (allCampaign, user, allMagicLinks) => {
  const targetedCampaigns = user.admin ? allCampaign : allCampaign.filter((cpg) => (cpg.public) || (cpg.userId === user._id))
  const usedProducts = uniq(flatten(targetedCampaigns.map((campaign) => campaign.products))).length
  const filteredMagicLinks = getMagicLinksCampaign(targetedCampaigns, allMagicLinks)
  return [targetedCampaigns, usedProducts, filteredMagicLinks]
}

/**
 * Permet de savoir si l'utilisateur a le droit de voir la colonne Admins (admin Panopli ou shop owner ou administrateur de son entité)
 * @param {object} user - L'utilisateur
 * @param {string} shopOwner - L'id du propriétaire du shop
 * @returns {boolean} Si l'utilisateur a le droit de voir la colonne Admin ou non
 */
const isMagicLinksAdmin = (user, shopOwner) => user.admin || (user._id === shopOwner) || (user.collaborator && user.adminCollaborator)

/**
 * Permet de retrouver l'administrateur d'une campagne à partir d'un envoi (magicLink).
 * @param {object} magicLink - Un magicLink de la campagne.
 * @param {array} collaborators - Les collaborateurs de l'entité.
 * @returns {object} L'administrateur de la campagne à laquelle appartient le magicLink
 */
const getCampaignAdmin = (magicLink, collaborators) => {
  const { userId } = magicLink
  return collaborators.find((collaborator) => collaborator._id === userId) || {}
}

/**
 * Permet de trier les campagnes selon le filtre d'ancienneté de la campagne
 * @param {array} campaigns - Un tableau de campagnes
 * @param {string} sort - Mot clé qui définit si on trie du plus jeune au plus vieux ou inversement
 * @returns {array} Les campagnes filtrées et triées de la plus jeune à plus vielle ou inversement
 */
const getSortedCampaigns = (campaigns, sort) => {
  switch (sort) {
  case 'recent':
  default:
    return campaigns.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
  case 'oldest':
    return campaigns.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
  }
}

/**
 *
 * @param {object} filters - Les filtres utilisés.
 * @param {array} campaigns - Les campagnes de l'entité.
 * @returns {array} - les campagnes filtrées et trier de la plus jeune à plus vielle ou inversement
 */
const getCampaignsByFilters = (filters, campaigns) => {
  try {
    const {
      search, type, status, adminsIds, sort,
    } = filters
    const campaignFiltered = campaigns.filter((campaign) => {
      // On filtre par recherche de titre de campagne si le filtre est actif
      const searchResult = !search?.length || trimInput(campaign.name).includes(trimInput(search))
      // On filtre par statut de campagnes si le filtre est actif
      const statusResult = !status ? campaign.status !== 'archived' : status === campaign.status
      // On filtre par type de campagnes si le filtre est actif
      const typeResult = !type || (type === 'private' ? !campaign.public : campaign.public)
      // On filtre par admins si le filtre est actif
      const adminsResult = !adminsIds.length || adminsIds.includes(campaign.userId)
      // On retourne la combinaison de ces différents résultats
      return searchResult && statusResult && typeResult && adminsResult
    })
    // On trie les campagnes selon le filtre d'ancienneté
    return getSortedCampaigns(campaignFiltered, sort)
  } catch (e) {
    console.log('helpers/magicLinks/getCampaignsByFilters error:', e)
    return []
  }
}

/**
 * Renvoie le prénom et nom de l'administrateur de la campagne.
 * @param {object} magicLink - Un magicLink de la campagne.
 * @param {array} collaborators - Les collaborateurs de l'entité.
 * @returns {string} Le prénom et nom de l'administrateur de la campagne, ou N/C
 */
const getAdminName = (magicLink, collaborators) => {
  const adminUser = getCampaignAdmin(magicLink, collaborators)
  const { additionalInfos: { firstName, lastName } = {} } = adminUser
  if (firstName && lastName) return `${firstName} ${lastName}`
  if (firstName) return `${firstName}`
  if (lastName) return `${lastName}`
  return 'N/C'
}

/**
 * Permet de connaître le statut d'un magicLink ('committed' => envoyé, 'spent' => utilisé, 'expired' => expiré)
 * @param {object} magicLink - Le magicLink dont le statut est à déterminer
 * @returns {string} Le statut du magicLink
 */
const getMagicLinkStatus = (magicLink) => {
  const { spent, sentAt, lifetime = 30 } = magicLink
  if (spent) return 'spent'
  if (parseInt(dayjs().diff(dayjs(sentAt), 'day'), 10) > lifetime) return 'expired'
  return 'committed'
}

/**
 * Renvoie une liste de magicLinks en fonction des filtres utilisés.
 * @param {array} campaigns - Les campagnes de l'entité.
 * @param {array} magicLinks - Les magicLinks de l'entité.
 * @param {array} collaborators - Les collaborateurs de l'entité.
 * @param {object} filters - Les filtres utilisés.
 * @param {boolean} restricted - Si les filtres sont restreints (pour le dashboard) ou non (pour la table).
 * @returns {array} Les magicLinks filtrés
 */
const getMagicLinksByFilters = (campaigns, magicLinks, collaborators, filters, restricted = false) => {
  try {
    dayjs.extend(isBetween)
    const {
      campaignsIds, statuses, range = [], adminsIds, search,
    } = filters

    const input = trim(search)
    const start = range[0]
    const end = range[1]

    return magicLinks?.filter((magicLink) => (!adminsIds?.length || adminsIds.includes(magicLink.userId))
      && (!campaignsIds?.length || campaignsIds.includes(magicLink.campaignId))
      && (!range?.length || dayjs(magicLink.sentAt).isBetween(dayjs(start), dayjs(end), null, []))
      && (!statuses?.length || (restricted || statuses.includes(getMagicLinkStatus(magicLink))))
      && (!search?.length || (restricted || trim(magicLink?.email).includes(input)
        || trim(getAdminName(magicLink, collaborators)).includes(input)
        || trim(campaigns.find((c) => c?._id === magicLink.campaignId)?.name).includes(input))
      )).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
  } catch (e) {
    console.log('helpers/magicLinks/getMagicLinksByFilters error:', e)
    return null
  }
}

/**
 * Renvoie un tag traduit et en couleur pour un statut magicLink donné.
 * @param {string} status - Le statut du magicLink tel qu'inscrit en DB.
 * @returns {React.ReactNode} Le tag traduit et à la bonne couleur
 */
const getStatusTag = (status) => {
  switch (status) {
  case 'spent':
    return (
      <Tag style={{
        background: validColor[1],
        color: validColor[6],
        borderRadius: '1000px',
        padding: '1px 8px',
        fontWeight: 500,
        fontSize: '12px',
        border: 0,
      }}
      >
        <FormattedMessage id='campaignTracking.status.spent' defaultMessage='Dépensé' />
      </Tag>
    )
  case 'committed':
    return (
      <Tag style={{
        background: primaryColor[1],
        color: primaryColor[5],
        borderRadius: '1000px',
        padding: '1px 8px',
        fontWeight: 500,
        fontSize: '12px',
        border: 0,
      }}
      >
        <FormattedMessage id='campaignTracking.status.committed' defaultMessage='Engagé' />
      </Tag>
    )
  case 'expired':
    return (
      <Tag style={{
        background: primaryColor[4],
        color: 'white',
        borderRadius: '1000px',
        padding: '1px 8px',
        fontWeight: 500,
        fontSize: '12px',
        border: 0,
      }}
      >
        <FontAwesomeIcon size='sm' icon={faClock} style={{ marginRight: '8px' }} />
        <FormattedMessage id='magicLinks.status.expired' defaultMessage='Expiré' />
      </Tag>
    )
  default:
    return null
  }
}

const isPlannedMagicLink = (sentAt, locale) => {
  const formattedTodayDate = displayDate(dayjs(), locale)
  return dayjs(dayjs(formattedTodayDate, 'DD-MM-YYYY HH:mm')).isBefore(dayjs(sentAt, 'DD-MM-YYYY HH:mm'), 'minute')
}

/**
 * Renvoie les colonnes de la table de suivi des magicLinks en fonction du statut utilisateur.
 * @param {boolean} isAdmin - Si l'utilisateur est administrateur de son entité (ou de Panopli).
 * @param {function} callback - La fonction à exécuter en callback
 * @param {string} locale - La langue de l'application
 * @returns {array} Les colonnes de la table de suivi
 */
const getColumns = (isAdmin, callback, locale, campaigns) => {
  const start = [
    getFormattedDate(locale),
    getFormattedExpireAt(locale),
    getEmail(),
    getFormattedCampaign(),
  ]
  const admin = [
    getFormattedAdmin(),
  ]
  const end = [
    getFormattedStatus(),
    getFormattedNPS(),
    getAction(callback, locale, campaigns),
  ]
  return isAdmin ? start.concat(admin).concat(end) : start.concat(end)
}

const getWantedColumns = (isAdmin, callback, locale, wantedColumnNames, campaigns) => {
  const columns = getColumns(isAdmin, callback, locale, campaigns)
  return columns.filter((column) => (isAdmin && column?.dataIndex === 'formatted.admin' && wantedColumnNames?.includes(column?.dataIndex))
     || wantedColumnNames?.includes(column?.dataIndex)
     || wantedColumnNames?.includes(column?.key))
}

/** Permet d'obtenir les "n" produits les plus commandés via MagicLink
 * @param {array} orders - Les commandes du shop
 * @param {array} magicLinks - Les magic links du shop
 * @param {array} products - Les produits du shop
 * @param {number} number - Le nombre de produits du top ("n")
 * @returns {array} Les 5 produits les plus commandés, par ordre décroissant
 */
const getTopProducts = (orders, magicLinks, products, number) => {
  try {
    // On récupère les commandes liées aux magic links du shop
    const magicLinksOrders = orders.filter((order) => magicLinks.some(
      (magicLink) => magicLink._id === order.magicLinkId,
    ))
    // On récupère les produits de ces commandes
    const orderedProducts = flatten(magicLinksOrders.map((order) => order.products))
    // On récupère les ids de ces produits
    const productsIds = orderedProducts.map((product) => product._id || product.id)
    // On dresse une liste sans doublon et sans "undefined"
    const productsIdsList = uniq(productsIds).filter(Boolean)
    // On fait le décompte des commandes par produits (ids)
    const productsBenchmark = productsIdsList.map((productId) => ({
      _id: productId,
      count: productsIds.filter((id) => id === productId).length,
    }))
    // On trie la liste par ordre décroissant en fonction du nombre de commandes
    // TODO trier en plus par NPS (pour éviter les ex æquo) lorsqu'ils seront établis en DB
    const sortedBenchmark = productsBenchmark.sort((a, b) => b.count - a.count)
    // On récupère les 5 premiers produits (ids) les plus commandés
    const topBenchmark = sortedBenchmark.splice(0, number)
    // On récupère les produits complets du top 5
    const topProducts = topBenchmark.map((benchmark) => {
      const { _id, count } = benchmark
      const current = products.find((product) => {
        const productId = product._id || product.id
        return productId === _id
      })
      return {
        ...current,
        count,
      }
    })
    return topProducts
  } catch (e) {
    console.log('helpers/magicLinks/getTopProducts error:', e)
    return []
  }
}

/**
 * Permet de récupérer l'image principale d'un produit
 * @param {object} product - Le produit concerné
 * @returns {object} L'objet contenant la source de l'image
 */
const getProductMainImage = (product = {}) => {
  const { picList, baseProduct = {} } = product
  const {
    _id, images, picList: bpPiclist, shopifyImages,
  } = baseProduct
  const productId = product._id || product.id
  // Si on a l'image du produit, on la retourne
  if (picList?.length >= 1) return { src: `${imageBaseUrl}/Products/${productId}/customProduct/${picList.find(Boolean)}` }
  // Sinon on regarde parmi les images du produit de base correspondant
  if (images?.length) return sortBy(images, 'position').find(Boolean)
  if (bpPiclist?.length) return bpPiclist.find(Boolean)
  if (shopifyImages) return shopifyImages.find(Boolean)
  return { src: `${imageBaseUrl}/SVGS/${_id}/baseProduct/display.png` }
}

const repushMagicLink = (modifyMagicLinks, magicLinksToResend) => {
  const ids = magicLinksToResend.map((magicLink) => String(magicLink._id))

  const modifications = { sentAt: new Date() }
  const payload = { ids, modifications }
  const callback = notification.open({
    message: (<FormattedMessage id='store.magicLinks.update.success.singular' defaultMessage='Le magic link a bien été relancé !' />),
    placement: 'bottomRight',
    duration: 8,
  })
  return modifyMagicLinks(payload, callback)
}

const getMagicLinksResendable = (magicLinks, selection, resendOne) => magicLinks.filter((magicLink) => (((selection.includes(String(magicLink._id))
|| String(resendOne._id) === String(magicLink._id)) && !magicLink.spent)))

export {
  getBudgetCampaign,
  getMagicLinksCampaign,
  campaignStatAdmin,
  isMagicLinksAdmin,
  getCampaignAdmin,
  getCampaignsByFilters,
  getAdminName,
  getMagicLinkStatus,
  getMagicLinksByFilters,
  hasValidEmailFormat,
  hasAlreadyBeenUsed,
  hasAlreadyBeenSent,
  displayUnvalidFormat,
  isPlannedMagicLink,
  getStatusTag,
  getColumns,
  getTopProducts,
  getProductMainImage,
  getWantedColumns,
  isParametersNextStepDisabled,
  isSendingMailsNextDisabled,
  isSendDateValid,
  displayWrongMails,
  isUsableEmailAddress,
  hasCorrectCSVHeaders,
  repushMagicLink,
  getMagicLinksResendable,
}

import React from 'react'
import {
  Switch,
} from 'antd'
import { FormattedMessage } from 'react-intl'

import {
  blockStyle, rowStyle, columnStyle, mainTitleStyle, titleStyle,
} from '../../../helpers/hiddenCustom'

const HiddenCustomNavMenu = (props) => {
  try {
    const { newCustom, setNewCustom } = props
    const { navMenu } = newCustom
    const { hideButton, hideSocial } = navMenu
    return (
      <div style={blockStyle}>
        <div style={mainTitleStyle}>
          <FormattedMessage
            id='custom.navMenu'
            defaultMessage='Menu de navigation'
          />
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.navMenuButton'
                defaultMessage="Masquer le bouton d'ouverture"
              />
            </div>
            <Switch
              checked={hideButton}
              onChange={(value) => setNewCustom({ ...newCustom, navMenu: { ...navMenu, hideButton: value } })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage
                id='custom.navMenuSocial'
                defaultMessage='Masquer les réseaux sociaux'
              />
            </div>
            <Switch
              checked={hideSocial}
              onChange={(value) => setNewCustom({ ...newCustom, navMenu: { ...navMenu, hideSocial: value } })}
            />
          </div>
          <div style={columnStyle} />
          <div style={columnStyle} />
        </div>
      </div>
    )
  } catch (e) {
    console.log('molecule/HiddenCustomNavMenu error:', e)
    return null
  }
}

export default HiddenCustomNavMenu

import React from 'react'
import { withRouter } from 'react-router-dom'
import { Drawer } from 'antd'
import { FormattedMessage } from 'react-intl'
import CustomizationDrawer from '../../molecules/CustomizationDrawer'
import CheckoutRecap from '../CheckoutRecap'

class CartDrawer extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    const {
      cartContent = [],
      visible,
      onClose,
      allProducts,
      empty,
      remove,
      history,
      customizeProduct,
      suppliers,
      shop,
      changeCartCountry,
      productsPrice,
      deliveryPrice,
      cartPrice,
      locale = 'fr',
    } = this.props
    // NOTE edge case cart empty
    if (!cartContent || !cartContent.length) {
      return (
        <Drawer
          visible={visible}
          width='25%'
          footer={null}
          onClose={onClose}
          title={<FormattedMessage id='cart' defaultMessage='Panier' />}
        >
          <FormattedMessage id='cartDrawer.emptyCart' defaultMessage='Votre Panier est vide' />
        </Drawer>
      )
    }
    const { customizing } = this.state
    const shouldCustomize = allProducts.find((p) => cartContent.find((c) => c._id === p._id)
      && p.clientCustomization && p.clientCustomization.length)
    const cart_product_customizing = cartContent.find((p) => p.cart_id === customizing) || {}
    const product = allProducts.find((p) => String(p._id) === cart_product_customizing._id)
    return (
      <Drawer
        visible={visible}
        width='25%'
        onClose={onClose}
        title={<FormattedMessage id='cart' defaultMessage='Panier' />}
        id='CartDrawer'
      >
        <CheckoutRecap
          cartContent={cartContent}
          allProducts={allProducts}
          deliveryPrice={deliveryPrice}
          shop={shop}
          suppliers={suppliers}
          remove={remove}
          productsPrice={productsPrice}
          cartPrice={cartPrice}
          changeCartCountry={changeCartCountry}
          manageCustomizations={(cart_id) => this.setState({
            customizing: cart_id,
          })}
          showButton
          empty={empty}
          onClose={onClose}
          history={history}
          locale={locale}
        />
        {shouldCustomize ? (
          <CustomizationDrawer
            visible={customizing}
            onClose={() => {
              this.setState({
                customizing: false,
              })
            }}
            customizeProduct={customizeProduct}
            cart_product={cart_product_customizing}
            product={product}
          />
        ) : null}
      </Drawer>
    )
  }
}

export default withRouter(CartDrawer)

import React from 'react'
import { notification } from 'antd'
import {
  put, call, takeEvery, select,
} from 'redux-saga/effects'
import { FormattedMessage } from 'react-intl'
import * as actions from './actions'
import { fromUsers } from '../selectors'

const { getAuth } = fromUsers

const endPointAPI = '/images'

export function* uploadImage(api, { payload, callback }) {
  try {
    yield put({ type: actions.START_UPLOAD })
    const auth = yield select(getAuth)
    const imageUploaded = yield call([api, api.post], endPointAPI, {
      ...payload,
    }, {
      headers: {
        mode: 'cors',
        authorization: auth,
      },
    })
    console.log(`image uploaded successfully at [${imageUploaded.fileUrl}]`)
    callback(imageUploaded.fileUrl)
    yield put({ type: actions.SUCCESS_UPLOAD })
    return true
  } catch (e) {
    console.log('uploadImage error', e, e.message)
    yield put({
      type: actions.ERROR,
    })

    notification.error({
      message: (<FormattedMessage
        id='store.editCampaign.create.error'
        defaultMessage='Oups, une erreur est survenue...'
      />),
      placement: 'topRight',
      duration: 8,
    })
    return false
  }
}

export default function* ({ api }) {
  yield takeEvery(actions.UPLOAD, uploadImage, api)
}

import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Refresher } from '../../components'
import { Theme } from '../../containers'
import { INIT_FETCH_DATA } from '../../store/shops/actions'

const Customization = (props) => {
  const { history, shopId, fetchData } = props
  console.log('IN CUSTOMIZATION', shopId)
  useEffect(() => {
    console.log('fetchData is called')
    fetchData()
  }, [shopId])

  return (
    <div
      className='Theme page'
      style={{ height: '100%', backgroundColor: 'white' }}
    >
      <Refresher fetchData={fetchData} />
      <Theme history={history} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(Customization))

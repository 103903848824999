export const FETCH_FORCE = 'FETCH_FORCE_DISCOUNTS'

export const GET = 'GET_DISCOUNTS'
export const RECEIVED = 'RECEIVED_DISCOUNTS'
// TODO ENHANCE ERROR WITH MESSAGE
export const ERROR = 'ERROR_DISCOUNTS'
export const MODIFY = 'MODIFY_DISCOUNT'
export const LOADING = 'LOADING_DISCOUNT'
export const MODIFIED = 'MODIFIED_DISCOUNT'
export const DELETE = 'DELETE_DISCOUNT'
export const DELETED = 'DELETED_DISCOUNT'
export const CREATE_DISCOUNT = 'CREATE_DISCOUNT'
export const CREATED_DISCOUNT = 'CREATED_DISCOUNT'
export const INIT_FETCH_DATA = 'INIT_FETCH_DATA_DISCOUNTS'
export const END_FETCH_DATA = 'END_FETCH_DATA_DISCOUNTS'

// TODO DISPATCH ERROR_DISCOUNTS ON CATCH
export const modifyDiscount = (discount) => {
  try {
    return {
      type: MODIFY,
      payload: {
        discount,
      },
    }
  } catch (e) {
    console.log('discounts/actions - modify', e)
    return -1
  }
}

export const deleteDiscounts = (ids) => {
  try {
    return {
      type: DELETE,
      payload: {
        ids,
      },
    }
  } catch (e) {
    console.log('dscounts/actions - delete')
    return -1
  }
}

export const createDiscount = (discount) => {
  try {
    console.log('createDiscount function', discount)
    return {
      type: CREATE_DISCOUNT,
      payload: {
        discount,
      },
    }
  } catch (e) {
    console.log('discounts/actions - create', e)
    return -1
  }
}

/* eslint-disable max-len */
import React, { useState } from 'react'
import {
  InputNumber,
  Select,
  Card,
  Button,
  Icon,
  Alert,
  Input,
  message,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { CSVReader } from 'react-papaparse'
import { CSVLink } from 'react-csv'
import { uniq, uniqBy } from 'lodash'

import {
  getMargin, getPrice, generateTechnicId,
} from '../../../helpers/baseProducts/creation'

const { Option } = Select
const Technic = (props) => {
  const {
    addStage, product, updateProduct, newStage, setNewStage, technic, getAllTechnics,
  } = props
  const dbTechnics = getAllTechnics()
  const disabled = dbTechnics.find((_technic) => _technic.idPanopli === technic.idPanopli)
  // const [dematerializedLength, setDematerializedLength] = useState(product.dematerialized?.codes?.length || false)

  return (
    <Card
      title={(
        <Input
          defaultValue={technic.name}
          disabled={disabled}
          onChange={(e) => {
            const newTechnics = product.technics.map((_technic) => {
              if (technic.idPanopli === _technic.idPanopli) {
                return ({
                  ..._technic,
                  name: e.target.value,
                })
              } return _technic
            })
            updateProduct(product.provisionalId, {
              technics: newTechnics,
            })
          }}
        />
      )}
      style={{ margin: '0px 5px 5px 0px', flex: '1 2', minWidth: '33%' }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '1 2' }}>
          <div style={{
            fontWeight: 'bold', margin: '10px 0',
          }}
          >
            <FormattedMessage id='baseProductTechnicsForm.techniqueFamily' defaultMessage='A quelle famille appartient cette technique ?' />
          </div>
          <Select
            showSearch
            id='family'
            disabled={disabled}
            style={{ width: '100%' }}
            value={technic.family}
            placeholder={<FormattedMessage id='family' defaultMessage='Famille' />}
            onChange={(value) => {
              if (value) {
                const newTechnics = product.technics.map((_technic) => {
                  if (technic.idPanopli === _technic.idPanopli) {
                    return ({
                      ..._technic,
                      family: value,
                    })
                  } return _technic
                })
                updateProduct(product.provisionalId, {
                  technics: newTechnics,
                })
              }
            }}
          >
            <Option value='Sans marquage'>
              <FormattedMessage id='noMarking' defaultMessage='Sans marquage' />
            </Option>
            <Option value='Sur-mesure'>
              <FormattedMessage id='tailored' defaultMessage='Sur mesure' />
            </Option>
            <Option value='Sur-devis'>
              <FormattedMessage id='baseProductTechnicsForm.quotation' defaultMessage='Sur devis' />
            </Option>
            <Option value='Sérigraphie'>
              <FormattedMessage id='silkscreen' defaultMessage='Sérigraphie' />
            </Option>
            <Option value='Broderie'>
              <FormattedMessage id='embroidery' defaultMessage='Broderie' />
            </Option>
            <Option value='Numérique/Quadri'>
              <FormattedMessage id='digitalQuadri' defaultMessage='Numérique/Quadri' />
            </Option>
            <Option value='Transfert'>
              <FormattedMessage id='transfer' defaultMessage='Transfert' />
            </Option>
            <Option value='Gravure'>
              <FormattedMessage id='engraving' defaultMessage='Gravure' />
            </Option>
            <Option value='Tampographie'>
              <FormattedMessage id='padPrinting' defaultMessage='Tampographie' />
            </Option>
            <Option value='Sublimation'>
              <FormattedMessage id='sublimation' defaultMessage='Sublimation' />
            </Option>
            <Option value='Embossage/Débossage'>
              <FormattedMessage id='embossingDebossing' defaultMessage='Embossage/Débossage' />
            </Option>
          </Select>
        </div>
        <div style={{ flex: '1 2', marginLeft: 10 }}>
          <div style={{
            fontWeight: 'bold', margin: '10px 0',
          }}
          >
            <FormattedMessage id='baseProductTechnicsForm.supportedColors' defaultMessage='Nombre de couleurs supportées' />
            {' ?'}
          </div>
          <Select
            showSearch
            id='colorSupported'
            disabled={disabled}
            style={{ width: '100%' }}
            value={technic.colorSupported}
            placeholder={<FormattedMessage id='baseProductTechnicsForm.supportedColors' defaultMessage='Nombre de couleurs supportées' />}
            onChange={(value) => {
              if (value) {
                const newTechnics = product.technics.map((_technic) => {
                  if (technic.idPanopli === _technic.idPanopli) {
                    return ({
                      ..._technic,
                      colorSupported: value,
                    })
                  } return _technic
                })
                updateProduct(product.provisionalId, {
                  technics: newTechnics,
                })
              }
            }}
          >
            <Option value='1'>1</Option>
            <Option value='2'>2</Option>
            <Option value='3'>3</Option>
            <Option value='+4'>+4</Option>
          </Select>
        </div>
      </div>
      <div style={{
        fontWeight: 'bold', margin: '10px 0',
      }}
      >
        <FormattedMessage id='baseProductTechnicsForm.stageMargin' defaultMessage='Quelle marge à appliquer sur chaque palier ?' />
      </div>
      <Input
        type='number'
        addonAfter='%'
        disabled={disabled}
        defaultValue={technic.margin}
        onChange={(e) => {
          const value = parseFloat(e.target.value)
          const newTechnics = product.technics.map((_technic) => {
            if (technic.idPanopli === _technic.idPanopli) {
              const { parameters: { cost = [], price = [], margins = [] } } = _technic
              const newPrice = price.map((p, index) => {
                const concernedCost = cost[index]
                return getPrice(concernedCost, value)
              })
              return ({
                ..._technic,
                margin: value,
                parameters: {
                  ..._technic.parameters,
                  margins: margins.map(() => value),
                  price: newPrice,
                },
              })
            } return _technic
          })
          updateProduct(product.provisionalId, {
            technics: newTechnics,
          })
        }}
        style={{ width: '100%' }}
      />
      {product.dematerialized && (
        <>
          <div style={{
            fontWeight: 'bold', margin: '10px 0px',
          }}
          >
            <FormattedMessage id='baseProductTechnicsForm.immaterial' defaultMessage='Dématérialisé - Ajouter la liste des codes' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{

              margin: '0px 5px',
              flex: '1 1 auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <CSVLink
                data={[{
                  code: 'BLABLABLA',
                }]}
                headers={[{
                  label: 'Code',
                  key: 'code',
                }]}
                filename='codes.csv'
                target='_blank'
                onClick={() => message.success(<FormattedMessage id='CSVDownloaded' defaultMessage='CSV téléchargé !' />)}
              >
                <Button type='primary'>
                  <Icon type='download' />
                  <FormattedMessage id='preFilledCSV' defaultMessage='CSV pré-rempli' />
                </Button>
              </CSVLink>
              <Icon style={{ margin: '0 10px' }} type='arrow-right' />
              <CSVReader
                onDrop={(_file) => {
                  try {
                    const codes = []
                    const file = _file
                    delete file[0]
                    file.map((line) => {
                      const { data } = line
                      codes.push({
                        code: data[0],
                        used: false,
                      })
                      return true
                    })
                    const newTechnics = product.technics.map((_technic) => {
                      if (technic.idPanopli === _technic.idPanopli) {
                        return ({
                          ..._technic,
                          codes,
                          newCode: true,
                        })
                      } return _technic
                    })
                    updateProduct(product.provisionalId, {
                      technics: newTechnics,
                      deliveryContribution: 0,
                    })
                    message.success(<FormattedMessage id='baseProductTechnicsForm.codesAdded' defaultMessage='Les codes ont bien été ajoutés à cette technique !' />)
                  } catch (e) {
                    console.log('error containers/MemberCreation/addMembersFromCSV', e)
                    message.error(<FormattedMessage id='error.download' defaultMessage='Erreur lors du téléchargement' />)
                  }
                }}
                addRemoveButton
                style={{
                  dropFile: {
                    height: 50,
                    borderRadius: 3,
                    width: 150,
                    padding: 0,
                  },
                  fileSizeInfo: {
                    display: 'none',
                  },
                  fileNameInfo: {
                    padding: 5,
                    lineHeight: 'inherit',
                  },
                  progressBar: {
                    backgroundColor: '#0000FF',
                  },
                }}
              >
                <Button type='primary'>
                  <Icon type='upload' />
                  <FormattedMessage id='filledCSV' defaultMessage='CSV rempli' />
                </Button>
              </CSVReader>
            </div>
          </div>
        </>
      )}
      <div style={{ fontWeight: 'bold', margin: '10px 0px' }}>
        <FormattedMessage id='baseProductTechnicsForm.stageAdd' defaultMessage='Ajouter un palier de quantité' />
      </div>
      <div style={{ display: 'flex' }}>
        <InputNumber
          id={technic.name}
          disabled={disabled}
          style={{ flex: 1 }}
          min={0}
          value={newStage}
          onChange={(value) => setNewStage(value)}
          onPressEnter={() => {
            if (newStage) {
              addStage(technic)
              setNewStage()
            } else {
              message.error(<FormattedMessage id='baseProductTechnicsForm.alreadyExistingStage' defaultMessage='Ce palier existe déjà !' />)
            }
          }}
          placeholder='100, 250...'
        />
        <Button
          type='primary'
          icon='plus'
          style={{ marginLeft: '5px' }}
          disabled={disabled}
          onClick={() => {
            if (newStage) {
              addStage(technic)
              setNewStage()
            } else {
              message.error(<FormattedMessage id='baseProductTechnicsForm.alreadyExistingStage' defaultMessage='Ce palier existe déjà !' />)
            }
          }}
        />
      </div>
      {technic.parameters.stage && technic.parameters.stage.length ? (
        <div style={{ fontWeight: 'bold', margin: '10px 0px' }}>
          <FormattedMessage id='baseProductTechnicsForm.priceImpactPerQuantity' defaultMessage='Ajouter les impacts de prix par quantité' />
        </div>
      ) : null}
      {technic.parameters.stage && technic.parameters.stage.length ? technic.parameters.stage.map((s, index) => (
        <Stage
          {...props}
          technic={technic}
          _stage={s}
          margin={technic.margin}
          index={index}
          disabled={disabled}
        />
      ))
        : null }
    </Card>
  )
}

const Stage = injectIntl((props) => {
  const {
    updateProduct, technic, product, index, margin, _stage, disabled,
  } = props
  const intl = useIntl()
  const { parameters: { margins = [] } } = technic
  return (
    <div style={{
      border: '1px solid #D5D5D5',
      borderRadius: '3px',
      margin: '5px 0px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
    >
      <span style={{
        padding: '5px 20px',
        display: 'flex',
        justifyContent: 'center',
        width: '20%',
      }}
      >
        {_stage}
      </span>
      <span style={{ padding: '5px' }}>
        <Input
          type='number'
          placeholder={intl.formatMessage({
            id: 'costExcl',
            defaultMessage: 'Coût HT',
          })}
          disabled={disabled}
          defaultValue={technic.parameters.cost && technic.parameters.cost.length ? technic.parameters.cost[index] : 0}
          addonAfter='€'
          onChange={(e) => {
            const value = parseFloat(e.target.value)
            const newTechnics = product.technics.map((_technic) => {
              if (technic.idPanopli === _technic.idPanopli) {
                const { parameters: { cost = [], price = [] } } = _technic
                const newPrice = margin ? getPrice(value, margin) : value
                cost[index] = parseFloat(value)
                price[index] = parseFloat(newPrice.toFixed(2))
                return ({
                  ..._technic,
                  parameters: {
                    ..._technic.parameters,
                    cost,
                    price,
                  },
                })
              } return _technic
            })
            updateProduct(product.provisionalId, {
              technics: newTechnics,
            })
          }}
        />
      </span>
      <span style={{ padding: '5px' }}>
        <Input
          placeholder={intl.formatMessage({
            id: 'baseProductTechnicsForm.margin',
            defaultMessage: 'Marge %',
          })}
          disabled={disabled}
          addonAfter='%'
          value={margins[index] || ''}
          defaultValue={margin}
          onChange={(e) => {
            const value = parseFloat(e.target.value)
            const newTechnics = product.technics.map((_technic) => {
              const { parameters: { price = [], cost = [] } } = _technic
              if (technic.idPanopli === _technic.idPanopli) {
                const newPrice = getPrice(cost[index], value)
                price[index] = parseFloat(newPrice.toFixed(2))
                margins[index] = parseFloat(value)
                return ({
                  ..._technic,
                  parameters: {
                    ..._technic.parameters,
                    price,
                    margins,
                  },
                })
              } return _technic
            })
            updateProduct(product.provisionalId, {
              technics: newTechnics,
            })
          }}
        />
      </span>
      <span style={{ padding: '5px' }}>
        <Input
          type='number'
          disabled={disabled}
          placeholder={intl.formatMessage({
            id: 'priceExcl',
            defaultMessage: 'Prix HT',
          })}
          defaultValue={technic.parameters.price[index] || ''}
          value={technic.parameters.price[index] || ''}
          style={{
            width: '100%',
            textAlign: 'right',
          }}
          addonAfter='€'
          onChange={(e) => {
            const value = parseFloat(e.target.value)
            const newTechnics = product.technics.map((_technic) => {
              if (technic.idPanopli === _technic.idPanopli) {
                const { parameters: { cost = [] } } = _technic
                const newPrice = _technic.parameters.price && _technic.parameters.price.length ? _technic.parameters.price : []
                const newMargin = getMargin(value, cost[index])
                newPrice[index] = parseFloat(value)
                margins[index] = parseFloat(newMargin.toFixed(2))
                return ({
                  ..._technic,
                  parameters: {
                    ..._technic.parameters,
                    price: newPrice,
                    margins,
                  },
                })
              } return _technic
            })
            updateProduct(product.provisionalId, {
              technics: newTechnics,
            })
          }}
        />
      </span>
      <div
        style={{
          padding: '5px 10px',
        }}
        onClick={() => {
          const newTechnics = product.technics.map((_technic) => {
            if (technic.idPanopli === _technic.idPanopli) {
              const {
                price, cost, stage,
              } = _technic.parameters
              // No shadowing margins is already declared uppper in the file
              // Hack , price cost and stage couldnt be undefined but margins can
              const _margins = technic.parameters.margins || []
              price.splice(index, 1)
              cost.splice(index, 1)
              _margins.splice(index, 1)
              return ({
                ..._technic,
                parameters: {
                  ..._technic.parameters,
                  stage: stage.filter((_s) => _s !== _stage),
                  price,
                  cost,
                  margins,
                },
              })
            } return _technic
          })
          updateProduct(product.provisionalId, {
            technics: newTechnics,
          })
        }}
      >
        <Icon type='delete' />
      </div>
    </div>
  )
})

const BaseProductTechnicsForm = (props) => {
  try {
    const {
      product,
      updateProduct,
      baseProducts,
      allTechnics,
      products,
    } = props
    const [newStage, setNewStage] = useState()
    const getAllTechnics = () => {
      try {
        const { dbTechnics } = allTechnics
        // let tech = []
        // if (!allTechnics.length) {
        //   baseProducts.map(baseProduct => baseProduct.technics
        //     && baseProduct.technics.length && baseProduct.technics.map(technic => allTechnics.push(technic)))
        //   tech = uniqBy(allTechnics, 'idPanopli')
        // }
        // return tech
        const concatTechnics = dbTechnics.concat((product && product.technics) || [])
        return uniqBy(dbTechnics, 'idPanopli')
      } catch (e) {
        return []
      }
    }

    const addStage = (technic) => {
      const newTechnics = product.technics.map((_technic) => {
        if (technic.idPanopli === _technic.idPanopli) {
          const {
            stage = [], price = [], cost = [], margins = [],
          } = _technic.parameters
          const moq = Math.min(...stage.concat(newStage))
          stage.push(newStage)
          if (product.pricing && product.pricing.costHT && product.pricing.margin) {
            cost.push(product.pricing && product.pricing.costHT ? product.pricing.costHT : '')
            const _margin = technic.margin ? technic.margin : product.pricing && product.pricing.margin ? product.pricing.margin : ''
            margins.push(_margin)
            const paht = getPrice(product.pricing.costHT, _margin)
            price.push(paht)
          }
          return ({
            ..._technic,
            parameters: {
              ..._technic.parameters,
              moq,
              stage: uniq(stage),
              price,
              cost,
              margins,
            },
          })
        } return _technic
      })
      updateProduct(product.provisionalId, {
        technics: newTechnics,
      })
    }
    const allTechnicsFromDbAndProducts = getAllTechnics()
    const { shopifyTechnics } = allTechnics
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {product.idPanopli ? (
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              <FormattedMessage id='baseProductTechnicsForm.techniquesChoice' defaultMessage='Choisir les techniques utilisable pour ce produit' />
            </div>
            <Select
              showSearch
              mode='tags'
              style={{ width: '100%', marginBottom: '10px' }}
              placeholder={<FormattedMessage id='baseProductTechnicsForm.placeholder.techniques' defaultMessage='Sérigraphie, impression numérique...' />}
              defaultValue={product.technics && product.technics.length ? product.technics.map((technic) => technic.name) : []}
              onChange={(value) => {
                let number = 0
                const newValue = value.map((v) => {
                  number += 1
                  const concernedTechnic = allTechnicsFromDbAndProducts.find((technic) => technic.idPanopli === v)
                  if (product.technics && product.technics.filter((technic) => technic.name === v).length) {
                    return product.technics.find((technic) => technic.name === v)
                  } if (allTechnicsFromDbAndProducts && allTechnicsFromDbAndProducts.length && allTechnicsFromDbAndProducts.filter((technic) => technic.idPanopli === v).length) {
                    const toAdd = {
                      ...concernedTechnic,
                      parameters: {
                        ...concernedTechnic.parameters,
                        margins: concernedTechnic.parameters.stage.map((s, index) => getPrice(concernedTechnic.parameters.cost[index], concernedTechnic.parameters.price[index])),
                      },
                    }
                    return toAdd
                  }
                  return ({
                    idPanopli: generateTechnicId(shopifyTechnics, product, baseProducts, number, products),
                    name: v,
                    parameters: {
                      moq: null,
                      stage: [],
                      price: [],
                      cost: [],
                    },
                  })
                })
                updateProduct(product.provisionalId, {
                  technics: newValue,
                })
              }}
            >
              {allTechnicsFromDbAndProducts.map((technic) => (
                <Option value={technic.idPanopli}>
                  <span style={{ fontWeight: '700', marginRight: '10px' }}>{technic.name}</span>
                  (
                  {technic.idPanopli}
                  )
                </Option>
              ))}
            </Select>
            {product.technics && product.technics.length ? (
              <div>
                <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                  <FormattedMessage id='baseProductTechnicsForm.techniquesList' defaultMessage='Liste des techniques' />
                </div>
                <div style={{
                  display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                }}
                >
                  {product.technics.map((technic) => (
                    <Technic
                      {...props}
                      newStage={newStage}
                      setNewStage={setNewStage}
                      addStage={addStage}
                      technic={technic}
                      getAllTechnics={getAllTechnics}
                    />
                  ))}
                </div>
              </div>
            ) : null}

          </div>
        ) : (
          <Alert
            message={(
              <FormattedMessage
                id='baseProductTechnicsForm.alertMessage'
                defaultMessage='Attention : Une sous-catégorie est obligatoire pour créer correctement les ids des techniques'
              />
            )}
            type='warning'
            closable
            showIcon
            style={{ marginBottom: '10px' }}
          />
        )}

      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/BaseProductTechnicsForm')
    return null
  }
}

export default BaseProductTechnicsForm

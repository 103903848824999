import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import dayjs from 'dayjs'
import { flatten } from 'lodash'
import {
  Typography, Button, Descriptions, Tag,
} from 'antd'

import { fromOrders } from '../../store/selectors'

import { BreakdownChart } from '../../components'
import theme from '../../config/theme'

const { Paragraph, Title } = Typography

class OrderBreakdown extends React.Component {
  constructor() {
    super()
    this.state = {
      allBestSellers: false,
    }
  }

  bestSellers(bestSellers) {
    if (!bestSellers || !bestSellers.length) return null
    return bestSellers.map((stat, index) => (
      <Descriptions.Item
        key={`${stat.color} - ${stat.size} : ${stat.value}`}
        span={24}
        label={`#${index + 1}`}
      >
        {`${stat.color} - ${stat.size} : ${stat.value}`}
      </Descriptions.Item>
    ))
  }

  render() {
    const {
      product,
      orders,
      history,
      isDisplayOnly = false,
    } = this.props
    if (!orders || !orders.length || !product || !product._id) {
      return (
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%',
        }}
        >
          <Paragraph>
            <Tag>
              <FormattedMessage id='orderBreakDown.notSoldYet' defaultMessage="Vous n'avez pas encore vendu ce produit" />
            </Tag>
          </Paragraph>
        </div>
      )
    }
    const orderedProducts = flatten(orders.filter((o) => o.status !== 'Cancelled').map((o) => o.products))
      .filter((p) => p._id === product._id || p.id === product._id)
    let stats = {
      colors: {},
      sizes: {},
      combo: {},
    }
    try {
      stats = orderedProducts.reduce((acc, cur) => {
        const {
          variations,
          quantity,
        } = cur
        const {
          size,
          color,
        } = variations
        if (acc.sizes[size.name]) {
          acc.sizes[size.name].value += quantity
        } else {
          acc.sizes[size.name] = {
            value: quantity,
            name: size.name,
          }
        }
        if (acc.colors[color.name]) {
          acc.colors[color.name].value += quantity
        } else {
          acc.colors[color.name] = {
            value: quantity,
            color,
            name: color.name,
          }
        }
        if (!acc.combo[color.name]) {
          acc.combo[color.name] = {
            color,
          }
        }
        if (acc.combo[color.name][size.name]) {
          acc.combo[color.name][size.name] += quantity
        } else {
          acc.combo[color.name][size.name] = quantity
        }
        return acc
      }, {
        colors: {},
        sizes: {},
        combo: {},
      })
    } catch (error) {
      // NOTE do nothing
    }

    const { allBestSellers } = this.state
    let bestSellers
    try {
      bestSellers = flatten(Object.keys(stats.combo).map((color) => Object.keys(stats.combo[color])
        .filter((k) => k !== 'color')
        .map((size) => ({
          color,
          size,
          value: stats.combo[color][size],
        }))))
        .sort((a, b) => (a.value > b.value ? -1 : 1))
    } catch (error) {
      // NOTE do nothing
    }

    const lastOrderDate = dayjs(orders.sort((a, b) => dayjs(a.createdAt).isAfter(dayjs(b.createdAt)))
      .find(Boolean).createdAt)
      .format('DD-MM-YYYY HH:mm')

    return (
      <div style={{
        display: 'flex', flexDirection: 'column', width: '100%',
      }}
      >
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20,
        }}
        >
          <div>
            <Paragraph style={{ color: theme.neutralColor[6][12], fontWeight: 'bold' }}>
              <FormattedMessage
                id='orderBreakDown.numberOfOrders'
                defaultMessage='Nombre de commandes : {number}'
                values={{ number: product.ordered || '0' }}
              />
            </Paragraph>
            <Paragraph style={{ color: theme.neutralColor[6][12], fontWeight: 'bold' }}>
              <FormattedMessage
                id='orderBreakDown.lastOrdersDate'
                defaultMessage='Date dernière commande : {date}'
                values={{ date: lastOrderDate }}
              />
            </Paragraph>
          </div>
          <Button
            type='primary'
            onClick={() => history.push(`/orders?product=${product._id}`)}
            disabled={isDisplayOnly}
          >
            <FormattedMessage id='orderBreakDown.productSales' defaultMessage='Voir les ventes de ce produit' />
          </Button>
        </div>
        <div style={{
          display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center', justifyContent: 'space-evenly',
        }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Title level={4}>
              <FormattedMessage id='orderBreakDown.ordersByColor' defaultMessage='Commandes par Couleurs' />
            </Title>
            <BreakdownChart
              data={stats.colors}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Title level={4}>
              <FormattedMessage id='orderBreakDown.ordersBySize' defaultMessage='Commandes par Tailles' />
            </Title>
            <BreakdownChart
              data={stats.sizes}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: 10 }}>
            <FormattedMessage id='orderBreakDown.mostOrderedCombos' defaultMessage='Combinaisons les plus commandées :' />
          </span>
          <Descriptions bordered column={24}>
            {this.bestSellers(allBestSellers ? bestSellers : bestSellers.slice(0, 3))}
            {bestSellers && bestSellers.length > 3 ? (
              <Descriptions.Item span={24}>
                <Button onClick={() => this.setState({ allBestSellers: !allBestSellers })}>
                  {allBestSellers
                    ? <FormattedMessage id='hide' defaultMessage='Cacher' />
                    : <FormattedMessage id='seeAll' defaultMessage='Voir tout' />}
                </Button>
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(
  (state, ownProps) => ({
    orders: fromOrders.getRelatedOrders(state, { product: ownProps.product._id }),
  }),
)(OrderBreakdown))

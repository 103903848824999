import React, { useState } from 'react'
import {
  Alert, Button, Checkbox, Divider, Input, Icon,
} from 'antd'
import { FormattedMessage } from 'react-intl'

import theme from '../../../config/theme'

const { primaryColor, validColor } = theme
const ApiKeyCreated = (props) => {
  const { apiKeyCreated, onClose } = props

  const [confirmed, setConfirmed] = useState(false)
  const [copied, setCopied] = useState(false)

  if (!apiKeyCreated) return null
  return (
    <>
      <h1 style={{ fontWeight: 'bold', margin: '25% 0 0 0' }}>
        <FormattedMessage
          id='developpers.drawer.created.success'
          defaultMessage='Votre clé API a bien été créée,'
        />
      </h1>
      <h1 style={{ fontWeight: 'bold', margin: '0', lineHeight: '1' }}>
        <FormattedMessage
          id='developpers.drawer.created.success.warning'
          defaultMessage='ne perdez pas son numéro !'
        />
      </h1>
      <Divider />
      <div style={{ marginBottom: '10px' }}>
        <h4 style={{ marginBottom: '5px' }}>
          <FormattedMessage
            id='developpers.drawer.created.copy'
            defaultMessage='Copiez votre clé API'
          />
        </h4>
        <Input
          id={copied ? 'copied' : false}
          value={apiKeyCreated}
          suffix={(
            <Icon
              type='copy'
              style={{ color: copied ? validColor : primaryColor }}
              onClick={() => {
                setCopied(true)
                navigator.clipboard.writeText(apiKeyCreated)
                setTimeout(() => setCopied(false), 5000)
              }}
            />
          )}
        />
        <h4 style={{
          color: validColor, opacity: copied ? '1' : '0', marginTop: '10px', transition: 'opacity 0.2s',
        }}
        >
          <FormattedMessage
            id='developpers.drawer.created.copied'
            defaultMessage='Votre clé API a bien été copiée'
          />
        </h4>
      </div>
      <Alert
        className='apiKey'
        message={(
          <FormattedMessage
            id='developpers.drawer.created.alert.message'
            defaultMessage='Ne perdez pas le numéro de votre clé API'
          />
        )}
        description={(
          <FormattedMessage
            id='developpers.drawer.created.alert.description'
            defaultMessage='En cas de perte, nous ne serons pas en mesure de vous fournir le numéro de
            votre clé API, vous serez dans l’obligation d’en créer une nouvelle.'
          />
        )}
        type='warning'
        icon={<Icon type='exclamation-circle' theme='filled' />}
        showIcon
      />
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
      }}
      >
        <Divider />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 24px 24px 24px',
          gap: '24px',
        }}
        >
          <Checkbox defaultChecked={false} onChange={(e) => setConfirmed(e.target.checked)}>
            <FormattedMessage
              id='developpers.drawer.created.checkbox'
              defaultMessage='Je confirme avoir copié et conserver ma clé API'
            />
          </Checkbox>
          <Button type='primary' onClick={onClose} disabled={!confirmed}>
            <FormattedMessage
              id='developpers.drawer.created.done'
              defaultMessage='J&apos;ai terminé !'
            />
          </Button>
        </div>
      </div>
    </>
  )
}

export default ApiKeyCreated

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Drawer,
} from 'antd'

import { FormattedMessage } from 'react-intl'
import { RatioMembership } from '../../components'

import { MODIFY } from '../../store/shops/actions'
import { MODIFY_MEMBERS_RATIO } from '../../store/members/actions'

import { fromShops, fromMembers } from '../../store/selectors'

export default (connect(
  (state) => ({
    allMembers: fromMembers.getMembers(state),
    shop: fromShops.getShop(state),
  }), (dispatch) => bindActionCreators({
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
    modifyMembersRatio: (payload) => ({
      type: MODIFY_MEMBERS_RATIO,
      payload,
    }),
  }, dispatch),
)((props) => {
  const {
    visibleDrawer,
    hideDrawer,
    modifyShop,
    shop,
    modifyMembersRatio,
    allMembers,
  } = props

  return (
    <Drawer
      title={<FormattedMessage id='ratioDrawer.title' defaultMessage='Définir mon ratio' />}
      width={640}
      closable
      visible={visibleDrawer}
      onClose={hideDrawer}
    >
      <RatioMembership
        modifyShop={modifyShop}
        shop={shop}
        modifyMembersRatio={modifyMembersRatio}
        allMembers={allMembers}
      />
    </Drawer>
  )
}))

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { InnerLayout } from '../../layouts'
import { BadgeBeta } from '../../components/atoms'

import { Developers, ApiKeyDrawer } from '../../containers'
import { INIT_FETCH_DATA } from '../../store/apiKeys/actions'

const DevelopersPage = (props) => {
  const { history, shopId, fetchData } = props

  const [extra, setExtra] = useState([])
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [currentApiKey, setCurrentApiKey] = useState(false)

  useEffect(() => {
    fetchData()
  }, [shopId])

  return (
    <>
      {drawerVisible && (
        <ApiKeyDrawer
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          currentApiKey={currentApiKey}
          setCurrentApiKey={setCurrentApiKey}
        />
      )}
      <InnerLayout
        title={(
          <div style={{
            fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
          }}
          >
            <FormattedMessage
              id='developpers.page.title'
              defaultMessage='Développeurs'
            />
            <BadgeBeta />
          </div>
        )}
        extra={extra}
      >
        <Developers history={history} setExtra={setExtra} setDrawerVisible={setDrawerVisible} setCurrentApiKey={setCurrentApiKey} />
      </InnerLayout>
    </>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(DevelopersPage))

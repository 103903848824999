import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Typography,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { TVAForm } from '../../components'
import { MODIFY } from '../../store/shops/actions'
import { fromShops } from '../../store/selectors'

const { Title } = Typography

export default connect(
  (state) => ({
    shopSettings: fromShops.getShopSettings(state),
    shop: fromShops.getShop(state),
  }),
  (dispatch) => bindActionCreators({
    modifyShop: (payload) => ({
      type: MODIFY,
      payload,
    }),
  }, dispatch),
)(({
  shopSettings,
  modifyShop,
  shop,
}) => (
  <div id='vat'>
    <Title level={2}>
      <FormattedMessage
        id='settings.vat'
        defaultMessage='Taxes'
      />
    </Title>
    <p>
      <FormattedMessage
        id='settings.vat.helper'
        defaultMessage="Si votre TVA n'est pas soumise aux 20% habituels, vous pouvez la modifier ici."
      />
    </p>
    <TVAForm
      shopSettings={shopSettings}
      modifyShop={modifyShop}
      shop={shop}
    />
  </div>
))

import React, { useEffect, useState } from 'react'
import {
  CampaignParametersCard,
} from '../..'

const CampaignCreationParameters = (props) => {
  try {
    const {
      setCampaign, // Required
      campaign, // Required
      shop, // Required
      moveNextStep,
      setNeedUpdateCampaign, // Required
      createdCampaignId, // Required
    } = props

    const [isContinueDisabled, setIsContinueDisabled] = useState(campaign?.name?.length <= 0)

    useEffect(() => {
      setIsContinueDisabled(campaign?.name?.length <= 0)
    }, [campaign?.name])

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CampaignParametersCard
          shop={shop}
          campaign={campaign}
          setCampaign={setCampaign}
          setIsContinueDisabled={setIsContinueDisabled}
          setNeedUpdateCampaign={setNeedUpdateCampaign}
          createdCampaignId={createdCampaignId}
          moveNextStep={moveNextStep}
          isContinueDisabled={isContinueDisabled}
        />
        <div>
          {/* TODO: add block when marketing decides what to put here. */}
        </div>
      </div>
    )
  } catch (e) {
    console.log('error molecules/CampaignCreationParameters/index.js', e)
    return null
  }
}

export default CampaignCreationParameters

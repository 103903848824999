/**
 * Applye a sorter (a function) on data to sort it.
 * The sorter is a function that must return  an array.
 * The returned value is a sorted array.
 */
const applySorter = (sorter, data) => {
  if (!(sorter instanceof Function)) {
    console.log('sorter must be a function, given: ', sorter)
    return data
  } if (!(data instanceof Array)) {
    console.log('data must be an array, given: ', data)
    return []
  }
  return sorter(data)
}

/**
 * Descendently sort an array of products by createdAt attribute.
 */
const sortProductsByMoreRecentDate = (dataToSort) => {
  if (!(dataToSort instanceof Array)) {
    console.log('dataToSort must be an array, given: ', dataToSort)
    return []
  }
  return dataToSort.slice().sort((firstObject, secondObject) => {
    if (secondObject?.createdAt === undefined || secondObject.createdAt === undefined) {
      console.log('firstObject and secondObject must contain createdAt attribute, firstObject: %o secondObject: %o', firstObject, secondObject)
      return 0
    }
    return new Date(secondObject.createdAt) - new Date(firstObject.createdAt)
  })
}

/**
 * Ascendently sort an array of products by createdAt attribute.
 */
const sortProductsByMoreRecentDateReverse = (dataToSort) => {
  if (!(dataToSort instanceof Array)) {
    console.log('dataToSort must be an array, given: ', dataToSort)
    return []
  }
  // Slice the original array (slice == copy) because sort does inplace array sorting (modifies the array itself)
  // If we don't do this, when we set the sorted array, no changes are detected and react doesn't rerender sorted data
  return dataToSort.slice().sort((firstObject, secondObject) => {
    if (secondObject?.createdAt === undefined || secondObject.createdAt === undefined) {
      console.log('firstObject and secondObject must contain createdAt attribute, firstObject: %o secondObject: %o', firstObject, secondObject)
      return 0
    }
    return new Date(firstObject.createdAt) - new Date(secondObject.createdAt)
  })
}

export { applySorter, sortProductsByMoreRecentDate, sortProductsByMoreRecentDateReverse }

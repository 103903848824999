import React from 'react'
import { Button, Icon, Input } from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { CSVLink } from 'react-csv'
import { CSVReader } from 'react-papaparse'

import theme from '../../../config/theme'

const CSVGiver = () => {
  const csvData = [['Emails', 'FirstName'], ['']]
  return (
    <CSVLink data={csvData}>
      <Button>
        <FormattedMessage id='CSVmodel' defaultMessage='Modèle de CSV' />
      </Button>
    </CSVLink>
  )
}

const MagicLinksCreationEmailsInsertion = (props) => {
  try {
    const intl = useIntl()

    const {
      creationState,
      setCreationState,
      arbitrate,
    } = props

    const {
      emailsByCSV,
      isUnvalidInput,
      inputEmail,
      inputFirstName,
    } = creationState

    /**
       * Vérifie la validité de l'adresse email entrée dans l'input, et l'insère dans la liste des envois si l'adresse est utilisable
       * @param {string} email - L'adresse mail entrée dans l'input
       * @param {string} firstName - Le prénom entrée dans l'input
       */
    const handleInputAdd = (email, firstName) => arbitrate({ email, firstName }, 'input')

    return (
      <>
        <h3 style={{ fontWeight: 'bold', margin: '10px 0 10px 0' }}>
          <FormattedMessage
            id='campaignTrackingModal.receivers'
            defaultMessage='Destinataires'
          />
        </h3>
        <Button.Group>
          <Button onClick={() => setCreationState({ ...creationState, emailsByCSV: false })}>
            <FormattedMessage
              id='campaignTrackingModal.emailsByInputs'
              defaultMessage='Entrer des emails'
            />
          </Button>
          <Button onClick={() => setCreationState({ ...creationState, emailsByCSV: true })}>
            <FormattedMessage
              id='campaignTrackingModal.emailsByCSV'
              defaultMessage='Importer un CSV'
            />
          </Button>
        </Button.Group>
        {!emailsByCSV ? (
          <>
            <div style={{ marginTop: '10px', display: 'flex' }}>
              <Input
                style={{ flex: '2 5', margin: '0 5px 0 0' }}
                placeholder={intl.formatMessage({
                  id: 'campaignTrackingModal.placeholders.input',
                  defaultMessage: 'Entrez un email',
                })}
                prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={(e) => setCreationState({ ...creationState, inputEmail: e.target.value, isUnvalidInput: false })}
                onPressEnter={() => handleInputAdd(inputEmail, inputFirstName)}
              />
              <Input
                style={{ flex: '2 5', margin: '0 5px 0 0' }}
                placeholder={intl.formatMessage({
                  id: 'campaignTrackingModal.placeholders.input.firstName',
                  defaultMessage: 'Entrez un prénom',
                })}
                suffix={(
                  <span style={{ color: 'rgba(0,0,0,.25)' }}>
                    {intl.formatMessage({
                      id: 'campaignTrackingModal.placeholders.input.firstName.facultatif',
                      defaultMessage: 'facultatif',
                    })}
                  </span>
                )}
                value={inputFirstName}
                prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={(e) => setCreationState({ ...creationState, inputFirstName: e.target.value })}
                onPressEnter={() => handleInputAdd(inputEmail, inputFirstName)}
              />
              <div style={{ flex: '0 5' }}>
                <Button
                  type='primary'
                  icon='enter'
                  onClick={() => handleInputAdd(inputEmail, inputFirstName)}
                />
              </div>
            </div>
            {isUnvalidInput && (
              <span style={{ color: 'red' }}>
                <Icon type='warning' style={{ margin: '5px 5px 0 0' }} />
                <FormattedMessage
                  id='auth.checkEmail'
                  defaultMessage='Le format de cette adresse mail est incorrect.'
                />
              </span>
            )}
          </>
        ) : (
          <div style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginTop: '10px',
          }}
          >
            <CSVGiver />
            <Icon type='arrow-right' />
            <div>
              <CSVReader
                onDrop={(rows) => rows.map((row) => arbitrate({ email: row.data.Emails, firstName: row.data.FirstName }, 'CSV'))}
                addRemoveButton
                config={{ header: true }}
                style={{
                  dropFile: {
                    height: 100,
                    borderRadius: 3,
                    width: 150,
                    padding: 0,
                  },
                  fileSizeInfo: {
                    display: 'none',
                  },
                  fileNameInfo: {
                    padding: 5,
                    lineHeight: 'inherit',
                  },
                  progressBar: {
                    backgroundColor: theme.primaryColor[6],
                  },
                }}
              >
                <Button type='primary'>
                  <Icon type='upload' />
                  {' '}
                  <FormattedMessage id='filledCSV' defaultMessage='CSV rempli' />
                </Button>
              </CSVReader>
            </div>
          </div>
        )}
      </>
    )
  } catch (e) {
    console.log('molecules/MagicLinksCreationEmailsInsertion error:', e)
    return null
  }
}

export default injectIntl(MagicLinksCreationEmailsInsertion)

import { initialState } from './selectors'
import * as actions from './actions'

export default (state = initialState, { type, payload = {} }) => {
  const {
    apiKey,
    data,
  } = payload
  switch (type) {
  case actions.GET:
    return {
      ...state,
      loading: true,
      error: false,
    }
  case actions.LOADING:
    return {
      ...state,
      loading: true,
      error: false,
    }
  case actions.ERROR:
    return {
      ...state,
      error: true,
    }
  case actions.RECEIVED:
    return {
      ...state,
      loading: false,
      error: false,
      data,
    }
  case actions.END_FETCH_DATA:
    return {
      ...state,
      loading: false,
      error: false,
    }
  case actions.CREATED:
    return {
      ...state,
      apiKey,
    }
  case actions.UPDATE:
    return {
      ...state,
      loading: true,
    }
  case actions.DELETE:
    return {
      ...state,
      loading: true,
    }
  case actions.RESET:
    return {
      ...state,
      apiKey: '',
    }
  default:
    return state
  }
}

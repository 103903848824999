import React from 'react'
import {
  Row, Col, Anchor,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { InnerLayout } from '../../layouts'
import {
  AccountForm,
} from '../../containers'

const { Link } = Anchor

const Account = () => (
  <div className='Account page'>
    <InnerLayout
      title={(
        <div style={{
          fontWeight: '600', fontSize: '30px', display: 'flex', alignItems: 'center',
        }}
        >
          <FormattedMessage
            id='settings.pageTitle'
            defaultMessage='Compte'
          />
        </div>
      )}
    >
      <div className='Account page'>
        <Row>
          <Col
            span={5}
            style={{
              padding: '24px',
            }}
          >
            <Anchor
              getContainer={() => document.getElementById('pageContent')}
              bounds={80}
              offsetTop={20}
            >
              <Link
                href='#personnel'
                title={(
                  <FormattedMessage
                    id='settings.personal'
                    defaultMessage='Informations personnelles'
                  />
                )}
              />
              <Link
                href='#notification'
                title={(
                  <FormattedMessage id='notifications' defaultMessage='Notifications' />
                )}
              />
              <Link
                href='#payment'
                title={(
                  <FormattedMessage
                    id='settings.payments'
                    defaultMessage='Paiements enregistrés'
                  />
                )}
              />
              <Link
                href='#streamlabs'
                title={(
                  <FormattedMessage
                    id='settings.stremlabs'
                    defaultMessage='Streamlabs'
                  />
                )}
              />
              {/* <Link
                    href='#language'
                    title={(
                  <FormattedMessage id='language' defaultMessage='Langue' />
                )}
                  /> */}
              <Link
                href='#security'
                title={(
                  <FormattedMessage
                    id='settings.security'
                    defaultMessage='Sécurité'
                  />
                )}
              />
            </Anchor>
          </Col>
          <Col
            span={19}
            style={{
              padding: '24px',
            }}
          >
            <AccountForm />
          </Col>
        </Row>
      </div>
    </InnerLayout>
  </div>
)

export default Account

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { InnerLayout } from '../../layouts'
import { Refresher } from '../../components'
import { ProductContainer } from '../../containers'
import { INIT_FETCH_DATA } from '../../store/products/actions'

/**
 * Page accessible pour les informations et l'édition d'un produit.
 */
const ProductPage = (props) => {
  const {
    history, user, shopId, fetchData,
  } = props
  useEffect(() => {
    fetchData()
  }, [shopId])

  return (
    <InnerLayout>
      <Refresher fetchData={fetchData} />
      <ProductContainer
        user={user}
        history={history}
      />
    </InnerLayout>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: () => ({
    type: INIT_FETCH_DATA,
  }),
}, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(ProductPage))

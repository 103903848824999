import React from 'react'
import {
  Row,
  Col,
  Empty,
  Card,
} from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import Sortable from 'sortablejs'
import { FormattedMessage } from 'react-intl'
import ProductGridCard from '../ProductGridCard'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

// const initialState = {
//   editing: false,
//   currentMedia: {},
// }

class ProductGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayed: 20,
      height: window.screen.height - 330,
    }
  }

  componentDidMount() {
    const {
      sortingArray,
      items,
    } = this.props

    if (items.length > 1) {
      this.list = Sortable.create(document.getElementsByClassName('cardScroller')[0], {
        animation: 150,
        onEnd: this.onSortItems,
        draggable: '.draggable',
        scrollSensitivity: 50,
      })
      this.list.sort(sortingArray)
    }

    const rightWing = document.getElementById('rightContainer')

    if (rightWing && rightWing.offsetHeight) {
      this.setState({
        height: rightWing.offsetHeight,
      })
    }

    // window.dispatchEvent(new Event('resize'))
  }

  componentDidUpdate(prevProps) {
    const {
      sortingArray,
      items,
    } = this.props

    const oldOrder = prevProps.sortingArray.join('')
    const newOrder = sortingArray.join('')

    if (items.length !== prevProps.items.length) {
      this.list = Sortable.create(document.getElementsByClassName('cardScroller')[0], {
        animation: 150,
        onEnd: this.onSortItems,
        draggable: '.draggable',
        scrollSensitivity: 50,
      })
      this.list.sort(sortingArray)
    }

    if (this.list && oldOrder !== newOrder) {
      this.list.sort(sortingArray)
    }
  }

  onSortItems = () => {
    try {
      const {
        setListingOrder,
      } = this.props
      setListingOrder(this.list.toArray())
    } catch (error) {
      console.log('OrganizationGrid - onSortItems', { error })
    }
  }

  renderItems = (datas = []) => {
    const getOffset = (type) => {
      switch (type) {
      case 'desktop':
        return 8
      case 'tablet':
        return 12
      case 'mobile':
        return 24
      default:
        return 0
      }
    }
    const {
      viewport,
      showMeta,
      editDesign,
    } = this.props
    const span = getOffset(viewport)
    return datas.map((item) => (
      <Col
        span={span}
        className='draggable'
        data-id={this.dataId(item)}
        key={this.dataId(item)}
      >
        <ProductGridCard
          item={item}
          editDesign={editDesign}
          showMeta={showMeta}
          // TODO baseURL should come from config
          base_url={imageBaseUrl}
        />
      </Col>
    ))
  }

  dataId = (product) => {
    if (product._id) {
      return product._id
    } if (product.id) {
      return product.id
    }
    return null
  }

  setNext = () => {
    this.setState((prevState) => ({
      displayed: prevState.displayed + 20,
    }))
  }

  render() {
    const {
      items,
    } = this.props

    const {
      displayed,
      height,
    } = this.state

    if (items.length < 1) {
      return <Empty />
    }

    try {
      const showedDatas = items.slice(0, displayed)
      return (
        <Card>
          <Row id='supergrid' gutter={[16, 16]} justify='center'>
            <InfiniteScroll
              dataLength={showedDatas.length}
              // NOTE This is important field to render the next data
              next={() => this.setNext()}
              className='cardScroller'
              hasMore={displayed < items.length}
              height={height}
              loader={(
                <h4>
                  <FormattedMessage
                    id='lazyLoading'
                    defaultMessage='Chargement...'
                  />
                </h4>
              )}
            >
              { this.renderItems(showedDatas) }
            </InfiniteScroll>
          </Row>
        </Card>
      )
    } catch (error) {
      console.log('OrganizationGrid - render', { error })
      return <Empty />
    }
  }
}

export default ProductGrid

import React, { useState } from 'react'
import { faTrashCan, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'
import { CampaignCardButton, CustomizableModal } from '../..'
import theme from '../../../config/theme'

const CampaignCardRemoveButton = (props) => {
  const {
    icon = faTrashCan,
    onClick, // Required
    tooltipFormattedMessage = <FormattedMessage id='magicLink.remove' defaultMessage='Supprimer la campagne' />,
  } = props

  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <>
      <CampaignCardButton
        onClick={() => setIsModalVisible(true)}
        icon={icon}
        tooltipFormattedMessage={tooltipFormattedMessage}
      />
      <CustomizableModal
        isModalVisible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        iconBackgroundColor={theme.warningColor[1]}
        icon={faTriangleExclamation}
        iconColor={theme.warningColor[6]}
        formattedMessageTitle={
          <FormattedMessage id='campaigns.list.card.remove.modal.title' defaultMessage='Souhaitez-vous vraiment supprimer cette campagne ?' />
        }
        FormattedMessageDescriptions={(
          <FormattedMessage
            id='campaigns.list.card.remove.modal.description'
            defaultMessage="La suppression d'une campagne brouillon est irréversible"
          />
        )}
      >
        <Button
          style={{ width: '100%', display: 'block', margin: '24px 0 12px 0' }}
          key='back'
          onClick={() => {
            onClick()
            setIsModalVisible(false)
          }}
          type='danger'
        >
          <FormattedMessage id='delete' defaultMessage='Supprimer' />
        </Button>
        <Button
          style={{ width: '100%', display: 'block', margin: '0' }}
          key='campaigns'
          onClick={() => setIsModalVisible(false)}
        >
          <FormattedMessage
            id='cancel'
            defaultMessage='Annuler'
          />
        </Button>
      </CustomizableModal>
    </>
  )
}

export default CampaignCardRemoveButton

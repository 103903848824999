import { Button } from 'antd'
import React from 'react'

const CustomizableButton = (props) => {
  const {
    content, // Required, JSX
    onClick, // Required
    style,
    type, // Required
  } = props

  return (
    <Button
      onClick={onClick}
      type={type}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '9px 16px',
        height: '40px',
        fontSize: '14px',
        gap: '10px',
        ...style,
      }}
    >
      {content}
    </Button>
  )
}

export default CustomizableButton

module.exports = ({
  primaryColor: {
    1: '#E6E6FF',
    2: '#CCCCFF',
    3: '#9999FF',
    4: '#6666FF',
    5: '#3333FF',
    6: '#0000FF', // MAIN
    7: '#0000CC',
    8: '#000099',
    9: '#000066',
    10: '#000033',
  },
  secondaryColor: {
    1: '#E6E9F0',
    2: '#CCD3E1',
    3: '#99A6C3',
    4: '#677AA5',
    5: '#334D87',
    6: '#012169', // MAIN
    7: '#011A54',
    8: '#01143F',
    9: '#000D2A',
    10: '#000715',
  },
  tertiaryColor: {
    1: '#FEF6EF',
    2: '#FEF2E7',
    3: '#FEEDDF',
    4: '#FDE4D0',
    5: '#FDDBC0',
    6: '#FCD2B0', // MAIN
    7: '#CAA88D',
    8: '#977E6A',
    9: '#655446',
    10: '#322A23',
  },
  neutralColor: {
    0: '#FBFCFD',
    1: '#F4F8FC',
    2: '#EFF2F5', // BACKGROUND COLOR
    3: '#E0E5EA',
    4: '#D0D8E0',
    5: '#C0CBD6',
    6: '#A1B1C1', // MAIN
    7: '#8197AD',
    8: '#627D98',
    9: '#4E647A',
    10: '#3B4B5B',
    11: '#27323D',
    12: '#1D262E',
    13: '#14191E', // TITLE
  },
  validColor: {
    1: '#E9F9EF',
    2: '#D3F3DF',
    3: '#A7E8BF',
    4: '#7ADC9E',
    5: '#4ED17E',
    6: '#22C55E', // MAIN
    7: '#1B9E4B',
    8: '#147638',
    9: '#0E4F26',
    10: '#072713',
  },
  errorColor: {
    1: '#FFEAEB',
    2: '#FFD5D7',
    3: '#FFAAB0',
    4: '#FF8088',
    5: '#FF5561',
    6: '#FF2B39', // MAIN
    7: '#CC222E',
    8: '#991A22',
    9: '#661117',
    10: '#33090B',
  },
  warningColor: {
    1: '#FFF5E6',
    2: '#FFEBCC',
    3: '#FFD699',
    4: '#FFC266',
    5: '#FFAD33',
    6: '#FF9900', // MAIN
    7: '#CC7A00',
    8: '#995C00',
    9: '#663D00',
    10: '#331F00',
  },
  white: '#FFFFFF',
  black: '#000000',

  character: {
    title: '#14191E',
    primary: '#1D262E',
    primaryInverse: '#FFFFFF',
    secondary: '#4E647A',
    disabledPlaceholder: '#8197AD',
    mark: '#14191E',
    danger: '#FF2B39',
    warning: '#FF9900',
    valid: '#22C55E',
  },
})

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import {
  Button,
  Form,
  Spin,
} from 'antd'

import { MemberForm as FormMember } from '../../components'
import {
  fromMembers,
} from '../../store/selectors'
import { MODIFY_MEMBER, FETCH_FORCE_ONE_MEMBER } from '../../store/members/actions'

class MemberForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      done: false,
    }
  }

  componentDidMount = () => {
    const { token, fetchOneMember } = this.props
    fetchOneMember(token)
  }

  modifyMemberForm = (e) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
      },
      modifyMember,
      member,
    } = this.props

    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const newMember = {
          ...member,
          ...values,
        }
        await modifyMember(newMember)
        this.setState({ done: true })
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
      },
      member,
      loading,
    } = this.props
    const {
      shopTheme,
    } = member

    const {
      done,
    } = this.state
    if (loading) {
      return (
        <div>
          <Helmet>
            <title>
              <FormattedMessage id='loading' defaultMessage='Chargement' />
            </title>
            <link id='favicon' rel='icon' href='%PUBLIC_URL%/favicon_loader.ico' />
          </Helmet>
          <Spin size='large' />
        </div>
      )
    }
    if (done) {
      return (
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
        >
          <div
            style={{
              backgroundColor: shopTheme.pageBackgroundColor,
              color: shopTheme.mainColor,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              zIndex: 10,
              position: 'relative',
              borderRadius: 5,
              padding: 20,
              width: '50%',
            }}
          >
            <div style={{ width: '150px', height: 'auto' }}>
              <img
                style={{ width: '100%', height: 'auto' }}
                src={shopTheme.logo}
                alt='logo'
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ margin: '0 0 20px 0' }}>
                <FormattedMessage id='memberForm.saved' defaultMessage='Votre compte a bien été enregistré !' />
              </div>
              <FormattedMessage id='memberForm.access' defaultMessage='Vous pouvez dès maintenant accéder à la boutique' />
              {' '}
              <a href={`https://${shopTheme.url}`} target='_blank' rel='noopener noreferrer' style={{ fontWeight: 700 }}>{shopTheme.url}</a>
              {' '}
              <FormattedMessage id='memberForm.credits' defaultMessage='pour utiliser vos crédits !' />
            </div>
          </div>
          <div
            style={{
              backgroundColor: shopTheme.mainColor,
              opacity: 0.4,
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 0,
            }}
          />
        </div>
      )
    }
    return (
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <Helmet>
          <title>
            {`${shopTheme.name} - ${member.email}`}
          </title>
          <link id='favicon' rel='icon' href={shopTheme.favicon} />
        </Helmet>
        <div
          style={{
            backgroundColor: shopTheme.pageBackgroundColor,
            color: shopTheme.mainColor,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 10,
            position: 'relative',
            borderRadius: 5,
            padding: 20,
            width: '50%',
          }}
        >
          <div style={{ width: '150px', height: 'auto' }}>
            <img
              style={{ width: '100%', height: 'auto' }}
              src={shopTheme.logo}
              alt='logo'
            />
          </div>
          <Form onSubmit={this.modifyMemberForm} style={{ width: '100%' }}>
            <FormMember
              member={member}
              getFieldDecorator={getFieldDecorator}
            />
            <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  marginRight: 20,
                  backgroundColor: shopTheme.mainColor,
                  borderColor: shopTheme.mainColor,
                  color: shopTheme.pageBackgroundColor,
                }}
              >
                <FormattedMessage id='memberForm.addInfos' defaultMessage='Ajouter mes informations' />
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            backgroundColor: shopTheme.mainColor,
            opacity: 0.4,
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 0,
          }}
        />
      </div>
    )
  }
}

export default connect(
  (state) => ({
    loading: fromMembers.loading(state),
    member: fromMembers.getMember(state),
  }), (dispatch) => bindActionCreators({
    modifyMember: (member) => ({
      type: MODIFY_MEMBER,
      member,
    }),
    fetchOneMember: (token) => ({
      type: FETCH_FORCE_ONE_MEMBER,
      token,
    }),
  }, dispatch),
)(Form.create({ name: 'modifyMemberForm' })(MemberForm))

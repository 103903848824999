import React, { useEffect } from 'react'
import { Divider, Input } from 'antd'
import { FormattedMessage } from 'react-intl'
import { faEnvelope, faEnvelopeOpenText, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import {
  CampaignSelection,
  FadingTransition,
  FormattedButton,
  MagicLinksParamsDatePicker,
  MagicLinksInputTitle,
  MagicLinksSendingStepsButtons,
  ErrorMessage,
} from '../..'

import { character, neutralColor } from '../../../config/theme'
import { trimInput } from '../../../helpers/trim'
import {
  displayUnvalidFormat,
  hasValidEmailFormat,
  isSendDateValid,
  STEP_DRAWER_MAILS,
} from '../../../helpers/magicLinks'

const greyInputStyle = {
  background: `${neutralColor[2]}`,
  border: '0',
  color: character.primary,
  padding: '12px 16px',
  height: '46px',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: 400,
}

const MagicLinksSendingParameters = (props) => {
  try {
    const {
      campaigns,
      currentCampaignId,
      setCurrentCampaignId,
      campaignSelection,
      campaignParams,
      setCampaignParams,
      email,
      mailObject,
      sendTestEmail,
      nextDisabled,
      locale,
      onClose,
      setStep,
    } = props

    const {
      testMail,
      expirationDate,
      sendDate,
    } = campaignParams

    const wrongSendingDate = !isSendDateValid(sendDate, expirationDate)
    const activeCampaigns = campaigns.filter((campaign) => campaign.status === 'active')

    // On fait remonter la partie scrollable si la date d'envoi est modifiée pour afficher un éventuel message d'erreur
    useEffect(() => {
      if (wrongSendingDate) document.getElementById('magicLinksSendingScroll').scrollTo(0, 1000)
    }, [sendDate, expirationDate])

    return (
      <>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
        >
          {campaignSelection && (
            <CampaignSelection
              currentCampaignId={currentCampaignId}
              campaigns={activeCampaigns}
              onChange={(value) => setCurrentCampaignId(value)}
            />
          )}
          <FadingTransition initial={!currentCampaignId}>
            {currentCampaignId && (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                  <MagicLinksInputTitle
                    icon={faEnvelope}
                    title={(<FormattedMessage id='magicLinks.sending.adminMail' defaultMessage="Mail de l'expéditeur" />)}
                    style={{ color: character.secondary }}
                  >
                    <div style={greyInputStyle}>{email}</div>
                  </MagicLinksInputTitle>
                  <MagicLinksInputTitle
                    icon={faEnvelopeOpenText}
                    title={(<FormattedMessage id='magicLinks.sending.mailObject' defaultMessage='Objet du mail' />)}
                    style={{ color: character.secondary }}
                  >
                    <div style={greyInputStyle}>{mailObject}</div>
                  </MagicLinksInputTitle>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                    <MagicLinksInputTitle title={(<FormattedMessage id='magicLinks.sending.mailTest' defaultMessage='Mail test' />)}>
                      <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                        <Input
                          value={testMail}
                          onChange={(e) => setCampaignParams({ ...campaignParams, testMail: trimInput(e.target.value) })}
                        />
                        <ErrorMessage
                          isVisible={displayUnvalidFormat(testMail)}
                        >
                          <FormattedMessage
                            id='auth.checkEmail'
                            defaultMessage='Le format de cette adresse mail est incorrect.'
                          />
                        </ErrorMessage>
                      </div>
                    </MagicLinksInputTitle>
                    <FormattedButton
                      style={{ display: 'block', alignSelf: 'flex-end', padding: '5px 8px' }}
                      messageId='campaignEdit.button.sendTestEmail'
                      defaultMessage='Envoyer un mail test'
                      icon={faPaperPlane}
                      type='primary'
                      onClickFunction={() => sendTestEmail({ _id: currentCampaignId, email: testMail })}
                      disabled={!hasValidEmailFormat(testMail)}
                    />
                  </div>
                </div>
                <Divider style={{ margin: '32px 0' }} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                  <MagicLinksInputTitle
                    title={(<FormattedMessage id='magicLinks.sending.expirationDate' defaultMessage='Date d’expiration du lien' />)}
                  >
                    <MagicLinksParamsDatePicker
                      date={expirationDate}
                      onChange={(date) => setCampaignParams({ ...campaignParams, expirationDate: date })}
                      locale={locale}
                    />
                  </MagicLinksInputTitle>
                  <MagicLinksInputTitle title={(<FormattedMessage id='productCustomizationForm.sendDate' defaultMessage="Date d'envoi" />)}>
                    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                      <MagicLinksParamsDatePicker
                        date={sendDate}
                        onChange={(date) => setCampaignParams({ ...campaignParams, sendDate: date })}
                        locale={locale}
                        allowToday
                      />
                      <ErrorMessage
                        isVisible={wrongSendingDate}
                      >
                        <FormattedMessage
                          id='sending.dates.check'
                          defaultMessage="La date d'envoi ne peut pas chevaucher ou dépasser la date d'expiration"
                        />
                      </ErrorMessage>
                    </div>
                  </MagicLinksInputTitle>
                </div>
              </>
            )}
          </FadingTransition>
        </div>
        <MagicLinksSendingStepsButtons
          previousTitle={<FormattedMessage id='cancel' defaultMessage='Annuler' />}
          nextTitle={<FormattedMessage id='continue' defaultMessage='Continuer' />}
          handlePreviousStep={onClose}
          handleNextStep={() => setStep(STEP_DRAWER_MAILS)}
          nextDisabled={nextDisabled}
        />
      </>
    )
  } catch (e) {
    console.log('molecules/MagicLinksSendingParameters error:', e)
    return null
  }
}

export default MagicLinksSendingParameters

import React from 'react'
import {
  Button, Divider, Input, Icon,
} from 'antd'
import { FormattedMessage } from 'react-intl'

import { ApiScopes } from '../..'

const ApiKeyForm = (props) => {
  const {
    isEdition, apiKeyCreated, name, onChangeName, scopes, setScopes, onChangeCheckboxGroup, onClose, onSubmit,
  } = props

  if (apiKeyCreated) return null
  return (
    <>
      <h1 style={{ fontWeight: 'bold', margin: '20px 0' }}>
        {isEdition
          ? (
            <FormattedMessage
              id='developpers.drawer.modification.title'
              defaultMessage='Modifier une clé API'
            />
          ) : (
            <FormattedMessage
              id='developpers.drawer.creation.title'
              defaultMessage='Créer une nouvelle clé API'
            />
          )}

      </h1>
      <div>
        <h4 style={{ marginBottom: '5px' }}>
          <FormattedMessage
            id='developpers.drawer.creation.name'
            defaultMessage='Nom de la clé API'
          />
        </h4>
        <Input
          defaultValue={name}
          prefix={<Icon type='tag' style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder='Monitoring API Key Name'
          onChange={onChangeName}
        />
      </div>
      <Divider />
      <h3 style={{ marginBottom: '24px' }}>
        <FormattedMessage
          id='developpers.drawer.creation.scopes'
          defaultMessage='Sélectionner les droits'
        />
      </h3>
      <ApiScopes scopes={scopes} setScopes={setScopes} onChangeCheckboxGroup={onChangeCheckboxGroup} />
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        display: 'flex',
        width: '100%',
        padding: '15px 10px',
        justifyContent: 'space-between',
        gap: '10px',
      }}
      >
        <Button style={{ flex: 1 }} onClick={onClose}>
          <FormattedMessage
            id='cancel'
            defaultMessage='Annuler'
          />
        </Button>
        <Button style={{ flex: 1 }} type='primary' onClick={onSubmit} disabled={!(name && scopes.length)}>
          {isEdition
            ? (
              <FormattedMessage
                id='developpers.drawer.modification.cta'
                defaultMessage='Modifier la clé API'
              />
            ) : (
              <FormattedMessage
                id='developpers.drawer.creation.cta'
                defaultMessage='Créer la clé API'
              />
            )}
        </Button>
      </div>
    </>
  )
}

export default ApiKeyForm

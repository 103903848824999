import { faGear } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import {
  FormStepInput,
  CampaignDeliveryInput,
  CampaignDescriptionInput,
  CampaignNameInput,
  CampaignPublicInput,
  MovingStepButton,
  StepCardForm,
} from '../..'

const CampaignParametersCard = (props) => {
  const {
    shop, // Required
    campaign, // Required
    setCampaign, // Required
    setIsContinueDisabled,
    setNeedUpdateCampaign, // Required
    createdCampaignId, // Required
    moveNextStep,
    isContinueDisabled,
    isDisabled = false,
  } = props

  return (
    <StepCardForm
      avatarIcon={faGear}
      titleMessageId='campagn.create.step.parameters.header'
      titleDefaultMessage='Paramètres Généraux'
      descriptionMessageId='campagn.create.step.parameters.header.description'
      descriptionDefaultMessage='Configurez votre campagne et retrouvez la facilement'
      isDisabled={isDisabled}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <FormStepInput
          titleMessageId='campaigns.create.step.parameters.sender_email'
          titleDefaultMesage="Mail de l'expéditeur"
          inputValue={`${shop?.settings?.url}@panopli.co`}
          disabled
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '32px' }}>
        <CampaignNameInput
          campaign={campaign}
          setCampaign={setCampaign}
          setIsContinueDisabled={setIsContinueDisabled}
          setNeedUpdateCampaign={setNeedUpdateCampaign}
          createdCampaignId={createdCampaignId}
          isDisabled={isDisabled}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '32px' }}>
        <CampaignDescriptionInput
          campaign={campaign}
          setCampaign={setCampaign}
          setNeedUpdateCampaign={setNeedUpdateCampaign}
          createdCampaignId={createdCampaignId}
          isDisabled={isDisabled}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '26px',
          alignItems: 'center',
        }}
      >
        <CampaignPublicInput
          campaign={campaign}
          setCampaign={setCampaign}
          setNeedUpdateCampaign={setNeedUpdateCampaign}
          createdCampaignId={createdCampaignId}
          isDisabled={isDisabled}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '32px',
          alignItems: 'center',
        }}
      >
        <CampaignDeliveryInput
          campaign={campaign}
          setCampaign={setCampaign}
          setNeedUpdateCampaign={setNeedUpdateCampaign}
          createdCampaignId={createdCampaignId}
          isDisabled={isDisabled}
        />
      </div>
      {moveNextStep && isContinueDisabled !== null && (
        <MovingStepButton
          moveNextStep={moveNextStep}
          isContinueDisabled={isContinueDisabled}
        />
      )}
    </StepCardForm>
  )
}

export default CampaignParametersCard

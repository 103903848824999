import React from 'react'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import {
  FormattedSwitch,
  StepCardForm,
  FormStepInput,
  FormStepTextarea,
  MovingStepButton,
  PreviewContainer,
  MessagePreview,
} from '../..'

const CampaignCreationMessage = (props) => {
  const {
    setCampaign, // Required
    campaign, // Required
    moveNextStep,
    movePreviousStep,
    setNeedUpdateCampaign, // Required
    isDisabled = false,
  } = props

  const MESSAGE_LIMIT_LENGTH = 400

  const onChangeIsMessageEnabled = (checked) => {
    // eslint-disable-next-line no-unused-expressions
    checked
      ? setCampaign({
        ...campaign,
        message: {
          ...campaign?.message,
          isEnabled: checked,
        },
      })
      : setCampaign({
        ...campaign,
        message: {
          ...campaign?.message,
          content: '',
          title: '',
          isEnabled: checked,
        },
      })
    setNeedUpdateCampaign(true)
  }
  const onChangeMessage = (event) => {
    try {
      setCampaign({
        ...campaign,
        message: {
          ...campaign?.message,
          content: event.target.value,
        },
      })
      setNeedUpdateCampaign(true)
    } catch (e) {
      console.log(e)
    }
  }
  const onChangeMessageTitle = (e) => {
    setCampaign({
      ...campaign,
      message: {
        ...campaign?.message,
        title: e.target.value,
      },
    })
    setNeedUpdateCampaign(true)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
      <StepCardForm
        avatarIcon={faMessage}
        titleMessageId='campaigns.create.step.message.header'
        titleDefaultMessage='Message général pour vos destinataires'
        descriptionMessageId='campaigns.create.step.message.header.description'
        descriptionDefaultMessage='Adressez-vous à vos destinataires grâce à un message personnalisé'
        isDisabled={isDisabled}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', marginTop: '32px',
        }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FormattedSwitch
              checked={campaign?.message?.isEnabled}
              onChangeFunction={onChangeIsMessageEnabled}
              title={(
                <FormattedMessage
                  id='campaigns.create.step.message.is_message_enabled'
                  defaultMessage='Ajoutez un message personnalisé'
                />
              )}
              isOptional
              isDisabled={isDisabled}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '32px' }}>
            <FormStepInput
              titleMessageId='campaigns.create.step.message.title'
              titleDefaultMesage='Titre'
              placeholderMessageId='campaigns.create.step.message.title.placeholder'
              placeholderDefaultMessage='Exemple: Un cadeau pour vous remercier de votre confiance !'
              inputValue={campaign?.message?.title}
              onChangeFunction={onChangeMessageTitle}
              disabled={!campaign?.message?.isEnabled || isDisabled}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '32px' }}>
            <FormStepTextarea
              titleMessageId='campaigns.create.step.message.message'
              titleDefaultMesage='Message'
              rows={4}
              placeholderMessageId='campaigns.create.step.message.message.placeholder'
              placeholderDefaultMessage='Lorem ipsum dolor sit amet, consectetur adipiscing elit,
               sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
               quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
               Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
               Excepteur sint occaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              onChangeFunction={onChangeMessage}
              textAreaValue={campaign?.message?.content}
              currentDescriptionLength={campaign?.message?.content?.length}
              descriptionLimitLength={MESSAGE_LIMIT_LENGTH}
              disabled={!campaign?.message?.isEnabled || isDisabled}
            />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', marginTop: '128px', justifyContent: 'space-between',
          }}
          >
            {moveNextStep && movePreviousStep && (
              <MovingStepButton
                moveNextStep={moveNextStep}
                isContinueDisabled={
                  campaign?.message?.isEnabled
                  && (!campaign?.message?.title || !campaign?.message?.content)
                }
                movePreviousStep={movePreviousStep}
              />
            )}

          </div>
        </div>
      </StepCardForm>
      <PreviewContainer>
        <MessagePreview campaign={campaign} onChangeIsMessageEnabled={onChangeIsMessageEnabled} />
      </PreviewContainer>
    </div>
  )
}

export default CampaignCreationMessage

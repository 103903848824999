import React from 'react'
import { Modal } from 'antd'
import { FormattedMessage } from 'react-intl'

export default ({
  visible,
  url,
  onCancel,
}) => (
  <Modal
    visible={visible}
    title={(
      <FormattedMessage
        defaultMessage='Aperçu de la boutique'
        id='previewModal.title'
      />
    )}
    footer={false}
    width='70vw'
    onCancel={onCancel}
  >
    <div>
      <iframe
        src={url}
        title='shopPreview'
        style={{
          width: '100%',
          height: '70vh',
          border: '1px solid #ddd',
          borderRadius: 3,
        }}
      />
    </div>
  </Modal>
)

import React from 'react'
import { withRouter } from 'react-router'
import { Layout } from 'antd'
import { SignUpUser as SignUpUserContainer } from '../../containers'

const { Content } = Layout

const SignUpUser = (props) => {
  try {
    const {
      match: {
        params: {
          id = null,
        },
      },
      history,
    } = props
    return (
      <Layout className='layout' style={{ width: '100%' }}>
        <Content
          style={{
            padding: '0 20%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SignUpUserContainer
            history={history}
            userId={id}
          />
        </Content>
      </Layout>
    )
  } catch (e) {
    console.log(e, 'error pages/SignUpUser')
    return null
  }
}

export default withRouter(SignUpUser)

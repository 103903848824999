import React, { useState } from 'react'
import {
  Drawer,
  Button,
  message,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { SubscriptionWarning, UserEntityForm } from '../..'

const UserCreation = (props) => {
  const {
    sendMailCreationUser,
    entities,
    pressetEntityId,
    alreadySetupEntityId,
    setPressetEntityId,
    setVisibleDrawerCreationUser,
  } = props

  const [email, setEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState(false)
  const [language, setLanguage] = useState('FR')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [adminCollaborator, setAdminCollaborator] = useState(false)
  const [loading, setLoading] = useState(false)

  const getText = (error) => {
    switch (error.message) {
    case '409':
      return <FormattedMessage id='email.alreadyUsed' defaultMessage='Cette adresse email est déjà utilisée' />
    case '400':
      return <FormattedMessage id='email.notSent' defaultMessage='Mail non envoyé' />
    case '500':
      return 'Erreur'
    default:
      return ''
    }
  }

  return (
    <div>
      <SubscriptionWarning
        content={(
          <FormattedMessage
            id='userCreation.warning'
            defaultMessage="10€ / utilisateur par mois, ajoutés à la facture mensuelle de l'entité"
          />
        )}
      />
      <UserEntityForm
        entities={entities}
        email={email}
        setEmail={setEmail}
        errorEmail={errorEmail}
        setErrorEmail={setErrorEmail}
        setLanguage={setLanguage}
        name={name}
        setName={setName}
        lastName={lastName}
        setLastName={setLastName}
        setAdminCollaborator={setAdminCollaborator}
        setPressetEntityId={setPressetEntityId}
        pressetEntityId={pressetEntityId}
        alreadySetupEntityId={alreadySetupEntityId}
      />
      <Button
        type='primary'
        style={{ margin: '20px 0 0 0' }}
        disabled={!(email && language && name && lastName && pressetEntityId && !errorEmail)}
        loading={loading}
        onClick={() => {
          setLoading(true)
          sendMailCreationUser({
            email,
            language,
            name,
            lastName,
            entityId: pressetEntityId,
            adminCollaborator,
          }, () => {
            setLoading(false)
            setVisibleDrawerCreationUser(false)
            message.success(<FormattedMessage id='userCreation.success' defaultMessage="L'email a été envoyé avec succès !" />)
          }, (e) => {
            message.error(getText(e))
            setLoading(false)
          })
        }}
      >
        <FormattedMessage id='userCreation.invitationEmail' defaultMessage="Envoyer le mail d'invitation" />
      </Button>
    </div>
  )
}

const DrawerUserCreation = (props) => {
  const {
    visibleDrawerCreationUser,
    setVisibleDrawerCreationUser,
  } = props
  return (
    <Drawer
      title={<FormattedMessage id='userCreation.newUser' defaultMessage='Créer un nouvel utilisateur' />}
      placement='right'
      width='35%'
      onClose={() => setVisibleDrawerCreationUser(false)}
      visible={visibleDrawerCreationUser}
      destroyOnClose
    >
      <UserCreation {...props} />
    </Drawer>
  )
}
export default DrawerUserCreation

import React from 'react'
import { FormattedMessage } from 'react-intl'
import MagicLinkSelectFilter from '../MagicLinkSelectFilter'

const MagicLinkStatusFilter = (props) => {
  const {
    setFilters, // Required
    filters, // Required
  } = props

  const options = [
    {
      value: 'committed',
      label: <FormattedMessage id='campaignTracking.status.committed' defaultMessage='Engagé' />,
    },
    {
      value: 'spent',
      label: <FormattedMessage id='campaignTracking.status.spent' defaultMessage='Dépensé' />,
    },
    {
      value: 'expired',
      label: <FormattedMessage id='campaignTracking.status.expired' defaultMessage='Lien expiré' />,
    },
  ]

  return (
    <MagicLinkSelectFilter
      options={options}
      title={<FormattedMessage id='status' defaultMessage='Statut' />}
      onChange={(value) => setFilters({ ...filters, statuses: value })}
      placeholder={<FormattedMessage id='campaignTracking.placeholders.status' defaultMessage='Tous les statuts' />}
      onResetFilter={() => setFilters({ ...filters, statuses: [] })}
      selectedValues={filters?.statuses}
    />
  )
}

export default MagicLinkStatusFilter

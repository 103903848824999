export const initialState = {
  loading: true,
  error: false,
  error_details: '',
  invoices: [],
  upcoming: {},
  upcoming_items: [],
}

export const getInvoices = (state = initialState) => state.invoices

export const getUpComingInvoice = (state = initialState) => state.upcoming

export const getInvoiceItems = (state = initialState) => state.upcoming_items

export const loading = (state = initialState) => state.loading

export const error = (state = initialState) => (state.error ? state.error_details : false)

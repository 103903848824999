/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
// Add sizes to whitelist and register them
const Size = Quill.import('formats/size')
Size.whitelist = ['extra-small', 'small', 'medium', 'large']
Quill.register(Size, true)
// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
}

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
]

// Quill Toolbar component
export const QuillToolbar = () => (
  <div id='toolbar' style={{ backgroundColor: 'white' }}>
    <span className='ql-formats'>
      <select className='ql-header' defaultValue='3'>
        <option value='1'>Heading</option>
        <option value='2'>Subheading</option>
        <option value='3'>Normal</option>
      </select>
    </span>
    <span className='ql-formats'>
      <button type='button' className='ql-bold' />
      <button type='button' className='ql-italic' />
      <button type='button' className='ql-underline' />
      <button type='button' className='ql-strike' />
    </span>
    <span className='ql-formats'>
      <button type='button' className='ql-list' value='ordered' />
      <button type='button' className='ql-list' value='bullet' />
      <button type='button' className='ql-indent' value='-1' />
      <button type='button' className='ql-indent' value='+1' />
    </span>
    <span className='ql-formats'>
      <button type='button' className='ql-blockquote' />
      <button type='button' className='ql-direction' />
      <button type='button' className='ql-clean' />
    </span>
    <span className='ql-formats'>
      <select type='button' className='ql-align' />
      <select type='button' className='ql-color' />
      <select type='button' className='ql-background' />
    </span>
  </div>
)

/**
 * Composant qui permet d'afficher l'editeur de Markdown Quill
 *
 * @param {string} placeholder
 * @param {string} value
 * @param {function} onChange
 * @param {object} style
 * @param {boolean} readOnly
 * @returns
 */

const QuillEditor = (props) => {
  try {
    const {
      placeholder, value, onChange, style, readOnly,
    } = props

    return (
      <>
        <QuillToolbar />
        <ReactQuill
          readOnly={readOnly}
          theme='snow'
          value={value}
          style={style}
          placeholder={placeholder}
          onChange={onChange}
          modules={modules}
          formats={formats}
          preserveWhitespace
        />
      </>
    )
  } catch (e) {
    console.log('error atom/QuillEditor', e)
    return null
  }
}

export default QuillEditor

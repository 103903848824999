import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faMessage, faXmark } from '@fortawesome/free-solid-svg-icons'

import { character } from '../../../config/theme'
import { STEP_DRAWER_MESSAGE } from '../../../helpers/magicLinks'

const buttonStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
  fontWeight: 500,
  fontSize: '14px',
}

const MagicLinksSendingSelectionActions = (props) => {
  try {
    const {
      selection,
      setSelection,
      handleSelectionDelete,
      customMessageAllowed,
      setStep,
    } = props

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '24px',
      }}
      >
        <span style={{ color: character.secondary, fontWeight: 500, fontSize: '14px' }}>
          <FormattedMessage
            id='number.selected'
            defaultMessage='{items, plural, =0 {# sélectionné} one {# sélectionné} other {# sélectionnés}}'
            values={{
              items: selection.length,
            }}
          />
        </span>
        <Button
          type='link'
          style={buttonStyle}
          onClick={handleSelectionDelete} // Suppression des adresses sélectionnées
        >
          <FontAwesomeIcon icon={faTrashCan} />
          <FormattedMessage id='list.remove' defaultMessage='Retirer de la liste' />
        </Button>
        {customMessageAllowed && (
          <Button
            type='primary'
            style={buttonStyle}
            onClick={() => setStep(STEP_DRAWER_MESSAGE)}
          >
            <FontAwesomeIcon icon={faMessage} />
            <FormattedMessage id='customizeMessage' defaultMessage='Personnaliser le message' />
          </Button>
        )}
        <FontAwesomeIcon
          icon={faXmark}
          color={character.secondary}
          style={{ fontSize: '16px', cursor: 'pointer' }}
          onClick={() => setSelection([])} // Déselection complète et fermeture du panneau d'actions
        />
      </div>
    )
  } catch (e) {
    console.log('molecules/MagicLinksSendingSelectionActions error:', e)
    return null
  }
}

export default MagicLinksSendingSelectionActions

import React from 'react'
import {
  message,
} from 'antd'
import { FormattedMessage } from 'react-intl'

/**
 * Copie la string dans le clipboard
 * -> Sujet et body
 *
 * @param {*} e - l'event du onClick
 * @param {string} variable - ce qu'on veut copier
 * @returns rien
 */
const copyToClipboard = (e, variable) => {
  e.stopPropagation()
  const toCopy = document.createElement('input')
  document.body.appendChild(toCopy)
  toCopy.setAttribute('value', variable)
  toCopy.select()
  document.execCommand('copy')
  message.info(<FormattedMessage id='copy' defaultMessage='Copié' />)
  document.body.removeChild(toCopy)
}

export default copyToClipboard

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Form,
  Input,
  Button,
  Drawer,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import {
  CardElement,
  injectStripe,
} from 'react-stripe-elements'

import {
  CREATE_PAYMENT_METHOD,
} from '../../store/payments/actions'

const FormItem = Form.Item

// check si c'est un iban valable
// eslint-disable-next-line
const REGEX_IBAN = '^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$'

class AddPayment extends React.Component {
  constructor() {
    super()
    this.state = {
      type: '',
      visibleDrawer: false,
    }
  }

  componentDidMount() {
    const { form } = this.props
    // To disabled submit button at the beginning.
    form.validateFields()
  }

  resetType = () => {
    this.setState({ type: '', visibleDrawer: false })
  }

  hasErrors = (fieldsError) => Object.keys(fieldsError).some((field) => fieldsError[field])

  handleSubmit = (e) => {
    const {
      form,
      createPaymentMethod,
      stripe,
    } = this.props
    const {
      type,
    } = this.state
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        if (type === 'sepa_debit') {
          createPaymentMethod({ type, stripe, iban: values.iban })
          this.resetType()
        }
        if (type === 'card') {
          createPaymentMethod({ type, stripe })
          this.resetType()
        }
      }
      return false
    })
  }

  render() {
    const {
      form,
    } = this.props

    const {
      type,
      visibleDrawer,
    } = this.state

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = form

    const ibanError = isFieldTouched('iban') && getFieldError('iban')

    try {
      return (
        <div style={{ marginBottom: 10 }}>
          <Button
            type='primary'
            onClick={() => this.setState({ type: 'card', visibleDrawer: true })}
            style={{ marginRight: '10px' }}
          >
            <FormattedMessage
              id='AccountPaymentMethod.addCard'
              defaultMessage='Ajouter une carte bleue'
            />
          </Button>
          <Button onClick={() => this.setState({ type: 'sepa_debit', visibleDrawer: true })}>
            <FormattedMessage
              id='AccountPaymentMethod.addSEPA'
              defaultMessage='Ajouter un SEPA'
            />
          </Button>
          <Drawer
            title={<FormattedMessage id='AccountPaymentMethod.newPayment' defaultMessage="Création d'un nouveau paiement" />}
            width={640}
            closable={false}
            visible={visibleDrawer}
            onClose={() => this.setState({ type: '', visibleDrawer: false })}
            className='DeliveryView'
          >
            <div>
              <Form
                onSubmit={(e) => this.handleSubmit(e)}
                className='form'
              >
                {
                  type === 'sepa_debit' ? (
                    <div className='wrapper'>
                      <FormItem
                        validateStatus={ibanError ? 'error' : ''}
                        className='input-iban'
                      >
                        {getFieldDecorator('iban', {
                          rules: [{
                            required: true,
                            message: ' ',
                            pattern: REGEX_IBAN,
                          }],
                        })(<Input
                          placeholder='ex.: FRXX XXXX XXXX XXXX XXXX XXXX XXX'
                          name='iban'
                          className='input-iban'
                        />)}
                      </FormItem>
                      <FormItem>
                        <Button htmlType='submit' disabled={this.hasErrors(getFieldsError())}>
                          <FormattedMessage
                            id='AccountPaymentMethod.SEPA'
                            defaultMessage='Ajouter mon SEPA'
                          />
                        </Button>
                      </FormItem>
                    </div>
                  ) : (
                    <div className='wrapper'>
                      <FormItem
                        className='input-card'
                        name='card'
                      >
                        <CardElement
                          className='stripeCardElement'
                        />
                      </FormItem>
                      <FormItem>
                        <Button htmlType='submit'>
                          <FormattedMessage
                            id='AccountPaymentMethod.Card'
                            defaultMessage='Ajouter ma carte'
                          />
                        </Button>
                      </FormItem>
                    </div>
                  )
                }
              </Form>
            </div>
          </Drawer>
        </div>
      )
    } catch (e) {
      console.log('Account - AddPayment', e)
      return null
    }
  }
}

export default connect(null,
  (dispatch) => bindActionCreators({
    createPaymentMethod: (payload) => ({
      type: CREATE_PAYMENT_METHOD,
      payload,
    }),
  }, dispatch))(injectStripe(Form.create()(AddPayment)))

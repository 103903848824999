import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Drawer, Button, Radio, Icon, message,
} from 'antd'
import { CSVLink } from 'react-csv'
import { CSVReader } from 'react-papaparse'
import { flatten } from 'lodash'
import { DisplayPDFViewer } from '..'

const MockupToDisplay = (props) => {
  const { keyMockup, csvInvoices } = props
  if (keyMockup === 1) {
    return <DisplayPDFViewer csvInvoices={csvInvoices} />
  } return null
}
const PDFCreatorInvoice = (props) => {
  try {
    const {
      visible, setVisible, extra, setExtra, isAdmin, locale,
    } = props
    const [csvInvoices, setCSVInvoices] = useState([])
    const [keyMockup, setKeyMockup] = useState(null)
    const [nextStep, setNextStep] = useState(false)
    if (extra && !extra.find((ex) => ex && ex.key === 'generateInvoice') && isAdmin) {
      const newExtra = extra
      newExtra.push(
        {
          content: (
            <Button onClick={() => setVisible(true)}>
              Générer une facture
            </Button>
          ),
          key: 'generateInvoice',
        },
      )
      setExtra(newExtra)
    }
    const headersCSV = [
      {
        label: locale === 'fr' ? 'Date de facturation' : 'Billing date',
        key: 'Date de facturation',
      },
      {
        label: locale === 'fr' ? 'Numéro de facture' : 'Bill number',
        key: 'Numéro de facture',
      },
      {
        label: locale === 'fr' ? 'Commande' : 'Order',
        key: 'Commande',
      },
      {
        label: locale === 'fr' ? 'Prénom' : 'First name',
        key: 'Prénom',
      },
      {
        label: locale === 'fr' ? 'Nom' : 'Last name',
        key: 'Nom',
      },
      {
        label: locale === 'fr' ? 'Adresse' : 'Street address',
        key: 'Adresse',
      },
      {
        label: locale === 'fr' ? 'Ville' : 'City',
        key: 'Ville',
      },
      {
        label: locale === 'fr' ? 'Code Postal' : 'Postal code',
        key: 'Code Postale',
      },
      {
        label: locale === 'fr' ? 'Pays' : 'Country',
        key: 'Pays',
      },
      {
        label: 'Tel',
        key: 'Tel',
      },
      {
        label: 'Mail',
        key: 'Mail',
      },
      {
        label: locale === 'fr' ? 'Produit' : 'Product',
        key: 'Produit',
      },
      {
        label: locale === 'fr' ? 'Origine' : 'Origin',
        key: 'Origine',
      },
      {
        label: locale === 'fr' ? 'Détail produit' : 'Product detail',
        key: 'Détail produit',
      },
      {
        label: locale === 'fr' ? 'Conditions de livraison' : 'Terms of delivery',
        key: 'Conditions de livraison',
      },
      {
        label: locale === 'fr' ? 'Code HS' : 'HS Code',
        key: 'HS Code',
      },
      {
        label: locale === 'fr' ? 'Poids' : 'Weight',
        key: 'Poids',
      },
      {
        label: locale === 'fr' ? 'Quantité' : 'Quantity',
        key: 'Quantité',
      },
      {
        label: locale === 'fr' ? 'Prix unique sans taxes' : 'Single Price Without Taxes',
        key: 'Single Price Without Taxes',
      },
      {
        label: locale === 'fr' ? 'Montant TVA' : 'TVA Amount',
        key: 'TVA Amount',
      },
      {
        label: locale === 'fr' ? 'Total (sans expe)' : 'Total (without expe)',
        key: 'Total (without expe)',
      },
      {
        label: locale === 'fr' ? 'Frais d\'expédition' : 'Expedition fee',
        key: 'Expedition fee',
      },
      {
        label: locale === 'fr' ? 'Total HT (y compris expe)' : 'Total HT (including expe)',
        key: 'Total HT (including expe)',
      },
      {
        label: locale === 'fr' ? 'Total (y compris vat)' : 'Total (vat included)',
        key: 'Total (vat included)',
      },
    ]

    // essayer de display seulement la première page
    return (
      <Drawer
        title={<FormattedMessage id='generateBill' defaultMessage='Générer une facture' />}
        placement='right'
        width={keyMockup && nextStep ? '60%' : '40%'}
        onClose={() => {
          setVisible(false)
          setNextStep(false)
        }}
        visible={visible}
        height='100%'
      >
        {keyMockup && nextStep ? (
          <MockupToDisplay csvInvoices={csvInvoices} keyMockup={keyMockup} />
        ) : (
          <>
            <div style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              marginTop: 15,
            }}
            >
              <CSVLink
                data={[{
                  'Date de facturation': '16/06/2021',
                  'Numéro de facture': 'FA200618',
                  Commande: 'Tik-euro-1082',
                  Prénom: 'noa',
                  Nom: 'aaronson',
                  Adresse: '3 Hahatzav',
                  Ville: 'Moshav Udim',
                  'Code Postale': '4290500',
                  Pays: 'israel',
                  Tel: '542447826',
                  Mail: 'noa.aaronson@bytedance.com',
                  Produit: 'Tee-shirt',
                  Origine: 'France',
                  'Détail produit': 'Coton printed tee-shirt',
                  'HS Code': '6109100010',
                  Poids: '200g',
                  'Single Price Without Taxes': '7,50 €',
                  'TVA Amount': '0,90 €',
                  'Total (without expe)': '8,40 €',
                  'Expedition fee': '9,90 €',
                  'Total HT (including expe)': '17,40 €',
                  'Total (vat included)': '19,49 €',
                }]}
                headers={headersCSV}
                filename='newInvoice.csv'
                target='_blank'
                onClick={() => message.success(
                  <FormattedMessage id='CSVDownloaded' defaultMessage='CSV téléchargé !' />,
                )}
              >
                <Button type='primary'>
                  <Icon type='download' />
                  <FormattedMessage id='preFilledCSV' defaultMessage='CSV pré-rempli' />
                </Button>
              </CSVLink>
              <Icon type='arrow-right' />
              <CSVReader
                config={{ header: true }}
                addRemoveButton
                onRemoveFile={() => setCSVInvoices([])}
                onDrop={(rows) => {
                  const arrayRows = []
                  rows.map((row) => {
                    const { data, errors } = row
                    if (errors.length) return null
                    if (arrayRows.find((r) => r['Numéro de facture'] === data['Numéro de facture'])) {
                      arrayRows.find((r) => r['Numéro de facture'] === data['Numéro de facture']).products.push({
                        Produit: data.Produit,
                        'Détail produit': data['Détail produit'],
                        'HS Code': data['HS Code'],
                        'Single Price Without Taxes': data['Single Price Without Taxes'],
                        'TVA Amount': data['TVA Amount'],
                        'Total (without expe)': data['Total (without expe)'],
                        Quantité: data['Quantité'],
                        Origine: data.Origine,
                      })
                    } else {
                      arrayRows.push({
                        ...data,
                        products: [{
                          Produit: data.Produit,
                          'Détail produit': data['Détail produit'],
                          'HS Code': data['HS Code'],
                          'Single Price Without Taxes': data['Single Price Without Taxes'],
                          'TVA Amount': data['TVA Amount'],
                          'Total (without expe)': data['Total (without expe)'],
                          Quantité: data['Quantité'],
                          Origine: data.Origine,
                        }],
                      })
                    }
                    return true
                  })
                  setCSVInvoices(flatten(arrayRows.map((row) => row)))
                }}
                style={{
                  dropFile: {
                    height: 50,
                    borderRadius: 3,
                    width: 150,
                    padding: 0,
                  },
                  fileSizeInfo: {
                    display: 'none',
                  },
                  fileNameInfo: {
                    padding: 5,
                    lineHeight: 'inherit',
                  },
                  progressBar: {
                    backgroundColor: '#0000FF',
                  },
                }}
              >
                <FormattedMessage id='ImportCSV' defaultMessage='Importer un CSV' />
              </CSVReader>
            </div>
            <div style={{ margin: '20px 0px' }}>
              <div style={{
                  fontWeight: 'bold', width: '100%',
              }}
              >
                <FormattedMessage id='pdfCreatorInvoice.selectInvoiceType' defaultMessage='Veuillez sélectionner le type de facture souhaité' />
              </div>
              <Radio.Group
                style={{ marginTop: '10px' }}
                onChange={(e) => {
                  setKeyMockup(e.target.value)
                }}
                value={keyMockup}
              >
                <Radio value={1}>
                  <FormattedMessage id='pdfCreatorInvoice.gbBill' defaultMessage='Facture commerciale GB' />
                </Radio>
              </Radio.Group>
            </div>
            <Button
              type='primary'
              disabled={!csvInvoices.length || !keyMockup}
              onClick={() => {
                setNextStep(true)
              }}
            >
              <FormattedMessage id='validate' defaultMessage='Valider' />
            </Button>
          </>
        )}
      </Drawer>
    )
  } catch (e) {
    console.log(e, 'containers/PDFCreatorInvoice error')
    return null
  }
}

export default PDFCreatorInvoice

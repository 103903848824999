// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
import { initialState } from './selectors'
import {
  RECEIVE_PAYMENT_METHODS,
  FORCE_FETCH,
  LOADING,
  ERROR,
} from './actions'


export default (state = initialState, {
  type,
  payload = {},
}) => {
  const {
    data,
  } = payload
  switch (type) {
  case RECEIVE_PAYMENT_METHODS:
    return {
      ...state,
      all_paymentMethods: data,
      loading: false,
    }
  case FORCE_FETCH:
    return { ...state, loading: !state.loading }
  case LOADING:
    return { ...state, loading: true }
  case ERROR:
    return { ...state, loading: false, error: true }
  default:
    return state
  }
}

import React from 'react'
import {
  Icon, Button, List,
} from 'antd'
import ProductImage from '@balibart/product-image'
import { getProductPrice } from '@balibart/pricing'
import { ColorSpan } from '../..'
import config from '../../../config'

const { image_base_url: imageBaseUrl } = config

export default class RecapItem extends React.Component {
  setValue = (input) => {
    this.setState((state) => {
      const { customizations = [] } = state
      const relevantCusto = customizations.find((c) => c.fieldId === input.fieldId)
      if (relevantCusto) {
        const relevantValue = relevantCusto.values.find((v) => v.groupId === input.groupId
            && input.inputId === v.inputId)
        if (relevantValue) {
          const newValues = relevantCusto.values.map((v) => {
            if (v.groupId === relevantValue.groupId && relevantValue.inputId === v.inputId) {
              return {
                ...v,
                value: input.value,
              }
            }
            return v
          })
          relevantCusto.values = newValues
        } else {
          relevantCusto.values.push(input)
        }
        const filteredCustos = relevantCusto
          ? customizations.filter((c) => c.fieldId
              !== relevantCusto.fieldId).concat([relevantCusto])
          : customizations
        return {
          customizations: filteredCustos,
        }
      }
      customizations.push({ fieldId: input.fieldId, groupId: input.groupId, values: [input] })
      return { customizations }
    })
  }

  // NOTE there is a missign custo if we can find one missing data accross all custos
  // we duplicate the clientCustomization Object per cart.product.quantity
  checkCustomizations = () => {
    const { product } = this.props
    const { clientCustomization } = product
    return !clientCustomization.find((c) => c.find((C) => !C.value))
  }

  renderCollapseButton = () => {
    try {
      const {
        canonicalProduct = {},
        manageCustomizations,
        product,
        location,
      } = this.props
      const hasCusto = canonicalProduct.clientCustomization && canonicalProduct.clientCustomization.length
      if (hasCusto && location !== 'checkout') {
        const isCompleted = this.checkCustomizations()
        return (
          <Button
            onClick={() => manageCustomizations(product.cart_id)}
            style={{ fontSize: '9px' }}
            type={isCompleted ? 'ghost' : 'primary'}
          >
            <Icon type={isCompleted ? 'check' : 'edit'} />
          </Button>
        )
      }
      return null
    } catch (e) {
      console.log('renderCollapseButton', e)
      return null
    }
  }

  displayButtonDelete = () => {
    const { showButton, remove, product } = this.props
    if (showButton) {
      return (
        <Button
          onClick={() => remove(product.cart_id)}
          type='danger'
        >
          <Icon type='delete' />
        </Button>
      )
    } return null
  }

  render() {
    const {
      product,
      canonicalProduct = {},
    } = this.props

    return (
      <List.Item
        actions={[
          this.renderCollapseButton(),
          this.displayButtonDelete(),
        ]}
      >
        <List.Item.Meta
          avatar={(
            <div style={{ width: '100px' }}>
              <ProductImage
              // TODO from config
                base_url={imageBaseUrl}
                product={canonicalProduct}
                variation={product.variations.color}
                currentPrintingZone={canonicalProduct.favPZ || 'front'}
              />
            </div>
          )}
          title={canonicalProduct.name}
          description={(
            <div style={{
              display: 'flex',
              flexFlow: 'column wrap',
            }}
            >
              <ColorSpan color={product.variations.color} />
              <div>
                {`${product.variations.size.name} x ${product.quantity}`}
              </div>
              <div>
                {`${(getProductPrice(
                  canonicalProduct,
                  canonicalProduct.baseProduct,
                  product.variations,
                  true,
                ) * product.quantity).toFixed(2)} €`}
              </div>
            </div>
          )}
        />
      </List.Item>
    )
  }
}

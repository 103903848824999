/* eslint-disable react/jsx-props-no-spreading */
import { Button, ConfigProvider, Modal } from 'antd'
import React from 'react'
import { hot } from 'react-hot-loader/root'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { Route as OriginalRoute } from 'react-router'
import {
  Redirect,
  Switch,
} from 'react-router-dom'
import { bindActionCreators } from 'redux'
// import actions from './store/actions'
import enUS from 'antd/es/locale/en_US'
import frFR from 'antd/es/locale/fr_FR'
import Layout from './layouts'
import {
  Account,
  Auth,
  CampaignCreatePage,
  CampaignEditPage,
  Catalog,
  Checkout,
  Customization,
  Dashboard,
  Developers,
  Discounts,
  EntitiesPage,
  Invoices,
  LegalNotice,
  MagicLinks,
  Member,
  Members,
  MultipleOrders,
  Onboarding,
  Orders,
  PrivacyPolicy,
  Product,
  Products,
  SignUpUser,
  Success,
  Terms,
  Theme,
  Workshop,
} from './pages'
import {
  fromBudgets,
  fromCart, fromEntities,
  fromLocale,
  fromShops,
  fromUsers,
} from './store/selectors'
import {
  shopSwitch as shopSwitchAction,
} from './store/shops/actions'
import {
  login as loginAction,
  logout as logoutAction,
} from './store/users/actions'
import EN from './translations/compiled-lang/en.json'
import FR from './translations/compiled-lang/fr.json'

import './App.css'
import BaseProductsCart from './containers/BaseProductsCart'

// const {
//   shops: {
//     shopSwitch: shopSwitchAction,
//   },
//   users: {
//     login: loginAction,
//     logout: logoutAction,
//   },
// } = actions

const language = navigator.language.split(/[-_]/)[0]

const loadLocaleData = (locale) => {
  switch (locale) {
  case 'en':
    return EN
  default:
    return FR
  }
}

const getAntDLocale = (locale) => {
  switch (locale) {
  case 'en':
    return enUS
  default:
    return frFR
  }
}

const UpdateModale = (props) => {
  const { shouldRefetchShop, fetchShop, close } = props
  return (
    <Modal footer={null} visible={shouldRefetchShop} closable={false}>
      <div>
        <p>
          <FormattedMessage
            id='app.modalMessage'
            defaultMessage="Votre Boutique n'est pas à jour, cliquez sur 'Actualiser' pour synchroniser vos données."
          />
        </p>
        <Button onClick={() => {
          fetchShop()
          close()
        }}
        >
          {' '}
          <FormattedMessage
            id='app.modalButton'
            defaultMessage='Actualiser mon shop'
          />
        </Button>
      </div>
    </Modal>
  )
}

const Route = ({
  component,
  logout,
  availableShops,
  shopSwitch,
  shopId,
  shopName,
  user,
  shop,
  shopUrl,
  shopOwner,
  isAdmin,
  budget,
  ...rest
}) => (
  <OriginalRoute
    {...rest}
    render={
      () => (
        <Layout
          logout={logout}
          component={component}
          shopSwitch={shopSwitch}
          availableShops={availableShops}
          shopId={shopId}
          shopName={shopName}
          user={user}
          shop={shop}
          shopUrl={shopUrl}
          shopOwner={shopOwner}
          isAdmin={isAdmin}
          budget={budget}
        />
      )
    }
  />
)

const Router = ({
  loggedIn,
  login,
  creation,
  hasContent,
  entities,
  shop,
  locale,
  ...rest
}) => {
  const {
    shopId, isAdmin, user, shopOwner,
  } = rest
  if (loggedIn) {
    return (
      <Switch>
        <OriginalRoute
          path='/onboarding'
        >
          <Onboarding {...rest} />
        </OriginalRoute>
        <Route
          path='/orders'
          {...rest}
          component={<Orders shopId={shopId} isAdmin={isAdmin} />}
        />
        <Route
          path='/success'
          {...rest}
          component={<Success />}
        />
        {user.collaborator && !user.adminCollaborator ? null : (
          <Route
            path='/entities'
            {...rest}
            component={<EntitiesPage entities={entities} shopId={shopId} />}
          />
        )}
        {isAdmin ? (
          <Route
            path='/catalog'
            {...rest}
            component={<Catalog />}
          />
        ) : null}
        <Route
          path='/products'
          {...rest}
          component={(
            <Products
              creationAllowed={creation}
              hasContent={hasContent}
              isAdmin={isAdmin}
              shopId={shopId}
            />
          )}
        />
        <Route
          path='/product'
          {...rest}
          component={<Product shopId={shopId} />}
        />

        {user.admin || user._id === shopOwner ? (
          <Route
            path='/shop/theme'
            exact
            {...rest}
            component={<Theme shopId={shopId} />}
          />
        ) : null}
        {user.collaborator && !user.adminCollaborator ? null : (
          <Route
            path='/shop'
            {...rest}
            component={<Customization shop={shop} entities={entities} shopId={shopId} user={user} />}
          />
        )}
        <Route
          path='/magicLinks'
          {...rest}
          component={<MagicLinks shopId={shopId} />}
        />
        <Route
          path='/campaign/create'
          {...rest}
          component={<CampaignCreatePage shop={shop} user={user} />}
        />
        <Route
          path='/campaign/edit'
          {...rest}
          component={<CampaignEditPage shopId={shopId} user={user} />}
        />
        <Route
          exact
          {...rest}
          path='/checkout'
          component={<Checkout />}
        />
        <Route
          path='/discounts'
          {...rest}
          component={<Discounts shopId={shopId} />}
        />
        <Route
          path='/invoices'
          {...rest}
          component={<Invoices shopId={shopId} />}
        />
        <Route
          path='/developers'
          {...rest}
          component={<Developers />}
        />
        <Route
          path='/members'
          {...rest}
          component={(
            <Members
              shopId={shopId}
            />
          )}
        />
        <Route
          path='/account'
          {...rest}
          component={<Account />}
        />
        <Route
          exact
          {...rest}
          path='/multiple-orders'
          component={<MultipleOrders isAdmin={isAdmin} shopId={shopId} />}
        />
        <Route
          exact
          {...rest}
          path='/tos'
          component={<Terms locale={locale} />}
        />
        <Route
          exact
          {...rest}
          path='/legal-notice'
          component={<LegalNotice locale={locale} />}
        />
        <Route
          exact
          {...rest}
          path='/privacy-policy'
          component={<PrivacyPolicy locale={locale} />}
        />
        {isAdmin ? (
          <Route
            path='/workshop'
            {...rest}
            component={<Workshop />}
          />
        ) : null}
        <Route
          path='*'
          {...rest}
          component={<Dashboard user={user} shopId={shopId} />}
        />
      </Switch>
    )
  }
  return (
    <Switch>
      <OriginalRoute
        path='/reset/:token'
      >
        <Auth login={login} />
      </OriginalRoute>
      <OriginalRoute
        path='/member/:token'
      >
        <Member />
      </OriginalRoute>
      <OriginalRoute
        path='/signup/:id'
      >
        <SignUpUser />
      </OriginalRoute>
      <OriginalRoute
        path='/resetPassword'
      >
        <Auth type='PASSWORD_REINIT' user={user} />
      </OriginalRoute>

      <OriginalRoute
        path='*'
      >
        <Auth login={login} user={user} />
      </OriginalRoute>
    </Switch>
  )
}

class App extends React.Component {
  formatUser() {
    try {
      const { user } = this.props
      return {
        user_id: user._id,
        email: user.email,
        name: user.additionnalInfos ? user.additionnalInfos.firstName : '',
      }
    } catch (error) {
      return {}
    }
  }

  render() {
    // NOTE redirect to https
    if (
      window.location.protocol !== 'https:'
      && !window.location.hostname.includes('localhost')
    ) {
      return (<Redirect to={`https://${window.location.hostname}${window.location.pathname}`} />)
    }
    const {
      isLoggedIn,
      login,
      logout,
      availableShops,
      shopSwitch,
      shopId,
      creation,
      hasContent,
      isAdmin,
      user,
      shopName,
      shopUrl,
      shopOwner,
      shouldRefetchShop,
      fetchShop,
      closeModal,
      allMembers,
      entities,
      shop,
      locale,
      budget,
    } = this.props

    const smartLocale = locale || user.language || language

    return (
      <IntlProvider
        locale={smartLocale}
        defaultLocale='fr'
        messages={loadLocaleData(locale)}
      >
        <ConfigProvider locale={getAntDLocale(locale || 'fr')}>
          <div id='App' className='Panopli' data-testid='root-element'>
            <BaseProductsCart />
            <UpdateModale shouldRefetchShop={shouldRefetchShop} fetchShop={fetchShop} close={closeModal} />
            <div className='content'>
              <Router
                loggedIn={isLoggedIn}
                login={login}
                logout={logout}
                isAdmin={isAdmin}
                availableShops={availableShops}
                shopSwitch={shopSwitch}
                shopId={shopId}
                creation={creation}
                hasContent={hasContent}
                shopName={shopName}
                user={user}
                shopUrl={shopUrl}
                allMembers={allMembers}
                entities={entities}
                shop={shop}
                shopOwner={shopOwner}
                locale={locale}
                budget={budget}
              />
            </div>
          </div>
        </ConfigProvider>
      </IntlProvider>
    )
  }
}

const ExportedApp = connect(
  (state) => ({
    isLoggedIn: fromUsers.isLoggedIn(state),
    user: fromUsers.getUser(state),
    availableShops: fromShops.allShops(state),
    shopId: fromShops.getId(state),
    shop: fromShops.getShop(state),
    creation: fromUsers.hasAccessToProductCreation(state),
    hasContent: !!fromCart.getContent(state).length,
    shopName: fromShops.getSettings(state, 'name', ''),
    shopUrl: fromShops.getSettings(state, 'url', ''),
    shopOwner: fromShops.getOwner(state),
    shouldRefetchShop: fromShops.shouldRefetchShop(state),
    isAdmin: fromUsers.isAdmin(state),
    entities: fromEntities.getEntities(state),
    locale: fromLocale.getLocale(state),
    budget: fromBudgets.getBudget(state),
  }),
  (dispatch) => bindActionCreators({
    login: loginAction,
    logout: logoutAction,
    shopSwitch: shopSwitchAction,
    fetchShop: () => ({ type: 'UPDATE_OLD_STATE' }),
    closeModal: () => ({ type: 'CHECK_REFETCH', payload: { shouldRefetchShop: false } }),
  }, dispatch),
)(App)

export default process.env.NODE_ENV === 'development' ? hot(ExportedApp) : ExportedApp

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Icon } from 'antd'
import { FETCH_FORCE } from '../../store/products/actions'
import { GET_STOCK } from '../../store/stocks/actions'

export default connect(
  null,
  (dispatch) => bindActionCreators({
    refresh: () => ({
      type: FETCH_FORCE,
      payload: {
        force: true,
      },
    }),
    refreshStock: () => ({
      type: GET_STOCK,
    }),
  }, dispatch),
)(({
  refresh,
  refreshStock,
}) => (
  <Button
    onClick={() => {
      refreshStock()
      refresh()
    }}
    key='refresh'
  >
    <Icon type='reload' />
  </Button>
))

/* eslint-disable max-len */
import React, { useState } from 'react'
import {
  Input,
  Select,
  Divider,
} from 'antd'
import { FormattedMessage } from 'react-intl'

const { Option } = Select

const BaseProductInfoForm = (props) => {
  try {
    const {
      product,
      updateProduct,
      allProviders,
    } = props

    const [name, setName] = useState(product.providerId && allProviders.length && allProviders.find((_provider) => _provider._id === product.providerId) ? allProviders.find((_provider) => _provider._id === product.providerId).name : product.provider && product.provider.name ? product.provider.name : '')
    const [websiteUrl, setWebsiteUrl] = useState(product.providerId && allProviders.length && allProviders.find((_provider) => _provider._id === product.providerId) ? allProviders.find((_provider) => _provider._id === product.providerId).websiteUrl : product.provider && product.provider.websiteUrl ? product.provider.websiteUrl : '')
    const [nameContact, setNameContact] = useState(product.providerId && allProviders.length && allProviders.find((_provider) => _provider._id === product.providerId) ? allProviders.find((_provider) => _provider._id === product.providerId).nameContact : product.provider && product.provider.nameContact ? product.provider.nameContact : '')
    const [email, setEmail] = useState(product.providerId && allProviders.length && allProviders.find((_provider) => _provider._id === product.providerId) ? allProviders.find((_provider) => _provider._id === product.providerId).email : product.provider && product.provider.email ? product.provider.email : '')
    const [phone, setPhone] = useState(product.providerId && allProviders.length && allProviders.find((_provider) => _provider._id === product.providerId) ? allProviders.find((_provider) => _provider._id === product.providerId).phone : product.provider && product.provider.phone ? product.provider.phone : '')
    const [trackingURL, setTrackingURL] = useState(product.providerId && allProviders.length && allProviders.find((_provider) => _provider._id === product.providerId) ? allProviders.find((_provider) => _provider._id === product.providerId).trackingURL : product.provider && product.provider.trackingURL ? product.provider.trackingURL : '')
    const setAllProviders = (provider) => {
      setName(provider && provider.name ? provider.name : '')
      setWebsiteUrl(provider && provider.websiteUrl ? provider.websiteUrl : '')
      setEmail(provider && provider.email ? provider.email : '')
      setPhone(provider && provider.phone ? provider.phone : '')
      setTrackingURL(provider && provider.trackingURL ? provider.trackingURL : '')
      setNameContact(provider && provider.nameContact ? provider.nameContact : '')
      updateProduct(product.provisionalId, {
        provider,
        providerId: provider._id,
      })
    }

    if (product.providerId && !name && allProviders.find((_provider) => _provider._id === product.providerId)) {
      const provider = allProviders.find((_provider) => _provider._id === product.providerId)
      setAllProviders(provider)
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{
            fontWeight: 'bold', margin: '0px 5px 10px 0px', flex: '1 2 auto',
        }}
        >
          <FormattedMessage id='baseProductInfoForm.chooseExistingSupplier' defaultMessage='Choisir un fournisseur existant' />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Select
              style={{ width: '100%' }}
              showSearch
              allowClear
              defaultValue={name}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => {
                const provider = allProviders.find((_provider) => _provider.name === value)
                setAllProviders(provider)
              }}
            >
              {allProviders.map((provider) => (
                <Option key={provider.name} value={provider.name}>{provider.name}</Option>
              ))}
            </Select>
          </div>
        </div>
        <Divider>OU</Divider>
        <div style={{
          display: 'flex', flexDirection: 'row', width: '100%', marginTop: '20px',
        }}
        >
          <div style={{
              fontWeight: 'bold', margin: '0px 5px 10px 0px', flex: '1 2',
          }}
          >
            <FormattedMessage id='baseProductInfoForm.supplierName' defaultMessage='Nom du fournisseur' />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                value={name}
                placeholder='Stanley Stella'
                onChange={(e) => {
                  setName(e.target.value)
                  updateProduct(product.provisionalId, {
                    provider: {
                      ...product.provider,
                      name: e.target.value,
                    },
                  })
                }}
                style={{ marginBottom: '10px' }}
              />
            </div>
          </div>
          <div style={{
              fontWeight: 'bold', margin: '0px 0px 10px 5px', flex: '1 2',
          }}
          >
            <FormattedMessage id='baseProductInfoForm.URL' defaultMessage='URL générique de suivi de commande' />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                value={trackingURL}
                onChange={(e) => {
                  setTrackingURL(e.target.value)
                  updateProduct(product.provisionalId, {
                    provider: {
                      ...product.provider,
                      trackingURL: e.target.value,
                    },
                  })
                }}
                style={{ marginBottom: '10px' }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div style={{
              fontWeight: 'bold', margin: '0px 0px 10px 0px', flex: '1 2',
          }}
          >
            <FormattedMessage id='websiteLink' defaultMessage='Lien du site' />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                value={websiteUrl}
                placeholder='https://www.stanleystella.com/'
                onChange={(e) => {
                  setWebsiteUrl(e.target.value)
                  updateProduct(product.provisionalId, {
                    provider: {
                      ...product.provider,
                      websiteUrl: e.target.value,
                    },
                  })
                }}
                style={{ marginBottom: '10px' }}
              />
            </div>
          </div>

        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div style={{
              fontWeight: 'bold', margin: '0px 5px 10px 0px', flex: '1 3',
          }}
          >
            <FormattedMessage id='contactName' defaultMessage='Nom du contact' />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                value={nameContact}
                onChange={(e) => {
                  setNameContact(e.target.value)
                  updateProduct(product.provisionalId, {
                    provider: {
                      ...product.provider,
                      nameContact: e.target.value,
                    },
                  })
                }}
                style={{ marginBottom: '10px' }}
              />
            </div>
          </div>
          <div style={{
              fontWeight: 'bold', margin: '0px 5px 10px 5px', flex: '1 3',
          }}
          >
            <FormattedMessage id='baseProductInfoForm.contactEmail' defaultMessage='Email du contact' />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  updateProduct(product.provisionalId, {
                    provider: {
                      ...product.provider,
                      email: e.target.value,
                    },
                  })
                }}
                style={{ marginBottom: '10px' }}
              />
            </div>
          </div>
          <div style={{
              fontWeight: 'bold', margin: '0px 0px 10px 5px', flex: '1 3',
          }}
          >
            <FormattedMessage id='phone' defaultMessage='Téléphone' />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value)
                  updateProduct(product.provisionalId, {
                    provider: {
                      ...product.provider,
                      phone: e.target.value,
                    },
                  })
                }}
                style={{ marginBottom: '10px' }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  } catch (e) {
    console.log(e, 'error molecules/BaseProductInfoForm')
    return null
  }
}

export default BaseProductInfoForm

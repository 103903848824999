import { initialState } from './selectors'
import * as actions from './actions'

const { FETCH_PASTILLES, PASTILLES_RECEIVED } = actions

export default (state = initialState, { type, payload = {} }) => {
  const {
    data,
  } = payload
  switch (type) {
  case FETCH_PASTILLES:
    return {
      ...state,
      loading: true,
    }
  case PASTILLES_RECEIVED:
    return {
      ...state,
      data,
      loading: false,
    }
  default:
    return state
  }
}

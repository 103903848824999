import React from 'react'
import { Button, Badge, Icon } from 'antd'
import { useDispatch } from 'react-redux'
import { setVisibleState } from '../../../../store/baseProductsCart/actions'

const CatalogCartDisplayButton = (props) => {
  const dispatch = useDispatch()
  const { catalogCartContent, catalogCartVisible } = props
  return (
    <Button
      onClick={() => dispatch(setVisibleState(!(catalogCartVisible)))}
      style={{ marginRight: '10px' }}
    >
      <Badge count={catalogCartContent.length} />
      <Icon type='shopping-cart' />
    </Button>
  )
}

export default CatalogCartDisplayButton

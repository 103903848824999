import { initialState } from './selectors'
import {
  GET_TECHNICS,
  RECEIVED_TECHNICS,
  ERROR,
} from './actions'

export default (state = initialState, { type, payload }) => {
  const { data } = payload || {}
  switch (type) {
  case GET_TECHNICS:
    return {
      ...state,
      loading: true,
    }
  case RECEIVED_TECHNICS:
    return {
      ...state,
      data,
      loading: false,
    }
  case ERROR:
    return {
      ...state,
      error: true,
      loading: false,
    }
  default:
    return state
  }
}

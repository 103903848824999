import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { character } from '../../../config/theme'

const MagicLinksInputTitle = (props) => {
  const {
    title, // Required
    children, // Required
    icon,
    info,
    style,
  } = props

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '8px', width: '100%',
    }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        fontWeight: 500,
        fontSize: '14px',
        color: character.title,
        ...style,
      }}
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        {title}
        {info}
      </div>
      {children}
    </div>
  )
}

export default MagicLinksInputTitle

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  InputNumber,
  Descriptions,
  Divider,
  Empty,
} from 'antd'

import 'react-multi-carousel/lib/styles.css'

import { calculateShippingPrice } from '@balibart/shipping'
import { CountrySelector } from '../..'

import theme from '../../../config/theme'

const Caracteristic = (props) => {
  const { baseProduct, language } = props
  const {
    category = {},
    subCategory = {},
    caracteristics = {},
  } = baseProduct

  return (
    <>
      <div style={{ color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px' }}>
        <FormattedMessage id='characteristics' defaultMessage='Caractéristiques' />
      </div>
      <Descriptions>
        <Descriptions.Item span={1} label={<FormattedMessage id='category' defaultMessage='Catégorie' />}>
          {category[language] && category[language].length
            ? category[language].find((cat) => cat)
            : 'NC'}
        </Descriptions.Item>
        <Descriptions.Item span={2} label={<FormattedMessage id='subCategory' defaultMessage='Sous-catégorie' />}>
          {subCategory[language] || 'NC'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='weight' defaultMessage='Poids' />}>
          {(caracteristics && caracteristics.weight) || 'NC'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='capacity' defaultMessage='Capacité' />}>
          {(caracteristics && caracteristics.capacity) || 'NC'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='dimension' defaultMessage='Dimension' />}>
          {(caracteristics && caracteristics.dimension) || 'NC'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='constitution' defaultMessage='Constitution' />}>
          {(caracteristics && caracteristics.constitution) || 'NC'}
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}

const ProductMoreInfosTab = (props) => {
  try {
    const {
      product,
      baseProduct,
      shop,
      products,
      suppliers,
      allStocks,
      locale = 'fr',
    } = props

    if (!baseProduct) return <Empty />

    const [deliveryInfos, setState] = useState({
      cartQuantity: 1,
      cartCountry: 'FR',
    })

    const {
      cartQuantity,
      cartCountry,
    } = deliveryInfos

    // const productBP = product.baseProduct
    const sizes = product.variations.filter((v) => v.type === 'size')
    const colors = product.variations.filter((v) => v.type === 'color')
    const language = locale === 'fr' ? 'fr' : 'en'

    let shipping = {
      delays: {},
    }

    try {
      shipping = calculateShippingPrice(
        {
          products: [{
            _id: product._id,
            quantity: cartQuantity,
            variations: {
              color: colors[0],
              size: sizes[0],
            },
          }],
          address: {
            country: cartCountry,
          },
        },
        shop,
        products,
        suppliers,
        products,
        allStocks,
      )
      // TODO handle non deliverable case
    } catch (error) {
      // NOTE do noting
      // console.log('shipping', { error })
    }

    const notAvailable = (
      <FormattedMessage id='notAvailableForThisCountry' defaultMessage='Non disponible pour ce pays' />
    )

    return (
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
      }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <div style={{
            color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px',
          }}
          >
            <FormattedMessage id='product.moreInfos.bpName' defaultMessage='Nom du produit dans le catalogue Panopli' />
          </div>
          <div style={{ marginBottom: '10px' }}>
            {baseProduct?.name && baseProduct.name[language] ? baseProduct.name[language] : baseProduct.name?.fr}
          </div>
          <Divider />
          <Caracteristic baseProduct={baseProduct} language={language} />
          <Divider />
          <div style={{
            color: theme.neutralColor[6][12], fontWeight: 'bold', marginBottom: '5px',
          }}
          >
            <FormattedMessage
              id='productInfoDrawer.deliveryPriceSimulator'
              defaultMessage="Simulateur de frais d'envoi"
            />
          </div>
          <div>
            <Descriptions bordered column={2}>
              <Descriptions.Item label={<FormattedMessage id='country' defaultMessage='Pays' />}>
                <CountrySelector
                  defaultValue='FR'
                  onChange={(value) => {
                    setState({
                      ...deliveryInfos,
                      cartCountry: value,
                    })
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id='productInfoDrawer.standardDelivery' defaultMessage='Livraison Standard' />}>
                {shipping?.price ? `${shipping.price} €` : notAvailable}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id='quantity' defaultMessage='Quantité' />}>
                <InputNumber
                  onChange={(value) => {
                    setState({
                      ...deliveryInfos,
                      cartQuantity: value,
                    })
                  }}
                  value={cartQuantity}
                  min={1}
                />
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id='productInfoDrawer.standardDelay' defaultMessage='Delais Standards' />}>
                {shipping?.delays?.deliveryMin && shipping?.delays?.deliveryMax
                  ? `min: ${shipping.delays.deliveryMin} - max: ${shipping.delays.deliveryMax}`
                  : notAvailable}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </div>
    )
  } catch (e) {
    console.log('organisms/BaseProductInfo error:', e)
    return null
  }
}

export default ProductMoreInfosTab

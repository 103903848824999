import React from 'react'
import { PieChart, Pie, Sector } from 'recharts'

import themeColors from '../../../config/theme'

export default class BreakdownChart extends React.Component {
  constructor() {
    super()
    this.state = {
      activeIndex: 0,
    }
  }

  renderActiveShape = (incoming) => {
    try {
      const RADIAN = Math.PI / 180
      const {
        cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value,
      } = incoming
      const sin = Math.sin(-RADIAN * midAngle)
      const cos = Math.cos(-RADIAN * midAngle)
      const sx = cx + (outerRadius + 10) * cos
      const sy = cy + (outerRadius + 10) * sin
      const mx = cx + (outerRadius + 30) * cos
      const my = cy + (outerRadius + 30) * sin
      const ex = mx + (cos >= 0 ? 1 : -1) * 22
      const ey = my
      const textAnchor = cos >= 0 ? 'start' : 'end'
      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>{`${payload.name}`}</text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={payload.color ? payload.color.value : fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>
            {
              `${value}`
            }
          </text>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
            {`${(percent * 100).toFixed(2)}%`}
          </text>
        </g>
      )
    } catch (error) {
      console.log('breakdownChart renderActiveShape', { error })
      return null
    }
  }

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    })
  }

  render() {
    try {
      const { activeIndex } = this.state
      const { data = {}, width = 400, height = 400 } = this.props
      const formattedData = Object.values(data)
      return (
        <PieChart
          // FIXME we cannot not have width & height but it fucks the responsiveness
          width={width}
          height={height}
        >
          <Pie
            activeIndex={activeIndex}
            activeShape={this.renderActiveShape}
            data={formattedData}
            cx={width / 2}
            cy={height / 2}
            innerRadius={60}
            outerRadius={80}
            fill={themeColors.primaryColor[6]}
            dataKey='value'
            onMouseEnter={this.onPieEnter}
          />
        </PieChart>
      )
    } catch (error) {
      console.log('breakdownChart render', { error })
      return null
    }
  }
}

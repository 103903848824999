import {
  put,
  call,
  takeEvery,
  select,
  all,
  takeLatest,
} from 'redux-saga/effects'
import { fetchEntities } from '../entities/sagas'
import { fromUsers, fromShops } from '../selectors'

import * as membersActions from './actions'

const { getId } = fromShops

export function* getMembers(api, action) {
  try {
    const { payload = {}, type } = action || {}
    const {
      page = 0,
      pageSize = 60,
    } = payload
    // const shouldMembersBeRefetch = shouldRefetch(lastFetchProducts, new Date(), shop, storedOrigin)
    if (type !== membersActions.FETCH_MORE) {
      yield put({
        type: membersActions.FETCH_MEMBERS,
      })
    }
    const { members, pagination } = yield call([api, api.get], '/members', {
      headers: {
        mode: 'cors',
        authorization: yield select(fromUsers.getAuth),
        shopId: yield select(getId),
        page,
        pageSize,
      },
    })

    if (type !== membersActions.FETCH_MORE) {
      yield put({
        type: membersActions.RECEIVED_MEMBERS,
        payload: {
          members,
        },
      })
    } else {
      yield put({ type: membersActions.RECEIVED_MORE, payload: { members, pagination } })
    }
    if (pagination.hasMore) {
      yield put({
        type: membersActions.FETCH_MORE,
        payload: {
          page: page + 1,
          pageSize,
        },
      })
    }

    return true
  } catch (e) {
    console.log('getMembers - error', e, e.message)
    return false
  }
}

export function* getOneMember(api, payload) {
  try {
    const result = yield call([api, api.get], '/memberToken', {
      headers: {
        mode: 'cors',
        token: payload.token,
      },
    })
    yield put({
      type: membersActions.RECEIVED_ONE_MEMBER,
      payload: {
        member: result.member,
      },
    })
    return true
  } catch (e) {
    console.log('getOneMember - error', e, e.message)
    return false
  }
}

export function* createMembers(api, payload) {
  try {
    const { members, callback = null } = payload
    const authorization = yield select(fromUsers.getAuth)
    // eslint-disable-next-line camelcase
    const shop_id = yield select(getId)
    const data = {
      members,
      shop_id,
    }
    yield call([api, api.post], '/members', data, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    yield put({
      type: membersActions.FETCH_FORCE_MEMBERS,
    })
    if (callback) callback()
    return true
  } catch (e) {
    console.log('createMembers - error', e, e.message)
    return false
  }
}

export function* modifyMembersCredit(api, action) {
  try {
    const { data, callback = null } = action.payload
    const authorization = yield select(fromUsers.getAuth)
    yield call([api, api.put], '/members/credit', data, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    yield put({
      type: membersActions.FETCH_FORCE_MEMBERS,
    })
    if (callback) callback()
    return true
  } catch (e) {
    console.log('modifyMembersCredits - error', e, e.message)
    return false
  }
}

export function* modifyMembersRatio(api, action) {
  try {
    const { data, callback = null } = action.payload
    const authorization = yield select(fromUsers.getAuth)
    yield call([api, api.put], '/members/ratio', data, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    yield put({
      type: membersActions.FETCH_FORCE_MEMBERS,
    })
    if (callback) callback()
    return true
  } catch (e) {
    console.log('modifyMembersCredits - error', e, e.message)
    return false
  }
}

export function* modifyMembersGroups(api, action) {
  try {
    const { data, callback = null } = action.payload
    const authorization = yield select(fromUsers.getAuth)
    yield call([api, api.put], '/members/groups', data, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    yield put({
      type: membersActions.FETCH_FORCE_MEMBERS,
    })
    if (callback) callback()
    return true
  } catch (e) {
    console.log('modifyMembersGroups - error', e, e.message)
    return false
  }
}

export function* modifyMembersEntity(api, action) {
  try {
    const { data, callback = null } = action.payload
    const authorization = yield select(fromUsers.getAuth)
    yield call([api, api.put], '/members/entity', data, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    yield put({
      type: membersActions.FETCH_FORCE_MEMBERS,
    })
    if (callback) callback()
    return true
  } catch (e) {
    console.log('modifyMembersEntity - error', e, e.message)
    return false
  }
}

export function* modifyMember(api, payload) {
  try {
    const { member } = payload
    const authorization = yield select(fromUsers.getAuth)
    const data = {
      member,
    }

    yield call([api, api.put], '/member', data, {
      headers: {
        mode: 'cors',
        authorization,
      },
    })
    return true
  } catch (e) {
    console.log('modifyMember - error', e, e.message)
    return false
  }
}

export function* deleteMember(api, payload) {
  try {
    const { member, callback = null } = payload
    const authorization = yield select(fromUsers.getAuth)

    yield call([api, api.delete], '/members', {
      headers: {
        mode: 'cors',
        authorization,
        member_id: member._id,
      },
    })
    yield put({
      type: membersActions.FETCH_FORCE_MEMBERS,
    })
    if (callback) callback()
    return true
  } catch (e) {
    console.log('deleteMember - error', e, e.message)
    return false
  }
}

export function* sendMail(api, payload) {
  try {
    const { member } = payload
    const authorization = yield select(fromUsers.getAuth)
    const data = {
      member,
    }
    yield call([api, api.post], '/member', data, {
      headers: {
        mode: 'cors',
        authorization,
        member_id: member._id,
      },
    })
    yield put({
      type: membersActions.FETCH_FORCE_MEMBERS,
    })
    return true
  } catch (e) {
    console.log('member sendMail - error', e, e.message)
    return false
  }
}

export function* getDataForMembersPage(api) {
  try {
    const page = 0
    const pageSize = 40
    yield all([
      call(getMembers, api, { payload: { page, pageSize } }),
      call(fetchEntities, api),
    ])
    yield put({ type: membersActions.END_FETCH_DATA })
  } catch (e) {
    yield put({ type: membersActions.ERROR_FETCH_DATA })
  }
}

export default function* ({ api }) {
  yield takeLatest(membersActions.INIT_FETCH_DATA, getDataForMembersPage, api)
  yield takeEvery(membersActions.FETCH_FORCE_MEMBERS, getMembers, api)
  yield takeEvery(membersActions.FETCH_FORCE_ONE_MEMBER, getOneMember, api)
  yield takeEvery(membersActions.ADD_MEMBERS, createMembers, api)
  yield takeEvery(membersActions.MODIFY_MEMBERS_CREDIT, modifyMembersCredit, api)
  yield takeEvery(membersActions.MODIFY_MEMBERS_RATIO, modifyMembersRatio, api)
  yield takeEvery(membersActions.MODIFY_MEMBERS_GROUPS, modifyMembersGroups, api)
  yield takeEvery(membersActions.MODIFY_MEMBERS_ENTITY, modifyMembersEntity, api)
  yield takeEvery(membersActions.DELETE_MEMBER, deleteMember, api)
  yield takeEvery(membersActions.MODIFY_MEMBER, modifyMember, api)
  yield takeEvery(membersActions.SEND_MAIL, sendMail, api)
  yield takeLatest(membersActions.FETCH_MORE, getMembers, api)
}

import React from 'react'
import {
  Layout, Menu, Button, Icon,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { Onboarding } from '../../containers'

const { Header, Content } = Layout

const OnboardingPage = ({ history, logout }) => (
  <Layout className='layout' style={{ width: '100%' }}>
    <Header
      style={{
        backgroundColor: 'transparent',
        padding: 0,
      }}
    >
      <Menu
        theme='light'
        mode='inline'
        defaultSelectedKeys={['2']}
        style={{
          height: '64px',
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Button
          type='link'
          onClick={() => history.push('/')}
          style={{
            padding: 0,
            height: 'auto',
          }}
        >
          <img
            style={{ height: 64 }}
            src='/logo-panopli-02.png'
            alt='logo'
          />
        </Button>
        <Button
          type='link'
          onClick={() => {
            logout()
          }}
          style={{
            padding: 0,
            height: 'auto',
            position: 'absolute',
            right: 30,
            fontWeight: 'bold',
          }}
        >
          <Icon type='login' style={{ marginRight: 10 }} />
          <FormattedMessage
            id='onboarding.connect'
            defaultMessage='Connexion'
          />
        </Button>
      </Menu>
    </Header>
    <Content
      style={{
        padding: '0 20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Onboarding />
    </Content>
  </Layout>
)

export default withRouter(OnboardingPage)

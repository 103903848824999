import React, { useState } from 'react'
import {
  Form,
  Tabs,
  Button,
  Input,
  message,
} from 'antd'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'

const { TabPane } = Tabs

const LegalNoticeForm = (props) => {
  try {
    const intl = useIntl()
    const {
      shop,
      modifyShop,
      legals,
      lang,
      form: {
        getFieldDecorator,
        validateFields,
        resetFields,
        isFieldsTouched,
      },
    } = props

    const [state, setState] = useState({
      loading: false,
    })

    const { loading } = state

    const handleSubmit = (e) => {
      e.preventDefault()
      validateFields((err, values) => {
        if (!err) {
          const newLegals = legals
          newLegals[lang] = values
          const modifications = {
            settings: {
              ...shop.settings,
              legals: newLegals,
            },
          }
          setState({ ...state, loading: true })
          modifyShop({
            shop_id: shop._id,
            modifications,
            callback: () => {
              message.info(
                <FormattedMessage
                  id='shops.updateSuccess.legalNoticeForm'
                  defaultMessage='Boutique mise à jour (langue : "{lang}")'
                  values={{ lang }}
                />,
              )
              setState({ ...state, loading: false })
              return true
            },
          })
        }
      })
    }

    return (
      <div>
        <Form onSubmit={handleSubmit} className='product-info-form'>
          <Tabs defaultActiveKey='1'>
            <TabPane
              tab={intl.formatMessage({ id: 'legalNoticeForm.terms', defaultMessage: 'Mentions légales' })}
              key='1'
            >
              <Form.Item>
                {getFieldDecorator('legalNotice', {
                  initialValue: legals[lang]?.legalNotice,
                })(
                  <Input.TextArea
                    type='description'
                    rows={4}
                  />,
                )}
              </Form.Item>
            </TabPane>
            <TabPane
              tab={intl.formatMessage({ id: 'legalNoticeForm.CGU/CGV', defaultMessage: 'Conditions Générales d\'Utilisation / Vente' })}
              key='2'
            >
              <Form.Item>
                {getFieldDecorator('terms', {
                  initialValue: legals[lang]?.terms,
                })(
                  <Input.TextArea
                    type='description'
                    rows={4}
                  />,
                )}
              </Form.Item>
            </TabPane>
            <TabPane
              tab={intl.formatMessage({ id: 'legalNoticeForm.privacyPolicy', defaultMessage: 'Politique de confidentialité' })}
              key='3'
            >
              <Form.Item>
                {getFieldDecorator('privacyPolicy', {
                  initialValue: legals[lang]?.privacyPolicy,
                })(
                  <Input.TextArea
                    type='description'
                    rows={4}
                  />,
                )}
              </Form.Item>
            </TabPane>
          </Tabs>
          { isFieldsTouched() ? (
            <div
              style={{
                display: 'flex',
              }}
            >
              <Button
                style={{ margin: '0 5px 5px 0' }}
                type='primary'
                htmlType='submit'
                loading={loading}
              >
                <FormattedMessage id='modify' defaultMessage='Modifier' />
              </Button>
              <Button
                style={{ margin: '0 5px 5px 0' }}
                onClick={() => resetFields()}
              >
                <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
              </Button>
            </div>
          ) : null}
        </Form>
      </div>

    )
  } catch (e) {
    console.log('Organisms/LegalNoticeForm/content error:', e)
    return null
  }
}

export default Form.create({
  name: 'legalNoticeForm',
})(injectIntl(LegalNoticeForm))

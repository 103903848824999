import React from 'react'
import {
  Tag,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import theme from '../../../config/theme'

const BudgetTags = ({ budget }) => (
  <>
    {budget?.committedCredit ? (
      <Tag
        color={theme.tertiaryColor[6]}
        style={{
          color: theme.headerColor,
          fontWeight: 'bold',
          fontSize: '11px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span style={{ margin: '0 5px 0 0' }}>
          {budget.committedCredit}
        </span>
        <FormattedMessage
          id='header.budget.creditCommitted'
          defaultMessage='links engagés'
        />
      </Tag>
    ) : null}
    {budget?.balance ? (
      <Tag
        color='white'
        style={{
          color: theme.primaryColor[6],
          fontWeight: 'bold',
          fontSize: '11px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span style={{ margin: '0 5px 0 0' }}>
          {budget.balance}
        </span>
        <FormattedMessage
          id='header.budget.creditAvailable'
          defaultMessage='links disponibles'
          style={{ margin: '0 0 0 5px' }}
        />
      </Tag>
    ) : null}
  </>
)

export default BudgetTags

/* eslint-disable react/no-unused-state */
/* eslint-disable no-useless-escape */
import React from 'react'
import {
  Form, Button, Row, Checkbox, Typography,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import {
  CheckoutPayment,
  TransactionTypes,
  PickupModal,
  PresetAddresses,
  CheckoutFormDeliveryMode,
  CheckoutFormContactInformation,
  CheckoutFormAddress,
} from '../..'

const { Title } = Typography

export default Form.create({
  name: 'companyInfos',
})(class extends React.Component {
  constructor() {
    super()
    this.state = {
      transactionType: 'billing',
      setPresetAddress: {},
      paymentMethodSelected: false,
    }
  }

  handleSubmit = (e) => {
    const {
      form,
      onSubmit,
      paymentMethods,
    } = this.props
    const {
      transactionType,
      setPresetAddress,
      paymentMethodSelected,
    } = this.state
    const {
      validateFieldsAndScroll,
    } = form
    e.preventDefault()
    validateFieldsAndScroll(async (err, _values) => {
      const values = _values
      if (!err) {
        let _paymentMethodSelected = false
        if (paymentMethodSelected) {
          _paymentMethodSelected = paymentMethods
            .find((paymentMethod) => paymentMethod.id === paymentMethodSelected)
        }
        let result = {
          ...values,
          transactionType,
          paymentMethodSelected: _paymentMethodSelected,
        }
        if (setPresetAddress && setPresetAddress.street) {
          result = {
            ...result,
            client: {
              ...result.client,
              ...setPresetAddress,
            },
            sameBillingAddress: true,
          }
        }
        onSubmit(result)
      }
    })
  }

  openPickup = (open) => {
    this.setState({ open })
  }

  updatePickupInfos = (pickupInfos) => {
    this.setState({ pickupInfos })
    this.openPickup(false)
  }

  onChangeShippingMode = (e) => {
    this.setState({ shippingMode: e.target.value })
    if (e.target.value === 'pickup') {
      this.openPickup(true)
    }
  }

  onChangeTransactionType = (e) => this.setState({ transactionType: e.target.value })

  handleChangePreSetAdresses = (value) => {
    const { shop } = this.props
    const { addresses } = shop.settings
    if (!value) {
      this.setState({ setPresetAddress: {} })
      return 'FR'
    }

    const addressFound = addresses.find((address) => address.name === value).address
    this.setState({
      setPresetAddress: addressFound,
    })

    return addressFound?.country || 'FR'
  }

  handleSelectionPaymentMethod = (id) => {
    this.setState({ paymentMethodSelected: id })
  }

  render() {
    const {
      form,
      changeCartCountry,
      shop,
      paymentMethods,
      isAdmin,
      entities,
      locale,
    } = this.props
    const { addresses, enableMonthlyInvoicing } = shop.settings
    const {
      transactionType,
      open,
      setPresetAddress,
      paymentMethodSelected,
    } = this.state
    const {
      getFieldDecorator,
      resetFields,
      getFieldValue,
    } = form
    const { cardCompleted, cardError } = this.state

    return (
      <Form
        onSubmit={this.handleSubmit}
        style={{
          padding: '12px',
        }}
      >
        <PickupModal
          open={open}
          updatePickupInfos={this.updatePickupInfos}
          close={() => this.openPickup(false)}
        />
        <PresetAddresses
          addresses={addresses}
          handleChangePreSetAdresses={this.handleChangePreSetAdresses}
          changeCountry={changeCartCountry}
          entities={entities}
        />
        <CheckoutFormContactInformation
          setPresetAddress={setPresetAddress}
          getFieldDecorator={getFieldDecorator}
        />
        <CheckoutFormAddress
          form={form}
          changeCountry={changeCartCountry}
          setPresetAddress={setPresetAddress}
          locale={locale}
        />
        {getFieldValue('sameBillingAddress') || setPresetAddress?.street ? null
          : <CheckoutFormAddress form={form} type='billing' />}
        {setPresetAddress && setPresetAddress.street ? null : (
          <Row>
            <Form.Item style={{ marginLeft: '10px', marginBottom: '10px' }}>
              {getFieldDecorator('sameBillingAddress', {
                initialValue: true,
              })(
                <Checkbox defaultChecked>
                  <FormattedMessage id='checkoutForm.sameDeliveryAddress' defaultMessage='Adresse de facturation identique' />
                </Checkbox>,
              )}
            </Form.Item>
          </Row>
        )}
        <Row style={{ marginLeft: '10px', marginBottom: '10px' }}>
          <Title level={4}>
            <FormattedMessage id='checkoutForm.deliveryMode.title' defaultMessage='Mode de livraison' />
          </Title>
          <CheckoutFormDeliveryMode
            getFieldDecorator={getFieldDecorator}
            onChangeShippingMode={this.onChangeShippingMode}
          />
        </Row>
        <Row style={{ marginLeft: '10px', marginBottom: '10px' }}>
          <Title level={4}>
            <FormattedMessage id='checkoutForm.payment.method' defaultMessage='Méthode de paiement' />
          </Title>
          <TransactionTypes
            enableMonthlyInvoicing={enableMonthlyInvoicing}
            onChangeTransactionType={this.onChangeTransactionType}
            transactionType={transactionType}
            isAdmin={isAdmin}
          />
        </Row>
        {transactionType === 'payment' ? (
          <div style={{ marginLeft: '10px', marginBottom: '10px' }} className='checkboxPaymentMethod'>
            <Title level={4}>
              <FormattedMessage id='checkoutForm.payment.card' defaultMessage='Paiement par carte' />
            </Title>
            <CheckoutPayment
              paymentMethods={paymentMethods}
              paymentMethodSelected={paymentMethodSelected}
              handleSelectionPaymentMethod={this.handleSelectionPaymentMethod}
              onChange={(e) => {
                this.setState({
                  cardError: e.error || false,
                  cardCompleted: e.complete || false,
                })
              }}
            />
            {!paymentMethodSelected ? (
              <Form.Item style={{ marginBottom: 0 }}>
                {getFieldDecorator('savePaymentMethod')(
                  <Checkbox>
                    <FormattedMessage id='checkoutForm.payment.save' defaultMessage='Enregistrer cette carte pour plus tard' />
                  </Checkbox>,
                )}
              </Form.Item>
            ) : null}
          </div>
        ) : null}
        <Row style={{ marginLeft: '10px', marginBottom: 0 }}>
          <Form.Item>
            {getFieldDecorator('cgu', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='checkoutForm.required.CGVU' defaultMessage='Veuillez accepter les CGUV' />,
                },
              ],
            })(
              <Checkbox>
                <FormattedMessage
                  id='checkoutForm.termsAcceptance'
                  defaultMessage="J'ai lu et j'accepte les conditions générales de vente et d'utilisation de la boutique"
                />
              </Checkbox>,
            )}
          </Form.Item>
        </Row>
        <Row>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{
                margin: '0 12px',
              }}
              disabled={!(transactionType !== 'card' || (cardCompleted && !cardError))}
            >
              <FormattedMessage id='ordering' defaultMessage='Commander' />
            </Button>
            <Button onClick={() => resetFields()}>
              <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
            </Button>
          </Form.Item>
        </Row>
      </Form>
    )
  }
})

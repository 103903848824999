import React from 'react'
import '../../../App.css'

import {
  Empty,
  Button,
  List,
} from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { CampaignCard, CampaignFilter } from '../../molecules'
import { getCampaignsByFilters } from '../../../helpers/magicLinks'
import { MagicLinksTrackingDashboard } from '..'

const CampaignList = (props) => {
  try {
    const {
      budgets,
      campaigns,
      setVisibleModalCreation,
      setVisibleMagicLinksDrawer,
      setCurrentCampaignId,
      filters,
      setFiltersState,
      user,
      magicLinks,
      collaborators,
      shopOwner,
      history,
      shopId,
      removeDraftCampaign,
    } = props

    const filteredCampaigns = getCampaignsByFilters(filters, campaigns)

    if (!campaigns?.length) {
      return (
        <Empty
          imageStyle={{
            height: 80,
          }}
          description={(
            <FormattedMessage
              id='campaigns.parameters.list.empty'
              defaultMessage='Pas de campagne créée'
            />
          )}
        >
          <Button
            type='primary'
            onClick={() => setVisibleModalCreation(true)}
          >
            <FormattedMessage
              id='campaigns.createCampaign'
              defaultMessage='Créer une campagne'
            />
          </Button>
        </Empty>
      )
    }

    return (
      <>
        <MagicLinksTrackingDashboard
          budgets={budgets}
          campaigns={campaigns}
          magicLinks={magicLinks}
          collaborators={collaborators}
          shopId={shopId}
          user={user}
        />
        <CampaignFilter
          filters={filters}
          magicLinks={magicLinks}
          setFiltersState={setFiltersState}
          filter
          user={user}
          collaborators={collaborators}
          shopOwner={shopOwner}
        />
        <List
          style={{ marginTop: '20px' }}
          grid={{ gutter: 12, column: 4 }}
          dataSource={filteredCampaigns}
          renderItem={(campaign) => (
            <CampaignCard
              campaign={campaign}
              magicLinks={magicLinks}
              history={history}
              setVisibleMagicLinksDrawer={setVisibleMagicLinksDrawer}
              setCurrentCampaignId={setCurrentCampaignId}
              removeDraftCampaign={removeDraftCampaign}
            />
          )}
        />
      </>
    )
  } catch (e) {
    console.log('CampaignList error:', e)
    return false
  }
}

export default injectIntl(CampaignList)

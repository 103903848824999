import React, { useState } from 'react'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import {
  Drawer,
  Input,
  Button,
} from 'antd'

const rowStyle = {
  display: 'flex',
  color: 'rgba(0, 0, 0, 0.85)',
  marginBottom: '30px',
}

const columnStyle = {
  display: 'flex',
  flex: '1 2',
  flexDirection: 'column',
  marginRight: '10px',
}

const titleStyle = {
   
  fontWeight: 'bold',
  flex: '1 1',
  marginBottom: '10px',
}

const CatalogProviderDrawer = (props) => {
  try {
    const {
      visible,
      onClose,
      baseProduct,
      allProviders,
      providerId,
      modifyProvider,
    } = props

    const provider = allProviders.find((pro) => baseProduct.providerId === pro._id) || {}

    const {
      name,
      websiteUrl,
      phone,
      email,
      nameContact,
    } = provider

    const initialState = {
      name, websiteUrl, phone, email, nameContact,
    }

    const [modifications, setModifications] = useState(initialState)

    const hasBeenModified = JSON.stringify(modifications) !== JSON.stringify(initialState)

    const intl = useIntl()

    return (
      <Drawer
        visible={visible}
        onClose={onClose}
        width='40%'
        destroyOnClose
        title={(
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '95%',
            }}
          >
            <FormattedMessage
              id='catalogProviderDrawer.title'
              defaultMessage='Edition du fournisseur "{name}"'
              values={{ name }}
            />
          </div>
        )}
      >
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage id='lastName' defaultMessage='Nom' />
            </div>
            <Input
              style={{ width: '100%' }}
              defaultValue={name}
              onChange={(e) => setModifications({ ...modifications, name: e.target.value })}
              placeholder={intl.formatMessage({
                id: 'catalogProviderDrawer.providerName',
                defaultMessage: 'Veuillez entrer le nom du fournisseur...',
              })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage id='website' defaultMessage='Site web' />
            </div>
            <Input
              style={{ width: '100%' }}
              defaultValue={websiteUrl}
              onChange={(e) => setModifications({ ...modifications, websiteUrl: e.target.value })}
              placeholder={intl.formatMessage({
                id: 'catalogProviderDrawer.websiteURL',
                defaultMessage: 'Veuillez entrer l\'URL du site web...',
              })}
            />
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage id='contactName' defaultMessage='Nom du contact' />
            </div>
            <Input
              style={{ width: '100%' }}
              defaultValue={nameContact}
              onChange={(e) => setModifications({ ...modifications, nameContact: e.target.value })}
              placeholder={intl.formatMessage({
                id: 'catalogProviderDrawer.contactName',
                defaultMessage: 'Veuillez entrer le nom du contact..',
              })}
            />
          </div>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage id='email' defaultMessage='Email' />
            </div>
            <Input
              style={{ width: '100%' }}
              defaultValue={email}
              onChange={(e) => setModifications({ ...modifications, email: e.target.value })}
              placeholder={intl.formatMessage({
                id: 'catalogProviderDrawer.contactEmail',
                defaultMessage: 'Veuillez entrer l\'email du contact...',
              })}
            />
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={titleStyle}>
              <FormattedMessage id='phone' defaultMessage='Téléphone' />
            </div>
            <Input
              style={{ width: '50%' }}
              defaultValue={phone}
              onChange={(e) => setModifications({ ...modifications, phone: e.target.value })}
              placeholder={intl.formatMessage({
                id: 'catalogProviderDrawer.phoneNumber',
                defaultMessage: 'Veuillez entrer le numéro de téléphone...',
              })}
            />
          </div>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          {hasBeenModified ? (
            <Button
              onClick={() => {
                modifyProvider({ providerId, modifications })
                onClose()
              }}
            >
              <FormattedMessage id='modify' defaultMessage='Modifier' />
            </Button>
          ) : null }
        </div>
      </Drawer>
    )
  } catch (e) {
    console.log('CatalogProviderDrawer', e)
    return null
  }
}

export default injectIntl(CatalogProviderDrawer)

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Drawer, Form, Button, Row, DatePicker, Checkbox,
} from 'antd'
import date from 'dayjs'
import { MODIFY_SEVERAL, managePreOrderDrawer } from '../../store/products/actions'
import { fromProducts, fromUsers, fromLocale } from '../../store/selectors'

const getInitialValue = (type, selected, products) => {
  if (type === 'start') {
    return selected.length > 1 ? null
      : ((products.length && products[0].preOrderInfos && date(products[0].preOrderInfos.startDate)) || null)
  }
  if (type === 'end') {
    return selected.length > 1 ? null
      : ((products.length && products[0].preOrderInfos && date(products[0].preOrderInfos.endDate)) || null)
  }
  if (type === 'shipping') {
    return selected.length > 1 ? null
      : ((products.length && products[0].preOrderInfos && date(products[0].preOrderInfos.shippingEstimate)) || null)
  }
  if (type === 'displayBand') {
    return selected.length > 1 ? null
      : ((products.length && products[0].preOrderInfos && products[0].preOrderInfos.displayBandOnCustomImage) || null)
  }
  return null
}

export default connect(
  (state) => ({
    allProducts: fromProducts.allProducts(state),
    preOrderDrawerOpen: fromProducts.preOrderDrawer(state),
    user: fromUsers.getUser(state),
    locale: fromLocale.getLocale(state),
  }),
  (dispatch) => bindActionCreators({
    modifyProducts: (payload) => ({
      type: MODIFY_SEVERAL,
      payload,
    }),
    closeDrawer: () => managePreOrderDrawer(false),
  }, dispatch),
)(Form.create({ name: 'preOrderCreation' })((props) => {
  const {
    form: {
      resetFields,
      isFieldsTouched,
      getFieldDecorator,
    },
    allProducts,
    selected,
    closeDrawer,
    modifyProducts,
    preOrderDrawerOpen,
    user,
    locale,
  } = props
  const products = allProducts.filter((p) => selected.includes(p._id))
  // const min = Math.min(...products.map((p) => p.pricing.margin))
  const dateFormatList = locale === 'en' ? ['MM/DD/YYYY', 'MM/DD/YY'] : ['DD/MM/YYYY', 'DD/MM/YY']
  return (
    <Drawer
      visible={preOrderDrawerOpen}
      width='50vw'
      onClose={closeDrawer}
      title={(
        <span>
          <FormattedMessage
            id='preOrderCreation.numberOfProducts'
            defaultMessage='Pré-commande : {number} produit{agreement}'
            values={{
              number: selected.length,
              agreement: selected.length > 1 ? 's' : '',
            }}
          />
        </span>
      )}
      style={{ zIndex: 1000 }}
    >
      <Form
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
        }}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
        }}
        onSubmit={(e) => {
          e.preventDefault()
          const {
            form,
            form: {
              validateFieldsAndScroll,
            },
          } = props
          validateFieldsAndScroll((err, _values) => {
            const values = _values
            const {
              start, end, shipping, displayBand,
            } = values
            if (!err) {
              modifyProducts({
                selected,
                values: {
                  preOrderInfos: {
                    startDate: start,
                    endDate: end,
                    shippingEstimate: shipping,
                    displayBandOnCustomImage: displayBand,
                  },
                  preOrder: true,
                },
              })
              closeDrawer()
              form.resetFields()
            }
          })
        }}
      >
        <Form.Item
          wrapperCol={{
            span: 12,
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }}
        >
          <Button
            type='primary'
            onClick={() => {
              modifyProducts({
                selected,
                values: {
                  preOrder: false,
                },
              })
              closeDrawer()
            }}
          >
            <FormattedMessage id='preOrderCreation.cancel' defaultMessage='Annuler la Précommande' />
          </Button>
        </Form.Item>
        <Row>
          <Form.Item label={<FormattedMessage id='preOrderCreation.start' defaultMessage='Début de pré-commande :' />}>
            {getFieldDecorator('start', {
              initialValue: getInitialValue('start', selected, products),
              rules: [{
                required: true,
                message: <FormattedMessage
                  id='preOrderCreation.dateRequired'
                  defaultMessage='Veuillez sélectionner une date avant de valider votre pré-commande'
                />,
              }],
            })(
              <DatePicker showTime={{ format: 'HH:mm' }} format={dateFormatList} />,
            )}
          </Form.Item>
        </Row>
        <Form.Item label={<FormattedMessage id='preOrderCreation.end' defaultMessage='Fin de pré-commande :' />}>
          {getFieldDecorator('end', {
            initialValue: getInitialValue('end', selected, products),
            rules: [{
              required: true,
              message: <FormattedMessage
                id='preOrderCreation.dateRequired'
                defaultMessage='Veuillez sélectionner une date avant de valider votre pré-commande'
              />,
            }],
          })(
            <DatePicker showTime={{ format: 'HH:mm' }} format={dateFormatList} />,
          )}
        </Form.Item>
        { user && user.admin ? (
          <Row>
            <Form.Item label={<FormattedMessage id='preOrderCreation.estimation' defaultMessage='Estimation de livraison :' />}>
              {getFieldDecorator('shipping', {
                initialValue: getInitialValue('shipping', selected, products),
                rules: [{
                  required: true,
                  message: <FormattedMessage
                    id='preOrderCreation.dateRequired'
                    defaultMessage='Veuillez sélectionner une date avant de valider votre pré-commande'
                  />,
                }],
              })(
                <DatePicker showTime={{ format: 'HH:mm' }} format={dateFormatList} />,
              )}
            </Form.Item>
          </Row>
        ) : null }
        <Form.Item label={<FormattedMessage id='preOrderCreation.banner' defaultMessage='Bandeau de pré-commande :' />}>
          {getFieldDecorator('displayBand', { initialValue: getInitialValue('displayBand', selected, products), valuePropName: 'checked' })(
            <Checkbox />,
          )}
        </Form.Item>
        <Row />
        {isFieldsTouched() ? (
          <Form.Item
            wrapperCol={{
              span: 12,
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              style={{
                margin: '0 12px',
              }}
            >
              <FormattedMessage id='validate' defaultMessage='Valider' />
            </Button>
            <FormattedMessage id='reset' defaultMessage='Réinitialiser' />
          </Form.Item>
        ) : null}
      </Form>
    </Drawer>
  )
}))

// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#social
export const FETCH_MEMBERS = 'FETCH_MEMBERS'
export const RECEIVE_MEMBERS = 'RECEIVE_MEMBERS'
export const RECEIVED_MEMBERS = 'RECEIVED_MEMBERS'
export const RECEIVED_ONE_MEMBER = 'RECEIVED_ONE_MEMBER'
export const FETCH_MORE_MEMBERS = 'FETCH_MORE_MEMBERS'
export const FETCH_FORCE_MEMBERS = 'FETCH_FORCE_MEMBERS'
export const FETCH_FORCE_ONE_MEMBER = 'FETCH_FORCE_ONE_MEMBER'
export const RECEIVE_MORE_MEMBERS = 'RECEIVE_MORE_MEMBERS'
export const ADD_MEMBERS = 'ADD_MEMBERS'
export const DELETE_MEMBER = 'DELETE_MEMBER'
export const MODIFY_MEMBER = 'MODIFY_MEMBER'
export const MODIFY_MEMBERS_ENTITY = 'MODIFY_MEMBERS_ENTITY'
export const MODIFY_MEMBERS_CREDIT = 'MODIFY_MEMBERS_CREDIT'
export const MODIFY_MEMBERS_RATIO = 'MODIFY_MEMBERS_RATIO'
export const MODIFY_MEMBERS_GROUPS = 'MODIFY_MEMBERS_GROUPS'
export const SEND_MAIL = 'SEND_MAIL'
export const INIT_FETCH_DATA = 'INIT_FETCH_DATA_MEMBERS'
export const END_FETCH_DATA = 'END_FETCH_DATA_MEMBERS'
export const ERROR_FETCH_DATA = 'ERROR_FETCH_DATA_MEMBERS'
export const FETCH_MORE = 'FETCH_MORE_MEMBERS'
export const RECEIVED_MORE = 'RECEIVED_MORE_MEMBERS'

import React, { useState } from 'react'
import {
  Radio, Button, Form,
} from 'antd'
import {
  CardElement, useStripe, useElements,
} from '@stripe/react-stripe-js'
import { FormattedMessage } from 'react-intl'
import Spin from '../../atoms/Spin'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const Facturation = ({
  orders,
  setPaymentMode,
  paymentMode,
  isLoading,
  setCardCompleted,
  cardCompleted,
  handleSubmit,
  errorDetails,
  translateError,
  hasError,
  setTransactionType,
  isAdmin,
}) => {
  const total = (parseFloat(orders.reduce((a, c) => a + c.transaction.amount, 0) / 100)).toFixed(2)
  const onChange = (e) => {
    setPaymentMode(e.target.value)
    const value = e.target.value === 'admin' ? 'batch-admin' : 'batch'
    setTransactionType(value)
  }
  return (
    <div style={{ padding: '20px', width: '40%' }}>
      <p style={{ fontWeight: 600, fontSize: '16px' }}>
        <FormattedMessage
          id='batchCheckout.numberOfOrders'
          defaultMessage='Nombre de commandes : {number}'
          values={{ number: orders.length }}
        />
      </p>
      <p>
        <FormattedMessage id='batchCheckout.total' defaultMessage='Total :' />
        <span style={{ fontWeight: 'bold', marginLeft: 4 }}>
          {`${total} €`}
        </span>
      </p>
      <div>
        <p style={{ fontWeight: 600, fontSize: '16px' }}>
          <FormattedMessage id='batchCheckout.paymentMethod' defaultMessage='Comment souhaitez vous payer ?' />
        </p>
        <p>
          <Radio.Group onChange={onChange} defaultValue={paymentMode}>
            <Radio value='card'>
              <FormattedMessage id='batchCheckout.directPayment' defaultMessage='Payer directement' />
            </Radio>
            <Radio value='billing'>
              <FormattedMessage id='batchCheckout.billingAdd' defaultMessage='Ajouter à ma facture' />
            </Radio>
            {isAdmin ? (
              <Radio value='admin'>
                <FormattedMessage id='batchCheckout.admin' defaultMessage='Mode Admin - la commande ne sera pas facturée' />
              </Radio>
            ) : null }
          </Radio.Group>
        </p>
      </div>
      { paymentMode === 'card' ? (
        <div style={{
          margin: '12px 0',
          border: '1px solid rgb(217, 217, 217)',
          padding: '5px',
          borderRadius: '5px',
        }}
        >
          <CardElement
            onChange={(e) => {
              setCardCompleted(e.complete)
            }}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  '::placeholder': {
                    color: 'rgb(217, 217, 217)',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
      ) : null }
      <Button type='primary' disabled={paymentMode === 'card' && !cardCompleted} onClick={handleSubmit}>
        <FormattedMessage id='batchCheckout.payment' defaultMessage='Procéder au paiement' />
      </Button>
      { hasError ? (
        <p style={{ color: 'red', marginTop: '10px' }}>
          {' '}
          {translateError(errorDetails)}
          {' '}
        </p>
      ) : null }
    </div>
  )
}

export default function BatchCheckout(props) {
  const {
    step, orders, sendOrders, isLoading, history, hasError, errorDetails, setTransactionType, isAdmin,
  } = props
  const [paymentMode, setPaymentMode] = useState('card')
  const [cardCompleted, setCardCompleted] = useState(false)
  const amount = orders.reduce((a, c) => a + c.transaction.amount, 0)

  const elements = useElements()
  const stripe = useStripe()
  if (step !== 2) {
    return null
  }

  const translateError = (message) => {
    switch (message) {
    case 'payment_failed':
      return <FormattedMessage id='batchCheckout.failedPayment' defaultMessage='Votre paiement a échoué' />
    default:
      return message
    }
  }

  const handleSubmit = async () => {
    const { setLoading } = props
    setLoading(true)
    if (paymentMode === 'card') {
      const cardElement = elements.getElement(CardElement)
      // DO WE NEED BILLING DETAILS ?? , SEEMS TO WORK WITHOUT
      const methodResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      const { paymentMethod, error } = methodResult
      return sendOrders({
        orders, amount, paymentMethod, stripe, transaction_type: paymentMode, history,
      })
    }
    return sendOrders({
      orders, amount, transaction_type: paymentMode, history,
    })
  }
  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgb(217, 217, 217, 0.5)',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin size='large' />
        </div>
      ) : null }
      <Facturation
        orders={orders}
        setPaymentMode={setPaymentMode}
        paymentMode={paymentMode}
        isLoading={isLoading}
        setCardCompleted={setCardCompleted}
        cardCompleted={cardCompleted}
        handleSubmit={handleSubmit}
        translateError={translateError}
        errorDetails={errorDetails}
        hasError={hasError}
        setTransactionType={setTransactionType}
        isAdmin={isAdmin}
      />
    </>
  )
}

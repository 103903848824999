import React from 'react'
import date from 'dayjs'
import { FormattedMessage } from 'react-intl'
import {
  Form,
  DatePicker,
  InputNumber,
  Card,
  Popconfirm,
  Button,
  Icon,
  message,
  Tag,
  Select,
} from 'antd'
import { getEntityId, getUserEntityStatus } from '../../../helpers/entities'

const { Option } = Select

class RulesCard extends React.Component {
  constructor(props) {
    super(props)
    const {
      shop: {
        settings: {
          rules = [],
        },
      },
    } = props
    this.state = {
      inputVisible: false,
      newRules: rules,
      displayButton: {},
    }
  }

  showInput = () => {
    this.setState({
      inputVisible: true,
    })
  }

  displayButton = (id) => {
    const {
      displayButton,
    } = this.state
    displayButton[id] = true
    this.setState({ displayButton })
  }

  deleteRule = (rule) => {
    const {
      modifyShop,
      shop,
    } = this.props
    const { newRules } = this.state
    this.setState({ newRules: newRules.filter((_rule) => _rule.id !== rule.id) })
    const saveSettings = {
      settings: {
        ...shop.settings,
        rules: [
          ...shop.settings.rules.filter((_rule) => _rule.id !== rule.id),
        ],
      },
    }
    modifyShop({
      shop_id: shop._id,
      modifications: saveSettings,
      callback: () => {
        message.info(<FormattedMessage id='rulesCard.deletedRule' defaultMessage='Règle supprimée !' />)
        return true
      },
    })
  }

  handleSubmit = (e, id) => {
    e.preventDefault()
    const {
      form: {
        validateFieldsAndScroll,
      },
      modifyShop,
      shop,
      user,
      entities,
    } = this.props
    const {
      newRules,
      displayButton,
    } = this.state
    const { entityId } = getEntityId(user, entities)
    validateFieldsAndScroll((err, _values) => {
      const values = _values
      if (!err) {
        const saveSettings = {
          settings: {
            ...shop.settings,
            rules: [
              ...((shop.settings.rules
                && shop.settings.rules.filter((rule) => rule.id !== id)) || []),
              {
                ...newRules.find((rule) => rule.id === id),
                credit: values[`credit-${id}`],
                frequency: values[`frequency-${id}`],
                date: values[`date-${id}`],
                entityId: values[`entityId-${id}`] || entityId,
                id,
                groups: newRules.find((rule) => rule.id === id).groups,
              },
            ],
          },
        }
        modifyShop({
          shop_id: shop._id,
          modifications: saveSettings,
          callback: () => {
            message.info(<FormattedMessage id='rulesCard.createdRule' defaultMessage='Règle créée !' />)
            delete displayButton[id]
            this.setState({ newRules: saveSettings.settings.rules, displayButton })
            return true
          },
        })
      }
    })
  }

  handleSelect = (groupId, ruleId) => {
    const {
      modifyShop,
      shop,
    } = this.props
    const { newRules } = this.state

    newRules.find((rule) => rule.id === ruleId).groups.push(groupId)
    const saveSettings = {
      settings: {
        ...shop.settings,
        rules: [
          ...newRules,
        ],
      },
    }
    modifyShop({
      shop_id: shop._id,
      modifications: saveSettings,
      callback: () => {
        message.info(<FormattedMessage id='rulesCard.addedGroup' defaultMessage='Groupe ajouté !' />)
        this.setState({ newRules: saveSettings.settings.rules, inputVisible: false })
        return true
      },
    })
  }

  handleClose = (groupId, ruleId) => {
    const {
      modifyShop,
      shop,
    } = this.props
    const { newRules } = this.state

    const saveSettings = {
      settings: {
        ...shop.settings,
        rules: [
          ...((shop.settings.rules
            && shop.settings.rules.filter((rule) => rule.id !== ruleId)) || []),
          {
            ...newRules.find((rule) => rule.id === ruleId),
            groups: newRules.find((rule) => rule.id === ruleId).groups
              .filter((id) => id !== groupId),
          },
        ],
      },
    }
    modifyShop({
      shop_id: shop._id,
      modifications: saveSettings,
      callback: () => {
        message.info(<FormattedMessage id='rulesCard.removedGroup' defaultMessage='Groupe enlevé !' />)
        this.setState({ newRules: saveSettings.settings.rules, inputVisible: false })
        return true
      },
    })
  }

  displayFrequency = (frequency) => {
    switch (frequency) {
    case 'day':
      return <FormattedMessage id='rulesCard.perDay' defaultMessage='par jour' />
    case 'week':
      return <FormattedMessage id='rulesCard.perWeek' defaultMessage='par semaine' />
    case 'month':
      return <FormattedMessage id='rulesCard.perMonth' defaultMessage='par mois' />
    case 'year':
      return <FormattedMessage id='rulesCard.perYear' defaultMessage='par ans' />
    case 'birthday':
      return <FormattedMessage id='rulesCard.perBirthday' defaultMessage='par anniversaire' />
    case 'companyBirthday':
      return <FormattedMessage id='rulesCard.perArrivalDate' defaultMessage="par date d'arrivée" />
    default:
      return false
    }
  }

  displayFrequencyInput = (rule, frequency) => {
    const {
      form: {
        getFieldDecorator,
      },
    } = this.props
    switch (frequency) {
    case 'week':
      return (
        <Form.Item label={<FormattedMessage id='rulesCard.whichDay' defaultMessage='Quel jour de la semaine ?' />} style={{ flex: '1 3 auto' }}>
          {getFieldDecorator(`date-${rule.id}`, {
            rules: [{
              required: true,
              whitespace: true,
              message: <FormattedMessage id='required.day' defaultMessage='Veuillez renseigner un jour ' />,
            }],
            initialValue: rule.date,
          })(
            <Select
              placeholder={<FormattedMessage id='select' defaultMessage='Sélectionner...' />}
              style={{ width: 200 }}
              onChange={() => this.displayButton(rule.id)}
            >
              <Option key={0}>
                <FormattedMessage id='monday' defaultMessage='Lundi' />
              </Option>
              <Option key={1}>
                <FormattedMessage id='tuesday' defaultMessage='Mardi' />
              </Option>
              <Option key={2}>
                <FormattedMessage id='wednesday' defaultMessage='Mercredi' />
              </Option>
              <Option key={3}>
                <FormattedMessage id='thursday' defaultMessage='Jeudi' />
              </Option>
              <Option key={4}>
                <FormattedMessage id='friday' defaultMessage='Vendredi' />
              </Option>
              <Option key={5}>
                <FormattedMessage id='saturday' defaultMessage='Samedi' />
              </Option>
              <Option key={6}>
                <FormattedMessage id='sunday' defaultMessage='Dimanche' />
              </Option>
            </Select>,
          )}
        </Form.Item>
      )
    case 'month':
      return (
        <Form.Item label={<FormattedMessage id='rulesCard.whatMonth' defaultMessage='Quel jour du mois ?' />} style={{ flex: '1 3 auto' }}>
          {getFieldDecorator(`date-${rule.id}`, {
            rules: [{ required: true, message: <FormattedMessage id='required.day' defaultMessage='Veuillez renseigner un jour ' /> }],
            initialValue: date(rule.date),
          })(
            <InputNumber min={1} max={31} onChange={() => this.displayButton(rule.id)} />,
          )}
        </Form.Item>
      )
    case 'year':
      return (
        <Form.Item
          label={(
            <FormattedMessage
              id='rulesCard.whatDayOfTheYear'
              defaultMessage="Quel jour de l'année ?"
            />
          )}
          style={{ flex: '1 3 auto' }}
        >
          {getFieldDecorator(`date-${rule.id}`, {
            rules: [{
              required: true,
              message: <FormattedMessage
                id='required.dayOfTheYear'
                defaultMessage="Veuillez renseigner un jour de l'année"
              />,
            }],
            initialValue: date(rule.date),
          })(
            <DatePicker mode='date' format='DD/MM' onChange={() => this.displayButton(rule.id)} />,
          )}
        </Form.Item>
      )
    default:
      return false
    }
  }

  render() {
    try {
      const {
        form: {
          getFieldDecorator,
          isFieldsTouched,
          getFieldValue,
        },
        shop,
        shop: {
          settings: {
            membersGroups = [],
          },
        },
        user,
        entities,
      } = this.props

      const {
        inputVisible,
        newRules,
        displayButton,
      } = this.state

      const status = getUserEntityStatus(user, shop)
      return (
        <div>
          <Card
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type='primary'
              onClick={() => {
                newRules.push({
                  id: Math.random().toString(36).substring(7),
                  credit: '',
                  frequency: '',
                  entityId: '',
                  groups: [],
                  createdAt: new Date(),
                })
                this.setState({ newRules })
              }}
            >
              <FormattedMessage id='rulesCard.createNewRule' defaultMessage='Créer une nouvelle règle' />
            </Button>
          </Card>
          {newRules.filter((rule) => rule.id !== 'Anniversary' && (rule.entityId === user.entityId || !rule.entityId || user._id === shop.owner))
            .sort((a, b) => (date(a.createdAt).isBefore(date(b.createdAt)) ? 1 : -1))
            .map((rule) => (
              <Form
                key={rule.id}
                id={`RulesCreationForm-${rule.id}`}
                onSubmit={(e) => this.handleSubmit(e, rule.id)}
              >
                <Card
                  extra={[
                    <Popconfirm
                      key={rule.id}
                      title={<FormattedMessage id='rulesCard.confirmRuleDeletion' defaultMessage='Êtes-vous sûr de supprimer cette règle ?' />}
                      onConfirm={() => this.deleteRule(rule)}
                      okText={<FormattedMessage id='yes' defaultMessage='Oui' />}
                      cancelText={<FormattedMessage id='no' defaultMessage='Non' />}
                    >
                      <Button type='link'>
                        <FormattedMessage id='delete' defaultMessage='Supprimer' />
                      </Button>
                    </Popconfirm>,
                  ]}
                  style={{ width: '100%', margin: '10px 0px' }}
                  actions={displayButton && displayButton[rule.id]
                    && isFieldsTouched([`credit-${rule.id}`, `frequency-${rule.id}`, `date-${rule.id}`]) ? [
                      <Button
                        type='primary'
                        htmlType='submit'
                      >
                        <FormattedMessage id='save' defaultMessage='Enregistrer' />
                      </Button>,
                    ] : null}
                  title={(rule.credit && rule.frequency) || isFieldsTouched([`credit-${rule.id}`, `frequency-${rule.id}`])
                    ? `Ajoute ${getFieldValue(`credit-${rule.id}`) || rule.credit || '... '}
              crédit ${this.displayFrequency(getFieldValue(`frequency-${rule.id}`) || rule.frequency) || '...'}` : null}
                >
                  <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Form.Item label={<FormattedMessage id='credit' defaultMessage='Crédit' />} style={{ flex: '1 2 auto', paddingRight: 10 }}>
                      {getFieldDecorator(`credit-${rule.id}`, {
                        rules: [{
                          required: true,
                          message: <FormattedMessage id='rulesCard.required.credits' defaultMessage='Veuillez ajouter des crédits' />,
                        }],
                        initialValue: rule.credit,
                      })(<InputNumber onChange={() => this.displayButton(rule.id)} />)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='frequency' defaultMessage='Fréquence' />} style={{ flex: '1 2 auto' }}>
                      {getFieldDecorator(`frequency-${rule.id}`, {
                        rules: [{ required: true, whitespace: true }],
                        initialValue: rule.frequency,
                      })(
                        <Select
                          placeholder={<FormattedMessage id='select' defaultMessage='Sélectionner...' />}
                          style={{ width: 200 }}
                          onChange={() => this.displayButton(rule.id)}
                        >
                          <Option key='day'>
                            <FormattedMessage id='rulesCard.perDay' defaultMessage='par jour' />
                          </Option>
                          <Option key='week'>
                            <FormattedMessage id='rulesCard.perWeek' defaultMessage='par semaine' />
                          </Option>
                          <Option key='month'>
                            <FormattedMessage id='rulesCard.perMonth' defaultMessage='par mois' />
                          </Option>
                          <Option key='year'>
                            <FormattedMessage id='rulesCard.perYear' defaultMessage='par ans' />
                          </Option>
                          <Option key='birthday'>
                            <FormattedMessage id='rulesCard.perBirthday' defaultMessage='par anniversaire' />
                          </Option>
                          <Option key='companyBirthday'>
                            <FormattedMessage id='rulesCard.perArrivalDate' defaultMessage="par date d'arrivée" />
                          </Option>
                        </Select>,
                      )}
                    </Form.Item>
                    {(entities.length > 1 && (status === 'owner' || status === 'admin')) || rule.entityId ? (
                      <Form.Item label={<FormattedMessage id='entity' defaultMessage='Entité' />} style={{ flex: '1 2 auto' }}>
                        {getFieldDecorator(`entityId-${rule.id}`, {
                          rules: [{ required: true, whitespace: true }],
                          initialValue: rule.entityId ? entities.find((entity) => entity._id === rule.entityId).name : '',
                        })(
                          <Select
                            placeholder={<FormattedMessage id='select' defaultMessage='Sélectionner...' />}
                            style={{ width: 200 }}
                            onChange={() => this.displayButton(rule.id)}
                          >
                            {entities.map((_entity) => (
                              <Option value={_entity._id} key={_entity._id}>
                                {_entity.name}
                              </Option>
                            ))}
                          </Select>,
                        )}
                      </Form.Item>
                    ) : null}
                  </div>
                  {this.displayFrequencyInput(rule, getFieldValue(`frequency-${rule.id}`))}
                  {rule.groups.map((id) => {
                    const group = membersGroups.find((_group) => _group.id === id) || false
                    if (!group) return false
                    return (
                      <Tag
                        key={group.id}
                        closable
                        onClose={() => this.handleClose(group.id, rule.id)}
                        color={group.color}
                      >
                        {group.name}
                      </Tag>
                    )
                  })}
                  <div style={{ marginBottom: 10 }}>
                    <FormattedMessage
                      id='rulesCard.helper1'
                      defaultMessage="Si aucun groupe n'est sélectionné, la règle s'appliquera à tous les membres."
                    />
                  </div>
                  {inputVisible && (
                    <Select
                      showSearch
                      placeholder={<FormattedMessage id='select' defaultMessage='Sélectionner...' />}
                      size='small'
                      style={{ width: 200 }}
                      onChange={(id) => this.handleSelect(id, rule.id)}
                    >
                      {membersGroups.filter((group) => !rule.groups.find((id) => id === group.id))
                        .map((group) => (
                          <Option key={group.id}>{group.name}</Option>
                        ))}
                    </Select>
                  )}
                  {!inputVisible
                  && !!membersGroups.filter((group) => !rule.groups
                    .find((id) => id === group.id)).length
                  && (
                    <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
                      <Icon type='plus' />
                      {' '}
                      <FormattedMessage id='rulesCard.addGroup' defaultMessage='Ajouter un groupe' />
                    </Tag>
                  )}
                </Card>
              </Form>
            ))}
        </div>
      )
    } catch (e) {
      console.log(e, 'error organisms/RulesCard')
      return false
    }
  }
}

export default Form.create({ name: 'RulesCreationForm' })(RulesCard)
